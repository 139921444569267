import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { MyAppointments } from '../pages/MyAppointments';
import {
  BASE_ROUTE,
  BASE_ROUTES,
  DASHBOARD,
  VONAGE_VIDEO_ROOM,
  VONAGE_WAITING_ROOM,
  MEET_OUT_PROVIDERS,
  MY_APPOINTMENTS,
  MY_FAVORITE_PROVIDERS,
  SCHEDULE_AN_APPOINTMENT,
  SELECT_A_PROVIDER,
  APPOINTMENT_DEEP_LINK,
} from 'utilities/routes';
import { useEffect, useMemo, useState } from 'react';
import {
  UserSettings,
  VonageContext,
  VonageContextType,
} from 'context/VonageContext';
import { WaitingRoom } from '../components/modalContent/WaitingRoom';
import { VideoRoom } from '../components/modalContent/VideoRoom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CodexFeatureFlags } from 'utilities/interfaces';
import AppointmentPrework from '../pages/AppointmentPrework';
import SelectAProvider from 'app/my-appointments/pages/SelectAProvider';
import ProtectedRoutesModal from 'components/ProtectedRoutesModal/ProtectedRoutesModal';
import AppointmentDeepLink from 'app/my-appointments/pages/AppointmentDeepLink';
import ProtectedSubRoute from 'components/ProtectedSubroute';
import { Roles } from '../../../firebase/interfaces';

export const MyAppointmentsRoutes = () => {
  const [user, setUser] = useState({
    videoEffects: {
      backgroundBlur: false,
      virtualBackground: false,
    },
    defaultSettings: {
      publishAudio: true,
      publishVideo: true,
      audioSource: undefined,
      videoSource: undefined,
      audioOutput: undefined,
    },
  } as UserSettings);
  const userValue = useMemo(() => ({ user, setUser }), [user, setUser]);

  const { appointmentRoutesDisabledFlag } = useFlags<CodexFeatureFlags>();
  const navigate = useNavigate();

  useEffect(() => {
    if (appointmentRoutesDisabledFlag) {
      navigate(DASHBOARD);
    }
  }, [appointmentRoutesDisabledFlag, navigate]);

  return (
    <VonageContext.Provider value={userValue as VonageContextType}>
      <Routes>
        <Route path={BASE_ROUTE} element={<MyAppointments />} />
        <Route path={BASE_ROUTES} element={<Navigate to={MY_APPOINTMENTS} />} />
        <Route path={VONAGE_WAITING_ROOM} element={<WaitingRoom />} />
        <Route path={VONAGE_VIDEO_ROOM} element={<VideoRoom />} />
        <Route path={APPOINTMENT_DEEP_LINK} element={<AppointmentDeepLink />} />
        <Route
          path={SCHEDULE_AN_APPOINTMENT}
          element={
            <ProtectedSubRoute allowedRoles={[Roles.PATIENT]}>
              <ProtectedRoutesModal>
                <AppointmentPrework />
              </ProtectedRoutesModal>
            </ProtectedSubRoute>
          }
        />
        <Route
          path={SELECT_A_PROVIDER}
          element={
            <ProtectedSubRoute allowedRoles={[Roles.PATIENT]}>
              <ProtectedRoutesModal>
                <SelectAProvider />
              </ProtectedRoutesModal>
            </ProtectedSubRoute>
          }
        />
        <Route
          path={MY_FAVORITE_PROVIDERS}
          element={
            <ProtectedSubRoute allowedRoles={[Roles.PATIENT]}>
              <ProtectedRoutesModal>
                <MyAppointments />
              </ProtectedRoutesModal>
            </ProtectedSubRoute>
          }
        />
        <Route
          path={MEET_OUT_PROVIDERS}
          element={
            <ProtectedSubRoute allowedRoles={[Roles.PATIENT]}>
              <ProtectedRoutesModal>
                <MyAppointments />
              </ProtectedRoutesModal>
            </ProtectedSubRoute>
          }
        />
      </Routes>
    </VonageContext.Provider>
  );
};
