import { ModalButtonProps } from 'components/modal/modal.interfaces';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import { genericActionsIds, pageIds } from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Modal from 'components/modal/modalComponent';
import { AlertType } from './interfaces/appointment.interfaces';
import AlertComponent from 'components/alertComponent';
import { Appointment } from './components/Appointment';
import ButtonComponent from 'components/button/buttonComponent';
import {
  AppointmentTypeEnum,
  useCancelAppointmentMutation,
  useGetAppointmentByCodexAppointmentIdQuery,
  useGetAppointmentRescheduleLinkQuery,
  useGetProviderInfoByProviderCodexIdLazyQuery,
} from 'graphql/generated/remote-schema-hasura';

import { useGetSignedUrlFromStorage } from 'hooks/useGetSignedUrlFromStorage';
import {
  appointmentTypeFromServiceType,
  formatDateForAppointment,
} from 'utilities/functions';
import { DASHBOARD } from 'utilities/routes';
import Loader from 'components/loaderComponent';
import { useConfirmationModal } from 'hooks/useConfirmationModal';

export const CancelAppointmentModal = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const openConfirmationModal = useConfirmationModal();

  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.CANCEL_APPOINTMENT_MODAL,
  });
  const { data: editAppointment, loading: editAppointmentLoading } = useGetPage(
    {
      locale: 'en',
      pageId: pageIds.EDIT_APPOINTMENT,
    },
  );

  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.CLOSE],
    });

  const appointmentId = location.state?.appointmentId;
  const [cancelAppointment, { loading: cancelAppointmentLoading }] =
    useCancelAppointmentMutation({
      variables: {
        codexAppointmentId: appointmentId,
        cancelationReason: '',
      },
      onCompleted: (data) => {
        if (data.cancelFHIRAppointment.appointment.appointmentId) {
          navigate(-1);
        } else {
          handleError();
        }
      },
      onError: () => handleError(),
    });
  const { data: appointmentRescheduleLinkData } =
    useGetAppointmentRescheduleLinkQuery({
      variables: { appointmentCodexId: appointmentId },
      skip: !appointmentId,
    });
  const appointmentRescheduleLink =
    appointmentRescheduleLinkData?.getAppointmentRescheduleLink?.rescheduleLink;
  const [getProviderInfo, { loading: providerLoading, data: providerData }] =
    useGetProviderInfoByProviderCodexIdLazyQuery();

  const provider = providerData?.getFHIRProviderbyId.providers[0];
  const providerCodexId = provider?.id;
  const photoUrl = provider?.SENSITIVE_profile_picture_id;

  const signedProfileUrl = useGetSignedUrlFromStorage(
    photoUrl ?? undefined,
    providerCodexId ?? undefined,
  );

  const { data: appointmentData, loading: appointmentLoading } =
    useGetAppointmentByCodexAppointmentIdQuery({
      variables: { appointmentCodexId: appointmentId },
      skip: !appointmentId,
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        if (data.getFHIRAppointmentByCodexId.appointment?.codexProviderId) {
          getProviderInfo({
            variables: {
              providerCodexId:
                data.getFHIRAppointmentByCodexId.appointment.codexProviderId,
            },
          });
        }
      },
    });

  const [alertProps, setAlertProps] = useState<{
    type: AlertType;
    text: string;
  } | null>({
    type: 'info',
    text: locale?.notificationText1,
  });
  const handleClose = () => navigate(-1);

  const cancelAppointmentHandler = () => {
    cancelAppointment();
  };

  const handleError = () => {
    navigate(-1);
  };

  const keepAppointmentHandler = () => {
    navigate(-1);
  };

  const appointmentDetailsRedirectionHandler = async () => {
    if (
      !appointmentRescheduleLink ||
      !editAppointment.confirmationModalTitle ||
      !editAppointment.confirmationModalText
    )
      return;
    const shouldContinue = await openConfirmationModal({
      text: editAppointment.confirmationModalText,
      title: editAppointment.confirmationModalTitle,
    });
    if (!shouldContinue) return;
    window.open(appointmentRescheduleLink, '_blank');
    navigate(-1);
  };

  const Buttons: ModalButtonProps[] = [
    {
      label: genericAction?.[genericActionsIds.CLOSE].close,
      onClick: handleClose,
      paddingX: 'px-[3.3rem]',
      className: 'bg-transparent text-clc-blue',
    },
    {
      label: locale?.rescheduleLabel,
      floatLeft: true,
      fontWeightUnderline: 'text-clc-blue underline uppercase',
      type: 'underline',
      onClick: appointmentDetailsRedirectionHandler,
    },
  ];

  useEffect(() => {
    if (locale) {
      setAlertProps({
        type: 'info',
        text: locale.notificationText1,
      });
    }
  }, [locale]);

  if (
    loading ||
    !locale ||
    genericActionLoading ||
    !genericAction ||
    !appointmentId ||
    !editAppointment ||
    editAppointmentLoading
  ) {
    return null;
  }

  const appointment = appointmentData?.getFHIRAppointmentByCodexId.appointment;
  const serviceOffering = provider?.serviceOfferings?.find(
    (service) =>
      appointmentTypeFromServiceType(service.serviceType) ===
      appointment?.appointmentType,
  );
  const specialty = provider?.SENSITIVE_credentials?.titles[0];

  const contentLoading = providerLoading || appointmentLoading;

  return (
    <Modal
      isOpen={true}
      title={locale?.cancelAppointmentTitle}
      modalDesktopWidth="desktop:max-w-[960px]"
      disableMobileGrow={true}
      buttons={Buttons}
      onClose={handleClose}
      modalContainerCustomClass="w-full"
      contentPaddingX="px-2.5"
    >
      {contentLoading ? (
        <div className="flex flex-col justify-center items-center">
          <Loader />
        </div>
      ) : (
        <div className="flex flex-col gap-5 desktop:gap-[60px] desktop:flex-row">
          <div className="flex flex-col py-2.5 desktop:max-h-[450px] desktop:flex-1">
            {alertProps && (
              <div className="flex flex-row mb-[30px] px-7">
                <AlertComponent
                  isNotification={true}
                  hideCloseButton={true}
                  actionLabel={locale?.notificationCtaLabel}
                  onActionClick={() => navigate(DASHBOARD)}
                  type={alertProps.type}
                  customContent={
                    <div className="flex flex-col w-full desktop:flex-row justify-between items-center desktop:relative">
                      <div className="flex flex-col desktop:flex-row w-full gap-[20px] justify-between">
                        <p
                          className={`desktop:max-w-[565px] text-center desktop:text-start text-base font-semibold ${
                            alertProps.type === 'info'
                              ? 'text-alert-yellow'
                              : 'text-alert-negative'
                          } desktop:mr-[35px]`}
                        >
                          {alertProps.text}
                        </p>
                      </div>
                    </div>
                  }
                />
              </div>
            )}
            <div className="mx-[30px] flex desktop:flex-row flex-col pl-[20px] pr-[20px] desktop:pl-[45px] desktop:pr-[45px] bg-white p-5 desktop:p-8 rounded-[10px] justify-between">
              <p className="text-lg font-semibold text-charcoal-gray mb-3">
                {locale?.appointmentTitle}
              </p>
              <Appointment
                date={formatDateForAppointment(
                  new Date(appointment?.start || ''),
                )}
                type={appointment?.appointmentType as AppointmentTypeEnum}
                price={serviceOffering?.price as string}
                provider={{
                  credentials: specialty as string,
                  name: `${provider?.SENSITIVE_firstname} ${provider?.SENSITIVE_lastname}`,
                  profileImg: signedProfileUrl,
                  title: specialty as string,
                }}
                locales={locale}
              />
            </div>
            <div className="desktop:mx-[30px] mt-[30px] flex flex-col pl-[45px] pr-[45px] justify-center items-center">
              <p className="text-lg font-semibold text-charcoal-gray text-center">
                {locale?.ctaTitle}
              </p>
              <div className="flex w-full flex-col desktop:flex-row justify-center mt-4 gap-3">
                <ButtonComponent
                  onClick={cancelAppointmentHandler}
                  disabled={cancelAppointmentLoading}
                  className="order-1 desktop:order-0 max-h-[45px] w-full px-[10px] desktop:px-[30px] bg-transparent hover:bg-transparent text-clc-blue desktop:mr-[15px]"
                >
                  {locale?.ctaButtonLabel1}
                </ButtonComponent>
                <ButtonComponent
                  onClick={keepAppointmentHandler}
                  className="order-0 desktop:order-1  max-h-[45px] w-full px-[10px] desktop:px-[30px] desktop:ml-[15px]"
                >
                  {locale?.ctaButtonLabel2}
                </ButtonComponent>
              </div>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};
