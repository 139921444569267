import { ChangeEvent } from 'react';
import { zipcodePattern } from 'utilities/variables';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import { genericActionsIds } from 'utilities/constants';
import { ReactComponent as ChevronRightIcon } from 'assets/icons/chevron-right-white.svg';
import InputComponent from 'components/inputComponent';
import ButtonComponent from 'components/button/buttonComponent';
import Alert from 'components/alertComponent';
import WeatherCarousel from './WeatherCarousel';
import CurrentConditions from './CurrentConditions';
import UVIndex from './UVIndex';
import AQIndex from './AQIndex';
import useWeatherData from 'hooks/useWeather/useWeather';
import Loader from 'components/loaderComponent';
import UVWarning from './UVWarning';

export const WeatherWidget = () => {
  const {
    weatherData,
    disableButton,
    error,
    refetch,
    getDate,
    loading,
    locale,
    localeLoading,
    onDisableButton,
    onZipCodeChange,
    zipCode,
  } = useWeatherData();

  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.UPDATE],
    });

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const zipcodeRegex = new RegExp(zipcodePattern);
    onDisableButton(!zipcodeRegex.test(value));
    onZipCodeChange(value);
  };

  const handleUpdate = () => refetch(zipCode);

  if (localeLoading || !locale || genericActionLoading || !genericAction) {
    return null;
  }

  return (
    <div className="flex flex-col items-center px-5 pt-5 pb-[30px] desktop:p-[30px] gap-5 bg-white rounded-10">
      <div className="flex flex-col w-full items-start p-0 gap-2.5">
        <div className="flex flex-col desktop:flex-row w-full desktop:items-center desktop:justify-between p-0 gap-2.5">
          <h3 className="flex text-h4 text-dark-gray font-semibold">
            {locale.weather.title}
            {weatherData?.location.name && `: ${weatherData.location.name}`}
            {weatherData?.location.country &&
              `, ${weatherData?.location.country}`}
          </h3>

          <div className="flex flex-row items-center justify-between gap-[15px]">
            <span className="flex font-bold">{locale.weather.zipcode} </span>
            <div className="flex grow max-w-[150px] desktop:max-w-[160px]">
              <InputComponent
                decorator={
                  <ButtonComponent
                    borderRadius="rounded-[5px]"
                    disabled={disableButton}
                    paddingX="px-[8px] desktop:px-[2px]"
                    paddingY="py-[5px] desktop:py-[5px]"
                    Icon={ChevronRightIcon}
                    onClick={handleUpdate}
                  />
                }
                paddingRight="pr-11"
                type="zip"
                name="zipcode"
                noMarginBottom={true}
                value={zipCode}
                onChange={handleOnChange}
              />
            </div>
          </div>
        </div>
        <hr className="flex flex-row w-full items-center h-px bg-black-blur" />
      </div>
      <div className="flex flex-col w-full items-start gap-2.5 text-base text-dark-gray font-medium">
        {error ? (
          <Alert type="warning" text={error} />
        ) : loading ? (
          <div className="flex items-center justify-center w-full">
            <Loader />
          </div>
        ) : (
          weatherData && (
            <div className="flex flex-col gap-5 w-full">
              <h3 className="flex text-h4 desktop:text-h3 text-dark-gray font-semibold">
                {getDate()}
              </h3>

              <div className="flex flex-col w-full desktop:hidden gap-10">
                <div className="flex flex-col">
                  <WeatherCarousel>
                    <CurrentConditions
                      tempF={weatherData.tempF}
                      tempC={weatherData.tempC}
                      condition={weatherData.condition}
                      isDay={weatherData.isDay}
                      locale={locale.weather}
                    />
                    <UVIndex
                      id="mobile-uvindex"
                      uvIndex={weatherData.uvIndex}
                      locale={locale.weather}
                    />
                    <AQIndex
                      id="mobile-aqindex"
                      aqIndex={weatherData.aqIndex}
                      locale={locale.weather}
                    />
                  </WeatherCarousel>
                </div>
              </div>

              <div className="hidden desktop:flex flex-col w-full justify-between gap-10">
                <div className="flex flex-row">
                  <CurrentConditions
                    tempF={weatherData.tempF}
                    tempC={weatherData.tempC}
                    condition={weatherData.condition}
                    isDay={weatherData.isDay}
                    locale={locale.weather}
                  />
                  <UVIndex
                    id="uvindex"
                    borderX
                    uvIndex={weatherData.uvIndex}
                    locale={locale.weather}
                  />
                  <AQIndex
                    id="aqindex"
                    aqIndex={weatherData.aqIndex}
                    locale={locale.weather}
                  />
                </div>
              </div>
              <UVWarning locale={locale.weather} />
            </div>
          )
        )}
      </div>
    </div>
  );
};
