import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as EditIconDisable } from 'assets/icons/editDisable.svg';
import { AccountDetailComponentProps } from 'app/my-account/interfaces/profile.interfaces';
import { useContext, useRef, useState } from 'react';
import { convertFileToBase64 } from 'utilities/functions';
import {
  useGetFileUrlFromStorageMutation,
  useUpdateProfilePictureMutation,
} from 'graphql/generated/hasura';

import Avatar from 'components/avatarComponent';
import InputComponent from 'components/inputComponent';
import { AuthContext } from 'auth/context/AuthContext';
import { AllowedFormats, ImageFormat } from 'utilities/constants';

export const AccountDetail: React.FC<AccountDetailComponentProps> = ({
  locale,
  accountDetailsFields,
}) => {
  const [updateProfilePictureMutation] = useUpdateProfilePictureMutation({});
  const [getFileUrlFromStorage] = useGetFileUrlFromStorageMutation({});
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { profilePicture, setProfilePicture } = useContext(AuthContext);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const handleUploadPhoto = () => {
    if (fileInputRef.current && !isEditing) {
      fileInputRef.current.click();
    }
  };

  const validateImage = (image: File): Promise<void> => {
    if (!AllowedFormats.includes(image.type as ImageFormat)) {
      return Promise.reject();
    }

    const img = new Image();
    img.src = URL.createObjectURL(image);

    return new Promise((resolve) => {
      img.onload = () => {
        setIsEditing(false);
        resolve();
      };
    });
  };

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (isEditing) return;
    const file = event.target.files && event.target.files[0];

    if (file) {
      try {
        await validateImage(file);
        setIsEditing(true);

        const base64Image = await convertFileToBase64(file);
        const profileResponse = await updateProfilePictureMutation({
          variables: {
            image: base64Image,
            filename: file.name,
          },
        });
        const image = await getFileUrlFromStorage({
          variables: {
            fileId: profileResponse.data?.UpdateProfilePicture?.fileId || '',
          },
        });

        setProfilePicture(image.data?.GetSignUrlFormStorage?.url || null);
        setIsEditing(false);
      } catch (error) {
        setIsEditing(false);
        error &&
          console.error(`Error on image upload ${JSON.stringify(error)}`);
      }
    }
  };

  return (
    <div className="flex flex-col items-start w-full gap-2.5 p-0">
      <div className="flex flex-row w-full items-center justify-between pb-[15px]">
        <div className="flex flex-row items-center">
          <h3 className="text-h7 desktop:text-h7 text-dark-gray font-bold desktop:mr-2.5">
            {locale?.personalInfo.accountDetails.title}
          </h3>
        </div>
      </div>

      <div className="desktop:flex flex-col w-full items-center space-y-4">
        <div className="flex flex-col desktop:flex-row w-full desktop:w-[690px] items-center desktop:items-center">
          <label
            className="w-full desktop:w-3/12 text-base text-dark-gray font-semibold mb-2"
            htmlFor="profile-picture"
          >
            {locale?.personalInfo.accountDetails.profilePictureLabel}
          </label>
          <div className="flex flex-row w-full desktop:w-9/12 gap-[15px]">
            <Avatar size="md" imageUrl={profilePicture} />
            <div className="flex flex-row items-center cursor-pointer">
              {isEditing ? (
                <EditIconDisable className="w-4 h-4 fill-current mr-[7px] text-med-gray" />
              ) : (
                <EditIcon className="w-4 h-4 fill-current mr-[7px] text-med-gray" />
              )}

              <p
                className={`text-bas font-bold underline ${
                  isEditing ? 'text-med-gray' : 'text-clc-blue'
                }`}
                onClick={handleUploadPhoto}
              >
                {locale?.personalInfo.accountDetails.changePhotoButton}
              </p>
              <input
                type="file"
                id="myImage"
                onChange={handleImageUpload}
                ref={fileInputRef}
                className="hidden"
              />
            </div>
          </div>
        </div>

        {accountDetailsFields.map((field) => (
          <div
            key={field.name}
            className="flex flex-col desktop:flex-row w-full desktop:w-[690px] items-center desktop:items-center"
          >
            <label
              className="w-full desktop:w-3/12 text-base text-dark-gray font-semibold mb-2"
              htmlFor={field.name}
            >
              {field.label}
              <span className="text-base font-bold text-clc-red">
                {locale?.accountDeletion.form.required}
              </span>
            </label>
            <div className="w-full desktop:w-9/12">
              <InputComponent
                type={field.type}
                name={field.name}
                value={field.value}
                onChange={field.onChange}
                isDisabled={field.isDisabled}
                maxLengthValue={field.maxLengthValue}
              />
            </div>
          </div>
        ))}
      </div>

      <hr className="desktop:flex flex-row w-full items-center h-px bg-black-blur" />
    </div>
  );
};
