import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContextType, AuthContext } from 'auth/context/AuthContext';
import { ModalButtonProps } from 'components/modal/modal.interfaces';
import { useGetPage } from 'hooks/useGetPage';
import { genericActionsIds, pageIds } from 'utilities/constants';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import {
  TEST_KITS_MANAGEMENT_BARCODE_CAMERA,
  TEST_KITS_MANAGEMENT_REGISTER_TEST_KIT,
} from 'utilities/routes';
import Modal from 'components/modal/modalComponent';
import AlertComponent from 'components/alertComponent';

export const TestKitBarcodeScanned = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoggedIn } = useContext<AuthContextType>(AuthContext);
  const token = location.state?.token || '';
  const testKitNumber = location.state?.testKitNumber || '';
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.TEST_KIT_BARCODE_SCANNED,
  });
  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.CONTINUE, genericActionsIds.CANCEL],
    });

  const handleNextStep = () => {
    navigate(TEST_KITS_MANAGEMENT_BARCODE_CAMERA, {
      state: { testKitNumber, token },
    });
  };

  const handleModalClose = () => {
    navigate(TEST_KITS_MANAGEMENT_REGISTER_TEST_KIT);
  };

  const Buttons: ModalButtonProps[] = [
    {
      label: genericAction?.[genericActionsIds.CONTINUE].continue,
      paddingX: 'px-[45.5px]',
      paddingY: 'py-[10.5px]',
      onClick: handleNextStep,
    },
    {
      label: genericAction?.[genericActionsIds.CANCEL].cancel,
      type: 'underline',
      onClick: handleModalClose,
    },
  ];

  if ((loading && !locale) || (genericActionLoading && !genericAction))
    return null;

  return (
    <Modal
      isOpen={true}
      title={locale?.scanTestKitBarcodes}
      buttons={Buttons}
      disableMobileClose={!isLoggedIn}
      onClose={handleModalClose}
    >
      <div className="flex flex-col gap-[30px] desktop:min-h-[155px]">
        <AlertComponent type="positive" text={locale?.testKitBarcodeScanned} />
        <p className="text-base font-medium text-med-gray">
          {locale?.pressContinueToScan}
        </p>
      </div>
    </Modal>
  );
};
