import { useEffect, useState } from 'react';
import {
  MILLISECONDS_IN_SEC,
  SECONDS_IN_MIN,
} from '../utilities/constants/time';

export const useTimer = (defaultTime = SECONDS_IN_MIN) => {
  const [timeLeft, setTimeLeft] = useState<number>(defaultTime);
  const [temporalyDisableResendActions, setTemporalyDisableResendActions] =
    useState<boolean>(false);

  useEffect(() => {
    let interval: NodeJS.Timer;
    if (temporalyDisableResendActions) {
      interval = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(+interval);
            setTemporalyDisableResendActions(false);
            return 0;
          }
          return prevTime - 1;
        });
      }, MILLISECONDS_IN_SEC);
    }
    return () => clearInterval(+interval);
  }, [temporalyDisableResendActions]);

  return {
    timeLeft,
    temporalyDisableResendActions,
    setTimeLeft,
    setTemporalyDisableResendActions,
  };
};
