import React from 'react';

export const PreworkSolidIcon: React.ComponentType<
  React.SVGProps<SVGSVGElement>
> = ({ fill, className }) => {
  return (
    <svg
      className={className}
      width="16"
      height="19"
      viewBox="0 0 16 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.48 3.20422V3.18422C15.2 2.94422 14.82 2.76422 14.37 2.76422H12.75V1.60422H10.94V0.574219H5.01V1.60422H3.2V2.77422H1.58C1.15 2.77422 0.75 2.92422 0.45 3.20422V3.22422C0.19 3.48422 0 3.83422 0 4.25422V18.5642L16 18.5442V4.25422C16 3.85422 15.84 3.48422 15.53 3.20422H15.48ZM4.74 3.07422H5.3C5.41 3.07422 5.51 3.05422 5.57 3.04422C6.11 2.97422 6.52 2.53422 6.55 2.04422H9.35C9.37 2.52422 9.75 2.96422 10.3 3.04422C10.38 3.06422 10.45 3.06422 10.47 3.07422C10.47 3.07422 10.53 3.07422 10.55 3.07422H10.58H11.15V3.81422H4.73V3.07422H4.74ZM4.09 16.2042L2.73 14.9442L3.84 13.9142L4.09 14.1442L5.45 12.8842L6.56 13.9142L4.09 16.2142V16.2042ZM4.09 13.3342L2.73 12.0742L3.84 11.0442L4.09 11.2742L5.45 10.0142L6.56 11.0442L4.09 13.3442V13.3342ZM6.15 8.67422L5.86 9.21422H3.35V7.75422H4.89L5.94 5.81422L7.2 7.50422L8.43 5.27422H8.72L10.15 7.89422L10.52 7.76422H12.55V9.22422H10.82L9.66 10.2242L8.57 8.22422L7.4 10.3442L6.16 8.68422L6.15 8.67422ZM12.62 15.2542H7.5V13.7942H12.62V15.2542ZM12.62 12.3842H7.5V10.9242H12.62V12.3842Z"
        fill={fill}
      />
    </svg>
  );
};
