import { pageIds } from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';

export const BackOfficePage = () => {
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.BACK_OFFICE,
  });

  if (loading && !locale) return null;

  return (
    <h1
      test-id="backoffice-page"
      className="text-h2 desktop:text-h1 text-dark-gray"
    >
      {locale?.title}
    </h1>
  );
};
