import { phoneNumberPattern } from 'utilities/variables';
import { useGetPage } from 'hooks/useGetPage';
import { pageIds } from 'utilities/constants';
import InputComponent, {
  SelectWithFlagsValues,
} from 'components/inputComponent';
import { parseMappedCountriesIntoSelectValues } from 'utilities/functions';
import React, { useMemo, useState } from 'react';

interface MfaPhoneInputComponentProps {
  country: string;
  countries: {
    code: string | null | undefined;
    flag: string | null | undefined;
  }[];
  onCountryChange: (country: SelectWithFlagsValues) => void;
  onPhoneNumberChange: (phoneNumber: string) => void;
  phoneNumber: string;
  currentPhoneNumber?: string;
  children?: React.ReactNode;
}

export const MfaPhoneInputComponent: React.FC<MfaPhoneInputComponentProps> = ({
  countries,
  onCountryChange,
  onPhoneNumberChange,
  country,
  phoneNumber,
  currentPhoneNumber,
  children,
}) => {
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.MULTI_FACTOR_AUTH,
  });
  const [isCheckboxChecked, setIsCheckboxChecked] = useState<boolean>(true);

  const handleCheckboxChange = (checked: boolean) => {
    const phone = checked ? currentPhoneNumber : '';
    onPhoneNumberChange(phone ?? '');
    setIsCheckboxChecked(checked);
  };

  const parseSelectedValue = (
    currentCountry: string,
  ): SelectWithFlagsValues | undefined => {
    const values = parseMappedCountriesIntoSelectValues(countries);
    const matchingValue = values.find((item) => item.value === currentCountry);

    if (
      matchingValue &&
      matchingValue.label &&
      matchingValue.value &&
      matchingValue.flag
    ) {
      return {
        label: matchingValue.label,
        value: matchingValue.value,
        flag: matchingValue.flag,
      };
    } else {
      return undefined;
    }
  };

  const getMemoizedDefaultParsedValue = useMemo(
    () => parseMappedCountriesIntoSelectValues(countries)[0],
    [countries],
  );

  const getMemoizedParsedCountries = useMemo(
    () => parseMappedCountriesIntoSelectValues(countries),
    [countries],
  );

  if (loading && !locale) return null;

  return (
    <>
      <div className="flex flex-col items-left desktop:flex-row desktop:items-start desktop:justify-center">
        <label
          className="block mb-2.5 text-base text-dark-gray font-bold desktop:justify-self-center desktop:mt-4 desktop:mx-auto"
          htmlFor="phoneNumber"
        >
          {locale?.mfaSMS.phoneLabel}
        </label>

        <div>
          <div className="flex flex-row w-full desktop:w-auto">
            <InputComponent
              testID="phoneNumber-country-select"
              type="select-with-country-icons"
              name="country"
              borderRadiusRight={true}
              selectInputWithFlagsProps={{
                currentValue: parseSelectedValue(country),
                defaultValue:
                  getMemoizedDefaultParsedValue as SelectWithFlagsValues,
                options: getMemoizedParsedCountries as SelectWithFlagsValues[],
                onSelectChange: (e) =>
                  onCountryChange(e as SelectWithFlagsValues),
              }}
            />

            <InputComponent
              testID="phoneNumber-input"
              isDisabled={isCheckboxChecked}
              disableTelWidth={true}
              type="tel"
              pattern={phoneNumberPattern}
              name="phoneNumber"
              onChange={(e) => {
                setIsCheckboxChecked(false);
                return onPhoneNumberChange(e.target.value);
              }}
              borderRadiusLeft={true}
              value={phoneNumber}
            />
          </div>
          <div className="flex flex-row gap-2.5 items-start mb-4 desktop:mb-0">
            <InputComponent
              testID="term-and-conditions-input"
              type="checkbox"
              name="agreementsCheckbox"
              checkboxProps={{
                checked: isCheckboxChecked,
                onCheckboxChange: handleCheckboxChange,
              }}
            />
            <label
              className="text-med-gray text-sm font-semibold my-auto"
              htmlFor="agreementsCheckbox"
            >
              {locale?.sameContactPhone}
            </label>
          </div>
        </div>
        {children}
      </div>
    </>
  );
};
