import {
  useGetExternalNotificationCountsSubscription,
  useGetUserNotificationCountsSubscription,
} from 'graphql/generated/hasura';

import { useRef } from 'react';
import { singleOrDefault } from 'utilities/functions';

export const useProviderNotificationSubscriptions = (
  setter: (value: { internal: number; external: number }) => void,
): void => {
  const internalNotificationCountRef = useRef<number | undefined>();
  const externalNotificationCountRef = useRef<number | undefined>();

  function setIfBothResults() {
    if (
      internalNotificationCountRef.current &&
      externalNotificationCountRef.current
    ) {
      console.log(
        'setting',
        internalNotificationCountRef.current,
        externalNotificationCountRef.current,
      );
      setter({
        internal: internalNotificationCountRef.current,
        external: externalNotificationCountRef.current,
      });
    }
  }

  useGetUserNotificationCountsSubscription({
    shouldResubscribe: true,
    onError: (error) =>
      console.log(
        'Error configuring internal notification subscriptions',
        error.message,
        error.cause,
        error,
      ),
    onData: (result) => {
      internalNotificationCountRef.current =
        result.data.data?.user_notifications_aggregate.aggregate?.count;
      setIfBothResults();
    },
  });
  useGetExternalNotificationCountsSubscription({
    shouldResubscribe: true,
    onError: (error) =>
      console.log(
        'Error configuring external notification subscriptions',
        error.message,
        error.cause,
        error,
      ),
    onData: (result) => {
      externalNotificationCountRef.current =
        singleOrDefault(
          result.data.data?.provider_unread_external_notification_counts ?? [],
        )?.dosespot_unread_notifications ?? 0;
      setIfBothResults();
    },
  });
};

export const useNotificationSubscriptions = (
  setter: (value: { internal: number; external: number }) => void,
): void => {
  useGetUserNotificationCountsSubscription({
    shouldResubscribe: true,
    onError: (error) =>
      console.log(
        'Error configuring internal notification subscriptions',
        error.message,
        error.cause,
        error,
      ),
    onData: (result) => {
      setter({
        internal:
          result.data.data?.user_notifications_aggregate.aggregate?.count ?? 0,
        external: 0,
      });
    },
  });
};
