import { useContext, useEffect, useRef, useState } from 'react';
import { AuthContext, AuthContextType } from 'auth/context/AuthContext';
import { useSendVerificationEmail } from '../../../firebase/hooks';
import {
  INITIAL_RESEND_VERIFICATION_COUNTER,
  pageIds,
} from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';
import { useLocation, useNavigate } from 'react-router-dom';
import { AUTH_LOGIN } from 'utilities/routes';
import Alert from 'components/alertComponent';
import ButtonComponent from 'components/button/buttonComponent';

export const SendVerificationEmail = () => {
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.SEND_VERIFICATION_EMAIL,
  });
  const { data: firebaseLocale, loading: firebaseLoading } = useGetPage({
    locale: 'en',
    pageId: pageIds.FIREBASE_ERRORS,
  });
  const [resendVerificationCounter, setResendVerificationCounter] = useState(
    INITIAL_RESEND_VERIFICATION_COUNTER,
  );
  const verificationTimeoutIntervalRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (
      resendVerificationCounter > 0 &&
      !verificationTimeoutIntervalRef.current
    ) {
      verificationTimeoutIntervalRef.current = setInterval(() => {
        setResendVerificationCounter((prev) => {
          if (prev === 1) {
            clearInterval(
              verificationTimeoutIntervalRef.current as NodeJS.Timeout,
            );
            verificationTimeoutIntervalRef.current = null;
          }
          return prev - 1;
        });
      }, 1000);
    }
  }, [resendVerificationCounter]);
  useEffect(() => {
    return () => {
      if (verificationTimeoutIntervalRef.current) {
        clearInterval(verificationTimeoutIntervalRef.current);
        verificationTimeoutIntervalRef.current = null;
      }
    };
  }, []);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const linkExpired = queryParams.get('linkExpired') || '';
  const accountNotVerified = queryParams.get('accountNotVerified') || '';
  const { user } = useContext<AuthContextType>(AuthContext);
  const [errorMsg, handleSendVerificationEmail] = useSendVerificationEmail();
  const [resentEmailError, setResentEmailError] = useState<boolean>(false);
  const handleResendVerificationEmail = async () => {
    const result = await handleSendVerificationEmail();
    if (result) {
      setResentEmailError(false);
      return navigate(`${AUTH_LOGIN}?resendVerificationEmail=true`, {
        replace: true,
      });
    }
    setResentEmailError(true);
    setResendVerificationCounter(INITIAL_RESEND_VERIFICATION_COUNTER);
  };

  if ((loading && !locale) || (firebaseLoading && !firebaseLocale)) return null;

  return (
    <>
      <div className="flex flex-col gap-2.5 w-full mb-2.5">
        {resentEmailError && (
          <Alert
            type="warning"
            testID="resent-email-error-alert"
            text={errorMsg}
          />
        )}

        {accountNotVerified ? (
          <Alert type="warning" text={locale?.emailNotVerified} />
        ) : (
          <Alert
            type="positive"
            testID="not-verified-alert"
            customContent={
              <div className="flex flex-col w-full gap-5 desktop:gap-2">
                <p className="text-base font-semibold text-alert-positive desktop:mr-[35px]">
                  {locale?.accountRegistered} {locale?.alreadyVerified}{' '}
                  <ButtonComponent
                    type="underline"
                    iconPosition="right"
                    onClick={() => navigate(AUTH_LOGIN)}
                  >
                    {locale?.loginButton}
                  </ButtonComponent>
                </p>
              </div>
            }
          />
        )}

        {linkExpired && (
          <Alert
            type="warning"
            testID="link-expired-alert"
            text={locale?.linkHasExpired}
          />
        )}
      </div>
      <div className="flex flex-col items-center desktop:justify-center px-[20px] py-[30px] desktop:px-[60px] desktop:py-[40px] gap-[30px] rounded-10 bg-white">
        <div className="flex flex-col items-start desktop:items-center p-0 gap-2.5 desktop:gap-[5px]">
          <p className="text-center text-h2 desktop:text-h1 text-dark-gray">
            {locale?.sendVerficationEmailTitle}
          </p>
          <p className="text-base text-med-gray font-semibold">
            {locale?.instructionText}
          </p>
        </div>
        <div className="flex flex-col items-center gap-2.5">
          <p className="text-center text-h4 font-bold text-med-gray">
            {locale?.emailNotReceived}
          </p>
          <div className="flex flex-col items-center gap-1">
            <ButtonComponent
              testID="resend-button"
              type="submit"
              paddingX="px-5"
              paddingY="py-[9.5px]"
              disabled={resendVerificationCounter > 0}
              onClick={handleResendVerificationEmail}
            >
              {locale?.resendButton}
            </ButtonComponent>
            <p className="text-med-gray text-center text-sm">
              {Math.floor(resendVerificationCounter / 60)}:
              {resendVerificationCounter % 60 < 10
                ? `0${resendVerificationCounter % 60}`
                : resendVerificationCounter % 60}
            </p>
          </div>
        </div>
        <hr className="flex flex-row w-full items-center h-px bg-black-blur" />
        <div className="flex flex-col font-semibold">
          <p className="text-base text-center text-med-gray">
            {locale?.yourEmailIs} {user?.email}
          </p>
          <p className="text-base text-center text-med-gray">
            {locale?.emailIncorrect}
          </p>
        </div>
      </div>
    </>
  );
};
