import { useGetPage } from 'hooks/useGetPage';
import { pageIds } from 'utilities/constants';
import { ChangeEvent } from 'react';
import { Errors } from 'hooks/useFormValidation';
import InputComponent from 'components/inputComponent';
import Alert from 'components/alertComponent';

interface PasswordChangeFormProps {
  errors: Errors;
  passwordChangeError: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const PasswordChangeForm: React.FC<PasswordChangeFormProps> = ({
  errors,
  passwordChangeError,
  onChange,
}) => {
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.MY_PROFILE,
  });

  if (loading && !locale) return null;

  return (
    <div className="flex flex-col gap-2.5">
      {passwordChangeError && (
        <div className="mb-2.5">
          <Alert type="warning" text={passwordChangeError} />
        </div>
      )}
      <div className="flex flex-col desktop:flex-row items-start">
        <label
          className="w-full desktop:w-4/12 text-base text-dark-gray font-semibold mb-2 desktop:mb-0 desktop:py-3"
          htmlFor="currentPassword"
        >
          {locale.securityAndPrivacy.currentPassword}
          <span className="text-base font-bold text-clc-red">*</span>
        </label>
        <div className="w-full desktop:w-8/12">
          <InputComponent
            type="password"
            name="currentPassword"
            onChange={onChange}
            errorStatus={!!errors.currentPassword}
            errorMsgWithIcon={errors.currentPassword}
            noMarginBottom={!errors.currentPassword}
          />
        </div>
      </div>
      <div className="flex flex-col desktop:flex-row items-start">
        <label
          className="w-full desktop:w-4/12 text-base text-dark-gray font-semibold mb-2 desktop:mb-0 desktop:py-3"
          htmlFor="newPassword"
        >
          {locale.securityAndPrivacy.newPassword}
          <span className="text-base font-bold text-clc-red">*</span>
        </label>
        <div className="w-full desktop:w-8/12">
          <InputComponent
            testID="newPassword-input"
            type="password"
            name="newPassword"
            onChange={onChange}
            errorStatus={!!errors.newPassword}
            errorMsgWithIcon={errors.newPassword}
            noMarginBottom={!errors.newPassword}
          />
        </div>
      </div>
      <div className="flex flex-col desktop:flex-row items-start">
        <label
          className="w-full desktop:w-4/12 text-base text-dark-gray font-semibold mb-2 desktop:mb-0 desktop:py-3"
          htmlFor="confirmPassword"
        >
          {locale.securityAndPrivacy.confirmPassword}
          <span className="text-base font-bold text-clc-red">*</span>
        </label>
        <div className="w-full desktop:w-8/12">
          <InputComponent
            testID="confirmPassword-input"
            type="password"
            name="confirmPassword"
            onChange={onChange}
            errorStatus={!!errors.confirmPassword}
            errorMsgWithIcon={errors.confirmPassword}
            noMarginBottom={!errors.confirmPassword}
          />
        </div>
      </div>
    </div>
  );
};
