import { Dispatch, SetStateAction } from 'react';
import { TabType } from '../../DatePicker.types';

interface MonthTabGridProps {
  title: string;
  monthLabels: string[];
  selectedMonth: number;
  setSelectedMonth: Dispatch<SetStateAction<number>>;
  setSelectionTab: Dispatch<SetStateAction<TabType>>;
}

export const MonthTab: React.FC<MonthTabGridProps> = ({
  title,
  monthLabels,
  selectedMonth,
  setSelectedMonth,
  setSelectionTab,
}) => {
  const onMonthChange = (value: number) => {
    setSelectionTab('day');
    setSelectedMonth(value);
  };

  return (
    <>
      <div className="flex justify-center font-bold mb-1.5">
        <p>{title}</p>
      </div>
      <hr />
      <div className="flex justify-center flex-wrap mt-1.5">
        {monthLabels.map((month: string, key: number) => (
          <div
            className={`flex ${selectedMonth === key ? ' selected' : ''}`}
            key={key}
            onClick={() => onMonthChange(key)}
          >
            {
              <p className="p-2 text-sm font-medium text-med-gray-2 text-base hover:bg-light-blue rounded-[5px] ">
                {month}
              </p>
            }
          </div>
        ))}
      </div>
    </>
  );
};
