import { Route, Routes } from 'react-router-dom';
import { MyProfilePage } from 'app/my-account/pages/my-profile/MyProfile';
import { HealthQuestionnaire } from '../pages/health-questionnaire/HealthQuestionnaire';
import {
  ACCOUNT_SETTINGS,
  HEALTH_QUESTIONNAIRE,
  MFA_ACCOUNT_DELETION,
  MY_MEDIA_LIBRARY,
  MY_PROFILE,
} from 'utilities/routes';
import { MultiFactorAuthVerify } from 'auth/pages/MultiFactorAuth/MultiFactorAuthVerify';
import MyMediaLibrary from '../pages/my-media-library/MyMediaLibrary';
import { AccountSettings } from '../pages/account-settings/AccountSettings';
import ProtectedSubRoute from 'components/ProtectedSubroute';
import { Roles } from '../../../firebase/interfaces';

export const MyAccountRoutes = () => {
  return (
    <Routes>
      <Route path={MY_PROFILE} element={<MyProfilePage />} />
      <Route
        path={ACCOUNT_SETTINGS}
        element={
          <ProtectedSubRoute allowedRoles={[Roles.PROVIDER]}>
            <AccountSettings />
          </ProtectedSubRoute>
        }
      />
      <Route
        path={HEALTH_QUESTIONNAIRE}
        element={
          <ProtectedSubRoute allowedRoles={[Roles.PATIENT]}>
            <HealthQuestionnaire />
          </ProtectedSubRoute>
        }
      />
      <Route
        path={MY_MEDIA_LIBRARY}
        element={
          <ProtectedSubRoute allowedRoles={[Roles.PATIENT]}>
            <MyMediaLibrary />
          </ProtectedSubRoute>
        }
      />
      <Route path={MFA_ACCOUNT_DELETION} element={<MultiFactorAuthVerify />} />
    </Routes>
  );
};
