import {
  AppointmentStatus,
  AppointmentType,
} from 'app/my-appointments/interfaces';
import { Record } from 'components/codexTable/interfaces/record.interfaces';
import { AppointmentTypeEnum } from 'graphql/generated/remote-schema-hasura';

interface MedicationType {
  id: string;
  text: string;
}

export interface ResourceLink {
  id: string;
  text: string;
  onClick: () => void;
}

export interface Medications {
  prescriptions: MedicationType[];
  overTheCounter: MedicationType[];
}

export enum ConsultationType {
  VIDEO = 'video',
  CHAT = 'chat',
  MESSAGING = 'messaging',
}

export interface ForPatientData {
  patientName: string;
  patientPicture: string;
  patientDate: string;
  consultationType: ConsultationType;
}

export interface FromProviderData {
  providerName: string;
  providerPicture: string;
  providerDate: string;
  consultationType: ConsultationType;
  providerId?: string;
}

export interface MyVisitSummaryProps extends Record {
  subject: string;
  summary: string;
  recommendations: string;
  medications: Medications;
  filesAndMedia: ResourceLink[];
  related: ResourceLink[];
  forPatientData: ForPatientData;
}

export interface MyVisitSummaryPatientProps extends Record {
  subject: string;
  summary: string;
  recommendations: string;
  filesAndMedia: Partial<ResourceLink>[];
  related: ResourceLink[];
  fromProviderData: FromProviderData;
  summaryDate: string;
  medications: MedicationTableListItem[];
}

export interface MyVisitSummaryHistoryPatientTable extends Record {
  subject: string;
  type: AppointmentTypeEnum;
  providerName: string;
  summaryDate: string;
  providerId?: string;
}

export interface MyVisitSummaryHistoryProviderTable extends Record {
  date: string;
  name: string;
  logs: string[];
  subject: string;
  type: AppointmentTypeEnum;
}

export interface PatientDropdownOption {
  id: string;
  name: string;
}

export interface MyVisitSummaryUpcomingProviderTable extends Record {
  date: string;
  provider: string;
  logs: string[];
  subject: string;
  type: AppointmentType;
}

export interface ForPatientDataWidgetLocale {
  videoConsultation: string;
  chatConsultation: string;
  messagingConsultation: string;
  altImage: string;
  forPatientData: string;
  title: string;
}

export interface FromProviderDataWidgetLocale {
  videoAppointment: string;
  chatAppointment: string;
  messagingConsultation: string;
  altImage: string;
  fromProviderData: string;
  title: string;
}

export interface ListRecord extends Record {
  link?: string;
  onClick?: () => void;
  text: string;
}

export interface MedicationListRecord extends Record {
  text: string;
}

export interface MedicationsLocale {
  medications: string;
  prescriptions: string;
  overTheCounter: string;
}

export interface MedicationsTableListLocale {
  medicationType: string;
  medicationBrand: string;
  medicationDelivery: string;
  medicationDosage: string;
  medicationFrecuency: string;
  medicationAdditionalNotes: string;
}

export interface MedicationTableListItem {
  type: string;
  brand: string;
  delivery: string;
  dosage: string;
  frecuency: string;
  additionalNotes?: string;
}

export interface HistoricAppointmentsPatientFilter {
  find?: string;
  from?: string;
  to?: string;
  provider?: string;
  status?: AppointmentStatus;
  type?: AppointmentTypeEnum;
  sort?: string;
  sortDirection?: 0 | 1;
}

export interface AppointmentsHistoryPatientFilterProps {
  onFilter?: () => void;
  filter: HistoricAppointmentsPatientFilter;
  setFilter: React.Dispatch<
    React.SetStateAction<HistoricAppointmentsPatientFilter>
  >;
}

export interface AppointmentsHistoryPatientFilterMobileProps
  extends AppointmentsHistoryPatientFilterProps {
  sortColumns: string[];
}

export interface SelectWithIdInfo {
  name: string;
  id: string;
}
