import {
  AcuitySchema,
  FirebaseConfigSchema,
  FullScriptSchema,
  HasuraConfigSchema,
  LaunchDarklySchema,
  NestConfigSchema,
  NotificationsConfigSchema,
  PersonaSchema,
  RootConfigSchema,
  StrapiConfigSchema,
  StrichConfigSchema,
  WeatherConfigSchema,
} from './interfaces';

const environments = ['development', 'test', 'production', 'staging'];
const analytics = ['firebase'];

const getCurrentEnvironment = (): string => {
  if (environments.includes(process.env.NODE_ENV ?? 'development')) {
    return process.env.NODE_ENV as string;
  }
  return 'development';
};

const getCurrentAnalyticsProviders = (): string[] => {
  const analyticsProviders = process.env.REACT_APP_ANALYTICS_PROVIDERS
    ? process.env.REACT_APP_ANALYTICS_PROVIDERS.split(',').map((provider) =>
        provider.trim(),
      )
    : [];

  const invalidProviders = analyticsProviders.filter(
    (provider) => !analytics.includes(provider),
  );

  if (invalidProviders.length > 0) {
    throw new Error(
      `Invalid analytics providers found: ${invalidProviders.join(', ')}`,
    );
  }

  return analyticsProviders;
};

const initRootConfig = (): RootConfigSchema => {
  return {
    environment: getCurrentEnvironment(),
    baseUrl: validateEnvVariable('REACT_APP_WEB_BASE_URL'),
    isTest: getCurrentEnvironment() === 'test',
    analyticsProviders: getCurrentAnalyticsProviders(),
  };
};

const initFirebaseConfig = (): FirebaseConfigSchema => {
  return {
    firebaseApiKey: validateEnvVariable('REACT_APP_FIREBASE_API_KEY'),
    firebaseAuthDomain: validateEnvVariable('REACT_APP_FIREBASE_AUTH_DOMAIN'),
    firebaseProjectId: validateEnvVariable('REACT_APP_FIREBASE_PROJECT_ID'),
    firebaseStorageBucket: validateEnvVariable(
      'REACT_APP_FIREBASE_STORAGE_BUCKET',
    ),
    firebaseMessagingSenderId: validateEnvVariable(
      'REACT_APP_FIREBASE_MESSAGING_SENDER_ID',
    ),
    firebaseAppId: validateEnvVariable('REACT_APP_FIREBASE_APP_ID'),
    firebaseMeasurementId: validateEnvVariable(
      'REACT_APP_FIREBASE_MEASUREMENT_ID',
    ),
  };
};

const initWeatherConfig = (): WeatherConfigSchema => ({
  api: validateEnvVariable('REACT_APP_WEATHER_API'),
  geoApi: validateEnvVariable('REACT_APP_WEATHER_GEO_API'),
  airApi: validateEnvVariable('REACT_APP_WEATHER_AIR_API'),
  apiKey: validateEnvVariable('REACT_APP_WEATHER_API_KEY'),
});

const initHasuraConfig = (): HasuraConfigSchema => {
  return {
    hasuraGraphQlApi: validateEnvVariable('REACT_APP_HASURA_GRAPHQL_API'),
    hasuraGraphQlWs: validateEnvVariable('REACT_APP_HASURA_GRAPHQL_WS'),
  };
};

const initStrapiConfig = (): StrapiConfigSchema => ({
  strapiGraphQlApi: validateEnvVariable('REACT_APP_STRAPI_GRAPHQL_API'),
});

const initNestConfig = (): NestConfigSchema => {
  return {
    nestApi: validateEnvVariable('REACT_APP_NEST_API'),
  };
};

const initStrichConfig = (): StrichConfigSchema => ({
  licenseKey: validateEnvVariable('REACT_APP_STRICH_LICENSE_KEY'),
});

const initLaunchDarklyConfig = (): LaunchDarklySchema => ({
  clientId: validateEnvVariable('REACT_APP_LAUNCHDARKLY_CLIENT_ID'),
});

const initPersonaConfig = (): PersonaSchema => ({
  personaTemplateId: validateEnvVariable('REACT_APP_PERSONA_TEMPLATE_ID'),
  personaEnvironmentId: validateEnvVariable('REACT_APP_PERSONA_ENVIRONMENT_ID'),
});

const initFullScriptConfig = (): FullScriptSchema => ({
  fullScriptClientId: validateEnvVariable('REACT_APP_FULLSCRIPT_CLIENT_ID'),
  fullScriptRedirectUri: validateEnvVariable(
    'REACT_APP_FULLSCRIPT_REDIRECT_URI',
  ),
  fullScriptEnv: validateEnvVariable('REACT_APP_FULLSCRIPT_ENV'),
  authorizationUrl: validateEnvVariable(
    'REACT_APP_FULLSCRIPT_AUTHORIZATION_URL',
  ),
});

const initAcuityConfig = (): AcuitySchema => ({
  acuityCalendarBaseUrl: validateEnvVariable(
    'REACT_APP_ACUITY_CALENDAR_BASE_URL',
  ),
  appointmentCodexIdFormId: validateEnvVariable(
    'REACT_APP_ACUITY_FIELD_APPOINTMENT_CODEX_ID',
  ),
});

const validateEnvVariable = (name: string): string => {
  const value = process.env[name];
  if (!value) {
    throw new Error(`Environment variable ${name} is not defined.`);
  }
  return value;
};

const initNotificationsConfig = (): NotificationsConfigSchema => {
  return {
    lookbackDays: parseInt(
      validateEnvVariable('REACT_APP_NOTIFICATION_LOOKBACK_DAYS') || '7',
      10,
    ),
  };
};

export const getNotificationsConfig = (): NotificationsConfigSchema =>
  initNotificationsConfig();

export const getRootConfig: () => RootConfigSchema = () => initRootConfig();
export const getFirebaseConfig: () => FirebaseConfigSchema = () =>
  initFirebaseConfig();
export const getWeatherConfig: () => WeatherConfigSchema = () =>
  initWeatherConfig();
export const getHasuraConfig: () => HasuraConfigSchema = () =>
  initHasuraConfig();
export const getStrapiConfig: () => StrapiConfigSchema = () =>
  initStrapiConfig();
export const getNestConfig: () => NestConfigSchema = () => initNestConfig();
export const getStrichConfig: () => StrichConfigSchema = () =>
  initStrichConfig();
export const getLaunchDarklyConfig: () => LaunchDarklySchema = () =>
  initLaunchDarklyConfig();
export const getPersonaConfig: () => PersonaSchema = () => initPersonaConfig();
export const getAcuityConfig: () => AcuitySchema = () => initAcuityConfig();
export const getFullScriptConfig: () => FullScriptSchema = () =>
  initFullScriptConfig();
