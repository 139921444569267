import { useEffect, useState, useCallback, useRef, useContext } from 'react';
import { pageIds } from 'utilities/constants';
import { useGetPage } from '../useGetPage';
import { WeatherData } from 'app/dashboard/components/WeatherWidget/interfaces';
import {
  fetchAirPollutionData,
  fetchCurrentPosition,
  fetchGeoData,
  fetchWeatherData,
  kelvinToCelsius,
  kelvinToFarenheiht,
} from './utilities';

import { AuthContext, AuthContextType } from 'auth/context/AuthContext';
import { LOCAL_STORAGE_WEATHER_ZIP } from './constants';
import { useLocalStorage } from 'hooks/useLocalStorage';
import useDate from './useDate';
import { useGetFhirPatientbyEmailQuery } from 'graphql/generated/remote-schema-hasura';

const isDaytime = (
  condition: { icon?: string },
  time: number,
  sunrise: number,
  sunset: number,
) => {
  if (condition.icon?.includes('d')) {
    return true;
  } else if (condition.icon?.includes('n')) {
    return false;
  }

  return time >= sunrise && time <= sunset;
};

const useWeatherData = () => {
  const { setItemWithUniqueKey, getItemWithUniqueKey } = useLocalStorage();
  const localZipCode = getItemWithUniqueKey(LOCAL_STORAGE_WEATHER_ZIP);

  const isFirstRender = useRef(false);
  const { user } = useContext<AuthContextType>(AuthContext);

  const [zipCode, setZipcode] = useState<string>(localZipCode || '');

  const [disableButton, setDisableButton] = useState<boolean>(true);

  const [weatherData, setWeatherData] = useState<WeatherData | null>(null);
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);

  const { loading: usersLoading } = useGetFhirPatientbyEmailQuery({
    variables: { email: user?.email || '' },
    onCompleted(data) {
      if (data?.getFHIRPatientbyEmail.users.length > 0 && !localZipCode) {
        const user = data?.getFHIRPatientbyEmail.users[0];
        user.SENSITIVE_address_information?.zipCode &&
          setZipcode(user.SENSITIVE_address_information.zipCode);
      }
    },
    fetchPolicy: 'network-only',
  });

  const { data: locale, loading: localeLoading } = useGetPage({
    locale: 'en',
    pageId: pageIds.DASHBOARD,
  });

  const { formatDate } = useDate();

  const onDisableButton = (value: boolean) => setDisableButton(value);
  const onZipCodeChange = (value: string) => setZipcode(value);

  const fetchData = useCallback(
    async (zipCode?: string) => {
      setLoading(true);

      try {
        const fetchPosition = zipCode
          ? fetchGeoData(zipCode)
          : fetchCurrentPosition();
        const data: Record<string, never> = await fetchPosition;

        const { name, country, lat, lon } = data;

        const { list } = await fetchAirPollutionData(lat, lon);
        const {
          current: { temp, uvi: uvIndex, weather, dt, sunrise, sunset },
        } = await fetchWeatherData(lat, lon);

        const tempC = kelvinToCelsius(temp);
        const tempF = kelvinToFarenheiht(temp);

        const aqIndex = list[0].main.aqi;
        const condition = weather[0];
        const isDay = isDaytime(condition, dt, sunrise, sunset);

        if (zipCode && zipCode !== localZipCode) {
          setItemWithUniqueKey(LOCAL_STORAGE_WEATHER_ZIP, zipCode);
        }

        setLoading(false);
        setWeatherData({
          tempC,
          tempF,
          isDay,
          aqIndex,
          uvIndex,
          location: {
            name,
            country,
          },
          condition,
        });
      } catch (error) {
        console.error(error);
        setLoading(false);
        setError(
          error instanceof GeolocationPositionError
            ? locale.weather.geoLocationError
            : locale.weather.invalidLocation,
        );
      }
    },
    [locale, localZipCode, setItemWithUniqueKey],
  );

  const getDate = (date = new Date()) => formatDate(date);

  const refetch = (zipCode: string) => {
    setError('');
    fetchData(zipCode);
  };

  useEffect(() => {
    if (locale && !localeLoading && !usersLoading && !isFirstRender.current) {
      isFirstRender.current = true;
      fetchData(zipCode);
    }
  }, [fetchData, locale, localeLoading, zipCode, usersLoading]);

  return {
    weatherData,
    disableButton,
    error,
    refetch,
    getDate,
    loading,
    locale,
    localeLoading,
    onDisableButton,
    onZipCodeChange,
    zipCode,
  };
};

export default useWeatherData;
