import { ReactComponent as ChevronUpIcon } from 'assets/icons/chevron-up.svg';
import IconComponent from 'components/iconComponent';
import { useRef } from 'react';

interface CollapseTabProps {
  active: boolean;
  activeIcon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  borderBottom?: boolean;
  children: React.ReactNode;
  contentBorderBottom?: boolean;
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  label: string;
  onClick: () => void;
  hiddeArrow?: boolean;
  inverted?: boolean;
  standalone?: boolean;
}

const CollapseTab: React.FC<CollapseTabProps> = ({
  active,
  activeIcon,
  borderBottom = true,
  children,
  contentBorderBottom = true,
  icon,
  label,
  onClick,
  hiddeArrow = false,
  inverted = false,
  standalone = false,
}) => {
  const childrenDivRef = useRef<HTMLDivElement>(null);
  return (
    <div
      className={`flex flex-col max-h-full ${
        active || standalone ? 'overflow-scroll' : ''
      } ${active ? 'grow' : ''} ${standalone ? 'h-full' : ''}`}
    >
      <div
        className={`flex flex-row justify-between items-center h-[55px] min-h-[55px] px-5 cursor-pointer relative ${
          borderBottom ? 'border-b' : ''
        } ${inverted ? 'bg-clc-blue' : ''}`}
        onClick={onClick}
      >
        <div
          className={`w-[6px] h-full absolute top-0 left-0 bg-clc-blue ${
            active ? 'opacity-100' : 'opacity-0'
          } transition-opacity duration-700 ease-in-out`}
        />
        <div className="flex flex-row items-center gap-2.5">
          <div className="relative w-[17px] h-5">
            <div
              className={`${
                active ? 'opacity-0' : 'opacity-100'
              } transition-all duration-700 ease-in-out absolute top-0 left-0`}
            >
              <IconComponent
                width="w-[17px]"
                height="h-5"
                icon={icon}
                fill="fill-charcoal-gray"
              />
            </div>

            <div
              className={`${
                !active ? 'opacity-0' : 'opacity-100'
              } transition-opacity duration-700 ease-in-out absolute top-0 left-0`}
            >
              <IconComponent
                width="w-[17px]"
                height="h-5"
                icon={activeIcon}
                fill="fill-clc-blue"
              />
            </div>
          </div>
          <p
            className={`text-h5 font-semibold ${
              active
                ? inverted
                  ? 'text-white'
                  : 'text-clc-blue'
                : 'text-charcoal-gray'
            } transition-colors duration-700 ease-in-out`}
          >
            {label}
          </p>
        </div>
        {!hiddeArrow && (
          <ChevronUpIcon
            className={`w-4 h-3  cursor-pointer ${
              active
                ? 'rotate-0 fill-clc-blue'
                : 'rotate-180 fill-charcoal-gray'
            } transition duration-700 ease-in-out`}
          />
        )}
      </div>

      <div
        className={`${contentBorderBottom ? 'border-b' : ''} ${
          active ? 'grow' : ''
        } overflow-hidden transition-[height] duration-700 ease-in-out`}
        style={{
          height: !active ? '0' : undefined,
        }}
      >
        <div ref={childrenDivRef} className="p-5 h-full overflow-auto">
          {children}
        </div>
      </div>
    </div>
  );
};

export default CollapseTab;
