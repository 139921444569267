import React from 'react';
import { twMerge } from 'tailwind-merge';

interface IconComponentProps {
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  width?: string;
  height?: string;
  fill: string;
  stroke?: string;
  hoverFill?: string;
  hoverStroke?: string;
}

const IconComponent: React.FC<IconComponentProps> = ({
  icon: IconComponent,
  width = 'w-auto desktop:w-6',
  height = 'h-auto desktop:h-6',
  fill,
  stroke,
  hoverFill,
  hoverStroke,
}) => {
  return (
    <IconComponent
      className={twMerge(
        `${width} ${height} flex-shrink-0 ${fill} ${stroke} hover:${hoverFill} hover:${hoverStroke}`,
      )}
    />
  );
};

export default IconComponent;
