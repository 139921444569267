import { Navigate, Route, Routes } from 'react-router-dom';
import {
  BASE_ROUTE,
  FIND_A_PROVIDER,
  NOT_FOUND_ROUTE,
  PAST_PROVIDERS,
  PROVIDER_BIO,
} from 'utilities/routes';
import { MyProviders } from '../pages/MyProviders';
import { PastProviders } from '../pages/PastProviders';
import { FindAProvider } from '../pages/FindAProvider';
import { ProviderBio } from '../pages/ProviderBio';
import ProtectedRoutesModal from 'components/ProtectedRoutesModal/ProtectedRoutesModal';

export const ProvidersViewSelectionRoutes = () => {
  return (
    <Routes>
      <Route path={BASE_ROUTE} element={<MyProviders />} />
      <Route path={PAST_PROVIDERS} element={<PastProviders />} />
      <Route
        path={FIND_A_PROVIDER}
        element={
          <ProtectedRoutesModal>
            <FindAProvider />
          </ProtectedRoutesModal>
        }
      />
      <Route path={PROVIDER_BIO} element={<ProviderBio />} />
      <Route path={NOT_FOUND_ROUTE} element={<Navigate to={BASE_ROUTE} />} />
    </Routes>
  );
};
