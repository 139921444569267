import InputComponent from 'components/inputComponent';
import {
  DynamicQuestionnaireCurrentIndexOfSection,
  HandleOnInputChangeType,
} from '../interfaces/dynamicQuestionnaire.interface';
import {
  DynamicQuestionResponseSection,
  QuestionnaireResponseInterface,
  childrenInputResponseTypes,
  questionStatusI,
  questionnaire_questions_question,
  RequiredIdsList,
} from '../interfaces/dynamicQuestionnaireResponse.interface';
import { getUiControlType } from '../utils/utils';
import DynamicQuestionnaireMixedInputs from '../components/dynamicQuestionnaireMixedInputs';
import { Dispatch, ReactNode, SetStateAction } from 'react';

interface DNAQuestionnaireModule {
  questionList: questionnaire_questions_question[];
  questionnaireData: QuestionnaireResponseInterface[];
  currentIndexSection: DynamicQuestionnaireCurrentIndexOfSection;
  radioCheckedState: QuestionnaireResponseInterface[];
  checkboxesCheckedState: QuestionnaireResponseInterface[];
  section: DynamicQuestionResponseSection;
  onChange: HandleOnInputChangeType;
  handleCheckboxToggle: (
    answer: string,
    questionID: number | undefined,
    questionParentID?: number,
    childrenOriginalQuestionID?: string,
  ) => void;
  setRadioCheckedState: Dispatch<
    SetStateAction<QuestionnaireResponseInterface[]>
  >;
  setCheckboxesCheckedState?: Dispatch<
    SetStateAction<QuestionnaireResponseInterface[]>
  >;
  matchSelectedRadio: (
    question: number,
    stringToMatch: string,
  ) => QuestionnaireResponseInterface | undefined;
  handleRadioClick: (question: number, value: string | undefined) => void;
  nestedQuestionList: questionStatusI[];
  renderNestedQuestions: (
    questionStatus: questionStatusI,
    question: questionnaire_questions_question,
    questionParentID: number,
    childrenOriginalQuestionID: string | null | undefined,
    handleCheckboxToggle: (
      answer: string,
      questionID: number | undefined,
      questionParentID?: number,
      childrenOriginalQuestionID?: string,
    ) => void,
    clearCheckboxState: (questionID: number) => void,
    currentSection: DynamicQuestionResponseSection,
    radioCheckedState?: QuestionnaireResponseInterface[],
    setRadioCheckedState?: Dispatch<
      SetStateAction<QuestionnaireResponseInterface[]>
    >,
    checkboxesCheckedState?: QuestionnaireResponseInterface[],
    setCheckboxesCheckedState?: Dispatch<
      SetStateAction<QuestionnaireResponseInterface[]>
    >,
    textFieldResponses?: QuestionnaireResponseInterface[],
    setTextFieldResponses?: Dispatch<
      SetStateAction<QuestionnaireResponseInterface[]>
    >,
  ) => ReactNode;
  clearCheckboxState: (questionID: number) => void;
  textFieldResponses: QuestionnaireResponseInterface[];
  setTextFieldResponses: Dispatch<
    SetStateAction<QuestionnaireResponseInterface[]>
  >;
  requiredQuestionsIds: RequiredIdsList;
  getIncompleteMainQuestion: () => string[];
  submitWasTriggered: boolean;
}

const DNAQuestionnaireModule: React.FC<DNAQuestionnaireModule> = ({
  questionList,
  questionnaireData,
  currentIndexSection,
  radioCheckedState,
  checkboxesCheckedState,
  section,
  onChange,
  handleCheckboxToggle,
  setRadioCheckedState,
  setCheckboxesCheckedState,
  matchSelectedRadio,
  handleRadioClick,
  nestedQuestionList,
  renderNestedQuestions,
  clearCheckboxState,
  textFieldResponses,
  setTextFieldResponses,
  requiredQuestionsIds,
  getIncompleteMainQuestion,
  submitWasTriggered,
}) => {
  const isRequiredQuestion = (questionId: string): boolean => {
    const isRequired = requiredQuestionsIds.find((id) => id === questionId);
    return Boolean(isRequired);
  };

  const missingMainQuestionIds = getIncompleteMainQuestion();

  return (
    <>
      <div className="mt-5 desktop:mx-[120px]">
        {questionList.map((question) => {
          let answerChecked: childrenInputResponseTypes = undefined;

          questionnaireData.forEach((value) => {
            if (
              value.questionID ===
                question.questionnaire_questions_question.id &&
              currentIndexSection.index === value.currentIndexOfSection
            ) {
              answerChecked = value.response;
            }
          });

          return (
            <div key={question.questionnaire_questions_question.id}>
              <div className="mb-2.5">
                <p
                  className={`${
                    missingMainQuestionIds.includes(
                      question.questionnaire_questions_question.id.toString(),
                    ) && submitWasTriggered
                      ? 'text-clc-red'
                      : 'text-dark-gray'
                  } font-semibold `}
                >
                  {question.questionnaire_questions_question.text}
                  {isRequiredQuestion(
                    question.questionnaire_questions_question.id.toString(),
                  ) && (
                    <span className="text-base font-bold text-clc-red"> *</span>
                  )}
                </p>
                {question.questionnaire_questions_question.subtitle && (
                  <p className="italic text-med-gray">
                    {question.questionnaire_questions_question.subtitle}
                  </p>
                )}
              </div>
              <div className="desktop:mb-3">
                {question.questionnaire_questions_question.ui_control &&
                question.questionnaire_questions_question.ui_control ===
                  'MIX' &&
                radioCheckedState &&
                checkboxesCheckedState ? (
                  <div className="flex flex-col">
                    <DynamicQuestionnaireMixedInputs
                      question={question}
                      section={section}
                      onChange={onChange}
                      handleCheckboxToggle={handleCheckboxToggle}
                      answerChecked={answerChecked}
                      radioCheckedState={radioCheckedState}
                      setRadioCheckedState={setRadioCheckedState}
                      checkboxesCheckedState={checkboxesCheckedState}
                      setCheckboxesCheckedState={setCheckboxesCheckedState}
                      isRequiredQuestion={isRequiredQuestion(
                        question.questionnaire_questions_question.id.toString(),
                      )}
                    />
                  </div>
                ) : (
                  question.questionnaire_questions_question.answers &&
                  question.questionnaire_questions_question.answers.map(
                    (answer) => {
                      return (
                        <div key={answer} className="flex flex-col">
                          <InputComponent
                            key={answer}
                            type={getUiControlType(
                              question.questionnaire_questions_question
                                .ui_control,
                            )}
                            radioInputProps={{
                              radioError: null,
                              radioInputValue: answer,
                              radioInputLabel: answer,
                              radioInputCheckedValue: matchSelectedRadio(
                                question.questionnaire_questions_question.id,
                                answer,
                              )
                                ? answer
                                : undefined,
                              onRadioClick: () =>
                                handleRadioClick(
                                  question.questionnaire_questions_question.id,
                                  answer,
                                ),
                            }}
                            checkboxProps={{
                              checkboxLabel: answer,
                              checked:
                                checkboxesCheckedState?.find(
                                  (checkbox: QuestionnaireResponseInterface) =>
                                    checkbox.response === answer &&
                                    checkbox.section === section.name &&
                                    checkbox.questionID ===
                                      question.questionnaire_questions_question
                                        .id,
                                )?.checked || false,
                              onCheckboxChange: () => {
                                handleCheckboxToggle(
                                  answer,
                                  question.questionnaire_questions_question.id,
                                );
                              },
                              bottomMargin: true,
                              onCheckboxChildrenInputChange: (e) => {
                                onChange(
                                  answer,
                                  question.questionnaire_questions_question.id,
                                  e.target.value,
                                  undefined,
                                  question.questionnaire_questions_question
                                    .ui_control,
                                );
                              },
                            }}
                          />
                        </div>
                      );
                    },
                  )
                )}
              </div>

              <div className="desktop:pl-10 pb-10">
                {nestedQuestionList.map((questionStatus, key) => (
                  <div key={key}>
                    {renderNestedQuestions(
                      questionStatus,
                      question,
                      question.questionnaire_questions_question.id,
                      questionStatus.dependency,
                      handleCheckboxToggle,
                      clearCheckboxState,
                      section,
                      radioCheckedState,
                      setRadioCheckedState,
                      checkboxesCheckedState,
                      setCheckboxesCheckedState,
                      textFieldResponses,
                      setTextFieldResponses,
                    )}
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default DNAQuestionnaireModule;
