import { Navigate, Route, Routes } from 'react-router-dom';
import {
  BASE_ROUTE,
  HORMONE_QUESTIONNAIRE,
  QUESTIONNAIRE,
  REGISTER_TEST_KIT,
  SELECT_A_PROVIDER,
  TEST_KITS,
  TEST_KITS_MANAGEMENT_TEST_KITS,
} from 'utilities/routes';
import { RegisterTestKit } from '../pages/RegisterTestKit';
import { Questionnaire } from '../pages/Questionnaire';
import { HormoneQuestionnaire } from '../pages/HormoneQuestionnaire';
import ZrtSelectAProvider from 'app/manage-test-kits/pages/ZrtSelectAProvider';
import { TestKitsBoth } from 'app/manage-test-kits/pages/TestKitsBoth';
import ProtectedRoutesModal from 'components/ProtectedRoutesModal/ProtectedRoutesModal';

export const ManageTestKitsRoutes = () => {
  return (
    <Routes>
      <Route
        path={BASE_ROUTE}
        element={<Navigate to={TEST_KITS_MANAGEMENT_TEST_KITS} />}
      />
      <Route path={TEST_KITS} element={<TestKitsBoth />} />
      <Route
        path={REGISTER_TEST_KIT}
        element={
          <ProtectedRoutesModal>
            <RegisterTestKit />
          </ProtectedRoutesModal>
        }
      />
      <Route
        path={QUESTIONNAIRE}
        element={
          <ProtectedRoutesModal>
            <Questionnaire />
          </ProtectedRoutesModal>
        }
      />
      <Route
        path={SELECT_A_PROVIDER}
        element={
          <ProtectedRoutesModal>
            <ZrtSelectAProvider />
          </ProtectedRoutesModal>
        }
      />
      <Route
        path={HORMONE_QUESTIONNAIRE}
        element={
          <ProtectedRoutesModal>
            <HormoneQuestionnaire />
          </ProtectedRoutesModal>
        }
      />

      <Route
        path="/*"
        element={<Navigate to={TEST_KITS_MANAGEMENT_TEST_KITS} />}
      />
    </Routes>
  );
};
