import { pageIds } from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';
import { NotificationsWidget } from '../components/NotificationsWidget';
import { ShopsWidget } from '../components/ShopsWidget';
import { useModalParams } from 'components/modal/useModalManager';
import { WeatherWidget } from '../components/WeatherWidget/WeatherWidget';
import { RegisterTestKitBanner } from '../components/RegisterTestKitBanner';
import { AddMedicationModal } from 'app/my-appointments/components/AddMedicationModal';
import DermscoreWidget from '../components/DermscoreWidget';
import Greetings from '../components/Greetings';
import UVInfo from '../components/WeatherWidget/UVInfo';
import AQInfo from '../components/WeatherWidget/AQInfo';
import Loader from 'components/loaderComponent';
import MyVisitSummaryModal from 'app/my-appointments/components/MyVisitSummaryModal';
import MyVisitSummaryModalPatient from '../../appointments/components/MyVisitSummaryModalPatient';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CodexFeatureFlags } from 'utilities/interfaces';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import Alert from 'components/alertComponent';
import { MedicationHistoryDetailView } from 'components/medicationHistoryDetailView/MedicationHistoryDetailView';
import { UpcomingAppointmentsCard } from 'app/my-appointments/components/upcomingAppointments/UpcomingAppointmentCard';
import { interpolateVariables, scrollToTop } from 'utilities/functions';
import { TEST_KITS_MANAGEMENT_TEST_KITS } from 'utilities/routes';
import { Test_Types_Enum } from 'graphql/generated/hasura';
import { MyAppointmentsLocaleProvider } from 'app/my-appointments/pages/MyAppointments';
import { NewDermScoreScan } from 'app/my-skin/components/newDermScoreScan';
import { ScanQr } from 'app/my-skin/components/ScanQr';
import { ResizePhoto } from 'app/my-skin/components/resizePhoto';
import { DermScoreResults } from 'app/my-skin/components/dermScoreResults';
import { AnalyzingPhoto } from 'app/my-skin/components/analyzingPhoto';

export const PatientDashboardPage = () => {
  const navigate = useNavigate();
  const [showZrtestKitAlertText, setShowZrtTestKitAlertText] = useState(false);
  const [zrtTestKitAlertText, setZrtTestKitAlertText] = useState<string>('');

  const location = useLocation();
  const { isOpen: isUVIOpen } = useModalParams('uvi-info');
  const { isOpen: isAQIOpen } = useModalParams('aqi-info');
  const { isOpen: isMyVisitSummaryOpen } = useModalParams(
    'my-visit-summary-testing',
  );
  const { isOpen: isAddMoreMedicationOpen } = useModalParams(
    'add-more-medications-testing',
  );

  const { isOpen: isMyVisitSummaryPatientOpen } = useModalParams(
    'my-visit-patient-summary-testing',
  );

  const { isOpen: isMedicationHistoryDetailViewOpen } = useModalParams(
    'medication-history-detail-view-testing',
  );

  const { isOpen: isScanQrModalOpen } = useModalParams('scan-qr');
  const { isOpen: isNewDermscorScanModalOpen } = useModalParams('new-scan');
  const { isOpen: isResizePhotoModalOpen } = useModalParams('resize-photo');
  const { isOpen: isDermscoreResultModalOpen } = useModalParams('scan-results');
  const { isOpen: isAnalyzingPhotoModalOpen } = useModalParams('analize-photo');

  const {
    myvisitSummaryModalDisabledFlag,
    medicationHistoryDetailsModalDisabled,
    patientDashboardUpcomingAppointmentDisabled,
  } = useFlags<CodexFeatureFlags>();
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.DASHBOARD,
  });

  const { data: localeMyAppointments, loading: loadingMyAppointments } =
    useGetPage({
      locale: 'en',
      pageId: pageIds.MY_APPOINTMENTS,
    });

  const zrtTestTypeToText = useMemo(() => {
    return new Map<string, string>([
      [Test_Types_Enum.ZrtHeavyMetals, locale?.heavyMetals],
      [Test_Types_Enum.ZrtHormone, locale?.hormone],
      [Test_Types_Enum.ZrtNeurotransmitters, locale?.neurotransmitter],
      [Test_Types_Enum.ZrtInflammatory, locale?.inflammatory],
    ]);
  }, [
    locale?.heavyMetals,
    locale?.hormone,
    locale?.neurotransmitter,
    locale?.inflammatory,
  ]);

  useEffect(() => {
    const isZrtAlert = location.state?.zrtTestkitAlert;
    const zrtTestType: Test_Types_Enum | null | undefined =
      location?.state?.testType;
    if (isZrtAlert && zrtTestType) {
      scrollToTop();
      setShowZrtTestKitAlertText(true);
      setZrtTestKitAlertText(
        interpolateVariables(locale?.zrtTestKitRegistrationAlert, {
          test_type: zrtTestTypeToText.get(zrtTestType) || '',
        }),
      );
    }
  }, [
    locale?.hormoneTestKitRegistrationAlert,
    location.state,
    locale?.zrtTestKitRegistrationAlert,
    zrtTestTypeToText,
  ]);

  if (loading || !locale || loadingMyAppointments || !localeMyAppointments)
    return <Loader />;

  if (isMyVisitSummaryOpen && !myvisitSummaryModalDisabledFlag) {
    return <MyVisitSummaryModal />;
  }

  if (isMyVisitSummaryPatientOpen && !myvisitSummaryModalDisabledFlag) {
    return <MyVisitSummaryModalPatient />;
  }

  if (isAddMoreMedicationOpen) {
    return <AddMedicationModal />;
  }

  if (
    !medicationHistoryDetailsModalDisabled &&
    isMedicationHistoryDetailViewOpen
  ) {
    return (
      <MedicationHistoryDetailView
        // TODO add proper handling - COD-2435
        medicationRequestLists={[]}
      />
    );
  }

  return (
    <>
      {isNewDermscorScanModalOpen && <NewDermScoreScan />}
      {isScanQrModalOpen && <ScanQr />}
      {isResizePhotoModalOpen && <ResizePhoto />}
      {isDermscoreResultModalOpen && <DermScoreResults />}
      {isAnalyzingPhotoModalOpen && <AnalyzingPhoto />}
      {isAQIOpen && <AQInfo locale={locale.weather} />}
      {isUVIOpen && <UVInfo locale={locale.weather} />}
      {showZrtestKitAlertText && (
        <Alert
          isNotification={true}
          type="positive"
          text={zrtTestKitAlertText}
          actionLabel={locale?.viewTestKiTHistory}
          onActionClick={() => navigate(TEST_KITS_MANAGEMENT_TEST_KITS)}
        />
      )}
      <div className="flex flex-col" data-testid="dashboard-page">
        <div
          className="flex flex-row w-full mb-6"
          data-testid="verified-alert-container"
        />
        <div className="grid grid-cols-12 gap-[30px] px-7">
          <Greetings />
          <div className="col-span-12 desktop:col-span-9">
            <div className="flex flex-col gap-[30px]">
              <div className="grid desktop:hidden">
                <RegisterTestKitBanner />
              </div>
              <WeatherWidget />
              {!patientDashboardUpcomingAppointmentDisabled && (
                <MyAppointmentsLocaleProvider
                  pageId={pageIds.MY_APPOINTMENTS}
                  locale="en"
                >
                  <UpcomingAppointmentsCard
                    disableLoadMore={true}
                    isDashboardView={true}
                    locale={localeMyAppointments}
                  />
                </MyAppointmentsLocaleProvider>
              )}

              <DermscoreWidget />
            </div>
          </div>
          <div className="col-span-12 desktop:grid desktop:col-span-3 desktop:gap-[30px]">
            <div className="hidden desktop:grid">
              <RegisterTestKitBanner />
            </div>
            <div className="hidden desktop:grid">
              <NotificationsWidget />
            </div>
            <ShopsWidget />
          </div>
        </div>
      </div>
    </>
  );
};
