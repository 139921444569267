import { useScanImage } from 'hooks/useScanImage';
import { useEffect, useState } from 'react';
import Thumbnail from 'assets/images/thumbnail.svg';
import { MiniLoaderComponent } from './DermscoreLoaderComponent';

interface ScanImageProps {
  className: string;
  imageId: string;
  isCarousel?: boolean;
  objectFit?: string;
  patientId?: string | null;
}

const ScanImage: React.FC<ScanImageProps> = ({
  className,
  imageId,
  isCarousel = false,
  objectFit = 'object-contain',
  patientId = null,
}) => {
  const { fileUrl, setChangeImage } = useScanImage(imageId, patientId);
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    if (isCarousel) {
      setChangeImage(true);
    }
  }, [imageId, isCarousel, setChangeImage]);

  return (
    <div className={className}>
      {isLoading && (
        <div className="w-full h-full relative">
          <img
            className="w-full h-full object-cover opacity-50"
            src={Thumbnail}
            alt="Default Image"
          />
          <div className="absolute top-0 left-0 right-0 bottom-0">
            <MiniLoaderComponent />
          </div>
        </div>
      )}
      <img
        className={`w-full h-full ${objectFit} ${isLoading ? 'hidden' : ''}`}
        src={fileUrl}
        onLoad={handleImageLoad}
        alt="Scan result"
      />
    </div>
  );
};

export default ScanImage;
