import {
  Dnavisit_Test_Statuses_Enum,
  Test_Types_Enum,
} from 'graphql/generated/hasura';
import {
  FEDEX_TRACKING_NO_TRACKING_NUMBER,
  FEDEX_TRACKING_URL,
  USPS_TRACKING_URL,
  pageIds,
} from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';
import { TestKitsTableData } from '../interfaces';
import {
  MY_APPOINTMENTS_SCHEDULE_AN_APPOINTMENT,
  SCHEDULE_DNA_VISIT,
  VISIT_SUMMARY,
} from 'utilities/routes';
import { useNavigate } from 'react-router-dom';
import DNATestKitActions from '../../DNATestKitActions';
import ButtonComponent from 'components/button/buttonComponent';
import React from 'react';

interface DNATestKitCardProps {
  data: TestKitsTableData;
  onLoadComplete: () => void;
}

const DNATestKitCard: React.FC<DNATestKitCardProps> = ({
  data,
  onLoadComplete,
}) => {
  const navigate = useNavigate();

  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.TEST_KITS_AND_RESULTS,
  });

  const handleScheduleAppointmentClick = () => {
    navigate(SCHEDULE_DNA_VISIT);
  };
  const handleSchedulZrtAppointmentClick = (
    providerAcuityCalendar: string,
    providerAcuityOwnderId: string,
    providerCodexId: string,
    diagnosticReportId: string,
  ) => {
    navigate(MY_APPOINTMENTS_SCHEDULE_AN_APPOINTMENT, {
      state: {
        codexProviderId: providerCodexId,
        calendarId: providerAcuityCalendar,
        ownerId: providerAcuityOwnderId,
        diagnosticReportId,
      },
    });
  };

  const handleTrackPackageClick = (trackingNumber?: string | null) => {
    window.open(
      `${USPS_TRACKING_URL}${trackingNumber}`,
      '_blank',
      'noreferrer',
    );
  };
  const handleZrtTrackPackageClick = (trackingNumber?: string | null) => {
    window.open(
      `${
        trackingNumber ? FEDEX_TRACKING_URL : FEDEX_TRACKING_NO_TRACKING_NUMBER
      }${trackingNumber}`,
      '_blank',
      'noreferrer',
    );
  };

  const renderTestKitStatusText = (
    value: string | number | Dnavisit_Test_Statuses_Enum,
  ) => {
    switch (value) {
      case Dnavisit_Test_Statuses_Enum.KitNotReceived:
      case Dnavisit_Test_Statuses_Enum.ProviderPending:
        return (
          <span className="text-clc-red border rounded-[25px] border-clc-red px-2.5 font-bold text-h7">
            {value === Dnavisit_Test_Statuses_Enum.KitNotReceived
              ? locale.kitNotReceived
              : locale.kitProviderPending}
          </span>
        );

      case Dnavisit_Test_Statuses_Enum.ReceivedByLab:
      case Dnavisit_Test_Statuses_Enum.ResultsWithProvider:
        return (
          <span className="bg-yellow border rounded-[25px] px-2.5 font-bold text-h7">
            {value === Dnavisit_Test_Statuses_Enum.ReceivedByLab
              ? locale.kitReceivedByLab
              : locale.kitResultsWithProvider}
          </span>
        );

      case Dnavisit_Test_Statuses_Enum.AppointmentScheduled:
        return (
          <span className="bg-yellow border rounded-[25px] px-2.5 font-bold text-h7">
            {locale.kitAppointmentScheduled}
          </span>
        );

      case Dnavisit_Test_Statuses_Enum.Complete:
        return (
          <span className="text-alert-positive border rounded-[25px] border-alert-positive px-2.5 font-bold text-h7">
            {locale.kitComplete}
          </span>
        );

      default:
        return <span className="text-med-gray">{value}</span>;
    }
  };

  const renderActionsByTestKitStatus = (
    status: Dnavisit_Test_Statuses_Enum,
    trackingNumber: string | null | undefined,
    patientPdfId: string | null | undefined,
    testType: Test_Types_Enum,
    providerAcuitycalendarId: string,
    providerAcuityOwnerId: string,
    providerCodexId: string,
    diagnosticReportId: string,
  ) => {
    switch (status) {
      case Dnavisit_Test_Statuses_Enum.KitNotReceived:
        switch (testType) {
          case Test_Types_Enum.DnaSkin:
          case Test_Types_Enum.DnaVitamin:
            return (
              <ButtonComponent
                className="bg-transparent text-clc-blue desktop:w-full overflow-ellipsis overflow-hidden"
                onClick={() => handleTrackPackageClick(trackingNumber)}
              >
                {locale.trackPackage}
              </ButtonComponent>
            );

          case Test_Types_Enum.ZrtHeavyMetals:
          case Test_Types_Enum.ZrtHormone:
          case Test_Types_Enum.ZrtInflammatory:
          case Test_Types_Enum.ZrtNeurotransmitters:
            return (
              <ButtonComponent
                className="bg-transparent text-clc-blue desktop:w-full overflow-ellipsis overflow-hidden"
                onClick={() => handleZrtTrackPackageClick(trackingNumber)}
              >
                {locale.trackPackage}
              </ButtonComponent>
            );

          default:
            return '';
        }

      case Dnavisit_Test_Statuses_Enum.ResultsWithProvider:
        switch (testType) {
          case Test_Types_Enum.DnaSkin:
          case Test_Types_Enum.DnaVitamin:
            return (
              <ButtonComponent
                className="bg-transparent text-clc-blue desktop:w-full overflow-ellipsis overflow-hidden"
                onClick={handleScheduleAppointmentClick}
              >
                {locale.scheduleAppointment}
              </ButtonComponent>
            );

          case Test_Types_Enum.ZrtHeavyMetals:
          case Test_Types_Enum.ZrtHormone:
          case Test_Types_Enum.ZrtInflammatory:
          case Test_Types_Enum.ZrtNeurotransmitters:
            return (
              <ButtonComponent
                className="bg-transparent text-clc-blue desktop:w-full overflow-ellipsis overflow-hidden"
                onClick={() =>
                  handleSchedulZrtAppointmentClick(
                    providerAcuitycalendarId,
                    providerAcuityOwnerId,
                    providerCodexId,
                    diagnosticReportId,
                  )
                }
              >
                {locale.scheduleAppointment}
              </ButtonComponent>
            );

          default:
            return 'default';
        }

      case Dnavisit_Test_Statuses_Enum.AppointmentScheduled:
        return (
          <ButtonComponent
            className="bg-transparent text-clc-blue desktop:w-full overflow-ellipsis overflow-hidden"
            onClick={handleScheduleAppointmentClick}
          >
            {locale.reScheduleAppointment}
          </ButtonComponent>
        );

      case Dnavisit_Test_Statuses_Enum.Complete:
        if (patientPdfId)
          return (
            <DNATestKitActions
              patientPdfId={patientPdfId}
              onLoadComplete={() => onLoadComplete()}
            />
          );
        return null;

      default:
        return null;
    }
  };

  const mapTestType = (testType: Test_Types_Enum) => {
    switch (testType) {
      case Test_Types_Enum.DnaSkin:
        return locale.dnaTestTestType;

      case Test_Types_Enum.DnaVitamin:
        return locale.dnaVitaminTestType;

      case Test_Types_Enum.Skintelligent:
        return locale.skintelligentTestType;

      case Test_Types_Enum.ZrtHeavyMetals:
        return locale.zrtHeavyMetalsTestType;

      case Test_Types_Enum.ZrtHormone:
        return locale.zrtHormoneTestType;

      case Test_Types_Enum.ZrtInflammatory:
        return locale.zrtInflammatoryTestType;

      case Test_Types_Enum.ZrtNeurotransmitters:
        return locale.zrtNeurotransmittersTestType;

      default:
        return locale.dnaTestTestType;
    }
  };

  const handleViewMyVisitSummaryClicked = () => {
    navigate(VISIT_SUMMARY, {
      state: {
        fileId: data.patientVisitSummaryPdfId,
      },
    });
  };

  if (loading && !locale) return null;

  return (
    <div className="py-5 mt-[50px] flex flex-col border-b-[1px] border-gray-opacity-15 gap-5">
      <div className="flex flex-col">
        <p className={'text-base text-dark-gray font-bold w-full'}>
          {locale.dateAndTimeTableHeader}:
        </p>
        <div className="mt-1 text-med-gray">{data.date}</div>
      </div>
      <div className="flex flex-col">
        <p className={'text-base text-dark-gray font-bold w-full'}>
          {locale.testTypeTableHeader}:
        </p>
        <div className="mt-1 text-med-gray">
          {mapTestType(data['test type'])}
        </div>
      </div>
      <div className="flex flex-col">
        <p className={'text-base text-dark-gray font-bold w-full'}>
          {locale.swabCodeTableHeader}:
        </p>
        <div className="mt-1 text-med-gray">{data.swabCode}</div>
      </div>
      <div className="flex flex-col">
        <p className={'text-base text-dark-gray font-bold w-full'}>
          {locale.providerTableHeader}:
        </p>
        <div className="mt-1 text-med-gray">{data.provider}</div>
      </div>
      <div className="flex flex-col">
        <p className={'text-base text-dark-gray font-bold w-full'}>
          {locale.statusTableHeader}:
        </p>
        <div className="mt-1 text-med-gray">
          {renderTestKitStatusText(data.status)}
        </div>
      </div>
      {data.patientVisitSummaryPdfId && (
        <div className="flex flex-col">
          <p className={'text-base text-dark-gray font-bold w-full'}>
            {locale.visitSummaryTableHeader}
          </p>
          <span
            className="mt-1 text-clc-blue font-bold"
            onClick={handleViewMyVisitSummaryClicked}
          >
            {locale.myVisitSummaryTableBody}
          </span>
        </div>
      )}
      {renderActionsByTestKitStatus(
        data.status,
        data.trackingNumber,
        data.patientPdfId,
        data['test type'],
        data.providerAcuityCalendarId || '',
        data.providerAcuityOwnerId || '',
        data.providerCodexId,
        data.fhirId,
      )}
    </div>
  );
};

export default DNATestKitCard;
