import { ModalButtonProps } from 'components/modal/modal.interfaces';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import {
  genericActionsIds,
  pageIds,
  PARAM_MODALS_IDENTIFIERS,
} from 'utilities/constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Modal from 'components/modal/modalComponent';
import AlertComponent, { AlertType } from 'components/alertComponent';
import ButtonComponent from 'components/button/buttonComponent';
import { Appointment } from 'components/cancelAppointmentModal/components/Appointment';
import {
  DASHBOARD,
  MY_APPOINTMENTS,
  MY_APPOINTMENTS_SCHEDULE_AN_APPOINTMENT,
} from 'utilities/routes';
import { useGetPage } from 'hooks/useGetPage';
import {
  AppointmentTypeEnum,
  useGetAppointmentByCodexAppointmentIdQuery,
  useGetAppointmentRescheduleLinkQuery,
  useGetProviderInfoByProviderCodexIdLazyQuery,
} from 'graphql/generated/remote-schema-hasura';

import {
  appointmentTypeFromServiceType,
  formatDateForAppointment,
} from 'utilities/functions';
import Loader from 'components/loaderComponent';
import { useGetSignedUrlFromStorage } from 'hooks/useGetSignedUrlFromStorage';
import { useConfirmationModal } from 'hooks/useConfirmationModal';

export const EditAppointmentModal = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { data: editAppointment, loading: editAppointmentLoading } = useGetPage(
    {
      locale: 'en',
      pageId: pageIds.EDIT_APPOINTMENT,
    },
  );

  const openConfirmationModal = useConfirmationModal();

  const appointmentId = location.state?.appointmentId;
  const { data: appointmentRescheduleLinkData } =
    useGetAppointmentRescheduleLinkQuery({
      variables: { appointmentCodexId: appointmentId },
      skip: !appointmentId,
    });
  const appointmentRescheduleLink =
    appointmentRescheduleLinkData?.getAppointmentRescheduleLink?.rescheduleLink;

  const [getProviderInfo, { loading: providerLoading, data: providerData }] =
    useGetProviderInfoByProviderCodexIdLazyQuery();

  const provider = providerData?.getFHIRProviderbyId.providers[0];
  const providerCodexId = provider?.id;
  const photoUrl = provider?.SENSITIVE_profile_picture_id;

  const signedProfileUrl = useGetSignedUrlFromStorage(
    photoUrl ?? undefined,
    providerCodexId ?? undefined,
  );

  const { data: appointmentData, loading: appointmentLoading } =
    useGetAppointmentByCodexAppointmentIdQuery({
      variables: { appointmentCodexId: appointmentId },
      skip: !appointmentId,
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        if (data.getFHIRAppointmentByCodexId.appointment?.codexProviderId) {
          getProviderInfo({
            variables: {
              providerCodexId:
                data.getFHIRAppointmentByCodexId.appointment.codexProviderId,
            },
          });
        }
      },
    });

  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.CLOSE],
    });

  const [alertProps, setAlertProps] = useState<{
    type: AlertType;
    text: string;
  } | null>({
    type: 'info-new',
    text: editAppointment?.notificationText1,
  });

  const handleClose = () => navigate(-2);
  const onCancelClick = () =>
    navigate(
      `${MY_APPOINTMENTS}?${PARAM_MODALS_IDENTIFIERS.CANCEL_APPOINTMENT_MODAL}=true`,
      {
        state: { appointmentId },
      },
    );

  const preWorkInformationRedirectionHandler = () => {
    const toEditAppointmentCodexId = location.state?.appointmentId;
    if (!toEditAppointmentCodexId) return;
    //TODO: Check if prework routing is working accordingly
    navigate(MY_APPOINTMENTS_SCHEDULE_AN_APPOINTMENT, {
      state: {
        toEditAppointmentCodexId: toEditAppointmentCodexId,
      },
    });
  };

  const appointmentDetailsRedirectionHandler = async () => {
    if (
      !appointmentRescheduleLink ||
      !editAppointment.confirmationModalTitle ||
      !editAppointment.confirmationModalText
    )
      return;
    const shouldContinue = await openConfirmationModal({
      text: editAppointment.confirmationModalText,
      title: editAppointment.confirmationModalTitle,
    });
    if (!shouldContinue) return;
    window.open(appointmentRescheduleLink, '_blank');
    navigate(-2);
  };

  useEffect(() => {
    if (editAppointment) {
      setAlertProps({
        type: 'info-new',
        text: editAppointment?.notificationText1,
      });
    }
  }, [editAppointment]);

  const Buttons: ModalButtonProps[] = [
    {
      label: genericAction?.[genericActionsIds.CLOSE].close,
      onClick: handleClose,
      paddingX: 'px-[3.3rem]',
      className: 'bg-clc-blue text-white',
    },
  ];

  if (
    editAppointmentLoading ||
    !editAppointment ||
    genericActionLoading ||
    !genericAction ||
    !appointmentId
  ) {
    return null;
  }

  const appointment = appointmentData?.getFHIRAppointmentByCodexId.appointment;
  const serviceOffering = provider?.serviceOfferings?.find(
    (service) =>
      appointmentTypeFromServiceType(service.serviceType) ===
      appointment?.appointmentType,
  );
  const specialty = provider?.SENSITIVE_credentials?.titles[0];

  const loading = providerLoading || appointmentLoading;

  return (
    <Modal
      isOpen={true}
      title={editAppointment?.editAppointmentTitle}
      modalDesktopWidth="desktop:max-w-[960px]"
      disableMobileGrow={true}
      buttons={Buttons}
      onClose={handleClose}
      modalContainerCustomClass="w-full"
      contentPaddingX="px-2.5"
    >
      {loading ? (
        <div className="flex flex-col justify-center items-center">
          <Loader />
        </div>
      ) : (
        <div className="flex flex-col gap-5 desktop:gap-14 desktop:flex-row">
          <div className="flex flex-col py-2.5 desktop:max-h-[450px] desktop:flex-1">
            <div className="mx-8 flex desktop:flex-row flex-col pl-5 pr-5 desktop:pl-[45px] desktop:pr-11 bg-white p-5 desktop:p-8 rounded-10 justify-between">
              <p className="text-lg font-semibold text-charcoal-gray mb-3">
                {editAppointment?.appointmentTitle}
              </p>
              <Appointment
                date={formatDateForAppointment(
                  new Date(appointment?.start || ''),
                )}
                type={appointment?.appointmentType as AppointmentTypeEnum}
                price={serviceOffering?.price as string}
                provider={{
                  credentials: specialty as string,
                  name: `${provider?.SENSITIVE_firstname} ${provider?.SENSITIVE_lastname}`,
                  profileImg: signedProfileUrl,
                  title: specialty as string,
                }}
                locales={editAppointment}
              />
            </div>
            <div className="desktop:mx-[30px] mt-[30px] flex flex-col pl-[45px] pr-[45px] justify-center items-center">
              <p className="text-lg font-semibold text-charcoal-gray text-center">
                {editAppointment?.ctaTitle}
              </p>
              <div className="flex w-full flex-col desktop:flex-row justify-center mt-4 gap-3">
                <ButtonComponent
                  onClick={preWorkInformationRedirectionHandler}
                  className="order-0 max-h-[45px] w-full px-[10px] desktop:px-[30px] bg-transparent hover:bg-transparent text-clc-blue desktop:mr-[15px]"
                >
                  {editAppointment?.ctaButtonLabel1}
                </ButtonComponent>
                <ButtonComponent
                  onClick={appointmentDetailsRedirectionHandler}
                  disabled={!appointmentRescheduleLink}
                  className="order-1 max-h-[45px] w-full px-[10px] desktop:px-[30px] bg-transparent hover:bg-transparent text-clc-blue desktop:mr-[15px]"
                >
                  {editAppointment?.ctaButtonLabel2}
                </ButtonComponent>
              </div>
            </div>
            {alertProps && (
              <div className="flex flex-row mt-[30px] px-7">
                <AlertComponent
                  isNotification={true}
                  hideCloseButton={false}
                  actionLabel={''}
                  onActionClick={() => navigate(DASHBOARD)}
                  type={alertProps.type}
                  customContent={
                    <div className="flex flex-col w-full desktop:flex-row justify-around desktop:items-start desktop:relative mobile:items-center">
                      <div className="flex flex-col desktop:flex-row gap-5 justify-between">
                        <p
                          className={`desktop:max-w-[565px] text-center desktop:text-start text-base font-semibold ${
                            alertProps.type === 'info-new'
                              ? 'text-med-gray'
                              : 'text-alert-negative'
                          } desktop:mr-[35px]`}
                        >
                          {alertProps.text}
                        </p>
                      </div>
                      <div className="mobile:mt-4 desktop:mt-0">
                        <p
                          className="text-clc-blue font-bold text-sm underline uppercase hover:cursor-pointer"
                          onClick={onCancelClick}
                        >
                          {editAppointment?.cancel}
                        </p>
                      </div>
                    </div>
                  }
                />
              </div>
            )}
          </div>
        </div>
      )}
    </Modal>
  );
};
