import { useCallback, useContext, useEffect, useState } from 'react';
import Persona from 'persona-react';
import { ModalButtonProps } from 'components/modal/modal.interfaces';
import Modal from 'components/modal/modalComponent';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import { useNavigate } from 'react-router-dom';
import { genericActionsIds } from 'utilities/constants';
import Loader from 'components/loaderComponent';
import { getPersonaConfig } from 'utilities/config';
import { ValidatePersonaModalProps } from './interfaces';
import {
  Persona_Inquiry_Status_Enum,
  useGenerateSessionTokenForPersonaMutation,
  useGetUserPersonaInquiryIdQuery,
} from 'graphql/generated/hasura';

import { AuthContext } from 'auth/context/AuthContext';
import { ApolloError } from '@apollo/client';
import Alert from 'components/alertComponent';

export const ValidatePersonaModal: React.FC<ValidatePersonaModalProps> = ({
  locale,
  userVerificationStatus,
  profileData,
}) => {
  const { user: loggedUser } = useContext(AuthContext);
  const { data: retrievalId, loading: retrievalIdLoading } =
    useGetUserPersonaInquiryIdQuery({
      variables: {
        id: loggedUser?.uuid,
      },
    });

  const [generateSessionTokenForPersonaMutation] =
    useGenerateSessionTokenForPersonaMutation();

  const [inquiryId, setInquiryId] = useState<string | undefined>(undefined);
  const [sessionToken, setSessionToken] = useState<string | undefined>(
    undefined,
  );

  const [sessionTokenError, setSessionTokenError] = useState<ApolloError>();

  const [personaReady, setPersonaReady] = useState(false);

  const getPersonaRetrievalId = useCallback(() => {
    const id = retrievalId?.persona_inquiries[0].persona_inquiry_id;
    setInquiryId(id);
  }, [retrievalId?.persona_inquiries]);

  useEffect(() => {
    if (
      userVerificationStatus.status === Persona_Inquiry_Status_Enum.Started &&
      retrievalId?.persona_inquiries[0] !== undefined
    ) {
      getPersonaRetrievalId();
    }
  }, [
    getPersonaRetrievalId,
    retrievalId?.persona_inquiries,
    userVerificationStatus.status,
  ]);

  useEffect(() => {
    if (
      userVerificationStatus.status === Persona_Inquiry_Status_Enum.Started &&
      retrievalId?.persona_inquiries[0] !== undefined
    ) {
      generateSessionTokenForPersonaMutation({
        variables: { inquiryId: inquiryId },
        onCompleted: (data) => {
          setSessionToken(data.GetPersonaSessionToken);
        },
        onError: (error) => {
          setSessionTokenError(error);
        },
      });
    }
  }, [
    generateSessionTokenForPersonaMutation,
    inquiryId,
    retrievalId?.persona_inquiries,
    userVerificationStatus.status,
  ]);

  useEffect(() => {
    if (inquiryId !== undefined && sessionToken !== undefined) {
      setPersonaReady(true);
    } else {
      setInquiryId(undefined);
      setSessionToken(undefined);
    }
  }, [inquiryId, sessionToken]);

  const getPersonaConfigVars = getPersonaConfig();
  const navigate = useNavigate();
  const handleClose = () => navigate(-1);

  const [personaWidgetReady, setPersonaWidgetReady] = useState(false);

  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.CLOSE],
    });

  const Buttons: ModalButtonProps[] = [
    {
      label: genericAction?.[genericActionsIds.CLOSE].close,
      onClick: handleClose,
    },
  ];

  if (genericActionLoading || !genericAction || retrievalIdLoading) {
    return null;
  }

  return (
    <Modal
      contentPaddingX="0"
      contentPaddingY="0"
      isOpen={true}
      disableMobileGrow={true}
      buttons={Buttons}
      onClose={handleClose}
      modalContainerCustomClass="max-w-[600px]"
      modalDesktopWidth="max-w-[600px]"
    >
      {sessionTokenError && (
        <Alert
          type="warning"
          text={`${locale.sessionTokenError}${sessionTokenError.message}`}
        />
      )}
      <div
        className="p-6"
        style={{ display: personaWidgetReady ? 'none' : 'unset' }}
      >
        <Loader />
      </div>
      <div style={{ display: personaWidgetReady ? 'unset' : 'none' }}>
        <div
          className={`py-10 h-full w-full flex 
          items-center justify-center`}
        >
          <Persona
            templateId={getPersonaConfigVars.personaTemplateId}
            environmentId={getPersonaConfigVars.personaEnvironmentId}
            onReady={() => setPersonaWidgetReady(true)}
            onComplete={() => navigate(-1)}
            inquiryId={personaReady ? inquiryId : undefined}
            sessionToken={personaReady ? sessionToken : undefined}
            fields={{
              name_first: profileData?.firstname ?? '',
              name_last: profileData?.lastname ?? '',
              email_address: profileData?.email ?? '',
              user_type: loggedUser?.role === 'provider' ? 'provider' : 'user',
            }}
          />
        </div>
      </div>
    </Modal>
  );
};
