import { ReactComponent as SearchIcon } from 'assets/icons/searchIcon.svg';
import { useGetPage } from 'hooks/useGetPage';
import { pageIds } from 'utilities/constants';
import ButtonComponent from 'components/button/buttonComponent';
import InputComponent from 'components/inputComponent';

interface ProviderFilterProps {
  search: string | null;
  setCurrentFilter: React.Dispatch<
    React.SetStateAction<Record<string, string>>
  >;
  onClick: () => void;
}

export const ProviderFilter: React.FC<ProviderFilterProps> = ({
  search,
  setCurrentFilter,
  onClick,
}) => {
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.PROVIDERS_SELECTION,
  });

  if (loading || !locale) return null;

  return (
    <div className="flex flex-col w-full py-4 px-8 gap-4 bg-base-content rounded-10">
      <p className="font-semibold text-dark-gray text-h5">
        {locale?.filters?.title}
      </p>
      <hr className="flex flex-row w-full items-center h-px bg-black-blur" />
      <div className="flex flex-col desktop:flex-row w-full justify-between gap-8">
        <div className="flex flex-col desktop:flex-row gap-4 desktop:items-center w-full desktop:w-full">
          <p className="text-dark-gray font-semibold text-h6">
            {locale?.filters?.find}
          </p>
          <div className="w-full">
            <InputComponent
              noMarginBottom
              type="text"
              name="find"
              maxLengthValue={35}
              decorator={<SearchIcon />}
              decoratorLeft
              value={search || ''}
              onChange={(e) =>
                setCurrentFilter((prev) => ({
                  ...prev,
                  search: e.target.value,
                }))
              }
            />
          </div>
        </div>
        <div className="hidden flex-col desktop:flex-row gap-4 desktop:items-center w-full desktop:w-[30%]">
          <p className="text-dark-gray font-semibold text-h6 desktop:w-20">
            {locale?.filters?.sortBy}
          </p>
          <div className="w-full">
            <InputComponent
              type="select"
              name="sortBy"
              selectInputProps={{ fullWidth: true }}
            />
          </div>
        </div>
      </div>
      <hr className="flex flex-row w-full items-center h-px bg-black-blur" />
      <div className="flex flex-col desktop:flex-row w-full justify-between desktop:justify-end items-center p-0 gap-[30px] mt-2.5">
        <div className="flex flex-row w-full desktop:w-auto items-end">
          <ButtonComponent type="outlined" onClick={() => onClick()}>
            {locale?.filters?.button}
          </ButtonComponent>
        </div>
      </div>
    </div>
  );
};
