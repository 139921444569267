export const personalInfoRequiredFields = [
  'firstname',
  'lastname',
  'email',
  'dob',
  'ethnicity',
  'gender',
  'selfIdentifyGender',
];
export const contactInfoRequiredField = ['country', 'phone'];
export const addressInfoRequiredFields = [
  'addressLine1',
  'country',
  'state',
  'zipCode',
];
export const userMeasurementsRequiredFields = [
  'heightFt',
  'heightIn',
  'weight',
  'hips',
  'waist',
];
