import { Scan } from '../interfaces/scan.interfaces';
import ScanIndicators from './ScanIndicators';
import ScanCarousel from './ScanCarousel';
import ScanActions from './ScanActions';
import ScanImage from 'components/ScanImage';

interface ScansListProps {
  scans: Scan[];
}

const ScansList: React.FC<ScansListProps> = ({ scans }) => (
  <>
    <div className="flex flex-col w-full items-start p-0 gap-[20px] desktop:hidden">
      <ScanCarousel scans={scans} />
    </div>
    <div className="hidden desktop:flex flex-col w-full items-start p-0 gap-[20px]">
      {scans.map((scan) => (
        <div
          key={scan.id}
          className="flex flex-col w-full items-start p-0 gap-[20px]"
        >
          <div className="flex flex-row w-full justify-between items-center p-0 gap-[30px]">
            <div className="flex flex-row shrink-0 items-center justify-center p-0 gap-2.5">
              <ScanImage
                className="overflow-hidden w-[60px] h-[75px] rounded-10 bg-cover bg-center"
                imageId={scan.imageId}
              />
            </div>
            <ScanIndicators
              inflammations={scan.inflammations}
              comedones={scan.comedones}
              pih={scan.pih}
              total={scan.total}
              date={scan.date}
            />
            <ScanActions scan={scan} withTitles />
          </div>
          <hr className="flex flex-row w-full items-center h-px bg-black-blur" />
        </div>
      ))}
    </div>
  </>
);

export default ScansList;
