import { Navigate, Route, Routes } from 'react-router-dom';
import { NotificationsSetup } from '../pages/NotificationsSetup';
import { NotificationsUnread } from '../pages/NotificationsUnread';
import {
  BASE_ROUTE,
  NOTIFICATIONS_UNREAD_BASE,
  NOT_FOUND_ROUTE,
} from 'utilities/routes';

export const NotificationsRoutes = () => {
  return (
    <Routes>
      <Route path={BASE_ROUTE} element={<NotificationsSetup />} />
      <Route
        path={NOTIFICATIONS_UNREAD_BASE}
        element={<NotificationsUnread />}
      />
      <Route path={NOT_FOUND_ROUTE} element={<Navigate to={BASE_ROUTE} />} />
    </Routes>
  );
};
