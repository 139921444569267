import Alert from 'components/alertComponent';
import { useGetPage } from 'hooks/useGetPage';
import { pageIds } from 'utilities/constants';

export const PasswordChangeSuccess = () => {
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.MY_PROFILE,
  });

  if (loading && !locale) return null;

  return (
    <div className="flex flex-col gap-6">
      <Alert
        type="positive"
        text={locale.securityAndPrivacy.passwordChangeSuccess}
      />
      <p className="text-med-gray">
        {locale.securityAndPrivacy.passwordChangeSuccessInstruction}
      </p>
    </div>
  );
};
