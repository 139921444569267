import LegacySFrameClient from './legacySFrameClient';
import StandardSFrameClient from './standardSFrameClient';

const create = (options = {}) => {
  const {
    // Whether the client will be based on the legacy SFrame draft or
    // the more recent, more standard-compliant version of the SFrame
    // protocol
    useStandard = false,
  } = options;

  const Client = useStandard
    ? StandardSFrameClient
    : LegacySFrameClient;

  // Needed for unit tests
  Client.isDeprecatedVersion = !useStandard;

  return Client;
};

const SFrameClient = {
  create,
};

export default SFrameClient;
