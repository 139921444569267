import { useLocation, useNavigate } from 'react-router-dom';
import { useGetPage } from 'hooks/useGetPage';
import { pageIds } from 'utilities/constants';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import Modal from 'components/modal/modalComponent';
import DNAVisitIFrame from './DnaVisitIframe';
import { TEST_KITS_MANAGEMENT_TEST_KITS } from 'utilities/routes';

export const ScheduleDnaVisit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.SCHEDULE_DNA_VISIT,
  });

  const handleClose = () => {
    const existingHistory = location.key !== 'default';
    existingHistory ? navigate(-1) : navigate(TEST_KITS_MANAGEMENT_TEST_KITS);
  };

  if (loading && !locale) return null;

  return (
    <Modal
      isOpen={true}
      title={locale?.scheduleADnaVisit}
      modalDesktopWidth="desktop:max-w-[1140px]"
      contentPaddingX="px-2.5"
      onClose={handleClose}
    >
      <div className="flex flex-col gap-[30px]">
        <div className="flex flex-col desktop:flex-row px-5 py-5 desktop:py-3 items-center desktop:items-start gap-[15px] bg-white">
          <InfoIcon className="w-12 h-12 desktop:w-6 desktop:h-6 fill-clc-blue flex shrink-0" />
          <div className="flex flex-col gap-[15px]">
            <p className="text-base font-semibold text-med-gray">
              {locale?.confirmationMessage}
            </p>
            <p className="text-base font-semibold text-med-gray">
              {locale?.dnaVisitSummary}
            </p>
          </div>
        </div>
        <div className="flex flex-col py-2.5 bg-white items-center justify-center w-full min-h-[790px] h-[70vh]">
          <DNAVisitIFrame />
        </div>
      </div>
    </Modal>
  );
};
