import { ReactComponent as ChevronDownIcon } from 'assets/icons/chevron-down.svg';
import { ReactComponent as ChevronUpIcon } from 'assets/icons/chevron-up.svg';
import { useNavigate } from 'react-router-dom';
import { Scan } from 'app/dashboard/interfaces/scan.interfaces';
import {
  Record,
  Record_Order_By,
} from 'components/codexTable/interfaces/record.interfaces';
import { KeyMapProps } from 'components/codexTable/interfaces/keymap.interfaces';
import { pageIds } from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';
import {
  Image_Source_Types_Enum,
  Order_By,
  Skintelligent_Tests_Order_By,
} from 'graphql/generated/hasura';
import { MY_DERMSCORE_NEW_SCAN } from 'utilities/routes';
import { useMemo } from 'react';
import ScanSource from 'app/dashboard/components/ScanSource';
import TableFilter from 'components/codexTable/TableFilter';
import ButtonComponent from 'components/button/buttonComponent';
import TableContent from 'components/codexTable/TableContent';
import TableContentMobile from 'components/codexTable/TableContentMobile';
import ScanActions from 'app/dashboard/components/ScanActions';

const keyMap: KeyMapProps = {
  date: {
    table: 'skintelligent_tests_test',
    column: 'created_at',
    isActive: (orderBy: Skintelligent_Tests_Order_By) =>
      !!orderBy.skintelligent_tests_test?.created_at,
    direction: (orderBy: Skintelligent_Tests_Order_By) =>
      orderBy.skintelligent_tests_test?.created_at || Order_By.Desc,
  },
  source: {
    table: 'skintelligent_tests_image_source_type',
    column: 'value',
    FormatValue: ({ value }) => (
      <ScanSource source={value as Image_Source_Types_Enum} hideText={true} />
    ),
    isActive: (orderBy: Skintelligent_Tests_Order_By) =>
      !!orderBy.skintelligent_tests_image_source_type?.value,
    direction: (orderBy: Skintelligent_Tests_Order_By) =>
      orderBy.skintelligent_tests_image_source_type?.value || Order_By.Desc,
  },
  inflammations: {
    column: 'inflammatory',
    isActive: (orderBy: Skintelligent_Tests_Order_By) => !!orderBy.inflammatory,
    direction: (orderBy: Skintelligent_Tests_Order_By) =>
      orderBy.inflammatory || Order_By.Desc,
  },
  comedones: {
    column: 'comedone',
    isActive: (orderBy: Skintelligent_Tests_Order_By) => !!orderBy.comedone,
    direction: (orderBy: Skintelligent_Tests_Order_By) =>
      orderBy.comedone || Order_By.Desc,
  },
  pih: {
    column: 'pih',
    isActive: (orderBy: Skintelligent_Tests_Order_By) => !!orderBy?.pih,
    direction: (orderBy: Skintelligent_Tests_Order_By) =>
      orderBy.pih || Order_By.Desc,
  },
  total: {
    column: 'total_lesions',
    isActive: (orderBy: Skintelligent_Tests_Order_By) =>
      !!orderBy.total_lesions,
    direction: (orderBy: Skintelligent_Tests_Order_By) =>
      orderBy.total_lesions || Order_By.Desc,
  },
};

interface DermScoreHistoryProps {
  scans: Scan[];
  onLoadMore: () => void;
  onFromDateChange: (date: string) => void;
  onToDateChange: (date: string) => void;
  onColumnSortChange: (nextSort: Record_Order_By) => void;
  onMobileSortChange: (sortDirection: Record_Order_By) => void;
  fromDate: string;
  toDate: string;
  orderBy: Record_Order_By;
  total: number;
  loading: boolean;
  useCurrentPathNavigate?: boolean;
  showCreateNewScanButton?: boolean;
  localeByProp?: { [key: string]: string };
  showSourceFilter?: boolean;
  onSourceChange?: (source: Image_Source_Types_Enum | null) => void;
  source?: Image_Source_Types_Enum | null;
}

const customTableClasses = { pih: { thClass: 'uppercase' } };
const customMobileClasses = { date: { thClass: '!block' } };

const DermScoreHistory: React.FC<DermScoreHistoryProps> = ({
  scans,
  onLoadMore,
  onFromDateChange,
  onToDateChange,
  onColumnSortChange,
  onMobileSortChange,
  fromDate,
  toDate,
  orderBy,
  total,
  loading = false,
  useCurrentPathNavigate = false,
  showCreateNewScanButton = true,
  localeByProp = undefined,
  showSourceFilter = false,
  onSourceChange = () => {
    return;
  },
  source = undefined,
}) => {
  const { data: localeByPage, loading: localeLoading } = useGetPage({
    locale: 'en',
    pageId: pageIds.DERMSCORE_HISTORY,
  });
  const locale = { ...(localeByPage || {}), ...(localeByProp || {}) };

  const navigate = useNavigate();

  const handleSortChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    if (value === 'Most Recent') {
      onMobileSortChange({
        skintelligent_tests_test: {
          created_at: Order_By.Desc,
        },
      });
    } else if (value === 'Oldest') {
      onMobileSortChange({
        skintelligent_tests_test: {
          created_at: Order_By.Asc,
        },
      });
    }
  };

  const columnKeys = useMemo(() => {
    if (scans.length > 0) {
      const allKeys = [...Object.keys(scans[0])] as string[];
      const uniqueKeys = Array.from(new Set(allKeys));
      return uniqueKeys.filter(
        (key) => key !== 'id' && key !== 'imageId' && key !== 'reportId',
      );
    }

    return [];
  }, [scans]);

  const canLoadMore = total > scans.length;

  if (localeLoading || !locale) return null;

  return (
    <div className="p-4 bg-white rounded-10 w-full">
      <div className="flex flex-col mb-4 items-start desktop:flex-row desktop:justify-between desktop:items-center">
        <div className="flex flex-col desktop:flex-row desktop:items-center">
          <h3 className="text-h3 text-dark-gray font-semibold">
            {locale?.title}
          </h3>
          <p className="text-base text-med-gray font-semibold mt-2.5 desktop:mt-0 desktop:ml-4 ">
            {locale?.showing} {scans.length} {locale?.of} {total}
          </p>
        </div>
        <TableFilter
          fromDate={fromDate}
          toDate={toDate}
          orderBy={orderBy}
          handleFromDateChange={(e) => onFromDateChange(e.target.value)}
          handleToDateChange={(e) => onToDateChange(e.target.value)}
          handleSortChange={handleSortChange}
          localeByProp={localeByProp}
          showSourceFilter={showSourceFilter}
          onSourceChange={onSourceChange}
          source={source}
        />
      </div>

      {!scans.length ? (
        <div className="py-10 flex justify-center">
          {locale?.noMatchingResults}
        </div>
      ) : (
        <>
          <TableContentMobile
            keyMap={keyMap}
            columnKeys={columnKeys}
            data={scans}
            className="px-0 py-4"
            customClasses={{ ...customTableClasses, ...customMobileClasses }}
            actions={(record: Record) => (
              <ScanActions
                useCurrentPathNavigate={useCurrentPathNavigate}
                scan={record as Scan}
                withTitles
              />
            )}
          />
          <TableContent
            keyMap={keyMap}
            columnKeys={columnKeys}
            data={scans}
            orderBy={orderBy}
            loading={loading}
            onColumnSortChange={onColumnSortChange}
            customClasses={customTableClasses}
            actions={(record: Record) => (
              <ScanActions
                scan={record as Scan}
                alignIconsRight={true}
                useCurrentPathNavigate={useCurrentPathNavigate}
                withTitles
              />
            )}
          />
        </>
      )}

      <hr />

      <div className="flex flex-col desktop:flex-row w-full justify-between items-center p-0 gap-[30px] mt-2.5">
        <div className="flex flex-row justify-center items-center p-0 gap-[7px] cursor-pointer">
          {canLoadMore && (
            <div
              className="flex flex-row justify-center items-center p-0 gap-[7px] cursor-pointer"
              onClick={onLoadMore}
            >
              <div className="text-base text-clc-blue font-bold underline">
                {locale?.loadMore}
              </div>
              <ChevronDownIcon className="w-[18px] h-2.5 fill-clc-blue" />
            </div>
          )}
          {false && (
            <>
              <button
                className="text-base text-clc-blue font-bold underline"
                onClick={() => null}
              >
                {locale?.showLess}
              </button>
              <ChevronUpIcon className="w-[9px] h-[14px] fill-current" />
            </>
          )}
        </div>
        {showCreateNewScanButton && (
          <div className="flex flex-row w-full desktop:w-auto">
            <ButtonComponent onClick={() => navigate(MY_DERMSCORE_NEW_SCAN)}>
              {locale?.createNewScan}
            </ButtonComponent>
          </div>
        )}
      </div>
    </div>
  );
};

export default DermScoreHistory;
