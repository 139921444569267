import {
  ChangeEvent,
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as BarcodeScanIcon } from 'assets/icons/barcode-scan.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/iconClose.svg';
import { useIsMobile } from 'hooks/useIsMobile';
import { useModalParams } from 'components/modal/useModalManager';
import { ResultsPrivacyStatement } from '../components/ResultsPrivacyStatement';
import { ScanTestQr } from '../components/ScanTestQr';
import { useGetPage } from 'hooks/useGetPage';
import { genericActionsIds, pageIds } from 'utilities/constants';
import { useGetGenericActions } from 'hooks/useGetGenericActions';

import {
  TEST_KITS_MANAGEMENT_REGISTER_TEST_KIT,
  TEST_KITS_MANAGEMENT_QUESTIONNAIRE_FULL,
  TEST_KITS_MANAGEMENT_BARCODE_CAMERA,
  TEST_KITS_MANAGEMENT_TEST_KITS,
  RESULTS_PRIVACY_STATEMENT,
  TEST_KITS_MANAGEMENT_REGISTER_TEST_KIT_SCAN_QR,
  MY_ACCOUNT_PROFILE,
  MY_ACCOUNT_HEALTH_QUESTIONNAIRE,
  TEST_KITS_MANAGEMENT_SELECT_A_PROVIDER,
} from 'utilities/routes';
import {
  Test_Types_Enum,
  useGetDnaTestBySwabcodeIdWithNoErrorsMutation,
  useSaveDnaScannedDataMutation,
  useGetDnaScannedDataByUserIdSubscription,
  Swab_Code_Statuses_Enum,
} from 'graphql/generated/hasura';
import { useFormatAndValidateDNATestKitsIDNumberByType } from 'hooks/DNATestKitIDNumber';
import { scrollToTop } from 'utilities/functions';
import { AuthContext, AuthContextType } from 'auth/context/AuthContext';
import {
  TEST_KIT_MAX_LENGTH,
  TRACKING_NUMBER_MAX_LENGTH,
} from '../../my-skin/pages/constants';
import {
  REMOVE_WHITE_SPACES_REGEX,
  SWABCODE_PREFIX_REGEX,
} from 'utilities/variables';
import { TestKitData } from 'components/dynamicQuestionnaire/interfaces/dynamicQuestionnaire.interface';
import { Tab } from 'components/tabs/tabs.interface';
import { ProfileSectionTypes } from 'app/my-account/pages/my-profile/enums';
import ButtonComponent from 'components/button/buttonComponent';
import InputComponent from 'components/inputComponent';
import AlertComponent, { AlertType } from 'components/alertComponent';
import TabsComponent from 'components/tabs/tabsComponent';
import Alert from 'components/alertComponent';
import useUserValidation from 'hooks/useUserValidation';
import { useValidateTrackingNumber } from 'hooks/useValidateTrackingNumber';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CodexFeatureFlags } from 'utilities/interfaces';

const removeWhiteSpacesRegex = new RegExp(REMOVE_WHITE_SPACES_REGEX, 'g');

export const RegisterTestKit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const referringPage = location.pathname;
  const dnaFlowCompleted = location.state?.dnaFlowCompleted || false;
  const customAlertMessage = (location.state?.customAlertMessage ?? null) as
    | string
    | null;
  const customAlertType = (location.state?.customAlertType ?? undefined) as
    | string
    | undefined;
  const [showCustomAlert, setShowCustomAlert] = useState<boolean>(true);
  const [userHasSelectedTestType, setUserHasSelectedTestType] = useState(false);
  const [successScan, setSuccessScan] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const isMobile = useIsMobile();
  const [testKitData, setTestKitData] = useState<TestKitData>({
    testType: 'DNA Skin test kit',
    testKitIdNumber: '',
    testKitTrackingNumber: '',
  });
  const [lastUpdate, setLastUpdate] = useState<string | null>(null);
  const [alertVisible, setAlertVisible] = useState<boolean>(true);
  const { barcodeScanningDisabled, zrtTestKitsFlowDisabled } =
    useFlags<CodexFeatureFlags>();
  const [showProfileComplete, setShowProfileComplete] =
    useState<boolean>(false);
  const [missingSectionType, setMissingSectionType] =
    useState<ProfileSectionTypes | null>(null);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState<boolean>(false);
  const { userValidation, loading: loadingUserValidation } =
    useUserValidation();
  const { user: loggedUser } = useContext<AuthContextType>(AuthContext);
  const { data: scannedData, loading: scannedDataLoading } =
    useGetDnaScannedDataByUserIdSubscription({
      variables: {
        user_id: loggedUser?.uuid,
      },
    });

  const [saveScannedDataMutation] = useSaveDnaScannedDataMutation({});
  const [lastInvalidTrackingNumber, setLastInvalidTrackingNumber] = useState<
    string | null
  >(null);

  const {
    isValid: isValidTrackingNumber,
    validateTrackingNumber,
    validating: validatingTrackingNumber,
  } = useValidateTrackingNumber();

  const isFirstRender = useRef<boolean>(true);
  const testKitNumberHasChanged = useRef<boolean>(false);
  const testKitTrackingNumberHasChanged = useRef<boolean>(false);

  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.REGISTER_TEST_KIT,
  });

  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.NEXT],
    });
  const { isOpen: isResultsPrivacyStatementOpen } = useModalParams(
    'results-privacy-statement',
  );
  const { isOpen: isScanTestQrModalOpen } = useModalParams('scan-test-qr');

  const [getDnaTestBySwabCodeIDWithNoErrors] =
    useGetDnaTestBySwabcodeIdWithNoErrorsMutation();

  useEffect(() => {
    const handleBeforeUnload = () => {
      navigate(location.pathname, {
        replace: true,
        state: { ...location.state, dnaFlowCompleted: false },
      });
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [location, navigate]);

  useEffect(() => {
    if (dnaFlowCompleted) setShowProfileComplete(true);
  }, [dnaFlowCompleted]);

  useEffect(() => {
    const validateUser = async () => {
      const result = await userValidation();
      if (!result.isPersonalInfoValid) {
        setMissingSectionType(ProfileSectionTypes.PROFILE);
      } else if (!result.isContactInfoValid) {
        setMissingSectionType(ProfileSectionTypes.CONTACT);
      } else if (!result.isQuestionnaireValid) {
        setMissingSectionType(ProfileSectionTypes.HEALTH_QUESTIONNAIRE);
      } else {
        setMissingSectionType(null);
      }
    };

    validateUser().catch((error) => {
      console.log(error);
      throw new Error('Failed to validate user');
    });
  }, [userValidation]);

  const convertEnumTestTypeToLocaleTestType = useCallback(
    (newTestType: Test_Types_Enum): string => {
      switch (newTestType) {
        case Test_Types_Enum.DnaSkin:
          return locale?.dnaVisitSkinOption;

        case Test_Types_Enum.DnaVitamin:
          return locale?.dnaVisitVitaminOption;

        case Test_Types_Enum.ZrtHormone:
          return locale?.zrtHormoneOption;

        case Test_Types_Enum.ZrtHeavyMetals:
          return locale?.zrtheavyMetalsOption;

        case Test_Types_Enum.ZrtInflammatory:
          return locale?.zrtInflammatoryOption;

        case Test_Types_Enum.ZrtNeurotransmitters:
          return locale?.zrtNeurotransmitterOption;

        default:
          return locale?.dnaVisitSkinOption;
      }
    },
    [
      locale?.dnaVisitSkinOption,
      locale?.dnaVisitVitaminOption,
      locale?.zrtheavyMetalsOption,
      locale?.zrtHormoneOption,
      locale?.zrtInflammatoryOption,
      locale?.zrtNeurotransmitterOption,
    ],
  );

  const convertLocaleTestTypeToEnumTestType = useCallback(
    (testType: string): Test_Types_Enum => {
      switch (testType) {
        case locale?.dnaVisitSkinOption:
          return Test_Types_Enum.DnaSkin;

        case locale?.dnaVisitVitaminOption:
          return Test_Types_Enum.DnaVitamin;

        case locale?.zrtHormoneOption:
          return Test_Types_Enum.ZrtHormone;

        case locale?.zrtheavyMetalsOption:
          return Test_Types_Enum.ZrtHeavyMetals;

        case locale?.zrtInflammatoryOption:
          return Test_Types_Enum.ZrtInflammatory;

        case locale?.zrtNeurotransmitterOption:
          return Test_Types_Enum.ZrtNeurotransmitters;

        default:
          return Test_Types_Enum.DnaSkin;
      }
    },
    [
      locale?.dnaVisitSkinOption,
      locale?.dnaVisitVitaminOption,
      locale?.zrtheavyMetalsOption,
      locale?.zrtHormoneOption,
      locale?.zrtInflammatoryOption,
      locale?.zrtNeurotransmitterOption,
    ],
  );

  const { inputErrors, setInputErrors, validateNumbersFormatByType } =
    useFormatAndValidateDNATestKitsIDNumberByType({
      testKitIdNumber: testKitData.testKitIdNumber,
      testType: convertLocaleTestTypeToEnumTestType(testKitData.testType),
    });
  const cleanTrackingNumberStatus = useCallback(() => {
    setTestKitData((prevState) => ({
      ...prevState,
      testKitTrackingNumber: '',
    }));
    setInputErrors((prevState) => ({
      ...prevState,
      testKitTrackingNumber: false,
    }));
    testKitTrackingNumberHasChanged.current = false;
  }, [setTestKitData, setInputErrors, testKitTrackingNumberHasChanged]);

  useEffect(() => {
    const checkData = async () => {
      if (scannedData?.user_scanned_data.length) {
        const updatedAt = scannedData?.user_scanned_data[0]?.updated_at;

        if (isFirstRender.current) {
          const skipTrackingNumber =
            scannedData?.user_scanned_data[0]?.skip_tracking_number;
          if (skipTrackingNumber !== null && skipTrackingNumber !== undefined) {
            setIsCheckboxChecked(skipTrackingNumber);
            if (skipTrackingNumber) {
              cleanTrackingNumberStatus();
            }
          }
          isFirstRender.current = false;
          return setLastUpdate(updatedAt);
        }

        if (lastUpdate !== updatedAt && !userHasSelectedTestType) {
          const testType = scannedData?.user_scanned_data[0]
            ?.test_type as string;

          const swabBarcode = scannedData?.user_scanned_data[0]?.swab_barcode;
          const trackingNumber =
            scannedData?.user_scanned_data[0]?.tracking_number;
          const skipTrackingNumber =
            scannedData?.user_scanned_data[0]?.skip_tracking_number;
          const scanCompleted =
            scannedData?.user_scanned_data[0]?.scan_completed;

          if (testType) {
            setTestKitData((prevState) => ({
              ...prevState,
              testType,
            }));
          }

          if (skipTrackingNumber !== null && skipTrackingNumber !== undefined) {
            setIsCheckboxChecked(skipTrackingNumber);
            if (skipTrackingNumber) {
              cleanTrackingNumberStatus();
            }
          }

          if (swabBarcode && scanCompleted) {
            if (trackingNumber) {
              setTestKitData((prevState) => ({
                ...prevState,
                testKitTrackingNumber: trackingNumber,
              }));
              testKitTrackingNumberHasChanged.current = true;
            }

            const { formatedValue: testKitNumberFormated, newTestType } =
              await validateNumbersFormatByType(swabBarcode, 'testKitIdNumber');
            setTestKitData((prevState) => ({
              ...prevState,
              testKitIdNumber: testKitNumberFormated,
              testType: convertEnumTestTypeToLocaleTestType(newTestType),
            }));
            setSuccessScan(true);
            testKitNumberHasChanged.current = true;
          }
        }

        setLastUpdate(updatedAt);
      } else {
        if (isFirstRender.current) {
          isFirstRender.current = false;
          return setLastUpdate(null);
        }
      }
    };
    checkData().catch((error) =>
      console.error('Something went wrong checking scanned data', error),
    );
  }, [
    scannedData,
    lastUpdate,
    successScan,
    isCheckboxChecked,
    locale?.dnaVisitSkinOption,
    locale?.dnaVisitVitaminOption,
    cleanTrackingNumberStatus,
    validateNumbersFormatByType,
    userHasSelectedTestType,
    convertEnumTestTypeToLocaleTestType,
  ]);

  const handleOnInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.currentTarget;
    const currentCursorPosition = e.currentTarget.selectionStart;

    if (name === 'testKitIdNumber') testKitNumberHasChanged.current = true;
    if (name === 'testKitTrackingNumber')
      testKitTrackingNumberHasChanged.current = true;

    if (name === 'testKitIdNumber' && value.length <= TEST_KIT_MAX_LENGTH) {
      const { formatedValue, newTestType } = await validateNumbersFormatByType(
        value,
        name,
      );
      setTestKitData((prevState) => ({
        ...prevState,
        [name]: formatedValue,
        testType: convertEnumTestTypeToLocaleTestType(newTestType),
      }));

      setTimeout(() => {
        const inputElement = document.querySelector<HTMLInputElement>(
          'input[name="testKitIdNumber"]',
        );
        if (inputElement) {
          inputElement.setSelectionRange(
            currentCursorPosition,
            currentCursorPosition,
          );
        }
      }, 0);
    } else if (
      name === 'testKitTrackingNumber' &&
      value.length <= TRACKING_NUMBER_MAX_LENGTH
    ) {
      return setTestKitData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleOnSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setUserHasSelectedTestType(true);
    setTestKitData({ ...testKitData, [e.target.name]: e.target.value });
    saveScannedDataMutation({
      variables: {
        testType: e.target.value,
      },
    });
  };

  const formatSwabCode = (swabCode: string) => {
    let cleanedInput = swabCode.replace(removeWhiteSpacesRegex, '');
    cleanedInput = cleanedInput.startsWith('C0')
      ? cleanedInput.replace('C0', 'CO')
      : cleanedInput;
    const regex = new RegExp(SWABCODE_PREFIX_REGEX);
    if (regex.test(cleanedInput)) {
      return cleanedInput.replace(regex, '$1-$2');
    }
    return swabCode;
  };

  const handleOnSubmit = async () => {
    const testType = convertLocaleTestTypeToEnumTestType(testKitData.testType);
    const formatedSwabCode = formatSwabCode(testKitData.testKitIdNumber);
    const formatedTrackingnumber = testKitData.testKitTrackingNumber
      ? testKitData.testKitTrackingNumber.replace(removeWhiteSpacesRegex, '')
      : '';

    if (!isCheckboxChecked) {
      setLastInvalidTrackingNumber(testKitData.testKitTrackingNumber);
      const isValidTrackingNumber = await validateTrackingNumber(
        formatedTrackingnumber,
        testType,
      );

      if (!isValidTrackingNumber) {
        setAlertMessage(locale.invalidTackingNumber);
        return scrollToTop();
      }
    }

    const { data } = await getDnaTestBySwabCodeIDWithNoErrors({
      variables: { swabCode: formatedSwabCode },
    });
    if (data && data.getDnaTestBySwabCodeIDWithNoErrors.existingDnaTests) {
      setAlertMessage(locale.testKitAlreadyInProcess);
      return scrollToTop();
    }

    let questionnaireUrlToNavigate;
    switch (testType) {
      case Test_Types_Enum.DnaSkin:
      case Test_Types_Enum.DnaVitamin:
        questionnaireUrlToNavigate = TEST_KITS_MANAGEMENT_QUESTIONNAIRE_FULL;
        break;

      case Test_Types_Enum.ZrtHormone:
      case Test_Types_Enum.ZrtHeavyMetals:
      case Test_Types_Enum.ZrtInflammatory:
      case Test_Types_Enum.ZrtNeurotransmitters:
        questionnaireUrlToNavigate = TEST_KITS_MANAGEMENT_SELECT_A_PROVIDER;
        break;

      default:
        questionnaireUrlToNavigate = TEST_KITS_MANAGEMENT_QUESTIONNAIRE_FULL;
        break;
    }
    navigate(questionnaireUrlToNavigate, {
      state: {
        testKitData: {
          testKitIdNumber: formatedSwabCode,
          testKitTrackingNumber: formatedTrackingnumber,
          testType,
          testName: testKitData.testType,
        },
      },
    });
  };

  const scanTestKitBarcode = () => {
    if (isMobile) {
      navigate(TEST_KITS_MANAGEMENT_BARCODE_CAMERA, { state: {} });
    } else {
      navigate(TEST_KITS_MANAGEMENT_REGISTER_TEST_KIT_SCAN_QR);
    }
  };

  const openResultsPrivacyStatement = () => {
    navigate(RESULTS_PRIVACY_STATEMENT);
  };

  const disableNextButton = () => {
    if (isCheckboxChecked) {
      return (
        !testKitData.testKitIdNumber ||
        !testKitData.testType ||
        inputErrors.testKitIdNumber
      );
    } else {
      return (
        !testKitData.testKitIdNumber ||
        !testKitData.testKitTrackingNumber ||
        !testKitData.testType ||
        inputErrors.testKitIdNumber ||
        validatingTrackingNumber
      );
    }
  };

  const showInputErrorsMessages = (name: string) => {
    if (name === 'testKitIdNumber') {
      if (!testKitData.testKitIdNumber && testKitNumberHasChanged.current)
        return locale.fieldRequired;
      if (
        testKitData.testKitIdNumber &&
        testKitNumberHasChanged.current &&
        inputErrors.testKitIdNumber
      )
        if (
          inputErrors?.testKitIdNumberStatus === Swab_Code_Statuses_Enum.Used
        ) {
          return locale.alreadyUsed;
        } else {
          return locale.invalidFormat;
        }
    } else if (name === 'testKitTrackingNumber' && !isCheckboxChecked) {
      if (
        !testKitData.testKitTrackingNumber &&
        testKitTrackingNumberHasChanged.current
      )
        return locale.fieldRequired;
      if (
        testKitData.testKitTrackingNumber &&
        isValidTrackingNumber === false &&
        lastInvalidTrackingNumber === testKitData.testKitTrackingNumber
      )
        return locale.invalidTackingNumber;
    }
    return null;
  };

  const handleCheckboxChange = () => {
    setUserHasSelectedTestType(true);
    setIsCheckboxChecked(!isCheckboxChecked);
    cleanTrackingNumberStatus();
    saveScannedDataMutation({
      variables: {
        skipTrackingNumber: !isCheckboxChecked,
      },
    });
  };

  const getAlertInfoBasedOnMissingData = (
    missingSectionType: ProfileSectionTypes,
  ): { text: string; action: string } => {
    switch (missingSectionType) {
      case ProfileSectionTypes.PROFILE:
        return {
          text: locale?.personalInformationIncomplete,
          action: locale?.completePersonalInformation,
        };
      case ProfileSectionTypes.CONTACT:
        return {
          text: locale?.contactInformationIncomplete,
          action: locale?.completeContactInformation,
        };
      default:
        return {
          text: locale?.healthQuestionnaireIncomplete,
          action: locale?.completeHealthQuestionnaire,
        };
    }
  };

  const handleNavigateIncompleteSection = async () => {
    switch (missingSectionType) {
      case ProfileSectionTypes.PROFILE:
        return navigate(MY_ACCOUNT_PROFILE, {
          state: {
            section: ProfileSectionTypes.PROFILE,
            isDnaTestKitsFlow: true,
            referringPage,
          },
        });
      case ProfileSectionTypes.CONTACT:
        return navigate(MY_ACCOUNT_PROFILE, {
          state: {
            section: ProfileSectionTypes.CONTACT,
            isDnaTestKitsFlow: true,
            referringPage,
          },
        });
      default:
        return navigate(MY_ACCOUNT_HEALTH_QUESTIONNAIRE, {
          state: { referringPage },
        });
    }
  };

  if (isResultsPrivacyStatementOpen) {
    return <ResultsPrivacyStatement />;
  }

  if (isScanTestQrModalOpen) {
    return <ScanTestQr />;
  }

  if (
    (loading && !locale) ||
    (genericActionLoading && !genericAction) ||
    (scannedDataLoading && !scannedData) ||
    loadingUserValidation
  )
    return null;

  const tabs: Tab[] = [
    {
      name: locale?.testKitsAndResultsTab,
      url: TEST_KITS_MANAGEMENT_TEST_KITS,
    },
    {
      name: locale?.registerTestKitTab,
      url: TEST_KITS_MANAGEMENT_REGISTER_TEST_KIT,
    },
  ];

  return (
    <div className="flex flex-col">
      <TabsComponent tabs={tabs} />
      <div className="px-7">
        {showProfileComplete && (
          <Alert
            type="positive"
            customContent={
              <div className="flex flex-col w-full desktop:flex-row justify-between items-center desktop:relative">
                <div className="flex flex-col desktop:flex-row w-full gap-[20px] desktop:gap-[30px]">
                  <p className="text-base font-semibold text-alert-positive desktop:mr-[35px]">
                    {locale?.profileCompleted}
                  </p>
                </div>

                <CloseIcon
                  className="absolute top-5 right-5 desktop:top-0 desktop:right-0 w-6 h-6 stroke-med-gray cursor-pointer"
                  onClick={() => setShowProfileComplete(false)}
                />
              </div>
            }
          />
        )}

        {customAlertMessage && showCustomAlert && (
          <div className="flex flex-row mb-[30px]">
            <Alert
              type={(customAlertType as AlertType) ?? 'positive'}
              text={locale[customAlertMessage] ?? customAlertMessage}
              isNotification
              onClose={() => setShowCustomAlert(false)}
            />
          </div>
        )}

        {missingSectionType && alertVisible && (
          <div className="flex flex-row mb-[30px]">
            <Alert
              type="info"
              text={getAlertInfoBasedOnMissingData(missingSectionType).text}
              actionLabel={
                getAlertInfoBasedOnMissingData(missingSectionType).action
              }
              onActionClick={handleNavigateIncompleteSection}
              onClose={() => setAlertVisible(false)}
              isNotification
            />
          </div>
        )}

        <div
          className={`flex flex-col desktop:flex-row desktop:justify-between ${
            showProfileComplete ? 'mt-4' : ''
          }`}
        >
          <div className="flex flex-col desktop:items-start desktop:max-w-[60%]">
            <p className="text-h2 text-dark-gray font-medium desktop:text-h1">
              {locale?.registerYourTestKit}
            </p>
          </div>
          {missingSectionType && (
            <ButtonComponent
              className="max-h-[45px] mt-[15px] desktop:mt-0"
              onClick={handleNavigateIncompleteSection}
            >
              {getAlertInfoBasedOnMissingData(missingSectionType).action}
            </ButtonComponent>
          )}
        </div>

        {!missingSectionType && (
          <div className="flex flex-col mt-[30px] gap-[30px]">
            {successScan && (
              <AlertComponent
                type="positive"
                text={locale?.testkitBarcodesScanned}
              />
            )}
            {alertMessage && (
              <AlertComponent type="warning" text={alertMessage} />
            )}
            <div className="flex flex-col gap-[30px] desktop:gap-[60px]">
              <div className="rounded-10 flex flex-col w-full pt-5 pb-[40px] px-5 desktop:p-[30px] gap-5 max-w-[930px] self-center bg-white">
                <div className="flex flex-col desktop:py-2.5 desktop:px-20 justify-center gap-5 desktop:gap-[30px]">
                  <div className="flex flex-col gap-5">
                    {!barcodeScanningDisabled && (
                      <>
                        <div className="flex flex-row self-center">
                          <ButtonComponent
                            paddingX="px-5"
                            paddingY="py-[10.5px]"
                            Icon={BarcodeScanIcon}
                            iconPosition={isMobile ? 'top' : 'left'}
                            onClick={scanTestKitBarcode}
                          >
                            {locale?.scanTestKitBarcodes}
                          </ButtonComponent>
                        </div>

                        <div className="flex flex-row w-full items-center gap-5">
                          <hr className="w-full h-px bg-gray-blur" />
                          <p className="text-base text-med-gray font-medium">
                            {locale?.or}
                          </p>
                          <hr className="w-full h-px bg-gray-blur" />
                        </div>
                      </>
                    )}

                    <p className="text-base text-center text-med-gray font-medium">
                      {locale?.enterFormData}
                    </p>
                  </div>

                  <div className="flex flex-col gap-5">
                    <div className="flex flex-col">
                      <label
                        className="font-base font-semibold text-dark-gray"
                        htmlFor="testType"
                      >
                        {locale?.testType}
                      </label>
                      <InputComponent
                        type="select"
                        name="testType"
                        selectInputProps={{
                          onSelectChange: handleOnSelectChange,
                          selectValues: [
                            locale?.dnaVisitSkinOption,
                            locale?.dnaVisitVitaminOption,
                            ...(!zrtTestKitsFlowDisabled
                              ? [
                                  locale?.zrtHormoneOption,
                                  locale?.zrtheavyMetalsOption,
                                  locale?.zrtInflammatoryOption,
                                  locale?.zrtNeurotransmitterOption,
                                ]
                              : []),
                          ],
                        }}
                        value={testKitData.testType}
                      />
                    </div>

                    <div className="flex flex-col">
                      <label
                        className="font-base font-semibold text-dark-gray"
                        htmlFor="testKitIdNumber"
                      >
                        {locale?.testKitIdNumber}
                      </label>
                      <InputComponent
                        type="text"
                        name="testKitIdNumber"
                        errorMsgWithIcon={showInputErrorsMessages(
                          'testKitIdNumber',
                        )}
                        value={testKitData.testKitIdNumber}
                        onChange={handleOnInputChange}
                      />
                    </div>

                    <div className="flex flex-col">
                      <label
                        className="font-base font-semibold text-dark-gray"
                        htmlFor="testKitTrackingNumber"
                      >
                        {locale?.testKitReturnTrackingNumber}
                      </label>
                      <InputComponent
                        type="text"
                        name="testKitTrackingNumber"
                        value={testKitData.testKitTrackingNumber}
                        errorMsgWithIcon={showInputErrorsMessages(
                          'testKitTrackingNumber',
                        )}
                        onChange={handleOnInputChange}
                        isDisabled={isCheckboxChecked}
                      />
                    </div>

                    <div className="flex flex-row gap-2.5 items-center">
                      <InputComponent
                        type="checkbox"
                        name="skipTrackingNumber"
                        checkboxProps={{
                          checked: isCheckboxChecked,
                          onCheckboxChange: handleCheckboxChange,
                        }}
                      />

                      <label
                        htmlFor="skipTrackingNumber"
                        className="text-med-gray text-sm font-medium"
                      >
                        {locale?.dontHaveTrackingNumber}
                      </label>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col gap-5 desktop:gap-[5px]">
                  <hr className="w-full h-px my-2.5 bg-gray-blur" />
                  <ButtonComponent
                    onClick={handleOnSubmit}
                    className="desktop:hidden"
                    disabled={disableNextButton()}
                  >
                    {genericAction?.[genericActionsIds.NEXT].next}
                  </ButtonComponent>
                  <ButtonComponent
                    className="desktop:hidden"
                    type="underline"
                    paddingX="px-5"
                    onClick={openResultsPrivacyStatement}
                  >
                    {locale?.labSampleResultsPrivacyStatement}
                  </ButtonComponent>

                  <div className="hidden desktop:flex flex-row justify-between">
                    <ButtonComponent
                      type="underline"
                      onClick={openResultsPrivacyStatement}
                    >
                      {locale?.labSampleResultsPrivacyStatement}
                    </ButtonComponent>

                    <ButtonComponent
                      onClick={handleOnSubmit}
                      paddingX="px-[45.5px]"
                      paddingY="py-[6px]"
                      disabled={disableNextButton()}
                    >
                      {genericAction?.[genericActionsIds.NEXT].next}
                    </ButtonComponent>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
