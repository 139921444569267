export const TEST_KIT_MAX_LENGTH = 13;
export const TRACKING_NUMBER_MAX_LENGTH = 29;
export const ZRT_TEST_KIT_MAX_LENGTH = 7;
export const allTabId = 'all';
export const MAX_GRAPH_POINTS = 1000;
export const viewValues = {
  last12Months: () =>
    new Date(Date.now() - 1000 * 60 * 60 * 24 * 365).toISOString(),
  last6Months: () =>
    new Date(Date.now() - (1000 * 60 * 60 * 24 * 365) / 2).toISOString(),
};
