import { useGetGenericActions } from 'hooks/useGetGenericActions';
import { genericActionsIds } from 'utilities/constants';
import { ModalButtonProps } from 'components/modal/modal.interfaces';
import { useNavigate } from 'react-router-dom';
import Modal from 'components/modal/modalComponent';

export const AddMedicationModal = () => {
  const navigate = useNavigate();

  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [
        genericActionsIds.CANCEL,
        genericActionsIds.ADD_MEDICATION,
      ],
    });

  const handleOnSubmit = () => {
    // TODO COD-1469: handleSubmit logic
  };

  const handleOnClose = () => navigate(-1);

  const Buttons: ModalButtonProps[] = [
    {
      label: genericAction?.[genericActionsIds.ADD_MEDICATION].addMedication,
      onClick: handleOnSubmit,
    },
    {
      type: 'underline',
      label: genericAction?.[genericActionsIds.CANCEL].cancel,
      onClick: handleOnClose,
    },
  ];

  if (!genericAction || genericActionLoading) {
    return null;
  }

  return (
    <Modal
      isOpen={true}
      title="ADD MEDICATION"
      modalDesktopWidth="desktop:max-w-[960px]"
      disableMobileGrow={true}
      buttons={Buttons}
      onClose={handleOnClose}
      children={null}
    />
  );
};
