import React from 'react';
import ButtonComponent from 'components/button/buttonComponent';
import { useNavigate } from 'react-router-dom';
import { useGetPage } from 'hooks/useGetPage';
import { pageIds } from 'utilities/constants';
import { AUTH_LOGIN } from 'utilities/routes';

export const UnAuthenticatedNavbar: React.FC = () => {
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.NAVBAR_COMPONENT,
  });

  const navigate = useNavigate();

  if (loading && !locale) return null;

  return (
    <div className="flex flex-row">
      <ButtonComponent
        paddingX="px-[18.5px] desktop:px-[56px]"
        paddingY="py-[9.5px]"
        onClick={() => navigate(AUTH_LOGIN)}
      >
        {locale?.signIn}
      </ButtonComponent>
    </div>
  );
};
