import Avatar from 'components/avatarComponent';
import { formatDateToAppointment } from 'utilities/functions';
import { ReactComponent as ChatIcon } from 'assets/icons/appointmentsIcons/chatIcon.svg';
import { ReactComponent as VideoIcon } from 'assets/icons/appointmentsIcons/videoIcon.svg';
import { useGetPage } from 'hooks/useGetPage';
import { pageIds } from 'utilities/constants';
import { AppointmentTypeEnum } from 'graphql/generated/remote-schema-hasura';

interface MyAppointmentDeetsProps {
  appointmentType: AppointmentTypeEnum;
  date: string;
  fullName: string;
  title?: string | null;
  profileImage?: string | null;
  isProvider?: boolean;
}

export const MyAppointmentDeets: React.FC<MyAppointmentDeetsProps> = ({
  appointmentType,
  date,
  fullName,
  title = null,
  profileImage = null,
  isProvider = false,
}) => {
  const { data: locale } = useGetPage({
    locale: 'en',
    pageId: pageIds.MY_APPOINTMENTS,
  });

  const getIcon = () => {
    switch (appointmentType) {
      case AppointmentTypeEnum.Video:
        return <VideoIcon />;
      case AppointmentTypeEnum.Chat:
        return <ChatIcon />;
      default:
        return <ChatIcon />;
    }
  };

  return (
    <div className="flex flex-col rounded-10 p-[30px] bg-white w-[320px] desktop:w-fit gap-5">
      <div className="flex flex-col">
        <div className="flex flex-row items-center text-h7 font-bold gap-2 text-dark-gray">
          {getIcon()} {locale?.[appointmentType]?.deets.toUpperCase()}
        </div>
        <div className="text-h5 font-semibold text-dark-gray">
          {formatDateToAppointment(date)}
        </div>
      </div>
      <div className="flex flex-row gap-5">
        <Avatar size="s" imageUrl={profileImage} />
        <div className="flex flex-col justify-center">
          <div className="text-h5 font-semibold text-dark-gray">{fullName}</div>
          {isProvider && <div className="text-h7 text-med-gray">{title}</div>}
        </div>
      </div>
    </div>
  );
};
