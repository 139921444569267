import React from 'react';

export const PreworkOutlineIcon: React.ComponentType<
  React.SVGProps<SVGSVGElement>
> = ({ fill, className }) => {
  return (
    <svg
      className={className}
      width="16"
      height="19"
      viewBox="0 0 16 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.0334 0.570312H10.9666V1.60407H12.7782V5.26448H3.22179V1.60407H5.0334V0.570312ZM6.60097 2.02921C6.57519 2.52474 6.16777 2.96247 5.62521 3.03412C5.56497 3.0467 5.46809 3.06297 5.35463 3.06297H4.79065V3.80558H11.2093V3.06297H10.6454V2.33352C10.6454 3.06297 10.6454 3.06297 10.6454 3.06297L10.6439 3.06297L10.6423 3.06296L10.6386 3.06294L10.6292 3.06284L10.6032 3.06223C10.5835 3.0616 10.5554 3.06032 10.5229 3.05758L10.5192 3.05728C10.499 3.05568 10.4332 3.05046 10.3494 3.03085C9.79891 2.94865 9.42371 2.5045 9.39903 2.02921H6.60097Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.62209 5.00003L10.1969 7.88321L10.5718 7.74916H12.6011V9.20806H10.8676L9.7045 10.2031L8.61309 8.20504L7.44013 10.325L6.20449 8.66314L5.91149 9.20806H3.39892V7.74916H4.94188L5.98701 5.80541L7.24233 7.49371L8.62209 5.00003Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4024 4.22115H12.2717V2.76225H14.4024C14.8577 2.76225 15.2381 2.93905 15.5141 3.17859L15.5239 3.18716L15.5335 3.19606C15.8356 3.47699 16 3.84462 16 4.24787V18.5491L0 18.5703V4.25745C0 3.83405 0.190126 3.48035 0.447713 3.22371L0.456939 3.21452L0.466499 3.20563C0.768609 2.92469 1.16395 2.77182 1.5976 2.77182H3.7283V4.23073H1.5976C1.5901 4.23073 1.58543 4.23125 1.58315 4.23159C1.57496 4.24135 1.57148 4.24792 1.57027 4.25058C1.56942 4.25242 1.56916 4.25356 1.56916 4.25356C1.56916 4.25356 1.56886 4.25484 1.56886 4.25745V17.1093L14.4311 17.0923V4.24787C14.4311 4.2409 14.4306 4.23656 14.4302 4.23444C14.4197 4.22683 14.4126 4.22359 14.4098 4.22246C14.4078 4.22167 14.4066 4.22143 14.4066 4.22143C14.4066 4.22143 14.4052 4.22115 14.4024 4.22115Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6731 15.2479H7.55739V13.789H12.6731V15.2479Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.60925 13.8952L4.14003 16.1913L2.78248 14.9289L3.89183 13.8973L4.14003 14.1281L5.4999 12.8636L6.60925 13.8952Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6731 12.3763H7.55739V10.9174H12.6731V12.3763Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.60925 11.0236L4.14003 13.3198L2.78248 12.0574L3.89183 11.0258L4.14003 11.2566L5.4999 9.99203L6.60925 11.0236Z"
        fill={fill}
      />
    </svg>
  );
};
