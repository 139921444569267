import { CustomLayerProps } from '@nivo/line';
import { DotsItem, DotsItemSymbolComponent } from '@nivo/core';
import { CurrentPoint } from './interface';

export function ActivePoint({ pointSymbol, ...props }: CustomLayerProps) {
  const { currentPoint } = props as CurrentPoint;

  if (!currentPoint) return null;
  return (
    <g>
      <DotsItem
        key={currentPoint.id}
        x={currentPoint.x}
        y={currentPoint.y}
        datum={currentPoint.data}
        symbol={pointSymbol as DotsItemSymbolComponent}
        size={props.pointSize || 0}
        color={currentPoint.color}
        borderWidth={props.pointBorderWidth || 0}
        borderColor={currentPoint.borderColor}
        labelYOffset={props.pointLabelYOffset}
      />
    </g>
  );
}
