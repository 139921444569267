import {
  HistoricAppointmentsPatientFilter,
  MyVisitSummaryHistoryPatientTable,
} from 'app/appointments/components/interfaces';
import { appointmentRenderInfo } from 'app/my-appointments/interfaces';
import TableContent from 'components/codexTable/TableContent';
import {
  CustomClassNamesProps,
  KeyMapProps,
} from 'components/codexTable/interfaces/keymap.interfaces';
import { Record_Order_By } from 'components/codexTable/interfaces/record.interfaces';
import {
  AppointmentTypeEnum,
  InputMaybe,
  Order_By,
} from 'graphql/generated/hasura';
import { useGetComponent } from 'hooks/useGetComponent';
import { useCallback, useMemo } from 'react';
import { componentIds } from 'utilities/constants';
import MyVisitSummariesTableActions from './MyVisitSummariesTableActions';
import { twMerge } from 'tailwind-merge';

export interface MyVisitSummariesHistoryProps {
  myVisitSummaries: MyVisitSummaryHistoryPatientTable[];
  historicAppointmentsPatientFilter: HistoricAppointmentsPatientFilter;
  setHistoricAppointmentsPatientFilter: React.Dispatch<
    React.SetStateAction<HistoricAppointmentsPatientFilter>
  >;
  loading?: boolean;
}

export interface MyVisitSummariesHistoryMobileProps {
  myVisitSummaries: MyVisitSummaryHistoryPatientTable[];
  loading?: boolean;
}

export const columnKeys = [
  'summaryDate',
  'subject',
  'type',
  'providerName',
] as const;
type Historic_Appointments_Order_By = Partial<
  Record<(typeof columnKeys)[number], InputMaybe<Order_By>>
>;
export const keyMap: KeyMapProps = {
  summaryDate: {
    column: 'summaryDate',
    isActive: (orderBy: Record_Order_By) =>
      orderBy.summaryDate ? true : false,
    direction: (orderBy: Historic_Appointments_Order_By) =>
      orderBy.summaryDate || Order_By.Desc,
  },
  subject: {
    column: 'subject',
    isActive: (orderBy: Record_Order_By) => (orderBy.subject ? true : false),
    direction: (orderBy: Historic_Appointments_Order_By) =>
      orderBy.subject || Order_By.Desc,
  },
  providerName: {
    column: 'providerName',
    isActive: (orderBy: Record_Order_By) =>
      orderBy.providerName ? true : false,
    direction: (orderBy: Historic_Appointments_Order_By) =>
      orderBy.providerName || Order_By.Desc,
    FormatValue: ({ value, entry }) => {
      let underline = '';
      if (
        (entry as unknown as MyVisitSummaryHistoryPatientTable).type !==
        AppointmentTypeEnum.Dnavisit
      ) {
        underline = 'text-charcoal-gray underline underline-offset-4';
      }
      const baseClass = '';

      return <p className={twMerge(baseClass, underline)}>{value}</p>;
    },
  },
  type: {
    column: 'type',
    isActive: (orderBy: Record_Order_By) => (orderBy.type ? true : false),
    direction: (orderBy: Historic_Appointments_Order_By) =>
      orderBy.type || Order_By.Desc,
    FormatValue: ({ value }) => {
      const Icon = appointmentRenderInfo[value as AppointmentTypeEnum].icon;
      return <Icon className="inline" />;
    },
  },
};

export const customClassesDesktop: CustomClassNamesProps = {
  summaryDate: {
    customRowClassName: 'text-left pt-3.5 align-top',
    spanClass: 'pl-0',
  },
  subject: {
    customRowClassName: 'text-left pt-3.5 align-top',
    spanClass: 'pl-0',
  },
  providerName: {
    customRowClassName: 'text-left pt-3.5 align-top pl-0',
    spanClass: 'pl-0',
  },
  type: {
    customRowClassName: 'text-center pt-3.5 align-top',
    spanClass: 'pl-0',
    flexContainerClass: 'justify-center',
  },
  actions: {
    customRowClassName: 'py-[21.5px] pt-3.5',
  },
};

const MyVisitSummariesHistoryTableDesktop: React.FC<
  MyVisitSummariesHistoryProps
> = ({
  myVisitSummaries,
  historicAppointmentsPatientFilter,
  setHistoricAppointmentsPatientFilter,
  loading,
}) => {
  const { data: locale, loading: localeLoading } = useGetComponent({
    locale: 'en',
    componentId: componentIds.APPOINTMENTS_PATIENT_FILTER,
  });
  const historicAppointmentsOrderBy = useMemo<Record_Order_By>(() => {
    if (
      !historicAppointmentsPatientFilter.sort ||
      historicAppointmentsPatientFilter.sortDirection === undefined
    )
      return {};
    return {
      [historicAppointmentsPatientFilter.sort]:
        historicAppointmentsPatientFilter.sortDirection
          ? Order_By.Asc
          : Order_By.Desc,
    };
  }, [historicAppointmentsPatientFilter]);

  const handleSortChange = useCallback(
    (nextSort: Record_Order_By) => {
      setHistoricAppointmentsPatientFilter((prevFilter) => ({
        ...prevFilter,
        sort: Object.keys(nextSort)[0],
        sortDirection:
          nextSort[Object.keys(nextSort)[0]] === Order_By.Asc ? 1 : 0,
      }));
    },
    [setHistoricAppointmentsPatientFilter],
  );
  if (localeLoading || !locale || !myVisitSummaries) {
    return null;
  }
  return (
    <TableContent
      columnKeys={[...columnKeys]}
      data={myVisitSummaries}
      keyMap={keyMap}
      onColumnSortChange={handleSortChange}
      orderBy={historicAppointmentsOrderBy}
      customClasses={customClassesDesktop}
      locale={locale}
      actions={({ id }) => <MyVisitSummariesTableActions appointmentId={id} />}
      loading={loading}
      bottomBorder
    />
  );
};

export default MyVisitSummariesHistoryTableDesktop;
