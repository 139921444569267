import React from 'react';
import { PrivacyPreferencesProps } from '../interfaces/privacyPreferences';
import InputComponent from '../../../../../components/inputComponent';

const RecoveryEmail: React.FC<PrivacyPreferencesProps> = ({ locale }) => {
  return (
    <div>
      <div className="mb-[30px]">
        <p className="text-title text-charcoal-gray font-bold uppercase">
          {locale.recoveryEmailSectionTitle}
        </p>
        <p className="text-sm font-semibold text-med-gray">
          {locale.recoveryEmailSectionSubtitle}
        </p>
        <div className="desktop:flex items-baseline mx-auto max-w-[690px] mt-4">
          <div className="desktop:flex-none text-base w-28 font-semibold text-charcoal-gray">
            {locale.recoveryEmailInputLabel}
          </div>
          <div className="desktop:flex-auto">
            <InputComponent type="email" name="email" />
            <p className="text-sm font-semibold text-med-gray">
              {locale.recoveryEmailInputSubtitle}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecoveryEmail;
