import { useEffect, useRef, useState } from 'react';
import {
  AppointmentTypeEnum,
  OrderByDirections,
  useGetFhirPatientsRelatedToProviderLazyQuery,
} from 'graphql/generated/remote-schema-hasura';
import { PatientsRelatedToProvider } from '../interfaces/patient.interface';
import {
  calculateAge,
  extractUUIDFromResURL,
  formatDateToCustomFormat,
} from 'utilities/functions';
import { useGetPage } from 'hooks/useGetPage';
import { pageIds } from 'utilities/constants';
import { SIGNAL_CHANNELS, useChannelSignal } from 'hooks/useChannelSignal';
import { useAuth } from 'auth/context/AuthContext';

interface Props {
  searchCriteria: string;
  sort: string;
}

const PATIENTS_INITIAL_OFFSET = 0;
const PATIENTS_INITIAL_LIMIT = 8;

export const useGetPatientsRelatedToProvider = ({
  searchCriteria,
  sort,
}: Props) => {
  const [relatedPatients, setRelatedPatients] = useState<
    PatientsRelatedToProvider[]
  >([]);
  const [totalPatients, setTotalPatients] = useState<number>(0);
  const isFirstRun = useRef(true);

  const { data: locale, loading: localeLoading } = useGetPage({
    locale: 'en',
    pageId: pageIds.MY_PATIENTS,
  });

  const getMessageBasedOnAppointmentType = (type: AppointmentTypeEnum) => {
    switch (type) {
      case AppointmentTypeEnum.Chat:
        return locale.CHAT;
      case AppointmentTypeEnum.Email:
        return locale.EMAIL;
      default:
        return locale.VIDEO;
    }
  };
  const formatUpcommingAppointmentLabel = (
    date: string,
    type: AppointmentTypeEnum,
  ): string => {
    return `${getMessageBasedOnAppointmentType(type)} ${
      locale.on
    } ${formatDateToCustomFormat(date, true)}`;
  };

  const [getFhirPatientsRelatedToProvider, { loading, fetchMore, refetch }] =
    useGetFhirPatientsRelatedToProviderLazyQuery({
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        if (data.getFHIRPatientsById.patients.length >= 0) {
          const newRelatedPatients: PatientsRelatedToProvider[] =
            data.getFHIRPatientsById.patients.map((patient) => ({
              id: patient.id,
              SENSITIVE_firstname: patient.SENSITIVE_firstname || '',
              SENSITIVE_lastname: patient.SENSITIVE_lastname || '',
              SENSITIVE_birth_date: patient.SENSITIVE_birth_date || '',
              SENSITIVE_gender: patient.SENSITIVE_gender || '',
              SENSITIVE_profile_picture_id: extractUUIDFromResURL(
                patient.SENSITIVE_profile_picture_id || '',
              ),
              kyc_approved: patient.kyc_approved,
              SENSITIVE_etnicity: patient.SENSITIVE_etnicity?.join(', ') || '',
              SENSITIVE_self_identity_gender:
                patient.SENSITIVE_self_identity_gender || '',
              heightFt: patient.SENSITIVE_user_measurements?.heightFt || 0,
              heightIn: patient.SENSITIVE_user_measurements?.heightIn || 0,
              waist: patient.SENSITIVE_user_measurements?.waist || 0,
              weight: patient.SENSITIVE_user_measurements?.weight || 0,
              age: calculateAge(
                formatDateToCustomFormat(
                  patient.SENSITIVE_birth_date || '',
                  true,
                ),
              ),
              city: patient.SENSITIVE_address_information?.city || '',
              state: patient.SENSITIVE_address_information?.state || '',
              appointments: patient.appointments?.map((app) => ({
                appointmentCodexId: app.appointmentCodexId,
                start: formatUpcommingAppointmentLabel(
                  app.start || '',
                  app.appointmentType || AppointmentTypeEnum.Video,
                ),
                appointmentType:
                  app.appointmentType || AppointmentTypeEnum.Chat,
                codexPatientId: app.codexPatientId || '',
              })),
            }));
          setTotalPatients(data.getFHIRPatientsById.total);
          setRelatedPatients(newRelatedPatients);
        }
      },
    });

  const handleFetchMore = async () => {
    fetchMore({
      variables: {
        offset: relatedPatients.length,
        limit: relatedPatients.length + PATIENTS_INITIAL_LIMIT,
        order:
          sort === locale?.dateNewest
            ? OrderByDirections.Desc
            : OrderByDirections.Asc,
        searchCriteria,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        return !fetchMoreResult
          ? prev
          : {
              ...prev,
              getFHIRPatientsById: {
                total: fetchMoreResult.getFHIRPatientsById.total,
                patients: [
                  ...prev.getFHIRPatientsById.patients,
                  ...fetchMoreResult.getFHIRPatientsById.patients,
                ],
              },
            };
      },
    });
  };

  const handleRefetch = (newSearchCriteria?: string) => {
    refetch({
      offset: PATIENTS_INITIAL_OFFSET,
      limit: PATIENTS_INITIAL_LIMIT,
      order:
        sort === locale?.dateNewest
          ? OrderByDirections.Desc
          : OrderByDirections.Asc,
      searchCriteria: newSearchCriteria ?? searchCriteria,
    });
  };

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      getFhirPatientsRelatedToProvider();
    }
  }, [isFirstRun, getFhirPatientsRelatedToProvider]);

  const { user } = useAuth();
  useChannelSignal(
    () => refetch(),
    SIGNAL_CHANNELS.PATIENTS_OF_PROVIDERS,
    user?.uuid,
  );

  if (!locale || localeLoading)
    return {
      relatedPatients: [],
      total: totalPatients,
      relatedPatientsLoading: loading,
      handleFetchMore,
      handleRefetch,
    };

  return {
    relatedPatients,
    total: totalPatients,
    relatedPatientsLoading: loading,
    handleFetchMore,
    handleRefetch,
  };
};
