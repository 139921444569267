import { Navigate, Route, Routes } from 'react-router-dom';
import { BackOfficePage } from 'backoffice/dashboard/BackOffice';
import NetworkRequiredRoutes from 'components/networkRequiredRoutes';
import PageLayout from 'layout/PageLayout';
import RoleRequiredRoute from 'components/RoleRequiredRoute';
import { Roles } from '../../firebase/interfaces';
import { BASE_ROUTE } from 'utilities/routes';

export const BackOfficeRoutes = () => {
  return (
    <PageLayout>
      <NetworkRequiredRoutes>
        <RoleRequiredRoute allowedRoles={[Roles.ADMIN, Roles.ADMIN_OBFUSCATED]}>
          <Routes>
            <Route path={BASE_ROUTE} element={<BackOfficePage />} />
            <Route path="/*" element={<Navigate to="/" />} />
          </Routes>
        </RoleRequiredRoute>
      </NetworkRequiredRoutes>
    </PageLayout>
  );
};
