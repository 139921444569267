import { ModalComponent } from 'layout/ModalComponent';
import { ModalSpecification } from 'layout/ModalSpecification';

export function createModal<TState = undefined, TReturn = undefined>(
  key: string,
  modal: ModalComponent<TReturn, TState>,
  deepLinkable = false,
) {
  return new ModalSpecification<TReturn, TState>(key, modal, deepLinkable);
}
