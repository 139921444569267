import { ContentBlock } from 'app/appointments/components/ContentBlock';
import { FromProviderDataWidget } from 'app/appointments/components/FromProviderDataWidget';
import { ListWidget } from 'app/appointments/components/ListWidget';
import { MedicationsTableList } from 'app/appointments/components/MedicationsTableList';
import { TreatmentSurvey } from 'app/appointments/components/TreatmentSurvey';
import {
  ListRecord,
  MyVisitSummaryPatientProps,
} from 'app/appointments/components/interfaces';
import Loader from 'components/loaderComponent';
import { useGetPage } from 'hooks/useGetPage';
import { uniqueId } from 'lodash';
import { useMemo } from 'react';
import { pageIds } from 'utilities/constants';

export interface InLineLastVisitSummaryProps {
  myVisitSummary: MyVisitSummaryPatientProps;
  surveyOptionHandler: (value: string) => void;
  surveyOption: string;
}

const InLineLastVisitSummary: React.FC<InLineLastVisitSummaryProps> = ({
  myVisitSummary,
  surveyOptionHandler,
  surveyOption,
}) => {
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.MY_VISIT_SUMMARY_MODAL,
  });

  const filesAndMedia = useMemo<ListRecord[]>(
    () =>
      myVisitSummary?.filesAndMedia.map((media, i) => {
        return {
          id: media.id || uniqueId(),
          onClick: media.onClick,
          text: media.text || locale?.mediaGenericTitle + ` ${i + 1}`,
        };
      }) || [],
    [myVisitSummary, locale],
  );

  if (loading) return <Loader />;

  return (
    <div className="flex flex-col gap-5 desktop:gap-[60px] desktop:flex-row">
      <div className="flex flex-col py-2.5 gap-8 min-h-[790px] desktop:flex-1">
        <div className="flex flex-col">
          <h1 className="font-semibold text-base text-charcoal-gray mb-2.5">
            {locale.date}
            <span className="ml-1 font-normal text-base text-med-gray">
              {myVisitSummary?.summaryDate}
            </span>
          </h1>
          <h1 className="font-semibold text-lg text-charcoal-gray">
            {myVisitSummary?.subject}
          </h1>
        </div>

        {myVisitSummary?.summary && (
          <ContentBlock
            content={myVisitSummary.summary}
            title={locale.providerSummary}
            titlesInBold={true}
          />
        )}
        {myVisitSummary?.recommendations && (
          <ContentBlock
            content={myVisitSummary.recommendations}
            title={locale.followUpRecommendations}
            titlesInBold={true}
          />
        )}
        {myVisitSummary?.medications && (
          <MedicationsTableList
            locale={locale}
            title={locale?.medications}
            list={myVisitSummary.medications}
          />
        )}
      </div>
      <div className="flex flex-col w-full gap-[30px] desktop:w-[280px]">
        <div className="flex flex-col order-1 desktop:order-2 gap-[30px]">
          {myVisitSummary?.fromProviderData && (
            <FromProviderDataWidget
              altImage={locale.altImage}
              locale={locale}
              fromProviderData={myVisitSummary.fromProviderData}
              backgroundColor="bg-base-content"
            />
          )}

          {myVisitSummary && myVisitSummary.filesAndMedia.length > 0 && (
            <ListWidget
              title={locale.additionalFilesProviderMedia}
              list={filesAndMedia}
              backgroundColor="bg-base-content"
              target="_blank"
            />
          )}

          {myVisitSummary && myVisitSummary.related.length > 0 && (
            <ListWidget
              title={locale.related}
              list={myVisitSummary.related}
              backgroundColor="bg-base-content"
            />
          )}
          <TreatmentSurvey
            locale={locale.treatmentSurvey}
            onOptionSelected={surveyOptionHandler}
            optionSelected={surveyOption}
            desktopHidden={false}
            title={locale.howTreatmentWorked}
            backgroundColor="bg-base-content"
          />
        </div>
      </div>
    </div>
  );
};

export default InLineLastVisitSummary;
