import { ReactComponent as SortDown } from 'assets/icons/sortDown.svg';
import { ReactComponent as SortUp } from 'assets/icons/sortUp.svg';
import { TestKitsTableHeaders, TestsSortTypes } from '../interfaces';
import { useGetPage } from 'hooks/useGetPage';
import { pageIds } from 'utilities/constants';
import React from 'react';

interface Props {
  selectedColumn: TestKitsTableHeaders;
  handleColumnClicked: (column: TestKitsTableHeaders) => void;
  orderBy: TestsSortTypes;
}

const TableHeaders: React.FC<Props> = ({
  selectedColumn,
  orderBy,
  handleColumnClicked,
}) => {
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.TEST_KITS_AND_RESULTS,
  });

  if (loading && !locale) return null;

  return (
    <div className="grid grid-cols-[minmax(130px,_1fr)_minmax(150px,_1fr)_minmax(140px,_1fr)_minmax(100px,_1fr)_minmax(185px,_1fr)_minmax(150px,_1fr)_minmax(240px,_1fr)] h-8 font-bold border-b-[1px] border-gray-opacity-15">
      <div className="flex items-center">
        <div
          className="flex flex-row items-center hover:cursor-pointer"
          onClick={() => handleColumnClicked('date')}
        >
          <span
            className={`${selectedColumn === 'date' ? 'text-clc-blue' : ''}`}
          >
            {locale.dateAndTimeTableHeader}
          </span>
          <SortDown
            className={`w-[9px] h-[6px] ml-2.5 ${
              selectedColumn === 'date' && orderBy === 'desc'
                ? 'fill-clc-blue'
                : 'fill-gray-opacity-15'
            }`}
          />
          <SortUp
            className={`w-[9px] h-[6px] ${
              selectedColumn === 'date' && orderBy === 'asc'
                ? 'fill-clc-blue'
                : 'fill-gray-opacity-15'
            }`}
          />
        </div>
      </div>
      <div className="flex items-center">
        <div
          className="flex flex-row items-center hover:cursor-pointer"
          onClick={() => handleColumnClicked('test type')}
        >
          <span
            className={`${
              selectedColumn === 'test type' ? 'text-clc-blue' : ''
            }`}
          >
            {locale.testTypeTableHeader}
          </span>
          <SortDown
            className={`w-[9px] h-[6px] ml-2.5 ${
              selectedColumn === 'test type' && orderBy === 'desc'
                ? 'fill-clc-blue'
                : 'fill-gray-opacity-15'
            }`}
          />
          <SortUp
            className={`w-[9px] h-[6px] ${
              selectedColumn === 'test type' && orderBy === 'asc'
                ? 'fill-clc-blue'
                : 'fill-gray-opacity-15'
            }`}
          />
        </div>
      </div>
      <div className="flex items-center">
        <div
          className="flex flex-row items-center hover:cursor-pointer"
          onClick={() => handleColumnClicked('swabCode')}
        >
          <span
            className={`${
              selectedColumn === 'swabCode' ? 'text-clc-blue' : ''
            }`}
          >
            {locale.swabCodeTableHeader}
          </span>
          <SortDown
            className={`w-[9px] h-[6px] ml-2.5 ${
              selectedColumn === 'swabCode' && orderBy === 'desc'
                ? 'fill-clc-blue'
                : 'fill-gray-opacity-15'
            }`}
          />
          <SortUp
            className={`w-[9px] h-[6px] ${
              selectedColumn === 'swabCode' && orderBy === 'asc'
                ? 'fill-clc-blue'
                : 'fill-gray-opacity-15'
            }`}
          />
        </div>
      </div>
      <div className="flex items-center">
        <div
          className="flex flex-row items-center hover:cursor-pointer"
          onClick={() => handleColumnClicked('provider')}
        >
          <span
            className={`${
              selectedColumn === 'provider' ? 'text-clc-blue' : ''
            }`}
          >
            {locale.providerTableHeader}
          </span>
          <SortDown
            className={`w-[9px] h-[6px] ml-2.5 ${
              selectedColumn === 'provider' && orderBy === 'desc'
                ? 'fill-clc-blue'
                : 'fill-gray-opacity-15'
            }`}
          />
          <SortUp
            className={`w-[9px] h-[6px] ${
              selectedColumn === 'provider' && orderBy === 'asc'
                ? 'fill-clc-blue'
                : 'fill-gray-opacity-15'
            }`}
          />
        </div>
      </div>
      <div className="flex items-center">
        <div
          className="flex flex-row items-center hover:cursor-pointer"
          onClick={() => handleColumnClicked('status')}
        >
          <span
            className={`${selectedColumn === 'status' ? 'text-clc-blue' : ''}`}
          >
            {locale.statusTableHeader}
          </span>
          <SortDown
            className={`w-[9px] h-[6px] ml-2.5 ${
              selectedColumn === 'status' && orderBy === 'desc'
                ? 'fill-clc-blue'
                : 'fill-gray-opacity-15'
            }`}
          />
          <SortUp
            className={`w-[9px] h-[6px] ${
              selectedColumn === 'status' && orderBy === 'asc'
                ? 'fill-clc-blue'
                : 'fill-gray-opacity-15'
            }`}
          />
        </div>
      </div>
      <div />
    </div>
  );
};

export default TableHeaders;
