import { useMemo } from 'react';
import {
  GetComponentQueryVariables,
  useGetComponentQuery,
} from '../graphql/generated/strapi';
import { useStrapi } from 'apollo/LocalesProvider';

export const useGetComponent = (variables: GetComponentQueryVariables) => {
  const { client } = useStrapi();

  const { data, loading, error } = useGetComponentQuery({
    variables,
    client: client,
    fetchPolicy: 'cache-first',
  });

  const pageMemoized = useMemo(
    () => ({
      data: data?.components?.data[0].attributes?.content,
      loading,
      error,
    }),
    [data, loading, error],
  );

  return pageMemoized;
};
