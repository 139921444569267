import {
  ProviderRequestStatus,
  useUpdateAppointmentStatusMutation,
  useUpdateBecomePatientRequestStatusMutation,
} from 'graphql/generated/remote-schema-hasura';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HORMONE_QUESTIONNAIRE_FULL } from 'utilities/routes';

export enum CustomNotificationActions {
  UPDATE_APPOINTMENT_STATUS = 'UPDATE_APPOINTMENT_STATUS',
  UPDATE_PROVIDER_REQUEST = 'UPDATE_PROVIDER_REQUEST',
  GO_TO_QUESTIONNAIRE = 'GO_TO_QUESTIONNAIRE',
}

interface RequestBecomePatientParams {
  itemReference: { reference: string; type?: string };
  date: string;
  patientFhirId: string;
  requestStatus: ProviderRequestStatus;
}

type CustomActionHandler = (
  customActionParams: Record<string, unknown>,
  fromAlert: boolean,
) => Promise<void>;

export interface useHandleExtraActionsType {
  handler: Record<CustomNotificationActions, CustomActionHandler>;
  isLoading: boolean;
  error?: string;
}

export function useHandleExtraActions(): useHandleExtraActionsType {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [updateAppointmentStatus] = useUpdateAppointmentStatusMutation({});
  const [updateBecomePatientRequest] =
    useUpdateBecomePatientRequestStatusMutation({});

  const handleAppointmentUpdate = useCallback(
    async (params: Record<string, unknown>, fromAlert: boolean) => {
      try {
        const { appointmentCodexId, status, isRescheduled } = params;
        if (!appointmentCodexId || !status) return;
        setIsLoading(true);
        await updateAppointmentStatus({
          variables: {
            updateStatusInput: {
              appointmentCodexId: appointmentCodexId as string,
              status: status as string,
              isRescheduled: !!isRescheduled,
            },
          },
        });
        setIsLoading(false);

        !fromAlert && navigate(-1);
      } catch (error) {
        setError((error as Error).message);
      }
    },
    [navigate, updateAppointmentStatus],
  );

  const handlePatientRequestUpdate = useCallback(
    async (params: Record<string, unknown>, fromAlert: boolean) => {
      try {
        const { itemReference, date, patientFhirId, requestStatus } =
          params as unknown as RequestBecomePatientParams;
        if (!itemReference || !date || !patientFhirId || !requestStatus) return;
        setIsLoading(true);
        await updateBecomePatientRequest({
          variables: {
            date: date,
            itemReferenceId: itemReference.reference,
            patientFhirId: patientFhirId,
            requestStatus,
            itemReferenceType: itemReference.type,
          },
        });
        setIsLoading(false);
        !fromAlert && navigate(-1);
      } catch (error) {
        setError((error as Error).message);
      }
    },
    [navigate, updateBecomePatientRequest],
  );

  const handlerMap = useMemo<
    Record<CustomNotificationActions, CustomActionHandler>
  >(
    () => ({
      [CustomNotificationActions.UPDATE_APPOINTMENT_STATUS]:
        handleAppointmentUpdate,
      [CustomNotificationActions.UPDATE_PROVIDER_REQUEST]:
        handlePatientRequestUpdate,
      [CustomNotificationActions.GO_TO_QUESTIONNAIRE]: async (params) =>
        navigate(HORMONE_QUESTIONNAIRE_FULL, {
          state: {
            testKitData: params,
          },
        }),
    }),
    [handleAppointmentUpdate, handlePatientRequestUpdate, navigate],
  );

  return { handler: handlerMap, isLoading, error };
}
