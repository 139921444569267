import Loader from 'components/loaderComponent';
import { Tab } from 'components/tabs/tabs.interface';
import TabsComponent from 'components/tabs/tabsComponent';
import { useGetPage } from 'hooks/useGetPage';
import { pageIds } from 'utilities/constants';
import {
  MY_ACCOUNT_PROFILE,
  MY_PROFILE_ACCOUNT_SETTINGS,
} from 'utilities/routes';
import { EmailPreferences } from '../components/EmailPreferences';
import ButtonComponent from 'components/button/buttonComponent';
import { useContext, useState } from 'react';
import { useUpdateAccountSettingsMutation } from 'graphql/generated/hasura';
import { AuthContext } from 'auth/context/AuthContext';

import AlertComponent from 'components/alertComponent';
import { ReactComponent as CloseIcon } from 'assets/icons/iconClose.svg';
import {
  AccountSettingsState,
  AlertProps,
  SectionsLoaded,
} from '../interfaces/providerModuleInterfaces';
import { scrollToTop } from 'utilities/functions';
import { PrivacyPreferences } from '../components/PrivacyPreferences';
import { AutomaticApprovalPreferences } from '../components/AutomaticApprovalPreferences';
import { AlertPreferences } from '../components/AlertPreferences';
import RecoveryEmail from '../components/RecoveryEmail';

export const ProviderModule: React.FC = () => {
  const { user: loggedUser } = useContext(AuthContext);
  const [alertProps, setAlertProps] = useState<AlertProps | null>();
  const [accountSettings, setAccountSettings] =
    useState<AccountSettingsState>();

  const [isAllSectionsLoaded, setIsAllSectionsLoaded] =
    useState<SectionsLoaded>({
      alertPreferences: false,
      automaticApprovalPreferences: false,
      emailPreferences: false,
      privacyPreferences: false,
    });

  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.ACCOUNT_SETTINGS,
  });

  const ProviderTabs: Tab[] = [
    {
      name: locale?.myProfileTab,
      url: MY_ACCOUNT_PROFILE,
    },
    {
      name: locale?.accountSettingTab,
      url: MY_PROFILE_ACCOUNT_SETTINGS,
    },
  ];

  const [updateAccountSettingsMutation, { loading: accountSettingsLoading }] =
    useUpdateAccountSettingsMutation({
      variables: {
        id: loggedUser?.uuid,
        EMAIL_MONTHLY_NEWSLETTER: accountSettings?.EMAIL_MONTHLY_NEWSLETTER,
        EMAIL_NEW_FEATURES_SERVICES_SETTINGS:
          accountSettings?.EMAIL_NEW_FEATURES_SERVICES_SETTINGS,
        EMAIL_SPECIAL_OFFERS_SETTING:
          accountSettings?.EMAIL_SPECIAL_OFFERS_SETTING,
        EMAIL_SPECIAL_OFFER_FRIENDS_SETTING:
          accountSettings?.EMAIL_SPECIAL_OFFER_FRIENDS_SETTING,
        EMAIL_UNSUBSCRIBED: accountSettings?.EMAIL_UNSUBSCRIBED,
        PRIVACY_AFFILIATES_CODEX_LABS_SETTING:
          accountSettings?.PRIVACY_AFFILIATES_CODEX_LABS_SETTING,
        PRIVACY_NON_AFFILIATES_CODEX_LABS_SETTING:
          accountSettings?.PRIVACY_NON_AFFILIATES_CODEX_LABS_SETTING,
        PRIVACY_CALIFORNIA_SETTINGS:
          accountSettings?.PRIVACY_CALIFORNIA_SETTINGS,
        AUTO_APPROVAL_PATIENTS_CODEX_LABS_SETTING:
          accountSettings?.AUTO_APPROVAL_PATIENTS_CODEX_LABS_SETTING,
        AUTO_APPROVAL_TESTKIT_SCRIPTS_CODEX_LABS_SETTING:
          accountSettings?.AUTO_APPROVAL_TESTKIT_SCRIPTS_CODEX_LABS_SETTING,
        ALERT_SETTINGS: accountSettings?.ALERT_SETTINGS,
      },
      onCompleted: () => {
        setAlertProps({
          type: 'positive',
          text: locale?.alertPositive,
        });
        scrollToTop();
      },
      onError: () => {
        setAlertProps({
          type: 'warning',
          text: locale?.alertNegative,
        });
        scrollToTop();
      },
    });

  const checkIfAllSectionsLoaded = () => {
    return Object.values(isAllSectionsLoaded).every((value) => value === true);
  };

  if (loading || !locale) {
    return <Loader />;
  }

  return (
    <div>
      <TabsComponent tabs={ProviderTabs} />
      <h1 className="px-[27.5px] desktop:px-0 text-h1 font-medium text-charcoal-gray mb-[30px]">
        {locale?.title}
      </h1>
      {alertProps && (
        <div className="flex flex-row mb-[30px] px-7">
          <AlertComponent
            type={alertProps.type}
            customContent={
              <div className="flex flex-col w-full desktop:flex-row justify-between items-center desktop:relative">
                <div className="flex flex-col desktop:flex-row w-full gap-5 desktop:gap-[30px]">
                  <p
                    className={`text-base font-semibold ${
                      alertProps.type === 'positive'
                        ? 'text-alert-positive'
                        : 'text-alert-negative'
                    } text-center desktop:mr-[35px]`}
                  >
                    {alertProps.text}
                  </p>
                </div>

                <CloseIcon
                  className="absolute top-5 right-5 desktop:top-0 desktop:right-0 w-6 h-6 stroke-med-gray cursor-pointer"
                  onClick={() => setAlertProps(null)}
                />
              </div>
            }
          />
        </div>
      )}
      <div className="px-[27.5px] desktop:px-0">
        <div className="rounded-[10px] bg-white w-full max-w-[1050px] px-[20px] py-[20px] desktop:px-[60px] desktop:py-[30px] mx-auto">
          {!checkIfAllSectionsLoaded() && <Loader />}
          <div className={checkIfAllSectionsLoaded() ? 'visible' : 'hidden'}>
            <RecoveryEmail
              locale={locale}
              setAccountSettings={setAccountSettings}
              setIsAllSectionsLoaded={setIsAllSectionsLoaded}
            />
            <hr className="mb-[20px]" />
            <PrivacyPreferences
              locale={locale}
              setAccountSettings={setAccountSettings}
              setIsAllSectionsLoaded={setIsAllSectionsLoaded}
            />
            <hr className="mb-[20px]" />
            <EmailPreferences
              locale={locale}
              setAccountSettings={setAccountSettings}
              setIsAllSectionsLoaded={setIsAllSectionsLoaded}
            />
            <hr className="mb-[20px]" />
            <AutomaticApprovalPreferences
              locale={locale}
              setAccountSettings={setAccountSettings}
              setIsAllSectionsLoaded={setIsAllSectionsLoaded}
            />
            <hr className="mb-[20px]" />
            <AlertPreferences
              locale={locale}
              setAccountSettings={setAccountSettings}
              setIsAllSectionsLoaded={setIsAllSectionsLoaded}
            />
            <hr className="mb-[20px]" />
            <div className="flex justify-end w-full">
              <ButtonComponent
                onClick={updateAccountSettingsMutation}
                paddingX="px-[50px]"
                className="font-exo"
                disabled={accountSettingsLoading}
                showSpinner={accountSettingsLoading}
              >
                {locale?.ctaLabel}
              </ButtonComponent>
            </div>
          </div>
        </div>
        <div className="max-w-[1050px] mx-auto mt-6">
          <ButtonComponent type="underline" className="font-exo uppercase">
            {locale?.closeAccountBtn}
          </ButtonComponent>
        </div>
      </div>
    </div>
  );
};
