import { useFlags } from 'launchdarkly-react-client-sdk';
import { useGetPage } from './useGetPage';
import { pageIds } from 'utilities/constants';
import {
  LOGOUT,
  MY_ACCOUNT_HEALTH_QUESTIONNAIRE,
  MY_ACCOUNT_PROFILE,
  TEST_KITS_MANAGEMENT_TEST_KITS,
  TEST_KITS_MANAGEMENT_REGISTER_TEST_KIT,
  MY_SKIN_DERMSCORE,
  PROVIDERS_FIND_A_PROVIDER,
  PROVIDERS_PAST_PROVIDERS,
  PROVIDERS,
  MY_SKIN_MY_VISIT_SUMMARIES,
  MY_SKIN_MY_MEDICATION_HISTORY,
  MY_PROFILE_ACCOUNT_SETTINGS,
  MY_APPOINTMENTS,
  MY_APPOINTMENTS_SCHEDULE_AN_APPOINTMENT,
} from 'utilities/routes';
import { CodexFeatureFlags } from 'utilities/interfaces';
import { transformNavBarOptionsToLowercase } from 'utilities/functions';

export const useNavbarOptions = () => {
  const { enableDermscoreTabs } = useFlags<CodexFeatureFlags>();
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.NAVBAR_COMPONENT,
  });

  const MySkinOptions: string[] = [
    locale?.mySkinOptions.myDermscore,
    ...(enableDermscoreTabs ? [locale?.mySkinOptions.myVisitSummaries] : []),
    ...(enableDermscoreTabs ? [locale?.mySkinOptions.myMedicationHistory] : []),
  ].map((option) => transformNavBarOptionsToLowercase(option));

  const ManageTestKitsOptions: string[] = [
    locale?.manageTestKitsOptions.testKitResults,
    locale?.manageTestKitsOptions.registerNewTestKit,
  ].map((option) => transformNavBarOptionsToLowercase(option));

  const ProvidersOptions: string[] = [
    locale?.providersOptions?.myProviders,
    locale?.providersOptions?.pastProviders,
    locale?.providersOptions?.findAProvider,
  ];

  const AppointmentsOptions: string[] = [
    locale?.appointmentsOptions?.myAppointments,
    locale?.appointmentsOptions?.scheduleAnAppointment,
  ].map((option) => transformNavBarOptionsToLowercase(option));

  const ShopOptions: string[] = [
    locale?.shopOptions.codexProducts,
    locale?.shopOptions.shopAcne,
    locale?.shopOptions.shopTestKits,
    locale?.shopOptions.shopSupplements,
    locale?.shopOptions.shopEczema,
    locale?.shopOptions.shopPsoriasis,
  ].map((option) => transformNavBarOptionsToLowercase(option));

  const MyAccountOptions: string[] = [
    locale?.myAccountOptions.myProfile,
    locale?.myAccountOptions.healthQuestionnaire,
    locale?.myAccountOptions.logOut,
  ];

  const ProviderMyAccountOptions: string[] = [
    locale?.myAccountOptions.myProfile,
    locale?.myAccountOptions.accountSettings,
    locale?.myAccountOptions.logOut,
  ].map((option) => option);

  const ProviderNotApprovedMyAccountOptions: string[] = [
    locale?.myAccountOptions.logOut,
  ].map((option) => transformNavBarOptionsToLowercase(option));

  const MySkinRoutes: Record<string, string> = {
    [locale?.mySkinOptions.myDermscore]: MY_SKIN_DERMSCORE,
    [locale?.mySkinOptions.myVisitSummaries]: MY_SKIN_MY_VISIT_SUMMARIES,
    [locale?.mySkinOptions.myMedicationHistory]: MY_SKIN_MY_MEDICATION_HISTORY,
  };

  const ManageTestKitsRoutes: Record<string, string> = {
    [locale?.manageTestKitsOptions.testKitResults]:
      TEST_KITS_MANAGEMENT_TEST_KITS,
    [locale?.manageTestKitsOptions.registerNewTestKit]:
      TEST_KITS_MANAGEMENT_REGISTER_TEST_KIT,
  };

  const ShopRoutes: Record<string, string> = {
    [locale?.shopOptions.codexProducts]: '',
    [locale?.shopOptions.shopTestKits]: 'collections/diagnostics',
    [locale?.shopOptions.shopAcne]: 'collections/shaant',
    [locale?.shopOptions.shopSupplements]: 'collections/supplements',
    [locale?.shopOptions.shopEczema]: 'collections/bia',
    [locale?.shopOptions.shopPsoriasis]: 'collections/antu',
  };

  const MyAccountRoutes: Record<string, string> = {
    [locale?.myAccountOptions.myProfile]: MY_ACCOUNT_PROFILE,
    [locale?.myAccountOptions.healthQuestionnaire]:
      MY_ACCOUNT_HEALTH_QUESTIONNAIRE,
    [locale?.myAccountOptions.logOut]: LOGOUT,
  };

  const ProviderMyAccountRoutes: Record<string, string> = {
    [locale?.myAccountOptions.myProfile]: MY_ACCOUNT_PROFILE,
    [locale?.myAccountOptions.accountSettings]: MY_PROFILE_ACCOUNT_SETTINGS,
    [locale?.myAccountOptions.logOut]: LOGOUT,
  };

  const ProviderNotApprovedMyAccountRoutes: Record<string, string> = {
    [locale?.myAccountOptions.logOut]: LOGOUT,
  };

  const ProvidersRoutes: Record<string, string> = {
    [locale?.providersOptions?.myProviders]: PROVIDERS,
    [locale?.providersOptions?.pastProviders]: PROVIDERS_PAST_PROVIDERS,
    [locale?.providersOptions?.findAProvider]: PROVIDERS_FIND_A_PROVIDER,
  };

  const AppointmentsRoutes: Record<string, string> = {
    [locale?.appointmentsOptions?.myAppointments]: MY_APPOINTMENTS,
    [locale?.appointmentsOptions?.scheduleAnAppointment]:
      MY_APPOINTMENTS_SCHEDULE_AN_APPOINTMENT,
  };

  return {
    loadingOptions: loading,
    MySkinOptions,
    ManageTestKitsOptions,
    ProvidersOptions,
    ShopOptions,
    MyAccountOptions,
    MySkinRoutes,
    ManageTestKitsRoutes,
    ShopRoutes,
    MyAccountRoutes,
    ProvidersRoutes,
    ProviderMyAccountOptions,
    ProviderNotApprovedMyAccountOptions,
    ProviderMyAccountRoutes,
    AppointmentsRoutes,
    AppointmentsOptions,
    ProviderNotApprovedMyAccountRoutes,
  };
};
