import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
export type GetUserTestsByUserIdQueryVariables = Exact<{
  userID: Scalars['uuid'];
  dnaSkinTestID: Scalars['Int'];
  dnaVitaminTestID: Scalars['Int'];
  fromDate?: InputMaybe<Scalars['timestamptz']>;
  toDate?: InputMaybe<Scalars['timestamptz']>;
  states?: Array<Dnavisit_Test_Statuses_Enum> | Dnavisit_Test_Statuses_Enum;
}>;


export type GetUserTestsByUserIdQuery = { __typename?: 'query_root', tests: Array<{ __typename?: 'tests', test_id: any, created_at: any, tests_test_definition: { __typename?: 'test_definitions', test_type: Test_Types_Enum } }> };

export type GetUserDnaTestsByTestsIdsQueryVariables = Exact<{
  testIDS?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  states?: Array<Dnavisit_Test_Statuses_Enum> | Dnavisit_Test_Statuses_Enum;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type GetUserDnaTestsByTestsIdsQuery = { __typename?: 'query_root', dnavisit_tests: Array<{ __typename?: 'dnavisit_tests', test_id: any, state?: Dnavisit_Test_Statuses_Enum | null, tracking_number?: string | null, patient_pdf_id?: any | null, visit_summary_pdf_id?: any | null, sapphiros_barcode?: string | null, visit_summary_appointment_date?: any | null, visit_summary_appointment_url?: string | null, created_at?: any | null }>, dnavisit_tests_aggregate: { __typename?: 'dnavisit_tests_aggregate', aggregate?: { __typename?: 'dnavisit_tests_aggregate_fields', count: number } | null } };

export type GetAcuityCalendarMutationVariables = Exact<{
  providerId: Scalars['String'];
}>;


export type GetAcuityCalendarMutation = { __typename?: 'mutation_root', GetAcuityCalendarInformation?: { __typename?: 'AcuityCalendarResult', calendar_id: number, active: boolean, owner_id?: string | null, provider_calendar: string, provider_id: string } | null };

export type GetAcuityProviderTimeSlotsQueryVariables = Exact<{
  providerCalendarId: Scalars['String'];
  date: Scalars['String'];
}>;


export type GetAcuityProviderTimeSlotsQuery = { __typename?: 'query_root', GetProviderTimeSlots: { __typename?: 'ProviderWorkingHoursAndBlockedTime', providerWorkingHours: { __typename?: 'ProviderWeeklyWorkingHours', data: { __typename?: 'AcuityWorkingHoursPerDay', Monday: Array<string>, Tuesday: Array<string>, Wednesday: Array<string>, Thursday: Array<string>, Friday: Array<string>, Saturday: Array<string>, Sunday: Array<string> } }, blocks: Array<{ __typename?: 'FormatedAvailableTime', start: string, end: string, enabled: boolean }> } };

export type GetCountriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCountriesQuery = { __typename?: 'query_root', countries: Array<{ __typename?: 'countries', code: string, name: string, flag?: string | null, id: number }> };

export type RegisterTestKitMutationVariables = Exact<{
  swabBarcode: Scalars['String'];
  trackingNumber?: InputMaybe<Scalars['String']>;
  questionnaireId: Scalars['Int'];
  testType: Scalars['String'];
  questionnaireResponses: Scalars['jsonb'];
}>;


export type RegisterTestKitMutation = { __typename?: 'mutation_root', RegisterTestKit?: { __typename?: 'SuccessfulRegisteredTestKit', status: number, message: string } | null };

export type GetDnaVisitIframeSecretMutationVariables = Exact<{
  userEmail: Scalars['String'];
}>;


export type GetDnaVisitIframeSecretMutation = { __typename?: 'mutation_root', getDNAVisitIframeSecret: { __typename?: 'IframeSecretResponse', dnaVisitIframeSecret: string } };

export type GetLatestDnaVisitTestsMutationVariables = Exact<{ [key: string]: never; }>;


export type GetLatestDnaVisitTestsMutation = { __typename?: 'mutation_root', GetLatestDnaVisitTests: any };

export type GetDnaTestBySwabcodeIdWithNoErrorsMutationVariables = Exact<{
  swabCode: Scalars['String'];
}>;


export type GetDnaTestBySwabcodeIdWithNoErrorsMutation = { __typename?: 'mutation_root', getDnaTestBySwabCodeIDWithNoErrors: { __typename?: 'DNAValidTestBySwabCode', existingDnaTests: boolean } };

export type GetLatestDnaTestsForSuperAdminQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLatestDnaTestsForSuperAdminQuery = { __typename?: 'query_root', dnavisit_tests: Array<{ __typename?: 'dnavisit_tests', state?: Dnavisit_Test_Statuses_Enum | null, errors?: any | null, sapphiros_barcode?: string | null, created_at?: any | null, tracking_number?: string | null, dna_test_questionnaire_pdf_id?: any | null, health_questionnaire_pdf_id?: any | null, provider_report_pdf_id?: any | null, patient_pdf_id?: any | null, sapphiros_report_pdf_id?: any | null, visit_summary_pdf_id?: any | null, dnavisit_tests_test: { __typename?: 'tests', tests_user: { __typename?: 'users', SENSITIVE_firstname?: string | null, SENSITIVE_lastname?: string | null, SENSITIVE_email: string, id: any }, tests_test_definition: { __typename?: 'test_definitions', test_type: Test_Types_Enum } } }> };

export type ValidateFedexTrackingNumberQueryVariables = Exact<{
  trackingNumber: Scalars['String'];
}>;


export type ValidateFedexTrackingNumberQuery = { __typename?: 'query_root', ValidateFedexTrackingNumber: { __typename?: 'FedexTrackingResponse', isValid: boolean } };

export type GetSignedUrlFromStorageMutationVariables = Exact<{
  fileId: Scalars['String'];
}>;


export type GetSignedUrlFromStorageMutation = { __typename?: 'mutation_root', GetSignUrlFormStorage?: { __typename?: 'SuccessGetSignUrlFormStorage', status: number, url: string } | null };

export type GetSignedUrlFromStorageByAdminMutationVariables = Exact<{
  userId: Scalars['String'];
  fileId: Scalars['String'];
}>;


export type GetSignedUrlFromStorageByAdminMutation = { __typename?: 'mutation_root', GetSignUrlFromStorageByAdmin?: { __typename?: 'SuccessGetSignUrlFormStorage', status: number, url: string } | null };

export type UploadFileToStorageMutationVariables = Exact<{
  file: UploadFileToStorageInput;
}>;


export type UploadFileToStorageMutation = { __typename?: 'mutation_root', UploadFileToStorage?: { __typename?: 'SuccessUploadedFileToStorage', status: number, file: { __typename?: 'FileStorage', url: string, fileName: string, fileId: string } } | null };

export type UploadVonageFileToStorageMutationVariables = Exact<{
  file: UploadVonageFileToStorageInput;
  appointmentId: Scalars['String'];
}>;


export type UploadVonageFileToStorageMutation = { __typename?: 'mutation_root', UploadVonageFileToStorage?: { __typename?: 'SuccessUploadedVonageFileToStorage', status: number, file: { __typename?: 'FileVonageStorage', url: string, fileName: string, fileId: string } } | null };

export type GetVonageSignUrlFormStorageMutationVariables = Exact<{
  appointmentId: Scalars['String'];
  fileId: Scalars['String'];
}>;


export type GetVonageSignUrlFormStorageMutation = { __typename?: 'mutation_root', GetVonageSignUrlFormStorage?: { __typename?: 'SuccessGetVonageSignUrlFormStorage', status: number, url: string } | null };

export type GetProviderPatientSignedUrlMutationVariables = Exact<{
  patientCodexId: Scalars['String'];
  fileId: Scalars['String'];
}>;


export type GetProviderPatientSignedUrlMutation = { __typename?: 'mutation_root', GetProviderPatientSignedUrl?: { __typename?: 'SuccessGetSignUrlFormStorage', status: number, url: string } | null };

export type GetSignUrlFromStorageForRequesterMutationVariables = Exact<{
  fileId: Scalars['String'];
  requestedUserId: Scalars['String'];
}>;


export type GetSignUrlFromStorageForRequesterMutation = { __typename?: 'mutation_root', GetSignUrlFromStorageForRequester?: { __typename?: 'SuccessGetSignUrlFormStorage', status: number, url: string } | null };

export type GetFileByIdQueryVariables = Exact<{
  fileId: Scalars['uuid'];
}>;


export type GetFileByIdQuery = { __typename?: 'query_root', files_by_pk?: { __typename?: 'files', id: any, file_name: string, created_at: any } | null };

export type GetBarcodeScanningDisabledStateSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type GetBarcodeScanningDisabledStateSubscription = { __typename?: 'subscription_root', global_configs: Array<{ __typename?: 'global_configs', value: any }> };

export type GetInactivityModalVariablesSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type GetInactivityModalVariablesSubscription = { __typename?: 'subscription_root', global_configs: Array<{ __typename?: 'global_configs', value: any }> };

export type GetUserLogoutBasedOnLastActivityVariablesSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type GetUserLogoutBasedOnLastActivityVariablesSubscription = { __typename?: 'subscription_root', global_configs: Array<{ __typename?: 'global_configs', value: any }> };

export type GetMyVisitSummaryModalDisabledStateSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type GetMyVisitSummaryModalDisabledStateSubscription = { __typename?: 'subscription_root', global_configs: Array<{ __typename?: 'global_configs', value: any }> };

export type GetAppointmentsDisabledStateSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type GetAppointmentsDisabledStateSubscription = { __typename?: 'subscription_root', global_configs: Array<{ __typename?: 'global_configs', value: any }> };

export type GetSignUpProviderRouteStateSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type GetSignUpProviderRouteStateSubscription = { __typename?: 'subscription_root', global_configs: Array<{ __typename?: 'global_configs', value: any }> };

export type GetChannelSignalsSubscriptionVariables = Exact<{
  channel: Scalars['String'];
  key: Scalars['String'];
}>;


export type GetChannelSignalsSubscription = { __typename?: 'subscription_root', channel_signals: Array<{ __typename?: 'channel_signals', last_updated: any }> };

export type AcceptLegalDocumentMutationVariables = Exact<{
  hash_content: Scalars['String'];
  legal_document_type: Scalars['String'];
}>;


export type AcceptLegalDocumentMutation = { __typename?: 'mutation_root', AcceptLegalDocument?: { __typename?: 'SuccessfulAcceptedLegalDocument', message: string, status: number } | null };

export type GetAcceptedLegalDocumentsQueryVariables = Exact<{
  user_id: Scalars['uuid'];
}>;


export type GetAcceptedLegalDocumentsQuery = { __typename?: 'query_root', accepted_legal_documents: Array<{ __typename?: 'accepted_legal_documents', accepted_hash_content: string }> };

export type GetLegalDocumentAcceptanceByLegalDocumentTypeQueryVariables = Exact<{
  user_id: Scalars['uuid'];
  legal_document_type: Legal_Document_Types_Enum;
}>;


export type GetLegalDocumentAcceptanceByLegalDocumentTypeQuery = { __typename?: 'query_root', accepted_legal_documents: Array<{ __typename?: 'accepted_legal_documents', termination_date?: any | null, expiration_date?: any | null, accepted_hash_content: string }> };

export type UpdateInAppNotificationMutationVariables = Exact<{
  id: Scalars['String'];
  status: Scalars['String'];
}>;


export type UpdateInAppNotificationMutation = { __typename?: 'mutation_root', UpdateInAppNotification?: { __typename?: 'SuccessfulUpdateInAppNotification', status: number, message: string } | null };

export type UpdateProfileNotificationMutationVariables = Exact<{ [key: string]: never; }>;


export type UpdateProfileNotificationMutation = { __typename?: 'mutation_root', UpdateProfileNotification?: { __typename?: 'SuccessfulUpdateProfileNotification', status: number, message: string } | null };

export type GetUserNotificationByUserIdSubscriptionVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Order_By>;
  includeStatuses?: InputMaybe<Array<User_Notification_Status_Enum> | User_Notification_Status_Enum>;
  includePriorities?: InputMaybe<Array<Scalars['Boolean']> | Scalars['Boolean']>;
  sinceFrom?: InputMaybe<Scalars['timestamptz']>;
}>;


export type GetUserNotificationByUserIdSubscription = { __typename?: 'subscription_root', user_notifications: Array<{ __typename?: 'user_notifications', data?: any | null, id: any, status: User_Notification_Status_Enum, user_communication_delivery_id: any, user_id: any }> };

export type GetUserNotificationCountsByUserIdSubscriptionVariables = Exact<{
  includeStatuses?: InputMaybe<Array<User_Notification_Status_Enum> | User_Notification_Status_Enum>;
  includePriorities?: InputMaybe<Array<Scalars['Boolean']> | Scalars['Boolean']>;
  sinceFrom?: InputMaybe<Scalars['timestamptz']>;
}>;


export type GetUserNotificationCountsByUserIdSubscription = { __typename?: 'subscription_root', user_notifications_aggregate: { __typename?: 'user_notifications_aggregate', aggregate?: { __typename?: 'user_notifications_aggregate_fields', count: number } | null } };

export type GetUserNotificationCountsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type GetUserNotificationCountsSubscription = { __typename?: 'subscription_root', user_notifications_aggregate: { __typename?: 'user_notifications_aggregate', aggregate?: { __typename?: 'user_notifications_aggregate_fields', count: number } | null } };

export type GetExternalNotificationCountsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type GetExternalNotificationCountsSubscription = { __typename?: 'subscription_root', provider_unread_external_notification_counts: Array<{ __typename?: 'provider_unread_external_notification_counts', dosespot_unread_notifications: any }> };

export type SignupProviderMutationVariables = Exact<{
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  nationalProviderIdentifier: Scalars['String'];
  gender: Scalars['String'];
  languagesOfCommunication: Scalars['jsonb'];
  about?: InputMaybe<Scalars['String']>;
  credentials: Scalars['jsonb'];
  education: Scalars['jsonb'];
  office_information: Scalars['jsonb'];
  address_information: Scalars['jsonb'];
  firebaseToken: Scalars['String'];
}>;


export type SignupProviderMutation = { __typename?: 'mutation_root', SignupProvider?: { __typename?: 'SuccessfulSignup', message: string, status: number } | null };

export type GetDoseSpotPractitionerUrlMutationVariables = Exact<{
  providerId: Scalars['String'];
  encounterId?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['String']>;
  showNotifications: Scalars['Boolean'];
}>;


export type GetDoseSpotPractitionerUrlMutation = { __typename?: 'mutation_root', GetDoseSpotPractitionerURL?: { __typename?: 'DoseSpotURLResult', url?: string | null } | null };

export type GetDosespotExternalUnreadNotificationQueryVariables = Exact<{
  codexProviderId: Scalars['uuid'];
}>;


export type GetDosespotExternalUnreadNotificationQuery = { __typename?: 'query_root', provider_unread_external_notification_counts: Array<{ __typename?: 'provider_unread_external_notification_counts', dosespot_unread_notifications: any }> };

export type SaveQuestionnaireMutationVariables = Exact<{
  questionnaireId: Scalars['Int'];
  responses: Scalars['jsonb'];
  is_complete?: InputMaybe<Scalars['Boolean']>;
}>;


export type SaveQuestionnaireMutation = { __typename?: 'mutation_root', SaveQuestionnaire?: { __typename?: 'SuccessfulQuestionnaireSave', status: number, message: string } | null };

export type SaveQuestionnaireManuallyMutationVariables = Exact<{
  questionnaireId: Scalars['Int'];
  userID: Scalars['uuid'];
  responses: Scalars['jsonb'];
  isComplete: Scalars['Boolean'];
}>;


export type SaveQuestionnaireManuallyMutation = { __typename?: 'mutation_root', insert_questionnaire_responses_one?: { __typename?: 'questionnaire_responses', id: any, responses: any } | null };

export type UpdateQuestionnaireMutationVariables = Exact<{
  questionnaireId: Scalars['Int'];
  responses: Scalars['jsonb'];
}>;


export type UpdateQuestionnaireMutation = { __typename?: 'mutation_root', UpdateQuestionnaire?: { __typename?: 'SuccessfulQuestionnaireUpdate', status: number, message: string } | null };

export type UpdateUserQuestionnaireResponseMutationVariables = Exact<{
  newResponse: Scalars['jsonb'];
  userID: Scalars['uuid'];
  questionnaireID: Scalars['Int'];
  is_complete?: InputMaybe<Scalars['Boolean']>;
}>;


export type UpdateUserQuestionnaireResponseMutation = { __typename?: 'mutation_root', update_questionnaire_responses?: { __typename?: 'questionnaire_responses_mutation_response', returning: Array<{ __typename?: 'questionnaire_responses', responses: any }> } | null };

export type UpdateUserLatestQuestionnaireMutationVariables = Exact<{
  id: Scalars['bigint'];
  responses: Scalars['jsonb'];
  isComplete: Scalars['Boolean'];
}>;


export type UpdateUserLatestQuestionnaireMutation = { __typename?: 'mutation_root', update_questionnaire_responses?: { __typename?: 'questionnaire_responses_mutation_response', affected_rows: number } | null };

export type GetQuestionnaireByIdQueryVariables = Exact<{
  questionnaire_id: Scalars['Int'];
}>;


export type GetQuestionnaireByIdQuery = { __typename?: 'query_root', questionnaires: Array<{ __typename?: 'questionnaires', title: string, subtitle?: string | null, data: any, questionnaires_questionnaire_questions: Array<{ __typename?: 'questionnaire_questions', questionnaire_questions_question: { __typename?: 'questions', id: number, text?: string | null, subtitle?: string | null, answers?: any | null, ui_control: Question_Ui_Control_Types_Enum, dont_know_answer?: string | null, double_text_combo_helper_texts?: any | null, dropdown_combo_answers?: any | null, dropdown_label_1?: string | null, dropdown_label_2?: string | null, measurement_unit?: Question_Measurement_Units_Enum | null, response_property?: string | null } }> }> };

export type GetQuestionnaireByUserIdAndQuestionnaireIdQueryVariables = Exact<{
  user_id: Scalars['uuid'];
  questionnaire_id?: InputMaybe<Scalars['Int']>;
}>;


export type GetQuestionnaireByUserIdAndQuestionnaireIdQuery = { __typename?: 'query_root', questionnaire_responses: Array<{ __typename?: 'questionnaire_responses', is_complete: boolean }> };

export type GetQuestionnaireResponsesByUserAndQuestionnaireIdQueryVariables = Exact<{
  questionnaire_id: Scalars['Int'];
  user_id: Scalars['uuid'];
}>;


export type GetQuestionnaireResponsesByUserAndQuestionnaireIdQuery = { __typename?: 'query_root', questionnaire_responses: Array<{ __typename?: 'questionnaire_responses', responses: any }> };

export type GetLatestQuestionnaireByIdQueryVariables = Exact<{
  questionnaire_id: Scalars['Int'];
}>;


export type GetLatestQuestionnaireByIdQuery = { __typename?: 'query_root', questionnaire_responses: Array<{ __typename?: 'questionnaire_responses', responses: any, id: any }> };

export type UploadCapturedImageMutationVariables = Exact<{
  image: Scalars['String'];
  filename?: InputMaybe<Scalars['String']>;
  source: Scalars['String'];
  token?: InputMaybe<Scalars['String']>;
}>;


export type UploadCapturedImageMutation = { __typename?: 'mutation_root', UploadCapturedImage?: { __typename?: 'SuccessfulUploadedImage', sessionId: string, message: string, status: number } | null };

export type GetFileUrlFromStorageMutationVariables = Exact<{
  fileId: Scalars['String'];
}>;


export type GetFileUrlFromStorageMutation = { __typename?: 'mutation_root', GetSignUrlFormStorage?: { __typename?: 'SuccessGetSignUrlFormStorage', url: string, status: number } | null };

export type GetSkintellingentTestByUserIdQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  fromDate?: InputMaybe<Scalars['timestamptz']>;
  toDate?: InputMaybe<Scalars['timestamptz']>;
  orderBy?: InputMaybe<Array<Skintelligent_Tests_Order_By> | Skintelligent_Tests_Order_By>;
}>;


export type GetSkintellingentTestByUserIdQuery = { __typename?: 'query_root', skintelligent_tests: Array<{ __typename?: 'skintelligent_tests', test_id: any, inflammatory?: number | null, comedone?: number | null, pih?: number | null, total_lesions?: number | null, report_id?: any | null, result_image_id?: any | null, skintelligent_tests_test: { __typename?: 'tests', created_at: any }, skintelligent_tests_image_source_type?: { __typename?: 'image_source_types', value: string } | null }>, skintelligent_tests_aggregate: { __typename?: 'skintelligent_tests_aggregate', aggregate?: { __typename?: 'skintelligent_tests_aggregate_fields', count: number } | null } };

export type GetSkintellingentTestsCountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSkintellingentTestsCountQuery = { __typename?: 'query_root', skintelligent_tests_aggregate: { __typename?: 'skintelligent_tests_aggregate', aggregate?: { __typename?: 'skintelligent_tests_aggregate_fields', count: number } | null } };

export type GetSkintellingentTestBySessionIdSubscriptionVariables = Exact<{
  sessionId?: InputMaybe<Scalars['String']>;
}>;


export type GetSkintellingentTestBySessionIdSubscription = { __typename?: 'subscription_root', skintelligent_tests: Array<{ __typename?: 'skintelligent_tests', test_id: any, session_id: string, result_image_id?: any | null, report_id?: any | null, pih?: number | null, overall_score?: any | null, inflammatory?: number | null, image_source?: Image_Source_Types_Enum | null, errors?: any | null, comedone?: number | null, total_lesions?: number | null, result_processed?: boolean | null, skintelligent_tests_image_source_type?: { __typename?: 'image_source_types', value: string, comment?: string | null } | null, skintelligent_tests_test: { __typename?: 'tests', created_at: any }, skintelligent_tests_result_image?: { __typename?: 'files', id: any, type: File_Types_Enum } | null }> };

export type GetSkintellingentTestsByUserIdSubscriptionVariables = Exact<{
  userId: Scalars['uuid'];
}>;


export type GetSkintellingentTestsByUserIdSubscription = { __typename?: 'subscription_root', skintelligent_tests_aggregate: { __typename?: 'skintelligent_tests_aggregate', nodes: Array<{ __typename?: 'skintelligent_tests', session_id: string }> } };

export type UpdateProviderApprovalMutationVariables = Exact<{
  codexId: Scalars['String'];
  approved: Scalars['Boolean'];
}>;


export type UpdateProviderApprovalMutation = { __typename?: 'mutation_root', patchCodexApproval: { __typename?: 'PatchFHIRProviderOutput', update_providers_one: { __typename?: 'ProviderOutput', codex_approved: boolean } } };

export type PatchFhirProviderMutationVariables = Exact<{
  codexId: Scalars['String'];
  input: PatchFhirProviderInput;
}>;


export type PatchFhirProviderMutation = { __typename?: 'mutation_root', saPatchFHIRProvider: { __typename?: 'PatchFHIRProviderOutput', update_providers_one: { __typename?: 'ProviderOutput', id: string } } };

export type GetDiagnosticReportsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  pageToken?: InputMaybe<Scalars['String']>;
  patientFhirId?: InputMaybe<Scalars['String']>;
  providerFhirId?: InputMaybe<Scalars['String']>;
  types?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetDiagnosticReportsQuery = { __typename?: 'query_root', getDiagnosticReports: { __typename?: 'SADiagnosticReports', count: number, total: string, nextPageToken?: string | null, prevPageToken?: string | null, reports: Array<{ __typename?: 'SADiagnosticReport', dates: Array<Array<string>>, type: Hasura_Test_Types_Enum, identifiers: Array<Array<string>>, status: string, artifacts: Array<Array<string>>, flags: Array<CodexTags>, patient?: { __typename?: 'SAPerson', identifiers: Array<Array<string>>, first: string, last: string } | null, provider?: { __typename?: 'SAPerson', identifiers: Array<Array<string>>, first: string, last: string } | null }> } };

export type GetAppointmentsQueryVariables = Exact<{
  patientFhirId?: InputMaybe<Scalars['String']>;
  providerFhirId?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetAppointmentsQuery = { __typename?: 'query_root', getAppointments: { __typename?: 'SAAppointments', count: number, total: string, nextPageToken?: string | null, prevPageToken?: string | null, appointments: Array<{ __typename?: 'SAAppointment', identifiers: Array<Array<string>>, type?: AppointmentTypeEnum | null, dates: Array<Array<string>>, status: string, logs: Array<Array<string>>, patient?: { __typename?: 'SAPerson', identifiers: Array<Array<string>>, first: string, last: string } | null, provider?: { __typename?: 'SAPerson', identifiers: Array<Array<string>>, first: string, last: string } | null }> } };

export type GetPatientsQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']>;
}>;


export type GetPatientsQuery = { __typename?: 'query_root', getPatients: { __typename?: 'SAPatients', count: number, nextPageToken?: string | null, prevPageToken?: string | null, total: string, patients: Array<{ __typename?: 'SAPatient', dates: Array<Array<string>>, appointmentCount: Array<Array<string>>, testKitCount: number, identifiers: Array<Array<string>>, first: string, last: string, nextAppointment?: { __typename?: 'SAAppointmentBrief', identifiers: Array<Array<string>>, dates: Array<Array<string>> } | null }> } };

export type GetProvidersQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']>;
}>;


export type GetProvidersQuery = { __typename?: 'query_root', getProviders: { __typename?: 'SAProviders', count: number, nextPageToken?: string | null, prevPageToken?: string | null, total: string, providers: Array<{ __typename?: 'SAProvider', dates: Array<Array<string>>, appointmentCount: Array<Array<string>>, first: string, flags: Array<CodexTags>, identifiers: Array<Array<string>>, last: string, patientCount: number }> } };

export type GetSwabCodeValidBySwabcodeQueryVariables = Exact<{
  swabcode: Scalars['String'];
}>;


export type GetSwabCodeValidBySwabcodeQuery = { __typename?: 'query_root', GetSwabCodeValidBySwabcode?: { __typename?: 'IsValidSwabCode', isValid: boolean, id: string, lab_name: string, status: string, swab_code: string, swab_usage_type: string, test_type: string } | null };

export type GetUnauthenticatedQuestionnaireByIdQueryVariables = Exact<{
  questionnaire_id: Scalars['Int'];
}>;


export type GetUnauthenticatedQuestionnaireByIdQuery = { __typename?: 'query_root', unauthenticated_questionnaires: Array<{ __typename?: 'unauthenticated_questionnaires', title: string, subtitle?: string | null, data: any, unauthenticated_questionnaire_questions: Array<{ __typename?: 'unauthenticated_questionnaire_questions', unauthenticated_questionnaire_questions_unauthenticated_question: { __typename?: 'unauthenticated_questions', id: number, text: string, subtitle?: string | null, answers?: any | null, ui_control: Question_Ui_Control_Types_Enum, dont_know_answer?: string | null, double_text_combo_helper_texts?: any | null, dropdown_combo_answers?: any | null, dropdown_label_1?: string | null, dropdown_label_2?: string | null, response_property?: string | null, has_tooltip?: boolean | null, tooltip_content?: string | null } }> }> };

export type GetUnauthenticatedQuestionsByIdsQueryVariables = Exact<{
  ids: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type GetUnauthenticatedQuestionsByIdsQuery = { __typename?: 'query_root', unauthenticated_questions: Array<{ __typename?: 'unauthenticated_questions', id: number, text: string, subtitle?: string | null, answers?: any | null }> };

export type SaveDnaScannedDataMutationVariables = Exact<{
  testType?: InputMaybe<Scalars['String']>;
  swabBarcode?: InputMaybe<Scalars['String']>;
  trackingNumber?: InputMaybe<Scalars['String']>;
  skipTrackingNumber?: InputMaybe<Scalars['Boolean']>;
  token?: InputMaybe<Scalars['String']>;
}>;


export type SaveDnaScannedDataMutation = { __typename?: 'mutation_root', SaveDnaScannedData?: { __typename?: 'SuccessfulSavedScannedData', message: string, status: number } | null };

export type ClearAllSavedDnaScannedDataForUserIdMutationVariables = Exact<{
  userId: Scalars['uuid'];
}>;


export type ClearAllSavedDnaScannedDataForUserIdMutation = { __typename?: 'mutation_root', delete_user_scanned_data?: { __typename?: 'user_scanned_data_mutation_response', affected_rows: number } | null };

export type GetDnaScannedDataByUserIdSubscriptionVariables = Exact<{
  user_id: Scalars['uuid'];
}>;


export type GetDnaScannedDataByUserIdSubscription = { __typename?: 'subscription_root', user_scanned_data: Array<{ __typename?: 'user_scanned_data', test_type?: string | null, swab_barcode?: string | null, tracking_number?: string | null, skip_tracking_number?: boolean | null, scan_completed?: boolean | null, updated_at?: any | null }> };

export type SignupUserMutationVariables = Exact<{
  dob?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firebaseToken: Scalars['String'];
  firstname?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  lastname?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
}>;


export type SignupUserMutation = { __typename?: 'mutation_root', SignupUser?: { __typename?: 'SuccessfulSignup', message: string, status: number } | null };

export type GenerateFirebaseCustomTokenMutationVariables = Exact<{
  firebaseToken: Scalars['String'];
}>;


export type GenerateFirebaseCustomTokenMutation = { __typename?: 'mutation_root', GenerateFirebaseCustomToken?: { __typename?: 'SuccessfulGeneratedFirebaseCustomToken', token: string, message: string, status: number } | null };

export type GenerateCustomTokenMutationVariables = Exact<{ [key: string]: never; }>;


export type GenerateCustomTokenMutation = { __typename?: 'mutation_root', GenerateCustomToken?: { __typename?: 'SuccessfulGeneratedCustomToken', token: string, message: string, status: number } | null };

export type ValidateCustomTokenMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type ValidateCustomTokenMutation = { __typename?: 'mutation_root', ValidateCustomToken?: { __typename?: 'SuccessfulValidatedCustomToken', message: string, status: number } | null };

export type AccountDeletionMutationVariables = Exact<{
  token: Scalars['String'];
  userId: Scalars['String'];
}>;


export type AccountDeletionMutation = { __typename?: 'mutation_root', AccountDeletion?: { __typename?: 'SuccessfulAccountDeletion', status: number, message: string } | null };

export type UpdatePersonalInfoMutationVariables = Exact<{
  id: Scalars['uuid'];
  SENSITIVE_dob?: InputMaybe<Scalars['date']>;
  SENSITIVE_etnicity?: InputMaybe<Scalars['jsonb']>;
  SENSITIVE_firstname?: InputMaybe<Scalars['String']>;
  SENSITIVE_gender?: InputMaybe<Scalars['String']>;
  SENSITIVE_lastname?: InputMaybe<Scalars['String']>;
  SENSITIVE_self_identity_gender?: InputMaybe<Scalars['String']>;
  SENSITIVE_user_measurements?: InputMaybe<Scalars['jsonb']>;
}>;


export type UpdatePersonalInfoMutation = { __typename?: 'mutation_root', update_users_by_pk?: { __typename?: 'users', SENSITIVE_address_information?: any | null, SENSITIVE_country?: string | null, SENSITIVE_dob?: any | null, SENSITIVE_email: string, SENSITIVE_etnicity?: any | null, SENSITIVE_firstname?: string | null, SENSITIVE_gender?: string | null, SENSITIVE_lastname?: string | null, SENSITIVE_phone?: string | null, SENSITIVE_self_identity_gender?: string | null, SENSITIVE_user_measurements?: any | null, id: any } | null };

export type UpdateContactInfoMutationVariables = Exact<{
  id: Scalars['uuid'];
  SENSITIVE_address_information?: InputMaybe<Scalars['jsonb']>;
  SENSITIVE_phone?: InputMaybe<Scalars['String']>;
  SENSITIVE_country?: InputMaybe<Scalars['String']>;
}>;


export type UpdateContactInfoMutation = { __typename?: 'mutation_root', update_users_by_pk?: { __typename?: 'users', SENSITIVE_address_information?: any | null, SENSITIVE_country?: string | null, SENSITIVE_dob?: any | null, SENSITIVE_email: string, SENSITIVE_etnicity?: any | null, SENSITIVE_firstname?: string | null, SENSITIVE_gender?: string | null, SENSITIVE_lastname?: string | null, SENSITIVE_phone?: string | null, SENSITIVE_self_identity_gender?: string | null, SENSITIVE_user_measurements?: any | null, id: any } | null };

export type UpdateProfileInfoMutationVariables = Exact<{
  id: Scalars['uuid'];
  SENSITIVE_dob?: InputMaybe<Scalars['date']>;
  SENSITIVE_etnicity?: InputMaybe<Scalars['jsonb']>;
  SENSITIVE_firstname?: InputMaybe<Scalars['String']>;
  SENSITIVE_gender?: InputMaybe<Scalars['String']>;
  SENSITIVE_lastname?: InputMaybe<Scalars['String']>;
  SENSITIVE_self_identity_gender?: InputMaybe<Scalars['String']>;
  SENSITIVE_user_measurements?: InputMaybe<Scalars['jsonb']>;
  SENSITIVE_address_information?: InputMaybe<Scalars['jsonb']>;
  SENSITIVE_phone?: InputMaybe<Scalars['String']>;
  SENSITIVE_country?: InputMaybe<Scalars['String']>;
}>;


export type UpdateProfileInfoMutation = { __typename?: 'mutation_root', update_users_by_pk?: { __typename?: 'users', SENSITIVE_address_information?: any | null, SENSITIVE_country?: string | null, SENSITIVE_dob?: any | null, SENSITIVE_email: string, SENSITIVE_etnicity?: any | null, SENSITIVE_firstname?: string | null, SENSITIVE_gender?: string | null, SENSITIVE_lastname?: string | null, SENSITIVE_phone?: string | null, SENSITIVE_self_identity_gender?: string | null, SENSITIVE_user_measurements?: any | null, id: any } | null };

export type UpdatePhoneAndCountryByUserIdMutationVariables = Exact<{
  id: Scalars['uuid'];
  SENSITIVE_phone: Scalars['String'];
  SENSITIVE_country: Scalars['String'];
}>;


export type UpdatePhoneAndCountryByUserIdMutation = { __typename?: 'mutation_root', update_users_by_pk?: { __typename?: 'users', id: any, SENSITIVE_country?: string | null, SENSITIVE_phone?: string | null, updated_at: any } | null };

export type UpdateProfilePictureMutationVariables = Exact<{
  image: Scalars['String'];
  filename?: InputMaybe<Scalars['String']>;
}>;


export type UpdateProfilePictureMutation = { __typename?: 'mutation_root', UpdateProfilePicture?: { __typename?: 'SuccessfullUpdatedProfilePicture', message: string, status: number, fileId: string } | null };

export type UpdateProviderProfilePictureMutationVariables = Exact<{
  image: Scalars['String'];
  filename?: InputMaybe<Scalars['String']>;
}>;


export type UpdateProviderProfilePictureMutation = { __typename?: 'mutation_root', UpdateProviderProfilePicture?: { __typename?: 'SuccessfullUpdatedProfilePicture', message: string, status: number, fileId: string } | null };

export type GenerateSessionTokenForPersonaMutationVariables = Exact<{
  inquiryId?: InputMaybe<Scalars['String']>;
}>;


export type GenerateSessionTokenForPersonaMutation = { __typename?: 'mutation_root', GetPersonaSessionToken: string };

export type UpdateAccountSettingsMutationVariables = Exact<{
  id: Scalars['uuid'];
  EMAIL_MONTHLY_NEWSLETTER?: InputMaybe<Scalars['Boolean']>;
  EMAIL_NEW_FEATURES_SERVICES_SETTINGS?: InputMaybe<Scalars['Boolean']>;
  EMAIL_SPECIAL_OFFERS_SETTING?: InputMaybe<Scalars['Boolean']>;
  EMAIL_SPECIAL_OFFER_FRIENDS_SETTING?: InputMaybe<Scalars['Boolean']>;
  EMAIL_UNSUBSCRIBED?: InputMaybe<Scalars['Boolean']>;
  PRIVACY_AFFILIATES_CODEX_LABS_SETTING?: InputMaybe<Scalars['Boolean']>;
  PRIVACY_NON_AFFILIATES_CODEX_LABS_SETTING?: InputMaybe<Scalars['Boolean']>;
  PRIVACY_CALIFORNIA_SETTINGS?: InputMaybe<Scalars['Boolean']>;
  AUTO_APPROVAL_PATIENTS_CODEX_LABS_SETTING?: InputMaybe<Scalars['Boolean']>;
  AUTO_APPROVAL_TESTKIT_SCRIPTS_CODEX_LABS_SETTING?: InputMaybe<Scalars['Boolean']>;
  ALERT_SETTINGS?: InputMaybe<Scalars['jsonb']>;
}>;


export type UpdateAccountSettingsMutation = { __typename?: 'mutation_root', update_users_by_pk?: { __typename?: 'users', EMAIL_MONTHLY_NEWSLETTER: boolean, EMAIL_NEW_FEATURES_SERVICES_SETTINGS: boolean, EMAIL_SPECIAL_OFFERS_SETTING: boolean, EMAIL_SPECIAL_OFFER_FRIENDS_SETTING: boolean, EMAIL_UNSUBSCRIBED: boolean, PRIVACY_AFFILIATES_CODEX_LABS_SETTING: boolean, PRIVACY_NON_AFFILIATES_CODEX_LABS_SETTING: boolean, PRIVACY_CALIFORNIA_SETTINGS: boolean, AUTO_APPROVAL_PATIENTS_CODEX_LABS_SETTING: boolean, AUTO_APPROVAL_TESTKIT_SCRIPTS_CODEX_LABS_SETTING: boolean, ALERT_SETTINGS: any } | null };

export type UpdateCustomAuthCodeAsUsedMutationVariables = Exact<{
  email: Scalars['String'];
  code: Scalars['String'];
}>;


export type UpdateCustomAuthCodeAsUsedMutation = { __typename?: 'mutation_root', update_custom_auth_code?: { __typename?: 'custom_auth_code_mutation_response', affected_rows: number } | null };

export type GetUsersByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetUsersByIdQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'users', id: any, SENSITIVE_phone?: string | null, SENSITIVE_lastname?: string | null, SENSITIVE_gender?: string | null, SENSITIVE_firstname?: string | null, SENSITIVE_email: string, SENSITIVE_dob?: any | null, SENSITIVE_country?: string | null, SENSITIVE_self_identity_gender?: string | null, SENSITIVE_user_measurements?: any | null, SENSITIVE_etnicity?: any | null, SENSITIVE_address_information?: any | null, SENSITIVE_profile_picture_id?: any | null }> };

export type GetUsersByEmailQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type GetUsersByEmailQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'users', id: any, SENSITIVE_dob?: any | null, SENSITIVE_email: string, SENSITIVE_firstname?: string | null, SENSITIVE_gender?: string | null, SENSITIVE_phone?: string | null, SENSITIVE_country?: string | null, SENSITIVE_lastname?: string | null, SENSITIVE_self_identity_gender?: string | null, SENSITIVE_user_measurements?: any | null, SENSITIVE_etnicity?: any | null, SENSITIVE_address_information?: any | null, SENSITIVE_profile_picture_id?: any | null }> };

export type GetUsersVerificationStatusByEmailSubscriptionVariables = Exact<{
  email: Scalars['String'];
}>;


export type GetUsersVerificationStatusByEmailSubscription = { __typename?: 'subscription_root', users: Array<{ __typename?: 'users', kyc_current_status?: Persona_Inquiry_Status_Enum | null, kyc_update_date?: any | null }> };

export type GetPersonaVerificationStatusByEmailQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type GetPersonaVerificationStatusByEmailQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'users', kyc_current_status?: Persona_Inquiry_Status_Enum | null, kyc_update_date?: any | null }> };

export type GetUserPersonaInquiryIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetUserPersonaInquiryIdQuery = { __typename?: 'query_root', persona_inquiries: Array<{ __typename?: 'persona_inquiries', persona_inquiry_id: string }> };

export type GetUserEmailPreferencesQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetUserEmailPreferencesQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'users', EMAIL_NEW_FEATURES_SERVICES_SETTINGS: boolean, EMAIL_MONTHLY_NEWSLETTER: boolean, EMAIL_SPECIAL_OFFERS_SETTING: boolean, EMAIL_SPECIAL_OFFER_FRIENDS_SETTING: boolean, EMAIL_UNSUBSCRIBED: boolean }> };

export type GetUserPrivacyPreferencesQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetUserPrivacyPreferencesQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'users', PRIVACY_AFFILIATES_CODEX_LABS_SETTING: boolean, PRIVACY_NON_AFFILIATES_CODEX_LABS_SETTING: boolean, PRIVACY_CALIFORNIA_SETTINGS: boolean }> };

export type GetUserAutoApprovalPreferencesQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetUserAutoApprovalPreferencesQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'users', AUTO_APPROVAL_PATIENTS_CODEX_LABS_SETTING: boolean, AUTO_APPROVAL_TESTKIT_SCRIPTS_CODEX_LABS_SETTING: boolean }> };

export type GetUserAlertPreferencesQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetUserAlertPreferencesQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'users', ALERT_SETTINGS: any }> };

export type GetCustomAuthCodeToLoginQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type GetCustomAuthCodeToLoginQuery = { __typename?: 'query_root', GetCustomAuthCodeToLogin?: { __typename?: 'CustomAuthCode', success: boolean } | null };

export type GetCustomAuthTokenFromCustomAuthCodeQueryVariables = Exact<{
  customAuthCode: Scalars['String'];
  email: Scalars['String'];
}>;


export type GetCustomAuthTokenFromCustomAuthCodeQuery = { __typename?: 'query_root', GetCustomAuthTokenFromCustomAuthCode: { __typename?: 'CustomAuthTokenFromCustomAuthCode', token: string } };

export type RegisterAuthProviderMutationVariables = Exact<{
  provider_type: Scalars['String'];
}>;


export type RegisterAuthProviderMutation = { __typename?: 'mutation_root', RegisterAuthProvider?: { __typename?: 'SuccessfulAuthProvider', message: string, status: number } | null };

export type VonageArchiveStartMutationVariables = Exact<{
  sessionId: Scalars['String'];
}>;


export type VonageArchiveStartMutation = { __typename?: 'mutation_root', VonageArchiveStart?: { __typename?: 'SuccessfulVonageArchiveStart', archiveId: string, status: string } | null };

export type VonageArchiveStopMutationVariables = Exact<{
  archiveId: Scalars['String'];
}>;


export type VonageArchiveStopMutation = { __typename?: 'mutation_root', VonageArchiveStop?: { __typename?: 'SuccessfulVonageArchiveStop', archiveId: string, status: string } | null };

export type GetVonageArchivesMutationVariables = Exact<{
  sessionId: Scalars['String'];
}>;


export type GetVonageArchivesMutation = { __typename?: 'mutation_root', GetVonageArchives?: { __typename?: 'SuccessfulGetVonageArchives', archives?: Array<any> | null } | null };

export type GetVonageSessionMutationVariables = Exact<{
  room: Scalars['String'];
}>;


export type GetVonageSessionMutation = { __typename?: 'mutation_root', GetVonageSession?: { __typename?: 'SuccessGetVonageSession', sessionId: string, token: string, apiKey: string } | null };

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Byte: any;
  DateTime: any;
  JSON: any;
  JSONObject: any;
  _text: any;
  bigint: any;
  date: any;
  jsonb: any;
  numeric: any;
  timestamp: any;
  timestamptz: any;
  timetz: any;
  uuid: any;
};

/** The type of appointment event */
export enum Appointment_Event {
  AppointmentEnd = 'APPOINTMENT_END',
  AppointmentStart = 'APPOINTMENT_START',
  PatientJoined = 'PATIENT_JOINED',
  PatientLeft = 'PATIENT_LEFT',
  ProviderJoined = 'PROVIDER_JOINED',
  ProviderLeft = 'PROVIDER_LEFT'
}

/** The source of the appointment event */
export enum Appointment_Event_Source {
  Patient = 'PATIENT',
  Provider = 'PROVIDER',
  System = 'SYSTEM'
}

export type AcceptLegalDocumentInput = {
  hash_content: Scalars['String'];
  legal_document_type: Scalars['String'];
};

export type AccountDeletionInput = {
  token: Scalars['String'];
  userId: Scalars['String'];
};

export type AcuityCalendarResult = {
  __typename?: 'AcuityCalendarResult';
  active: Scalars['Boolean'];
  calendar_id: Scalars['Int'];
  created_at?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  owner_id?: Maybe<Scalars['String']>;
  provider_calendar: Scalars['String'];
  provider_id: Scalars['String'];
  updated_at?: Maybe<Scalars['String']>;
};

export type AcuityWorkingHoursPerDay = {
  __typename?: 'AcuityWorkingHoursPerDay';
  Friday: Array<Scalars['String']>;
  Monday: Array<Scalars['String']>;
  Saturday: Array<Scalars['String']>;
  Sunday: Array<Scalars['String']>;
  Thursday: Array<Scalars['String']>;
  Tuesday: Array<Scalars['String']>;
  Wednesday: Array<Scalars['String']>;
};

export type AddFhirHealthCareServiceInput = {
  offerings: Array<AddServiceOfferingInput>;
};

export type AddServiceOfferingInput = {
  active: Scalars['Boolean'];
  price: Scalars['String'];
  serviceType: ServiceType;
};

export type AddressInformationInput = {
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  province?: InputMaybe<Scalars['String']>;
  state: Scalars['String'];
  zipCode: Scalars['String'];
};

export type AddressInformationOutput = {
  __typename?: 'AddressInformationOutput';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type Annotation = {
  __typename?: 'Annotation';
  authorReference?: Maybe<ReferenceType>;
  authorString?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  time?: Maybe<Scalars['DateTime']>;
};

export type AppendEncounterOutput = {
  __typename?: 'AppendEncounterOutput';
  encounterId: Scalars['String'];
};

export type AppendFhirEncounterInput = {
  appointmentCodexId: Scalars['String'];
  providerNotes: CreateFhirEncounterNotesInput;
};

export type AppointmentChat = {
  __typename?: 'AppointmentChat';
  appointmentCodexId: Scalars['String'];
  id: Scalars['String'];
  messages: Array<AppointmentChatMessage>;
  patientId: Scalars['String'];
  providerId: Scalars['String'];
};

export type AppointmentChatMessage = {
  __typename?: 'AppointmentChatMessage';
  contentType?: Maybe<ContentType>;
  date: Scalars['String'];
  from: Scalars['String'];
  mediaUrl?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

/** Enum representing the possible values for "from" field in AppointmentChatMessage. */
export enum AppointmentChatMessageFrom {
  Patient = 'PATIENT',
  Provider = 'PROVIDER'
}

export type AppointmentChatMessageInput = {
  contentType?: InputMaybe<ContentType>;
  date?: InputMaybe<Scalars['String']>;
  from: AppointmentChatMessageFrom;
  mediaUrl?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
};

export type AppointmentEvent = {
  __typename?: 'AppointmentEvent';
  author: Appointment_Event_Source;
  date: Scalars['String'];
  disconnectReason?: Maybe<VonageDisconnectReason>;
  event: Appointment_Event;
  fhirId: Scalars['String'];
};

export type AppointmentMediaInsert = {
  bodySite?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  mediaId: Scalars['String'];
};

/** Enum representing the possible values for "appointmentType" field in Appointment. */
export enum AppointmentTypeEnum {
  Chat = 'CHAT',
  Dnavisit = 'DNAVISIT',
  Email = 'EMAIL',
  Video = 'VIDEO'
}

export type Attachment = {
  __typename?: 'Attachment';
  contentType?: Maybe<ContentType>;
  creation?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  extension?: Maybe<Array<ResourceExtension>>;
  hash?: Maybe<Scalars['String']>;
  language?: Maybe<Array<Language>>;
  size?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type AuthProviderInput = {
  providerType: Scalars['String'];
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type CodeableConcept = {
  __typename?: 'CodeableConcept';
  coding?: Maybe<Array<Coding>>;
  text?: Maybe<Scalars['String']>;
};

export type CodeableConceptData = {
  __typename?: 'CodeableConceptData';
  display: Scalars['String'];
  system?: Maybe<Scalars['String']>;
};

export enum CodexTags {
  AcceptingPatients = 'AcceptingPatients',
  CodexApproved = 'CodexApproved',
  Enabled = 'Enabled',
  Errored = 'Errored',
  IdVerified = 'IdVerified',
  Ignored = 'Ignored',
  Test = 'Test'
}

export type Coding = {
  __typename?: 'Coding';
  code?: Maybe<Scalars['String']>;
  display?: Maybe<Scalars['String']>;
  system?: Maybe<Scalars['String']>;
};

export type Communication = {
  __typename?: 'Communication';
  encounter: Array<ReferenceType>;
  extension?: Maybe<Array<ResourceExtension>>;
  id: Scalars['String'];
  identifier?: Maybe<Array<Identifier>>;
  meta: Meta;
  note?: Maybe<Array<Annotation>>;
  payload?: Maybe<Array<CommunicationPayload>>;
  recipient: Array<ReferenceType>;
  resourceType: ResourcesTypes;
  sender: ReferenceType;
  text?: Maybe<Narrative>;
};

export type CommunicationPayload = {
  __typename?: 'CommunicationPayload';
  contentAttachment: Attachment;
};

/** Enum representing the possible values for "contentType" field in Attachment. */
export enum ContentType {
  Csv = 'CSV',
  Image = 'IMAGE',
  Json = 'JSON',
  Pdf = 'PDF'
}

export type CreateAppoinimentOutput = {
  __typename?: 'CreateAppoinimentOutput';
  appointmentCodexId: Scalars['String'];
  appointmentId: Scalars['String'];
  appointmentUrl: Scalars['String'];
  created: Scalars['String'];
  lastProcessedDate: Scalars['String'];
  start: Scalars['String'];
  status: Scalars['String'];
};

export type CreateDnaVisitOutput = {
  __typename?: 'CreateDnaVisitOutput';
  created_at: Scalars['String'];
  dna_test_questionnaire_pdf_id: Scalars['String'];
  dnavisit_order_id: Scalars['String'];
  health_questionnaire_pdf_id: Scalars['String'];
  sapphiros_barcode: Scalars['String'];
  test_id: Scalars['String'];
  test_type: Hasura_Test_Types_Enum;
  tracking_number?: Maybe<Scalars['String']>;
};

export type CreateEncounterOutput = {
  __typename?: 'CreateEncounterOutput';
  encounterId: Scalars['String'];
  status: Scalars['String'];
};

export type CreateFhirAppointmentInput = {
  appointmentNotes?: InputMaybe<FhirAppointmentNotesObservation>;
  appointmentType?: InputMaybe<AppointmentTypeEnum>;
  appointmentUrl?: InputMaybe<Scalars['String']>;
  endAt?: InputMaybe<Scalars['String']>;
  mediaToAppend?: InputMaybe<Array<Scalars['String']>>;
  mediaToInsert?: InputMaybe<Array<AppointmentMediaInsert>>;
  patientCodexId: Scalars['String'];
  providerId?: InputMaybe<Scalars['String']>;
  startAt: Scalars['String'];
  status?: InputMaybe<Scalars['String']>;
  subjectOfAppointment?: InputMaybe<Scalars['String']>;
  supportingInfo?: InputMaybe<Array<Scalars['String']>>;
  symptomsLocation?: InputMaybe<Array<Scalars['String']>>;
};

export type CreateFhirAppointmentInputWithoutPatientCodexId = {
  appointmentNotes?: InputMaybe<FhirAppointmentNotesObservation>;
  appointmentType?: InputMaybe<AppointmentTypeEnum>;
  appointmentUrl?: InputMaybe<Scalars['String']>;
  endAt?: InputMaybe<Scalars['String']>;
  mediaToAppend?: InputMaybe<Array<Scalars['String']>>;
  mediaToInsert?: InputMaybe<Array<AppointmentMediaInsert>>;
  providerId?: InputMaybe<Scalars['String']>;
  startAt: Scalars['String'];
  status?: InputMaybe<Scalars['String']>;
  subjectOfAppointment?: InputMaybe<Scalars['String']>;
  supportingInfo?: InputMaybe<Array<Scalars['String']>>;
  symptomsLocation?: InputMaybe<Array<Scalars['String']>>;
};

export type CreateFhirAppointmentOutput = {
  __typename?: 'CreateFHIRAppointmentOutput';
  appointment: CreateAppoinimentOutput;
};

export type CreateFhirDnaVisitTestInputWithResponses = {
  codexPatientId: Scalars['String'];
  codexProviderId?: InputMaybe<Scalars['String']>;
  dna_test_questionnaire_pdf_id: Scalars['String'];
  health_questionnaire_pdf_id: Scalars['String'];
  order_id: Scalars['String'];
  questionnaire_response: Scalars['JSON'];
  sapphiros_barcode: Scalars['String'];
  test_id: Scalars['String'];
  test_type: Hasura_Test_Types_Enum;
  tracking_number?: InputMaybe<Scalars['String']>;
};

export type CreateFhirDnaVisitTestOutput = {
  __typename?: 'CreateFHIRDnaVisitTestOutput';
  dnavisit_test: CreateDnaVisitOutput;
};

export type CreateFhirEncounterInput = {
  appointmentId: Scalars['String'];
  class: Scalars['String'];
  observationId?: InputMaybe<Scalars['String']>;
  patientId: Scalars['String'];
  providerId?: InputMaybe<Scalars['String']>;
  providerNotes?: InputMaybe<CreateFhirEncounterNotesInput>;
  serviceRequestId?: InputMaybe<Scalars['String']>;
  status: Scalars['String'];
};

export type CreateFhirEncounterNotesInput = {
  followupRecommendations: Scalars['String'];
  providerNotes: Scalars['String'];
  providerSummary: Scalars['String'];
};

export type CreateFhirEncounterOutput = {
  __typename?: 'CreateFHIREncounterOutput';
  encounter: CreateEncounterOutput;
};

export type CreateFhirOfficeLocationOutput = {
  __typename?: 'CreateFHIROfficeLocationOutput';
  insert_office_locations_one: CreateOfficeLocationOutput;
};

export type CreateFhirOrganizationInput = {
  address_information?: InputMaybe<AddressInformationInput>;
  email?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<OrganizationTypes>;
};

export type CreateFhirOrganizationOutput = {
  __typename?: 'CreateFHIROrganizationOutput';
  insert_organizations_one: CreateOrganizationOutput;
};

export type CreateFhirPatientInput = {
  codexUUID: Scalars['String'];
  country?: InputMaybe<Scalars['String']>;
  dob?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstname?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  lastname?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export type CreateFhirPatientOutput = {
  __typename?: 'CreateFHIRPatientOutput';
  insert_users_one: CreatePatientOutput;
};

export type CreateFhirProviderInput = {
  about?: InputMaybe<Scalars['String']>;
  address_information: AddressInformationInput;
  codexId: Scalars['String'];
  credentials: ProviderCredentialsInput;
  education: Array<ProviderEducationInput>;
  email: Scalars['String'];
  firstname: Scalars['String'];
  gender: Scalars['String'];
  languagesOfCommunication: Array<Scalars['String']>;
  lastname: Scalars['String'];
  nationalProviderIdentifier: Scalars['String'];
  office_information: OfficeAddressInformationInput;
  phone: Scalars['String'];
};

export type CreateFhirProviderOutput = {
  __typename?: 'CreateFHIRProviderOutput';
  insert_providers_one: ProviderOutput;
};

export type CreateFhirProviderRoleInput = {
  locationId: Scalars['String'];
  providerId: Scalars['String'];
};

export type CreateFhirProviderRoleOutput = {
  __typename?: 'CreateFHIRProviderRoleOutput';
  insert_provider_role_one: CreateProviderRoleOutput;
};

export type CreateFhirQuestionnaireResponsesOutput = {
  __typename?: 'CreateFHIRQuestionnaireResponsesOutput';
  questionnaire_response: CreateQuestionnaireResponsesOutput;
};

export type CreateFhirSkintelligentTestInput = {
  codexPatientId: Scalars['String'];
  codexProviderId?: InputMaybe<Scalars['String']>;
  image_source: Scalars['String'];
  original_image_id: Scalars['String'];
  session_id: Scalars['String'];
  test_id: Scalars['String'];
};

export type CreateFhirSkintelligentTestOutput = {
  __typename?: 'CreateFHIRSkintelligentTestOutput';
  skintelligent_test: CreateSkintelligentOutput;
};

export type CreateFhirZrtTestInput = {
  codexPatientId: Scalars['String'];
  codexProviderId?: InputMaybe<Scalars['String']>;
  health_questionnaire_pdf_id: Scalars['String'];
  questionnaire_response: Scalars['JSON'];
  reference_code: Scalars['String'];
  status: DiagnosticReportStatus;
  swab_code: Scalars['String'];
  swab_code_without_transform: Scalars['String'];
  test_type: Hasura_Test_Types_Enum;
  tracking_number?: InputMaybe<Scalars['String']>;
  zrt_questionnaire_response_id: Scalars['String'];
};

export type CreateFhirZrtTestOutput = {
  __typename?: 'CreateFHIRZrtTestOutput';
  zrt_test: CreateZrtOutput;
};

export type CreateOfficeLocationOutput = {
  __typename?: 'CreateOfficeLocationOutput';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  id: Scalars['String'];
  officeName?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  province?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type CreateOrganizationOutput = {
  __typename?: 'CreateOrganizationOutput';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type CreatePatientOutput = {
  __typename?: 'CreatePatientOutput';
  SENSITIVE_address_information?: Maybe<AddressInformationOutput>;
  SENSITIVE_country?: Maybe<Scalars['String']>;
  SENSITIVE_dob?: Maybe<Scalars['String']>;
  SENSITIVE_email: Scalars['String'];
  SENSITIVE_etnicity?: Maybe<Array<Scalars['String']>>;
  SENSITIVE_firstname?: Maybe<Scalars['String']>;
  SENSITIVE_gender?: Maybe<Scalars['String']>;
  SENSITIVE_lastname?: Maybe<Scalars['String']>;
  SENSITIVE_pharmacies?: Maybe<Array<PharmacyInformationOutput>>;
  SENSITIVE_phone?: Maybe<Scalars['String']>;
  SENSITIVE_profile_picture_id?: Maybe<Scalars['String']>;
  SENSITIVE_self_identity_gender?: Maybe<Scalars['String']>;
  SENSITIVE_user_measurements?: Maybe<PatientMeasurementsOutput>;
  id: Scalars['String'];
};

export type CreateProviderRoleOutput = {
  __typename?: 'CreateProviderRoleOutput';
  locationId: Scalars['String'];
  providerId: Scalars['String'];
};

export type CreateQuestionnaireResponsesOutput = {
  __typename?: 'CreateQuestionnaireResponsesOutput';
  created_at: Scalars['String'];
  id: Scalars['String'];
};

export type CreateSkintelligentOutput = {
  __typename?: 'CreateSkintelligentOutput';
  created_at: Scalars['String'];
  image_source: Scalars['String'];
  original_image_id: Scalars['String'];
  session_id: Scalars['String'];
  test_id: Scalars['String'];
};

export type CreateZrtOutput = {
  __typename?: 'CreateZrtOutput';
  created_at: Scalars['String'];
  health_questionnaire_pdf_id: Scalars['String'];
  swab_code: Scalars['String'];
  test_id: Scalars['String'];
  test_type: Hasura_Test_Types_Enum;
  tracking_number?: Maybe<Scalars['String']>;
};

export type CustomAuthCode = {
  __typename?: 'CustomAuthCode';
  success: Scalars['Boolean'];
};

export type CustomAuthTokenFromCustomAuthCode = {
  __typename?: 'CustomAuthTokenFromCustomAuthCode';
  token: Scalars['String'];
};

export type CustomTokenInput = {
  token: Scalars['String'];
};

export type DnaValidTestBySwabCode = {
  __typename?: 'DNAValidTestBySwabCode';
  existingDnaTests: Scalars['Boolean'];
};

export type DiagnosticReportOrderByInput = {
  direction: OrderByDirections;
  field: OrderByCriterias;
};

/** Enum representing the possible values for "status" field in CreateFHIRZrtTestInput. */
export enum DiagnosticReportStatus {
  Amended = 'AMENDED',
  Appended = 'APPENDED',
  Cancelled = 'CANCELLED',
  Corrected = 'CORRECTED',
  EnteredInError = 'ENTERED_IN_ERROR',
  Final = 'FINAL',
  Partial = 'PARTIAL',
  Preliminary = 'PRELIMINARY',
  Registered = 'REGISTERED',
  Unknown = 'UNKNOWN'
}

export type DisableMfaResponse = {
  __typename?: 'DisableMfaResponse';
  message: Scalars['String'];
  status: Scalars['Int'];
};

/** Enum representing the possible values for "field" field in DnaVisitOrderByInput. */
export enum DnaVisitOrderByCriterias {
  CreatedAt = 'CREATED_AT',
  ProviderName = 'PROVIDER_NAME',
  SapphirosBarcode = 'SAPPHIROS_BARCODE',
  TestType = 'TEST_TYPE'
}

export type DnaVisitOrderByInput = {
  direction: OrderByDirections;
  field: DnaVisitOrderByCriterias;
};

export type DnaVisitTestDefinition = {
  __typename?: 'DnaVisitTestDefinition';
  test_type: Hasura_Test_Types_Enum;
};

export type DnaVisitTestOutput = {
  __typename?: 'DnaVisitTestOutput';
  created_at: Scalars['String'];
  diagnosticReportId: Scalars['String'];
  dna_test_questionnaire_pdf_id?: Maybe<Scalars['String']>;
  dnavisit_order_id: Scalars['String'];
  health_questionnaire_pdf_id?: Maybe<Scalars['String']>;
  last_processed_date?: Maybe<Scalars['String']>;
  last_processed_state?: Maybe<Hasura_Dnavisit_Test_Statuses_Enum>;
  patient_pdf_id?: Maybe<Scalars['String']>;
  provider_acuity_calendar_id?: Maybe<Scalars['String']>;
  provider_acuity_owner_id?: Maybe<Scalars['String']>;
  provider_codex_id?: Maybe<Scalars['String']>;
  provider_name?: Maybe<Scalars['String']>;
  provider_report_pdf_id?: Maybe<Scalars['String']>;
  sapphiros_barcode: Scalars['String'];
  sapphiros_report_pdf_id?: Maybe<Scalars['String']>;
  state: Hasura_Dnavisit_Test_Statuses_Enum;
  test_id: Scalars['String'];
  test_type: Hasura_Test_Types_Enum;
  tracking_number?: Maybe<Scalars['String']>;
  updated_at: Scalars['String'];
  visit_summary_pdf_id?: Maybe<Scalars['String']>;
};

export type DnaVisitTestWithUserOutput = {
  __typename?: 'DnaVisitTestWithUserOutput';
  created_at: Scalars['String'];
  diagnosticReportId: Scalars['String'];
  dna_test_questionnaire_pdf_id?: Maybe<Scalars['String']>;
  dnavisit_order_id: Scalars['String'];
  dnavisit_tests_npi_data: DnaVisitTestsNpiData;
  dnavisit_tests_test: DnaVisitTestsTest;
  health_questionnaire_pdf_id?: Maybe<Scalars['String']>;
  last_processed_date?: Maybe<Scalars['String']>;
  last_processed_state?: Maybe<Hasura_Dnavisit_Test_Statuses_Enum>;
  patient_pdf_id?: Maybe<Scalars['String']>;
  provider_acuity_calendar_id?: Maybe<Scalars['String']>;
  provider_acuity_owner_id?: Maybe<Scalars['String']>;
  provider_codex_id?: Maybe<Scalars['String']>;
  provider_name?: Maybe<Scalars['String']>;
  provider_report_pdf_id?: Maybe<Scalars['String']>;
  sapphiros_barcode: Scalars['String'];
  sapphiros_report_pdf_id?: Maybe<Scalars['String']>;
  sapphiros_results?: Maybe<Scalars['JSON']>;
  state: Hasura_Dnavisit_Test_Statuses_Enum;
  test_id: Scalars['String'];
  test_type: Hasura_Test_Types_Enum;
  tracking_number?: Maybe<Scalars['String']>;
  updated_at: Scalars['String'];
  visit_summary_pdf_id?: Maybe<Scalars['String']>;
};

export type DnaVisitTestsNpiData = {
  __typename?: 'DnaVisitTestsNpiData';
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  npi_number: Scalars['String'];
  prefix: Scalars['String'];
};

export type DnaVisitTestsTest = {
  __typename?: 'DnaVisitTestsTest';
  tests_test_definition: DnaVisitTestDefinition;
  tests_user: DnaVisitTestsUser;
};

export type DnaVisitTestsUser = {
  __typename?: 'DnaVisitTestsUser';
  SENSITIVE_address_information?: Maybe<DnaVisitTestsUserAddressInformation>;
  SENSITIVE_dob?: Maybe<Scalars['String']>;
  SENSITIVE_email?: Maybe<Scalars['String']>;
  SENSITIVE_firstname?: Maybe<Scalars['String']>;
  SENSITIVE_gender?: Maybe<Scalars['String']>;
  SENSITIVE_lastname?: Maybe<Scalars['String']>;
  SENSITIVE_phone?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

export type DnaVisitTestsUserAddressInformation = {
  __typename?: 'DnaVisitTestsUserAddressInformation';
  addressLine1: Scalars['String'];
  addressLine2: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  zipCode: Scalars['String'];
};

export type DoseSpotMedicationItem = {
  __typename?: 'DoseSpotMedicationItem';
  name: Scalars['String'];
  strength: Array<DoseSpotMedicationStrength>;
};

export type DoseSpotMedicationItemDetail = {
  __typename?: 'DoseSpotMedicationItemDetail';
  brand: Scalars['Boolean'];
  compound_ingredients: Array<DoseSpotSelectCompoundIngredient>;
  dispense_unit_id: Scalars['Int'];
  display_name: Scalars['String'];
  dose_form: Scalars['String'];
  drug_classification: Scalars['String'];
  generic_drug_name: Scalars['String'];
  generic_product_name: Scalars['String'];
  lexi_drug_syn_id: Scalars['Int'];
  lexi_gen_drug_id: Scalars['String'];
  lexi_gen_product_id: Scalars['Int'];
  lexi_synonym_type_id: Scalars['Int'];
  monograph_path: Scalars['String'];
  ndc: Scalars['String'];
  otc: Scalars['Boolean'];
  route: Scalars['String'];
  rxcui: Scalars['String'];
  schedule: Scalars['String'];
  state_schedules: Array<DoseSpotStateSchedule>;
  strength: Scalars['String'];
};

export type DoseSpotMedicationSearchResult = {
  __typename?: 'DoseSpotMedicationSearchResult';
  items: Array<DoseSpotMedicationItem>;
  result: DoseSpotResult;
};

export type DoseSpotMedicationSelectResponse = {
  __typename?: 'DoseSpotMedicationSelectResponse';
  item: DoseSpotMedicationItemDetail;
  result: DoseSpotResult;
};

export type DoseSpotMedicationStrength = {
  __typename?: 'DoseSpotMedicationStrength';
  ndc: Scalars['String'];
  strength: Scalars['String'];
};

export type DoseSpotResult = {
  __typename?: 'DoseSpotResult';
  result_code: Scalars['String'];
  result_description: Scalars['String'];
};

export type DoseSpotSelectCompoundIngredient = {
  __typename?: 'DoseSpotSelectCompoundIngredient';
  dispense_unit_id: Scalars['Int'];
  free_text: Scalars['String'];
  is_free_text_epcs: Scalars['Boolean'];
  lexi_drug_syn_id: Scalars['Int'];
  lexi_gen_product_id: Scalars['Int'];
  lexi_synonym_type_id: Scalars['Int'];
  quantity: Scalars['String'];
  schedule: Scalars['Int'];
  state_schedules: Array<DoseSpotStateSchedule>;
};

export type DoseSpotStateSchedule = {
  __typename?: 'DoseSpotStateSchedule';
  schedule: Scalars['Int'];
  state_name: Scalars['String'];
};

export type DoseSpotUrlResult = {
  __typename?: 'DoseSpotURLResult';
  url?: Maybe<Scalars['String']>;
};

export type DynamicQuestionnaireDependencies = {
  __typename?: 'DynamicQuestionnaireDependencies';
  if: Scalars['JSONObject'];
  then: ThenObject;
};

export type FhirAppointmentNotesObservation = {
  anythingElseQuestion?: InputMaybe<Scalars['String']>;
  howLongQuestion?: InputMaybe<Scalars['String']>;
  severityQuestion?: InputMaybe<Scalars['String']>;
  symptomsTypes?: InputMaybe<Array<Scalars['String']>>;
};

export type FhirAppointmentNotesObservationOutput = {
  __typename?: 'FHIRAppointmentNotesObservationOutput';
  anythingElseQuestion?: Maybe<Scalars['String']>;
  howLongQuestion?: Maybe<Scalars['String']>;
  practitionerNotes?: Maybe<Scalars['String']>;
  severityQuestion?: Maybe<Scalars['String']>;
  symptomsTypes?: Maybe<Array<Scalars['String']>>;
};

export type FhirEncounterNotesOutput = {
  __typename?: 'FHIREncounterNotesOutput';
  followupRecommendations: Scalars['String'];
  providerNotes: Scalars['String'];
  providerSummary: Scalars['String'];
};

export type FedexTrackingResponse = {
  __typename?: 'FedexTrackingResponse';
  isValid: Scalars['Boolean'];
};

/** Enum representing the possible values for "questionnaireName" field in AttachQuestionnaireResponseInputment. */
export enum FhirQuestionnaireNames {
  DnaQuestionnaire = 'DNA_QUESTIONNAIRE',
  HealthQuestionnaire = 'HEALTH_QUESTIONNAIRE',
  ZrtQuestionnaire = 'ZRT_QUESTIONNAIRE'
}

export type FileStorage = {
  __typename?: 'FileStorage';
  contentType: Scalars['String'];
  fileId: Scalars['String'];
  fileName: Scalars['String'];
  url: Scalars['String'];
};

export type FileVonageStorage = {
  __typename?: 'FileVonageStorage';
  contentType: Scalars['String'];
  fileId: Scalars['String'];
  fileName: Scalars['String'];
  url: Scalars['String'];
};

export type FormatedAvailableTime = {
  __typename?: 'FormatedAvailableTime';
  enabled: Scalars['Boolean'];
  end: Scalars['String'];
  start: Scalars['String'];
};

export type FromFhirToHasuraAnswerItemUnion = HasuraAnswerItem | HasuraMedicationItem;

export type FullscriptOperationResult = {
  __typename?: 'FullscriptOperationResult';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type FullscriptSessionGrantResult = {
  __typename?: 'FullscriptSessionGrantResult';
  patientFullscriptId: Scalars['String'];
  sessionGrant: Scalars['String'];
};

export type GenerateFirebaseCustomTokenInput = {
  firebaseToken: Scalars['String'];
};

export type GenerateTempAuthTokenInput = {
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type GenerateTempAuthTokenInputResponse = {
  __typename?: 'GenerateTempAuthTokenInputResponse';
  authToken: Scalars['String'];
};

export type GetAppointmentChatMessagesOutput = {
  __typename?: 'GetAppointmentChatMessagesOutput';
  chat?: Maybe<AppointmentChat>;
};

export type GetAppointmentMediaOutput = {
  __typename?: 'GetAppointmentMediaOutput';
  bodySite?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fhirId: Scalars['String'];
  fileId?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export type GetAppointmentNotesByAppointmentIdOutput = {
  __typename?: 'GetAppointmentNotesByAppointmentIdOutput';
  nabla_notes: Array<NablaNote>;
};

export type GetAppointmentOutput = {
  __typename?: 'GetAppointmentOutput';
  acuityId?: Maybe<Scalars['String']>;
  appointmentCodexId: Scalars['String'];
  appointmentId: Scalars['String'];
  appointmentNotes?: Maybe<FhirAppointmentNotesObservationOutput>;
  appointmentType?: Maybe<AppointmentTypeEnum>;
  appointmentUrl?: Maybe<Scalars['String']>;
  cancelationReason?: Maybe<Scalars['String']>;
  codexPatientId: Scalars['String'];
  codexProviderId?: Maybe<Scalars['String']>;
  created: Scalars['String'];
  diagnosticReportId?: Maybe<Scalars['String']>;
  encounterId?: Maybe<Scalars['String']>;
  encounterNotes?: Maybe<FhirEncounterNotesOutput>;
  end?: Maybe<Scalars['String']>;
  events?: Maybe<Array<AppointmentEvent>>;
  lastProcessedDate: Scalars['String'];
  lastUpdate?: Maybe<Scalars['String']>;
  media?: Maybe<Array<GetAppointmentMediaOutput>>;
  participant: Array<ReferenceType>;
  patientFirstName?: Maybe<Scalars['String']>;
  patientLastName?: Maybe<Scalars['String']>;
  patientPdfId?: Maybe<Scalars['String']>;
  providerFirstName?: Maybe<Scalars['String']>;
  providerLastName?: Maybe<Scalars['String']>;
  providerPdfId?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  subjectOfAppointment?: Maybe<Scalars['String']>;
  supportingInformation?: Maybe<Array<ReferenceType>>;
  symptomsLocation?: Maybe<Array<Scalars['String']>>;
};

export type GetAppointmentRescheduleLinkOutput = {
  __typename?: 'GetAppointmentRescheduleLinkOutput';
  rescheduleLink?: Maybe<Scalars['String']>;
};

export type GetBasicPatientInfoOutput = {
  __typename?: 'GetBasicPatientInfoOutput';
  SENSITIVE_address_information?: Maybe<AddressInformationOutput>;
  SENSITIVE_birth_date?: Maybe<Scalars['String']>;
  SENSITIVE_etnicity?: Maybe<Array<Scalars['String']>>;
  SENSITIVE_firstname?: Maybe<Scalars['String']>;
  SENSITIVE_gender?: Maybe<Scalars['String']>;
  SENSITIVE_lastname?: Maybe<Scalars['String']>;
  SENSITIVE_profile_picture_id?: Maybe<Scalars['String']>;
  SENSITIVE_self_identity_gender?: Maybe<Scalars['String']>;
  SENSITIVE_user_measurements?: Maybe<PatientMeasurementsOutput>;
  appointments?: Maybe<Array<GetAppointmentOutput>>;
  id: Scalars['String'];
  kyc_approved: Scalars['Boolean'];
};

export type GetFhirAppointmentOutput = {
  __typename?: 'GetFHIRAppointmentOutput';
  appointment?: Maybe<GetAppointmentOutput>;
};

export type GetFhirAppointmentsOutput = {
  __typename?: 'GetFHIRAppointmentsOutput';
  appointment: Array<GetAppointmentOutput>;
  total: Scalars['Float'];
};

export type GetFhirAppointmentsOutputWithUpcomingCounts = {
  __typename?: 'GetFHIRAppointmentsOutputWithUpcomingCounts';
  appointment: Array<GetAppointmentOutput>;
  counts: UpcomingAppointmentCounts;
  total: Scalars['Float'];
};

export type GetFhirDnaAndZrtTests = {
  __typename?: 'GetFHIRDnaAndZrtTests';
  dna_tests: Array<RankedDnaVisitTestOutput>;
  total: Scalars['Float'];
  zrt_tests: Array<RankedZrtTestOutput>;
};

export type GetFhirDnaVisitTestByIdentifier = {
  __typename?: 'GetFHIRDnaVisitTestByIdentifier';
  dnavisit_tests?: Maybe<Array<DnaVisitTestWithUserOutput>>;
};

export type GetFhirDnaVisitTests = {
  __typename?: 'GetFHIRDnaVisitTests';
  dnavisit_tests: Array<DnaVisitTestOutput>;
  total: Scalars['Int'];
};

export type GetFhirDnaVisitTestsByUserId = {
  __typename?: 'GetFHIRDnaVisitTestsByUserId';
  dnavisit_tests: Array<DnaVisitTestOutput>;
  providers: Array<Scalars['String']>;
  total: Scalars['Int'];
};

export type GetFhirPatientsByProviderIdOutput = {
  __typename?: 'GetFHIRPatientsByProviderIdOutput';
  patients: Array<GetBasicPatientInfoOutput>;
  total: Scalars['Int'];
};

export type GetFhirProviderBioByIdOutput = {
  __typename?: 'GetFHIRProviderBioByIdOutput';
  providers: Array<ProviderOutput>;
};

export type GetFhirProviderByEmailOutput = {
  __typename?: 'GetFHIRProviderByEmailOutput';
  providers: Array<ProviderOutput>;
};

export type GetFhirProviderByIdOutput = {
  __typename?: 'GetFHIRProviderByIdOutput';
  providers: Array<ProviderOutput>;
};

export type GetFhirProvidersOutput = {
  __typename?: 'GetFHIRProvidersOutput';
  providers: Array<ProviderOutput>;
  total?: Maybe<Scalars['Float']>;
};

export type GetFhirQuestionnaireResponse = {
  __typename?: 'GetFHIRQuestionnaireResponse';
  questionnaire_response: GetQuestionnaireResponse;
};

export type GetFhirSkintelligentTestByIdentifier = {
  __typename?: 'GetFHIRSkintelligentTestByIdentifier';
  skintelligent_tests: Array<SkintelligentTestWithUserOutput>;
};

export type GetFhirSkintelligentTestByIdentifierAndUserId = {
  __typename?: 'GetFHIRSkintelligentTestByIdentifierAndUserId';
  skintelligent_test: SkintelligentTestOutput;
};

export type GetFhirSkintelligentTests = {
  __typename?: 'GetFHIRSkintelligentTests';
  skintelligent_tests: Array<SkintelligentTestOutput>;
  total: Scalars['Int'];
};

export type GetFhirSkintelligentTestsByUserId = {
  __typename?: 'GetFHIRSkintelligentTestsByUserId';
  skintelligent_tests: Array<SkintelligentTestOutput>;
  total: Scalars['Int'];
};

export type GetFhirZrtTest = {
  __typename?: 'GetFHIRZrtTest';
  zrt_test: ZrtTestOutput;
};

export type GetFhirZrtTests = {
  __typename?: 'GetFHIRZrtTests';
  zrt_tests: Array<ZrtTestOutput>;
};

export type GetLatestFhirQuestionnaireById = {
  __typename?: 'GetLatestFHIRQuestionnaireById';
  id: Scalars['String'];
  responses: Scalars['JSON'];
};

export type GetLatestFhirQuestionnaireByIdResponse = {
  __typename?: 'GetLatestFHIRQuestionnaireByIdResponse';
  questionnaire_responses: Array<GetLatestFhirQuestionnaireById>;
};

export type GetMedicationRequestsBrandAndNameOutput = {
  __typename?: 'GetMedicationRequestsBrandAndNameOutput';
  medication_request_bran_and_names: Array<Scalars['String']>;
};

export type GetMedicationRequestsOutput = {
  __typename?: 'GetMedicationRequestsOutput';
  medication_requests: Array<MedicationRequestOutputDto>;
  total: Scalars['Float'];
};

export type GetPatientOutput = {
  __typename?: 'GetPatientOutput';
  SENSITIVE_address_information?: Maybe<AddressInformationOutput>;
  SENSITIVE_country?: Maybe<Scalars['String']>;
  SENSITIVE_dob?: Maybe<Scalars['String']>;
  SENSITIVE_email: Scalars['String'];
  SENSITIVE_etnicity?: Maybe<Array<Scalars['String']>>;
  SENSITIVE_firstname?: Maybe<Scalars['String']>;
  SENSITIVE_gender?: Maybe<Scalars['String']>;
  SENSITIVE_lastname?: Maybe<Scalars['String']>;
  SENSITIVE_pharmacies?: Maybe<Array<PharmacyInformationOutput>>;
  SENSITIVE_phone?: Maybe<Scalars['String']>;
  SENSITIVE_profile_picture_id?: Maybe<Scalars['String']>;
  SENSITIVE_self_identity_gender?: Maybe<Scalars['String']>;
  SENSITIVE_user_measurements?: Maybe<PatientMeasurementsOutput>;
  created_at: Scalars['String'];
  doseSpotId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  sapphirosId?: Maybe<Scalars['String']>;
  updated_at: Scalars['String'];
};

export type GetProviderBioById = {
  __typename?: 'GetProviderBioById';
  providers: ProviderOutput;
  requestStatus: ProviderRequestStatus;
};

export type GetProviderBioByIdQueryOutput = {
  __typename?: 'GetProviderBioByIdQueryOutput';
  providers: Array<GetProviderBioById>;
};

export type GetQuestionnaireOutput = {
  __typename?: 'GetQuestionnaireOutput';
  data: Array<QuestionnaireDependenciesGroup>;
  questionnaires_questionnaire_questions: Array<QuestionnairesQuestionnaireQuestions>;
  subtitle?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type GetQuestionnaireResponse = {
  __typename?: 'GetQuestionnaireResponse';
  created_at?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  is_complete: QuestionnaireResponseStatus;
  responses: Array<FromFhirToHasuraAnswerItemUnion>;
  updated_at?: Maybe<Scalars['String']>;
};

export type GetSignUrlFromStorageByAdminInput = {
  fileId: Scalars['String'];
  userId: Scalars['String'];
};

/** Enum representing possible url values for resources extensions */
export enum GoogleEhrExtensionsUrLs {
  CreatedAt = 'CREATED_AT',
  EhrAbout = 'EHR_ABOUT',
  EhrAppointmentChatAttachmentFrom = 'EHR_APPOINTMENT_CHAT_ATTACHMENT_FROM',
  EhrAppointmentLastProcessedStartDate = 'EHR_APPOINTMENT_LAST_PROCESSED_START_DATE',
  EhrAppointmentUrl = 'EHR_APPOINTMENT_URL',
  EhrDeviceCode = 'EHR_DEVICE_CODE',
  EhrDnaVisitState = 'EHR_DNA_VISIT_STATE',
  EhrLastProcessedTestKitDate = 'EHR_LAST_PROCESSED_TEST_KIT_DATE',
  EhrLastProcessedTestKitStatus = 'EHR_LAST_PROCESSED_TEST_KIT_STATUS',
  EhrMedicationIsBrand = 'EHR_MEDICATION_IS_BRAND',
  EhrNpiNumber = 'EHR_NPI_NUMBER',
  EhrProviderNoteType = 'EHR_PROVIDER_NOTE_TYPE',
  EhrQuestionnaireResponseCurrentIndexOfSection = 'EHR_QUESTIONNAIRE_RESPONSE_CURRENT_INDEX_OF_SECTION',
  EhrQuestionnaireResponseCurrentSection = 'EHR_QUESTIONNAIRE_RESPONSE_CURRENT_SECTION',
  EhrQuestionnaireResponseIsArrayOfStrings = 'EHR_QUESTIONNAIRE_RESPONSE_IS_ARRAY_OF_STRINGS',
  EhrQuestionnaireResponseMedicationStatus = 'EHR_QUESTIONNAIRE_RESPONSE_MEDICATION_STATUS',
  EhrRaceExtension = 'EHR_RACE_EXTENSION',
  EhrServiceOfferingPrice = 'EHR_SERVICE_OFFERING_PRICE',
  EhrTrackingNumber = 'EHR_TRACKING_NUMBER',
  EhrZrtState = 'EHR_ZRT_STATE',
  SelfIndentityGenderExtension = 'SELF_INDENTITY_GENDER_EXTENSION'
}

export type HasuraAnswerItem = {
  __typename?: 'HasuraAnswerItem';
  currentIndexOfSection?: Maybe<Scalars['Int']>;
  currentSection?: Maybe<Scalars['String']>;
  hasuraItemArrayResponse?: Maybe<Array<HasuraAnswerItem>>;
  hasuraMedicationItemResponse?: Maybe<HasuraMedicationItem>;
  questionID?: Maybe<Scalars['Int']>;
  responseType: Scalars['String'];
  stringArrayResponse?: Maybe<Array<Scalars['String']>>;
  stringResponse?: Maybe<Scalars['String']>;
};

export type HasuraMedicationItem = {
  __typename?: 'HasuraMedicationItem';
  completed?: Maybe<Scalars['Boolean']>;
  hasuraItemArrayResponse?: Maybe<Array<HasuraAnswerItem>>;
  hasuraMedicationItemResponse?: Maybe<HasuraMedicationItem>;
  id: Scalars['String'];
  responseType: Scalars['String'];
  stringArrayResponse?: Maybe<Array<Scalars['String']>>;
  stringResponse?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** Enum representing the possible values for "state" field in DnaVisitTestOutput. */
export enum Hasura_Dnavisit_Test_Statuses_Enum {
  AppointmentScheduled = 'AppointmentScheduled',
  Complete = 'Complete',
  KitNotReceived = 'KitNotReceived',
  ProviderPending = 'ProviderPending',
  ReceivedByLab = 'ReceivedByLab',
  ResultsWithProvider = 'ResultsWithProvider'
}

/** Enum representing the possible values for "source" field in getFHIRSkintelligentTests. */
export enum Hasura_Image_Source_Types_Enum {
  Mobile = 'Mobile',
  Pc = 'Pc'
}

/** Enum representing the possible values for "test_type" field in ZrtTestOutput. */
export enum Hasura_Test_Types_Enum {
  DnaSkin = 'DnaSkin',
  DnaVitamin = 'DnaVitamin',
  Skintelligent = 'Skintelligent',
  ZrtHeavyMetals = 'ZrtHeavyMetals',
  ZrtHormone = 'ZrtHormone',
  ZrtInflammatory = 'ZrtInflammatory',
  ZrtNeurotransmitters = 'ZrtNeurotransmitters'
}

/** Enum representing the possible values for "state" field in ZrtTestOutput. */
export enum Hasura_Zrt_Test_States_Enum {
  AppointmentScheduled = 'AppointmentScheduled',
  Complete = 'Complete',
  KitNotReceived = 'KitNotReceived',
  ProviderPending = 'ProviderPending',
  ReceivedByLab = 'ReceivedByLab',
  ResultsWithProvider = 'ResultsWithProvider'
}

export type Identifier = {
  __typename?: 'Identifier';
  system?: Maybe<Scalars['String']>;
  type?: Maybe<CodeableConcept>;
  use?: Maybe<IdentifierUse>;
  value?: Maybe<Scalars['String']>;
};

/** Enum representing the possible values for "use" field in Identifier. */
export enum IdentifierUse {
  Official = 'OFFICIAL',
  Old = 'OLD',
  Secondary = 'SECONDARY',
  Temp = 'TEMP',
  Usual = 'USUAL'
}

export type IframeSecretResponse = {
  __typename?: 'IframeSecretResponse';
  dnaVisitIframeSecret: Scalars['String'];
};

export type InsertAppointmentChatMessages = {
  appointmentCodexId: Scalars['String'];
  communicationFhirId: Scalars['String'];
  messages: Array<AppointmentChatMessageInput>;
};

export type InsertAppointmentChatMessagesByAppointmentCodexId = {
  appointmentCodexId: Scalars['String'];
  messages: Array<AppointmentChatMessageInput>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type IsValidSwabCode = {
  __typename?: 'IsValidSwabCode';
  id: Scalars['String'];
  isValid: Scalars['Boolean'];
  lab_name: Scalars['String'];
  status: Scalars['String'];
  swab_code: Scalars['String'];
  swab_usage_type: Scalars['String'];
  test_type: Scalars['String'];
};

/** Enum representing the possible values for "language" field in Attachment. */
export enum Language {
  EnUs = 'EN_US'
}

export type LinkProviderAccountInput = {
  tokenCode: Scalars['String'];
};

export type MediaOutput = {
  __typename?: 'MediaOutput';
  bodySite?: Maybe<CodeableConcept>;
  content: Attachment;
  description?: Maybe<Scalars['String']>;
  extension?: Maybe<Array<ResourceExtension>>;
  id: Scalars['String'];
  identifier?: Maybe<Array<Identifier>>;
  meta: Meta;
  partOfSkintelligentId?: Maybe<Scalars['String']>;
  resourceType: ResourcesTypes;
  status: MediaStatus;
  text?: Maybe<Narrative>;
};

/** Enum representing the possible values for "status" field in Media. */
export enum MediaStatus {
  Completed = 'COMPLETED',
  EnteredInError = 'ENTERED_IN_ERROR',
  InProgress = 'IN_PROGRESS',
  NotDone = 'NOT_DONE',
  OnHold = 'ON_HOLD',
  Preparation = 'PREPARATION',
  Stopped = 'STOPPED',
  Unknown = 'UNKNOWN'
}

export type MedicationRequestOutputDto = {
  __typename?: 'MedicationRequestOutputDto';
  brandName?: Maybe<Scalars['String']>;
  daysSupply?: Maybe<Scalars['Float']>;
  delivery?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  dosage?: Maybe<Scalars['String']>;
  frequency?: Maybe<Scalars['String']>;
  fullScriptVariantId?: Maybe<Scalars['String']>;
  fullScriptVariantSKU?: Maybe<Scalars['String']>;
  isOTC: Scalars['Boolean'];
  mediSpanClassification?: Maybe<Scalars['String']>;
  mediSpanId?: Maybe<Scalars['String']>;
  ndc?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  patientCodexId: Scalars['String'];
  prescriptionDoseSpotId?: Maybe<Scalars['String']>;
  prescriptionFhirId: Scalars['String'];
  prescriptionFullScriptId?: Maybe<Scalars['String']>;
  providerCodexId: Scalars['String'];
  requestedAt?: Maybe<Scalars['String']>;
  rxcui?: Maybe<Scalars['String']>;
  status: MedicationRequestStatusEnum;
  type?: Maybe<Scalars['String']>;
};

/** Enum representing the possible values for "status" field in MedicationRequest. */
export enum MedicationRequestStatusEnum {
  Active = 'active',
  Cancelled = 'cancelled',
  Completed = 'completed',
  Draft = 'draft',
  EnteredInError = 'entered_in_error',
  OnHold = 'on_hold',
  Stopped = 'stopped',
  Unknown = 'unknown'
}

export type Meta = {
  __typename?: 'Meta';
  lastUpdated: Scalars['String'];
  tag: Array<Coding>;
  versionId: Scalars['String'];
};

export type NablaNote = {
  __typename?: 'NablaNote';
  appointment_id: Scalars['String'];
  nabla_notes: Scalars['String'];
};

export type Narrative = {
  __typename?: 'Narrative';
  div: Scalars['String'];
  status: NarrativeStatusEnum;
};

/** Enum representing the possible values for "status" field in Narrative. */
export enum NarrativeStatusEnum {
  Additional = 'Additional',
  Empty = 'Empty',
  Extensions = 'Extensions',
  Generated = 'Generated'
}

export type OfficeAddressInformationInput = {
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  email: Scalars['String'];
  officeName?: InputMaybe<Scalars['String']>;
  phone: Scalars['String'];
  province?: InputMaybe<Scalars['String']>;
  state: Scalars['String'];
  zipCode: Scalars['String'];
};

export type OfficeAddressInformationOutput = {
  __typename?: 'OfficeAddressInformationOutput';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  officeName?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  province?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type OnTreatmentPlanEventInput = {
  appointmentCodexId?: InputMaybe<Scalars['String']>;
  patientCodexId: Scalars['String'];
  treatmentPlanId: Scalars['String'];
};

/** A generic list of OrderByCriterias valid across both DNA Visit and ZRT */
export enum OrderByCriterias {
  Barcode = 'BARCODE',
  CreatedAt = 'CREATED_AT',
  ProviderName = 'PROVIDER_NAME',
  SapphirosBarcode = 'SAPPHIROS_BARCODE',
  SwabCode = 'SWAB_CODE',
  TestType = 'TEST_TYPE'
}

/** Enum representing the possible values for "direction" field in ZrtOrderByCriterias. */
export enum OrderByDirections {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Enum representing the possible values for "type" field in CreateFHIROrganizationInput. */
export enum OrganizationTypes {
  ClinicalResearchSponsor = 'CLINICAL_RESEARCH_SPONSOR',
  CommunityGroup = 'COMMUNITY_GROUP',
  EducationalInstitute = 'EDUCATIONAL_INSTITUTE',
  Government = 'GOVERNMENT',
  HealthcareProvider = 'HEALTHCARE_PROVIDER',
  HospitalDepartment = 'HOSPITAL_DEPARTMENT',
  InsuranceCompany = 'INSURANCE_COMPANY',
  NonHealthcareBusiness = 'NON_HEALTHCARE_BUSINESS',
  OrganizationalTeam = 'ORGANIZATIONAL_TEAM',
  Other = 'OTHER',
  Payer = 'PAYER',
  ReligiousInstitution = 'RELIGIOUS_INSTITUTION'
}

export type PatchAppointmentAndEncounterOutput = {
  __typename?: 'PatchAppointmentAndEncounterOutput';
  appointment: PatchAppointmentOutput;
  encounter: AppendEncounterOutput;
};

export type PatchAppointmentOutput = {
  __typename?: 'PatchAppointmentOutput';
  appointmentId: Scalars['String'];
};

export type PatchDnaVisitOutput = {
  __typename?: 'PatchDnaVisitOutput';
  created_at: Scalars['String'];
  dna_test_questionnaire_pdf_id?: Maybe<Scalars['String']>;
  dnavisit_order_id: Scalars['String'];
  health_questionnaire_pdf_id?: Maybe<Scalars['String']>;
  last_processed_date?: Maybe<Scalars['String']>;
  last_processed_state?: Maybe<Hasura_Dnavisit_Test_Statuses_Enum>;
  patient_pdf_id?: Maybe<Scalars['String']>;
  provider_report_pdf_id?: Maybe<Scalars['String']>;
  sapphiros_barcode: Scalars['String'];
  sapphiros_report_pdf_id?: Maybe<Scalars['String']>;
  state: Hasura_Dnavisit_Test_Statuses_Enum;
  test_id: Scalars['String'];
  test_type: Hasura_Test_Types_Enum;
  tracking_number?: Maybe<Scalars['String']>;
  updated_at: Scalars['String'];
  visit_summary_pdf_id?: Maybe<Scalars['String']>;
};

export type PatchEncounterInput = {
  appointmentId?: InputMaybe<Scalars['String']>;
  encounterCodexId?: InputMaybe<Scalars['String']>;
  encounterNotes?: InputMaybe<CreateFhirEncounterNotesInput>;
  providerId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type PatchEncounterOutput = {
  __typename?: 'PatchEncounterOutput';
  encounterId: Scalars['String'];
  status: Scalars['String'];
};

export type PatchFhirAppointmentInput = {
  acuityId?: InputMaybe<Scalars['String']>;
  appointmentCodexId: Scalars['String'];
  appointmentNotes?: InputMaybe<FhirAppointmentNotesObservation>;
  appointmentType?: InputMaybe<AppointmentTypeEnum>;
  appointmentUrl?: InputMaybe<Scalars['String']>;
  cancelationReason?: InputMaybe<Scalars['String']>;
  codexProviderId?: InputMaybe<Scalars['String']>;
  endAt?: InputMaybe<Scalars['String']>;
  lastProcessedDate?: InputMaybe<Scalars['String']>;
  mediaToAppend?: InputMaybe<Array<Scalars['String']>>;
  mediaToInsert?: InputMaybe<Array<AppointmentMediaInsert>>;
  startAt?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  subjectOfAppointment?: InputMaybe<Scalars['String']>;
  supportingInformation?: InputMaybe<Array<ReferenceInput>>;
  symptomsLocation?: InputMaybe<Array<Scalars['String']>>;
  vonageSessionId?: InputMaybe<Scalars['String']>;
};

export type PatchFhirAppointmentOutput = {
  __typename?: 'PatchFHIRAppointmentOutput';
  appointment: PatchAppointmentOutput;
};

export type PatchFhirDnaVisitTestInput = {
  encounterId?: InputMaybe<Scalars['String']>;
  last_processed_state?: InputMaybe<Hasura_Dnavisit_Test_Statuses_Enum>;
  npi_number?: InputMaybe<Scalars['String']>;
  patient_pdf_id?: InputMaybe<Scalars['String']>;
  provider_report_pdf_id?: InputMaybe<Scalars['String']>;
  sapphiros_data?: InputMaybe<Scalars['JSON']>;
  sapphiros_report_pdf_id?: InputMaybe<Scalars['String']>;
  sapphiros_results?: InputMaybe<Scalars['JSON']>;
  state?: InputMaybe<Hasura_Dnavisit_Test_Statuses_Enum>;
  test_id: Scalars['String'];
  visit_summary_pdf_id?: InputMaybe<Scalars['String']>;
};

export type PatchFhirDnaVisitTestOutput = {
  __typename?: 'PatchFHIRDnaVisitTestOutput';
  dnavisit_test: PatchDnaVisitOutput;
};

export type PatchFhirEncounterOutput = {
  __typename?: 'PatchFHIREncounterOutput';
  encounter?: Maybe<PatchEncounterOutput>;
};

export type PatchFhirHealthCareServiceInput = {
  active: Scalars['Boolean'];
  fhirId: Scalars['String'];
  price: Scalars['String'];
};

export type PatchFhirPatientOutput = {
  __typename?: 'PatchFHIRPatientOutput';
  update_patients_one: CreatePatientOutput;
};

export type PatchFhirPatientPersonalInfoInput = {
  SENSITIVE_address_information?: InputMaybe<PatientAddressInfo>;
  SENSITIVE_country?: InputMaybe<Scalars['String']>;
  SENSITIVE_dob?: InputMaybe<Scalars['String']>;
  SENSITIVE_etnicity?: InputMaybe<Array<Scalars['String']>>;
  SENSITIVE_firstname?: InputMaybe<Scalars['String']>;
  SENSITIVE_gender?: InputMaybe<Scalars['String']>;
  SENSITIVE_lastname?: InputMaybe<Scalars['String']>;
  SENSITIVE_pharmacies?: InputMaybe<Array<PharmacyInformationInput>>;
  SENSITIVE_phone?: InputMaybe<Scalars['String']>;
  SENSITIVE_profile_picture_id?: InputMaybe<Scalars['String']>;
  SENSITIVE_self_identity_gender?: InputMaybe<Scalars['String']>;
  SENSITIVE_user_measurements?: InputMaybe<PatientMeasurements>;
  codexID: Scalars['String'];
  doseSpotID?: InputMaybe<Scalars['String']>;
  fullScriptID?: InputMaybe<Scalars['String']>;
  sapphirosID?: InputMaybe<Scalars['String']>;
};

export type PatchFhirProviderInput = {
  about?: InputMaybe<Scalars['String']>;
  accepting_patients?: InputMaybe<Scalars['Boolean']>;
  acuityCalendarId?: InputMaybe<Scalars['String']>;
  acuityOwnerId?: InputMaybe<Scalars['String']>;
  addressInformation?: InputMaybe<AddressInformationInput>;
  credentials?: InputMaybe<ProviderCredentialsInput>;
  dosespotId?: InputMaybe<Scalars['String']>;
  education?: InputMaybe<Array<ProviderEducationInput>>;
  email?: InputMaybe<Scalars['String']>;
  firstname?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  languagesOfCommunication?: InputMaybe<Array<Scalars['String']>>;
  lastname?: InputMaybe<Scalars['String']>;
  nationalProviderIdentifier?: InputMaybe<Scalars['String']>;
  officeInformation?: InputMaybe<OfficeAddressInformationInput>;
  phone?: InputMaybe<Scalars['String']>;
  profilePicture?: InputMaybe<Scalars['String']>;
};

export type PatchFhirProviderOutput = {
  __typename?: 'PatchFHIRProviderOutput';
  update_providers_one: ProviderOutput;
};

export type PatchFhirRequestBecomePatientOutput = {
  __typename?: 'PatchFHIRRequestBecomePatientOutput';
  requestStatus: ProviderRequestStatus;
};

export type PatchFhirSkintelligentTestInput = {
  comedone?: InputMaybe<Scalars['Int']>;
  inflammatory?: InputMaybe<Scalars['Int']>;
  overall_score?: InputMaybe<Scalars['Int']>;
  pih?: InputMaybe<Scalars['Int']>;
  report_id?: InputMaybe<Scalars['String']>;
  result_image_id?: InputMaybe<Scalars['String']>;
  session_id: Scalars['String'];
  total_lesions?: InputMaybe<Scalars['Int']>;
};

export type PatchFhirSkintelligentTestOutput = {
  __typename?: 'PatchFHIRSkintelligentTestOutput';
  skintelligent_test: PatchSkintelligentOutput;
};

export type PatchFhirZrtTestInput = {
  comments?: InputMaybe<Scalars['String']>;
  date_received?: InputMaybe<Scalars['String']>;
  date_reported?: InputMaybe<Scalars['String']>;
  last_processed_state?: InputMaybe<Hasura_Zrt_Test_States_Enum>;
  npi_number?: InputMaybe<Scalars['String']>;
  patient_pdf_id?: InputMaybe<Scalars['String']>;
  provider_report_pdf_id?: InputMaybe<Scalars['String']>;
  reference_code: Scalars['String'];
  status?: InputMaybe<Scalars['String']>;
};

export type PatchFhirZrtTestOutput = {
  __typename?: 'PatchFHIRZrtTestOutput';
  zrt_test: PatchZrtOutput;
};

export type PatchSkintelligentOutput = {
  __typename?: 'PatchSkintelligentOutput';
  comedone?: Maybe<Scalars['Int']>;
  created_at: Scalars['String'];
  inflammatory?: Maybe<Scalars['Int']>;
  original_image_id: Scalars['String'];
  overall_score?: Maybe<Scalars['Int']>;
  pih?: Maybe<Scalars['Int']>;
  report_id?: Maybe<Scalars['String']>;
  result_image_id?: Maybe<Scalars['String']>;
  session_id: Scalars['String'];
  test_id: Scalars['String'];
  total_lesions?: Maybe<Scalars['Int']>;
};

export type PatchZrtOutput = {
  __typename?: 'PatchZrtOutput';
  created_at: Scalars['String'];
  health_questionnaire_pdf_id?: Maybe<Scalars['String']>;
  npi_number?: Maybe<Scalars['String']>;
  patient_pdf_id?: Maybe<Scalars['String']>;
  provider_report_pdf_id?: Maybe<Scalars['String']>;
  sapphiros_barcode: Scalars['String'];
  state: Hasura_Zrt_Test_States_Enum;
  test_id: Scalars['String'];
  test_type: Hasura_Test_Types_Enum;
  tracking_number?: Maybe<Scalars['String']>;
  updated_at: Scalars['String'];
};

export type PatientAddressInfo = {
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  province?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type PatientMeasurements = {
  heightFt?: InputMaybe<Scalars['Int']>;
  heightIn?: InputMaybe<Scalars['Int']>;
  hips?: InputMaybe<Scalars['Int']>;
  waist?: InputMaybe<Scalars['Int']>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type PatientMeasurementsOutput = {
  __typename?: 'PatientMeasurementsOutput';
  heightFt?: Maybe<Scalars['Int']>;
  heightIn?: Maybe<Scalars['Int']>;
  hips?: Maybe<Scalars['Int']>;
  waist?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['Int']>;
};

export type PatientRequest = {
  __typename?: 'PatientRequest';
  date: Scalars['String'];
  diagnosticServiceRequestFhirId?: Maybe<Scalars['String']>;
  itemReference: ReferenceType;
  patientFhirId: Scalars['String'];
  requestStatus: ProviderRequestStatus;
};

export type PharmacyInformationInput = {
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  phone: Scalars['String'];
  province?: InputMaybe<Scalars['String']>;
  state: Scalars['String'];
  zipCode: Scalars['String'];
};

export type PharmacyInformationOutput = {
  __typename?: 'PharmacyInformationOutput';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isDefault: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  province?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

/** Enum representing the price categories associated with service offerings. */
export enum PriceCategory {
  NotApplicable = 'NotApplicable',
  Price1 = 'Price1',
  Price2 = 'Price2',
  Price3 = 'Price3',
  Price4 = 'Price4'
}

export type ProviderCredentialsInput = {
  certifications: Array<Scalars['String']>;
  states: Array<Scalars['String']>;
  titles: Array<Scalars['String']>;
};

export type ProviderCredentialsOutput = {
  __typename?: 'ProviderCredentialsOutput';
  certifications: Array<Scalars['String']>;
  states: Array<Scalars['String']>;
  titles: Array<Scalars['String']>;
};

export type ProviderEducationInput = {
  medicalSchool: Scalars['String'];
  residency: Scalars['String'];
  undergraduate: Scalars['String'];
};

export type ProviderEducationOutput = {
  __typename?: 'ProviderEducationOutput';
  medicalSchool: Scalars['String'];
  residency: Scalars['String'];
  undergraduate: Scalars['String'];
};

export type ProviderOutput = {
  __typename?: 'ProviderOutput';
  SENSITIVE_about?: Maybe<Scalars['String']>;
  SENSITIVE_address_information: AddressInformationOutput;
  SENSITIVE_credentials: ProviderCredentialsOutput;
  SENSITIVE_education: Array<ProviderEducationOutput>;
  SENSITIVE_email: Scalars['String'];
  SENSITIVE_firstname: Scalars['String'];
  SENSITIVE_gender: Scalars['String'];
  SENSITIVE_languages_of_communication: Array<Scalars['String']>;
  SENSITIVE_lastname: Scalars['String'];
  SENSITIVE_national_provider_identifier: Scalars['String'];
  SENSITIVE_office_information?: Maybe<OfficeAddressInformationOutput>;
  SENSITIVE_phone: Scalars['String'];
  SENSITIVE_profile_picture_id?: Maybe<Scalars['String']>;
  accepting_patients: Scalars['Boolean'];
  acuityCalendarId?: Maybe<Scalars['String']>;
  acuityOwnerId?: Maybe<Scalars['String']>;
  codex_approved: Scalars['Boolean'];
  dosespotId?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  fullscriptId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  id_verified: Scalars['Boolean'];
  patientRequests: Array<PatientRequest>;
  patientRequestsListId?: Maybe<Scalars['String']>;
  serviceOfferings: Array<ServiceOfferingsOutput>;
};

/** The status of a request to become a patient. */
export enum ProviderRequestStatus {
  Accepted = 'Accepted',
  Cancelled = 'Cancelled',
  None = 'None',
  Pending = 'Pending',
  Rejected = 'Rejected'
}

export type ProviderSignupInput = {
  about?: InputMaybe<Scalars['String']>;
  address_information: Scalars['jsonb'];
  credentials: Scalars['jsonb'];
  education: Scalars['jsonb'];
  email: Scalars['String'];
  firebaseToken: Scalars['String'];
  firstname: Scalars['String'];
  gender: Scalars['String'];
  languagesOfCommunication: Scalars['jsonb'];
  lastname: Scalars['String'];
  nationalProviderIdentifier: Scalars['String'];
  office_information: Scalars['jsonb'];
  phone: Scalars['String'];
};

export type ProviderWeeklyWorkingHours = {
  __typename?: 'ProviderWeeklyWorkingHours';
  data: AcuityWorkingHoursPerDay;
};

export type ProviderWorkingHoursAndBlockedTime = {
  __typename?: 'ProviderWorkingHoursAndBlockedTime';
  blocks: Array<FormatedAvailableTime>;
  providerWorkingHours: ProviderWeeklyWorkingHours;
};

/** Enum representing the possible values for "provider" field in getFHIRDnaVisitTests. */
export enum ProvidersTypes {
  All = 'ALL',
  DnaVisit = 'DNA_VISIT',
  OtherProviders = 'OTHER_PROVIDERS'
}

export type QuestionHelperTextData = {
  __typename?: 'QuestionHelperTextData';
  helper_text_1: Scalars['String'];
  helper_text_2: Scalars['String'];
};

export type QuestionnaireDependenciesGroup = {
  __typename?: 'QuestionnaireDependenciesGroup';
  complete?: Maybe<Scalars['Boolean']>;
  dependencies?: Maybe<Array<DynamicQuestionnaireDependencies>>;
  name: Scalars['String'];
  properties: Array<Scalars['String']>;
};

export type QuestionnaireQuestionsQuestion = {
  __typename?: 'QuestionnaireQuestionsQuestion';
  answers?: Maybe<Array<Scalars['String']>>;
  dont_know_answer?: Maybe<Scalars['String']>;
  double_text_combo_helper_texts?: Maybe<QuestionHelperTextData>;
  dropdown_combo_answers?: Maybe<Scalars['JSON']>;
  dropdown_label_1?: Maybe<Scalars['String']>;
  dropdown_label_2?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  measurement_unit?: Maybe<Scalars['String']>;
  response_property?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  ui_control: Scalars['String'];
};

export type QuestionnaireResponseInput = {
  authored?: InputMaybe<Scalars['String']>;
  codexPatientId: Scalars['String'];
  completed?: InputMaybe<Scalars['Boolean']>;
  questionnaireName: FhirQuestionnaireNames;
  questionnaireResponse: Scalars['JSON'];
  serviceRequestId?: InputMaybe<Scalars['String']>;
};

/** Enum representing the possible values for "status" field in QuestionnaireResponse. */
export enum QuestionnaireResponseStatus {
  Amended = 'AMENDED',
  Completed = 'COMPLETED',
  EnteredInError = 'ENTERED_IN_ERROR',
  InProgress = 'IN_PROGRESS',
  Stopped = 'STOPPED'
}

export type QuestionnairesQuestionnaireQuestions = {
  __typename?: 'QuestionnairesQuestionnaireQuestions';
  questionnaire_questions_question: QuestionnaireQuestionsQuestion;
};

export type RankedDnaVisitTestOutput = {
  __typename?: 'RankedDnaVisitTestOutput';
  created_at: Scalars['String'];
  diagnosticReportId: Scalars['String'];
  dna_test_questionnaire_pdf_id?: Maybe<Scalars['String']>;
  dnavisit_order_id: Scalars['String'];
  health_questionnaire_pdf_id?: Maybe<Scalars['String']>;
  last_processed_date?: Maybe<Scalars['String']>;
  last_processed_state?: Maybe<Hasura_Dnavisit_Test_Statuses_Enum>;
  patient_pdf_id?: Maybe<Scalars['String']>;
  provider_acuity_calendar_id?: Maybe<Scalars['String']>;
  provider_acuity_owner_id?: Maybe<Scalars['String']>;
  provider_codex_id?: Maybe<Scalars['String']>;
  provider_name?: Maybe<Scalars['String']>;
  provider_report_pdf_id?: Maybe<Scalars['String']>;
  rank: Scalars['Float'];
  sapphiros_barcode: Scalars['String'];
  sapphiros_report_pdf_id?: Maybe<Scalars['String']>;
  state: Hasura_Dnavisit_Test_Statuses_Enum;
  test_id: Scalars['String'];
  test_type: Hasura_Test_Types_Enum;
  tracking_number?: Maybe<Scalars['String']>;
  updated_at: Scalars['String'];
  visit_summary_pdf_id?: Maybe<Scalars['String']>;
};

export type RankedZrtTestOutput = {
  __typename?: 'RankedZrtTestOutput';
  created_at: Scalars['String'];
  fhirId: Scalars['String'];
  health_questionnaire_pdf_id?: Maybe<Scalars['String']>;
  npi_number?: Maybe<Scalars['String']>;
  observation_id?: Maybe<Scalars['String']>;
  patient_fhir_id?: Maybe<Scalars['String']>;
  patient_pdf_id?: Maybe<Scalars['String']>;
  provider_acuity_calendar_id?: Maybe<Scalars['String']>;
  provider_acuity_owner_id?: Maybe<Scalars['String']>;
  provider_codex_id?: Maybe<Scalars['String']>;
  provider_fhir_id?: Maybe<Scalars['String']>;
  provider_name?: Maybe<Scalars['String']>;
  provider_report_pdf_id?: Maybe<Scalars['String']>;
  rank: Scalars['Float'];
  reference_code: Scalars['String'];
  service_request_id?: Maybe<Scalars['String']>;
  state: Hasura_Zrt_Test_States_Enum;
  swab_code: Scalars['String'];
  test_type: Hasura_Test_Types_Enum;
  tracking_number?: Maybe<Scalars['String']>;
  updated_at: Scalars['String'];
};

export type ReferenceInput = {
  reference?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type ReferenceType = {
  __typename?: 'ReferenceType';
  reference?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type RegisterProviderCalendarInput = {
  calendarId: Scalars['Int'];
  ownerId: Scalars['Int'];
  providerId: Scalars['String'];
};

export type RegisterTestKitInput = {
  questionnaireId: Scalars['Int'];
  questionnaireResponses: Scalars['jsonb'];
  swabBarcode: Scalars['String'];
  testType: Scalars['String'];
  trackingNumber?: InputMaybe<Scalars['String']>;
};

export type RequestZrtTestOutput = {
  __typename?: 'RequestZrtTestOutput';
  requestStatus: ProviderRequestStatus;
  serviceRequestId: Scalars['String'];
};

export type ResourceExtension = {
  __typename?: 'ResourceExtension';
  url?: Maybe<GoogleEhrExtensionsUrLs>;
  valueBoolean?: Maybe<Scalars['Boolean']>;
  valueCodeableConcept?: Maybe<ValuableCodeableConcept>;
  valueString?: Maybe<Scalars['String']>;
};

/** Enum representing the possible values for "resourceType" field in BaseResource. */
export enum ResourcesTypes {
  Appointment = 'APPOINTMENT',
  Basic = 'BASIC',
  Communication = 'COMMUNICATION',
  Condition = 'CONDITION',
  DiagnosticReport = 'DIAGNOSTIC_REPORT',
  Encounter = 'ENCOUNTER',
  Group = 'GROUP',
  HealthCareService = 'HEALTH_CARE_SERVICE',
  List = 'LIST',
  Location = 'LOCATION',
  Media = 'MEDIA',
  Medication = 'MEDICATION',
  MedicationRequest = 'MEDICATION_REQUEST',
  Observation = 'OBSERVATION',
  Organization = 'ORGANIZATION',
  Patient = 'PATIENT',
  Provider = 'PROVIDER',
  ProviderRole = 'PROVIDER_ROLE',
  Questionnaire = 'QUESTIONNAIRE',
  QuestionnaireResponse = 'QUESTIONNAIRE_RESPONSE',
  ServiceRequest = 'SERVICE_REQUEST'
}

export type SaAppointment = {
  __typename?: 'SAAppointment';
  dates: Array<Array<Scalars['String']>>;
  flags: Array<CodexTags>;
  identifiers: Array<Array<Scalars['String']>>;
  logs: Array<Array<Scalars['String']>>;
  patient?: Maybe<SaPerson>;
  provider?: Maybe<SaPerson>;
  status: Scalars['String'];
  type?: Maybe<AppointmentTypeEnum>;
};

export type SaAppointmentBrief = {
  __typename?: 'SAAppointmentBrief';
  dates: Array<Array<Scalars['String']>>;
  flags: Array<CodexTags>;
  identifiers: Array<Array<Scalars['String']>>;
};

export type SaAppointments = {
  __typename?: 'SAAppointments';
  appointments: Array<SaAppointment>;
  count: Scalars['Int'];
  nextPageToken?: Maybe<Scalars['String']>;
  prevPageToken?: Maybe<Scalars['String']>;
  total: Scalars['String'];
};

export type SaDiagnosticReport = {
  __typename?: 'SADiagnosticReport';
  artifacts: Array<Array<Scalars['String']>>;
  dates: Array<Array<Scalars['String']>>;
  flags: Array<CodexTags>;
  identifiers: Array<Array<Scalars['String']>>;
  patient?: Maybe<SaPerson>;
  provider?: Maybe<SaPerson>;
  status: Scalars['String'];
  type: Hasura_Test_Types_Enum;
};

export type SaDiagnosticReports = {
  __typename?: 'SADiagnosticReports';
  count: Scalars['Int'];
  nextPageToken?: Maybe<Scalars['String']>;
  prevPageToken?: Maybe<Scalars['String']>;
  reports: Array<SaDiagnosticReport>;
  total: Scalars['String'];
};

export type SaNarrowingQueryOptions = {
  patientFhirId?: InputMaybe<Scalars['String']>;
  providerFhirId?: InputMaybe<Scalars['String']>;
};

export type SaPageableQueryOptions = {
  limit?: InputMaybe<Scalars['Int']>;
  pageToken?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
};

export type SaPatient = {
  __typename?: 'SAPatient';
  appointmentCount: Array<Array<Scalars['String']>>;
  dates: Array<Array<Scalars['String']>>;
  first: Scalars['String'];
  flags: Array<CodexTags>;
  identifiers: Array<Array<Scalars['String']>>;
  last: Scalars['String'];
  nextAppointment?: Maybe<SaAppointmentBrief>;
  testKitCount: Scalars['Int'];
};

export type SaPatients = {
  __typename?: 'SAPatients';
  count: Scalars['Int'];
  nextPageToken?: Maybe<Scalars['String']>;
  patients: Array<SaPatient>;
  prevPageToken?: Maybe<Scalars['String']>;
  total: Scalars['String'];
};

export type SaPerson = {
  __typename?: 'SAPerson';
  dates: Array<Array<Scalars['String']>>;
  first: Scalars['String'];
  flags: Array<CodexTags>;
  identifiers: Array<Array<Scalars['String']>>;
  last: Scalars['String'];
};

export type SaProvider = {
  __typename?: 'SAProvider';
  appointmentCount: Array<Array<Scalars['String']>>;
  dates: Array<Array<Scalars['String']>>;
  first: Scalars['String'];
  flags: Array<CodexTags>;
  identifiers: Array<Array<Scalars['String']>>;
  last: Scalars['String'];
  patientCount: Scalars['Int'];
};

export type SaProviders = {
  __typename?: 'SAProviders';
  count: Scalars['Int'];
  nextPageToken?: Maybe<Scalars['String']>;
  prevPageToken?: Maybe<Scalars['String']>;
  providers: Array<SaProvider>;
  total: Scalars['String'];
};

export type SaveDnaScannedDataInput = {
  skipTrackingNumber?: InputMaybe<Scalars['Boolean']>;
  swabBarcode?: InputMaybe<Scalars['String']>;
  testType?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  trackingNumber?: InputMaybe<Scalars['String']>;
};

export type SaveFhirQuestionnaireManually = {
  __typename?: 'SaveFHIRQuestionnaireManually';
  id: Scalars['String'];
  responses: Scalars['JSON'];
};

export type SaveQuestionnaireInput = {
  is_complete?: InputMaybe<Scalars['Boolean']>;
  questionnaireId: Scalars['Int'];
  responses: Scalars['jsonb'];
};

export type ScheduleAppointmentInput = {
  appointmentDate?: InputMaybe<Scalars['String']>;
  appointmentTime?: InputMaybe<Scalars['String']>;
  appointmentType?: InputMaybe<Scalars['String']>;
  patientId: Scalars['String'];
  providerId: Scalars['String'];
};

export type SeedFhirOrganizationsOutput = {
  __typename?: 'SeedFHIROrganizationsOutput';
  message: Scalars['String'];
  status: Scalars['Int'];
};

export type ServiceOfferingsOutput = {
  __typename?: 'ServiceOfferingsOutput';
  healthCareServiceFhirId: Scalars['String'];
  price: Scalars['String'];
  priceCategory: PriceCategory;
  serviceType: ServiceType;
};

/** Enum representing the different types of service offerings. */
export enum ServiceType {
  Chat = 'Chat',
  Video = 'Video'
}

/** Enum representing the possible values for "field" field in SkintelligentOrderByInput. */
export enum SkintelligentOrderByCriterias {
  Comedone = 'COMEDONE',
  CreatedAt = 'CREATED_AT',
  ImageSource = 'IMAGE_SOURCE',
  Inflammatory = 'INFLAMMATORY',
  Pih = 'PIH',
  TotalLesions = 'TOTAL_LESIONS'
}

export type SkintelligentOrderByInput = {
  direction: OrderByDirections;
  field: SkintelligentOrderByCriterias;
};

export type SkintelligentTestOutput = {
  __typename?: 'SkintelligentTestOutput';
  comedone?: Maybe<Scalars['Int']>;
  created_at: Scalars['String'];
  image_source: Scalars['String'];
  inflammatory?: Maybe<Scalars['Int']>;
  original_image_id: Scalars['String'];
  overall_score?: Maybe<Scalars['Int']>;
  pih?: Maybe<Scalars['Int']>;
  report_id?: Maybe<Scalars['String']>;
  result_image_id?: Maybe<Scalars['String']>;
  session_id: Scalars['String'];
  test_id: Scalars['String'];
  total_lesions?: Maybe<Scalars['Int']>;
};

export type SkintelligentTestWithUserOutput = {
  __typename?: 'SkintelligentTestWithUserOutput';
  comedone?: Maybe<Scalars['Int']>;
  created_at: Scalars['String'];
  image_source: Scalars['String'];
  inflammatory?: Maybe<Scalars['Int']>;
  original_image_id: Scalars['String'];
  overall_score?: Maybe<Scalars['Int']>;
  pih?: Maybe<Scalars['Int']>;
  report_id?: Maybe<Scalars['String']>;
  result_image_id?: Maybe<Scalars['String']>;
  session_id: Scalars['String'];
  skintelligent_tests_test: SkintelligentTestsTest;
  test_id: Scalars['String'];
  total_lesions?: Maybe<Scalars['Int']>;
};

export type SkintelligentTestsTest = {
  __typename?: 'SkintelligentTestsTest';
  tests_user: SkintelligentTestsUser;
};

export type SkintelligentTestsUser = {
  __typename?: 'SkintelligentTestsUser';
  SENSITIVE_dob: Scalars['String'];
  SENSITIVE_firstname: Scalars['String'];
  SENSITIVE_lastname: Scalars['String'];
  id: Scalars['String'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

export type SuccessGetSignUrlFormStorage = {
  __typename?: 'SuccessGetSignUrlFormStorage';
  status: Scalars['Int'];
  url: Scalars['String'];
};

export type SuccessGetVonageSession = {
  __typename?: 'SuccessGetVonageSession';
  apiKey: Scalars['String'];
  sessionId: Scalars['String'];
  token: Scalars['String'];
};

export type SuccessGetVonageSignUrlFormStorage = {
  __typename?: 'SuccessGetVonageSignUrlFormStorage';
  status: Scalars['Int'];
  url: Scalars['String'];
};

export type SuccessUploadedFileToStorage = {
  __typename?: 'SuccessUploadedFileToStorage';
  file: FileStorage;
  message: Scalars['String'];
  status: Scalars['Int'];
};

export type SuccessUploadedVonageFileToStorage = {
  __typename?: 'SuccessUploadedVonageFileToStorage';
  file: FileVonageStorage;
  message: Scalars['String'];
  status: Scalars['Int'];
};

export type SuccessfulAcceptedLegalDocument = {
  __typename?: 'SuccessfulAcceptedLegalDocument';
  message: Scalars['String'];
  status: Scalars['Int'];
};

export type SuccessfulAccountDeletion = {
  __typename?: 'SuccessfulAccountDeletion';
  message: Scalars['String'];
  status: Scalars['Int'];
};

export type SuccessfulAuthProvider = {
  __typename?: 'SuccessfulAuthProvider';
  message: Scalars['String'];
  status: Scalars['Int'];
};

export type SuccessfulGeneratedCustomToken = {
  __typename?: 'SuccessfulGeneratedCustomToken';
  message: Scalars['String'];
  status: Scalars['Int'];
  token: Scalars['String'];
};

export type SuccessfulGeneratedFirebaseCustomToken = {
  __typename?: 'SuccessfulGeneratedFirebaseCustomToken';
  message: Scalars['String'];
  status: Scalars['Int'];
  token: Scalars['String'];
};

export type SuccessfulGetVonageArchives = {
  __typename?: 'SuccessfulGetVonageArchives';
  archives?: Maybe<Array<Scalars['jsonb']>>;
};

export type SuccessfulProviderUpdated = {
  __typename?: 'SuccessfulProviderUpdated';
  message: Scalars['String'];
  status: Scalars['Int'];
  update_providers_one: Scalars['jsonb'];
};

export type SuccessfulQuestionnaireSave = {
  __typename?: 'SuccessfulQuestionnaireSave';
  message: Scalars['String'];
  status: Scalars['Int'];
};

export type SuccessfulQuestionnaireUpdate = {
  __typename?: 'SuccessfulQuestionnaireUpdate';
  message: Scalars['String'];
  status: Scalars['Int'];
};

export type SuccessfulRegisteredCalendarInformation = {
  __typename?: 'SuccessfulRegisteredCalendarInformation';
  message: Scalars['String'];
  status: Scalars['Int'];
};

export type SuccessfulRegisteredTestKit = {
  __typename?: 'SuccessfulRegisteredTestKit';
  message: Scalars['String'];
  status: Scalars['Int'];
};

export type SuccessfulSavedScannedData = {
  __typename?: 'SuccessfulSavedScannedData';
  message: Scalars['String'];
  status: Scalars['Int'];
};

export type SuccessfulSignup = {
  __typename?: 'SuccessfulSignup';
  message: Scalars['String'];
  status: Scalars['Int'];
};

export type SuccessfulUpdateInAppNotification = {
  __typename?: 'SuccessfulUpdateInAppNotification';
  message: Scalars['String'];
  status: Scalars['Int'];
};

export type SuccessfulUpdateProfileNotification = {
  __typename?: 'SuccessfulUpdateProfileNotification';
  message: Scalars['String'];
  status: Scalars['Int'];
};

export type SuccessfulUpdatedProviderAccountSettings = {
  __typename?: 'SuccessfulUpdatedProviderAccountSettings';
  message: Scalars['String'];
  status: Scalars['Int'];
};

export type SuccessfulUploadedImage = {
  __typename?: 'SuccessfulUploadedImage';
  message: Scalars['String'];
  sessionId: Scalars['String'];
  status: Scalars['Int'];
};

export type SuccessfulValidatedCustomToken = {
  __typename?: 'SuccessfulValidatedCustomToken';
  message: Scalars['String'];
  status: Scalars['Int'];
};

export type SuccessfulVonageArchiveStart = {
  __typename?: 'SuccessfulVonageArchiveStart';
  archiveId: Scalars['String'];
  status: Scalars['String'];
};

export type SuccessfulVonageArchiveStop = {
  __typename?: 'SuccessfulVonageArchiveStop';
  archiveId: Scalars['String'];
  status: Scalars['String'];
};

export type SuccessfullScheduledAppointment = {
  __typename?: 'SuccessfullScheduledAppointment';
  message: Scalars['String'];
  status: Scalars['Int'];
};

export type SuccessfullUpdatedProfilePicture = {
  __typename?: 'SuccessfullUpdatedProfilePicture';
  fileId: Scalars['String'];
  message: Scalars['String'];
  status: Scalars['Int'];
};

/** A generic list of states valid across both DNA Visit and ZRT */
export enum Test_States_Enum {
  AppointmentScheduled = 'AppointmentScheduled',
  Complete = 'Complete',
  KitNotReceived = 'KitNotReceived',
  ProviderPending = 'ProviderPending',
  ReceivedByLab = 'ReceivedByLab',
  ResultsWithProvider = 'ResultsWithProvider'
}

export type ThenObject = {
  __typename?: 'ThenObject';
  disabled?: Maybe<Array<Scalars['String']>>;
  enabled?: Maybe<Array<Scalars['String']>>;
  required?: Maybe<Array<Scalars['String']>>;
};

export type UpcomingAppointmentCounts = {
  __typename?: 'UpcomingAppointmentCounts';
  month: Scalars['Int'];
  today: Scalars['Int'];
  tomorrow: Scalars['Int'];
  week: Scalars['Int'];
};

export type UpdateAppointmentStatusFhirAppointmentInput = {
  appointmentCodexId: Scalars['String'];
  isRescheduled?: InputMaybe<Scalars['Boolean']>;
  status: Scalars['String'];
};

export type UpdateBecomePatientRequestStatusInput = {
  date: Scalars['String'];
  itemReference: ReferenceInput;
  patientFhirId: Scalars['String'];
  requestStatus: ProviderRequestStatus;
};

export type UpdateFhirUserLatestQuestionnaire = {
  __typename?: 'UpdateFHIRUserLatestQuestionnaire';
  affected_rows: Scalars['Int'];
};

export type UpdateInAppNotificationInput = {
  id: Scalars['String'];
  status: Scalars['String'];
};

export type UpdateProfilePictureInput = {
  filename?: InputMaybe<Scalars['String']>;
  image: Scalars['String'];
};

export type UpdateProviderAccountSettingsInput = {
  account_settings: Scalars['jsonb'];
};

export type UpdateProviderInput = {
  about?: InputMaybe<Scalars['String']>;
  address_information?: InputMaybe<Scalars['jsonb']>;
  credentials?: InputMaybe<Scalars['jsonb']>;
  education?: InputMaybe<Scalars['jsonb']>;
  email?: InputMaybe<Scalars['String']>;
  firstname?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  languagesOfCommunication?: InputMaybe<Scalars['jsonb']>;
  lastname?: InputMaybe<Scalars['String']>;
  nationalProviderIdentifier?: InputMaybe<Scalars['String']>;
  office_information?: InputMaybe<Scalars['jsonb']>;
  phone?: InputMaybe<Scalars['String']>;
};

export type UpdateQuestionnaireInput = {
  is_complete?: InputMaybe<Scalars['Boolean']>;
  questionnaireId: Scalars['Int'];
  responses: Scalars['jsonb'];
};

export type UploadFileToStorageInput = {
  buffer: Array<Scalars['Byte']>;
  mimetype: Scalars['String'];
  originalname: Scalars['String'];
};

export type UploadImageInput = {
  filename?: InputMaybe<Scalars['String']>;
  image: Scalars['String'];
  source: Scalars['String'];
  token?: InputMaybe<Scalars['String']>;
};

export type UploadVonageFileToStorageInput = {
  buffer: Array<Scalars['Byte']>;
  mimetype: Scalars['String'];
  originalname: Scalars['String'];
};

export type UserSignupInput = {
  country?: InputMaybe<Scalars['String']>;
  dob?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firebaseToken: Scalars['String'];
  firstname?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  lastname?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export type ValuableCodeableConcept = {
  __typename?: 'ValuableCodeableConcept';
  coding: Array<CodeableConceptData>;
};

export enum VonageDisconnectReason {
  ClientDisconnected = 'ClientDisconnected',
  ForceDisconnected = 'ForceDisconnected',
  NetworkDisconnected = 'NetworkDisconnected'
}

export type ZrtKitIdsBatchOutput = {
  __typename?: 'ZrtKitIdsBatchOutput';
  kitIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Enum representing the possible values for "field" field in ZrtOrderByCriterias. */
export enum ZrtOrderByCriterias {
  Barcode = 'BARCODE',
  CreatedAt = 'CREATED_AT',
  ProviderName = 'PROVIDER_NAME',
  TestType = 'TEST_TYPE'
}

export type ZrtOrderByInput = {
  direction: OrderByDirections;
  field: ZrtOrderByCriterias;
};

export type ZrtTestOutput = {
  __typename?: 'ZrtTestOutput';
  created_at: Scalars['String'];
  fhirId: Scalars['String'];
  health_questionnaire_pdf_id?: Maybe<Scalars['String']>;
  npi_number?: Maybe<Scalars['String']>;
  observation_id?: Maybe<Scalars['String']>;
  patient_fhir_id?: Maybe<Scalars['String']>;
  patient_pdf_id?: Maybe<Scalars['String']>;
  provider_acuity_calendar_id?: Maybe<Scalars['String']>;
  provider_acuity_owner_id?: Maybe<Scalars['String']>;
  provider_codex_id?: Maybe<Scalars['String']>;
  provider_fhir_id?: Maybe<Scalars['String']>;
  provider_name?: Maybe<Scalars['String']>;
  provider_report_pdf_id?: Maybe<Scalars['String']>;
  reference_code: Scalars['String'];
  service_request_id?: Maybe<Scalars['String']>;
  state: Hasura_Zrt_Test_States_Enum;
  swab_code: Scalars['String'];
  test_type: Hasura_Test_Types_Enum;
  tracking_number?: Maybe<Scalars['String']>;
  updated_at: Scalars['String'];
};

/** Boolean expression to compare columns of type "_text". All fields are combined with logical 'AND'. */
export type _Text_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['_text']>;
  _gt?: InputMaybe<Scalars['_text']>;
  _gte?: InputMaybe<Scalars['_text']>;
  _in?: InputMaybe<Array<Scalars['_text']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['_text']>;
  _lte?: InputMaybe<Scalars['_text']>;
  _neq?: InputMaybe<Scalars['_text']>;
  _nin?: InputMaybe<Array<Scalars['_text']>>;
};

/** columns and relationships of "accepted_legal_documents" */
export type Accepted_Legal_Documents = {
  __typename?: 'accepted_legal_documents';
  acceptance_date: Scalars['timestamptz'];
  accepted_hash_content: Scalars['String'];
  accepted_legal_document_id: Scalars['bigint'];
  /** An object relationship */
  accepted_legal_documents_legal_document: Legal_Documents;
  /** An object relationship */
  accepted_legal_documents_user: Users;
  expiration_date?: Maybe<Scalars['timestamptz']>;
  legal_document_id: Scalars['bigint'];
  termination_date?: Maybe<Scalars['timestamptz']>;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "accepted_legal_documents" */
export type Accepted_Legal_Documents_Aggregate = {
  __typename?: 'accepted_legal_documents_aggregate';
  aggregate?: Maybe<Accepted_Legal_Documents_Aggregate_Fields>;
  nodes: Array<Accepted_Legal_Documents>;
};

/** aggregate fields of "accepted_legal_documents" */
export type Accepted_Legal_Documents_Aggregate_Fields = {
  __typename?: 'accepted_legal_documents_aggregate_fields';
  avg?: Maybe<Accepted_Legal_Documents_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Accepted_Legal_Documents_Max_Fields>;
  min?: Maybe<Accepted_Legal_Documents_Min_Fields>;
  stddev?: Maybe<Accepted_Legal_Documents_Stddev_Fields>;
  stddev_pop?: Maybe<Accepted_Legal_Documents_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Accepted_Legal_Documents_Stddev_Samp_Fields>;
  sum?: Maybe<Accepted_Legal_Documents_Sum_Fields>;
  var_pop?: Maybe<Accepted_Legal_Documents_Var_Pop_Fields>;
  var_samp?: Maybe<Accepted_Legal_Documents_Var_Samp_Fields>;
  variance?: Maybe<Accepted_Legal_Documents_Variance_Fields>;
};


/** aggregate fields of "accepted_legal_documents" */
export type Accepted_Legal_Documents_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Accepted_Legal_Documents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Accepted_Legal_Documents_Avg_Fields = {
  __typename?: 'accepted_legal_documents_avg_fields';
  accepted_legal_document_id?: Maybe<Scalars['Float']>;
  legal_document_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "accepted_legal_documents". All fields are combined with a logical 'AND'. */
export type Accepted_Legal_Documents_Bool_Exp = {
  _and?: InputMaybe<Array<Accepted_Legal_Documents_Bool_Exp>>;
  _not?: InputMaybe<Accepted_Legal_Documents_Bool_Exp>;
  _or?: InputMaybe<Array<Accepted_Legal_Documents_Bool_Exp>>;
  acceptance_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  accepted_hash_content?: InputMaybe<String_Comparison_Exp>;
  accepted_legal_document_id?: InputMaybe<Bigint_Comparison_Exp>;
  accepted_legal_documents_legal_document?: InputMaybe<Legal_Documents_Bool_Exp>;
  accepted_legal_documents_user?: InputMaybe<Users_Bool_Exp>;
  expiration_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  legal_document_id?: InputMaybe<Bigint_Comparison_Exp>;
  termination_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "accepted_legal_documents" */
export enum Accepted_Legal_Documents_Constraint {
  /** unique or primary key constraint on columns "accepted_legal_document_id" */
  AcceptedLegalDocumentsPkey = 'accepted_legal_documents_pkey'
}

/** input type for incrementing numeric columns in table "accepted_legal_documents" */
export type Accepted_Legal_Documents_Inc_Input = {
  accepted_legal_document_id?: InputMaybe<Scalars['bigint']>;
  legal_document_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "accepted_legal_documents" */
export type Accepted_Legal_Documents_Insert_Input = {
  acceptance_date?: InputMaybe<Scalars['timestamptz']>;
  accepted_hash_content?: InputMaybe<Scalars['String']>;
  accepted_legal_document_id?: InputMaybe<Scalars['bigint']>;
  accepted_legal_documents_legal_document?: InputMaybe<Legal_Documents_Obj_Rel_Insert_Input>;
  accepted_legal_documents_user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  expiration_date?: InputMaybe<Scalars['timestamptz']>;
  legal_document_id?: InputMaybe<Scalars['bigint']>;
  termination_date?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Accepted_Legal_Documents_Max_Fields = {
  __typename?: 'accepted_legal_documents_max_fields';
  acceptance_date?: Maybe<Scalars['timestamptz']>;
  accepted_hash_content?: Maybe<Scalars['String']>;
  accepted_legal_document_id?: Maybe<Scalars['bigint']>;
  expiration_date?: Maybe<Scalars['timestamptz']>;
  legal_document_id?: Maybe<Scalars['bigint']>;
  termination_date?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Accepted_Legal_Documents_Min_Fields = {
  __typename?: 'accepted_legal_documents_min_fields';
  acceptance_date?: Maybe<Scalars['timestamptz']>;
  accepted_hash_content?: Maybe<Scalars['String']>;
  accepted_legal_document_id?: Maybe<Scalars['bigint']>;
  expiration_date?: Maybe<Scalars['timestamptz']>;
  legal_document_id?: Maybe<Scalars['bigint']>;
  termination_date?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "accepted_legal_documents" */
export type Accepted_Legal_Documents_Mutation_Response = {
  __typename?: 'accepted_legal_documents_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Accepted_Legal_Documents>;
};

/** on_conflict condition type for table "accepted_legal_documents" */
export type Accepted_Legal_Documents_On_Conflict = {
  constraint: Accepted_Legal_Documents_Constraint;
  update_columns?: Array<Accepted_Legal_Documents_Update_Column>;
  where?: InputMaybe<Accepted_Legal_Documents_Bool_Exp>;
};

/** Ordering options when selecting data from "accepted_legal_documents". */
export type Accepted_Legal_Documents_Order_By = {
  acceptance_date?: InputMaybe<Order_By>;
  accepted_hash_content?: InputMaybe<Order_By>;
  accepted_legal_document_id?: InputMaybe<Order_By>;
  accepted_legal_documents_legal_document?: InputMaybe<Legal_Documents_Order_By>;
  accepted_legal_documents_user?: InputMaybe<Users_Order_By>;
  expiration_date?: InputMaybe<Order_By>;
  legal_document_id?: InputMaybe<Order_By>;
  termination_date?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: accepted_legal_documents */
export type Accepted_Legal_Documents_Pk_Columns_Input = {
  accepted_legal_document_id: Scalars['bigint'];
};

/** select columns of table "accepted_legal_documents" */
export enum Accepted_Legal_Documents_Select_Column {
  /** column name */
  AcceptanceDate = 'acceptance_date',
  /** column name */
  AcceptedHashContent = 'accepted_hash_content',
  /** column name */
  AcceptedLegalDocumentId = 'accepted_legal_document_id',
  /** column name */
  ExpirationDate = 'expiration_date',
  /** column name */
  LegalDocumentId = 'legal_document_id',
  /** column name */
  TerminationDate = 'termination_date',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "accepted_legal_documents" */
export type Accepted_Legal_Documents_Set_Input = {
  acceptance_date?: InputMaybe<Scalars['timestamptz']>;
  accepted_hash_content?: InputMaybe<Scalars['String']>;
  accepted_legal_document_id?: InputMaybe<Scalars['bigint']>;
  expiration_date?: InputMaybe<Scalars['timestamptz']>;
  legal_document_id?: InputMaybe<Scalars['bigint']>;
  termination_date?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Accepted_Legal_Documents_Stddev_Fields = {
  __typename?: 'accepted_legal_documents_stddev_fields';
  accepted_legal_document_id?: Maybe<Scalars['Float']>;
  legal_document_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Accepted_Legal_Documents_Stddev_Pop_Fields = {
  __typename?: 'accepted_legal_documents_stddev_pop_fields';
  accepted_legal_document_id?: Maybe<Scalars['Float']>;
  legal_document_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Accepted_Legal_Documents_Stddev_Samp_Fields = {
  __typename?: 'accepted_legal_documents_stddev_samp_fields';
  accepted_legal_document_id?: Maybe<Scalars['Float']>;
  legal_document_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "accepted_legal_documents" */
export type Accepted_Legal_Documents_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Accepted_Legal_Documents_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Accepted_Legal_Documents_Stream_Cursor_Value_Input = {
  acceptance_date?: InputMaybe<Scalars['timestamptz']>;
  accepted_hash_content?: InputMaybe<Scalars['String']>;
  accepted_legal_document_id?: InputMaybe<Scalars['bigint']>;
  expiration_date?: InputMaybe<Scalars['timestamptz']>;
  legal_document_id?: InputMaybe<Scalars['bigint']>;
  termination_date?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Accepted_Legal_Documents_Sum_Fields = {
  __typename?: 'accepted_legal_documents_sum_fields';
  accepted_legal_document_id?: Maybe<Scalars['bigint']>;
  legal_document_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "accepted_legal_documents" */
export enum Accepted_Legal_Documents_Update_Column {
  /** column name */
  AcceptanceDate = 'acceptance_date',
  /** column name */
  AcceptedHashContent = 'accepted_hash_content',
  /** column name */
  AcceptedLegalDocumentId = 'accepted_legal_document_id',
  /** column name */
  ExpirationDate = 'expiration_date',
  /** column name */
  LegalDocumentId = 'legal_document_id',
  /** column name */
  TerminationDate = 'termination_date',
  /** column name */
  UserId = 'user_id'
}

export type Accepted_Legal_Documents_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Accepted_Legal_Documents_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Accepted_Legal_Documents_Set_Input>;
  /** filter the rows which have to be updated */
  where: Accepted_Legal_Documents_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Accepted_Legal_Documents_Var_Pop_Fields = {
  __typename?: 'accepted_legal_documents_var_pop_fields';
  accepted_legal_document_id?: Maybe<Scalars['Float']>;
  legal_document_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Accepted_Legal_Documents_Var_Samp_Fields = {
  __typename?: 'accepted_legal_documents_var_samp_fields';
  accepted_legal_document_id?: Maybe<Scalars['Float']>;
  legal_document_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Accepted_Legal_Documents_Variance_Fields = {
  __typename?: 'accepted_legal_documents_variance_fields';
  accepted_legal_document_id?: Maybe<Scalars['Float']>;
  legal_document_id?: Maybe<Scalars['Float']>;
};

/** Table that holds the list of calendar Ids and owner ID for providers. */
export type Acuity_Provider_Calendar = {
  __typename?: 'acuity_provider_calendar';
  active: Scalars['Boolean'];
  /** An object relationship */
  acuity_provider_calendar_user_user_id?: Maybe<Users>;
  calendar_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  owner_id?: Maybe<Scalars['bigint']>;
  provider_calendar?: Maybe<Scalars['String']>;
  provider_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "acuity_provider_calendar" */
export type Acuity_Provider_Calendar_Aggregate = {
  __typename?: 'acuity_provider_calendar_aggregate';
  aggregate?: Maybe<Acuity_Provider_Calendar_Aggregate_Fields>;
  nodes: Array<Acuity_Provider_Calendar>;
};

/** aggregate fields of "acuity_provider_calendar" */
export type Acuity_Provider_Calendar_Aggregate_Fields = {
  __typename?: 'acuity_provider_calendar_aggregate_fields';
  avg?: Maybe<Acuity_Provider_Calendar_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Acuity_Provider_Calendar_Max_Fields>;
  min?: Maybe<Acuity_Provider_Calendar_Min_Fields>;
  stddev?: Maybe<Acuity_Provider_Calendar_Stddev_Fields>;
  stddev_pop?: Maybe<Acuity_Provider_Calendar_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Acuity_Provider_Calendar_Stddev_Samp_Fields>;
  sum?: Maybe<Acuity_Provider_Calendar_Sum_Fields>;
  var_pop?: Maybe<Acuity_Provider_Calendar_Var_Pop_Fields>;
  var_samp?: Maybe<Acuity_Provider_Calendar_Var_Samp_Fields>;
  variance?: Maybe<Acuity_Provider_Calendar_Variance_Fields>;
};


/** aggregate fields of "acuity_provider_calendar" */
export type Acuity_Provider_Calendar_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Acuity_Provider_Calendar_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Acuity_Provider_Calendar_Avg_Fields = {
  __typename?: 'acuity_provider_calendar_avg_fields';
  calendar_id?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "acuity_provider_calendar". All fields are combined with a logical 'AND'. */
export type Acuity_Provider_Calendar_Bool_Exp = {
  _and?: InputMaybe<Array<Acuity_Provider_Calendar_Bool_Exp>>;
  _not?: InputMaybe<Acuity_Provider_Calendar_Bool_Exp>;
  _or?: InputMaybe<Array<Acuity_Provider_Calendar_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  acuity_provider_calendar_user_user_id?: InputMaybe<Users_Bool_Exp>;
  calendar_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  owner_id?: InputMaybe<Bigint_Comparison_Exp>;
  provider_calendar?: InputMaybe<String_Comparison_Exp>;
  provider_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "acuity_provider_calendar" */
export enum Acuity_Provider_Calendar_Constraint {
  /** unique or primary key constraint on columns "calendar_id" */
  AcuityProviderCalendarCalendarIdKey = 'acuity_provider_calendar_calendar_id_key',
  /** unique or primary key constraint on columns "id" */
  AcuityProviderCalendarPkey = 'acuity_provider_calendar_pkey',
  /** unique or primary key constraint on columns "provider_id" */
  AcuityProviderCalendarProviderIdKey = 'acuity_provider_calendar_provider_id_key'
}

/** input type for incrementing numeric columns in table "acuity_provider_calendar" */
export type Acuity_Provider_Calendar_Inc_Input = {
  calendar_id?: InputMaybe<Scalars['bigint']>;
  owner_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "acuity_provider_calendar" */
export type Acuity_Provider_Calendar_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  acuity_provider_calendar_user_user_id?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  calendar_id?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  owner_id?: InputMaybe<Scalars['bigint']>;
  provider_calendar?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Acuity_Provider_Calendar_Max_Fields = {
  __typename?: 'acuity_provider_calendar_max_fields';
  calendar_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  owner_id?: Maybe<Scalars['bigint']>;
  provider_calendar?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Acuity_Provider_Calendar_Min_Fields = {
  __typename?: 'acuity_provider_calendar_min_fields';
  calendar_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  owner_id?: Maybe<Scalars['bigint']>;
  provider_calendar?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "acuity_provider_calendar" */
export type Acuity_Provider_Calendar_Mutation_Response = {
  __typename?: 'acuity_provider_calendar_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Acuity_Provider_Calendar>;
};

/** on_conflict condition type for table "acuity_provider_calendar" */
export type Acuity_Provider_Calendar_On_Conflict = {
  constraint: Acuity_Provider_Calendar_Constraint;
  update_columns?: Array<Acuity_Provider_Calendar_Update_Column>;
  where?: InputMaybe<Acuity_Provider_Calendar_Bool_Exp>;
};

/** Ordering options when selecting data from "acuity_provider_calendar". */
export type Acuity_Provider_Calendar_Order_By = {
  active?: InputMaybe<Order_By>;
  acuity_provider_calendar_user_user_id?: InputMaybe<Users_Order_By>;
  calendar_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  provider_calendar?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: acuity_provider_calendar */
export type Acuity_Provider_Calendar_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "acuity_provider_calendar" */
export enum Acuity_Provider_Calendar_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CalendarId = 'calendar_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  ProviderCalendar = 'provider_calendar',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "acuity_provider_calendar" */
export type Acuity_Provider_Calendar_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  calendar_id?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  owner_id?: InputMaybe<Scalars['bigint']>;
  provider_calendar?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Acuity_Provider_Calendar_Stddev_Fields = {
  __typename?: 'acuity_provider_calendar_stddev_fields';
  calendar_id?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Acuity_Provider_Calendar_Stddev_Pop_Fields = {
  __typename?: 'acuity_provider_calendar_stddev_pop_fields';
  calendar_id?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Acuity_Provider_Calendar_Stddev_Samp_Fields = {
  __typename?: 'acuity_provider_calendar_stddev_samp_fields';
  calendar_id?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "acuity_provider_calendar" */
export type Acuity_Provider_Calendar_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Acuity_Provider_Calendar_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Acuity_Provider_Calendar_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  calendar_id?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  owner_id?: InputMaybe<Scalars['bigint']>;
  provider_calendar?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Acuity_Provider_Calendar_Sum_Fields = {
  __typename?: 'acuity_provider_calendar_sum_fields';
  calendar_id?: Maybe<Scalars['bigint']>;
  owner_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "acuity_provider_calendar" */
export enum Acuity_Provider_Calendar_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CalendarId = 'calendar_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  ProviderCalendar = 'provider_calendar',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Acuity_Provider_Calendar_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Acuity_Provider_Calendar_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Acuity_Provider_Calendar_Set_Input>;
  /** filter the rows which have to be updated */
  where: Acuity_Provider_Calendar_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Acuity_Provider_Calendar_Var_Pop_Fields = {
  __typename?: 'acuity_provider_calendar_var_pop_fields';
  calendar_id?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Acuity_Provider_Calendar_Var_Samp_Fields = {
  __typename?: 'acuity_provider_calendar_var_samp_fields';
  calendar_id?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Acuity_Provider_Calendar_Variance_Fields = {
  __typename?: 'acuity_provider_calendar_variance_fields';
  calendar_id?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "addresses" */
export type Addresses = {
  __typename?: 'addresses';
  address_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  is_default: Scalars['Boolean'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "addresses" */
export type Addresses_Aggregate = {
  __typename?: 'addresses_aggregate';
  aggregate?: Maybe<Addresses_Aggregate_Fields>;
  nodes: Array<Addresses>;
};

/** aggregate fields of "addresses" */
export type Addresses_Aggregate_Fields = {
  __typename?: 'addresses_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Addresses_Max_Fields>;
  min?: Maybe<Addresses_Min_Fields>;
};


/** aggregate fields of "addresses" */
export type Addresses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Addresses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "addresses". All fields are combined with a logical 'AND'. */
export type Addresses_Bool_Exp = {
  _and?: InputMaybe<Array<Addresses_Bool_Exp>>;
  _not?: InputMaybe<Addresses_Bool_Exp>;
  _or?: InputMaybe<Array<Addresses_Bool_Exp>>;
  address_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  is_default?: InputMaybe<Boolean_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "addresses" */
export enum Addresses_Constraint {
  /** unique or primary key constraint on columns "address_id" */
  AddressesPkey = 'addresses_pkey'
}

/** input type for inserting data into table "addresses" */
export type Addresses_Insert_Input = {
  address_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  is_default?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Addresses_Max_Fields = {
  __typename?: 'addresses_max_fields';
  address_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Addresses_Min_Fields = {
  __typename?: 'addresses_min_fields';
  address_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "addresses" */
export type Addresses_Mutation_Response = {
  __typename?: 'addresses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Addresses>;
};

/** on_conflict condition type for table "addresses" */
export type Addresses_On_Conflict = {
  constraint: Addresses_Constraint;
  update_columns?: Array<Addresses_Update_Column>;
  where?: InputMaybe<Addresses_Bool_Exp>;
};

/** Ordering options when selecting data from "addresses". */
export type Addresses_Order_By = {
  address_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  is_default?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: addresses */
export type Addresses_Pk_Columns_Input = {
  address_id: Scalars['uuid'];
};

/** select columns of table "addresses" */
export enum Addresses_Select_Column {
  /** column name */
  AddressId = 'address_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsDefault = 'is_default',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "addresses" */
export type Addresses_Set_Input = {
  address_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  is_default?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "addresses" */
export type Addresses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Addresses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Addresses_Stream_Cursor_Value_Input = {
  address_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  is_default?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "addresses" */
export enum Addresses_Update_Column {
  /** column name */
  AddressId = 'address_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsDefault = 'is_default',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Addresses_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Addresses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Addresses_Bool_Exp;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']>;
  _gt?: InputMaybe<Scalars['bigint']>;
  _gte?: InputMaybe<Scalars['bigint']>;
  _in?: InputMaybe<Array<Scalars['bigint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bigint']>;
  _lte?: InputMaybe<Scalars['bigint']>;
  _neq?: InputMaybe<Scalars['bigint']>;
  _nin?: InputMaybe<Array<Scalars['bigint']>>;
};

/** columns and relationships of "channel_signals" */
export type Channel_Signals = {
  __typename?: 'channel_signals';
  channel: Scalars['String'];
  id: Scalars['Int'];
  key?: Maybe<Scalars['String']>;
  last_updated: Scalars['timestamptz'];
};

/** aggregated selection of "channel_signals" */
export type Channel_Signals_Aggregate = {
  __typename?: 'channel_signals_aggregate';
  aggregate?: Maybe<Channel_Signals_Aggregate_Fields>;
  nodes: Array<Channel_Signals>;
};

/** aggregate fields of "channel_signals" */
export type Channel_Signals_Aggregate_Fields = {
  __typename?: 'channel_signals_aggregate_fields';
  avg?: Maybe<Channel_Signals_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Channel_Signals_Max_Fields>;
  min?: Maybe<Channel_Signals_Min_Fields>;
  stddev?: Maybe<Channel_Signals_Stddev_Fields>;
  stddev_pop?: Maybe<Channel_Signals_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Channel_Signals_Stddev_Samp_Fields>;
  sum?: Maybe<Channel_Signals_Sum_Fields>;
  var_pop?: Maybe<Channel_Signals_Var_Pop_Fields>;
  var_samp?: Maybe<Channel_Signals_Var_Samp_Fields>;
  variance?: Maybe<Channel_Signals_Variance_Fields>;
};


/** aggregate fields of "channel_signals" */
export type Channel_Signals_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Channel_Signals_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Channel_Signals_Avg_Fields = {
  __typename?: 'channel_signals_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "channel_signals". All fields are combined with a logical 'AND'. */
export type Channel_Signals_Bool_Exp = {
  _and?: InputMaybe<Array<Channel_Signals_Bool_Exp>>;
  _not?: InputMaybe<Channel_Signals_Bool_Exp>;
  _or?: InputMaybe<Array<Channel_Signals_Bool_Exp>>;
  channel?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  last_updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "channel_signals" */
export enum Channel_Signals_Constraint {
  /** unique or primary key constraint on columns "key", "channel" */
  ChannelSignalsChannelKeyKey = 'channel_signals_channel_key_key',
  /** unique or primary key constraint on columns "id" */
  ChannelSignalsPkey = 'channel_signals_pkey'
}

/** input type for incrementing numeric columns in table "channel_signals" */
export type Channel_Signals_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "channel_signals" */
export type Channel_Signals_Insert_Input = {
  channel?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  key?: InputMaybe<Scalars['String']>;
  last_updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Channel_Signals_Max_Fields = {
  __typename?: 'channel_signals_max_fields';
  channel?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  last_updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Channel_Signals_Min_Fields = {
  __typename?: 'channel_signals_min_fields';
  channel?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  last_updated?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "channel_signals" */
export type Channel_Signals_Mutation_Response = {
  __typename?: 'channel_signals_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Channel_Signals>;
};

/** on_conflict condition type for table "channel_signals" */
export type Channel_Signals_On_Conflict = {
  constraint: Channel_Signals_Constraint;
  update_columns?: Array<Channel_Signals_Update_Column>;
  where?: InputMaybe<Channel_Signals_Bool_Exp>;
};

/** Ordering options when selecting data from "channel_signals". */
export type Channel_Signals_Order_By = {
  channel?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  last_updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: channel_signals */
export type Channel_Signals_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "channel_signals" */
export enum Channel_Signals_Select_Column {
  /** column name */
  Channel = 'channel',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  LastUpdated = 'last_updated'
}

/** input type for updating data in table "channel_signals" */
export type Channel_Signals_Set_Input = {
  channel?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  key?: InputMaybe<Scalars['String']>;
  last_updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Channel_Signals_Stddev_Fields = {
  __typename?: 'channel_signals_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Channel_Signals_Stddev_Pop_Fields = {
  __typename?: 'channel_signals_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Channel_Signals_Stddev_Samp_Fields = {
  __typename?: 'channel_signals_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "channel_signals" */
export type Channel_Signals_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Channel_Signals_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Channel_Signals_Stream_Cursor_Value_Input = {
  channel?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  key?: InputMaybe<Scalars['String']>;
  last_updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Channel_Signals_Sum_Fields = {
  __typename?: 'channel_signals_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "channel_signals" */
export enum Channel_Signals_Update_Column {
  /** column name */
  Channel = 'channel',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  LastUpdated = 'last_updated'
}

export type Channel_Signals_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Channel_Signals_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Channel_Signals_Set_Input>;
  /** filter the rows which have to be updated */
  where: Channel_Signals_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Channel_Signals_Var_Pop_Fields = {
  __typename?: 'channel_signals_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Channel_Signals_Var_Samp_Fields = {
  __typename?: 'channel_signals_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Channel_Signals_Variance_Fields = {
  __typename?: 'channel_signals_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "common_question_responses" */
export type Common_Question_Responses = {
  __typename?: 'common_question_responses';
  common_question_id: Scalars['Int'];
  id: Scalars['bigint'];
  responses: Scalars['jsonb'];
  user_id: Scalars['uuid'];
};


/** columns and relationships of "common_question_responses" */
export type Common_Question_ResponsesResponsesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "common_question_responses" */
export type Common_Question_Responses_Aggregate = {
  __typename?: 'common_question_responses_aggregate';
  aggregate?: Maybe<Common_Question_Responses_Aggregate_Fields>;
  nodes: Array<Common_Question_Responses>;
};

/** aggregate fields of "common_question_responses" */
export type Common_Question_Responses_Aggregate_Fields = {
  __typename?: 'common_question_responses_aggregate_fields';
  avg?: Maybe<Common_Question_Responses_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Common_Question_Responses_Max_Fields>;
  min?: Maybe<Common_Question_Responses_Min_Fields>;
  stddev?: Maybe<Common_Question_Responses_Stddev_Fields>;
  stddev_pop?: Maybe<Common_Question_Responses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Common_Question_Responses_Stddev_Samp_Fields>;
  sum?: Maybe<Common_Question_Responses_Sum_Fields>;
  var_pop?: Maybe<Common_Question_Responses_Var_Pop_Fields>;
  var_samp?: Maybe<Common_Question_Responses_Var_Samp_Fields>;
  variance?: Maybe<Common_Question_Responses_Variance_Fields>;
};


/** aggregate fields of "common_question_responses" */
export type Common_Question_Responses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Common_Question_Responses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Common_Question_Responses_Append_Input = {
  responses?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Common_Question_Responses_Avg_Fields = {
  __typename?: 'common_question_responses_avg_fields';
  common_question_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "common_question_responses". All fields are combined with a logical 'AND'. */
export type Common_Question_Responses_Bool_Exp = {
  _and?: InputMaybe<Array<Common_Question_Responses_Bool_Exp>>;
  _not?: InputMaybe<Common_Question_Responses_Bool_Exp>;
  _or?: InputMaybe<Array<Common_Question_Responses_Bool_Exp>>;
  common_question_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  responses?: InputMaybe<Jsonb_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "common_question_responses" */
export enum Common_Question_Responses_Constraint {
  /** unique or primary key constraint on columns "id" */
  CommonQuestionResponsesPkey = 'common_question_responses_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Common_Question_Responses_Delete_At_Path_Input = {
  responses?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Common_Question_Responses_Delete_Elem_Input = {
  responses?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Common_Question_Responses_Delete_Key_Input = {
  responses?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "common_question_responses" */
export type Common_Question_Responses_Inc_Input = {
  common_question_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "common_question_responses" */
export type Common_Question_Responses_Insert_Input = {
  common_question_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  responses?: InputMaybe<Scalars['jsonb']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Common_Question_Responses_Max_Fields = {
  __typename?: 'common_question_responses_max_fields';
  common_question_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Common_Question_Responses_Min_Fields = {
  __typename?: 'common_question_responses_min_fields';
  common_question_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "common_question_responses" */
export type Common_Question_Responses_Mutation_Response = {
  __typename?: 'common_question_responses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Common_Question_Responses>;
};

/** on_conflict condition type for table "common_question_responses" */
export type Common_Question_Responses_On_Conflict = {
  constraint: Common_Question_Responses_Constraint;
  update_columns?: Array<Common_Question_Responses_Update_Column>;
  where?: InputMaybe<Common_Question_Responses_Bool_Exp>;
};

/** Ordering options when selecting data from "common_question_responses". */
export type Common_Question_Responses_Order_By = {
  common_question_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  responses?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: common_question_responses */
export type Common_Question_Responses_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Common_Question_Responses_Prepend_Input = {
  responses?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "common_question_responses" */
export enum Common_Question_Responses_Select_Column {
  /** column name */
  CommonQuestionId = 'common_question_id',
  /** column name */
  Id = 'id',
  /** column name */
  Responses = 'responses',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "common_question_responses" */
export type Common_Question_Responses_Set_Input = {
  common_question_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  responses?: InputMaybe<Scalars['jsonb']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Common_Question_Responses_Stddev_Fields = {
  __typename?: 'common_question_responses_stddev_fields';
  common_question_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Common_Question_Responses_Stddev_Pop_Fields = {
  __typename?: 'common_question_responses_stddev_pop_fields';
  common_question_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Common_Question_Responses_Stddev_Samp_Fields = {
  __typename?: 'common_question_responses_stddev_samp_fields';
  common_question_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "common_question_responses" */
export type Common_Question_Responses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Common_Question_Responses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Common_Question_Responses_Stream_Cursor_Value_Input = {
  common_question_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  responses?: InputMaybe<Scalars['jsonb']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Common_Question_Responses_Sum_Fields = {
  __typename?: 'common_question_responses_sum_fields';
  common_question_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "common_question_responses" */
export enum Common_Question_Responses_Update_Column {
  /** column name */
  CommonQuestionId = 'common_question_id',
  /** column name */
  Id = 'id',
  /** column name */
  Responses = 'responses',
  /** column name */
  UserId = 'user_id'
}

export type Common_Question_Responses_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Common_Question_Responses_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Common_Question_Responses_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Common_Question_Responses_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Common_Question_Responses_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Common_Question_Responses_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Common_Question_Responses_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Common_Question_Responses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Common_Question_Responses_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Common_Question_Responses_Var_Pop_Fields = {
  __typename?: 'common_question_responses_var_pop_fields';
  common_question_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Common_Question_Responses_Var_Samp_Fields = {
  __typename?: 'common_question_responses_var_samp_fields';
  common_question_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Common_Question_Responses_Variance_Fields = {
  __typename?: 'common_question_responses_variance_fields';
  common_question_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "common_questions" */
export type Common_Questions = {
  __typename?: 'common_questions';
  id: Scalars['Int'];
  question_id: Scalars['Int'];
};

/** aggregated selection of "common_questions" */
export type Common_Questions_Aggregate = {
  __typename?: 'common_questions_aggregate';
  aggregate?: Maybe<Common_Questions_Aggregate_Fields>;
  nodes: Array<Common_Questions>;
};

/** aggregate fields of "common_questions" */
export type Common_Questions_Aggregate_Fields = {
  __typename?: 'common_questions_aggregate_fields';
  avg?: Maybe<Common_Questions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Common_Questions_Max_Fields>;
  min?: Maybe<Common_Questions_Min_Fields>;
  stddev?: Maybe<Common_Questions_Stddev_Fields>;
  stddev_pop?: Maybe<Common_Questions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Common_Questions_Stddev_Samp_Fields>;
  sum?: Maybe<Common_Questions_Sum_Fields>;
  var_pop?: Maybe<Common_Questions_Var_Pop_Fields>;
  var_samp?: Maybe<Common_Questions_Var_Samp_Fields>;
  variance?: Maybe<Common_Questions_Variance_Fields>;
};


/** aggregate fields of "common_questions" */
export type Common_Questions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Common_Questions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Common_Questions_Avg_Fields = {
  __typename?: 'common_questions_avg_fields';
  id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "common_questions". All fields are combined with a logical 'AND'. */
export type Common_Questions_Bool_Exp = {
  _and?: InputMaybe<Array<Common_Questions_Bool_Exp>>;
  _not?: InputMaybe<Common_Questions_Bool_Exp>;
  _or?: InputMaybe<Array<Common_Questions_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  question_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "common_questions" */
export enum Common_Questions_Constraint {
  /** unique or primary key constraint on columns "id" */
  CommonQuestionsPkey = 'common_questions_pkey',
  /** unique or primary key constraint on columns "question_id" */
  CommonQuestionsQuestionIdKey = 'common_questions_question_id_key'
}

/** input type for incrementing numeric columns in table "common_questions" */
export type Common_Questions_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  question_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "common_questions" */
export type Common_Questions_Insert_Input = {
  id?: InputMaybe<Scalars['Int']>;
  question_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Common_Questions_Max_Fields = {
  __typename?: 'common_questions_max_fields';
  id?: Maybe<Scalars['Int']>;
  question_id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Common_Questions_Min_Fields = {
  __typename?: 'common_questions_min_fields';
  id?: Maybe<Scalars['Int']>;
  question_id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "common_questions" */
export type Common_Questions_Mutation_Response = {
  __typename?: 'common_questions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Common_Questions>;
};

/** on_conflict condition type for table "common_questions" */
export type Common_Questions_On_Conflict = {
  constraint: Common_Questions_Constraint;
  update_columns?: Array<Common_Questions_Update_Column>;
  where?: InputMaybe<Common_Questions_Bool_Exp>;
};

/** Ordering options when selecting data from "common_questions". */
export type Common_Questions_Order_By = {
  id?: InputMaybe<Order_By>;
  question_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: common_questions */
export type Common_Questions_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "common_questions" */
export enum Common_Questions_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  QuestionId = 'question_id'
}

/** input type for updating data in table "common_questions" */
export type Common_Questions_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  question_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Common_Questions_Stddev_Fields = {
  __typename?: 'common_questions_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Common_Questions_Stddev_Pop_Fields = {
  __typename?: 'common_questions_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Common_Questions_Stddev_Samp_Fields = {
  __typename?: 'common_questions_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "common_questions" */
export type Common_Questions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Common_Questions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Common_Questions_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  question_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Common_Questions_Sum_Fields = {
  __typename?: 'common_questions_sum_fields';
  id?: Maybe<Scalars['Int']>;
  question_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "common_questions" */
export enum Common_Questions_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  QuestionId = 'question_id'
}

export type Common_Questions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Common_Questions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Common_Questions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Common_Questions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Common_Questions_Var_Pop_Fields = {
  __typename?: 'common_questions_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Common_Questions_Var_Samp_Fields = {
  __typename?: 'common_questions_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Common_Questions_Variance_Fields = {
  __typename?: 'common_questions_variance_fields';
  id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "communication_channels" */
export type Communication_Channels = {
  __typename?: 'communication_channels';
  comment?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "communication_channels" */
export type Communication_Channels_Aggregate = {
  __typename?: 'communication_channels_aggregate';
  aggregate?: Maybe<Communication_Channels_Aggregate_Fields>;
  nodes: Array<Communication_Channels>;
};

/** aggregate fields of "communication_channels" */
export type Communication_Channels_Aggregate_Fields = {
  __typename?: 'communication_channels_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Communication_Channels_Max_Fields>;
  min?: Maybe<Communication_Channels_Min_Fields>;
};


/** aggregate fields of "communication_channels" */
export type Communication_Channels_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Communication_Channels_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "communication_channels". All fields are combined with a logical 'AND'. */
export type Communication_Channels_Bool_Exp = {
  _and?: InputMaybe<Array<Communication_Channels_Bool_Exp>>;
  _not?: InputMaybe<Communication_Channels_Bool_Exp>;
  _or?: InputMaybe<Array<Communication_Channels_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "communication_channels" */
export enum Communication_Channels_Constraint {
  /** unique or primary key constraint on columns "value" */
  CommunicationChannelsPkey = 'communication_channels_pkey'
}

export enum Communication_Channels_Enum {
  Email = 'EMAIL',
  InAppNotification = 'IN_APP_NOTIFICATION',
  Sms = 'SMS'
}

/** Boolean expression to compare columns of type "communication_channels_enum". All fields are combined with logical 'AND'. */
export type Communication_Channels_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Communication_Channels_Enum>;
  _in?: InputMaybe<Array<Communication_Channels_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Communication_Channels_Enum>;
  _nin?: InputMaybe<Array<Communication_Channels_Enum>>;
};

/** input type for inserting data into table "communication_channels" */
export type Communication_Channels_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Communication_Channels_Max_Fields = {
  __typename?: 'communication_channels_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Communication_Channels_Min_Fields = {
  __typename?: 'communication_channels_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "communication_channels" */
export type Communication_Channels_Mutation_Response = {
  __typename?: 'communication_channels_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Communication_Channels>;
};

/** on_conflict condition type for table "communication_channels" */
export type Communication_Channels_On_Conflict = {
  constraint: Communication_Channels_Constraint;
  update_columns?: Array<Communication_Channels_Update_Column>;
  where?: InputMaybe<Communication_Channels_Bool_Exp>;
};

/** Ordering options when selecting data from "communication_channels". */
export type Communication_Channels_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: communication_channels */
export type Communication_Channels_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "communication_channels" */
export enum Communication_Channels_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "communication_channels" */
export type Communication_Channels_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "communication_channels" */
export type Communication_Channels_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Communication_Channels_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Communication_Channels_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "communication_channels" */
export enum Communication_Channels_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Communication_Channels_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Communication_Channels_Set_Input>;
  /** filter the rows which have to be updated */
  where: Communication_Channels_Bool_Exp;
};

/** columns and relationships of "countries" */
export type Countries = {
  __typename?: 'countries';
  code: Scalars['String'];
  flag?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
};

/** aggregated selection of "countries" */
export type Countries_Aggregate = {
  __typename?: 'countries_aggregate';
  aggregate?: Maybe<Countries_Aggregate_Fields>;
  nodes: Array<Countries>;
};

/** aggregate fields of "countries" */
export type Countries_Aggregate_Fields = {
  __typename?: 'countries_aggregate_fields';
  avg?: Maybe<Countries_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Countries_Max_Fields>;
  min?: Maybe<Countries_Min_Fields>;
  stddev?: Maybe<Countries_Stddev_Fields>;
  stddev_pop?: Maybe<Countries_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Countries_Stddev_Samp_Fields>;
  sum?: Maybe<Countries_Sum_Fields>;
  var_pop?: Maybe<Countries_Var_Pop_Fields>;
  var_samp?: Maybe<Countries_Var_Samp_Fields>;
  variance?: Maybe<Countries_Variance_Fields>;
};


/** aggregate fields of "countries" */
export type Countries_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Countries_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Countries_Avg_Fields = {
  __typename?: 'countries_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "countries". All fields are combined with a logical 'AND'. */
export type Countries_Bool_Exp = {
  _and?: InputMaybe<Array<Countries_Bool_Exp>>;
  _not?: InputMaybe<Countries_Bool_Exp>;
  _or?: InputMaybe<Array<Countries_Bool_Exp>>;
  code?: InputMaybe<String_Comparison_Exp>;
  flag?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "countries" */
export enum Countries_Constraint {
  /** unique or primary key constraint on columns "id" */
  CountriesPkey = 'countries_pkey'
}

/** input type for incrementing numeric columns in table "countries" */
export type Countries_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "countries" */
export type Countries_Insert_Input = {
  code?: InputMaybe<Scalars['String']>;
  flag?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Countries_Max_Fields = {
  __typename?: 'countries_max_fields';
  code?: Maybe<Scalars['String']>;
  flag?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Countries_Min_Fields = {
  __typename?: 'countries_min_fields';
  code?: Maybe<Scalars['String']>;
  flag?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "countries" */
export type Countries_Mutation_Response = {
  __typename?: 'countries_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Countries>;
};

/** on_conflict condition type for table "countries" */
export type Countries_On_Conflict = {
  constraint: Countries_Constraint;
  update_columns?: Array<Countries_Update_Column>;
  where?: InputMaybe<Countries_Bool_Exp>;
};

/** Ordering options when selecting data from "countries". */
export type Countries_Order_By = {
  code?: InputMaybe<Order_By>;
  flag?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: countries */
export type Countries_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "countries" */
export enum Countries_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Flag = 'flag',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "countries" */
export type Countries_Set_Input = {
  code?: InputMaybe<Scalars['String']>;
  flag?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Countries_Stddev_Fields = {
  __typename?: 'countries_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Countries_Stddev_Pop_Fields = {
  __typename?: 'countries_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Countries_Stddev_Samp_Fields = {
  __typename?: 'countries_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "countries" */
export type Countries_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Countries_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Countries_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']>;
  flag?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Countries_Sum_Fields = {
  __typename?: 'countries_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "countries" */
export enum Countries_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Flag = 'flag',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Countries_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Countries_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Countries_Set_Input>;
  /** filter the rows which have to be updated */
  where: Countries_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Countries_Var_Pop_Fields = {
  __typename?: 'countries_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Countries_Var_Samp_Fields = {
  __typename?: 'countries_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Countries_Variance_Fields = {
  __typename?: 'countries_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** table used for storing custom auth code for user */
export type Custom_Auth_Code = {
  __typename?: 'custom_auth_code';
  SENSITIVE_email: Scalars['String'];
  code: Scalars['String'];
  created_at: Scalars['timestamptz'];
  expires_at: Scalars['timestamp'];
  id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  used: Scalars['Boolean'];
};

/** aggregated selection of "custom_auth_code" */
export type Custom_Auth_Code_Aggregate = {
  __typename?: 'custom_auth_code_aggregate';
  aggregate?: Maybe<Custom_Auth_Code_Aggregate_Fields>;
  nodes: Array<Custom_Auth_Code>;
};

/** aggregate fields of "custom_auth_code" */
export type Custom_Auth_Code_Aggregate_Fields = {
  __typename?: 'custom_auth_code_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Custom_Auth_Code_Max_Fields>;
  min?: Maybe<Custom_Auth_Code_Min_Fields>;
};


/** aggregate fields of "custom_auth_code" */
export type Custom_Auth_Code_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Custom_Auth_Code_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "custom_auth_code". All fields are combined with a logical 'AND'. */
export type Custom_Auth_Code_Bool_Exp = {
  SENSITIVE_email?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Custom_Auth_Code_Bool_Exp>>;
  _not?: InputMaybe<Custom_Auth_Code_Bool_Exp>;
  _or?: InputMaybe<Array<Custom_Auth_Code_Bool_Exp>>;
  code?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  expires_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  used?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "custom_auth_code" */
export enum Custom_Auth_Code_Constraint {
  /** unique or primary key constraint on columns "code" */
  CustomAuthCodeCodeKey = 'custom_auth_code_code_key',
  /** unique or primary key constraint on columns "id" */
  CustomAuthCodePkey = 'custom_auth_code_pkey'
}

/** input type for inserting data into table "custom_auth_code" */
export type Custom_Auth_Code_Insert_Input = {
  SENSITIVE_email?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  expires_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  used?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Custom_Auth_Code_Max_Fields = {
  __typename?: 'custom_auth_code_max_fields';
  SENSITIVE_email?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Custom_Auth_Code_Min_Fields = {
  __typename?: 'custom_auth_code_min_fields';
  SENSITIVE_email?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "custom_auth_code" */
export type Custom_Auth_Code_Mutation_Response = {
  __typename?: 'custom_auth_code_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Custom_Auth_Code>;
};

/** on_conflict condition type for table "custom_auth_code" */
export type Custom_Auth_Code_On_Conflict = {
  constraint: Custom_Auth_Code_Constraint;
  update_columns?: Array<Custom_Auth_Code_Update_Column>;
  where?: InputMaybe<Custom_Auth_Code_Bool_Exp>;
};

/** Ordering options when selecting data from "custom_auth_code". */
export type Custom_Auth_Code_Order_By = {
  SENSITIVE_email?: InputMaybe<Order_By>;
  code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  used?: InputMaybe<Order_By>;
};

/** primary key columns input for table: custom_auth_code */
export type Custom_Auth_Code_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "custom_auth_code" */
export enum Custom_Auth_Code_Select_Column {
  /** column name */
  SensitiveEmail = 'SENSITIVE_email',
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Used = 'used'
}

/** input type for updating data in table "custom_auth_code" */
export type Custom_Auth_Code_Set_Input = {
  SENSITIVE_email?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  expires_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  used?: InputMaybe<Scalars['Boolean']>;
};

/** Streaming cursor of the table "custom_auth_code" */
export type Custom_Auth_Code_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Custom_Auth_Code_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Custom_Auth_Code_Stream_Cursor_Value_Input = {
  SENSITIVE_email?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  expires_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  used?: InputMaybe<Scalars['Boolean']>;
};

/** update columns of table "custom_auth_code" */
export enum Custom_Auth_Code_Update_Column {
  /** column name */
  SensitiveEmail = 'SENSITIVE_email',
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Used = 'used'
}

export type Custom_Auth_Code_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Custom_Auth_Code_Set_Input>;
  /** filter the rows which have to be updated */
  where: Custom_Auth_Code_Bool_Exp;
};

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']>;
  _gt?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['date']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _neq?: InputMaybe<Scalars['date']>;
  _nin?: InputMaybe<Array<Scalars['date']>>;
};

/** columns and relationships of "dnavisit_test_statuses" */
export type Dnavisit_Test_Statuses = {
  __typename?: 'dnavisit_test_statuses';
  comment?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "dnavisit_test_statuses" */
export type Dnavisit_Test_Statuses_Aggregate = {
  __typename?: 'dnavisit_test_statuses_aggregate';
  aggregate?: Maybe<Dnavisit_Test_Statuses_Aggregate_Fields>;
  nodes: Array<Dnavisit_Test_Statuses>;
};

/** aggregate fields of "dnavisit_test_statuses" */
export type Dnavisit_Test_Statuses_Aggregate_Fields = {
  __typename?: 'dnavisit_test_statuses_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Dnavisit_Test_Statuses_Max_Fields>;
  min?: Maybe<Dnavisit_Test_Statuses_Min_Fields>;
};


/** aggregate fields of "dnavisit_test_statuses" */
export type Dnavisit_Test_Statuses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dnavisit_Test_Statuses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "dnavisit_test_statuses". All fields are combined with a logical 'AND'. */
export type Dnavisit_Test_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Dnavisit_Test_Statuses_Bool_Exp>>;
  _not?: InputMaybe<Dnavisit_Test_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Dnavisit_Test_Statuses_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "dnavisit_test_statuses" */
export enum Dnavisit_Test_Statuses_Constraint {
  /** unique or primary key constraint on columns "value" */
  DnavisitTestStatusesPkey = 'dnavisit_test_statuses_pkey'
}

export enum Dnavisit_Test_Statuses_Enum {
  /** Status once the appointment has been scheduled in DNA */
  AppointmentScheduled = 'APPOINTMENT_SCHEDULED',
  Complete = 'COMPLETE',
  /** Status once the user has registered the test kit (initial state) */
  KitNotReceived = 'KIT_NOT_RECEIVED',
  /** Status once the sapphiros results are published but, the schedule appointment link has not been yet shared into the chat */
  ProviderPending = 'PROVIDER_PENDING',
  /** Status once the test kit have been received by the lab (Sapphiros) */
  ReceivedByLab = 'RECEIVED_BY_LAB',
  /** Status once the results from the lab (Sapphiros) have reached DNA visit. */
  ResultsWithProvider = 'RESULTS_WITH_PROVIDER'
}

/** Boolean expression to compare columns of type "dnavisit_test_statuses_enum". All fields are combined with logical 'AND'. */
export type Dnavisit_Test_Statuses_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Dnavisit_Test_Statuses_Enum>;
  _in?: InputMaybe<Array<Dnavisit_Test_Statuses_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Dnavisit_Test_Statuses_Enum>;
  _nin?: InputMaybe<Array<Dnavisit_Test_Statuses_Enum>>;
};

/** input type for inserting data into table "dnavisit_test_statuses" */
export type Dnavisit_Test_Statuses_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Dnavisit_Test_Statuses_Max_Fields = {
  __typename?: 'dnavisit_test_statuses_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Dnavisit_Test_Statuses_Min_Fields = {
  __typename?: 'dnavisit_test_statuses_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "dnavisit_test_statuses" */
export type Dnavisit_Test_Statuses_Mutation_Response = {
  __typename?: 'dnavisit_test_statuses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dnavisit_Test_Statuses>;
};

/** input type for inserting object relation for remote table "dnavisit_test_statuses" */
export type Dnavisit_Test_Statuses_Obj_Rel_Insert_Input = {
  data: Dnavisit_Test_Statuses_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Dnavisit_Test_Statuses_On_Conflict>;
};

/** on_conflict condition type for table "dnavisit_test_statuses" */
export type Dnavisit_Test_Statuses_On_Conflict = {
  constraint: Dnavisit_Test_Statuses_Constraint;
  update_columns?: Array<Dnavisit_Test_Statuses_Update_Column>;
  where?: InputMaybe<Dnavisit_Test_Statuses_Bool_Exp>;
};

/** Ordering options when selecting data from "dnavisit_test_statuses". */
export type Dnavisit_Test_Statuses_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: dnavisit_test_statuses */
export type Dnavisit_Test_Statuses_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "dnavisit_test_statuses" */
export enum Dnavisit_Test_Statuses_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "dnavisit_test_statuses" */
export type Dnavisit_Test_Statuses_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "dnavisit_test_statuses" */
export type Dnavisit_Test_Statuses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dnavisit_Test_Statuses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dnavisit_Test_Statuses_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "dnavisit_test_statuses" */
export enum Dnavisit_Test_Statuses_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Dnavisit_Test_Statuses_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Dnavisit_Test_Statuses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Dnavisit_Test_Statuses_Bool_Exp;
};

/** columns and relationships of "dnavisit_tests" */
export type Dnavisit_Tests = {
  __typename?: 'dnavisit_tests';
  created_at?: Maybe<Scalars['timestamptz']>;
  dna_test_questionnaire_pdf_id?: Maybe<Scalars['uuid']>;
  dnavisit_order_id?: Maybe<Scalars['String']>;
  dnavisit_post_data?: Maybe<Scalars['jsonb']>;
  dnavisit_pre_data?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  dnavisit_tests_dna_questionnaire_file?: Maybe<Files>;
  /** An object relationship */
  dnavisit_tests_dnavisit_test_status?: Maybe<Dnavisit_Test_Statuses>;
  /** An object relationship */
  dnavisit_tests_health_questionnaire_file?: Maybe<Files>;
  /** An object relationship */
  dnavisit_tests_npi_number?: Maybe<Npi_Registry_Data>;
  /** An object relationship */
  dnavisit_tests_patient_file?: Maybe<Files>;
  /** An object relationship */
  dnavisit_tests_provider_file?: Maybe<Files>;
  /** An object relationship */
  dnavisit_tests_sapphiros_file?: Maybe<Files>;
  /** An object relationship */
  dnavisit_tests_test: Tests;
  /** An object relationship */
  dnavisit_tests_visit_summary_file?: Maybe<Files>;
  errors?: Maybe<Scalars['jsonb']>;
  health_questionnaire_pdf_id?: Maybe<Scalars['uuid']>;
  new_barcode?: Maybe<Scalars['String']>;
  npi_number?: Maybe<Scalars['String']>;
  patient_pdf_id?: Maybe<Scalars['uuid']>;
  provider_report_pdf_id?: Maybe<Scalars['uuid']>;
  sapphiros_barcode?: Maybe<Scalars['String']>;
  sapphiros_data?: Maybe<Scalars['jsonb']>;
  sapphiros_report_pdf_id?: Maybe<Scalars['uuid']>;
  sapphiros_results?: Maybe<Scalars['jsonb']>;
  state?: Maybe<Dnavisit_Test_Statuses_Enum>;
  test_id: Scalars['uuid'];
  tracking_number?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  visit_summary_appointment_date?: Maybe<Scalars['timestamptz']>;
  visit_summary_appointment_url?: Maybe<Scalars['String']>;
  visit_summary_pdf_id?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "dnavisit_tests" */
export type Dnavisit_TestsDnavisit_Post_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "dnavisit_tests" */
export type Dnavisit_TestsDnavisit_Pre_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "dnavisit_tests" */
export type Dnavisit_TestsErrorsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "dnavisit_tests" */
export type Dnavisit_TestsSapphiros_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "dnavisit_tests" */
export type Dnavisit_TestsSapphiros_ResultsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "dnavisit_tests" */
export type Dnavisit_Tests_Aggregate = {
  __typename?: 'dnavisit_tests_aggregate';
  aggregate?: Maybe<Dnavisit_Tests_Aggregate_Fields>;
  nodes: Array<Dnavisit_Tests>;
};

export type Dnavisit_Tests_Aggregate_Bool_Exp = {
  count?: InputMaybe<Dnavisit_Tests_Aggregate_Bool_Exp_Count>;
};

export type Dnavisit_Tests_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Dnavisit_Tests_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Dnavisit_Tests_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "dnavisit_tests" */
export type Dnavisit_Tests_Aggregate_Fields = {
  __typename?: 'dnavisit_tests_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Dnavisit_Tests_Max_Fields>;
  min?: Maybe<Dnavisit_Tests_Min_Fields>;
};


/** aggregate fields of "dnavisit_tests" */
export type Dnavisit_Tests_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dnavisit_Tests_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dnavisit_tests" */
export type Dnavisit_Tests_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Dnavisit_Tests_Max_Order_By>;
  min?: InputMaybe<Dnavisit_Tests_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Dnavisit_Tests_Append_Input = {
  dnavisit_post_data?: InputMaybe<Scalars['jsonb']>;
  dnavisit_pre_data?: InputMaybe<Scalars['jsonb']>;
  errors?: InputMaybe<Scalars['jsonb']>;
  sapphiros_data?: InputMaybe<Scalars['jsonb']>;
  sapphiros_results?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "dnavisit_tests" */
export type Dnavisit_Tests_Arr_Rel_Insert_Input = {
  data: Array<Dnavisit_Tests_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Dnavisit_Tests_On_Conflict>;
};

/** Boolean expression to filter rows from the table "dnavisit_tests". All fields are combined with a logical 'AND'. */
export type Dnavisit_Tests_Bool_Exp = {
  _and?: InputMaybe<Array<Dnavisit_Tests_Bool_Exp>>;
  _not?: InputMaybe<Dnavisit_Tests_Bool_Exp>;
  _or?: InputMaybe<Array<Dnavisit_Tests_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  dna_test_questionnaire_pdf_id?: InputMaybe<Uuid_Comparison_Exp>;
  dnavisit_order_id?: InputMaybe<String_Comparison_Exp>;
  dnavisit_post_data?: InputMaybe<Jsonb_Comparison_Exp>;
  dnavisit_pre_data?: InputMaybe<Jsonb_Comparison_Exp>;
  dnavisit_tests_dna_questionnaire_file?: InputMaybe<Files_Bool_Exp>;
  dnavisit_tests_dnavisit_test_status?: InputMaybe<Dnavisit_Test_Statuses_Bool_Exp>;
  dnavisit_tests_health_questionnaire_file?: InputMaybe<Files_Bool_Exp>;
  dnavisit_tests_npi_number?: InputMaybe<Npi_Registry_Data_Bool_Exp>;
  dnavisit_tests_patient_file?: InputMaybe<Files_Bool_Exp>;
  dnavisit_tests_provider_file?: InputMaybe<Files_Bool_Exp>;
  dnavisit_tests_sapphiros_file?: InputMaybe<Files_Bool_Exp>;
  dnavisit_tests_test?: InputMaybe<Tests_Bool_Exp>;
  dnavisit_tests_visit_summary_file?: InputMaybe<Files_Bool_Exp>;
  errors?: InputMaybe<Jsonb_Comparison_Exp>;
  health_questionnaire_pdf_id?: InputMaybe<Uuid_Comparison_Exp>;
  new_barcode?: InputMaybe<String_Comparison_Exp>;
  npi_number?: InputMaybe<String_Comparison_Exp>;
  patient_pdf_id?: InputMaybe<Uuid_Comparison_Exp>;
  provider_report_pdf_id?: InputMaybe<Uuid_Comparison_Exp>;
  sapphiros_barcode?: InputMaybe<String_Comparison_Exp>;
  sapphiros_data?: InputMaybe<Jsonb_Comparison_Exp>;
  sapphiros_report_pdf_id?: InputMaybe<Uuid_Comparison_Exp>;
  sapphiros_results?: InputMaybe<Jsonb_Comparison_Exp>;
  state?: InputMaybe<Dnavisit_Test_Statuses_Enum_Comparison_Exp>;
  test_id?: InputMaybe<Uuid_Comparison_Exp>;
  tracking_number?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  visit_summary_appointment_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  visit_summary_appointment_url?: InputMaybe<String_Comparison_Exp>;
  visit_summary_pdf_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "dnavisit_tests" */
export enum Dnavisit_Tests_Constraint {
  /** unique or primary key constraint on columns "dna_test_questionnaire_pdf_id" */
  DnavisitTestsDnaTestQuestionnairePdfIdKey = 'dnavisit_tests_dna_test_questionnaire_pdf_id_key',
  /** unique or primary key constraint on columns "dnavisit_order_id" */
  DnavisitTestsDnavisitOrderIdKey = 'dnavisit_tests_dnavisit_order_id_key',
  /** unique or primary key constraint on columns "patient_pdf_id" */
  DnavisitTestsDnavisitResultPdfIdKey = 'dnavisit_tests_dnavisit_result_pdf_id_key',
  /** unique or primary key constraint on columns "health_questionnaire_pdf_id" */
  DnavisitTestsHealthQuestionnairePdfIdKey = 'dnavisit_tests_health_questionnaire_pdf_id_key',
  /** unique or primary key constraint on columns "test_id" */
  DnavisitTestsPkey = 'dnavisit_tests_pkey',
  /** unique or primary key constraint on columns "provider_report_pdf_id" */
  DnavisitTestsProviderReportPdfIdKey = 'dnavisit_tests_provider_report_pdf_id_key',
  /** unique or primary key constraint on columns "sapphiros_report_pdf_id" */
  DnavisitTestsSapphirosReportPdfIdKey = 'dnavisit_tests_sapphiros_report_pdf_id_key',
  /** unique or primary key constraint on columns "visit_summary_pdf_id" */
  DnavisitTestsVisitSummaryPdfIdKey = 'dnavisit_tests_visit_summary_pdf_id_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Dnavisit_Tests_Delete_At_Path_Input = {
  dnavisit_post_data?: InputMaybe<Array<Scalars['String']>>;
  dnavisit_pre_data?: InputMaybe<Array<Scalars['String']>>;
  errors?: InputMaybe<Array<Scalars['String']>>;
  sapphiros_data?: InputMaybe<Array<Scalars['String']>>;
  sapphiros_results?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Dnavisit_Tests_Delete_Elem_Input = {
  dnavisit_post_data?: InputMaybe<Scalars['Int']>;
  dnavisit_pre_data?: InputMaybe<Scalars['Int']>;
  errors?: InputMaybe<Scalars['Int']>;
  sapphiros_data?: InputMaybe<Scalars['Int']>;
  sapphiros_results?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Dnavisit_Tests_Delete_Key_Input = {
  dnavisit_post_data?: InputMaybe<Scalars['String']>;
  dnavisit_pre_data?: InputMaybe<Scalars['String']>;
  errors?: InputMaybe<Scalars['String']>;
  sapphiros_data?: InputMaybe<Scalars['String']>;
  sapphiros_results?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "dnavisit_tests" */
export type Dnavisit_Tests_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dna_test_questionnaire_pdf_id?: InputMaybe<Scalars['uuid']>;
  dnavisit_order_id?: InputMaybe<Scalars['String']>;
  dnavisit_post_data?: InputMaybe<Scalars['jsonb']>;
  dnavisit_pre_data?: InputMaybe<Scalars['jsonb']>;
  dnavisit_tests_dna_questionnaire_file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  dnavisit_tests_dnavisit_test_status?: InputMaybe<Dnavisit_Test_Statuses_Obj_Rel_Insert_Input>;
  dnavisit_tests_health_questionnaire_file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  dnavisit_tests_npi_number?: InputMaybe<Npi_Registry_Data_Obj_Rel_Insert_Input>;
  dnavisit_tests_patient_file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  dnavisit_tests_provider_file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  dnavisit_tests_sapphiros_file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  dnavisit_tests_test?: InputMaybe<Tests_Obj_Rel_Insert_Input>;
  dnavisit_tests_visit_summary_file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  errors?: InputMaybe<Scalars['jsonb']>;
  health_questionnaire_pdf_id?: InputMaybe<Scalars['uuid']>;
  new_barcode?: InputMaybe<Scalars['String']>;
  npi_number?: InputMaybe<Scalars['String']>;
  patient_pdf_id?: InputMaybe<Scalars['uuid']>;
  provider_report_pdf_id?: InputMaybe<Scalars['uuid']>;
  sapphiros_barcode?: InputMaybe<Scalars['String']>;
  sapphiros_data?: InputMaybe<Scalars['jsonb']>;
  sapphiros_report_pdf_id?: InputMaybe<Scalars['uuid']>;
  sapphiros_results?: InputMaybe<Scalars['jsonb']>;
  state?: InputMaybe<Dnavisit_Test_Statuses_Enum>;
  test_id?: InputMaybe<Scalars['uuid']>;
  tracking_number?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  visit_summary_appointment_date?: InputMaybe<Scalars['timestamptz']>;
  visit_summary_appointment_url?: InputMaybe<Scalars['String']>;
  visit_summary_pdf_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Dnavisit_Tests_Max_Fields = {
  __typename?: 'dnavisit_tests_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  dna_test_questionnaire_pdf_id?: Maybe<Scalars['uuid']>;
  dnavisit_order_id?: Maybe<Scalars['String']>;
  health_questionnaire_pdf_id?: Maybe<Scalars['uuid']>;
  new_barcode?: Maybe<Scalars['String']>;
  npi_number?: Maybe<Scalars['String']>;
  patient_pdf_id?: Maybe<Scalars['uuid']>;
  provider_report_pdf_id?: Maybe<Scalars['uuid']>;
  sapphiros_barcode?: Maybe<Scalars['String']>;
  sapphiros_report_pdf_id?: Maybe<Scalars['uuid']>;
  test_id?: Maybe<Scalars['uuid']>;
  tracking_number?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  visit_summary_appointment_date?: Maybe<Scalars['timestamptz']>;
  visit_summary_appointment_url?: Maybe<Scalars['String']>;
  visit_summary_pdf_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "dnavisit_tests" */
export type Dnavisit_Tests_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  dna_test_questionnaire_pdf_id?: InputMaybe<Order_By>;
  dnavisit_order_id?: InputMaybe<Order_By>;
  health_questionnaire_pdf_id?: InputMaybe<Order_By>;
  new_barcode?: InputMaybe<Order_By>;
  npi_number?: InputMaybe<Order_By>;
  patient_pdf_id?: InputMaybe<Order_By>;
  provider_report_pdf_id?: InputMaybe<Order_By>;
  sapphiros_barcode?: InputMaybe<Order_By>;
  sapphiros_report_pdf_id?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
  tracking_number?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  visit_summary_appointment_date?: InputMaybe<Order_By>;
  visit_summary_appointment_url?: InputMaybe<Order_By>;
  visit_summary_pdf_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Dnavisit_Tests_Min_Fields = {
  __typename?: 'dnavisit_tests_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  dna_test_questionnaire_pdf_id?: Maybe<Scalars['uuid']>;
  dnavisit_order_id?: Maybe<Scalars['String']>;
  health_questionnaire_pdf_id?: Maybe<Scalars['uuid']>;
  new_barcode?: Maybe<Scalars['String']>;
  npi_number?: Maybe<Scalars['String']>;
  patient_pdf_id?: Maybe<Scalars['uuid']>;
  provider_report_pdf_id?: Maybe<Scalars['uuid']>;
  sapphiros_barcode?: Maybe<Scalars['String']>;
  sapphiros_report_pdf_id?: Maybe<Scalars['uuid']>;
  test_id?: Maybe<Scalars['uuid']>;
  tracking_number?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  visit_summary_appointment_date?: Maybe<Scalars['timestamptz']>;
  visit_summary_appointment_url?: Maybe<Scalars['String']>;
  visit_summary_pdf_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "dnavisit_tests" */
export type Dnavisit_Tests_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  dna_test_questionnaire_pdf_id?: InputMaybe<Order_By>;
  dnavisit_order_id?: InputMaybe<Order_By>;
  health_questionnaire_pdf_id?: InputMaybe<Order_By>;
  new_barcode?: InputMaybe<Order_By>;
  npi_number?: InputMaybe<Order_By>;
  patient_pdf_id?: InputMaybe<Order_By>;
  provider_report_pdf_id?: InputMaybe<Order_By>;
  sapphiros_barcode?: InputMaybe<Order_By>;
  sapphiros_report_pdf_id?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
  tracking_number?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  visit_summary_appointment_date?: InputMaybe<Order_By>;
  visit_summary_appointment_url?: InputMaybe<Order_By>;
  visit_summary_pdf_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "dnavisit_tests" */
export type Dnavisit_Tests_Mutation_Response = {
  __typename?: 'dnavisit_tests_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dnavisit_Tests>;
};

/** on_conflict condition type for table "dnavisit_tests" */
export type Dnavisit_Tests_On_Conflict = {
  constraint: Dnavisit_Tests_Constraint;
  update_columns?: Array<Dnavisit_Tests_Update_Column>;
  where?: InputMaybe<Dnavisit_Tests_Bool_Exp>;
};

/** Ordering options when selecting data from "dnavisit_tests". */
export type Dnavisit_Tests_Order_By = {
  created_at?: InputMaybe<Order_By>;
  dna_test_questionnaire_pdf_id?: InputMaybe<Order_By>;
  dnavisit_order_id?: InputMaybe<Order_By>;
  dnavisit_post_data?: InputMaybe<Order_By>;
  dnavisit_pre_data?: InputMaybe<Order_By>;
  dnavisit_tests_dna_questionnaire_file?: InputMaybe<Files_Order_By>;
  dnavisit_tests_dnavisit_test_status?: InputMaybe<Dnavisit_Test_Statuses_Order_By>;
  dnavisit_tests_health_questionnaire_file?: InputMaybe<Files_Order_By>;
  dnavisit_tests_npi_number?: InputMaybe<Npi_Registry_Data_Order_By>;
  dnavisit_tests_patient_file?: InputMaybe<Files_Order_By>;
  dnavisit_tests_provider_file?: InputMaybe<Files_Order_By>;
  dnavisit_tests_sapphiros_file?: InputMaybe<Files_Order_By>;
  dnavisit_tests_test?: InputMaybe<Tests_Order_By>;
  dnavisit_tests_visit_summary_file?: InputMaybe<Files_Order_By>;
  errors?: InputMaybe<Order_By>;
  health_questionnaire_pdf_id?: InputMaybe<Order_By>;
  new_barcode?: InputMaybe<Order_By>;
  npi_number?: InputMaybe<Order_By>;
  patient_pdf_id?: InputMaybe<Order_By>;
  provider_report_pdf_id?: InputMaybe<Order_By>;
  sapphiros_barcode?: InputMaybe<Order_By>;
  sapphiros_data?: InputMaybe<Order_By>;
  sapphiros_report_pdf_id?: InputMaybe<Order_By>;
  sapphiros_results?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
  tracking_number?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  visit_summary_appointment_date?: InputMaybe<Order_By>;
  visit_summary_appointment_url?: InputMaybe<Order_By>;
  visit_summary_pdf_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: dnavisit_tests */
export type Dnavisit_Tests_Pk_Columns_Input = {
  test_id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Dnavisit_Tests_Prepend_Input = {
  dnavisit_post_data?: InputMaybe<Scalars['jsonb']>;
  dnavisit_pre_data?: InputMaybe<Scalars['jsonb']>;
  errors?: InputMaybe<Scalars['jsonb']>;
  sapphiros_data?: InputMaybe<Scalars['jsonb']>;
  sapphiros_results?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "dnavisit_tests" */
export enum Dnavisit_Tests_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DnaTestQuestionnairePdfId = 'dna_test_questionnaire_pdf_id',
  /** column name */
  DnavisitOrderId = 'dnavisit_order_id',
  /** column name */
  DnavisitPostData = 'dnavisit_post_data',
  /** column name */
  DnavisitPreData = 'dnavisit_pre_data',
  /** column name */
  Errors = 'errors',
  /** column name */
  HealthQuestionnairePdfId = 'health_questionnaire_pdf_id',
  /** column name */
  NewBarcode = 'new_barcode',
  /** column name */
  NpiNumber = 'npi_number',
  /** column name */
  PatientPdfId = 'patient_pdf_id',
  /** column name */
  ProviderReportPdfId = 'provider_report_pdf_id',
  /** column name */
  SapphirosBarcode = 'sapphiros_barcode',
  /** column name */
  SapphirosData = 'sapphiros_data',
  /** column name */
  SapphirosReportPdfId = 'sapphiros_report_pdf_id',
  /** column name */
  SapphirosResults = 'sapphiros_results',
  /** column name */
  State = 'state',
  /** column name */
  TestId = 'test_id',
  /** column name */
  TrackingNumber = 'tracking_number',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VisitSummaryAppointmentDate = 'visit_summary_appointment_date',
  /** column name */
  VisitSummaryAppointmentUrl = 'visit_summary_appointment_url',
  /** column name */
  VisitSummaryPdfId = 'visit_summary_pdf_id'
}

/** input type for updating data in table "dnavisit_tests" */
export type Dnavisit_Tests_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dna_test_questionnaire_pdf_id?: InputMaybe<Scalars['uuid']>;
  dnavisit_order_id?: InputMaybe<Scalars['String']>;
  dnavisit_post_data?: InputMaybe<Scalars['jsonb']>;
  dnavisit_pre_data?: InputMaybe<Scalars['jsonb']>;
  errors?: InputMaybe<Scalars['jsonb']>;
  health_questionnaire_pdf_id?: InputMaybe<Scalars['uuid']>;
  new_barcode?: InputMaybe<Scalars['String']>;
  npi_number?: InputMaybe<Scalars['String']>;
  patient_pdf_id?: InputMaybe<Scalars['uuid']>;
  provider_report_pdf_id?: InputMaybe<Scalars['uuid']>;
  sapphiros_barcode?: InputMaybe<Scalars['String']>;
  sapphiros_data?: InputMaybe<Scalars['jsonb']>;
  sapphiros_report_pdf_id?: InputMaybe<Scalars['uuid']>;
  sapphiros_results?: InputMaybe<Scalars['jsonb']>;
  state?: InputMaybe<Dnavisit_Test_Statuses_Enum>;
  test_id?: InputMaybe<Scalars['uuid']>;
  tracking_number?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  visit_summary_appointment_date?: InputMaybe<Scalars['timestamptz']>;
  visit_summary_appointment_url?: InputMaybe<Scalars['String']>;
  visit_summary_pdf_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "dnavisit_tests" */
export type Dnavisit_Tests_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dnavisit_Tests_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dnavisit_Tests_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dna_test_questionnaire_pdf_id?: InputMaybe<Scalars['uuid']>;
  dnavisit_order_id?: InputMaybe<Scalars['String']>;
  dnavisit_post_data?: InputMaybe<Scalars['jsonb']>;
  dnavisit_pre_data?: InputMaybe<Scalars['jsonb']>;
  errors?: InputMaybe<Scalars['jsonb']>;
  health_questionnaire_pdf_id?: InputMaybe<Scalars['uuid']>;
  new_barcode?: InputMaybe<Scalars['String']>;
  npi_number?: InputMaybe<Scalars['String']>;
  patient_pdf_id?: InputMaybe<Scalars['uuid']>;
  provider_report_pdf_id?: InputMaybe<Scalars['uuid']>;
  sapphiros_barcode?: InputMaybe<Scalars['String']>;
  sapphiros_data?: InputMaybe<Scalars['jsonb']>;
  sapphiros_report_pdf_id?: InputMaybe<Scalars['uuid']>;
  sapphiros_results?: InputMaybe<Scalars['jsonb']>;
  state?: InputMaybe<Dnavisit_Test_Statuses_Enum>;
  test_id?: InputMaybe<Scalars['uuid']>;
  tracking_number?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  visit_summary_appointment_date?: InputMaybe<Scalars['timestamptz']>;
  visit_summary_appointment_url?: InputMaybe<Scalars['String']>;
  visit_summary_pdf_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "dnavisit_tests" */
export enum Dnavisit_Tests_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DnaTestQuestionnairePdfId = 'dna_test_questionnaire_pdf_id',
  /** column name */
  DnavisitOrderId = 'dnavisit_order_id',
  /** column name */
  DnavisitPostData = 'dnavisit_post_data',
  /** column name */
  DnavisitPreData = 'dnavisit_pre_data',
  /** column name */
  Errors = 'errors',
  /** column name */
  HealthQuestionnairePdfId = 'health_questionnaire_pdf_id',
  /** column name */
  NewBarcode = 'new_barcode',
  /** column name */
  NpiNumber = 'npi_number',
  /** column name */
  PatientPdfId = 'patient_pdf_id',
  /** column name */
  ProviderReportPdfId = 'provider_report_pdf_id',
  /** column name */
  SapphirosBarcode = 'sapphiros_barcode',
  /** column name */
  SapphirosData = 'sapphiros_data',
  /** column name */
  SapphirosReportPdfId = 'sapphiros_report_pdf_id',
  /** column name */
  SapphirosResults = 'sapphiros_results',
  /** column name */
  State = 'state',
  /** column name */
  TestId = 'test_id',
  /** column name */
  TrackingNumber = 'tracking_number',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VisitSummaryAppointmentDate = 'visit_summary_appointment_date',
  /** column name */
  VisitSummaryAppointmentUrl = 'visit_summary_appointment_url',
  /** column name */
  VisitSummaryPdfId = 'visit_summary_pdf_id'
}

export type Dnavisit_Tests_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Dnavisit_Tests_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Dnavisit_Tests_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Dnavisit_Tests_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Dnavisit_Tests_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Dnavisit_Tests_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Dnavisit_Tests_Set_Input>;
  /** filter the rows which have to be updated */
  where: Dnavisit_Tests_Bool_Exp;
};

/** Do not use this table. This table holds our inventory swab codes. */
export type Do_Not_Use_Production_Swab_Codes = {
  __typename?: 'do_not_use_production_swab_codes';
  active: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  is_test: Scalars['Boolean'];
  swab_code: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  used_by?: Maybe<Scalars['String']>;
};

/** aggregated selection of "do_not_use_production_swab_codes" */
export type Do_Not_Use_Production_Swab_Codes_Aggregate = {
  __typename?: 'do_not_use_production_swab_codes_aggregate';
  aggregate?: Maybe<Do_Not_Use_Production_Swab_Codes_Aggregate_Fields>;
  nodes: Array<Do_Not_Use_Production_Swab_Codes>;
};

/** aggregate fields of "do_not_use_production_swab_codes" */
export type Do_Not_Use_Production_Swab_Codes_Aggregate_Fields = {
  __typename?: 'do_not_use_production_swab_codes_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Do_Not_Use_Production_Swab_Codes_Max_Fields>;
  min?: Maybe<Do_Not_Use_Production_Swab_Codes_Min_Fields>;
};


/** aggregate fields of "do_not_use_production_swab_codes" */
export type Do_Not_Use_Production_Swab_Codes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Do_Not_Use_Production_Swab_Codes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "do_not_use_production_swab_codes". All fields are combined with a logical 'AND'. */
export type Do_Not_Use_Production_Swab_Codes_Bool_Exp = {
  _and?: InputMaybe<Array<Do_Not_Use_Production_Swab_Codes_Bool_Exp>>;
  _not?: InputMaybe<Do_Not_Use_Production_Swab_Codes_Bool_Exp>;
  _or?: InputMaybe<Array<Do_Not_Use_Production_Swab_Codes_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  is_test?: InputMaybe<Boolean_Comparison_Exp>;
  swab_code?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  used_by?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "do_not_use_production_swab_codes" */
export enum Do_Not_Use_Production_Swab_Codes_Constraint {
  /** unique or primary key constraint on columns "swab_code" */
  DoNotUseProductionSwabCodesPkey = 'do_not_use_production_swab_codes_pkey'
}

/** input type for inserting data into table "do_not_use_production_swab_codes" */
export type Do_Not_Use_Production_Swab_Codes_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  is_test?: InputMaybe<Scalars['Boolean']>;
  swab_code?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  used_by?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Do_Not_Use_Production_Swab_Codes_Max_Fields = {
  __typename?: 'do_not_use_production_swab_codes_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  swab_code?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  used_by?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Do_Not_Use_Production_Swab_Codes_Min_Fields = {
  __typename?: 'do_not_use_production_swab_codes_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  swab_code?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  used_by?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "do_not_use_production_swab_codes" */
export type Do_Not_Use_Production_Swab_Codes_Mutation_Response = {
  __typename?: 'do_not_use_production_swab_codes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Do_Not_Use_Production_Swab_Codes>;
};

/** on_conflict condition type for table "do_not_use_production_swab_codes" */
export type Do_Not_Use_Production_Swab_Codes_On_Conflict = {
  constraint: Do_Not_Use_Production_Swab_Codes_Constraint;
  update_columns?: Array<Do_Not_Use_Production_Swab_Codes_Update_Column>;
  where?: InputMaybe<Do_Not_Use_Production_Swab_Codes_Bool_Exp>;
};

/** Ordering options when selecting data from "do_not_use_production_swab_codes". */
export type Do_Not_Use_Production_Swab_Codes_Order_By = {
  active?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  is_test?: InputMaybe<Order_By>;
  swab_code?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  used_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: do_not_use_production_swab_codes */
export type Do_Not_Use_Production_Swab_Codes_Pk_Columns_Input = {
  swab_code: Scalars['String'];
};

/** select columns of table "do_not_use_production_swab_codes" */
export enum Do_Not_Use_Production_Swab_Codes_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsTest = 'is_test',
  /** column name */
  SwabCode = 'swab_code',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UsedBy = 'used_by'
}

/** input type for updating data in table "do_not_use_production_swab_codes" */
export type Do_Not_Use_Production_Swab_Codes_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  is_test?: InputMaybe<Scalars['Boolean']>;
  swab_code?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  used_by?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "do_not_use_production_swab_codes" */
export type Do_Not_Use_Production_Swab_Codes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Do_Not_Use_Production_Swab_Codes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Do_Not_Use_Production_Swab_Codes_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  is_test?: InputMaybe<Scalars['Boolean']>;
  swab_code?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  used_by?: InputMaybe<Scalars['String']>;
};

/** update columns of table "do_not_use_production_swab_codes" */
export enum Do_Not_Use_Production_Swab_Codes_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsTest = 'is_test',
  /** column name */
  SwabCode = 'swab_code',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UsedBy = 'used_by'
}

export type Do_Not_Use_Production_Swab_Codes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Do_Not_Use_Production_Swab_Codes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Do_Not_Use_Production_Swab_Codes_Bool_Exp;
};

/** Clinician ID for DoseSpot */
export type Dosespot_Clinicians = {
  __typename?: 'dosespot_clinicians';
  clinician_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['uuid'];
};

/** aggregated selection of "dosespot_clinicians" */
export type Dosespot_Clinicians_Aggregate = {
  __typename?: 'dosespot_clinicians_aggregate';
  aggregate?: Maybe<Dosespot_Clinicians_Aggregate_Fields>;
  nodes: Array<Dosespot_Clinicians>;
};

/** aggregate fields of "dosespot_clinicians" */
export type Dosespot_Clinicians_Aggregate_Fields = {
  __typename?: 'dosespot_clinicians_aggregate_fields';
  avg?: Maybe<Dosespot_Clinicians_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dosespot_Clinicians_Max_Fields>;
  min?: Maybe<Dosespot_Clinicians_Min_Fields>;
  stddev?: Maybe<Dosespot_Clinicians_Stddev_Fields>;
  stddev_pop?: Maybe<Dosespot_Clinicians_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dosespot_Clinicians_Stddev_Samp_Fields>;
  sum?: Maybe<Dosespot_Clinicians_Sum_Fields>;
  var_pop?: Maybe<Dosespot_Clinicians_Var_Pop_Fields>;
  var_samp?: Maybe<Dosespot_Clinicians_Var_Samp_Fields>;
  variance?: Maybe<Dosespot_Clinicians_Variance_Fields>;
};


/** aggregate fields of "dosespot_clinicians" */
export type Dosespot_Clinicians_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dosespot_Clinicians_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Dosespot_Clinicians_Avg_Fields = {
  __typename?: 'dosespot_clinicians_avg_fields';
  clinician_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "dosespot_clinicians". All fields are combined with a logical 'AND'. */
export type Dosespot_Clinicians_Bool_Exp = {
  _and?: InputMaybe<Array<Dosespot_Clinicians_Bool_Exp>>;
  _not?: InputMaybe<Dosespot_Clinicians_Bool_Exp>;
  _or?: InputMaybe<Array<Dosespot_Clinicians_Bool_Exp>>;
  clinician_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "dosespot_clinicians" */
export enum Dosespot_Clinicians_Constraint {
  /** unique or primary key constraint on columns "id" */
  DosespotCliniciansPkey = 'dosespot_clinicians_pkey'
}

/** input type for incrementing numeric columns in table "dosespot_clinicians" */
export type Dosespot_Clinicians_Inc_Input = {
  clinician_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "dosespot_clinicians" */
export type Dosespot_Clinicians_Insert_Input = {
  clinician_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Dosespot_Clinicians_Max_Fields = {
  __typename?: 'dosespot_clinicians_max_fields';
  clinician_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Dosespot_Clinicians_Min_Fields = {
  __typename?: 'dosespot_clinicians_min_fields';
  clinician_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "dosespot_clinicians" */
export type Dosespot_Clinicians_Mutation_Response = {
  __typename?: 'dosespot_clinicians_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dosespot_Clinicians>;
};

/** on_conflict condition type for table "dosespot_clinicians" */
export type Dosespot_Clinicians_On_Conflict = {
  constraint: Dosespot_Clinicians_Constraint;
  update_columns?: Array<Dosespot_Clinicians_Update_Column>;
  where?: InputMaybe<Dosespot_Clinicians_Bool_Exp>;
};

/** Ordering options when selecting data from "dosespot_clinicians". */
export type Dosespot_Clinicians_Order_By = {
  clinician_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: dosespot_clinicians */
export type Dosespot_Clinicians_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "dosespot_clinicians" */
export enum Dosespot_Clinicians_Select_Column {
  /** column name */
  ClinicianId = 'clinician_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "dosespot_clinicians" */
export type Dosespot_Clinicians_Set_Input = {
  clinician_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Dosespot_Clinicians_Stddev_Fields = {
  __typename?: 'dosespot_clinicians_stddev_fields';
  clinician_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Dosespot_Clinicians_Stddev_Pop_Fields = {
  __typename?: 'dosespot_clinicians_stddev_pop_fields';
  clinician_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Dosespot_Clinicians_Stddev_Samp_Fields = {
  __typename?: 'dosespot_clinicians_stddev_samp_fields';
  clinician_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "dosespot_clinicians" */
export type Dosespot_Clinicians_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dosespot_Clinicians_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dosespot_Clinicians_Stream_Cursor_Value_Input = {
  clinician_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Dosespot_Clinicians_Sum_Fields = {
  __typename?: 'dosespot_clinicians_sum_fields';
  clinician_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "dosespot_clinicians" */
export enum Dosespot_Clinicians_Update_Column {
  /** column name */
  ClinicianId = 'clinician_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Dosespot_Clinicians_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Dosespot_Clinicians_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Dosespot_Clinicians_Set_Input>;
  /** filter the rows which have to be updated */
  where: Dosespot_Clinicians_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Dosespot_Clinicians_Var_Pop_Fields = {
  __typename?: 'dosespot_clinicians_var_pop_fields';
  clinician_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Dosespot_Clinicians_Var_Samp_Fields = {
  __typename?: 'dosespot_clinicians_var_samp_fields';
  clinician_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Dosespot_Clinicians_Variance_Fields = {
  __typename?: 'dosespot_clinicians_variance_fields';
  clinician_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "file_types" */
export type File_Types = {
  __typename?: 'file_types';
  comment?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "file_types" */
export type File_Types_Aggregate = {
  __typename?: 'file_types_aggregate';
  aggregate?: Maybe<File_Types_Aggregate_Fields>;
  nodes: Array<File_Types>;
};

/** aggregate fields of "file_types" */
export type File_Types_Aggregate_Fields = {
  __typename?: 'file_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<File_Types_Max_Fields>;
  min?: Maybe<File_Types_Min_Fields>;
};


/** aggregate fields of "file_types" */
export type File_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<File_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "file_types". All fields are combined with a logical 'AND'. */
export type File_Types_Bool_Exp = {
  _and?: InputMaybe<Array<File_Types_Bool_Exp>>;
  _not?: InputMaybe<File_Types_Bool_Exp>;
  _or?: InputMaybe<Array<File_Types_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "file_types" */
export enum File_Types_Constraint {
  /** unique or primary key constraint on columns "value" */
  FileTypesPkey = 'file_types_pkey'
}

export enum File_Types_Enum {
  Archive = 'ARCHIVE',
  Audio = 'AUDIO',
  Document = 'DOCUMENT',
  Image = 'IMAGE',
  Other = 'OTHER',
  Pdf = 'PDF',
  Presentation = 'PRESENTATION',
  Spreadsheet = 'SPREADSHEET',
  Text = 'TEXT',
  Video = 'VIDEO'
}

/** Boolean expression to compare columns of type "file_types_enum". All fields are combined with logical 'AND'. */
export type File_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<File_Types_Enum>;
  _in?: InputMaybe<Array<File_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<File_Types_Enum>;
  _nin?: InputMaybe<Array<File_Types_Enum>>;
};

/** input type for inserting data into table "file_types" */
export type File_Types_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type File_Types_Max_Fields = {
  __typename?: 'file_types_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type File_Types_Min_Fields = {
  __typename?: 'file_types_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "file_types" */
export type File_Types_Mutation_Response = {
  __typename?: 'file_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<File_Types>;
};

/** on_conflict condition type for table "file_types" */
export type File_Types_On_Conflict = {
  constraint: File_Types_Constraint;
  update_columns?: Array<File_Types_Update_Column>;
  where?: InputMaybe<File_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "file_types". */
export type File_Types_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: file_types */
export type File_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "file_types" */
export enum File_Types_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "file_types" */
export type File_Types_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "file_types" */
export type File_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: File_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type File_Types_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "file_types" */
export enum File_Types_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type File_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<File_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: File_Types_Bool_Exp;
};

/** columns and relationships of "files" */
export type Files = {
  __typename?: 'files';
  created_at: Scalars['timestamptz'];
  file_name: Scalars['String'];
  id: Scalars['uuid'];
  type: File_Types_Enum;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "files" */
export type Files_Aggregate = {
  __typename?: 'files_aggregate';
  aggregate?: Maybe<Files_Aggregate_Fields>;
  nodes: Array<Files>;
};

/** aggregate fields of "files" */
export type Files_Aggregate_Fields = {
  __typename?: 'files_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Files_Max_Fields>;
  min?: Maybe<Files_Min_Fields>;
};


/** aggregate fields of "files" */
export type Files_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Files_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "files". All fields are combined with a logical 'AND'. */
export type Files_Bool_Exp = {
  _and?: InputMaybe<Array<Files_Bool_Exp>>;
  _not?: InputMaybe<Files_Bool_Exp>;
  _or?: InputMaybe<Array<Files_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  file_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<File_Types_Enum_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "files" */
export enum Files_Constraint {
  /** unique or primary key constraint on columns "id" */
  FilesPkey = 'files_pkey'
}

/** input type for inserting data into table "files" */
export type Files_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<File_Types_Enum>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Files_Max_Fields = {
  __typename?: 'files_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  file_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Files_Min_Fields = {
  __typename?: 'files_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  file_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "files" */
export type Files_Mutation_Response = {
  __typename?: 'files_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Files>;
};

/** input type for inserting object relation for remote table "files" */
export type Files_Obj_Rel_Insert_Input = {
  data: Files_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Files_On_Conflict>;
};

/** on_conflict condition type for table "files" */
export type Files_On_Conflict = {
  constraint: Files_Constraint;
  update_columns?: Array<Files_Update_Column>;
  where?: InputMaybe<Files_Bool_Exp>;
};

/** Ordering options when selecting data from "files". */
export type Files_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: files */
export type Files_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "files" */
export enum Files_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileName = 'file_name',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "files" */
export type Files_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<File_Types_Enum>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "files" */
export type Files_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Files_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Files_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<File_Types_Enum>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "files" */
export enum Files_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileName = 'file_name',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

export type Files_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Files_Set_Input>;
  /** filter the rows which have to be updated */
  where: Files_Bool_Exp;
};

/** Clinician ID for FullScript */
export type Fullscript_Clinicians = {
  __typename?: 'fullscript_clinicians';
  clinician_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['uuid'];
};

/** aggregated selection of "fullscript_clinicians" */
export type Fullscript_Clinicians_Aggregate = {
  __typename?: 'fullscript_clinicians_aggregate';
  aggregate?: Maybe<Fullscript_Clinicians_Aggregate_Fields>;
  nodes: Array<Fullscript_Clinicians>;
};

/** aggregate fields of "fullscript_clinicians" */
export type Fullscript_Clinicians_Aggregate_Fields = {
  __typename?: 'fullscript_clinicians_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Fullscript_Clinicians_Max_Fields>;
  min?: Maybe<Fullscript_Clinicians_Min_Fields>;
};


/** aggregate fields of "fullscript_clinicians" */
export type Fullscript_Clinicians_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Fullscript_Clinicians_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "fullscript_clinicians". All fields are combined with a logical 'AND'. */
export type Fullscript_Clinicians_Bool_Exp = {
  _and?: InputMaybe<Array<Fullscript_Clinicians_Bool_Exp>>;
  _not?: InputMaybe<Fullscript_Clinicians_Bool_Exp>;
  _or?: InputMaybe<Array<Fullscript_Clinicians_Bool_Exp>>;
  clinician_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "fullscript_clinicians" */
export enum Fullscript_Clinicians_Constraint {
  /** unique or primary key constraint on columns "id" */
  FullscriptCliniciansPkey = 'fullscript_clinicians_pkey'
}

/** input type for inserting data into table "fullscript_clinicians" */
export type Fullscript_Clinicians_Insert_Input = {
  clinician_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Fullscript_Clinicians_Max_Fields = {
  __typename?: 'fullscript_clinicians_max_fields';
  clinician_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Fullscript_Clinicians_Min_Fields = {
  __typename?: 'fullscript_clinicians_min_fields';
  clinician_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "fullscript_clinicians" */
export type Fullscript_Clinicians_Mutation_Response = {
  __typename?: 'fullscript_clinicians_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Fullscript_Clinicians>;
};

/** on_conflict condition type for table "fullscript_clinicians" */
export type Fullscript_Clinicians_On_Conflict = {
  constraint: Fullscript_Clinicians_Constraint;
  update_columns?: Array<Fullscript_Clinicians_Update_Column>;
  where?: InputMaybe<Fullscript_Clinicians_Bool_Exp>;
};

/** Ordering options when selecting data from "fullscript_clinicians". */
export type Fullscript_Clinicians_Order_By = {
  clinician_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: fullscript_clinicians */
export type Fullscript_Clinicians_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "fullscript_clinicians" */
export enum Fullscript_Clinicians_Select_Column {
  /** column name */
  ClinicianId = 'clinician_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "fullscript_clinicians" */
export type Fullscript_Clinicians_Set_Input = {
  clinician_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "fullscript_clinicians" */
export type Fullscript_Clinicians_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Fullscript_Clinicians_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Fullscript_Clinicians_Stream_Cursor_Value_Input = {
  clinician_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "fullscript_clinicians" */
export enum Fullscript_Clinicians_Update_Column {
  /** column name */
  ClinicianId = 'clinician_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Fullscript_Clinicians_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Fullscript_Clinicians_Set_Input>;
  /** filter the rows which have to be updated */
  where: Fullscript_Clinicians_Bool_Exp;
};

export type GetFhirPatientMediaOutput = {
  __typename?: 'getFHIRPatientMediaOutput';
  media: Array<MediaOutput>;
  patientCodexId: Scalars['String'];
  total: Scalars['Float'];
};

export type GetFhirPatientOutput = {
  __typename?: 'getFHIRPatientOutput';
  users: Array<GetPatientOutput>;
};

/** Global configs */
export type Global_Configs = {
  __typename?: 'global_configs';
  created_at: Scalars['timestamptz'];
  name: Global_Configs_Names_Enum;
  updated_at: Scalars['timestamptz'];
  value: Scalars['jsonb'];
};


/** Global configs */
export type Global_ConfigsValueArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "global_configs" */
export type Global_Configs_Aggregate = {
  __typename?: 'global_configs_aggregate';
  aggregate?: Maybe<Global_Configs_Aggregate_Fields>;
  nodes: Array<Global_Configs>;
};

/** aggregate fields of "global_configs" */
export type Global_Configs_Aggregate_Fields = {
  __typename?: 'global_configs_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Global_Configs_Max_Fields>;
  min?: Maybe<Global_Configs_Min_Fields>;
};


/** aggregate fields of "global_configs" */
export type Global_Configs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Global_Configs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Global_Configs_Append_Input = {
  value?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "global_configs". All fields are combined with a logical 'AND'. */
export type Global_Configs_Bool_Exp = {
  _and?: InputMaybe<Array<Global_Configs_Bool_Exp>>;
  _not?: InputMaybe<Global_Configs_Bool_Exp>;
  _or?: InputMaybe<Array<Global_Configs_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  name?: InputMaybe<Global_Configs_Names_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "global_configs" */
export enum Global_Configs_Constraint {
  /** unique or primary key constraint on columns "name" */
  GlobalConfigsPkey = 'global_configs_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Global_Configs_Delete_At_Path_Input = {
  value?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Global_Configs_Delete_Elem_Input = {
  value?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Global_Configs_Delete_Key_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "global_configs" */
export type Global_Configs_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Global_Configs_Names_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type Global_Configs_Max_Fields = {
  __typename?: 'global_configs_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Global_Configs_Min_Fields = {
  __typename?: 'global_configs_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "global_configs" */
export type Global_Configs_Mutation_Response = {
  __typename?: 'global_configs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Global_Configs>;
};

/** Global configs names */
export type Global_Configs_Names = {
  __typename?: 'global_configs_names';
  comment?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "global_configs_names" */
export type Global_Configs_Names_Aggregate = {
  __typename?: 'global_configs_names_aggregate';
  aggregate?: Maybe<Global_Configs_Names_Aggregate_Fields>;
  nodes: Array<Global_Configs_Names>;
};

/** aggregate fields of "global_configs_names" */
export type Global_Configs_Names_Aggregate_Fields = {
  __typename?: 'global_configs_names_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Global_Configs_Names_Max_Fields>;
  min?: Maybe<Global_Configs_Names_Min_Fields>;
};


/** aggregate fields of "global_configs_names" */
export type Global_Configs_Names_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Global_Configs_Names_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "global_configs_names". All fields are combined with a logical 'AND'. */
export type Global_Configs_Names_Bool_Exp = {
  _and?: InputMaybe<Array<Global_Configs_Names_Bool_Exp>>;
  _not?: InputMaybe<Global_Configs_Names_Bool_Exp>;
  _or?: InputMaybe<Array<Global_Configs_Names_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "global_configs_names" */
export enum Global_Configs_Names_Constraint {
  /** unique or primary key constraint on columns "value" */
  GlobalConfigsNamesPkey = 'global_configs_names_pkey'
}

export enum Global_Configs_Names_Enum {
  /** Disable the routes for the appointments section */
  AppointmentRoutesDisable = 'APPOINTMENT_ROUTES_DISABLE',
  /** Deactivate the barcode scanning feature for test kit registrations. */
  BarcodeScanningDisabled = 'BARCODE_SCANNING_DISABLED',
  /** Deactivates the Complete MyVisit Summary Modal */
  CompleteMyvisitSummaryModalDisabled = 'COMPLETE_MYVISIT_SUMMARY_MODAL_DISABLED',
  /** Enables sign up provider route */
  EnableSignUpProvider = 'ENABLE_SIGN_UP_PROVIDER',
  /** Combination of both values give a timer of 06:00 minutes. With an idle timer of 01:00 minute. Meaning that after one minute, the user will be prompted if it's still there. */
  InactivityModalOverallTimeoutVariable = 'INACTIVITY_MODAL_OVERALL_TIMEOUT_VARIABLE',
  /** Combination of both values give a timer of 06:00 minutes. With an idle timer of 01:00 minute. Meaning that after one minute, the user will be prompted if it's still there. */
  InactivityModalPromptBeforeIdleVariable = 'INACTIVITY_MODAL_PROMPT_BEFORE_IDLE_VARIABLE',
  /** Report status of whether the feature is enabled or not for Inactivity modal across the whole app. */
  InactivityModalStatus = 'INACTIVITY_MODAL_STATUS',
  /** Deactivate the make appointment screen. */
  MakeAppointmentDisabled = 'MAKE_APPOINTMENT_DISABLED',
  /** Deactivates the MyVisit Summary Modal */
  MyvisitSummaryModalDisabled = 'MYVISIT_SUMMARY_MODAL_DISABLED',
  /** If true, skintelligent result will be processed in a cloud function, if not it will be processed in the nest app */
  SkintelligentProcessInCloudFunction = 'SKINTELLIGENT_PROCESS_IN_CLOUD_FUNCTION',
  /** Enables / disables mechanism to logout user after closing the application and an elapsed time has passed. */
  UserLogoutBasedOnLastActivity = 'USER_LOGOUT_BASED_ON_LAST_ACTIVITY',
  /** Provides value for time limit of elapsed time before logging out an user that has closed the application. */
  UserLogoutBasedOnLastActivityTimeLimit = 'USER_LOGOUT_BASED_ON_LAST_ACTIVITY_TIME_LIMIT'
}

/** Boolean expression to compare columns of type "global_configs_names_enum". All fields are combined with logical 'AND'. */
export type Global_Configs_Names_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Global_Configs_Names_Enum>;
  _in?: InputMaybe<Array<Global_Configs_Names_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Global_Configs_Names_Enum>;
  _nin?: InputMaybe<Array<Global_Configs_Names_Enum>>;
};

/** input type for inserting data into table "global_configs_names" */
export type Global_Configs_Names_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Global_Configs_Names_Max_Fields = {
  __typename?: 'global_configs_names_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Global_Configs_Names_Min_Fields = {
  __typename?: 'global_configs_names_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "global_configs_names" */
export type Global_Configs_Names_Mutation_Response = {
  __typename?: 'global_configs_names_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Global_Configs_Names>;
};

/** on_conflict condition type for table "global_configs_names" */
export type Global_Configs_Names_On_Conflict = {
  constraint: Global_Configs_Names_Constraint;
  update_columns?: Array<Global_Configs_Names_Update_Column>;
  where?: InputMaybe<Global_Configs_Names_Bool_Exp>;
};

/** Ordering options when selecting data from "global_configs_names". */
export type Global_Configs_Names_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: global_configs_names */
export type Global_Configs_Names_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "global_configs_names" */
export enum Global_Configs_Names_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "global_configs_names" */
export type Global_Configs_Names_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "global_configs_names" */
export type Global_Configs_Names_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Global_Configs_Names_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Global_Configs_Names_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "global_configs_names" */
export enum Global_Configs_Names_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Global_Configs_Names_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Global_Configs_Names_Set_Input>;
  /** filter the rows which have to be updated */
  where: Global_Configs_Names_Bool_Exp;
};

/** on_conflict condition type for table "global_configs" */
export type Global_Configs_On_Conflict = {
  constraint: Global_Configs_Constraint;
  update_columns?: Array<Global_Configs_Update_Column>;
  where?: InputMaybe<Global_Configs_Bool_Exp>;
};

/** Ordering options when selecting data from "global_configs". */
export type Global_Configs_Order_By = {
  created_at?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: global_configs */
export type Global_Configs_Pk_Columns_Input = {
  name: Global_Configs_Names_Enum;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Global_Configs_Prepend_Input = {
  value?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "global_configs" */
export enum Global_Configs_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "global_configs" */
export type Global_Configs_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Global_Configs_Names_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['jsonb']>;
};

/** Streaming cursor of the table "global_configs" */
export type Global_Configs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Global_Configs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Global_Configs_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Global_Configs_Names_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['jsonb']>;
};

/** update columns of table "global_configs" */
export enum Global_Configs_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

export type Global_Configs_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Global_Configs_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Global_Configs_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Global_Configs_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Global_Configs_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Global_Configs_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Global_Configs_Set_Input>;
  /** filter the rows which have to be updated */
  where: Global_Configs_Bool_Exp;
};

/** columns and relationships of "image_source_types" */
export type Image_Source_Types = {
  __typename?: 'image_source_types';
  comment?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "image_source_types" */
export type Image_Source_Types_Aggregate = {
  __typename?: 'image_source_types_aggregate';
  aggregate?: Maybe<Image_Source_Types_Aggregate_Fields>;
  nodes: Array<Image_Source_Types>;
};

/** aggregate fields of "image_source_types" */
export type Image_Source_Types_Aggregate_Fields = {
  __typename?: 'image_source_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Image_Source_Types_Max_Fields>;
  min?: Maybe<Image_Source_Types_Min_Fields>;
};


/** aggregate fields of "image_source_types" */
export type Image_Source_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Image_Source_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "image_source_types". All fields are combined with a logical 'AND'. */
export type Image_Source_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Image_Source_Types_Bool_Exp>>;
  _not?: InputMaybe<Image_Source_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Image_Source_Types_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "image_source_types" */
export enum Image_Source_Types_Constraint {
  /** unique or primary key constraint on columns "value" */
  ImageSourceTypesPkey = 'image_source_types_pkey'
}

export enum Image_Source_Types_Enum {
  Mobile = 'MOBILE',
  Pc = 'PC'
}

/** Boolean expression to compare columns of type "image_source_types_enum". All fields are combined with logical 'AND'. */
export type Image_Source_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Image_Source_Types_Enum>;
  _in?: InputMaybe<Array<Image_Source_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Image_Source_Types_Enum>;
  _nin?: InputMaybe<Array<Image_Source_Types_Enum>>;
};

/** input type for inserting data into table "image_source_types" */
export type Image_Source_Types_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Image_Source_Types_Max_Fields = {
  __typename?: 'image_source_types_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Image_Source_Types_Min_Fields = {
  __typename?: 'image_source_types_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "image_source_types" */
export type Image_Source_Types_Mutation_Response = {
  __typename?: 'image_source_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Image_Source_Types>;
};

/** input type for inserting object relation for remote table "image_source_types" */
export type Image_Source_Types_Obj_Rel_Insert_Input = {
  data: Image_Source_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Image_Source_Types_On_Conflict>;
};

/** on_conflict condition type for table "image_source_types" */
export type Image_Source_Types_On_Conflict = {
  constraint: Image_Source_Types_Constraint;
  update_columns?: Array<Image_Source_Types_Update_Column>;
  where?: InputMaybe<Image_Source_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "image_source_types". */
export type Image_Source_Types_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: image_source_types */
export type Image_Source_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "image_source_types" */
export enum Image_Source_Types_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "image_source_types" */
export type Image_Source_Types_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "image_source_types" */
export type Image_Source_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Image_Source_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Image_Source_Types_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "image_source_types" */
export enum Image_Source_Types_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Image_Source_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Image_Source_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Image_Source_Types_Bool_Exp;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "labs" */
export type Labs = {
  __typename?: 'labs';
  comment?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "labs" */
export type Labs_Aggregate = {
  __typename?: 'labs_aggregate';
  aggregate?: Maybe<Labs_Aggregate_Fields>;
  nodes: Array<Labs>;
};

/** aggregate fields of "labs" */
export type Labs_Aggregate_Fields = {
  __typename?: 'labs_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Labs_Max_Fields>;
  min?: Maybe<Labs_Min_Fields>;
};


/** aggregate fields of "labs" */
export type Labs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Labs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "labs". All fields are combined with a logical 'AND'. */
export type Labs_Bool_Exp = {
  _and?: InputMaybe<Array<Labs_Bool_Exp>>;
  _not?: InputMaybe<Labs_Bool_Exp>;
  _or?: InputMaybe<Array<Labs_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "labs" */
export enum Labs_Constraint {
  /** unique or primary key constraint on columns "value" */
  LabsPkey = 'labs_pkey'
}

export enum Labs_Enum {
  DnaVisit = 'DNA_VISIT',
  Zrt = 'ZRT'
}

/** Boolean expression to compare columns of type "labs_enum". All fields are combined with logical 'AND'. */
export type Labs_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Labs_Enum>;
  _in?: InputMaybe<Array<Labs_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Labs_Enum>;
  _nin?: InputMaybe<Array<Labs_Enum>>;
};

/** input type for inserting data into table "labs" */
export type Labs_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Labs_Max_Fields = {
  __typename?: 'labs_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Labs_Min_Fields = {
  __typename?: 'labs_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "labs" */
export type Labs_Mutation_Response = {
  __typename?: 'labs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Labs>;
};

/** on_conflict condition type for table "labs" */
export type Labs_On_Conflict = {
  constraint: Labs_Constraint;
  update_columns?: Array<Labs_Update_Column>;
  where?: InputMaybe<Labs_Bool_Exp>;
};

/** Ordering options when selecting data from "labs". */
export type Labs_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: labs */
export type Labs_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "labs" */
export enum Labs_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "labs" */
export type Labs_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "labs" */
export type Labs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Labs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Labs_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "labs" */
export enum Labs_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Labs_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Labs_Set_Input>;
  /** filter the rows which have to be updated */
  where: Labs_Bool_Exp;
};

/** columns and relationships of "legal_document_types" */
export type Legal_Document_Types = {
  __typename?: 'legal_document_types';
  comment?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "legal_document_types" */
export type Legal_Document_Types_Aggregate = {
  __typename?: 'legal_document_types_aggregate';
  aggregate?: Maybe<Legal_Document_Types_Aggregate_Fields>;
  nodes: Array<Legal_Document_Types>;
};

/** aggregate fields of "legal_document_types" */
export type Legal_Document_Types_Aggregate_Fields = {
  __typename?: 'legal_document_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Legal_Document_Types_Max_Fields>;
  min?: Maybe<Legal_Document_Types_Min_Fields>;
};


/** aggregate fields of "legal_document_types" */
export type Legal_Document_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Legal_Document_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "legal_document_types". All fields are combined with a logical 'AND'. */
export type Legal_Document_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Legal_Document_Types_Bool_Exp>>;
  _not?: InputMaybe<Legal_Document_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Legal_Document_Types_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "legal_document_types" */
export enum Legal_Document_Types_Constraint {
  /** unique or primary key constraint on columns "value" */
  LegalDocumentTypesPkey = 'legal_document_types_pkey'
}

export enum Legal_Document_Types_Enum {
  /** Consent agreement with DNA visit related to the supervision of laboratory tests. */
  DnaVisitRecordConsent = 'DNA_VISIT_RECORD_CONSENT',
  /** Consent agreement for general test records. */
  GeneralTestRecordConsent = 'GENERAL_TEST_RECORD_CONSENT',
  /** Codex Labs' privacy policies for DECODE diagnostic test data, emphasizing consent-driven non-sharing, secure result handling, and user empowerment. */
  LabSampleResultsPrivacyStatement = 'LAB_SAMPLE_RESULTS_PRIVACY_STATEMENT',
  PrivacyPolicy = 'PRIVACY_POLICY',
  TermsOfService = 'TERMS_OF_SERVICE'
}

/** Boolean expression to compare columns of type "legal_document_types_enum". All fields are combined with logical 'AND'. */
export type Legal_Document_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Legal_Document_Types_Enum>;
  _in?: InputMaybe<Array<Legal_Document_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Legal_Document_Types_Enum>;
  _nin?: InputMaybe<Array<Legal_Document_Types_Enum>>;
};

/** input type for inserting data into table "legal_document_types" */
export type Legal_Document_Types_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Legal_Document_Types_Max_Fields = {
  __typename?: 'legal_document_types_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Legal_Document_Types_Min_Fields = {
  __typename?: 'legal_document_types_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "legal_document_types" */
export type Legal_Document_Types_Mutation_Response = {
  __typename?: 'legal_document_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Legal_Document_Types>;
};

/** on_conflict condition type for table "legal_document_types" */
export type Legal_Document_Types_On_Conflict = {
  constraint: Legal_Document_Types_Constraint;
  update_columns?: Array<Legal_Document_Types_Update_Column>;
  where?: InputMaybe<Legal_Document_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "legal_document_types". */
export type Legal_Document_Types_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: legal_document_types */
export type Legal_Document_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "legal_document_types" */
export enum Legal_Document_Types_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "legal_document_types" */
export type Legal_Document_Types_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "legal_document_types" */
export type Legal_Document_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Legal_Document_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Legal_Document_Types_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "legal_document_types" */
export enum Legal_Document_Types_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Legal_Document_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Legal_Document_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Legal_Document_Types_Bool_Exp;
};

/** columns and relationships of "legal_documents" */
export type Legal_Documents = {
  __typename?: 'legal_documents';
  content_mobile: Scalars['String'];
  content_web: Scalars['String'];
  hash_content_mobile: Scalars['String'];
  hash_content_web: Scalars['String'];
  legal_document_id: Scalars['bigint'];
  legal_document_type: Legal_Document_Types_Enum;
};

/** aggregated selection of "legal_documents" */
export type Legal_Documents_Aggregate = {
  __typename?: 'legal_documents_aggregate';
  aggregate?: Maybe<Legal_Documents_Aggregate_Fields>;
  nodes: Array<Legal_Documents>;
};

/** aggregate fields of "legal_documents" */
export type Legal_Documents_Aggregate_Fields = {
  __typename?: 'legal_documents_aggregate_fields';
  avg?: Maybe<Legal_Documents_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Legal_Documents_Max_Fields>;
  min?: Maybe<Legal_Documents_Min_Fields>;
  stddev?: Maybe<Legal_Documents_Stddev_Fields>;
  stddev_pop?: Maybe<Legal_Documents_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Legal_Documents_Stddev_Samp_Fields>;
  sum?: Maybe<Legal_Documents_Sum_Fields>;
  var_pop?: Maybe<Legal_Documents_Var_Pop_Fields>;
  var_samp?: Maybe<Legal_Documents_Var_Samp_Fields>;
  variance?: Maybe<Legal_Documents_Variance_Fields>;
};


/** aggregate fields of "legal_documents" */
export type Legal_Documents_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Legal_Documents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Legal_Documents_Avg_Fields = {
  __typename?: 'legal_documents_avg_fields';
  legal_document_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "legal_documents". All fields are combined with a logical 'AND'. */
export type Legal_Documents_Bool_Exp = {
  _and?: InputMaybe<Array<Legal_Documents_Bool_Exp>>;
  _not?: InputMaybe<Legal_Documents_Bool_Exp>;
  _or?: InputMaybe<Array<Legal_Documents_Bool_Exp>>;
  content_mobile?: InputMaybe<String_Comparison_Exp>;
  content_web?: InputMaybe<String_Comparison_Exp>;
  hash_content_mobile?: InputMaybe<String_Comparison_Exp>;
  hash_content_web?: InputMaybe<String_Comparison_Exp>;
  legal_document_id?: InputMaybe<Bigint_Comparison_Exp>;
  legal_document_type?: InputMaybe<Legal_Document_Types_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "legal_documents" */
export enum Legal_Documents_Constraint {
  /** unique or primary key constraint on columns "legal_document_id" */
  LegalDocumentsPkey = 'legal_documents_pkey'
}

/** input type for incrementing numeric columns in table "legal_documents" */
export type Legal_Documents_Inc_Input = {
  legal_document_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "legal_documents" */
export type Legal_Documents_Insert_Input = {
  content_mobile?: InputMaybe<Scalars['String']>;
  content_web?: InputMaybe<Scalars['String']>;
  hash_content_mobile?: InputMaybe<Scalars['String']>;
  hash_content_web?: InputMaybe<Scalars['String']>;
  legal_document_id?: InputMaybe<Scalars['bigint']>;
  legal_document_type?: InputMaybe<Legal_Document_Types_Enum>;
};

/** aggregate max on columns */
export type Legal_Documents_Max_Fields = {
  __typename?: 'legal_documents_max_fields';
  content_mobile?: Maybe<Scalars['String']>;
  content_web?: Maybe<Scalars['String']>;
  hash_content_mobile?: Maybe<Scalars['String']>;
  hash_content_web?: Maybe<Scalars['String']>;
  legal_document_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Legal_Documents_Min_Fields = {
  __typename?: 'legal_documents_min_fields';
  content_mobile?: Maybe<Scalars['String']>;
  content_web?: Maybe<Scalars['String']>;
  hash_content_mobile?: Maybe<Scalars['String']>;
  hash_content_web?: Maybe<Scalars['String']>;
  legal_document_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "legal_documents" */
export type Legal_Documents_Mutation_Response = {
  __typename?: 'legal_documents_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Legal_Documents>;
};

/** input type for inserting object relation for remote table "legal_documents" */
export type Legal_Documents_Obj_Rel_Insert_Input = {
  data: Legal_Documents_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Legal_Documents_On_Conflict>;
};

/** on_conflict condition type for table "legal_documents" */
export type Legal_Documents_On_Conflict = {
  constraint: Legal_Documents_Constraint;
  update_columns?: Array<Legal_Documents_Update_Column>;
  where?: InputMaybe<Legal_Documents_Bool_Exp>;
};

/** Ordering options when selecting data from "legal_documents". */
export type Legal_Documents_Order_By = {
  content_mobile?: InputMaybe<Order_By>;
  content_web?: InputMaybe<Order_By>;
  hash_content_mobile?: InputMaybe<Order_By>;
  hash_content_web?: InputMaybe<Order_By>;
  legal_document_id?: InputMaybe<Order_By>;
  legal_document_type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: legal_documents */
export type Legal_Documents_Pk_Columns_Input = {
  legal_document_id: Scalars['bigint'];
};

/** select columns of table "legal_documents" */
export enum Legal_Documents_Select_Column {
  /** column name */
  ContentMobile = 'content_mobile',
  /** column name */
  ContentWeb = 'content_web',
  /** column name */
  HashContentMobile = 'hash_content_mobile',
  /** column name */
  HashContentWeb = 'hash_content_web',
  /** column name */
  LegalDocumentId = 'legal_document_id',
  /** column name */
  LegalDocumentType = 'legal_document_type'
}

/** input type for updating data in table "legal_documents" */
export type Legal_Documents_Set_Input = {
  content_mobile?: InputMaybe<Scalars['String']>;
  content_web?: InputMaybe<Scalars['String']>;
  hash_content_mobile?: InputMaybe<Scalars['String']>;
  hash_content_web?: InputMaybe<Scalars['String']>;
  legal_document_id?: InputMaybe<Scalars['bigint']>;
  legal_document_type?: InputMaybe<Legal_Document_Types_Enum>;
};

/** aggregate stddev on columns */
export type Legal_Documents_Stddev_Fields = {
  __typename?: 'legal_documents_stddev_fields';
  legal_document_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Legal_Documents_Stddev_Pop_Fields = {
  __typename?: 'legal_documents_stddev_pop_fields';
  legal_document_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Legal_Documents_Stddev_Samp_Fields = {
  __typename?: 'legal_documents_stddev_samp_fields';
  legal_document_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "legal_documents" */
export type Legal_Documents_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Legal_Documents_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Legal_Documents_Stream_Cursor_Value_Input = {
  content_mobile?: InputMaybe<Scalars['String']>;
  content_web?: InputMaybe<Scalars['String']>;
  hash_content_mobile?: InputMaybe<Scalars['String']>;
  hash_content_web?: InputMaybe<Scalars['String']>;
  legal_document_id?: InputMaybe<Scalars['bigint']>;
  legal_document_type?: InputMaybe<Legal_Document_Types_Enum>;
};

/** aggregate sum on columns */
export type Legal_Documents_Sum_Fields = {
  __typename?: 'legal_documents_sum_fields';
  legal_document_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "legal_documents" */
export enum Legal_Documents_Update_Column {
  /** column name */
  ContentMobile = 'content_mobile',
  /** column name */
  ContentWeb = 'content_web',
  /** column name */
  HashContentMobile = 'hash_content_mobile',
  /** column name */
  HashContentWeb = 'hash_content_web',
  /** column name */
  LegalDocumentId = 'legal_document_id',
  /** column name */
  LegalDocumentType = 'legal_document_type'
}

export type Legal_Documents_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Legal_Documents_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Legal_Documents_Set_Input>;
  /** filter the rows which have to be updated */
  where: Legal_Documents_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Legal_Documents_Var_Pop_Fields = {
  __typename?: 'legal_documents_var_pop_fields';
  legal_document_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Legal_Documents_Var_Samp_Fields = {
  __typename?: 'legal_documents_var_samp_fields';
  legal_document_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Legal_Documents_Variance_Fields = {
  __typename?: 'legal_documents_variance_fields';
  legal_document_id?: Maybe<Scalars['Float']>;
};

/** Medical schools */
export type Medical_Schools = {
  __typename?: 'medical_schools';
  id: Scalars['Int'];
  name: Scalars['String'];
};

/** aggregated selection of "medical_schools" */
export type Medical_Schools_Aggregate = {
  __typename?: 'medical_schools_aggregate';
  aggregate?: Maybe<Medical_Schools_Aggregate_Fields>;
  nodes: Array<Medical_Schools>;
};

/** aggregate fields of "medical_schools" */
export type Medical_Schools_Aggregate_Fields = {
  __typename?: 'medical_schools_aggregate_fields';
  avg?: Maybe<Medical_Schools_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Medical_Schools_Max_Fields>;
  min?: Maybe<Medical_Schools_Min_Fields>;
  stddev?: Maybe<Medical_Schools_Stddev_Fields>;
  stddev_pop?: Maybe<Medical_Schools_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Medical_Schools_Stddev_Samp_Fields>;
  sum?: Maybe<Medical_Schools_Sum_Fields>;
  var_pop?: Maybe<Medical_Schools_Var_Pop_Fields>;
  var_samp?: Maybe<Medical_Schools_Var_Samp_Fields>;
  variance?: Maybe<Medical_Schools_Variance_Fields>;
};


/** aggregate fields of "medical_schools" */
export type Medical_Schools_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Medical_Schools_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Medical_Schools_Avg_Fields = {
  __typename?: 'medical_schools_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "medical_schools". All fields are combined with a logical 'AND'. */
export type Medical_Schools_Bool_Exp = {
  _and?: InputMaybe<Array<Medical_Schools_Bool_Exp>>;
  _not?: InputMaybe<Medical_Schools_Bool_Exp>;
  _or?: InputMaybe<Array<Medical_Schools_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "medical_schools" */
export enum Medical_Schools_Constraint {
  /** unique or primary key constraint on columns "name" */
  MedicalSchoolsNameKey = 'medical_schools_name_key',
  /** unique or primary key constraint on columns "id" */
  MedicalSchoolsPkey = 'medical_schools_pkey'
}

/** input type for incrementing numeric columns in table "medical_schools" */
export type Medical_Schools_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "medical_schools" */
export type Medical_Schools_Insert_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Medical_Schools_Max_Fields = {
  __typename?: 'medical_schools_max_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Medical_Schools_Min_Fields = {
  __typename?: 'medical_schools_min_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "medical_schools" */
export type Medical_Schools_Mutation_Response = {
  __typename?: 'medical_schools_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Medical_Schools>;
};

/** on_conflict condition type for table "medical_schools" */
export type Medical_Schools_On_Conflict = {
  constraint: Medical_Schools_Constraint;
  update_columns?: Array<Medical_Schools_Update_Column>;
  where?: InputMaybe<Medical_Schools_Bool_Exp>;
};

/** Ordering options when selecting data from "medical_schools". */
export type Medical_Schools_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: medical_schools */
export type Medical_Schools_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "medical_schools" */
export enum Medical_Schools_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "medical_schools" */
export type Medical_Schools_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Medical_Schools_Stddev_Fields = {
  __typename?: 'medical_schools_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Medical_Schools_Stddev_Pop_Fields = {
  __typename?: 'medical_schools_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Medical_Schools_Stddev_Samp_Fields = {
  __typename?: 'medical_schools_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "medical_schools" */
export type Medical_Schools_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Medical_Schools_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Medical_Schools_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Medical_Schools_Sum_Fields = {
  __typename?: 'medical_schools_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "medical_schools" */
export enum Medical_Schools_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Medical_Schools_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Medical_Schools_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Medical_Schools_Set_Input>;
  /** filter the rows which have to be updated */
  where: Medical_Schools_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Medical_Schools_Var_Pop_Fields = {
  __typename?: 'medical_schools_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Medical_Schools_Var_Samp_Fields = {
  __typename?: 'medical_schools_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Medical_Schools_Variance_Fields = {
  __typename?: 'medical_schools_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** AcceptLegalDocument */
  AcceptLegalDocument?: Maybe<SuccessfulAcceptedLegalDocument>;
  /** account Deletion */
  AccountDeletion?: Maybe<SuccessfulAccountDeletion>;
  /** generateCustomToken */
  GenerateCustomToken?: Maybe<SuccessfulGeneratedCustomToken>;
  /** generateFirebaseCustomToken */
  GenerateFirebaseCustomToken?: Maybe<SuccessfulGeneratedFirebaseCustomToken>;
  /** Get Acuity Calendar information for Provider */
  GetAcuityCalendarInformation?: Maybe<AcuityCalendarResult>;
  /** GetDoseSpotMedicationsSearch */
  GetDoseSpotMedicationsSearch?: Maybe<DoseSpotMedicationSearchResult>;
  /** GetDoseSpotMedicationsSelect */
  GetDoseSpotMedicationsSelect?: Maybe<DoseSpotMedicationSelectResponse>;
  /** GetDoseSpotPractitionerURL */
  GetDoseSpotPractitionerURL?: Maybe<DoseSpotUrlResult>;
  /** Get latest DNA visit tests */
  GetLatestDnaVisitTests: Scalars['jsonb'];
  /** Get Persona session token to resume inquiry */
  GetPersonaSessionToken: Scalars['String'];
  /** getSignUrlFormStorageByProviderForPatient */
  GetProviderPatientSignedUrl?: Maybe<SuccessGetSignUrlFormStorage>;
  /** getSignUrlFormStorage */
  GetSignUrlFormStorage?: Maybe<SuccessGetSignUrlFormStorage>;
  /** getSignUrlFromStorageByAdmin */
  GetSignUrlFromStorageByAdmin?: Maybe<SuccessGetSignUrlFormStorage>;
  /** getSignUrlFromStorageForRequester */
  GetSignUrlFromStorageForRequester?: Maybe<SuccessGetSignUrlFormStorage>;
  /** Get Vonage archives */
  GetVonageArchives?: Maybe<SuccessfulGetVonageArchives>;
  /** get vonage session */
  GetVonageSession?: Maybe<SuccessGetVonageSession>;
  GetVonageSignUrlFormStorage?: Maybe<SuccessGetVonageSignUrlFormStorage>;
  /** registerAuthProvider */
  RegisterAuthProvider?: Maybe<SuccessfulAuthProvider>;
  /** Register provider acuity calendar information */
  RegisterProviderAcuityCalendarInformation?: Maybe<SuccessfulRegisteredCalendarInformation>;
  /** Register Test Kit */
  RegisterTestKit?: Maybe<SuccessfulRegisteredTestKit>;
  /** Save DNA scanned data */
  SaveDnaScannedData?: Maybe<SuccessfulSavedScannedData>;
  /** SaveQuestionnaire */
  SaveQuestionnaire?: Maybe<SuccessfulQuestionnaireSave>;
  /** scheduleAppointment */
  ScheduleAppointment?: Maybe<SuccessfullScheduledAppointment>;
  /** Signup provider */
  SignupProvider?: Maybe<SuccessfulSignup>;
  /** user signup */
  SignupUser?: Maybe<SuccessfulSignup>;
  /** updateInAppNotifications */
  UpdateInAppNotification?: Maybe<SuccessfulUpdateInAppNotification>;
  /** UpdateProfileNotification */
  UpdateProfileNotification?: Maybe<SuccessfulUpdateProfileNotification>;
  /** updateProfilePicture */
  UpdateProfilePicture?: Maybe<SuccessfullUpdatedProfilePicture>;
  UpdateProvider?: Maybe<SuccessfulProviderUpdated>;
  /** UpdateProviderAccountSettings */
  UpdateProviderAccountSettings?: Maybe<SuccessfulUpdatedProviderAccountSettings>;
  /** updateProviderProfilePicture */
  UpdateProviderProfilePicture?: Maybe<SuccessfullUpdatedProfilePicture>;
  /** UpdateQuestionnaire */
  UpdateQuestionnaire?: Maybe<SuccessfulQuestionnaireUpdate>;
  /** uploadCapturedImage */
  UploadCapturedImage?: Maybe<SuccessfulUploadedImage>;
  /** uploadFileToStorage */
  UploadFileToStorage?: Maybe<SuccessUploadedFileToStorage>;
  /** uploadVonageFileToStorage */
  UploadVonageFileToStorage?: Maybe<SuccessUploadedVonageFileToStorage>;
  /** validateCustomToken */
  ValidateCustomToken?: Maybe<SuccessfulValidatedCustomToken>;
  /** Validate NPI number */
  ValidateNpiNumber: Scalars['Boolean'];
  /** Vonage archive start */
  VonageArchiveStart?: Maybe<SuccessfulVonageArchiveStart>;
  /** Vonage archive stop */
  VonageArchiveStop?: Maybe<SuccessfulVonageArchiveStop>;
  acceptOrDeclineAppointment: PatchFhirAppointmentOutput;
  addMessageToAppointmentMessageList: Communication;
  addMessageToAppointmentMessageListByAppointmentCodexId: Communication;
  addServiceOfferings: PatchFhirProviderOutput;
  appendEncounterToFHIRAppointment: PatchAppointmentAndEncounterOutput;
  cancelFHIRAppointment: PatchFhirAppointmentOutput;
  createAppointmentMessageList: Communication;
  createBecomePatientRequest: PatchFhirRequestBecomePatientOutput;
  createFHIRAppointment: CreateFhirAppointmentOutput;
  createFHIRAppointmentForCurrentUser: CreateFhirAppointmentOutput;
  createFHIRDnaVisitTest: CreateFhirDnaVisitTestOutput;
  createFHIREncounter: CreateFhirEncounterOutput;
  createFHIROfficeLocation: CreateFhirOfficeLocationOutput;
  createFHIROrganization: CreateFhirOrganizationOutput;
  createFHIRPatient: CreateFhirPatientOutput;
  createFHIRProvider: CreateFhirProviderOutput;
  createFHIRProviderRole: CreateFhirProviderRoleOutput;
  createFHIRSkintelligentTest: CreateFhirSkintelligentTestOutput;
  createFHIRZrtTest: CreateFhirZrtTestOutput;
  /** delete data from the table: "accepted_legal_documents" */
  delete_accepted_legal_documents?: Maybe<Accepted_Legal_Documents_Mutation_Response>;
  /** delete single row from the table: "accepted_legal_documents" */
  delete_accepted_legal_documents_by_pk?: Maybe<Accepted_Legal_Documents>;
  /** delete data from the table: "acuity_provider_calendar" */
  delete_acuity_provider_calendar?: Maybe<Acuity_Provider_Calendar_Mutation_Response>;
  /** delete single row from the table: "acuity_provider_calendar" */
  delete_acuity_provider_calendar_by_pk?: Maybe<Acuity_Provider_Calendar>;
  /** delete data from the table: "addresses" */
  delete_addresses?: Maybe<Addresses_Mutation_Response>;
  /** delete single row from the table: "addresses" */
  delete_addresses_by_pk?: Maybe<Addresses>;
  /** delete data from the table: "channel_signals" */
  delete_channel_signals?: Maybe<Channel_Signals_Mutation_Response>;
  /** delete single row from the table: "channel_signals" */
  delete_channel_signals_by_pk?: Maybe<Channel_Signals>;
  /** delete data from the table: "common_question_responses" */
  delete_common_question_responses?: Maybe<Common_Question_Responses_Mutation_Response>;
  /** delete single row from the table: "common_question_responses" */
  delete_common_question_responses_by_pk?: Maybe<Common_Question_Responses>;
  /** delete data from the table: "common_questions" */
  delete_common_questions?: Maybe<Common_Questions_Mutation_Response>;
  /** delete single row from the table: "common_questions" */
  delete_common_questions_by_pk?: Maybe<Common_Questions>;
  /** delete data from the table: "communication_channels" */
  delete_communication_channels?: Maybe<Communication_Channels_Mutation_Response>;
  /** delete single row from the table: "communication_channels" */
  delete_communication_channels_by_pk?: Maybe<Communication_Channels>;
  /** delete data from the table: "countries" */
  delete_countries?: Maybe<Countries_Mutation_Response>;
  /** delete single row from the table: "countries" */
  delete_countries_by_pk?: Maybe<Countries>;
  /** delete data from the table: "custom_auth_code" */
  delete_custom_auth_code?: Maybe<Custom_Auth_Code_Mutation_Response>;
  /** delete single row from the table: "custom_auth_code" */
  delete_custom_auth_code_by_pk?: Maybe<Custom_Auth_Code>;
  /** delete data from the table: "dnavisit_test_statuses" */
  delete_dnavisit_test_statuses?: Maybe<Dnavisit_Test_Statuses_Mutation_Response>;
  /** delete single row from the table: "dnavisit_test_statuses" */
  delete_dnavisit_test_statuses_by_pk?: Maybe<Dnavisit_Test_Statuses>;
  /** delete data from the table: "dnavisit_tests" */
  delete_dnavisit_tests?: Maybe<Dnavisit_Tests_Mutation_Response>;
  /** delete single row from the table: "dnavisit_tests" */
  delete_dnavisit_tests_by_pk?: Maybe<Dnavisit_Tests>;
  /** delete data from the table: "do_not_use_production_swab_codes" */
  delete_do_not_use_production_swab_codes?: Maybe<Do_Not_Use_Production_Swab_Codes_Mutation_Response>;
  /** delete single row from the table: "do_not_use_production_swab_codes" */
  delete_do_not_use_production_swab_codes_by_pk?: Maybe<Do_Not_Use_Production_Swab_Codes>;
  /** delete data from the table: "dosespot_clinicians" */
  delete_dosespot_clinicians?: Maybe<Dosespot_Clinicians_Mutation_Response>;
  /** delete single row from the table: "dosespot_clinicians" */
  delete_dosespot_clinicians_by_pk?: Maybe<Dosespot_Clinicians>;
  /** delete data from the table: "file_types" */
  delete_file_types?: Maybe<File_Types_Mutation_Response>;
  /** delete single row from the table: "file_types" */
  delete_file_types_by_pk?: Maybe<File_Types>;
  /** delete data from the table: "files" */
  delete_files?: Maybe<Files_Mutation_Response>;
  /** delete single row from the table: "files" */
  delete_files_by_pk?: Maybe<Files>;
  /** delete data from the table: "fullscript_clinicians" */
  delete_fullscript_clinicians?: Maybe<Fullscript_Clinicians_Mutation_Response>;
  /** delete single row from the table: "fullscript_clinicians" */
  delete_fullscript_clinicians_by_pk?: Maybe<Fullscript_Clinicians>;
  /** delete data from the table: "global_configs" */
  delete_global_configs?: Maybe<Global_Configs_Mutation_Response>;
  /** delete single row from the table: "global_configs" */
  delete_global_configs_by_pk?: Maybe<Global_Configs>;
  /** delete data from the table: "global_configs_names" */
  delete_global_configs_names?: Maybe<Global_Configs_Names_Mutation_Response>;
  /** delete single row from the table: "global_configs_names" */
  delete_global_configs_names_by_pk?: Maybe<Global_Configs_Names>;
  /** delete data from the table: "image_source_types" */
  delete_image_source_types?: Maybe<Image_Source_Types_Mutation_Response>;
  /** delete single row from the table: "image_source_types" */
  delete_image_source_types_by_pk?: Maybe<Image_Source_Types>;
  /** delete data from the table: "labs" */
  delete_labs?: Maybe<Labs_Mutation_Response>;
  /** delete single row from the table: "labs" */
  delete_labs_by_pk?: Maybe<Labs>;
  /** delete data from the table: "legal_document_types" */
  delete_legal_document_types?: Maybe<Legal_Document_Types_Mutation_Response>;
  /** delete single row from the table: "legal_document_types" */
  delete_legal_document_types_by_pk?: Maybe<Legal_Document_Types>;
  /** delete data from the table: "legal_documents" */
  delete_legal_documents?: Maybe<Legal_Documents_Mutation_Response>;
  /** delete single row from the table: "legal_documents" */
  delete_legal_documents_by_pk?: Maybe<Legal_Documents>;
  /** delete data from the table: "medical_schools" */
  delete_medical_schools?: Maybe<Medical_Schools_Mutation_Response>;
  /** delete single row from the table: "medical_schools" */
  delete_medical_schools_by_pk?: Maybe<Medical_Schools>;
  /** delete data from the table: "nabla_notes" */
  delete_nabla_notes?: Maybe<Nabla_Notes_Mutation_Response>;
  /** delete single row from the table: "nabla_notes" */
  delete_nabla_notes_by_pk?: Maybe<Nabla_Notes>;
  /** delete data from the table: "npi_registry_data" */
  delete_npi_registry_data?: Maybe<Npi_Registry_Data_Mutation_Response>;
  /** delete single row from the table: "npi_registry_data" */
  delete_npi_registry_data_by_pk?: Maybe<Npi_Registry_Data>;
  /** delete data from the table: "persona_inquiries" */
  delete_persona_inquiries?: Maybe<Persona_Inquiries_Mutation_Response>;
  /** delete single row from the table: "persona_inquiries" */
  delete_persona_inquiries_by_pk?: Maybe<Persona_Inquiries>;
  /** delete data from the table: "persona_inquiry_status" */
  delete_persona_inquiry_status?: Maybe<Persona_Inquiry_Status_Mutation_Response>;
  /** delete single row from the table: "persona_inquiry_status" */
  delete_persona_inquiry_status_by_pk?: Maybe<Persona_Inquiry_Status>;
  /** delete data from the table: "provider_unread_external_notification_counts" */
  delete_provider_unread_external_notification_counts?: Maybe<Provider_Unread_External_Notification_Counts_Mutation_Response>;
  /** delete single row from the table: "provider_unread_external_notification_counts" */
  delete_provider_unread_external_notification_counts_by_pk?: Maybe<Provider_Unread_External_Notification_Counts>;
  /** delete data from the table: "question_measurement_units" */
  delete_question_measurement_units?: Maybe<Question_Measurement_Units_Mutation_Response>;
  /** delete single row from the table: "question_measurement_units" */
  delete_question_measurement_units_by_pk?: Maybe<Question_Measurement_Units>;
  /** delete data from the table: "question_types" */
  delete_question_types?: Maybe<Question_Types_Mutation_Response>;
  /** delete single row from the table: "question_types" */
  delete_question_types_by_pk?: Maybe<Question_Types>;
  /** delete data from the table: "question_ui_control_helper_text_position" */
  delete_question_ui_control_helper_text_position?: Maybe<Question_Ui_Control_Helper_Text_Position_Mutation_Response>;
  /** delete single row from the table: "question_ui_control_helper_text_position" */
  delete_question_ui_control_helper_text_position_by_pk?: Maybe<Question_Ui_Control_Helper_Text_Position>;
  /** delete data from the table: "question_ui_control_types" */
  delete_question_ui_control_types?: Maybe<Question_Ui_Control_Types_Mutation_Response>;
  /** delete single row from the table: "question_ui_control_types" */
  delete_question_ui_control_types_by_pk?: Maybe<Question_Ui_Control_Types>;
  /** delete data from the table: "questionnaire_questions" */
  delete_questionnaire_questions?: Maybe<Questionnaire_Questions_Mutation_Response>;
  /** delete single row from the table: "questionnaire_questions" */
  delete_questionnaire_questions_by_pk?: Maybe<Questionnaire_Questions>;
  /** delete data from the table: "questionnaire_responses" */
  delete_questionnaire_responses?: Maybe<Questionnaire_Responses_Mutation_Response>;
  /** delete single row from the table: "questionnaire_responses" */
  delete_questionnaire_responses_by_pk?: Maybe<Questionnaire_Responses>;
  /** delete data from the table: "questionnaires" */
  delete_questionnaires?: Maybe<Questionnaires_Mutation_Response>;
  /** delete single row from the table: "questionnaires" */
  delete_questionnaires_by_pk?: Maybe<Questionnaires>;
  /** delete data from the table: "questions" */
  delete_questions?: Maybe<Questions_Mutation_Response>;
  /** delete single row from the table: "questions" */
  delete_questions_by_pk?: Maybe<Questions>;
  /** delete data from the table: "role_types" */
  delete_role_types?: Maybe<Role_Types_Mutation_Response>;
  /** delete single row from the table: "role_types" */
  delete_role_types_by_pk?: Maybe<Role_Types>;
  /** delete data from the table: "roles" */
  delete_roles?: Maybe<Roles_Mutation_Response>;
  /** delete single row from the table: "roles" */
  delete_roles_by_pk?: Maybe<Roles>;
  /** delete data from the table: "skintelligent_tests" */
  delete_skintelligent_tests?: Maybe<Skintelligent_Tests_Mutation_Response>;
  /** delete single row from the table: "skintelligent_tests" */
  delete_skintelligent_tests_by_pk?: Maybe<Skintelligent_Tests>;
  /** delete data from the table: "swab_code_statuses" */
  delete_swab_code_statuses?: Maybe<Swab_Code_Statuses_Mutation_Response>;
  /** delete single row from the table: "swab_code_statuses" */
  delete_swab_code_statuses_by_pk?: Maybe<Swab_Code_Statuses>;
  /** delete data from the table: "swab_codes" */
  delete_swab_codes?: Maybe<Swab_Codes_Mutation_Response>;
  /** delete single row from the table: "swab_codes" */
  delete_swab_codes_by_pk?: Maybe<Swab_Codes>;
  /** delete data from the table: "swab_usage_type" */
  delete_swab_usage_type?: Maybe<Swab_Usage_Type_Mutation_Response>;
  /** delete single row from the table: "swab_usage_type" */
  delete_swab_usage_type_by_pk?: Maybe<Swab_Usage_Type>;
  /** delete data from the table: "test_definitions" */
  delete_test_definitions?: Maybe<Test_Definitions_Mutation_Response>;
  /** delete single row from the table: "test_definitions" */
  delete_test_definitions_by_pk?: Maybe<Test_Definitions>;
  /** delete data from the table: "test_kits_update_states_summary_log" */
  delete_test_kits_update_states_summary_log?: Maybe<Test_Kits_Update_States_Summary_Log_Mutation_Response>;
  /** delete single row from the table: "test_kits_update_states_summary_log" */
  delete_test_kits_update_states_summary_log_by_pk?: Maybe<Test_Kits_Update_States_Summary_Log>;
  /** delete data from the table: "test_types" */
  delete_test_types?: Maybe<Test_Types_Mutation_Response>;
  /** delete single row from the table: "test_types" */
  delete_test_types_by_pk?: Maybe<Test_Types>;
  /** delete data from the table: "tests" */
  delete_tests?: Maybe<Tests_Mutation_Response>;
  /** delete single row from the table: "tests" */
  delete_tests_by_pk?: Maybe<Tests>;
  /** delete data from the table: "text_addresses" */
  delete_text_addresses?: Maybe<Text_Addresses_Mutation_Response>;
  /** delete single row from the table: "text_addresses" */
  delete_text_addresses_by_pk?: Maybe<Text_Addresses>;
  /** delete data from the table: "unauthenticated_questionnaire_questions" */
  delete_unauthenticated_questionnaire_questions?: Maybe<Unauthenticated_Questionnaire_Questions_Mutation_Response>;
  /** delete single row from the table: "unauthenticated_questionnaire_questions" */
  delete_unauthenticated_questionnaire_questions_by_pk?: Maybe<Unauthenticated_Questionnaire_Questions>;
  /** delete data from the table: "unauthenticated_questionnaires" */
  delete_unauthenticated_questionnaires?: Maybe<Unauthenticated_Questionnaires_Mutation_Response>;
  /** delete single row from the table: "unauthenticated_questionnaires" */
  delete_unauthenticated_questionnaires_by_pk?: Maybe<Unauthenticated_Questionnaires>;
  /** delete data from the table: "unauthenticated_questions" */
  delete_unauthenticated_questions?: Maybe<Unauthenticated_Questions_Mutation_Response>;
  /** delete single row from the table: "unauthenticated_questions" */
  delete_unauthenticated_questions_by_pk?: Maybe<Unauthenticated_Questions>;
  /** delete data from the table: "universities" */
  delete_universities?: Maybe<Universities_Mutation_Response>;
  /** delete single row from the table: "universities" */
  delete_universities_by_pk?: Maybe<Universities>;
  /** delete data from the table: "user_addresses" */
  delete_user_addresses?: Maybe<User_Addresses_Mutation_Response>;
  /** delete single row from the table: "user_addresses" */
  delete_user_addresses_by_pk?: Maybe<User_Addresses>;
  /** delete data from the table: "user_auth_provider_types" */
  delete_user_auth_provider_types?: Maybe<User_Auth_Provider_Types_Mutation_Response>;
  /** delete single row from the table: "user_auth_provider_types" */
  delete_user_auth_provider_types_by_pk?: Maybe<User_Auth_Provider_Types>;
  /** delete data from the table: "user_auth_providers" */
  delete_user_auth_providers?: Maybe<User_Auth_Providers_Mutation_Response>;
  /** delete single row from the table: "user_auth_providers" */
  delete_user_auth_providers_by_pk?: Maybe<User_Auth_Providers>;
  /** delete data from the table: "user_auth_tokens" */
  delete_user_auth_tokens?: Maybe<User_Auth_Tokens_Mutation_Response>;
  /** delete single row from the table: "user_auth_tokens" */
  delete_user_auth_tokens_by_pk?: Maybe<User_Auth_Tokens>;
  /** delete data from the table: "user_communication_deliveries" */
  delete_user_communication_deliveries?: Maybe<User_Communication_Deliveries_Mutation_Response>;
  /** delete single row from the table: "user_communication_deliveries" */
  delete_user_communication_deliveries_by_pk?: Maybe<User_Communication_Deliveries>;
  /** delete data from the table: "user_communications" */
  delete_user_communications?: Maybe<User_Communications_Mutation_Response>;
  /** delete single row from the table: "user_communications" */
  delete_user_communications_by_pk?: Maybe<User_Communications>;
  /** delete data from the table: "user_notification_status" */
  delete_user_notification_status?: Maybe<User_Notification_Status_Mutation_Response>;
  /** delete single row from the table: "user_notification_status" */
  delete_user_notification_status_by_pk?: Maybe<User_Notification_Status>;
  /** delete data from the table: "user_notifications" */
  delete_user_notifications?: Maybe<User_Notifications_Mutation_Response>;
  /** delete single row from the table: "user_notifications" */
  delete_user_notifications_by_pk?: Maybe<User_Notifications>;
  /** delete data from the table: "user_roles" */
  delete_user_roles?: Maybe<User_Roles_Mutation_Response>;
  /** delete single row from the table: "user_roles" */
  delete_user_roles_by_pk?: Maybe<User_Roles>;
  /** delete data from the table: "user_scanned_data" */
  delete_user_scanned_data?: Maybe<User_Scanned_Data_Mutation_Response>;
  /** delete single row from the table: "user_scanned_data" */
  delete_user_scanned_data_by_pk?: Maybe<User_Scanned_Data>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** delete data from the table: "vonage_appointment" */
  delete_vonage_appointment?: Maybe<Vonage_Appointment_Mutation_Response>;
  /** delete single row from the table: "vonage_appointment" */
  delete_vonage_appointment_by_pk?: Maybe<Vonage_Appointment>;
  /** delete data from the table: "zrt_tests" */
  delete_zrt_tests?: Maybe<Zrt_Tests_Mutation_Response>;
  /** delete single row from the table: "zrt_tests" */
  delete_zrt_tests_by_pk?: Maybe<Zrt_Tests>;
  /** disable Mfa */
  disableMfa?: Maybe<DisableMfaResponse>;
  /** generate Temp Auth Token */
  generateTempAuthToken?: Maybe<GenerateTempAuthTokenInputResponse>;
  /** Get DNA visit secret for loading Iframe */
  getDNAVisitIframeSecret: IframeSecretResponse;
  getDnaTestBySwabCodeIDWithNoErrors: DnaValidTestBySwabCode;
  insertFHIRQuestionnaireResponse: CreateFhirQuestionnaireResponsesOutput;
  /** insert data into the table: "accepted_legal_documents" */
  insert_accepted_legal_documents?: Maybe<Accepted_Legal_Documents_Mutation_Response>;
  /** insert a single row into the table: "accepted_legal_documents" */
  insert_accepted_legal_documents_one?: Maybe<Accepted_Legal_Documents>;
  /** insert data into the table: "acuity_provider_calendar" */
  insert_acuity_provider_calendar?: Maybe<Acuity_Provider_Calendar_Mutation_Response>;
  /** insert a single row into the table: "acuity_provider_calendar" */
  insert_acuity_provider_calendar_one?: Maybe<Acuity_Provider_Calendar>;
  /** insert data into the table: "addresses" */
  insert_addresses?: Maybe<Addresses_Mutation_Response>;
  /** insert a single row into the table: "addresses" */
  insert_addresses_one?: Maybe<Addresses>;
  /** insert data into the table: "channel_signals" */
  insert_channel_signals?: Maybe<Channel_Signals_Mutation_Response>;
  /** insert a single row into the table: "channel_signals" */
  insert_channel_signals_one?: Maybe<Channel_Signals>;
  /** insert data into the table: "common_question_responses" */
  insert_common_question_responses?: Maybe<Common_Question_Responses_Mutation_Response>;
  /** insert a single row into the table: "common_question_responses" */
  insert_common_question_responses_one?: Maybe<Common_Question_Responses>;
  /** insert data into the table: "common_questions" */
  insert_common_questions?: Maybe<Common_Questions_Mutation_Response>;
  /** insert a single row into the table: "common_questions" */
  insert_common_questions_one?: Maybe<Common_Questions>;
  /** insert data into the table: "communication_channels" */
  insert_communication_channels?: Maybe<Communication_Channels_Mutation_Response>;
  /** insert a single row into the table: "communication_channels" */
  insert_communication_channels_one?: Maybe<Communication_Channels>;
  /** insert data into the table: "countries" */
  insert_countries?: Maybe<Countries_Mutation_Response>;
  /** insert a single row into the table: "countries" */
  insert_countries_one?: Maybe<Countries>;
  /** insert data into the table: "custom_auth_code" */
  insert_custom_auth_code?: Maybe<Custom_Auth_Code_Mutation_Response>;
  /** insert a single row into the table: "custom_auth_code" */
  insert_custom_auth_code_one?: Maybe<Custom_Auth_Code>;
  /** insert data into the table: "dnavisit_test_statuses" */
  insert_dnavisit_test_statuses?: Maybe<Dnavisit_Test_Statuses_Mutation_Response>;
  /** insert a single row into the table: "dnavisit_test_statuses" */
  insert_dnavisit_test_statuses_one?: Maybe<Dnavisit_Test_Statuses>;
  /** insert data into the table: "dnavisit_tests" */
  insert_dnavisit_tests?: Maybe<Dnavisit_Tests_Mutation_Response>;
  /** insert a single row into the table: "dnavisit_tests" */
  insert_dnavisit_tests_one?: Maybe<Dnavisit_Tests>;
  /** insert data into the table: "do_not_use_production_swab_codes" */
  insert_do_not_use_production_swab_codes?: Maybe<Do_Not_Use_Production_Swab_Codes_Mutation_Response>;
  /** insert a single row into the table: "do_not_use_production_swab_codes" */
  insert_do_not_use_production_swab_codes_one?: Maybe<Do_Not_Use_Production_Swab_Codes>;
  /** insert data into the table: "dosespot_clinicians" */
  insert_dosespot_clinicians?: Maybe<Dosespot_Clinicians_Mutation_Response>;
  /** insert a single row into the table: "dosespot_clinicians" */
  insert_dosespot_clinicians_one?: Maybe<Dosespot_Clinicians>;
  /** insert data into the table: "file_types" */
  insert_file_types?: Maybe<File_Types_Mutation_Response>;
  /** insert a single row into the table: "file_types" */
  insert_file_types_one?: Maybe<File_Types>;
  /** insert data into the table: "files" */
  insert_files?: Maybe<Files_Mutation_Response>;
  /** insert a single row into the table: "files" */
  insert_files_one?: Maybe<Files>;
  /** insert data into the table: "fullscript_clinicians" */
  insert_fullscript_clinicians?: Maybe<Fullscript_Clinicians_Mutation_Response>;
  /** insert a single row into the table: "fullscript_clinicians" */
  insert_fullscript_clinicians_one?: Maybe<Fullscript_Clinicians>;
  /** insert data into the table: "global_configs" */
  insert_global_configs?: Maybe<Global_Configs_Mutation_Response>;
  /** insert data into the table: "global_configs_names" */
  insert_global_configs_names?: Maybe<Global_Configs_Names_Mutation_Response>;
  /** insert a single row into the table: "global_configs_names" */
  insert_global_configs_names_one?: Maybe<Global_Configs_Names>;
  /** insert a single row into the table: "global_configs" */
  insert_global_configs_one?: Maybe<Global_Configs>;
  /** insert data into the table: "image_source_types" */
  insert_image_source_types?: Maybe<Image_Source_Types_Mutation_Response>;
  /** insert a single row into the table: "image_source_types" */
  insert_image_source_types_one?: Maybe<Image_Source_Types>;
  /** insert data into the table: "labs" */
  insert_labs?: Maybe<Labs_Mutation_Response>;
  /** insert a single row into the table: "labs" */
  insert_labs_one?: Maybe<Labs>;
  /** insert data into the table: "legal_document_types" */
  insert_legal_document_types?: Maybe<Legal_Document_Types_Mutation_Response>;
  /** insert a single row into the table: "legal_document_types" */
  insert_legal_document_types_one?: Maybe<Legal_Document_Types>;
  /** insert data into the table: "legal_documents" */
  insert_legal_documents?: Maybe<Legal_Documents_Mutation_Response>;
  /** insert a single row into the table: "legal_documents" */
  insert_legal_documents_one?: Maybe<Legal_Documents>;
  /** insert data into the table: "medical_schools" */
  insert_medical_schools?: Maybe<Medical_Schools_Mutation_Response>;
  /** insert a single row into the table: "medical_schools" */
  insert_medical_schools_one?: Maybe<Medical_Schools>;
  /** insert data into the table: "nabla_notes" */
  insert_nabla_notes?: Maybe<Nabla_Notes_Mutation_Response>;
  /** insert a single row into the table: "nabla_notes" */
  insert_nabla_notes_one?: Maybe<Nabla_Notes>;
  /** insert data into the table: "npi_registry_data" */
  insert_npi_registry_data?: Maybe<Npi_Registry_Data_Mutation_Response>;
  /** insert a single row into the table: "npi_registry_data" */
  insert_npi_registry_data_one?: Maybe<Npi_Registry_Data>;
  /** insert data into the table: "persona_inquiries" */
  insert_persona_inquiries?: Maybe<Persona_Inquiries_Mutation_Response>;
  /** insert a single row into the table: "persona_inquiries" */
  insert_persona_inquiries_one?: Maybe<Persona_Inquiries>;
  /** insert data into the table: "persona_inquiry_status" */
  insert_persona_inquiry_status?: Maybe<Persona_Inquiry_Status_Mutation_Response>;
  /** insert a single row into the table: "persona_inquiry_status" */
  insert_persona_inquiry_status_one?: Maybe<Persona_Inquiry_Status>;
  /** insert data into the table: "provider_unread_external_notification_counts" */
  insert_provider_unread_external_notification_counts?: Maybe<Provider_Unread_External_Notification_Counts_Mutation_Response>;
  /** insert a single row into the table: "provider_unread_external_notification_counts" */
  insert_provider_unread_external_notification_counts_one?: Maybe<Provider_Unread_External_Notification_Counts>;
  /** insert data into the table: "question_measurement_units" */
  insert_question_measurement_units?: Maybe<Question_Measurement_Units_Mutation_Response>;
  /** insert a single row into the table: "question_measurement_units" */
  insert_question_measurement_units_one?: Maybe<Question_Measurement_Units>;
  /** insert data into the table: "question_types" */
  insert_question_types?: Maybe<Question_Types_Mutation_Response>;
  /** insert a single row into the table: "question_types" */
  insert_question_types_one?: Maybe<Question_Types>;
  /** insert data into the table: "question_ui_control_helper_text_position" */
  insert_question_ui_control_helper_text_position?: Maybe<Question_Ui_Control_Helper_Text_Position_Mutation_Response>;
  /** insert a single row into the table: "question_ui_control_helper_text_position" */
  insert_question_ui_control_helper_text_position_one?: Maybe<Question_Ui_Control_Helper_Text_Position>;
  /** insert data into the table: "question_ui_control_types" */
  insert_question_ui_control_types?: Maybe<Question_Ui_Control_Types_Mutation_Response>;
  /** insert a single row into the table: "question_ui_control_types" */
  insert_question_ui_control_types_one?: Maybe<Question_Ui_Control_Types>;
  /** insert data into the table: "questionnaire_questions" */
  insert_questionnaire_questions?: Maybe<Questionnaire_Questions_Mutation_Response>;
  /** insert a single row into the table: "questionnaire_questions" */
  insert_questionnaire_questions_one?: Maybe<Questionnaire_Questions>;
  /** insert data into the table: "questionnaire_responses" */
  insert_questionnaire_responses?: Maybe<Questionnaire_Responses_Mutation_Response>;
  /** insert a single row into the table: "questionnaire_responses" */
  insert_questionnaire_responses_one?: Maybe<Questionnaire_Responses>;
  /** insert data into the table: "questionnaires" */
  insert_questionnaires?: Maybe<Questionnaires_Mutation_Response>;
  /** insert a single row into the table: "questionnaires" */
  insert_questionnaires_one?: Maybe<Questionnaires>;
  /** insert data into the table: "questions" */
  insert_questions?: Maybe<Questions_Mutation_Response>;
  /** insert a single row into the table: "questions" */
  insert_questions_one?: Maybe<Questions>;
  /** insert data into the table: "role_types" */
  insert_role_types?: Maybe<Role_Types_Mutation_Response>;
  /** insert a single row into the table: "role_types" */
  insert_role_types_one?: Maybe<Role_Types>;
  /** insert data into the table: "roles" */
  insert_roles?: Maybe<Roles_Mutation_Response>;
  /** insert a single row into the table: "roles" */
  insert_roles_one?: Maybe<Roles>;
  /** insert data into the table: "skintelligent_tests" */
  insert_skintelligent_tests?: Maybe<Skintelligent_Tests_Mutation_Response>;
  /** insert a single row into the table: "skintelligent_tests" */
  insert_skintelligent_tests_one?: Maybe<Skintelligent_Tests>;
  /** insert data into the table: "swab_code_statuses" */
  insert_swab_code_statuses?: Maybe<Swab_Code_Statuses_Mutation_Response>;
  /** insert a single row into the table: "swab_code_statuses" */
  insert_swab_code_statuses_one?: Maybe<Swab_Code_Statuses>;
  /** insert data into the table: "swab_codes" */
  insert_swab_codes?: Maybe<Swab_Codes_Mutation_Response>;
  /** insert a single row into the table: "swab_codes" */
  insert_swab_codes_one?: Maybe<Swab_Codes>;
  /** insert data into the table: "swab_usage_type" */
  insert_swab_usage_type?: Maybe<Swab_Usage_Type_Mutation_Response>;
  /** insert a single row into the table: "swab_usage_type" */
  insert_swab_usage_type_one?: Maybe<Swab_Usage_Type>;
  /** insert data into the table: "test_definitions" */
  insert_test_definitions?: Maybe<Test_Definitions_Mutation_Response>;
  /** insert a single row into the table: "test_definitions" */
  insert_test_definitions_one?: Maybe<Test_Definitions>;
  /** insert data into the table: "test_kits_update_states_summary_log" */
  insert_test_kits_update_states_summary_log?: Maybe<Test_Kits_Update_States_Summary_Log_Mutation_Response>;
  /** insert a single row into the table: "test_kits_update_states_summary_log" */
  insert_test_kits_update_states_summary_log_one?: Maybe<Test_Kits_Update_States_Summary_Log>;
  /** insert data into the table: "test_types" */
  insert_test_types?: Maybe<Test_Types_Mutation_Response>;
  /** insert a single row into the table: "test_types" */
  insert_test_types_one?: Maybe<Test_Types>;
  /** insert data into the table: "tests" */
  insert_tests?: Maybe<Tests_Mutation_Response>;
  /** insert a single row into the table: "tests" */
  insert_tests_one?: Maybe<Tests>;
  /** insert data into the table: "text_addresses" */
  insert_text_addresses?: Maybe<Text_Addresses_Mutation_Response>;
  /** insert a single row into the table: "text_addresses" */
  insert_text_addresses_one?: Maybe<Text_Addresses>;
  /** insert data into the table: "unauthenticated_questionnaire_questions" */
  insert_unauthenticated_questionnaire_questions?: Maybe<Unauthenticated_Questionnaire_Questions_Mutation_Response>;
  /** insert a single row into the table: "unauthenticated_questionnaire_questions" */
  insert_unauthenticated_questionnaire_questions_one?: Maybe<Unauthenticated_Questionnaire_Questions>;
  /** insert data into the table: "unauthenticated_questionnaires" */
  insert_unauthenticated_questionnaires?: Maybe<Unauthenticated_Questionnaires_Mutation_Response>;
  /** insert a single row into the table: "unauthenticated_questionnaires" */
  insert_unauthenticated_questionnaires_one?: Maybe<Unauthenticated_Questionnaires>;
  /** insert data into the table: "unauthenticated_questions" */
  insert_unauthenticated_questions?: Maybe<Unauthenticated_Questions_Mutation_Response>;
  /** insert a single row into the table: "unauthenticated_questions" */
  insert_unauthenticated_questions_one?: Maybe<Unauthenticated_Questions>;
  /** insert data into the table: "universities" */
  insert_universities?: Maybe<Universities_Mutation_Response>;
  /** insert a single row into the table: "universities" */
  insert_universities_one?: Maybe<Universities>;
  /** insert data into the table: "user_addresses" */
  insert_user_addresses?: Maybe<User_Addresses_Mutation_Response>;
  /** insert a single row into the table: "user_addresses" */
  insert_user_addresses_one?: Maybe<User_Addresses>;
  /** insert data into the table: "user_auth_provider_types" */
  insert_user_auth_provider_types?: Maybe<User_Auth_Provider_Types_Mutation_Response>;
  /** insert a single row into the table: "user_auth_provider_types" */
  insert_user_auth_provider_types_one?: Maybe<User_Auth_Provider_Types>;
  /** insert data into the table: "user_auth_providers" */
  insert_user_auth_providers?: Maybe<User_Auth_Providers_Mutation_Response>;
  /** insert a single row into the table: "user_auth_providers" */
  insert_user_auth_providers_one?: Maybe<User_Auth_Providers>;
  /** insert data into the table: "user_auth_tokens" */
  insert_user_auth_tokens?: Maybe<User_Auth_Tokens_Mutation_Response>;
  /** insert a single row into the table: "user_auth_tokens" */
  insert_user_auth_tokens_one?: Maybe<User_Auth_Tokens>;
  /** insert data into the table: "user_communication_deliveries" */
  insert_user_communication_deliveries?: Maybe<User_Communication_Deliveries_Mutation_Response>;
  /** insert a single row into the table: "user_communication_deliveries" */
  insert_user_communication_deliveries_one?: Maybe<User_Communication_Deliveries>;
  /** insert data into the table: "user_communications" */
  insert_user_communications?: Maybe<User_Communications_Mutation_Response>;
  /** insert a single row into the table: "user_communications" */
  insert_user_communications_one?: Maybe<User_Communications>;
  /** insert data into the table: "user_notification_status" */
  insert_user_notification_status?: Maybe<User_Notification_Status_Mutation_Response>;
  /** insert a single row into the table: "user_notification_status" */
  insert_user_notification_status_one?: Maybe<User_Notification_Status>;
  /** insert data into the table: "user_notifications" */
  insert_user_notifications?: Maybe<User_Notifications_Mutation_Response>;
  /** insert a single row into the table: "user_notifications" */
  insert_user_notifications_one?: Maybe<User_Notifications>;
  /** insert data into the table: "user_roles" */
  insert_user_roles?: Maybe<User_Roles_Mutation_Response>;
  /** insert a single row into the table: "user_roles" */
  insert_user_roles_one?: Maybe<User_Roles>;
  /** insert data into the table: "user_scanned_data" */
  insert_user_scanned_data?: Maybe<User_Scanned_Data_Mutation_Response>;
  /** insert a single row into the table: "user_scanned_data" */
  insert_user_scanned_data_one?: Maybe<User_Scanned_Data>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** insert data into the table: "vonage_appointment" */
  insert_vonage_appointment?: Maybe<Vonage_Appointment_Mutation_Response>;
  /** insert a single row into the table: "vonage_appointment" */
  insert_vonage_appointment_one?: Maybe<Vonage_Appointment>;
  /** insert data into the table: "zrt_tests" */
  insert_zrt_tests?: Maybe<Zrt_Tests_Mutation_Response>;
  /** insert a single row into the table: "zrt_tests" */
  insert_zrt_tests_one?: Maybe<Zrt_Tests>;
  linkProviderAccount: FullscriptOperationResult;
  onTreatmentPlanActivated: FullscriptOperationResult;
  patchCodexApproval: PatchFhirProviderOutput;
  patchFHIRAppointment: PatchFhirAppointmentOutput;
  patchFHIRDnaVisitTest: PatchFhirDnaVisitTestOutput;
  patchFHIREncounter: PatchFhirEncounterOutput;
  patchFHIRPatientPersonalData: PatchFhirPatientOutput;
  patchFHIRProvider: PatchFhirProviderOutput;
  patchFHIRSkintelligentTest: PatchFhirSkintelligentTestOutput;
  patchFHIRZrtTest: PatchFhirZrtTestOutput;
  patchServiceOffering: PatchFhirProviderOutput;
  requestZrtTest: RequestZrtTestOutput;
  saPatchFHIRProvider: PatchFhirProviderOutput;
  saveFHIRQuestionnaireManually: SaveFhirQuestionnaireManually;
  seedFHIROrganizations: SeedFhirOrganizationsOutput;
  unlinkFullscript: PatchFhirProviderOutput;
  updateBecomePatientRequestStatus: PatchFhirRequestBecomePatientOutput;
  updateFHIRUserLatestQuestionnaire: UpdateFhirUserLatestQuestionnaire;
  /** update data of the table: "accepted_legal_documents" */
  update_accepted_legal_documents?: Maybe<Accepted_Legal_Documents_Mutation_Response>;
  /** update single row of the table: "accepted_legal_documents" */
  update_accepted_legal_documents_by_pk?: Maybe<Accepted_Legal_Documents>;
  /** update multiples rows of table: "accepted_legal_documents" */
  update_accepted_legal_documents_many?: Maybe<Array<Maybe<Accepted_Legal_Documents_Mutation_Response>>>;
  /** update data of the table: "acuity_provider_calendar" */
  update_acuity_provider_calendar?: Maybe<Acuity_Provider_Calendar_Mutation_Response>;
  /** update single row of the table: "acuity_provider_calendar" */
  update_acuity_provider_calendar_by_pk?: Maybe<Acuity_Provider_Calendar>;
  /** update multiples rows of table: "acuity_provider_calendar" */
  update_acuity_provider_calendar_many?: Maybe<Array<Maybe<Acuity_Provider_Calendar_Mutation_Response>>>;
  /** update data of the table: "addresses" */
  update_addresses?: Maybe<Addresses_Mutation_Response>;
  /** update single row of the table: "addresses" */
  update_addresses_by_pk?: Maybe<Addresses>;
  /** update multiples rows of table: "addresses" */
  update_addresses_many?: Maybe<Array<Maybe<Addresses_Mutation_Response>>>;
  /** update data of the table: "channel_signals" */
  update_channel_signals?: Maybe<Channel_Signals_Mutation_Response>;
  /** update single row of the table: "channel_signals" */
  update_channel_signals_by_pk?: Maybe<Channel_Signals>;
  /** update multiples rows of table: "channel_signals" */
  update_channel_signals_many?: Maybe<Array<Maybe<Channel_Signals_Mutation_Response>>>;
  /** update data of the table: "common_question_responses" */
  update_common_question_responses?: Maybe<Common_Question_Responses_Mutation_Response>;
  /** update single row of the table: "common_question_responses" */
  update_common_question_responses_by_pk?: Maybe<Common_Question_Responses>;
  /** update multiples rows of table: "common_question_responses" */
  update_common_question_responses_many?: Maybe<Array<Maybe<Common_Question_Responses_Mutation_Response>>>;
  /** update data of the table: "common_questions" */
  update_common_questions?: Maybe<Common_Questions_Mutation_Response>;
  /** update single row of the table: "common_questions" */
  update_common_questions_by_pk?: Maybe<Common_Questions>;
  /** update multiples rows of table: "common_questions" */
  update_common_questions_many?: Maybe<Array<Maybe<Common_Questions_Mutation_Response>>>;
  /** update data of the table: "communication_channels" */
  update_communication_channels?: Maybe<Communication_Channels_Mutation_Response>;
  /** update single row of the table: "communication_channels" */
  update_communication_channels_by_pk?: Maybe<Communication_Channels>;
  /** update multiples rows of table: "communication_channels" */
  update_communication_channels_many?: Maybe<Array<Maybe<Communication_Channels_Mutation_Response>>>;
  /** update data of the table: "countries" */
  update_countries?: Maybe<Countries_Mutation_Response>;
  /** update single row of the table: "countries" */
  update_countries_by_pk?: Maybe<Countries>;
  /** update multiples rows of table: "countries" */
  update_countries_many?: Maybe<Array<Maybe<Countries_Mutation_Response>>>;
  /** update data of the table: "custom_auth_code" */
  update_custom_auth_code?: Maybe<Custom_Auth_Code_Mutation_Response>;
  /** update single row of the table: "custom_auth_code" */
  update_custom_auth_code_by_pk?: Maybe<Custom_Auth_Code>;
  /** update multiples rows of table: "custom_auth_code" */
  update_custom_auth_code_many?: Maybe<Array<Maybe<Custom_Auth_Code_Mutation_Response>>>;
  /** update data of the table: "dnavisit_test_statuses" */
  update_dnavisit_test_statuses?: Maybe<Dnavisit_Test_Statuses_Mutation_Response>;
  /** update single row of the table: "dnavisit_test_statuses" */
  update_dnavisit_test_statuses_by_pk?: Maybe<Dnavisit_Test_Statuses>;
  /** update multiples rows of table: "dnavisit_test_statuses" */
  update_dnavisit_test_statuses_many?: Maybe<Array<Maybe<Dnavisit_Test_Statuses_Mutation_Response>>>;
  /** update data of the table: "dnavisit_tests" */
  update_dnavisit_tests?: Maybe<Dnavisit_Tests_Mutation_Response>;
  /** update single row of the table: "dnavisit_tests" */
  update_dnavisit_tests_by_pk?: Maybe<Dnavisit_Tests>;
  /** update multiples rows of table: "dnavisit_tests" */
  update_dnavisit_tests_many?: Maybe<Array<Maybe<Dnavisit_Tests_Mutation_Response>>>;
  /** update data of the table: "do_not_use_production_swab_codes" */
  update_do_not_use_production_swab_codes?: Maybe<Do_Not_Use_Production_Swab_Codes_Mutation_Response>;
  /** update single row of the table: "do_not_use_production_swab_codes" */
  update_do_not_use_production_swab_codes_by_pk?: Maybe<Do_Not_Use_Production_Swab_Codes>;
  /** update multiples rows of table: "do_not_use_production_swab_codes" */
  update_do_not_use_production_swab_codes_many?: Maybe<Array<Maybe<Do_Not_Use_Production_Swab_Codes_Mutation_Response>>>;
  /** update data of the table: "dosespot_clinicians" */
  update_dosespot_clinicians?: Maybe<Dosespot_Clinicians_Mutation_Response>;
  /** update single row of the table: "dosespot_clinicians" */
  update_dosespot_clinicians_by_pk?: Maybe<Dosespot_Clinicians>;
  /** update multiples rows of table: "dosespot_clinicians" */
  update_dosespot_clinicians_many?: Maybe<Array<Maybe<Dosespot_Clinicians_Mutation_Response>>>;
  /** update data of the table: "file_types" */
  update_file_types?: Maybe<File_Types_Mutation_Response>;
  /** update single row of the table: "file_types" */
  update_file_types_by_pk?: Maybe<File_Types>;
  /** update multiples rows of table: "file_types" */
  update_file_types_many?: Maybe<Array<Maybe<File_Types_Mutation_Response>>>;
  /** update data of the table: "files" */
  update_files?: Maybe<Files_Mutation_Response>;
  /** update single row of the table: "files" */
  update_files_by_pk?: Maybe<Files>;
  /** update multiples rows of table: "files" */
  update_files_many?: Maybe<Array<Maybe<Files_Mutation_Response>>>;
  /** update data of the table: "fullscript_clinicians" */
  update_fullscript_clinicians?: Maybe<Fullscript_Clinicians_Mutation_Response>;
  /** update single row of the table: "fullscript_clinicians" */
  update_fullscript_clinicians_by_pk?: Maybe<Fullscript_Clinicians>;
  /** update multiples rows of table: "fullscript_clinicians" */
  update_fullscript_clinicians_many?: Maybe<Array<Maybe<Fullscript_Clinicians_Mutation_Response>>>;
  /** update data of the table: "global_configs" */
  update_global_configs?: Maybe<Global_Configs_Mutation_Response>;
  /** update single row of the table: "global_configs" */
  update_global_configs_by_pk?: Maybe<Global_Configs>;
  /** update multiples rows of table: "global_configs" */
  update_global_configs_many?: Maybe<Array<Maybe<Global_Configs_Mutation_Response>>>;
  /** update data of the table: "global_configs_names" */
  update_global_configs_names?: Maybe<Global_Configs_Names_Mutation_Response>;
  /** update single row of the table: "global_configs_names" */
  update_global_configs_names_by_pk?: Maybe<Global_Configs_Names>;
  /** update multiples rows of table: "global_configs_names" */
  update_global_configs_names_many?: Maybe<Array<Maybe<Global_Configs_Names_Mutation_Response>>>;
  /** update data of the table: "image_source_types" */
  update_image_source_types?: Maybe<Image_Source_Types_Mutation_Response>;
  /** update single row of the table: "image_source_types" */
  update_image_source_types_by_pk?: Maybe<Image_Source_Types>;
  /** update multiples rows of table: "image_source_types" */
  update_image_source_types_many?: Maybe<Array<Maybe<Image_Source_Types_Mutation_Response>>>;
  /** update data of the table: "labs" */
  update_labs?: Maybe<Labs_Mutation_Response>;
  /** update single row of the table: "labs" */
  update_labs_by_pk?: Maybe<Labs>;
  /** update multiples rows of table: "labs" */
  update_labs_many?: Maybe<Array<Maybe<Labs_Mutation_Response>>>;
  /** update data of the table: "legal_document_types" */
  update_legal_document_types?: Maybe<Legal_Document_Types_Mutation_Response>;
  /** update single row of the table: "legal_document_types" */
  update_legal_document_types_by_pk?: Maybe<Legal_Document_Types>;
  /** update multiples rows of table: "legal_document_types" */
  update_legal_document_types_many?: Maybe<Array<Maybe<Legal_Document_Types_Mutation_Response>>>;
  /** update data of the table: "legal_documents" */
  update_legal_documents?: Maybe<Legal_Documents_Mutation_Response>;
  /** update single row of the table: "legal_documents" */
  update_legal_documents_by_pk?: Maybe<Legal_Documents>;
  /** update multiples rows of table: "legal_documents" */
  update_legal_documents_many?: Maybe<Array<Maybe<Legal_Documents_Mutation_Response>>>;
  /** update data of the table: "medical_schools" */
  update_medical_schools?: Maybe<Medical_Schools_Mutation_Response>;
  /** update single row of the table: "medical_schools" */
  update_medical_schools_by_pk?: Maybe<Medical_Schools>;
  /** update multiples rows of table: "medical_schools" */
  update_medical_schools_many?: Maybe<Array<Maybe<Medical_Schools_Mutation_Response>>>;
  /** update data of the table: "nabla_notes" */
  update_nabla_notes?: Maybe<Nabla_Notes_Mutation_Response>;
  /** update single row of the table: "nabla_notes" */
  update_nabla_notes_by_pk?: Maybe<Nabla_Notes>;
  /** update multiples rows of table: "nabla_notes" */
  update_nabla_notes_many?: Maybe<Array<Maybe<Nabla_Notes_Mutation_Response>>>;
  /** update data of the table: "npi_registry_data" */
  update_npi_registry_data?: Maybe<Npi_Registry_Data_Mutation_Response>;
  /** update single row of the table: "npi_registry_data" */
  update_npi_registry_data_by_pk?: Maybe<Npi_Registry_Data>;
  /** update multiples rows of table: "npi_registry_data" */
  update_npi_registry_data_many?: Maybe<Array<Maybe<Npi_Registry_Data_Mutation_Response>>>;
  /** update data of the table: "persona_inquiries" */
  update_persona_inquiries?: Maybe<Persona_Inquiries_Mutation_Response>;
  /** update single row of the table: "persona_inquiries" */
  update_persona_inquiries_by_pk?: Maybe<Persona_Inquiries>;
  /** update multiples rows of table: "persona_inquiries" */
  update_persona_inquiries_many?: Maybe<Array<Maybe<Persona_Inquiries_Mutation_Response>>>;
  /** update data of the table: "persona_inquiry_status" */
  update_persona_inquiry_status?: Maybe<Persona_Inquiry_Status_Mutation_Response>;
  /** update single row of the table: "persona_inquiry_status" */
  update_persona_inquiry_status_by_pk?: Maybe<Persona_Inquiry_Status>;
  /** update multiples rows of table: "persona_inquiry_status" */
  update_persona_inquiry_status_many?: Maybe<Array<Maybe<Persona_Inquiry_Status_Mutation_Response>>>;
  /** update data of the table: "provider_unread_external_notification_counts" */
  update_provider_unread_external_notification_counts?: Maybe<Provider_Unread_External_Notification_Counts_Mutation_Response>;
  /** update single row of the table: "provider_unread_external_notification_counts" */
  update_provider_unread_external_notification_counts_by_pk?: Maybe<Provider_Unread_External_Notification_Counts>;
  /** update multiples rows of table: "provider_unread_external_notification_counts" */
  update_provider_unread_external_notification_counts_many?: Maybe<Array<Maybe<Provider_Unread_External_Notification_Counts_Mutation_Response>>>;
  /** update data of the table: "question_measurement_units" */
  update_question_measurement_units?: Maybe<Question_Measurement_Units_Mutation_Response>;
  /** update single row of the table: "question_measurement_units" */
  update_question_measurement_units_by_pk?: Maybe<Question_Measurement_Units>;
  /** update multiples rows of table: "question_measurement_units" */
  update_question_measurement_units_many?: Maybe<Array<Maybe<Question_Measurement_Units_Mutation_Response>>>;
  /** update data of the table: "question_types" */
  update_question_types?: Maybe<Question_Types_Mutation_Response>;
  /** update single row of the table: "question_types" */
  update_question_types_by_pk?: Maybe<Question_Types>;
  /** update multiples rows of table: "question_types" */
  update_question_types_many?: Maybe<Array<Maybe<Question_Types_Mutation_Response>>>;
  /** update data of the table: "question_ui_control_helper_text_position" */
  update_question_ui_control_helper_text_position?: Maybe<Question_Ui_Control_Helper_Text_Position_Mutation_Response>;
  /** update single row of the table: "question_ui_control_helper_text_position" */
  update_question_ui_control_helper_text_position_by_pk?: Maybe<Question_Ui_Control_Helper_Text_Position>;
  /** update multiples rows of table: "question_ui_control_helper_text_position" */
  update_question_ui_control_helper_text_position_many?: Maybe<Array<Maybe<Question_Ui_Control_Helper_Text_Position_Mutation_Response>>>;
  /** update data of the table: "question_ui_control_types" */
  update_question_ui_control_types?: Maybe<Question_Ui_Control_Types_Mutation_Response>;
  /** update single row of the table: "question_ui_control_types" */
  update_question_ui_control_types_by_pk?: Maybe<Question_Ui_Control_Types>;
  /** update multiples rows of table: "question_ui_control_types" */
  update_question_ui_control_types_many?: Maybe<Array<Maybe<Question_Ui_Control_Types_Mutation_Response>>>;
  /** update data of the table: "questionnaire_questions" */
  update_questionnaire_questions?: Maybe<Questionnaire_Questions_Mutation_Response>;
  /** update single row of the table: "questionnaire_questions" */
  update_questionnaire_questions_by_pk?: Maybe<Questionnaire_Questions>;
  /** update multiples rows of table: "questionnaire_questions" */
  update_questionnaire_questions_many?: Maybe<Array<Maybe<Questionnaire_Questions_Mutation_Response>>>;
  /** update data of the table: "questionnaire_responses" */
  update_questionnaire_responses?: Maybe<Questionnaire_Responses_Mutation_Response>;
  /** update single row of the table: "questionnaire_responses" */
  update_questionnaire_responses_by_pk?: Maybe<Questionnaire_Responses>;
  /** update multiples rows of table: "questionnaire_responses" */
  update_questionnaire_responses_many?: Maybe<Array<Maybe<Questionnaire_Responses_Mutation_Response>>>;
  /** update data of the table: "questionnaires" */
  update_questionnaires?: Maybe<Questionnaires_Mutation_Response>;
  /** update single row of the table: "questionnaires" */
  update_questionnaires_by_pk?: Maybe<Questionnaires>;
  /** update multiples rows of table: "questionnaires" */
  update_questionnaires_many?: Maybe<Array<Maybe<Questionnaires_Mutation_Response>>>;
  /** update data of the table: "questions" */
  update_questions?: Maybe<Questions_Mutation_Response>;
  /** update single row of the table: "questions" */
  update_questions_by_pk?: Maybe<Questions>;
  /** update multiples rows of table: "questions" */
  update_questions_many?: Maybe<Array<Maybe<Questions_Mutation_Response>>>;
  /** update data of the table: "role_types" */
  update_role_types?: Maybe<Role_Types_Mutation_Response>;
  /** update single row of the table: "role_types" */
  update_role_types_by_pk?: Maybe<Role_Types>;
  /** update multiples rows of table: "role_types" */
  update_role_types_many?: Maybe<Array<Maybe<Role_Types_Mutation_Response>>>;
  /** update data of the table: "roles" */
  update_roles?: Maybe<Roles_Mutation_Response>;
  /** update single row of the table: "roles" */
  update_roles_by_pk?: Maybe<Roles>;
  /** update multiples rows of table: "roles" */
  update_roles_many?: Maybe<Array<Maybe<Roles_Mutation_Response>>>;
  /** update data of the table: "skintelligent_tests" */
  update_skintelligent_tests?: Maybe<Skintelligent_Tests_Mutation_Response>;
  /** update single row of the table: "skintelligent_tests" */
  update_skintelligent_tests_by_pk?: Maybe<Skintelligent_Tests>;
  /** update multiples rows of table: "skintelligent_tests" */
  update_skintelligent_tests_many?: Maybe<Array<Maybe<Skintelligent_Tests_Mutation_Response>>>;
  /** update data of the table: "swab_code_statuses" */
  update_swab_code_statuses?: Maybe<Swab_Code_Statuses_Mutation_Response>;
  /** update single row of the table: "swab_code_statuses" */
  update_swab_code_statuses_by_pk?: Maybe<Swab_Code_Statuses>;
  /** update multiples rows of table: "swab_code_statuses" */
  update_swab_code_statuses_many?: Maybe<Array<Maybe<Swab_Code_Statuses_Mutation_Response>>>;
  /** update data of the table: "swab_codes" */
  update_swab_codes?: Maybe<Swab_Codes_Mutation_Response>;
  /** update single row of the table: "swab_codes" */
  update_swab_codes_by_pk?: Maybe<Swab_Codes>;
  /** update multiples rows of table: "swab_codes" */
  update_swab_codes_many?: Maybe<Array<Maybe<Swab_Codes_Mutation_Response>>>;
  /** update data of the table: "swab_usage_type" */
  update_swab_usage_type?: Maybe<Swab_Usage_Type_Mutation_Response>;
  /** update single row of the table: "swab_usage_type" */
  update_swab_usage_type_by_pk?: Maybe<Swab_Usage_Type>;
  /** update multiples rows of table: "swab_usage_type" */
  update_swab_usage_type_many?: Maybe<Array<Maybe<Swab_Usage_Type_Mutation_Response>>>;
  /** update data of the table: "test_definitions" */
  update_test_definitions?: Maybe<Test_Definitions_Mutation_Response>;
  /** update single row of the table: "test_definitions" */
  update_test_definitions_by_pk?: Maybe<Test_Definitions>;
  /** update multiples rows of table: "test_definitions" */
  update_test_definitions_many?: Maybe<Array<Maybe<Test_Definitions_Mutation_Response>>>;
  /** update data of the table: "test_kits_update_states_summary_log" */
  update_test_kits_update_states_summary_log?: Maybe<Test_Kits_Update_States_Summary_Log_Mutation_Response>;
  /** update single row of the table: "test_kits_update_states_summary_log" */
  update_test_kits_update_states_summary_log_by_pk?: Maybe<Test_Kits_Update_States_Summary_Log>;
  /** update multiples rows of table: "test_kits_update_states_summary_log" */
  update_test_kits_update_states_summary_log_many?: Maybe<Array<Maybe<Test_Kits_Update_States_Summary_Log_Mutation_Response>>>;
  /** update data of the table: "test_types" */
  update_test_types?: Maybe<Test_Types_Mutation_Response>;
  /** update single row of the table: "test_types" */
  update_test_types_by_pk?: Maybe<Test_Types>;
  /** update multiples rows of table: "test_types" */
  update_test_types_many?: Maybe<Array<Maybe<Test_Types_Mutation_Response>>>;
  /** update data of the table: "tests" */
  update_tests?: Maybe<Tests_Mutation_Response>;
  /** update single row of the table: "tests" */
  update_tests_by_pk?: Maybe<Tests>;
  /** update multiples rows of table: "tests" */
  update_tests_many?: Maybe<Array<Maybe<Tests_Mutation_Response>>>;
  /** update data of the table: "text_addresses" */
  update_text_addresses?: Maybe<Text_Addresses_Mutation_Response>;
  /** update single row of the table: "text_addresses" */
  update_text_addresses_by_pk?: Maybe<Text_Addresses>;
  /** update multiples rows of table: "text_addresses" */
  update_text_addresses_many?: Maybe<Array<Maybe<Text_Addresses_Mutation_Response>>>;
  /** update data of the table: "unauthenticated_questionnaire_questions" */
  update_unauthenticated_questionnaire_questions?: Maybe<Unauthenticated_Questionnaire_Questions_Mutation_Response>;
  /** update single row of the table: "unauthenticated_questionnaire_questions" */
  update_unauthenticated_questionnaire_questions_by_pk?: Maybe<Unauthenticated_Questionnaire_Questions>;
  /** update multiples rows of table: "unauthenticated_questionnaire_questions" */
  update_unauthenticated_questionnaire_questions_many?: Maybe<Array<Maybe<Unauthenticated_Questionnaire_Questions_Mutation_Response>>>;
  /** update data of the table: "unauthenticated_questionnaires" */
  update_unauthenticated_questionnaires?: Maybe<Unauthenticated_Questionnaires_Mutation_Response>;
  /** update single row of the table: "unauthenticated_questionnaires" */
  update_unauthenticated_questionnaires_by_pk?: Maybe<Unauthenticated_Questionnaires>;
  /** update multiples rows of table: "unauthenticated_questionnaires" */
  update_unauthenticated_questionnaires_many?: Maybe<Array<Maybe<Unauthenticated_Questionnaires_Mutation_Response>>>;
  /** update data of the table: "unauthenticated_questions" */
  update_unauthenticated_questions?: Maybe<Unauthenticated_Questions_Mutation_Response>;
  /** update single row of the table: "unauthenticated_questions" */
  update_unauthenticated_questions_by_pk?: Maybe<Unauthenticated_Questions>;
  /** update multiples rows of table: "unauthenticated_questions" */
  update_unauthenticated_questions_many?: Maybe<Array<Maybe<Unauthenticated_Questions_Mutation_Response>>>;
  /** update data of the table: "universities" */
  update_universities?: Maybe<Universities_Mutation_Response>;
  /** update single row of the table: "universities" */
  update_universities_by_pk?: Maybe<Universities>;
  /** update multiples rows of table: "universities" */
  update_universities_many?: Maybe<Array<Maybe<Universities_Mutation_Response>>>;
  /** update data of the table: "user_addresses" */
  update_user_addresses?: Maybe<User_Addresses_Mutation_Response>;
  /** update single row of the table: "user_addresses" */
  update_user_addresses_by_pk?: Maybe<User_Addresses>;
  /** update multiples rows of table: "user_addresses" */
  update_user_addresses_many?: Maybe<Array<Maybe<User_Addresses_Mutation_Response>>>;
  /** update data of the table: "user_auth_provider_types" */
  update_user_auth_provider_types?: Maybe<User_Auth_Provider_Types_Mutation_Response>;
  /** update single row of the table: "user_auth_provider_types" */
  update_user_auth_provider_types_by_pk?: Maybe<User_Auth_Provider_Types>;
  /** update multiples rows of table: "user_auth_provider_types" */
  update_user_auth_provider_types_many?: Maybe<Array<Maybe<User_Auth_Provider_Types_Mutation_Response>>>;
  /** update data of the table: "user_auth_providers" */
  update_user_auth_providers?: Maybe<User_Auth_Providers_Mutation_Response>;
  /** update single row of the table: "user_auth_providers" */
  update_user_auth_providers_by_pk?: Maybe<User_Auth_Providers>;
  /** update multiples rows of table: "user_auth_providers" */
  update_user_auth_providers_many?: Maybe<Array<Maybe<User_Auth_Providers_Mutation_Response>>>;
  /** update data of the table: "user_auth_tokens" */
  update_user_auth_tokens?: Maybe<User_Auth_Tokens_Mutation_Response>;
  /** update single row of the table: "user_auth_tokens" */
  update_user_auth_tokens_by_pk?: Maybe<User_Auth_Tokens>;
  /** update multiples rows of table: "user_auth_tokens" */
  update_user_auth_tokens_many?: Maybe<Array<Maybe<User_Auth_Tokens_Mutation_Response>>>;
  /** update data of the table: "user_communication_deliveries" */
  update_user_communication_deliveries?: Maybe<User_Communication_Deliveries_Mutation_Response>;
  /** update single row of the table: "user_communication_deliveries" */
  update_user_communication_deliveries_by_pk?: Maybe<User_Communication_Deliveries>;
  /** update multiples rows of table: "user_communication_deliveries" */
  update_user_communication_deliveries_many?: Maybe<Array<Maybe<User_Communication_Deliveries_Mutation_Response>>>;
  /** update data of the table: "user_communications" */
  update_user_communications?: Maybe<User_Communications_Mutation_Response>;
  /** update single row of the table: "user_communications" */
  update_user_communications_by_pk?: Maybe<User_Communications>;
  /** update multiples rows of table: "user_communications" */
  update_user_communications_many?: Maybe<Array<Maybe<User_Communications_Mutation_Response>>>;
  /** update data of the table: "user_notification_status" */
  update_user_notification_status?: Maybe<User_Notification_Status_Mutation_Response>;
  /** update single row of the table: "user_notification_status" */
  update_user_notification_status_by_pk?: Maybe<User_Notification_Status>;
  /** update multiples rows of table: "user_notification_status" */
  update_user_notification_status_many?: Maybe<Array<Maybe<User_Notification_Status_Mutation_Response>>>;
  /** update data of the table: "user_notifications" */
  update_user_notifications?: Maybe<User_Notifications_Mutation_Response>;
  /** update single row of the table: "user_notifications" */
  update_user_notifications_by_pk?: Maybe<User_Notifications>;
  /** update multiples rows of table: "user_notifications" */
  update_user_notifications_many?: Maybe<Array<Maybe<User_Notifications_Mutation_Response>>>;
  /** update data of the table: "user_roles" */
  update_user_roles?: Maybe<User_Roles_Mutation_Response>;
  /** update single row of the table: "user_roles" */
  update_user_roles_by_pk?: Maybe<User_Roles>;
  /** update multiples rows of table: "user_roles" */
  update_user_roles_many?: Maybe<Array<Maybe<User_Roles_Mutation_Response>>>;
  /** update data of the table: "user_scanned_data" */
  update_user_scanned_data?: Maybe<User_Scanned_Data_Mutation_Response>;
  /** update single row of the table: "user_scanned_data" */
  update_user_scanned_data_by_pk?: Maybe<User_Scanned_Data>;
  /** update multiples rows of table: "user_scanned_data" */
  update_user_scanned_data_many?: Maybe<Array<Maybe<User_Scanned_Data_Mutation_Response>>>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  /** update multiples rows of table: "users" */
  update_users_many?: Maybe<Array<Maybe<Users_Mutation_Response>>>;
  /** update data of the table: "vonage_appointment" */
  update_vonage_appointment?: Maybe<Vonage_Appointment_Mutation_Response>;
  /** update single row of the table: "vonage_appointment" */
  update_vonage_appointment_by_pk?: Maybe<Vonage_Appointment>;
  /** update multiples rows of table: "vonage_appointment" */
  update_vonage_appointment_many?: Maybe<Array<Maybe<Vonage_Appointment_Mutation_Response>>>;
  /** update data of the table: "zrt_tests" */
  update_zrt_tests?: Maybe<Zrt_Tests_Mutation_Response>;
  /** update single row of the table: "zrt_tests" */
  update_zrt_tests_by_pk?: Maybe<Zrt_Tests>;
  /** update multiples rows of table: "zrt_tests" */
  update_zrt_tests_many?: Maybe<Array<Maybe<Zrt_Tests_Mutation_Response>>>;
  /** verify Firebase Email */
  verifyFirebaseEmail?: Maybe<VerifyFirebaseEmailResponse>;
};


/** mutation root */
export type Mutation_RootAcceptLegalDocumentArgs = {
  legalDocument?: InputMaybe<AcceptLegalDocumentInput>;
};


/** mutation root */
export type Mutation_RootAccountDeletionArgs = {
  account?: InputMaybe<AccountDeletionInput>;
};


/** mutation root */
export type Mutation_RootGenerateFirebaseCustomTokenArgs = {
  token?: InputMaybe<GenerateFirebaseCustomTokenInput>;
};


/** mutation root */
export type Mutation_RootGetAcuityCalendarInformationArgs = {
  providerId?: InputMaybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootGetDoseSpotMedicationsSearchArgs = {
  name?: InputMaybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootGetDoseSpotMedicationsSelectArgs = {
  name?: InputMaybe<Scalars['String']>;
  rxcui: Scalars['Int'];
  strength?: InputMaybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootGetDoseSpotPractitionerUrlArgs = {
  encounterId?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['String']>;
  providerId: Scalars['String'];
  showNotifications: Scalars['Boolean'];
};


/** mutation root */
export type Mutation_RootGetPersonaSessionTokenArgs = {
  inquiryId?: InputMaybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootGetProviderPatientSignedUrlArgs = {
  fileId: Scalars['String'];
  patientCodexId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootGetSignUrlFormStorageArgs = {
  fileId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootGetSignUrlFromStorageByAdminArgs = {
  filePath?: InputMaybe<GetSignUrlFromStorageByAdminInput>;
};


/** mutation root */
export type Mutation_RootGetSignUrlFromStorageForRequesterArgs = {
  fileId: Scalars['String'];
  requestedUserId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootGetVonageArchivesArgs = {
  sessionId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootGetVonageSessionArgs = {
  room: Scalars['String'];
};


/** mutation root */
export type Mutation_RootGetVonageSignUrlFormStorageArgs = {
  appointmentId: Scalars['String'];
  fileId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootRegisterAuthProviderArgs = {
  provider?: InputMaybe<AuthProviderInput>;
};


/** mutation root */
export type Mutation_RootRegisterProviderAcuityCalendarInformationArgs = {
  calendarInformation?: InputMaybe<RegisterProviderCalendarInput>;
};


/** mutation root */
export type Mutation_RootRegisterTestKitArgs = {
  testKit?: InputMaybe<RegisterTestKitInput>;
};


/** mutation root */
export type Mutation_RootSaveDnaScannedDataArgs = {
  dnaScannedData?: InputMaybe<SaveDnaScannedDataInput>;
};


/** mutation root */
export type Mutation_RootSaveQuestionnaireArgs = {
  questionnaire?: InputMaybe<SaveQuestionnaireInput>;
};


/** mutation root */
export type Mutation_RootScheduleAppointmentArgs = {
  appointment?: InputMaybe<ScheduleAppointmentInput>;
};


/** mutation root */
export type Mutation_RootSignupProviderArgs = {
  providerData?: InputMaybe<ProviderSignupInput>;
};


/** mutation root */
export type Mutation_RootSignupUserArgs = {
  userData?: InputMaybe<UserSignupInput>;
};


/** mutation root */
export type Mutation_RootUpdateInAppNotificationArgs = {
  notification?: InputMaybe<UpdateInAppNotificationInput>;
};


/** mutation root */
export type Mutation_RootUpdateProfilePictureArgs = {
  image?: InputMaybe<UpdateProfilePictureInput>;
};


/** mutation root */
export type Mutation_RootUpdateProviderArgs = {
  providerData?: InputMaybe<UpdateProviderInput>;
};


/** mutation root */
export type Mutation_RootUpdateProviderAccountSettingsArgs = {
  accountSettings?: InputMaybe<UpdateProviderAccountSettingsInput>;
};


/** mutation root */
export type Mutation_RootUpdateProviderProfilePictureArgs = {
  image?: InputMaybe<UpdateProfilePictureInput>;
};


/** mutation root */
export type Mutation_RootUpdateQuestionnaireArgs = {
  questionnaire?: InputMaybe<UpdateQuestionnaireInput>;
};


/** mutation root */
export type Mutation_RootUploadCapturedImageArgs = {
  image?: InputMaybe<UploadImageInput>;
};


/** mutation root */
export type Mutation_RootUploadFileToStorageArgs = {
  file: UploadFileToStorageInput;
};


/** mutation root */
export type Mutation_RootUploadVonageFileToStorageArgs = {
  appointmentId: Scalars['String'];
  file: UploadVonageFileToStorageInput;
};


/** mutation root */
export type Mutation_RootValidateCustomTokenArgs = {
  token?: InputMaybe<CustomTokenInput>;
};


/** mutation root */
export type Mutation_RootValidateNpiNumberArgs = {
  npiNumber?: InputMaybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootVonageArchiveStartArgs = {
  sessionId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootVonageArchiveStopArgs = {
  archiveId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootAcceptOrDeclineAppointmentArgs = {
  updateAppointmentStatusFHIRAppointmentInput: UpdateAppointmentStatusFhirAppointmentInput;
};


/** mutation root */
export type Mutation_RootAddMessageToAppointmentMessageListArgs = {
  insertAppointmentChatMessages: InsertAppointmentChatMessages;
};


/** mutation root */
export type Mutation_RootAddMessageToAppointmentMessageListByAppointmentCodexIdArgs = {
  insertAppointmentChatMessages: InsertAppointmentChatMessagesByAppointmentCodexId;
};


/** mutation root */
export type Mutation_RootAddServiceOfferingsArgs = {
  patchFHIRProviderServiceOfferingsInput: AddFhirHealthCareServiceInput;
};


/** mutation root */
export type Mutation_RootAppendEncounterToFhirAppointmentArgs = {
  appendEncounterToFHIRAppointmentInput: AppendFhirEncounterInput;
};


/** mutation root */
export type Mutation_RootCancelFhirAppointmentArgs = {
  appointmentCodexId: Scalars['String'];
  cancelationReason: Scalars['String'];
};


/** mutation root */
export type Mutation_RootCreateAppointmentMessageListArgs = {
  appointmentCodexId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootCreateBecomePatientRequestArgs = {
  providerCodexId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootCreateFhirAppointmentArgs = {
  createFHIRAppointment: CreateFhirAppointmentInput;
};


/** mutation root */
export type Mutation_RootCreateFhirAppointmentForCurrentUserArgs = {
  createFHIRAppointment: CreateFhirAppointmentInputWithoutPatientCodexId;
};


/** mutation root */
export type Mutation_RootCreateFhirDnaVisitTestArgs = {
  createFHIRDnaVisitTest: CreateFhirDnaVisitTestInputWithResponses;
};


/** mutation root */
export type Mutation_RootCreateFhirEncounterArgs = {
  createFHIREncounter: CreateFhirEncounterInput;
};


/** mutation root */
export type Mutation_RootCreateFhirOfficeLocationArgs = {
  createFHIROfficeLocationInput: OfficeAddressInformationInput;
};


/** mutation root */
export type Mutation_RootCreateFhirOrganizationArgs = {
  createFHIROrganizationInput: CreateFhirOrganizationInput;
};


/** mutation root */
export type Mutation_RootCreateFhirPatientArgs = {
  createFHIRPatientInput: CreateFhirPatientInput;
};


/** mutation root */
export type Mutation_RootCreateFhirProviderArgs = {
  createFHIRProviderInput: CreateFhirProviderInput;
};


/** mutation root */
export type Mutation_RootCreateFhirProviderRoleArgs = {
  createFHIRProviderRoleInput: CreateFhirProviderRoleInput;
};


/** mutation root */
export type Mutation_RootCreateFhirSkintelligentTestArgs = {
  createFHIRSkintelligentTest: CreateFhirSkintelligentTestInput;
};


/** mutation root */
export type Mutation_RootCreateFhirZrtTestArgs = {
  createFHIRZrtTest: CreateFhirZrtTestInput;
};


/** mutation root */
export type Mutation_RootDelete_Accepted_Legal_DocumentsArgs = {
  where: Accepted_Legal_Documents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Accepted_Legal_Documents_By_PkArgs = {
  accepted_legal_document_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Acuity_Provider_CalendarArgs = {
  where: Acuity_Provider_Calendar_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Acuity_Provider_Calendar_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_AddressesArgs = {
  where: Addresses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Addresses_By_PkArgs = {
  address_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Channel_SignalsArgs = {
  where: Channel_Signals_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Channel_Signals_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Common_Question_ResponsesArgs = {
  where: Common_Question_Responses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Common_Question_Responses_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Common_QuestionsArgs = {
  where: Common_Questions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Common_Questions_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Communication_ChannelsArgs = {
  where: Communication_Channels_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Communication_Channels_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_CountriesArgs = {
  where: Countries_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Countries_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Custom_Auth_CodeArgs = {
  where: Custom_Auth_Code_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Custom_Auth_Code_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Dnavisit_Test_StatusesArgs = {
  where: Dnavisit_Test_Statuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dnavisit_Test_Statuses_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Dnavisit_TestsArgs = {
  where: Dnavisit_Tests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dnavisit_Tests_By_PkArgs = {
  test_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Do_Not_Use_Production_Swab_CodesArgs = {
  where: Do_Not_Use_Production_Swab_Codes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Do_Not_Use_Production_Swab_Codes_By_PkArgs = {
  swab_code: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Dosespot_CliniciansArgs = {
  where: Dosespot_Clinicians_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dosespot_Clinicians_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_File_TypesArgs = {
  where: File_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_File_Types_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_FilesArgs = {
  where: Files_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Files_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Fullscript_CliniciansArgs = {
  where: Fullscript_Clinicians_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Fullscript_Clinicians_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Global_ConfigsArgs = {
  where: Global_Configs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Global_Configs_By_PkArgs = {
  name: Global_Configs_Names_Enum;
};


/** mutation root */
export type Mutation_RootDelete_Global_Configs_NamesArgs = {
  where: Global_Configs_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Global_Configs_Names_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Image_Source_TypesArgs = {
  where: Image_Source_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Image_Source_Types_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_LabsArgs = {
  where: Labs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Labs_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Legal_Document_TypesArgs = {
  where: Legal_Document_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Legal_Document_Types_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Legal_DocumentsArgs = {
  where: Legal_Documents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Legal_Documents_By_PkArgs = {
  legal_document_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Medical_SchoolsArgs = {
  where: Medical_Schools_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Medical_Schools_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Nabla_NotesArgs = {
  where: Nabla_Notes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nabla_Notes_By_PkArgs = {
  appointment_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Npi_Registry_DataArgs = {
  where: Npi_Registry_Data_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Npi_Registry_Data_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Persona_InquiriesArgs = {
  where: Persona_Inquiries_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Persona_Inquiries_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Persona_Inquiry_StatusArgs = {
  where: Persona_Inquiry_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Persona_Inquiry_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Provider_Unread_External_Notification_CountsArgs = {
  where: Provider_Unread_External_Notification_Counts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Provider_Unread_External_Notification_Counts_By_PkArgs = {
  codex_provider_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Question_Measurement_UnitsArgs = {
  where: Question_Measurement_Units_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Question_Measurement_Units_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Question_TypesArgs = {
  where: Question_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Question_Types_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Question_Ui_Control_Helper_Text_PositionArgs = {
  where: Question_Ui_Control_Helper_Text_Position_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Question_Ui_Control_Helper_Text_Position_By_PkArgs = {
  helper_text_position: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Question_Ui_Control_TypesArgs = {
  where: Question_Ui_Control_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Question_Ui_Control_Types_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Questionnaire_QuestionsArgs = {
  where: Questionnaire_Questions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Questionnaire_Questions_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Questionnaire_ResponsesArgs = {
  where: Questionnaire_Responses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Questionnaire_Responses_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_QuestionnairesArgs = {
  where: Questionnaires_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Questionnaires_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_QuestionsArgs = {
  where: Questions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Questions_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Role_TypesArgs = {
  where: Role_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Role_Types_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_RolesArgs = {
  where: Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Roles_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Skintelligent_TestsArgs = {
  where: Skintelligent_Tests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Skintelligent_Tests_By_PkArgs = {
  test_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Swab_Code_StatusesArgs = {
  where: Swab_Code_Statuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Swab_Code_Statuses_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Swab_CodesArgs = {
  where: Swab_Codes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Swab_Codes_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Swab_Usage_TypeArgs = {
  where: Swab_Usage_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Swab_Usage_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Test_DefinitionsArgs = {
  where: Test_Definitions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Test_Definitions_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Test_Kits_Update_States_Summary_LogArgs = {
  where: Test_Kits_Update_States_Summary_Log_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Test_Kits_Update_States_Summary_Log_By_PkArgs = {
  log_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Test_TypesArgs = {
  where: Test_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Test_Types_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_TestsArgs = {
  where: Tests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tests_By_PkArgs = {
  test_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Text_AddressesArgs = {
  where: Text_Addresses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Text_Addresses_By_PkArgs = {
  address_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Unauthenticated_Questionnaire_QuestionsArgs = {
  where: Unauthenticated_Questionnaire_Questions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Unauthenticated_Questionnaire_Questions_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Unauthenticated_QuestionnairesArgs = {
  where: Unauthenticated_Questionnaires_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Unauthenticated_Questionnaires_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Unauthenticated_QuestionsArgs = {
  where: Unauthenticated_Questions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Unauthenticated_Questions_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_UniversitiesArgs = {
  where: Universities_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Universities_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_User_AddressesArgs = {
  where: User_Addresses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Addresses_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_User_Auth_Provider_TypesArgs = {
  where: User_Auth_Provider_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Auth_Provider_Types_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_User_Auth_ProvidersArgs = {
  where: User_Auth_Providers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Auth_Providers_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_User_Auth_TokensArgs = {
  where: User_Auth_Tokens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Auth_Tokens_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_User_Communication_DeliveriesArgs = {
  where: User_Communication_Deliveries_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Communication_Deliveries_By_PkArgs = {
  user_communication_delivery_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_User_CommunicationsArgs = {
  where: User_Communications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Communications_By_PkArgs = {
  user_communication_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_User_Notification_StatusArgs = {
  where: User_Notification_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Notification_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_User_NotificationsArgs = {
  where: User_Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Notifications_By_PkArgs = {
  user_communication_delivery_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_User_RolesArgs = {
  where: User_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Roles_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_User_Scanned_DataArgs = {
  where: User_Scanned_Data_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Scanned_Data_By_PkArgs = {
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Vonage_AppointmentArgs = {
  where: Vonage_Appointment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Vonage_Appointment_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Zrt_TestsArgs = {
  where: Zrt_Tests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Zrt_Tests_By_PkArgs = {
  test_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDisableMfaArgs = {
  email?: InputMaybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootGenerateTempAuthTokenArgs = {
  generateTempAuthTokenInput?: InputMaybe<GenerateTempAuthTokenInput>;
};


/** mutation root */
export type Mutation_RootGetDnaVisitIframeSecretArgs = {
  userEmail: Scalars['String'];
};


/** mutation root */
export type Mutation_RootGetDnaTestBySwabCodeIdWithNoErrorsArgs = {
  swab_code: Scalars['String'];
};


/** mutation root */
export type Mutation_RootInsertFhirQuestionnaireResponseArgs = {
  createQuestionnaireResponsesInput: QuestionnaireResponseInput;
};


/** mutation root */
export type Mutation_RootInsert_Accepted_Legal_DocumentsArgs = {
  objects: Array<Accepted_Legal_Documents_Insert_Input>;
  on_conflict?: InputMaybe<Accepted_Legal_Documents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Accepted_Legal_Documents_OneArgs = {
  object: Accepted_Legal_Documents_Insert_Input;
  on_conflict?: InputMaybe<Accepted_Legal_Documents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Acuity_Provider_CalendarArgs = {
  objects: Array<Acuity_Provider_Calendar_Insert_Input>;
  on_conflict?: InputMaybe<Acuity_Provider_Calendar_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Acuity_Provider_Calendar_OneArgs = {
  object: Acuity_Provider_Calendar_Insert_Input;
  on_conflict?: InputMaybe<Acuity_Provider_Calendar_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AddressesArgs = {
  objects: Array<Addresses_Insert_Input>;
  on_conflict?: InputMaybe<Addresses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Addresses_OneArgs = {
  object: Addresses_Insert_Input;
  on_conflict?: InputMaybe<Addresses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Channel_SignalsArgs = {
  objects: Array<Channel_Signals_Insert_Input>;
  on_conflict?: InputMaybe<Channel_Signals_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Channel_Signals_OneArgs = {
  object: Channel_Signals_Insert_Input;
  on_conflict?: InputMaybe<Channel_Signals_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Common_Question_ResponsesArgs = {
  objects: Array<Common_Question_Responses_Insert_Input>;
  on_conflict?: InputMaybe<Common_Question_Responses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Common_Question_Responses_OneArgs = {
  object: Common_Question_Responses_Insert_Input;
  on_conflict?: InputMaybe<Common_Question_Responses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Common_QuestionsArgs = {
  objects: Array<Common_Questions_Insert_Input>;
  on_conflict?: InputMaybe<Common_Questions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Common_Questions_OneArgs = {
  object: Common_Questions_Insert_Input;
  on_conflict?: InputMaybe<Common_Questions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Communication_ChannelsArgs = {
  objects: Array<Communication_Channels_Insert_Input>;
  on_conflict?: InputMaybe<Communication_Channels_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Communication_Channels_OneArgs = {
  object: Communication_Channels_Insert_Input;
  on_conflict?: InputMaybe<Communication_Channels_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CountriesArgs = {
  objects: Array<Countries_Insert_Input>;
  on_conflict?: InputMaybe<Countries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Countries_OneArgs = {
  object: Countries_Insert_Input;
  on_conflict?: InputMaybe<Countries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Custom_Auth_CodeArgs = {
  objects: Array<Custom_Auth_Code_Insert_Input>;
  on_conflict?: InputMaybe<Custom_Auth_Code_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Custom_Auth_Code_OneArgs = {
  object: Custom_Auth_Code_Insert_Input;
  on_conflict?: InputMaybe<Custom_Auth_Code_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dnavisit_Test_StatusesArgs = {
  objects: Array<Dnavisit_Test_Statuses_Insert_Input>;
  on_conflict?: InputMaybe<Dnavisit_Test_Statuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dnavisit_Test_Statuses_OneArgs = {
  object: Dnavisit_Test_Statuses_Insert_Input;
  on_conflict?: InputMaybe<Dnavisit_Test_Statuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dnavisit_TestsArgs = {
  objects: Array<Dnavisit_Tests_Insert_Input>;
  on_conflict?: InputMaybe<Dnavisit_Tests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dnavisit_Tests_OneArgs = {
  object: Dnavisit_Tests_Insert_Input;
  on_conflict?: InputMaybe<Dnavisit_Tests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Do_Not_Use_Production_Swab_CodesArgs = {
  objects: Array<Do_Not_Use_Production_Swab_Codes_Insert_Input>;
  on_conflict?: InputMaybe<Do_Not_Use_Production_Swab_Codes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Do_Not_Use_Production_Swab_Codes_OneArgs = {
  object: Do_Not_Use_Production_Swab_Codes_Insert_Input;
  on_conflict?: InputMaybe<Do_Not_Use_Production_Swab_Codes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dosespot_CliniciansArgs = {
  objects: Array<Dosespot_Clinicians_Insert_Input>;
  on_conflict?: InputMaybe<Dosespot_Clinicians_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dosespot_Clinicians_OneArgs = {
  object: Dosespot_Clinicians_Insert_Input;
  on_conflict?: InputMaybe<Dosespot_Clinicians_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_File_TypesArgs = {
  objects: Array<File_Types_Insert_Input>;
  on_conflict?: InputMaybe<File_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_File_Types_OneArgs = {
  object: File_Types_Insert_Input;
  on_conflict?: InputMaybe<File_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FilesArgs = {
  objects: Array<Files_Insert_Input>;
  on_conflict?: InputMaybe<Files_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Files_OneArgs = {
  object: Files_Insert_Input;
  on_conflict?: InputMaybe<Files_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Fullscript_CliniciansArgs = {
  objects: Array<Fullscript_Clinicians_Insert_Input>;
  on_conflict?: InputMaybe<Fullscript_Clinicians_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Fullscript_Clinicians_OneArgs = {
  object: Fullscript_Clinicians_Insert_Input;
  on_conflict?: InputMaybe<Fullscript_Clinicians_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Global_ConfigsArgs = {
  objects: Array<Global_Configs_Insert_Input>;
  on_conflict?: InputMaybe<Global_Configs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Global_Configs_NamesArgs = {
  objects: Array<Global_Configs_Names_Insert_Input>;
  on_conflict?: InputMaybe<Global_Configs_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Global_Configs_Names_OneArgs = {
  object: Global_Configs_Names_Insert_Input;
  on_conflict?: InputMaybe<Global_Configs_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Global_Configs_OneArgs = {
  object: Global_Configs_Insert_Input;
  on_conflict?: InputMaybe<Global_Configs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Image_Source_TypesArgs = {
  objects: Array<Image_Source_Types_Insert_Input>;
  on_conflict?: InputMaybe<Image_Source_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Image_Source_Types_OneArgs = {
  object: Image_Source_Types_Insert_Input;
  on_conflict?: InputMaybe<Image_Source_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LabsArgs = {
  objects: Array<Labs_Insert_Input>;
  on_conflict?: InputMaybe<Labs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Labs_OneArgs = {
  object: Labs_Insert_Input;
  on_conflict?: InputMaybe<Labs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Legal_Document_TypesArgs = {
  objects: Array<Legal_Document_Types_Insert_Input>;
  on_conflict?: InputMaybe<Legal_Document_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Legal_Document_Types_OneArgs = {
  object: Legal_Document_Types_Insert_Input;
  on_conflict?: InputMaybe<Legal_Document_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Legal_DocumentsArgs = {
  objects: Array<Legal_Documents_Insert_Input>;
  on_conflict?: InputMaybe<Legal_Documents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Legal_Documents_OneArgs = {
  object: Legal_Documents_Insert_Input;
  on_conflict?: InputMaybe<Legal_Documents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Medical_SchoolsArgs = {
  objects: Array<Medical_Schools_Insert_Input>;
  on_conflict?: InputMaybe<Medical_Schools_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Medical_Schools_OneArgs = {
  object: Medical_Schools_Insert_Input;
  on_conflict?: InputMaybe<Medical_Schools_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nabla_NotesArgs = {
  objects: Array<Nabla_Notes_Insert_Input>;
  on_conflict?: InputMaybe<Nabla_Notes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nabla_Notes_OneArgs = {
  object: Nabla_Notes_Insert_Input;
  on_conflict?: InputMaybe<Nabla_Notes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Npi_Registry_DataArgs = {
  objects: Array<Npi_Registry_Data_Insert_Input>;
  on_conflict?: InputMaybe<Npi_Registry_Data_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Npi_Registry_Data_OneArgs = {
  object: Npi_Registry_Data_Insert_Input;
  on_conflict?: InputMaybe<Npi_Registry_Data_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Persona_InquiriesArgs = {
  objects: Array<Persona_Inquiries_Insert_Input>;
  on_conflict?: InputMaybe<Persona_Inquiries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Persona_Inquiries_OneArgs = {
  object: Persona_Inquiries_Insert_Input;
  on_conflict?: InputMaybe<Persona_Inquiries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Persona_Inquiry_StatusArgs = {
  objects: Array<Persona_Inquiry_Status_Insert_Input>;
  on_conflict?: InputMaybe<Persona_Inquiry_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Persona_Inquiry_Status_OneArgs = {
  object: Persona_Inquiry_Status_Insert_Input;
  on_conflict?: InputMaybe<Persona_Inquiry_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Provider_Unread_External_Notification_CountsArgs = {
  objects: Array<Provider_Unread_External_Notification_Counts_Insert_Input>;
  on_conflict?: InputMaybe<Provider_Unread_External_Notification_Counts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Provider_Unread_External_Notification_Counts_OneArgs = {
  object: Provider_Unread_External_Notification_Counts_Insert_Input;
  on_conflict?: InputMaybe<Provider_Unread_External_Notification_Counts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Question_Measurement_UnitsArgs = {
  objects: Array<Question_Measurement_Units_Insert_Input>;
  on_conflict?: InputMaybe<Question_Measurement_Units_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Question_Measurement_Units_OneArgs = {
  object: Question_Measurement_Units_Insert_Input;
  on_conflict?: InputMaybe<Question_Measurement_Units_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Question_TypesArgs = {
  objects: Array<Question_Types_Insert_Input>;
  on_conflict?: InputMaybe<Question_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Question_Types_OneArgs = {
  object: Question_Types_Insert_Input;
  on_conflict?: InputMaybe<Question_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Question_Ui_Control_Helper_Text_PositionArgs = {
  objects: Array<Question_Ui_Control_Helper_Text_Position_Insert_Input>;
  on_conflict?: InputMaybe<Question_Ui_Control_Helper_Text_Position_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Question_Ui_Control_Helper_Text_Position_OneArgs = {
  object: Question_Ui_Control_Helper_Text_Position_Insert_Input;
  on_conflict?: InputMaybe<Question_Ui_Control_Helper_Text_Position_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Question_Ui_Control_TypesArgs = {
  objects: Array<Question_Ui_Control_Types_Insert_Input>;
  on_conflict?: InputMaybe<Question_Ui_Control_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Question_Ui_Control_Types_OneArgs = {
  object: Question_Ui_Control_Types_Insert_Input;
  on_conflict?: InputMaybe<Question_Ui_Control_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Questionnaire_QuestionsArgs = {
  objects: Array<Questionnaire_Questions_Insert_Input>;
  on_conflict?: InputMaybe<Questionnaire_Questions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Questionnaire_Questions_OneArgs = {
  object: Questionnaire_Questions_Insert_Input;
  on_conflict?: InputMaybe<Questionnaire_Questions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Questionnaire_ResponsesArgs = {
  objects: Array<Questionnaire_Responses_Insert_Input>;
  on_conflict?: InputMaybe<Questionnaire_Responses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Questionnaire_Responses_OneArgs = {
  object: Questionnaire_Responses_Insert_Input;
  on_conflict?: InputMaybe<Questionnaire_Responses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_QuestionnairesArgs = {
  objects: Array<Questionnaires_Insert_Input>;
  on_conflict?: InputMaybe<Questionnaires_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Questionnaires_OneArgs = {
  object: Questionnaires_Insert_Input;
  on_conflict?: InputMaybe<Questionnaires_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_QuestionsArgs = {
  objects: Array<Questions_Insert_Input>;
  on_conflict?: InputMaybe<Questions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Questions_OneArgs = {
  object: Questions_Insert_Input;
  on_conflict?: InputMaybe<Questions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Role_TypesArgs = {
  objects: Array<Role_Types_Insert_Input>;
  on_conflict?: InputMaybe<Role_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Role_Types_OneArgs = {
  object: Role_Types_Insert_Input;
  on_conflict?: InputMaybe<Role_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RolesArgs = {
  objects: Array<Roles_Insert_Input>;
  on_conflict?: InputMaybe<Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Roles_OneArgs = {
  object: Roles_Insert_Input;
  on_conflict?: InputMaybe<Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Skintelligent_TestsArgs = {
  objects: Array<Skintelligent_Tests_Insert_Input>;
  on_conflict?: InputMaybe<Skintelligent_Tests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Skintelligent_Tests_OneArgs = {
  object: Skintelligent_Tests_Insert_Input;
  on_conflict?: InputMaybe<Skintelligent_Tests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Swab_Code_StatusesArgs = {
  objects: Array<Swab_Code_Statuses_Insert_Input>;
  on_conflict?: InputMaybe<Swab_Code_Statuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Swab_Code_Statuses_OneArgs = {
  object: Swab_Code_Statuses_Insert_Input;
  on_conflict?: InputMaybe<Swab_Code_Statuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Swab_CodesArgs = {
  objects: Array<Swab_Codes_Insert_Input>;
  on_conflict?: InputMaybe<Swab_Codes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Swab_Codes_OneArgs = {
  object: Swab_Codes_Insert_Input;
  on_conflict?: InputMaybe<Swab_Codes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Swab_Usage_TypeArgs = {
  objects: Array<Swab_Usage_Type_Insert_Input>;
  on_conflict?: InputMaybe<Swab_Usage_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Swab_Usage_Type_OneArgs = {
  object: Swab_Usage_Type_Insert_Input;
  on_conflict?: InputMaybe<Swab_Usage_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Test_DefinitionsArgs = {
  objects: Array<Test_Definitions_Insert_Input>;
  on_conflict?: InputMaybe<Test_Definitions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Test_Definitions_OneArgs = {
  object: Test_Definitions_Insert_Input;
  on_conflict?: InputMaybe<Test_Definitions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Test_Kits_Update_States_Summary_LogArgs = {
  objects: Array<Test_Kits_Update_States_Summary_Log_Insert_Input>;
  on_conflict?: InputMaybe<Test_Kits_Update_States_Summary_Log_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Test_Kits_Update_States_Summary_Log_OneArgs = {
  object: Test_Kits_Update_States_Summary_Log_Insert_Input;
  on_conflict?: InputMaybe<Test_Kits_Update_States_Summary_Log_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Test_TypesArgs = {
  objects: Array<Test_Types_Insert_Input>;
  on_conflict?: InputMaybe<Test_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Test_Types_OneArgs = {
  object: Test_Types_Insert_Input;
  on_conflict?: InputMaybe<Test_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TestsArgs = {
  objects: Array<Tests_Insert_Input>;
  on_conflict?: InputMaybe<Tests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tests_OneArgs = {
  object: Tests_Insert_Input;
  on_conflict?: InputMaybe<Tests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Text_AddressesArgs = {
  objects: Array<Text_Addresses_Insert_Input>;
  on_conflict?: InputMaybe<Text_Addresses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Text_Addresses_OneArgs = {
  object: Text_Addresses_Insert_Input;
  on_conflict?: InputMaybe<Text_Addresses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Unauthenticated_Questionnaire_QuestionsArgs = {
  objects: Array<Unauthenticated_Questionnaire_Questions_Insert_Input>;
  on_conflict?: InputMaybe<Unauthenticated_Questionnaire_Questions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Unauthenticated_Questionnaire_Questions_OneArgs = {
  object: Unauthenticated_Questionnaire_Questions_Insert_Input;
  on_conflict?: InputMaybe<Unauthenticated_Questionnaire_Questions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Unauthenticated_QuestionnairesArgs = {
  objects: Array<Unauthenticated_Questionnaires_Insert_Input>;
  on_conflict?: InputMaybe<Unauthenticated_Questionnaires_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Unauthenticated_Questionnaires_OneArgs = {
  object: Unauthenticated_Questionnaires_Insert_Input;
  on_conflict?: InputMaybe<Unauthenticated_Questionnaires_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Unauthenticated_QuestionsArgs = {
  objects: Array<Unauthenticated_Questions_Insert_Input>;
  on_conflict?: InputMaybe<Unauthenticated_Questions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Unauthenticated_Questions_OneArgs = {
  object: Unauthenticated_Questions_Insert_Input;
  on_conflict?: InputMaybe<Unauthenticated_Questions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UniversitiesArgs = {
  objects: Array<Universities_Insert_Input>;
  on_conflict?: InputMaybe<Universities_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Universities_OneArgs = {
  object: Universities_Insert_Input;
  on_conflict?: InputMaybe<Universities_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_AddressesArgs = {
  objects: Array<User_Addresses_Insert_Input>;
  on_conflict?: InputMaybe<User_Addresses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Addresses_OneArgs = {
  object: User_Addresses_Insert_Input;
  on_conflict?: InputMaybe<User_Addresses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Auth_Provider_TypesArgs = {
  objects: Array<User_Auth_Provider_Types_Insert_Input>;
  on_conflict?: InputMaybe<User_Auth_Provider_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Auth_Provider_Types_OneArgs = {
  object: User_Auth_Provider_Types_Insert_Input;
  on_conflict?: InputMaybe<User_Auth_Provider_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Auth_ProvidersArgs = {
  objects: Array<User_Auth_Providers_Insert_Input>;
  on_conflict?: InputMaybe<User_Auth_Providers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Auth_Providers_OneArgs = {
  object: User_Auth_Providers_Insert_Input;
  on_conflict?: InputMaybe<User_Auth_Providers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Auth_TokensArgs = {
  objects: Array<User_Auth_Tokens_Insert_Input>;
  on_conflict?: InputMaybe<User_Auth_Tokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Auth_Tokens_OneArgs = {
  object: User_Auth_Tokens_Insert_Input;
  on_conflict?: InputMaybe<User_Auth_Tokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Communication_DeliveriesArgs = {
  objects: Array<User_Communication_Deliveries_Insert_Input>;
  on_conflict?: InputMaybe<User_Communication_Deliveries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Communication_Deliveries_OneArgs = {
  object: User_Communication_Deliveries_Insert_Input;
  on_conflict?: InputMaybe<User_Communication_Deliveries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_CommunicationsArgs = {
  objects: Array<User_Communications_Insert_Input>;
  on_conflict?: InputMaybe<User_Communications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Communications_OneArgs = {
  object: User_Communications_Insert_Input;
  on_conflict?: InputMaybe<User_Communications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Notification_StatusArgs = {
  objects: Array<User_Notification_Status_Insert_Input>;
  on_conflict?: InputMaybe<User_Notification_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Notification_Status_OneArgs = {
  object: User_Notification_Status_Insert_Input;
  on_conflict?: InputMaybe<User_Notification_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_NotificationsArgs = {
  objects: Array<User_Notifications_Insert_Input>;
  on_conflict?: InputMaybe<User_Notifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Notifications_OneArgs = {
  object: User_Notifications_Insert_Input;
  on_conflict?: InputMaybe<User_Notifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_RolesArgs = {
  objects: Array<User_Roles_Insert_Input>;
  on_conflict?: InputMaybe<User_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Roles_OneArgs = {
  object: User_Roles_Insert_Input;
  on_conflict?: InputMaybe<User_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Scanned_DataArgs = {
  objects: Array<User_Scanned_Data_Insert_Input>;
  on_conflict?: InputMaybe<User_Scanned_Data_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Scanned_Data_OneArgs = {
  object: User_Scanned_Data_Insert_Input;
  on_conflict?: InputMaybe<User_Scanned_Data_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Vonage_AppointmentArgs = {
  objects: Array<Vonage_Appointment_Insert_Input>;
  on_conflict?: InputMaybe<Vonage_Appointment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Vonage_Appointment_OneArgs = {
  object: Vonage_Appointment_Insert_Input;
  on_conflict?: InputMaybe<Vonage_Appointment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zrt_TestsArgs = {
  objects: Array<Zrt_Tests_Insert_Input>;
  on_conflict?: InputMaybe<Zrt_Tests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zrt_Tests_OneArgs = {
  object: Zrt_Tests_Insert_Input;
  on_conflict?: InputMaybe<Zrt_Tests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootLinkProviderAccountArgs = {
  linkProviderAccountInput: LinkProviderAccountInput;
};


/** mutation root */
export type Mutation_RootOnTreatmentPlanActivatedArgs = {
  onTreatmentPlanActivatedInput: OnTreatmentPlanEventInput;
};


/** mutation root */
export type Mutation_RootPatchCodexApprovalArgs = {
  approved: Scalars['Boolean'];
  codexProviderID: Scalars['String'];
};


/** mutation root */
export type Mutation_RootPatchFhirAppointmentArgs = {
  patchFHIRAppointmentInput: PatchFhirAppointmentInput;
};


/** mutation root */
export type Mutation_RootPatchFhirDnaVisitTestArgs = {
  patchFHIRDnaVisitTest: PatchFhirDnaVisitTestInput;
};


/** mutation root */
export type Mutation_RootPatchFhirEncounterArgs = {
  patchFHIREncounter: PatchEncounterInput;
};


/** mutation root */
export type Mutation_RootPatchFhirPatientPersonalDataArgs = {
  patientPersonalInfo: PatchFhirPatientPersonalInfoInput;
};


/** mutation root */
export type Mutation_RootPatchFhirProviderArgs = {
  patchFHIRProviderInput: PatchFhirProviderInput;
};


/** mutation root */
export type Mutation_RootPatchFhirSkintelligentTestArgs = {
  patchFHIRSkintelligentTest: PatchFhirSkintelligentTestInput;
};


/** mutation root */
export type Mutation_RootPatchFhirZrtTestArgs = {
  patchFHIRZrtTest: PatchFhirZrtTestInput;
};


/** mutation root */
export type Mutation_RootPatchServiceOfferingArgs = {
  patchFHIRProviderServiceOfferingsInput: PatchFhirHealthCareServiceInput;
};


/** mutation root */
export type Mutation_RootRequestZrtTestArgs = {
  providerCodexId: Scalars['String'];
  testType: Scalars['String'];
  trackingNumber?: InputMaybe<Scalars['String']>;
  zrtSwabCode: Scalars['String'];
};


/** mutation root */
export type Mutation_RootSaPatchFhirProviderArgs = {
  patchFHIRProviderInput: PatchFhirProviderInput;
  providerCodexId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootSaveFhirQuestionnaireManuallyArgs = {
  isComplete: Scalars['Boolean'];
  questionnaireId: Scalars['Int'];
  responses: Scalars['JSON'];
  userID: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUpdateBecomePatientRequestStatusArgs = {
  updateBecomePatientRequestStatusInput: UpdateBecomePatientRequestStatusInput;
};


/** mutation root */
export type Mutation_RootUpdateFhirUserLatestQuestionnaireArgs = {
  id: Scalars['String'];
  isComplete: Scalars['Boolean'];
  responses: Scalars['JSON'];
};


/** mutation root */
export type Mutation_RootUpdate_Accepted_Legal_DocumentsArgs = {
  _inc?: InputMaybe<Accepted_Legal_Documents_Inc_Input>;
  _set?: InputMaybe<Accepted_Legal_Documents_Set_Input>;
  where: Accepted_Legal_Documents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Accepted_Legal_Documents_By_PkArgs = {
  _inc?: InputMaybe<Accepted_Legal_Documents_Inc_Input>;
  _set?: InputMaybe<Accepted_Legal_Documents_Set_Input>;
  pk_columns: Accepted_Legal_Documents_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Accepted_Legal_Documents_ManyArgs = {
  updates: Array<Accepted_Legal_Documents_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Acuity_Provider_CalendarArgs = {
  _inc?: InputMaybe<Acuity_Provider_Calendar_Inc_Input>;
  _set?: InputMaybe<Acuity_Provider_Calendar_Set_Input>;
  where: Acuity_Provider_Calendar_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Acuity_Provider_Calendar_By_PkArgs = {
  _inc?: InputMaybe<Acuity_Provider_Calendar_Inc_Input>;
  _set?: InputMaybe<Acuity_Provider_Calendar_Set_Input>;
  pk_columns: Acuity_Provider_Calendar_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Acuity_Provider_Calendar_ManyArgs = {
  updates: Array<Acuity_Provider_Calendar_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AddressesArgs = {
  _set?: InputMaybe<Addresses_Set_Input>;
  where: Addresses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Addresses_By_PkArgs = {
  _set?: InputMaybe<Addresses_Set_Input>;
  pk_columns: Addresses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Addresses_ManyArgs = {
  updates: Array<Addresses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Channel_SignalsArgs = {
  _inc?: InputMaybe<Channel_Signals_Inc_Input>;
  _set?: InputMaybe<Channel_Signals_Set_Input>;
  where: Channel_Signals_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Channel_Signals_By_PkArgs = {
  _inc?: InputMaybe<Channel_Signals_Inc_Input>;
  _set?: InputMaybe<Channel_Signals_Set_Input>;
  pk_columns: Channel_Signals_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Channel_Signals_ManyArgs = {
  updates: Array<Channel_Signals_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Common_Question_ResponsesArgs = {
  _append?: InputMaybe<Common_Question_Responses_Append_Input>;
  _delete_at_path?: InputMaybe<Common_Question_Responses_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Common_Question_Responses_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Common_Question_Responses_Delete_Key_Input>;
  _inc?: InputMaybe<Common_Question_Responses_Inc_Input>;
  _prepend?: InputMaybe<Common_Question_Responses_Prepend_Input>;
  _set?: InputMaybe<Common_Question_Responses_Set_Input>;
  where: Common_Question_Responses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Common_Question_Responses_By_PkArgs = {
  _append?: InputMaybe<Common_Question_Responses_Append_Input>;
  _delete_at_path?: InputMaybe<Common_Question_Responses_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Common_Question_Responses_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Common_Question_Responses_Delete_Key_Input>;
  _inc?: InputMaybe<Common_Question_Responses_Inc_Input>;
  _prepend?: InputMaybe<Common_Question_Responses_Prepend_Input>;
  _set?: InputMaybe<Common_Question_Responses_Set_Input>;
  pk_columns: Common_Question_Responses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Common_Question_Responses_ManyArgs = {
  updates: Array<Common_Question_Responses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Common_QuestionsArgs = {
  _inc?: InputMaybe<Common_Questions_Inc_Input>;
  _set?: InputMaybe<Common_Questions_Set_Input>;
  where: Common_Questions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Common_Questions_By_PkArgs = {
  _inc?: InputMaybe<Common_Questions_Inc_Input>;
  _set?: InputMaybe<Common_Questions_Set_Input>;
  pk_columns: Common_Questions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Common_Questions_ManyArgs = {
  updates: Array<Common_Questions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Communication_ChannelsArgs = {
  _set?: InputMaybe<Communication_Channels_Set_Input>;
  where: Communication_Channels_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Communication_Channels_By_PkArgs = {
  _set?: InputMaybe<Communication_Channels_Set_Input>;
  pk_columns: Communication_Channels_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Communication_Channels_ManyArgs = {
  updates: Array<Communication_Channels_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CountriesArgs = {
  _inc?: InputMaybe<Countries_Inc_Input>;
  _set?: InputMaybe<Countries_Set_Input>;
  where: Countries_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Countries_By_PkArgs = {
  _inc?: InputMaybe<Countries_Inc_Input>;
  _set?: InputMaybe<Countries_Set_Input>;
  pk_columns: Countries_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Countries_ManyArgs = {
  updates: Array<Countries_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Custom_Auth_CodeArgs = {
  _set?: InputMaybe<Custom_Auth_Code_Set_Input>;
  where: Custom_Auth_Code_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Custom_Auth_Code_By_PkArgs = {
  _set?: InputMaybe<Custom_Auth_Code_Set_Input>;
  pk_columns: Custom_Auth_Code_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Custom_Auth_Code_ManyArgs = {
  updates: Array<Custom_Auth_Code_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Dnavisit_Test_StatusesArgs = {
  _set?: InputMaybe<Dnavisit_Test_Statuses_Set_Input>;
  where: Dnavisit_Test_Statuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dnavisit_Test_Statuses_By_PkArgs = {
  _set?: InputMaybe<Dnavisit_Test_Statuses_Set_Input>;
  pk_columns: Dnavisit_Test_Statuses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dnavisit_Test_Statuses_ManyArgs = {
  updates: Array<Dnavisit_Test_Statuses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Dnavisit_TestsArgs = {
  _append?: InputMaybe<Dnavisit_Tests_Append_Input>;
  _delete_at_path?: InputMaybe<Dnavisit_Tests_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Dnavisit_Tests_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Dnavisit_Tests_Delete_Key_Input>;
  _prepend?: InputMaybe<Dnavisit_Tests_Prepend_Input>;
  _set?: InputMaybe<Dnavisit_Tests_Set_Input>;
  where: Dnavisit_Tests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dnavisit_Tests_By_PkArgs = {
  _append?: InputMaybe<Dnavisit_Tests_Append_Input>;
  _delete_at_path?: InputMaybe<Dnavisit_Tests_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Dnavisit_Tests_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Dnavisit_Tests_Delete_Key_Input>;
  _prepend?: InputMaybe<Dnavisit_Tests_Prepend_Input>;
  _set?: InputMaybe<Dnavisit_Tests_Set_Input>;
  pk_columns: Dnavisit_Tests_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dnavisit_Tests_ManyArgs = {
  updates: Array<Dnavisit_Tests_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Do_Not_Use_Production_Swab_CodesArgs = {
  _set?: InputMaybe<Do_Not_Use_Production_Swab_Codes_Set_Input>;
  where: Do_Not_Use_Production_Swab_Codes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Do_Not_Use_Production_Swab_Codes_By_PkArgs = {
  _set?: InputMaybe<Do_Not_Use_Production_Swab_Codes_Set_Input>;
  pk_columns: Do_Not_Use_Production_Swab_Codes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Do_Not_Use_Production_Swab_Codes_ManyArgs = {
  updates: Array<Do_Not_Use_Production_Swab_Codes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Dosespot_CliniciansArgs = {
  _inc?: InputMaybe<Dosespot_Clinicians_Inc_Input>;
  _set?: InputMaybe<Dosespot_Clinicians_Set_Input>;
  where: Dosespot_Clinicians_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dosespot_Clinicians_By_PkArgs = {
  _inc?: InputMaybe<Dosespot_Clinicians_Inc_Input>;
  _set?: InputMaybe<Dosespot_Clinicians_Set_Input>;
  pk_columns: Dosespot_Clinicians_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dosespot_Clinicians_ManyArgs = {
  updates: Array<Dosespot_Clinicians_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_File_TypesArgs = {
  _set?: InputMaybe<File_Types_Set_Input>;
  where: File_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_File_Types_By_PkArgs = {
  _set?: InputMaybe<File_Types_Set_Input>;
  pk_columns: File_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_File_Types_ManyArgs = {
  updates: Array<File_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_FilesArgs = {
  _set?: InputMaybe<Files_Set_Input>;
  where: Files_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Files_By_PkArgs = {
  _set?: InputMaybe<Files_Set_Input>;
  pk_columns: Files_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Files_ManyArgs = {
  updates: Array<Files_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Fullscript_CliniciansArgs = {
  _set?: InputMaybe<Fullscript_Clinicians_Set_Input>;
  where: Fullscript_Clinicians_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Fullscript_Clinicians_By_PkArgs = {
  _set?: InputMaybe<Fullscript_Clinicians_Set_Input>;
  pk_columns: Fullscript_Clinicians_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Fullscript_Clinicians_ManyArgs = {
  updates: Array<Fullscript_Clinicians_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Global_ConfigsArgs = {
  _append?: InputMaybe<Global_Configs_Append_Input>;
  _delete_at_path?: InputMaybe<Global_Configs_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Global_Configs_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Global_Configs_Delete_Key_Input>;
  _prepend?: InputMaybe<Global_Configs_Prepend_Input>;
  _set?: InputMaybe<Global_Configs_Set_Input>;
  where: Global_Configs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Global_Configs_By_PkArgs = {
  _append?: InputMaybe<Global_Configs_Append_Input>;
  _delete_at_path?: InputMaybe<Global_Configs_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Global_Configs_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Global_Configs_Delete_Key_Input>;
  _prepend?: InputMaybe<Global_Configs_Prepend_Input>;
  _set?: InputMaybe<Global_Configs_Set_Input>;
  pk_columns: Global_Configs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Global_Configs_ManyArgs = {
  updates: Array<Global_Configs_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Global_Configs_NamesArgs = {
  _set?: InputMaybe<Global_Configs_Names_Set_Input>;
  where: Global_Configs_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Global_Configs_Names_By_PkArgs = {
  _set?: InputMaybe<Global_Configs_Names_Set_Input>;
  pk_columns: Global_Configs_Names_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Global_Configs_Names_ManyArgs = {
  updates: Array<Global_Configs_Names_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Image_Source_TypesArgs = {
  _set?: InputMaybe<Image_Source_Types_Set_Input>;
  where: Image_Source_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Image_Source_Types_By_PkArgs = {
  _set?: InputMaybe<Image_Source_Types_Set_Input>;
  pk_columns: Image_Source_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Image_Source_Types_ManyArgs = {
  updates: Array<Image_Source_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_LabsArgs = {
  _set?: InputMaybe<Labs_Set_Input>;
  where: Labs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Labs_By_PkArgs = {
  _set?: InputMaybe<Labs_Set_Input>;
  pk_columns: Labs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Labs_ManyArgs = {
  updates: Array<Labs_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Legal_Document_TypesArgs = {
  _set?: InputMaybe<Legal_Document_Types_Set_Input>;
  where: Legal_Document_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Legal_Document_Types_By_PkArgs = {
  _set?: InputMaybe<Legal_Document_Types_Set_Input>;
  pk_columns: Legal_Document_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Legal_Document_Types_ManyArgs = {
  updates: Array<Legal_Document_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Legal_DocumentsArgs = {
  _inc?: InputMaybe<Legal_Documents_Inc_Input>;
  _set?: InputMaybe<Legal_Documents_Set_Input>;
  where: Legal_Documents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Legal_Documents_By_PkArgs = {
  _inc?: InputMaybe<Legal_Documents_Inc_Input>;
  _set?: InputMaybe<Legal_Documents_Set_Input>;
  pk_columns: Legal_Documents_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Legal_Documents_ManyArgs = {
  updates: Array<Legal_Documents_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Medical_SchoolsArgs = {
  _inc?: InputMaybe<Medical_Schools_Inc_Input>;
  _set?: InputMaybe<Medical_Schools_Set_Input>;
  where: Medical_Schools_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Medical_Schools_By_PkArgs = {
  _inc?: InputMaybe<Medical_Schools_Inc_Input>;
  _set?: InputMaybe<Medical_Schools_Set_Input>;
  pk_columns: Medical_Schools_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Medical_Schools_ManyArgs = {
  updates: Array<Medical_Schools_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nabla_NotesArgs = {
  _append?: InputMaybe<Nabla_Notes_Append_Input>;
  _delete_at_path?: InputMaybe<Nabla_Notes_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Nabla_Notes_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Nabla_Notes_Delete_Key_Input>;
  _prepend?: InputMaybe<Nabla_Notes_Prepend_Input>;
  _set?: InputMaybe<Nabla_Notes_Set_Input>;
  where: Nabla_Notes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nabla_Notes_By_PkArgs = {
  _append?: InputMaybe<Nabla_Notes_Append_Input>;
  _delete_at_path?: InputMaybe<Nabla_Notes_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Nabla_Notes_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Nabla_Notes_Delete_Key_Input>;
  _prepend?: InputMaybe<Nabla_Notes_Prepend_Input>;
  _set?: InputMaybe<Nabla_Notes_Set_Input>;
  pk_columns: Nabla_Notes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nabla_Notes_ManyArgs = {
  updates: Array<Nabla_Notes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Npi_Registry_DataArgs = {
  _append?: InputMaybe<Npi_Registry_Data_Append_Input>;
  _delete_at_path?: InputMaybe<Npi_Registry_Data_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Npi_Registry_Data_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Npi_Registry_Data_Delete_Key_Input>;
  _prepend?: InputMaybe<Npi_Registry_Data_Prepend_Input>;
  _set?: InputMaybe<Npi_Registry_Data_Set_Input>;
  where: Npi_Registry_Data_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Npi_Registry_Data_By_PkArgs = {
  _append?: InputMaybe<Npi_Registry_Data_Append_Input>;
  _delete_at_path?: InputMaybe<Npi_Registry_Data_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Npi_Registry_Data_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Npi_Registry_Data_Delete_Key_Input>;
  _prepend?: InputMaybe<Npi_Registry_Data_Prepend_Input>;
  _set?: InputMaybe<Npi_Registry_Data_Set_Input>;
  pk_columns: Npi_Registry_Data_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Npi_Registry_Data_ManyArgs = {
  updates: Array<Npi_Registry_Data_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Persona_InquiriesArgs = {
  _set?: InputMaybe<Persona_Inquiries_Set_Input>;
  where: Persona_Inquiries_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Persona_Inquiries_By_PkArgs = {
  _set?: InputMaybe<Persona_Inquiries_Set_Input>;
  pk_columns: Persona_Inquiries_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Persona_Inquiries_ManyArgs = {
  updates: Array<Persona_Inquiries_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Persona_Inquiry_StatusArgs = {
  _set?: InputMaybe<Persona_Inquiry_Status_Set_Input>;
  where: Persona_Inquiry_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Persona_Inquiry_Status_By_PkArgs = {
  _set?: InputMaybe<Persona_Inquiry_Status_Set_Input>;
  pk_columns: Persona_Inquiry_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Persona_Inquiry_Status_ManyArgs = {
  updates: Array<Persona_Inquiry_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Provider_Unread_External_Notification_CountsArgs = {
  _inc?: InputMaybe<Provider_Unread_External_Notification_Counts_Inc_Input>;
  _set?: InputMaybe<Provider_Unread_External_Notification_Counts_Set_Input>;
  where: Provider_Unread_External_Notification_Counts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Provider_Unread_External_Notification_Counts_By_PkArgs = {
  _inc?: InputMaybe<Provider_Unread_External_Notification_Counts_Inc_Input>;
  _set?: InputMaybe<Provider_Unread_External_Notification_Counts_Set_Input>;
  pk_columns: Provider_Unread_External_Notification_Counts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Provider_Unread_External_Notification_Counts_ManyArgs = {
  updates: Array<Provider_Unread_External_Notification_Counts_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Question_Measurement_UnitsArgs = {
  _set?: InputMaybe<Question_Measurement_Units_Set_Input>;
  where: Question_Measurement_Units_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Question_Measurement_Units_By_PkArgs = {
  _set?: InputMaybe<Question_Measurement_Units_Set_Input>;
  pk_columns: Question_Measurement_Units_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Question_Measurement_Units_ManyArgs = {
  updates: Array<Question_Measurement_Units_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Question_TypesArgs = {
  _set?: InputMaybe<Question_Types_Set_Input>;
  where: Question_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Question_Types_By_PkArgs = {
  _set?: InputMaybe<Question_Types_Set_Input>;
  pk_columns: Question_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Question_Types_ManyArgs = {
  updates: Array<Question_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Question_Ui_Control_Helper_Text_PositionArgs = {
  _set?: InputMaybe<Question_Ui_Control_Helper_Text_Position_Set_Input>;
  where: Question_Ui_Control_Helper_Text_Position_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Question_Ui_Control_Helper_Text_Position_By_PkArgs = {
  _set?: InputMaybe<Question_Ui_Control_Helper_Text_Position_Set_Input>;
  pk_columns: Question_Ui_Control_Helper_Text_Position_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Question_Ui_Control_Helper_Text_Position_ManyArgs = {
  updates: Array<Question_Ui_Control_Helper_Text_Position_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Question_Ui_Control_TypesArgs = {
  _set?: InputMaybe<Question_Ui_Control_Types_Set_Input>;
  where: Question_Ui_Control_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Question_Ui_Control_Types_By_PkArgs = {
  _set?: InputMaybe<Question_Ui_Control_Types_Set_Input>;
  pk_columns: Question_Ui_Control_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Question_Ui_Control_Types_ManyArgs = {
  updates: Array<Question_Ui_Control_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Questionnaire_QuestionsArgs = {
  _inc?: InputMaybe<Questionnaire_Questions_Inc_Input>;
  _set?: InputMaybe<Questionnaire_Questions_Set_Input>;
  where: Questionnaire_Questions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Questionnaire_Questions_By_PkArgs = {
  _inc?: InputMaybe<Questionnaire_Questions_Inc_Input>;
  _set?: InputMaybe<Questionnaire_Questions_Set_Input>;
  pk_columns: Questionnaire_Questions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Questionnaire_Questions_ManyArgs = {
  updates: Array<Questionnaire_Questions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Questionnaire_ResponsesArgs = {
  _append?: InputMaybe<Questionnaire_Responses_Append_Input>;
  _delete_at_path?: InputMaybe<Questionnaire_Responses_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Questionnaire_Responses_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Questionnaire_Responses_Delete_Key_Input>;
  _inc?: InputMaybe<Questionnaire_Responses_Inc_Input>;
  _prepend?: InputMaybe<Questionnaire_Responses_Prepend_Input>;
  _set?: InputMaybe<Questionnaire_Responses_Set_Input>;
  where: Questionnaire_Responses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Questionnaire_Responses_By_PkArgs = {
  _append?: InputMaybe<Questionnaire_Responses_Append_Input>;
  _delete_at_path?: InputMaybe<Questionnaire_Responses_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Questionnaire_Responses_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Questionnaire_Responses_Delete_Key_Input>;
  _inc?: InputMaybe<Questionnaire_Responses_Inc_Input>;
  _prepend?: InputMaybe<Questionnaire_Responses_Prepend_Input>;
  _set?: InputMaybe<Questionnaire_Responses_Set_Input>;
  pk_columns: Questionnaire_Responses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Questionnaire_Responses_ManyArgs = {
  updates: Array<Questionnaire_Responses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_QuestionnairesArgs = {
  _append?: InputMaybe<Questionnaires_Append_Input>;
  _delete_at_path?: InputMaybe<Questionnaires_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Questionnaires_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Questionnaires_Delete_Key_Input>;
  _inc?: InputMaybe<Questionnaires_Inc_Input>;
  _prepend?: InputMaybe<Questionnaires_Prepend_Input>;
  _set?: InputMaybe<Questionnaires_Set_Input>;
  where: Questionnaires_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Questionnaires_By_PkArgs = {
  _append?: InputMaybe<Questionnaires_Append_Input>;
  _delete_at_path?: InputMaybe<Questionnaires_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Questionnaires_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Questionnaires_Delete_Key_Input>;
  _inc?: InputMaybe<Questionnaires_Inc_Input>;
  _prepend?: InputMaybe<Questionnaires_Prepend_Input>;
  _set?: InputMaybe<Questionnaires_Set_Input>;
  pk_columns: Questionnaires_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Questionnaires_ManyArgs = {
  updates: Array<Questionnaires_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_QuestionsArgs = {
  _append?: InputMaybe<Questions_Append_Input>;
  _delete_at_path?: InputMaybe<Questions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Questions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Questions_Delete_Key_Input>;
  _inc?: InputMaybe<Questions_Inc_Input>;
  _prepend?: InputMaybe<Questions_Prepend_Input>;
  _set?: InputMaybe<Questions_Set_Input>;
  where: Questions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Questions_By_PkArgs = {
  _append?: InputMaybe<Questions_Append_Input>;
  _delete_at_path?: InputMaybe<Questions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Questions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Questions_Delete_Key_Input>;
  _inc?: InputMaybe<Questions_Inc_Input>;
  _prepend?: InputMaybe<Questions_Prepend_Input>;
  _set?: InputMaybe<Questions_Set_Input>;
  pk_columns: Questions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Questions_ManyArgs = {
  updates: Array<Questions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Role_TypesArgs = {
  _set?: InputMaybe<Role_Types_Set_Input>;
  where: Role_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Role_Types_By_PkArgs = {
  _set?: InputMaybe<Role_Types_Set_Input>;
  pk_columns: Role_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Role_Types_ManyArgs = {
  updates: Array<Role_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_RolesArgs = {
  _inc?: InputMaybe<Roles_Inc_Input>;
  _set?: InputMaybe<Roles_Set_Input>;
  where: Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Roles_By_PkArgs = {
  _inc?: InputMaybe<Roles_Inc_Input>;
  _set?: InputMaybe<Roles_Set_Input>;
  pk_columns: Roles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Roles_ManyArgs = {
  updates: Array<Roles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Skintelligent_TestsArgs = {
  _append?: InputMaybe<Skintelligent_Tests_Append_Input>;
  _delete_at_path?: InputMaybe<Skintelligent_Tests_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Skintelligent_Tests_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Skintelligent_Tests_Delete_Key_Input>;
  _inc?: InputMaybe<Skintelligent_Tests_Inc_Input>;
  _prepend?: InputMaybe<Skintelligent_Tests_Prepend_Input>;
  _set?: InputMaybe<Skintelligent_Tests_Set_Input>;
  where: Skintelligent_Tests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Skintelligent_Tests_By_PkArgs = {
  _append?: InputMaybe<Skintelligent_Tests_Append_Input>;
  _delete_at_path?: InputMaybe<Skintelligent_Tests_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Skintelligent_Tests_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Skintelligent_Tests_Delete_Key_Input>;
  _inc?: InputMaybe<Skintelligent_Tests_Inc_Input>;
  _prepend?: InputMaybe<Skintelligent_Tests_Prepend_Input>;
  _set?: InputMaybe<Skintelligent_Tests_Set_Input>;
  pk_columns: Skintelligent_Tests_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Skintelligent_Tests_ManyArgs = {
  updates: Array<Skintelligent_Tests_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Swab_Code_StatusesArgs = {
  _set?: InputMaybe<Swab_Code_Statuses_Set_Input>;
  where: Swab_Code_Statuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Swab_Code_Statuses_By_PkArgs = {
  _set?: InputMaybe<Swab_Code_Statuses_Set_Input>;
  pk_columns: Swab_Code_Statuses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Swab_Code_Statuses_ManyArgs = {
  updates: Array<Swab_Code_Statuses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Swab_CodesArgs = {
  _inc?: InputMaybe<Swab_Codes_Inc_Input>;
  _set?: InputMaybe<Swab_Codes_Set_Input>;
  where: Swab_Codes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Swab_Codes_By_PkArgs = {
  _inc?: InputMaybe<Swab_Codes_Inc_Input>;
  _set?: InputMaybe<Swab_Codes_Set_Input>;
  pk_columns: Swab_Codes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Swab_Codes_ManyArgs = {
  updates: Array<Swab_Codes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Swab_Usage_TypeArgs = {
  _set?: InputMaybe<Swab_Usage_Type_Set_Input>;
  where: Swab_Usage_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Swab_Usage_Type_By_PkArgs = {
  _set?: InputMaybe<Swab_Usage_Type_Set_Input>;
  pk_columns: Swab_Usage_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Swab_Usage_Type_ManyArgs = {
  updates: Array<Swab_Usage_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Test_DefinitionsArgs = {
  _append?: InputMaybe<Test_Definitions_Append_Input>;
  _delete_at_path?: InputMaybe<Test_Definitions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Test_Definitions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Test_Definitions_Delete_Key_Input>;
  _inc?: InputMaybe<Test_Definitions_Inc_Input>;
  _prepend?: InputMaybe<Test_Definitions_Prepend_Input>;
  _set?: InputMaybe<Test_Definitions_Set_Input>;
  where: Test_Definitions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Test_Definitions_By_PkArgs = {
  _append?: InputMaybe<Test_Definitions_Append_Input>;
  _delete_at_path?: InputMaybe<Test_Definitions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Test_Definitions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Test_Definitions_Delete_Key_Input>;
  _inc?: InputMaybe<Test_Definitions_Inc_Input>;
  _prepend?: InputMaybe<Test_Definitions_Prepend_Input>;
  _set?: InputMaybe<Test_Definitions_Set_Input>;
  pk_columns: Test_Definitions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Test_Definitions_ManyArgs = {
  updates: Array<Test_Definitions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Test_Kits_Update_States_Summary_LogArgs = {
  _set?: InputMaybe<Test_Kits_Update_States_Summary_Log_Set_Input>;
  where: Test_Kits_Update_States_Summary_Log_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Test_Kits_Update_States_Summary_Log_By_PkArgs = {
  _set?: InputMaybe<Test_Kits_Update_States_Summary_Log_Set_Input>;
  pk_columns: Test_Kits_Update_States_Summary_Log_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Test_Kits_Update_States_Summary_Log_ManyArgs = {
  updates: Array<Test_Kits_Update_States_Summary_Log_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Test_TypesArgs = {
  _set?: InputMaybe<Test_Types_Set_Input>;
  where: Test_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Test_Types_By_PkArgs = {
  _set?: InputMaybe<Test_Types_Set_Input>;
  pk_columns: Test_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Test_Types_ManyArgs = {
  updates: Array<Test_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TestsArgs = {
  _inc?: InputMaybe<Tests_Inc_Input>;
  _set?: InputMaybe<Tests_Set_Input>;
  where: Tests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tests_By_PkArgs = {
  _inc?: InputMaybe<Tests_Inc_Input>;
  _set?: InputMaybe<Tests_Set_Input>;
  pk_columns: Tests_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tests_ManyArgs = {
  updates: Array<Tests_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Text_AddressesArgs = {
  _set?: InputMaybe<Text_Addresses_Set_Input>;
  where: Text_Addresses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Text_Addresses_By_PkArgs = {
  _set?: InputMaybe<Text_Addresses_Set_Input>;
  pk_columns: Text_Addresses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Text_Addresses_ManyArgs = {
  updates: Array<Text_Addresses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Unauthenticated_Questionnaire_QuestionsArgs = {
  _inc?: InputMaybe<Unauthenticated_Questionnaire_Questions_Inc_Input>;
  _set?: InputMaybe<Unauthenticated_Questionnaire_Questions_Set_Input>;
  where: Unauthenticated_Questionnaire_Questions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Unauthenticated_Questionnaire_Questions_By_PkArgs = {
  _inc?: InputMaybe<Unauthenticated_Questionnaire_Questions_Inc_Input>;
  _set?: InputMaybe<Unauthenticated_Questionnaire_Questions_Set_Input>;
  pk_columns: Unauthenticated_Questionnaire_Questions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Unauthenticated_Questionnaire_Questions_ManyArgs = {
  updates: Array<Unauthenticated_Questionnaire_Questions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Unauthenticated_QuestionnairesArgs = {
  _append?: InputMaybe<Unauthenticated_Questionnaires_Append_Input>;
  _delete_at_path?: InputMaybe<Unauthenticated_Questionnaires_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Unauthenticated_Questionnaires_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Unauthenticated_Questionnaires_Delete_Key_Input>;
  _inc?: InputMaybe<Unauthenticated_Questionnaires_Inc_Input>;
  _prepend?: InputMaybe<Unauthenticated_Questionnaires_Prepend_Input>;
  _set?: InputMaybe<Unauthenticated_Questionnaires_Set_Input>;
  where: Unauthenticated_Questionnaires_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Unauthenticated_Questionnaires_By_PkArgs = {
  _append?: InputMaybe<Unauthenticated_Questionnaires_Append_Input>;
  _delete_at_path?: InputMaybe<Unauthenticated_Questionnaires_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Unauthenticated_Questionnaires_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Unauthenticated_Questionnaires_Delete_Key_Input>;
  _inc?: InputMaybe<Unauthenticated_Questionnaires_Inc_Input>;
  _prepend?: InputMaybe<Unauthenticated_Questionnaires_Prepend_Input>;
  _set?: InputMaybe<Unauthenticated_Questionnaires_Set_Input>;
  pk_columns: Unauthenticated_Questionnaires_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Unauthenticated_Questionnaires_ManyArgs = {
  updates: Array<Unauthenticated_Questionnaires_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Unauthenticated_QuestionsArgs = {
  _append?: InputMaybe<Unauthenticated_Questions_Append_Input>;
  _delete_at_path?: InputMaybe<Unauthenticated_Questions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Unauthenticated_Questions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Unauthenticated_Questions_Delete_Key_Input>;
  _inc?: InputMaybe<Unauthenticated_Questions_Inc_Input>;
  _prepend?: InputMaybe<Unauthenticated_Questions_Prepend_Input>;
  _set?: InputMaybe<Unauthenticated_Questions_Set_Input>;
  where: Unauthenticated_Questions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Unauthenticated_Questions_By_PkArgs = {
  _append?: InputMaybe<Unauthenticated_Questions_Append_Input>;
  _delete_at_path?: InputMaybe<Unauthenticated_Questions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Unauthenticated_Questions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Unauthenticated_Questions_Delete_Key_Input>;
  _inc?: InputMaybe<Unauthenticated_Questions_Inc_Input>;
  _prepend?: InputMaybe<Unauthenticated_Questions_Prepend_Input>;
  _set?: InputMaybe<Unauthenticated_Questions_Set_Input>;
  pk_columns: Unauthenticated_Questions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Unauthenticated_Questions_ManyArgs = {
  updates: Array<Unauthenticated_Questions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UniversitiesArgs = {
  _inc?: InputMaybe<Universities_Inc_Input>;
  _set?: InputMaybe<Universities_Set_Input>;
  where: Universities_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Universities_By_PkArgs = {
  _inc?: InputMaybe<Universities_Inc_Input>;
  _set?: InputMaybe<Universities_Set_Input>;
  pk_columns: Universities_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Universities_ManyArgs = {
  updates: Array<Universities_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_AddressesArgs = {
  _inc?: InputMaybe<User_Addresses_Inc_Input>;
  _set?: InputMaybe<User_Addresses_Set_Input>;
  where: User_Addresses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Addresses_By_PkArgs = {
  _inc?: InputMaybe<User_Addresses_Inc_Input>;
  _set?: InputMaybe<User_Addresses_Set_Input>;
  pk_columns: User_Addresses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Addresses_ManyArgs = {
  updates: Array<User_Addresses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Auth_Provider_TypesArgs = {
  _set?: InputMaybe<User_Auth_Provider_Types_Set_Input>;
  where: User_Auth_Provider_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Auth_Provider_Types_By_PkArgs = {
  _set?: InputMaybe<User_Auth_Provider_Types_Set_Input>;
  pk_columns: User_Auth_Provider_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Auth_Provider_Types_ManyArgs = {
  updates: Array<User_Auth_Provider_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Auth_ProvidersArgs = {
  _append?: InputMaybe<User_Auth_Providers_Append_Input>;
  _delete_at_path?: InputMaybe<User_Auth_Providers_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Auth_Providers_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Auth_Providers_Delete_Key_Input>;
  _inc?: InputMaybe<User_Auth_Providers_Inc_Input>;
  _prepend?: InputMaybe<User_Auth_Providers_Prepend_Input>;
  _set?: InputMaybe<User_Auth_Providers_Set_Input>;
  where: User_Auth_Providers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Auth_Providers_By_PkArgs = {
  _append?: InputMaybe<User_Auth_Providers_Append_Input>;
  _delete_at_path?: InputMaybe<User_Auth_Providers_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Auth_Providers_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Auth_Providers_Delete_Key_Input>;
  _inc?: InputMaybe<User_Auth_Providers_Inc_Input>;
  _prepend?: InputMaybe<User_Auth_Providers_Prepend_Input>;
  _set?: InputMaybe<User_Auth_Providers_Set_Input>;
  pk_columns: User_Auth_Providers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Auth_Providers_ManyArgs = {
  updates: Array<User_Auth_Providers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Auth_TokensArgs = {
  _inc?: InputMaybe<User_Auth_Tokens_Inc_Input>;
  _set?: InputMaybe<User_Auth_Tokens_Set_Input>;
  where: User_Auth_Tokens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Auth_Tokens_By_PkArgs = {
  _inc?: InputMaybe<User_Auth_Tokens_Inc_Input>;
  _set?: InputMaybe<User_Auth_Tokens_Set_Input>;
  pk_columns: User_Auth_Tokens_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Auth_Tokens_ManyArgs = {
  updates: Array<User_Auth_Tokens_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Communication_DeliveriesArgs = {
  _inc?: InputMaybe<User_Communication_Deliveries_Inc_Input>;
  _set?: InputMaybe<User_Communication_Deliveries_Set_Input>;
  where: User_Communication_Deliveries_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Communication_Deliveries_By_PkArgs = {
  _inc?: InputMaybe<User_Communication_Deliveries_Inc_Input>;
  _set?: InputMaybe<User_Communication_Deliveries_Set_Input>;
  pk_columns: User_Communication_Deliveries_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Communication_Deliveries_ManyArgs = {
  updates: Array<User_Communication_Deliveries_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_CommunicationsArgs = {
  _append?: InputMaybe<User_Communications_Append_Input>;
  _delete_at_path?: InputMaybe<User_Communications_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Communications_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Communications_Delete_Key_Input>;
  _inc?: InputMaybe<User_Communications_Inc_Input>;
  _prepend?: InputMaybe<User_Communications_Prepend_Input>;
  _set?: InputMaybe<User_Communications_Set_Input>;
  where: User_Communications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Communications_By_PkArgs = {
  _append?: InputMaybe<User_Communications_Append_Input>;
  _delete_at_path?: InputMaybe<User_Communications_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Communications_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Communications_Delete_Key_Input>;
  _inc?: InputMaybe<User_Communications_Inc_Input>;
  _prepend?: InputMaybe<User_Communications_Prepend_Input>;
  _set?: InputMaybe<User_Communications_Set_Input>;
  pk_columns: User_Communications_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Communications_ManyArgs = {
  updates: Array<User_Communications_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Notification_StatusArgs = {
  _set?: InputMaybe<User_Notification_Status_Set_Input>;
  where: User_Notification_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Notification_Status_By_PkArgs = {
  _set?: InputMaybe<User_Notification_Status_Set_Input>;
  pk_columns: User_Notification_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Notification_Status_ManyArgs = {
  updates: Array<User_Notification_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_NotificationsArgs = {
  _append?: InputMaybe<User_Notifications_Append_Input>;
  _delete_at_path?: InputMaybe<User_Notifications_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Notifications_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Notifications_Delete_Key_Input>;
  _inc?: InputMaybe<User_Notifications_Inc_Input>;
  _prepend?: InputMaybe<User_Notifications_Prepend_Input>;
  _set?: InputMaybe<User_Notifications_Set_Input>;
  where: User_Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Notifications_By_PkArgs = {
  _append?: InputMaybe<User_Notifications_Append_Input>;
  _delete_at_path?: InputMaybe<User_Notifications_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Notifications_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Notifications_Delete_Key_Input>;
  _inc?: InputMaybe<User_Notifications_Inc_Input>;
  _prepend?: InputMaybe<User_Notifications_Prepend_Input>;
  _set?: InputMaybe<User_Notifications_Set_Input>;
  pk_columns: User_Notifications_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Notifications_ManyArgs = {
  updates: Array<User_Notifications_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_RolesArgs = {
  _inc?: InputMaybe<User_Roles_Inc_Input>;
  _set?: InputMaybe<User_Roles_Set_Input>;
  where: User_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Roles_By_PkArgs = {
  _inc?: InputMaybe<User_Roles_Inc_Input>;
  _set?: InputMaybe<User_Roles_Set_Input>;
  pk_columns: User_Roles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Roles_ManyArgs = {
  updates: Array<User_Roles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Scanned_DataArgs = {
  _set?: InputMaybe<User_Scanned_Data_Set_Input>;
  where: User_Scanned_Data_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Scanned_Data_By_PkArgs = {
  _set?: InputMaybe<User_Scanned_Data_Set_Input>;
  pk_columns: User_Scanned_Data_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Scanned_Data_ManyArgs = {
  updates: Array<User_Scanned_Data_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _append?: InputMaybe<Users_Append_Input>;
  _delete_at_path?: InputMaybe<Users_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_Delete_Key_Input>;
  _inc?: InputMaybe<Users_Inc_Input>;
  _prepend?: InputMaybe<Users_Prepend_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _append?: InputMaybe<Users_Append_Input>;
  _delete_at_path?: InputMaybe<Users_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_Delete_Key_Input>;
  _inc?: InputMaybe<Users_Inc_Input>;
  _prepend?: InputMaybe<Users_Prepend_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_ManyArgs = {
  updates: Array<Users_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Vonage_AppointmentArgs = {
  _set?: InputMaybe<Vonage_Appointment_Set_Input>;
  where: Vonage_Appointment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Vonage_Appointment_By_PkArgs = {
  _set?: InputMaybe<Vonage_Appointment_Set_Input>;
  pk_columns: Vonage_Appointment_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Vonage_Appointment_ManyArgs = {
  updates: Array<Vonage_Appointment_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Zrt_TestsArgs = {
  _append?: InputMaybe<Zrt_Tests_Append_Input>;
  _delete_at_path?: InputMaybe<Zrt_Tests_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Zrt_Tests_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Zrt_Tests_Delete_Key_Input>;
  _prepend?: InputMaybe<Zrt_Tests_Prepend_Input>;
  _set?: InputMaybe<Zrt_Tests_Set_Input>;
  where: Zrt_Tests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Zrt_Tests_By_PkArgs = {
  _append?: InputMaybe<Zrt_Tests_Append_Input>;
  _delete_at_path?: InputMaybe<Zrt_Tests_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Zrt_Tests_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Zrt_Tests_Delete_Key_Input>;
  _prepend?: InputMaybe<Zrt_Tests_Prepend_Input>;
  _set?: InputMaybe<Zrt_Tests_Set_Input>;
  pk_columns: Zrt_Tests_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Zrt_Tests_ManyArgs = {
  updates: Array<Zrt_Tests_Updates>;
};


/** mutation root */
export type Mutation_RootVerifyFirebaseEmailArgs = {
  email?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "nabla_notes" */
export type Nabla_Notes = {
  __typename?: 'nabla_notes';
  appointment_id: Scalars['String'];
  nabla_notes?: Maybe<Scalars['jsonb']>;
};


/** columns and relationships of "nabla_notes" */
export type Nabla_NotesNabla_NotesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "nabla_notes" */
export type Nabla_Notes_Aggregate = {
  __typename?: 'nabla_notes_aggregate';
  aggregate?: Maybe<Nabla_Notes_Aggregate_Fields>;
  nodes: Array<Nabla_Notes>;
};

/** aggregate fields of "nabla_notes" */
export type Nabla_Notes_Aggregate_Fields = {
  __typename?: 'nabla_notes_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nabla_Notes_Max_Fields>;
  min?: Maybe<Nabla_Notes_Min_Fields>;
};


/** aggregate fields of "nabla_notes" */
export type Nabla_Notes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nabla_Notes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Nabla_Notes_Append_Input = {
  nabla_notes?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "nabla_notes". All fields are combined with a logical 'AND'. */
export type Nabla_Notes_Bool_Exp = {
  _and?: InputMaybe<Array<Nabla_Notes_Bool_Exp>>;
  _not?: InputMaybe<Nabla_Notes_Bool_Exp>;
  _or?: InputMaybe<Array<Nabla_Notes_Bool_Exp>>;
  appointment_id?: InputMaybe<String_Comparison_Exp>;
  nabla_notes?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "nabla_notes" */
export enum Nabla_Notes_Constraint {
  /** unique or primary key constraint on columns "appointment_id" */
  NablaNotesPkey = 'nabla_notes_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Nabla_Notes_Delete_At_Path_Input = {
  nabla_notes?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Nabla_Notes_Delete_Elem_Input = {
  nabla_notes?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Nabla_Notes_Delete_Key_Input = {
  nabla_notes?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "nabla_notes" */
export type Nabla_Notes_Insert_Input = {
  appointment_id?: InputMaybe<Scalars['String']>;
  nabla_notes?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type Nabla_Notes_Max_Fields = {
  __typename?: 'nabla_notes_max_fields';
  appointment_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Nabla_Notes_Min_Fields = {
  __typename?: 'nabla_notes_min_fields';
  appointment_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "nabla_notes" */
export type Nabla_Notes_Mutation_Response = {
  __typename?: 'nabla_notes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nabla_Notes>;
};

/** on_conflict condition type for table "nabla_notes" */
export type Nabla_Notes_On_Conflict = {
  constraint: Nabla_Notes_Constraint;
  update_columns?: Array<Nabla_Notes_Update_Column>;
  where?: InputMaybe<Nabla_Notes_Bool_Exp>;
};

/** Ordering options when selecting data from "nabla_notes". */
export type Nabla_Notes_Order_By = {
  appointment_id?: InputMaybe<Order_By>;
  nabla_notes?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nabla_notes */
export type Nabla_Notes_Pk_Columns_Input = {
  appointment_id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Nabla_Notes_Prepend_Input = {
  nabla_notes?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "nabla_notes" */
export enum Nabla_Notes_Select_Column {
  /** column name */
  AppointmentId = 'appointment_id',
  /** column name */
  NablaNotes = 'nabla_notes'
}

/** input type for updating data in table "nabla_notes" */
export type Nabla_Notes_Set_Input = {
  appointment_id?: InputMaybe<Scalars['String']>;
  nabla_notes?: InputMaybe<Scalars['jsonb']>;
};

/** Streaming cursor of the table "nabla_notes" */
export type Nabla_Notes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nabla_Notes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nabla_Notes_Stream_Cursor_Value_Input = {
  appointment_id?: InputMaybe<Scalars['String']>;
  nabla_notes?: InputMaybe<Scalars['jsonb']>;
};

/** update columns of table "nabla_notes" */
export enum Nabla_Notes_Update_Column {
  /** column name */
  AppointmentId = 'appointment_id',
  /** column name */
  NablaNotes = 'nabla_notes'
}

export type Nabla_Notes_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Nabla_Notes_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Nabla_Notes_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Nabla_Notes_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Nabla_Notes_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Nabla_Notes_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nabla_Notes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Nabla_Notes_Bool_Exp;
};

/** columns and relationships of "npi_registry_data" */
export type Npi_Registry_Data = {
  __typename?: 'npi_registry_data';
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  npi_data: Scalars['jsonb'];
  npi_number: Scalars['String'];
};


/** columns and relationships of "npi_registry_data" */
export type Npi_Registry_DataNpi_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "npi_registry_data" */
export type Npi_Registry_Data_Aggregate = {
  __typename?: 'npi_registry_data_aggregate';
  aggregate?: Maybe<Npi_Registry_Data_Aggregate_Fields>;
  nodes: Array<Npi_Registry_Data>;
};

/** aggregate fields of "npi_registry_data" */
export type Npi_Registry_Data_Aggregate_Fields = {
  __typename?: 'npi_registry_data_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Npi_Registry_Data_Max_Fields>;
  min?: Maybe<Npi_Registry_Data_Min_Fields>;
};


/** aggregate fields of "npi_registry_data" */
export type Npi_Registry_Data_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Npi_Registry_Data_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Npi_Registry_Data_Append_Input = {
  npi_data?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "npi_registry_data". All fields are combined with a logical 'AND'. */
export type Npi_Registry_Data_Bool_Exp = {
  _and?: InputMaybe<Array<Npi_Registry_Data_Bool_Exp>>;
  _not?: InputMaybe<Npi_Registry_Data_Bool_Exp>;
  _or?: InputMaybe<Array<Npi_Registry_Data_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  npi_data?: InputMaybe<Jsonb_Comparison_Exp>;
  npi_number?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "npi_registry_data" */
export enum Npi_Registry_Data_Constraint {
  /** unique or primary key constraint on columns "id" */
  NpiRegistryDataPkey = 'npi_registry_data_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Npi_Registry_Data_Delete_At_Path_Input = {
  npi_data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Npi_Registry_Data_Delete_Elem_Input = {
  npi_data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Npi_Registry_Data_Delete_Key_Input = {
  npi_data?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "npi_registry_data" */
export type Npi_Registry_Data_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  npi_data?: InputMaybe<Scalars['jsonb']>;
  npi_number?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Npi_Registry_Data_Max_Fields = {
  __typename?: 'npi_registry_data_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  npi_number?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Npi_Registry_Data_Min_Fields = {
  __typename?: 'npi_registry_data_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  npi_number?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "npi_registry_data" */
export type Npi_Registry_Data_Mutation_Response = {
  __typename?: 'npi_registry_data_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Npi_Registry_Data>;
};

/** input type for inserting object relation for remote table "npi_registry_data" */
export type Npi_Registry_Data_Obj_Rel_Insert_Input = {
  data: Npi_Registry_Data_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Npi_Registry_Data_On_Conflict>;
};

/** on_conflict condition type for table "npi_registry_data" */
export type Npi_Registry_Data_On_Conflict = {
  constraint: Npi_Registry_Data_Constraint;
  update_columns?: Array<Npi_Registry_Data_Update_Column>;
  where?: InputMaybe<Npi_Registry_Data_Bool_Exp>;
};

/** Ordering options when selecting data from "npi_registry_data". */
export type Npi_Registry_Data_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  npi_data?: InputMaybe<Order_By>;
  npi_number?: InputMaybe<Order_By>;
};

/** primary key columns input for table: npi_registry_data */
export type Npi_Registry_Data_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Npi_Registry_Data_Prepend_Input = {
  npi_data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "npi_registry_data" */
export enum Npi_Registry_Data_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  NpiData = 'npi_data',
  /** column name */
  NpiNumber = 'npi_number'
}

/** input type for updating data in table "npi_registry_data" */
export type Npi_Registry_Data_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  npi_data?: InputMaybe<Scalars['jsonb']>;
  npi_number?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "npi_registry_data" */
export type Npi_Registry_Data_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Npi_Registry_Data_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Npi_Registry_Data_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  npi_data?: InputMaybe<Scalars['jsonb']>;
  npi_number?: InputMaybe<Scalars['String']>;
};

/** update columns of table "npi_registry_data" */
export enum Npi_Registry_Data_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  NpiData = 'npi_data',
  /** column name */
  NpiNumber = 'npi_number'
}

export type Npi_Registry_Data_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Npi_Registry_Data_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Npi_Registry_Data_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Npi_Registry_Data_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Npi_Registry_Data_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Npi_Registry_Data_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Npi_Registry_Data_Set_Input>;
  /** filter the rows which have to be updated */
  where: Npi_Registry_Data_Bool_Exp;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "persona_inquiries" */
export type Persona_Inquiries = {
  __typename?: 'persona_inquiries';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  persona_event_id: Scalars['String'];
  /** An object relationship */
  persona_inquiries_persona_inquiry_status: Persona_Inquiry_Status;
  /** An object relationship */
  persona_inquiries_user: Users;
  persona_inquiry_id: Scalars['String'];
  status: Persona_Inquiry_Status_Enum;
  status_applied: Scalars['Boolean'];
  user_id: Scalars['uuid'];
};

/** aggregated selection of "persona_inquiries" */
export type Persona_Inquiries_Aggregate = {
  __typename?: 'persona_inquiries_aggregate';
  aggregate?: Maybe<Persona_Inquiries_Aggregate_Fields>;
  nodes: Array<Persona_Inquiries>;
};

/** aggregate fields of "persona_inquiries" */
export type Persona_Inquiries_Aggregate_Fields = {
  __typename?: 'persona_inquiries_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Persona_Inquiries_Max_Fields>;
  min?: Maybe<Persona_Inquiries_Min_Fields>;
};


/** aggregate fields of "persona_inquiries" */
export type Persona_Inquiries_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Persona_Inquiries_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "persona_inquiries". All fields are combined with a logical 'AND'. */
export type Persona_Inquiries_Bool_Exp = {
  _and?: InputMaybe<Array<Persona_Inquiries_Bool_Exp>>;
  _not?: InputMaybe<Persona_Inquiries_Bool_Exp>;
  _or?: InputMaybe<Array<Persona_Inquiries_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  persona_event_id?: InputMaybe<String_Comparison_Exp>;
  persona_inquiries_persona_inquiry_status?: InputMaybe<Persona_Inquiry_Status_Bool_Exp>;
  persona_inquiries_user?: InputMaybe<Users_Bool_Exp>;
  persona_inquiry_id?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Persona_Inquiry_Status_Enum_Comparison_Exp>;
  status_applied?: InputMaybe<Boolean_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "persona_inquiries" */
export enum Persona_Inquiries_Constraint {
  /** unique or primary key constraint on columns "id" */
  PersonaInquiriesPkey = 'persona_inquiries_pkey'
}

/** input type for inserting data into table "persona_inquiries" */
export type Persona_Inquiries_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  persona_event_id?: InputMaybe<Scalars['String']>;
  persona_inquiries_persona_inquiry_status?: InputMaybe<Persona_Inquiry_Status_Obj_Rel_Insert_Input>;
  persona_inquiries_user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  persona_inquiry_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Persona_Inquiry_Status_Enum>;
  status_applied?: InputMaybe<Scalars['Boolean']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Persona_Inquiries_Max_Fields = {
  __typename?: 'persona_inquiries_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  persona_event_id?: Maybe<Scalars['String']>;
  persona_inquiry_id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Persona_Inquiries_Min_Fields = {
  __typename?: 'persona_inquiries_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  persona_event_id?: Maybe<Scalars['String']>;
  persona_inquiry_id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "persona_inquiries" */
export type Persona_Inquiries_Mutation_Response = {
  __typename?: 'persona_inquiries_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Persona_Inquiries>;
};

/** on_conflict condition type for table "persona_inquiries" */
export type Persona_Inquiries_On_Conflict = {
  constraint: Persona_Inquiries_Constraint;
  update_columns?: Array<Persona_Inquiries_Update_Column>;
  where?: InputMaybe<Persona_Inquiries_Bool_Exp>;
};

/** Ordering options when selecting data from "persona_inquiries". */
export type Persona_Inquiries_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  persona_event_id?: InputMaybe<Order_By>;
  persona_inquiries_persona_inquiry_status?: InputMaybe<Persona_Inquiry_Status_Order_By>;
  persona_inquiries_user?: InputMaybe<Users_Order_By>;
  persona_inquiry_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  status_applied?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: persona_inquiries */
export type Persona_Inquiries_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "persona_inquiries" */
export enum Persona_Inquiries_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PersonaEventId = 'persona_event_id',
  /** column name */
  PersonaInquiryId = 'persona_inquiry_id',
  /** column name */
  Status = 'status',
  /** column name */
  StatusApplied = 'status_applied',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "persona_inquiries" */
export type Persona_Inquiries_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  persona_event_id?: InputMaybe<Scalars['String']>;
  persona_inquiry_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Persona_Inquiry_Status_Enum>;
  status_applied?: InputMaybe<Scalars['Boolean']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "persona_inquiries" */
export type Persona_Inquiries_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Persona_Inquiries_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Persona_Inquiries_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  persona_event_id?: InputMaybe<Scalars['String']>;
  persona_inquiry_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Persona_Inquiry_Status_Enum>;
  status_applied?: InputMaybe<Scalars['Boolean']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "persona_inquiries" */
export enum Persona_Inquiries_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PersonaEventId = 'persona_event_id',
  /** column name */
  PersonaInquiryId = 'persona_inquiry_id',
  /** column name */
  Status = 'status',
  /** column name */
  StatusApplied = 'status_applied',
  /** column name */
  UserId = 'user_id'
}

export type Persona_Inquiries_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Persona_Inquiries_Set_Input>;
  /** filter the rows which have to be updated */
  where: Persona_Inquiries_Bool_Exp;
};

/** Status of persona inquiry */
export type Persona_Inquiry_Status = {
  __typename?: 'persona_inquiry_status';
  comment?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "persona_inquiry_status" */
export type Persona_Inquiry_Status_Aggregate = {
  __typename?: 'persona_inquiry_status_aggregate';
  aggregate?: Maybe<Persona_Inquiry_Status_Aggregate_Fields>;
  nodes: Array<Persona_Inquiry_Status>;
};

/** aggregate fields of "persona_inquiry_status" */
export type Persona_Inquiry_Status_Aggregate_Fields = {
  __typename?: 'persona_inquiry_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Persona_Inquiry_Status_Max_Fields>;
  min?: Maybe<Persona_Inquiry_Status_Min_Fields>;
};


/** aggregate fields of "persona_inquiry_status" */
export type Persona_Inquiry_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Persona_Inquiry_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "persona_inquiry_status". All fields are combined with a logical 'AND'. */
export type Persona_Inquiry_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Persona_Inquiry_Status_Bool_Exp>>;
  _not?: InputMaybe<Persona_Inquiry_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Persona_Inquiry_Status_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "persona_inquiry_status" */
export enum Persona_Inquiry_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  PersonaInquiryStatusPkey = 'persona_inquiry_status_pkey'
}

export enum Persona_Inquiry_Status_Enum {
  Approved = 'APPROVED',
  Completed = 'COMPLETED',
  Created = 'CREATED',
  Declined = 'DECLINED',
  Expired = 'EXPIRED',
  Failed = 'FAILED',
  Locked = 'LOCKED',
  MarkedForReview = 'MARKED_FOR_REVIEW',
  Started = 'STARTED',
  Transitioned = 'TRANSITIONED'
}

/** Boolean expression to compare columns of type "persona_inquiry_status_enum". All fields are combined with logical 'AND'. */
export type Persona_Inquiry_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Persona_Inquiry_Status_Enum>;
  _in?: InputMaybe<Array<Persona_Inquiry_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Persona_Inquiry_Status_Enum>;
  _nin?: InputMaybe<Array<Persona_Inquiry_Status_Enum>>;
};

/** input type for inserting data into table "persona_inquiry_status" */
export type Persona_Inquiry_Status_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Persona_Inquiry_Status_Max_Fields = {
  __typename?: 'persona_inquiry_status_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Persona_Inquiry_Status_Min_Fields = {
  __typename?: 'persona_inquiry_status_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "persona_inquiry_status" */
export type Persona_Inquiry_Status_Mutation_Response = {
  __typename?: 'persona_inquiry_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Persona_Inquiry_Status>;
};

/** input type for inserting object relation for remote table "persona_inquiry_status" */
export type Persona_Inquiry_Status_Obj_Rel_Insert_Input = {
  data: Persona_Inquiry_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Persona_Inquiry_Status_On_Conflict>;
};

/** on_conflict condition type for table "persona_inquiry_status" */
export type Persona_Inquiry_Status_On_Conflict = {
  constraint: Persona_Inquiry_Status_Constraint;
  update_columns?: Array<Persona_Inquiry_Status_Update_Column>;
  where?: InputMaybe<Persona_Inquiry_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "persona_inquiry_status". */
export type Persona_Inquiry_Status_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: persona_inquiry_status */
export type Persona_Inquiry_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "persona_inquiry_status" */
export enum Persona_Inquiry_Status_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "persona_inquiry_status" */
export type Persona_Inquiry_Status_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "persona_inquiry_status" */
export type Persona_Inquiry_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Persona_Inquiry_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Persona_Inquiry_Status_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "persona_inquiry_status" */
export enum Persona_Inquiry_Status_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Persona_Inquiry_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Persona_Inquiry_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Persona_Inquiry_Status_Bool_Exp;
};

/** Tracks unread notifications for provider */
export type Provider_Unread_External_Notification_Counts = {
  __typename?: 'provider_unread_external_notification_counts';
  codex_provider_id: Scalars['uuid'];
  dosespot_unread_notifications: Scalars['numeric'];
  fhir_provider_id: Scalars['uuid'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "provider_unread_external_notification_counts" */
export type Provider_Unread_External_Notification_Counts_Aggregate = {
  __typename?: 'provider_unread_external_notification_counts_aggregate';
  aggregate?: Maybe<Provider_Unread_External_Notification_Counts_Aggregate_Fields>;
  nodes: Array<Provider_Unread_External_Notification_Counts>;
};

/** aggregate fields of "provider_unread_external_notification_counts" */
export type Provider_Unread_External_Notification_Counts_Aggregate_Fields = {
  __typename?: 'provider_unread_external_notification_counts_aggregate_fields';
  avg?: Maybe<Provider_Unread_External_Notification_Counts_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Provider_Unread_External_Notification_Counts_Max_Fields>;
  min?: Maybe<Provider_Unread_External_Notification_Counts_Min_Fields>;
  stddev?: Maybe<Provider_Unread_External_Notification_Counts_Stddev_Fields>;
  stddev_pop?: Maybe<Provider_Unread_External_Notification_Counts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Provider_Unread_External_Notification_Counts_Stddev_Samp_Fields>;
  sum?: Maybe<Provider_Unread_External_Notification_Counts_Sum_Fields>;
  var_pop?: Maybe<Provider_Unread_External_Notification_Counts_Var_Pop_Fields>;
  var_samp?: Maybe<Provider_Unread_External_Notification_Counts_Var_Samp_Fields>;
  variance?: Maybe<Provider_Unread_External_Notification_Counts_Variance_Fields>;
};


/** aggregate fields of "provider_unread_external_notification_counts" */
export type Provider_Unread_External_Notification_Counts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Provider_Unread_External_Notification_Counts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Provider_Unread_External_Notification_Counts_Avg_Fields = {
  __typename?: 'provider_unread_external_notification_counts_avg_fields';
  dosespot_unread_notifications?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "provider_unread_external_notification_counts". All fields are combined with a logical 'AND'. */
export type Provider_Unread_External_Notification_Counts_Bool_Exp = {
  _and?: InputMaybe<Array<Provider_Unread_External_Notification_Counts_Bool_Exp>>;
  _not?: InputMaybe<Provider_Unread_External_Notification_Counts_Bool_Exp>;
  _or?: InputMaybe<Array<Provider_Unread_External_Notification_Counts_Bool_Exp>>;
  codex_provider_id?: InputMaybe<Uuid_Comparison_Exp>;
  dosespot_unread_notifications?: InputMaybe<Numeric_Comparison_Exp>;
  fhir_provider_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "provider_unread_external_notification_counts" */
export enum Provider_Unread_External_Notification_Counts_Constraint {
  /** unique or primary key constraint on columns "codex_provider_id" */
  ProviderUnreadExternalNotificationCountsPkey = 'provider_unread_external_notification_counts_pkey'
}

/** input type for incrementing numeric columns in table "provider_unread_external_notification_counts" */
export type Provider_Unread_External_Notification_Counts_Inc_Input = {
  dosespot_unread_notifications?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "provider_unread_external_notification_counts" */
export type Provider_Unread_External_Notification_Counts_Insert_Input = {
  codex_provider_id?: InputMaybe<Scalars['uuid']>;
  dosespot_unread_notifications?: InputMaybe<Scalars['numeric']>;
  fhir_provider_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Provider_Unread_External_Notification_Counts_Max_Fields = {
  __typename?: 'provider_unread_external_notification_counts_max_fields';
  codex_provider_id?: Maybe<Scalars['uuid']>;
  dosespot_unread_notifications?: Maybe<Scalars['numeric']>;
  fhir_provider_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Provider_Unread_External_Notification_Counts_Min_Fields = {
  __typename?: 'provider_unread_external_notification_counts_min_fields';
  codex_provider_id?: Maybe<Scalars['uuid']>;
  dosespot_unread_notifications?: Maybe<Scalars['numeric']>;
  fhir_provider_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "provider_unread_external_notification_counts" */
export type Provider_Unread_External_Notification_Counts_Mutation_Response = {
  __typename?: 'provider_unread_external_notification_counts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Provider_Unread_External_Notification_Counts>;
};

/** on_conflict condition type for table "provider_unread_external_notification_counts" */
export type Provider_Unread_External_Notification_Counts_On_Conflict = {
  constraint: Provider_Unread_External_Notification_Counts_Constraint;
  update_columns?: Array<Provider_Unread_External_Notification_Counts_Update_Column>;
  where?: InputMaybe<Provider_Unread_External_Notification_Counts_Bool_Exp>;
};

/** Ordering options when selecting data from "provider_unread_external_notification_counts". */
export type Provider_Unread_External_Notification_Counts_Order_By = {
  codex_provider_id?: InputMaybe<Order_By>;
  dosespot_unread_notifications?: InputMaybe<Order_By>;
  fhir_provider_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: provider_unread_external_notification_counts */
export type Provider_Unread_External_Notification_Counts_Pk_Columns_Input = {
  codex_provider_id: Scalars['uuid'];
};

/** select columns of table "provider_unread_external_notification_counts" */
export enum Provider_Unread_External_Notification_Counts_Select_Column {
  /** column name */
  CodexProviderId = 'codex_provider_id',
  /** column name */
  DosespotUnreadNotifications = 'dosespot_unread_notifications',
  /** column name */
  FhirProviderId = 'fhir_provider_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "provider_unread_external_notification_counts" */
export type Provider_Unread_External_Notification_Counts_Set_Input = {
  codex_provider_id?: InputMaybe<Scalars['uuid']>;
  dosespot_unread_notifications?: InputMaybe<Scalars['numeric']>;
  fhir_provider_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Provider_Unread_External_Notification_Counts_Stddev_Fields = {
  __typename?: 'provider_unread_external_notification_counts_stddev_fields';
  dosespot_unread_notifications?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Provider_Unread_External_Notification_Counts_Stddev_Pop_Fields = {
  __typename?: 'provider_unread_external_notification_counts_stddev_pop_fields';
  dosespot_unread_notifications?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Provider_Unread_External_Notification_Counts_Stddev_Samp_Fields = {
  __typename?: 'provider_unread_external_notification_counts_stddev_samp_fields';
  dosespot_unread_notifications?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "provider_unread_external_notification_counts" */
export type Provider_Unread_External_Notification_Counts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Provider_Unread_External_Notification_Counts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Provider_Unread_External_Notification_Counts_Stream_Cursor_Value_Input = {
  codex_provider_id?: InputMaybe<Scalars['uuid']>;
  dosespot_unread_notifications?: InputMaybe<Scalars['numeric']>;
  fhir_provider_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Provider_Unread_External_Notification_Counts_Sum_Fields = {
  __typename?: 'provider_unread_external_notification_counts_sum_fields';
  dosespot_unread_notifications?: Maybe<Scalars['numeric']>;
};

/** update columns of table "provider_unread_external_notification_counts" */
export enum Provider_Unread_External_Notification_Counts_Update_Column {
  /** column name */
  CodexProviderId = 'codex_provider_id',
  /** column name */
  DosespotUnreadNotifications = 'dosespot_unread_notifications',
  /** column name */
  FhirProviderId = 'fhir_provider_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Provider_Unread_External_Notification_Counts_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Provider_Unread_External_Notification_Counts_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Provider_Unread_External_Notification_Counts_Set_Input>;
  /** filter the rows which have to be updated */
  where: Provider_Unread_External_Notification_Counts_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Provider_Unread_External_Notification_Counts_Var_Pop_Fields = {
  __typename?: 'provider_unread_external_notification_counts_var_pop_fields';
  dosespot_unread_notifications?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Provider_Unread_External_Notification_Counts_Var_Samp_Fields = {
  __typename?: 'provider_unread_external_notification_counts_var_samp_fields';
  dosespot_unread_notifications?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Provider_Unread_External_Notification_Counts_Variance_Fields = {
  __typename?: 'provider_unread_external_notification_counts_variance_fields';
  dosespot_unread_notifications?: Maybe<Scalars['Float']>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** Creates kit ids batch with ZRT */
  CreateKitIdsBatch?: Maybe<ZrtKitIdsBatchOutput>;
  /** Action that creates custom auth code for user login */
  GetCustomAuthCodeToLogin?: Maybe<CustomAuthCode>;
  GetCustomAuthTokenFromCustomAuthCode: CustomAuthTokenFromCustomAuthCode;
  /** Gets provider time slots and block times from Acuity */
  GetProviderTimeSlots: ProviderWorkingHoursAndBlockedTime;
  /** Checks if swab is valid and returns its data */
  GetSwabCodeValidBySwabcode?: Maybe<IsValidSwabCode>;
  /** Query to validate fedex tracking number */
  ValidateFedexTrackingNumber: FedexTrackingResponse;
  /** fetch data from the table: "accepted_legal_documents" */
  accepted_legal_documents: Array<Accepted_Legal_Documents>;
  /** fetch aggregated fields from the table: "accepted_legal_documents" */
  accepted_legal_documents_aggregate: Accepted_Legal_Documents_Aggregate;
  /** fetch data from the table: "accepted_legal_documents" using primary key columns */
  accepted_legal_documents_by_pk?: Maybe<Accepted_Legal_Documents>;
  /** fetch data from the table: "acuity_provider_calendar" */
  acuity_provider_calendar: Array<Acuity_Provider_Calendar>;
  /** fetch aggregated fields from the table: "acuity_provider_calendar" */
  acuity_provider_calendar_aggregate: Acuity_Provider_Calendar_Aggregate;
  /** fetch data from the table: "acuity_provider_calendar" using primary key columns */
  acuity_provider_calendar_by_pk?: Maybe<Acuity_Provider_Calendar>;
  /** fetch data from the table: "addresses" */
  addresses: Array<Addresses>;
  /** fetch aggregated fields from the table: "addresses" */
  addresses_aggregate: Addresses_Aggregate;
  /** fetch data from the table: "addresses" using primary key columns */
  addresses_by_pk?: Maybe<Addresses>;
  /** fetch data from the table: "channel_signals" */
  channel_signals: Array<Channel_Signals>;
  /** fetch aggregated fields from the table: "channel_signals" */
  channel_signals_aggregate: Channel_Signals_Aggregate;
  /** fetch data from the table: "channel_signals" using primary key columns */
  channel_signals_by_pk?: Maybe<Channel_Signals>;
  /** fetch data from the table: "common_question_responses" */
  common_question_responses: Array<Common_Question_Responses>;
  /** fetch aggregated fields from the table: "common_question_responses" */
  common_question_responses_aggregate: Common_Question_Responses_Aggregate;
  /** fetch data from the table: "common_question_responses" using primary key columns */
  common_question_responses_by_pk?: Maybe<Common_Question_Responses>;
  /** fetch data from the table: "common_questions" */
  common_questions: Array<Common_Questions>;
  /** fetch aggregated fields from the table: "common_questions" */
  common_questions_aggregate: Common_Questions_Aggregate;
  /** fetch data from the table: "common_questions" using primary key columns */
  common_questions_by_pk?: Maybe<Common_Questions>;
  /** fetch data from the table: "communication_channels" */
  communication_channels: Array<Communication_Channels>;
  /** fetch aggregated fields from the table: "communication_channels" */
  communication_channels_aggregate: Communication_Channels_Aggregate;
  /** fetch data from the table: "communication_channels" using primary key columns */
  communication_channels_by_pk?: Maybe<Communication_Channels>;
  /** fetch data from the table: "countries" */
  countries: Array<Countries>;
  /** fetch aggregated fields from the table: "countries" */
  countries_aggregate: Countries_Aggregate;
  /** fetch data from the table: "countries" using primary key columns */
  countries_by_pk?: Maybe<Countries>;
  /** fetch data from the table: "custom_auth_code" */
  custom_auth_code: Array<Custom_Auth_Code>;
  /** fetch aggregated fields from the table: "custom_auth_code" */
  custom_auth_code_aggregate: Custom_Auth_Code_Aggregate;
  /** fetch data from the table: "custom_auth_code" using primary key columns */
  custom_auth_code_by_pk?: Maybe<Custom_Auth_Code>;
  /** fetch data from the table: "dnavisit_test_statuses" */
  dnavisit_test_statuses: Array<Dnavisit_Test_Statuses>;
  /** fetch aggregated fields from the table: "dnavisit_test_statuses" */
  dnavisit_test_statuses_aggregate: Dnavisit_Test_Statuses_Aggregate;
  /** fetch data from the table: "dnavisit_test_statuses" using primary key columns */
  dnavisit_test_statuses_by_pk?: Maybe<Dnavisit_Test_Statuses>;
  /** fetch data from the table: "dnavisit_tests" */
  dnavisit_tests: Array<Dnavisit_Tests>;
  /** fetch aggregated fields from the table: "dnavisit_tests" */
  dnavisit_tests_aggregate: Dnavisit_Tests_Aggregate;
  /** fetch data from the table: "dnavisit_tests" using primary key columns */
  dnavisit_tests_by_pk?: Maybe<Dnavisit_Tests>;
  /** fetch data from the table: "do_not_use_production_swab_codes" */
  do_not_use_production_swab_codes: Array<Do_Not_Use_Production_Swab_Codes>;
  /** fetch aggregated fields from the table: "do_not_use_production_swab_codes" */
  do_not_use_production_swab_codes_aggregate: Do_Not_Use_Production_Swab_Codes_Aggregate;
  /** fetch data from the table: "do_not_use_production_swab_codes" using primary key columns */
  do_not_use_production_swab_codes_by_pk?: Maybe<Do_Not_Use_Production_Swab_Codes>;
  /** fetch data from the table: "dosespot_clinicians" */
  dosespot_clinicians: Array<Dosespot_Clinicians>;
  /** fetch aggregated fields from the table: "dosespot_clinicians" */
  dosespot_clinicians_aggregate: Dosespot_Clinicians_Aggregate;
  /** fetch data from the table: "dosespot_clinicians" using primary key columns */
  dosespot_clinicians_by_pk?: Maybe<Dosespot_Clinicians>;
  /** fetch data from the table: "file_types" */
  file_types: Array<File_Types>;
  /** fetch aggregated fields from the table: "file_types" */
  file_types_aggregate: File_Types_Aggregate;
  /** fetch data from the table: "file_types" using primary key columns */
  file_types_by_pk?: Maybe<File_Types>;
  /** fetch data from the table: "files" */
  files: Array<Files>;
  /** fetch aggregated fields from the table: "files" */
  files_aggregate: Files_Aggregate;
  /** fetch data from the table: "files" using primary key columns */
  files_by_pk?: Maybe<Files>;
  /** fetch data from the table: "fullscript_clinicians" */
  fullscript_clinicians: Array<Fullscript_Clinicians>;
  /** fetch aggregated fields from the table: "fullscript_clinicians" */
  fullscript_clinicians_aggregate: Fullscript_Clinicians_Aggregate;
  /** fetch data from the table: "fullscript_clinicians" using primary key columns */
  fullscript_clinicians_by_pk?: Maybe<Fullscript_Clinicians>;
  getAppointmentMessageByCommunicationFhirId: GetAppointmentChatMessagesOutput;
  getAppointmentMessageListByAppointmentCodexId: GetAppointmentChatMessagesOutput;
  getAppointmentNotesByAppointmentId: GetAppointmentNotesByAppointmentIdOutput;
  getAppointmentRescheduleLink: GetAppointmentRescheduleLinkOutput;
  getAppointments: SaAppointments;
  getDiagnosticReports: SaDiagnosticReports;
  getFHIRAppointmentByAcuityId: GetFhirAppointmentOutput;
  getFHIRAppointmentByCodexId: GetFhirAppointmentOutput;
  getFHIRAppointmentByPatientCodexId: GetFhirAppointmentsOutput;
  getFHIRAppointmentByRequestPatientCodexId: GetFhirAppointmentsOutputWithUpcomingCounts;
  getFHIRAppointmentByRequestProviderId: GetFhirAppointmentsOutputWithUpcomingCounts;
  getFHIRDnaAndZrtTests: GetFhirDnaAndZrtTests;
  getFHIRDnaVisitTestByOrderId: GetFhirDnaVisitTestByIdentifier;
  getFHIRDnaVisitTestBySwabcode: GetFhirDnaVisitTestByIdentifier;
  getFHIRDnaVisitTestByTestId: GetFhirDnaVisitTestByIdentifier;
  getFHIRDnaVisitTests: GetFhirDnaVisitTests;
  getFHIRDnaVisitTestsByUserId: GetFhirDnaVisitTestsByUserId;
  getFHIRMediaByRequestPatientCodexID: GetFhirPatientMediaOutput;
  getFHIRMedicationRequestBranAndNamesByPatientCodexId: GetMedicationRequestsBrandAndNameOutput;
  getFHIRMedicationRequestByFhirId: GetMedicationRequestsOutput;
  getFHIRMedicationRequestByPatientCodexId: GetMedicationRequestsOutput;
  getFHIRPatientByFHIRID: GetFhirPatientOutput;
  getFHIRPatientbyCodexID: GetFhirPatientOutput;
  getFHIRPatientbyCodexIDParameter: GetFhirPatientOutput;
  getFHIRPatientbyEmail: GetFhirPatientOutput;
  getFHIRPatientbyMediaCodexIDParameter: GetFhirPatientMediaOutput;
  getFHIRPatientsById: GetFhirPatientsByProviderIdOutput;
  getFHIRProviderBioById: GetFhirProviderBioByIdOutput;
  getFHIRProviderByRequestId: GetFhirProviderByIdOutput;
  getFHIRProviderbyCodexIdArgument: GetProviderBioByIdQueryOutput;
  getFHIRProviderbyEmail: GetFhirProviderByEmailOutput;
  getFHIRProviderbyId: GetFhirProviderByIdOutput;
  getFHIRProviders: GetFhirProvidersOutput;
  getFHIRQuestionnaireByCodexID: GetQuestionnaireOutput;
  getFHIRQuestionnaireResponse: GetFhirQuestionnaireResponse;
  getFHIRSkintelligentTestBySessionId: GetFhirSkintelligentTestByIdentifier;
  getFHIRSkintelligentTestBySessionIdAndUserId: GetFhirSkintelligentTestByIdentifierAndUserId;
  getFHIRSkintelligentTestByTestIdAndUserId: GetFhirSkintelligentTestByIdentifierAndUserId;
  getFHIRSkintelligentTests: GetFhirSkintelligentTests;
  getFHIRSkintelligentTestsByUserId: GetFhirSkintelligentTestsByUserId;
  getFHIRZrtTestByTestId: GetFhirZrtTest;
  getFHIRZrtTests: GetFhirZrtTests;
  getLatestFHIRQuestionnaireById: GetLatestFhirQuestionnaireByIdResponse;
  getPatients: SaPatients;
  getProviderByPatientId: GetFhirProvidersOutput;
  getProviders: SaProviders;
  getProvidersByPatientId: GetFhirProviderByIdOutput;
  getSessionGrant: FullscriptSessionGrantResult;
  /** fetch data from the table: "global_configs" */
  global_configs: Array<Global_Configs>;
  /** fetch aggregated fields from the table: "global_configs" */
  global_configs_aggregate: Global_Configs_Aggregate;
  /** fetch data from the table: "global_configs" using primary key columns */
  global_configs_by_pk?: Maybe<Global_Configs>;
  /** fetch data from the table: "global_configs_names" */
  global_configs_names: Array<Global_Configs_Names>;
  /** fetch aggregated fields from the table: "global_configs_names" */
  global_configs_names_aggregate: Global_Configs_Names_Aggregate;
  /** fetch data from the table: "global_configs_names" using primary key columns */
  global_configs_names_by_pk?: Maybe<Global_Configs_Names>;
  health: Scalars['String'];
  /** fetch data from the table: "image_source_types" */
  image_source_types: Array<Image_Source_Types>;
  /** fetch aggregated fields from the table: "image_source_types" */
  image_source_types_aggregate: Image_Source_Types_Aggregate;
  /** fetch data from the table: "image_source_types" using primary key columns */
  image_source_types_by_pk?: Maybe<Image_Source_Types>;
  /** fetch data from the table: "labs" */
  labs: Array<Labs>;
  /** fetch aggregated fields from the table: "labs" */
  labs_aggregate: Labs_Aggregate;
  /** fetch data from the table: "labs" using primary key columns */
  labs_by_pk?: Maybe<Labs>;
  /** fetch data from the table: "legal_document_types" */
  legal_document_types: Array<Legal_Document_Types>;
  /** fetch aggregated fields from the table: "legal_document_types" */
  legal_document_types_aggregate: Legal_Document_Types_Aggregate;
  /** fetch data from the table: "legal_document_types" using primary key columns */
  legal_document_types_by_pk?: Maybe<Legal_Document_Types>;
  /** fetch data from the table: "legal_documents" */
  legal_documents: Array<Legal_Documents>;
  /** fetch aggregated fields from the table: "legal_documents" */
  legal_documents_aggregate: Legal_Documents_Aggregate;
  /** fetch data from the table: "legal_documents" using primary key columns */
  legal_documents_by_pk?: Maybe<Legal_Documents>;
  /** fetch data from the table: "medical_schools" */
  medical_schools: Array<Medical_Schools>;
  /** fetch aggregated fields from the table: "medical_schools" */
  medical_schools_aggregate: Medical_Schools_Aggregate;
  /** fetch data from the table: "medical_schools" using primary key columns */
  medical_schools_by_pk?: Maybe<Medical_Schools>;
  /** fetch data from the table: "nabla_notes" */
  nabla_notes: Array<Nabla_Notes>;
  /** fetch aggregated fields from the table: "nabla_notes" */
  nabla_notes_aggregate: Nabla_Notes_Aggregate;
  /** fetch data from the table: "nabla_notes" using primary key columns */
  nabla_notes_by_pk?: Maybe<Nabla_Notes>;
  /** fetch data from the table: "npi_registry_data" */
  npi_registry_data: Array<Npi_Registry_Data>;
  /** fetch aggregated fields from the table: "npi_registry_data" */
  npi_registry_data_aggregate: Npi_Registry_Data_Aggregate;
  /** fetch data from the table: "npi_registry_data" using primary key columns */
  npi_registry_data_by_pk?: Maybe<Npi_Registry_Data>;
  /** fetch data from the table: "persona_inquiries" */
  persona_inquiries: Array<Persona_Inquiries>;
  /** fetch aggregated fields from the table: "persona_inquiries" */
  persona_inquiries_aggregate: Persona_Inquiries_Aggregate;
  /** fetch data from the table: "persona_inquiries" using primary key columns */
  persona_inquiries_by_pk?: Maybe<Persona_Inquiries>;
  /** fetch data from the table: "persona_inquiry_status" */
  persona_inquiry_status: Array<Persona_Inquiry_Status>;
  /** fetch aggregated fields from the table: "persona_inquiry_status" */
  persona_inquiry_status_aggregate: Persona_Inquiry_Status_Aggregate;
  /** fetch data from the table: "persona_inquiry_status" using primary key columns */
  persona_inquiry_status_by_pk?: Maybe<Persona_Inquiry_Status>;
  /** fetch data from the table: "provider_unread_external_notification_counts" */
  provider_unread_external_notification_counts: Array<Provider_Unread_External_Notification_Counts>;
  /** fetch aggregated fields from the table: "provider_unread_external_notification_counts" */
  provider_unread_external_notification_counts_aggregate: Provider_Unread_External_Notification_Counts_Aggregate;
  /** fetch data from the table: "provider_unread_external_notification_counts" using primary key columns */
  provider_unread_external_notification_counts_by_pk?: Maybe<Provider_Unread_External_Notification_Counts>;
  /** fetch data from the table: "question_measurement_units" */
  question_measurement_units: Array<Question_Measurement_Units>;
  /** fetch aggregated fields from the table: "question_measurement_units" */
  question_measurement_units_aggregate: Question_Measurement_Units_Aggregate;
  /** fetch data from the table: "question_measurement_units" using primary key columns */
  question_measurement_units_by_pk?: Maybe<Question_Measurement_Units>;
  /** fetch data from the table: "question_types" */
  question_types: Array<Question_Types>;
  /** fetch aggregated fields from the table: "question_types" */
  question_types_aggregate: Question_Types_Aggregate;
  /** fetch data from the table: "question_types" using primary key columns */
  question_types_by_pk?: Maybe<Question_Types>;
  /** fetch data from the table: "question_ui_control_helper_text_position" */
  question_ui_control_helper_text_position: Array<Question_Ui_Control_Helper_Text_Position>;
  /** fetch aggregated fields from the table: "question_ui_control_helper_text_position" */
  question_ui_control_helper_text_position_aggregate: Question_Ui_Control_Helper_Text_Position_Aggregate;
  /** fetch data from the table: "question_ui_control_helper_text_position" using primary key columns */
  question_ui_control_helper_text_position_by_pk?: Maybe<Question_Ui_Control_Helper_Text_Position>;
  /** fetch data from the table: "question_ui_control_types" */
  question_ui_control_types: Array<Question_Ui_Control_Types>;
  /** fetch aggregated fields from the table: "question_ui_control_types" */
  question_ui_control_types_aggregate: Question_Ui_Control_Types_Aggregate;
  /** fetch data from the table: "question_ui_control_types" using primary key columns */
  question_ui_control_types_by_pk?: Maybe<Question_Ui_Control_Types>;
  /** fetch data from the table: "questionnaire_questions" */
  questionnaire_questions: Array<Questionnaire_Questions>;
  /** fetch aggregated fields from the table: "questionnaire_questions" */
  questionnaire_questions_aggregate: Questionnaire_Questions_Aggregate;
  /** fetch data from the table: "questionnaire_questions" using primary key columns */
  questionnaire_questions_by_pk?: Maybe<Questionnaire_Questions>;
  /** fetch data from the table: "questionnaire_responses" */
  questionnaire_responses: Array<Questionnaire_Responses>;
  /** fetch aggregated fields from the table: "questionnaire_responses" */
  questionnaire_responses_aggregate: Questionnaire_Responses_Aggregate;
  /** fetch data from the table: "questionnaire_responses" using primary key columns */
  questionnaire_responses_by_pk?: Maybe<Questionnaire_Responses>;
  /** fetch data from the table: "questionnaires" */
  questionnaires: Array<Questionnaires>;
  /** fetch aggregated fields from the table: "questionnaires" */
  questionnaires_aggregate: Questionnaires_Aggregate;
  /** fetch data from the table: "questionnaires" using primary key columns */
  questionnaires_by_pk?: Maybe<Questionnaires>;
  /** fetch data from the table: "questions" */
  questions: Array<Questions>;
  /** fetch aggregated fields from the table: "questions" */
  questions_aggregate: Questions_Aggregate;
  /** fetch data from the table: "questions" using primary key columns */
  questions_by_pk?: Maybe<Questions>;
  /** fetch data from the table: "role_types" */
  role_types: Array<Role_Types>;
  /** fetch aggregated fields from the table: "role_types" */
  role_types_aggregate: Role_Types_Aggregate;
  /** fetch data from the table: "role_types" using primary key columns */
  role_types_by_pk?: Maybe<Role_Types>;
  /** fetch data from the table: "roles" */
  roles: Array<Roles>;
  /** fetch aggregated fields from the table: "roles" */
  roles_aggregate: Roles_Aggregate;
  /** fetch data from the table: "roles" using primary key columns */
  roles_by_pk?: Maybe<Roles>;
  /** fetch data from the table: "skintelligent_tests" */
  skintelligent_tests: Array<Skintelligent_Tests>;
  /** fetch aggregated fields from the table: "skintelligent_tests" */
  skintelligent_tests_aggregate: Skintelligent_Tests_Aggregate;
  /** fetch data from the table: "skintelligent_tests" using primary key columns */
  skintelligent_tests_by_pk?: Maybe<Skintelligent_Tests>;
  /** fetch data from the table: "swab_code_statuses" */
  swab_code_statuses: Array<Swab_Code_Statuses>;
  /** fetch aggregated fields from the table: "swab_code_statuses" */
  swab_code_statuses_aggregate: Swab_Code_Statuses_Aggregate;
  /** fetch data from the table: "swab_code_statuses" using primary key columns */
  swab_code_statuses_by_pk?: Maybe<Swab_Code_Statuses>;
  /** fetch data from the table: "swab_codes" */
  swab_codes: Array<Swab_Codes>;
  /** fetch aggregated fields from the table: "swab_codes" */
  swab_codes_aggregate: Swab_Codes_Aggregate;
  /** fetch data from the table: "swab_codes" using primary key columns */
  swab_codes_by_pk?: Maybe<Swab_Codes>;
  /** fetch data from the table: "swab_usage_type" */
  swab_usage_type: Array<Swab_Usage_Type>;
  /** fetch aggregated fields from the table: "swab_usage_type" */
  swab_usage_type_aggregate: Swab_Usage_Type_Aggregate;
  /** fetch data from the table: "swab_usage_type" using primary key columns */
  swab_usage_type_by_pk?: Maybe<Swab_Usage_Type>;
  /** fetch data from the table: "test_definitions" */
  test_definitions: Array<Test_Definitions>;
  /** fetch aggregated fields from the table: "test_definitions" */
  test_definitions_aggregate: Test_Definitions_Aggregate;
  /** fetch data from the table: "test_definitions" using primary key columns */
  test_definitions_by_pk?: Maybe<Test_Definitions>;
  /** fetch data from the table: "test_kits_update_states_summary_log" */
  test_kits_update_states_summary_log: Array<Test_Kits_Update_States_Summary_Log>;
  /** fetch aggregated fields from the table: "test_kits_update_states_summary_log" */
  test_kits_update_states_summary_log_aggregate: Test_Kits_Update_States_Summary_Log_Aggregate;
  /** fetch data from the table: "test_kits_update_states_summary_log" using primary key columns */
  test_kits_update_states_summary_log_by_pk?: Maybe<Test_Kits_Update_States_Summary_Log>;
  /** fetch data from the table: "test_types" */
  test_types: Array<Test_Types>;
  /** fetch aggregated fields from the table: "test_types" */
  test_types_aggregate: Test_Types_Aggregate;
  /** fetch data from the table: "test_types" using primary key columns */
  test_types_by_pk?: Maybe<Test_Types>;
  /** fetch data from the table: "tests" */
  tests: Array<Tests>;
  /** fetch aggregated fields from the table: "tests" */
  tests_aggregate: Tests_Aggregate;
  /** fetch data from the table: "tests" using primary key columns */
  tests_by_pk?: Maybe<Tests>;
  /** fetch data from the table: "text_addresses" */
  text_addresses: Array<Text_Addresses>;
  /** fetch aggregated fields from the table: "text_addresses" */
  text_addresses_aggregate: Text_Addresses_Aggregate;
  /** fetch data from the table: "text_addresses" using primary key columns */
  text_addresses_by_pk?: Maybe<Text_Addresses>;
  /** An array relationship */
  unauthenticated_questionnaire_questions: Array<Unauthenticated_Questionnaire_Questions>;
  /** An aggregate relationship */
  unauthenticated_questionnaire_questions_aggregate: Unauthenticated_Questionnaire_Questions_Aggregate;
  /** fetch data from the table: "unauthenticated_questionnaire_questions" using primary key columns */
  unauthenticated_questionnaire_questions_by_pk?: Maybe<Unauthenticated_Questionnaire_Questions>;
  /** fetch data from the table: "unauthenticated_questionnaires" */
  unauthenticated_questionnaires: Array<Unauthenticated_Questionnaires>;
  /** fetch aggregated fields from the table: "unauthenticated_questionnaires" */
  unauthenticated_questionnaires_aggregate: Unauthenticated_Questionnaires_Aggregate;
  /** fetch data from the table: "unauthenticated_questionnaires" using primary key columns */
  unauthenticated_questionnaires_by_pk?: Maybe<Unauthenticated_Questionnaires>;
  /** fetch data from the table: "unauthenticated_questions" */
  unauthenticated_questions: Array<Unauthenticated_Questions>;
  /** fetch aggregated fields from the table: "unauthenticated_questions" */
  unauthenticated_questions_aggregate: Unauthenticated_Questions_Aggregate;
  /** fetch data from the table: "unauthenticated_questions" using primary key columns */
  unauthenticated_questions_by_pk?: Maybe<Unauthenticated_Questions>;
  /** fetch data from the table: "universities" */
  universities: Array<Universities>;
  /** fetch aggregated fields from the table: "universities" */
  universities_aggregate: Universities_Aggregate;
  /** fetch data from the table: "universities" using primary key columns */
  universities_by_pk?: Maybe<Universities>;
  /** fetch data from the table: "user_addresses" */
  user_addresses: Array<User_Addresses>;
  /** fetch aggregated fields from the table: "user_addresses" */
  user_addresses_aggregate: User_Addresses_Aggregate;
  /** fetch data from the table: "user_addresses" using primary key columns */
  user_addresses_by_pk?: Maybe<User_Addresses>;
  /** fetch data from the table: "user_auth_provider_types" */
  user_auth_provider_types: Array<User_Auth_Provider_Types>;
  /** fetch aggregated fields from the table: "user_auth_provider_types" */
  user_auth_provider_types_aggregate: User_Auth_Provider_Types_Aggregate;
  /** fetch data from the table: "user_auth_provider_types" using primary key columns */
  user_auth_provider_types_by_pk?: Maybe<User_Auth_Provider_Types>;
  /** fetch data from the table: "user_auth_providers" */
  user_auth_providers: Array<User_Auth_Providers>;
  /** fetch aggregated fields from the table: "user_auth_providers" */
  user_auth_providers_aggregate: User_Auth_Providers_Aggregate;
  /** fetch data from the table: "user_auth_providers" using primary key columns */
  user_auth_providers_by_pk?: Maybe<User_Auth_Providers>;
  /** fetch data from the table: "user_auth_tokens" */
  user_auth_tokens: Array<User_Auth_Tokens>;
  /** fetch aggregated fields from the table: "user_auth_tokens" */
  user_auth_tokens_aggregate: User_Auth_Tokens_Aggregate;
  /** fetch data from the table: "user_auth_tokens" using primary key columns */
  user_auth_tokens_by_pk?: Maybe<User_Auth_Tokens>;
  /** fetch data from the table: "user_communication_deliveries" */
  user_communication_deliveries: Array<User_Communication_Deliveries>;
  /** fetch aggregated fields from the table: "user_communication_deliveries" */
  user_communication_deliveries_aggregate: User_Communication_Deliveries_Aggregate;
  /** fetch data from the table: "user_communication_deliveries" using primary key columns */
  user_communication_deliveries_by_pk?: Maybe<User_Communication_Deliveries>;
  /** fetch data from the table: "user_communications" */
  user_communications: Array<User_Communications>;
  /** fetch aggregated fields from the table: "user_communications" */
  user_communications_aggregate: User_Communications_Aggregate;
  /** fetch data from the table: "user_communications" using primary key columns */
  user_communications_by_pk?: Maybe<User_Communications>;
  /** fetch data from the table: "user_notification_status" */
  user_notification_status: Array<User_Notification_Status>;
  /** fetch aggregated fields from the table: "user_notification_status" */
  user_notification_status_aggregate: User_Notification_Status_Aggregate;
  /** fetch data from the table: "user_notification_status" using primary key columns */
  user_notification_status_by_pk?: Maybe<User_Notification_Status>;
  /** fetch data from the table: "user_notifications" */
  user_notifications: Array<User_Notifications>;
  /** fetch aggregated fields from the table: "user_notifications" */
  user_notifications_aggregate: User_Notifications_Aggregate;
  /** fetch data from the table: "user_notifications" using primary key columns */
  user_notifications_by_pk?: Maybe<User_Notifications>;
  /** fetch data from the table: "user_roles" */
  user_roles: Array<User_Roles>;
  /** fetch aggregated fields from the table: "user_roles" */
  user_roles_aggregate: User_Roles_Aggregate;
  /** fetch data from the table: "user_roles" using primary key columns */
  user_roles_by_pk?: Maybe<User_Roles>;
  /** fetch data from the table: "user_scanned_data" */
  user_scanned_data: Array<User_Scanned_Data>;
  /** fetch aggregated fields from the table: "user_scanned_data" */
  user_scanned_data_aggregate: User_Scanned_Data_Aggregate;
  /** fetch data from the table: "user_scanned_data" using primary key columns */
  user_scanned_data_by_pk?: Maybe<User_Scanned_Data>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "vonage_appointment" */
  vonage_appointment: Array<Vonage_Appointment>;
  /** fetch aggregated fields from the table: "vonage_appointment" */
  vonage_appointment_aggregate: Vonage_Appointment_Aggregate;
  /** fetch data from the table: "vonage_appointment" using primary key columns */
  vonage_appointment_by_pk?: Maybe<Vonage_Appointment>;
  /** fetch data from the table: "zrt_tests" */
  zrt_tests: Array<Zrt_Tests>;
  /** fetch aggregated fields from the table: "zrt_tests" */
  zrt_tests_aggregate: Zrt_Tests_Aggregate;
  /** fetch data from the table: "zrt_tests" using primary key columns */
  zrt_tests_by_pk?: Maybe<Zrt_Tests>;
};


export type Query_RootGetCustomAuthCodeToLoginArgs = {
  email: Scalars['String'];
};


export type Query_RootGetCustomAuthTokenFromCustomAuthCodeArgs = {
  customAuthCode: Scalars['String'];
  email: Scalars['String'];
};


export type Query_RootGetProviderTimeSlotsArgs = {
  date: Scalars['String'];
  providerCalendarId: Scalars['String'];
};


export type Query_RootGetSwabCodeValidBySwabcodeArgs = {
  swabcode: Scalars['String'];
};


export type Query_RootValidateFedexTrackingNumberArgs = {
  trackingNumber: Scalars['String'];
};


export type Query_RootAccepted_Legal_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Accepted_Legal_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accepted_Legal_Documents_Order_By>>;
  where?: InputMaybe<Accepted_Legal_Documents_Bool_Exp>;
};


export type Query_RootAccepted_Legal_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Accepted_Legal_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accepted_Legal_Documents_Order_By>>;
  where?: InputMaybe<Accepted_Legal_Documents_Bool_Exp>;
};


export type Query_RootAccepted_Legal_Documents_By_PkArgs = {
  accepted_legal_document_id: Scalars['bigint'];
};


export type Query_RootAcuity_Provider_CalendarArgs = {
  distinct_on?: InputMaybe<Array<Acuity_Provider_Calendar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Acuity_Provider_Calendar_Order_By>>;
  where?: InputMaybe<Acuity_Provider_Calendar_Bool_Exp>;
};


export type Query_RootAcuity_Provider_Calendar_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Acuity_Provider_Calendar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Acuity_Provider_Calendar_Order_By>>;
  where?: InputMaybe<Acuity_Provider_Calendar_Bool_Exp>;
};


export type Query_RootAcuity_Provider_Calendar_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAddressesArgs = {
  distinct_on?: InputMaybe<Array<Addresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Addresses_Order_By>>;
  where?: InputMaybe<Addresses_Bool_Exp>;
};


export type Query_RootAddresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Addresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Addresses_Order_By>>;
  where?: InputMaybe<Addresses_Bool_Exp>;
};


export type Query_RootAddresses_By_PkArgs = {
  address_id: Scalars['uuid'];
};


export type Query_RootChannel_SignalsArgs = {
  distinct_on?: InputMaybe<Array<Channel_Signals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Channel_Signals_Order_By>>;
  where?: InputMaybe<Channel_Signals_Bool_Exp>;
};


export type Query_RootChannel_Signals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Channel_Signals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Channel_Signals_Order_By>>;
  where?: InputMaybe<Channel_Signals_Bool_Exp>;
};


export type Query_RootChannel_Signals_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCommon_Question_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<Common_Question_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Common_Question_Responses_Order_By>>;
  where?: InputMaybe<Common_Question_Responses_Bool_Exp>;
};


export type Query_RootCommon_Question_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Common_Question_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Common_Question_Responses_Order_By>>;
  where?: InputMaybe<Common_Question_Responses_Bool_Exp>;
};


export type Query_RootCommon_Question_Responses_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCommon_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Common_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Common_Questions_Order_By>>;
  where?: InputMaybe<Common_Questions_Bool_Exp>;
};


export type Query_RootCommon_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Common_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Common_Questions_Order_By>>;
  where?: InputMaybe<Common_Questions_Bool_Exp>;
};


export type Query_RootCommon_Questions_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCommunication_ChannelsArgs = {
  distinct_on?: InputMaybe<Array<Communication_Channels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communication_Channels_Order_By>>;
  where?: InputMaybe<Communication_Channels_Bool_Exp>;
};


export type Query_RootCommunication_Channels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Communication_Channels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communication_Channels_Order_By>>;
  where?: InputMaybe<Communication_Channels_Bool_Exp>;
};


export type Query_RootCommunication_Channels_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootCountriesArgs = {
  distinct_on?: InputMaybe<Array<Countries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Countries_Order_By>>;
  where?: InputMaybe<Countries_Bool_Exp>;
};


export type Query_RootCountries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Countries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Countries_Order_By>>;
  where?: InputMaybe<Countries_Bool_Exp>;
};


export type Query_RootCountries_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCustom_Auth_CodeArgs = {
  distinct_on?: InputMaybe<Array<Custom_Auth_Code_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Custom_Auth_Code_Order_By>>;
  where?: InputMaybe<Custom_Auth_Code_Bool_Exp>;
};


export type Query_RootCustom_Auth_Code_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Custom_Auth_Code_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Custom_Auth_Code_Order_By>>;
  where?: InputMaybe<Custom_Auth_Code_Bool_Exp>;
};


export type Query_RootCustom_Auth_Code_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDnavisit_Test_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Dnavisit_Test_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dnavisit_Test_Statuses_Order_By>>;
  where?: InputMaybe<Dnavisit_Test_Statuses_Bool_Exp>;
};


export type Query_RootDnavisit_Test_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dnavisit_Test_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dnavisit_Test_Statuses_Order_By>>;
  where?: InputMaybe<Dnavisit_Test_Statuses_Bool_Exp>;
};


export type Query_RootDnavisit_Test_Statuses_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootDnavisit_TestsArgs = {
  distinct_on?: InputMaybe<Array<Dnavisit_Tests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dnavisit_Tests_Order_By>>;
  where?: InputMaybe<Dnavisit_Tests_Bool_Exp>;
};


export type Query_RootDnavisit_Tests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dnavisit_Tests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dnavisit_Tests_Order_By>>;
  where?: InputMaybe<Dnavisit_Tests_Bool_Exp>;
};


export type Query_RootDnavisit_Tests_By_PkArgs = {
  test_id: Scalars['uuid'];
};


export type Query_RootDo_Not_Use_Production_Swab_CodesArgs = {
  distinct_on?: InputMaybe<Array<Do_Not_Use_Production_Swab_Codes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Do_Not_Use_Production_Swab_Codes_Order_By>>;
  where?: InputMaybe<Do_Not_Use_Production_Swab_Codes_Bool_Exp>;
};


export type Query_RootDo_Not_Use_Production_Swab_Codes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Do_Not_Use_Production_Swab_Codes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Do_Not_Use_Production_Swab_Codes_Order_By>>;
  where?: InputMaybe<Do_Not_Use_Production_Swab_Codes_Bool_Exp>;
};


export type Query_RootDo_Not_Use_Production_Swab_Codes_By_PkArgs = {
  swab_code: Scalars['String'];
};


export type Query_RootDosespot_CliniciansArgs = {
  distinct_on?: InputMaybe<Array<Dosespot_Clinicians_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dosespot_Clinicians_Order_By>>;
  where?: InputMaybe<Dosespot_Clinicians_Bool_Exp>;
};


export type Query_RootDosespot_Clinicians_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dosespot_Clinicians_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dosespot_Clinicians_Order_By>>;
  where?: InputMaybe<Dosespot_Clinicians_Bool_Exp>;
};


export type Query_RootDosespot_Clinicians_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootFile_TypesArgs = {
  distinct_on?: InputMaybe<Array<File_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Types_Order_By>>;
  where?: InputMaybe<File_Types_Bool_Exp>;
};


export type Query_RootFile_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<File_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Types_Order_By>>;
  where?: InputMaybe<File_Types_Bool_Exp>;
};


export type Query_RootFile_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootFilesArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


export type Query_RootFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


export type Query_RootFiles_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootFullscript_CliniciansArgs = {
  distinct_on?: InputMaybe<Array<Fullscript_Clinicians_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fullscript_Clinicians_Order_By>>;
  where?: InputMaybe<Fullscript_Clinicians_Bool_Exp>;
};


export type Query_RootFullscript_Clinicians_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Fullscript_Clinicians_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fullscript_Clinicians_Order_By>>;
  where?: InputMaybe<Fullscript_Clinicians_Bool_Exp>;
};


export type Query_RootFullscript_Clinicians_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGetAppointmentMessageByCommunicationFhirIdArgs = {
  communicationFhirId: Scalars['String'];
};


export type Query_RootGetAppointmentMessageListByAppointmentCodexIdArgs = {
  appointmentCodexId: Scalars['String'];
};


export type Query_RootGetAppointmentNotesByAppointmentIdArgs = {
  appointmentCodexId: Scalars['String'];
};


export type Query_RootGetAppointmentRescheduleLinkArgs = {
  appointmentCodexId: Scalars['String'];
};


export type Query_RootGetAppointmentsArgs = {
  narrowing?: InputMaybe<SaNarrowingQueryOptions>;
  paging?: InputMaybe<SaPageableQueryOptions>;
  statuses?: InputMaybe<Array<Scalars['String']>>;
  types?: InputMaybe<Array<AppointmentTypeEnum>>;
};


export type Query_RootGetDiagnosticReportsArgs = {
  narrowing?: InputMaybe<SaNarrowingQueryOptions>;
  paging?: InputMaybe<SaPageableQueryOptions>;
  types?: InputMaybe<Array<Scalars['String']>>;
};


export type Query_RootGetFhirAppointmentByAcuityIdArgs = {
  appointmentAcuityId: Scalars['String'];
};


export type Query_RootGetFhirAppointmentByCodexIdArgs = {
  appointmentCodexId: Scalars['String'];
};


export type Query_RootGetFhirAppointmentByPatientCodexIdArgs = {
  appointmentStatus?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  patientCodexId: Scalars['String'];
};


export type Query_RootGetFhirAppointmentByRequestPatientCodexIdArgs = {
  appointmentCodexId?: InputMaybe<Scalars['String']>;
  appointmentStatus?: InputMaybe<Array<Scalars['String']>>;
  codexProviderId?: InputMaybe<Scalars['String']>;
  dayFilter?: InputMaybe<Scalars['String']>;
  find?: InputMaybe<Scalars['String']>;
  from?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  providerName?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<Scalars['Float']>;
  to?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  tzOffset?: InputMaybe<Scalars['Float']>;
};


export type Query_RootGetFhirAppointmentByRequestProviderIdArgs = {
  appointmentCodexId?: InputMaybe<Scalars['String']>;
  appointmentStatus?: InputMaybe<Array<Scalars['String']>>;
  dayFilter?: InputMaybe<Scalars['String']>;
  find?: InputMaybe<Scalars['String']>;
  from?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  patientCodexId?: InputMaybe<Scalars['String']>;
  patientName?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<Scalars['Float']>;
  to?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  tzOffset?: InputMaybe<Scalars['Float']>;
};


export type Query_RootGetFhirDnaAndZrtTestsArgs = {
  find?: InputMaybe<Scalars['String']>;
  from?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: Scalars['Float'];
  orderBy?: InputMaybe<DiagnosticReportOrderByInput>;
  patientCodexId?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<ProvidersTypes>;
  providerName?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Test_States_Enum>;
  testType?: InputMaybe<Array<Hasura_Test_Types_Enum>>;
  to?: InputMaybe<Scalars['String']>;
};


export type Query_RootGetFhirDnaVisitTestByOrderIdArgs = {
  getFHIRDnaVisitTestByOrderId: Scalars['String'];
};


export type Query_RootGetFhirDnaVisitTestBySwabcodeArgs = {
  getFHIRDnaVisitTestBySwabcode: Scalars['String'];
};


export type Query_RootGetFhirDnaVisitTestByTestIdArgs = {
  getFHIRDnaVisitTestByTestId: Scalars['String'];
};


export type Query_RootGetFhirDnaVisitTestsArgs = {
  find?: InputMaybe<Scalars['String']>;
  from?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: Scalars['Float'];
  orderBy?: InputMaybe<DnaVisitOrderByInput>;
  provider?: InputMaybe<ProvidersTypes>;
  providerName?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Hasura_Dnavisit_Test_Statuses_Enum>;
  testType?: InputMaybe<Array<Hasura_Test_Types_Enum>>;
  to?: InputMaybe<Scalars['String']>;
};


export type Query_RootGetFhirDnaVisitTestsByUserIdArgs = {
  codexPatientId: Scalars['String'];
  find?: InputMaybe<Scalars['String']>;
  from?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: Scalars['Float'];
  orderBy?: InputMaybe<DnaVisitOrderByInput>;
  providerName?: InputMaybe<Scalars['String']>;
  testType?: InputMaybe<Array<Hasura_Test_Types_Enum>>;
  to?: InputMaybe<Scalars['String']>;
};


export type Query_RootGetFhirMediaByRequestPatientCodexIdArgs = {
  from?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Scalars['Float']>;
  to?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};


export type Query_RootGetFhirMedicationRequestBranAndNamesByPatientCodexIdArgs = {
  patientCodexId?: InputMaybe<Scalars['String']>;
};


export type Query_RootGetFhirMedicationRequestByFhirIdArgs = {
  fhirId: Scalars['String'];
};


export type Query_RootGetFhirMedicationRequestByPatientCodexIdArgs = {
  brandAndName?: InputMaybe<Scalars['String']>;
  encounterId?: InputMaybe<Scalars['String']>;
  find?: InputMaybe<Scalars['String']>;
  from?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  patientCodexId?: InputMaybe<Scalars['String']>;
  providerId?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<Scalars['Float']>;
  to?: InputMaybe<Scalars['String']>;
};


export type Query_RootGetFhirPatientByFhiridArgs = {
  fhirId: Scalars['String'];
};


export type Query_RootGetFhirPatientbyCodexIdParameterArgs = {
  patientCodexId: Scalars['String'];
};


export type Query_RootGetFhirPatientbyEmailArgs = {
  email: Scalars['String'];
};


export type Query_RootGetFhirPatientbyMediaCodexIdParameterArgs = {
  from?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  patientCodexId: Scalars['String'];
  sort?: InputMaybe<Scalars['Float']>;
  to?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};


export type Query_RootGetFhirPatientsByIdArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<OrderByDirections>;
  patientCodexId?: InputMaybe<Scalars['String']>;
  searchCriteria?: InputMaybe<Scalars['String']>;
};


export type Query_RootGetFhirProviderbyCodexIdArgumentArgs = {
  codexProviderId: Scalars['String'];
};


export type Query_RootGetFhirProviderbyEmailArgs = {
  email: Scalars['String'];
};


export type Query_RootGetFhirProviderbyIdArgs = {
  providerId: Scalars['String'];
};


export type Query_RootGetFhirProvidersArgs = {
  gender?: InputMaybe<Array<Scalars['String']>>;
  languages?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
};


export type Query_RootGetFhirQuestionnaireByCodexIdArgs = {
  questionnaireId: Scalars['Int'];
};


export type Query_RootGetFhirQuestionnaireResponseArgs = {
  codexPatientId: Scalars['String'];
  identifier: Scalars['String'];
  system: Scalars['String'];
};


export type Query_RootGetFhirSkintelligentTestBySessionIdArgs = {
  sessionId: Scalars['String'];
};


export type Query_RootGetFhirSkintelligentTestBySessionIdAndUserIdArgs = {
  sessionId: Scalars['String'];
};


export type Query_RootGetFhirSkintelligentTestByTestIdAndUserIdArgs = {
  testId: Scalars['String'];
};


export type Query_RootGetFhirSkintelligentTestsArgs = {
  from?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: Scalars['Float'];
  orderBy?: InputMaybe<SkintelligentOrderByInput>;
  source?: InputMaybe<Hasura_Image_Source_Types_Enum>;
  to?: InputMaybe<Scalars['String']>;
};


export type Query_RootGetFhirSkintelligentTestsByUserIdArgs = {
  codexPatientId: Scalars['String'];
  from?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: Scalars['Float'];
  orderBy?: InputMaybe<SkintelligentOrderByInput>;
  source?: InputMaybe<Hasura_Image_Source_Types_Enum>;
  to?: InputMaybe<Scalars['String']>;
};


export type Query_RootGetFhirZrtTestByTestIdArgs = {
  getFHIRZrtTestByTestId: Scalars['String'];
};


export type Query_RootGetFhirZrtTestsArgs = {
  find?: InputMaybe<Scalars['String']>;
  from?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: Scalars['Float'];
  orderBy?: InputMaybe<ZrtOrderByInput>;
  provider?: InputMaybe<ProvidersTypes>;
  providerName?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Hasura_Zrt_Test_States_Enum>;
  testType?: InputMaybe<Array<Hasura_Test_Types_Enum>>;
  to?: InputMaybe<Scalars['String']>;
};


export type Query_RootGetLatestFhirQuestionnaireByIdArgs = {
  questionnaire_id: Scalars['Int'];
};


export type Query_RootGetPatientsArgs = {
  paging?: InputMaybe<SaPageableQueryOptions>;
};


export type Query_RootGetProvidersArgs = {
  paging?: InputMaybe<SaPageableQueryOptions>;
};


export type Query_RootGetProvidersByPatientIdArgs = {
  patientCodexId?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
};


export type Query_RootGetSessionGrantArgs = {
  patientCodexId: Scalars['String'];
};


export type Query_RootGlobal_ConfigsArgs = {
  distinct_on?: InputMaybe<Array<Global_Configs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Global_Configs_Order_By>>;
  where?: InputMaybe<Global_Configs_Bool_Exp>;
};


export type Query_RootGlobal_Configs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Global_Configs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Global_Configs_Order_By>>;
  where?: InputMaybe<Global_Configs_Bool_Exp>;
};


export type Query_RootGlobal_Configs_By_PkArgs = {
  name: Global_Configs_Names_Enum;
};


export type Query_RootGlobal_Configs_NamesArgs = {
  distinct_on?: InputMaybe<Array<Global_Configs_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Global_Configs_Names_Order_By>>;
  where?: InputMaybe<Global_Configs_Names_Bool_Exp>;
};


export type Query_RootGlobal_Configs_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Global_Configs_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Global_Configs_Names_Order_By>>;
  where?: InputMaybe<Global_Configs_Names_Bool_Exp>;
};


export type Query_RootGlobal_Configs_Names_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootImage_Source_TypesArgs = {
  distinct_on?: InputMaybe<Array<Image_Source_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Image_Source_Types_Order_By>>;
  where?: InputMaybe<Image_Source_Types_Bool_Exp>;
};


export type Query_RootImage_Source_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Image_Source_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Image_Source_Types_Order_By>>;
  where?: InputMaybe<Image_Source_Types_Bool_Exp>;
};


export type Query_RootImage_Source_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootLabsArgs = {
  distinct_on?: InputMaybe<Array<Labs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Labs_Order_By>>;
  where?: InputMaybe<Labs_Bool_Exp>;
};


export type Query_RootLabs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Labs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Labs_Order_By>>;
  where?: InputMaybe<Labs_Bool_Exp>;
};


export type Query_RootLabs_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootLegal_Document_TypesArgs = {
  distinct_on?: InputMaybe<Array<Legal_Document_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Legal_Document_Types_Order_By>>;
  where?: InputMaybe<Legal_Document_Types_Bool_Exp>;
};


export type Query_RootLegal_Document_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Legal_Document_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Legal_Document_Types_Order_By>>;
  where?: InputMaybe<Legal_Document_Types_Bool_Exp>;
};


export type Query_RootLegal_Document_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootLegal_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Legal_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Legal_Documents_Order_By>>;
  where?: InputMaybe<Legal_Documents_Bool_Exp>;
};


export type Query_RootLegal_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Legal_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Legal_Documents_Order_By>>;
  where?: InputMaybe<Legal_Documents_Bool_Exp>;
};


export type Query_RootLegal_Documents_By_PkArgs = {
  legal_document_id: Scalars['bigint'];
};


export type Query_RootMedical_SchoolsArgs = {
  distinct_on?: InputMaybe<Array<Medical_Schools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medical_Schools_Order_By>>;
  where?: InputMaybe<Medical_Schools_Bool_Exp>;
};


export type Query_RootMedical_Schools_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Medical_Schools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medical_Schools_Order_By>>;
  where?: InputMaybe<Medical_Schools_Bool_Exp>;
};


export type Query_RootMedical_Schools_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootNabla_NotesArgs = {
  distinct_on?: InputMaybe<Array<Nabla_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nabla_Notes_Order_By>>;
  where?: InputMaybe<Nabla_Notes_Bool_Exp>;
};


export type Query_RootNabla_Notes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nabla_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nabla_Notes_Order_By>>;
  where?: InputMaybe<Nabla_Notes_Bool_Exp>;
};


export type Query_RootNabla_Notes_By_PkArgs = {
  appointment_id: Scalars['String'];
};


export type Query_RootNpi_Registry_DataArgs = {
  distinct_on?: InputMaybe<Array<Npi_Registry_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Npi_Registry_Data_Order_By>>;
  where?: InputMaybe<Npi_Registry_Data_Bool_Exp>;
};


export type Query_RootNpi_Registry_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Npi_Registry_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Npi_Registry_Data_Order_By>>;
  where?: InputMaybe<Npi_Registry_Data_Bool_Exp>;
};


export type Query_RootNpi_Registry_Data_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPersona_InquiriesArgs = {
  distinct_on?: InputMaybe<Array<Persona_Inquiries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Persona_Inquiries_Order_By>>;
  where?: InputMaybe<Persona_Inquiries_Bool_Exp>;
};


export type Query_RootPersona_Inquiries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Persona_Inquiries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Persona_Inquiries_Order_By>>;
  where?: InputMaybe<Persona_Inquiries_Bool_Exp>;
};


export type Query_RootPersona_Inquiries_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPersona_Inquiry_StatusArgs = {
  distinct_on?: InputMaybe<Array<Persona_Inquiry_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Persona_Inquiry_Status_Order_By>>;
  where?: InputMaybe<Persona_Inquiry_Status_Bool_Exp>;
};


export type Query_RootPersona_Inquiry_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Persona_Inquiry_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Persona_Inquiry_Status_Order_By>>;
  where?: InputMaybe<Persona_Inquiry_Status_Bool_Exp>;
};


export type Query_RootPersona_Inquiry_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootProvider_Unread_External_Notification_CountsArgs = {
  distinct_on?: InputMaybe<Array<Provider_Unread_External_Notification_Counts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Provider_Unread_External_Notification_Counts_Order_By>>;
  where?: InputMaybe<Provider_Unread_External_Notification_Counts_Bool_Exp>;
};


export type Query_RootProvider_Unread_External_Notification_Counts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Provider_Unread_External_Notification_Counts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Provider_Unread_External_Notification_Counts_Order_By>>;
  where?: InputMaybe<Provider_Unread_External_Notification_Counts_Bool_Exp>;
};


export type Query_RootProvider_Unread_External_Notification_Counts_By_PkArgs = {
  codex_provider_id: Scalars['uuid'];
};


export type Query_RootQuestion_Measurement_UnitsArgs = {
  distinct_on?: InputMaybe<Array<Question_Measurement_Units_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Question_Measurement_Units_Order_By>>;
  where?: InputMaybe<Question_Measurement_Units_Bool_Exp>;
};


export type Query_RootQuestion_Measurement_Units_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Question_Measurement_Units_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Question_Measurement_Units_Order_By>>;
  where?: InputMaybe<Question_Measurement_Units_Bool_Exp>;
};


export type Query_RootQuestion_Measurement_Units_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootQuestion_TypesArgs = {
  distinct_on?: InputMaybe<Array<Question_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Question_Types_Order_By>>;
  where?: InputMaybe<Question_Types_Bool_Exp>;
};


export type Query_RootQuestion_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Question_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Question_Types_Order_By>>;
  where?: InputMaybe<Question_Types_Bool_Exp>;
};


export type Query_RootQuestion_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootQuestion_Ui_Control_Helper_Text_PositionArgs = {
  distinct_on?: InputMaybe<Array<Question_Ui_Control_Helper_Text_Position_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Question_Ui_Control_Helper_Text_Position_Order_By>>;
  where?: InputMaybe<Question_Ui_Control_Helper_Text_Position_Bool_Exp>;
};


export type Query_RootQuestion_Ui_Control_Helper_Text_Position_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Question_Ui_Control_Helper_Text_Position_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Question_Ui_Control_Helper_Text_Position_Order_By>>;
  where?: InputMaybe<Question_Ui_Control_Helper_Text_Position_Bool_Exp>;
};


export type Query_RootQuestion_Ui_Control_Helper_Text_Position_By_PkArgs = {
  helper_text_position: Scalars['String'];
};


export type Query_RootQuestion_Ui_Control_TypesArgs = {
  distinct_on?: InputMaybe<Array<Question_Ui_Control_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Question_Ui_Control_Types_Order_By>>;
  where?: InputMaybe<Question_Ui_Control_Types_Bool_Exp>;
};


export type Query_RootQuestion_Ui_Control_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Question_Ui_Control_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Question_Ui_Control_Types_Order_By>>;
  where?: InputMaybe<Question_Ui_Control_Types_Bool_Exp>;
};


export type Query_RootQuestion_Ui_Control_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootQuestionnaire_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Questionnaire_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questionnaire_Questions_Order_By>>;
  where?: InputMaybe<Questionnaire_Questions_Bool_Exp>;
};


export type Query_RootQuestionnaire_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questionnaire_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questionnaire_Questions_Order_By>>;
  where?: InputMaybe<Questionnaire_Questions_Bool_Exp>;
};


export type Query_RootQuestionnaire_Questions_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootQuestionnaire_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<Questionnaire_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questionnaire_Responses_Order_By>>;
  where?: InputMaybe<Questionnaire_Responses_Bool_Exp>;
};


export type Query_RootQuestionnaire_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questionnaire_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questionnaire_Responses_Order_By>>;
  where?: InputMaybe<Questionnaire_Responses_Bool_Exp>;
};


export type Query_RootQuestionnaire_Responses_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootQuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questionnaires_Order_By>>;
  where?: InputMaybe<Questionnaires_Bool_Exp>;
};


export type Query_RootQuestionnaires_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questionnaires_Order_By>>;
  where?: InputMaybe<Questionnaires_Bool_Exp>;
};


export type Query_RootQuestionnaires_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootQuestionsArgs = {
  distinct_on?: InputMaybe<Array<Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Order_By>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};


export type Query_RootQuestions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Order_By>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};


export type Query_RootQuestions_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootRole_TypesArgs = {
  distinct_on?: InputMaybe<Array<Role_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Role_Types_Order_By>>;
  where?: InputMaybe<Role_Types_Bool_Exp>;
};


export type Query_RootRole_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Role_Types_Order_By>>;
  where?: InputMaybe<Role_Types_Bool_Exp>;
};


export type Query_RootRole_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootRolesArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Query_RootRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Query_RootRoles_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootSkintelligent_TestsArgs = {
  distinct_on?: InputMaybe<Array<Skintelligent_Tests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Skintelligent_Tests_Order_By>>;
  where?: InputMaybe<Skintelligent_Tests_Bool_Exp>;
};


export type Query_RootSkintelligent_Tests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Skintelligent_Tests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Skintelligent_Tests_Order_By>>;
  where?: InputMaybe<Skintelligent_Tests_Bool_Exp>;
};


export type Query_RootSkintelligent_Tests_By_PkArgs = {
  test_id: Scalars['uuid'];
};


export type Query_RootSwab_Code_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Swab_Code_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Swab_Code_Statuses_Order_By>>;
  where?: InputMaybe<Swab_Code_Statuses_Bool_Exp>;
};


export type Query_RootSwab_Code_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Swab_Code_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Swab_Code_Statuses_Order_By>>;
  where?: InputMaybe<Swab_Code_Statuses_Bool_Exp>;
};


export type Query_RootSwab_Code_Statuses_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootSwab_CodesArgs = {
  distinct_on?: InputMaybe<Array<Swab_Codes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Swab_Codes_Order_By>>;
  where?: InputMaybe<Swab_Codes_Bool_Exp>;
};


export type Query_RootSwab_Codes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Swab_Codes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Swab_Codes_Order_By>>;
  where?: InputMaybe<Swab_Codes_Bool_Exp>;
};


export type Query_RootSwab_Codes_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootSwab_Usage_TypeArgs = {
  distinct_on?: InputMaybe<Array<Swab_Usage_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Swab_Usage_Type_Order_By>>;
  where?: InputMaybe<Swab_Usage_Type_Bool_Exp>;
};


export type Query_RootSwab_Usage_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Swab_Usage_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Swab_Usage_Type_Order_By>>;
  where?: InputMaybe<Swab_Usage_Type_Bool_Exp>;
};


export type Query_RootSwab_Usage_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootTest_DefinitionsArgs = {
  distinct_on?: InputMaybe<Array<Test_Definitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Definitions_Order_By>>;
  where?: InputMaybe<Test_Definitions_Bool_Exp>;
};


export type Query_RootTest_Definitions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Test_Definitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Definitions_Order_By>>;
  where?: InputMaybe<Test_Definitions_Bool_Exp>;
};


export type Query_RootTest_Definitions_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTest_Kits_Update_States_Summary_LogArgs = {
  distinct_on?: InputMaybe<Array<Test_Kits_Update_States_Summary_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Kits_Update_States_Summary_Log_Order_By>>;
  where?: InputMaybe<Test_Kits_Update_States_Summary_Log_Bool_Exp>;
};


export type Query_RootTest_Kits_Update_States_Summary_Log_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Test_Kits_Update_States_Summary_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Kits_Update_States_Summary_Log_Order_By>>;
  where?: InputMaybe<Test_Kits_Update_States_Summary_Log_Bool_Exp>;
};


export type Query_RootTest_Kits_Update_States_Summary_Log_By_PkArgs = {
  log_id: Scalars['uuid'];
};


export type Query_RootTest_TypesArgs = {
  distinct_on?: InputMaybe<Array<Test_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Types_Order_By>>;
  where?: InputMaybe<Test_Types_Bool_Exp>;
};


export type Query_RootTest_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Test_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Types_Order_By>>;
  where?: InputMaybe<Test_Types_Bool_Exp>;
};


export type Query_RootTest_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootTestsArgs = {
  distinct_on?: InputMaybe<Array<Tests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tests_Order_By>>;
  where?: InputMaybe<Tests_Bool_Exp>;
};


export type Query_RootTests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tests_Order_By>>;
  where?: InputMaybe<Tests_Bool_Exp>;
};


export type Query_RootTests_By_PkArgs = {
  test_id: Scalars['uuid'];
};


export type Query_RootText_AddressesArgs = {
  distinct_on?: InputMaybe<Array<Text_Addresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Text_Addresses_Order_By>>;
  where?: InputMaybe<Text_Addresses_Bool_Exp>;
};


export type Query_RootText_Addresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Text_Addresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Text_Addresses_Order_By>>;
  where?: InputMaybe<Text_Addresses_Bool_Exp>;
};


export type Query_RootText_Addresses_By_PkArgs = {
  address_id: Scalars['uuid'];
};


export type Query_RootUnauthenticated_Questionnaire_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Unauthenticated_Questionnaire_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Unauthenticated_Questionnaire_Questions_Order_By>>;
  where?: InputMaybe<Unauthenticated_Questionnaire_Questions_Bool_Exp>;
};


export type Query_RootUnauthenticated_Questionnaire_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Unauthenticated_Questionnaire_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Unauthenticated_Questionnaire_Questions_Order_By>>;
  where?: InputMaybe<Unauthenticated_Questionnaire_Questions_Bool_Exp>;
};


export type Query_RootUnauthenticated_Questionnaire_Questions_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootUnauthenticated_QuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<Unauthenticated_Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Unauthenticated_Questionnaires_Order_By>>;
  where?: InputMaybe<Unauthenticated_Questionnaires_Bool_Exp>;
};


export type Query_RootUnauthenticated_Questionnaires_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Unauthenticated_Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Unauthenticated_Questionnaires_Order_By>>;
  where?: InputMaybe<Unauthenticated_Questionnaires_Bool_Exp>;
};


export type Query_RootUnauthenticated_Questionnaires_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootUnauthenticated_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Unauthenticated_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Unauthenticated_Questions_Order_By>>;
  where?: InputMaybe<Unauthenticated_Questions_Bool_Exp>;
};


export type Query_RootUnauthenticated_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Unauthenticated_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Unauthenticated_Questions_Order_By>>;
  where?: InputMaybe<Unauthenticated_Questions_Bool_Exp>;
};


export type Query_RootUnauthenticated_Questions_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootUniversitiesArgs = {
  distinct_on?: InputMaybe<Array<Universities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Universities_Order_By>>;
  where?: InputMaybe<Universities_Bool_Exp>;
};


export type Query_RootUniversities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Universities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Universities_Order_By>>;
  where?: InputMaybe<Universities_Bool_Exp>;
};


export type Query_RootUniversities_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootUser_AddressesArgs = {
  distinct_on?: InputMaybe<Array<User_Addresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Addresses_Order_By>>;
  where?: InputMaybe<User_Addresses_Bool_Exp>;
};


export type Query_RootUser_Addresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Addresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Addresses_Order_By>>;
  where?: InputMaybe<User_Addresses_Bool_Exp>;
};


export type Query_RootUser_Addresses_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootUser_Auth_Provider_TypesArgs = {
  distinct_on?: InputMaybe<Array<User_Auth_Provider_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Auth_Provider_Types_Order_By>>;
  where?: InputMaybe<User_Auth_Provider_Types_Bool_Exp>;
};


export type Query_RootUser_Auth_Provider_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Auth_Provider_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Auth_Provider_Types_Order_By>>;
  where?: InputMaybe<User_Auth_Provider_Types_Bool_Exp>;
};


export type Query_RootUser_Auth_Provider_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootUser_Auth_ProvidersArgs = {
  distinct_on?: InputMaybe<Array<User_Auth_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Auth_Providers_Order_By>>;
  where?: InputMaybe<User_Auth_Providers_Bool_Exp>;
};


export type Query_RootUser_Auth_Providers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Auth_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Auth_Providers_Order_By>>;
  where?: InputMaybe<User_Auth_Providers_Bool_Exp>;
};


export type Query_RootUser_Auth_Providers_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootUser_Auth_TokensArgs = {
  distinct_on?: InputMaybe<Array<User_Auth_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Auth_Tokens_Order_By>>;
  where?: InputMaybe<User_Auth_Tokens_Bool_Exp>;
};


export type Query_RootUser_Auth_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Auth_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Auth_Tokens_Order_By>>;
  where?: InputMaybe<User_Auth_Tokens_Bool_Exp>;
};


export type Query_RootUser_Auth_Tokens_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootUser_Communication_DeliveriesArgs = {
  distinct_on?: InputMaybe<Array<User_Communication_Deliveries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Communication_Deliveries_Order_By>>;
  where?: InputMaybe<User_Communication_Deliveries_Bool_Exp>;
};


export type Query_RootUser_Communication_Deliveries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Communication_Deliveries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Communication_Deliveries_Order_By>>;
  where?: InputMaybe<User_Communication_Deliveries_Bool_Exp>;
};


export type Query_RootUser_Communication_Deliveries_By_PkArgs = {
  user_communication_delivery_id: Scalars['bigint'];
};


export type Query_RootUser_CommunicationsArgs = {
  distinct_on?: InputMaybe<Array<User_Communications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Communications_Order_By>>;
  where?: InputMaybe<User_Communications_Bool_Exp>;
};


export type Query_RootUser_Communications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Communications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Communications_Order_By>>;
  where?: InputMaybe<User_Communications_Bool_Exp>;
};


export type Query_RootUser_Communications_By_PkArgs = {
  user_communication_id: Scalars['bigint'];
};


export type Query_RootUser_Notification_StatusArgs = {
  distinct_on?: InputMaybe<Array<User_Notification_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Notification_Status_Order_By>>;
  where?: InputMaybe<User_Notification_Status_Bool_Exp>;
};


export type Query_RootUser_Notification_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Notification_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Notification_Status_Order_By>>;
  where?: InputMaybe<User_Notification_Status_Bool_Exp>;
};


export type Query_RootUser_Notification_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootUser_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<User_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Notifications_Order_By>>;
  where?: InputMaybe<User_Notifications_Bool_Exp>;
};


export type Query_RootUser_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Notifications_Order_By>>;
  where?: InputMaybe<User_Notifications_Bool_Exp>;
};


export type Query_RootUser_Notifications_By_PkArgs = {
  user_communication_delivery_id: Scalars['bigint'];
};


export type Query_RootUser_RolesArgs = {
  distinct_on?: InputMaybe<Array<User_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Roles_Order_By>>;
  where?: InputMaybe<User_Roles_Bool_Exp>;
};


export type Query_RootUser_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Roles_Order_By>>;
  where?: InputMaybe<User_Roles_Bool_Exp>;
};


export type Query_RootUser_Roles_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootUser_Scanned_DataArgs = {
  distinct_on?: InputMaybe<Array<User_Scanned_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Scanned_Data_Order_By>>;
  where?: InputMaybe<User_Scanned_Data_Bool_Exp>;
};


export type Query_RootUser_Scanned_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Scanned_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Scanned_Data_Order_By>>;
  where?: InputMaybe<User_Scanned_Data_Bool_Exp>;
};


export type Query_RootUser_Scanned_Data_By_PkArgs = {
  user_id: Scalars['uuid'];
};


export type Query_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootVonage_AppointmentArgs = {
  distinct_on?: InputMaybe<Array<Vonage_Appointment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vonage_Appointment_Order_By>>;
  where?: InputMaybe<Vonage_Appointment_Bool_Exp>;
};


export type Query_RootVonage_Appointment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vonage_Appointment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vonage_Appointment_Order_By>>;
  where?: InputMaybe<Vonage_Appointment_Bool_Exp>;
};


export type Query_RootVonage_Appointment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootZrt_TestsArgs = {
  distinct_on?: InputMaybe<Array<Zrt_Tests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zrt_Tests_Order_By>>;
  where?: InputMaybe<Zrt_Tests_Bool_Exp>;
};


export type Query_RootZrt_Tests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zrt_Tests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zrt_Tests_Order_By>>;
  where?: InputMaybe<Zrt_Tests_Bool_Exp>;
};


export type Query_RootZrt_Tests_By_PkArgs = {
  test_id: Scalars['uuid'];
};

/** columns and relationships of "question_measurement_units" */
export type Question_Measurement_Units = {
  __typename?: 'question_measurement_units';
  comment?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "question_measurement_units" */
export type Question_Measurement_Units_Aggregate = {
  __typename?: 'question_measurement_units_aggregate';
  aggregate?: Maybe<Question_Measurement_Units_Aggregate_Fields>;
  nodes: Array<Question_Measurement_Units>;
};

/** aggregate fields of "question_measurement_units" */
export type Question_Measurement_Units_Aggregate_Fields = {
  __typename?: 'question_measurement_units_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Question_Measurement_Units_Max_Fields>;
  min?: Maybe<Question_Measurement_Units_Min_Fields>;
};


/** aggregate fields of "question_measurement_units" */
export type Question_Measurement_Units_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Question_Measurement_Units_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "question_measurement_units". All fields are combined with a logical 'AND'. */
export type Question_Measurement_Units_Bool_Exp = {
  _and?: InputMaybe<Array<Question_Measurement_Units_Bool_Exp>>;
  _not?: InputMaybe<Question_Measurement_Units_Bool_Exp>;
  _or?: InputMaybe<Array<Question_Measurement_Units_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "question_measurement_units" */
export enum Question_Measurement_Units_Constraint {
  /** unique or primary key constraint on columns "value" */
  QuestionMeasurementUnitsPkey = 'question_measurement_units_pkey'
}

export enum Question_Measurement_Units_Enum {
  /** FEET_AND_INCHES, METERS */
  Height = 'HEIGHT',
  /** POUNDS, KILOGRAMS */
  Weight = 'WEIGHT',
  /** FEET_AND_INCHES, CENTIMETERS, METERS */
  Width = 'WIDTH'
}

/** Boolean expression to compare columns of type "question_measurement_units_enum". All fields are combined with logical 'AND'. */
export type Question_Measurement_Units_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Question_Measurement_Units_Enum>;
  _in?: InputMaybe<Array<Question_Measurement_Units_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Question_Measurement_Units_Enum>;
  _nin?: InputMaybe<Array<Question_Measurement_Units_Enum>>;
};

/** input type for inserting data into table "question_measurement_units" */
export type Question_Measurement_Units_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Question_Measurement_Units_Max_Fields = {
  __typename?: 'question_measurement_units_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Question_Measurement_Units_Min_Fields = {
  __typename?: 'question_measurement_units_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "question_measurement_units" */
export type Question_Measurement_Units_Mutation_Response = {
  __typename?: 'question_measurement_units_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Question_Measurement_Units>;
};

/** input type for inserting object relation for remote table "question_measurement_units" */
export type Question_Measurement_Units_Obj_Rel_Insert_Input = {
  data: Question_Measurement_Units_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Question_Measurement_Units_On_Conflict>;
};

/** on_conflict condition type for table "question_measurement_units" */
export type Question_Measurement_Units_On_Conflict = {
  constraint: Question_Measurement_Units_Constraint;
  update_columns?: Array<Question_Measurement_Units_Update_Column>;
  where?: InputMaybe<Question_Measurement_Units_Bool_Exp>;
};

/** Ordering options when selecting data from "question_measurement_units". */
export type Question_Measurement_Units_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: question_measurement_units */
export type Question_Measurement_Units_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "question_measurement_units" */
export enum Question_Measurement_Units_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "question_measurement_units" */
export type Question_Measurement_Units_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "question_measurement_units" */
export type Question_Measurement_Units_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Question_Measurement_Units_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Question_Measurement_Units_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "question_measurement_units" */
export enum Question_Measurement_Units_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Question_Measurement_Units_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Question_Measurement_Units_Set_Input>;
  /** filter the rows which have to be updated */
  where: Question_Measurement_Units_Bool_Exp;
};

/** columns and relationships of "question_types" */
export type Question_Types = {
  __typename?: 'question_types';
  comment?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "question_types" */
export type Question_Types_Aggregate = {
  __typename?: 'question_types_aggregate';
  aggregate?: Maybe<Question_Types_Aggregate_Fields>;
  nodes: Array<Question_Types>;
};

/** aggregate fields of "question_types" */
export type Question_Types_Aggregate_Fields = {
  __typename?: 'question_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Question_Types_Max_Fields>;
  min?: Maybe<Question_Types_Min_Fields>;
};


/** aggregate fields of "question_types" */
export type Question_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Question_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "question_types". All fields are combined with a logical 'AND'. */
export type Question_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Question_Types_Bool_Exp>>;
  _not?: InputMaybe<Question_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Question_Types_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "question_types" */
export enum Question_Types_Constraint {
  /** unique or primary key constraint on columns "value" */
  QuestionTypesPkey = 'question_types_pkey'
}

export enum Question_Types_Enum {
  String = 'STRING'
}

/** Boolean expression to compare columns of type "question_types_enum". All fields are combined with logical 'AND'. */
export type Question_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Question_Types_Enum>;
  _in?: InputMaybe<Array<Question_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Question_Types_Enum>;
  _nin?: InputMaybe<Array<Question_Types_Enum>>;
};

/** input type for inserting data into table "question_types" */
export type Question_Types_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Question_Types_Max_Fields = {
  __typename?: 'question_types_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Question_Types_Min_Fields = {
  __typename?: 'question_types_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "question_types" */
export type Question_Types_Mutation_Response = {
  __typename?: 'question_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Question_Types>;
};

/** on_conflict condition type for table "question_types" */
export type Question_Types_On_Conflict = {
  constraint: Question_Types_Constraint;
  update_columns?: Array<Question_Types_Update_Column>;
  where?: InputMaybe<Question_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "question_types". */
export type Question_Types_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: question_types */
export type Question_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "question_types" */
export enum Question_Types_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "question_types" */
export type Question_Types_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "question_types" */
export type Question_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Question_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Question_Types_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "question_types" */
export enum Question_Types_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Question_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Question_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Question_Types_Bool_Exp;
};

/** columns and relationships of "question_ui_control_helper_text_position" */
export type Question_Ui_Control_Helper_Text_Position = {
  __typename?: 'question_ui_control_helper_text_position';
  helper_text_position: Scalars['String'];
};

/** aggregated selection of "question_ui_control_helper_text_position" */
export type Question_Ui_Control_Helper_Text_Position_Aggregate = {
  __typename?: 'question_ui_control_helper_text_position_aggregate';
  aggregate?: Maybe<Question_Ui_Control_Helper_Text_Position_Aggregate_Fields>;
  nodes: Array<Question_Ui_Control_Helper_Text_Position>;
};

/** aggregate fields of "question_ui_control_helper_text_position" */
export type Question_Ui_Control_Helper_Text_Position_Aggregate_Fields = {
  __typename?: 'question_ui_control_helper_text_position_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Question_Ui_Control_Helper_Text_Position_Max_Fields>;
  min?: Maybe<Question_Ui_Control_Helper_Text_Position_Min_Fields>;
};


/** aggregate fields of "question_ui_control_helper_text_position" */
export type Question_Ui_Control_Helper_Text_Position_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Question_Ui_Control_Helper_Text_Position_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "question_ui_control_helper_text_position". All fields are combined with a logical 'AND'. */
export type Question_Ui_Control_Helper_Text_Position_Bool_Exp = {
  _and?: InputMaybe<Array<Question_Ui_Control_Helper_Text_Position_Bool_Exp>>;
  _not?: InputMaybe<Question_Ui_Control_Helper_Text_Position_Bool_Exp>;
  _or?: InputMaybe<Array<Question_Ui_Control_Helper_Text_Position_Bool_Exp>>;
  helper_text_position?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "question_ui_control_helper_text_position" */
export enum Question_Ui_Control_Helper_Text_Position_Constraint {
  /** unique or primary key constraint on columns "helper_text_position" */
  QuestionUiControlHelperTextPositionPkey = 'question_ui_control_helper_text_position_pkey'
}

export enum Question_Ui_Control_Helper_Text_Position_Enum {
  Left = 'LEFT',
  Right = 'RIGHT'
}

/** Boolean expression to compare columns of type "question_ui_control_helper_text_position_enum". All fields are combined with logical 'AND'. */
export type Question_Ui_Control_Helper_Text_Position_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Question_Ui_Control_Helper_Text_Position_Enum>;
  _in?: InputMaybe<Array<Question_Ui_Control_Helper_Text_Position_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Question_Ui_Control_Helper_Text_Position_Enum>;
  _nin?: InputMaybe<Array<Question_Ui_Control_Helper_Text_Position_Enum>>;
};

/** input type for inserting data into table "question_ui_control_helper_text_position" */
export type Question_Ui_Control_Helper_Text_Position_Insert_Input = {
  helper_text_position?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Question_Ui_Control_Helper_Text_Position_Max_Fields = {
  __typename?: 'question_ui_control_helper_text_position_max_fields';
  helper_text_position?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Question_Ui_Control_Helper_Text_Position_Min_Fields = {
  __typename?: 'question_ui_control_helper_text_position_min_fields';
  helper_text_position?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "question_ui_control_helper_text_position" */
export type Question_Ui_Control_Helper_Text_Position_Mutation_Response = {
  __typename?: 'question_ui_control_helper_text_position_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Question_Ui_Control_Helper_Text_Position>;
};

/** input type for inserting object relation for remote table "question_ui_control_helper_text_position" */
export type Question_Ui_Control_Helper_Text_Position_Obj_Rel_Insert_Input = {
  data: Question_Ui_Control_Helper_Text_Position_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Question_Ui_Control_Helper_Text_Position_On_Conflict>;
};

/** on_conflict condition type for table "question_ui_control_helper_text_position" */
export type Question_Ui_Control_Helper_Text_Position_On_Conflict = {
  constraint: Question_Ui_Control_Helper_Text_Position_Constraint;
  update_columns?: Array<Question_Ui_Control_Helper_Text_Position_Update_Column>;
  where?: InputMaybe<Question_Ui_Control_Helper_Text_Position_Bool_Exp>;
};

/** Ordering options when selecting data from "question_ui_control_helper_text_position". */
export type Question_Ui_Control_Helper_Text_Position_Order_By = {
  helper_text_position?: InputMaybe<Order_By>;
};

/** primary key columns input for table: question_ui_control_helper_text_position */
export type Question_Ui_Control_Helper_Text_Position_Pk_Columns_Input = {
  helper_text_position: Scalars['String'];
};

/** select columns of table "question_ui_control_helper_text_position" */
export enum Question_Ui_Control_Helper_Text_Position_Select_Column {
  /** column name */
  HelperTextPosition = 'helper_text_position'
}

/** input type for updating data in table "question_ui_control_helper_text_position" */
export type Question_Ui_Control_Helper_Text_Position_Set_Input = {
  helper_text_position?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "question_ui_control_helper_text_position" */
export type Question_Ui_Control_Helper_Text_Position_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Question_Ui_Control_Helper_Text_Position_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Question_Ui_Control_Helper_Text_Position_Stream_Cursor_Value_Input = {
  helper_text_position?: InputMaybe<Scalars['String']>;
};

/** update columns of table "question_ui_control_helper_text_position" */
export enum Question_Ui_Control_Helper_Text_Position_Update_Column {
  /** column name */
  HelperTextPosition = 'helper_text_position'
}

export type Question_Ui_Control_Helper_Text_Position_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Question_Ui_Control_Helper_Text_Position_Set_Input>;
  /** filter the rows which have to be updated */
  where: Question_Ui_Control_Helper_Text_Position_Bool_Exp;
};

/** columns and relationships of "question_ui_control_types" */
export type Question_Ui_Control_Types = {
  __typename?: 'question_ui_control_types';
  comment?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "question_ui_control_types" */
export type Question_Ui_Control_Types_Aggregate = {
  __typename?: 'question_ui_control_types_aggregate';
  aggregate?: Maybe<Question_Ui_Control_Types_Aggregate_Fields>;
  nodes: Array<Question_Ui_Control_Types>;
};

/** aggregate fields of "question_ui_control_types" */
export type Question_Ui_Control_Types_Aggregate_Fields = {
  __typename?: 'question_ui_control_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Question_Ui_Control_Types_Max_Fields>;
  min?: Maybe<Question_Ui_Control_Types_Min_Fields>;
};


/** aggregate fields of "question_ui_control_types" */
export type Question_Ui_Control_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Question_Ui_Control_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "question_ui_control_types". All fields are combined with a logical 'AND'. */
export type Question_Ui_Control_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Question_Ui_Control_Types_Bool_Exp>>;
  _not?: InputMaybe<Question_Ui_Control_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Question_Ui_Control_Types_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "question_ui_control_types" */
export enum Question_Ui_Control_Types_Constraint {
  /** unique or primary key constraint on columns "value" */
  QuestionUiControlTypesPkey = 'question_ui_control_types_pkey'
}

export enum Question_Ui_Control_Types_Enum {
  Checkbox = 'CHECKBOX',
  ComboTextWithDropdown = 'COMBO_TEXT_WITH_DROPDOWN',
  Date = 'DATE',
  DateWithHours = 'DATE_WITH_HOURS',
  DateWithHoursDropdown = 'DATE_WITH_HOURS_DROPDOWN',
  DateWithHoursText = 'DATE_WITH_HOURS_TEXT',
  DoubleDropdownCombo = 'DOUBLE_DROPDOWN_COMBO',
  DoubleTextComboLHelperText = 'DOUBLE_TEXT_COMBO_L_HELPER_TEXT',
  Dropdown_35Percent = 'DROPDOWN_35_PERCENT',
  Dropdown_50Percent = 'DROPDOWN_50_PERCENT',
  LookupField = 'LOOKUP_FIELD',
  LookupWithMultiselect = 'LOOKUP_WITH_MULTISELECT',
  Mix = 'MIX',
  Password = 'PASSWORD',
  Phone = 'PHONE',
  RadioButton = 'RADIO_BUTTON',
  RadioWithImage = 'RADIO_WITH_IMAGE',
  Slider = 'SLIDER',
  SmallText = 'SMALL_TEXT',
  Text = 'TEXT',
  TextArea = 'TEXT_AREA'
}

/** Boolean expression to compare columns of type "question_ui_control_types_enum". All fields are combined with logical 'AND'. */
export type Question_Ui_Control_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Question_Ui_Control_Types_Enum>;
  _in?: InputMaybe<Array<Question_Ui_Control_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Question_Ui_Control_Types_Enum>;
  _nin?: InputMaybe<Array<Question_Ui_Control_Types_Enum>>;
};

/** input type for inserting data into table "question_ui_control_types" */
export type Question_Ui_Control_Types_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Question_Ui_Control_Types_Max_Fields = {
  __typename?: 'question_ui_control_types_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Question_Ui_Control_Types_Min_Fields = {
  __typename?: 'question_ui_control_types_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "question_ui_control_types" */
export type Question_Ui_Control_Types_Mutation_Response = {
  __typename?: 'question_ui_control_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Question_Ui_Control_Types>;
};

/** input type for inserting object relation for remote table "question_ui_control_types" */
export type Question_Ui_Control_Types_Obj_Rel_Insert_Input = {
  data: Question_Ui_Control_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Question_Ui_Control_Types_On_Conflict>;
};

/** on_conflict condition type for table "question_ui_control_types" */
export type Question_Ui_Control_Types_On_Conflict = {
  constraint: Question_Ui_Control_Types_Constraint;
  update_columns?: Array<Question_Ui_Control_Types_Update_Column>;
  where?: InputMaybe<Question_Ui_Control_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "question_ui_control_types". */
export type Question_Ui_Control_Types_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: question_ui_control_types */
export type Question_Ui_Control_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "question_ui_control_types" */
export enum Question_Ui_Control_Types_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "question_ui_control_types" */
export type Question_Ui_Control_Types_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "question_ui_control_types" */
export type Question_Ui_Control_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Question_Ui_Control_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Question_Ui_Control_Types_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "question_ui_control_types" */
export enum Question_Ui_Control_Types_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Question_Ui_Control_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Question_Ui_Control_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Question_Ui_Control_Types_Bool_Exp;
};

/** columns and relationships of "questionnaire_questions" */
export type Questionnaire_Questions = {
  __typename?: 'questionnaire_questions';
  id: Scalars['bigint'];
  question_id: Scalars['Int'];
  questionnaire_id: Scalars['Int'];
  /** An object relationship */
  questionnaire_questions_question: Questions;
  /** An object relationship */
  questionnaire_questions_questionnaire: Questionnaires;
};

/** aggregated selection of "questionnaire_questions" */
export type Questionnaire_Questions_Aggregate = {
  __typename?: 'questionnaire_questions_aggregate';
  aggregate?: Maybe<Questionnaire_Questions_Aggregate_Fields>;
  nodes: Array<Questionnaire_Questions>;
};

export type Questionnaire_Questions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Questionnaire_Questions_Aggregate_Bool_Exp_Count>;
};

export type Questionnaire_Questions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Questionnaire_Questions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Questionnaire_Questions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "questionnaire_questions" */
export type Questionnaire_Questions_Aggregate_Fields = {
  __typename?: 'questionnaire_questions_aggregate_fields';
  avg?: Maybe<Questionnaire_Questions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Questionnaire_Questions_Max_Fields>;
  min?: Maybe<Questionnaire_Questions_Min_Fields>;
  stddev?: Maybe<Questionnaire_Questions_Stddev_Fields>;
  stddev_pop?: Maybe<Questionnaire_Questions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Questionnaire_Questions_Stddev_Samp_Fields>;
  sum?: Maybe<Questionnaire_Questions_Sum_Fields>;
  var_pop?: Maybe<Questionnaire_Questions_Var_Pop_Fields>;
  var_samp?: Maybe<Questionnaire_Questions_Var_Samp_Fields>;
  variance?: Maybe<Questionnaire_Questions_Variance_Fields>;
};


/** aggregate fields of "questionnaire_questions" */
export type Questionnaire_Questions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Questionnaire_Questions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "questionnaire_questions" */
export type Questionnaire_Questions_Aggregate_Order_By = {
  avg?: InputMaybe<Questionnaire_Questions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Questionnaire_Questions_Max_Order_By>;
  min?: InputMaybe<Questionnaire_Questions_Min_Order_By>;
  stddev?: InputMaybe<Questionnaire_Questions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Questionnaire_Questions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Questionnaire_Questions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Questionnaire_Questions_Sum_Order_By>;
  var_pop?: InputMaybe<Questionnaire_Questions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Questionnaire_Questions_Var_Samp_Order_By>;
  variance?: InputMaybe<Questionnaire_Questions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "questionnaire_questions" */
export type Questionnaire_Questions_Arr_Rel_Insert_Input = {
  data: Array<Questionnaire_Questions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Questionnaire_Questions_On_Conflict>;
};

/** aggregate avg on columns */
export type Questionnaire_Questions_Avg_Fields = {
  __typename?: 'questionnaire_questions_avg_fields';
  id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
  questionnaire_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "questionnaire_questions" */
export type Questionnaire_Questions_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  question_id?: InputMaybe<Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "questionnaire_questions". All fields are combined with a logical 'AND'. */
export type Questionnaire_Questions_Bool_Exp = {
  _and?: InputMaybe<Array<Questionnaire_Questions_Bool_Exp>>;
  _not?: InputMaybe<Questionnaire_Questions_Bool_Exp>;
  _or?: InputMaybe<Array<Questionnaire_Questions_Bool_Exp>>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  question_id?: InputMaybe<Int_Comparison_Exp>;
  questionnaire_id?: InputMaybe<Int_Comparison_Exp>;
  questionnaire_questions_question?: InputMaybe<Questions_Bool_Exp>;
  questionnaire_questions_questionnaire?: InputMaybe<Questionnaires_Bool_Exp>;
};

/** unique or primary key constraints on table "questionnaire_questions" */
export enum Questionnaire_Questions_Constraint {
  /** unique or primary key constraint on columns "id" */
  QuestionnaireQuestionsPkey = 'questionnaire_questions_pkey',
  /** unique or primary key constraint on columns "questionnaire_id", "question_id" */
  QuestionnaireQuestionsQuestionnaireIdQuestionIdKey = 'questionnaire_questions_questionnaire_id_question_id_key'
}

/** input type for incrementing numeric columns in table "questionnaire_questions" */
export type Questionnaire_Questions_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  question_id?: InputMaybe<Scalars['Int']>;
  questionnaire_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "questionnaire_questions" */
export type Questionnaire_Questions_Insert_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  question_id?: InputMaybe<Scalars['Int']>;
  questionnaire_id?: InputMaybe<Scalars['Int']>;
  questionnaire_questions_question?: InputMaybe<Questions_Obj_Rel_Insert_Input>;
  questionnaire_questions_questionnaire?: InputMaybe<Questionnaires_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Questionnaire_Questions_Max_Fields = {
  __typename?: 'questionnaire_questions_max_fields';
  id?: Maybe<Scalars['bigint']>;
  question_id?: Maybe<Scalars['Int']>;
  questionnaire_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "questionnaire_questions" */
export type Questionnaire_Questions_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  question_id?: InputMaybe<Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Questionnaire_Questions_Min_Fields = {
  __typename?: 'questionnaire_questions_min_fields';
  id?: Maybe<Scalars['bigint']>;
  question_id?: Maybe<Scalars['Int']>;
  questionnaire_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "questionnaire_questions" */
export type Questionnaire_Questions_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  question_id?: InputMaybe<Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "questionnaire_questions" */
export type Questionnaire_Questions_Mutation_Response = {
  __typename?: 'questionnaire_questions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Questionnaire_Questions>;
};

/** on_conflict condition type for table "questionnaire_questions" */
export type Questionnaire_Questions_On_Conflict = {
  constraint: Questionnaire_Questions_Constraint;
  update_columns?: Array<Questionnaire_Questions_Update_Column>;
  where?: InputMaybe<Questionnaire_Questions_Bool_Exp>;
};

/** Ordering options when selecting data from "questionnaire_questions". */
export type Questionnaire_Questions_Order_By = {
  id?: InputMaybe<Order_By>;
  question_id?: InputMaybe<Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
  questionnaire_questions_question?: InputMaybe<Questions_Order_By>;
  questionnaire_questions_questionnaire?: InputMaybe<Questionnaires_Order_By>;
};

/** primary key columns input for table: questionnaire_questions */
export type Questionnaire_Questions_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "questionnaire_questions" */
export enum Questionnaire_Questions_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  QuestionnaireId = 'questionnaire_id'
}

/** input type for updating data in table "questionnaire_questions" */
export type Questionnaire_Questions_Set_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  question_id?: InputMaybe<Scalars['Int']>;
  questionnaire_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Questionnaire_Questions_Stddev_Fields = {
  __typename?: 'questionnaire_questions_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
  questionnaire_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "questionnaire_questions" */
export type Questionnaire_Questions_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  question_id?: InputMaybe<Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Questionnaire_Questions_Stddev_Pop_Fields = {
  __typename?: 'questionnaire_questions_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
  questionnaire_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "questionnaire_questions" */
export type Questionnaire_Questions_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  question_id?: InputMaybe<Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Questionnaire_Questions_Stddev_Samp_Fields = {
  __typename?: 'questionnaire_questions_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
  questionnaire_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "questionnaire_questions" */
export type Questionnaire_Questions_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  question_id?: InputMaybe<Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "questionnaire_questions" */
export type Questionnaire_Questions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Questionnaire_Questions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Questionnaire_Questions_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  question_id?: InputMaybe<Scalars['Int']>;
  questionnaire_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Questionnaire_Questions_Sum_Fields = {
  __typename?: 'questionnaire_questions_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  question_id?: Maybe<Scalars['Int']>;
  questionnaire_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "questionnaire_questions" */
export type Questionnaire_Questions_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  question_id?: InputMaybe<Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
};

/** update columns of table "questionnaire_questions" */
export enum Questionnaire_Questions_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  QuestionnaireId = 'questionnaire_id'
}

export type Questionnaire_Questions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Questionnaire_Questions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Questionnaire_Questions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Questionnaire_Questions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Questionnaire_Questions_Var_Pop_Fields = {
  __typename?: 'questionnaire_questions_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
  questionnaire_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "questionnaire_questions" */
export type Questionnaire_Questions_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  question_id?: InputMaybe<Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Questionnaire_Questions_Var_Samp_Fields = {
  __typename?: 'questionnaire_questions_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
  questionnaire_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "questionnaire_questions" */
export type Questionnaire_Questions_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  question_id?: InputMaybe<Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Questionnaire_Questions_Variance_Fields = {
  __typename?: 'questionnaire_questions_variance_fields';
  id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
  questionnaire_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "questionnaire_questions" */
export type Questionnaire_Questions_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  question_id?: InputMaybe<Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "questionnaire_responses" */
export type Questionnaire_Responses = {
  __typename?: 'questionnaire_responses';
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  is_complete: Scalars['Boolean'];
  questionnaire_id: Scalars['Int'];
  /** An object relationship */
  questionnaire_responses_questionnaire: Questionnaires;
  responses: Scalars['jsonb'];
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['uuid'];
};


/** columns and relationships of "questionnaire_responses" */
export type Questionnaire_ResponsesResponsesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "questionnaire_responses" */
export type Questionnaire_Responses_Aggregate = {
  __typename?: 'questionnaire_responses_aggregate';
  aggregate?: Maybe<Questionnaire_Responses_Aggregate_Fields>;
  nodes: Array<Questionnaire_Responses>;
};

/** aggregate fields of "questionnaire_responses" */
export type Questionnaire_Responses_Aggregate_Fields = {
  __typename?: 'questionnaire_responses_aggregate_fields';
  avg?: Maybe<Questionnaire_Responses_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Questionnaire_Responses_Max_Fields>;
  min?: Maybe<Questionnaire_Responses_Min_Fields>;
  stddev?: Maybe<Questionnaire_Responses_Stddev_Fields>;
  stddev_pop?: Maybe<Questionnaire_Responses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Questionnaire_Responses_Stddev_Samp_Fields>;
  sum?: Maybe<Questionnaire_Responses_Sum_Fields>;
  var_pop?: Maybe<Questionnaire_Responses_Var_Pop_Fields>;
  var_samp?: Maybe<Questionnaire_Responses_Var_Samp_Fields>;
  variance?: Maybe<Questionnaire_Responses_Variance_Fields>;
};


/** aggregate fields of "questionnaire_responses" */
export type Questionnaire_Responses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Questionnaire_Responses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Questionnaire_Responses_Append_Input = {
  responses?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Questionnaire_Responses_Avg_Fields = {
  __typename?: 'questionnaire_responses_avg_fields';
  id?: Maybe<Scalars['Float']>;
  questionnaire_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "questionnaire_responses". All fields are combined with a logical 'AND'. */
export type Questionnaire_Responses_Bool_Exp = {
  _and?: InputMaybe<Array<Questionnaire_Responses_Bool_Exp>>;
  _not?: InputMaybe<Questionnaire_Responses_Bool_Exp>;
  _or?: InputMaybe<Array<Questionnaire_Responses_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  is_complete?: InputMaybe<Boolean_Comparison_Exp>;
  questionnaire_id?: InputMaybe<Int_Comparison_Exp>;
  questionnaire_responses_questionnaire?: InputMaybe<Questionnaires_Bool_Exp>;
  responses?: InputMaybe<Jsonb_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "questionnaire_responses" */
export enum Questionnaire_Responses_Constraint {
  /** unique or primary key constraint on columns "id" */
  QuestionnaireResponsesPkey = 'questionnaire_responses_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Questionnaire_Responses_Delete_At_Path_Input = {
  responses?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Questionnaire_Responses_Delete_Elem_Input = {
  responses?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Questionnaire_Responses_Delete_Key_Input = {
  responses?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "questionnaire_responses" */
export type Questionnaire_Responses_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  questionnaire_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "questionnaire_responses" */
export type Questionnaire_Responses_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  is_complete?: InputMaybe<Scalars['Boolean']>;
  questionnaire_id?: InputMaybe<Scalars['Int']>;
  questionnaire_responses_questionnaire?: InputMaybe<Questionnaires_Obj_Rel_Insert_Input>;
  responses?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Questionnaire_Responses_Max_Fields = {
  __typename?: 'questionnaire_responses_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  questionnaire_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Questionnaire_Responses_Min_Fields = {
  __typename?: 'questionnaire_responses_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  questionnaire_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "questionnaire_responses" */
export type Questionnaire_Responses_Mutation_Response = {
  __typename?: 'questionnaire_responses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Questionnaire_Responses>;
};

/** on_conflict condition type for table "questionnaire_responses" */
export type Questionnaire_Responses_On_Conflict = {
  constraint: Questionnaire_Responses_Constraint;
  update_columns?: Array<Questionnaire_Responses_Update_Column>;
  where?: InputMaybe<Questionnaire_Responses_Bool_Exp>;
};

/** Ordering options when selecting data from "questionnaire_responses". */
export type Questionnaire_Responses_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_complete?: InputMaybe<Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
  questionnaire_responses_questionnaire?: InputMaybe<Questionnaires_Order_By>;
  responses?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: questionnaire_responses */
export type Questionnaire_Responses_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Questionnaire_Responses_Prepend_Input = {
  responses?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "questionnaire_responses" */
export enum Questionnaire_Responses_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsComplete = 'is_complete',
  /** column name */
  QuestionnaireId = 'questionnaire_id',
  /** column name */
  Responses = 'responses',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "questionnaire_responses" */
export type Questionnaire_Responses_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  is_complete?: InputMaybe<Scalars['Boolean']>;
  questionnaire_id?: InputMaybe<Scalars['Int']>;
  responses?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Questionnaire_Responses_Stddev_Fields = {
  __typename?: 'questionnaire_responses_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  questionnaire_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Questionnaire_Responses_Stddev_Pop_Fields = {
  __typename?: 'questionnaire_responses_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  questionnaire_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Questionnaire_Responses_Stddev_Samp_Fields = {
  __typename?: 'questionnaire_responses_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  questionnaire_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "questionnaire_responses" */
export type Questionnaire_Responses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Questionnaire_Responses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Questionnaire_Responses_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  is_complete?: InputMaybe<Scalars['Boolean']>;
  questionnaire_id?: InputMaybe<Scalars['Int']>;
  responses?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Questionnaire_Responses_Sum_Fields = {
  __typename?: 'questionnaire_responses_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  questionnaire_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "questionnaire_responses" */
export enum Questionnaire_Responses_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsComplete = 'is_complete',
  /** column name */
  QuestionnaireId = 'questionnaire_id',
  /** column name */
  Responses = 'responses',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Questionnaire_Responses_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Questionnaire_Responses_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Questionnaire_Responses_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Questionnaire_Responses_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Questionnaire_Responses_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Questionnaire_Responses_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Questionnaire_Responses_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Questionnaire_Responses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Questionnaire_Responses_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Questionnaire_Responses_Var_Pop_Fields = {
  __typename?: 'questionnaire_responses_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  questionnaire_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Questionnaire_Responses_Var_Samp_Fields = {
  __typename?: 'questionnaire_responses_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  questionnaire_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Questionnaire_Responses_Variance_Fields = {
  __typename?: 'questionnaire_responses_variance_fields';
  id?: Maybe<Scalars['Float']>;
  questionnaire_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "questionnaires" */
export type Questionnaires = {
  __typename?: 'questionnaires';
  data: Scalars['jsonb'];
  id: Scalars['Int'];
  is_active: Scalars['Boolean'];
  notes?: Maybe<Scalars['String']>;
  /** An array relationship */
  questionnaires_questionnaire_questions: Array<Questionnaire_Questions>;
  /** An aggregate relationship */
  questionnaires_questionnaire_questions_aggregate: Questionnaire_Questions_Aggregate;
  subtitle?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};


/** columns and relationships of "questionnaires" */
export type QuestionnairesDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "questionnaires" */
export type QuestionnairesQuestionnaires_Questionnaire_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Questionnaire_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questionnaire_Questions_Order_By>>;
  where?: InputMaybe<Questionnaire_Questions_Bool_Exp>;
};


/** columns and relationships of "questionnaires" */
export type QuestionnairesQuestionnaires_Questionnaire_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questionnaire_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questionnaire_Questions_Order_By>>;
  where?: InputMaybe<Questionnaire_Questions_Bool_Exp>;
};

/** aggregated selection of "questionnaires" */
export type Questionnaires_Aggregate = {
  __typename?: 'questionnaires_aggregate';
  aggregate?: Maybe<Questionnaires_Aggregate_Fields>;
  nodes: Array<Questionnaires>;
};

/** aggregate fields of "questionnaires" */
export type Questionnaires_Aggregate_Fields = {
  __typename?: 'questionnaires_aggregate_fields';
  avg?: Maybe<Questionnaires_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Questionnaires_Max_Fields>;
  min?: Maybe<Questionnaires_Min_Fields>;
  stddev?: Maybe<Questionnaires_Stddev_Fields>;
  stddev_pop?: Maybe<Questionnaires_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Questionnaires_Stddev_Samp_Fields>;
  sum?: Maybe<Questionnaires_Sum_Fields>;
  var_pop?: Maybe<Questionnaires_Var_Pop_Fields>;
  var_samp?: Maybe<Questionnaires_Var_Samp_Fields>;
  variance?: Maybe<Questionnaires_Variance_Fields>;
};


/** aggregate fields of "questionnaires" */
export type Questionnaires_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Questionnaires_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Questionnaires_Append_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Questionnaires_Avg_Fields = {
  __typename?: 'questionnaires_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "questionnaires". All fields are combined with a logical 'AND'. */
export type Questionnaires_Bool_Exp = {
  _and?: InputMaybe<Array<Questionnaires_Bool_Exp>>;
  _not?: InputMaybe<Questionnaires_Bool_Exp>;
  _or?: InputMaybe<Array<Questionnaires_Bool_Exp>>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  questionnaires_questionnaire_questions?: InputMaybe<Questionnaire_Questions_Bool_Exp>;
  questionnaires_questionnaire_questions_aggregate?: InputMaybe<Questionnaire_Questions_Aggregate_Bool_Exp>;
  subtitle?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "questionnaires" */
export enum Questionnaires_Constraint {
  /** unique or primary key constraint on columns "id" */
  QuestionnairesPkey = 'questionnaires_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Questionnaires_Delete_At_Path_Input = {
  data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Questionnaires_Delete_Elem_Input = {
  data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Questionnaires_Delete_Key_Input = {
  data?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "questionnaires" */
export type Questionnaires_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "questionnaires" */
export type Questionnaires_Insert_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  notes?: InputMaybe<Scalars['String']>;
  questionnaires_questionnaire_questions?: InputMaybe<Questionnaire_Questions_Arr_Rel_Insert_Input>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Questionnaires_Max_Fields = {
  __typename?: 'questionnaires_max_fields';
  id?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Questionnaires_Min_Fields = {
  __typename?: 'questionnaires_min_fields';
  id?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "questionnaires" */
export type Questionnaires_Mutation_Response = {
  __typename?: 'questionnaires_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Questionnaires>;
};

/** input type for inserting object relation for remote table "questionnaires" */
export type Questionnaires_Obj_Rel_Insert_Input = {
  data: Questionnaires_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Questionnaires_On_Conflict>;
};

/** on_conflict condition type for table "questionnaires" */
export type Questionnaires_On_Conflict = {
  constraint: Questionnaires_Constraint;
  update_columns?: Array<Questionnaires_Update_Column>;
  where?: InputMaybe<Questionnaires_Bool_Exp>;
};

/** Ordering options when selecting data from "questionnaires". */
export type Questionnaires_Order_By = {
  data?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  questionnaires_questionnaire_questions_aggregate?: InputMaybe<Questionnaire_Questions_Aggregate_Order_By>;
  subtitle?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** primary key columns input for table: questionnaires */
export type Questionnaires_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Questionnaires_Prepend_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "questionnaires" */
export enum Questionnaires_Select_Column {
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Notes = 'notes',
  /** column name */
  Subtitle = 'subtitle',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "questionnaires" */
export type Questionnaires_Set_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  notes?: InputMaybe<Scalars['String']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Questionnaires_Stddev_Fields = {
  __typename?: 'questionnaires_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Questionnaires_Stddev_Pop_Fields = {
  __typename?: 'questionnaires_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Questionnaires_Stddev_Samp_Fields = {
  __typename?: 'questionnaires_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "questionnaires" */
export type Questionnaires_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Questionnaires_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Questionnaires_Stream_Cursor_Value_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  notes?: InputMaybe<Scalars['String']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Questionnaires_Sum_Fields = {
  __typename?: 'questionnaires_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "questionnaires" */
export enum Questionnaires_Update_Column {
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Notes = 'notes',
  /** column name */
  Subtitle = 'subtitle',
  /** column name */
  Title = 'title'
}

export type Questionnaires_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Questionnaires_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Questionnaires_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Questionnaires_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Questionnaires_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Questionnaires_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Questionnaires_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Questionnaires_Set_Input>;
  /** filter the rows which have to be updated */
  where: Questionnaires_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Questionnaires_Var_Pop_Fields = {
  __typename?: 'questionnaires_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Questionnaires_Var_Samp_Fields = {
  __typename?: 'questionnaires_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Questionnaires_Variance_Fields = {
  __typename?: 'questionnaires_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "questions" */
export type Questions = {
  __typename?: 'questions';
  answers?: Maybe<Scalars['_text']>;
  default?: Maybe<Scalars['String']>;
  dont_know_answer?: Maybe<Scalars['String']>;
  double_text_combo_helper_texts?: Maybe<Scalars['jsonb']>;
  dropdown_combo_answers?: Maybe<Scalars['jsonb']>;
  dropdown_label_1?: Maybe<Scalars['String']>;
  dropdown_label_2?: Maybe<Scalars['String']>;
  helper_text?: Maybe<Scalars['String']>;
  helper_text_position?: Maybe<Question_Ui_Control_Helper_Text_Position_Enum>;
  id: Scalars['Int'];
  measurement_unit?: Maybe<Question_Measurement_Units_Enum>;
  response_property?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  type: Question_Types_Enum;
  ui_control: Question_Ui_Control_Types_Enum;
};


/** columns and relationships of "questions" */
export type QuestionsDouble_Text_Combo_Helper_TextsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "questions" */
export type QuestionsDropdown_Combo_AnswersArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "questions" */
export type Questions_Aggregate = {
  __typename?: 'questions_aggregate';
  aggregate?: Maybe<Questions_Aggregate_Fields>;
  nodes: Array<Questions>;
};

/** aggregate fields of "questions" */
export type Questions_Aggregate_Fields = {
  __typename?: 'questions_aggregate_fields';
  avg?: Maybe<Questions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Questions_Max_Fields>;
  min?: Maybe<Questions_Min_Fields>;
  stddev?: Maybe<Questions_Stddev_Fields>;
  stddev_pop?: Maybe<Questions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Questions_Stddev_Samp_Fields>;
  sum?: Maybe<Questions_Sum_Fields>;
  var_pop?: Maybe<Questions_Var_Pop_Fields>;
  var_samp?: Maybe<Questions_Var_Samp_Fields>;
  variance?: Maybe<Questions_Variance_Fields>;
};


/** aggregate fields of "questions" */
export type Questions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Questions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Questions_Append_Input = {
  double_text_combo_helper_texts?: InputMaybe<Scalars['jsonb']>;
  dropdown_combo_answers?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Questions_Avg_Fields = {
  __typename?: 'questions_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "questions". All fields are combined with a logical 'AND'. */
export type Questions_Bool_Exp = {
  _and?: InputMaybe<Array<Questions_Bool_Exp>>;
  _not?: InputMaybe<Questions_Bool_Exp>;
  _or?: InputMaybe<Array<Questions_Bool_Exp>>;
  answers?: InputMaybe<_Text_Comparison_Exp>;
  default?: InputMaybe<String_Comparison_Exp>;
  dont_know_answer?: InputMaybe<String_Comparison_Exp>;
  double_text_combo_helper_texts?: InputMaybe<Jsonb_Comparison_Exp>;
  dropdown_combo_answers?: InputMaybe<Jsonb_Comparison_Exp>;
  dropdown_label_1?: InputMaybe<String_Comparison_Exp>;
  dropdown_label_2?: InputMaybe<String_Comparison_Exp>;
  helper_text?: InputMaybe<String_Comparison_Exp>;
  helper_text_position?: InputMaybe<Question_Ui_Control_Helper_Text_Position_Enum_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  measurement_unit?: InputMaybe<Question_Measurement_Units_Enum_Comparison_Exp>;
  response_property?: InputMaybe<String_Comparison_Exp>;
  subtitle?: InputMaybe<String_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<Question_Types_Enum_Comparison_Exp>;
  ui_control?: InputMaybe<Question_Ui_Control_Types_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "questions" */
export enum Questions_Constraint {
  /** unique or primary key constraint on columns "id" */
  QuestionsPkey = 'questions_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Questions_Delete_At_Path_Input = {
  double_text_combo_helper_texts?: InputMaybe<Array<Scalars['String']>>;
  dropdown_combo_answers?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Questions_Delete_Elem_Input = {
  double_text_combo_helper_texts?: InputMaybe<Scalars['Int']>;
  dropdown_combo_answers?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Questions_Delete_Key_Input = {
  double_text_combo_helper_texts?: InputMaybe<Scalars['String']>;
  dropdown_combo_answers?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "questions" */
export type Questions_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "questions" */
export type Questions_Insert_Input = {
  answers?: InputMaybe<Scalars['_text']>;
  default?: InputMaybe<Scalars['String']>;
  dont_know_answer?: InputMaybe<Scalars['String']>;
  double_text_combo_helper_texts?: InputMaybe<Scalars['jsonb']>;
  dropdown_combo_answers?: InputMaybe<Scalars['jsonb']>;
  dropdown_label_1?: InputMaybe<Scalars['String']>;
  dropdown_label_2?: InputMaybe<Scalars['String']>;
  helper_text?: InputMaybe<Scalars['String']>;
  helper_text_position?: InputMaybe<Question_Ui_Control_Helper_Text_Position_Enum>;
  id?: InputMaybe<Scalars['Int']>;
  measurement_unit?: InputMaybe<Question_Measurement_Units_Enum>;
  response_property?: InputMaybe<Scalars['String']>;
  subtitle?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Question_Types_Enum>;
  ui_control?: InputMaybe<Question_Ui_Control_Types_Enum>;
};

/** aggregate max on columns */
export type Questions_Max_Fields = {
  __typename?: 'questions_max_fields';
  default?: Maybe<Scalars['String']>;
  dont_know_answer?: Maybe<Scalars['String']>;
  dropdown_label_1?: Maybe<Scalars['String']>;
  dropdown_label_2?: Maybe<Scalars['String']>;
  helper_text?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  response_property?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Questions_Min_Fields = {
  __typename?: 'questions_min_fields';
  default?: Maybe<Scalars['String']>;
  dont_know_answer?: Maybe<Scalars['String']>;
  dropdown_label_1?: Maybe<Scalars['String']>;
  dropdown_label_2?: Maybe<Scalars['String']>;
  helper_text?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  response_property?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "questions" */
export type Questions_Mutation_Response = {
  __typename?: 'questions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Questions>;
};

/** input type for inserting object relation for remote table "questions" */
export type Questions_Obj_Rel_Insert_Input = {
  data: Questions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Questions_On_Conflict>;
};

/** on_conflict condition type for table "questions" */
export type Questions_On_Conflict = {
  constraint: Questions_Constraint;
  update_columns?: Array<Questions_Update_Column>;
  where?: InputMaybe<Questions_Bool_Exp>;
};

/** Ordering options when selecting data from "questions". */
export type Questions_Order_By = {
  answers?: InputMaybe<Order_By>;
  default?: InputMaybe<Order_By>;
  dont_know_answer?: InputMaybe<Order_By>;
  double_text_combo_helper_texts?: InputMaybe<Order_By>;
  dropdown_combo_answers?: InputMaybe<Order_By>;
  dropdown_label_1?: InputMaybe<Order_By>;
  dropdown_label_2?: InputMaybe<Order_By>;
  helper_text?: InputMaybe<Order_By>;
  helper_text_position?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  measurement_unit?: InputMaybe<Order_By>;
  response_property?: InputMaybe<Order_By>;
  subtitle?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  ui_control?: InputMaybe<Order_By>;
};

/** primary key columns input for table: questions */
export type Questions_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Questions_Prepend_Input = {
  double_text_combo_helper_texts?: InputMaybe<Scalars['jsonb']>;
  dropdown_combo_answers?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "questions" */
export enum Questions_Select_Column {
  /** column name */
  Answers = 'answers',
  /** column name */
  Default = 'default',
  /** column name */
  DontKnowAnswer = 'dont_know_answer',
  /** column name */
  DoubleTextComboHelperTexts = 'double_text_combo_helper_texts',
  /** column name */
  DropdownComboAnswers = 'dropdown_combo_answers',
  /** column name */
  DropdownLabel_1 = 'dropdown_label_1',
  /** column name */
  DropdownLabel_2 = 'dropdown_label_2',
  /** column name */
  HelperText = 'helper_text',
  /** column name */
  HelperTextPosition = 'helper_text_position',
  /** column name */
  Id = 'id',
  /** column name */
  MeasurementUnit = 'measurement_unit',
  /** column name */
  ResponseProperty = 'response_property',
  /** column name */
  Subtitle = 'subtitle',
  /** column name */
  Text = 'text',
  /** column name */
  Type = 'type',
  /** column name */
  UiControl = 'ui_control'
}

/** input type for updating data in table "questions" */
export type Questions_Set_Input = {
  answers?: InputMaybe<Scalars['_text']>;
  default?: InputMaybe<Scalars['String']>;
  dont_know_answer?: InputMaybe<Scalars['String']>;
  double_text_combo_helper_texts?: InputMaybe<Scalars['jsonb']>;
  dropdown_combo_answers?: InputMaybe<Scalars['jsonb']>;
  dropdown_label_1?: InputMaybe<Scalars['String']>;
  dropdown_label_2?: InputMaybe<Scalars['String']>;
  helper_text?: InputMaybe<Scalars['String']>;
  helper_text_position?: InputMaybe<Question_Ui_Control_Helper_Text_Position_Enum>;
  id?: InputMaybe<Scalars['Int']>;
  measurement_unit?: InputMaybe<Question_Measurement_Units_Enum>;
  response_property?: InputMaybe<Scalars['String']>;
  subtitle?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Question_Types_Enum>;
  ui_control?: InputMaybe<Question_Ui_Control_Types_Enum>;
};

/** aggregate stddev on columns */
export type Questions_Stddev_Fields = {
  __typename?: 'questions_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Questions_Stddev_Pop_Fields = {
  __typename?: 'questions_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Questions_Stddev_Samp_Fields = {
  __typename?: 'questions_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "questions" */
export type Questions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Questions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Questions_Stream_Cursor_Value_Input = {
  answers?: InputMaybe<Scalars['_text']>;
  default?: InputMaybe<Scalars['String']>;
  dont_know_answer?: InputMaybe<Scalars['String']>;
  double_text_combo_helper_texts?: InputMaybe<Scalars['jsonb']>;
  dropdown_combo_answers?: InputMaybe<Scalars['jsonb']>;
  dropdown_label_1?: InputMaybe<Scalars['String']>;
  dropdown_label_2?: InputMaybe<Scalars['String']>;
  helper_text?: InputMaybe<Scalars['String']>;
  helper_text_position?: InputMaybe<Question_Ui_Control_Helper_Text_Position_Enum>;
  id?: InputMaybe<Scalars['Int']>;
  measurement_unit?: InputMaybe<Question_Measurement_Units_Enum>;
  response_property?: InputMaybe<Scalars['String']>;
  subtitle?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Question_Types_Enum>;
  ui_control?: InputMaybe<Question_Ui_Control_Types_Enum>;
};

/** aggregate sum on columns */
export type Questions_Sum_Fields = {
  __typename?: 'questions_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "questions" */
export enum Questions_Update_Column {
  /** column name */
  Answers = 'answers',
  /** column name */
  Default = 'default',
  /** column name */
  DontKnowAnswer = 'dont_know_answer',
  /** column name */
  DoubleTextComboHelperTexts = 'double_text_combo_helper_texts',
  /** column name */
  DropdownComboAnswers = 'dropdown_combo_answers',
  /** column name */
  DropdownLabel_1 = 'dropdown_label_1',
  /** column name */
  DropdownLabel_2 = 'dropdown_label_2',
  /** column name */
  HelperText = 'helper_text',
  /** column name */
  HelperTextPosition = 'helper_text_position',
  /** column name */
  Id = 'id',
  /** column name */
  MeasurementUnit = 'measurement_unit',
  /** column name */
  ResponseProperty = 'response_property',
  /** column name */
  Subtitle = 'subtitle',
  /** column name */
  Text = 'text',
  /** column name */
  Type = 'type',
  /** column name */
  UiControl = 'ui_control'
}

export type Questions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Questions_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Questions_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Questions_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Questions_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Questions_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Questions_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Questions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Questions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Questions_Var_Pop_Fields = {
  __typename?: 'questions_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Questions_Var_Samp_Fields = {
  __typename?: 'questions_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Questions_Variance_Fields = {
  __typename?: 'questions_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "role_types" */
export type Role_Types = {
  __typename?: 'role_types';
  comment?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "role_types" */
export type Role_Types_Aggregate = {
  __typename?: 'role_types_aggregate';
  aggregate?: Maybe<Role_Types_Aggregate_Fields>;
  nodes: Array<Role_Types>;
};

/** aggregate fields of "role_types" */
export type Role_Types_Aggregate_Fields = {
  __typename?: 'role_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Role_Types_Max_Fields>;
  min?: Maybe<Role_Types_Min_Fields>;
};


/** aggregate fields of "role_types" */
export type Role_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Role_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "role_types". All fields are combined with a logical 'AND'. */
export type Role_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Role_Types_Bool_Exp>>;
  _not?: InputMaybe<Role_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Role_Types_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "role_types" */
export enum Role_Types_Constraint {
  /** unique or primary key constraint on columns "value" */
  RoleTypesPkey = 'role_types_pkey'
}

export enum Role_Types_Enum {
  Patient = 'PATIENT'
}

/** Boolean expression to compare columns of type "role_types_enum". All fields are combined with logical 'AND'. */
export type Role_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Role_Types_Enum>;
  _in?: InputMaybe<Array<Role_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Role_Types_Enum>;
  _nin?: InputMaybe<Array<Role_Types_Enum>>;
};

/** input type for inserting data into table "role_types" */
export type Role_Types_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Role_Types_Max_Fields = {
  __typename?: 'role_types_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Role_Types_Min_Fields = {
  __typename?: 'role_types_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "role_types" */
export type Role_Types_Mutation_Response = {
  __typename?: 'role_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Role_Types>;
};

/** on_conflict condition type for table "role_types" */
export type Role_Types_On_Conflict = {
  constraint: Role_Types_Constraint;
  update_columns?: Array<Role_Types_Update_Column>;
  where?: InputMaybe<Role_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "role_types". */
export type Role_Types_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: role_types */
export type Role_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "role_types" */
export enum Role_Types_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "role_types" */
export type Role_Types_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "role_types" */
export type Role_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Role_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Role_Types_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "role_types" */
export enum Role_Types_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Role_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Role_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Role_Types_Bool_Exp;
};

/** columns and relationships of "roles" */
export type Roles = {
  __typename?: 'roles';
  id: Scalars['Int'];
  name: Role_Types_Enum;
  questionnaire_id?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "roles" */
export type Roles_Aggregate = {
  __typename?: 'roles_aggregate';
  aggregate?: Maybe<Roles_Aggregate_Fields>;
  nodes: Array<Roles>;
};

/** aggregate fields of "roles" */
export type Roles_Aggregate_Fields = {
  __typename?: 'roles_aggregate_fields';
  avg?: Maybe<Roles_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Roles_Max_Fields>;
  min?: Maybe<Roles_Min_Fields>;
  stddev?: Maybe<Roles_Stddev_Fields>;
  stddev_pop?: Maybe<Roles_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Roles_Stddev_Samp_Fields>;
  sum?: Maybe<Roles_Sum_Fields>;
  var_pop?: Maybe<Roles_Var_Pop_Fields>;
  var_samp?: Maybe<Roles_Var_Samp_Fields>;
  variance?: Maybe<Roles_Variance_Fields>;
};


/** aggregate fields of "roles" */
export type Roles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Roles_Avg_Fields = {
  __typename?: 'roles_avg_fields';
  id?: Maybe<Scalars['Float']>;
  questionnaire_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "roles". All fields are combined with a logical 'AND'. */
export type Roles_Bool_Exp = {
  _and?: InputMaybe<Array<Roles_Bool_Exp>>;
  _not?: InputMaybe<Roles_Bool_Exp>;
  _or?: InputMaybe<Array<Roles_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<Role_Types_Enum_Comparison_Exp>;
  questionnaire_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "roles" */
export enum Roles_Constraint {
  /** unique or primary key constraint on columns "name" */
  RolesNameKey = 'roles_name_key',
  /** unique or primary key constraint on columns "id" */
  RolesPkey = 'roles_pkey'
}

/** input type for incrementing numeric columns in table "roles" */
export type Roles_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  questionnaire_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "roles" */
export type Roles_Insert_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Role_Types_Enum>;
  questionnaire_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Roles_Max_Fields = {
  __typename?: 'roles_max_fields';
  id?: Maybe<Scalars['Int']>;
  questionnaire_id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Roles_Min_Fields = {
  __typename?: 'roles_min_fields';
  id?: Maybe<Scalars['Int']>;
  questionnaire_id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "roles" */
export type Roles_Mutation_Response = {
  __typename?: 'roles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Roles>;
};

/** on_conflict condition type for table "roles" */
export type Roles_On_Conflict = {
  constraint: Roles_Constraint;
  update_columns?: Array<Roles_Update_Column>;
  where?: InputMaybe<Roles_Bool_Exp>;
};

/** Ordering options when selecting data from "roles". */
export type Roles_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: roles */
export type Roles_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "roles" */
export enum Roles_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  QuestionnaireId = 'questionnaire_id'
}

/** input type for updating data in table "roles" */
export type Roles_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Role_Types_Enum>;
  questionnaire_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Roles_Stddev_Fields = {
  __typename?: 'roles_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  questionnaire_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Roles_Stddev_Pop_Fields = {
  __typename?: 'roles_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  questionnaire_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Roles_Stddev_Samp_Fields = {
  __typename?: 'roles_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  questionnaire_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "roles" */
export type Roles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Roles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Roles_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Role_Types_Enum>;
  questionnaire_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Roles_Sum_Fields = {
  __typename?: 'roles_sum_fields';
  id?: Maybe<Scalars['Int']>;
  questionnaire_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "roles" */
export enum Roles_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  QuestionnaireId = 'questionnaire_id'
}

export type Roles_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Roles_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Roles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Roles_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Roles_Var_Pop_Fields = {
  __typename?: 'roles_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  questionnaire_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Roles_Var_Samp_Fields = {
  __typename?: 'roles_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  questionnaire_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Roles_Variance_Fields = {
  __typename?: 'roles_variance_fields';
  id?: Maybe<Scalars['Float']>;
  questionnaire_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "skintelligent_tests" */
export type Skintelligent_Tests = {
  __typename?: 'skintelligent_tests';
  comedone?: Maybe<Scalars['Int']>;
  errors?: Maybe<Scalars['jsonb']>;
  image_source?: Maybe<Image_Source_Types_Enum>;
  inflammatory?: Maybe<Scalars['Int']>;
  original_image_id?: Maybe<Scalars['uuid']>;
  overall_score?: Maybe<Scalars['numeric']>;
  pih?: Maybe<Scalars['Int']>;
  raw_data?: Maybe<Scalars['jsonb']>;
  report_id?: Maybe<Scalars['uuid']>;
  result_image_id?: Maybe<Scalars['uuid']>;
  result_processed?: Maybe<Scalars['Boolean']>;
  session_id: Scalars['String'];
  /** An object relationship */
  skintelligent_tests_image_source_type?: Maybe<Image_Source_Types>;
  /** An object relationship */
  skintelligent_tests_original_image?: Maybe<Files>;
  /** An object relationship */
  skintelligent_tests_report?: Maybe<Files>;
  /** An object relationship */
  skintelligent_tests_result_image?: Maybe<Files>;
  /** An object relationship */
  skintelligent_tests_test: Tests;
  test_id: Scalars['uuid'];
  total_lesions?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "skintelligent_tests" */
export type Skintelligent_TestsErrorsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "skintelligent_tests" */
export type Skintelligent_TestsRaw_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "skintelligent_tests" */
export type Skintelligent_Tests_Aggregate = {
  __typename?: 'skintelligent_tests_aggregate';
  aggregate?: Maybe<Skintelligent_Tests_Aggregate_Fields>;
  nodes: Array<Skintelligent_Tests>;
};

/** aggregate fields of "skintelligent_tests" */
export type Skintelligent_Tests_Aggregate_Fields = {
  __typename?: 'skintelligent_tests_aggregate_fields';
  avg?: Maybe<Skintelligent_Tests_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Skintelligent_Tests_Max_Fields>;
  min?: Maybe<Skintelligent_Tests_Min_Fields>;
  stddev?: Maybe<Skintelligent_Tests_Stddev_Fields>;
  stddev_pop?: Maybe<Skintelligent_Tests_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Skintelligent_Tests_Stddev_Samp_Fields>;
  sum?: Maybe<Skintelligent_Tests_Sum_Fields>;
  var_pop?: Maybe<Skintelligent_Tests_Var_Pop_Fields>;
  var_samp?: Maybe<Skintelligent_Tests_Var_Samp_Fields>;
  variance?: Maybe<Skintelligent_Tests_Variance_Fields>;
};


/** aggregate fields of "skintelligent_tests" */
export type Skintelligent_Tests_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Skintelligent_Tests_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Skintelligent_Tests_Append_Input = {
  errors?: InputMaybe<Scalars['jsonb']>;
  raw_data?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Skintelligent_Tests_Avg_Fields = {
  __typename?: 'skintelligent_tests_avg_fields';
  comedone?: Maybe<Scalars['Float']>;
  inflammatory?: Maybe<Scalars['Float']>;
  overall_score?: Maybe<Scalars['Float']>;
  pih?: Maybe<Scalars['Float']>;
  total_lesions?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "skintelligent_tests". All fields are combined with a logical 'AND'. */
export type Skintelligent_Tests_Bool_Exp = {
  _and?: InputMaybe<Array<Skintelligent_Tests_Bool_Exp>>;
  _not?: InputMaybe<Skintelligent_Tests_Bool_Exp>;
  _or?: InputMaybe<Array<Skintelligent_Tests_Bool_Exp>>;
  comedone?: InputMaybe<Int_Comparison_Exp>;
  errors?: InputMaybe<Jsonb_Comparison_Exp>;
  image_source?: InputMaybe<Image_Source_Types_Enum_Comparison_Exp>;
  inflammatory?: InputMaybe<Int_Comparison_Exp>;
  original_image_id?: InputMaybe<Uuid_Comparison_Exp>;
  overall_score?: InputMaybe<Numeric_Comparison_Exp>;
  pih?: InputMaybe<Int_Comparison_Exp>;
  raw_data?: InputMaybe<Jsonb_Comparison_Exp>;
  report_id?: InputMaybe<Uuid_Comparison_Exp>;
  result_image_id?: InputMaybe<Uuid_Comparison_Exp>;
  result_processed?: InputMaybe<Boolean_Comparison_Exp>;
  session_id?: InputMaybe<String_Comparison_Exp>;
  skintelligent_tests_image_source_type?: InputMaybe<Image_Source_Types_Bool_Exp>;
  skintelligent_tests_original_image?: InputMaybe<Files_Bool_Exp>;
  skintelligent_tests_report?: InputMaybe<Files_Bool_Exp>;
  skintelligent_tests_result_image?: InputMaybe<Files_Bool_Exp>;
  skintelligent_tests_test?: InputMaybe<Tests_Bool_Exp>;
  test_id?: InputMaybe<Uuid_Comparison_Exp>;
  total_lesions?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "skintelligent_tests" */
export enum Skintelligent_Tests_Constraint {
  /** unique or primary key constraint on columns "test_id" */
  SkintelligentTestsPkey = 'skintelligent_tests_pkey',
  /** unique or primary key constraint on columns "session_id" */
  SkintelligentTestsSessionIdKey = 'skintelligent_tests_session_id_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Skintelligent_Tests_Delete_At_Path_Input = {
  errors?: InputMaybe<Array<Scalars['String']>>;
  raw_data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Skintelligent_Tests_Delete_Elem_Input = {
  errors?: InputMaybe<Scalars['Int']>;
  raw_data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Skintelligent_Tests_Delete_Key_Input = {
  errors?: InputMaybe<Scalars['String']>;
  raw_data?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "skintelligent_tests" */
export type Skintelligent_Tests_Inc_Input = {
  comedone?: InputMaybe<Scalars['Int']>;
  inflammatory?: InputMaybe<Scalars['Int']>;
  overall_score?: InputMaybe<Scalars['numeric']>;
  pih?: InputMaybe<Scalars['Int']>;
  total_lesions?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "skintelligent_tests" */
export type Skintelligent_Tests_Insert_Input = {
  comedone?: InputMaybe<Scalars['Int']>;
  errors?: InputMaybe<Scalars['jsonb']>;
  image_source?: InputMaybe<Image_Source_Types_Enum>;
  inflammatory?: InputMaybe<Scalars['Int']>;
  original_image_id?: InputMaybe<Scalars['uuid']>;
  overall_score?: InputMaybe<Scalars['numeric']>;
  pih?: InputMaybe<Scalars['Int']>;
  raw_data?: InputMaybe<Scalars['jsonb']>;
  report_id?: InputMaybe<Scalars['uuid']>;
  result_image_id?: InputMaybe<Scalars['uuid']>;
  result_processed?: InputMaybe<Scalars['Boolean']>;
  session_id?: InputMaybe<Scalars['String']>;
  skintelligent_tests_image_source_type?: InputMaybe<Image_Source_Types_Obj_Rel_Insert_Input>;
  skintelligent_tests_original_image?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  skintelligent_tests_report?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  skintelligent_tests_result_image?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  skintelligent_tests_test?: InputMaybe<Tests_Obj_Rel_Insert_Input>;
  test_id?: InputMaybe<Scalars['uuid']>;
  total_lesions?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Skintelligent_Tests_Max_Fields = {
  __typename?: 'skintelligent_tests_max_fields';
  comedone?: Maybe<Scalars['Int']>;
  inflammatory?: Maybe<Scalars['Int']>;
  original_image_id?: Maybe<Scalars['uuid']>;
  overall_score?: Maybe<Scalars['numeric']>;
  pih?: Maybe<Scalars['Int']>;
  report_id?: Maybe<Scalars['uuid']>;
  result_image_id?: Maybe<Scalars['uuid']>;
  session_id?: Maybe<Scalars['String']>;
  test_id?: Maybe<Scalars['uuid']>;
  total_lesions?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Skintelligent_Tests_Min_Fields = {
  __typename?: 'skintelligent_tests_min_fields';
  comedone?: Maybe<Scalars['Int']>;
  inflammatory?: Maybe<Scalars['Int']>;
  original_image_id?: Maybe<Scalars['uuid']>;
  overall_score?: Maybe<Scalars['numeric']>;
  pih?: Maybe<Scalars['Int']>;
  report_id?: Maybe<Scalars['uuid']>;
  result_image_id?: Maybe<Scalars['uuid']>;
  session_id?: Maybe<Scalars['String']>;
  test_id?: Maybe<Scalars['uuid']>;
  total_lesions?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "skintelligent_tests" */
export type Skintelligent_Tests_Mutation_Response = {
  __typename?: 'skintelligent_tests_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Skintelligent_Tests>;
};

/** on_conflict condition type for table "skintelligent_tests" */
export type Skintelligent_Tests_On_Conflict = {
  constraint: Skintelligent_Tests_Constraint;
  update_columns?: Array<Skintelligent_Tests_Update_Column>;
  where?: InputMaybe<Skintelligent_Tests_Bool_Exp>;
};

/** Ordering options when selecting data from "skintelligent_tests". */
export type Skintelligent_Tests_Order_By = {
  comedone?: InputMaybe<Order_By>;
  errors?: InputMaybe<Order_By>;
  image_source?: InputMaybe<Order_By>;
  inflammatory?: InputMaybe<Order_By>;
  original_image_id?: InputMaybe<Order_By>;
  overall_score?: InputMaybe<Order_By>;
  pih?: InputMaybe<Order_By>;
  raw_data?: InputMaybe<Order_By>;
  report_id?: InputMaybe<Order_By>;
  result_image_id?: InputMaybe<Order_By>;
  result_processed?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
  skintelligent_tests_image_source_type?: InputMaybe<Image_Source_Types_Order_By>;
  skintelligent_tests_original_image?: InputMaybe<Files_Order_By>;
  skintelligent_tests_report?: InputMaybe<Files_Order_By>;
  skintelligent_tests_result_image?: InputMaybe<Files_Order_By>;
  skintelligent_tests_test?: InputMaybe<Tests_Order_By>;
  test_id?: InputMaybe<Order_By>;
  total_lesions?: InputMaybe<Order_By>;
};

/** primary key columns input for table: skintelligent_tests */
export type Skintelligent_Tests_Pk_Columns_Input = {
  test_id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Skintelligent_Tests_Prepend_Input = {
  errors?: InputMaybe<Scalars['jsonb']>;
  raw_data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "skintelligent_tests" */
export enum Skintelligent_Tests_Select_Column {
  /** column name */
  Comedone = 'comedone',
  /** column name */
  Errors = 'errors',
  /** column name */
  ImageSource = 'image_source',
  /** column name */
  Inflammatory = 'inflammatory',
  /** column name */
  OriginalImageId = 'original_image_id',
  /** column name */
  OverallScore = 'overall_score',
  /** column name */
  Pih = 'pih',
  /** column name */
  RawData = 'raw_data',
  /** column name */
  ReportId = 'report_id',
  /** column name */
  ResultImageId = 'result_image_id',
  /** column name */
  ResultProcessed = 'result_processed',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  TestId = 'test_id',
  /** column name */
  TotalLesions = 'total_lesions'
}

/** input type for updating data in table "skintelligent_tests" */
export type Skintelligent_Tests_Set_Input = {
  comedone?: InputMaybe<Scalars['Int']>;
  errors?: InputMaybe<Scalars['jsonb']>;
  image_source?: InputMaybe<Image_Source_Types_Enum>;
  inflammatory?: InputMaybe<Scalars['Int']>;
  original_image_id?: InputMaybe<Scalars['uuid']>;
  overall_score?: InputMaybe<Scalars['numeric']>;
  pih?: InputMaybe<Scalars['Int']>;
  raw_data?: InputMaybe<Scalars['jsonb']>;
  report_id?: InputMaybe<Scalars['uuid']>;
  result_image_id?: InputMaybe<Scalars['uuid']>;
  result_processed?: InputMaybe<Scalars['Boolean']>;
  session_id?: InputMaybe<Scalars['String']>;
  test_id?: InputMaybe<Scalars['uuid']>;
  total_lesions?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Skintelligent_Tests_Stddev_Fields = {
  __typename?: 'skintelligent_tests_stddev_fields';
  comedone?: Maybe<Scalars['Float']>;
  inflammatory?: Maybe<Scalars['Float']>;
  overall_score?: Maybe<Scalars['Float']>;
  pih?: Maybe<Scalars['Float']>;
  total_lesions?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Skintelligent_Tests_Stddev_Pop_Fields = {
  __typename?: 'skintelligent_tests_stddev_pop_fields';
  comedone?: Maybe<Scalars['Float']>;
  inflammatory?: Maybe<Scalars['Float']>;
  overall_score?: Maybe<Scalars['Float']>;
  pih?: Maybe<Scalars['Float']>;
  total_lesions?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Skintelligent_Tests_Stddev_Samp_Fields = {
  __typename?: 'skintelligent_tests_stddev_samp_fields';
  comedone?: Maybe<Scalars['Float']>;
  inflammatory?: Maybe<Scalars['Float']>;
  overall_score?: Maybe<Scalars['Float']>;
  pih?: Maybe<Scalars['Float']>;
  total_lesions?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "skintelligent_tests" */
export type Skintelligent_Tests_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Skintelligent_Tests_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Skintelligent_Tests_Stream_Cursor_Value_Input = {
  comedone?: InputMaybe<Scalars['Int']>;
  errors?: InputMaybe<Scalars['jsonb']>;
  image_source?: InputMaybe<Image_Source_Types_Enum>;
  inflammatory?: InputMaybe<Scalars['Int']>;
  original_image_id?: InputMaybe<Scalars['uuid']>;
  overall_score?: InputMaybe<Scalars['numeric']>;
  pih?: InputMaybe<Scalars['Int']>;
  raw_data?: InputMaybe<Scalars['jsonb']>;
  report_id?: InputMaybe<Scalars['uuid']>;
  result_image_id?: InputMaybe<Scalars['uuid']>;
  result_processed?: InputMaybe<Scalars['Boolean']>;
  session_id?: InputMaybe<Scalars['String']>;
  test_id?: InputMaybe<Scalars['uuid']>;
  total_lesions?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Skintelligent_Tests_Sum_Fields = {
  __typename?: 'skintelligent_tests_sum_fields';
  comedone?: Maybe<Scalars['Int']>;
  inflammatory?: Maybe<Scalars['Int']>;
  overall_score?: Maybe<Scalars['numeric']>;
  pih?: Maybe<Scalars['Int']>;
  total_lesions?: Maybe<Scalars['Int']>;
};

/** update columns of table "skintelligent_tests" */
export enum Skintelligent_Tests_Update_Column {
  /** column name */
  Comedone = 'comedone',
  /** column name */
  Errors = 'errors',
  /** column name */
  ImageSource = 'image_source',
  /** column name */
  Inflammatory = 'inflammatory',
  /** column name */
  OriginalImageId = 'original_image_id',
  /** column name */
  OverallScore = 'overall_score',
  /** column name */
  Pih = 'pih',
  /** column name */
  RawData = 'raw_data',
  /** column name */
  ReportId = 'report_id',
  /** column name */
  ResultImageId = 'result_image_id',
  /** column name */
  ResultProcessed = 'result_processed',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  TestId = 'test_id',
  /** column name */
  TotalLesions = 'total_lesions'
}

export type Skintelligent_Tests_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Skintelligent_Tests_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Skintelligent_Tests_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Skintelligent_Tests_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Skintelligent_Tests_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Skintelligent_Tests_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Skintelligent_Tests_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Skintelligent_Tests_Set_Input>;
  /** filter the rows which have to be updated */
  where: Skintelligent_Tests_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Skintelligent_Tests_Var_Pop_Fields = {
  __typename?: 'skintelligent_tests_var_pop_fields';
  comedone?: Maybe<Scalars['Float']>;
  inflammatory?: Maybe<Scalars['Float']>;
  overall_score?: Maybe<Scalars['Float']>;
  pih?: Maybe<Scalars['Float']>;
  total_lesions?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Skintelligent_Tests_Var_Samp_Fields = {
  __typename?: 'skintelligent_tests_var_samp_fields';
  comedone?: Maybe<Scalars['Float']>;
  inflammatory?: Maybe<Scalars['Float']>;
  overall_score?: Maybe<Scalars['Float']>;
  pih?: Maybe<Scalars['Float']>;
  total_lesions?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Skintelligent_Tests_Variance_Fields = {
  __typename?: 'skintelligent_tests_variance_fields';
  comedone?: Maybe<Scalars['Float']>;
  inflammatory?: Maybe<Scalars['Float']>;
  overall_score?: Maybe<Scalars['Float']>;
  pih?: Maybe<Scalars['Float']>;
  total_lesions?: Maybe<Scalars['Float']>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "accepted_legal_documents" */
  accepted_legal_documents: Array<Accepted_Legal_Documents>;
  /** fetch aggregated fields from the table: "accepted_legal_documents" */
  accepted_legal_documents_aggregate: Accepted_Legal_Documents_Aggregate;
  /** fetch data from the table: "accepted_legal_documents" using primary key columns */
  accepted_legal_documents_by_pk?: Maybe<Accepted_Legal_Documents>;
  /** fetch data from the table in a streaming manner: "accepted_legal_documents" */
  accepted_legal_documents_stream: Array<Accepted_Legal_Documents>;
  /** fetch data from the table: "acuity_provider_calendar" */
  acuity_provider_calendar: Array<Acuity_Provider_Calendar>;
  /** fetch aggregated fields from the table: "acuity_provider_calendar" */
  acuity_provider_calendar_aggregate: Acuity_Provider_Calendar_Aggregate;
  /** fetch data from the table: "acuity_provider_calendar" using primary key columns */
  acuity_provider_calendar_by_pk?: Maybe<Acuity_Provider_Calendar>;
  /** fetch data from the table in a streaming manner: "acuity_provider_calendar" */
  acuity_provider_calendar_stream: Array<Acuity_Provider_Calendar>;
  /** fetch data from the table: "addresses" */
  addresses: Array<Addresses>;
  /** fetch aggregated fields from the table: "addresses" */
  addresses_aggregate: Addresses_Aggregate;
  /** fetch data from the table: "addresses" using primary key columns */
  addresses_by_pk?: Maybe<Addresses>;
  /** fetch data from the table in a streaming manner: "addresses" */
  addresses_stream: Array<Addresses>;
  /** fetch data from the table: "channel_signals" */
  channel_signals: Array<Channel_Signals>;
  /** fetch aggregated fields from the table: "channel_signals" */
  channel_signals_aggregate: Channel_Signals_Aggregate;
  /** fetch data from the table: "channel_signals" using primary key columns */
  channel_signals_by_pk?: Maybe<Channel_Signals>;
  /** fetch data from the table in a streaming manner: "channel_signals" */
  channel_signals_stream: Array<Channel_Signals>;
  /** fetch data from the table: "common_question_responses" */
  common_question_responses: Array<Common_Question_Responses>;
  /** fetch aggregated fields from the table: "common_question_responses" */
  common_question_responses_aggregate: Common_Question_Responses_Aggregate;
  /** fetch data from the table: "common_question_responses" using primary key columns */
  common_question_responses_by_pk?: Maybe<Common_Question_Responses>;
  /** fetch data from the table in a streaming manner: "common_question_responses" */
  common_question_responses_stream: Array<Common_Question_Responses>;
  /** fetch data from the table: "common_questions" */
  common_questions: Array<Common_Questions>;
  /** fetch aggregated fields from the table: "common_questions" */
  common_questions_aggregate: Common_Questions_Aggregate;
  /** fetch data from the table: "common_questions" using primary key columns */
  common_questions_by_pk?: Maybe<Common_Questions>;
  /** fetch data from the table in a streaming manner: "common_questions" */
  common_questions_stream: Array<Common_Questions>;
  /** fetch data from the table: "communication_channels" */
  communication_channels: Array<Communication_Channels>;
  /** fetch aggregated fields from the table: "communication_channels" */
  communication_channels_aggregate: Communication_Channels_Aggregate;
  /** fetch data from the table: "communication_channels" using primary key columns */
  communication_channels_by_pk?: Maybe<Communication_Channels>;
  /** fetch data from the table in a streaming manner: "communication_channels" */
  communication_channels_stream: Array<Communication_Channels>;
  /** fetch data from the table: "countries" */
  countries: Array<Countries>;
  /** fetch aggregated fields from the table: "countries" */
  countries_aggregate: Countries_Aggregate;
  /** fetch data from the table: "countries" using primary key columns */
  countries_by_pk?: Maybe<Countries>;
  /** fetch data from the table in a streaming manner: "countries" */
  countries_stream: Array<Countries>;
  /** fetch data from the table: "custom_auth_code" */
  custom_auth_code: Array<Custom_Auth_Code>;
  /** fetch aggregated fields from the table: "custom_auth_code" */
  custom_auth_code_aggregate: Custom_Auth_Code_Aggregate;
  /** fetch data from the table: "custom_auth_code" using primary key columns */
  custom_auth_code_by_pk?: Maybe<Custom_Auth_Code>;
  /** fetch data from the table in a streaming manner: "custom_auth_code" */
  custom_auth_code_stream: Array<Custom_Auth_Code>;
  /** fetch data from the table: "dnavisit_test_statuses" */
  dnavisit_test_statuses: Array<Dnavisit_Test_Statuses>;
  /** fetch aggregated fields from the table: "dnavisit_test_statuses" */
  dnavisit_test_statuses_aggregate: Dnavisit_Test_Statuses_Aggregate;
  /** fetch data from the table: "dnavisit_test_statuses" using primary key columns */
  dnavisit_test_statuses_by_pk?: Maybe<Dnavisit_Test_Statuses>;
  /** fetch data from the table in a streaming manner: "dnavisit_test_statuses" */
  dnavisit_test_statuses_stream: Array<Dnavisit_Test_Statuses>;
  /** fetch data from the table: "dnavisit_tests" */
  dnavisit_tests: Array<Dnavisit_Tests>;
  /** fetch aggregated fields from the table: "dnavisit_tests" */
  dnavisit_tests_aggregate: Dnavisit_Tests_Aggregate;
  /** fetch data from the table: "dnavisit_tests" using primary key columns */
  dnavisit_tests_by_pk?: Maybe<Dnavisit_Tests>;
  /** fetch data from the table in a streaming manner: "dnavisit_tests" */
  dnavisit_tests_stream: Array<Dnavisit_Tests>;
  /** fetch data from the table: "do_not_use_production_swab_codes" */
  do_not_use_production_swab_codes: Array<Do_Not_Use_Production_Swab_Codes>;
  /** fetch aggregated fields from the table: "do_not_use_production_swab_codes" */
  do_not_use_production_swab_codes_aggregate: Do_Not_Use_Production_Swab_Codes_Aggregate;
  /** fetch data from the table: "do_not_use_production_swab_codes" using primary key columns */
  do_not_use_production_swab_codes_by_pk?: Maybe<Do_Not_Use_Production_Swab_Codes>;
  /** fetch data from the table in a streaming manner: "do_not_use_production_swab_codes" */
  do_not_use_production_swab_codes_stream: Array<Do_Not_Use_Production_Swab_Codes>;
  /** fetch data from the table: "dosespot_clinicians" */
  dosespot_clinicians: Array<Dosespot_Clinicians>;
  /** fetch aggregated fields from the table: "dosespot_clinicians" */
  dosespot_clinicians_aggregate: Dosespot_Clinicians_Aggregate;
  /** fetch data from the table: "dosespot_clinicians" using primary key columns */
  dosespot_clinicians_by_pk?: Maybe<Dosespot_Clinicians>;
  /** fetch data from the table in a streaming manner: "dosespot_clinicians" */
  dosespot_clinicians_stream: Array<Dosespot_Clinicians>;
  /** fetch data from the table: "file_types" */
  file_types: Array<File_Types>;
  /** fetch aggregated fields from the table: "file_types" */
  file_types_aggregate: File_Types_Aggregate;
  /** fetch data from the table: "file_types" using primary key columns */
  file_types_by_pk?: Maybe<File_Types>;
  /** fetch data from the table in a streaming manner: "file_types" */
  file_types_stream: Array<File_Types>;
  /** fetch data from the table: "files" */
  files: Array<Files>;
  /** fetch aggregated fields from the table: "files" */
  files_aggregate: Files_Aggregate;
  /** fetch data from the table: "files" using primary key columns */
  files_by_pk?: Maybe<Files>;
  /** fetch data from the table in a streaming manner: "files" */
  files_stream: Array<Files>;
  /** fetch data from the table: "fullscript_clinicians" */
  fullscript_clinicians: Array<Fullscript_Clinicians>;
  /** fetch aggregated fields from the table: "fullscript_clinicians" */
  fullscript_clinicians_aggregate: Fullscript_Clinicians_Aggregate;
  /** fetch data from the table: "fullscript_clinicians" using primary key columns */
  fullscript_clinicians_by_pk?: Maybe<Fullscript_Clinicians>;
  /** fetch data from the table in a streaming manner: "fullscript_clinicians" */
  fullscript_clinicians_stream: Array<Fullscript_Clinicians>;
  /** fetch data from the table: "global_configs" */
  global_configs: Array<Global_Configs>;
  /** fetch aggregated fields from the table: "global_configs" */
  global_configs_aggregate: Global_Configs_Aggregate;
  /** fetch data from the table: "global_configs" using primary key columns */
  global_configs_by_pk?: Maybe<Global_Configs>;
  /** fetch data from the table: "global_configs_names" */
  global_configs_names: Array<Global_Configs_Names>;
  /** fetch aggregated fields from the table: "global_configs_names" */
  global_configs_names_aggregate: Global_Configs_Names_Aggregate;
  /** fetch data from the table: "global_configs_names" using primary key columns */
  global_configs_names_by_pk?: Maybe<Global_Configs_Names>;
  /** fetch data from the table in a streaming manner: "global_configs_names" */
  global_configs_names_stream: Array<Global_Configs_Names>;
  /** fetch data from the table in a streaming manner: "global_configs" */
  global_configs_stream: Array<Global_Configs>;
  /** fetch data from the table: "image_source_types" */
  image_source_types: Array<Image_Source_Types>;
  /** fetch aggregated fields from the table: "image_source_types" */
  image_source_types_aggregate: Image_Source_Types_Aggregate;
  /** fetch data from the table: "image_source_types" using primary key columns */
  image_source_types_by_pk?: Maybe<Image_Source_Types>;
  /** fetch data from the table in a streaming manner: "image_source_types" */
  image_source_types_stream: Array<Image_Source_Types>;
  /** fetch data from the table: "labs" */
  labs: Array<Labs>;
  /** fetch aggregated fields from the table: "labs" */
  labs_aggregate: Labs_Aggregate;
  /** fetch data from the table: "labs" using primary key columns */
  labs_by_pk?: Maybe<Labs>;
  /** fetch data from the table in a streaming manner: "labs" */
  labs_stream: Array<Labs>;
  /** fetch data from the table: "legal_document_types" */
  legal_document_types: Array<Legal_Document_Types>;
  /** fetch aggregated fields from the table: "legal_document_types" */
  legal_document_types_aggregate: Legal_Document_Types_Aggregate;
  /** fetch data from the table: "legal_document_types" using primary key columns */
  legal_document_types_by_pk?: Maybe<Legal_Document_Types>;
  /** fetch data from the table in a streaming manner: "legal_document_types" */
  legal_document_types_stream: Array<Legal_Document_Types>;
  /** fetch data from the table: "legal_documents" */
  legal_documents: Array<Legal_Documents>;
  /** fetch aggregated fields from the table: "legal_documents" */
  legal_documents_aggregate: Legal_Documents_Aggregate;
  /** fetch data from the table: "legal_documents" using primary key columns */
  legal_documents_by_pk?: Maybe<Legal_Documents>;
  /** fetch data from the table in a streaming manner: "legal_documents" */
  legal_documents_stream: Array<Legal_Documents>;
  /** fetch data from the table: "medical_schools" */
  medical_schools: Array<Medical_Schools>;
  /** fetch aggregated fields from the table: "medical_schools" */
  medical_schools_aggregate: Medical_Schools_Aggregate;
  /** fetch data from the table: "medical_schools" using primary key columns */
  medical_schools_by_pk?: Maybe<Medical_Schools>;
  /** fetch data from the table in a streaming manner: "medical_schools" */
  medical_schools_stream: Array<Medical_Schools>;
  /** fetch data from the table: "nabla_notes" */
  nabla_notes: Array<Nabla_Notes>;
  /** fetch aggregated fields from the table: "nabla_notes" */
  nabla_notes_aggregate: Nabla_Notes_Aggregate;
  /** fetch data from the table: "nabla_notes" using primary key columns */
  nabla_notes_by_pk?: Maybe<Nabla_Notes>;
  /** fetch data from the table in a streaming manner: "nabla_notes" */
  nabla_notes_stream: Array<Nabla_Notes>;
  /** fetch data from the table: "npi_registry_data" */
  npi_registry_data: Array<Npi_Registry_Data>;
  /** fetch aggregated fields from the table: "npi_registry_data" */
  npi_registry_data_aggregate: Npi_Registry_Data_Aggregate;
  /** fetch data from the table: "npi_registry_data" using primary key columns */
  npi_registry_data_by_pk?: Maybe<Npi_Registry_Data>;
  /** fetch data from the table in a streaming manner: "npi_registry_data" */
  npi_registry_data_stream: Array<Npi_Registry_Data>;
  /** fetch data from the table: "persona_inquiries" */
  persona_inquiries: Array<Persona_Inquiries>;
  /** fetch aggregated fields from the table: "persona_inquiries" */
  persona_inquiries_aggregate: Persona_Inquiries_Aggregate;
  /** fetch data from the table: "persona_inquiries" using primary key columns */
  persona_inquiries_by_pk?: Maybe<Persona_Inquiries>;
  /** fetch data from the table in a streaming manner: "persona_inquiries" */
  persona_inquiries_stream: Array<Persona_Inquiries>;
  /** fetch data from the table: "persona_inquiry_status" */
  persona_inquiry_status: Array<Persona_Inquiry_Status>;
  /** fetch aggregated fields from the table: "persona_inquiry_status" */
  persona_inquiry_status_aggregate: Persona_Inquiry_Status_Aggregate;
  /** fetch data from the table: "persona_inquiry_status" using primary key columns */
  persona_inquiry_status_by_pk?: Maybe<Persona_Inquiry_Status>;
  /** fetch data from the table in a streaming manner: "persona_inquiry_status" */
  persona_inquiry_status_stream: Array<Persona_Inquiry_Status>;
  /** fetch data from the table: "provider_unread_external_notification_counts" */
  provider_unread_external_notification_counts: Array<Provider_Unread_External_Notification_Counts>;
  /** fetch aggregated fields from the table: "provider_unread_external_notification_counts" */
  provider_unread_external_notification_counts_aggregate: Provider_Unread_External_Notification_Counts_Aggregate;
  /** fetch data from the table: "provider_unread_external_notification_counts" using primary key columns */
  provider_unread_external_notification_counts_by_pk?: Maybe<Provider_Unread_External_Notification_Counts>;
  /** fetch data from the table in a streaming manner: "provider_unread_external_notification_counts" */
  provider_unread_external_notification_counts_stream: Array<Provider_Unread_External_Notification_Counts>;
  /** fetch data from the table: "question_measurement_units" */
  question_measurement_units: Array<Question_Measurement_Units>;
  /** fetch aggregated fields from the table: "question_measurement_units" */
  question_measurement_units_aggregate: Question_Measurement_Units_Aggregate;
  /** fetch data from the table: "question_measurement_units" using primary key columns */
  question_measurement_units_by_pk?: Maybe<Question_Measurement_Units>;
  /** fetch data from the table in a streaming manner: "question_measurement_units" */
  question_measurement_units_stream: Array<Question_Measurement_Units>;
  /** fetch data from the table: "question_types" */
  question_types: Array<Question_Types>;
  /** fetch aggregated fields from the table: "question_types" */
  question_types_aggregate: Question_Types_Aggregate;
  /** fetch data from the table: "question_types" using primary key columns */
  question_types_by_pk?: Maybe<Question_Types>;
  /** fetch data from the table in a streaming manner: "question_types" */
  question_types_stream: Array<Question_Types>;
  /** fetch data from the table: "question_ui_control_helper_text_position" */
  question_ui_control_helper_text_position: Array<Question_Ui_Control_Helper_Text_Position>;
  /** fetch aggregated fields from the table: "question_ui_control_helper_text_position" */
  question_ui_control_helper_text_position_aggregate: Question_Ui_Control_Helper_Text_Position_Aggregate;
  /** fetch data from the table: "question_ui_control_helper_text_position" using primary key columns */
  question_ui_control_helper_text_position_by_pk?: Maybe<Question_Ui_Control_Helper_Text_Position>;
  /** fetch data from the table in a streaming manner: "question_ui_control_helper_text_position" */
  question_ui_control_helper_text_position_stream: Array<Question_Ui_Control_Helper_Text_Position>;
  /** fetch data from the table: "question_ui_control_types" */
  question_ui_control_types: Array<Question_Ui_Control_Types>;
  /** fetch aggregated fields from the table: "question_ui_control_types" */
  question_ui_control_types_aggregate: Question_Ui_Control_Types_Aggregate;
  /** fetch data from the table: "question_ui_control_types" using primary key columns */
  question_ui_control_types_by_pk?: Maybe<Question_Ui_Control_Types>;
  /** fetch data from the table in a streaming manner: "question_ui_control_types" */
  question_ui_control_types_stream: Array<Question_Ui_Control_Types>;
  /** fetch data from the table: "questionnaire_questions" */
  questionnaire_questions: Array<Questionnaire_Questions>;
  /** fetch aggregated fields from the table: "questionnaire_questions" */
  questionnaire_questions_aggregate: Questionnaire_Questions_Aggregate;
  /** fetch data from the table: "questionnaire_questions" using primary key columns */
  questionnaire_questions_by_pk?: Maybe<Questionnaire_Questions>;
  /** fetch data from the table in a streaming manner: "questionnaire_questions" */
  questionnaire_questions_stream: Array<Questionnaire_Questions>;
  /** fetch data from the table: "questionnaire_responses" */
  questionnaire_responses: Array<Questionnaire_Responses>;
  /** fetch aggregated fields from the table: "questionnaire_responses" */
  questionnaire_responses_aggregate: Questionnaire_Responses_Aggregate;
  /** fetch data from the table: "questionnaire_responses" using primary key columns */
  questionnaire_responses_by_pk?: Maybe<Questionnaire_Responses>;
  /** fetch data from the table in a streaming manner: "questionnaire_responses" */
  questionnaire_responses_stream: Array<Questionnaire_Responses>;
  /** fetch data from the table: "questionnaires" */
  questionnaires: Array<Questionnaires>;
  /** fetch aggregated fields from the table: "questionnaires" */
  questionnaires_aggregate: Questionnaires_Aggregate;
  /** fetch data from the table: "questionnaires" using primary key columns */
  questionnaires_by_pk?: Maybe<Questionnaires>;
  /** fetch data from the table in a streaming manner: "questionnaires" */
  questionnaires_stream: Array<Questionnaires>;
  /** fetch data from the table: "questions" */
  questions: Array<Questions>;
  /** fetch aggregated fields from the table: "questions" */
  questions_aggregate: Questions_Aggregate;
  /** fetch data from the table: "questions" using primary key columns */
  questions_by_pk?: Maybe<Questions>;
  /** fetch data from the table in a streaming manner: "questions" */
  questions_stream: Array<Questions>;
  /** fetch data from the table: "role_types" */
  role_types: Array<Role_Types>;
  /** fetch aggregated fields from the table: "role_types" */
  role_types_aggregate: Role_Types_Aggregate;
  /** fetch data from the table: "role_types" using primary key columns */
  role_types_by_pk?: Maybe<Role_Types>;
  /** fetch data from the table in a streaming manner: "role_types" */
  role_types_stream: Array<Role_Types>;
  /** fetch data from the table: "roles" */
  roles: Array<Roles>;
  /** fetch aggregated fields from the table: "roles" */
  roles_aggregate: Roles_Aggregate;
  /** fetch data from the table: "roles" using primary key columns */
  roles_by_pk?: Maybe<Roles>;
  /** fetch data from the table in a streaming manner: "roles" */
  roles_stream: Array<Roles>;
  /** fetch data from the table: "skintelligent_tests" */
  skintelligent_tests: Array<Skintelligent_Tests>;
  /** fetch aggregated fields from the table: "skintelligent_tests" */
  skintelligent_tests_aggregate: Skintelligent_Tests_Aggregate;
  /** fetch data from the table: "skintelligent_tests" using primary key columns */
  skintelligent_tests_by_pk?: Maybe<Skintelligent_Tests>;
  /** fetch data from the table in a streaming manner: "skintelligent_tests" */
  skintelligent_tests_stream: Array<Skintelligent_Tests>;
  /** fetch data from the table: "swab_code_statuses" */
  swab_code_statuses: Array<Swab_Code_Statuses>;
  /** fetch aggregated fields from the table: "swab_code_statuses" */
  swab_code_statuses_aggregate: Swab_Code_Statuses_Aggregate;
  /** fetch data from the table: "swab_code_statuses" using primary key columns */
  swab_code_statuses_by_pk?: Maybe<Swab_Code_Statuses>;
  /** fetch data from the table in a streaming manner: "swab_code_statuses" */
  swab_code_statuses_stream: Array<Swab_Code_Statuses>;
  /** fetch data from the table: "swab_codes" */
  swab_codes: Array<Swab_Codes>;
  /** fetch aggregated fields from the table: "swab_codes" */
  swab_codes_aggregate: Swab_Codes_Aggregate;
  /** fetch data from the table: "swab_codes" using primary key columns */
  swab_codes_by_pk?: Maybe<Swab_Codes>;
  /** fetch data from the table in a streaming manner: "swab_codes" */
  swab_codes_stream: Array<Swab_Codes>;
  /** fetch data from the table: "swab_usage_type" */
  swab_usage_type: Array<Swab_Usage_Type>;
  /** fetch aggregated fields from the table: "swab_usage_type" */
  swab_usage_type_aggregate: Swab_Usage_Type_Aggregate;
  /** fetch data from the table: "swab_usage_type" using primary key columns */
  swab_usage_type_by_pk?: Maybe<Swab_Usage_Type>;
  /** fetch data from the table in a streaming manner: "swab_usage_type" */
  swab_usage_type_stream: Array<Swab_Usage_Type>;
  /** fetch data from the table: "test_definitions" */
  test_definitions: Array<Test_Definitions>;
  /** fetch aggregated fields from the table: "test_definitions" */
  test_definitions_aggregate: Test_Definitions_Aggregate;
  /** fetch data from the table: "test_definitions" using primary key columns */
  test_definitions_by_pk?: Maybe<Test_Definitions>;
  /** fetch data from the table in a streaming manner: "test_definitions" */
  test_definitions_stream: Array<Test_Definitions>;
  /** fetch data from the table: "test_kits_update_states_summary_log" */
  test_kits_update_states_summary_log: Array<Test_Kits_Update_States_Summary_Log>;
  /** fetch aggregated fields from the table: "test_kits_update_states_summary_log" */
  test_kits_update_states_summary_log_aggregate: Test_Kits_Update_States_Summary_Log_Aggregate;
  /** fetch data from the table: "test_kits_update_states_summary_log" using primary key columns */
  test_kits_update_states_summary_log_by_pk?: Maybe<Test_Kits_Update_States_Summary_Log>;
  /** fetch data from the table in a streaming manner: "test_kits_update_states_summary_log" */
  test_kits_update_states_summary_log_stream: Array<Test_Kits_Update_States_Summary_Log>;
  /** fetch data from the table: "test_types" */
  test_types: Array<Test_Types>;
  /** fetch aggregated fields from the table: "test_types" */
  test_types_aggregate: Test_Types_Aggregate;
  /** fetch data from the table: "test_types" using primary key columns */
  test_types_by_pk?: Maybe<Test_Types>;
  /** fetch data from the table in a streaming manner: "test_types" */
  test_types_stream: Array<Test_Types>;
  /** fetch data from the table: "tests" */
  tests: Array<Tests>;
  /** fetch aggregated fields from the table: "tests" */
  tests_aggregate: Tests_Aggregate;
  /** fetch data from the table: "tests" using primary key columns */
  tests_by_pk?: Maybe<Tests>;
  /** fetch data from the table in a streaming manner: "tests" */
  tests_stream: Array<Tests>;
  /** fetch data from the table: "text_addresses" */
  text_addresses: Array<Text_Addresses>;
  /** fetch aggregated fields from the table: "text_addresses" */
  text_addresses_aggregate: Text_Addresses_Aggregate;
  /** fetch data from the table: "text_addresses" using primary key columns */
  text_addresses_by_pk?: Maybe<Text_Addresses>;
  /** fetch data from the table in a streaming manner: "text_addresses" */
  text_addresses_stream: Array<Text_Addresses>;
  /** An array relationship */
  unauthenticated_questionnaire_questions: Array<Unauthenticated_Questionnaire_Questions>;
  /** An aggregate relationship */
  unauthenticated_questionnaire_questions_aggregate: Unauthenticated_Questionnaire_Questions_Aggregate;
  /** fetch data from the table: "unauthenticated_questionnaire_questions" using primary key columns */
  unauthenticated_questionnaire_questions_by_pk?: Maybe<Unauthenticated_Questionnaire_Questions>;
  /** fetch data from the table in a streaming manner: "unauthenticated_questionnaire_questions" */
  unauthenticated_questionnaire_questions_stream: Array<Unauthenticated_Questionnaire_Questions>;
  /** fetch data from the table: "unauthenticated_questionnaires" */
  unauthenticated_questionnaires: Array<Unauthenticated_Questionnaires>;
  /** fetch aggregated fields from the table: "unauthenticated_questionnaires" */
  unauthenticated_questionnaires_aggregate: Unauthenticated_Questionnaires_Aggregate;
  /** fetch data from the table: "unauthenticated_questionnaires" using primary key columns */
  unauthenticated_questionnaires_by_pk?: Maybe<Unauthenticated_Questionnaires>;
  /** fetch data from the table in a streaming manner: "unauthenticated_questionnaires" */
  unauthenticated_questionnaires_stream: Array<Unauthenticated_Questionnaires>;
  /** fetch data from the table: "unauthenticated_questions" */
  unauthenticated_questions: Array<Unauthenticated_Questions>;
  /** fetch aggregated fields from the table: "unauthenticated_questions" */
  unauthenticated_questions_aggregate: Unauthenticated_Questions_Aggregate;
  /** fetch data from the table: "unauthenticated_questions" using primary key columns */
  unauthenticated_questions_by_pk?: Maybe<Unauthenticated_Questions>;
  /** fetch data from the table in a streaming manner: "unauthenticated_questions" */
  unauthenticated_questions_stream: Array<Unauthenticated_Questions>;
  /** fetch data from the table: "universities" */
  universities: Array<Universities>;
  /** fetch aggregated fields from the table: "universities" */
  universities_aggregate: Universities_Aggregate;
  /** fetch data from the table: "universities" using primary key columns */
  universities_by_pk?: Maybe<Universities>;
  /** fetch data from the table in a streaming manner: "universities" */
  universities_stream: Array<Universities>;
  /** fetch data from the table: "user_addresses" */
  user_addresses: Array<User_Addresses>;
  /** fetch aggregated fields from the table: "user_addresses" */
  user_addresses_aggregate: User_Addresses_Aggregate;
  /** fetch data from the table: "user_addresses" using primary key columns */
  user_addresses_by_pk?: Maybe<User_Addresses>;
  /** fetch data from the table in a streaming manner: "user_addresses" */
  user_addresses_stream: Array<User_Addresses>;
  /** fetch data from the table: "user_auth_provider_types" */
  user_auth_provider_types: Array<User_Auth_Provider_Types>;
  /** fetch aggregated fields from the table: "user_auth_provider_types" */
  user_auth_provider_types_aggregate: User_Auth_Provider_Types_Aggregate;
  /** fetch data from the table: "user_auth_provider_types" using primary key columns */
  user_auth_provider_types_by_pk?: Maybe<User_Auth_Provider_Types>;
  /** fetch data from the table in a streaming manner: "user_auth_provider_types" */
  user_auth_provider_types_stream: Array<User_Auth_Provider_Types>;
  /** fetch data from the table: "user_auth_providers" */
  user_auth_providers: Array<User_Auth_Providers>;
  /** fetch aggregated fields from the table: "user_auth_providers" */
  user_auth_providers_aggregate: User_Auth_Providers_Aggregate;
  /** fetch data from the table: "user_auth_providers" using primary key columns */
  user_auth_providers_by_pk?: Maybe<User_Auth_Providers>;
  /** fetch data from the table in a streaming manner: "user_auth_providers" */
  user_auth_providers_stream: Array<User_Auth_Providers>;
  /** fetch data from the table: "user_auth_tokens" */
  user_auth_tokens: Array<User_Auth_Tokens>;
  /** fetch aggregated fields from the table: "user_auth_tokens" */
  user_auth_tokens_aggregate: User_Auth_Tokens_Aggregate;
  /** fetch data from the table: "user_auth_tokens" using primary key columns */
  user_auth_tokens_by_pk?: Maybe<User_Auth_Tokens>;
  /** fetch data from the table in a streaming manner: "user_auth_tokens" */
  user_auth_tokens_stream: Array<User_Auth_Tokens>;
  /** fetch data from the table: "user_communication_deliveries" */
  user_communication_deliveries: Array<User_Communication_Deliveries>;
  /** fetch aggregated fields from the table: "user_communication_deliveries" */
  user_communication_deliveries_aggregate: User_Communication_Deliveries_Aggregate;
  /** fetch data from the table: "user_communication_deliveries" using primary key columns */
  user_communication_deliveries_by_pk?: Maybe<User_Communication_Deliveries>;
  /** fetch data from the table in a streaming manner: "user_communication_deliveries" */
  user_communication_deliveries_stream: Array<User_Communication_Deliveries>;
  /** fetch data from the table: "user_communications" */
  user_communications: Array<User_Communications>;
  /** fetch aggregated fields from the table: "user_communications" */
  user_communications_aggregate: User_Communications_Aggregate;
  /** fetch data from the table: "user_communications" using primary key columns */
  user_communications_by_pk?: Maybe<User_Communications>;
  /** fetch data from the table in a streaming manner: "user_communications" */
  user_communications_stream: Array<User_Communications>;
  /** fetch data from the table: "user_notification_status" */
  user_notification_status: Array<User_Notification_Status>;
  /** fetch aggregated fields from the table: "user_notification_status" */
  user_notification_status_aggregate: User_Notification_Status_Aggregate;
  /** fetch data from the table: "user_notification_status" using primary key columns */
  user_notification_status_by_pk?: Maybe<User_Notification_Status>;
  /** fetch data from the table in a streaming manner: "user_notification_status" */
  user_notification_status_stream: Array<User_Notification_Status>;
  /** fetch data from the table: "user_notifications" */
  user_notifications: Array<User_Notifications>;
  /** fetch aggregated fields from the table: "user_notifications" */
  user_notifications_aggregate: User_Notifications_Aggregate;
  /** fetch data from the table: "user_notifications" using primary key columns */
  user_notifications_by_pk?: Maybe<User_Notifications>;
  /** fetch data from the table in a streaming manner: "user_notifications" */
  user_notifications_stream: Array<User_Notifications>;
  /** fetch data from the table: "user_roles" */
  user_roles: Array<User_Roles>;
  /** fetch aggregated fields from the table: "user_roles" */
  user_roles_aggregate: User_Roles_Aggregate;
  /** fetch data from the table: "user_roles" using primary key columns */
  user_roles_by_pk?: Maybe<User_Roles>;
  /** fetch data from the table in a streaming manner: "user_roles" */
  user_roles_stream: Array<User_Roles>;
  /** fetch data from the table: "user_scanned_data" */
  user_scanned_data: Array<User_Scanned_Data>;
  /** fetch aggregated fields from the table: "user_scanned_data" */
  user_scanned_data_aggregate: User_Scanned_Data_Aggregate;
  /** fetch data from the table: "user_scanned_data" using primary key columns */
  user_scanned_data_by_pk?: Maybe<User_Scanned_Data>;
  /** fetch data from the table in a streaming manner: "user_scanned_data" */
  user_scanned_data_stream: Array<User_Scanned_Data>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table in a streaming manner: "users" */
  users_stream: Array<Users>;
  /** fetch data from the table: "vonage_appointment" */
  vonage_appointment: Array<Vonage_Appointment>;
  /** fetch aggregated fields from the table: "vonage_appointment" */
  vonage_appointment_aggregate: Vonage_Appointment_Aggregate;
  /** fetch data from the table: "vonage_appointment" using primary key columns */
  vonage_appointment_by_pk?: Maybe<Vonage_Appointment>;
  /** fetch data from the table in a streaming manner: "vonage_appointment" */
  vonage_appointment_stream: Array<Vonage_Appointment>;
  /** fetch data from the table: "zrt_tests" */
  zrt_tests: Array<Zrt_Tests>;
  /** fetch aggregated fields from the table: "zrt_tests" */
  zrt_tests_aggregate: Zrt_Tests_Aggregate;
  /** fetch data from the table: "zrt_tests" using primary key columns */
  zrt_tests_by_pk?: Maybe<Zrt_Tests>;
  /** fetch data from the table in a streaming manner: "zrt_tests" */
  zrt_tests_stream: Array<Zrt_Tests>;
};


export type Subscription_RootAccepted_Legal_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Accepted_Legal_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accepted_Legal_Documents_Order_By>>;
  where?: InputMaybe<Accepted_Legal_Documents_Bool_Exp>;
};


export type Subscription_RootAccepted_Legal_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Accepted_Legal_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accepted_Legal_Documents_Order_By>>;
  where?: InputMaybe<Accepted_Legal_Documents_Bool_Exp>;
};


export type Subscription_RootAccepted_Legal_Documents_By_PkArgs = {
  accepted_legal_document_id: Scalars['bigint'];
};


export type Subscription_RootAccepted_Legal_Documents_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Accepted_Legal_Documents_Stream_Cursor_Input>>;
  where?: InputMaybe<Accepted_Legal_Documents_Bool_Exp>;
};


export type Subscription_RootAcuity_Provider_CalendarArgs = {
  distinct_on?: InputMaybe<Array<Acuity_Provider_Calendar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Acuity_Provider_Calendar_Order_By>>;
  where?: InputMaybe<Acuity_Provider_Calendar_Bool_Exp>;
};


export type Subscription_RootAcuity_Provider_Calendar_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Acuity_Provider_Calendar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Acuity_Provider_Calendar_Order_By>>;
  where?: InputMaybe<Acuity_Provider_Calendar_Bool_Exp>;
};


export type Subscription_RootAcuity_Provider_Calendar_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAcuity_Provider_Calendar_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Acuity_Provider_Calendar_Stream_Cursor_Input>>;
  where?: InputMaybe<Acuity_Provider_Calendar_Bool_Exp>;
};


export type Subscription_RootAddressesArgs = {
  distinct_on?: InputMaybe<Array<Addresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Addresses_Order_By>>;
  where?: InputMaybe<Addresses_Bool_Exp>;
};


export type Subscription_RootAddresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Addresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Addresses_Order_By>>;
  where?: InputMaybe<Addresses_Bool_Exp>;
};


export type Subscription_RootAddresses_By_PkArgs = {
  address_id: Scalars['uuid'];
};


export type Subscription_RootAddresses_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Addresses_Stream_Cursor_Input>>;
  where?: InputMaybe<Addresses_Bool_Exp>;
};


export type Subscription_RootChannel_SignalsArgs = {
  distinct_on?: InputMaybe<Array<Channel_Signals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Channel_Signals_Order_By>>;
  where?: InputMaybe<Channel_Signals_Bool_Exp>;
};


export type Subscription_RootChannel_Signals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Channel_Signals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Channel_Signals_Order_By>>;
  where?: InputMaybe<Channel_Signals_Bool_Exp>;
};


export type Subscription_RootChannel_Signals_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootChannel_Signals_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Channel_Signals_Stream_Cursor_Input>>;
  where?: InputMaybe<Channel_Signals_Bool_Exp>;
};


export type Subscription_RootCommon_Question_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<Common_Question_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Common_Question_Responses_Order_By>>;
  where?: InputMaybe<Common_Question_Responses_Bool_Exp>;
};


export type Subscription_RootCommon_Question_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Common_Question_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Common_Question_Responses_Order_By>>;
  where?: InputMaybe<Common_Question_Responses_Bool_Exp>;
};


export type Subscription_RootCommon_Question_Responses_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCommon_Question_Responses_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Common_Question_Responses_Stream_Cursor_Input>>;
  where?: InputMaybe<Common_Question_Responses_Bool_Exp>;
};


export type Subscription_RootCommon_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Common_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Common_Questions_Order_By>>;
  where?: InputMaybe<Common_Questions_Bool_Exp>;
};


export type Subscription_RootCommon_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Common_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Common_Questions_Order_By>>;
  where?: InputMaybe<Common_Questions_Bool_Exp>;
};


export type Subscription_RootCommon_Questions_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCommon_Questions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Common_Questions_Stream_Cursor_Input>>;
  where?: InputMaybe<Common_Questions_Bool_Exp>;
};


export type Subscription_RootCommunication_ChannelsArgs = {
  distinct_on?: InputMaybe<Array<Communication_Channels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communication_Channels_Order_By>>;
  where?: InputMaybe<Communication_Channels_Bool_Exp>;
};


export type Subscription_RootCommunication_Channels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Communication_Channels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communication_Channels_Order_By>>;
  where?: InputMaybe<Communication_Channels_Bool_Exp>;
};


export type Subscription_RootCommunication_Channels_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootCommunication_Channels_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Communication_Channels_Stream_Cursor_Input>>;
  where?: InputMaybe<Communication_Channels_Bool_Exp>;
};


export type Subscription_RootCountriesArgs = {
  distinct_on?: InputMaybe<Array<Countries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Countries_Order_By>>;
  where?: InputMaybe<Countries_Bool_Exp>;
};


export type Subscription_RootCountries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Countries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Countries_Order_By>>;
  where?: InputMaybe<Countries_Bool_Exp>;
};


export type Subscription_RootCountries_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCountries_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Countries_Stream_Cursor_Input>>;
  where?: InputMaybe<Countries_Bool_Exp>;
};


export type Subscription_RootCustom_Auth_CodeArgs = {
  distinct_on?: InputMaybe<Array<Custom_Auth_Code_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Custom_Auth_Code_Order_By>>;
  where?: InputMaybe<Custom_Auth_Code_Bool_Exp>;
};


export type Subscription_RootCustom_Auth_Code_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Custom_Auth_Code_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Custom_Auth_Code_Order_By>>;
  where?: InputMaybe<Custom_Auth_Code_Bool_Exp>;
};


export type Subscription_RootCustom_Auth_Code_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCustom_Auth_Code_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Custom_Auth_Code_Stream_Cursor_Input>>;
  where?: InputMaybe<Custom_Auth_Code_Bool_Exp>;
};


export type Subscription_RootDnavisit_Test_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Dnavisit_Test_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dnavisit_Test_Statuses_Order_By>>;
  where?: InputMaybe<Dnavisit_Test_Statuses_Bool_Exp>;
};


export type Subscription_RootDnavisit_Test_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dnavisit_Test_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dnavisit_Test_Statuses_Order_By>>;
  where?: InputMaybe<Dnavisit_Test_Statuses_Bool_Exp>;
};


export type Subscription_RootDnavisit_Test_Statuses_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootDnavisit_Test_Statuses_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dnavisit_Test_Statuses_Stream_Cursor_Input>>;
  where?: InputMaybe<Dnavisit_Test_Statuses_Bool_Exp>;
};


export type Subscription_RootDnavisit_TestsArgs = {
  distinct_on?: InputMaybe<Array<Dnavisit_Tests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dnavisit_Tests_Order_By>>;
  where?: InputMaybe<Dnavisit_Tests_Bool_Exp>;
};


export type Subscription_RootDnavisit_Tests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dnavisit_Tests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dnavisit_Tests_Order_By>>;
  where?: InputMaybe<Dnavisit_Tests_Bool_Exp>;
};


export type Subscription_RootDnavisit_Tests_By_PkArgs = {
  test_id: Scalars['uuid'];
};


export type Subscription_RootDnavisit_Tests_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dnavisit_Tests_Stream_Cursor_Input>>;
  where?: InputMaybe<Dnavisit_Tests_Bool_Exp>;
};


export type Subscription_RootDo_Not_Use_Production_Swab_CodesArgs = {
  distinct_on?: InputMaybe<Array<Do_Not_Use_Production_Swab_Codes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Do_Not_Use_Production_Swab_Codes_Order_By>>;
  where?: InputMaybe<Do_Not_Use_Production_Swab_Codes_Bool_Exp>;
};


export type Subscription_RootDo_Not_Use_Production_Swab_Codes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Do_Not_Use_Production_Swab_Codes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Do_Not_Use_Production_Swab_Codes_Order_By>>;
  where?: InputMaybe<Do_Not_Use_Production_Swab_Codes_Bool_Exp>;
};


export type Subscription_RootDo_Not_Use_Production_Swab_Codes_By_PkArgs = {
  swab_code: Scalars['String'];
};


export type Subscription_RootDo_Not_Use_Production_Swab_Codes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Do_Not_Use_Production_Swab_Codes_Stream_Cursor_Input>>;
  where?: InputMaybe<Do_Not_Use_Production_Swab_Codes_Bool_Exp>;
};


export type Subscription_RootDosespot_CliniciansArgs = {
  distinct_on?: InputMaybe<Array<Dosespot_Clinicians_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dosespot_Clinicians_Order_By>>;
  where?: InputMaybe<Dosespot_Clinicians_Bool_Exp>;
};


export type Subscription_RootDosespot_Clinicians_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dosespot_Clinicians_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dosespot_Clinicians_Order_By>>;
  where?: InputMaybe<Dosespot_Clinicians_Bool_Exp>;
};


export type Subscription_RootDosespot_Clinicians_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootDosespot_Clinicians_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dosespot_Clinicians_Stream_Cursor_Input>>;
  where?: InputMaybe<Dosespot_Clinicians_Bool_Exp>;
};


export type Subscription_RootFile_TypesArgs = {
  distinct_on?: InputMaybe<Array<File_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Types_Order_By>>;
  where?: InputMaybe<File_Types_Bool_Exp>;
};


export type Subscription_RootFile_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<File_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Types_Order_By>>;
  where?: InputMaybe<File_Types_Bool_Exp>;
};


export type Subscription_RootFile_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootFile_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<File_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<File_Types_Bool_Exp>;
};


export type Subscription_RootFilesArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


export type Subscription_RootFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


export type Subscription_RootFiles_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootFiles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Files_Stream_Cursor_Input>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


export type Subscription_RootFullscript_CliniciansArgs = {
  distinct_on?: InputMaybe<Array<Fullscript_Clinicians_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fullscript_Clinicians_Order_By>>;
  where?: InputMaybe<Fullscript_Clinicians_Bool_Exp>;
};


export type Subscription_RootFullscript_Clinicians_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Fullscript_Clinicians_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fullscript_Clinicians_Order_By>>;
  where?: InputMaybe<Fullscript_Clinicians_Bool_Exp>;
};


export type Subscription_RootFullscript_Clinicians_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootFullscript_Clinicians_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Fullscript_Clinicians_Stream_Cursor_Input>>;
  where?: InputMaybe<Fullscript_Clinicians_Bool_Exp>;
};


export type Subscription_RootGlobal_ConfigsArgs = {
  distinct_on?: InputMaybe<Array<Global_Configs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Global_Configs_Order_By>>;
  where?: InputMaybe<Global_Configs_Bool_Exp>;
};


export type Subscription_RootGlobal_Configs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Global_Configs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Global_Configs_Order_By>>;
  where?: InputMaybe<Global_Configs_Bool_Exp>;
};


export type Subscription_RootGlobal_Configs_By_PkArgs = {
  name: Global_Configs_Names_Enum;
};


export type Subscription_RootGlobal_Configs_NamesArgs = {
  distinct_on?: InputMaybe<Array<Global_Configs_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Global_Configs_Names_Order_By>>;
  where?: InputMaybe<Global_Configs_Names_Bool_Exp>;
};


export type Subscription_RootGlobal_Configs_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Global_Configs_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Global_Configs_Names_Order_By>>;
  where?: InputMaybe<Global_Configs_Names_Bool_Exp>;
};


export type Subscription_RootGlobal_Configs_Names_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootGlobal_Configs_Names_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Global_Configs_Names_Stream_Cursor_Input>>;
  where?: InputMaybe<Global_Configs_Names_Bool_Exp>;
};


export type Subscription_RootGlobal_Configs_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Global_Configs_Stream_Cursor_Input>>;
  where?: InputMaybe<Global_Configs_Bool_Exp>;
};


export type Subscription_RootImage_Source_TypesArgs = {
  distinct_on?: InputMaybe<Array<Image_Source_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Image_Source_Types_Order_By>>;
  where?: InputMaybe<Image_Source_Types_Bool_Exp>;
};


export type Subscription_RootImage_Source_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Image_Source_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Image_Source_Types_Order_By>>;
  where?: InputMaybe<Image_Source_Types_Bool_Exp>;
};


export type Subscription_RootImage_Source_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootImage_Source_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Image_Source_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Image_Source_Types_Bool_Exp>;
};


export type Subscription_RootLabsArgs = {
  distinct_on?: InputMaybe<Array<Labs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Labs_Order_By>>;
  where?: InputMaybe<Labs_Bool_Exp>;
};


export type Subscription_RootLabs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Labs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Labs_Order_By>>;
  where?: InputMaybe<Labs_Bool_Exp>;
};


export type Subscription_RootLabs_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootLabs_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Labs_Stream_Cursor_Input>>;
  where?: InputMaybe<Labs_Bool_Exp>;
};


export type Subscription_RootLegal_Document_TypesArgs = {
  distinct_on?: InputMaybe<Array<Legal_Document_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Legal_Document_Types_Order_By>>;
  where?: InputMaybe<Legal_Document_Types_Bool_Exp>;
};


export type Subscription_RootLegal_Document_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Legal_Document_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Legal_Document_Types_Order_By>>;
  where?: InputMaybe<Legal_Document_Types_Bool_Exp>;
};


export type Subscription_RootLegal_Document_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootLegal_Document_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Legal_Document_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Legal_Document_Types_Bool_Exp>;
};


export type Subscription_RootLegal_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Legal_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Legal_Documents_Order_By>>;
  where?: InputMaybe<Legal_Documents_Bool_Exp>;
};


export type Subscription_RootLegal_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Legal_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Legal_Documents_Order_By>>;
  where?: InputMaybe<Legal_Documents_Bool_Exp>;
};


export type Subscription_RootLegal_Documents_By_PkArgs = {
  legal_document_id: Scalars['bigint'];
};


export type Subscription_RootLegal_Documents_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Legal_Documents_Stream_Cursor_Input>>;
  where?: InputMaybe<Legal_Documents_Bool_Exp>;
};


export type Subscription_RootMedical_SchoolsArgs = {
  distinct_on?: InputMaybe<Array<Medical_Schools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medical_Schools_Order_By>>;
  where?: InputMaybe<Medical_Schools_Bool_Exp>;
};


export type Subscription_RootMedical_Schools_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Medical_Schools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medical_Schools_Order_By>>;
  where?: InputMaybe<Medical_Schools_Bool_Exp>;
};


export type Subscription_RootMedical_Schools_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootMedical_Schools_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Medical_Schools_Stream_Cursor_Input>>;
  where?: InputMaybe<Medical_Schools_Bool_Exp>;
};


export type Subscription_RootNabla_NotesArgs = {
  distinct_on?: InputMaybe<Array<Nabla_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nabla_Notes_Order_By>>;
  where?: InputMaybe<Nabla_Notes_Bool_Exp>;
};


export type Subscription_RootNabla_Notes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nabla_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nabla_Notes_Order_By>>;
  where?: InputMaybe<Nabla_Notes_Bool_Exp>;
};


export type Subscription_RootNabla_Notes_By_PkArgs = {
  appointment_id: Scalars['String'];
};


export type Subscription_RootNabla_Notes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nabla_Notes_Stream_Cursor_Input>>;
  where?: InputMaybe<Nabla_Notes_Bool_Exp>;
};


export type Subscription_RootNpi_Registry_DataArgs = {
  distinct_on?: InputMaybe<Array<Npi_Registry_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Npi_Registry_Data_Order_By>>;
  where?: InputMaybe<Npi_Registry_Data_Bool_Exp>;
};


export type Subscription_RootNpi_Registry_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Npi_Registry_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Npi_Registry_Data_Order_By>>;
  where?: InputMaybe<Npi_Registry_Data_Bool_Exp>;
};


export type Subscription_RootNpi_Registry_Data_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNpi_Registry_Data_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Npi_Registry_Data_Stream_Cursor_Input>>;
  where?: InputMaybe<Npi_Registry_Data_Bool_Exp>;
};


export type Subscription_RootPersona_InquiriesArgs = {
  distinct_on?: InputMaybe<Array<Persona_Inquiries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Persona_Inquiries_Order_By>>;
  where?: InputMaybe<Persona_Inquiries_Bool_Exp>;
};


export type Subscription_RootPersona_Inquiries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Persona_Inquiries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Persona_Inquiries_Order_By>>;
  where?: InputMaybe<Persona_Inquiries_Bool_Exp>;
};


export type Subscription_RootPersona_Inquiries_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPersona_Inquiries_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Persona_Inquiries_Stream_Cursor_Input>>;
  where?: InputMaybe<Persona_Inquiries_Bool_Exp>;
};


export type Subscription_RootPersona_Inquiry_StatusArgs = {
  distinct_on?: InputMaybe<Array<Persona_Inquiry_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Persona_Inquiry_Status_Order_By>>;
  where?: InputMaybe<Persona_Inquiry_Status_Bool_Exp>;
};


export type Subscription_RootPersona_Inquiry_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Persona_Inquiry_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Persona_Inquiry_Status_Order_By>>;
  where?: InputMaybe<Persona_Inquiry_Status_Bool_Exp>;
};


export type Subscription_RootPersona_Inquiry_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootPersona_Inquiry_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Persona_Inquiry_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Persona_Inquiry_Status_Bool_Exp>;
};


export type Subscription_RootProvider_Unread_External_Notification_CountsArgs = {
  distinct_on?: InputMaybe<Array<Provider_Unread_External_Notification_Counts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Provider_Unread_External_Notification_Counts_Order_By>>;
  where?: InputMaybe<Provider_Unread_External_Notification_Counts_Bool_Exp>;
};


export type Subscription_RootProvider_Unread_External_Notification_Counts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Provider_Unread_External_Notification_Counts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Provider_Unread_External_Notification_Counts_Order_By>>;
  where?: InputMaybe<Provider_Unread_External_Notification_Counts_Bool_Exp>;
};


export type Subscription_RootProvider_Unread_External_Notification_Counts_By_PkArgs = {
  codex_provider_id: Scalars['uuid'];
};


export type Subscription_RootProvider_Unread_External_Notification_Counts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Provider_Unread_External_Notification_Counts_Stream_Cursor_Input>>;
  where?: InputMaybe<Provider_Unread_External_Notification_Counts_Bool_Exp>;
};


export type Subscription_RootQuestion_Measurement_UnitsArgs = {
  distinct_on?: InputMaybe<Array<Question_Measurement_Units_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Question_Measurement_Units_Order_By>>;
  where?: InputMaybe<Question_Measurement_Units_Bool_Exp>;
};


export type Subscription_RootQuestion_Measurement_Units_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Question_Measurement_Units_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Question_Measurement_Units_Order_By>>;
  where?: InputMaybe<Question_Measurement_Units_Bool_Exp>;
};


export type Subscription_RootQuestion_Measurement_Units_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootQuestion_Measurement_Units_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Question_Measurement_Units_Stream_Cursor_Input>>;
  where?: InputMaybe<Question_Measurement_Units_Bool_Exp>;
};


export type Subscription_RootQuestion_TypesArgs = {
  distinct_on?: InputMaybe<Array<Question_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Question_Types_Order_By>>;
  where?: InputMaybe<Question_Types_Bool_Exp>;
};


export type Subscription_RootQuestion_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Question_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Question_Types_Order_By>>;
  where?: InputMaybe<Question_Types_Bool_Exp>;
};


export type Subscription_RootQuestion_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootQuestion_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Question_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Question_Types_Bool_Exp>;
};


export type Subscription_RootQuestion_Ui_Control_Helper_Text_PositionArgs = {
  distinct_on?: InputMaybe<Array<Question_Ui_Control_Helper_Text_Position_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Question_Ui_Control_Helper_Text_Position_Order_By>>;
  where?: InputMaybe<Question_Ui_Control_Helper_Text_Position_Bool_Exp>;
};


export type Subscription_RootQuestion_Ui_Control_Helper_Text_Position_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Question_Ui_Control_Helper_Text_Position_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Question_Ui_Control_Helper_Text_Position_Order_By>>;
  where?: InputMaybe<Question_Ui_Control_Helper_Text_Position_Bool_Exp>;
};


export type Subscription_RootQuestion_Ui_Control_Helper_Text_Position_By_PkArgs = {
  helper_text_position: Scalars['String'];
};


export type Subscription_RootQuestion_Ui_Control_Helper_Text_Position_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Question_Ui_Control_Helper_Text_Position_Stream_Cursor_Input>>;
  where?: InputMaybe<Question_Ui_Control_Helper_Text_Position_Bool_Exp>;
};


export type Subscription_RootQuestion_Ui_Control_TypesArgs = {
  distinct_on?: InputMaybe<Array<Question_Ui_Control_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Question_Ui_Control_Types_Order_By>>;
  where?: InputMaybe<Question_Ui_Control_Types_Bool_Exp>;
};


export type Subscription_RootQuestion_Ui_Control_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Question_Ui_Control_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Question_Ui_Control_Types_Order_By>>;
  where?: InputMaybe<Question_Ui_Control_Types_Bool_Exp>;
};


export type Subscription_RootQuestion_Ui_Control_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootQuestion_Ui_Control_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Question_Ui_Control_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Question_Ui_Control_Types_Bool_Exp>;
};


export type Subscription_RootQuestionnaire_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Questionnaire_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questionnaire_Questions_Order_By>>;
  where?: InputMaybe<Questionnaire_Questions_Bool_Exp>;
};


export type Subscription_RootQuestionnaire_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questionnaire_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questionnaire_Questions_Order_By>>;
  where?: InputMaybe<Questionnaire_Questions_Bool_Exp>;
};


export type Subscription_RootQuestionnaire_Questions_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootQuestionnaire_Questions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Questionnaire_Questions_Stream_Cursor_Input>>;
  where?: InputMaybe<Questionnaire_Questions_Bool_Exp>;
};


export type Subscription_RootQuestionnaire_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<Questionnaire_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questionnaire_Responses_Order_By>>;
  where?: InputMaybe<Questionnaire_Responses_Bool_Exp>;
};


export type Subscription_RootQuestionnaire_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questionnaire_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questionnaire_Responses_Order_By>>;
  where?: InputMaybe<Questionnaire_Responses_Bool_Exp>;
};


export type Subscription_RootQuestionnaire_Responses_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootQuestionnaire_Responses_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Questionnaire_Responses_Stream_Cursor_Input>>;
  where?: InputMaybe<Questionnaire_Responses_Bool_Exp>;
};


export type Subscription_RootQuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questionnaires_Order_By>>;
  where?: InputMaybe<Questionnaires_Bool_Exp>;
};


export type Subscription_RootQuestionnaires_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questionnaires_Order_By>>;
  where?: InputMaybe<Questionnaires_Bool_Exp>;
};


export type Subscription_RootQuestionnaires_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootQuestionnaires_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Questionnaires_Stream_Cursor_Input>>;
  where?: InputMaybe<Questionnaires_Bool_Exp>;
};


export type Subscription_RootQuestionsArgs = {
  distinct_on?: InputMaybe<Array<Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Order_By>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};


export type Subscription_RootQuestions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Order_By>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};


export type Subscription_RootQuestions_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootQuestions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Questions_Stream_Cursor_Input>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};


export type Subscription_RootRole_TypesArgs = {
  distinct_on?: InputMaybe<Array<Role_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Role_Types_Order_By>>;
  where?: InputMaybe<Role_Types_Bool_Exp>;
};


export type Subscription_RootRole_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Role_Types_Order_By>>;
  where?: InputMaybe<Role_Types_Bool_Exp>;
};


export type Subscription_RootRole_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootRole_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Role_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Role_Types_Bool_Exp>;
};


export type Subscription_RootRolesArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Subscription_RootRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Subscription_RootRoles_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootRoles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Roles_Stream_Cursor_Input>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Subscription_RootSkintelligent_TestsArgs = {
  distinct_on?: InputMaybe<Array<Skintelligent_Tests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Skintelligent_Tests_Order_By>>;
  where?: InputMaybe<Skintelligent_Tests_Bool_Exp>;
};


export type Subscription_RootSkintelligent_Tests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Skintelligent_Tests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Skintelligent_Tests_Order_By>>;
  where?: InputMaybe<Skintelligent_Tests_Bool_Exp>;
};


export type Subscription_RootSkintelligent_Tests_By_PkArgs = {
  test_id: Scalars['uuid'];
};


export type Subscription_RootSkintelligent_Tests_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Skintelligent_Tests_Stream_Cursor_Input>>;
  where?: InputMaybe<Skintelligent_Tests_Bool_Exp>;
};


export type Subscription_RootSwab_Code_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Swab_Code_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Swab_Code_Statuses_Order_By>>;
  where?: InputMaybe<Swab_Code_Statuses_Bool_Exp>;
};


export type Subscription_RootSwab_Code_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Swab_Code_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Swab_Code_Statuses_Order_By>>;
  where?: InputMaybe<Swab_Code_Statuses_Bool_Exp>;
};


export type Subscription_RootSwab_Code_Statuses_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootSwab_Code_Statuses_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Swab_Code_Statuses_Stream_Cursor_Input>>;
  where?: InputMaybe<Swab_Code_Statuses_Bool_Exp>;
};


export type Subscription_RootSwab_CodesArgs = {
  distinct_on?: InputMaybe<Array<Swab_Codes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Swab_Codes_Order_By>>;
  where?: InputMaybe<Swab_Codes_Bool_Exp>;
};


export type Subscription_RootSwab_Codes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Swab_Codes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Swab_Codes_Order_By>>;
  where?: InputMaybe<Swab_Codes_Bool_Exp>;
};


export type Subscription_RootSwab_Codes_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootSwab_Codes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Swab_Codes_Stream_Cursor_Input>>;
  where?: InputMaybe<Swab_Codes_Bool_Exp>;
};


export type Subscription_RootSwab_Usage_TypeArgs = {
  distinct_on?: InputMaybe<Array<Swab_Usage_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Swab_Usage_Type_Order_By>>;
  where?: InputMaybe<Swab_Usage_Type_Bool_Exp>;
};


export type Subscription_RootSwab_Usage_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Swab_Usage_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Swab_Usage_Type_Order_By>>;
  where?: InputMaybe<Swab_Usage_Type_Bool_Exp>;
};


export type Subscription_RootSwab_Usage_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootSwab_Usage_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Swab_Usage_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Swab_Usage_Type_Bool_Exp>;
};


export type Subscription_RootTest_DefinitionsArgs = {
  distinct_on?: InputMaybe<Array<Test_Definitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Definitions_Order_By>>;
  where?: InputMaybe<Test_Definitions_Bool_Exp>;
};


export type Subscription_RootTest_Definitions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Test_Definitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Definitions_Order_By>>;
  where?: InputMaybe<Test_Definitions_Bool_Exp>;
};


export type Subscription_RootTest_Definitions_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTest_Definitions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Test_Definitions_Stream_Cursor_Input>>;
  where?: InputMaybe<Test_Definitions_Bool_Exp>;
};


export type Subscription_RootTest_Kits_Update_States_Summary_LogArgs = {
  distinct_on?: InputMaybe<Array<Test_Kits_Update_States_Summary_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Kits_Update_States_Summary_Log_Order_By>>;
  where?: InputMaybe<Test_Kits_Update_States_Summary_Log_Bool_Exp>;
};


export type Subscription_RootTest_Kits_Update_States_Summary_Log_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Test_Kits_Update_States_Summary_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Kits_Update_States_Summary_Log_Order_By>>;
  where?: InputMaybe<Test_Kits_Update_States_Summary_Log_Bool_Exp>;
};


export type Subscription_RootTest_Kits_Update_States_Summary_Log_By_PkArgs = {
  log_id: Scalars['uuid'];
};


export type Subscription_RootTest_Kits_Update_States_Summary_Log_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Test_Kits_Update_States_Summary_Log_Stream_Cursor_Input>>;
  where?: InputMaybe<Test_Kits_Update_States_Summary_Log_Bool_Exp>;
};


export type Subscription_RootTest_TypesArgs = {
  distinct_on?: InputMaybe<Array<Test_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Types_Order_By>>;
  where?: InputMaybe<Test_Types_Bool_Exp>;
};


export type Subscription_RootTest_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Test_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Types_Order_By>>;
  where?: InputMaybe<Test_Types_Bool_Exp>;
};


export type Subscription_RootTest_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootTest_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Test_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Test_Types_Bool_Exp>;
};


export type Subscription_RootTestsArgs = {
  distinct_on?: InputMaybe<Array<Tests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tests_Order_By>>;
  where?: InputMaybe<Tests_Bool_Exp>;
};


export type Subscription_RootTests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tests_Order_By>>;
  where?: InputMaybe<Tests_Bool_Exp>;
};


export type Subscription_RootTests_By_PkArgs = {
  test_id: Scalars['uuid'];
};


export type Subscription_RootTests_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tests_Stream_Cursor_Input>>;
  where?: InputMaybe<Tests_Bool_Exp>;
};


export type Subscription_RootText_AddressesArgs = {
  distinct_on?: InputMaybe<Array<Text_Addresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Text_Addresses_Order_By>>;
  where?: InputMaybe<Text_Addresses_Bool_Exp>;
};


export type Subscription_RootText_Addresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Text_Addresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Text_Addresses_Order_By>>;
  where?: InputMaybe<Text_Addresses_Bool_Exp>;
};


export type Subscription_RootText_Addresses_By_PkArgs = {
  address_id: Scalars['uuid'];
};


export type Subscription_RootText_Addresses_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Text_Addresses_Stream_Cursor_Input>>;
  where?: InputMaybe<Text_Addresses_Bool_Exp>;
};


export type Subscription_RootUnauthenticated_Questionnaire_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Unauthenticated_Questionnaire_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Unauthenticated_Questionnaire_Questions_Order_By>>;
  where?: InputMaybe<Unauthenticated_Questionnaire_Questions_Bool_Exp>;
};


export type Subscription_RootUnauthenticated_Questionnaire_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Unauthenticated_Questionnaire_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Unauthenticated_Questionnaire_Questions_Order_By>>;
  where?: InputMaybe<Unauthenticated_Questionnaire_Questions_Bool_Exp>;
};


export type Subscription_RootUnauthenticated_Questionnaire_Questions_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootUnauthenticated_Questionnaire_Questions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Unauthenticated_Questionnaire_Questions_Stream_Cursor_Input>>;
  where?: InputMaybe<Unauthenticated_Questionnaire_Questions_Bool_Exp>;
};


export type Subscription_RootUnauthenticated_QuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<Unauthenticated_Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Unauthenticated_Questionnaires_Order_By>>;
  where?: InputMaybe<Unauthenticated_Questionnaires_Bool_Exp>;
};


export type Subscription_RootUnauthenticated_Questionnaires_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Unauthenticated_Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Unauthenticated_Questionnaires_Order_By>>;
  where?: InputMaybe<Unauthenticated_Questionnaires_Bool_Exp>;
};


export type Subscription_RootUnauthenticated_Questionnaires_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootUnauthenticated_Questionnaires_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Unauthenticated_Questionnaires_Stream_Cursor_Input>>;
  where?: InputMaybe<Unauthenticated_Questionnaires_Bool_Exp>;
};


export type Subscription_RootUnauthenticated_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Unauthenticated_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Unauthenticated_Questions_Order_By>>;
  where?: InputMaybe<Unauthenticated_Questions_Bool_Exp>;
};


export type Subscription_RootUnauthenticated_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Unauthenticated_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Unauthenticated_Questions_Order_By>>;
  where?: InputMaybe<Unauthenticated_Questions_Bool_Exp>;
};


export type Subscription_RootUnauthenticated_Questions_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootUnauthenticated_Questions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Unauthenticated_Questions_Stream_Cursor_Input>>;
  where?: InputMaybe<Unauthenticated_Questions_Bool_Exp>;
};


export type Subscription_RootUniversitiesArgs = {
  distinct_on?: InputMaybe<Array<Universities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Universities_Order_By>>;
  where?: InputMaybe<Universities_Bool_Exp>;
};


export type Subscription_RootUniversities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Universities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Universities_Order_By>>;
  where?: InputMaybe<Universities_Bool_Exp>;
};


export type Subscription_RootUniversities_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootUniversities_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Universities_Stream_Cursor_Input>>;
  where?: InputMaybe<Universities_Bool_Exp>;
};


export type Subscription_RootUser_AddressesArgs = {
  distinct_on?: InputMaybe<Array<User_Addresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Addresses_Order_By>>;
  where?: InputMaybe<User_Addresses_Bool_Exp>;
};


export type Subscription_RootUser_Addresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Addresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Addresses_Order_By>>;
  where?: InputMaybe<User_Addresses_Bool_Exp>;
};


export type Subscription_RootUser_Addresses_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootUser_Addresses_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Addresses_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Addresses_Bool_Exp>;
};


export type Subscription_RootUser_Auth_Provider_TypesArgs = {
  distinct_on?: InputMaybe<Array<User_Auth_Provider_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Auth_Provider_Types_Order_By>>;
  where?: InputMaybe<User_Auth_Provider_Types_Bool_Exp>;
};


export type Subscription_RootUser_Auth_Provider_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Auth_Provider_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Auth_Provider_Types_Order_By>>;
  where?: InputMaybe<User_Auth_Provider_Types_Bool_Exp>;
};


export type Subscription_RootUser_Auth_Provider_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootUser_Auth_Provider_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Auth_Provider_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Auth_Provider_Types_Bool_Exp>;
};


export type Subscription_RootUser_Auth_ProvidersArgs = {
  distinct_on?: InputMaybe<Array<User_Auth_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Auth_Providers_Order_By>>;
  where?: InputMaybe<User_Auth_Providers_Bool_Exp>;
};


export type Subscription_RootUser_Auth_Providers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Auth_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Auth_Providers_Order_By>>;
  where?: InputMaybe<User_Auth_Providers_Bool_Exp>;
};


export type Subscription_RootUser_Auth_Providers_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootUser_Auth_Providers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Auth_Providers_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Auth_Providers_Bool_Exp>;
};


export type Subscription_RootUser_Auth_TokensArgs = {
  distinct_on?: InputMaybe<Array<User_Auth_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Auth_Tokens_Order_By>>;
  where?: InputMaybe<User_Auth_Tokens_Bool_Exp>;
};


export type Subscription_RootUser_Auth_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Auth_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Auth_Tokens_Order_By>>;
  where?: InputMaybe<User_Auth_Tokens_Bool_Exp>;
};


export type Subscription_RootUser_Auth_Tokens_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootUser_Auth_Tokens_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Auth_Tokens_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Auth_Tokens_Bool_Exp>;
};


export type Subscription_RootUser_Communication_DeliveriesArgs = {
  distinct_on?: InputMaybe<Array<User_Communication_Deliveries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Communication_Deliveries_Order_By>>;
  where?: InputMaybe<User_Communication_Deliveries_Bool_Exp>;
};


export type Subscription_RootUser_Communication_Deliveries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Communication_Deliveries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Communication_Deliveries_Order_By>>;
  where?: InputMaybe<User_Communication_Deliveries_Bool_Exp>;
};


export type Subscription_RootUser_Communication_Deliveries_By_PkArgs = {
  user_communication_delivery_id: Scalars['bigint'];
};


export type Subscription_RootUser_Communication_Deliveries_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Communication_Deliveries_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Communication_Deliveries_Bool_Exp>;
};


export type Subscription_RootUser_CommunicationsArgs = {
  distinct_on?: InputMaybe<Array<User_Communications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Communications_Order_By>>;
  where?: InputMaybe<User_Communications_Bool_Exp>;
};


export type Subscription_RootUser_Communications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Communications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Communications_Order_By>>;
  where?: InputMaybe<User_Communications_Bool_Exp>;
};


export type Subscription_RootUser_Communications_By_PkArgs = {
  user_communication_id: Scalars['bigint'];
};


export type Subscription_RootUser_Communications_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Communications_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Communications_Bool_Exp>;
};


export type Subscription_RootUser_Notification_StatusArgs = {
  distinct_on?: InputMaybe<Array<User_Notification_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Notification_Status_Order_By>>;
  where?: InputMaybe<User_Notification_Status_Bool_Exp>;
};


export type Subscription_RootUser_Notification_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Notification_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Notification_Status_Order_By>>;
  where?: InputMaybe<User_Notification_Status_Bool_Exp>;
};


export type Subscription_RootUser_Notification_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootUser_Notification_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Notification_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Notification_Status_Bool_Exp>;
};


export type Subscription_RootUser_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<User_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Notifications_Order_By>>;
  where?: InputMaybe<User_Notifications_Bool_Exp>;
};


export type Subscription_RootUser_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Notifications_Order_By>>;
  where?: InputMaybe<User_Notifications_Bool_Exp>;
};


export type Subscription_RootUser_Notifications_By_PkArgs = {
  user_communication_delivery_id: Scalars['bigint'];
};


export type Subscription_RootUser_Notifications_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Notifications_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Notifications_Bool_Exp>;
};


export type Subscription_RootUser_RolesArgs = {
  distinct_on?: InputMaybe<Array<User_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Roles_Order_By>>;
  where?: InputMaybe<User_Roles_Bool_Exp>;
};


export type Subscription_RootUser_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Roles_Order_By>>;
  where?: InputMaybe<User_Roles_Bool_Exp>;
};


export type Subscription_RootUser_Roles_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootUser_Roles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Roles_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Roles_Bool_Exp>;
};


export type Subscription_RootUser_Scanned_DataArgs = {
  distinct_on?: InputMaybe<Array<User_Scanned_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Scanned_Data_Order_By>>;
  where?: InputMaybe<User_Scanned_Data_Bool_Exp>;
};


export type Subscription_RootUser_Scanned_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Scanned_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Scanned_Data_Order_By>>;
  where?: InputMaybe<User_Scanned_Data_Bool_Exp>;
};


export type Subscription_RootUser_Scanned_Data_By_PkArgs = {
  user_id: Scalars['uuid'];
};


export type Subscription_RootUser_Scanned_Data_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Scanned_Data_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Scanned_Data_Bool_Exp>;
};


export type Subscription_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUsers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootVonage_AppointmentArgs = {
  distinct_on?: InputMaybe<Array<Vonage_Appointment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vonage_Appointment_Order_By>>;
  where?: InputMaybe<Vonage_Appointment_Bool_Exp>;
};


export type Subscription_RootVonage_Appointment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vonage_Appointment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vonage_Appointment_Order_By>>;
  where?: InputMaybe<Vonage_Appointment_Bool_Exp>;
};


export type Subscription_RootVonage_Appointment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootVonage_Appointment_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Vonage_Appointment_Stream_Cursor_Input>>;
  where?: InputMaybe<Vonage_Appointment_Bool_Exp>;
};


export type Subscription_RootZrt_TestsArgs = {
  distinct_on?: InputMaybe<Array<Zrt_Tests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zrt_Tests_Order_By>>;
  where?: InputMaybe<Zrt_Tests_Bool_Exp>;
};


export type Subscription_RootZrt_Tests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zrt_Tests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zrt_Tests_Order_By>>;
  where?: InputMaybe<Zrt_Tests_Bool_Exp>;
};


export type Subscription_RootZrt_Tests_By_PkArgs = {
  test_id: Scalars['uuid'];
};


export type Subscription_RootZrt_Tests_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Zrt_Tests_Stream_Cursor_Input>>;
  where?: InputMaybe<Zrt_Tests_Bool_Exp>;
};

/** columns and relationships of "swab_code_statuses" */
export type Swab_Code_Statuses = {
  __typename?: 'swab_code_statuses';
  comment?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** aggregated selection of "swab_code_statuses" */
export type Swab_Code_Statuses_Aggregate = {
  __typename?: 'swab_code_statuses_aggregate';
  aggregate?: Maybe<Swab_Code_Statuses_Aggregate_Fields>;
  nodes: Array<Swab_Code_Statuses>;
};

/** aggregate fields of "swab_code_statuses" */
export type Swab_Code_Statuses_Aggregate_Fields = {
  __typename?: 'swab_code_statuses_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Swab_Code_Statuses_Max_Fields>;
  min?: Maybe<Swab_Code_Statuses_Min_Fields>;
};


/** aggregate fields of "swab_code_statuses" */
export type Swab_Code_Statuses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Swab_Code_Statuses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "swab_code_statuses". All fields are combined with a logical 'AND'. */
export type Swab_Code_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Swab_Code_Statuses_Bool_Exp>>;
  _not?: InputMaybe<Swab_Code_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Swab_Code_Statuses_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "swab_code_statuses" */
export enum Swab_Code_Statuses_Constraint {
  /** unique or primary key constraint on columns "name" */
  SwabCodeStatusesPkey = 'swab_code_statuses_pkey'
}

export enum Swab_Code_Statuses_Enum {
  /** Never used, can be scanned and registered. */
  Created = 'CREATED',
  /** Do not use, discarded. */
  Disabled = 'DISABLED',
  /** Discarded, do not use */
  Discarded = 'DISCARDED',
  /** Used, no longer available. */
  Used = 'USED'
}

/** Boolean expression to compare columns of type "swab_code_statuses_enum". All fields are combined with logical 'AND'. */
export type Swab_Code_Statuses_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Swab_Code_Statuses_Enum>;
  _in?: InputMaybe<Array<Swab_Code_Statuses_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Swab_Code_Statuses_Enum>;
  _nin?: InputMaybe<Array<Swab_Code_Statuses_Enum>>;
};

/** input type for inserting data into table "swab_code_statuses" */
export type Swab_Code_Statuses_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Swab_Code_Statuses_Max_Fields = {
  __typename?: 'swab_code_statuses_max_fields';
  comment?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Swab_Code_Statuses_Min_Fields = {
  __typename?: 'swab_code_statuses_min_fields';
  comment?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "swab_code_statuses" */
export type Swab_Code_Statuses_Mutation_Response = {
  __typename?: 'swab_code_statuses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Swab_Code_Statuses>;
};

/** on_conflict condition type for table "swab_code_statuses" */
export type Swab_Code_Statuses_On_Conflict = {
  constraint: Swab_Code_Statuses_Constraint;
  update_columns?: Array<Swab_Code_Statuses_Update_Column>;
  where?: InputMaybe<Swab_Code_Statuses_Bool_Exp>;
};

/** Ordering options when selecting data from "swab_code_statuses". */
export type Swab_Code_Statuses_Order_By = {
  comment?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: swab_code_statuses */
export type Swab_Code_Statuses_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "swab_code_statuses" */
export enum Swab_Code_Statuses_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "swab_code_statuses" */
export type Swab_Code_Statuses_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "swab_code_statuses" */
export type Swab_Code_Statuses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Swab_Code_Statuses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Swab_Code_Statuses_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "swab_code_statuses" */
export enum Swab_Code_Statuses_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Name = 'name'
}

export type Swab_Code_Statuses_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Swab_Code_Statuses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Swab_Code_Statuses_Bool_Exp;
};

/** Storing generated swab codes to track inventory and validate those kits as they are registered on the site */
export type Swab_Codes = {
  __typename?: 'swab_codes';
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  lab_name: Labs_Enum;
  /** Current status of swab code on system (CREATED | DISCARDED | USED) */
  status: Swab_Code_Statuses_Enum;
  swab_code: Scalars['String'];
  /** An array relationship */
  swab_codes_test: Array<Tests>;
  /** An aggregate relationship */
  swab_codes_test_aggregate: Tests_Aggregate;
  swab_usage_type: Swab_Usage_Type_Enum;
  test_type: Test_Types_Enum;
  updated_at: Scalars['timestamptz'];
};


/** Storing generated swab codes to track inventory and validate those kits as they are registered on the site */
export type Swab_CodesSwab_Codes_TestArgs = {
  distinct_on?: InputMaybe<Array<Tests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tests_Order_By>>;
  where?: InputMaybe<Tests_Bool_Exp>;
};


/** Storing generated swab codes to track inventory and validate those kits as they are registered on the site */
export type Swab_CodesSwab_Codes_Test_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tests_Order_By>>;
  where?: InputMaybe<Tests_Bool_Exp>;
};

/** aggregated selection of "swab_codes" */
export type Swab_Codes_Aggregate = {
  __typename?: 'swab_codes_aggregate';
  aggregate?: Maybe<Swab_Codes_Aggregate_Fields>;
  nodes: Array<Swab_Codes>;
};

/** aggregate fields of "swab_codes" */
export type Swab_Codes_Aggregate_Fields = {
  __typename?: 'swab_codes_aggregate_fields';
  avg?: Maybe<Swab_Codes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Swab_Codes_Max_Fields>;
  min?: Maybe<Swab_Codes_Min_Fields>;
  stddev?: Maybe<Swab_Codes_Stddev_Fields>;
  stddev_pop?: Maybe<Swab_Codes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Swab_Codes_Stddev_Samp_Fields>;
  sum?: Maybe<Swab_Codes_Sum_Fields>;
  var_pop?: Maybe<Swab_Codes_Var_Pop_Fields>;
  var_samp?: Maybe<Swab_Codes_Var_Samp_Fields>;
  variance?: Maybe<Swab_Codes_Variance_Fields>;
};


/** aggregate fields of "swab_codes" */
export type Swab_Codes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Swab_Codes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Swab_Codes_Avg_Fields = {
  __typename?: 'swab_codes_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "swab_codes". All fields are combined with a logical 'AND'. */
export type Swab_Codes_Bool_Exp = {
  _and?: InputMaybe<Array<Swab_Codes_Bool_Exp>>;
  _not?: InputMaybe<Swab_Codes_Bool_Exp>;
  _or?: InputMaybe<Array<Swab_Codes_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  lab_name?: InputMaybe<Labs_Enum_Comparison_Exp>;
  status?: InputMaybe<Swab_Code_Statuses_Enum_Comparison_Exp>;
  swab_code?: InputMaybe<String_Comparison_Exp>;
  swab_codes_test?: InputMaybe<Tests_Bool_Exp>;
  swab_codes_test_aggregate?: InputMaybe<Tests_Aggregate_Bool_Exp>;
  swab_usage_type?: InputMaybe<Swab_Usage_Type_Enum_Comparison_Exp>;
  test_type?: InputMaybe<Test_Types_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "swab_codes" */
export enum Swab_Codes_Constraint {
  /** unique or primary key constraint on columns "id" */
  SwabCodesPkey = 'swab_codes_pkey',
  /** unique or primary key constraint on columns "swab_code" */
  SwabCodesSwabCodeKey = 'swab_codes_swab_code_key'
}

/** input type for incrementing numeric columns in table "swab_codes" */
export type Swab_Codes_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "swab_codes" */
export type Swab_Codes_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  lab_name?: InputMaybe<Labs_Enum>;
  /** Current status of swab code on system (CREATED | DISCARDED | USED) */
  status?: InputMaybe<Swab_Code_Statuses_Enum>;
  swab_code?: InputMaybe<Scalars['String']>;
  swab_codes_test?: InputMaybe<Tests_Arr_Rel_Insert_Input>;
  swab_usage_type?: InputMaybe<Swab_Usage_Type_Enum>;
  test_type?: InputMaybe<Test_Types_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Swab_Codes_Max_Fields = {
  __typename?: 'swab_codes_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  swab_code?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Swab_Codes_Min_Fields = {
  __typename?: 'swab_codes_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  swab_code?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "swab_codes" */
export type Swab_Codes_Mutation_Response = {
  __typename?: 'swab_codes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Swab_Codes>;
};

/** input type for inserting object relation for remote table "swab_codes" */
export type Swab_Codes_Obj_Rel_Insert_Input = {
  data: Swab_Codes_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Swab_Codes_On_Conflict>;
};

/** on_conflict condition type for table "swab_codes" */
export type Swab_Codes_On_Conflict = {
  constraint: Swab_Codes_Constraint;
  update_columns?: Array<Swab_Codes_Update_Column>;
  where?: InputMaybe<Swab_Codes_Bool_Exp>;
};

/** Ordering options when selecting data from "swab_codes". */
export type Swab_Codes_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lab_name?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  swab_code?: InputMaybe<Order_By>;
  swab_codes_test_aggregate?: InputMaybe<Tests_Aggregate_Order_By>;
  swab_usage_type?: InputMaybe<Order_By>;
  test_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: swab_codes */
export type Swab_Codes_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "swab_codes" */
export enum Swab_Codes_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LabName = 'lab_name',
  /** column name */
  Status = 'status',
  /** column name */
  SwabCode = 'swab_code',
  /** column name */
  SwabUsageType = 'swab_usage_type',
  /** column name */
  TestType = 'test_type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "swab_codes" */
export type Swab_Codes_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  lab_name?: InputMaybe<Labs_Enum>;
  /** Current status of swab code on system (CREATED | DISCARDED | USED) */
  status?: InputMaybe<Swab_Code_Statuses_Enum>;
  swab_code?: InputMaybe<Scalars['String']>;
  swab_usage_type?: InputMaybe<Swab_Usage_Type_Enum>;
  test_type?: InputMaybe<Test_Types_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Swab_Codes_Stddev_Fields = {
  __typename?: 'swab_codes_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Swab_Codes_Stddev_Pop_Fields = {
  __typename?: 'swab_codes_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Swab_Codes_Stddev_Samp_Fields = {
  __typename?: 'swab_codes_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "swab_codes" */
export type Swab_Codes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Swab_Codes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Swab_Codes_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  lab_name?: InputMaybe<Labs_Enum>;
  /** Current status of swab code on system (CREATED | DISCARDED | USED) */
  status?: InputMaybe<Swab_Code_Statuses_Enum>;
  swab_code?: InputMaybe<Scalars['String']>;
  swab_usage_type?: InputMaybe<Swab_Usage_Type_Enum>;
  test_type?: InputMaybe<Test_Types_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Swab_Codes_Sum_Fields = {
  __typename?: 'swab_codes_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "swab_codes" */
export enum Swab_Codes_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LabName = 'lab_name',
  /** column name */
  Status = 'status',
  /** column name */
  SwabCode = 'swab_code',
  /** column name */
  SwabUsageType = 'swab_usage_type',
  /** column name */
  TestType = 'test_type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Swab_Codes_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Swab_Codes_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Swab_Codes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Swab_Codes_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Swab_Codes_Var_Pop_Fields = {
  __typename?: 'swab_codes_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Swab_Codes_Var_Samp_Fields = {
  __typename?: 'swab_codes_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Swab_Codes_Variance_Fields = {
  __typename?: 'swab_codes_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Defines types of usage we may have for swabs. */
export type Swab_Usage_Type = {
  __typename?: 'swab_usage_type';
  comment?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "swab_usage_type" */
export type Swab_Usage_Type_Aggregate = {
  __typename?: 'swab_usage_type_aggregate';
  aggregate?: Maybe<Swab_Usage_Type_Aggregate_Fields>;
  nodes: Array<Swab_Usage_Type>;
};

/** aggregate fields of "swab_usage_type" */
export type Swab_Usage_Type_Aggregate_Fields = {
  __typename?: 'swab_usage_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Swab_Usage_Type_Max_Fields>;
  min?: Maybe<Swab_Usage_Type_Min_Fields>;
};


/** aggregate fields of "swab_usage_type" */
export type Swab_Usage_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Swab_Usage_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "swab_usage_type". All fields are combined with a logical 'AND'. */
export type Swab_Usage_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Swab_Usage_Type_Bool_Exp>>;
  _not?: InputMaybe<Swab_Usage_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Swab_Usage_Type_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "swab_usage_type" */
export enum Swab_Usage_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  SwabUsageTypePkey = 'swab_usage_type_pkey'
}

export enum Swab_Usage_Type_Enum {
  /** Standard usage of swab for test. */
  Standard = 'STANDARD',
  /** Swab used for internal testing, actual test may or may not exists with lab. */
  Test = 'TEST'
}

/** Boolean expression to compare columns of type "swab_usage_type_enum". All fields are combined with logical 'AND'. */
export type Swab_Usage_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Swab_Usage_Type_Enum>;
  _in?: InputMaybe<Array<Swab_Usage_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Swab_Usage_Type_Enum>;
  _nin?: InputMaybe<Array<Swab_Usage_Type_Enum>>;
};

/** input type for inserting data into table "swab_usage_type" */
export type Swab_Usage_Type_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Swab_Usage_Type_Max_Fields = {
  __typename?: 'swab_usage_type_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Swab_Usage_Type_Min_Fields = {
  __typename?: 'swab_usage_type_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "swab_usage_type" */
export type Swab_Usage_Type_Mutation_Response = {
  __typename?: 'swab_usage_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Swab_Usage_Type>;
};

/** on_conflict condition type for table "swab_usage_type" */
export type Swab_Usage_Type_On_Conflict = {
  constraint: Swab_Usage_Type_Constraint;
  update_columns?: Array<Swab_Usage_Type_Update_Column>;
  where?: InputMaybe<Swab_Usage_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "swab_usage_type". */
export type Swab_Usage_Type_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: swab_usage_type */
export type Swab_Usage_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "swab_usage_type" */
export enum Swab_Usage_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "swab_usage_type" */
export type Swab_Usage_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "swab_usage_type" */
export type Swab_Usage_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Swab_Usage_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Swab_Usage_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "swab_usage_type" */
export enum Swab_Usage_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Swab_Usage_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Swab_Usage_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Swab_Usage_Type_Bool_Exp;
};

/** columns and relationships of "test_definitions" */
export type Test_Definitions = {
  __typename?: 'test_definitions';
  config?: Maybe<Scalars['jsonb']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  questionnaire_id?: Maybe<Scalars['Int']>;
  test_type: Test_Types_Enum;
};


/** columns and relationships of "test_definitions" */
export type Test_DefinitionsConfigArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "test_definitions" */
export type Test_Definitions_Aggregate = {
  __typename?: 'test_definitions_aggregate';
  aggregate?: Maybe<Test_Definitions_Aggregate_Fields>;
  nodes: Array<Test_Definitions>;
};

/** aggregate fields of "test_definitions" */
export type Test_Definitions_Aggregate_Fields = {
  __typename?: 'test_definitions_aggregate_fields';
  avg?: Maybe<Test_Definitions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Test_Definitions_Max_Fields>;
  min?: Maybe<Test_Definitions_Min_Fields>;
  stddev?: Maybe<Test_Definitions_Stddev_Fields>;
  stddev_pop?: Maybe<Test_Definitions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Test_Definitions_Stddev_Samp_Fields>;
  sum?: Maybe<Test_Definitions_Sum_Fields>;
  var_pop?: Maybe<Test_Definitions_Var_Pop_Fields>;
  var_samp?: Maybe<Test_Definitions_Var_Samp_Fields>;
  variance?: Maybe<Test_Definitions_Variance_Fields>;
};


/** aggregate fields of "test_definitions" */
export type Test_Definitions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Test_Definitions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Test_Definitions_Append_Input = {
  config?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Test_Definitions_Avg_Fields = {
  __typename?: 'test_definitions_avg_fields';
  id?: Maybe<Scalars['Float']>;
  questionnaire_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "test_definitions". All fields are combined with a logical 'AND'. */
export type Test_Definitions_Bool_Exp = {
  _and?: InputMaybe<Array<Test_Definitions_Bool_Exp>>;
  _not?: InputMaybe<Test_Definitions_Bool_Exp>;
  _or?: InputMaybe<Array<Test_Definitions_Bool_Exp>>;
  config?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  questionnaire_id?: InputMaybe<Int_Comparison_Exp>;
  test_type?: InputMaybe<Test_Types_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "test_definitions" */
export enum Test_Definitions_Constraint {
  /** unique or primary key constraint on columns "id" */
  TestDefinitionsPkey = 'test_definitions_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Test_Definitions_Delete_At_Path_Input = {
  config?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Test_Definitions_Delete_Elem_Input = {
  config?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Test_Definitions_Delete_Key_Input = {
  config?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "test_definitions" */
export type Test_Definitions_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  questionnaire_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "test_definitions" */
export type Test_Definitions_Insert_Input = {
  config?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  questionnaire_id?: InputMaybe<Scalars['Int']>;
  test_type?: InputMaybe<Test_Types_Enum>;
};

/** aggregate max on columns */
export type Test_Definitions_Max_Fields = {
  __typename?: 'test_definitions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  questionnaire_id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Test_Definitions_Min_Fields = {
  __typename?: 'test_definitions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  questionnaire_id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "test_definitions" */
export type Test_Definitions_Mutation_Response = {
  __typename?: 'test_definitions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Test_Definitions>;
};

/** input type for inserting object relation for remote table "test_definitions" */
export type Test_Definitions_Obj_Rel_Insert_Input = {
  data: Test_Definitions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Test_Definitions_On_Conflict>;
};

/** on_conflict condition type for table "test_definitions" */
export type Test_Definitions_On_Conflict = {
  constraint: Test_Definitions_Constraint;
  update_columns?: Array<Test_Definitions_Update_Column>;
  where?: InputMaybe<Test_Definitions_Bool_Exp>;
};

/** Ordering options when selecting data from "test_definitions". */
export type Test_Definitions_Order_By = {
  config?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
  test_type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: test_definitions */
export type Test_Definitions_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Test_Definitions_Prepend_Input = {
  config?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "test_definitions" */
export enum Test_Definitions_Select_Column {
  /** column name */
  Config = 'config',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  QuestionnaireId = 'questionnaire_id',
  /** column name */
  TestType = 'test_type'
}

/** input type for updating data in table "test_definitions" */
export type Test_Definitions_Set_Input = {
  config?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  questionnaire_id?: InputMaybe<Scalars['Int']>;
  test_type?: InputMaybe<Test_Types_Enum>;
};

/** aggregate stddev on columns */
export type Test_Definitions_Stddev_Fields = {
  __typename?: 'test_definitions_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  questionnaire_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Test_Definitions_Stddev_Pop_Fields = {
  __typename?: 'test_definitions_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  questionnaire_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Test_Definitions_Stddev_Samp_Fields = {
  __typename?: 'test_definitions_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  questionnaire_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "test_definitions" */
export type Test_Definitions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Test_Definitions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Test_Definitions_Stream_Cursor_Value_Input = {
  config?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  questionnaire_id?: InputMaybe<Scalars['Int']>;
  test_type?: InputMaybe<Test_Types_Enum>;
};

/** aggregate sum on columns */
export type Test_Definitions_Sum_Fields = {
  __typename?: 'test_definitions_sum_fields';
  id?: Maybe<Scalars['Int']>;
  questionnaire_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "test_definitions" */
export enum Test_Definitions_Update_Column {
  /** column name */
  Config = 'config',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  QuestionnaireId = 'questionnaire_id',
  /** column name */
  TestType = 'test_type'
}

export type Test_Definitions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Test_Definitions_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Test_Definitions_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Test_Definitions_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Test_Definitions_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Test_Definitions_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Test_Definitions_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Test_Definitions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Test_Definitions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Test_Definitions_Var_Pop_Fields = {
  __typename?: 'test_definitions_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  questionnaire_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Test_Definitions_Var_Samp_Fields = {
  __typename?: 'test_definitions_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  questionnaire_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Test_Definitions_Variance_Fields = {
  __typename?: 'test_definitions_variance_fields';
  id?: Maybe<Scalars['Float']>;
  questionnaire_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "test_kits_update_states_summary_log" */
export type Test_Kits_Update_States_Summary_Log = {
  __typename?: 'test_kits_update_states_summary_log';
  from_state?: Maybe<Dnavisit_Test_Statuses_Enum>;
  internal_testing: Scalars['Boolean'];
  log_id: Scalars['uuid'];
  status_change_date: Scalars['timestamptz'];
  test_id: Scalars['uuid'];
  test_type: Test_Types_Enum;
  to_state?: Maybe<Dnavisit_Test_Statuses_Enum>;
};

/** aggregated selection of "test_kits_update_states_summary_log" */
export type Test_Kits_Update_States_Summary_Log_Aggregate = {
  __typename?: 'test_kits_update_states_summary_log_aggregate';
  aggregate?: Maybe<Test_Kits_Update_States_Summary_Log_Aggregate_Fields>;
  nodes: Array<Test_Kits_Update_States_Summary_Log>;
};

/** aggregate fields of "test_kits_update_states_summary_log" */
export type Test_Kits_Update_States_Summary_Log_Aggregate_Fields = {
  __typename?: 'test_kits_update_states_summary_log_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Test_Kits_Update_States_Summary_Log_Max_Fields>;
  min?: Maybe<Test_Kits_Update_States_Summary_Log_Min_Fields>;
};


/** aggregate fields of "test_kits_update_states_summary_log" */
export type Test_Kits_Update_States_Summary_Log_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Test_Kits_Update_States_Summary_Log_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "test_kits_update_states_summary_log". All fields are combined with a logical 'AND'. */
export type Test_Kits_Update_States_Summary_Log_Bool_Exp = {
  _and?: InputMaybe<Array<Test_Kits_Update_States_Summary_Log_Bool_Exp>>;
  _not?: InputMaybe<Test_Kits_Update_States_Summary_Log_Bool_Exp>;
  _or?: InputMaybe<Array<Test_Kits_Update_States_Summary_Log_Bool_Exp>>;
  from_state?: InputMaybe<Dnavisit_Test_Statuses_Enum_Comparison_Exp>;
  internal_testing?: InputMaybe<Boolean_Comparison_Exp>;
  log_id?: InputMaybe<Uuid_Comparison_Exp>;
  status_change_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  test_id?: InputMaybe<Uuid_Comparison_Exp>;
  test_type?: InputMaybe<Test_Types_Enum_Comparison_Exp>;
  to_state?: InputMaybe<Dnavisit_Test_Statuses_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "test_kits_update_states_summary_log" */
export enum Test_Kits_Update_States_Summary_Log_Constraint {
  /** unique or primary key constraint on columns "log_id" */
  TestKitsUpdateStatesSummaryLogPkey = 'test_kits_update_states_summary_log_pkey'
}

/** input type for inserting data into table "test_kits_update_states_summary_log" */
export type Test_Kits_Update_States_Summary_Log_Insert_Input = {
  from_state?: InputMaybe<Dnavisit_Test_Statuses_Enum>;
  internal_testing?: InputMaybe<Scalars['Boolean']>;
  log_id?: InputMaybe<Scalars['uuid']>;
  status_change_date?: InputMaybe<Scalars['timestamptz']>;
  test_id?: InputMaybe<Scalars['uuid']>;
  test_type?: InputMaybe<Test_Types_Enum>;
  to_state?: InputMaybe<Dnavisit_Test_Statuses_Enum>;
};

/** aggregate max on columns */
export type Test_Kits_Update_States_Summary_Log_Max_Fields = {
  __typename?: 'test_kits_update_states_summary_log_max_fields';
  log_id?: Maybe<Scalars['uuid']>;
  status_change_date?: Maybe<Scalars['timestamptz']>;
  test_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Test_Kits_Update_States_Summary_Log_Min_Fields = {
  __typename?: 'test_kits_update_states_summary_log_min_fields';
  log_id?: Maybe<Scalars['uuid']>;
  status_change_date?: Maybe<Scalars['timestamptz']>;
  test_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "test_kits_update_states_summary_log" */
export type Test_Kits_Update_States_Summary_Log_Mutation_Response = {
  __typename?: 'test_kits_update_states_summary_log_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Test_Kits_Update_States_Summary_Log>;
};

/** on_conflict condition type for table "test_kits_update_states_summary_log" */
export type Test_Kits_Update_States_Summary_Log_On_Conflict = {
  constraint: Test_Kits_Update_States_Summary_Log_Constraint;
  update_columns?: Array<Test_Kits_Update_States_Summary_Log_Update_Column>;
  where?: InputMaybe<Test_Kits_Update_States_Summary_Log_Bool_Exp>;
};

/** Ordering options when selecting data from "test_kits_update_states_summary_log". */
export type Test_Kits_Update_States_Summary_Log_Order_By = {
  from_state?: InputMaybe<Order_By>;
  internal_testing?: InputMaybe<Order_By>;
  log_id?: InputMaybe<Order_By>;
  status_change_date?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
  test_type?: InputMaybe<Order_By>;
  to_state?: InputMaybe<Order_By>;
};

/** primary key columns input for table: test_kits_update_states_summary_log */
export type Test_Kits_Update_States_Summary_Log_Pk_Columns_Input = {
  log_id: Scalars['uuid'];
};

/** select columns of table "test_kits_update_states_summary_log" */
export enum Test_Kits_Update_States_Summary_Log_Select_Column {
  /** column name */
  FromState = 'from_state',
  /** column name */
  InternalTesting = 'internal_testing',
  /** column name */
  LogId = 'log_id',
  /** column name */
  StatusChangeDate = 'status_change_date',
  /** column name */
  TestId = 'test_id',
  /** column name */
  TestType = 'test_type',
  /** column name */
  ToState = 'to_state'
}

/** input type for updating data in table "test_kits_update_states_summary_log" */
export type Test_Kits_Update_States_Summary_Log_Set_Input = {
  from_state?: InputMaybe<Dnavisit_Test_Statuses_Enum>;
  internal_testing?: InputMaybe<Scalars['Boolean']>;
  log_id?: InputMaybe<Scalars['uuid']>;
  status_change_date?: InputMaybe<Scalars['timestamptz']>;
  test_id?: InputMaybe<Scalars['uuid']>;
  test_type?: InputMaybe<Test_Types_Enum>;
  to_state?: InputMaybe<Dnavisit_Test_Statuses_Enum>;
};

/** Streaming cursor of the table "test_kits_update_states_summary_log" */
export type Test_Kits_Update_States_Summary_Log_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Test_Kits_Update_States_Summary_Log_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Test_Kits_Update_States_Summary_Log_Stream_Cursor_Value_Input = {
  from_state?: InputMaybe<Dnavisit_Test_Statuses_Enum>;
  internal_testing?: InputMaybe<Scalars['Boolean']>;
  log_id?: InputMaybe<Scalars['uuid']>;
  status_change_date?: InputMaybe<Scalars['timestamptz']>;
  test_id?: InputMaybe<Scalars['uuid']>;
  test_type?: InputMaybe<Test_Types_Enum>;
  to_state?: InputMaybe<Dnavisit_Test_Statuses_Enum>;
};

/** update columns of table "test_kits_update_states_summary_log" */
export enum Test_Kits_Update_States_Summary_Log_Update_Column {
  /** column name */
  FromState = 'from_state',
  /** column name */
  InternalTesting = 'internal_testing',
  /** column name */
  LogId = 'log_id',
  /** column name */
  StatusChangeDate = 'status_change_date',
  /** column name */
  TestId = 'test_id',
  /** column name */
  TestType = 'test_type',
  /** column name */
  ToState = 'to_state'
}

export type Test_Kits_Update_States_Summary_Log_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Test_Kits_Update_States_Summary_Log_Set_Input>;
  /** filter the rows which have to be updated */
  where: Test_Kits_Update_States_Summary_Log_Bool_Exp;
};

/** columns and relationships of "test_types" */
export type Test_Types = {
  __typename?: 'test_types';
  comment?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "test_types" */
export type Test_Types_Aggregate = {
  __typename?: 'test_types_aggregate';
  aggregate?: Maybe<Test_Types_Aggregate_Fields>;
  nodes: Array<Test_Types>;
};

/** aggregate fields of "test_types" */
export type Test_Types_Aggregate_Fields = {
  __typename?: 'test_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Test_Types_Max_Fields>;
  min?: Maybe<Test_Types_Min_Fields>;
};


/** aggregate fields of "test_types" */
export type Test_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Test_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "test_types". All fields are combined with a logical 'AND'. */
export type Test_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Test_Types_Bool_Exp>>;
  _not?: InputMaybe<Test_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Test_Types_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "test_types" */
export enum Test_Types_Constraint {
  /** unique or primary key constraint on columns "value" */
  TestTypesPkey = 'test_types_pkey'
}

export enum Test_Types_Enum {
  DnaSkin = 'DNA_SKIN',
  DnaVitamin = 'DNA_VITAMIN',
  Skintelligent = 'SKINTELLIGENT',
  ZrtHeavyMetals = 'ZRT_HEAVY_METALS',
  ZrtHormone = 'ZRT_HORMONE',
  ZrtInflammatory = 'ZRT_INFLAMMATORY',
  ZrtNeurotransmitters = 'ZRT_NEUROTRANSMITTERS'
}

/** Boolean expression to compare columns of type "test_types_enum". All fields are combined with logical 'AND'. */
export type Test_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Test_Types_Enum>;
  _in?: InputMaybe<Array<Test_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Test_Types_Enum>;
  _nin?: InputMaybe<Array<Test_Types_Enum>>;
};

/** input type for inserting data into table "test_types" */
export type Test_Types_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Test_Types_Max_Fields = {
  __typename?: 'test_types_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Test_Types_Min_Fields = {
  __typename?: 'test_types_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "test_types" */
export type Test_Types_Mutation_Response = {
  __typename?: 'test_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Test_Types>;
};

/** on_conflict condition type for table "test_types" */
export type Test_Types_On_Conflict = {
  constraint: Test_Types_Constraint;
  update_columns?: Array<Test_Types_Update_Column>;
  where?: InputMaybe<Test_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "test_types". */
export type Test_Types_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: test_types */
export type Test_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "test_types" */
export enum Test_Types_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "test_types" */
export type Test_Types_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "test_types" */
export type Test_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Test_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Test_Types_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "test_types" */
export enum Test_Types_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Test_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Test_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Test_Types_Bool_Exp;
};

/** columns and relationships of "tests" */
export type Tests = {
  __typename?: 'tests';
  created_at: Scalars['timestamptz'];
  questionaire_response_id?: Maybe<Scalars['bigint']>;
  state: Scalars['String'];
  swab_code_id?: Maybe<Scalars['bigint']>;
  test_id: Scalars['uuid'];
  test_type_id: Scalars['Int'];
  /** An array relationship */
  tests_dnavisit_tests: Array<Dnavisit_Tests>;
  /** An aggregate relationship */
  tests_dnavisit_tests_aggregate: Dnavisit_Tests_Aggregate;
  /** An object relationship */
  tests_swab_code?: Maybe<Swab_Codes>;
  /** An object relationship */
  tests_test_definition: Test_Definitions;
  /** An object relationship */
  tests_user: Users;
  /** An array relationship */
  tests_zrt_tests: Array<Zrt_Tests>;
  /** An aggregate relationship */
  tests_zrt_tests_aggregate: Zrt_Tests_Aggregate;
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['uuid'];
};


/** columns and relationships of "tests" */
export type TestsTests_Dnavisit_TestsArgs = {
  distinct_on?: InputMaybe<Array<Dnavisit_Tests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dnavisit_Tests_Order_By>>;
  where?: InputMaybe<Dnavisit_Tests_Bool_Exp>;
};


/** columns and relationships of "tests" */
export type TestsTests_Dnavisit_Tests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dnavisit_Tests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dnavisit_Tests_Order_By>>;
  where?: InputMaybe<Dnavisit_Tests_Bool_Exp>;
};


/** columns and relationships of "tests" */
export type TestsTests_Zrt_TestsArgs = {
  distinct_on?: InputMaybe<Array<Zrt_Tests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zrt_Tests_Order_By>>;
  where?: InputMaybe<Zrt_Tests_Bool_Exp>;
};


/** columns and relationships of "tests" */
export type TestsTests_Zrt_Tests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zrt_Tests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zrt_Tests_Order_By>>;
  where?: InputMaybe<Zrt_Tests_Bool_Exp>;
};

/** aggregated selection of "tests" */
export type Tests_Aggregate = {
  __typename?: 'tests_aggregate';
  aggregate?: Maybe<Tests_Aggregate_Fields>;
  nodes: Array<Tests>;
};

export type Tests_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tests_Aggregate_Bool_Exp_Count>;
};

export type Tests_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tests_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tests_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tests" */
export type Tests_Aggregate_Fields = {
  __typename?: 'tests_aggregate_fields';
  avg?: Maybe<Tests_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tests_Max_Fields>;
  min?: Maybe<Tests_Min_Fields>;
  stddev?: Maybe<Tests_Stddev_Fields>;
  stddev_pop?: Maybe<Tests_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tests_Stddev_Samp_Fields>;
  sum?: Maybe<Tests_Sum_Fields>;
  var_pop?: Maybe<Tests_Var_Pop_Fields>;
  var_samp?: Maybe<Tests_Var_Samp_Fields>;
  variance?: Maybe<Tests_Variance_Fields>;
};


/** aggregate fields of "tests" */
export type Tests_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tests_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tests" */
export type Tests_Aggregate_Order_By = {
  avg?: InputMaybe<Tests_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tests_Max_Order_By>;
  min?: InputMaybe<Tests_Min_Order_By>;
  stddev?: InputMaybe<Tests_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tests_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tests_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tests_Sum_Order_By>;
  var_pop?: InputMaybe<Tests_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tests_Var_Samp_Order_By>;
  variance?: InputMaybe<Tests_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "tests" */
export type Tests_Arr_Rel_Insert_Input = {
  data: Array<Tests_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tests_On_Conflict>;
};

/** aggregate avg on columns */
export type Tests_Avg_Fields = {
  __typename?: 'tests_avg_fields';
  questionaire_response_id?: Maybe<Scalars['Float']>;
  swab_code_id?: Maybe<Scalars['Float']>;
  test_type_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "tests" */
export type Tests_Avg_Order_By = {
  questionaire_response_id?: InputMaybe<Order_By>;
  swab_code_id?: InputMaybe<Order_By>;
  test_type_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tests". All fields are combined with a logical 'AND'. */
export type Tests_Bool_Exp = {
  _and?: InputMaybe<Array<Tests_Bool_Exp>>;
  _not?: InputMaybe<Tests_Bool_Exp>;
  _or?: InputMaybe<Array<Tests_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  questionaire_response_id?: InputMaybe<Bigint_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  swab_code_id?: InputMaybe<Bigint_Comparison_Exp>;
  test_id?: InputMaybe<Uuid_Comparison_Exp>;
  test_type_id?: InputMaybe<Int_Comparison_Exp>;
  tests_dnavisit_tests?: InputMaybe<Dnavisit_Tests_Bool_Exp>;
  tests_dnavisit_tests_aggregate?: InputMaybe<Dnavisit_Tests_Aggregate_Bool_Exp>;
  tests_swab_code?: InputMaybe<Swab_Codes_Bool_Exp>;
  tests_test_definition?: InputMaybe<Test_Definitions_Bool_Exp>;
  tests_user?: InputMaybe<Users_Bool_Exp>;
  tests_zrt_tests?: InputMaybe<Zrt_Tests_Bool_Exp>;
  tests_zrt_tests_aggregate?: InputMaybe<Zrt_Tests_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "tests" */
export enum Tests_Constraint {
  /** unique or primary key constraint on columns "test_id" */
  TestsPkey = 'tests_pkey',
  /** unique or primary key constraint on columns "swab_code_id" */
  TestsSwabCodeIdKey = 'tests_swab_code_id_key'
}

/** input type for incrementing numeric columns in table "tests" */
export type Tests_Inc_Input = {
  questionaire_response_id?: InputMaybe<Scalars['bigint']>;
  swab_code_id?: InputMaybe<Scalars['bigint']>;
  test_type_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "tests" */
export type Tests_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  questionaire_response_id?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Scalars['String']>;
  swab_code_id?: InputMaybe<Scalars['bigint']>;
  test_id?: InputMaybe<Scalars['uuid']>;
  test_type_id?: InputMaybe<Scalars['Int']>;
  tests_dnavisit_tests?: InputMaybe<Dnavisit_Tests_Arr_Rel_Insert_Input>;
  tests_swab_code?: InputMaybe<Swab_Codes_Obj_Rel_Insert_Input>;
  tests_test_definition?: InputMaybe<Test_Definitions_Obj_Rel_Insert_Input>;
  tests_user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  tests_zrt_tests?: InputMaybe<Zrt_Tests_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Tests_Max_Fields = {
  __typename?: 'tests_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  questionaire_response_id?: Maybe<Scalars['bigint']>;
  state?: Maybe<Scalars['String']>;
  swab_code_id?: Maybe<Scalars['bigint']>;
  test_id?: Maybe<Scalars['uuid']>;
  test_type_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "tests" */
export type Tests_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  questionaire_response_id?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  swab_code_id?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
  test_type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tests_Min_Fields = {
  __typename?: 'tests_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  questionaire_response_id?: Maybe<Scalars['bigint']>;
  state?: Maybe<Scalars['String']>;
  swab_code_id?: Maybe<Scalars['bigint']>;
  test_id?: Maybe<Scalars['uuid']>;
  test_type_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "tests" */
export type Tests_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  questionaire_response_id?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  swab_code_id?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
  test_type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tests" */
export type Tests_Mutation_Response = {
  __typename?: 'tests_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tests>;
};

/** input type for inserting object relation for remote table "tests" */
export type Tests_Obj_Rel_Insert_Input = {
  data: Tests_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tests_On_Conflict>;
};

/** on_conflict condition type for table "tests" */
export type Tests_On_Conflict = {
  constraint: Tests_Constraint;
  update_columns?: Array<Tests_Update_Column>;
  where?: InputMaybe<Tests_Bool_Exp>;
};

/** Ordering options when selecting data from "tests". */
export type Tests_Order_By = {
  created_at?: InputMaybe<Order_By>;
  questionaire_response_id?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  swab_code_id?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
  test_type_id?: InputMaybe<Order_By>;
  tests_dnavisit_tests_aggregate?: InputMaybe<Dnavisit_Tests_Aggregate_Order_By>;
  tests_swab_code?: InputMaybe<Swab_Codes_Order_By>;
  tests_test_definition?: InputMaybe<Test_Definitions_Order_By>;
  tests_user?: InputMaybe<Users_Order_By>;
  tests_zrt_tests_aggregate?: InputMaybe<Zrt_Tests_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tests */
export type Tests_Pk_Columns_Input = {
  test_id: Scalars['uuid'];
};

/** select columns of table "tests" */
export enum Tests_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  QuestionaireResponseId = 'questionaire_response_id',
  /** column name */
  State = 'state',
  /** column name */
  SwabCodeId = 'swab_code_id',
  /** column name */
  TestId = 'test_id',
  /** column name */
  TestTypeId = 'test_type_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "tests" */
export type Tests_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  questionaire_response_id?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Scalars['String']>;
  swab_code_id?: InputMaybe<Scalars['bigint']>;
  test_id?: InputMaybe<Scalars['uuid']>;
  test_type_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Tests_Stddev_Fields = {
  __typename?: 'tests_stddev_fields';
  questionaire_response_id?: Maybe<Scalars['Float']>;
  swab_code_id?: Maybe<Scalars['Float']>;
  test_type_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "tests" */
export type Tests_Stddev_Order_By = {
  questionaire_response_id?: InputMaybe<Order_By>;
  swab_code_id?: InputMaybe<Order_By>;
  test_type_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Tests_Stddev_Pop_Fields = {
  __typename?: 'tests_stddev_pop_fields';
  questionaire_response_id?: Maybe<Scalars['Float']>;
  swab_code_id?: Maybe<Scalars['Float']>;
  test_type_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "tests" */
export type Tests_Stddev_Pop_Order_By = {
  questionaire_response_id?: InputMaybe<Order_By>;
  swab_code_id?: InputMaybe<Order_By>;
  test_type_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Tests_Stddev_Samp_Fields = {
  __typename?: 'tests_stddev_samp_fields';
  questionaire_response_id?: Maybe<Scalars['Float']>;
  swab_code_id?: Maybe<Scalars['Float']>;
  test_type_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "tests" */
export type Tests_Stddev_Samp_Order_By = {
  questionaire_response_id?: InputMaybe<Order_By>;
  swab_code_id?: InputMaybe<Order_By>;
  test_type_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "tests" */
export type Tests_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tests_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tests_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  questionaire_response_id?: InputMaybe<Scalars['bigint']>;
  state?: InputMaybe<Scalars['String']>;
  swab_code_id?: InputMaybe<Scalars['bigint']>;
  test_id?: InputMaybe<Scalars['uuid']>;
  test_type_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Tests_Sum_Fields = {
  __typename?: 'tests_sum_fields';
  questionaire_response_id?: Maybe<Scalars['bigint']>;
  swab_code_id?: Maybe<Scalars['bigint']>;
  test_type_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "tests" */
export type Tests_Sum_Order_By = {
  questionaire_response_id?: InputMaybe<Order_By>;
  swab_code_id?: InputMaybe<Order_By>;
  test_type_id?: InputMaybe<Order_By>;
};

/** update columns of table "tests" */
export enum Tests_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  QuestionaireResponseId = 'questionaire_response_id',
  /** column name */
  State = 'state',
  /** column name */
  SwabCodeId = 'swab_code_id',
  /** column name */
  TestId = 'test_id',
  /** column name */
  TestTypeId = 'test_type_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Tests_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tests_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tests_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tests_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Tests_Var_Pop_Fields = {
  __typename?: 'tests_var_pop_fields';
  questionaire_response_id?: Maybe<Scalars['Float']>;
  swab_code_id?: Maybe<Scalars['Float']>;
  test_type_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "tests" */
export type Tests_Var_Pop_Order_By = {
  questionaire_response_id?: InputMaybe<Order_By>;
  swab_code_id?: InputMaybe<Order_By>;
  test_type_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Tests_Var_Samp_Fields = {
  __typename?: 'tests_var_samp_fields';
  questionaire_response_id?: Maybe<Scalars['Float']>;
  swab_code_id?: Maybe<Scalars['Float']>;
  test_type_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "tests" */
export type Tests_Var_Samp_Order_By = {
  questionaire_response_id?: InputMaybe<Order_By>;
  swab_code_id?: InputMaybe<Order_By>;
  test_type_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Tests_Variance_Fields = {
  __typename?: 'tests_variance_fields';
  questionaire_response_id?: Maybe<Scalars['Float']>;
  swab_code_id?: Maybe<Scalars['Float']>;
  test_type_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "tests" */
export type Tests_Variance_Order_By = {
  questionaire_response_id?: InputMaybe<Order_By>;
  swab_code_id?: InputMaybe<Order_By>;
  test_type_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "text_addresses" */
export type Text_Addresses = {
  __typename?: 'text_addresses';
  add1: Scalars['String'];
  add2?: Maybe<Scalars['String']>;
  address_id: Scalars['uuid'];
  city: Scalars['String'];
  country: Scalars['String'];
  phone: Scalars['String'];
  post_code: Scalars['String'];
  province: Scalars['String'];
  state: Scalars['String'];
};

/** aggregated selection of "text_addresses" */
export type Text_Addresses_Aggregate = {
  __typename?: 'text_addresses_aggregate';
  aggregate?: Maybe<Text_Addresses_Aggregate_Fields>;
  nodes: Array<Text_Addresses>;
};

/** aggregate fields of "text_addresses" */
export type Text_Addresses_Aggregate_Fields = {
  __typename?: 'text_addresses_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Text_Addresses_Max_Fields>;
  min?: Maybe<Text_Addresses_Min_Fields>;
};


/** aggregate fields of "text_addresses" */
export type Text_Addresses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Text_Addresses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "text_addresses". All fields are combined with a logical 'AND'. */
export type Text_Addresses_Bool_Exp = {
  _and?: InputMaybe<Array<Text_Addresses_Bool_Exp>>;
  _not?: InputMaybe<Text_Addresses_Bool_Exp>;
  _or?: InputMaybe<Array<Text_Addresses_Bool_Exp>>;
  add1?: InputMaybe<String_Comparison_Exp>;
  add2?: InputMaybe<String_Comparison_Exp>;
  address_id?: InputMaybe<Uuid_Comparison_Exp>;
  city?: InputMaybe<String_Comparison_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  post_code?: InputMaybe<String_Comparison_Exp>;
  province?: InputMaybe<String_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "text_addresses" */
export enum Text_Addresses_Constraint {
  /** unique or primary key constraint on columns "address_id" */
  TextAddressesPkey = 'text_addresses_pkey'
}

/** input type for inserting data into table "text_addresses" */
export type Text_Addresses_Insert_Input = {
  add1?: InputMaybe<Scalars['String']>;
  add2?: InputMaybe<Scalars['String']>;
  address_id?: InputMaybe<Scalars['uuid']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  post_code?: InputMaybe<Scalars['String']>;
  province?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Text_Addresses_Max_Fields = {
  __typename?: 'text_addresses_max_fields';
  add1?: Maybe<Scalars['String']>;
  add2?: Maybe<Scalars['String']>;
  address_id?: Maybe<Scalars['uuid']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  post_code?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Text_Addresses_Min_Fields = {
  __typename?: 'text_addresses_min_fields';
  add1?: Maybe<Scalars['String']>;
  add2?: Maybe<Scalars['String']>;
  address_id?: Maybe<Scalars['uuid']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  post_code?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "text_addresses" */
export type Text_Addresses_Mutation_Response = {
  __typename?: 'text_addresses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Text_Addresses>;
};

/** on_conflict condition type for table "text_addresses" */
export type Text_Addresses_On_Conflict = {
  constraint: Text_Addresses_Constraint;
  update_columns?: Array<Text_Addresses_Update_Column>;
  where?: InputMaybe<Text_Addresses_Bool_Exp>;
};

/** Ordering options when selecting data from "text_addresses". */
export type Text_Addresses_Order_By = {
  add1?: InputMaybe<Order_By>;
  add2?: InputMaybe<Order_By>;
  address_id?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  post_code?: InputMaybe<Order_By>;
  province?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
};

/** primary key columns input for table: text_addresses */
export type Text_Addresses_Pk_Columns_Input = {
  address_id: Scalars['uuid'];
};

/** select columns of table "text_addresses" */
export enum Text_Addresses_Select_Column {
  /** column name */
  Add1 = 'add1',
  /** column name */
  Add2 = 'add2',
  /** column name */
  AddressId = 'address_id',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  Phone = 'phone',
  /** column name */
  PostCode = 'post_code',
  /** column name */
  Province = 'province',
  /** column name */
  State = 'state'
}

/** input type for updating data in table "text_addresses" */
export type Text_Addresses_Set_Input = {
  add1?: InputMaybe<Scalars['String']>;
  add2?: InputMaybe<Scalars['String']>;
  address_id?: InputMaybe<Scalars['uuid']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  post_code?: InputMaybe<Scalars['String']>;
  province?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "text_addresses" */
export type Text_Addresses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Text_Addresses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Text_Addresses_Stream_Cursor_Value_Input = {
  add1?: InputMaybe<Scalars['String']>;
  add2?: InputMaybe<Scalars['String']>;
  address_id?: InputMaybe<Scalars['uuid']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  post_code?: InputMaybe<Scalars['String']>;
  province?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

/** update columns of table "text_addresses" */
export enum Text_Addresses_Update_Column {
  /** column name */
  Add1 = 'add1',
  /** column name */
  Add2 = 'add2',
  /** column name */
  AddressId = 'address_id',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  Phone = 'phone',
  /** column name */
  PostCode = 'post_code',
  /** column name */
  Province = 'province',
  /** column name */
  State = 'state'
}

export type Text_Addresses_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Text_Addresses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Text_Addresses_Bool_Exp;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']>;
  _gt?: InputMaybe<Scalars['timestamp']>;
  _gte?: InputMaybe<Scalars['timestamp']>;
  _in?: InputMaybe<Array<Scalars['timestamp']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamp']>;
  _lte?: InputMaybe<Scalars['timestamp']>;
  _neq?: InputMaybe<Scalars['timestamp']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** Boolean expression to compare columns of type "timetz". All fields are combined with logical 'AND'. */
export type Timetz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timetz']>;
  _gt?: InputMaybe<Scalars['timetz']>;
  _gte?: InputMaybe<Scalars['timetz']>;
  _in?: InputMaybe<Array<Scalars['timetz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timetz']>;
  _lte?: InputMaybe<Scalars['timetz']>;
  _neq?: InputMaybe<Scalars['timetz']>;
  _nin?: InputMaybe<Array<Scalars['timetz']>>;
};

/** Unauthenticated enabling of questions to selected questionnaire */
export type Unauthenticated_Questionnaire_Questions = {
  __typename?: 'unauthenticated_questionnaire_questions';
  id: Scalars['bigint'];
  unauthenticated_question_id: Scalars['Int'];
  unauthenticated_questionnaire_id: Scalars['Int'];
  /** An object relationship */
  unauthenticated_questionnaire_questions_unauthenticated_question: Unauthenticated_Questions;
  /** An object relationship */
  unauthenticated_questionnaire_questions_unauthenticated_questionnaire: Unauthenticated_Questionnaires;
};

/** aggregated selection of "unauthenticated_questionnaire_questions" */
export type Unauthenticated_Questionnaire_Questions_Aggregate = {
  __typename?: 'unauthenticated_questionnaire_questions_aggregate';
  aggregate?: Maybe<Unauthenticated_Questionnaire_Questions_Aggregate_Fields>;
  nodes: Array<Unauthenticated_Questionnaire_Questions>;
};

export type Unauthenticated_Questionnaire_Questions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Unauthenticated_Questionnaire_Questions_Aggregate_Bool_Exp_Count>;
};

export type Unauthenticated_Questionnaire_Questions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Unauthenticated_Questionnaire_Questions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Unauthenticated_Questionnaire_Questions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "unauthenticated_questionnaire_questions" */
export type Unauthenticated_Questionnaire_Questions_Aggregate_Fields = {
  __typename?: 'unauthenticated_questionnaire_questions_aggregate_fields';
  avg?: Maybe<Unauthenticated_Questionnaire_Questions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Unauthenticated_Questionnaire_Questions_Max_Fields>;
  min?: Maybe<Unauthenticated_Questionnaire_Questions_Min_Fields>;
  stddev?: Maybe<Unauthenticated_Questionnaire_Questions_Stddev_Fields>;
  stddev_pop?: Maybe<Unauthenticated_Questionnaire_Questions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Unauthenticated_Questionnaire_Questions_Stddev_Samp_Fields>;
  sum?: Maybe<Unauthenticated_Questionnaire_Questions_Sum_Fields>;
  var_pop?: Maybe<Unauthenticated_Questionnaire_Questions_Var_Pop_Fields>;
  var_samp?: Maybe<Unauthenticated_Questionnaire_Questions_Var_Samp_Fields>;
  variance?: Maybe<Unauthenticated_Questionnaire_Questions_Variance_Fields>;
};


/** aggregate fields of "unauthenticated_questionnaire_questions" */
export type Unauthenticated_Questionnaire_Questions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Unauthenticated_Questionnaire_Questions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "unauthenticated_questionnaire_questions" */
export type Unauthenticated_Questionnaire_Questions_Aggregate_Order_By = {
  avg?: InputMaybe<Unauthenticated_Questionnaire_Questions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Unauthenticated_Questionnaire_Questions_Max_Order_By>;
  min?: InputMaybe<Unauthenticated_Questionnaire_Questions_Min_Order_By>;
  stddev?: InputMaybe<Unauthenticated_Questionnaire_Questions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Unauthenticated_Questionnaire_Questions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Unauthenticated_Questionnaire_Questions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Unauthenticated_Questionnaire_Questions_Sum_Order_By>;
  var_pop?: InputMaybe<Unauthenticated_Questionnaire_Questions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Unauthenticated_Questionnaire_Questions_Var_Samp_Order_By>;
  variance?: InputMaybe<Unauthenticated_Questionnaire_Questions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "unauthenticated_questionnaire_questions" */
export type Unauthenticated_Questionnaire_Questions_Arr_Rel_Insert_Input = {
  data: Array<Unauthenticated_Questionnaire_Questions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Unauthenticated_Questionnaire_Questions_On_Conflict>;
};

/** aggregate avg on columns */
export type Unauthenticated_Questionnaire_Questions_Avg_Fields = {
  __typename?: 'unauthenticated_questionnaire_questions_avg_fields';
  id?: Maybe<Scalars['Float']>;
  unauthenticated_question_id?: Maybe<Scalars['Float']>;
  unauthenticated_questionnaire_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "unauthenticated_questionnaire_questions" */
export type Unauthenticated_Questionnaire_Questions_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  unauthenticated_question_id?: InputMaybe<Order_By>;
  unauthenticated_questionnaire_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "unauthenticated_questionnaire_questions". All fields are combined with a logical 'AND'. */
export type Unauthenticated_Questionnaire_Questions_Bool_Exp = {
  _and?: InputMaybe<Array<Unauthenticated_Questionnaire_Questions_Bool_Exp>>;
  _not?: InputMaybe<Unauthenticated_Questionnaire_Questions_Bool_Exp>;
  _or?: InputMaybe<Array<Unauthenticated_Questionnaire_Questions_Bool_Exp>>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  unauthenticated_question_id?: InputMaybe<Int_Comparison_Exp>;
  unauthenticated_questionnaire_id?: InputMaybe<Int_Comparison_Exp>;
  unauthenticated_questionnaire_questions_unauthenticated_question?: InputMaybe<Unauthenticated_Questions_Bool_Exp>;
  unauthenticated_questionnaire_questions_unauthenticated_questionnaire?: InputMaybe<Unauthenticated_Questionnaires_Bool_Exp>;
};

/** unique or primary key constraints on table "unauthenticated_questionnaire_questions" */
export enum Unauthenticated_Questionnaire_Questions_Constraint {
  /** unique or primary key constraint on columns "id" */
  UnauthenticatedQuestionnaireQuestionsIdSeq = 'unauthenticated_questionnaire_questions_id_seq',
  /** unique or primary key constraint on columns "id" */
  UnauthenticatedQuestionnaireQuestionsPkey = 'unauthenticated_questionnaire_questions_pkey',
  /** unique or primary key constraint on columns "unauthenticated_question_id", "unauthenticated_questionnaire_id" */
  UnauthenticatedQuestionnaireQuestionsUnauthenticatedQuestio = 'unauthenticated_questionnaire_questions_unauthenticated_questio'
}

/** input type for incrementing numeric columns in table "unauthenticated_questionnaire_questions" */
export type Unauthenticated_Questionnaire_Questions_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  unauthenticated_question_id?: InputMaybe<Scalars['Int']>;
  unauthenticated_questionnaire_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "unauthenticated_questionnaire_questions" */
export type Unauthenticated_Questionnaire_Questions_Insert_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  unauthenticated_question_id?: InputMaybe<Scalars['Int']>;
  unauthenticated_questionnaire_id?: InputMaybe<Scalars['Int']>;
  unauthenticated_questionnaire_questions_unauthenticated_question?: InputMaybe<Unauthenticated_Questions_Obj_Rel_Insert_Input>;
  unauthenticated_questionnaire_questions_unauthenticated_questionnaire?: InputMaybe<Unauthenticated_Questionnaires_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Unauthenticated_Questionnaire_Questions_Max_Fields = {
  __typename?: 'unauthenticated_questionnaire_questions_max_fields';
  id?: Maybe<Scalars['bigint']>;
  unauthenticated_question_id?: Maybe<Scalars['Int']>;
  unauthenticated_questionnaire_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "unauthenticated_questionnaire_questions" */
export type Unauthenticated_Questionnaire_Questions_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  unauthenticated_question_id?: InputMaybe<Order_By>;
  unauthenticated_questionnaire_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Unauthenticated_Questionnaire_Questions_Min_Fields = {
  __typename?: 'unauthenticated_questionnaire_questions_min_fields';
  id?: Maybe<Scalars['bigint']>;
  unauthenticated_question_id?: Maybe<Scalars['Int']>;
  unauthenticated_questionnaire_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "unauthenticated_questionnaire_questions" */
export type Unauthenticated_Questionnaire_Questions_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  unauthenticated_question_id?: InputMaybe<Order_By>;
  unauthenticated_questionnaire_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "unauthenticated_questionnaire_questions" */
export type Unauthenticated_Questionnaire_Questions_Mutation_Response = {
  __typename?: 'unauthenticated_questionnaire_questions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Unauthenticated_Questionnaire_Questions>;
};

/** on_conflict condition type for table "unauthenticated_questionnaire_questions" */
export type Unauthenticated_Questionnaire_Questions_On_Conflict = {
  constraint: Unauthenticated_Questionnaire_Questions_Constraint;
  update_columns?: Array<Unauthenticated_Questionnaire_Questions_Update_Column>;
  where?: InputMaybe<Unauthenticated_Questionnaire_Questions_Bool_Exp>;
};

/** Ordering options when selecting data from "unauthenticated_questionnaire_questions". */
export type Unauthenticated_Questionnaire_Questions_Order_By = {
  id?: InputMaybe<Order_By>;
  unauthenticated_question_id?: InputMaybe<Order_By>;
  unauthenticated_questionnaire_id?: InputMaybe<Order_By>;
  unauthenticated_questionnaire_questions_unauthenticated_question?: InputMaybe<Unauthenticated_Questions_Order_By>;
  unauthenticated_questionnaire_questions_unauthenticated_questionnaire?: InputMaybe<Unauthenticated_Questionnaires_Order_By>;
};

/** primary key columns input for table: unauthenticated_questionnaire_questions */
export type Unauthenticated_Questionnaire_Questions_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "unauthenticated_questionnaire_questions" */
export enum Unauthenticated_Questionnaire_Questions_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  UnauthenticatedQuestionId = 'unauthenticated_question_id',
  /** column name */
  UnauthenticatedQuestionnaireId = 'unauthenticated_questionnaire_id'
}

/** input type for updating data in table "unauthenticated_questionnaire_questions" */
export type Unauthenticated_Questionnaire_Questions_Set_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  unauthenticated_question_id?: InputMaybe<Scalars['Int']>;
  unauthenticated_questionnaire_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Unauthenticated_Questionnaire_Questions_Stddev_Fields = {
  __typename?: 'unauthenticated_questionnaire_questions_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  unauthenticated_question_id?: Maybe<Scalars['Float']>;
  unauthenticated_questionnaire_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "unauthenticated_questionnaire_questions" */
export type Unauthenticated_Questionnaire_Questions_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  unauthenticated_question_id?: InputMaybe<Order_By>;
  unauthenticated_questionnaire_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Unauthenticated_Questionnaire_Questions_Stddev_Pop_Fields = {
  __typename?: 'unauthenticated_questionnaire_questions_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  unauthenticated_question_id?: Maybe<Scalars['Float']>;
  unauthenticated_questionnaire_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "unauthenticated_questionnaire_questions" */
export type Unauthenticated_Questionnaire_Questions_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  unauthenticated_question_id?: InputMaybe<Order_By>;
  unauthenticated_questionnaire_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Unauthenticated_Questionnaire_Questions_Stddev_Samp_Fields = {
  __typename?: 'unauthenticated_questionnaire_questions_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  unauthenticated_question_id?: Maybe<Scalars['Float']>;
  unauthenticated_questionnaire_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "unauthenticated_questionnaire_questions" */
export type Unauthenticated_Questionnaire_Questions_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  unauthenticated_question_id?: InputMaybe<Order_By>;
  unauthenticated_questionnaire_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "unauthenticated_questionnaire_questions" */
export type Unauthenticated_Questionnaire_Questions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Unauthenticated_Questionnaire_Questions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Unauthenticated_Questionnaire_Questions_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  unauthenticated_question_id?: InputMaybe<Scalars['Int']>;
  unauthenticated_questionnaire_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Unauthenticated_Questionnaire_Questions_Sum_Fields = {
  __typename?: 'unauthenticated_questionnaire_questions_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  unauthenticated_question_id?: Maybe<Scalars['Int']>;
  unauthenticated_questionnaire_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "unauthenticated_questionnaire_questions" */
export type Unauthenticated_Questionnaire_Questions_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  unauthenticated_question_id?: InputMaybe<Order_By>;
  unauthenticated_questionnaire_id?: InputMaybe<Order_By>;
};

/** update columns of table "unauthenticated_questionnaire_questions" */
export enum Unauthenticated_Questionnaire_Questions_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  UnauthenticatedQuestionId = 'unauthenticated_question_id',
  /** column name */
  UnauthenticatedQuestionnaireId = 'unauthenticated_questionnaire_id'
}

export type Unauthenticated_Questionnaire_Questions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Unauthenticated_Questionnaire_Questions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Unauthenticated_Questionnaire_Questions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Unauthenticated_Questionnaire_Questions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Unauthenticated_Questionnaire_Questions_Var_Pop_Fields = {
  __typename?: 'unauthenticated_questionnaire_questions_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  unauthenticated_question_id?: Maybe<Scalars['Float']>;
  unauthenticated_questionnaire_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "unauthenticated_questionnaire_questions" */
export type Unauthenticated_Questionnaire_Questions_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  unauthenticated_question_id?: InputMaybe<Order_By>;
  unauthenticated_questionnaire_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Unauthenticated_Questionnaire_Questions_Var_Samp_Fields = {
  __typename?: 'unauthenticated_questionnaire_questions_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  unauthenticated_question_id?: Maybe<Scalars['Float']>;
  unauthenticated_questionnaire_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "unauthenticated_questionnaire_questions" */
export type Unauthenticated_Questionnaire_Questions_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  unauthenticated_question_id?: InputMaybe<Order_By>;
  unauthenticated_questionnaire_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Unauthenticated_Questionnaire_Questions_Variance_Fields = {
  __typename?: 'unauthenticated_questionnaire_questions_variance_fields';
  id?: Maybe<Scalars['Float']>;
  unauthenticated_question_id?: Maybe<Scalars['Float']>;
  unauthenticated_questionnaire_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "unauthenticated_questionnaire_questions" */
export type Unauthenticated_Questionnaire_Questions_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  unauthenticated_question_id?: InputMaybe<Order_By>;
  unauthenticated_questionnaire_id?: InputMaybe<Order_By>;
};

/** Questionnaires for public access */
export type Unauthenticated_Questionnaires = {
  __typename?: 'unauthenticated_questionnaires';
  data: Scalars['jsonb'];
  id: Scalars['Int'];
  is_active: Scalars['Boolean'];
  notes?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  /** An array relationship */
  unauthenticated_questionnaire_questions: Array<Unauthenticated_Questionnaire_Questions>;
  /** An aggregate relationship */
  unauthenticated_questionnaire_questions_aggregate: Unauthenticated_Questionnaire_Questions_Aggregate;
};


/** Questionnaires for public access */
export type Unauthenticated_QuestionnairesDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** Questionnaires for public access */
export type Unauthenticated_QuestionnairesUnauthenticated_Questionnaire_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Unauthenticated_Questionnaire_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Unauthenticated_Questionnaire_Questions_Order_By>>;
  where?: InputMaybe<Unauthenticated_Questionnaire_Questions_Bool_Exp>;
};


/** Questionnaires for public access */
export type Unauthenticated_QuestionnairesUnauthenticated_Questionnaire_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Unauthenticated_Questionnaire_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Unauthenticated_Questionnaire_Questions_Order_By>>;
  where?: InputMaybe<Unauthenticated_Questionnaire_Questions_Bool_Exp>;
};

/** aggregated selection of "unauthenticated_questionnaires" */
export type Unauthenticated_Questionnaires_Aggregate = {
  __typename?: 'unauthenticated_questionnaires_aggregate';
  aggregate?: Maybe<Unauthenticated_Questionnaires_Aggregate_Fields>;
  nodes: Array<Unauthenticated_Questionnaires>;
};

/** aggregate fields of "unauthenticated_questionnaires" */
export type Unauthenticated_Questionnaires_Aggregate_Fields = {
  __typename?: 'unauthenticated_questionnaires_aggregate_fields';
  avg?: Maybe<Unauthenticated_Questionnaires_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Unauthenticated_Questionnaires_Max_Fields>;
  min?: Maybe<Unauthenticated_Questionnaires_Min_Fields>;
  stddev?: Maybe<Unauthenticated_Questionnaires_Stddev_Fields>;
  stddev_pop?: Maybe<Unauthenticated_Questionnaires_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Unauthenticated_Questionnaires_Stddev_Samp_Fields>;
  sum?: Maybe<Unauthenticated_Questionnaires_Sum_Fields>;
  var_pop?: Maybe<Unauthenticated_Questionnaires_Var_Pop_Fields>;
  var_samp?: Maybe<Unauthenticated_Questionnaires_Var_Samp_Fields>;
  variance?: Maybe<Unauthenticated_Questionnaires_Variance_Fields>;
};


/** aggregate fields of "unauthenticated_questionnaires" */
export type Unauthenticated_Questionnaires_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Unauthenticated_Questionnaires_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Unauthenticated_Questionnaires_Append_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Unauthenticated_Questionnaires_Avg_Fields = {
  __typename?: 'unauthenticated_questionnaires_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "unauthenticated_questionnaires". All fields are combined with a logical 'AND'. */
export type Unauthenticated_Questionnaires_Bool_Exp = {
  _and?: InputMaybe<Array<Unauthenticated_Questionnaires_Bool_Exp>>;
  _not?: InputMaybe<Unauthenticated_Questionnaires_Bool_Exp>;
  _or?: InputMaybe<Array<Unauthenticated_Questionnaires_Bool_Exp>>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  subtitle?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  unauthenticated_questionnaire_questions?: InputMaybe<Unauthenticated_Questionnaire_Questions_Bool_Exp>;
  unauthenticated_questionnaire_questions_aggregate?: InputMaybe<Unauthenticated_Questionnaire_Questions_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "unauthenticated_questionnaires" */
export enum Unauthenticated_Questionnaires_Constraint {
  /** unique or primary key constraint on columns "id" */
  UnauthenticatedQuestionnairesPkey = 'unauthenticated_questionnaires_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Unauthenticated_Questionnaires_Delete_At_Path_Input = {
  data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Unauthenticated_Questionnaires_Delete_Elem_Input = {
  data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Unauthenticated_Questionnaires_Delete_Key_Input = {
  data?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "unauthenticated_questionnaires" */
export type Unauthenticated_Questionnaires_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "unauthenticated_questionnaires" */
export type Unauthenticated_Questionnaires_Insert_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  notes?: InputMaybe<Scalars['String']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  unauthenticated_questionnaire_questions?: InputMaybe<Unauthenticated_Questionnaire_Questions_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Unauthenticated_Questionnaires_Max_Fields = {
  __typename?: 'unauthenticated_questionnaires_max_fields';
  id?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Unauthenticated_Questionnaires_Min_Fields = {
  __typename?: 'unauthenticated_questionnaires_min_fields';
  id?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "unauthenticated_questionnaires" */
export type Unauthenticated_Questionnaires_Mutation_Response = {
  __typename?: 'unauthenticated_questionnaires_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Unauthenticated_Questionnaires>;
};

/** input type for inserting object relation for remote table "unauthenticated_questionnaires" */
export type Unauthenticated_Questionnaires_Obj_Rel_Insert_Input = {
  data: Unauthenticated_Questionnaires_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Unauthenticated_Questionnaires_On_Conflict>;
};

/** on_conflict condition type for table "unauthenticated_questionnaires" */
export type Unauthenticated_Questionnaires_On_Conflict = {
  constraint: Unauthenticated_Questionnaires_Constraint;
  update_columns?: Array<Unauthenticated_Questionnaires_Update_Column>;
  where?: InputMaybe<Unauthenticated_Questionnaires_Bool_Exp>;
};

/** Ordering options when selecting data from "unauthenticated_questionnaires". */
export type Unauthenticated_Questionnaires_Order_By = {
  data?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  subtitle?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  unauthenticated_questionnaire_questions_aggregate?: InputMaybe<Unauthenticated_Questionnaire_Questions_Aggregate_Order_By>;
};

/** primary key columns input for table: unauthenticated_questionnaires */
export type Unauthenticated_Questionnaires_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Unauthenticated_Questionnaires_Prepend_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "unauthenticated_questionnaires" */
export enum Unauthenticated_Questionnaires_Select_Column {
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Notes = 'notes',
  /** column name */
  Subtitle = 'subtitle',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "unauthenticated_questionnaires" */
export type Unauthenticated_Questionnaires_Set_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  notes?: InputMaybe<Scalars['String']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Unauthenticated_Questionnaires_Stddev_Fields = {
  __typename?: 'unauthenticated_questionnaires_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Unauthenticated_Questionnaires_Stddev_Pop_Fields = {
  __typename?: 'unauthenticated_questionnaires_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Unauthenticated_Questionnaires_Stddev_Samp_Fields = {
  __typename?: 'unauthenticated_questionnaires_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "unauthenticated_questionnaires" */
export type Unauthenticated_Questionnaires_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Unauthenticated_Questionnaires_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Unauthenticated_Questionnaires_Stream_Cursor_Value_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  notes?: InputMaybe<Scalars['String']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Unauthenticated_Questionnaires_Sum_Fields = {
  __typename?: 'unauthenticated_questionnaires_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "unauthenticated_questionnaires" */
export enum Unauthenticated_Questionnaires_Update_Column {
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Notes = 'notes',
  /** column name */
  Subtitle = 'subtitle',
  /** column name */
  Title = 'title'
}

export type Unauthenticated_Questionnaires_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Unauthenticated_Questionnaires_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Unauthenticated_Questionnaires_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Unauthenticated_Questionnaires_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Unauthenticated_Questionnaires_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Unauthenticated_Questionnaires_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Unauthenticated_Questionnaires_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Unauthenticated_Questionnaires_Set_Input>;
  /** filter the rows which have to be updated */
  where: Unauthenticated_Questionnaires_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Unauthenticated_Questionnaires_Var_Pop_Fields = {
  __typename?: 'unauthenticated_questionnaires_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Unauthenticated_Questionnaires_Var_Samp_Fields = {
  __typename?: 'unauthenticated_questionnaires_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Unauthenticated_Questionnaires_Variance_Fields = {
  __typename?: 'unauthenticated_questionnaires_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Unauthenticated questions for unauthenticated questionnaires */
export type Unauthenticated_Questions = {
  __typename?: 'unauthenticated_questions';
  answers?: Maybe<Scalars['jsonb']>;
  default?: Maybe<Scalars['String']>;
  dont_know_answer?: Maybe<Scalars['String']>;
  double_text_combo_helper_texts?: Maybe<Scalars['jsonb']>;
  dropdown_combo_answers?: Maybe<Scalars['jsonb']>;
  dropdown_label_1?: Maybe<Scalars['String']>;
  dropdown_label_2?: Maybe<Scalars['String']>;
  form_section_name?: Maybe<Scalars['String']>;
  has_tooltip?: Maybe<Scalars['Boolean']>;
  helper_text_position?: Maybe<Question_Ui_Control_Helper_Text_Position_Enum>;
  id: Scalars['Int'];
  measurement_unit?: Maybe<Question_Measurement_Units_Enum>;
  /** An array relationship */
  relationship_unauthenticated_questionnaire_to_unauthenticated_questions: Array<Unauthenticated_Questionnaire_Questions>;
  /** An aggregate relationship */
  relationship_unauthenticated_questionnaire_to_unauthenticated_questions_aggregate: Unauthenticated_Questionnaire_Questions_Aggregate;
  response_property?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  tooltip_content?: Maybe<Scalars['String']>;
  type: Question_Types_Enum;
  ui_control: Question_Ui_Control_Types_Enum;
  /** An object relationship */
  unauthenticated_questions_helper_text_position?: Maybe<Question_Ui_Control_Helper_Text_Position>;
  /** An object relationship */
  unauthenticated_questions_question_measurement_unit?: Maybe<Question_Measurement_Units>;
  /** An object relationship */
  unauthenticated_questions_question_type?: Maybe<Question_Ui_Control_Types>;
  /** An object relationship */
  unauthenticated_questions_question_ui_control?: Maybe<Question_Ui_Control_Types>;
};


/** Unauthenticated questions for unauthenticated questionnaires */
export type Unauthenticated_QuestionsAnswersArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** Unauthenticated questions for unauthenticated questionnaires */
export type Unauthenticated_QuestionsDouble_Text_Combo_Helper_TextsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** Unauthenticated questions for unauthenticated questionnaires */
export type Unauthenticated_QuestionsDropdown_Combo_AnswersArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** Unauthenticated questions for unauthenticated questionnaires */
export type Unauthenticated_QuestionsRelationship_Unauthenticated_Questionnaire_To_Unauthenticated_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Unauthenticated_Questionnaire_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Unauthenticated_Questionnaire_Questions_Order_By>>;
  where?: InputMaybe<Unauthenticated_Questionnaire_Questions_Bool_Exp>;
};


/** Unauthenticated questions for unauthenticated questionnaires */
export type Unauthenticated_QuestionsRelationship_Unauthenticated_Questionnaire_To_Unauthenticated_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Unauthenticated_Questionnaire_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Unauthenticated_Questionnaire_Questions_Order_By>>;
  where?: InputMaybe<Unauthenticated_Questionnaire_Questions_Bool_Exp>;
};

/** aggregated selection of "unauthenticated_questions" */
export type Unauthenticated_Questions_Aggregate = {
  __typename?: 'unauthenticated_questions_aggregate';
  aggregate?: Maybe<Unauthenticated_Questions_Aggregate_Fields>;
  nodes: Array<Unauthenticated_Questions>;
};

/** aggregate fields of "unauthenticated_questions" */
export type Unauthenticated_Questions_Aggregate_Fields = {
  __typename?: 'unauthenticated_questions_aggregate_fields';
  avg?: Maybe<Unauthenticated_Questions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Unauthenticated_Questions_Max_Fields>;
  min?: Maybe<Unauthenticated_Questions_Min_Fields>;
  stddev?: Maybe<Unauthenticated_Questions_Stddev_Fields>;
  stddev_pop?: Maybe<Unauthenticated_Questions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Unauthenticated_Questions_Stddev_Samp_Fields>;
  sum?: Maybe<Unauthenticated_Questions_Sum_Fields>;
  var_pop?: Maybe<Unauthenticated_Questions_Var_Pop_Fields>;
  var_samp?: Maybe<Unauthenticated_Questions_Var_Samp_Fields>;
  variance?: Maybe<Unauthenticated_Questions_Variance_Fields>;
};


/** aggregate fields of "unauthenticated_questions" */
export type Unauthenticated_Questions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Unauthenticated_Questions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Unauthenticated_Questions_Append_Input = {
  answers?: InputMaybe<Scalars['jsonb']>;
  double_text_combo_helper_texts?: InputMaybe<Scalars['jsonb']>;
  dropdown_combo_answers?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Unauthenticated_Questions_Avg_Fields = {
  __typename?: 'unauthenticated_questions_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "unauthenticated_questions". All fields are combined with a logical 'AND'. */
export type Unauthenticated_Questions_Bool_Exp = {
  _and?: InputMaybe<Array<Unauthenticated_Questions_Bool_Exp>>;
  _not?: InputMaybe<Unauthenticated_Questions_Bool_Exp>;
  _or?: InputMaybe<Array<Unauthenticated_Questions_Bool_Exp>>;
  answers?: InputMaybe<Jsonb_Comparison_Exp>;
  default?: InputMaybe<String_Comparison_Exp>;
  dont_know_answer?: InputMaybe<String_Comparison_Exp>;
  double_text_combo_helper_texts?: InputMaybe<Jsonb_Comparison_Exp>;
  dropdown_combo_answers?: InputMaybe<Jsonb_Comparison_Exp>;
  dropdown_label_1?: InputMaybe<String_Comparison_Exp>;
  dropdown_label_2?: InputMaybe<String_Comparison_Exp>;
  form_section_name?: InputMaybe<String_Comparison_Exp>;
  has_tooltip?: InputMaybe<Boolean_Comparison_Exp>;
  helper_text_position?: InputMaybe<Question_Ui_Control_Helper_Text_Position_Enum_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  measurement_unit?: InputMaybe<Question_Measurement_Units_Enum_Comparison_Exp>;
  relationship_unauthenticated_questionnaire_to_unauthenticated_questions?: InputMaybe<Unauthenticated_Questionnaire_Questions_Bool_Exp>;
  relationship_unauthenticated_questionnaire_to_unauthenticated_questions_aggregate?: InputMaybe<Unauthenticated_Questionnaire_Questions_Aggregate_Bool_Exp>;
  response_property?: InputMaybe<String_Comparison_Exp>;
  subtitle?: InputMaybe<String_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
  tooltip_content?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<Question_Types_Enum_Comparison_Exp>;
  ui_control?: InputMaybe<Question_Ui_Control_Types_Enum_Comparison_Exp>;
  unauthenticated_questions_helper_text_position?: InputMaybe<Question_Ui_Control_Helper_Text_Position_Bool_Exp>;
  unauthenticated_questions_question_measurement_unit?: InputMaybe<Question_Measurement_Units_Bool_Exp>;
  unauthenticated_questions_question_type?: InputMaybe<Question_Ui_Control_Types_Bool_Exp>;
  unauthenticated_questions_question_ui_control?: InputMaybe<Question_Ui_Control_Types_Bool_Exp>;
};

/** unique or primary key constraints on table "unauthenticated_questions" */
export enum Unauthenticated_Questions_Constraint {
  /** unique or primary key constraint on columns "id" */
  UnauthenticatedQuestionsIdSeq = 'unauthenticated_questions_id_seq',
  /** unique or primary key constraint on columns "id" */
  UnauthenticatedQuestionsPkey = 'unauthenticated_questions_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Unauthenticated_Questions_Delete_At_Path_Input = {
  answers?: InputMaybe<Array<Scalars['String']>>;
  double_text_combo_helper_texts?: InputMaybe<Array<Scalars['String']>>;
  dropdown_combo_answers?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Unauthenticated_Questions_Delete_Elem_Input = {
  answers?: InputMaybe<Scalars['Int']>;
  double_text_combo_helper_texts?: InputMaybe<Scalars['Int']>;
  dropdown_combo_answers?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Unauthenticated_Questions_Delete_Key_Input = {
  answers?: InputMaybe<Scalars['String']>;
  double_text_combo_helper_texts?: InputMaybe<Scalars['String']>;
  dropdown_combo_answers?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "unauthenticated_questions" */
export type Unauthenticated_Questions_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "unauthenticated_questions" */
export type Unauthenticated_Questions_Insert_Input = {
  answers?: InputMaybe<Scalars['jsonb']>;
  default?: InputMaybe<Scalars['String']>;
  dont_know_answer?: InputMaybe<Scalars['String']>;
  double_text_combo_helper_texts?: InputMaybe<Scalars['jsonb']>;
  dropdown_combo_answers?: InputMaybe<Scalars['jsonb']>;
  dropdown_label_1?: InputMaybe<Scalars['String']>;
  dropdown_label_2?: InputMaybe<Scalars['String']>;
  form_section_name?: InputMaybe<Scalars['String']>;
  has_tooltip?: InputMaybe<Scalars['Boolean']>;
  helper_text_position?: InputMaybe<Question_Ui_Control_Helper_Text_Position_Enum>;
  id?: InputMaybe<Scalars['Int']>;
  measurement_unit?: InputMaybe<Question_Measurement_Units_Enum>;
  relationship_unauthenticated_questionnaire_to_unauthenticated_questions?: InputMaybe<Unauthenticated_Questionnaire_Questions_Arr_Rel_Insert_Input>;
  response_property?: InputMaybe<Scalars['String']>;
  subtitle?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  tooltip_content?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Question_Types_Enum>;
  ui_control?: InputMaybe<Question_Ui_Control_Types_Enum>;
  unauthenticated_questions_helper_text_position?: InputMaybe<Question_Ui_Control_Helper_Text_Position_Obj_Rel_Insert_Input>;
  unauthenticated_questions_question_measurement_unit?: InputMaybe<Question_Measurement_Units_Obj_Rel_Insert_Input>;
  unauthenticated_questions_question_type?: InputMaybe<Question_Ui_Control_Types_Obj_Rel_Insert_Input>;
  unauthenticated_questions_question_ui_control?: InputMaybe<Question_Ui_Control_Types_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Unauthenticated_Questions_Max_Fields = {
  __typename?: 'unauthenticated_questions_max_fields';
  default?: Maybe<Scalars['String']>;
  dont_know_answer?: Maybe<Scalars['String']>;
  dropdown_label_1?: Maybe<Scalars['String']>;
  dropdown_label_2?: Maybe<Scalars['String']>;
  form_section_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  response_property?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  tooltip_content?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Unauthenticated_Questions_Min_Fields = {
  __typename?: 'unauthenticated_questions_min_fields';
  default?: Maybe<Scalars['String']>;
  dont_know_answer?: Maybe<Scalars['String']>;
  dropdown_label_1?: Maybe<Scalars['String']>;
  dropdown_label_2?: Maybe<Scalars['String']>;
  form_section_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  response_property?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  tooltip_content?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "unauthenticated_questions" */
export type Unauthenticated_Questions_Mutation_Response = {
  __typename?: 'unauthenticated_questions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Unauthenticated_Questions>;
};

/** input type for inserting object relation for remote table "unauthenticated_questions" */
export type Unauthenticated_Questions_Obj_Rel_Insert_Input = {
  data: Unauthenticated_Questions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Unauthenticated_Questions_On_Conflict>;
};

/** on_conflict condition type for table "unauthenticated_questions" */
export type Unauthenticated_Questions_On_Conflict = {
  constraint: Unauthenticated_Questions_Constraint;
  update_columns?: Array<Unauthenticated_Questions_Update_Column>;
  where?: InputMaybe<Unauthenticated_Questions_Bool_Exp>;
};

/** Ordering options when selecting data from "unauthenticated_questions". */
export type Unauthenticated_Questions_Order_By = {
  answers?: InputMaybe<Order_By>;
  default?: InputMaybe<Order_By>;
  dont_know_answer?: InputMaybe<Order_By>;
  double_text_combo_helper_texts?: InputMaybe<Order_By>;
  dropdown_combo_answers?: InputMaybe<Order_By>;
  dropdown_label_1?: InputMaybe<Order_By>;
  dropdown_label_2?: InputMaybe<Order_By>;
  form_section_name?: InputMaybe<Order_By>;
  has_tooltip?: InputMaybe<Order_By>;
  helper_text_position?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  measurement_unit?: InputMaybe<Order_By>;
  relationship_unauthenticated_questionnaire_to_unauthenticated_questions_aggregate?: InputMaybe<Unauthenticated_Questionnaire_Questions_Aggregate_Order_By>;
  response_property?: InputMaybe<Order_By>;
  subtitle?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  tooltip_content?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  ui_control?: InputMaybe<Order_By>;
  unauthenticated_questions_helper_text_position?: InputMaybe<Question_Ui_Control_Helper_Text_Position_Order_By>;
  unauthenticated_questions_question_measurement_unit?: InputMaybe<Question_Measurement_Units_Order_By>;
  unauthenticated_questions_question_type?: InputMaybe<Question_Ui_Control_Types_Order_By>;
  unauthenticated_questions_question_ui_control?: InputMaybe<Question_Ui_Control_Types_Order_By>;
};

/** primary key columns input for table: unauthenticated_questions */
export type Unauthenticated_Questions_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Unauthenticated_Questions_Prepend_Input = {
  answers?: InputMaybe<Scalars['jsonb']>;
  double_text_combo_helper_texts?: InputMaybe<Scalars['jsonb']>;
  dropdown_combo_answers?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "unauthenticated_questions" */
export enum Unauthenticated_Questions_Select_Column {
  /** column name */
  Answers = 'answers',
  /** column name */
  Default = 'default',
  /** column name */
  DontKnowAnswer = 'dont_know_answer',
  /** column name */
  DoubleTextComboHelperTexts = 'double_text_combo_helper_texts',
  /** column name */
  DropdownComboAnswers = 'dropdown_combo_answers',
  /** column name */
  DropdownLabel_1 = 'dropdown_label_1',
  /** column name */
  DropdownLabel_2 = 'dropdown_label_2',
  /** column name */
  FormSectionName = 'form_section_name',
  /** column name */
  HasTooltip = 'has_tooltip',
  /** column name */
  HelperTextPosition = 'helper_text_position',
  /** column name */
  Id = 'id',
  /** column name */
  MeasurementUnit = 'measurement_unit',
  /** column name */
  ResponseProperty = 'response_property',
  /** column name */
  Subtitle = 'subtitle',
  /** column name */
  Text = 'text',
  /** column name */
  TooltipContent = 'tooltip_content',
  /** column name */
  Type = 'type',
  /** column name */
  UiControl = 'ui_control'
}

/** input type for updating data in table "unauthenticated_questions" */
export type Unauthenticated_Questions_Set_Input = {
  answers?: InputMaybe<Scalars['jsonb']>;
  default?: InputMaybe<Scalars['String']>;
  dont_know_answer?: InputMaybe<Scalars['String']>;
  double_text_combo_helper_texts?: InputMaybe<Scalars['jsonb']>;
  dropdown_combo_answers?: InputMaybe<Scalars['jsonb']>;
  dropdown_label_1?: InputMaybe<Scalars['String']>;
  dropdown_label_2?: InputMaybe<Scalars['String']>;
  form_section_name?: InputMaybe<Scalars['String']>;
  has_tooltip?: InputMaybe<Scalars['Boolean']>;
  helper_text_position?: InputMaybe<Question_Ui_Control_Helper_Text_Position_Enum>;
  id?: InputMaybe<Scalars['Int']>;
  measurement_unit?: InputMaybe<Question_Measurement_Units_Enum>;
  response_property?: InputMaybe<Scalars['String']>;
  subtitle?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  tooltip_content?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Question_Types_Enum>;
  ui_control?: InputMaybe<Question_Ui_Control_Types_Enum>;
};

/** aggregate stddev on columns */
export type Unauthenticated_Questions_Stddev_Fields = {
  __typename?: 'unauthenticated_questions_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Unauthenticated_Questions_Stddev_Pop_Fields = {
  __typename?: 'unauthenticated_questions_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Unauthenticated_Questions_Stddev_Samp_Fields = {
  __typename?: 'unauthenticated_questions_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "unauthenticated_questions" */
export type Unauthenticated_Questions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Unauthenticated_Questions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Unauthenticated_Questions_Stream_Cursor_Value_Input = {
  answers?: InputMaybe<Scalars['jsonb']>;
  default?: InputMaybe<Scalars['String']>;
  dont_know_answer?: InputMaybe<Scalars['String']>;
  double_text_combo_helper_texts?: InputMaybe<Scalars['jsonb']>;
  dropdown_combo_answers?: InputMaybe<Scalars['jsonb']>;
  dropdown_label_1?: InputMaybe<Scalars['String']>;
  dropdown_label_2?: InputMaybe<Scalars['String']>;
  form_section_name?: InputMaybe<Scalars['String']>;
  has_tooltip?: InputMaybe<Scalars['Boolean']>;
  helper_text_position?: InputMaybe<Question_Ui_Control_Helper_Text_Position_Enum>;
  id?: InputMaybe<Scalars['Int']>;
  measurement_unit?: InputMaybe<Question_Measurement_Units_Enum>;
  response_property?: InputMaybe<Scalars['String']>;
  subtitle?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  tooltip_content?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Question_Types_Enum>;
  ui_control?: InputMaybe<Question_Ui_Control_Types_Enum>;
};

/** aggregate sum on columns */
export type Unauthenticated_Questions_Sum_Fields = {
  __typename?: 'unauthenticated_questions_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "unauthenticated_questions" */
export enum Unauthenticated_Questions_Update_Column {
  /** column name */
  Answers = 'answers',
  /** column name */
  Default = 'default',
  /** column name */
  DontKnowAnswer = 'dont_know_answer',
  /** column name */
  DoubleTextComboHelperTexts = 'double_text_combo_helper_texts',
  /** column name */
  DropdownComboAnswers = 'dropdown_combo_answers',
  /** column name */
  DropdownLabel_1 = 'dropdown_label_1',
  /** column name */
  DropdownLabel_2 = 'dropdown_label_2',
  /** column name */
  FormSectionName = 'form_section_name',
  /** column name */
  HasTooltip = 'has_tooltip',
  /** column name */
  HelperTextPosition = 'helper_text_position',
  /** column name */
  Id = 'id',
  /** column name */
  MeasurementUnit = 'measurement_unit',
  /** column name */
  ResponseProperty = 'response_property',
  /** column name */
  Subtitle = 'subtitle',
  /** column name */
  Text = 'text',
  /** column name */
  TooltipContent = 'tooltip_content',
  /** column name */
  Type = 'type',
  /** column name */
  UiControl = 'ui_control'
}

export type Unauthenticated_Questions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Unauthenticated_Questions_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Unauthenticated_Questions_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Unauthenticated_Questions_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Unauthenticated_Questions_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Unauthenticated_Questions_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Unauthenticated_Questions_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Unauthenticated_Questions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Unauthenticated_Questions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Unauthenticated_Questions_Var_Pop_Fields = {
  __typename?: 'unauthenticated_questions_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Unauthenticated_Questions_Var_Samp_Fields = {
  __typename?: 'unauthenticated_questions_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Unauthenticated_Questions_Variance_Fields = {
  __typename?: 'unauthenticated_questions_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** USA universities */
export type Universities = {
  __typename?: 'universities';
  id: Scalars['Int'];
  name: Scalars['String'];
};

/** aggregated selection of "universities" */
export type Universities_Aggregate = {
  __typename?: 'universities_aggregate';
  aggregate?: Maybe<Universities_Aggregate_Fields>;
  nodes: Array<Universities>;
};

/** aggregate fields of "universities" */
export type Universities_Aggregate_Fields = {
  __typename?: 'universities_aggregate_fields';
  avg?: Maybe<Universities_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Universities_Max_Fields>;
  min?: Maybe<Universities_Min_Fields>;
  stddev?: Maybe<Universities_Stddev_Fields>;
  stddev_pop?: Maybe<Universities_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Universities_Stddev_Samp_Fields>;
  sum?: Maybe<Universities_Sum_Fields>;
  var_pop?: Maybe<Universities_Var_Pop_Fields>;
  var_samp?: Maybe<Universities_Var_Samp_Fields>;
  variance?: Maybe<Universities_Variance_Fields>;
};


/** aggregate fields of "universities" */
export type Universities_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Universities_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Universities_Avg_Fields = {
  __typename?: 'universities_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "universities". All fields are combined with a logical 'AND'. */
export type Universities_Bool_Exp = {
  _and?: InputMaybe<Array<Universities_Bool_Exp>>;
  _not?: InputMaybe<Universities_Bool_Exp>;
  _or?: InputMaybe<Array<Universities_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "universities" */
export enum Universities_Constraint {
  /** unique or primary key constraint on columns "name" */
  UniversitiesNameKey = 'universities_name_key',
  /** unique or primary key constraint on columns "id" */
  UniversitiesPkey = 'universities_pkey'
}

/** input type for incrementing numeric columns in table "universities" */
export type Universities_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "universities" */
export type Universities_Insert_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Universities_Max_Fields = {
  __typename?: 'universities_max_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Universities_Min_Fields = {
  __typename?: 'universities_min_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "universities" */
export type Universities_Mutation_Response = {
  __typename?: 'universities_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Universities>;
};

/** on_conflict condition type for table "universities" */
export type Universities_On_Conflict = {
  constraint: Universities_Constraint;
  update_columns?: Array<Universities_Update_Column>;
  where?: InputMaybe<Universities_Bool_Exp>;
};

/** Ordering options when selecting data from "universities". */
export type Universities_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: universities */
export type Universities_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "universities" */
export enum Universities_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "universities" */
export type Universities_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Universities_Stddev_Fields = {
  __typename?: 'universities_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Universities_Stddev_Pop_Fields = {
  __typename?: 'universities_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Universities_Stddev_Samp_Fields = {
  __typename?: 'universities_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "universities" */
export type Universities_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Universities_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Universities_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Universities_Sum_Fields = {
  __typename?: 'universities_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "universities" */
export enum Universities_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Universities_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Universities_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Universities_Set_Input>;
  /** filter the rows which have to be updated */
  where: Universities_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Universities_Var_Pop_Fields = {
  __typename?: 'universities_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Universities_Var_Samp_Fields = {
  __typename?: 'universities_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Universities_Variance_Fields = {
  __typename?: 'universities_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "user_addresses" */
export type User_Addresses = {
  __typename?: 'user_addresses';
  address_id: Scalars['uuid'];
  id: Scalars['bigint'];
  user_id: Scalars['uuid'];
};

/** aggregated selection of "user_addresses" */
export type User_Addresses_Aggregate = {
  __typename?: 'user_addresses_aggregate';
  aggregate?: Maybe<User_Addresses_Aggregate_Fields>;
  nodes: Array<User_Addresses>;
};

/** aggregate fields of "user_addresses" */
export type User_Addresses_Aggregate_Fields = {
  __typename?: 'user_addresses_aggregate_fields';
  avg?: Maybe<User_Addresses_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Addresses_Max_Fields>;
  min?: Maybe<User_Addresses_Min_Fields>;
  stddev?: Maybe<User_Addresses_Stddev_Fields>;
  stddev_pop?: Maybe<User_Addresses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Addresses_Stddev_Samp_Fields>;
  sum?: Maybe<User_Addresses_Sum_Fields>;
  var_pop?: Maybe<User_Addresses_Var_Pop_Fields>;
  var_samp?: Maybe<User_Addresses_Var_Samp_Fields>;
  variance?: Maybe<User_Addresses_Variance_Fields>;
};


/** aggregate fields of "user_addresses" */
export type User_Addresses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Addresses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type User_Addresses_Avg_Fields = {
  __typename?: 'user_addresses_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "user_addresses". All fields are combined with a logical 'AND'. */
export type User_Addresses_Bool_Exp = {
  _and?: InputMaybe<Array<User_Addresses_Bool_Exp>>;
  _not?: InputMaybe<User_Addresses_Bool_Exp>;
  _or?: InputMaybe<Array<User_Addresses_Bool_Exp>>;
  address_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_addresses" */
export enum User_Addresses_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserAddressesPkey = 'user_addresses_pkey',
  /** unique or primary key constraint on columns "user_id", "address_id" */
  UserAddressesUserIdAddressIdKey = 'user_addresses_user_id_address_id_key'
}

/** input type for incrementing numeric columns in table "user_addresses" */
export type User_Addresses_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "user_addresses" */
export type User_Addresses_Insert_Input = {
  address_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Addresses_Max_Fields = {
  __typename?: 'user_addresses_max_fields';
  address_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type User_Addresses_Min_Fields = {
  __typename?: 'user_addresses_min_fields';
  address_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "user_addresses" */
export type User_Addresses_Mutation_Response = {
  __typename?: 'user_addresses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Addresses>;
};

/** on_conflict condition type for table "user_addresses" */
export type User_Addresses_On_Conflict = {
  constraint: User_Addresses_Constraint;
  update_columns?: Array<User_Addresses_Update_Column>;
  where?: InputMaybe<User_Addresses_Bool_Exp>;
};

/** Ordering options when selecting data from "user_addresses". */
export type User_Addresses_Order_By = {
  address_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_addresses */
export type User_Addresses_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "user_addresses" */
export enum User_Addresses_Select_Column {
  /** column name */
  AddressId = 'address_id',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_addresses" */
export type User_Addresses_Set_Input = {
  address_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type User_Addresses_Stddev_Fields = {
  __typename?: 'user_addresses_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type User_Addresses_Stddev_Pop_Fields = {
  __typename?: 'user_addresses_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type User_Addresses_Stddev_Samp_Fields = {
  __typename?: 'user_addresses_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "user_addresses" */
export type User_Addresses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Addresses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Addresses_Stream_Cursor_Value_Input = {
  address_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type User_Addresses_Sum_Fields = {
  __typename?: 'user_addresses_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "user_addresses" */
export enum User_Addresses_Update_Column {
  /** column name */
  AddressId = 'address_id',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

export type User_Addresses_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Addresses_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Addresses_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Addresses_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Addresses_Var_Pop_Fields = {
  __typename?: 'user_addresses_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type User_Addresses_Var_Samp_Fields = {
  __typename?: 'user_addresses_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type User_Addresses_Variance_Fields = {
  __typename?: 'user_addresses_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "user_auth_provider_types" */
export type User_Auth_Provider_Types = {
  __typename?: 'user_auth_provider_types';
  comment?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "user_auth_provider_types" */
export type User_Auth_Provider_Types_Aggregate = {
  __typename?: 'user_auth_provider_types_aggregate';
  aggregate?: Maybe<User_Auth_Provider_Types_Aggregate_Fields>;
  nodes: Array<User_Auth_Provider_Types>;
};

/** aggregate fields of "user_auth_provider_types" */
export type User_Auth_Provider_Types_Aggregate_Fields = {
  __typename?: 'user_auth_provider_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Auth_Provider_Types_Max_Fields>;
  min?: Maybe<User_Auth_Provider_Types_Min_Fields>;
};


/** aggregate fields of "user_auth_provider_types" */
export type User_Auth_Provider_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Auth_Provider_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user_auth_provider_types". All fields are combined with a logical 'AND'. */
export type User_Auth_Provider_Types_Bool_Exp = {
  _and?: InputMaybe<Array<User_Auth_Provider_Types_Bool_Exp>>;
  _not?: InputMaybe<User_Auth_Provider_Types_Bool_Exp>;
  _or?: InputMaybe<Array<User_Auth_Provider_Types_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_auth_provider_types" */
export enum User_Auth_Provider_Types_Constraint {
  /** unique or primary key constraint on columns "value" */
  UserAuthProviderTypesPkey = 'user_auth_provider_types_pkey'
}

export enum User_Auth_Provider_Types_Enum {
  Apple = 'APPLE',
  Email = 'EMAIL',
  Google = 'GOOGLE'
}

/** Boolean expression to compare columns of type "user_auth_provider_types_enum". All fields are combined with logical 'AND'. */
export type User_Auth_Provider_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<User_Auth_Provider_Types_Enum>;
  _in?: InputMaybe<Array<User_Auth_Provider_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<User_Auth_Provider_Types_Enum>;
  _nin?: InputMaybe<Array<User_Auth_Provider_Types_Enum>>;
};

/** input type for inserting data into table "user_auth_provider_types" */
export type User_Auth_Provider_Types_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Auth_Provider_Types_Max_Fields = {
  __typename?: 'user_auth_provider_types_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type User_Auth_Provider_Types_Min_Fields = {
  __typename?: 'user_auth_provider_types_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "user_auth_provider_types" */
export type User_Auth_Provider_Types_Mutation_Response = {
  __typename?: 'user_auth_provider_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Auth_Provider_Types>;
};

/** on_conflict condition type for table "user_auth_provider_types" */
export type User_Auth_Provider_Types_On_Conflict = {
  constraint: User_Auth_Provider_Types_Constraint;
  update_columns?: Array<User_Auth_Provider_Types_Update_Column>;
  where?: InputMaybe<User_Auth_Provider_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "user_auth_provider_types". */
export type User_Auth_Provider_Types_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_auth_provider_types */
export type User_Auth_Provider_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "user_auth_provider_types" */
export enum User_Auth_Provider_Types_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "user_auth_provider_types" */
export type User_Auth_Provider_Types_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "user_auth_provider_types" */
export type User_Auth_Provider_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Auth_Provider_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Auth_Provider_Types_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "user_auth_provider_types" */
export enum User_Auth_Provider_Types_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type User_Auth_Provider_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Auth_Provider_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Auth_Provider_Types_Bool_Exp;
};

/** columns and relationships of "user_auth_providers" */
export type User_Auth_Providers = {
  __typename?: 'user_auth_providers';
  id: Scalars['bigint'];
  provider_data?: Maybe<Scalars['jsonb']>;
  provider_identifier: Scalars['String'];
  provider_type: User_Auth_Provider_Types_Enum;
  user_id: Scalars['uuid'];
};


/** columns and relationships of "user_auth_providers" */
export type User_Auth_ProvidersProvider_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "user_auth_providers" */
export type User_Auth_Providers_Aggregate = {
  __typename?: 'user_auth_providers_aggregate';
  aggregate?: Maybe<User_Auth_Providers_Aggregate_Fields>;
  nodes: Array<User_Auth_Providers>;
};

/** aggregate fields of "user_auth_providers" */
export type User_Auth_Providers_Aggregate_Fields = {
  __typename?: 'user_auth_providers_aggregate_fields';
  avg?: Maybe<User_Auth_Providers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Auth_Providers_Max_Fields>;
  min?: Maybe<User_Auth_Providers_Min_Fields>;
  stddev?: Maybe<User_Auth_Providers_Stddev_Fields>;
  stddev_pop?: Maybe<User_Auth_Providers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Auth_Providers_Stddev_Samp_Fields>;
  sum?: Maybe<User_Auth_Providers_Sum_Fields>;
  var_pop?: Maybe<User_Auth_Providers_Var_Pop_Fields>;
  var_samp?: Maybe<User_Auth_Providers_Var_Samp_Fields>;
  variance?: Maybe<User_Auth_Providers_Variance_Fields>;
};


/** aggregate fields of "user_auth_providers" */
export type User_Auth_Providers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Auth_Providers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type User_Auth_Providers_Append_Input = {
  provider_data?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type User_Auth_Providers_Avg_Fields = {
  __typename?: 'user_auth_providers_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "user_auth_providers". All fields are combined with a logical 'AND'. */
export type User_Auth_Providers_Bool_Exp = {
  _and?: InputMaybe<Array<User_Auth_Providers_Bool_Exp>>;
  _not?: InputMaybe<User_Auth_Providers_Bool_Exp>;
  _or?: InputMaybe<Array<User_Auth_Providers_Bool_Exp>>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  provider_data?: InputMaybe<Jsonb_Comparison_Exp>;
  provider_identifier?: InputMaybe<String_Comparison_Exp>;
  provider_type?: InputMaybe<User_Auth_Provider_Types_Enum_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_auth_providers" */
export enum User_Auth_Providers_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserAuthProvidersPkey = 'user_auth_providers_pkey',
  /** unique or primary key constraint on columns "provider_type", "user_id" */
  UserAuthProvidersUserIdProviderTypeKey = 'user_auth_providers_user_id_provider_type_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type User_Auth_Providers_Delete_At_Path_Input = {
  provider_data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type User_Auth_Providers_Delete_Elem_Input = {
  provider_data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type User_Auth_Providers_Delete_Key_Input = {
  provider_data?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "user_auth_providers" */
export type User_Auth_Providers_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "user_auth_providers" */
export type User_Auth_Providers_Insert_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  provider_data?: InputMaybe<Scalars['jsonb']>;
  provider_identifier?: InputMaybe<Scalars['String']>;
  provider_type?: InputMaybe<User_Auth_Provider_Types_Enum>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Auth_Providers_Max_Fields = {
  __typename?: 'user_auth_providers_max_fields';
  id?: Maybe<Scalars['bigint']>;
  provider_identifier?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type User_Auth_Providers_Min_Fields = {
  __typename?: 'user_auth_providers_min_fields';
  id?: Maybe<Scalars['bigint']>;
  provider_identifier?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "user_auth_providers" */
export type User_Auth_Providers_Mutation_Response = {
  __typename?: 'user_auth_providers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Auth_Providers>;
};

/** on_conflict condition type for table "user_auth_providers" */
export type User_Auth_Providers_On_Conflict = {
  constraint: User_Auth_Providers_Constraint;
  update_columns?: Array<User_Auth_Providers_Update_Column>;
  where?: InputMaybe<User_Auth_Providers_Bool_Exp>;
};

/** Ordering options when selecting data from "user_auth_providers". */
export type User_Auth_Providers_Order_By = {
  id?: InputMaybe<Order_By>;
  provider_data?: InputMaybe<Order_By>;
  provider_identifier?: InputMaybe<Order_By>;
  provider_type?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_auth_providers */
export type User_Auth_Providers_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type User_Auth_Providers_Prepend_Input = {
  provider_data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "user_auth_providers" */
export enum User_Auth_Providers_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  ProviderData = 'provider_data',
  /** column name */
  ProviderIdentifier = 'provider_identifier',
  /** column name */
  ProviderType = 'provider_type',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_auth_providers" */
export type User_Auth_Providers_Set_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  provider_data?: InputMaybe<Scalars['jsonb']>;
  provider_identifier?: InputMaybe<Scalars['String']>;
  provider_type?: InputMaybe<User_Auth_Provider_Types_Enum>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type User_Auth_Providers_Stddev_Fields = {
  __typename?: 'user_auth_providers_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type User_Auth_Providers_Stddev_Pop_Fields = {
  __typename?: 'user_auth_providers_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type User_Auth_Providers_Stddev_Samp_Fields = {
  __typename?: 'user_auth_providers_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "user_auth_providers" */
export type User_Auth_Providers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Auth_Providers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Auth_Providers_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  provider_data?: InputMaybe<Scalars['jsonb']>;
  provider_identifier?: InputMaybe<Scalars['String']>;
  provider_type?: InputMaybe<User_Auth_Provider_Types_Enum>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type User_Auth_Providers_Sum_Fields = {
  __typename?: 'user_auth_providers_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "user_auth_providers" */
export enum User_Auth_Providers_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  ProviderData = 'provider_data',
  /** column name */
  ProviderIdentifier = 'provider_identifier',
  /** column name */
  ProviderType = 'provider_type',
  /** column name */
  UserId = 'user_id'
}

export type User_Auth_Providers_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<User_Auth_Providers_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<User_Auth_Providers_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<User_Auth_Providers_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<User_Auth_Providers_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Auth_Providers_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<User_Auth_Providers_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Auth_Providers_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Auth_Providers_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Auth_Providers_Var_Pop_Fields = {
  __typename?: 'user_auth_providers_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type User_Auth_Providers_Var_Samp_Fields = {
  __typename?: 'user_auth_providers_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type User_Auth_Providers_Variance_Fields = {
  __typename?: 'user_auth_providers_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "user_auth_tokens" */
export type User_Auth_Tokens = {
  __typename?: 'user_auth_tokens';
  access_token: Scalars['String'];
  auth_token: Scalars['String'];
  created_at?: Maybe<Scalars['timestamp']>;
  expire_at: Scalars['timestamp'];
  id: Scalars['Int'];
  refresh_token: Scalars['String'];
  user_id: Scalars['String'];
};

/** aggregated selection of "user_auth_tokens" */
export type User_Auth_Tokens_Aggregate = {
  __typename?: 'user_auth_tokens_aggregate';
  aggregate?: Maybe<User_Auth_Tokens_Aggregate_Fields>;
  nodes: Array<User_Auth_Tokens>;
};

/** aggregate fields of "user_auth_tokens" */
export type User_Auth_Tokens_Aggregate_Fields = {
  __typename?: 'user_auth_tokens_aggregate_fields';
  avg?: Maybe<User_Auth_Tokens_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Auth_Tokens_Max_Fields>;
  min?: Maybe<User_Auth_Tokens_Min_Fields>;
  stddev?: Maybe<User_Auth_Tokens_Stddev_Fields>;
  stddev_pop?: Maybe<User_Auth_Tokens_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Auth_Tokens_Stddev_Samp_Fields>;
  sum?: Maybe<User_Auth_Tokens_Sum_Fields>;
  var_pop?: Maybe<User_Auth_Tokens_Var_Pop_Fields>;
  var_samp?: Maybe<User_Auth_Tokens_Var_Samp_Fields>;
  variance?: Maybe<User_Auth_Tokens_Variance_Fields>;
};


/** aggregate fields of "user_auth_tokens" */
export type User_Auth_Tokens_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Auth_Tokens_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type User_Auth_Tokens_Avg_Fields = {
  __typename?: 'user_auth_tokens_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "user_auth_tokens". All fields are combined with a logical 'AND'. */
export type User_Auth_Tokens_Bool_Exp = {
  _and?: InputMaybe<Array<User_Auth_Tokens_Bool_Exp>>;
  _not?: InputMaybe<User_Auth_Tokens_Bool_Exp>;
  _or?: InputMaybe<Array<User_Auth_Tokens_Bool_Exp>>;
  access_token?: InputMaybe<String_Comparison_Exp>;
  auth_token?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  expire_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  refresh_token?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_auth_tokens" */
export enum User_Auth_Tokens_Constraint {
  /** unique or primary key constraint on columns "auth_token" */
  UserAuthTokensAuthTokenKey = 'user_auth_tokens_auth_token_key',
  /** unique or primary key constraint on columns "id" */
  UserAuthTokensPkey = 'user_auth_tokens_pkey'
}

/** input type for incrementing numeric columns in table "user_auth_tokens" */
export type User_Auth_Tokens_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "user_auth_tokens" */
export type User_Auth_Tokens_Insert_Input = {
  access_token?: InputMaybe<Scalars['String']>;
  auth_token?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  expire_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  refresh_token?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Auth_Tokens_Max_Fields = {
  __typename?: 'user_auth_tokens_max_fields';
  access_token?: Maybe<Scalars['String']>;
  auth_token?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  expire_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  refresh_token?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type User_Auth_Tokens_Min_Fields = {
  __typename?: 'user_auth_tokens_min_fields';
  access_token?: Maybe<Scalars['String']>;
  auth_token?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  expire_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  refresh_token?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "user_auth_tokens" */
export type User_Auth_Tokens_Mutation_Response = {
  __typename?: 'user_auth_tokens_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Auth_Tokens>;
};

/** on_conflict condition type for table "user_auth_tokens" */
export type User_Auth_Tokens_On_Conflict = {
  constraint: User_Auth_Tokens_Constraint;
  update_columns?: Array<User_Auth_Tokens_Update_Column>;
  where?: InputMaybe<User_Auth_Tokens_Bool_Exp>;
};

/** Ordering options when selecting data from "user_auth_tokens". */
export type User_Auth_Tokens_Order_By = {
  access_token?: InputMaybe<Order_By>;
  auth_token?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  expire_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  refresh_token?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_auth_tokens */
export type User_Auth_Tokens_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "user_auth_tokens" */
export enum User_Auth_Tokens_Select_Column {
  /** column name */
  AccessToken = 'access_token',
  /** column name */
  AuthToken = 'auth_token',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpireAt = 'expire_at',
  /** column name */
  Id = 'id',
  /** column name */
  RefreshToken = 'refresh_token',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_auth_tokens" */
export type User_Auth_Tokens_Set_Input = {
  access_token?: InputMaybe<Scalars['String']>;
  auth_token?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  expire_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  refresh_token?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type User_Auth_Tokens_Stddev_Fields = {
  __typename?: 'user_auth_tokens_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type User_Auth_Tokens_Stddev_Pop_Fields = {
  __typename?: 'user_auth_tokens_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type User_Auth_Tokens_Stddev_Samp_Fields = {
  __typename?: 'user_auth_tokens_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "user_auth_tokens" */
export type User_Auth_Tokens_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Auth_Tokens_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Auth_Tokens_Stream_Cursor_Value_Input = {
  access_token?: InputMaybe<Scalars['String']>;
  auth_token?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  expire_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  refresh_token?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type User_Auth_Tokens_Sum_Fields = {
  __typename?: 'user_auth_tokens_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "user_auth_tokens" */
export enum User_Auth_Tokens_Update_Column {
  /** column name */
  AccessToken = 'access_token',
  /** column name */
  AuthToken = 'auth_token',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpireAt = 'expire_at',
  /** column name */
  Id = 'id',
  /** column name */
  RefreshToken = 'refresh_token',
  /** column name */
  UserId = 'user_id'
}

export type User_Auth_Tokens_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Auth_Tokens_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Auth_Tokens_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Auth_Tokens_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Auth_Tokens_Var_Pop_Fields = {
  __typename?: 'user_auth_tokens_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type User_Auth_Tokens_Var_Samp_Fields = {
  __typename?: 'user_auth_tokens_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type User_Auth_Tokens_Variance_Fields = {
  __typename?: 'user_auth_tokens_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "user_communication_deliveries" */
export type User_Communication_Deliveries = {
  __typename?: 'user_communication_deliveries';
  communication_channel: Communication_Channels_Enum;
  created_at: Scalars['timestamptz'];
  updated_at: Scalars['timestamptz'];
  user_communication_delivery_id: Scalars['bigint'];
  user_communication_id: Scalars['bigint'];
};

/** aggregated selection of "user_communication_deliveries" */
export type User_Communication_Deliveries_Aggregate = {
  __typename?: 'user_communication_deliveries_aggregate';
  aggregate?: Maybe<User_Communication_Deliveries_Aggregate_Fields>;
  nodes: Array<User_Communication_Deliveries>;
};

/** aggregate fields of "user_communication_deliveries" */
export type User_Communication_Deliveries_Aggregate_Fields = {
  __typename?: 'user_communication_deliveries_aggregate_fields';
  avg?: Maybe<User_Communication_Deliveries_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Communication_Deliveries_Max_Fields>;
  min?: Maybe<User_Communication_Deliveries_Min_Fields>;
  stddev?: Maybe<User_Communication_Deliveries_Stddev_Fields>;
  stddev_pop?: Maybe<User_Communication_Deliveries_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Communication_Deliveries_Stddev_Samp_Fields>;
  sum?: Maybe<User_Communication_Deliveries_Sum_Fields>;
  var_pop?: Maybe<User_Communication_Deliveries_Var_Pop_Fields>;
  var_samp?: Maybe<User_Communication_Deliveries_Var_Samp_Fields>;
  variance?: Maybe<User_Communication_Deliveries_Variance_Fields>;
};


/** aggregate fields of "user_communication_deliveries" */
export type User_Communication_Deliveries_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Communication_Deliveries_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type User_Communication_Deliveries_Avg_Fields = {
  __typename?: 'user_communication_deliveries_avg_fields';
  user_communication_delivery_id?: Maybe<Scalars['Float']>;
  user_communication_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "user_communication_deliveries". All fields are combined with a logical 'AND'. */
export type User_Communication_Deliveries_Bool_Exp = {
  _and?: InputMaybe<Array<User_Communication_Deliveries_Bool_Exp>>;
  _not?: InputMaybe<User_Communication_Deliveries_Bool_Exp>;
  _or?: InputMaybe<Array<User_Communication_Deliveries_Bool_Exp>>;
  communication_channel?: InputMaybe<Communication_Channels_Enum_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_communication_delivery_id?: InputMaybe<Bigint_Comparison_Exp>;
  user_communication_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_communication_deliveries" */
export enum User_Communication_Deliveries_Constraint {
  /** unique or primary key constraint on columns "user_communication_delivery_id" */
  UserCommunicationDeliveriesPkey = 'user_communication_deliveries_pkey'
}

/** input type for incrementing numeric columns in table "user_communication_deliveries" */
export type User_Communication_Deliveries_Inc_Input = {
  user_communication_delivery_id?: InputMaybe<Scalars['bigint']>;
  user_communication_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "user_communication_deliveries" */
export type User_Communication_Deliveries_Insert_Input = {
  communication_channel?: InputMaybe<Communication_Channels_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_communication_delivery_id?: InputMaybe<Scalars['bigint']>;
  user_communication_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type User_Communication_Deliveries_Max_Fields = {
  __typename?: 'user_communication_deliveries_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_communication_delivery_id?: Maybe<Scalars['bigint']>;
  user_communication_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type User_Communication_Deliveries_Min_Fields = {
  __typename?: 'user_communication_deliveries_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_communication_delivery_id?: Maybe<Scalars['bigint']>;
  user_communication_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "user_communication_deliveries" */
export type User_Communication_Deliveries_Mutation_Response = {
  __typename?: 'user_communication_deliveries_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Communication_Deliveries>;
};

/** on_conflict condition type for table "user_communication_deliveries" */
export type User_Communication_Deliveries_On_Conflict = {
  constraint: User_Communication_Deliveries_Constraint;
  update_columns?: Array<User_Communication_Deliveries_Update_Column>;
  where?: InputMaybe<User_Communication_Deliveries_Bool_Exp>;
};

/** Ordering options when selecting data from "user_communication_deliveries". */
export type User_Communication_Deliveries_Order_By = {
  communication_channel?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_communication_delivery_id?: InputMaybe<Order_By>;
  user_communication_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_communication_deliveries */
export type User_Communication_Deliveries_Pk_Columns_Input = {
  user_communication_delivery_id: Scalars['bigint'];
};

/** select columns of table "user_communication_deliveries" */
export enum User_Communication_Deliveries_Select_Column {
  /** column name */
  CommunicationChannel = 'communication_channel',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserCommunicationDeliveryId = 'user_communication_delivery_id',
  /** column name */
  UserCommunicationId = 'user_communication_id'
}

/** input type for updating data in table "user_communication_deliveries" */
export type User_Communication_Deliveries_Set_Input = {
  communication_channel?: InputMaybe<Communication_Channels_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_communication_delivery_id?: InputMaybe<Scalars['bigint']>;
  user_communication_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type User_Communication_Deliveries_Stddev_Fields = {
  __typename?: 'user_communication_deliveries_stddev_fields';
  user_communication_delivery_id?: Maybe<Scalars['Float']>;
  user_communication_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type User_Communication_Deliveries_Stddev_Pop_Fields = {
  __typename?: 'user_communication_deliveries_stddev_pop_fields';
  user_communication_delivery_id?: Maybe<Scalars['Float']>;
  user_communication_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type User_Communication_Deliveries_Stddev_Samp_Fields = {
  __typename?: 'user_communication_deliveries_stddev_samp_fields';
  user_communication_delivery_id?: Maybe<Scalars['Float']>;
  user_communication_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "user_communication_deliveries" */
export type User_Communication_Deliveries_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Communication_Deliveries_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Communication_Deliveries_Stream_Cursor_Value_Input = {
  communication_channel?: InputMaybe<Communication_Channels_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_communication_delivery_id?: InputMaybe<Scalars['bigint']>;
  user_communication_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type User_Communication_Deliveries_Sum_Fields = {
  __typename?: 'user_communication_deliveries_sum_fields';
  user_communication_delivery_id?: Maybe<Scalars['bigint']>;
  user_communication_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "user_communication_deliveries" */
export enum User_Communication_Deliveries_Update_Column {
  /** column name */
  CommunicationChannel = 'communication_channel',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserCommunicationDeliveryId = 'user_communication_delivery_id',
  /** column name */
  UserCommunicationId = 'user_communication_id'
}

export type User_Communication_Deliveries_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Communication_Deliveries_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Communication_Deliveries_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Communication_Deliveries_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Communication_Deliveries_Var_Pop_Fields = {
  __typename?: 'user_communication_deliveries_var_pop_fields';
  user_communication_delivery_id?: Maybe<Scalars['Float']>;
  user_communication_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type User_Communication_Deliveries_Var_Samp_Fields = {
  __typename?: 'user_communication_deliveries_var_samp_fields';
  user_communication_delivery_id?: Maybe<Scalars['Float']>;
  user_communication_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type User_Communication_Deliveries_Variance_Fields = {
  __typename?: 'user_communication_deliveries_variance_fields';
  user_communication_delivery_id?: Maybe<Scalars['Float']>;
  user_communication_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "user_communications" */
export type User_Communications = {
  __typename?: 'user_communications';
  created_at: Scalars['timestamptz'];
  parameters?: Maybe<Scalars['jsonb']>;
  template_id: Scalars['String'];
  template_version: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  user_communication_id: Scalars['bigint'];
  user_id: Scalars['uuid'];
};


/** columns and relationships of "user_communications" */
export type User_CommunicationsParametersArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "user_communications" */
export type User_Communications_Aggregate = {
  __typename?: 'user_communications_aggregate';
  aggregate?: Maybe<User_Communications_Aggregate_Fields>;
  nodes: Array<User_Communications>;
};

/** aggregate fields of "user_communications" */
export type User_Communications_Aggregate_Fields = {
  __typename?: 'user_communications_aggregate_fields';
  avg?: Maybe<User_Communications_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Communications_Max_Fields>;
  min?: Maybe<User_Communications_Min_Fields>;
  stddev?: Maybe<User_Communications_Stddev_Fields>;
  stddev_pop?: Maybe<User_Communications_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Communications_Stddev_Samp_Fields>;
  sum?: Maybe<User_Communications_Sum_Fields>;
  var_pop?: Maybe<User_Communications_Var_Pop_Fields>;
  var_samp?: Maybe<User_Communications_Var_Samp_Fields>;
  variance?: Maybe<User_Communications_Variance_Fields>;
};


/** aggregate fields of "user_communications" */
export type User_Communications_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Communications_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type User_Communications_Append_Input = {
  parameters?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type User_Communications_Avg_Fields = {
  __typename?: 'user_communications_avg_fields';
  template_version?: Maybe<Scalars['Float']>;
  user_communication_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "user_communications". All fields are combined with a logical 'AND'. */
export type User_Communications_Bool_Exp = {
  _and?: InputMaybe<Array<User_Communications_Bool_Exp>>;
  _not?: InputMaybe<User_Communications_Bool_Exp>;
  _or?: InputMaybe<Array<User_Communications_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  parameters?: InputMaybe<Jsonb_Comparison_Exp>;
  template_id?: InputMaybe<String_Comparison_Exp>;
  template_version?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_communication_id?: InputMaybe<Bigint_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_communications" */
export enum User_Communications_Constraint {
  /** unique or primary key constraint on columns "user_communication_id" */
  UserCommunicationsPkey = 'user_communications_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type User_Communications_Delete_At_Path_Input = {
  parameters?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type User_Communications_Delete_Elem_Input = {
  parameters?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type User_Communications_Delete_Key_Input = {
  parameters?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "user_communications" */
export type User_Communications_Inc_Input = {
  template_version?: InputMaybe<Scalars['Int']>;
  user_communication_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "user_communications" */
export type User_Communications_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  parameters?: InputMaybe<Scalars['jsonb']>;
  template_id?: InputMaybe<Scalars['String']>;
  template_version?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_communication_id?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Communications_Max_Fields = {
  __typename?: 'user_communications_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  template_id?: Maybe<Scalars['String']>;
  template_version?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_communication_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type User_Communications_Min_Fields = {
  __typename?: 'user_communications_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  template_id?: Maybe<Scalars['String']>;
  template_version?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_communication_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "user_communications" */
export type User_Communications_Mutation_Response = {
  __typename?: 'user_communications_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Communications>;
};

/** on_conflict condition type for table "user_communications" */
export type User_Communications_On_Conflict = {
  constraint: User_Communications_Constraint;
  update_columns?: Array<User_Communications_Update_Column>;
  where?: InputMaybe<User_Communications_Bool_Exp>;
};

/** Ordering options when selecting data from "user_communications". */
export type User_Communications_Order_By = {
  created_at?: InputMaybe<Order_By>;
  parameters?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  template_version?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_communication_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_communications */
export type User_Communications_Pk_Columns_Input = {
  user_communication_id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type User_Communications_Prepend_Input = {
  parameters?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "user_communications" */
export enum User_Communications_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Parameters = 'parameters',
  /** column name */
  TemplateId = 'template_id',
  /** column name */
  TemplateVersion = 'template_version',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserCommunicationId = 'user_communication_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_communications" */
export type User_Communications_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  parameters?: InputMaybe<Scalars['jsonb']>;
  template_id?: InputMaybe<Scalars['String']>;
  template_version?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_communication_id?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type User_Communications_Stddev_Fields = {
  __typename?: 'user_communications_stddev_fields';
  template_version?: Maybe<Scalars['Float']>;
  user_communication_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type User_Communications_Stddev_Pop_Fields = {
  __typename?: 'user_communications_stddev_pop_fields';
  template_version?: Maybe<Scalars['Float']>;
  user_communication_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type User_Communications_Stddev_Samp_Fields = {
  __typename?: 'user_communications_stddev_samp_fields';
  template_version?: Maybe<Scalars['Float']>;
  user_communication_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "user_communications" */
export type User_Communications_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Communications_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Communications_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  parameters?: InputMaybe<Scalars['jsonb']>;
  template_id?: InputMaybe<Scalars['String']>;
  template_version?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_communication_id?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type User_Communications_Sum_Fields = {
  __typename?: 'user_communications_sum_fields';
  template_version?: Maybe<Scalars['Int']>;
  user_communication_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "user_communications" */
export enum User_Communications_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Parameters = 'parameters',
  /** column name */
  TemplateId = 'template_id',
  /** column name */
  TemplateVersion = 'template_version',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserCommunicationId = 'user_communication_id',
  /** column name */
  UserId = 'user_id'
}

export type User_Communications_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<User_Communications_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<User_Communications_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<User_Communications_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<User_Communications_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Communications_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<User_Communications_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Communications_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Communications_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Communications_Var_Pop_Fields = {
  __typename?: 'user_communications_var_pop_fields';
  template_version?: Maybe<Scalars['Float']>;
  user_communication_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type User_Communications_Var_Samp_Fields = {
  __typename?: 'user_communications_var_samp_fields';
  template_version?: Maybe<Scalars['Float']>;
  user_communication_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type User_Communications_Variance_Fields = {
  __typename?: 'user_communications_variance_fields';
  template_version?: Maybe<Scalars['Float']>;
  user_communication_id?: Maybe<Scalars['Float']>;
};

/** Enum table for status of user_notifications table */
export type User_Notification_Status = {
  __typename?: 'user_notification_status';
  comment?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "user_notification_status" */
export type User_Notification_Status_Aggregate = {
  __typename?: 'user_notification_status_aggregate';
  aggregate?: Maybe<User_Notification_Status_Aggregate_Fields>;
  nodes: Array<User_Notification_Status>;
};

/** aggregate fields of "user_notification_status" */
export type User_Notification_Status_Aggregate_Fields = {
  __typename?: 'user_notification_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Notification_Status_Max_Fields>;
  min?: Maybe<User_Notification_Status_Min_Fields>;
};


/** aggregate fields of "user_notification_status" */
export type User_Notification_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Notification_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user_notification_status". All fields are combined with a logical 'AND'. */
export type User_Notification_Status_Bool_Exp = {
  _and?: InputMaybe<Array<User_Notification_Status_Bool_Exp>>;
  _not?: InputMaybe<User_Notification_Status_Bool_Exp>;
  _or?: InputMaybe<Array<User_Notification_Status_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_notification_status" */
export enum User_Notification_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  UserNotificationStatusPkey = 'user_notification_status_pkey'
}

export enum User_Notification_Status_Enum {
  Delivered = 'DELIVERED',
  Read = 'READ',
  Seen = 'SEEN'
}

/** Boolean expression to compare columns of type "user_notification_status_enum". All fields are combined with logical 'AND'. */
export type User_Notification_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<User_Notification_Status_Enum>;
  _in?: InputMaybe<Array<User_Notification_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<User_Notification_Status_Enum>;
  _nin?: InputMaybe<Array<User_Notification_Status_Enum>>;
};

/** input type for inserting data into table "user_notification_status" */
export type User_Notification_Status_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Notification_Status_Max_Fields = {
  __typename?: 'user_notification_status_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type User_Notification_Status_Min_Fields = {
  __typename?: 'user_notification_status_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "user_notification_status" */
export type User_Notification_Status_Mutation_Response = {
  __typename?: 'user_notification_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Notification_Status>;
};

/** on_conflict condition type for table "user_notification_status" */
export type User_Notification_Status_On_Conflict = {
  constraint: User_Notification_Status_Constraint;
  update_columns?: Array<User_Notification_Status_Update_Column>;
  where?: InputMaybe<User_Notification_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "user_notification_status". */
export type User_Notification_Status_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_notification_status */
export type User_Notification_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "user_notification_status" */
export enum User_Notification_Status_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "user_notification_status" */
export type User_Notification_Status_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "user_notification_status" */
export type User_Notification_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Notification_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Notification_Status_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "user_notification_status" */
export enum User_Notification_Status_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type User_Notification_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Notification_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Notification_Status_Bool_Exp;
};

/** columns and relationships of "user_notifications" */
export type User_Notifications = {
  __typename?: 'user_notifications';
  created_at: Scalars['timestamptz'];
  data?: Maybe<Scalars['jsonb']>;
  has_priority: Scalars['Boolean'];
  id: Scalars['uuid'];
  status: User_Notification_Status_Enum;
  user_communication_delivery_id: Scalars['bigint'];
  user_id: Scalars['uuid'];
};


/** columns and relationships of "user_notifications" */
export type User_NotificationsDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "user_notifications" */
export type User_Notifications_Aggregate = {
  __typename?: 'user_notifications_aggregate';
  aggregate?: Maybe<User_Notifications_Aggregate_Fields>;
  nodes: Array<User_Notifications>;
};

/** aggregate fields of "user_notifications" */
export type User_Notifications_Aggregate_Fields = {
  __typename?: 'user_notifications_aggregate_fields';
  avg?: Maybe<User_Notifications_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Notifications_Max_Fields>;
  min?: Maybe<User_Notifications_Min_Fields>;
  stddev?: Maybe<User_Notifications_Stddev_Fields>;
  stddev_pop?: Maybe<User_Notifications_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Notifications_Stddev_Samp_Fields>;
  sum?: Maybe<User_Notifications_Sum_Fields>;
  var_pop?: Maybe<User_Notifications_Var_Pop_Fields>;
  var_samp?: Maybe<User_Notifications_Var_Samp_Fields>;
  variance?: Maybe<User_Notifications_Variance_Fields>;
};


/** aggregate fields of "user_notifications" */
export type User_Notifications_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Notifications_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type User_Notifications_Append_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type User_Notifications_Avg_Fields = {
  __typename?: 'user_notifications_avg_fields';
  user_communication_delivery_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "user_notifications". All fields are combined with a logical 'AND'. */
export type User_Notifications_Bool_Exp = {
  _and?: InputMaybe<Array<User_Notifications_Bool_Exp>>;
  _not?: InputMaybe<User_Notifications_Bool_Exp>;
  _or?: InputMaybe<Array<User_Notifications_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  has_priority?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<User_Notification_Status_Enum_Comparison_Exp>;
  user_communication_delivery_id?: InputMaybe<Bigint_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_notifications" */
export enum User_Notifications_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserNotificationsIdKey = 'user_notifications_id_key',
  /** unique or primary key constraint on columns "user_communication_delivery_id" */
  UserNotificationsPkey = 'user_notifications_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type User_Notifications_Delete_At_Path_Input = {
  data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type User_Notifications_Delete_Elem_Input = {
  data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type User_Notifications_Delete_Key_Input = {
  data?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "user_notifications" */
export type User_Notifications_Inc_Input = {
  user_communication_delivery_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "user_notifications" */
export type User_Notifications_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  has_priority?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<User_Notification_Status_Enum>;
  user_communication_delivery_id?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Notifications_Max_Fields = {
  __typename?: 'user_notifications_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  user_communication_delivery_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type User_Notifications_Min_Fields = {
  __typename?: 'user_notifications_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  user_communication_delivery_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "user_notifications" */
export type User_Notifications_Mutation_Response = {
  __typename?: 'user_notifications_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Notifications>;
};

/** on_conflict condition type for table "user_notifications" */
export type User_Notifications_On_Conflict = {
  constraint: User_Notifications_Constraint;
  update_columns?: Array<User_Notifications_Update_Column>;
  where?: InputMaybe<User_Notifications_Bool_Exp>;
};

/** Ordering options when selecting data from "user_notifications". */
export type User_Notifications_Order_By = {
  created_at?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  has_priority?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  user_communication_delivery_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_notifications */
export type User_Notifications_Pk_Columns_Input = {
  user_communication_delivery_id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type User_Notifications_Prepend_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "user_notifications" */
export enum User_Notifications_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  HasPriority = 'has_priority',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UserCommunicationDeliveryId = 'user_communication_delivery_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_notifications" */
export type User_Notifications_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  has_priority?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<User_Notification_Status_Enum>;
  user_communication_delivery_id?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type User_Notifications_Stddev_Fields = {
  __typename?: 'user_notifications_stddev_fields';
  user_communication_delivery_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type User_Notifications_Stddev_Pop_Fields = {
  __typename?: 'user_notifications_stddev_pop_fields';
  user_communication_delivery_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type User_Notifications_Stddev_Samp_Fields = {
  __typename?: 'user_notifications_stddev_samp_fields';
  user_communication_delivery_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "user_notifications" */
export type User_Notifications_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Notifications_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Notifications_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  has_priority?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<User_Notification_Status_Enum>;
  user_communication_delivery_id?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type User_Notifications_Sum_Fields = {
  __typename?: 'user_notifications_sum_fields';
  user_communication_delivery_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "user_notifications" */
export enum User_Notifications_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  HasPriority = 'has_priority',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UserCommunicationDeliveryId = 'user_communication_delivery_id',
  /** column name */
  UserId = 'user_id'
}

export type User_Notifications_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<User_Notifications_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<User_Notifications_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<User_Notifications_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<User_Notifications_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Notifications_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<User_Notifications_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Notifications_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Notifications_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Notifications_Var_Pop_Fields = {
  __typename?: 'user_notifications_var_pop_fields';
  user_communication_delivery_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type User_Notifications_Var_Samp_Fields = {
  __typename?: 'user_notifications_var_samp_fields';
  user_communication_delivery_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type User_Notifications_Variance_Fields = {
  __typename?: 'user_notifications_variance_fields';
  user_communication_delivery_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "user_roles" */
export type User_Roles = {
  __typename?: 'user_roles';
  id: Scalars['bigint'];
  role_id: Scalars['Int'];
  user_id: Scalars['uuid'];
};

/** aggregated selection of "user_roles" */
export type User_Roles_Aggregate = {
  __typename?: 'user_roles_aggregate';
  aggregate?: Maybe<User_Roles_Aggregate_Fields>;
  nodes: Array<User_Roles>;
};

/** aggregate fields of "user_roles" */
export type User_Roles_Aggregate_Fields = {
  __typename?: 'user_roles_aggregate_fields';
  avg?: Maybe<User_Roles_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Roles_Max_Fields>;
  min?: Maybe<User_Roles_Min_Fields>;
  stddev?: Maybe<User_Roles_Stddev_Fields>;
  stddev_pop?: Maybe<User_Roles_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Roles_Stddev_Samp_Fields>;
  sum?: Maybe<User_Roles_Sum_Fields>;
  var_pop?: Maybe<User_Roles_Var_Pop_Fields>;
  var_samp?: Maybe<User_Roles_Var_Samp_Fields>;
  variance?: Maybe<User_Roles_Variance_Fields>;
};


/** aggregate fields of "user_roles" */
export type User_Roles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type User_Roles_Avg_Fields = {
  __typename?: 'user_roles_avg_fields';
  id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "user_roles". All fields are combined with a logical 'AND'. */
export type User_Roles_Bool_Exp = {
  _and?: InputMaybe<Array<User_Roles_Bool_Exp>>;
  _not?: InputMaybe<User_Roles_Bool_Exp>;
  _or?: InputMaybe<Array<User_Roles_Bool_Exp>>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  role_id?: InputMaybe<Int_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_roles" */
export enum User_Roles_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserRolesPkey = 'user_roles_pkey',
  /** unique or primary key constraint on columns "role_id", "user_id" */
  UserRolesUserIdRoleIdKey = 'user_roles_user_id_role_id_key'
}

/** input type for incrementing numeric columns in table "user_roles" */
export type User_Roles_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  role_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "user_roles" */
export type User_Roles_Insert_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  role_id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Roles_Max_Fields = {
  __typename?: 'user_roles_max_fields';
  id?: Maybe<Scalars['bigint']>;
  role_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type User_Roles_Min_Fields = {
  __typename?: 'user_roles_min_fields';
  id?: Maybe<Scalars['bigint']>;
  role_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "user_roles" */
export type User_Roles_Mutation_Response = {
  __typename?: 'user_roles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Roles>;
};

/** on_conflict condition type for table "user_roles" */
export type User_Roles_On_Conflict = {
  constraint: User_Roles_Constraint;
  update_columns?: Array<User_Roles_Update_Column>;
  where?: InputMaybe<User_Roles_Bool_Exp>;
};

/** Ordering options when selecting data from "user_roles". */
export type User_Roles_Order_By = {
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_roles */
export type User_Roles_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "user_roles" */
export enum User_Roles_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_roles" */
export type User_Roles_Set_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  role_id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type User_Roles_Stddev_Fields = {
  __typename?: 'user_roles_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type User_Roles_Stddev_Pop_Fields = {
  __typename?: 'user_roles_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type User_Roles_Stddev_Samp_Fields = {
  __typename?: 'user_roles_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "user_roles" */
export type User_Roles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Roles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Roles_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  role_id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type User_Roles_Sum_Fields = {
  __typename?: 'user_roles_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  role_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "user_roles" */
export enum User_Roles_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  UserId = 'user_id'
}

export type User_Roles_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Roles_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Roles_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Roles_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Roles_Var_Pop_Fields = {
  __typename?: 'user_roles_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type User_Roles_Var_Samp_Fields = {
  __typename?: 'user_roles_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type User_Roles_Variance_Fields = {
  __typename?: 'user_roles_variance_fields';
  id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
};

/** Data scanned by the user with his mobile phone */
export type User_Scanned_Data = {
  __typename?: 'user_scanned_data';
  scan_completed?: Maybe<Scalars['Boolean']>;
  skip_tracking_number?: Maybe<Scalars['Boolean']>;
  swab_barcode?: Maybe<Scalars['String']>;
  test_type?: Maybe<Scalars['String']>;
  tracking_number?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "user_scanned_data" */
export type User_Scanned_Data_Aggregate = {
  __typename?: 'user_scanned_data_aggregate';
  aggregate?: Maybe<User_Scanned_Data_Aggregate_Fields>;
  nodes: Array<User_Scanned_Data>;
};

/** aggregate fields of "user_scanned_data" */
export type User_Scanned_Data_Aggregate_Fields = {
  __typename?: 'user_scanned_data_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Scanned_Data_Max_Fields>;
  min?: Maybe<User_Scanned_Data_Min_Fields>;
};


/** aggregate fields of "user_scanned_data" */
export type User_Scanned_Data_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Scanned_Data_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user_scanned_data". All fields are combined with a logical 'AND'. */
export type User_Scanned_Data_Bool_Exp = {
  _and?: InputMaybe<Array<User_Scanned_Data_Bool_Exp>>;
  _not?: InputMaybe<User_Scanned_Data_Bool_Exp>;
  _or?: InputMaybe<Array<User_Scanned_Data_Bool_Exp>>;
  scan_completed?: InputMaybe<Boolean_Comparison_Exp>;
  skip_tracking_number?: InputMaybe<Boolean_Comparison_Exp>;
  swab_barcode?: InputMaybe<String_Comparison_Exp>;
  test_type?: InputMaybe<String_Comparison_Exp>;
  tracking_number?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_scanned_data" */
export enum User_Scanned_Data_Constraint {
  /** unique or primary key constraint on columns "user_id" */
  UserScannedDataPkey = 'user_scanned_data_pkey'
}

/** input type for inserting data into table "user_scanned_data" */
export type User_Scanned_Data_Insert_Input = {
  scan_completed?: InputMaybe<Scalars['Boolean']>;
  skip_tracking_number?: InputMaybe<Scalars['Boolean']>;
  swab_barcode?: InputMaybe<Scalars['String']>;
  test_type?: InputMaybe<Scalars['String']>;
  tracking_number?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Scanned_Data_Max_Fields = {
  __typename?: 'user_scanned_data_max_fields';
  swab_barcode?: Maybe<Scalars['String']>;
  test_type?: Maybe<Scalars['String']>;
  tracking_number?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type User_Scanned_Data_Min_Fields = {
  __typename?: 'user_scanned_data_min_fields';
  swab_barcode?: Maybe<Scalars['String']>;
  test_type?: Maybe<Scalars['String']>;
  tracking_number?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "user_scanned_data" */
export type User_Scanned_Data_Mutation_Response = {
  __typename?: 'user_scanned_data_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Scanned_Data>;
};

/** on_conflict condition type for table "user_scanned_data" */
export type User_Scanned_Data_On_Conflict = {
  constraint: User_Scanned_Data_Constraint;
  update_columns?: Array<User_Scanned_Data_Update_Column>;
  where?: InputMaybe<User_Scanned_Data_Bool_Exp>;
};

/** Ordering options when selecting data from "user_scanned_data". */
export type User_Scanned_Data_Order_By = {
  scan_completed?: InputMaybe<Order_By>;
  skip_tracking_number?: InputMaybe<Order_By>;
  swab_barcode?: InputMaybe<Order_By>;
  test_type?: InputMaybe<Order_By>;
  tracking_number?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_scanned_data */
export type User_Scanned_Data_Pk_Columns_Input = {
  user_id: Scalars['uuid'];
};

/** select columns of table "user_scanned_data" */
export enum User_Scanned_Data_Select_Column {
  /** column name */
  ScanCompleted = 'scan_completed',
  /** column name */
  SkipTrackingNumber = 'skip_tracking_number',
  /** column name */
  SwabBarcode = 'swab_barcode',
  /** column name */
  TestType = 'test_type',
  /** column name */
  TrackingNumber = 'tracking_number',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_scanned_data" */
export type User_Scanned_Data_Set_Input = {
  scan_completed?: InputMaybe<Scalars['Boolean']>;
  skip_tracking_number?: InputMaybe<Scalars['Boolean']>;
  swab_barcode?: InputMaybe<Scalars['String']>;
  test_type?: InputMaybe<Scalars['String']>;
  tracking_number?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "user_scanned_data" */
export type User_Scanned_Data_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Scanned_Data_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Scanned_Data_Stream_Cursor_Value_Input = {
  scan_completed?: InputMaybe<Scalars['Boolean']>;
  skip_tracking_number?: InputMaybe<Scalars['Boolean']>;
  swab_barcode?: InputMaybe<Scalars['String']>;
  test_type?: InputMaybe<Scalars['String']>;
  tracking_number?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_scanned_data" */
export enum User_Scanned_Data_Update_Column {
  /** column name */
  ScanCompleted = 'scan_completed',
  /** column name */
  SkipTrackingNumber = 'skip_tracking_number',
  /** column name */
  SwabBarcode = 'swab_barcode',
  /** column name */
  TestType = 'test_type',
  /** column name */
  TrackingNumber = 'tracking_number',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type User_Scanned_Data_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Scanned_Data_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Scanned_Data_Bool_Exp;
};

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  ALERT_SETTINGS: Scalars['jsonb'];
  AUTO_APPROVAL_PATIENTS_CODEX_LABS_SETTING: Scalars['Boolean'];
  AUTO_APPROVAL_TESTKIT_SCRIPTS_CODEX_LABS_SETTING: Scalars['Boolean'];
  EMAIL_MONTHLY_NEWSLETTER: Scalars['Boolean'];
  EMAIL_NEW_FEATURES_SERVICES_SETTINGS: Scalars['Boolean'];
  EMAIL_SPECIAL_OFFERS_SETTING: Scalars['Boolean'];
  EMAIL_SPECIAL_OFFER_FRIENDS_SETTING: Scalars['Boolean'];
  EMAIL_UNSUBSCRIBED: Scalars['Boolean'];
  PRIVACY_AFFILIATES_CODEX_LABS_SETTING: Scalars['Boolean'];
  PRIVACY_CALIFORNIA_SETTINGS: Scalars['Boolean'];
  PRIVACY_NON_AFFILIATES_CODEX_LABS_SETTING: Scalars['Boolean'];
  SENSITIVE_address_information?: Maybe<Scalars['jsonb']>;
  SENSITIVE_country?: Maybe<Scalars['String']>;
  SENSITIVE_dob?: Maybe<Scalars['date']>;
  SENSITIVE_email: Scalars['String'];
  SENSITIVE_etnicity?: Maybe<Scalars['jsonb']>;
  SENSITIVE_firstname?: Maybe<Scalars['String']>;
  SENSITIVE_gender?: Maybe<Scalars['String']>;
  SENSITIVE_lastname?: Maybe<Scalars['String']>;
  SENSITIVE_phone?: Maybe<Scalars['String']>;
  SENSITIVE_profile_picture_id?: Maybe<Scalars['uuid']>;
  SENSITIVE_self_identity_gender?: Maybe<Scalars['String']>;
  SENSITIVE_user_measurements?: Maybe<Scalars['jsonb']>;
  account_settings?: Maybe<Scalars['jsonb']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  is_active?: Maybe<Scalars['Boolean']>;
  kyc_current_status?: Maybe<Persona_Inquiry_Status_Enum>;
  kyc_retries_count: Scalars['Int'];
  kyc_update_date?: Maybe<Scalars['timestamptz']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "users" */
export type UsersAlert_SettingsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "users" */
export type UsersSensitive_Address_InformationArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "users" */
export type UsersSensitive_EtnicityArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "users" */
export type UsersSensitive_User_MeasurementsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "users" */
export type UsersAccount_SettingsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields';
  avg?: Maybe<Users_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
  stddev?: Maybe<Users_Stddev_Fields>;
  stddev_pop?: Maybe<Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Users_Stddev_Samp_Fields>;
  sum?: Maybe<Users_Sum_Fields>;
  var_pop?: Maybe<Users_Var_Pop_Fields>;
  var_samp?: Maybe<Users_Var_Samp_Fields>;
  variance?: Maybe<Users_Variance_Fields>;
};


/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Users_Append_Input = {
  ALERT_SETTINGS?: InputMaybe<Scalars['jsonb']>;
  SENSITIVE_address_information?: InputMaybe<Scalars['jsonb']>;
  SENSITIVE_etnicity?: InputMaybe<Scalars['jsonb']>;
  SENSITIVE_user_measurements?: InputMaybe<Scalars['jsonb']>;
  account_settings?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Users_Avg_Fields = {
  __typename?: 'users_avg_fields';
  kyc_retries_count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  ALERT_SETTINGS?: InputMaybe<Jsonb_Comparison_Exp>;
  AUTO_APPROVAL_PATIENTS_CODEX_LABS_SETTING?: InputMaybe<Boolean_Comparison_Exp>;
  AUTO_APPROVAL_TESTKIT_SCRIPTS_CODEX_LABS_SETTING?: InputMaybe<Boolean_Comparison_Exp>;
  EMAIL_MONTHLY_NEWSLETTER?: InputMaybe<Boolean_Comparison_Exp>;
  EMAIL_NEW_FEATURES_SERVICES_SETTINGS?: InputMaybe<Boolean_Comparison_Exp>;
  EMAIL_SPECIAL_OFFERS_SETTING?: InputMaybe<Boolean_Comparison_Exp>;
  EMAIL_SPECIAL_OFFER_FRIENDS_SETTING?: InputMaybe<Boolean_Comparison_Exp>;
  EMAIL_UNSUBSCRIBED?: InputMaybe<Boolean_Comparison_Exp>;
  PRIVACY_AFFILIATES_CODEX_LABS_SETTING?: InputMaybe<Boolean_Comparison_Exp>;
  PRIVACY_CALIFORNIA_SETTINGS?: InputMaybe<Boolean_Comparison_Exp>;
  PRIVACY_NON_AFFILIATES_CODEX_LABS_SETTING?: InputMaybe<Boolean_Comparison_Exp>;
  SENSITIVE_address_information?: InputMaybe<Jsonb_Comparison_Exp>;
  SENSITIVE_country?: InputMaybe<String_Comparison_Exp>;
  SENSITIVE_dob?: InputMaybe<Date_Comparison_Exp>;
  SENSITIVE_email?: InputMaybe<String_Comparison_Exp>;
  SENSITIVE_etnicity?: InputMaybe<Jsonb_Comparison_Exp>;
  SENSITIVE_firstname?: InputMaybe<String_Comparison_Exp>;
  SENSITIVE_gender?: InputMaybe<String_Comparison_Exp>;
  SENSITIVE_lastname?: InputMaybe<String_Comparison_Exp>;
  SENSITIVE_phone?: InputMaybe<String_Comparison_Exp>;
  SENSITIVE_profile_picture_id?: InputMaybe<Uuid_Comparison_Exp>;
  SENSITIVE_self_identity_gender?: InputMaybe<String_Comparison_Exp>;
  SENSITIVE_user_measurements?: InputMaybe<Jsonb_Comparison_Exp>;
  _and?: InputMaybe<Array<Users_Bool_Exp>>;
  _not?: InputMaybe<Users_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Bool_Exp>>;
  account_settings?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  kyc_current_status?: InputMaybe<Persona_Inquiry_Status_Enum_Comparison_Exp>;
  kyc_retries_count?: InputMaybe<Int_Comparison_Exp>;
  kyc_update_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Users_Delete_At_Path_Input = {
  ALERT_SETTINGS?: InputMaybe<Array<Scalars['String']>>;
  SENSITIVE_address_information?: InputMaybe<Array<Scalars['String']>>;
  SENSITIVE_etnicity?: InputMaybe<Array<Scalars['String']>>;
  SENSITIVE_user_measurements?: InputMaybe<Array<Scalars['String']>>;
  account_settings?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Users_Delete_Elem_Input = {
  ALERT_SETTINGS?: InputMaybe<Scalars['Int']>;
  SENSITIVE_address_information?: InputMaybe<Scalars['Int']>;
  SENSITIVE_etnicity?: InputMaybe<Scalars['Int']>;
  SENSITIVE_user_measurements?: InputMaybe<Scalars['Int']>;
  account_settings?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Users_Delete_Key_Input = {
  ALERT_SETTINGS?: InputMaybe<Scalars['String']>;
  SENSITIVE_address_information?: InputMaybe<Scalars['String']>;
  SENSITIVE_etnicity?: InputMaybe<Scalars['String']>;
  SENSITIVE_user_measurements?: InputMaybe<Scalars['String']>;
  account_settings?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "users" */
export type Users_Inc_Input = {
  kyc_retries_count?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  ALERT_SETTINGS?: InputMaybe<Scalars['jsonb']>;
  AUTO_APPROVAL_PATIENTS_CODEX_LABS_SETTING?: InputMaybe<Scalars['Boolean']>;
  AUTO_APPROVAL_TESTKIT_SCRIPTS_CODEX_LABS_SETTING?: InputMaybe<Scalars['Boolean']>;
  EMAIL_MONTHLY_NEWSLETTER?: InputMaybe<Scalars['Boolean']>;
  EMAIL_NEW_FEATURES_SERVICES_SETTINGS?: InputMaybe<Scalars['Boolean']>;
  EMAIL_SPECIAL_OFFERS_SETTING?: InputMaybe<Scalars['Boolean']>;
  EMAIL_SPECIAL_OFFER_FRIENDS_SETTING?: InputMaybe<Scalars['Boolean']>;
  EMAIL_UNSUBSCRIBED?: InputMaybe<Scalars['Boolean']>;
  PRIVACY_AFFILIATES_CODEX_LABS_SETTING?: InputMaybe<Scalars['Boolean']>;
  PRIVACY_CALIFORNIA_SETTINGS?: InputMaybe<Scalars['Boolean']>;
  PRIVACY_NON_AFFILIATES_CODEX_LABS_SETTING?: InputMaybe<Scalars['Boolean']>;
  SENSITIVE_address_information?: InputMaybe<Scalars['jsonb']>;
  SENSITIVE_country?: InputMaybe<Scalars['String']>;
  SENSITIVE_dob?: InputMaybe<Scalars['date']>;
  SENSITIVE_email?: InputMaybe<Scalars['String']>;
  SENSITIVE_etnicity?: InputMaybe<Scalars['jsonb']>;
  SENSITIVE_firstname?: InputMaybe<Scalars['String']>;
  SENSITIVE_gender?: InputMaybe<Scalars['String']>;
  SENSITIVE_lastname?: InputMaybe<Scalars['String']>;
  SENSITIVE_phone?: InputMaybe<Scalars['String']>;
  SENSITIVE_profile_picture_id?: InputMaybe<Scalars['uuid']>;
  SENSITIVE_self_identity_gender?: InputMaybe<Scalars['String']>;
  SENSITIVE_user_measurements?: InputMaybe<Scalars['jsonb']>;
  account_settings?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  kyc_current_status?: InputMaybe<Persona_Inquiry_Status_Enum>;
  kyc_retries_count?: InputMaybe<Scalars['Int']>;
  kyc_update_date?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields';
  SENSITIVE_country?: Maybe<Scalars['String']>;
  SENSITIVE_dob?: Maybe<Scalars['date']>;
  SENSITIVE_email?: Maybe<Scalars['String']>;
  SENSITIVE_firstname?: Maybe<Scalars['String']>;
  SENSITIVE_gender?: Maybe<Scalars['String']>;
  SENSITIVE_lastname?: Maybe<Scalars['String']>;
  SENSITIVE_phone?: Maybe<Scalars['String']>;
  SENSITIVE_profile_picture_id?: Maybe<Scalars['uuid']>;
  SENSITIVE_self_identity_gender?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  kyc_retries_count?: Maybe<Scalars['Int']>;
  kyc_update_date?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields';
  SENSITIVE_country?: Maybe<Scalars['String']>;
  SENSITIVE_dob?: Maybe<Scalars['date']>;
  SENSITIVE_email?: Maybe<Scalars['String']>;
  SENSITIVE_firstname?: Maybe<Scalars['String']>;
  SENSITIVE_gender?: Maybe<Scalars['String']>;
  SENSITIVE_lastname?: Maybe<Scalars['String']>;
  SENSITIVE_phone?: Maybe<Scalars['String']>;
  SENSITIVE_profile_picture_id?: Maybe<Scalars['uuid']>;
  SENSITIVE_self_identity_gender?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  kyc_retries_count?: Maybe<Scalars['Int']>;
  kyc_update_date?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** on_conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns?: Array<Users_Update_Column>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  ALERT_SETTINGS?: InputMaybe<Order_By>;
  AUTO_APPROVAL_PATIENTS_CODEX_LABS_SETTING?: InputMaybe<Order_By>;
  AUTO_APPROVAL_TESTKIT_SCRIPTS_CODEX_LABS_SETTING?: InputMaybe<Order_By>;
  EMAIL_MONTHLY_NEWSLETTER?: InputMaybe<Order_By>;
  EMAIL_NEW_FEATURES_SERVICES_SETTINGS?: InputMaybe<Order_By>;
  EMAIL_SPECIAL_OFFERS_SETTING?: InputMaybe<Order_By>;
  EMAIL_SPECIAL_OFFER_FRIENDS_SETTING?: InputMaybe<Order_By>;
  EMAIL_UNSUBSCRIBED?: InputMaybe<Order_By>;
  PRIVACY_AFFILIATES_CODEX_LABS_SETTING?: InputMaybe<Order_By>;
  PRIVACY_CALIFORNIA_SETTINGS?: InputMaybe<Order_By>;
  PRIVACY_NON_AFFILIATES_CODEX_LABS_SETTING?: InputMaybe<Order_By>;
  SENSITIVE_address_information?: InputMaybe<Order_By>;
  SENSITIVE_country?: InputMaybe<Order_By>;
  SENSITIVE_dob?: InputMaybe<Order_By>;
  SENSITIVE_email?: InputMaybe<Order_By>;
  SENSITIVE_etnicity?: InputMaybe<Order_By>;
  SENSITIVE_firstname?: InputMaybe<Order_By>;
  SENSITIVE_gender?: InputMaybe<Order_By>;
  SENSITIVE_lastname?: InputMaybe<Order_By>;
  SENSITIVE_phone?: InputMaybe<Order_By>;
  SENSITIVE_profile_picture_id?: InputMaybe<Order_By>;
  SENSITIVE_self_identity_gender?: InputMaybe<Order_By>;
  SENSITIVE_user_measurements?: InputMaybe<Order_By>;
  account_settings?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  kyc_current_status?: InputMaybe<Order_By>;
  kyc_retries_count?: InputMaybe<Order_By>;
  kyc_update_date?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Users_Prepend_Input = {
  ALERT_SETTINGS?: InputMaybe<Scalars['jsonb']>;
  SENSITIVE_address_information?: InputMaybe<Scalars['jsonb']>;
  SENSITIVE_etnicity?: InputMaybe<Scalars['jsonb']>;
  SENSITIVE_user_measurements?: InputMaybe<Scalars['jsonb']>;
  account_settings?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  AlertSettings = 'ALERT_SETTINGS',
  /** column name */
  AutoApprovalPatientsCodexLabsSetting = 'AUTO_APPROVAL_PATIENTS_CODEX_LABS_SETTING',
  /** column name */
  AutoApprovalTestkitScriptsCodexLabsSetting = 'AUTO_APPROVAL_TESTKIT_SCRIPTS_CODEX_LABS_SETTING',
  /** column name */
  EmailMonthlyNewsletter = 'EMAIL_MONTHLY_NEWSLETTER',
  /** column name */
  EmailNewFeaturesServicesSettings = 'EMAIL_NEW_FEATURES_SERVICES_SETTINGS',
  /** column name */
  EmailSpecialOffersSetting = 'EMAIL_SPECIAL_OFFERS_SETTING',
  /** column name */
  EmailSpecialOfferFriendsSetting = 'EMAIL_SPECIAL_OFFER_FRIENDS_SETTING',
  /** column name */
  EmailUnsubscribed = 'EMAIL_UNSUBSCRIBED',
  /** column name */
  PrivacyAffiliatesCodexLabsSetting = 'PRIVACY_AFFILIATES_CODEX_LABS_SETTING',
  /** column name */
  PrivacyCaliforniaSettings = 'PRIVACY_CALIFORNIA_SETTINGS',
  /** column name */
  PrivacyNonAffiliatesCodexLabsSetting = 'PRIVACY_NON_AFFILIATES_CODEX_LABS_SETTING',
  /** column name */
  SensitiveAddressInformation = 'SENSITIVE_address_information',
  /** column name */
  SensitiveCountry = 'SENSITIVE_country',
  /** column name */
  SensitiveDob = 'SENSITIVE_dob',
  /** column name */
  SensitiveEmail = 'SENSITIVE_email',
  /** column name */
  SensitiveEtnicity = 'SENSITIVE_etnicity',
  /** column name */
  SensitiveFirstname = 'SENSITIVE_firstname',
  /** column name */
  SensitiveGender = 'SENSITIVE_gender',
  /** column name */
  SensitiveLastname = 'SENSITIVE_lastname',
  /** column name */
  SensitivePhone = 'SENSITIVE_phone',
  /** column name */
  SensitiveProfilePictureId = 'SENSITIVE_profile_picture_id',
  /** column name */
  SensitiveSelfIdentityGender = 'SENSITIVE_self_identity_gender',
  /** column name */
  SensitiveUserMeasurements = 'SENSITIVE_user_measurements',
  /** column name */
  AccountSettings = 'account_settings',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  KycCurrentStatus = 'kyc_current_status',
  /** column name */
  KycRetriesCount = 'kyc_retries_count',
  /** column name */
  KycUpdateDate = 'kyc_update_date',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  ALERT_SETTINGS?: InputMaybe<Scalars['jsonb']>;
  AUTO_APPROVAL_PATIENTS_CODEX_LABS_SETTING?: InputMaybe<Scalars['Boolean']>;
  AUTO_APPROVAL_TESTKIT_SCRIPTS_CODEX_LABS_SETTING?: InputMaybe<Scalars['Boolean']>;
  EMAIL_MONTHLY_NEWSLETTER?: InputMaybe<Scalars['Boolean']>;
  EMAIL_NEW_FEATURES_SERVICES_SETTINGS?: InputMaybe<Scalars['Boolean']>;
  EMAIL_SPECIAL_OFFERS_SETTING?: InputMaybe<Scalars['Boolean']>;
  EMAIL_SPECIAL_OFFER_FRIENDS_SETTING?: InputMaybe<Scalars['Boolean']>;
  EMAIL_UNSUBSCRIBED?: InputMaybe<Scalars['Boolean']>;
  PRIVACY_AFFILIATES_CODEX_LABS_SETTING?: InputMaybe<Scalars['Boolean']>;
  PRIVACY_CALIFORNIA_SETTINGS?: InputMaybe<Scalars['Boolean']>;
  PRIVACY_NON_AFFILIATES_CODEX_LABS_SETTING?: InputMaybe<Scalars['Boolean']>;
  SENSITIVE_address_information?: InputMaybe<Scalars['jsonb']>;
  SENSITIVE_country?: InputMaybe<Scalars['String']>;
  SENSITIVE_dob?: InputMaybe<Scalars['date']>;
  SENSITIVE_email?: InputMaybe<Scalars['String']>;
  SENSITIVE_etnicity?: InputMaybe<Scalars['jsonb']>;
  SENSITIVE_firstname?: InputMaybe<Scalars['String']>;
  SENSITIVE_gender?: InputMaybe<Scalars['String']>;
  SENSITIVE_lastname?: InputMaybe<Scalars['String']>;
  SENSITIVE_phone?: InputMaybe<Scalars['String']>;
  SENSITIVE_profile_picture_id?: InputMaybe<Scalars['uuid']>;
  SENSITIVE_self_identity_gender?: InputMaybe<Scalars['String']>;
  SENSITIVE_user_measurements?: InputMaybe<Scalars['jsonb']>;
  account_settings?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  kyc_current_status?: InputMaybe<Persona_Inquiry_Status_Enum>;
  kyc_retries_count?: InputMaybe<Scalars['Int']>;
  kyc_update_date?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Users_Stddev_Fields = {
  __typename?: 'users_stddev_fields';
  kyc_retries_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Users_Stddev_Pop_Fields = {
  __typename?: 'users_stddev_pop_fields';
  kyc_retries_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Users_Stddev_Samp_Fields = {
  __typename?: 'users_stddev_samp_fields';
  kyc_retries_count?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "users" */
export type Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Stream_Cursor_Value_Input = {
  ALERT_SETTINGS?: InputMaybe<Scalars['jsonb']>;
  AUTO_APPROVAL_PATIENTS_CODEX_LABS_SETTING?: InputMaybe<Scalars['Boolean']>;
  AUTO_APPROVAL_TESTKIT_SCRIPTS_CODEX_LABS_SETTING?: InputMaybe<Scalars['Boolean']>;
  EMAIL_MONTHLY_NEWSLETTER?: InputMaybe<Scalars['Boolean']>;
  EMAIL_NEW_FEATURES_SERVICES_SETTINGS?: InputMaybe<Scalars['Boolean']>;
  EMAIL_SPECIAL_OFFERS_SETTING?: InputMaybe<Scalars['Boolean']>;
  EMAIL_SPECIAL_OFFER_FRIENDS_SETTING?: InputMaybe<Scalars['Boolean']>;
  EMAIL_UNSUBSCRIBED?: InputMaybe<Scalars['Boolean']>;
  PRIVACY_AFFILIATES_CODEX_LABS_SETTING?: InputMaybe<Scalars['Boolean']>;
  PRIVACY_CALIFORNIA_SETTINGS?: InputMaybe<Scalars['Boolean']>;
  PRIVACY_NON_AFFILIATES_CODEX_LABS_SETTING?: InputMaybe<Scalars['Boolean']>;
  SENSITIVE_address_information?: InputMaybe<Scalars['jsonb']>;
  SENSITIVE_country?: InputMaybe<Scalars['String']>;
  SENSITIVE_dob?: InputMaybe<Scalars['date']>;
  SENSITIVE_email?: InputMaybe<Scalars['String']>;
  SENSITIVE_etnicity?: InputMaybe<Scalars['jsonb']>;
  SENSITIVE_firstname?: InputMaybe<Scalars['String']>;
  SENSITIVE_gender?: InputMaybe<Scalars['String']>;
  SENSITIVE_lastname?: InputMaybe<Scalars['String']>;
  SENSITIVE_phone?: InputMaybe<Scalars['String']>;
  SENSITIVE_profile_picture_id?: InputMaybe<Scalars['uuid']>;
  SENSITIVE_self_identity_gender?: InputMaybe<Scalars['String']>;
  SENSITIVE_user_measurements?: InputMaybe<Scalars['jsonb']>;
  account_settings?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  kyc_current_status?: InputMaybe<Persona_Inquiry_Status_Enum>;
  kyc_retries_count?: InputMaybe<Scalars['Int']>;
  kyc_update_date?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Users_Sum_Fields = {
  __typename?: 'users_sum_fields';
  kyc_retries_count?: Maybe<Scalars['Int']>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  AlertSettings = 'ALERT_SETTINGS',
  /** column name */
  AutoApprovalPatientsCodexLabsSetting = 'AUTO_APPROVAL_PATIENTS_CODEX_LABS_SETTING',
  /** column name */
  AutoApprovalTestkitScriptsCodexLabsSetting = 'AUTO_APPROVAL_TESTKIT_SCRIPTS_CODEX_LABS_SETTING',
  /** column name */
  EmailMonthlyNewsletter = 'EMAIL_MONTHLY_NEWSLETTER',
  /** column name */
  EmailNewFeaturesServicesSettings = 'EMAIL_NEW_FEATURES_SERVICES_SETTINGS',
  /** column name */
  EmailSpecialOffersSetting = 'EMAIL_SPECIAL_OFFERS_SETTING',
  /** column name */
  EmailSpecialOfferFriendsSetting = 'EMAIL_SPECIAL_OFFER_FRIENDS_SETTING',
  /** column name */
  EmailUnsubscribed = 'EMAIL_UNSUBSCRIBED',
  /** column name */
  PrivacyAffiliatesCodexLabsSetting = 'PRIVACY_AFFILIATES_CODEX_LABS_SETTING',
  /** column name */
  PrivacyCaliforniaSettings = 'PRIVACY_CALIFORNIA_SETTINGS',
  /** column name */
  PrivacyNonAffiliatesCodexLabsSetting = 'PRIVACY_NON_AFFILIATES_CODEX_LABS_SETTING',
  /** column name */
  SensitiveAddressInformation = 'SENSITIVE_address_information',
  /** column name */
  SensitiveCountry = 'SENSITIVE_country',
  /** column name */
  SensitiveDob = 'SENSITIVE_dob',
  /** column name */
  SensitiveEmail = 'SENSITIVE_email',
  /** column name */
  SensitiveEtnicity = 'SENSITIVE_etnicity',
  /** column name */
  SensitiveFirstname = 'SENSITIVE_firstname',
  /** column name */
  SensitiveGender = 'SENSITIVE_gender',
  /** column name */
  SensitiveLastname = 'SENSITIVE_lastname',
  /** column name */
  SensitivePhone = 'SENSITIVE_phone',
  /** column name */
  SensitiveProfilePictureId = 'SENSITIVE_profile_picture_id',
  /** column name */
  SensitiveSelfIdentityGender = 'SENSITIVE_self_identity_gender',
  /** column name */
  SensitiveUserMeasurements = 'SENSITIVE_user_measurements',
  /** column name */
  AccountSettings = 'account_settings',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  KycCurrentStatus = 'kyc_current_status',
  /** column name */
  KycRetriesCount = 'kyc_retries_count',
  /** column name */
  KycUpdateDate = 'kyc_update_date',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Users_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Users_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Users_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Users_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Users_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Users_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Users_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Users_Var_Pop_Fields = {
  __typename?: 'users_var_pop_fields';
  kyc_retries_count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Users_Var_Samp_Fields = {
  __typename?: 'users_var_samp_fields';
  kyc_retries_count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Users_Variance_Fields = {
  __typename?: 'users_variance_fields';
  kyc_retries_count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

export type VerifyFirebaseEmailResponse = {
  __typename?: 'verifyFirebaseEmailResponse';
  message: Scalars['String'];
  status: Scalars['Int'];
};

/** columns and relationships of "vonage_appointment" */
export type Vonage_Appointment = {
  __typename?: 'vonage_appointment';
  appointment_date?: Maybe<Scalars['date']>;
  appointment_time?: Maybe<Scalars['timetz']>;
  appointment_type?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  patient_id: Scalars['uuid'];
  provider_id: Scalars['uuid'];
  room_id: Scalars['uuid'];
};

/** aggregated selection of "vonage_appointment" */
export type Vonage_Appointment_Aggregate = {
  __typename?: 'vonage_appointment_aggregate';
  aggregate?: Maybe<Vonage_Appointment_Aggregate_Fields>;
  nodes: Array<Vonage_Appointment>;
};

/** aggregate fields of "vonage_appointment" */
export type Vonage_Appointment_Aggregate_Fields = {
  __typename?: 'vonage_appointment_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Vonage_Appointment_Max_Fields>;
  min?: Maybe<Vonage_Appointment_Min_Fields>;
};


/** aggregate fields of "vonage_appointment" */
export type Vonage_Appointment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Vonage_Appointment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "vonage_appointment". All fields are combined with a logical 'AND'. */
export type Vonage_Appointment_Bool_Exp = {
  _and?: InputMaybe<Array<Vonage_Appointment_Bool_Exp>>;
  _not?: InputMaybe<Vonage_Appointment_Bool_Exp>;
  _or?: InputMaybe<Array<Vonage_Appointment_Bool_Exp>>;
  appointment_date?: InputMaybe<Date_Comparison_Exp>;
  appointment_time?: InputMaybe<Timetz_Comparison_Exp>;
  appointment_type?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  patient_id?: InputMaybe<Uuid_Comparison_Exp>;
  provider_id?: InputMaybe<Uuid_Comparison_Exp>;
  room_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "vonage_appointment" */
export enum Vonage_Appointment_Constraint {
  /** unique or primary key constraint on columns "id" */
  VonageAppointmentPkey = 'vonage_appointment_pkey',
  /** unique or primary key constraint on columns "room_id" */
  VonageAppointmentRoomIdKey = 'vonage_appointment_room_id_key'
}

/** input type for inserting data into table "vonage_appointment" */
export type Vonage_Appointment_Insert_Input = {
  appointment_date?: InputMaybe<Scalars['date']>;
  appointment_time?: InputMaybe<Scalars['timetz']>;
  appointment_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  patient_id?: InputMaybe<Scalars['uuid']>;
  provider_id?: InputMaybe<Scalars['uuid']>;
  room_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Vonage_Appointment_Max_Fields = {
  __typename?: 'vonage_appointment_max_fields';
  appointment_date?: Maybe<Scalars['date']>;
  appointment_time?: Maybe<Scalars['timetz']>;
  appointment_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  patient_id?: Maybe<Scalars['uuid']>;
  provider_id?: Maybe<Scalars['uuid']>;
  room_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Vonage_Appointment_Min_Fields = {
  __typename?: 'vonage_appointment_min_fields';
  appointment_date?: Maybe<Scalars['date']>;
  appointment_time?: Maybe<Scalars['timetz']>;
  appointment_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  patient_id?: Maybe<Scalars['uuid']>;
  provider_id?: Maybe<Scalars['uuid']>;
  room_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "vonage_appointment" */
export type Vonage_Appointment_Mutation_Response = {
  __typename?: 'vonage_appointment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Vonage_Appointment>;
};

/** on_conflict condition type for table "vonage_appointment" */
export type Vonage_Appointment_On_Conflict = {
  constraint: Vonage_Appointment_Constraint;
  update_columns?: Array<Vonage_Appointment_Update_Column>;
  where?: InputMaybe<Vonage_Appointment_Bool_Exp>;
};

/** Ordering options when selecting data from "vonage_appointment". */
export type Vonage_Appointment_Order_By = {
  appointment_date?: InputMaybe<Order_By>;
  appointment_time?: InputMaybe<Order_By>;
  appointment_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: vonage_appointment */
export type Vonage_Appointment_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "vonage_appointment" */
export enum Vonage_Appointment_Select_Column {
  /** column name */
  AppointmentDate = 'appointment_date',
  /** column name */
  AppointmentTime = 'appointment_time',
  /** column name */
  AppointmentType = 'appointment_type',
  /** column name */
  Id = 'id',
  /** column name */
  PatientId = 'patient_id',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  RoomId = 'room_id'
}

/** input type for updating data in table "vonage_appointment" */
export type Vonage_Appointment_Set_Input = {
  appointment_date?: InputMaybe<Scalars['date']>;
  appointment_time?: InputMaybe<Scalars['timetz']>;
  appointment_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  patient_id?: InputMaybe<Scalars['uuid']>;
  provider_id?: InputMaybe<Scalars['uuid']>;
  room_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "vonage_appointment" */
export type Vonage_Appointment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vonage_Appointment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vonage_Appointment_Stream_Cursor_Value_Input = {
  appointment_date?: InputMaybe<Scalars['date']>;
  appointment_time?: InputMaybe<Scalars['timetz']>;
  appointment_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  patient_id?: InputMaybe<Scalars['uuid']>;
  provider_id?: InputMaybe<Scalars['uuid']>;
  room_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "vonage_appointment" */
export enum Vonage_Appointment_Update_Column {
  /** column name */
  AppointmentDate = 'appointment_date',
  /** column name */
  AppointmentTime = 'appointment_time',
  /** column name */
  AppointmentType = 'appointment_type',
  /** column name */
  Id = 'id',
  /** column name */
  PatientId = 'patient_id',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  RoomId = 'room_id'
}

export type Vonage_Appointment_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Vonage_Appointment_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vonage_Appointment_Bool_Exp;
};

/** ZRT test results */
export type Zrt_Tests = {
  __typename?: 'zrt_tests';
  created_at: Scalars['timestamptz'];
  errors?: Maybe<Scalars['jsonb']>;
  state: Scalars['String'];
  test_id: Scalars['uuid'];
  tracking_number?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  zrt_tests_test: Tests;
};


/** ZRT test results */
export type Zrt_TestsErrorsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "zrt_tests" */
export type Zrt_Tests_Aggregate = {
  __typename?: 'zrt_tests_aggregate';
  aggregate?: Maybe<Zrt_Tests_Aggregate_Fields>;
  nodes: Array<Zrt_Tests>;
};

export type Zrt_Tests_Aggregate_Bool_Exp = {
  count?: InputMaybe<Zrt_Tests_Aggregate_Bool_Exp_Count>;
};

export type Zrt_Tests_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Zrt_Tests_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Zrt_Tests_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "zrt_tests" */
export type Zrt_Tests_Aggregate_Fields = {
  __typename?: 'zrt_tests_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Zrt_Tests_Max_Fields>;
  min?: Maybe<Zrt_Tests_Min_Fields>;
};


/** aggregate fields of "zrt_tests" */
export type Zrt_Tests_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Zrt_Tests_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "zrt_tests" */
export type Zrt_Tests_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Zrt_Tests_Max_Order_By>;
  min?: InputMaybe<Zrt_Tests_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Zrt_Tests_Append_Input = {
  errors?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "zrt_tests" */
export type Zrt_Tests_Arr_Rel_Insert_Input = {
  data: Array<Zrt_Tests_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Zrt_Tests_On_Conflict>;
};

/** Boolean expression to filter rows from the table "zrt_tests". All fields are combined with a logical 'AND'. */
export type Zrt_Tests_Bool_Exp = {
  _and?: InputMaybe<Array<Zrt_Tests_Bool_Exp>>;
  _not?: InputMaybe<Zrt_Tests_Bool_Exp>;
  _or?: InputMaybe<Array<Zrt_Tests_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  errors?: InputMaybe<Jsonb_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  test_id?: InputMaybe<Uuid_Comparison_Exp>;
  tracking_number?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  zrt_tests_test?: InputMaybe<Tests_Bool_Exp>;
};

/** unique or primary key constraints on table "zrt_tests" */
export enum Zrt_Tests_Constraint {
  /** unique or primary key constraint on columns "test_id" */
  ZrtTestsPkey = 'zrt_tests_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Zrt_Tests_Delete_At_Path_Input = {
  errors?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Zrt_Tests_Delete_Elem_Input = {
  errors?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Zrt_Tests_Delete_Key_Input = {
  errors?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "zrt_tests" */
export type Zrt_Tests_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  errors?: InputMaybe<Scalars['jsonb']>;
  state?: InputMaybe<Scalars['String']>;
  test_id?: InputMaybe<Scalars['uuid']>;
  tracking_number?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  zrt_tests_test?: InputMaybe<Tests_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Zrt_Tests_Max_Fields = {
  __typename?: 'zrt_tests_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  state?: Maybe<Scalars['String']>;
  test_id?: Maybe<Scalars['uuid']>;
  tracking_number?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "zrt_tests" */
export type Zrt_Tests_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
  tracking_number?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Zrt_Tests_Min_Fields = {
  __typename?: 'zrt_tests_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  state?: Maybe<Scalars['String']>;
  test_id?: Maybe<Scalars['uuid']>;
  tracking_number?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "zrt_tests" */
export type Zrt_Tests_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
  tracking_number?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "zrt_tests" */
export type Zrt_Tests_Mutation_Response = {
  __typename?: 'zrt_tests_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Zrt_Tests>;
};

/** on_conflict condition type for table "zrt_tests" */
export type Zrt_Tests_On_Conflict = {
  constraint: Zrt_Tests_Constraint;
  update_columns?: Array<Zrt_Tests_Update_Column>;
  where?: InputMaybe<Zrt_Tests_Bool_Exp>;
};

/** Ordering options when selecting data from "zrt_tests". */
export type Zrt_Tests_Order_By = {
  created_at?: InputMaybe<Order_By>;
  errors?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
  tracking_number?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  zrt_tests_test?: InputMaybe<Tests_Order_By>;
};

/** primary key columns input for table: zrt_tests */
export type Zrt_Tests_Pk_Columns_Input = {
  test_id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Zrt_Tests_Prepend_Input = {
  errors?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "zrt_tests" */
export enum Zrt_Tests_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Errors = 'errors',
  /** column name */
  State = 'state',
  /** column name */
  TestId = 'test_id',
  /** column name */
  TrackingNumber = 'tracking_number',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "zrt_tests" */
export type Zrt_Tests_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  errors?: InputMaybe<Scalars['jsonb']>;
  state?: InputMaybe<Scalars['String']>;
  test_id?: InputMaybe<Scalars['uuid']>;
  tracking_number?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "zrt_tests" */
export type Zrt_Tests_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Zrt_Tests_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Zrt_Tests_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  errors?: InputMaybe<Scalars['jsonb']>;
  state?: InputMaybe<Scalars['String']>;
  test_id?: InputMaybe<Scalars['uuid']>;
  tracking_number?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "zrt_tests" */
export enum Zrt_Tests_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Errors = 'errors',
  /** column name */
  State = 'state',
  /** column name */
  TestId = 'test_id',
  /** column name */
  TrackingNumber = 'tracking_number',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Zrt_Tests_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Zrt_Tests_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Zrt_Tests_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Zrt_Tests_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Zrt_Tests_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Zrt_Tests_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Zrt_Tests_Set_Input>;
  /** filter the rows which have to be updated */
  where: Zrt_Tests_Bool_Exp;
};


export const GetUserTestsByUserIdDocument = gql`
    query GetUserTestsByUserID($userID: uuid!, $dnaSkinTestID: Int!, $dnaVitaminTestID: Int!, $fromDate: timestamptz = "2000-01-01T00:00:00", $toDate: timestamptz = "2099-12-31T23:59:00", $states: [dnavisit_test_statuses_enum!]! = [COMPLETE, KIT_NOT_RECEIVED, RECEIVED_BY_LAB, RESULTS_WITH_PROVIDER, APPOINTMENT_SCHEDULED]) {
  tests(
    where: {user_id: {_eq: $userID}, _or: [{test_type_id: {_eq: $dnaSkinTestID}}, {test_type_id: {_eq: $dnaVitaminTestID}}], created_at: {_gte: $fromDate, _lte: $toDate}}
    order_by: {created_at: desc}
  ) {
    test_id
    created_at
    tests_test_definition {
      test_type
    }
  }
}
    `;

/**
 * __useGetUserTestsByUserIdQuery__
 *
 * To run a query within a React component, call `useGetUserTestsByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserTestsByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserTestsByUserIdQuery({
 *   variables: {
 *      userID: // value for 'userID'
 *      dnaSkinTestID: // value for 'dnaSkinTestID'
 *      dnaVitaminTestID: // value for 'dnaVitaminTestID'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *      states: // value for 'states'
 *   },
 * });
 */
export function useGetUserTestsByUserIdQuery(baseOptions: Apollo.QueryHookOptions<GetUserTestsByUserIdQuery, GetUserTestsByUserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserTestsByUserIdQuery, GetUserTestsByUserIdQueryVariables>(GetUserTestsByUserIdDocument, options);
      }
export function useGetUserTestsByUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserTestsByUserIdQuery, GetUserTestsByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserTestsByUserIdQuery, GetUserTestsByUserIdQueryVariables>(GetUserTestsByUserIdDocument, options);
        }
export type GetUserTestsByUserIdQueryHookResult = ReturnType<typeof useGetUserTestsByUserIdQuery>;
export type GetUserTestsByUserIdLazyQueryHookResult = ReturnType<typeof useGetUserTestsByUserIdLazyQuery>;
export type GetUserTestsByUserIdQueryResult = Apollo.QueryResult<GetUserTestsByUserIdQuery, GetUserTestsByUserIdQueryVariables>;
export const GetUserDnaTestsByTestsIdsDocument = gql`
    query GetUserDNATestsByTestsIDS($testIDS: [uuid!], $states: [dnavisit_test_statuses_enum!]! = [COMPLETE, KIT_NOT_RECEIVED, RECEIVED_BY_LAB, RESULTS_WITH_PROVIDER, APPOINTMENT_SCHEDULED], $limit: Int = 10, $offset: Int = 0) {
  dnavisit_tests(
    where: {test_id: {_in: $testIDS}, state: {_in: $states}, errors: {_is_null: true}}
    order_by: {created_at: desc}
    limit: $limit
    offset: $offset
  ) {
    test_id
    state
    tracking_number
    patient_pdf_id
    visit_summary_pdf_id
    sapphiros_barcode
    visit_summary_appointment_date
    visit_summary_appointment_url
    created_at
  }
  dnavisit_tests_aggregate(
    where: {test_id: {_in: $testIDS}, state: {_in: $states}, errors: {_is_null: true}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetUserDnaTestsByTestsIdsQuery__
 *
 * To run a query within a React component, call `useGetUserDnaTestsByTestsIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDnaTestsByTestsIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDnaTestsByTestsIdsQuery({
 *   variables: {
 *      testIDS: // value for 'testIDS'
 *      states: // value for 'states'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetUserDnaTestsByTestsIdsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserDnaTestsByTestsIdsQuery, GetUserDnaTestsByTestsIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserDnaTestsByTestsIdsQuery, GetUserDnaTestsByTestsIdsQueryVariables>(GetUserDnaTestsByTestsIdsDocument, options);
      }
export function useGetUserDnaTestsByTestsIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserDnaTestsByTestsIdsQuery, GetUserDnaTestsByTestsIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserDnaTestsByTestsIdsQuery, GetUserDnaTestsByTestsIdsQueryVariables>(GetUserDnaTestsByTestsIdsDocument, options);
        }
export type GetUserDnaTestsByTestsIdsQueryHookResult = ReturnType<typeof useGetUserDnaTestsByTestsIdsQuery>;
export type GetUserDnaTestsByTestsIdsLazyQueryHookResult = ReturnType<typeof useGetUserDnaTestsByTestsIdsLazyQuery>;
export type GetUserDnaTestsByTestsIdsQueryResult = Apollo.QueryResult<GetUserDnaTestsByTestsIdsQuery, GetUserDnaTestsByTestsIdsQueryVariables>;
export const GetAcuityCalendarDocument = gql`
    mutation GetAcuityCalendar($providerId: String!) {
  GetAcuityCalendarInformation(providerId: $providerId) {
    calendar_id
    active
    owner_id
    provider_calendar
    provider_id
  }
}
    `;
export type GetAcuityCalendarMutationFn = Apollo.MutationFunction<GetAcuityCalendarMutation, GetAcuityCalendarMutationVariables>;

/**
 * __useGetAcuityCalendarMutation__
 *
 * To run a mutation, you first call `useGetAcuityCalendarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetAcuityCalendarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getAcuityCalendarMutation, { data, loading, error }] = useGetAcuityCalendarMutation({
 *   variables: {
 *      providerId: // value for 'providerId'
 *   },
 * });
 */
export function useGetAcuityCalendarMutation(baseOptions?: Apollo.MutationHookOptions<GetAcuityCalendarMutation, GetAcuityCalendarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetAcuityCalendarMutation, GetAcuityCalendarMutationVariables>(GetAcuityCalendarDocument, options);
      }
export type GetAcuityCalendarMutationHookResult = ReturnType<typeof useGetAcuityCalendarMutation>;
export type GetAcuityCalendarMutationResult = Apollo.MutationResult<GetAcuityCalendarMutation>;
export type GetAcuityCalendarMutationOptions = Apollo.BaseMutationOptions<GetAcuityCalendarMutation, GetAcuityCalendarMutationVariables>;
export const GetAcuityProviderTimeSlotsDocument = gql`
    query GetAcuityProviderTimeSlots($providerCalendarId: String!, $date: String!) {
  GetProviderTimeSlots(providerCalendarId: $providerCalendarId, date: $date) {
    providerWorkingHours {
      data {
        Monday
        Tuesday
        Wednesday
        Thursday
        Friday
        Saturday
        Sunday
      }
    }
    blocks {
      start
      end
      enabled
    }
  }
}
    `;

/**
 * __useGetAcuityProviderTimeSlotsQuery__
 *
 * To run a query within a React component, call `useGetAcuityProviderTimeSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAcuityProviderTimeSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAcuityProviderTimeSlotsQuery({
 *   variables: {
 *      providerCalendarId: // value for 'providerCalendarId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetAcuityProviderTimeSlotsQuery(baseOptions: Apollo.QueryHookOptions<GetAcuityProviderTimeSlotsQuery, GetAcuityProviderTimeSlotsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAcuityProviderTimeSlotsQuery, GetAcuityProviderTimeSlotsQueryVariables>(GetAcuityProviderTimeSlotsDocument, options);
      }
export function useGetAcuityProviderTimeSlotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAcuityProviderTimeSlotsQuery, GetAcuityProviderTimeSlotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAcuityProviderTimeSlotsQuery, GetAcuityProviderTimeSlotsQueryVariables>(GetAcuityProviderTimeSlotsDocument, options);
        }
export type GetAcuityProviderTimeSlotsQueryHookResult = ReturnType<typeof useGetAcuityProviderTimeSlotsQuery>;
export type GetAcuityProviderTimeSlotsLazyQueryHookResult = ReturnType<typeof useGetAcuityProviderTimeSlotsLazyQuery>;
export type GetAcuityProviderTimeSlotsQueryResult = Apollo.QueryResult<GetAcuityProviderTimeSlotsQuery, GetAcuityProviderTimeSlotsQueryVariables>;
export const GetCountriesDocument = gql`
    query GetCountries {
  countries {
    code
    name
    flag
    id
  }
}
    `;

/**
 * __useGetCountriesQuery__
 *
 * To run a query within a React component, call `useGetCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCountriesQuery(baseOptions?: Apollo.QueryHookOptions<GetCountriesQuery, GetCountriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCountriesQuery, GetCountriesQueryVariables>(GetCountriesDocument, options);
      }
export function useGetCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCountriesQuery, GetCountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCountriesQuery, GetCountriesQueryVariables>(GetCountriesDocument, options);
        }
export type GetCountriesQueryHookResult = ReturnType<typeof useGetCountriesQuery>;
export type GetCountriesLazyQueryHookResult = ReturnType<typeof useGetCountriesLazyQuery>;
export type GetCountriesQueryResult = Apollo.QueryResult<GetCountriesQuery, GetCountriesQueryVariables>;
export const RegisterTestKitDocument = gql`
    mutation RegisterTestKit($swabBarcode: String!, $trackingNumber: String, $questionnaireId: Int!, $testType: String!, $questionnaireResponses: jsonb!) {
  RegisterTestKit(
    testKit: {swabBarcode: $swabBarcode, trackingNumber: $trackingNumber, questionnaireId: $questionnaireId, testType: $testType, questionnaireResponses: $questionnaireResponses}
  ) {
    status
    message
  }
}
    `;
export type RegisterTestKitMutationFn = Apollo.MutationFunction<RegisterTestKitMutation, RegisterTestKitMutationVariables>;

/**
 * __useRegisterTestKitMutation__
 *
 * To run a mutation, you first call `useRegisterTestKitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterTestKitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerTestKitMutation, { data, loading, error }] = useRegisterTestKitMutation({
 *   variables: {
 *      swabBarcode: // value for 'swabBarcode'
 *      trackingNumber: // value for 'trackingNumber'
 *      questionnaireId: // value for 'questionnaireId'
 *      testType: // value for 'testType'
 *      questionnaireResponses: // value for 'questionnaireResponses'
 *   },
 * });
 */
export function useRegisterTestKitMutation(baseOptions?: Apollo.MutationHookOptions<RegisterTestKitMutation, RegisterTestKitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterTestKitMutation, RegisterTestKitMutationVariables>(RegisterTestKitDocument, options);
      }
export type RegisterTestKitMutationHookResult = ReturnType<typeof useRegisterTestKitMutation>;
export type RegisterTestKitMutationResult = Apollo.MutationResult<RegisterTestKitMutation>;
export type RegisterTestKitMutationOptions = Apollo.BaseMutationOptions<RegisterTestKitMutation, RegisterTestKitMutationVariables>;
export const GetDnaVisitIframeSecretDocument = gql`
    mutation GetDNAVisitIframeSecret($userEmail: String!) {
  getDNAVisitIframeSecret(userEmail: $userEmail) {
    dnaVisitIframeSecret
  }
}
    `;
export type GetDnaVisitIframeSecretMutationFn = Apollo.MutationFunction<GetDnaVisitIframeSecretMutation, GetDnaVisitIframeSecretMutationVariables>;

/**
 * __useGetDnaVisitIframeSecretMutation__
 *
 * To run a mutation, you first call `useGetDnaVisitIframeSecretMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetDnaVisitIframeSecretMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getDnaVisitIframeSecretMutation, { data, loading, error }] = useGetDnaVisitIframeSecretMutation({
 *   variables: {
 *      userEmail: // value for 'userEmail'
 *   },
 * });
 */
export function useGetDnaVisitIframeSecretMutation(baseOptions?: Apollo.MutationHookOptions<GetDnaVisitIframeSecretMutation, GetDnaVisitIframeSecretMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetDnaVisitIframeSecretMutation, GetDnaVisitIframeSecretMutationVariables>(GetDnaVisitIframeSecretDocument, options);
      }
export type GetDnaVisitIframeSecretMutationHookResult = ReturnType<typeof useGetDnaVisitIframeSecretMutation>;
export type GetDnaVisitIframeSecretMutationResult = Apollo.MutationResult<GetDnaVisitIframeSecretMutation>;
export type GetDnaVisitIframeSecretMutationOptions = Apollo.BaseMutationOptions<GetDnaVisitIframeSecretMutation, GetDnaVisitIframeSecretMutationVariables>;
export const GetLatestDnaVisitTestsDocument = gql`
    mutation GetLatestDnaVisitTests {
  GetLatestDnaVisitTests
}
    `;
export type GetLatestDnaVisitTestsMutationFn = Apollo.MutationFunction<GetLatestDnaVisitTestsMutation, GetLatestDnaVisitTestsMutationVariables>;

/**
 * __useGetLatestDnaVisitTestsMutation__
 *
 * To run a mutation, you first call `useGetLatestDnaVisitTestsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetLatestDnaVisitTestsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getLatestDnaVisitTestsMutation, { data, loading, error }] = useGetLatestDnaVisitTestsMutation({
 *   variables: {
 *   },
 * });
 */
export function useGetLatestDnaVisitTestsMutation(baseOptions?: Apollo.MutationHookOptions<GetLatestDnaVisitTestsMutation, GetLatestDnaVisitTestsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetLatestDnaVisitTestsMutation, GetLatestDnaVisitTestsMutationVariables>(GetLatestDnaVisitTestsDocument, options);
      }
export type GetLatestDnaVisitTestsMutationHookResult = ReturnType<typeof useGetLatestDnaVisitTestsMutation>;
export type GetLatestDnaVisitTestsMutationResult = Apollo.MutationResult<GetLatestDnaVisitTestsMutation>;
export type GetLatestDnaVisitTestsMutationOptions = Apollo.BaseMutationOptions<GetLatestDnaVisitTestsMutation, GetLatestDnaVisitTestsMutationVariables>;
export const GetDnaTestBySwabcodeIdWithNoErrorsDocument = gql`
    mutation GetDnaTestBySwabcodeIdWithNoErrors($swabCode: String!) {
  getDnaTestBySwabCodeIDWithNoErrors(swab_code: $swabCode) {
    existingDnaTests
  }
}
    `;
export type GetDnaTestBySwabcodeIdWithNoErrorsMutationFn = Apollo.MutationFunction<GetDnaTestBySwabcodeIdWithNoErrorsMutation, GetDnaTestBySwabcodeIdWithNoErrorsMutationVariables>;

/**
 * __useGetDnaTestBySwabcodeIdWithNoErrorsMutation__
 *
 * To run a mutation, you first call `useGetDnaTestBySwabcodeIdWithNoErrorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetDnaTestBySwabcodeIdWithNoErrorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getDnaTestBySwabcodeIdWithNoErrorsMutation, { data, loading, error }] = useGetDnaTestBySwabcodeIdWithNoErrorsMutation({
 *   variables: {
 *      swabCode: // value for 'swabCode'
 *   },
 * });
 */
export function useGetDnaTestBySwabcodeIdWithNoErrorsMutation(baseOptions?: Apollo.MutationHookOptions<GetDnaTestBySwabcodeIdWithNoErrorsMutation, GetDnaTestBySwabcodeIdWithNoErrorsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetDnaTestBySwabcodeIdWithNoErrorsMutation, GetDnaTestBySwabcodeIdWithNoErrorsMutationVariables>(GetDnaTestBySwabcodeIdWithNoErrorsDocument, options);
      }
export type GetDnaTestBySwabcodeIdWithNoErrorsMutationHookResult = ReturnType<typeof useGetDnaTestBySwabcodeIdWithNoErrorsMutation>;
export type GetDnaTestBySwabcodeIdWithNoErrorsMutationResult = Apollo.MutationResult<GetDnaTestBySwabcodeIdWithNoErrorsMutation>;
export type GetDnaTestBySwabcodeIdWithNoErrorsMutationOptions = Apollo.BaseMutationOptions<GetDnaTestBySwabcodeIdWithNoErrorsMutation, GetDnaTestBySwabcodeIdWithNoErrorsMutationVariables>;
export const GetLatestDnaTestsForSuperAdminDocument = gql`
    query GetLatestDnaTestsForSuperAdmin {
  dnavisit_tests(
    order_by: {created_at: desc}
    where: {created_at: {_gte: "2023-10-01T00:00:00Z"}}
  ) {
    state
    errors
    dnavisit_tests_test {
      tests_user {
        SENSITIVE_firstname
        SENSITIVE_lastname
        SENSITIVE_email
        id
      }
      tests_test_definition {
        test_type
      }
    }
    sapphiros_barcode
    created_at
    tracking_number
    dna_test_questionnaire_pdf_id
    health_questionnaire_pdf_id
    provider_report_pdf_id
    patient_pdf_id
    sapphiros_report_pdf_id
    visit_summary_pdf_id
  }
}
    `;

/**
 * __useGetLatestDnaTestsForSuperAdminQuery__
 *
 * To run a query within a React component, call `useGetLatestDnaTestsForSuperAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestDnaTestsForSuperAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestDnaTestsForSuperAdminQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLatestDnaTestsForSuperAdminQuery(baseOptions?: Apollo.QueryHookOptions<GetLatestDnaTestsForSuperAdminQuery, GetLatestDnaTestsForSuperAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLatestDnaTestsForSuperAdminQuery, GetLatestDnaTestsForSuperAdminQueryVariables>(GetLatestDnaTestsForSuperAdminDocument, options);
      }
export function useGetLatestDnaTestsForSuperAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLatestDnaTestsForSuperAdminQuery, GetLatestDnaTestsForSuperAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLatestDnaTestsForSuperAdminQuery, GetLatestDnaTestsForSuperAdminQueryVariables>(GetLatestDnaTestsForSuperAdminDocument, options);
        }
export type GetLatestDnaTestsForSuperAdminQueryHookResult = ReturnType<typeof useGetLatestDnaTestsForSuperAdminQuery>;
export type GetLatestDnaTestsForSuperAdminLazyQueryHookResult = ReturnType<typeof useGetLatestDnaTestsForSuperAdminLazyQuery>;
export type GetLatestDnaTestsForSuperAdminQueryResult = Apollo.QueryResult<GetLatestDnaTestsForSuperAdminQuery, GetLatestDnaTestsForSuperAdminQueryVariables>;
export const ValidateFedexTrackingNumberDocument = gql`
    query ValidateFedexTrackingNumber($trackingNumber: String!) {
  ValidateFedexTrackingNumber(trackingNumber: $trackingNumber) {
    isValid
  }
}
    `;

/**
 * __useValidateFedexTrackingNumberQuery__
 *
 * To run a query within a React component, call `useValidateFedexTrackingNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateFedexTrackingNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateFedexTrackingNumberQuery({
 *   variables: {
 *      trackingNumber: // value for 'trackingNumber'
 *   },
 * });
 */
export function useValidateFedexTrackingNumberQuery(baseOptions: Apollo.QueryHookOptions<ValidateFedexTrackingNumberQuery, ValidateFedexTrackingNumberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidateFedexTrackingNumberQuery, ValidateFedexTrackingNumberQueryVariables>(ValidateFedexTrackingNumberDocument, options);
      }
export function useValidateFedexTrackingNumberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateFedexTrackingNumberQuery, ValidateFedexTrackingNumberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidateFedexTrackingNumberQuery, ValidateFedexTrackingNumberQueryVariables>(ValidateFedexTrackingNumberDocument, options);
        }
export type ValidateFedexTrackingNumberQueryHookResult = ReturnType<typeof useValidateFedexTrackingNumberQuery>;
export type ValidateFedexTrackingNumberLazyQueryHookResult = ReturnType<typeof useValidateFedexTrackingNumberLazyQuery>;
export type ValidateFedexTrackingNumberQueryResult = Apollo.QueryResult<ValidateFedexTrackingNumberQuery, ValidateFedexTrackingNumberQueryVariables>;
export const GetSignedUrlFromStorageDocument = gql`
    mutation GetSignedUrlFromStorage($fileId: String!) {
  GetSignUrlFormStorage(fileId: $fileId) {
    status
    url
  }
}
    `;
export type GetSignedUrlFromStorageMutationFn = Apollo.MutationFunction<GetSignedUrlFromStorageMutation, GetSignedUrlFromStorageMutationVariables>;

/**
 * __useGetSignedUrlFromStorageMutation__
 *
 * To run a mutation, you first call `useGetSignedUrlFromStorageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetSignedUrlFromStorageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getSignedUrlFromStorageMutation, { data, loading, error }] = useGetSignedUrlFromStorageMutation({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useGetSignedUrlFromStorageMutation(baseOptions?: Apollo.MutationHookOptions<GetSignedUrlFromStorageMutation, GetSignedUrlFromStorageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetSignedUrlFromStorageMutation, GetSignedUrlFromStorageMutationVariables>(GetSignedUrlFromStorageDocument, options);
      }
export type GetSignedUrlFromStorageMutationHookResult = ReturnType<typeof useGetSignedUrlFromStorageMutation>;
export type GetSignedUrlFromStorageMutationResult = Apollo.MutationResult<GetSignedUrlFromStorageMutation>;
export type GetSignedUrlFromStorageMutationOptions = Apollo.BaseMutationOptions<GetSignedUrlFromStorageMutation, GetSignedUrlFromStorageMutationVariables>;
export const GetSignedUrlFromStorageByAdminDocument = gql`
    mutation GetSignedUrlFromStorageByAdmin($userId: String!, $fileId: String!) {
  GetSignUrlFromStorageByAdmin(filePath: {userId: $userId, fileId: $fileId}) {
    status
    url
  }
}
    `;
export type GetSignedUrlFromStorageByAdminMutationFn = Apollo.MutationFunction<GetSignedUrlFromStorageByAdminMutation, GetSignedUrlFromStorageByAdminMutationVariables>;

/**
 * __useGetSignedUrlFromStorageByAdminMutation__
 *
 * To run a mutation, you first call `useGetSignedUrlFromStorageByAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetSignedUrlFromStorageByAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getSignedUrlFromStorageByAdminMutation, { data, loading, error }] = useGetSignedUrlFromStorageByAdminMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useGetSignedUrlFromStorageByAdminMutation(baseOptions?: Apollo.MutationHookOptions<GetSignedUrlFromStorageByAdminMutation, GetSignedUrlFromStorageByAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetSignedUrlFromStorageByAdminMutation, GetSignedUrlFromStorageByAdminMutationVariables>(GetSignedUrlFromStorageByAdminDocument, options);
      }
export type GetSignedUrlFromStorageByAdminMutationHookResult = ReturnType<typeof useGetSignedUrlFromStorageByAdminMutation>;
export type GetSignedUrlFromStorageByAdminMutationResult = Apollo.MutationResult<GetSignedUrlFromStorageByAdminMutation>;
export type GetSignedUrlFromStorageByAdminMutationOptions = Apollo.BaseMutationOptions<GetSignedUrlFromStorageByAdminMutation, GetSignedUrlFromStorageByAdminMutationVariables>;
export const UploadFileToStorageDocument = gql`
    mutation UploadFileToStorage($file: UploadFileToStorageInput!) {
  UploadFileToStorage(file: $file) {
    status
    file {
      url
      fileName
      fileId
    }
  }
}
    `;
export type UploadFileToStorageMutationFn = Apollo.MutationFunction<UploadFileToStorageMutation, UploadFileToStorageMutationVariables>;

/**
 * __useUploadFileToStorageMutation__
 *
 * To run a mutation, you first call `useUploadFileToStorageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileToStorageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileToStorageMutation, { data, loading, error }] = useUploadFileToStorageMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadFileToStorageMutation(baseOptions?: Apollo.MutationHookOptions<UploadFileToStorageMutation, UploadFileToStorageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadFileToStorageMutation, UploadFileToStorageMutationVariables>(UploadFileToStorageDocument, options);
      }
export type UploadFileToStorageMutationHookResult = ReturnType<typeof useUploadFileToStorageMutation>;
export type UploadFileToStorageMutationResult = Apollo.MutationResult<UploadFileToStorageMutation>;
export type UploadFileToStorageMutationOptions = Apollo.BaseMutationOptions<UploadFileToStorageMutation, UploadFileToStorageMutationVariables>;
export const UploadVonageFileToStorageDocument = gql`
    mutation UploadVonageFileToStorage($file: UploadVonageFileToStorageInput!, $appointmentId: String!) {
  UploadVonageFileToStorage(file: $file, appointmentId: $appointmentId) {
    status
    file {
      url
      fileName
      fileId
    }
  }
}
    `;
export type UploadVonageFileToStorageMutationFn = Apollo.MutationFunction<UploadVonageFileToStorageMutation, UploadVonageFileToStorageMutationVariables>;

/**
 * __useUploadVonageFileToStorageMutation__
 *
 * To run a mutation, you first call `useUploadVonageFileToStorageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadVonageFileToStorageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadVonageFileToStorageMutation, { data, loading, error }] = useUploadVonageFileToStorageMutation({
 *   variables: {
 *      file: // value for 'file'
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useUploadVonageFileToStorageMutation(baseOptions?: Apollo.MutationHookOptions<UploadVonageFileToStorageMutation, UploadVonageFileToStorageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadVonageFileToStorageMutation, UploadVonageFileToStorageMutationVariables>(UploadVonageFileToStorageDocument, options);
      }
export type UploadVonageFileToStorageMutationHookResult = ReturnType<typeof useUploadVonageFileToStorageMutation>;
export type UploadVonageFileToStorageMutationResult = Apollo.MutationResult<UploadVonageFileToStorageMutation>;
export type UploadVonageFileToStorageMutationOptions = Apollo.BaseMutationOptions<UploadVonageFileToStorageMutation, UploadVonageFileToStorageMutationVariables>;
export const GetVonageSignUrlFormStorageDocument = gql`
    mutation GetVonageSignUrlFormStorage($appointmentId: String!, $fileId: String!) {
  GetVonageSignUrlFormStorage(appointmentId: $appointmentId, fileId: $fileId) {
    status
    url
  }
}
    `;
export type GetVonageSignUrlFormStorageMutationFn = Apollo.MutationFunction<GetVonageSignUrlFormStorageMutation, GetVonageSignUrlFormStorageMutationVariables>;

/**
 * __useGetVonageSignUrlFormStorageMutation__
 *
 * To run a mutation, you first call `useGetVonageSignUrlFormStorageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetVonageSignUrlFormStorageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getVonageSignUrlFormStorageMutation, { data, loading, error }] = useGetVonageSignUrlFormStorageMutation({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useGetVonageSignUrlFormStorageMutation(baseOptions?: Apollo.MutationHookOptions<GetVonageSignUrlFormStorageMutation, GetVonageSignUrlFormStorageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetVonageSignUrlFormStorageMutation, GetVonageSignUrlFormStorageMutationVariables>(GetVonageSignUrlFormStorageDocument, options);
      }
export type GetVonageSignUrlFormStorageMutationHookResult = ReturnType<typeof useGetVonageSignUrlFormStorageMutation>;
export type GetVonageSignUrlFormStorageMutationResult = Apollo.MutationResult<GetVonageSignUrlFormStorageMutation>;
export type GetVonageSignUrlFormStorageMutationOptions = Apollo.BaseMutationOptions<GetVonageSignUrlFormStorageMutation, GetVonageSignUrlFormStorageMutationVariables>;
export const GetProviderPatientSignedUrlDocument = gql`
    mutation GetProviderPatientSignedUrl($patientCodexId: String!, $fileId: String!) {
  GetProviderPatientSignedUrl(patientCodexId: $patientCodexId, fileId: $fileId) {
    status
    url
  }
}
    `;
export type GetProviderPatientSignedUrlMutationFn = Apollo.MutationFunction<GetProviderPatientSignedUrlMutation, GetProviderPatientSignedUrlMutationVariables>;

/**
 * __useGetProviderPatientSignedUrlMutation__
 *
 * To run a mutation, you first call `useGetProviderPatientSignedUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetProviderPatientSignedUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getProviderPatientSignedUrlMutation, { data, loading, error }] = useGetProviderPatientSignedUrlMutation({
 *   variables: {
 *      patientCodexId: // value for 'patientCodexId'
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useGetProviderPatientSignedUrlMutation(baseOptions?: Apollo.MutationHookOptions<GetProviderPatientSignedUrlMutation, GetProviderPatientSignedUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetProviderPatientSignedUrlMutation, GetProviderPatientSignedUrlMutationVariables>(GetProviderPatientSignedUrlDocument, options);
      }
export type GetProviderPatientSignedUrlMutationHookResult = ReturnType<typeof useGetProviderPatientSignedUrlMutation>;
export type GetProviderPatientSignedUrlMutationResult = Apollo.MutationResult<GetProviderPatientSignedUrlMutation>;
export type GetProviderPatientSignedUrlMutationOptions = Apollo.BaseMutationOptions<GetProviderPatientSignedUrlMutation, GetProviderPatientSignedUrlMutationVariables>;
export const GetSignUrlFromStorageForRequesterDocument = gql`
    mutation GetSignUrlFromStorageForRequester($fileId: String!, $requestedUserId: String!) {
  GetSignUrlFromStorageForRequester(
    fileId: $fileId
    requestedUserId: $requestedUserId
  ) {
    status
    url
  }
}
    `;
export type GetSignUrlFromStorageForRequesterMutationFn = Apollo.MutationFunction<GetSignUrlFromStorageForRequesterMutation, GetSignUrlFromStorageForRequesterMutationVariables>;

/**
 * __useGetSignUrlFromStorageForRequesterMutation__
 *
 * To run a mutation, you first call `useGetSignUrlFromStorageForRequesterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetSignUrlFromStorageForRequesterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getSignUrlFromStorageForRequesterMutation, { data, loading, error }] = useGetSignUrlFromStorageForRequesterMutation({
 *   variables: {
 *      fileId: // value for 'fileId'
 *      requestedUserId: // value for 'requestedUserId'
 *   },
 * });
 */
export function useGetSignUrlFromStorageForRequesterMutation(baseOptions?: Apollo.MutationHookOptions<GetSignUrlFromStorageForRequesterMutation, GetSignUrlFromStorageForRequesterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetSignUrlFromStorageForRequesterMutation, GetSignUrlFromStorageForRequesterMutationVariables>(GetSignUrlFromStorageForRequesterDocument, options);
      }
export type GetSignUrlFromStorageForRequesterMutationHookResult = ReturnType<typeof useGetSignUrlFromStorageForRequesterMutation>;
export type GetSignUrlFromStorageForRequesterMutationResult = Apollo.MutationResult<GetSignUrlFromStorageForRequesterMutation>;
export type GetSignUrlFromStorageForRequesterMutationOptions = Apollo.BaseMutationOptions<GetSignUrlFromStorageForRequesterMutation, GetSignUrlFromStorageForRequesterMutationVariables>;
export const GetFileByIdDocument = gql`
    query GetFileById($fileId: uuid!) {
  files_by_pk(id: $fileId) {
    id
    file_name
    created_at
  }
}
    `;

/**
 * __useGetFileByIdQuery__
 *
 * To run a query within a React component, call `useGetFileByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFileByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFileByIdQuery({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useGetFileByIdQuery(baseOptions: Apollo.QueryHookOptions<GetFileByIdQuery, GetFileByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFileByIdQuery, GetFileByIdQueryVariables>(GetFileByIdDocument, options);
      }
export function useGetFileByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFileByIdQuery, GetFileByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFileByIdQuery, GetFileByIdQueryVariables>(GetFileByIdDocument, options);
        }
export type GetFileByIdQueryHookResult = ReturnType<typeof useGetFileByIdQuery>;
export type GetFileByIdLazyQueryHookResult = ReturnType<typeof useGetFileByIdLazyQuery>;
export type GetFileByIdQueryResult = Apollo.QueryResult<GetFileByIdQuery, GetFileByIdQueryVariables>;
export const GetBarcodeScanningDisabledStateDocument = gql`
    subscription GetBarcodeScanningDisabledState {
  global_configs(where: {name: {_eq: BARCODE_SCANNING_DISABLED}}) {
    value
  }
}
    `;

/**
 * __useGetBarcodeScanningDisabledStateSubscription__
 *
 * To run a query within a React component, call `useGetBarcodeScanningDisabledStateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetBarcodeScanningDisabledStateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBarcodeScanningDisabledStateSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetBarcodeScanningDisabledStateSubscription(baseOptions?: Apollo.SubscriptionHookOptions<GetBarcodeScanningDisabledStateSubscription, GetBarcodeScanningDisabledStateSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetBarcodeScanningDisabledStateSubscription, GetBarcodeScanningDisabledStateSubscriptionVariables>(GetBarcodeScanningDisabledStateDocument, options);
      }
export type GetBarcodeScanningDisabledStateSubscriptionHookResult = ReturnType<typeof useGetBarcodeScanningDisabledStateSubscription>;
export type GetBarcodeScanningDisabledStateSubscriptionResult = Apollo.SubscriptionResult<GetBarcodeScanningDisabledStateSubscription>;
export const GetInactivityModalVariablesDocument = gql`
    subscription GetInactivityModalVariables {
  global_configs(
    where: {_or: [{name: {_eq: INACTIVITY_MODAL_STATUS}}, {name: {_eq: INACTIVITY_MODAL_OVERALL_TIMEOUT_VARIABLE}}, {name: {_eq: INACTIVITY_MODAL_PROMPT_BEFORE_IDLE_VARIABLE}}]}
  ) {
    value
  }
}
    `;

/**
 * __useGetInactivityModalVariablesSubscription__
 *
 * To run a query within a React component, call `useGetInactivityModalVariablesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetInactivityModalVariablesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInactivityModalVariablesSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetInactivityModalVariablesSubscription(baseOptions?: Apollo.SubscriptionHookOptions<GetInactivityModalVariablesSubscription, GetInactivityModalVariablesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetInactivityModalVariablesSubscription, GetInactivityModalVariablesSubscriptionVariables>(GetInactivityModalVariablesDocument, options);
      }
export type GetInactivityModalVariablesSubscriptionHookResult = ReturnType<typeof useGetInactivityModalVariablesSubscription>;
export type GetInactivityModalVariablesSubscriptionResult = Apollo.SubscriptionResult<GetInactivityModalVariablesSubscription>;
export const GetUserLogoutBasedOnLastActivityVariablesDocument = gql`
    subscription GetUserLogoutBasedOnLastActivityVariables {
  global_configs(
    where: {_or: [{name: {_eq: USER_LOGOUT_BASED_ON_LAST_ACTIVITY}}, {name: {_eq: USER_LOGOUT_BASED_ON_LAST_ACTIVITY_TIME_LIMIT}}]}
  ) {
    value
  }
}
    `;

/**
 * __useGetUserLogoutBasedOnLastActivityVariablesSubscription__
 *
 * To run a query within a React component, call `useGetUserLogoutBasedOnLastActivityVariablesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetUserLogoutBasedOnLastActivityVariablesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserLogoutBasedOnLastActivityVariablesSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetUserLogoutBasedOnLastActivityVariablesSubscription(baseOptions?: Apollo.SubscriptionHookOptions<GetUserLogoutBasedOnLastActivityVariablesSubscription, GetUserLogoutBasedOnLastActivityVariablesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetUserLogoutBasedOnLastActivityVariablesSubscription, GetUserLogoutBasedOnLastActivityVariablesSubscriptionVariables>(GetUserLogoutBasedOnLastActivityVariablesDocument, options);
      }
export type GetUserLogoutBasedOnLastActivityVariablesSubscriptionHookResult = ReturnType<typeof useGetUserLogoutBasedOnLastActivityVariablesSubscription>;
export type GetUserLogoutBasedOnLastActivityVariablesSubscriptionResult = Apollo.SubscriptionResult<GetUserLogoutBasedOnLastActivityVariablesSubscription>;
export const GetMyVisitSummaryModalDisabledStateDocument = gql`
    subscription GetMyVisitSummaryModalDisabledState {
  global_configs(where: {name: {_eq: MYVISIT_SUMMARY_MODAL_DISABLED}}) {
    value
  }
}
    `;

/**
 * __useGetMyVisitSummaryModalDisabledStateSubscription__
 *
 * To run a query within a React component, call `useGetMyVisitSummaryModalDisabledStateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetMyVisitSummaryModalDisabledStateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyVisitSummaryModalDisabledStateSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetMyVisitSummaryModalDisabledStateSubscription(baseOptions?: Apollo.SubscriptionHookOptions<GetMyVisitSummaryModalDisabledStateSubscription, GetMyVisitSummaryModalDisabledStateSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetMyVisitSummaryModalDisabledStateSubscription, GetMyVisitSummaryModalDisabledStateSubscriptionVariables>(GetMyVisitSummaryModalDisabledStateDocument, options);
      }
export type GetMyVisitSummaryModalDisabledStateSubscriptionHookResult = ReturnType<typeof useGetMyVisitSummaryModalDisabledStateSubscription>;
export type GetMyVisitSummaryModalDisabledStateSubscriptionResult = Apollo.SubscriptionResult<GetMyVisitSummaryModalDisabledStateSubscription>;
export const GetAppointmentsDisabledStateDocument = gql`
    subscription GetAppointmentsDisabledState {
  global_configs(where: {name: {_eq: APPOINTMENT_ROUTES_DISABLE}}) {
    value
  }
}
    `;

/**
 * __useGetAppointmentsDisabledStateSubscription__
 *
 * To run a query within a React component, call `useGetAppointmentsDisabledStateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetAppointmentsDisabledStateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppointmentsDisabledStateSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetAppointmentsDisabledStateSubscription(baseOptions?: Apollo.SubscriptionHookOptions<GetAppointmentsDisabledStateSubscription, GetAppointmentsDisabledStateSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetAppointmentsDisabledStateSubscription, GetAppointmentsDisabledStateSubscriptionVariables>(GetAppointmentsDisabledStateDocument, options);
      }
export type GetAppointmentsDisabledStateSubscriptionHookResult = ReturnType<typeof useGetAppointmentsDisabledStateSubscription>;
export type GetAppointmentsDisabledStateSubscriptionResult = Apollo.SubscriptionResult<GetAppointmentsDisabledStateSubscription>;
export const GetSignUpProviderRouteStateDocument = gql`
    subscription GetSignUpProviderRouteState {
  global_configs(where: {name: {_eq: ENABLE_SIGN_UP_PROVIDER}}) {
    value
  }
}
    `;

/**
 * __useGetSignUpProviderRouteStateSubscription__
 *
 * To run a query within a React component, call `useGetSignUpProviderRouteStateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetSignUpProviderRouteStateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSignUpProviderRouteStateSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetSignUpProviderRouteStateSubscription(baseOptions?: Apollo.SubscriptionHookOptions<GetSignUpProviderRouteStateSubscription, GetSignUpProviderRouteStateSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetSignUpProviderRouteStateSubscription, GetSignUpProviderRouteStateSubscriptionVariables>(GetSignUpProviderRouteStateDocument, options);
      }
export type GetSignUpProviderRouteStateSubscriptionHookResult = ReturnType<typeof useGetSignUpProviderRouteStateSubscription>;
export type GetSignUpProviderRouteStateSubscriptionResult = Apollo.SubscriptionResult<GetSignUpProviderRouteStateSubscription>;
export const GetChannelSignalsDocument = gql`
    subscription GetChannelSignals($channel: String!, $key: String!) {
  channel_signals(where: {channel: {_eq: $channel}, _and: {key: {_eq: $key}}}) {
    last_updated
  }
}
    `;

/**
 * __useGetChannelSignalsSubscription__
 *
 * To run a query within a React component, call `useGetChannelSignalsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetChannelSignalsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChannelSignalsSubscription({
 *   variables: {
 *      channel: // value for 'channel'
 *      key: // value for 'key'
 *   },
 * });
 */
export function useGetChannelSignalsSubscription(baseOptions: Apollo.SubscriptionHookOptions<GetChannelSignalsSubscription, GetChannelSignalsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetChannelSignalsSubscription, GetChannelSignalsSubscriptionVariables>(GetChannelSignalsDocument, options);
      }
export type GetChannelSignalsSubscriptionHookResult = ReturnType<typeof useGetChannelSignalsSubscription>;
export type GetChannelSignalsSubscriptionResult = Apollo.SubscriptionResult<GetChannelSignalsSubscription>;
export const AcceptLegalDocumentDocument = gql`
    mutation AcceptLegalDocument($hash_content: String!, $legal_document_type: String!) {
  AcceptLegalDocument(
    legalDocument: {hash_content: $hash_content, legal_document_type: $legal_document_type}
  ) {
    message
    status
  }
}
    `;
export type AcceptLegalDocumentMutationFn = Apollo.MutationFunction<AcceptLegalDocumentMutation, AcceptLegalDocumentMutationVariables>;

/**
 * __useAcceptLegalDocumentMutation__
 *
 * To run a mutation, you first call `useAcceptLegalDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptLegalDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptLegalDocumentMutation, { data, loading, error }] = useAcceptLegalDocumentMutation({
 *   variables: {
 *      hash_content: // value for 'hash_content'
 *      legal_document_type: // value for 'legal_document_type'
 *   },
 * });
 */
export function useAcceptLegalDocumentMutation(baseOptions?: Apollo.MutationHookOptions<AcceptLegalDocumentMutation, AcceptLegalDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptLegalDocumentMutation, AcceptLegalDocumentMutationVariables>(AcceptLegalDocumentDocument, options);
      }
export type AcceptLegalDocumentMutationHookResult = ReturnType<typeof useAcceptLegalDocumentMutation>;
export type AcceptLegalDocumentMutationResult = Apollo.MutationResult<AcceptLegalDocumentMutation>;
export type AcceptLegalDocumentMutationOptions = Apollo.BaseMutationOptions<AcceptLegalDocumentMutation, AcceptLegalDocumentMutationVariables>;
export const GetAcceptedLegalDocumentsDocument = gql`
    query GetAcceptedLegalDocuments($user_id: uuid!) {
  accepted_legal_documents(where: {user_id: {_eq: $user_id}}) {
    accepted_hash_content
  }
}
    `;

/**
 * __useGetAcceptedLegalDocumentsQuery__
 *
 * To run a query within a React component, call `useGetAcceptedLegalDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAcceptedLegalDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAcceptedLegalDocumentsQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useGetAcceptedLegalDocumentsQuery(baseOptions: Apollo.QueryHookOptions<GetAcceptedLegalDocumentsQuery, GetAcceptedLegalDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAcceptedLegalDocumentsQuery, GetAcceptedLegalDocumentsQueryVariables>(GetAcceptedLegalDocumentsDocument, options);
      }
export function useGetAcceptedLegalDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAcceptedLegalDocumentsQuery, GetAcceptedLegalDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAcceptedLegalDocumentsQuery, GetAcceptedLegalDocumentsQueryVariables>(GetAcceptedLegalDocumentsDocument, options);
        }
export type GetAcceptedLegalDocumentsQueryHookResult = ReturnType<typeof useGetAcceptedLegalDocumentsQuery>;
export type GetAcceptedLegalDocumentsLazyQueryHookResult = ReturnType<typeof useGetAcceptedLegalDocumentsLazyQuery>;
export type GetAcceptedLegalDocumentsQueryResult = Apollo.QueryResult<GetAcceptedLegalDocumentsQuery, GetAcceptedLegalDocumentsQueryVariables>;
export const GetLegalDocumentAcceptanceByLegalDocumentTypeDocument = gql`
    query GetLegalDocumentAcceptanceByLegalDocumentType($user_id: uuid!, $legal_document_type: legal_document_types_enum!) {
  accepted_legal_documents(
    where: {user_id: {_eq: $user_id}, _and: {accepted_legal_documents_legal_document: {legal_document_type: {_eq: $legal_document_type}}}}
  ) {
    termination_date
    expiration_date
    accepted_hash_content
  }
}
    `;

/**
 * __useGetLegalDocumentAcceptanceByLegalDocumentTypeQuery__
 *
 * To run a query within a React component, call `useGetLegalDocumentAcceptanceByLegalDocumentTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLegalDocumentAcceptanceByLegalDocumentTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLegalDocumentAcceptanceByLegalDocumentTypeQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      legal_document_type: // value for 'legal_document_type'
 *   },
 * });
 */
export function useGetLegalDocumentAcceptanceByLegalDocumentTypeQuery(baseOptions: Apollo.QueryHookOptions<GetLegalDocumentAcceptanceByLegalDocumentTypeQuery, GetLegalDocumentAcceptanceByLegalDocumentTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLegalDocumentAcceptanceByLegalDocumentTypeQuery, GetLegalDocumentAcceptanceByLegalDocumentTypeQueryVariables>(GetLegalDocumentAcceptanceByLegalDocumentTypeDocument, options);
      }
export function useGetLegalDocumentAcceptanceByLegalDocumentTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLegalDocumentAcceptanceByLegalDocumentTypeQuery, GetLegalDocumentAcceptanceByLegalDocumentTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLegalDocumentAcceptanceByLegalDocumentTypeQuery, GetLegalDocumentAcceptanceByLegalDocumentTypeQueryVariables>(GetLegalDocumentAcceptanceByLegalDocumentTypeDocument, options);
        }
export type GetLegalDocumentAcceptanceByLegalDocumentTypeQueryHookResult = ReturnType<typeof useGetLegalDocumentAcceptanceByLegalDocumentTypeQuery>;
export type GetLegalDocumentAcceptanceByLegalDocumentTypeLazyQueryHookResult = ReturnType<typeof useGetLegalDocumentAcceptanceByLegalDocumentTypeLazyQuery>;
export type GetLegalDocumentAcceptanceByLegalDocumentTypeQueryResult = Apollo.QueryResult<GetLegalDocumentAcceptanceByLegalDocumentTypeQuery, GetLegalDocumentAcceptanceByLegalDocumentTypeQueryVariables>;
export const UpdateInAppNotificationDocument = gql`
    mutation UpdateInAppNotification($id: String!, $status: String!) {
  UpdateInAppNotification(notification: {id: $id, status: $status}) {
    status
    message
  }
}
    `;
export type UpdateInAppNotificationMutationFn = Apollo.MutationFunction<UpdateInAppNotificationMutation, UpdateInAppNotificationMutationVariables>;

/**
 * __useUpdateInAppNotificationMutation__
 *
 * To run a mutation, you first call `useUpdateInAppNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInAppNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInAppNotificationMutation, { data, loading, error }] = useUpdateInAppNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateInAppNotificationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInAppNotificationMutation, UpdateInAppNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInAppNotificationMutation, UpdateInAppNotificationMutationVariables>(UpdateInAppNotificationDocument, options);
      }
export type UpdateInAppNotificationMutationHookResult = ReturnType<typeof useUpdateInAppNotificationMutation>;
export type UpdateInAppNotificationMutationResult = Apollo.MutationResult<UpdateInAppNotificationMutation>;
export type UpdateInAppNotificationMutationOptions = Apollo.BaseMutationOptions<UpdateInAppNotificationMutation, UpdateInAppNotificationMutationVariables>;
export const UpdateProfileNotificationDocument = gql`
    mutation UpdateProfileNotification {
  UpdateProfileNotification {
    status
    message
  }
}
    `;
export type UpdateProfileNotificationMutationFn = Apollo.MutationFunction<UpdateProfileNotificationMutation, UpdateProfileNotificationMutationVariables>;

/**
 * __useUpdateProfileNotificationMutation__
 *
 * To run a mutation, you first call `useUpdateProfileNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileNotificationMutation, { data, loading, error }] = useUpdateProfileNotificationMutation({
 *   variables: {
 *   },
 * });
 */
export function useUpdateProfileNotificationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileNotificationMutation, UpdateProfileNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfileNotificationMutation, UpdateProfileNotificationMutationVariables>(UpdateProfileNotificationDocument, options);
      }
export type UpdateProfileNotificationMutationHookResult = ReturnType<typeof useUpdateProfileNotificationMutation>;
export type UpdateProfileNotificationMutationResult = Apollo.MutationResult<UpdateProfileNotificationMutation>;
export type UpdateProfileNotificationMutationOptions = Apollo.BaseMutationOptions<UpdateProfileNotificationMutation, UpdateProfileNotificationMutationVariables>;
export const GetUserNotificationByUserIdDocument = gql`
    subscription GetUserNotificationByUserId($limit: Int = 10, $offset: Int = 0, $sort: order_by = desc, $includeStatuses: [user_notification_status_enum!] = [DELIVERED, READ, SEEN], $includePriorities: [Boolean!] = [true, false], $sinceFrom: timestamptz) {
  user_notifications(
    order_by: {created_at: $sort}
    limit: $limit
    offset: $offset
    where: {status: {_in: $includeStatuses}, has_priority: {_in: $includePriorities}, created_at: {_gte: $sinceFrom}}
  ) {
    data
    id
    status
    user_communication_delivery_id
    user_id
  }
}
    `;

/**
 * __useGetUserNotificationByUserIdSubscription__
 *
 * To run a query within a React component, call `useGetUserNotificationByUserIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetUserNotificationByUserIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserNotificationByUserIdSubscription({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sort: // value for 'sort'
 *      includeStatuses: // value for 'includeStatuses'
 *      includePriorities: // value for 'includePriorities'
 *      sinceFrom: // value for 'sinceFrom'
 *   },
 * });
 */
export function useGetUserNotificationByUserIdSubscription(baseOptions?: Apollo.SubscriptionHookOptions<GetUserNotificationByUserIdSubscription, GetUserNotificationByUserIdSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetUserNotificationByUserIdSubscription, GetUserNotificationByUserIdSubscriptionVariables>(GetUserNotificationByUserIdDocument, options);
      }
export type GetUserNotificationByUserIdSubscriptionHookResult = ReturnType<typeof useGetUserNotificationByUserIdSubscription>;
export type GetUserNotificationByUserIdSubscriptionResult = Apollo.SubscriptionResult<GetUserNotificationByUserIdSubscription>;
export const GetUserNotificationCountsByUserIdDocument = gql`
    subscription GetUserNotificationCountsByUserId($includeStatuses: [user_notification_status_enum!] = [DELIVERED, READ, SEEN], $includePriorities: [Boolean!] = [true, false], $sinceFrom: timestamptz) {
  user_notifications_aggregate(
    where: {status: {_in: $includeStatuses}, has_priority: {_in: $includePriorities}, created_at: {_gte: $sinceFrom}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetUserNotificationCountsByUserIdSubscription__
 *
 * To run a query within a React component, call `useGetUserNotificationCountsByUserIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetUserNotificationCountsByUserIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserNotificationCountsByUserIdSubscription({
 *   variables: {
 *      includeStatuses: // value for 'includeStatuses'
 *      includePriorities: // value for 'includePriorities'
 *      sinceFrom: // value for 'sinceFrom'
 *   },
 * });
 */
export function useGetUserNotificationCountsByUserIdSubscription(baseOptions?: Apollo.SubscriptionHookOptions<GetUserNotificationCountsByUserIdSubscription, GetUserNotificationCountsByUserIdSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetUserNotificationCountsByUserIdSubscription, GetUserNotificationCountsByUserIdSubscriptionVariables>(GetUserNotificationCountsByUserIdDocument, options);
      }
export type GetUserNotificationCountsByUserIdSubscriptionHookResult = ReturnType<typeof useGetUserNotificationCountsByUserIdSubscription>;
export type GetUserNotificationCountsByUserIdSubscriptionResult = Apollo.SubscriptionResult<GetUserNotificationCountsByUserIdSubscription>;
export const GetUserNotificationCountsDocument = gql`
    subscription GetUserNotificationCounts {
  user_notifications_aggregate(where: {status: {_neq: READ}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetUserNotificationCountsSubscription__
 *
 * To run a query within a React component, call `useGetUserNotificationCountsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetUserNotificationCountsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserNotificationCountsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetUserNotificationCountsSubscription(baseOptions?: Apollo.SubscriptionHookOptions<GetUserNotificationCountsSubscription, GetUserNotificationCountsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetUserNotificationCountsSubscription, GetUserNotificationCountsSubscriptionVariables>(GetUserNotificationCountsDocument, options);
      }
export type GetUserNotificationCountsSubscriptionHookResult = ReturnType<typeof useGetUserNotificationCountsSubscription>;
export type GetUserNotificationCountsSubscriptionResult = Apollo.SubscriptionResult<GetUserNotificationCountsSubscription>;
export const GetExternalNotificationCountsDocument = gql`
    subscription GetExternalNotificationCounts {
  provider_unread_external_notification_counts {
    dosespot_unread_notifications
  }
}
    `;

/**
 * __useGetExternalNotificationCountsSubscription__
 *
 * To run a query within a React component, call `useGetExternalNotificationCountsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetExternalNotificationCountsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExternalNotificationCountsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetExternalNotificationCountsSubscription(baseOptions?: Apollo.SubscriptionHookOptions<GetExternalNotificationCountsSubscription, GetExternalNotificationCountsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetExternalNotificationCountsSubscription, GetExternalNotificationCountsSubscriptionVariables>(GetExternalNotificationCountsDocument, options);
      }
export type GetExternalNotificationCountsSubscriptionHookResult = ReturnType<typeof useGetExternalNotificationCountsSubscription>;
export type GetExternalNotificationCountsSubscriptionResult = Apollo.SubscriptionResult<GetExternalNotificationCountsSubscription>;
export const SignupProviderDocument = gql`
    mutation SignupProvider($firstname: String!, $lastname: String!, $email: String!, $phone: String!, $nationalProviderIdentifier: String!, $gender: String!, $languagesOfCommunication: jsonb!, $about: String, $credentials: jsonb!, $education: jsonb!, $office_information: jsonb!, $address_information: jsonb!, $firebaseToken: String!) {
  SignupProvider(
    providerData: {firstname: $firstname, lastname: $lastname, email: $email, phone: $phone, nationalProviderIdentifier: $nationalProviderIdentifier, gender: $gender, languagesOfCommunication: $languagesOfCommunication, about: $about, credentials: $credentials, education: $education, office_information: $office_information, address_information: $address_information, firebaseToken: $firebaseToken}
  ) {
    message
    status
  }
}
    `;
export type SignupProviderMutationFn = Apollo.MutationFunction<SignupProviderMutation, SignupProviderMutationVariables>;

/**
 * __useSignupProviderMutation__
 *
 * To run a mutation, you first call `useSignupProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupProviderMutation, { data, loading, error }] = useSignupProviderMutation({
 *   variables: {
 *      firstname: // value for 'firstname'
 *      lastname: // value for 'lastname'
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *      nationalProviderIdentifier: // value for 'nationalProviderIdentifier'
 *      gender: // value for 'gender'
 *      languagesOfCommunication: // value for 'languagesOfCommunication'
 *      about: // value for 'about'
 *      credentials: // value for 'credentials'
 *      education: // value for 'education'
 *      office_information: // value for 'office_information'
 *      address_information: // value for 'address_information'
 *      firebaseToken: // value for 'firebaseToken'
 *   },
 * });
 */
export function useSignupProviderMutation(baseOptions?: Apollo.MutationHookOptions<SignupProviderMutation, SignupProviderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignupProviderMutation, SignupProviderMutationVariables>(SignupProviderDocument, options);
      }
export type SignupProviderMutationHookResult = ReturnType<typeof useSignupProviderMutation>;
export type SignupProviderMutationResult = Apollo.MutationResult<SignupProviderMutation>;
export type SignupProviderMutationOptions = Apollo.BaseMutationOptions<SignupProviderMutation, SignupProviderMutationVariables>;
export const GetDoseSpotPractitionerUrlDocument = gql`
    mutation GetDoseSpotPractitionerURL($providerId: String!, $encounterId: String, $patientId: String, $showNotifications: Boolean!) {
  GetDoseSpotPractitionerURL(
    providerId: $providerId
    encounterId: $encounterId
    patientId: $patientId
    showNotifications: $showNotifications
  ) {
    url
  }
}
    `;
export type GetDoseSpotPractitionerUrlMutationFn = Apollo.MutationFunction<GetDoseSpotPractitionerUrlMutation, GetDoseSpotPractitionerUrlMutationVariables>;

/**
 * __useGetDoseSpotPractitionerUrlMutation__
 *
 * To run a mutation, you first call `useGetDoseSpotPractitionerUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetDoseSpotPractitionerUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getDoseSpotPractitionerUrlMutation, { data, loading, error }] = useGetDoseSpotPractitionerUrlMutation({
 *   variables: {
 *      providerId: // value for 'providerId'
 *      encounterId: // value for 'encounterId'
 *      patientId: // value for 'patientId'
 *      showNotifications: // value for 'showNotifications'
 *   },
 * });
 */
export function useGetDoseSpotPractitionerUrlMutation(baseOptions?: Apollo.MutationHookOptions<GetDoseSpotPractitionerUrlMutation, GetDoseSpotPractitionerUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetDoseSpotPractitionerUrlMutation, GetDoseSpotPractitionerUrlMutationVariables>(GetDoseSpotPractitionerUrlDocument, options);
      }
export type GetDoseSpotPractitionerUrlMutationHookResult = ReturnType<typeof useGetDoseSpotPractitionerUrlMutation>;
export type GetDoseSpotPractitionerUrlMutationResult = Apollo.MutationResult<GetDoseSpotPractitionerUrlMutation>;
export type GetDoseSpotPractitionerUrlMutationOptions = Apollo.BaseMutationOptions<GetDoseSpotPractitionerUrlMutation, GetDoseSpotPractitionerUrlMutationVariables>;
export const GetDosespotExternalUnreadNotificationDocument = gql`
    query GetDosespotExternalUnreadNotification($codexProviderId: uuid!) {
  provider_unread_external_notification_counts(
    where: {codex_provider_id: {_eq: $codexProviderId}}
  ) {
    dosespot_unread_notifications
  }
}
    `;

/**
 * __useGetDosespotExternalUnreadNotificationQuery__
 *
 * To run a query within a React component, call `useGetDosespotExternalUnreadNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDosespotExternalUnreadNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDosespotExternalUnreadNotificationQuery({
 *   variables: {
 *      codexProviderId: // value for 'codexProviderId'
 *   },
 * });
 */
export function useGetDosespotExternalUnreadNotificationQuery(baseOptions: Apollo.QueryHookOptions<GetDosespotExternalUnreadNotificationQuery, GetDosespotExternalUnreadNotificationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDosespotExternalUnreadNotificationQuery, GetDosespotExternalUnreadNotificationQueryVariables>(GetDosespotExternalUnreadNotificationDocument, options);
      }
export function useGetDosespotExternalUnreadNotificationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDosespotExternalUnreadNotificationQuery, GetDosespotExternalUnreadNotificationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDosespotExternalUnreadNotificationQuery, GetDosespotExternalUnreadNotificationQueryVariables>(GetDosespotExternalUnreadNotificationDocument, options);
        }
export type GetDosespotExternalUnreadNotificationQueryHookResult = ReturnType<typeof useGetDosespotExternalUnreadNotificationQuery>;
export type GetDosespotExternalUnreadNotificationLazyQueryHookResult = ReturnType<typeof useGetDosespotExternalUnreadNotificationLazyQuery>;
export type GetDosespotExternalUnreadNotificationQueryResult = Apollo.QueryResult<GetDosespotExternalUnreadNotificationQuery, GetDosespotExternalUnreadNotificationQueryVariables>;
export const SaveQuestionnaireDocument = gql`
    mutation SaveQuestionnaire($questionnaireId: Int!, $responses: jsonb!, $is_complete: Boolean) {
  SaveQuestionnaire(
    questionnaire: {questionnaireId: $questionnaireId, responses: $responses, is_complete: $is_complete}
  ) {
    status
    message
  }
}
    `;
export type SaveQuestionnaireMutationFn = Apollo.MutationFunction<SaveQuestionnaireMutation, SaveQuestionnaireMutationVariables>;

/**
 * __useSaveQuestionnaireMutation__
 *
 * To run a mutation, you first call `useSaveQuestionnaireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveQuestionnaireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveQuestionnaireMutation, { data, loading, error }] = useSaveQuestionnaireMutation({
 *   variables: {
 *      questionnaireId: // value for 'questionnaireId'
 *      responses: // value for 'responses'
 *      is_complete: // value for 'is_complete'
 *   },
 * });
 */
export function useSaveQuestionnaireMutation(baseOptions?: Apollo.MutationHookOptions<SaveQuestionnaireMutation, SaveQuestionnaireMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveQuestionnaireMutation, SaveQuestionnaireMutationVariables>(SaveQuestionnaireDocument, options);
      }
export type SaveQuestionnaireMutationHookResult = ReturnType<typeof useSaveQuestionnaireMutation>;
export type SaveQuestionnaireMutationResult = Apollo.MutationResult<SaveQuestionnaireMutation>;
export type SaveQuestionnaireMutationOptions = Apollo.BaseMutationOptions<SaveQuestionnaireMutation, SaveQuestionnaireMutationVariables>;
export const SaveQuestionnaireManuallyDocument = gql`
    mutation SaveQuestionnaireManually($questionnaireId: Int!, $userID: uuid!, $responses: jsonb!, $isComplete: Boolean!) {
  insert_questionnaire_responses_one(
    object: {responses: $responses, user_id: $userID, is_complete: $isComplete, questionnaire_id: $questionnaireId}
  ) {
    id
    responses
  }
}
    `;
export type SaveQuestionnaireManuallyMutationFn = Apollo.MutationFunction<SaveQuestionnaireManuallyMutation, SaveQuestionnaireManuallyMutationVariables>;

/**
 * __useSaveQuestionnaireManuallyMutation__
 *
 * To run a mutation, you first call `useSaveQuestionnaireManuallyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveQuestionnaireManuallyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveQuestionnaireManuallyMutation, { data, loading, error }] = useSaveQuestionnaireManuallyMutation({
 *   variables: {
 *      questionnaireId: // value for 'questionnaireId'
 *      userID: // value for 'userID'
 *      responses: // value for 'responses'
 *      isComplete: // value for 'isComplete'
 *   },
 * });
 */
export function useSaveQuestionnaireManuallyMutation(baseOptions?: Apollo.MutationHookOptions<SaveQuestionnaireManuallyMutation, SaveQuestionnaireManuallyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveQuestionnaireManuallyMutation, SaveQuestionnaireManuallyMutationVariables>(SaveQuestionnaireManuallyDocument, options);
      }
export type SaveQuestionnaireManuallyMutationHookResult = ReturnType<typeof useSaveQuestionnaireManuallyMutation>;
export type SaveQuestionnaireManuallyMutationResult = Apollo.MutationResult<SaveQuestionnaireManuallyMutation>;
export type SaveQuestionnaireManuallyMutationOptions = Apollo.BaseMutationOptions<SaveQuestionnaireManuallyMutation, SaveQuestionnaireManuallyMutationVariables>;
export const UpdateQuestionnaireDocument = gql`
    mutation UpdateQuestionnaire($questionnaireId: Int!, $responses: jsonb!) {
  UpdateQuestionnaire(
    questionnaire: {questionnaireId: $questionnaireId, responses: $responses}
  ) {
    status
    message
  }
}
    `;
export type UpdateQuestionnaireMutationFn = Apollo.MutationFunction<UpdateQuestionnaireMutation, UpdateQuestionnaireMutationVariables>;

/**
 * __useUpdateQuestionnaireMutation__
 *
 * To run a mutation, you first call `useUpdateQuestionnaireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuestionnaireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuestionnaireMutation, { data, loading, error }] = useUpdateQuestionnaireMutation({
 *   variables: {
 *      questionnaireId: // value for 'questionnaireId'
 *      responses: // value for 'responses'
 *   },
 * });
 */
export function useUpdateQuestionnaireMutation(baseOptions?: Apollo.MutationHookOptions<UpdateQuestionnaireMutation, UpdateQuestionnaireMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateQuestionnaireMutation, UpdateQuestionnaireMutationVariables>(UpdateQuestionnaireDocument, options);
      }
export type UpdateQuestionnaireMutationHookResult = ReturnType<typeof useUpdateQuestionnaireMutation>;
export type UpdateQuestionnaireMutationResult = Apollo.MutationResult<UpdateQuestionnaireMutation>;
export type UpdateQuestionnaireMutationOptions = Apollo.BaseMutationOptions<UpdateQuestionnaireMutation, UpdateQuestionnaireMutationVariables>;
export const UpdateUserQuestionnaireResponseDocument = gql`
    mutation UpdateUserQuestionnaireResponse($newResponse: jsonb!, $userID: uuid!, $questionnaireID: Int!, $is_complete: Boolean) {
  update_questionnaire_responses(
    _set: {responses: $newResponse}
    where: {user_id: {_eq: $userID}, questionnaire_id: {_eq: $questionnaireID}, is_complete: {_eq: $is_complete}}
  ) {
    returning {
      responses
    }
  }
}
    `;
export type UpdateUserQuestionnaireResponseMutationFn = Apollo.MutationFunction<UpdateUserQuestionnaireResponseMutation, UpdateUserQuestionnaireResponseMutationVariables>;

/**
 * __useUpdateUserQuestionnaireResponseMutation__
 *
 * To run a mutation, you first call `useUpdateUserQuestionnaireResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserQuestionnaireResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserQuestionnaireResponseMutation, { data, loading, error }] = useUpdateUserQuestionnaireResponseMutation({
 *   variables: {
 *      newResponse: // value for 'newResponse'
 *      userID: // value for 'userID'
 *      questionnaireID: // value for 'questionnaireID'
 *      is_complete: // value for 'is_complete'
 *   },
 * });
 */
export function useUpdateUserQuestionnaireResponseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserQuestionnaireResponseMutation, UpdateUserQuestionnaireResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserQuestionnaireResponseMutation, UpdateUserQuestionnaireResponseMutationVariables>(UpdateUserQuestionnaireResponseDocument, options);
      }
export type UpdateUserQuestionnaireResponseMutationHookResult = ReturnType<typeof useUpdateUserQuestionnaireResponseMutation>;
export type UpdateUserQuestionnaireResponseMutationResult = Apollo.MutationResult<UpdateUserQuestionnaireResponseMutation>;
export type UpdateUserQuestionnaireResponseMutationOptions = Apollo.BaseMutationOptions<UpdateUserQuestionnaireResponseMutation, UpdateUserQuestionnaireResponseMutationVariables>;
export const UpdateUserLatestQuestionnaireDocument = gql`
    mutation UpdateUserLatestQuestionnaire($id: bigint!, $responses: jsonb!, $isComplete: Boolean!) {
  update_questionnaire_responses(
    where: {id: {_eq: $id}}
    _set: {responses: $responses, is_complete: $isComplete}
  ) {
    affected_rows
  }
}
    `;
export type UpdateUserLatestQuestionnaireMutationFn = Apollo.MutationFunction<UpdateUserLatestQuestionnaireMutation, UpdateUserLatestQuestionnaireMutationVariables>;

/**
 * __useUpdateUserLatestQuestionnaireMutation__
 *
 * To run a mutation, you first call `useUpdateUserLatestQuestionnaireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserLatestQuestionnaireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserLatestQuestionnaireMutation, { data, loading, error }] = useUpdateUserLatestQuestionnaireMutation({
 *   variables: {
 *      id: // value for 'id'
 *      responses: // value for 'responses'
 *      isComplete: // value for 'isComplete'
 *   },
 * });
 */
export function useUpdateUserLatestQuestionnaireMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserLatestQuestionnaireMutation, UpdateUserLatestQuestionnaireMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserLatestQuestionnaireMutation, UpdateUserLatestQuestionnaireMutationVariables>(UpdateUserLatestQuestionnaireDocument, options);
      }
export type UpdateUserLatestQuestionnaireMutationHookResult = ReturnType<typeof useUpdateUserLatestQuestionnaireMutation>;
export type UpdateUserLatestQuestionnaireMutationResult = Apollo.MutationResult<UpdateUserLatestQuestionnaireMutation>;
export type UpdateUserLatestQuestionnaireMutationOptions = Apollo.BaseMutationOptions<UpdateUserLatestQuestionnaireMutation, UpdateUserLatestQuestionnaireMutationVariables>;
export const GetQuestionnaireByIdDocument = gql`
    query GetQuestionnaireById($questionnaire_id: Int!) {
  questionnaires(where: {id: {_eq: $questionnaire_id}}) {
    title
    subtitle
    data
    questionnaires_questionnaire_questions {
      questionnaire_questions_question {
        id
        text
        subtitle
        answers
        ui_control
        dont_know_answer
        double_text_combo_helper_texts
        dropdown_combo_answers
        dropdown_label_1
        dropdown_label_2
        measurement_unit
        response_property
      }
    }
  }
}
    `;

/**
 * __useGetQuestionnaireByIdQuery__
 *
 * To run a query within a React component, call `useGetQuestionnaireByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionnaireByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionnaireByIdQuery({
 *   variables: {
 *      questionnaire_id: // value for 'questionnaire_id'
 *   },
 * });
 */
export function useGetQuestionnaireByIdQuery(baseOptions: Apollo.QueryHookOptions<GetQuestionnaireByIdQuery, GetQuestionnaireByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuestionnaireByIdQuery, GetQuestionnaireByIdQueryVariables>(GetQuestionnaireByIdDocument, options);
      }
export function useGetQuestionnaireByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuestionnaireByIdQuery, GetQuestionnaireByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuestionnaireByIdQuery, GetQuestionnaireByIdQueryVariables>(GetQuestionnaireByIdDocument, options);
        }
export type GetQuestionnaireByIdQueryHookResult = ReturnType<typeof useGetQuestionnaireByIdQuery>;
export type GetQuestionnaireByIdLazyQueryHookResult = ReturnType<typeof useGetQuestionnaireByIdLazyQuery>;
export type GetQuestionnaireByIdQueryResult = Apollo.QueryResult<GetQuestionnaireByIdQuery, GetQuestionnaireByIdQueryVariables>;
export const GetQuestionnaireByUserIdAndQuestionnaireIdDocument = gql`
    query GetQuestionnaireByUserIdAndQuestionnaireID($user_id: uuid!, $questionnaire_id: Int = 40) {
  questionnaire_responses(
    where: {questionnaire_id: {_eq: $questionnaire_id}, user_id: {_eq: $user_id}}
    order_by: {created_at: desc}
    limit: 1
  ) {
    is_complete
  }
}
    `;

/**
 * __useGetQuestionnaireByUserIdAndQuestionnaireIdQuery__
 *
 * To run a query within a React component, call `useGetQuestionnaireByUserIdAndQuestionnaireIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionnaireByUserIdAndQuestionnaireIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionnaireByUserIdAndQuestionnaireIdQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      questionnaire_id: // value for 'questionnaire_id'
 *   },
 * });
 */
export function useGetQuestionnaireByUserIdAndQuestionnaireIdQuery(baseOptions: Apollo.QueryHookOptions<GetQuestionnaireByUserIdAndQuestionnaireIdQuery, GetQuestionnaireByUserIdAndQuestionnaireIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuestionnaireByUserIdAndQuestionnaireIdQuery, GetQuestionnaireByUserIdAndQuestionnaireIdQueryVariables>(GetQuestionnaireByUserIdAndQuestionnaireIdDocument, options);
      }
export function useGetQuestionnaireByUserIdAndQuestionnaireIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuestionnaireByUserIdAndQuestionnaireIdQuery, GetQuestionnaireByUserIdAndQuestionnaireIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuestionnaireByUserIdAndQuestionnaireIdQuery, GetQuestionnaireByUserIdAndQuestionnaireIdQueryVariables>(GetQuestionnaireByUserIdAndQuestionnaireIdDocument, options);
        }
export type GetQuestionnaireByUserIdAndQuestionnaireIdQueryHookResult = ReturnType<typeof useGetQuestionnaireByUserIdAndQuestionnaireIdQuery>;
export type GetQuestionnaireByUserIdAndQuestionnaireIdLazyQueryHookResult = ReturnType<typeof useGetQuestionnaireByUserIdAndQuestionnaireIdLazyQuery>;
export type GetQuestionnaireByUserIdAndQuestionnaireIdQueryResult = Apollo.QueryResult<GetQuestionnaireByUserIdAndQuestionnaireIdQuery, GetQuestionnaireByUserIdAndQuestionnaireIdQueryVariables>;
export const GetQuestionnaireResponsesByUserAndQuestionnaireIdDocument = gql`
    query GetQuestionnaireResponsesByUserAndQuestionnaireID($questionnaire_id: Int!, $user_id: uuid!) {
  questionnaire_responses(
    where: {questionnaire_id: {_eq: $questionnaire_id}, user_id: {_eq: $user_id}}
    order_by: {created_at: desc}
    limit: 1
  ) {
    responses
  }
}
    `;

/**
 * __useGetQuestionnaireResponsesByUserAndQuestionnaireIdQuery__
 *
 * To run a query within a React component, call `useGetQuestionnaireResponsesByUserAndQuestionnaireIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionnaireResponsesByUserAndQuestionnaireIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionnaireResponsesByUserAndQuestionnaireIdQuery({
 *   variables: {
 *      questionnaire_id: // value for 'questionnaire_id'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useGetQuestionnaireResponsesByUserAndQuestionnaireIdQuery(baseOptions: Apollo.QueryHookOptions<GetQuestionnaireResponsesByUserAndQuestionnaireIdQuery, GetQuestionnaireResponsesByUserAndQuestionnaireIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuestionnaireResponsesByUserAndQuestionnaireIdQuery, GetQuestionnaireResponsesByUserAndQuestionnaireIdQueryVariables>(GetQuestionnaireResponsesByUserAndQuestionnaireIdDocument, options);
      }
export function useGetQuestionnaireResponsesByUserAndQuestionnaireIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuestionnaireResponsesByUserAndQuestionnaireIdQuery, GetQuestionnaireResponsesByUserAndQuestionnaireIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuestionnaireResponsesByUserAndQuestionnaireIdQuery, GetQuestionnaireResponsesByUserAndQuestionnaireIdQueryVariables>(GetQuestionnaireResponsesByUserAndQuestionnaireIdDocument, options);
        }
export type GetQuestionnaireResponsesByUserAndQuestionnaireIdQueryHookResult = ReturnType<typeof useGetQuestionnaireResponsesByUserAndQuestionnaireIdQuery>;
export type GetQuestionnaireResponsesByUserAndQuestionnaireIdLazyQueryHookResult = ReturnType<typeof useGetQuestionnaireResponsesByUserAndQuestionnaireIdLazyQuery>;
export type GetQuestionnaireResponsesByUserAndQuestionnaireIdQueryResult = Apollo.QueryResult<GetQuestionnaireResponsesByUserAndQuestionnaireIdQuery, GetQuestionnaireResponsesByUserAndQuestionnaireIdQueryVariables>;
export const GetLatestQuestionnaireByIdDocument = gql`
    query GetLatestQuestionnaireById($questionnaire_id: Int!) {
  questionnaire_responses(
    where: {questionnaire_id: {_eq: $questionnaire_id}, is_complete: {_eq: false}}
    order_by: {created_at: desc}
    limit: 1
  ) {
    responses
    id
  }
}
    `;

/**
 * __useGetLatestQuestionnaireByIdQuery__
 *
 * To run a query within a React component, call `useGetLatestQuestionnaireByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestQuestionnaireByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestQuestionnaireByIdQuery({
 *   variables: {
 *      questionnaire_id: // value for 'questionnaire_id'
 *   },
 * });
 */
export function useGetLatestQuestionnaireByIdQuery(baseOptions: Apollo.QueryHookOptions<GetLatestQuestionnaireByIdQuery, GetLatestQuestionnaireByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLatestQuestionnaireByIdQuery, GetLatestQuestionnaireByIdQueryVariables>(GetLatestQuestionnaireByIdDocument, options);
      }
export function useGetLatestQuestionnaireByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLatestQuestionnaireByIdQuery, GetLatestQuestionnaireByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLatestQuestionnaireByIdQuery, GetLatestQuestionnaireByIdQueryVariables>(GetLatestQuestionnaireByIdDocument, options);
        }
export type GetLatestQuestionnaireByIdQueryHookResult = ReturnType<typeof useGetLatestQuestionnaireByIdQuery>;
export type GetLatestQuestionnaireByIdLazyQueryHookResult = ReturnType<typeof useGetLatestQuestionnaireByIdLazyQuery>;
export type GetLatestQuestionnaireByIdQueryResult = Apollo.QueryResult<GetLatestQuestionnaireByIdQuery, GetLatestQuestionnaireByIdQueryVariables>;
export const UploadCapturedImageDocument = gql`
    mutation UploadCapturedImage($image: String!, $filename: String, $source: String!, $token: String) {
  UploadCapturedImage(
    image: {image: $image, filename: $filename, source: $source, token: $token}
  ) {
    sessionId
    message
    status
  }
}
    `;
export type UploadCapturedImageMutationFn = Apollo.MutationFunction<UploadCapturedImageMutation, UploadCapturedImageMutationVariables>;

/**
 * __useUploadCapturedImageMutation__
 *
 * To run a mutation, you first call `useUploadCapturedImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadCapturedImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadCapturedImageMutation, { data, loading, error }] = useUploadCapturedImageMutation({
 *   variables: {
 *      image: // value for 'image'
 *      filename: // value for 'filename'
 *      source: // value for 'source'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useUploadCapturedImageMutation(baseOptions?: Apollo.MutationHookOptions<UploadCapturedImageMutation, UploadCapturedImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadCapturedImageMutation, UploadCapturedImageMutationVariables>(UploadCapturedImageDocument, options);
      }
export type UploadCapturedImageMutationHookResult = ReturnType<typeof useUploadCapturedImageMutation>;
export type UploadCapturedImageMutationResult = Apollo.MutationResult<UploadCapturedImageMutation>;
export type UploadCapturedImageMutationOptions = Apollo.BaseMutationOptions<UploadCapturedImageMutation, UploadCapturedImageMutationVariables>;
export const GetFileUrlFromStorageDocument = gql`
    mutation GetFileUrlFromStorage($fileId: String!) {
  GetSignUrlFormStorage(fileId: $fileId) {
    url
    status
  }
}
    `;
export type GetFileUrlFromStorageMutationFn = Apollo.MutationFunction<GetFileUrlFromStorageMutation, GetFileUrlFromStorageMutationVariables>;

/**
 * __useGetFileUrlFromStorageMutation__
 *
 * To run a mutation, you first call `useGetFileUrlFromStorageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetFileUrlFromStorageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getFileUrlFromStorageMutation, { data, loading, error }] = useGetFileUrlFromStorageMutation({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useGetFileUrlFromStorageMutation(baseOptions?: Apollo.MutationHookOptions<GetFileUrlFromStorageMutation, GetFileUrlFromStorageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetFileUrlFromStorageMutation, GetFileUrlFromStorageMutationVariables>(GetFileUrlFromStorageDocument, options);
      }
export type GetFileUrlFromStorageMutationHookResult = ReturnType<typeof useGetFileUrlFromStorageMutation>;
export type GetFileUrlFromStorageMutationResult = Apollo.MutationResult<GetFileUrlFromStorageMutation>;
export type GetFileUrlFromStorageMutationOptions = Apollo.BaseMutationOptions<GetFileUrlFromStorageMutation, GetFileUrlFromStorageMutationVariables>;
export const GetSkintellingentTestByUserIdDocument = gql`
    query GetSkintellingentTestByUserId($offset: Int = 0, $limit: Int = 10, $fromDate: timestamptz = "1970-01-01", $toDate: timestamptz = "9999-12-31", $orderBy: [skintelligent_tests_order_by!] = {skintelligent_tests_test: {created_at: desc}}) {
  skintelligent_tests(
    where: {errors: {_is_null: true}, result_image_id: {_is_null: false}, report_id: {_is_null: false}, skintelligent_tests_test: {created_at: {_gt: $fromDate, _lt: $toDate}}}
    order_by: $orderBy
    limit: $limit
    offset: $offset
  ) {
    test_id
    inflammatory
    comedone
    pih
    total_lesions
    report_id
    result_image_id
    skintelligent_tests_test {
      created_at
    }
    skintelligent_tests_image_source_type {
      value
    }
  }
  skintelligent_tests_aggregate(
    where: {errors: {_is_null: true}, result_image_id: {_is_null: false}, report_id: {_is_null: false}, skintelligent_tests_test: {created_at: {_gt: $fromDate, _lt: $toDate}}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetSkintellingentTestByUserIdQuery__
 *
 * To run a query within a React component, call `useGetSkintellingentTestByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSkintellingentTestByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSkintellingentTestByUserIdQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetSkintellingentTestByUserIdQuery(baseOptions?: Apollo.QueryHookOptions<GetSkintellingentTestByUserIdQuery, GetSkintellingentTestByUserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSkintellingentTestByUserIdQuery, GetSkintellingentTestByUserIdQueryVariables>(GetSkintellingentTestByUserIdDocument, options);
      }
export function useGetSkintellingentTestByUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSkintellingentTestByUserIdQuery, GetSkintellingentTestByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSkintellingentTestByUserIdQuery, GetSkintellingentTestByUserIdQueryVariables>(GetSkintellingentTestByUserIdDocument, options);
        }
export type GetSkintellingentTestByUserIdQueryHookResult = ReturnType<typeof useGetSkintellingentTestByUserIdQuery>;
export type GetSkintellingentTestByUserIdLazyQueryHookResult = ReturnType<typeof useGetSkintellingentTestByUserIdLazyQuery>;
export type GetSkintellingentTestByUserIdQueryResult = Apollo.QueryResult<GetSkintellingentTestByUserIdQuery, GetSkintellingentTestByUserIdQueryVariables>;
export const GetSkintellingentTestsCountDocument = gql`
    query GetSkintellingentTestsCount {
  skintelligent_tests_aggregate(
    where: {errors: {_is_null: true}, result_image_id: {_is_null: false}, report_id: {_is_null: false}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetSkintellingentTestsCountQuery__
 *
 * To run a query within a React component, call `useGetSkintellingentTestsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSkintellingentTestsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSkintellingentTestsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSkintellingentTestsCountQuery(baseOptions?: Apollo.QueryHookOptions<GetSkintellingentTestsCountQuery, GetSkintellingentTestsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSkintellingentTestsCountQuery, GetSkintellingentTestsCountQueryVariables>(GetSkintellingentTestsCountDocument, options);
      }
export function useGetSkintellingentTestsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSkintellingentTestsCountQuery, GetSkintellingentTestsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSkintellingentTestsCountQuery, GetSkintellingentTestsCountQueryVariables>(GetSkintellingentTestsCountDocument, options);
        }
export type GetSkintellingentTestsCountQueryHookResult = ReturnType<typeof useGetSkintellingentTestsCountQuery>;
export type GetSkintellingentTestsCountLazyQueryHookResult = ReturnType<typeof useGetSkintellingentTestsCountLazyQuery>;
export type GetSkintellingentTestsCountQueryResult = Apollo.QueryResult<GetSkintellingentTestsCountQuery, GetSkintellingentTestsCountQueryVariables>;
export const GetSkintellingentTestBySessionIdDocument = gql`
    subscription GetSkintellingentTestBySessionId($sessionId: String) {
  skintelligent_tests(where: {session_id: {_eq: $sessionId}}) {
    test_id
    session_id
    result_image_id
    report_id
    pih
    overall_score
    inflammatory
    image_source
    errors
    comedone
    total_lesions
    result_processed
    skintelligent_tests_image_source_type {
      value
      comment
    }
    skintelligent_tests_test {
      created_at
    }
    skintelligent_tests_result_image {
      id
      type
    }
  }
}
    `;

/**
 * __useGetSkintellingentTestBySessionIdSubscription__
 *
 * To run a query within a React component, call `useGetSkintellingentTestBySessionIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetSkintellingentTestBySessionIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSkintellingentTestBySessionIdSubscription({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useGetSkintellingentTestBySessionIdSubscription(baseOptions?: Apollo.SubscriptionHookOptions<GetSkintellingentTestBySessionIdSubscription, GetSkintellingentTestBySessionIdSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetSkintellingentTestBySessionIdSubscription, GetSkintellingentTestBySessionIdSubscriptionVariables>(GetSkintellingentTestBySessionIdDocument, options);
      }
export type GetSkintellingentTestBySessionIdSubscriptionHookResult = ReturnType<typeof useGetSkintellingentTestBySessionIdSubscription>;
export type GetSkintellingentTestBySessionIdSubscriptionResult = Apollo.SubscriptionResult<GetSkintellingentTestBySessionIdSubscription>;
export const GetSkintellingentTestsByUserIdDocument = gql`
    subscription GetSkintellingentTestsByUserId($userId: uuid!) {
  skintelligent_tests_aggregate(
    where: {skintelligent_tests_test: {user_id: {_eq: $userId}}}
    order_by: {skintelligent_tests_test: {created_at: desc}}
  ) {
    nodes {
      session_id
    }
  }
}
    `;

/**
 * __useGetSkintellingentTestsByUserIdSubscription__
 *
 * To run a query within a React component, call `useGetSkintellingentTestsByUserIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetSkintellingentTestsByUserIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSkintellingentTestsByUserIdSubscription({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetSkintellingentTestsByUserIdSubscription(baseOptions: Apollo.SubscriptionHookOptions<GetSkintellingentTestsByUserIdSubscription, GetSkintellingentTestsByUserIdSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetSkintellingentTestsByUserIdSubscription, GetSkintellingentTestsByUserIdSubscriptionVariables>(GetSkintellingentTestsByUserIdDocument, options);
      }
export type GetSkintellingentTestsByUserIdSubscriptionHookResult = ReturnType<typeof useGetSkintellingentTestsByUserIdSubscription>;
export type GetSkintellingentTestsByUserIdSubscriptionResult = Apollo.SubscriptionResult<GetSkintellingentTestsByUserIdSubscription>;
export const UpdateProviderApprovalDocument = gql`
    mutation updateProviderApproval($codexId: String!, $approved: Boolean!) {
  patchCodexApproval(approved: $approved, codexProviderID: $codexId) {
    update_providers_one {
      codex_approved
    }
  }
}
    `;
export type UpdateProviderApprovalMutationFn = Apollo.MutationFunction<UpdateProviderApprovalMutation, UpdateProviderApprovalMutationVariables>;

/**
 * __useUpdateProviderApprovalMutation__
 *
 * To run a mutation, you first call `useUpdateProviderApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProviderApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProviderApprovalMutation, { data, loading, error }] = useUpdateProviderApprovalMutation({
 *   variables: {
 *      codexId: // value for 'codexId'
 *      approved: // value for 'approved'
 *   },
 * });
 */
export function useUpdateProviderApprovalMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProviderApprovalMutation, UpdateProviderApprovalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProviderApprovalMutation, UpdateProviderApprovalMutationVariables>(UpdateProviderApprovalDocument, options);
      }
export type UpdateProviderApprovalMutationHookResult = ReturnType<typeof useUpdateProviderApprovalMutation>;
export type UpdateProviderApprovalMutationResult = Apollo.MutationResult<UpdateProviderApprovalMutation>;
export type UpdateProviderApprovalMutationOptions = Apollo.BaseMutationOptions<UpdateProviderApprovalMutation, UpdateProviderApprovalMutationVariables>;
export const PatchFhirProviderDocument = gql`
    mutation patchFhirProvider($codexId: String!, $input: PatchFHIRProviderInput!) {
  saPatchFHIRProvider(providerCodexId: $codexId, patchFHIRProviderInput: $input) {
    update_providers_one {
      id
    }
  }
}
    `;
export type PatchFhirProviderMutationFn = Apollo.MutationFunction<PatchFhirProviderMutation, PatchFhirProviderMutationVariables>;

/**
 * __usePatchFhirProviderMutation__
 *
 * To run a mutation, you first call `usePatchFhirProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatchFhirProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patchFhirProviderMutation, { data, loading, error }] = usePatchFhirProviderMutation({
 *   variables: {
 *      codexId: // value for 'codexId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePatchFhirProviderMutation(baseOptions?: Apollo.MutationHookOptions<PatchFhirProviderMutation, PatchFhirProviderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PatchFhirProviderMutation, PatchFhirProviderMutationVariables>(PatchFhirProviderDocument, options);
      }
export type PatchFhirProviderMutationHookResult = ReturnType<typeof usePatchFhirProviderMutation>;
export type PatchFhirProviderMutationResult = Apollo.MutationResult<PatchFhirProviderMutation>;
export type PatchFhirProviderMutationOptions = Apollo.BaseMutationOptions<PatchFhirProviderMutation, PatchFhirProviderMutationVariables>;
export const GetDiagnosticReportsDocument = gql`
    query getDiagnosticReports($limit: Int, $pageToken: String, $patientFhirId: String, $providerFhirId: String, $types: [String!]) {
  getDiagnosticReports(
    paging: {limit: $limit, pageToken: $pageToken}
    narrowing: {patientFhirId: $patientFhirId, providerFhirId: $providerFhirId}
    types: $types
  ) {
    count
    total
    nextPageToken
    prevPageToken
    reports {
      dates
      type
      identifiers
      status
      artifacts
      flags
      patient {
        identifiers
        first
        last
      }
      provider {
        identifiers
        first
        last
      }
    }
  }
}
    `;

/**
 * __useGetDiagnosticReportsQuery__
 *
 * To run a query within a React component, call `useGetDiagnosticReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiagnosticReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiagnosticReportsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      pageToken: // value for 'pageToken'
 *      patientFhirId: // value for 'patientFhirId'
 *      providerFhirId: // value for 'providerFhirId'
 *      types: // value for 'types'
 *   },
 * });
 */
export function useGetDiagnosticReportsQuery(baseOptions?: Apollo.QueryHookOptions<GetDiagnosticReportsQuery, GetDiagnosticReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDiagnosticReportsQuery, GetDiagnosticReportsQueryVariables>(GetDiagnosticReportsDocument, options);
      }
export function useGetDiagnosticReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDiagnosticReportsQuery, GetDiagnosticReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDiagnosticReportsQuery, GetDiagnosticReportsQueryVariables>(GetDiagnosticReportsDocument, options);
        }
export type GetDiagnosticReportsQueryHookResult = ReturnType<typeof useGetDiagnosticReportsQuery>;
export type GetDiagnosticReportsLazyQueryHookResult = ReturnType<typeof useGetDiagnosticReportsLazyQuery>;
export type GetDiagnosticReportsQueryResult = Apollo.QueryResult<GetDiagnosticReportsQuery, GetDiagnosticReportsQueryVariables>;
export const GetAppointmentsDocument = gql`
    query getAppointments($patientFhirId: String, $providerFhirId: String, $statuses: [String!]) {
  getAppointments(
    paging: {limit: 500}
    narrowing: {patientFhirId: $patientFhirId, providerFhirId: $providerFhirId}
    statuses: $statuses
  ) {
    count
    total
    nextPageToken
    prevPageToken
    appointments {
      identifiers
      type
      dates
      status
      logs
      patient {
        identifiers
        first
        last
      }
      provider {
        identifiers
        first
        last
      }
    }
  }
}
    `;

/**
 * __useGetAppointmentsQuery__
 *
 * To run a query within a React component, call `useGetAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppointmentsQuery({
 *   variables: {
 *      patientFhirId: // value for 'patientFhirId'
 *      providerFhirId: // value for 'providerFhirId'
 *      statuses: // value for 'statuses'
 *   },
 * });
 */
export function useGetAppointmentsQuery(baseOptions?: Apollo.QueryHookOptions<GetAppointmentsQuery, GetAppointmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAppointmentsQuery, GetAppointmentsQueryVariables>(GetAppointmentsDocument, options);
      }
export function useGetAppointmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAppointmentsQuery, GetAppointmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAppointmentsQuery, GetAppointmentsQueryVariables>(GetAppointmentsDocument, options);
        }
export type GetAppointmentsQueryHookResult = ReturnType<typeof useGetAppointmentsQuery>;
export type GetAppointmentsLazyQueryHookResult = ReturnType<typeof useGetAppointmentsLazyQuery>;
export type GetAppointmentsQueryResult = Apollo.QueryResult<GetAppointmentsQuery, GetAppointmentsQueryVariables>;
export const GetPatientsDocument = gql`
    query getPatients($search: String) {
  getPatients(paging: {limit: 500, search: $search}) {
    count
    nextPageToken
    prevPageToken
    total
    patients {
      dates
      appointmentCount
      testKitCount
      nextAppointment {
        identifiers
        dates
      }
      identifiers
      first
      last
    }
  }
}
    `;

/**
 * __useGetPatientsQuery__
 *
 * To run a query within a React component, call `useGetPatientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetPatientsQuery(baseOptions?: Apollo.QueryHookOptions<GetPatientsQuery, GetPatientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPatientsQuery, GetPatientsQueryVariables>(GetPatientsDocument, options);
      }
export function useGetPatientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPatientsQuery, GetPatientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPatientsQuery, GetPatientsQueryVariables>(GetPatientsDocument, options);
        }
export type GetPatientsQueryHookResult = ReturnType<typeof useGetPatientsQuery>;
export type GetPatientsLazyQueryHookResult = ReturnType<typeof useGetPatientsLazyQuery>;
export type GetPatientsQueryResult = Apollo.QueryResult<GetPatientsQuery, GetPatientsQueryVariables>;
export const GetProvidersDocument = gql`
    query getProviders($search: String) {
  getProviders(paging: {limit: 500, search: $search}) {
    count
    nextPageToken
    prevPageToken
    total
    providers {
      dates
      appointmentCount
      first
      flags
      identifiers
      last
      patientCount
    }
  }
}
    `;

/**
 * __useGetProvidersQuery__
 *
 * To run a query within a React component, call `useGetProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProvidersQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetProvidersQuery(baseOptions?: Apollo.QueryHookOptions<GetProvidersQuery, GetProvidersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProvidersQuery, GetProvidersQueryVariables>(GetProvidersDocument, options);
      }
export function useGetProvidersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProvidersQuery, GetProvidersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProvidersQuery, GetProvidersQueryVariables>(GetProvidersDocument, options);
        }
export type GetProvidersQueryHookResult = ReturnType<typeof useGetProvidersQuery>;
export type GetProvidersLazyQueryHookResult = ReturnType<typeof useGetProvidersLazyQuery>;
export type GetProvidersQueryResult = Apollo.QueryResult<GetProvidersQuery, GetProvidersQueryVariables>;
export const GetSwabCodeValidBySwabcodeDocument = gql`
    query GetSwabCodeValidBySwabcode($swabcode: String!) {
  GetSwabCodeValidBySwabcode(swabcode: $swabcode) {
    isValid
    id
    lab_name
    status
    swab_code
    swab_usage_type
    test_type
  }
}
    `;

/**
 * __useGetSwabCodeValidBySwabcodeQuery__
 *
 * To run a query within a React component, call `useGetSwabCodeValidBySwabcodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSwabCodeValidBySwabcodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSwabCodeValidBySwabcodeQuery({
 *   variables: {
 *      swabcode: // value for 'swabcode'
 *   },
 * });
 */
export function useGetSwabCodeValidBySwabcodeQuery(baseOptions: Apollo.QueryHookOptions<GetSwabCodeValidBySwabcodeQuery, GetSwabCodeValidBySwabcodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSwabCodeValidBySwabcodeQuery, GetSwabCodeValidBySwabcodeQueryVariables>(GetSwabCodeValidBySwabcodeDocument, options);
      }
export function useGetSwabCodeValidBySwabcodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSwabCodeValidBySwabcodeQuery, GetSwabCodeValidBySwabcodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSwabCodeValidBySwabcodeQuery, GetSwabCodeValidBySwabcodeQueryVariables>(GetSwabCodeValidBySwabcodeDocument, options);
        }
export type GetSwabCodeValidBySwabcodeQueryHookResult = ReturnType<typeof useGetSwabCodeValidBySwabcodeQuery>;
export type GetSwabCodeValidBySwabcodeLazyQueryHookResult = ReturnType<typeof useGetSwabCodeValidBySwabcodeLazyQuery>;
export type GetSwabCodeValidBySwabcodeQueryResult = Apollo.QueryResult<GetSwabCodeValidBySwabcodeQuery, GetSwabCodeValidBySwabcodeQueryVariables>;
export const GetUnauthenticatedQuestionnaireByIdDocument = gql`
    query GetUnauthenticatedQuestionnaireById($questionnaire_id: Int!) {
  unauthenticated_questionnaires(where: {id: {_eq: $questionnaire_id}}) {
    title
    subtitle
    data
    unauthenticated_questionnaire_questions {
      unauthenticated_questionnaire_questions_unauthenticated_question {
        id
        text
        subtitle
        answers
        ui_control
        dont_know_answer
        double_text_combo_helper_texts
        dropdown_combo_answers
        dropdown_label_1
        dropdown_label_2
        response_property
        has_tooltip
        tooltip_content
      }
    }
  }
}
    `;

/**
 * __useGetUnauthenticatedQuestionnaireByIdQuery__
 *
 * To run a query within a React component, call `useGetUnauthenticatedQuestionnaireByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnauthenticatedQuestionnaireByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnauthenticatedQuestionnaireByIdQuery({
 *   variables: {
 *      questionnaire_id: // value for 'questionnaire_id'
 *   },
 * });
 */
export function useGetUnauthenticatedQuestionnaireByIdQuery(baseOptions: Apollo.QueryHookOptions<GetUnauthenticatedQuestionnaireByIdQuery, GetUnauthenticatedQuestionnaireByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnauthenticatedQuestionnaireByIdQuery, GetUnauthenticatedQuestionnaireByIdQueryVariables>(GetUnauthenticatedQuestionnaireByIdDocument, options);
      }
export function useGetUnauthenticatedQuestionnaireByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnauthenticatedQuestionnaireByIdQuery, GetUnauthenticatedQuestionnaireByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnauthenticatedQuestionnaireByIdQuery, GetUnauthenticatedQuestionnaireByIdQueryVariables>(GetUnauthenticatedQuestionnaireByIdDocument, options);
        }
export type GetUnauthenticatedQuestionnaireByIdQueryHookResult = ReturnType<typeof useGetUnauthenticatedQuestionnaireByIdQuery>;
export type GetUnauthenticatedQuestionnaireByIdLazyQueryHookResult = ReturnType<typeof useGetUnauthenticatedQuestionnaireByIdLazyQuery>;
export type GetUnauthenticatedQuestionnaireByIdQueryResult = Apollo.QueryResult<GetUnauthenticatedQuestionnaireByIdQuery, GetUnauthenticatedQuestionnaireByIdQueryVariables>;
export const GetUnauthenticatedQuestionsByIdsDocument = gql`
    query GetUnauthenticatedQuestionsByIds($ids: [Int!]!) {
  unauthenticated_questions(where: {id: {_in: $ids}}) {
    id
    text
    subtitle
    answers
  }
}
    `;

/**
 * __useGetUnauthenticatedQuestionsByIdsQuery__
 *
 * To run a query within a React component, call `useGetUnauthenticatedQuestionsByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnauthenticatedQuestionsByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnauthenticatedQuestionsByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetUnauthenticatedQuestionsByIdsQuery(baseOptions: Apollo.QueryHookOptions<GetUnauthenticatedQuestionsByIdsQuery, GetUnauthenticatedQuestionsByIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnauthenticatedQuestionsByIdsQuery, GetUnauthenticatedQuestionsByIdsQueryVariables>(GetUnauthenticatedQuestionsByIdsDocument, options);
      }
export function useGetUnauthenticatedQuestionsByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnauthenticatedQuestionsByIdsQuery, GetUnauthenticatedQuestionsByIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnauthenticatedQuestionsByIdsQuery, GetUnauthenticatedQuestionsByIdsQueryVariables>(GetUnauthenticatedQuestionsByIdsDocument, options);
        }
export type GetUnauthenticatedQuestionsByIdsQueryHookResult = ReturnType<typeof useGetUnauthenticatedQuestionsByIdsQuery>;
export type GetUnauthenticatedQuestionsByIdsLazyQueryHookResult = ReturnType<typeof useGetUnauthenticatedQuestionsByIdsLazyQuery>;
export type GetUnauthenticatedQuestionsByIdsQueryResult = Apollo.QueryResult<GetUnauthenticatedQuestionsByIdsQuery, GetUnauthenticatedQuestionsByIdsQueryVariables>;
export const SaveDnaScannedDataDocument = gql`
    mutation SaveDnaScannedData($testType: String, $swabBarcode: String, $trackingNumber: String, $skipTrackingNumber: Boolean, $token: String) {
  SaveDnaScannedData(
    dnaScannedData: {testType: $testType, swabBarcode: $swabBarcode, trackingNumber: $trackingNumber, skipTrackingNumber: $skipTrackingNumber, token: $token}
  ) {
    message
    status
  }
}
    `;
export type SaveDnaScannedDataMutationFn = Apollo.MutationFunction<SaveDnaScannedDataMutation, SaveDnaScannedDataMutationVariables>;

/**
 * __useSaveDnaScannedDataMutation__
 *
 * To run a mutation, you first call `useSaveDnaScannedDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveDnaScannedDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveDnaScannedDataMutation, { data, loading, error }] = useSaveDnaScannedDataMutation({
 *   variables: {
 *      testType: // value for 'testType'
 *      swabBarcode: // value for 'swabBarcode'
 *      trackingNumber: // value for 'trackingNumber'
 *      skipTrackingNumber: // value for 'skipTrackingNumber'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useSaveDnaScannedDataMutation(baseOptions?: Apollo.MutationHookOptions<SaveDnaScannedDataMutation, SaveDnaScannedDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveDnaScannedDataMutation, SaveDnaScannedDataMutationVariables>(SaveDnaScannedDataDocument, options);
      }
export type SaveDnaScannedDataMutationHookResult = ReturnType<typeof useSaveDnaScannedDataMutation>;
export type SaveDnaScannedDataMutationResult = Apollo.MutationResult<SaveDnaScannedDataMutation>;
export type SaveDnaScannedDataMutationOptions = Apollo.BaseMutationOptions<SaveDnaScannedDataMutation, SaveDnaScannedDataMutationVariables>;
export const ClearAllSavedDnaScannedDataForUserIdDocument = gql`
    mutation ClearAllSavedDnaScannedDataForUserId($userId: uuid!) {
  delete_user_scanned_data(where: {user_id: {_eq: $userId}}) {
    affected_rows
  }
}
    `;
export type ClearAllSavedDnaScannedDataForUserIdMutationFn = Apollo.MutationFunction<ClearAllSavedDnaScannedDataForUserIdMutation, ClearAllSavedDnaScannedDataForUserIdMutationVariables>;

/**
 * __useClearAllSavedDnaScannedDataForUserIdMutation__
 *
 * To run a mutation, you first call `useClearAllSavedDnaScannedDataForUserIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearAllSavedDnaScannedDataForUserIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearAllSavedDnaScannedDataForUserIdMutation, { data, loading, error }] = useClearAllSavedDnaScannedDataForUserIdMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useClearAllSavedDnaScannedDataForUserIdMutation(baseOptions?: Apollo.MutationHookOptions<ClearAllSavedDnaScannedDataForUserIdMutation, ClearAllSavedDnaScannedDataForUserIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClearAllSavedDnaScannedDataForUserIdMutation, ClearAllSavedDnaScannedDataForUserIdMutationVariables>(ClearAllSavedDnaScannedDataForUserIdDocument, options);
      }
export type ClearAllSavedDnaScannedDataForUserIdMutationHookResult = ReturnType<typeof useClearAllSavedDnaScannedDataForUserIdMutation>;
export type ClearAllSavedDnaScannedDataForUserIdMutationResult = Apollo.MutationResult<ClearAllSavedDnaScannedDataForUserIdMutation>;
export type ClearAllSavedDnaScannedDataForUserIdMutationOptions = Apollo.BaseMutationOptions<ClearAllSavedDnaScannedDataForUserIdMutation, ClearAllSavedDnaScannedDataForUserIdMutationVariables>;
export const GetDnaScannedDataByUserIdDocument = gql`
    subscription GetDnaScannedDataByUserId($user_id: uuid!) {
  user_scanned_data(where: {user_id: {_eq: $user_id}}) {
    test_type
    swab_barcode
    tracking_number
    skip_tracking_number
    scan_completed
    updated_at
  }
}
    `;

/**
 * __useGetDnaScannedDataByUserIdSubscription__
 *
 * To run a query within a React component, call `useGetDnaScannedDataByUserIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetDnaScannedDataByUserIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDnaScannedDataByUserIdSubscription({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useGetDnaScannedDataByUserIdSubscription(baseOptions: Apollo.SubscriptionHookOptions<GetDnaScannedDataByUserIdSubscription, GetDnaScannedDataByUserIdSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetDnaScannedDataByUserIdSubscription, GetDnaScannedDataByUserIdSubscriptionVariables>(GetDnaScannedDataByUserIdDocument, options);
      }
export type GetDnaScannedDataByUserIdSubscriptionHookResult = ReturnType<typeof useGetDnaScannedDataByUserIdSubscription>;
export type GetDnaScannedDataByUserIdSubscriptionResult = Apollo.SubscriptionResult<GetDnaScannedDataByUserIdSubscription>;
export const SignupUserDocument = gql`
    mutation SignupUser($dob: String, $email: String!, $firebaseToken: String!, $firstname: String, $gender: String, $lastname: String, $phone: String, $country: String) {
  SignupUser(
    userData: {dob: $dob, email: $email, firebaseToken: $firebaseToken, firstname: $firstname, gender: $gender, lastname: $lastname, phone: $phone, country: $country}
  ) {
    message
    status
  }
}
    `;
export type SignupUserMutationFn = Apollo.MutationFunction<SignupUserMutation, SignupUserMutationVariables>;

/**
 * __useSignupUserMutation__
 *
 * To run a mutation, you first call `useSignupUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupUserMutation, { data, loading, error }] = useSignupUserMutation({
 *   variables: {
 *      dob: // value for 'dob'
 *      email: // value for 'email'
 *      firebaseToken: // value for 'firebaseToken'
 *      firstname: // value for 'firstname'
 *      gender: // value for 'gender'
 *      lastname: // value for 'lastname'
 *      phone: // value for 'phone'
 *      country: // value for 'country'
 *   },
 * });
 */
export function useSignupUserMutation(baseOptions?: Apollo.MutationHookOptions<SignupUserMutation, SignupUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignupUserMutation, SignupUserMutationVariables>(SignupUserDocument, options);
      }
export type SignupUserMutationHookResult = ReturnType<typeof useSignupUserMutation>;
export type SignupUserMutationResult = Apollo.MutationResult<SignupUserMutation>;
export type SignupUserMutationOptions = Apollo.BaseMutationOptions<SignupUserMutation, SignupUserMutationVariables>;
export const GenerateFirebaseCustomTokenDocument = gql`
    mutation GenerateFirebaseCustomToken($firebaseToken: String!) {
  GenerateFirebaseCustomToken(token: {firebaseToken: $firebaseToken}) {
    token
    message
    status
  }
}
    `;
export type GenerateFirebaseCustomTokenMutationFn = Apollo.MutationFunction<GenerateFirebaseCustomTokenMutation, GenerateFirebaseCustomTokenMutationVariables>;

/**
 * __useGenerateFirebaseCustomTokenMutation__
 *
 * To run a mutation, you first call `useGenerateFirebaseCustomTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateFirebaseCustomTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateFirebaseCustomTokenMutation, { data, loading, error }] = useGenerateFirebaseCustomTokenMutation({
 *   variables: {
 *      firebaseToken: // value for 'firebaseToken'
 *   },
 * });
 */
export function useGenerateFirebaseCustomTokenMutation(baseOptions?: Apollo.MutationHookOptions<GenerateFirebaseCustomTokenMutation, GenerateFirebaseCustomTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateFirebaseCustomTokenMutation, GenerateFirebaseCustomTokenMutationVariables>(GenerateFirebaseCustomTokenDocument, options);
      }
export type GenerateFirebaseCustomTokenMutationHookResult = ReturnType<typeof useGenerateFirebaseCustomTokenMutation>;
export type GenerateFirebaseCustomTokenMutationResult = Apollo.MutationResult<GenerateFirebaseCustomTokenMutation>;
export type GenerateFirebaseCustomTokenMutationOptions = Apollo.BaseMutationOptions<GenerateFirebaseCustomTokenMutation, GenerateFirebaseCustomTokenMutationVariables>;
export const GenerateCustomTokenDocument = gql`
    mutation GenerateCustomToken {
  GenerateCustomToken {
    token
    message
    status
  }
}
    `;
export type GenerateCustomTokenMutationFn = Apollo.MutationFunction<GenerateCustomTokenMutation, GenerateCustomTokenMutationVariables>;

/**
 * __useGenerateCustomTokenMutation__
 *
 * To run a mutation, you first call `useGenerateCustomTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateCustomTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateCustomTokenMutation, { data, loading, error }] = useGenerateCustomTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useGenerateCustomTokenMutation(baseOptions?: Apollo.MutationHookOptions<GenerateCustomTokenMutation, GenerateCustomTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateCustomTokenMutation, GenerateCustomTokenMutationVariables>(GenerateCustomTokenDocument, options);
      }
export type GenerateCustomTokenMutationHookResult = ReturnType<typeof useGenerateCustomTokenMutation>;
export type GenerateCustomTokenMutationResult = Apollo.MutationResult<GenerateCustomTokenMutation>;
export type GenerateCustomTokenMutationOptions = Apollo.BaseMutationOptions<GenerateCustomTokenMutation, GenerateCustomTokenMutationVariables>;
export const ValidateCustomTokenDocument = gql`
    mutation ValidateCustomToken($token: String!) {
  ValidateCustomToken(token: {token: $token}) {
    message
    status
  }
}
    `;
export type ValidateCustomTokenMutationFn = Apollo.MutationFunction<ValidateCustomTokenMutation, ValidateCustomTokenMutationVariables>;

/**
 * __useValidateCustomTokenMutation__
 *
 * To run a mutation, you first call `useValidateCustomTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateCustomTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateCustomTokenMutation, { data, loading, error }] = useValidateCustomTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useValidateCustomTokenMutation(baseOptions?: Apollo.MutationHookOptions<ValidateCustomTokenMutation, ValidateCustomTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ValidateCustomTokenMutation, ValidateCustomTokenMutationVariables>(ValidateCustomTokenDocument, options);
      }
export type ValidateCustomTokenMutationHookResult = ReturnType<typeof useValidateCustomTokenMutation>;
export type ValidateCustomTokenMutationResult = Apollo.MutationResult<ValidateCustomTokenMutation>;
export type ValidateCustomTokenMutationOptions = Apollo.BaseMutationOptions<ValidateCustomTokenMutation, ValidateCustomTokenMutationVariables>;
export const AccountDeletionDocument = gql`
    mutation AccountDeletion($token: String!, $userId: String!) {
  AccountDeletion(account: {token: $token, userId: $userId}) {
    status
    message
  }
}
    `;
export type AccountDeletionMutationFn = Apollo.MutationFunction<AccountDeletionMutation, AccountDeletionMutationVariables>;

/**
 * __useAccountDeletionMutation__
 *
 * To run a mutation, you first call `useAccountDeletionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountDeletionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountDeletionMutation, { data, loading, error }] = useAccountDeletionMutation({
 *   variables: {
 *      token: // value for 'token'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAccountDeletionMutation(baseOptions?: Apollo.MutationHookOptions<AccountDeletionMutation, AccountDeletionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AccountDeletionMutation, AccountDeletionMutationVariables>(AccountDeletionDocument, options);
      }
export type AccountDeletionMutationHookResult = ReturnType<typeof useAccountDeletionMutation>;
export type AccountDeletionMutationResult = Apollo.MutationResult<AccountDeletionMutation>;
export type AccountDeletionMutationOptions = Apollo.BaseMutationOptions<AccountDeletionMutation, AccountDeletionMutationVariables>;
export const UpdatePersonalInfoDocument = gql`
    mutation UpdatePersonalInfo($id: uuid!, $SENSITIVE_dob: date, $SENSITIVE_etnicity: jsonb, $SENSITIVE_firstname: String, $SENSITIVE_gender: String, $SENSITIVE_lastname: String, $SENSITIVE_self_identity_gender: String, $SENSITIVE_user_measurements: jsonb) {
  update_users_by_pk(
    pk_columns: {id: $id}
    _set: {SENSITIVE_dob: $SENSITIVE_dob, SENSITIVE_etnicity: $SENSITIVE_etnicity, SENSITIVE_firstname: $SENSITIVE_firstname, SENSITIVE_gender: $SENSITIVE_gender, SENSITIVE_lastname: $SENSITIVE_lastname, SENSITIVE_self_identity_gender: $SENSITIVE_self_identity_gender, SENSITIVE_user_measurements: $SENSITIVE_user_measurements}
  ) {
    SENSITIVE_address_information
    SENSITIVE_country
    SENSITIVE_dob
    SENSITIVE_email
    SENSITIVE_etnicity
    SENSITIVE_firstname
    SENSITIVE_gender
    SENSITIVE_lastname
    SENSITIVE_phone
    SENSITIVE_self_identity_gender
    SENSITIVE_user_measurements
    id
  }
}
    `;
export type UpdatePersonalInfoMutationFn = Apollo.MutationFunction<UpdatePersonalInfoMutation, UpdatePersonalInfoMutationVariables>;

/**
 * __useUpdatePersonalInfoMutation__
 *
 * To run a mutation, you first call `useUpdatePersonalInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonalInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonalInfoMutation, { data, loading, error }] = useUpdatePersonalInfoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      SENSITIVE_dob: // value for 'SENSITIVE_dob'
 *      SENSITIVE_etnicity: // value for 'SENSITIVE_etnicity'
 *      SENSITIVE_firstname: // value for 'SENSITIVE_firstname'
 *      SENSITIVE_gender: // value for 'SENSITIVE_gender'
 *      SENSITIVE_lastname: // value for 'SENSITIVE_lastname'
 *      SENSITIVE_self_identity_gender: // value for 'SENSITIVE_self_identity_gender'
 *      SENSITIVE_user_measurements: // value for 'SENSITIVE_user_measurements'
 *   },
 * });
 */
export function useUpdatePersonalInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePersonalInfoMutation, UpdatePersonalInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePersonalInfoMutation, UpdatePersonalInfoMutationVariables>(UpdatePersonalInfoDocument, options);
      }
export type UpdatePersonalInfoMutationHookResult = ReturnType<typeof useUpdatePersonalInfoMutation>;
export type UpdatePersonalInfoMutationResult = Apollo.MutationResult<UpdatePersonalInfoMutation>;
export type UpdatePersonalInfoMutationOptions = Apollo.BaseMutationOptions<UpdatePersonalInfoMutation, UpdatePersonalInfoMutationVariables>;
export const UpdateContactInfoDocument = gql`
    mutation UpdateContactInfo($id: uuid!, $SENSITIVE_address_information: jsonb, $SENSITIVE_phone: String, $SENSITIVE_country: String) {
  update_users_by_pk(
    pk_columns: {id: $id}
    _set: {SENSITIVE_address_information: $SENSITIVE_address_information, SENSITIVE_phone: $SENSITIVE_phone, SENSITIVE_country: $SENSITIVE_country}
  ) {
    SENSITIVE_address_information
    SENSITIVE_country
    SENSITIVE_dob
    SENSITIVE_email
    SENSITIVE_etnicity
    SENSITIVE_firstname
    SENSITIVE_gender
    SENSITIVE_lastname
    SENSITIVE_phone
    SENSITIVE_self_identity_gender
    SENSITIVE_user_measurements
    id
  }
}
    `;
export type UpdateContactInfoMutationFn = Apollo.MutationFunction<UpdateContactInfoMutation, UpdateContactInfoMutationVariables>;

/**
 * __useUpdateContactInfoMutation__
 *
 * To run a mutation, you first call `useUpdateContactInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactInfoMutation, { data, loading, error }] = useUpdateContactInfoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      SENSITIVE_address_information: // value for 'SENSITIVE_address_information'
 *      SENSITIVE_phone: // value for 'SENSITIVE_phone'
 *      SENSITIVE_country: // value for 'SENSITIVE_country'
 *   },
 * });
 */
export function useUpdateContactInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContactInfoMutation, UpdateContactInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateContactInfoMutation, UpdateContactInfoMutationVariables>(UpdateContactInfoDocument, options);
      }
export type UpdateContactInfoMutationHookResult = ReturnType<typeof useUpdateContactInfoMutation>;
export type UpdateContactInfoMutationResult = Apollo.MutationResult<UpdateContactInfoMutation>;
export type UpdateContactInfoMutationOptions = Apollo.BaseMutationOptions<UpdateContactInfoMutation, UpdateContactInfoMutationVariables>;
export const UpdateProfileInfoDocument = gql`
    mutation UpdateProfileInfo($id: uuid!, $SENSITIVE_dob: date, $SENSITIVE_etnicity: jsonb, $SENSITIVE_firstname: String, $SENSITIVE_gender: String, $SENSITIVE_lastname: String, $SENSITIVE_self_identity_gender: String, $SENSITIVE_user_measurements: jsonb, $SENSITIVE_address_information: jsonb, $SENSITIVE_phone: String, $SENSITIVE_country: String) {
  update_users_by_pk(
    pk_columns: {id: $id}
    _set: {SENSITIVE_dob: $SENSITIVE_dob, SENSITIVE_etnicity: $SENSITIVE_etnicity, SENSITIVE_firstname: $SENSITIVE_firstname, SENSITIVE_gender: $SENSITIVE_gender, SENSITIVE_lastname: $SENSITIVE_lastname, SENSITIVE_self_identity_gender: $SENSITIVE_self_identity_gender, SENSITIVE_user_measurements: $SENSITIVE_user_measurements, SENSITIVE_address_information: $SENSITIVE_address_information, SENSITIVE_phone: $SENSITIVE_phone, SENSITIVE_country: $SENSITIVE_country}
  ) {
    SENSITIVE_address_information
    SENSITIVE_country
    SENSITIVE_dob
    SENSITIVE_email
    SENSITIVE_etnicity
    SENSITIVE_firstname
    SENSITIVE_gender
    SENSITIVE_lastname
    SENSITIVE_phone
    SENSITIVE_self_identity_gender
    SENSITIVE_user_measurements
    id
  }
}
    `;
export type UpdateProfileInfoMutationFn = Apollo.MutationFunction<UpdateProfileInfoMutation, UpdateProfileInfoMutationVariables>;

/**
 * __useUpdateProfileInfoMutation__
 *
 * To run a mutation, you first call `useUpdateProfileInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileInfoMutation, { data, loading, error }] = useUpdateProfileInfoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      SENSITIVE_dob: // value for 'SENSITIVE_dob'
 *      SENSITIVE_etnicity: // value for 'SENSITIVE_etnicity'
 *      SENSITIVE_firstname: // value for 'SENSITIVE_firstname'
 *      SENSITIVE_gender: // value for 'SENSITIVE_gender'
 *      SENSITIVE_lastname: // value for 'SENSITIVE_lastname'
 *      SENSITIVE_self_identity_gender: // value for 'SENSITIVE_self_identity_gender'
 *      SENSITIVE_user_measurements: // value for 'SENSITIVE_user_measurements'
 *      SENSITIVE_address_information: // value for 'SENSITIVE_address_information'
 *      SENSITIVE_phone: // value for 'SENSITIVE_phone'
 *      SENSITIVE_country: // value for 'SENSITIVE_country'
 *   },
 * });
 */
export function useUpdateProfileInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileInfoMutation, UpdateProfileInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfileInfoMutation, UpdateProfileInfoMutationVariables>(UpdateProfileInfoDocument, options);
      }
export type UpdateProfileInfoMutationHookResult = ReturnType<typeof useUpdateProfileInfoMutation>;
export type UpdateProfileInfoMutationResult = Apollo.MutationResult<UpdateProfileInfoMutation>;
export type UpdateProfileInfoMutationOptions = Apollo.BaseMutationOptions<UpdateProfileInfoMutation, UpdateProfileInfoMutationVariables>;
export const UpdatePhoneAndCountryByUserIdDocument = gql`
    mutation UpdatePhoneAndCountryByUserID($id: uuid!, $SENSITIVE_phone: String!, $SENSITIVE_country: String!) {
  update_users_by_pk(
    pk_columns: {id: $id}
    _set: {SENSITIVE_phone: $SENSITIVE_phone, SENSITIVE_country: $SENSITIVE_country}
  ) {
    id
    SENSITIVE_country
    SENSITIVE_phone
    updated_at
  }
}
    `;
export type UpdatePhoneAndCountryByUserIdMutationFn = Apollo.MutationFunction<UpdatePhoneAndCountryByUserIdMutation, UpdatePhoneAndCountryByUserIdMutationVariables>;

/**
 * __useUpdatePhoneAndCountryByUserIdMutation__
 *
 * To run a mutation, you first call `useUpdatePhoneAndCountryByUserIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePhoneAndCountryByUserIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePhoneAndCountryByUserIdMutation, { data, loading, error }] = useUpdatePhoneAndCountryByUserIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      SENSITIVE_phone: // value for 'SENSITIVE_phone'
 *      SENSITIVE_country: // value for 'SENSITIVE_country'
 *   },
 * });
 */
export function useUpdatePhoneAndCountryByUserIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePhoneAndCountryByUserIdMutation, UpdatePhoneAndCountryByUserIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePhoneAndCountryByUserIdMutation, UpdatePhoneAndCountryByUserIdMutationVariables>(UpdatePhoneAndCountryByUserIdDocument, options);
      }
export type UpdatePhoneAndCountryByUserIdMutationHookResult = ReturnType<typeof useUpdatePhoneAndCountryByUserIdMutation>;
export type UpdatePhoneAndCountryByUserIdMutationResult = Apollo.MutationResult<UpdatePhoneAndCountryByUserIdMutation>;
export type UpdatePhoneAndCountryByUserIdMutationOptions = Apollo.BaseMutationOptions<UpdatePhoneAndCountryByUserIdMutation, UpdatePhoneAndCountryByUserIdMutationVariables>;
export const UpdateProfilePictureDocument = gql`
    mutation UpdateProfilePicture($image: String!, $filename: String) {
  UpdateProfilePicture(image: {image: $image, filename: $filename}) {
    message
    status
    fileId
  }
}
    `;
export type UpdateProfilePictureMutationFn = Apollo.MutationFunction<UpdateProfilePictureMutation, UpdateProfilePictureMutationVariables>;

/**
 * __useUpdateProfilePictureMutation__
 *
 * To run a mutation, you first call `useUpdateProfilePictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfilePictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfilePictureMutation, { data, loading, error }] = useUpdateProfilePictureMutation({
 *   variables: {
 *      image: // value for 'image'
 *      filename: // value for 'filename'
 *   },
 * });
 */
export function useUpdateProfilePictureMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfilePictureMutation, UpdateProfilePictureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfilePictureMutation, UpdateProfilePictureMutationVariables>(UpdateProfilePictureDocument, options);
      }
export type UpdateProfilePictureMutationHookResult = ReturnType<typeof useUpdateProfilePictureMutation>;
export type UpdateProfilePictureMutationResult = Apollo.MutationResult<UpdateProfilePictureMutation>;
export type UpdateProfilePictureMutationOptions = Apollo.BaseMutationOptions<UpdateProfilePictureMutation, UpdateProfilePictureMutationVariables>;
export const UpdateProviderProfilePictureDocument = gql`
    mutation UpdateProviderProfilePicture($image: String!, $filename: String) {
  UpdateProviderProfilePicture(image: {image: $image, filename: $filename}) {
    message
    status
    fileId
  }
}
    `;
export type UpdateProviderProfilePictureMutationFn = Apollo.MutationFunction<UpdateProviderProfilePictureMutation, UpdateProviderProfilePictureMutationVariables>;

/**
 * __useUpdateProviderProfilePictureMutation__
 *
 * To run a mutation, you first call `useUpdateProviderProfilePictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProviderProfilePictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProviderProfilePictureMutation, { data, loading, error }] = useUpdateProviderProfilePictureMutation({
 *   variables: {
 *      image: // value for 'image'
 *      filename: // value for 'filename'
 *   },
 * });
 */
export function useUpdateProviderProfilePictureMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProviderProfilePictureMutation, UpdateProviderProfilePictureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProviderProfilePictureMutation, UpdateProviderProfilePictureMutationVariables>(UpdateProviderProfilePictureDocument, options);
      }
export type UpdateProviderProfilePictureMutationHookResult = ReturnType<typeof useUpdateProviderProfilePictureMutation>;
export type UpdateProviderProfilePictureMutationResult = Apollo.MutationResult<UpdateProviderProfilePictureMutation>;
export type UpdateProviderProfilePictureMutationOptions = Apollo.BaseMutationOptions<UpdateProviderProfilePictureMutation, UpdateProviderProfilePictureMutationVariables>;
export const GenerateSessionTokenForPersonaDocument = gql`
    mutation GenerateSessionTokenForPersona($inquiryId: String) {
  GetPersonaSessionToken(inquiryId: $inquiryId)
}
    `;
export type GenerateSessionTokenForPersonaMutationFn = Apollo.MutationFunction<GenerateSessionTokenForPersonaMutation, GenerateSessionTokenForPersonaMutationVariables>;

/**
 * __useGenerateSessionTokenForPersonaMutation__
 *
 * To run a mutation, you first call `useGenerateSessionTokenForPersonaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateSessionTokenForPersonaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateSessionTokenForPersonaMutation, { data, loading, error }] = useGenerateSessionTokenForPersonaMutation({
 *   variables: {
 *      inquiryId: // value for 'inquiryId'
 *   },
 * });
 */
export function useGenerateSessionTokenForPersonaMutation(baseOptions?: Apollo.MutationHookOptions<GenerateSessionTokenForPersonaMutation, GenerateSessionTokenForPersonaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateSessionTokenForPersonaMutation, GenerateSessionTokenForPersonaMutationVariables>(GenerateSessionTokenForPersonaDocument, options);
      }
export type GenerateSessionTokenForPersonaMutationHookResult = ReturnType<typeof useGenerateSessionTokenForPersonaMutation>;
export type GenerateSessionTokenForPersonaMutationResult = Apollo.MutationResult<GenerateSessionTokenForPersonaMutation>;
export type GenerateSessionTokenForPersonaMutationOptions = Apollo.BaseMutationOptions<GenerateSessionTokenForPersonaMutation, GenerateSessionTokenForPersonaMutationVariables>;
export const UpdateAccountSettingsDocument = gql`
    mutation UpdateAccountSettings($id: uuid!, $EMAIL_MONTHLY_NEWSLETTER: Boolean, $EMAIL_NEW_FEATURES_SERVICES_SETTINGS: Boolean, $EMAIL_SPECIAL_OFFERS_SETTING: Boolean, $EMAIL_SPECIAL_OFFER_FRIENDS_SETTING: Boolean, $EMAIL_UNSUBSCRIBED: Boolean, $PRIVACY_AFFILIATES_CODEX_LABS_SETTING: Boolean, $PRIVACY_NON_AFFILIATES_CODEX_LABS_SETTING: Boolean, $PRIVACY_CALIFORNIA_SETTINGS: Boolean, $AUTO_APPROVAL_PATIENTS_CODEX_LABS_SETTING: Boolean, $AUTO_APPROVAL_TESTKIT_SCRIPTS_CODEX_LABS_SETTING: Boolean, $ALERT_SETTINGS: jsonb) {
  update_users_by_pk(
    pk_columns: {id: $id}
    _set: {EMAIL_MONTHLY_NEWSLETTER: $EMAIL_MONTHLY_NEWSLETTER, EMAIL_NEW_FEATURES_SERVICES_SETTINGS: $EMAIL_NEW_FEATURES_SERVICES_SETTINGS, EMAIL_SPECIAL_OFFERS_SETTING: $EMAIL_SPECIAL_OFFERS_SETTING, EMAIL_SPECIAL_OFFER_FRIENDS_SETTING: $EMAIL_SPECIAL_OFFER_FRIENDS_SETTING, EMAIL_UNSUBSCRIBED: $EMAIL_UNSUBSCRIBED, PRIVACY_AFFILIATES_CODEX_LABS_SETTING: $PRIVACY_AFFILIATES_CODEX_LABS_SETTING, PRIVACY_NON_AFFILIATES_CODEX_LABS_SETTING: $PRIVACY_NON_AFFILIATES_CODEX_LABS_SETTING, PRIVACY_CALIFORNIA_SETTINGS: $PRIVACY_CALIFORNIA_SETTINGS, AUTO_APPROVAL_PATIENTS_CODEX_LABS_SETTING: $AUTO_APPROVAL_PATIENTS_CODEX_LABS_SETTING, AUTO_APPROVAL_TESTKIT_SCRIPTS_CODEX_LABS_SETTING: $AUTO_APPROVAL_TESTKIT_SCRIPTS_CODEX_LABS_SETTING, ALERT_SETTINGS: $ALERT_SETTINGS}
  ) {
    EMAIL_MONTHLY_NEWSLETTER
    EMAIL_NEW_FEATURES_SERVICES_SETTINGS
    EMAIL_SPECIAL_OFFERS_SETTING
    EMAIL_SPECIAL_OFFER_FRIENDS_SETTING
    EMAIL_UNSUBSCRIBED
    PRIVACY_AFFILIATES_CODEX_LABS_SETTING
    PRIVACY_NON_AFFILIATES_CODEX_LABS_SETTING
    PRIVACY_CALIFORNIA_SETTINGS
    AUTO_APPROVAL_PATIENTS_CODEX_LABS_SETTING
    AUTO_APPROVAL_TESTKIT_SCRIPTS_CODEX_LABS_SETTING
    ALERT_SETTINGS
  }
}
    `;
export type UpdateAccountSettingsMutationFn = Apollo.MutationFunction<UpdateAccountSettingsMutation, UpdateAccountSettingsMutationVariables>;

/**
 * __useUpdateAccountSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateAccountSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountSettingsMutation, { data, loading, error }] = useUpdateAccountSettingsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      EMAIL_MONTHLY_NEWSLETTER: // value for 'EMAIL_MONTHLY_NEWSLETTER'
 *      EMAIL_NEW_FEATURES_SERVICES_SETTINGS: // value for 'EMAIL_NEW_FEATURES_SERVICES_SETTINGS'
 *      EMAIL_SPECIAL_OFFERS_SETTING: // value for 'EMAIL_SPECIAL_OFFERS_SETTING'
 *      EMAIL_SPECIAL_OFFER_FRIENDS_SETTING: // value for 'EMAIL_SPECIAL_OFFER_FRIENDS_SETTING'
 *      EMAIL_UNSUBSCRIBED: // value for 'EMAIL_UNSUBSCRIBED'
 *      PRIVACY_AFFILIATES_CODEX_LABS_SETTING: // value for 'PRIVACY_AFFILIATES_CODEX_LABS_SETTING'
 *      PRIVACY_NON_AFFILIATES_CODEX_LABS_SETTING: // value for 'PRIVACY_NON_AFFILIATES_CODEX_LABS_SETTING'
 *      PRIVACY_CALIFORNIA_SETTINGS: // value for 'PRIVACY_CALIFORNIA_SETTINGS'
 *      AUTO_APPROVAL_PATIENTS_CODEX_LABS_SETTING: // value for 'AUTO_APPROVAL_PATIENTS_CODEX_LABS_SETTING'
 *      AUTO_APPROVAL_TESTKIT_SCRIPTS_CODEX_LABS_SETTING: // value for 'AUTO_APPROVAL_TESTKIT_SCRIPTS_CODEX_LABS_SETTING'
 *      ALERT_SETTINGS: // value for 'ALERT_SETTINGS'
 *   },
 * });
 */
export function useUpdateAccountSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountSettingsMutation, UpdateAccountSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAccountSettingsMutation, UpdateAccountSettingsMutationVariables>(UpdateAccountSettingsDocument, options);
      }
export type UpdateAccountSettingsMutationHookResult = ReturnType<typeof useUpdateAccountSettingsMutation>;
export type UpdateAccountSettingsMutationResult = Apollo.MutationResult<UpdateAccountSettingsMutation>;
export type UpdateAccountSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateAccountSettingsMutation, UpdateAccountSettingsMutationVariables>;
export const UpdateCustomAuthCodeAsUsedDocument = gql`
    mutation UpdateCustomAuthCodeAsUsed($email: String!, $code: String!) {
  update_custom_auth_code(
    where: {SENSITIVE_email: {_eq: $email}, _and: {code: {_eq: $code}}}
    _set: {used: true}
  ) {
    affected_rows
  }
}
    `;
export type UpdateCustomAuthCodeAsUsedMutationFn = Apollo.MutationFunction<UpdateCustomAuthCodeAsUsedMutation, UpdateCustomAuthCodeAsUsedMutationVariables>;

/**
 * __useUpdateCustomAuthCodeAsUsedMutation__
 *
 * To run a mutation, you first call `useUpdateCustomAuthCodeAsUsedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomAuthCodeAsUsedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomAuthCodeAsUsedMutation, { data, loading, error }] = useUpdateCustomAuthCodeAsUsedMutation({
 *   variables: {
 *      email: // value for 'email'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useUpdateCustomAuthCodeAsUsedMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomAuthCodeAsUsedMutation, UpdateCustomAuthCodeAsUsedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomAuthCodeAsUsedMutation, UpdateCustomAuthCodeAsUsedMutationVariables>(UpdateCustomAuthCodeAsUsedDocument, options);
      }
export type UpdateCustomAuthCodeAsUsedMutationHookResult = ReturnType<typeof useUpdateCustomAuthCodeAsUsedMutation>;
export type UpdateCustomAuthCodeAsUsedMutationResult = Apollo.MutationResult<UpdateCustomAuthCodeAsUsedMutation>;
export type UpdateCustomAuthCodeAsUsedMutationOptions = Apollo.BaseMutationOptions<UpdateCustomAuthCodeAsUsedMutation, UpdateCustomAuthCodeAsUsedMutationVariables>;
export const GetUsersByIdDocument = gql`
    query GetUsersById($id: uuid!) {
  users(where: {id: {_eq: $id}, is_active: {_eq: true}}) {
    id
    SENSITIVE_phone
    SENSITIVE_lastname
    SENSITIVE_gender
    SENSITIVE_firstname
    SENSITIVE_email
    SENSITIVE_dob
    SENSITIVE_country
    SENSITIVE_self_identity_gender
    SENSITIVE_user_measurements
    SENSITIVE_etnicity
    SENSITIVE_address_information
    SENSITIVE_profile_picture_id
  }
}
    `;

/**
 * __useGetUsersByIdQuery__
 *
 * To run a query within a React component, call `useGetUsersByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUsersByIdQuery(baseOptions: Apollo.QueryHookOptions<GetUsersByIdQuery, GetUsersByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersByIdQuery, GetUsersByIdQueryVariables>(GetUsersByIdDocument, options);
      }
export function useGetUsersByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersByIdQuery, GetUsersByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersByIdQuery, GetUsersByIdQueryVariables>(GetUsersByIdDocument, options);
        }
export type GetUsersByIdQueryHookResult = ReturnType<typeof useGetUsersByIdQuery>;
export type GetUsersByIdLazyQueryHookResult = ReturnType<typeof useGetUsersByIdLazyQuery>;
export type GetUsersByIdQueryResult = Apollo.QueryResult<GetUsersByIdQuery, GetUsersByIdQueryVariables>;
export const GetUsersByEmailDocument = gql`
    query GetUsersByEmail($email: String!) {
  users(where: {SENSITIVE_email: {_eq: $email}, is_active: {_eq: true}}) {
    id
    SENSITIVE_dob
    SENSITIVE_email
    SENSITIVE_firstname
    SENSITIVE_gender
    SENSITIVE_phone
    SENSITIVE_country
    SENSITIVE_lastname
    SENSITIVE_self_identity_gender
    SENSITIVE_user_measurements
    SENSITIVE_etnicity
    SENSITIVE_address_information
    SENSITIVE_profile_picture_id
  }
}
    `;

/**
 * __useGetUsersByEmailQuery__
 *
 * To run a query within a React component, call `useGetUsersByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetUsersByEmailQuery(baseOptions: Apollo.QueryHookOptions<GetUsersByEmailQuery, GetUsersByEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersByEmailQuery, GetUsersByEmailQueryVariables>(GetUsersByEmailDocument, options);
      }
export function useGetUsersByEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersByEmailQuery, GetUsersByEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersByEmailQuery, GetUsersByEmailQueryVariables>(GetUsersByEmailDocument, options);
        }
export type GetUsersByEmailQueryHookResult = ReturnType<typeof useGetUsersByEmailQuery>;
export type GetUsersByEmailLazyQueryHookResult = ReturnType<typeof useGetUsersByEmailLazyQuery>;
export type GetUsersByEmailQueryResult = Apollo.QueryResult<GetUsersByEmailQuery, GetUsersByEmailQueryVariables>;
export const GetUsersVerificationStatusByEmailDocument = gql`
    subscription GetUsersVerificationStatusByEmail($email: String!) {
  users(where: {SENSITIVE_email: {_eq: $email}, is_active: {_eq: true}}) {
    kyc_current_status
    kyc_update_date
  }
}
    `;

/**
 * __useGetUsersVerificationStatusByEmailSubscription__
 *
 * To run a query within a React component, call `useGetUsersVerificationStatusByEmailSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersVerificationStatusByEmailSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersVerificationStatusByEmailSubscription({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetUsersVerificationStatusByEmailSubscription(baseOptions: Apollo.SubscriptionHookOptions<GetUsersVerificationStatusByEmailSubscription, GetUsersVerificationStatusByEmailSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetUsersVerificationStatusByEmailSubscription, GetUsersVerificationStatusByEmailSubscriptionVariables>(GetUsersVerificationStatusByEmailDocument, options);
      }
export type GetUsersVerificationStatusByEmailSubscriptionHookResult = ReturnType<typeof useGetUsersVerificationStatusByEmailSubscription>;
export type GetUsersVerificationStatusByEmailSubscriptionResult = Apollo.SubscriptionResult<GetUsersVerificationStatusByEmailSubscription>;
export const GetPersonaVerificationStatusByEmailDocument = gql`
    query GetPersonaVerificationStatusByEmail($email: String!) {
  users(where: {SENSITIVE_email: {_eq: $email}, is_active: {_eq: true}}) {
    kyc_current_status
    kyc_update_date
  }
}
    `;

/**
 * __useGetPersonaVerificationStatusByEmailQuery__
 *
 * To run a query within a React component, call `useGetPersonaVerificationStatusByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonaVerificationStatusByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonaVerificationStatusByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetPersonaVerificationStatusByEmailQuery(baseOptions: Apollo.QueryHookOptions<GetPersonaVerificationStatusByEmailQuery, GetPersonaVerificationStatusByEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPersonaVerificationStatusByEmailQuery, GetPersonaVerificationStatusByEmailQueryVariables>(GetPersonaVerificationStatusByEmailDocument, options);
      }
export function useGetPersonaVerificationStatusByEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPersonaVerificationStatusByEmailQuery, GetPersonaVerificationStatusByEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPersonaVerificationStatusByEmailQuery, GetPersonaVerificationStatusByEmailQueryVariables>(GetPersonaVerificationStatusByEmailDocument, options);
        }
export type GetPersonaVerificationStatusByEmailQueryHookResult = ReturnType<typeof useGetPersonaVerificationStatusByEmailQuery>;
export type GetPersonaVerificationStatusByEmailLazyQueryHookResult = ReturnType<typeof useGetPersonaVerificationStatusByEmailLazyQuery>;
export type GetPersonaVerificationStatusByEmailQueryResult = Apollo.QueryResult<GetPersonaVerificationStatusByEmailQuery, GetPersonaVerificationStatusByEmailQueryVariables>;
export const GetUserPersonaInquiryIdDocument = gql`
    query GetUserPersonaInquiryId($id: uuid!) {
  persona_inquiries(where: {user_id: {_eq: $id}}) {
    persona_inquiry_id
  }
}
    `;

/**
 * __useGetUserPersonaInquiryIdQuery__
 *
 * To run a query within a React component, call `useGetUserPersonaInquiryIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPersonaInquiryIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPersonaInquiryIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserPersonaInquiryIdQuery(baseOptions: Apollo.QueryHookOptions<GetUserPersonaInquiryIdQuery, GetUserPersonaInquiryIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserPersonaInquiryIdQuery, GetUserPersonaInquiryIdQueryVariables>(GetUserPersonaInquiryIdDocument, options);
      }
export function useGetUserPersonaInquiryIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserPersonaInquiryIdQuery, GetUserPersonaInquiryIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserPersonaInquiryIdQuery, GetUserPersonaInquiryIdQueryVariables>(GetUserPersonaInquiryIdDocument, options);
        }
export type GetUserPersonaInquiryIdQueryHookResult = ReturnType<typeof useGetUserPersonaInquiryIdQuery>;
export type GetUserPersonaInquiryIdLazyQueryHookResult = ReturnType<typeof useGetUserPersonaInquiryIdLazyQuery>;
export type GetUserPersonaInquiryIdQueryResult = Apollo.QueryResult<GetUserPersonaInquiryIdQuery, GetUserPersonaInquiryIdQueryVariables>;
export const GetUserEmailPreferencesDocument = gql`
    query GetUserEmailPreferences($id: uuid!) {
  users(where: {id: {_eq: $id}}) {
    EMAIL_NEW_FEATURES_SERVICES_SETTINGS
    EMAIL_MONTHLY_NEWSLETTER
    EMAIL_SPECIAL_OFFERS_SETTING
    EMAIL_SPECIAL_OFFER_FRIENDS_SETTING
    EMAIL_UNSUBSCRIBED
  }
}
    `;

/**
 * __useGetUserEmailPreferencesQuery__
 *
 * To run a query within a React component, call `useGetUserEmailPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserEmailPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserEmailPreferencesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserEmailPreferencesQuery(baseOptions: Apollo.QueryHookOptions<GetUserEmailPreferencesQuery, GetUserEmailPreferencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserEmailPreferencesQuery, GetUserEmailPreferencesQueryVariables>(GetUserEmailPreferencesDocument, options);
      }
export function useGetUserEmailPreferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserEmailPreferencesQuery, GetUserEmailPreferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserEmailPreferencesQuery, GetUserEmailPreferencesQueryVariables>(GetUserEmailPreferencesDocument, options);
        }
export type GetUserEmailPreferencesQueryHookResult = ReturnType<typeof useGetUserEmailPreferencesQuery>;
export type GetUserEmailPreferencesLazyQueryHookResult = ReturnType<typeof useGetUserEmailPreferencesLazyQuery>;
export type GetUserEmailPreferencesQueryResult = Apollo.QueryResult<GetUserEmailPreferencesQuery, GetUserEmailPreferencesQueryVariables>;
export const GetUserPrivacyPreferencesDocument = gql`
    query GetUserPrivacyPreferences($id: uuid!) {
  users(where: {id: {_eq: $id}}) {
    PRIVACY_AFFILIATES_CODEX_LABS_SETTING
    PRIVACY_NON_AFFILIATES_CODEX_LABS_SETTING
    PRIVACY_CALIFORNIA_SETTINGS
  }
}
    `;

/**
 * __useGetUserPrivacyPreferencesQuery__
 *
 * To run a query within a React component, call `useGetUserPrivacyPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPrivacyPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPrivacyPreferencesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserPrivacyPreferencesQuery(baseOptions: Apollo.QueryHookOptions<GetUserPrivacyPreferencesQuery, GetUserPrivacyPreferencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserPrivacyPreferencesQuery, GetUserPrivacyPreferencesQueryVariables>(GetUserPrivacyPreferencesDocument, options);
      }
export function useGetUserPrivacyPreferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserPrivacyPreferencesQuery, GetUserPrivacyPreferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserPrivacyPreferencesQuery, GetUserPrivacyPreferencesQueryVariables>(GetUserPrivacyPreferencesDocument, options);
        }
export type GetUserPrivacyPreferencesQueryHookResult = ReturnType<typeof useGetUserPrivacyPreferencesQuery>;
export type GetUserPrivacyPreferencesLazyQueryHookResult = ReturnType<typeof useGetUserPrivacyPreferencesLazyQuery>;
export type GetUserPrivacyPreferencesQueryResult = Apollo.QueryResult<GetUserPrivacyPreferencesQuery, GetUserPrivacyPreferencesQueryVariables>;
export const GetUserAutoApprovalPreferencesDocument = gql`
    query GetUserAutoApprovalPreferences($id: uuid!) {
  users(where: {id: {_eq: $id}}) {
    AUTO_APPROVAL_PATIENTS_CODEX_LABS_SETTING
    AUTO_APPROVAL_TESTKIT_SCRIPTS_CODEX_LABS_SETTING
  }
}
    `;

/**
 * __useGetUserAutoApprovalPreferencesQuery__
 *
 * To run a query within a React component, call `useGetUserAutoApprovalPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAutoApprovalPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAutoApprovalPreferencesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserAutoApprovalPreferencesQuery(baseOptions: Apollo.QueryHookOptions<GetUserAutoApprovalPreferencesQuery, GetUserAutoApprovalPreferencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserAutoApprovalPreferencesQuery, GetUserAutoApprovalPreferencesQueryVariables>(GetUserAutoApprovalPreferencesDocument, options);
      }
export function useGetUserAutoApprovalPreferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserAutoApprovalPreferencesQuery, GetUserAutoApprovalPreferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserAutoApprovalPreferencesQuery, GetUserAutoApprovalPreferencesQueryVariables>(GetUserAutoApprovalPreferencesDocument, options);
        }
export type GetUserAutoApprovalPreferencesQueryHookResult = ReturnType<typeof useGetUserAutoApprovalPreferencesQuery>;
export type GetUserAutoApprovalPreferencesLazyQueryHookResult = ReturnType<typeof useGetUserAutoApprovalPreferencesLazyQuery>;
export type GetUserAutoApprovalPreferencesQueryResult = Apollo.QueryResult<GetUserAutoApprovalPreferencesQuery, GetUserAutoApprovalPreferencesQueryVariables>;
export const GetUserAlertPreferencesDocument = gql`
    query GetUserAlertPreferences($id: uuid!) {
  users(where: {id: {_eq: $id}}) {
    ALERT_SETTINGS
  }
}
    `;

/**
 * __useGetUserAlertPreferencesQuery__
 *
 * To run a query within a React component, call `useGetUserAlertPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAlertPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAlertPreferencesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserAlertPreferencesQuery(baseOptions: Apollo.QueryHookOptions<GetUserAlertPreferencesQuery, GetUserAlertPreferencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserAlertPreferencesQuery, GetUserAlertPreferencesQueryVariables>(GetUserAlertPreferencesDocument, options);
      }
export function useGetUserAlertPreferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserAlertPreferencesQuery, GetUserAlertPreferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserAlertPreferencesQuery, GetUserAlertPreferencesQueryVariables>(GetUserAlertPreferencesDocument, options);
        }
export type GetUserAlertPreferencesQueryHookResult = ReturnType<typeof useGetUserAlertPreferencesQuery>;
export type GetUserAlertPreferencesLazyQueryHookResult = ReturnType<typeof useGetUserAlertPreferencesLazyQuery>;
export type GetUserAlertPreferencesQueryResult = Apollo.QueryResult<GetUserAlertPreferencesQuery, GetUserAlertPreferencesQueryVariables>;
export const GetCustomAuthCodeToLoginDocument = gql`
    query GetCustomAuthCodeToLogin($email: String!) {
  GetCustomAuthCodeToLogin(email: $email) {
    success
  }
}
    `;

/**
 * __useGetCustomAuthCodeToLoginQuery__
 *
 * To run a query within a React component, call `useGetCustomAuthCodeToLoginQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomAuthCodeToLoginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomAuthCodeToLoginQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetCustomAuthCodeToLoginQuery(baseOptions: Apollo.QueryHookOptions<GetCustomAuthCodeToLoginQuery, GetCustomAuthCodeToLoginQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomAuthCodeToLoginQuery, GetCustomAuthCodeToLoginQueryVariables>(GetCustomAuthCodeToLoginDocument, options);
      }
export function useGetCustomAuthCodeToLoginLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomAuthCodeToLoginQuery, GetCustomAuthCodeToLoginQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomAuthCodeToLoginQuery, GetCustomAuthCodeToLoginQueryVariables>(GetCustomAuthCodeToLoginDocument, options);
        }
export type GetCustomAuthCodeToLoginQueryHookResult = ReturnType<typeof useGetCustomAuthCodeToLoginQuery>;
export type GetCustomAuthCodeToLoginLazyQueryHookResult = ReturnType<typeof useGetCustomAuthCodeToLoginLazyQuery>;
export type GetCustomAuthCodeToLoginQueryResult = Apollo.QueryResult<GetCustomAuthCodeToLoginQuery, GetCustomAuthCodeToLoginQueryVariables>;
export const GetCustomAuthTokenFromCustomAuthCodeDocument = gql`
    query GetCustomAuthTokenFromCustomAuthCode($customAuthCode: String!, $email: String!) {
  GetCustomAuthTokenFromCustomAuthCode(
    customAuthCode: $customAuthCode
    email: $email
  ) {
    token
  }
}
    `;

/**
 * __useGetCustomAuthTokenFromCustomAuthCodeQuery__
 *
 * To run a query within a React component, call `useGetCustomAuthTokenFromCustomAuthCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomAuthTokenFromCustomAuthCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomAuthTokenFromCustomAuthCodeQuery({
 *   variables: {
 *      customAuthCode: // value for 'customAuthCode'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetCustomAuthTokenFromCustomAuthCodeQuery(baseOptions: Apollo.QueryHookOptions<GetCustomAuthTokenFromCustomAuthCodeQuery, GetCustomAuthTokenFromCustomAuthCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomAuthTokenFromCustomAuthCodeQuery, GetCustomAuthTokenFromCustomAuthCodeQueryVariables>(GetCustomAuthTokenFromCustomAuthCodeDocument, options);
      }
export function useGetCustomAuthTokenFromCustomAuthCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomAuthTokenFromCustomAuthCodeQuery, GetCustomAuthTokenFromCustomAuthCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomAuthTokenFromCustomAuthCodeQuery, GetCustomAuthTokenFromCustomAuthCodeQueryVariables>(GetCustomAuthTokenFromCustomAuthCodeDocument, options);
        }
export type GetCustomAuthTokenFromCustomAuthCodeQueryHookResult = ReturnType<typeof useGetCustomAuthTokenFromCustomAuthCodeQuery>;
export type GetCustomAuthTokenFromCustomAuthCodeLazyQueryHookResult = ReturnType<typeof useGetCustomAuthTokenFromCustomAuthCodeLazyQuery>;
export type GetCustomAuthTokenFromCustomAuthCodeQueryResult = Apollo.QueryResult<GetCustomAuthTokenFromCustomAuthCodeQuery, GetCustomAuthTokenFromCustomAuthCodeQueryVariables>;
export const RegisterAuthProviderDocument = gql`
    mutation RegisterAuthProvider($provider_type: String!) {
  RegisterAuthProvider(provider: {providerType: $provider_type}) {
    message
    status
  }
}
    `;
export type RegisterAuthProviderMutationFn = Apollo.MutationFunction<RegisterAuthProviderMutation, RegisterAuthProviderMutationVariables>;

/**
 * __useRegisterAuthProviderMutation__
 *
 * To run a mutation, you first call `useRegisterAuthProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterAuthProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerAuthProviderMutation, { data, loading, error }] = useRegisterAuthProviderMutation({
 *   variables: {
 *      provider_type: // value for 'provider_type'
 *   },
 * });
 */
export function useRegisterAuthProviderMutation(baseOptions?: Apollo.MutationHookOptions<RegisterAuthProviderMutation, RegisterAuthProviderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterAuthProviderMutation, RegisterAuthProviderMutationVariables>(RegisterAuthProviderDocument, options);
      }
export type RegisterAuthProviderMutationHookResult = ReturnType<typeof useRegisterAuthProviderMutation>;
export type RegisterAuthProviderMutationResult = Apollo.MutationResult<RegisterAuthProviderMutation>;
export type RegisterAuthProviderMutationOptions = Apollo.BaseMutationOptions<RegisterAuthProviderMutation, RegisterAuthProviderMutationVariables>;
export const VonageArchiveStartDocument = gql`
    mutation VonageArchiveStart($sessionId: String!) {
  VonageArchiveStart(sessionId: $sessionId) {
    archiveId
    status
  }
}
    `;
export type VonageArchiveStartMutationFn = Apollo.MutationFunction<VonageArchiveStartMutation, VonageArchiveStartMutationVariables>;

/**
 * __useVonageArchiveStartMutation__
 *
 * To run a mutation, you first call `useVonageArchiveStartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVonageArchiveStartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vonageArchiveStartMutation, { data, loading, error }] = useVonageArchiveStartMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useVonageArchiveStartMutation(baseOptions?: Apollo.MutationHookOptions<VonageArchiveStartMutation, VonageArchiveStartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VonageArchiveStartMutation, VonageArchiveStartMutationVariables>(VonageArchiveStartDocument, options);
      }
export type VonageArchiveStartMutationHookResult = ReturnType<typeof useVonageArchiveStartMutation>;
export type VonageArchiveStartMutationResult = Apollo.MutationResult<VonageArchiveStartMutation>;
export type VonageArchiveStartMutationOptions = Apollo.BaseMutationOptions<VonageArchiveStartMutation, VonageArchiveStartMutationVariables>;
export const VonageArchiveStopDocument = gql`
    mutation VonageArchiveStop($archiveId: String!) {
  VonageArchiveStop(archiveId: $archiveId) {
    archiveId
    status
  }
}
    `;
export type VonageArchiveStopMutationFn = Apollo.MutationFunction<VonageArchiveStopMutation, VonageArchiveStopMutationVariables>;

/**
 * __useVonageArchiveStopMutation__
 *
 * To run a mutation, you first call `useVonageArchiveStopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVonageArchiveStopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vonageArchiveStopMutation, { data, loading, error }] = useVonageArchiveStopMutation({
 *   variables: {
 *      archiveId: // value for 'archiveId'
 *   },
 * });
 */
export function useVonageArchiveStopMutation(baseOptions?: Apollo.MutationHookOptions<VonageArchiveStopMutation, VonageArchiveStopMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VonageArchiveStopMutation, VonageArchiveStopMutationVariables>(VonageArchiveStopDocument, options);
      }
export type VonageArchiveStopMutationHookResult = ReturnType<typeof useVonageArchiveStopMutation>;
export type VonageArchiveStopMutationResult = Apollo.MutationResult<VonageArchiveStopMutation>;
export type VonageArchiveStopMutationOptions = Apollo.BaseMutationOptions<VonageArchiveStopMutation, VonageArchiveStopMutationVariables>;
export const GetVonageArchivesDocument = gql`
    mutation GetVonageArchives($sessionId: String!) {
  GetVonageArchives(sessionId: $sessionId) {
    archives
  }
}
    `;
export type GetVonageArchivesMutationFn = Apollo.MutationFunction<GetVonageArchivesMutation, GetVonageArchivesMutationVariables>;

/**
 * __useGetVonageArchivesMutation__
 *
 * To run a mutation, you first call `useGetVonageArchivesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetVonageArchivesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getVonageArchivesMutation, { data, loading, error }] = useGetVonageArchivesMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useGetVonageArchivesMutation(baseOptions?: Apollo.MutationHookOptions<GetVonageArchivesMutation, GetVonageArchivesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetVonageArchivesMutation, GetVonageArchivesMutationVariables>(GetVonageArchivesDocument, options);
      }
export type GetVonageArchivesMutationHookResult = ReturnType<typeof useGetVonageArchivesMutation>;
export type GetVonageArchivesMutationResult = Apollo.MutationResult<GetVonageArchivesMutation>;
export type GetVonageArchivesMutationOptions = Apollo.BaseMutationOptions<GetVonageArchivesMutation, GetVonageArchivesMutationVariables>;
export const GetVonageSessionDocument = gql`
    mutation GetVonageSession($room: String!) {
  GetVonageSession(room: $room) {
    sessionId
    token
    apiKey
  }
}
    `;
export type GetVonageSessionMutationFn = Apollo.MutationFunction<GetVonageSessionMutation, GetVonageSessionMutationVariables>;

/**
 * __useGetVonageSessionMutation__
 *
 * To run a mutation, you first call `useGetVonageSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetVonageSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getVonageSessionMutation, { data, loading, error }] = useGetVonageSessionMutation({
 *   variables: {
 *      room: // value for 'room'
 *   },
 * });
 */
export function useGetVonageSessionMutation(baseOptions?: Apollo.MutationHookOptions<GetVonageSessionMutation, GetVonageSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetVonageSessionMutation, GetVonageSessionMutationVariables>(GetVonageSessionDocument, options);
      }
export type GetVonageSessionMutationHookResult = ReturnType<typeof useGetVonageSessionMutation>;
export type GetVonageSessionMutationResult = Apollo.MutationResult<GetVonageSessionMutation>;
export type GetVonageSessionMutationOptions = Apollo.BaseMutationOptions<GetVonageSessionMutation, GetVonageSessionMutationVariables>;