import {
  AccountSettingsState,
  SectionsLoaded,
} from './providerModuleInterfaces';

export interface AlertPreferencesProps {
  locale: Record<string, string>;
  setAccountSettings: React.Dispatch<
    React.SetStateAction<AccountSettingsState | undefined>
  >;
  setIsAllSectionsLoaded: React.Dispatch<React.SetStateAction<SectionsLoaded>>;
}

export enum AlertPreferencesKeys {
  PRIMARY_EMAIL = 'primaryEmail',
  MOBILE_MESSAGE = 'mobileMessage',
}

export type AlertPreferencesOptions = {
  [AlertPreferencesKeys.PRIMARY_EMAIL]: boolean;
  [AlertPreferencesKeys.MOBILE_MESSAGE]: boolean;
};

export enum AlertIdentifiers {
  SYSTEM_OUTAGES = 'SYSTEM_OUTAGES',
  APPOINTMENT_CHANGES = 'APPOINTMENT_CHANGES',
  APPOINTMENT_REMINDERS = 'APPOINTMENT_REMINDERS',
  PASSWORD_OR_EMAIL_CHANGES = 'PASSWORD_OR_EMAIL_CHANGES',
}

export interface AlertPreferencesState {
  ALERT_SETTINGS: {
    [AlertIdentifiers.SYSTEM_OUTAGES]: AlertPreferencesOptions;
    [AlertIdentifiers.APPOINTMENT_CHANGES]: AlertPreferencesOptions;
    [AlertIdentifiers.APPOINTMENT_REMINDERS]: AlertPreferencesOptions;
    [AlertIdentifiers.PASSWORD_OR_EMAIL_CHANGES]: AlertPreferencesOptions;
  };
}
