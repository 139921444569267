import React, { Fragment, useContext, useEffect, useState } from 'react';
import { FooterTypes } from 'components/footer/footer.types';
import { useLocation, useNavigate } from 'react-router-dom';
import { Section } from 'components/navbar/navbar.interfaces';
import {
  User_Notification_Status_Enum,
  useUpdateInAppNotificationMutation,
} from 'graphql/generated/hasura';

import { relativeDate } from 'utilities/functions';
import { Notification } from 'app/dashboard/interfaces/notifications.interfaces';
import { AuthContext } from 'auth/context/AuthContext';
import { NOTIFICATIONS } from 'utilities/routes';
import { USER_LAST_ACTIVITY, messageTemplatesIds } from 'utilities/constants';
import { useGetMessageTemplates } from 'hooks/useGetMessageTemplates';
import { ProfileSectionTypes } from 'app/my-account/pages/my-profile/enums';
import { useModalParams } from 'components/modal/useModalManager';
import Alert, { AlertType } from 'components/alertComponent';
import Navbar from 'components/navbar/navbarComponent';
import Footer from 'components/footer/footerComponent';
import NavbarMobile from 'components/navbar/navbarMobileComponent';
import DownloadReport from 'app/my-skin/components/downloadReport';
import { ModalButtonProps } from 'components/modal/modal.interfaces';
import { useIdleTimerModalFunctionality } from 'hooks/useIdleTimerFunctionality';
import { useLocalStorage } from 'hooks/useLocalStorage';
import useUnload from 'hooks/useUnload';
import InactivityModal from 'components/InactivityModal';
import usePageVisibility from 'hooks/usePageVisibility';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { AlertExtraActions, CodexFeatureFlags } from 'utilities/interfaces';
import ButtonComponent from 'components/button/buttonComponent';
import { Roles } from '../firebase/interfaces';
import ProviderNavbarMobile from 'components/navbar/ProviderNavbarMobileComponent';
import {
  CustomNotificationActions,
  useHandleExtraActions,
} from 'hooks/useHandleExtraActions';
import ProviderNavbarNotApprovedMobile from 'components/navbar/ProviderNavbarNotApprovedMobileComponent';
import { NotificationsProvider } from 'notifications/contexts/NotificationsProvider';
import { ModalProvider } from 'layout/ModalProvider';
import { useNotifications } from 'hooks/useNotifications';
import SuperAdminNavbarMobile from 'components/navbar/SuperAdminNavbarMobile';
import { Toaster } from 'react-hot-toast';

interface PageLayoutProps {
  children: React.ReactNode;
  footerType?: FooterTypes;
}

const PageLayoutInternal: React.FC<PageLayoutProps> = ({
  children,
  footerType,
}) => {
  const navigate = useNavigate();
  const [isNavbarMobileVisible, setNavbarMobileVisible] =
    useState<boolean>(false);
  const [selectedSection, setSelectedSection] = useState<Section>();
  const location = useLocation();
  const { isOpen: isDownloadReportWarningOpen } =
    useModalParams('download-warning');
  const { userLogoutFlag } = useFlags<CodexFeatureFlags>();
  const toggleNavMobile = () => {
    setNavbarMobileVisible(!isNavbarMobileVisible);
  };
  const { user } = useContext(AuthContext);

  const userRole = user?.role;
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const { handler: customActionsHandler, isLoading } = useHandleExtraActions();

  const { notifications: notificationsData, loading: loadingNotifications } =
    useNotifications({
      includePriorities: [true],
      includeStatuses: [
        User_Notification_Status_Enum.Delivered,
        User_Notification_Status_Enum.Seen,
      ],
    });

  useEffect(() => {
    if (notificationsData) {
      const populateNotifications: Notification[] =
        notificationsData.notifications.map((notification) => {
          return {
            id: notification.id,
            body: notification.data.message,
            date: relativeDate(notification.data.date),
            isRead: notification.status === User_Notification_Status_Enum.Read,
            action: notification.data.action,
            extraActions:
              (notification.data.extraActions as AlertExtraActions[]) || [],
          };
        }) ?? [];
      setNotifications(populateNotifications);
    }
  }, [notificationsData]);

  const [updateInAppNotificationMutation] = useUpdateInAppNotificationMutation(
    {},
  );

  const { data: locale, loading } = useGetMessageTemplates({
    locale: 'en',
    templateId: [
      messageTemplatesIds.MY_PROFILE,
      messageTemplatesIds.MY_PERSONAL_INFO,
      messageTemplatesIds.MY_CONTACT_INFO,
      messageTemplatesIds.HEALTH_QUESTIONNAIRE,
    ],
  });

  const {
    inactivityModalOpen,
    handleStillHere,
    handleLogOut,
    componentLoading,
    componentLocale,
    remainingTime,
    getLastActiveTime,
    signOutUser,
  } = useIdleTimerModalFunctionality();

  const validateMyProfileNotification = (notification: string) => {
    return locale?.includes(notification);
  };

  const handleAlertClose = async (
    notificationId: number,
    route: string,
    message = '',
    customAction?: string,
    customActionParams?: Record<string, unknown>,
    isCloseButton = false,
  ) => {
    try {
      const response = await updateInAppNotificationMutation({
        variables: {
          id: String(notificationId),
          status: validateMyProfileNotification(message)
            ? User_Notification_Status_Enum.Seen
            : User_Notification_Status_Enum.Read,
        },
      });
      if (!response.data) {
        console.log(notificationId, response.errors);
        throw new Error('Failed to update notification');
      }
      if (isCloseButton) {
        return;
      }
      if (
        customAction &&
        customActionsHandler[customAction as CustomNotificationActions]
      ) {
        return customActionsHandler[customAction as CustomNotificationActions](
          customActionParams ?? {},
          true,
        );
      }
      if (
        message
          .toLocaleUpperCase()
          .includes(ProfileSectionTypes.PROFILE.toLocaleUpperCase())
      ) {
        return navigate(route, {
          state: { section: ProfileSectionTypes.PROFILE },
        });
      }
      if (
        message
          .toLocaleUpperCase()
          .includes(ProfileSectionTypes.CONTACT.toLocaleUpperCase())
      ) {
        return navigate(route, {
          state: { section: ProfileSectionTypes.CONTACT },
        });
      }
      return navigate(route, {
        state: {
          referringPage: location.pathname,
        },
      });
    } catch (error: unknown) {
      console.log(error);
      throw new Error('Failed to update notification');
    }
  };

  useEffect(() => {
    const route = location.pathname.split('/')[1];
    setSelectedSection((route as Section) || Section.Dashboard);
  }, [location, selectedSection]);

  const {
    setItemWithUniqueKey,
    getItemWithUniqueKey,
    removeItemWithUniqueKey,
  } = useLocalStorage();

  //  on unload of tab
  useUnload(() => {
    const getLastActiveTimeValue =
      getLastActiveTime && getLastActiveTime()?.toString();
    setItemWithUniqueKey(USER_LAST_ACTIVITY, getLastActiveTimeValue ?? '');
  });

  // on loss of focus of tab
  usePageVisibility((isVisible) => {
    if (isVisible === false) {
      const getLastActiveTimeValue =
        getLastActiveTime && getLastActiveTime()?.toString();
      setItemWithUniqueKey(USER_LAST_ACTIVITY, getLastActiveTimeValue ?? '');
    } else {
      setItemWithUniqueKey(USER_LAST_ACTIVITY, '');
    }
  });

  const getAuthenticatedNavbarBasedOnUserRole = () => {
    switch (userRole) {
      case Roles.PATIENT:
        return <NavbarMobile closeNavbarMobile={toggleNavMobile} />;
      case Roles.PROVIDER:
        return user?.providerEnabled ? (
          <ProviderNavbarMobile closeNavbarMobile={toggleNavMobile} />
        ) : (
          <ProviderNavbarNotApprovedMobile
            closeNavbarMobile={toggleNavMobile}
          />
        );
      case Roles.SUPERADMIN:
        return <SuperAdminNavbarMobile closeNavbarMobile={toggleNavMobile} />;
      default:
        return <NavbarMobile closeNavbarMobile={toggleNavMobile} />;
    }
  };

  useEffect(() => {
    if (userLogoutFlag?.logoutBasedOnLastActivity) {
      const FALLBACK_VALUE = 15 * 60 * 1000;
      const lastActivityTime = getItemWithUniqueKey(USER_LAST_ACTIVITY);
      const lastActivityTimeProcessed = new Date(lastActivityTime).getTime();

      const lastActivityLimit =
        userLogoutFlag?.logoutBasedOnLastActivityTimeLimit ?? FALLBACK_VALUE;

      const hasElapsedLimitTime = () => {
        const currentTime = Date.now();
        const elapsedTime = currentTime - lastActivityTimeProcessed;

        if (lastActivityTime === null) {
          return;
        }

        return elapsedTime >= lastActivityLimit;
      };

      const elapsedTime = hasElapsedLimitTime();

      if (elapsedTime) {
        removeItemWithUniqueKey(USER_LAST_ACTIVITY);
        signOutUser();
      } else {
        removeItemWithUniqueKey(USER_LAST_ACTIVITY);
      }
    }
  }, [
    getItemWithUniqueKey,
    removeItemWithUniqueKey,
    signOutUser,
    userLogoutFlag,
  ]);

  const InactivityModalButtons: ModalButtonProps[] = [
    {
      label: componentLocale?.CTA2Label,
      onClick: handleStillHere,
    },
    {
      label: componentLocale?.CTA1Label,
      onClick: handleLogOut,
      className: 'text-clc-blue bg-white',
    },
  ];

  if (
    loadingNotifications ||
    (loading && !locale) ||
    (componentLoading && !componentLocale)
  )
    return null;

  return (
    <>
      {isDownloadReportWarningOpen && <DownloadReport />}
      {inactivityModalOpen && (
        <InactivityModal
          inactivityModalOpen={inactivityModalOpen}
          title={componentLocale?.title}
          message={componentLocale?.message}
          timerMessage={componentLocale?.timerMessage}
          remainingTime={remainingTime}
          continueMessage={componentLocale?.continueMessage}
          buttons={InactivityModalButtons}
          onClose={() => handleStillHere()}
        />
      )}

      {isNavbarMobileVisible ? (
        getAuthenticatedNavbarBasedOnUserRole()
      ) : (
        <>
          <Navbar openNavbarMobile={toggleNavMobile} />
          <div className="flex flex-col justify-between mt-[80px] desktop:mt-[100px] bg-base-content min-h-content-page-mobile desktop:min-h-content-page-web">
            <div className="flex flex-col grow h-full pb-10 desktop:py-[60px] desktop:px-[135px]">
              {notifications.length > 0 &&
                selectedSection !== Section.Notifications &&
                selectedSection !== Section.LegalDocuments &&
                notifications.map((notification) => {
                  return (
                    ((selectedSection !== Section.ManageTestKits &&
                      selectedSection !== Section.MyAccount) ||
                      !validateMyProfileNotification(
                        notification.body as string,
                      )) && (
                      <div className="my-3" key={notification.id}>
                        <Alert
                          key={notification.id}
                          type={
                            (notification.action?.type as AlertType) ??
                            'positive'
                          }
                          text={notification.body}
                          actionLabel={notification.action?.actionName ?? ''}
                          onActionClick={() =>
                            handleAlertClose(
                              notification.id,
                              notification.action?.route ?? NOTIFICATIONS,
                              notification.body,
                              notification.action?.customAction,
                              notification.action?.customActionParams,
                            )
                          }
                          onClose={() =>
                            handleAlertClose(
                              notification.id,
                              notification.action?.route ?? NOTIFICATIONS,
                              notification.body,
                              notification.action?.customAction,
                              notification.action?.customActionParams,
                              true,
                            )
                          }
                          isNotification
                          hideCloseButton={validateMyProfileNotification(
                            notification.body as string,
                          )}
                          enableAutoFittingOfExtraActions
                          extraActions={notification.extraActions?.map(
                            (action, key) => (
                              <Fragment key={`${key}-${action.actionName}`}>
                                <ButtonComponent
                                  type={action?.type ?? 'underline'}
                                  iconPosition="right"
                                  disabled={isLoading}
                                  className="mt-5 desktop:mt-0"
                                  onClick={() =>
                                    handleAlertClose(
                                      notification.id,
                                      action?.route ?? NOTIFICATIONS,
                                      notification.body,
                                      action?.customAction,
                                      action?.customActionParams,
                                    )
                                  }
                                >
                                  {action.actionName}
                                </ButtonComponent>
                              </Fragment>
                            ),
                          )}
                        />
                      </div>
                    )
                  );
                })}

              {children}
            </div>
            <Footer type={footerType} />
          </div>
        </>
      )}
    </>
  );
};

const PageLayout: React.FC<PageLayoutProps> = ({ children, footerType }) => {
  return (
    <>
      <ModalProvider>
        <NotificationsProvider>
          <PageLayoutInternal footerType={footerType}>
            {children}
          </PageLayoutInternal>
        </NotificationsProvider>
      </ModalProvider>
      <Toaster position="top-center" reverse-order={false} />
    </>
  );
};

export default PageLayout;
