export enum Section {
  Dashboard = 'dashboard',
  MySkin = 'my-skin',
  ManageTestKits = 'manage-test-kits',
  Shop = 'shop',
  MyAccount = 'my-account',
  Notifications = 'notifications',
  LegalDocuments = 'legal-documents',
  Providers = 'providers',
  Appointments = 'my-appointments',
  MyPatients = 'my-patients',
  ProviderAppointments = 'provider-appointments',
}
