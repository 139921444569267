import { Test_Types_Enum } from 'graphql/generated/hasura';
import { useState } from 'react';

const uspsTrackingNumberRegexps: RegExp[] = [
  new RegExp(
    /\b((420 ?\d{5} ?)?(91|92|93|94|95|01|03|04|70|23|13)\d{2} ?\d{4} ?\d{4} ?\d{4} ?\d{4}( ?\d{2,6})?)\b/i,
  ),
  new RegExp(
    /\b((M|P[A-Z]?|D[C-Z]|LK|E[A-C]|V[A-Z]|R[A-Z]|CP|CJ|LC|LJ) ?\d{3} ?\d{3} ?\d{3} ?[A-Z]?[A-Z]?)\b/i,
  ),
  new RegExp(/\b(82 ?\d{3} ?\d{3} ?\d{2})\b/i),
];

export const useValidateTrackingNumber = () => {
  const [isValid, setIsValid] = useState<boolean>();
  const [validating, setValidating] = useState<boolean>(false);

  /* Temp disabling validation for Fedex  */
  // const [validateFedexTrackingNumber] = useValidateFedexTrackingNumberLazyQuery(
  //   {},
  // );

  const validateTrackingNumber = async (
    trackingNumber: string,
    testType: Test_Types_Enum,
  ): Promise<boolean> => {
    try {
      setValidating(true);
      let isValidTrackingNumber = false;
      if (
        testType === Test_Types_Enum.ZrtHormone ||
        testType === Test_Types_Enum.ZrtHeavyMetals ||
        testType === Test_Types_Enum.ZrtNeurotransmitters ||
        testType === Test_Types_Enum.ZrtInflammatory
      ) {
        // const { data, error } = await validateFedexTrackingNumber({
        //   variables: { trackingNumber },
        // });
        // if (error || !data || !data?.ValidateFedexTrackingNumber.isValid)
        //   isValidTrackingNumber = false;
        // else isValidTrackingNumber = true;
        isValidTrackingNumber = true;
      } else {
        isValidTrackingNumber = uspsTrackingNumberRegexps.some((regexp) =>
          regexp.test(trackingNumber),
        );
      }
      setIsValid(isValidTrackingNumber);
      return isValidTrackingNumber;
    } catch (error: unknown) {
      console.error('Error validating tracking number', error);
      setIsValid(false);
      return false;
    } finally {
      setValidating(false);
    }
  };

  return {
    isValid,
    validating,
    validateTrackingNumber,
  };
};
