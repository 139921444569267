import Modal from 'components/modal/modalComponent';
import { componentIds } from 'utilities/constants';
import { useGetComponent } from 'hooks/useGetComponent';
import { ReactComponent as TakePhotoIcon } from 'assets/icons/takePhoto.svg';
import { ReactComponent as RotateCameraIcon } from 'assets/icons/rotateCamera.svg';
import { CameraExactFacingMode, useCamera } from 'hooks/useCamera';
import {
  useScreenOrientation,
  ScreenOrientation,
} from 'hooks/useScreenOrientation';
import { Helmet } from 'react-helmet';
import { useCallback, useEffect } from 'react';
import Alert from 'components/alertComponent';
import { ModalButtonProps } from 'components/modal/modal.interfaces';
import { ReactComponent as FlashIcon } from 'assets/icons/flash.svg';
import SizeableLoader from 'components/SizeableLoader';
import { createModal } from 'layout/createModal';

export const AppointmentPreWorkCamera = createModal<
  { bodyPartLocation: string },
  string | undefined
>('AppointmentPreWorkCamera', ({ close, isOpen, state }) => {
  const {
    videoRef,
    canvasRef,
    cameraFacingMode,
    switchCameraFacingMode,
    isTorchSupported,
    supportsFacingMode,
    captureImageWithAspectRatio,
    toggleFlash,
    initializeCamera,
    stopCamera,
    isLoading: isLoadingCamera,
    error,
    imageData,
  } = useCamera(CameraExactFacingMode.NON_EXACT);

  const onOrientationChange = useCallback(
    (orientation: ScreenOrientation) => {
      switch (orientation) {
        case ScreenOrientation.Portrait:
        case ScreenOrientation.Landscape:
          initializeCamera();
          break;
        default:
          console.log(`Unexpected orientation: ${orientation}`);
          stopCamera();
          break;
      }
    },
    [initializeCamera, stopCamera],
  );
  const { screenOrientation } = useScreenOrientation(onOrientationChange);

  const bodyPartSelected = state.bodyPartLocation;

  const { data: locale, loading: localeLoading } = useGetComponent({
    componentId: componentIds.APPOINTMENT_PRE_WORK_PRE_CAPTURE_MODAL,
    locale: 'en',
  });

  const handleClose = () => {
    close(undefined);
  };

  const retryInitializeCamera = () => {
    initializeCamera();
  };

  const handleCaptureImage = () => {
    const image = captureImageWithAspectRatio();
    if (image) {
      stopCamera();
      close(image);
    }
  };

  const ErrorButtons: ModalButtonProps[] = [
    {
      label: 'try again',
      onClick: retryInitializeCamera,
    },
  ];

  useEffect(() => {
    if (videoRef.current && canvasRef.current) {
      const video = videoRef.current;
      const canvas = canvasRef.current;

      const updateDimensions = () => {
        const { videoWidth, videoHeight } = video;
        const orientation = window.screen.orientation.type;

        if (orientation.includes('landscape')) {
          canvas.width = videoWidth;
          canvas.height = videoHeight;
        } else {
          canvas.width = videoWidth;
          canvas.height = videoHeight;
        }
      };

      video.onloadedmetadata = updateDimensions;
      window.addEventListener('orientationchange', updateDimensions);

      return () => {
        window.removeEventListener('orientationchange', updateDimensions);
      };
    }
  }, [videoRef, canvasRef]);

  if (localeLoading) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      titleContainerMinHeight="!min-h-[0px] z-20 landscape:relative landscape:right-[3vw]"
      contentPaddingY="py-0"
      contentPaddingX="px-0"
      closeIconColor="stroke-white"
      contentContainerDefaultBorder="landscape:px-0"
    >
      <div className="flex justify-between landscape:flex-row flex-col h-screen">
        <div className="flex flex-col bg-camera-blur px-4 pt-3 pb-2 z-10 landscape:w-full landscape:h-[70px]">
          <p className="text-white uppercase text-sm font-bold">
            {locale?.takeAPhoto}
          </p>
          <p className="font-medium uppercase text-white text-[20px]">
            {bodyPartSelected}
          </p>
        </div>
        <div className="absolute w-full h-full">
          {/* camera */}
          <Helmet>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
            />
          </Helmet>
          {(screenOrientation === 'portrait' ||
            screenOrientation === 'landscape') && (
            <>
              <div
                className={`${
                  error === 'fatal' ? 'flex flex-col' : 'hidden'
                } z-50`}
              >
                <Modal
                  isOpen={true}
                  title={locale?.cameraError}
                  buttons={ErrorButtons}
                  disableMobileClose={true}
                >
                  <Alert
                    type="warning"
                    text={locale?.errorWhenInitializingCamera}
                  />
                </Modal>
              </div>

              <div
                className={`${isLoadingCamera ? 'flex flex-col' : 'hidden'} ${
                  screenOrientation === 'landscape' ? 'pt-[115px]' : ''
                } fixed top-0 left-0 w-full h-full items-center justify-center bg-base-content gap-10 z-100`}
              >
                <SizeableLoader />

                <p className="text-h3 font-semibold text-dark-gray">
                  {locale?.loading}
                </p>
              </div>

              <div
                className={`w-full h-full ${
                  isLoadingCamera || error ? 'hidden' : 'flex-col'
                }`}
              >
                <div className="w-full h-full">
                  {imageData.length ? (
                    <img
                      className="h-full w-full object-cover"
                      src={`${imageData}`}
                      alt="Captured Image"
                    />
                  ) : (
                    <>
                      <video
                        className="w-screen h-screen object-cover"
                        ref={videoRef}
                        autoPlay={true}
                      />
                      <canvas
                        className="w-screen h-screen opacity-0"
                        ref={canvasRef}
                      />
                    </>
                  )}
                </div>
              </div>
            </>
          )}
          {/* camera */}
        </div>
        {/* camera controls */}
        <div className="relative flex flex-row bg-camera-blur px-4 pt-2 pb-2 justify-center items-center landscape:w-[100px] landscape:min-h-[100px] min-h-[120px]">
          <TakePhotoIcon
            onClick={handleCaptureImage}
            className="my-[20px] absolute ml-auto mr-auto"
          />

          {screenOrientation === 'portrait' && (
            <>
              {supportsFacingMode() && (
                <RotateCameraIcon
                  onClick={switchCameraFacingMode}
                  className="landscape:mt-auto landscape:ml-[10px] ml-auto mr-[10px]"
                />
              )}
              {cameraFacingMode === 'environment' && isTorchSupported() && (
                <FlashIcon onClick={toggleFlash} />
              )}
            </>
          )}
          {screenOrientation === 'landscape' && (
            <div className="mt-auto flex justify-center items-center flex-col gap-3">
              {supportsFacingMode() && (
                <RotateCameraIcon
                  onClick={switchCameraFacingMode}
                  className="landscape:mt-auto landscape:ml-[10px] ml-auto mr-[10px]"
                />
              )}
              {cameraFacingMode === 'environment' && isTorchSupported() && (
                <FlashIcon
                  className="landscape:mt-auto landscape:ml-[10px] ml-auto mr-[10px]"
                  onClick={toggleFlash}
                />
              )}
            </div>
          )}
          {/* camera controls */}
        </div>
      </div>
    </Modal>
  );
});
