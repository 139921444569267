import React from 'react';

export const PatientSolidIcon: React.ComponentType<
  React.SVGProps<SVGSVGElement>
> = ({ fill, className }) => {
  return (
    <svg
      className={className}
      width="18"
      height="21"
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.00006 10.8703H8.97006C8.81006 10.8703 8.20006 10.8303 7.48006 10.3703H7.47006L7.37006 10.2903C6.39006 9.61031 5.42006 8.23031 5.12006 5.52031V5.43031L5.08006 4.76031V4.74031C5.08006 3.70031 5.37006 2.77031 5.98006 2.05031C7.03006 0.810313 8.20006 0.570312 8.99006 0.570312H9.01006C9.80006 0.570312 10.9701 0.810313 12.0201 2.05031C12.6301 2.77031 12.9201 3.70031 12.9201 4.74031V4.76031L12.8801 5.43031V5.52031C12.5801 8.23031 11.6101 9.61031 10.6301 10.2903L10.5301 10.3703H10.5201C9.80006 10.8303 9.19006 10.8703 9.03006 10.8703H9.00006ZM8.97006 20.5703C7.02006 20.5703 5.69006 20.1903 4.81006 19.8003L4.88006 17.3303C4.96006 16.7403 4.54006 16.2603 4.02006 16.1703C3.42006 16.0803 2.94006 16.5003 2.86006 17.0303L2.81006 19.0903C2.16006 19.0903 1.66006 18.9103 1.28006 18.6603C0.790062 18.3403 0.570062 17.9403 0.510062 17.8103L0.470062 17.7303L0.450062 17.6503C0.110062 16.1203 0.320062 14.6703 1.15006 13.4203H1.13006C2.07006 11.9703 3.65006 11.0503 4.97006 10.4803C5.12006 10.4103 6.66006 10.8403 7.06006 11.0903C7.46006 11.3403 7.67006 11.8703 8.98006 11.8703H9.02006C10.3301 11.8703 10.5401 11.3403 10.9401 11.0903C11.3401 10.8403 12.8701 10.4103 13.0301 10.4803C14.3501 11.0503 15.9301 11.9803 16.8701 13.4203H16.8501C17.6801 14.6703 17.8901 16.1203 17.5501 17.6503L17.5301 17.7303L17.4901 17.8103C17.4301 17.9403 17.2101 18.3403 16.7201 18.6603C16.3401 18.9103 15.8401 19.0903 15.1901 19.0903L15.1401 17.0303C15.0601 16.5003 14.5801 16.0803 13.9801 16.1703C13.4601 16.2603 13.0401 16.7403 13.1201 17.3303L13.1901 19.8003C12.3101 20.1903 10.9801 20.5603 9.03006 20.5703H8.97006Z"
        fill={fill}
      />
    </svg>
  );
};
