import { AuthContext } from 'auth/context/AuthContext';
import { ModalButtonProps } from 'components/modal/modal.interfaces';
import Modal from 'components/modal/modalComponent';
import { useGetComponent } from 'hooks/useGetComponent';
import { createModal } from 'layout/createModal';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { componentIds } from 'utilities/constants';
import { DASHBOARD } from 'utilities/routes';

const UserNotMatchPreworkModal = createModal<undefined, void>(
  'UserNotMatchPreworkModal',
  ({ close, isOpen }) => {
    const { logout } = useContext(AuthContext);
    const navigate = useNavigate();

    const { data: locale, loading: localeLoading } = useGetComponent({
      componentId: componentIds.USER_NOT_MATCH_PREWORK_MODAL,
      locale: 'en',
    });

    if (localeLoading) return null;

    const buttons: ModalButtonProps[] = [
      {
        label: locale.logout,
        onClick: () => {
          logout();
          navigate('/');
        },
        showSpinner: true,
      },
      {
        label: locale.cancel,
        onClick: () => navigate(DASHBOARD),
        type: 'outlined',
      },
    ];

    return (
      <Modal
        isOpen={isOpen}
        title={locale?.title}
        disableMobileGrow={true}
        buttons={buttons}
        onClose={close}
      >
        <p>{locale?.notMatchingUser}</p>
      </Modal>
    );
  },
);

export default UserNotMatchPreworkModal;
