import ButtonComponent from 'components/button/buttonComponent';
import InputComponent from 'components/inputComponent';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { FormEvent } from 'react';
import { pageIds } from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';

export const Verification = () => {
  const [, setValue] = useState('');
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.VERIFICATION,
  });

  const handleOnInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // TODO: Should perform check of MFA verification code.
  };

  if (loading && !locale) return null;

  return (
    <div className="bg-transparent overflow-hidden shadow desktop:rounded-lg desktop:mb-0 desktop:p-0 desktop:w-full desktop:max-w-screen-xl desktop:mx-auto">
      <div className="w-full bg-white bg-border rounded-lg p-[30px] desktop:py-16 desktop:px-40">
        <h1 className="text-h1 text-dark-gray text-center pb-[10px]">
          {locale?.verificationTitle}
        </h1>
        <h5 className="text-base text-med-gray text-center font-bold pb-[34px] desktop:text-h5">
          {locale?.instructionText}
        </h5>
        <form onSubmit={handleSubmit}>
          <label
            className="text-base text-dark-gray font-semibold block mb-[5px]"
            htmlFor="Verification Code"
          >
            {locale?.verificationCodeLabel}
          </label>
          <InputComponent
            testID="verification-code-input"
            type="text"
            name="Verification Code"
            onChange={handleOnInputChange}
          />

          <div className="flex justify-center pt-[30px]">
            <ButtonComponent
              testID="login-button"
              type="submit"
              paddingX="px-10"
              paddingY="py-[9.5px] desktop:py-[17px]"
              disabled={false}
            >
              {locale?.loginButton}
            </ButtonComponent>
          </div>
        </form>
        <Link
          className="text-clc-blue text-base font-bold underline mt-[20px] text-center block"
          to={'/auth/verification'}
        >
          {locale?.resendCodeLink}
        </Link>
      </div>
    </div>
  );
};
