import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export interface ModalManager {
  readonly isOpen: boolean;
  open(): void;
  close(): void;
}

export const useModalParams = (modalName: string): ModalManager => {
  const location = useLocation();
  const [modalOpen, setModal] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const modalParam = searchParams.get(modalName);
    if (modalParam === 'true') {
      setModal(true);
    } else {
      setModal(false);
    }
  }, [location.search, modalName]);

  return {
    isOpen: modalOpen,
    close: () => setModal(false),
    open: () => setModal(true),
  };
};
