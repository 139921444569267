import {
  TestKitsTableData,
  TestKitsTableHeaders,
  TestsSortTypes,
} from '../interfaces';
import React, { useCallback, useEffect, useState } from 'react';
import TableHeaders from './TableHeaders';
import TableBody from './TableBody';

interface Props {
  data: TestKitsTableData[];
  onLoadComplete: () => void;
}

export const DNATestKitTable: React.FC<Props> = ({ data, onLoadComplete }) => {
  const [testKitSorted, setTestKitSorted] = useState<TestKitsTableData[]>([]);

  const [selectedColumn, setSelectedColumn] =
    useState<TestKitsTableHeaders>('date');
  const [orderBy, setOrderBy] = useState<TestsSortTypes>('desc');

  const sortData = useCallback(
    (
      a: string,
      b: string,
      column: keyof TestKitsTableData,
      orderBy: TestsSortTypes,
    ) => {
      if (column === 'date') {
        const firstDate = new Date(a);
        const secondDate = new Date(b);
        const descOrd =
          firstDate.getTime() > secondDate.getTime()
            ? -1
            : secondDate.getTime() > firstDate.getTime()
            ? 1
            : 0;
        if (orderBy === 'desc') return descOrd;
        return descOrd * -1;
      }
      const descOrd = a > b ? -1 : b > a ? 1 : 0;
      if (orderBy === 'desc') return descOrd;
      return descOrd * -1;
    },
    [],
  );

  const handleOnColumnClicked = useCallback(
    (column: TestKitsTableHeaders) => {
      const toogledOrderBy = orderBy === 'asc' ? 'desc' : 'asc';
      setOrderBy(toogledOrderBy);
      setSelectedColumn(column);

      const sortedData = [...data];
      sortedData.sort((a, b) =>
        sortData(
          a[column]?.toLocaleLowerCase() ?? '',
          b[column]?.toLocaleLowerCase() ?? '',
          column,
          toogledOrderBy,
        ),
      );
      setTestKitSorted(sortedData);
    },
    [data, orderBy, sortData],
  );

  const orderByDateOnce = useCallback(() => {
    const sortedData = [...data];
    sortedData.sort((a, b) => sortData(a.date, b.date, 'date', 'desc'));
    setTestKitSorted(sortedData);
  }, [data, sortData]);

  useEffect(() => {
    orderByDateOnce();
  }, [orderByDateOnce]);

  useEffect(() => {
    if (data) {
      const sortedData = [...data];
      sortedData.sort((a, b) =>
        sortData(
          a[selectedColumn] ?? '',
          b[selectedColumn] ?? '',
          selectedColumn,
          orderBy,
        ),
      );
      setTestKitSorted(sortedData);
    }
  }, [data, orderBy, selectedColumn, sortData, setTestKitSorted]);

  return (
    <div className="mt-[30px] desktop:flex desktop:flex-col">
      <TableHeaders
        selectedColumn={selectedColumn}
        orderBy={orderBy}
        handleColumnClicked={handleOnColumnClicked}
      />
      <TableBody data={testKitSorted} onLoadComplete={onLoadComplete} />
    </div>
  );
};

export default DNATestKitTable;
