import { useGetPage } from 'hooks/useGetPage';
import { genericActionsIds, pageIds } from 'utilities/constants';
import { ReactComponent as ChevronDownIcon } from 'assets/icons/chevron-down.svg';
import { ReactComponent as ChevronUpIcon } from 'assets/icons/chevron-up.svg';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { AuthContext } from 'auth/context/AuthContext';
import { AccountDetail } from './PersonalInfo/AccountDetail';
import { PersonalDetail } from './PersonalInfo/PersonalDetail';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import { useUpdateProfileNotificationMutation } from 'graphql/generated/hasura';

import {
  GeneralStates,
  PersonalData,
  PersonalInfoProps,
  UserMeasurements,
} from 'app/my-account/interfaces/profile.interfaces';
import {
  allowOnlyLettersWithAllowedCharacters,
  allowOnlyNumbers,
  getFirstNameFromDisplayName,
  getLastNameFromDisplayName,
  scrollToTop,
} from 'utilities/functions';
import ButtonComponent from 'components/button/buttonComponent';
import {
  personalInfoRequiredFields,
  userMeasurementsRequiredFields,
} from './constants';
import { usePatchFhirPatientPersonalDataMutation } from 'graphql/generated/remote-schema-hasura';
import { PersonaSection } from '../personaComponents/PersonaSection';
import { CodexFeatureFlags } from 'utilities/interfaces';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const PersonalInfo: React.FC<PersonalInfoProps> = ({
  firstname,
  lastname,
  email,
  dob,
  userMeasurements,
  etnicity,
  gender,
  selfIdentifyGender,
  handleUpdatedPersonalInfo,
  handlePersonalInfoChange,
  userVerificationStatus,
}) => {
  const { enablePersonaVerification } = useFlags<CodexFeatureFlags>();
  const [isSubmiting, setIsSubmiting] = useState(false);
  const { user: loggedUser } = useContext(AuthContext);
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.MY_PROFILE,
  });
  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.UPDATE],
    });
  const [isFormDisplayed, setIsFormDisplayed] = useState<boolean>(true);
  const [formValues, setFormValues] = useState<PersonalData>({
    firstname:
      firstname || getFirstNameFromDisplayName(loggedUser?.displayName),
    lastname: lastname || getLastNameFromDisplayName(loggedUser?.displayName),
    email,
    dob,
    userMeasurements,
    etnicity,
    gender,
    selfIdentifyGender,
  });

  const [patchFhirPatientPersonalData] =
    usePatchFhirPatientPersonalDataMutation({});
  const [updateProfileNotification] = useUpdateProfileNotificationMutation({});

  const handleShowForm = () => {
    setIsFormDisplayed(!isFormDisplayed);
  };

  const handleOnInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;

    if (name === 'firstname' || name === 'lastname') {
      if (allowOnlyLettersWithAllowedCharacters(value)) {
        setFormValues({ ...formValues, [name]: value });
      }
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const handleOnUserMeasurementsChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;

    if (allowOnlyNumbers(value) && value !== '0') {
      setFormValues({
        ...formValues,
        userMeasurements: { ...formValues.userMeasurements, [name]: value },
      });
    }
  };

  const handleOnDateChange = (e: { target: HTMLInputElement }) => {
    const { value, name } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleOnSelectChange = (e: { target: HTMLSelectElement }) => {
    const { value, name } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  useEffect(() => {
    handlePersonalInfoChange(formValues);
  }, [formValues, handlePersonalInfoChange]);

  if (loading || !locale || genericActionLoading || !genericAction) return null;

  const accountDetailsFields = [
    {
      label: locale?.personalInfo.accountDetails.firstNameLabel,
      type: 'text',
      placeholder: locale?.personalInfo.accountDetails.firstNamePlaceholder,
      name: 'firstname',
      value: formValues.firstname,
      onChange: handleOnInputChange,
      maxLengthValue: 100,
    },
    {
      label: locale?.personalInfo.accountDetails.lastNameLabel,
      type: 'text',
      placeholder: locale?.personalInfo.accountDetails.lastNamePlaceholder,
      name: 'lastname',
      value: formValues.lastname,
      onChange: handleOnInputChange,
      maxLengthValue: 100,
    },
    {
      label: locale?.personalInfo.accountDetails.emailLabel,
      type: 'text',
      placeholder: locale?.personalInfo.accountDetails.emailPlaceholder,
      name: 'email',
      value: formValues.email,
      onChange: handleOnInputChange,
      isDisabled: true,
    },
  ];

  const validateRequiredFields = () => {
    for (const key in formValues) {
      if (key === 'etnicity') {
        if (formValues.etnicity.length === 0) return false;
      }
      if (key === 'userMeasurements') {
        for (const requiredField of userMeasurementsRequiredFields) {
          if (!formValues[key][requiredField as keyof UserMeasurements])
            return false;
        }
      }
      if (
        personalInfoRequiredFields.find(
          (requiredField) => requiredField === key,
        )
      ) {
        if (!formValues[key as keyof PersonalData]) {
          return false;
        }
      }
    }
    return true;
  };

  const handleOnSubmit = async () => {
    const isFormValid = validateRequiredFields();
    if (!isFormValid)
      return handleUpdatedPersonalInfo(GeneralStates.MISSING_INFO);

    try {
      setIsSubmiting(true);
      const response = await patchFhirPatientPersonalData({
        variables: {
          patientPersonalInfo: {
            codexID: loggedUser?.uuid || '',
            SENSITIVE_firstname: formValues.firstname || null,
            SENSITIVE_lastname: formValues.lastname || null,
            SENSITIVE_dob: formValues.dob || null,
            SENSITIVE_etnicity: formValues.etnicity || null,
            SENSITIVE_gender: formValues.gender || null,
            SENSITIVE_self_identity_gender:
              formValues.selfIdentifyGender || null,
            SENSITIVE_user_measurements: {
              heightFt: Number(formValues.userMeasurements?.heightFt) || null,
              heightIn: Number(formValues.userMeasurements?.heightIn) || null,
              weight: Number(formValues.userMeasurements?.weight) || null,
              hips: Number(formValues.userMeasurements?.hips) || null,
              waist: Number(formValues.userMeasurements?.waist) || null,
            },
          },
        },
      });
      if (!response.data) {
        setIsSubmiting(false);
        handleUpdatedPersonalInfo(GeneralStates.ERROR);
        scrollToTop();
        throw new Error('Failed to update user');
      }

      handleUpdatedPersonalInfo(GeneralStates.SUCCESS);
      await updateProfileNotification();

      setIsSubmiting(false);
      scrollToTop();
    } catch (error: unknown) {
      setIsSubmiting(false);
      console.log(error);
      handleUpdatedPersonalInfo(GeneralStates.ERROR);
      scrollToTop();
      throw new Error('Failed to update user');
    }
  };

  return (
    <div className="flex flex-col items-center bg-white w-full p-5 desktop:p-[30px] gap-5 rounded-10 desktop:rounded-tl-none">
      <div
        className="flex flex-col items-start w-full gap-2.5 p-0 cursor-pointer desktop:cursor-default"
        onClick={handleShowForm}
      >
        <div className="flex flex-row w-full items-center justify-between">
          <div className="flex flex-row items-center">
            <h3 className="text-h5 desktop:text-h4 text-dark-gray font-semibold desktop:mr-2.5">
              {locale?.personalInfo.title}
            </h3>
          </div>
          {isFormDisplayed ? (
            <ChevronUpIcon
              className="desktop:hidden w-[18px] h-2.5 fill-current cursor-pointer"
              onClick={handleShowForm}
            />
          ) : (
            <ChevronDownIcon
              className="desktop:hidden w-[18px] h-2.5 fill-current cursor-pointer"
              onClick={handleShowForm}
            />
          )}
        </div>
        <hr
          className={`desktop:flex flex-row w-full items-center h-px bg-black-blur ${
            isFormDisplayed ? 'flex' : 'hidden'
          }`}
        />
      </div>

      <div
        className={`w-full desktop:flex flex-col gap-[15px] ${
          isFormDisplayed ? 'flex' : 'hidden'
        }`}
      >
        <AccountDetail
          locale={locale}
          accountDetailsFields={accountDetailsFields}
        />
        <PersonalDetail
          locale={locale}
          formValues={formValues}
          setFormValues={setFormValues}
          onChange={handleOnUserMeasurementsChange}
          onDateChange={handleOnDateChange}
          onSelectChange={handleOnSelectChange}
        />

        {enablePersonaVerification && (
          <PersonaSection
            locale={locale}
            userVerificationStatus={userVerificationStatus}
          />
        )}

        <div className="hidden w-full desktop:flex justify-end">
          <ButtonComponent
            disabled={isSubmiting}
            iconPosition="right"
            className="px-10"
            onClick={handleOnSubmit}
          >
            {genericAction?.[genericActionsIds.UPDATE].update}
          </ButtonComponent>
        </div>
      </div>
    </div>
  );
};
