import { useLocation, useNavigate } from 'react-router-dom';
import { useGetPage } from 'hooks/useGetPage';
import {
  LOCAL_STORAGE_USER_SCANNED_TEST_KIT_ID,
  LOCAL_STORAGE_USER_SCANNED_TRACKING_NUMBER,
  pageIds,
} from 'utilities/constants';
import { ModalButtonProps } from 'components/modal/modal.interfaces';
import { AUTH_LOGIN } from 'utilities/routes';
import AlertComponent from 'components/alertComponent';
import Modal from 'components/modal/modalComponent';
import StorageService from 'utilities/storageService';

export const BarcodeScanCompleted = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const testKitNumber = location.state?.testKitNumber || '';
  const testKitReturnNumber = location.state?.testKitReturnNumber || '';
  const invalidToken = location.state?.invalidToken || '';
  const unexpectedError = location.state?.unexpectedError || '';

  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.BARCODE_SCAN_COMPLETED,
  });

  const handleLogin = () => {
    new StorageService(
      LOCAL_STORAGE_USER_SCANNED_TEST_KIT_ID,
      localStorage,
      testKitNumber,
    );
    new StorageService(
      LOCAL_STORAGE_USER_SCANNED_TRACKING_NUMBER,
      localStorage,
      testKitReturnNumber,
    );
    navigate(AUTH_LOGIN, {
      replace: true,
    });
  };

  if (loading || !locale) return null;

  const Buttons: ModalButtonProps[] = [
    {
      label: locale?.logIn,
      onClick: handleLogin,
    },
  ];

  const getAlertMessage = () => {
    if (invalidToken) {
      return locale?.invalidToken;
    } else if (unexpectedError) {
      return locale?.unexpectedError;
    } else {
      return locale?.successResults;
    }
  };

  return (
    <Modal
      isOpen={true}
      title={locale?.testKitBarcodesScanner}
      buttons={Buttons}
      disableMobileClose={true}
    >
      <div className="flex flex-col">
        <AlertComponent
          type={invalidToken || unexpectedError ? 'warning' : 'positive'}
          text={getAlertMessage()}
        />
      </div>
    </Modal>
  );
};
