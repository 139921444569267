import { useContext } from 'react';
import { AuthContext, AuthContextType } from 'auth/context/AuthContext';

export const useLocalStorage = () => {
  const { user } = useContext<AuthContextType>(AuthContext);

  const setItemWithUniqueKey = (key: string, value: string) => {
    if (user?.uuid) {
      localStorage.setItem(
        key,
        JSON.stringify({
          [user?.uuid]: value,
        }),
      );
    }
  };

  const getItemWithUniqueKey = (key: string) => {
    if (user?.uuid) {
      const result = localStorage.getItem(key);
      if (!result) return null;

      const object = JSON.parse(result);
      return object[user.uuid] || null;
    } else {
      return null;
    }
  };

  const removeItemWithUniqueKey = (key: string) => {
    if (user?.uuid) {
      const result = localStorage.getItem(key);
      if (!result) return;

      const object = JSON.parse(result);
      delete object[user.uuid];
      localStorage.setItem(key, JSON.stringify(object));
    }
  };

  return {
    setItemWithUniqueKey,
    getItemWithUniqueKey,
    removeItemWithUniqueKey,
  };
};
