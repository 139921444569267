import { Record } from 'components/codexTable/interfaces/record.interfaces';
import { SetStateAction } from 'react';
import { Roles } from '../../../firebase/interfaces';

export enum Tabs {
  Patient,
  Prework,
  Chat,
  MyNotes,
}

export enum SenderType {
  PATIENT = 'patient',
  PROVIDER = 'provider',
}

export enum MessageType {
  MESSAGE = 'message',
  MEDIA = 'media',
}

export enum VonageMessageType {
  TEXT = 'text',
  IMAGE = 'image',
}

export interface ChatMessageLocale {
  mediaAlt: string;
  cameraAlt: string;
  senderPictureAlt: string;
}

export interface Patient {
  picture: string | undefined;
  name: string;
  dob: string;
  height: string;
  weight: string;
  waist: string;
  hips: string;
  ethnicity: string;
  gender: string;
  selfIdentification: string;
}

interface SymptomsLocation {
  front: string[];
  back: string[];
}

export enum DOSAGE_ENUM {
  MG = 'mg',
  ML = 'ml',
}

export enum FREQUENCY_ENUM {
  DAILY = 'perDay',
  WEEKLY = 'perWeek',
  MONTHLY = 'perMonth',
}

export interface Medication {
  id: string;
  name: string;
  type: string;
  brand: string;
  delivery: string;
  dosage: {
    quantity: string;
    unit: DOSAGE_ENUM;
  };
  frequency: {
    quantity: number;
    unit: FREQUENCY_ENUM;
  };
}

export interface Symptom {
  id: string;
  name: string;
}

export interface Prework {
  subject: string;
  symptoms: Symptom[];
  symptomsDuration: string;
  symptomsSeverity: string;
  symptomsLocation: SymptomsLocation;
}

export interface ChatMessage extends Record {
  senderPicture?: string | null;
  senderName?: string | null;
  senderType: Roles;
  mediaURL?: string;
  message?: string;
  messageGroup?: ChatMessage[];
  type?: MessageType;
}

export interface Medications {
  prescriptions: Medication[];
  overTheCounter: Medication[];
}

export interface Summary {
  patient: Patient;
  prework: Prework;
  chatMessages: ChatMessage[];
  medications: Medications;
  providerNotes?: string;
  providerSummary?: string;
  followUpRecommendations?: string;
  summaryDate?: string;
}

export interface VonageMessage {
  data: string;
  isSentByMe: boolean;
  date: string;
}

export interface ChangeTextEvent {
  target: { value: SetStateAction<string> };
}

export interface OnKeyDownEvent {
  keyCode: number;
  preventDefault: () => void;
}
