import { useEffect, useRef } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

// useNavigate return value is updated when the location changes
// so in we want to call navigate from an useEffect and we don't
// want the location to be a dependency, we can use this hook
export const useNavigateRef = () => {
  const navigate = useNavigate();
  const navRef = useRef<NavigateFunction>(navigate);

  useEffect(() => {
    navRef.current = navigate;
  }, [navigate]);

  return navRef;
};
