import { Route, Routes } from 'react-router-dom';
import MySkinPage from '../pages/MySkinPage';

export const MySkinRoutes = () => {
  return (
    <Routes>
      <Route path="/*" element={<MySkinPage />} />
    </Routes>
  );
};
