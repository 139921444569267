import { SidebarProps } from './sidebar.interface';
import { ReactComponent as ChevronRight } from '../../assets/icons/chevron-right.svg';

export const SidebarComponent: React.FC<SidebarProps> = ({
  items,
  currentItem,
  onClick,
}) => {
  return (
    <>
      <div className="hidden desktop:flex desktop:w-full desktop:max-w-[360px] desktop:mx-auto">
        <div className="w-full desktop:flex desktop:flex-col">
          {items.map((item) => (
            <div
              className={`${
                currentItem === item.name ? 'desktop:bg-white' : ''
              } cursor-pointer w-full flex justify-between items-center desktop:pl-5 desktop:pr-2.5 rounded-s-[10px] bg-transparent p-0`}
              key={item.name}
              onClick={() => onClick(item.name)}
            >
              <div className="flex items-center">
                <li className="p-5 desktop:flex hidden text-dark-gray font-semibold text-h6">
                  {item.name}
                </li>
              </div>
              <div
                className={`${
                  currentItem === item.name ? 'desktop:flex' : 'hidden'
                } hidden`}
              >
                <ChevronRight style={{ width: '10px', height: '15px' }} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
