import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ModalButtonProps } from 'components/modal/modal.interfaces';
import { useGetPage } from 'hooks/useGetPage';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import { genericActionsIds, pageIds } from 'utilities/constants';
import { useRootConfig } from 'utilities/rootConfig';
import { useGenerateCustomToken } from '../../../firebase/hooks';
import {
  QR_ACTION,
  TEST_KITS_MANAGEMENT_REGISTER_TEST_KIT,
} from 'utilities/routes';
import { useGetDnaScannedDataByUserIdSubscription } from 'graphql/generated/hasura';
import { AuthContext, AuthContextType } from 'auth/context/AuthContext';
import Modal from 'components/modal/modalComponent';
import QRCode from 'react-qr-code';

export const ScanTestQr = () => {
  const navigate = useNavigate();
  const { baseUrl } = useRootConfig();
  const { handleGenerateCustomToken, data, error } = useGenerateCustomToken();
  const [link, setLink] = useState<string>('');
  const [lastUpdate, setLastUpdate] = useState<string | null>(null);
  const isFirstRender = useRef<boolean>(true);
  const { user: loggedUser } = useContext<AuthContextType>(AuthContext);
  const { data: scannedData } = useGetDnaScannedDataByUserIdSubscription({
    variables: {
      user_id: loggedUser?.uuid,
    },
  });
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.TEST_KIT_SCANNER,
  });
  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.CLOSE],
    });

  useEffect(() => {
    if (scannedData?.user_scanned_data) {
      const updatedAt = scannedData?.user_scanned_data[0]?.updated_at;

      if (isFirstRender.current) {
        isFirstRender.current = false;
        setLastUpdate(updatedAt);
        return;
      }

      if (scannedData.user_scanned_data.length && lastUpdate !== updatedAt) {
        navigate(TEST_KITS_MANAGEMENT_REGISTER_TEST_KIT);
      }
    }
  }, [scannedData, lastUpdate, navigate]);

  useEffect(() => {
    const token = data?.GenerateCustomToken?.token;
    if (token) {
      const link = `${baseUrl}${QR_ACTION}?action=scanBarcode&token=${token}`;
      setLink(link);
    }

    if (error) {
      navigate(TEST_KITS_MANAGEMENT_REGISTER_TEST_KIT, {
        state: { qrCodeError: true },
      });
    }
  }, [baseUrl, data, error, navigate]);

  useEffect(() => {
    handleGenerateCustomToken();
    const interval = setInterval(() => {
      handleGenerateCustomToken();
    }, 55 * 60 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [handleGenerateCustomToken]);

  const handleModalClose = () => {
    navigate(TEST_KITS_MANAGEMENT_REGISTER_TEST_KIT);
  };

  const Buttons: ModalButtonProps[] = [
    {
      label: genericAction?.[genericActionsIds.CLOSE].close,
      onClick: handleModalClose,
    },
  ];

  if ((loading && !locale) || (genericActionLoading && !genericAction) || !link)
    return null;

  return (
    <Modal
      isOpen={true}
      title={locale?.takeAPhoto}
      buttons={Buttons}
      onClose={handleModalClose}
    >
      <div className="flex flex-col gap-[40px]">
        <p className="text-base font-semibold text-med-gray">
          {locale?.scanQrCode}
        </p>
        <QRCode size={300} className="self-center" value={link} />
      </div>
    </Modal>
  );
};
