import { DEVICE_ACCESS_STATUS, pageIds } from 'utilities/constants';
import accessPending from 'assets/images/access-dialog-pending.png';
import accessRejected from 'assets/images/access-dialog-rejected.png';
import { useGetPage } from 'hooks/useGetPage';

interface DeviceAcessAlertProps {
  accessStatus: string;
}

export const DeviceAcessAlert: React.FC<DeviceAcessAlertProps> = ({
  accessStatus,
}) => {
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.VONAGE,
  });

  if (loading || !locale) return null;

  const messageToDisplay =
    accessStatus === DEVICE_ACCESS_STATUS.PENDING
      ? locale?.askDeviceMessage
      : locale?.deniedDeviceMessage;
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
      <div className="flex flex-col bg-white p-8 rounded-lg w-1/3 gap-2">
        <h2 className="font-medium">{messageToDisplay}</h2>
        <div className="flex justify-center">
          {accessStatus === DEVICE_ACCESS_STATUS.PENDING ? (
            <img src={accessPending} alt="access pending" />
          ) : (
            <img src={accessRejected} alt="access rejected" />
          )}
        </div>
      </div>
    </div>
  );
};
