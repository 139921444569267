interface CarouselDotsProps {
  selectedDotIndex: number;
  carouselLength: number;
}

const CarouselDots: React.FC<CarouselDotsProps> = ({
  selectedDotIndex,
  carouselLength,
}) => {
  const getDotClass = (index: number): string => {
    return index === selectedDotIndex ? 'bg-clc-blue' : 'bg-black-blur';
  };

  const renderCarouselItems = () => {
    return Array.from({ length: carouselLength }, (_, index) => (
      <div
        key={index}
        className="flex flex-row w-5 h-5 items-center justify-center p-0 gap-2.5"
      >
        <div className={`w-4 h-4 ${getDotClass(index)} rounded-full`} />
      </div>
    ));
  };

  return (
    <div className="flex flex-row items-start p-0 gap-5">
      {renderCarouselItems()}
    </div>
  );
};

export default CarouselDots;
