import InputComponent from 'components/inputComponent';
import {
  DynamicQuestionnaireCurrentIndexOfSection,
  HandleOnInputChangeType,
} from '../interfaces/dynamicQuestionnaire.interface';
import {
  DropdownItem,
  DropdownOption,
  DropdownOptionsState,
  DropdownResponses,
  DynamicQuestionResponseSection,
  QuestionnaireResponseInterface,
  questionnaire_questions_question,
  RequiredIdsList,
} from '../interfaces/dynamicQuestionnaireResponse.interface';
import { getUiControlType } from '../utils/utils';
import {
  Dispatch,
  LegacyRef,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { UI_CONTROL } from '../interfaces/uiControl.interface';
import React, { useState } from 'react';
import {
  GroupBase,
  MenuListProps,
  MultiValue,
  Options,
  SingleValue,
} from 'react-select';
import { ReactComponent as MinusIcon } from '../../../assets/icons/minusIcon.svg';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plusIcon.svg';
import CreatableSelect from 'react-select/creatable';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
  ListProps,
} from 'react-virtualized';
import { MeasuredCellParent } from 'react-virtualized/dist/es/CellMeasurer';
import { useGetComponent } from 'hooks/useGetComponent';
import { componentIds, genericActionsIds } from 'utilities/constants';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import { GetQuestionnaireResponsesByUserAndQuestionnaireIdQuery } from 'graphql/generated/hasura';

const cache = new CellMeasurerCache({
  fixedWidth: true,
  defaultHeight: 80,
});

const MenuList = (
  props:
    | MenuListProps<
        | string
        | {
            label: string;
            value: string;
          },
        false,
        GroupBase<
          | string
          | {
              label: string;
              value: string;
            }
        >
      >
    | ListProps
    | undefined,
) => {
  const list = props && props.children;

  const rowRenderer = ({
    index,
    key,
    style,
    parent,
  }: {
    index: number;
    key: React.Key;
    style: React.CSSProperties;
    parent: MeasuredCellParent;
  }) => {
    return (
      <CellMeasurer
        key={key}
        cache={cache}
        parent={parent}
        columnIndex={0}
        rowIndex={index}
      >
        {({ registerChild }) => (
          <div style={style} ref={registerChild as LegacyRef<HTMLDivElement>}>
            <div className="flex flex-1">{list[index]}</div>
          </div>
        )}
      </CellMeasurer>
    );
  };

  return (
    <div className="w-full h-[350px]">
      <AutoSizer>
        {({ width, height }) => (
          <List
            width={width}
            height={height}
            deferredMeasurementCache={cache}
            rowHeight={cache.rowHeight}
            rowRenderer={rowRenderer}
            rowCount={list?.length}
            overscanRowCount={3}
          />
        )}
      </AutoSizer>
    </div>
  );
};

interface HealthQuestionnaireModule {
  setPersistentData: Dispatch<
    React.SetStateAction<QuestionnaireResponseInterface[]>
  >;
  questionList: questionnaire_questions_question[];
  questionnaireData: QuestionnaireResponseInterface[];
  currentIndexSection: DynamicQuestionnaireCurrentIndexOfSection;
  radioCheckedState: QuestionnaireResponseInterface[];
  checkboxesCheckedState: QuestionnaireResponseInterface[];
  section: DynamicQuestionResponseSection;
  onChange: HandleOnInputChangeType;
  handleCheckboxToggle: (
    answer: string,
    questionID: number | undefined,
    questionParentID?: number,
    childrenOriginalQuestionID?: string,
  ) => void;
  setRadioCheckedState: Dispatch<
    SetStateAction<QuestionnaireResponseInterface[]>
  >;
  matchSelectedRadio: (
    question: number,
    stringToMatch: string,
    latestState: QuestionnaireResponseInterface[],
  ) => QuestionnaireResponseInterface | undefined;

  handleRadioClick: (question: number, value: string | undefined) => void;
  textFieldResponses: QuestionnaireResponseInterface[];
  setTextFieldResponses: Dispatch<
    SetStateAction<QuestionnaireResponseInterface[]>
  >;
  onDropdownWithMultiSelectChange: (
    value: string[],
    questionID: number,
  ) => void;
  onDoubleTextFieldChange: (
    value: QuestionnaireResponseInterface[],
    questionID: number,
  ) => void;
  selectsFromStorage?: QuestionnaireResponseInterface[];
  medicationsDataFromStorage?: QuestionnaireResponseInterface[];
  requiredQuestionsIds: RequiredIdsList;
  refetchData:
    | (() => Promise<GetQuestionnaireResponsesByUserAndQuestionnaireIdQuery>)
    | undefined;
}

const HealthQuestionnaireModule: React.FC<HealthQuestionnaireModule> = ({
  setPersistentData,
  questionList,
  checkboxesCheckedState,
  section,
  onChange,
  handleCheckboxToggle,
  matchSelectedRadio,
  radioCheckedState,
  handleRadioClick,
  textFieldResponses,
  setTextFieldResponses,
  onDropdownWithMultiSelectChange,
  onDoubleTextFieldChange,
  selectsFromStorage,
  medicationsDataFromStorage,
  requiredQuestionsIds,
  refetchData,
}) => {
  const {
    data: dynamicQuestionnaireLocale,
    loading: dynamicQuestionnaireLocaleLoading,
  } = useGetComponent({
    locale: 'en',
    componentId: componentIds.DYNAMIC_QUESTIONNAIRE,
  });

  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.BEGINTYPINGTOLOOKUP],
    });

  const isRequiredQuestion = (questionId: string): boolean => {
    const isRequired = requiredQuestionsIds.find((id) => id === questionId);
    return Boolean(isRequired);
  };

  const setMedicationStorageValues = useCallback(() => {
    const defaultValue: QuestionnaireResponseInterface[] = [];
    return medicationsDataFromStorage?.length === 0
      ? defaultValue
      : medicationsDataFromStorage ?? defaultValue;
  }, [medicationsDataFromStorage]);

  const [medicationList, setMedicationList] = useState<
    QuestionnaireResponseInterface[]
  >(setMedicationStorageValues());

  const getMedicationListValue = (
    index: number,
    questionID: number,
    inputID?: string,
  ) => {
    let parsedResponse: { label: string; value: string } = {
      label: '',
      value: '',
    };
    let singleResponse = '';

    const indexedQuery = medicationList.findIndex((item) => {
      return item.id === index;
    });
    const indexedAnswers = medicationList[indexedQuery].answers;

    const responseFromStorage = indexedAnswers?.find(
      (item: QuestionnaireResponseInterface) => {
        return item.questionID === questionID;
      },
    );

    if (responseFromStorage && inputID !== 'text-area') {
      parsedResponse = {
        label: responseFromStorage.response as string,
        value: responseFromStorage.response as string,
      };
    } else if (responseFromStorage && inputID === 'text-area') {
      singleResponse = responseFromStorage.response as string;
    }

    return inputID === 'text-area' ? singleResponse : parsedResponse;
  };

  const [textLocalFieldResponses, setTextLocalFieldResponses] = useState<
    QuestionnaireResponseInterface[]
  >([]);

  const insertAfter = (
    id: number,
    medication: QuestionnaireResponseInterface,
  ) => {
    const array = [...medicationList];
    const index = medicationList.findIndex((item) => item.id === id);
    array.splice(index + 1, 0, medication);
    setMedicationList(array);
  };

  const removeMedication = (idToRemove: number) => {
    const updatedArray = medicationList
      .filter((medication) => medication.id !== idToRemove)
      .map((medication, index) => ({
        ...medication,
        id: index, // Resetting the id based on the new index
      }));
    setMedicationList(updatedArray);
  };

  const setSelectStorageValues = useCallback((): DropdownOptionsState[] => {
    const defaultValue: DropdownOptionsState[] = [{ value: [] }, { value: [] }];

    const selectValues = (questionID: number) =>
      selectsFromStorage?.filter((data) => {
        if (data.questionID === questionID) {
          return data.response;
        }
      });

    const populatedValue: DropdownOptionsState[] = [
      { value: (selectValues(71)?.[0]?.response as string[]) || [] },
      { value: (selectValues(72)?.[0]?.response as string[]) || [] },
    ];

    return populatedValue ? populatedValue : defaultValue;
  }, [selectsFromStorage]);

  const [selectedValue, setSelectedValue] = useState<DropdownOptionsState[]>(
    setSelectStorageValues(),
  );

  const handleChange = (
    e: MultiValue<DropdownOption>,
    no: number,
    questionID: number,
  ) => {
    const newItem: DropdownOptionsState[] = selectedValue.map(
      (item: DropdownOptionsState) => {
        return selectedValue.indexOf(item) === no
          ? { value: Array.isArray(e) ? e.map((x) => x.value) : [] }
          : item;
      },
    );
    // If length of array is > 1 and none option is selected, remove it
    const filteredOptions = newItem.map((item) => {
      if (
        item.value.some((value) => value.toLowerCase() === 'none') &&
        item.value.length > 1
      ) {
        return {
          ...item,
          value: item.value.filter((value) => value.toLowerCase() !== 'none'),
        };
      } else {
        return item;
      }
    });

    onDropdownWithMultiSelectChange(newItem[no].value, questionID);
    setSelectedValue(filteredOptions);
  };

  const parseQuestionOptionsForDropdownWithLookup = (options: string[]) => {
    const parsedOptions = options.map((option: string) => ({
      label: option,
      value: option,
    }));
    return parsedOptions;
  };

  // Use this to add the unit of measure that come from the profile
  const getUnitOfMeasure = () => {
    return 'in.';
  };

  const getResponseByQuestionID = (questionIDToFind: number) => {
    const foundResponse = textFieldResponses?.find(
      (response) => response.questionID === questionIDToFind,
    );

    return foundResponse ? (foundResponse.response as string) : '';
  };

  const getResponseByInputId = (inputIDToFind: string) => {
    const foundResponse = textFieldResponses?.find(
      (response) => response.inputId === inputIDToFind,
    );

    return foundResponse ? (foundResponse.response as string) : '';
  };

  const handleTextFieldResponses = (
    value: string,
    questionID: number,
    question: questionnaire_questions_question,
  ) => {
    if (Array.isArray(textFieldResponses) && setTextFieldResponses) {
      // Check if the questionID already exists in the array
      const existingIndex = textFieldResponses.findIndex(
        (response) => response.questionID === questionID,
      );

      if (existingIndex !== -1) {
        // If the questionID exists, update the existing item
        const updatedResponses = [...textFieldResponses];
        updatedResponses[existingIndex].response = value;
        setTextFieldResponses(updatedResponses);
        onChange(
          (value as string) || '',
          question?.questionnaire_questions_question.id || 0,
        );
      } else {
        // If the questionID doesn't exist, create a new response
        const newResponse: QuestionnaireResponseInterface = {
          questionID: questionID,
          response: value,
        };

        setTextFieldResponses([...textFieldResponses, newResponse]);

        onChange(
          (value as string) || '',
          question?.questionnaire_questions_question.id || 0,
        );
      }
    }
  };

  const handleDoubleComboTextFieldResponses = (
    value: string,
    questionID: number,
    question: questionnaire_questions_question,
    inputId: string,
  ) => {
    if (Array.isArray(textFieldResponses) && setTextFieldResponses) {
      const filteredResponses = textFieldResponses.filter(
        (response) => response.questionID === questionID,
      );

      // Check if the questionID already exists in the array
      const existingIndex = textFieldResponses.findIndex(
        (response) => response.inputId === inputId,
      );

      if (existingIndex !== -1) {
        const existingLocalIndex = filteredResponses.findIndex(
          (response) => response.inputId === inputId,
        );

        // If the questionID exists, update the existing item
        const updatedResponses = [...filteredResponses];
        updatedResponses[existingLocalIndex] = {
          ...updatedResponses[existingLocalIndex],
          response: value,
          inputId: inputId,
          inputLabel:
            inputId === 'input-1'
              ? question.questionnaire_questions_question
                  .double_text_combo_helper_texts?.helper_text_1
              : question.questionnaire_questions_question
                  .double_text_combo_helper_texts?.helper_text_2,
        };

        setTextFieldResponses(updatedResponses);
        onDoubleTextFieldChange(
          updatedResponses || '',
          question?.questionnaire_questions_question.id || 0,
        );
      } else {
        // If the questionID doesn't exist, create a new response
        const newResponse: QuestionnaireResponseInterface = {
          questionID: questionID,
          response: value,
          inputId: inputId,
          inputLabel:
            inputId === 'input-1'
              ? question.questionnaire_questions_question
                  .double_text_combo_helper_texts?.helper_text_1
              : question.questionnaire_questions_question
                  .double_text_combo_helper_texts?.helper_text_2,
        };

        setTextFieldResponses([...textFieldResponses, newResponse]);
        onDoubleTextFieldChange(
          [newResponse],
          question?.questionnaire_questions_question.id || 0,
        );
      }
    }
  };

  const handleDropdownOptionSelected = (
    value: SingleValue<string | { label: string; value: string }>,
    questionID: number,
    dropdownID: string,
    medicationListID: number,
  ) => {
    const selectedValue = (value?.valueOf() as { value: string }).value;
    const updatedMedicationList = medicationList.map((medicationItem) => {
      if (medicationItem.id === medicationListID && medicationItem.answers) {
        // Update the answers for the matching medication
        const updatedAnswers = [...medicationItem.answers];

        // Check if there's an existing answer for the same questionID
        const existingAnswerIndex = updatedAnswers.findIndex(
          (answer) => answer.questionID === questionID,
        );

        if (existingAnswerIndex !== -1) {
          // Update the existing answer with the new response values
          updatedAnswers[existingAnswerIndex] = {
            ...updatedAnswers[existingAnswerIndex], // Create a shallow copy of the answer object
            response: selectedValue,
          };
        } else {
          // If there is no existing answer, create a new one
          updatedAnswers.push({
            questionID: questionID,
            response: selectedValue,
          });
        }

        // Check if dropdownID includes 'double-1' or 'double-2'
        if (
          dropdownID.includes('double-1') ||
          dropdownID.includes('double-2')
        ) {
          // Handle the double dropdown logic and update the response array

          // Define the keys for the response objects
          const responseKey = dropdownID.includes('double-1')
            ? 'answer-1'
            : 'answer-2';

          // Find the existing response for 'double-1' or 'double-2'
          const existingDoubleResponseIndex = updatedAnswers.findIndex(
            (answer) =>
              answer.questionID === questionID &&
              answer.response !== null &&
              typeof answer.response === 'object' &&
              'id' in answer.response &&
              answer.response.id === responseKey,
          );

          if (existingDoubleResponseIndex !== -1) {
            updatedAnswers[existingDoubleResponseIndex] = {
              ...updatedAnswers[existingDoubleResponseIndex],
              response: {
                // ...updatedAnswers[existingDoubleResponseIndex].response,
                ...(updatedAnswers[existingDoubleResponseIndex]
                  .response as QuestionnaireResponseInterface),
                value: value,
              } as QuestionnaireResponseInterface,
            };
          } else {
            // If there is no existing response, create a new one
            updatedAnswers.push({
              questionID: questionID,
              response: { id: responseKey, value: value as string },
            });
          }
        }

        return {
          ...medicationItem,
          answers: updatedAnswers,
        };
      }

      return medicationItem;
    });

    // Update the state with the modified medicationList
    setMedicationList(updatedMedicationList);
  };

  const handleLocalTextFieldResponses = (
    value: string,
    questionID: number,
    medicationID: number,
  ) => {
    // Ensure that textLocalFieldResponses is initialized as an empty array initially
    if (!Array.isArray(textLocalFieldResponses)) {
      setTextLocalFieldResponses([]);
    }

    // Find an existing response with the same questionID and medicationID
    const existingResponseIndex = textLocalFieldResponses.findIndex(
      (response) =>
        response.medicationID === medicationID &&
        response.questionID === questionID,
    );

    if (existingResponseIndex !== -1) {
      // If the response exists, update it
      const updatedResponses = [...textLocalFieldResponses];
      updatedResponses[existingResponseIndex].response = value;
      setTextLocalFieldResponses(updatedResponses);
    } else {
      // If the response doesn't exist, create a new response
      const newResponse: {
        questionID: number;
        medicationID: number;
        response: string;
      } = {
        questionID: questionID,
        medicationID: medicationID,
        response: value,
      };

      setTextLocalFieldResponses([...textLocalFieldResponses, newResponse]);
    }

    const updatedMedicationList = medicationList.map((medicationItem) => {
      if (medicationItem.id === medicationID) {
        const existingAnswers = medicationItem.answers || [];

        // Check if the questionID already exists in the answers
        const existingAnswerIndex = existingAnswers.findIndex(
          (answer) => answer.questionID === questionID,
        );

        const updatedAnswers =
          existingAnswerIndex !== -1
            ? existingAnswers.map((answer) =>
                answer.questionID === questionID
                  ? { ...answer, response: value } // Update existing answer
                  : answer,
              )
            : [...existingAnswers, { questionID, response: value }]; // Add new answer

        // Create a new object with the updated or new answers and assign it
        medicationItem = { ...medicationItem, answers: updatedAnswers };
      }

      return medicationItem;
    });

    setMedicationList(updatedMedicationList);
  };

  const sortedMedicationList = [...medicationList].sort(
    (a, b) => (b.id ?? 0) - (a.id ?? 0),
  );

  const insertAfterLastMedication = () => {
    const lastMedication = medicationList[medicationList.length - 1];
    if (lastMedication && lastMedication.id !== undefined) {
      insertAfter(lastMedication.id, {
        id: medicationList.length,
        title: `Medication List ${medicationList.length + 1}`,
        completed: false,
        answers: [],
      });
    } else if (lastMedication === undefined) {
      insertAfter(0, {
        id: 0,
        title: 'Medication List 1',
        completed: false,
        answers: [],
      });
    }
  };

  const getSelectedValues = (
    parsedOptions: DropdownItem[],
    selectedOptions: string[],
  ): DropdownItem[] => {
    const filteredOptions = parsedOptions.filter((obj: DropdownItem) => {
      return selectedOptions.includes(obj.value);
    });

    const customValues = selectedOptions.filter((value) => {
      return !parsedOptions.some((obj) => obj.value === value);
    });

    const customOptions = customValues.map((option) => {
      return {
        label: option,
        value: option,
      };
    });

    return [...filteredOptions, ...customOptions];
  };

  const handleInputChange = () => {
    cache.clearAll();
  };

  const getMedicationDataForIndex = useMemo(() => {
    return (index: number) => {
      const medication = medicationList?.find((item) => item.id === index);

      return (
        medication &&
        medication.answers?.length &&
        medication.answers?.length > 1
      );
    };
  }, [medicationList]);

  useEffect(() => {
    if (section.name === 'Medications') {
      setPersistentData((prevState) => {
        let updatedState = [...prevState];
        let foundExistingEntry = false;
        // Check if an entry with the same questionID exists in prevState
        updatedState = updatedState.map((entry) => {
          if (entry.questionID === 99) {
            const updatedEntry = {
              ...entry,
              currentSection: section.name,
              response: medicationList,
            };
            foundExistingEntry = true;
            return updatedEntry;
          }
          return entry;
        });
        // If no existing entry was found, create a new one
        if (!foundExistingEntry) {
          updatedState.push({
            currentSection: section.name,
            questionID: 99,
            response: medicationList,
          });
        }
        return updatedState;
      });
    }
  }, [section, medicationList, textLocalFieldResponses, setPersistentData]);

  const refetchDataExecuted = useRef(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        refetchData && (await refetchData());

        setSelectedValue(setSelectStorageValues());
        setMedicationList(setMedicationStorageValues());
        // Set the flag to true after executing refetchData
        refetchDataExecuted.current = true;
      } catch (error) {
        // Handle error if needed
        console.error('Error fetching data:', error);
        refetchDataExecuted.current = true;
      }
    };

    if (
      selectsFromStorage?.length &&
      medicationsDataFromStorage?.length &&
      !refetchDataExecuted.current
    ) {
      fetchData();
    }
  }, [
    selectsFromStorage,
    medicationsDataFromStorage,
    setSelectStorageValues,
    setMedicationStorageValues,
    refetchData,
  ]);

  if (
    (dynamicQuestionnaireLocaleLoading && !dynamicQuestionnaireLocale) ||
    genericActionLoading ||
    !genericAction
  )
    return null;

  return (
    <>
      <div className="mt-5 desktop:mx-[120px]">
        {section.name === 'Medications' ? (
          <div>
            {medicationList?.length === 0 && (
              <div className="pb-10">
                <p className="font-medium text-dark-gray">
                  {dynamicQuestionnaireLocale.addMedicationsNotice}
                </p>
              </div>
            )}

            {sortedMedicationList.map((medication, index) => {
              return (
                <div key={index}>
                  {questionList.map((question) => {
                    const getCurrentIndexOfMedicationList =
                      medicationList.filter(
                        (obj: QuestionnaireResponseInterface) => {
                          if (obj.id === index) {
                            return obj;
                          } else {
                            return obj;
                          }
                        },
                      );

                    const dropdownOneResponses: DropdownResponses = {};
                    const dropdownTwoResponses: DropdownResponses = {};

                    getCurrentIndexOfMedicationList[index].answers?.forEach(
                      (item) => {
                        let newObject: {
                          label: string | { label: string };
                          value: string | { value: string };
                        } = { label: '', value: '' };

                        if (
                          item.response !== null &&
                          typeof item.response === 'object' &&
                          'id' in item.response &&
                          item.response?.id === 'answer-1' &&
                          item.response?.id === 'answer-1'
                        ) {
                          if (
                            (item.response !== null &&
                              typeof item.response === 'object' &&
                              'value' in item.response &&
                              item.response.value) ||
                            (typeof item.response === 'object' &&
                              'value' in item.response &&
                              typeof item.response.value === 'string')
                          ) {
                            newObject = {
                              label: item.response.value,
                              value: item.response.value,
                            };
                          }

                          dropdownOneResponses[Number(item.questionID)] = {
                            label:
                              typeof newObject.label === 'object'
                                ? newObject.label.label
                                : newObject.label,
                            value:
                              typeof newObject.value === 'object'
                                ? newObject.value.value
                                : newObject.value,
                          };
                        } else if (
                          item.response !== null &&
                          typeof item.response === 'object' &&
                          'id' in item.response &&
                          item.response?.id === 'answer-2' &&
                          item.response?.id === 'answer-2' &&
                          item.response.id === 'answer-2'
                        ) {
                          if (
                            (item.response !== null &&
                              typeof item.response === 'object' &&
                              'value' in item.response &&
                              item.response.value) ||
                            (typeof item.response === 'object' &&
                              'value' in item.response &&
                              typeof item.response.value === 'string')
                          ) {
                            newObject = {
                              label: item.response.value,
                              value: item.response.value,
                            };
                          }
                          dropdownTwoResponses[Number(item.questionID)] = {
                            label:
                              typeof newObject.label === 'object'
                                ? newObject.label.label
                                : newObject.label,
                            value:
                              typeof newObject.value === 'object'
                                ? newObject.value.value
                                : newObject.value,
                          };
                        }
                      },
                    );

                    let parsedOptions: DropdownItem[] = [];

                    if (
                      question.questionnaire_questions_question.ui_control ===
                      UI_CONTROL.LOOKUP_FIELD
                    ) {
                      parsedOptions = parseQuestionOptionsForDropdownWithLookup(
                        question.questionnaire_questions_question.answers
                          ? question.questionnaire_questions_question.answers
                          : [''],
                      );
                    }

                    const enablePlaceholder = getMedicationDataForIndex(index);

                    return (
                      <div key={question.questionnaire_questions_question.id}>
                        {!question.questionnaire_questions_question
                          .dropdown_label_1 &&
                          !question.questionnaire_questions_question
                            .dropdown_label_2 && (
                            <div className="mb-2.5">
                              <p className="font-semibold text-dark-gray">
                                {question.questionnaire_questions_question.text}
                                {isRequiredQuestion(
                                  question.questionnaire_questions_question.id.toString(),
                                ) && (
                                  <span className="text-base font-bold text-clc-red">
                                    {' '}
                                    *
                                  </span>
                                )}
                              </p>
                              {question.questionnaire_questions_question
                                .subtitle && (
                                <p className="italic text-med-gray">
                                  {
                                    question.questionnaire_questions_question
                                      .subtitle
                                  }
                                </p>
                              )}
                            </div>
                          )}
                        <div className="mb-11">
                          {question.questionnaire_questions_question
                            .ui_control &&
                          question.questionnaire_questions_question
                            .ui_control === UI_CONTROL.LOOKUP_FIELD ? (
                            <div className="flex flex-col w-full">
                              <CreatableSelect
                                components={{ MenuList }}
                                className="dropdown"
                                classNamePrefix="react-select"
                                placeholder={
                                  dynamicQuestionnaireLocale?.placeholderLookahead
                                }
                                value={
                                  enablePlaceholder
                                    ? getMedicationListValue(
                                        index,
                                        question
                                          .questionnaire_questions_question.id,
                                      )
                                    : undefined
                                }
                                options={parsedOptions}
                                onInputChange={handleInputChange}
                                onChange={(e) => {
                                  handleDropdownOptionSelected(
                                    e,
                                    question.questionnaire_questions_question
                                      .id,
                                    medication.id +
                                      '-' +
                                      question.questionnaire_questions_question
                                        .id,
                                    index,
                                  );
                                }}
                              />
                            </div>
                          ) : question.questionnaire_questions_question
                              .ui_control &&
                            question.questionnaire_questions_question
                              .ui_control ===
                              UI_CONTROL.DOUBLE_DROPDOWN_COMBO ? (
                            <div className="flex flex-col desktop:flex-row w-full">
                              <div className="desktop:max-w-[130px] desktop:mr-2.5 mb-2 desktop:mb-0">
                                <div className="flex flex-col gap-2.5">
                                  {question.questionnaire_questions_question
                                    .dropdown_label_1 && (
                                    <p className="font-semibold text-dark-gray">
                                      {
                                        question
                                          .questionnaire_questions_question
                                          .dropdown_label_1
                                      }
                                      {isRequiredQuestion(
                                        question.questionnaire_questions_question.id.toString(),
                                      ) && (
                                        <span className="text-base font-bold text-clc-red">
                                          {' '}
                                          *
                                        </span>
                                      )}
                                    </p>
                                  )}
                                  <CreatableSelect
                                    className="dropdown"
                                    classNamePrefix="react-select"
                                    value={
                                      dropdownOneResponses[
                                        question
                                          .questionnaire_questions_question.id
                                      ]
                                    }
                                    options={parseQuestionOptionsForDropdownWithLookup(
                                      question.questionnaire_questions_question
                                        .dropdown_combo_answers
                                        ?.dropdown_1_answers
                                        ? question
                                            .questionnaire_questions_question
                                            .dropdown_combo_answers
                                            .dropdown_1_answers
                                        : [],
                                    )}
                                    onChange={(e) => {
                                      handleDropdownOptionSelected(
                                        e,
                                        question
                                          .questionnaire_questions_question.id,
                                        medication.id +
                                          '-double-1-' +
                                          question
                                            .questionnaire_questions_question
                                            .id,
                                        index,
                                      );
                                    }}
                                  />
                                </div>
                              </div>

                              {/* match id for the question that needs to have id in the middle */}
                              {question.questionnaire_questions_question.id ===
                              81 ? (
                                <div className="flex items-center text-med-gray mb-2">
                                  <p>per</p>
                                </div>
                              ) : (
                                ''
                              )}

                              <div className="desktop:max-w-[130px] desktop:ml-2.5">
                                <div className="flex flex-col gap-2.5">
                                  {question.questionnaire_questions_question
                                    .dropdown_label_2 && (
                                    <p className="font-semibold text-dark-gray">
                                      {
                                        question
                                          .questionnaire_questions_question
                                          .dropdown_label_2
                                      }
                                      {isRequiredQuestion(
                                        question.questionnaire_questions_question.id.toString(),
                                      ) && (
                                        <span className="text-base font-bold text-clc-red">
                                          {' '}
                                          *
                                        </span>
                                      )}
                                    </p>
                                  )}
                                  <CreatableSelect
                                    className="dropdown"
                                    classNamePrefix="react-select"
                                    value={
                                      dropdownTwoResponses[
                                        question
                                          .questionnaire_questions_question.id
                                      ]
                                    }
                                    options={parseQuestionOptionsForDropdownWithLookup(
                                      question.questionnaire_questions_question
                                        .dropdown_combo_answers
                                        ? question
                                            .questionnaire_questions_question
                                            .dropdown_combo_answers
                                            .dropdown_2_answers
                                        : [],
                                    )}
                                    onChange={(e) =>
                                      handleDropdownOptionSelected(
                                        e,
                                        question
                                          .questionnaire_questions_question.id,
                                        medication.id +
                                          '-double-2-' +
                                          question
                                            .questionnaire_questions_question
                                            .id,
                                        index,
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          ) : question.questionnaire_questions_question
                              .ui_control &&
                            question.questionnaire_questions_question
                              .ui_control === UI_CONTROL.DROPDOWN_50_PERCENT ? (
                            <div className="desktop:max-w-[390px]">
                              <CreatableSelect
                                className="dropdown"
                                classNamePrefix="react-select"
                                placeholder={
                                  genericAction?.[
                                    genericActionsIds.BEGINTYPINGTOLOOKUP
                                  ].beginTypingToLookUp
                                }
                                value={getMedicationListValue(
                                  index,
                                  question.questionnaire_questions_question.id,
                                )}
                                options={parseQuestionOptionsForDropdownWithLookup(
                                  question.questionnaire_questions_question
                                    .answers
                                    ? question.questionnaire_questions_question
                                        .answers
                                    : [],
                                )}
                                onChange={(e) => {
                                  handleDropdownOptionSelected(
                                    e,
                                    question.questionnaire_questions_question
                                      .id,
                                    medication.id +
                                      '-50_percent-' +
                                      question.questionnaire_questions_question
                                        .id,
                                    index,
                                  );
                                }}
                              />
                            </div>
                          ) : question.questionnaire_questions_question
                              .ui_control &&
                            question.questionnaire_questions_question
                              .ui_control === UI_CONTROL.DROPDOWN_35_PERCENT ? (
                            <div className="desktop:max-w-[240px]">
                              <CreatableSelect
                                className="dropdown"
                                classNamePrefix="react-select"
                                placeholder={
                                  genericAction?.[
                                    genericActionsIds.BEGINTYPINGTOLOOKUP
                                  ].beginTypingToLookUp
                                }
                                value={getMedicationListValue(
                                  index,
                                  question.questionnaire_questions_question.id,
                                )}
                                options={parseQuestionOptionsForDropdownWithLookup(
                                  question.questionnaire_questions_question
                                    .answers
                                    ? question.questionnaire_questions_question
                                        .answers
                                    : [],
                                )}
                                onChange={(e) => {
                                  handleDropdownOptionSelected(
                                    e,
                                    question.questionnaire_questions_question
                                      .id,
                                    medication.id +
                                      '-35_percent-' +
                                      question.questionnaire_questions_question
                                        .id,
                                    index,
                                  );
                                }}
                              />
                            </div>
                          ) : question.questionnaire_questions_question
                              .ui_control &&
                            question.questionnaire_questions_question
                              .ui_control === UI_CONTROL.TEXT_AREA ? (
                            <div className="">
                              <InputComponent
                                value={
                                  getMedicationListValue(
                                    index,
                                    question.questionnaire_questions_question
                                      .id,
                                    'text-area',
                                  ) as string
                                }
                                key={
                                  question.questionnaire_questions_question.id
                                }
                                type={'text-area'}
                                textAreaProps={{
                                  onTextAreaChange: (e) =>
                                    handleLocalTextFieldResponses(
                                      e.target.value,
                                      question.questionnaire_questions_question
                                        .id,
                                      index,
                                    ),
                                }}
                              />
                            </div>
                          ) : (
                            question.questionnaire_questions_question
                              .ui_control &&
                            question.questionnaire_questions_question
                              .ui_control === UI_CONTROL.RADIO_BUTTON && (
                              <div className="">
                                <div className="desktop:max-w-[390px]">
                                  <CreatableSelect
                                    className="dropdown"
                                    classNamePrefix="react-select"
                                    placeholder={
                                      genericAction?.[
                                        genericActionsIds.BEGINTYPINGTOLOOKUP
                                      ].beginTypingToLookUp
                                    }
                                    value={getMedicationListValue(
                                      index,
                                      question.questionnaire_questions_question
                                        .id,
                                    )}
                                    options={parseQuestionOptionsForDropdownWithLookup(
                                      question.questionnaire_questions_question
                                        .answers
                                        ? question
                                            .questionnaire_questions_question
                                            .answers
                                        : [],
                                    )}
                                    onChange={(e) => {
                                      handleDropdownOptionSelected(
                                        e,
                                        question
                                          .questionnaire_questions_question.id,
                                        medication.id +
                                          '-dropdown_radio-' +
                                          question
                                            .questionnaire_questions_question
                                            .id,
                                        index,
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    );
                  })}
                  <div className="flex pb-10">
                    {
                      <button
                        className="flex mr-6"
                        onClick={() => removeMedication(index)}
                      >
                        <MinusIcon className="text-clc-blue mr-2" />
                        <span className="text-clc-blue font-bold underline uppercase">
                          {dynamicQuestionnaireLocale.removeMedication}
                        </span>
                      </button>
                    }
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div>
            {questionList.map((question) => {
              let parsedOptions: DropdownItem[] = [];

              if (
                question.questionnaire_questions_question.ui_control ===
                UI_CONTROL.LOOKUP_WITH_MULTISELECT
              ) {
                parsedOptions = parseQuestionOptionsForDropdownWithLookup(
                  question.questionnaire_questions_question.answers
                    ? question.questionnaire_questions_question.answers
                    : [''],
                );
              }

              return (
                <div key={question.questionnaire_questions_question.id}>
                  <div className="mb-2.5">
                    <p className="font-semibold text-dark-gray">
                      {question.questionnaire_questions_question.text}
                      {isRequiredQuestion(
                        question.questionnaire_questions_question.id.toString(),
                      ) && (
                        <span className="text-base font-bold text-clc-red">
                          {' '}
                          *
                        </span>
                      )}
                    </p>

                    {question.questionnaire_questions_question.subtitle && (
                      <p className="italic text-med-gray">
                        {question.questionnaire_questions_question.subtitle}
                      </p>
                    )}
                  </div>
                  <div className="mb-11">
                    {question.questionnaire_questions_question.ui_control &&
                    question.questionnaire_questions_question.ui_control ===
                      UI_CONTROL.SMALL_TEXT ? (
                      <div className="flex flex-col">
                        <div>
                          <div className="w-full desktop:w-[130px]">
                            <InputComponent
                              key={question.questionnaire_questions_question.id}
                              value={getResponseByQuestionID(
                                question.questionnaire_questions_question.id,
                              )}
                              type={'text'}
                              rightText={getUnitOfMeasure()}
                              onChange={(e) => {
                                textFieldResponses &&
                                  handleTextFieldResponses(
                                    e.target.value,
                                    question.questionnaire_questions_question
                                      .id,
                                    question,
                                  );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    ) : question.questionnaire_questions_question.ui_control &&
                      question.questionnaire_questions_question.ui_control ===
                        UI_CONTROL.LOOKUP_WITH_MULTISELECT &&
                      question.questionnaire_questions_question.id === 71 ? (
                      <div className="flex flex-col w-full">
                        <CreatableSelect
                          className="dropdown"
                          classNamePrefix="react-select"
                          placeholder={
                            dynamicQuestionnaireLocale?.placeholderLookahead
                          }
                          value={getSelectedValues(
                            parsedOptions,
                            selectedValue[0].value,
                          )} // set selected values
                          options={parsedOptions} // set list of the data
                          onChange={(event) => {
                            return handleChange(
                              event,
                              0,
                              question.questionnaire_questions_question.id,
                            );
                          }} // assign onChange function
                          isMulti={true}
                          noOptionsMessage={() =>
                            dynamicQuestionnaireLocale?.noOptionsPlaceholder
                          }
                          isOptionSelected={(
                            option: DropdownItem,
                            selectValue: Options<DropdownItem>,
                          ) => {
                            let status = false;
                            selectValue.forEach((data) => {
                              if (data.value.toLowerCase() !== 'none') {
                                status = false;
                              } else {
                                status = true;
                              }
                            });
                            return status;
                          }}
                        />
                      </div>
                    ) : question.questionnaire_questions_question.ui_control &&
                      question.questionnaire_questions_question.ui_control ===
                        UI_CONTROL.LOOKUP_WITH_MULTISELECT &&
                      question.questionnaire_questions_question.id === 72 ? (
                      <div className="flex flex-col w-full">
                        <CreatableSelect
                          className="dropdown"
                          classNamePrefix="react-select"
                          placeholder={
                            dynamicQuestionnaireLocale?.placeholderLookahead
                          }
                          value={getSelectedValues(
                            parsedOptions,
                            selectedValue[1].value,
                          )} // set selected values
                          options={parsedOptions} // set list of the data
                          onChange={(event) =>
                            handleChange(
                              event,
                              1,
                              question.questionnaire_questions_question.id,
                            )
                          } // assign onChange function
                          isMulti={true}
                          noOptionsMessage={() =>
                            dynamicQuestionnaireLocale?.noOptionsPlaceholder
                          }
                          isOptionSelected={(
                            option: DropdownItem,
                            selectValue: Options<DropdownItem>,
                          ) => {
                            let status = false;
                            selectValue.forEach((data) => {
                              if (data.value.toLowerCase() !== 'none') {
                                status = false;
                              } else {
                                status = true;
                              }
                            });
                            return status;
                          }}
                        />
                      </div>
                    ) : question.questionnaire_questions_question.ui_control &&
                      question.questionnaire_questions_question.ui_control ===
                        UI_CONTROL.DOUBLE_TEXT_COMBO_L_HELPER_TEXT ? (
                      <div className="flex flex-col desktop:flex-row w-full">
                        <div className="w-full desktop:w-[170px]">
                          <InputComponent
                            key={question.questionnaire_questions_question.id}
                            value={getResponseByInputId('input-1')}
                            type={'text'}
                            leftText={
                              question.questionnaire_questions_question
                                .double_text_combo_helper_texts?.helper_text_1
                            }
                            leftTextBold={true}
                            onChange={(e) => {
                              textFieldResponses &&
                                handleDoubleComboTextFieldResponses(
                                  e.target.value,
                                  question.questionnaire_questions_question.id,
                                  question,
                                  'input-1',
                                );
                            }}
                          />
                        </div>
                        <div className="w-full desktop:w-[175px]">
                          <InputComponent
                            key={question.questionnaire_questions_question.id}
                            value={getResponseByInputId('input-2')}
                            type={'text'}
                            leftText={
                              question.questionnaire_questions_question
                                .double_text_combo_helper_texts?.helper_text_2
                            }
                            leftTextBold={true}
                            onChange={(e) => {
                              textFieldResponses &&
                                handleDoubleComboTextFieldResponses(
                                  e.target.value,
                                  question.questionnaire_questions_question.id,
                                  question,
                                  'input-2',
                                );
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      question.questionnaire_questions_question.answers &&
                      question.questionnaire_questions_question.answers.map(
                        (answer) => {
                          return (
                            <div key={answer} className="flex flex-col">
                              <InputComponent
                                key={answer}
                                type={getUiControlType(
                                  question.questionnaire_questions_question
                                    .ui_control,
                                )}
                                radioInputProps={{
                                  radioError: null,
                                  radioInputValue: answer,
                                  radioInputLabel: answer,
                                  radioInputCheckedValue: matchSelectedRadio(
                                    question.questionnaire_questions_question
                                      .id,
                                    answer,
                                    radioCheckedState,
                                  )
                                    ? answer
                                    : undefined,
                                  onRadioClick: () =>
                                    handleRadioClick(
                                      question.questionnaire_questions_question
                                        .id,
                                      answer,
                                    ),
                                }}
                                checkboxProps={{
                                  checkboxLabel: answer,
                                  checked:
                                    checkboxesCheckedState?.find(
                                      (
                                        checkbox: QuestionnaireResponseInterface,
                                      ) =>
                                        checkbox.response === answer &&
                                        checkbox.section === section.name &&
                                        checkbox.questionID ===
                                          question
                                            .questionnaire_questions_question
                                            .id,
                                    )?.checked || false,
                                  onCheckboxChange: () => {
                                    handleCheckboxToggle(
                                      answer,
                                      question.questionnaire_questions_question
                                        .id,
                                    );
                                  },
                                  bottomMargin: true,
                                  onCheckboxChildrenInputChange: (e) => {
                                    onChange(
                                      answer,
                                      question.questionnaire_questions_question
                                        .id,
                                      e.target.value,
                                      undefined,
                                      question.questionnaire_questions_question
                                        .ui_control,
                                    );
                                  },
                                }}
                              />
                            </div>
                          );
                        },
                      )
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {section.name === 'Medications' && (
          <div>
            <hr />
            <button
              className="flex mr-3 my-5"
              onClick={insertAfterLastMedication}
            >
              <PlusIcon className="mr-2" />
              <span className="text-clc-blue font-bold underline uppercase">
                {medicationList.length > 0
                  ? dynamicQuestionnaireLocale.addAnotherMedication
                  : dynamicQuestionnaireLocale.addMedication}
              </span>
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default HealthQuestionnaireModule;
