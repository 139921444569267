import { pageIds } from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';
import { capitalizeFirstLetter } from 'utilities/functions';
import {
  Skintelligent_Tests_Order_By,
  Order_By,
  Image_Source_Types_Enum,
} from 'graphql/generated/hasura';
import DatePicker from 'components/datePicker/DatePicker';
import InputComponent from 'components/inputComponent';
import { useCallback, useMemo, useState } from 'react';

interface TableFilterProps {
  fromDate: string;
  toDate: string;
  orderBy: Skintelligent_Tests_Order_By | string | undefined;
  handleFromDateChange: (e: { target: HTMLInputElement }) => void | undefined;
  handleToDateChange: (e: { target: HTMLInputElement }) => void | undefined;
  handleSortChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  showSourceFilter?: boolean;
  onSourceChange?: (source: Image_Source_Types_Enum | null) => void;
  source?: Image_Source_Types_Enum | null;
  localeByProp?: Record<string, string>;
}

const TableFilter: React.FC<TableFilterProps> = ({
  fromDate,
  toDate,
  orderBy,
  handleFromDateChange,
  handleToDateChange,
  handleSortChange,
  showSourceFilter = false,
  onSourceChange = () => {
    return;
  },
  source = undefined,
  localeByProp = undefined,
}) => {
  const { data: localeByPage, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.TABLE_FILTER,
  });

  const locale = useMemo(
    () => ({ ...(localeByPage || {}), ...(localeByProp || {}) }),
    [localeByPage, localeByProp],
  );
  const [isDatepickerOpenFrom, setIsDatepickerOpenFrom] = useState(false);
  const [isDatepickerOpenTo, setIsDatepickerOpenTo] = useState(false);

  const handleOpenFromDatePicker = () => {
    setIsDatepickerOpenFrom(true);
    setIsDatepickerOpenTo(false);
  };

  const handleOpenToDatePicker = () => {
    setIsDatepickerOpenTo(true);
    setIsDatepickerOpenFrom(false);
  };

  const handleCloseDatepicker = () => {
    setIsDatepickerOpenFrom(false);
    setIsDatepickerOpenTo(false);
  };

  const sortOptions = ['Select Option', 'Most Recent', 'Oldest'];

  let selectValue: string;
  if (typeof orderBy === 'string' || typeof orderBy === 'undefined') {
    selectValue = orderBy || sortOptions[0];
  } else {
    selectValue = orderBy.skintelligent_tests_test
      ? orderBy.skintelligent_tests_test.created_at === Order_By.Desc
        ? sortOptions[1]
        : sortOptions[2]
      : sortOptions[0];
  }

  const onSourceSelectChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const value = e.target.value;
      switch (value) {
        case locale[Image_Source_Types_Enum.Mobile]:
          onSourceChange(Image_Source_Types_Enum.Mobile);
          break;
        case locale[Image_Source_Types_Enum.Pc]:
          onSourceChange(Image_Source_Types_Enum.Pc);
          break;
        default:
          onSourceChange(null);
          break;
      }
    },
    [onSourceChange, locale],
  );

  if (loading && !locale) return null;

  return (
    <div className="flex flex-col w-full bg-base-content rounded px-5 py-6 mt-5 desktop:w-max desktop:flex-row desktop:items-center desktop:px-[30px] desktop:py-2.5 desktop:gap-10">
      <p className="text-base text-dark-gray font-semibold mb-4 desktop:mb-0">
        {locale.filterDates}
      </p>
      <div className="flex flex-row justify-between mb-4 desktop:mb-0 items-center desktop:gap-2">
        <label className="text-base text-dark-gray font-semibold desktop:mb-0">
          {locale.from}
        </label>
        <div className="w-4/5 desktop:w-auto">
          <DatePicker
            onChange={handleFromDateChange}
            value={fromDate}
            isOpen={isDatepickerOpenFrom}
            onOpen={() => {
              handleOpenFromDatePicker();
            }}
            disableFutureDates
          />
        </div>
      </div>
      <div className="flex flex-row justify-between mb-4 desktop:mb-0 items-center desktop:gap-2">
        <label className="text-base text-dark-gray font-semibold  desktop:mb-0">
          {locale.to}
        </label>
        <div className="w-4/5 desktop:w-auto">
          <DatePicker
            onChange={handleToDateChange}
            value={toDate}
            isOpen={isDatepickerOpenTo}
            onOpen={() => {
              handleOpenToDatePicker();
            }}
            disableFutureDates
          />
        </div>
      </div>
      {showSourceFilter && (
        <div className="flex flex-row justify-between items-center desktop:gap-2">
          <label className="text-base text-dark-gray font-semibold  desktop:mb-0">
            {locale.source}
          </label>
          <div className="flex w-4/5 desktop:w-24">
            <InputComponent
              type="select"
              name="testType"
              selectInputProps={{
                onSelectChange: onSourceSelectChange,
                selectValues: [
                  locale.all,
                  locale[Image_Source_Types_Enum.Mobile],
                  locale[Image_Source_Types_Enum.Pc],
                ],
                fullWidth: true,
              }}
              value={source ? locale[source] : locale.all}
            />
          </div>
        </div>
      )}
      <hr className="my-4 w-full desktop:hidden" />
      <div className="flex flex-col desktop:hidden">
        <label className="text-base text-dark-gray font-semibold mb-[10px]">
          {locale.sort}
        </label>
        <select
          className="border rounded p-2"
          onChange={(value) => {
            handleSortChange(value);
            handleCloseDatepicker();
          }}
          value={selectValue}
        >
          {sortOptions.map((option, index) => (
            <option key={option} value={option} disabled={index === 0}>
              {capitalizeFirstLetter(option)}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default TableFilter;
