import { Dispatch, SetStateAction } from 'react';
import { TabType } from '../../DatePicker.types';
import { DatePickerButton } from '../datePickerButton';

interface yearTabProps {
  title: string;
  yearsLabels: number[];
  selectedYear: number;
  setSelectedYear: Dispatch<SetStateAction<number>>;
  setSelectionTab: Dispatch<SetStateAction<TabType>>;
  onNavClickPrev: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onNavClickNext: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const YearTab: React.FC<yearTabProps> = ({
  title,
  yearsLabels,
  selectedYear,
  setSelectedYear,
  setSelectionTab,
  onNavClickPrev,
  onNavClickNext,
}) => {
  const onYearChange = (value: number) => {
    setSelectionTab('day');
    setSelectedYear(value);
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-1.5">
        <DatePickerButton
          buttonType="arrow-button"
          arrowDirection="left"
          onClick={onNavClickPrev}
        />

        <span className="font-bold">{title}</span>
        <DatePickerButton
          buttonType="arrow-button"
          arrowDirection="right"
          onClick={onNavClickNext}
        />
      </div>
      <hr />
      <div className="flex flex-row flex-wrap mt-1.5 justify-center">
        {yearsLabels.map((year, index) => (
          <div
            className={`flex justify-center items-center px-5 w-8 h-8  ml-[5px] mr-[5px] text-center text-xs font-medium hover:bg-light-blue rounded-[5px] ${
              selectedYear === year ? ' selected' : ''
            }`}
            key={year + index}
            onClick={() => {
              onYearChange(year);
            }}
          >
            {<p className="text-med-gray-2">{year}</p>}
          </div>
        ))}
      </div>
    </div>
  );
};
