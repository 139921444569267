import { ReactComponent as ChevronDownIcon } from 'assets/icons/chevron-down.svg';
import { ReactComponent as ChevronUpIcon } from 'assets/icons/chevron-up.svg';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import { useGetPage } from 'hooks/useGetPage';
import { ChangeEvent, useContext, useEffect, useMemo, useState } from 'react';
import {
  ALLOWED_CHARACTERS,
  DEFAULT_COUNTRY_CODE_NUMBER,
  ISO_CODE_TO_EXCLUDE,
  STATES_TO_EXCLUDE,
  genericActionsIds,
  pageIds,
} from 'utilities/constants';
import {
  useGetCountriesQuery,
  useUpdateProfileNotificationMutation,
} from 'graphql/generated/hasura';

import { State, City, Country } from 'country-state-city';
import ButtonComponent from 'components/button/buttonComponent';
import InputComponent, {
  SelectWithFlagsValues,
} from 'components/inputComponent';
import { AuthContext } from 'auth/context/AuthContext';
import {
  AddressInformation,
  ContactData,
  ContactInfoProps,
  GeneralStates,
} from 'app/my-account/interfaces/profile.interfaces';
import {
  CountriesSelectValue,
  allowOnlyNumbers,
  normalizePhoneNumber,
  onSelectKeyDownAllowOnlyLettersAndSpaces,
  parseMappedCountriesIntoSelectValues,
  parseSelectedValue,
  scrollToTop,
} from 'utilities/functions';
import {
  addressInfoRequiredFields,
  contactInfoRequiredField,
} from './constants';
import CreatableSelect from 'react-select/creatable';
import { GroupBase, SingleValue, components } from 'react-select';
import Select from 'react-select';
import { LOCAL_STORAGE_WEATHER_ZIP } from 'hooks/useWeather/constants';
import { usePatchFhirPatientPersonalDataMutation } from 'graphql/generated/remote-schema-hasura';

interface FullContactData extends ContactData {
  extension: string;
}

export const ContactInfo: React.FC<ContactInfoProps> = ({
  phone,
  country,
  addressInformation,
  handleUpdatedContactInfo,
  handleContactInfoChange,
}) => {
  const [isSubmiting, setIsSubmiting] = useState(false);
  const { user: loggedUser } = useContext(AuthContext);
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.MY_PROFILE,
  });
  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [
        genericActionsIds.UPDATE,
        genericActionsIds.BEGINTYPINGTOLOOKUP,
      ],
    });
  const [formValues, setFormValues] = useState<FullContactData>({
    phone: phone.split('ext')[0] || '',
    extension: phone.split('ext')[1] || '',
    country: country || DEFAULT_COUNTRY_CODE_NUMBER,
    addressInformation,
  });

  const parseIso = (value: string | { label: string; value: string }) => {
    let stringToReturn = '';
    if (typeof value === 'string') {
      stringToReturn = value.split(' - ')[0];
    } else {
      if (value && value.value) {
        stringToReturn = value.value.split(' - ')[0];
      }
    }
    return stringToReturn;
  };

  const [countryIso, setCountryIso] = useState<string>(
    parseIso(addressInformation.country) ?? '',
  );

  const [stateData, setStateData] = useState<string[]>([
    addressInformation.state || '',
  ]);

  const [stateIso, setStateIso] = useState<string>(
    parseIso(addressInformation.state) || '',
  );

  const [cityData, setCityData] = useState<string[]>([
    addressInformation.city || '',
  ]);
  const [isCountryClicked, setIsCountryClicked] = useState<boolean>(false);
  const [isStateClicked, setIsStateClicked] = useState<boolean>(false);
  const [patchFhirPatientPersonalData] =
    usePatchFhirPatientPersonalDataMutation({});
  const [updateProfileNotification] = useUpdateProfileNotificationMutation({});

  const { data: countryNumberData } = useGetCountriesQuery({});

  const mappedCountriesValues = useMemo(
    () =>
      countryNumberData?.countries
        ? [...countryNumberData.countries]
            .sort((a, b) => {
              const codea = parseInt(a.code);
              const codeb = parseInt(b.code);

              if (codea < codeb) {
                return -1;
              } else if (codea > codeb) {
                return 1;
              } else {
                return 0;
              }
            })
            .map((country) => {
              return { code: country.code, flag: country.flag };
            })
        : [],
    [countryNumberData?.countries],
  );

  const getMemoizedCurrentCountryValue = useMemo(
    () => parseSelectedValue(formValues.country, mappedCountriesValues),
    [formValues.country, mappedCountriesValues],
  );

  const getMemoizedDefaultParsedValue = useMemo(
    () => parseMappedCountriesIntoSelectValues(mappedCountriesValues)[0],
    [mappedCountriesValues],
  );

  const getMemoizedParsedCountries = useMemo(
    () => parseMappedCountriesIntoSelectValues(mappedCountriesValues),
    [mappedCountriesValues],
  );

  useEffect(() => {
    if (countryIso) {
      const states = State.getStatesOfCountry(countryIso)
        .filter((state) => !state.isoCode.includes(ISO_CODE_TO_EXCLUDE))
        .filter((state) => !STATES_TO_EXCLUDE.includes(state.name))
        .map((state) => `${state.isoCode} - ${state.name}`);

      setStateData(states);
      if (isCountryClicked) {
        const stateCode = states?.[0]?.split(' - ')[0] || '';
        setStateIso(stateCode);
        setFormValues((prevFormValues) => ({
          ...prevFormValues,
          addressInformation: {
            ...prevFormValues.addressInformation,
            state: states?.[0] || '',
            city: '',
          },
        }));
        setIsCountryClicked(false);
        setIsStateClicked(false);
      }
    } else {
      setStateData([]);
      setCityData([]);
    }
  }, [countryIso, isCountryClicked]);

  useEffect(() => {
    if (countryIso && stateIso) {
      const cities = City.getCitiesOfState(countryIso, stateIso).map(
        (city) => `${city.name}`,
      );
      setCityData(cities);
      if (isStateClicked) {
        setFormValues((prevFormValues) => ({
          ...prevFormValues,
          addressInformation: {
            ...prevFormValues.addressInformation,
            city: cities?.[0] || '',
          },
        }));
        setIsStateClicked(false);
      }
    } else {
      setCityData([]);
    }
  }, [stateIso, countryIso, isStateClicked]);

  const countryData = Country.getAllCountries().map(
    (country) => `${country.isoCode} - ${country.name}`,
  );
  const usaCountry = countryData?.find(
    (country) => country === 'US - United States',
  );

  const [isFormDisplayed, setIsFormDisplayed] = useState<boolean>(true);

  const handleShowForm = () => {
    setIsFormDisplayed(!isFormDisplayed);
  };

  const handleOnInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;

    setFormValues({
      ...formValues,
      addressInformation: {
        ...formValues.addressInformation,
        [name]: value,
      },
    });
  };

  const handleZipCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;

    if (allowOnlyNumbers(value)) {
      setFormValues({
        ...formValues,
        addressInformation: {
          ...formValues.addressInformation,
          [name]: value,
        },
      });
    }
  };

  const hadleOnPhoneInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setFormValues({
      ...formValues,
      [name]: normalizePhoneNumber(value),
    });
  };

  const hadleOnExtensionChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;

    if (allowOnlyNumbers(value)) {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };

  const handleCountryChange = (e: SelectWithFlagsValues) => {
    setFormValues({ ...formValues, country: e.value });
  };

  const handleOnSelectChange = (
    value: SingleValue<string | { label: string; value: string }>,
    name: string,
  ) => {
    if (name === 'country') {
      let countryCode = '';
      let countryValue = '';

      if (typeof value !== 'string') {
        countryValue = value?.value ?? '';
      }

      if (typeof value === 'string') {
        countryCode = value.split(' - ')[0];
      } else {
        countryCode = value?.value.split(' - ')[0] ?? '';
      }

      setCountryIso(countryCode);
      const statesIso = State.getStatesOfCountry(countryCode).map(
        (state) => `${state.isoCode}`,
      );
      setStateIso(statesIso[0]);
      setFormValues({
        ...formValues,
        addressInformation: {
          ...formValues.addressInformation,
          country: countryValue,
        },
      });
      setIsCountryClicked(true);
      setIsStateClicked(true);
    }
    if (name === 'state') {
      let stateCode = '';
      let stateValue = '';
      if (typeof value !== 'string') {
        stateValue = value?.value ?? '';
      }

      if (typeof value === 'string') {
        stateCode = value.split(' - ')[0];
      } else {
        stateCode = value?.value.split(' - ')[0] ?? '';
      }
      setStateIso(stateCode);
      setFormValues({
        ...formValues,
        addressInformation: {
          ...formValues.addressInformation,
          state: stateValue,
        },
      });
      setIsStateClicked(true);
    }
    if (name === 'city') {
      const cityValue = typeof value === 'string' ? value : value?.value;
      setFormValues({
        ...formValues,
        addressInformation: {
          ...formValues.addressInformation,
          city: cityValue as string,
        },
      });
    }
  };
  useEffect(() => {
    handleContactInfoChange({
      ...formValues,
      phone:
        formValues.phone +
        (formValues.extension ? `ext${formValues.extension}` : ''),
    });
  }, [formValues, handleContactInfoChange]);

  if (loading || !locale || genericActionLoading || !genericAction) return null;

  const textFields = [
    {
      label: locale?.contactInfo.address1Label,
      type: 'text',
      name: 'addressLine1',
      value:
        formValues.addressInformation.addressLine1.replace(
          ALLOWED_CHARACTERS,
          '',
        ) || '',
      onChange: handleOnInputChange,
      maxLengthValue: 100,
      required: true,
    },
    {
      label: locale?.contactInfo.address2Label,
      type: 'text',
      name: 'addressLine2',
      value:
        formValues.addressInformation.addressLine2.replace(
          ALLOWED_CHARACTERS,
          '',
        ) || '',
      onChange: handleOnInputChange,
      maxLengthValue: 100,
      required: false,
    },
    {
      label: locale?.contactInfo.countryLabel,
      type: 'select',
      name: 'country',
      selectInputProps: {
        fullWidth: true,
        selectValues: ['US - United States'],
      },
      value: formValues.addressInformation.country || '',
      required: true,
    },
    {
      label: locale?.contactInfo.stateLabel,
      type: 'select',
      name: 'state',
      selectInputProps: {
        fullWidth: true,
        selectValues: stateData,
      },
      value:
        stateData.find((state) =>
          state.includes(formValues.addressInformation.state),
        ) || '',
      required: true,
    },
    {
      label: locale?.contactInfo.cityLabel,
      type: 'select',
      name: 'city',
      selectInputProps: {
        fullWidth: true,
        selectValues: cityData,
      },
      value: formValues.addressInformation.city || '',
    },
  ];

  if (!formValues.addressInformation.country) {
    handleOnSelectChange(
      { label: usaCountry, value: usaCountry } as SingleValue<
        string | { label: string; value: string }
      >,
      'country',
    );
  }

  const validateRequiredFields = () => {
    for (const key in formValues) {
      if (key === 'addressInformation') {
        for (const requiredField of addressInfoRequiredFields) {
          if (!formValues[key][requiredField as keyof AddressInformation])
            return false;
        }
      }
      if (
        contactInfoRequiredField.find((requiredField) => requiredField === key)
      ) {
        if (!formValues[key as keyof FullContactData]) {
          return false;
        }
      }
    }
    return true;
  };

  const handleOnSubmit = async () => {
    const isFormValid = validateRequiredFields();
    if (!isFormValid)
      return handleUpdatedContactInfo(GeneralStates.MISSING_INFO);

    try {
      setIsSubmiting(true);
      const phoneNumberWithExtension =
        formValues.phone +
        (formValues.extension ? `ext${formValues.extension}` : '');

      const formattedAddressInformation = {
        ...formValues?.addressInformation,
        state: formValues?.addressInformation?.state?.split(' - ')[0],
      };

      const response = await patchFhirPatientPersonalData({
        variables: {
          patientPersonalInfo: {
            codexID: loggedUser?.uuid || '',
            SENSITIVE_country: formValues.country || null,
            SENSITIVE_phone: phoneNumberWithExtension || null,
            SENSITIVE_address_information: {
              address1: formattedAddressInformation.addressLine1,
              address2: formattedAddressInformation.addressLine2,
              city: formattedAddressInformation.city,
              state: formattedAddressInformation.state,
              zipCode: formattedAddressInformation.zipCode,
              country: formattedAddressInformation.country as string,
            },
          },
        },
      });
      if (!response.data) {
        setIsSubmiting(false);
        handleUpdatedContactInfo(GeneralStates.ERROR);
        scrollToTop();
        throw new Error('Failed to update user');
      }

      localStorage.removeItem(LOCAL_STORAGE_WEATHER_ZIP);
      handleUpdatedContactInfo(GeneralStates.SUCCESS);
      await updateProfileNotification();

      setIsSubmiting(false);
      scrollToTop();
    } catch (error: unknown) {
      console.log(error);
      setIsSubmiting(false);
      handleUpdatedContactInfo(GeneralStates.ERROR);
      scrollToTop();
      throw new Error('Failed to update user');
    }
  };

  const parseQuestionOptionsForDropdownWithLookup = (options: string[]) => {
    const parsedOptions =
      options &&
      options.map((option: string) => ({
        label: option,
        value: option,
      }));
    return parsedOptions;
  };

  const mapValuesToSelect = textFields.map((field) => {
    if (typeof field === 'string') {
      return {
        name: field,
        label: field,
        value: field,
      };
    } else {
      return {
        name: field.name,
        label: field.value,
        value: field.value,
      };
    }
  });

  return (
    <div className="flex flex-col items-center bg-white w-full p-5 desktop:p-[30px] gap-5 rounded-10">
      <div
        className="flex flex-col items-start w-full gap-2.5 p-0 cursor-pointer desktop:cursor-default"
        onClick={handleShowForm}
      >
        <div className="flex flex-row w-full items-center justify-between">
          <div className="flex flex-row items-center">
            <h3 className="text-h5 desktop:text-h4 text-dark-gray font-semibold desktop:mr-2.5">
              {locale?.contactInfo.title}
            </h3>
          </div>
          {isFormDisplayed ? (
            <ChevronUpIcon
              className="desktop:hidden w-[18px] h-2.5 fill-current cursor-pointer"
              onClick={handleShowForm}
            />
          ) : (
            <ChevronDownIcon
              className="desktop:hidden w-[18px] h-2.5 fill-current cursor-pointer"
              onClick={handleShowForm}
            />
          )}
        </div>
        <hr
          className={`desktop:flex flex-row w-full items-center h-px bg-black-blur ${
            isFormDisplayed ? 'flex' : 'hidden'
          }`}
        />
      </div>

      <div
        className={`w-full desktop:flex flex-col gap-[15px] ${
          isFormDisplayed ? 'flex' : 'hidden'
        }`}
      >
        <div className="flex flex-col items-start w-full gap-2.5 p-0">
          <div className="desktop:flex flex-col w-full items-center space-y-4">
            <div className="flex flex-col desktop:flex-row w-full desktop:w-[690px] items-center desktop:items-center">
              <label
                className="w-full desktop:w-3/12 text-base text-dark-gray font-semibold mb-2"
                htmlFor="phoneNumber"
              >
                {locale.contactInfo.phoneLabel}
                <span className="text-base font-bold text-clc-red">
                  {locale.accountDeletion.form.required}
                </span>
              </label>
              <div className="w-full flex flex-col desktop:w-9/12 desktop:flex-row">
                <div className="flex flex-col items-start">
                  <div className="flex w-full">
                    <InputComponent
                      testID="phoneNumber-country-select"
                      type="select-with-country-icons"
                      name="country"
                      borderRadiusRight={true}
                      selectInputWithFlagsProps={{
                        currentValue:
                          getMemoizedCurrentCountryValue as SelectWithFlagsValues,
                        defaultValue:
                          getMemoizedDefaultParsedValue as SelectWithFlagsValues,
                        options:
                          getMemoizedParsedCountries as SelectWithFlagsValues[],
                        onSelectChange: (e) =>
                          handleCountryChange(e as CountriesSelectValue),
                      }}
                    />

                    <InputComponent
                      testID="phoneNumber-input"
                      type="tel"
                      name="phone"
                      onChange={hadleOnPhoneInputChange}
                      flexGrow={1}
                      borderRadiusLeft={true}
                      value={formValues.phone}
                    />
                  </div>
                </div>

                <div className="flex flex-row items-start ml-3">
                  <label
                    className="mt-3  mb-[10px] mr-[10px] text-base text-dark-gray font-bold desktop:w-2/12 desktop:mb-2"
                    htmlFor="phoneNumberExtension"
                  >
                    {locale?.contactInfo.extLabel}
                  </label>

                  <InputComponent
                    type="ext"
                    name="extension"
                    value={formValues.extension}
                    onChange={hadleOnExtensionChange}
                    maxLengthValue={4}
                  />
                </div>
              </div>
            </div>
            {textFields.map((field) => {
              const result = mapValuesToSelect.map((item) => {
                if (item.name === field.name) {
                  return { label: item.value, value: item.value };
                }
              });

              const selectedItem = result.filter(
                (item) => item != undefined,
              )[0] as SingleValue<string | { label: string; value: string }>;

              return (
                <div
                  key={field.name}
                  className="flex flex-col desktop:flex-row w-full desktop:w-[690px] items-center desktop:items-center"
                >
                  <label
                    className="w-full desktop:w-3/12 text-base text-dark-gray font-semibold mb-2"
                    htmlFor={field.name}
                  >
                    {field.label}
                    {field.required && (
                      <span className="text-base font-bold text-clc-red">
                        {locale.accountDeletion.form.required}
                      </span>
                    )}
                  </label>
                  <div className="w-full desktop:w-9/12">
                    {field.name === 'city' ? (
                      <CreatableSelect
                        className="dropdown"
                        classNamePrefix="react-select"
                        placeholder={
                          genericAction?.[genericActionsIds.BEGINTYPINGTOLOOKUP]
                            .beginTypingToLookUp
                        }
                        value={selectedItem}
                        options={
                          parseQuestionOptionsForDropdownWithLookup(
                            field.selectInputProps?.selectValues
                              ? field.selectInputProps?.selectValues
                              : [],
                          ) as unknown as readonly (
                            | string
                            | GroupBase<string>
                          )[]
                        }
                        onChange={(e) => {
                          handleOnSelectChange(e, field.name);
                        }}
                        onKeyDown={onSelectKeyDownAllowOnlyLettersAndSpaces}
                        components={{
                          Input: (props) => (
                            <components.Input {...props} maxLength={40} />
                          ),
                        }}
                      />
                    ) : field.type === 'select' ? (
                      <>
                        <Select
                          className="dropdown"
                          classNamePrefix="react-select"
                          placeholder={
                            genericAction?.[
                              genericActionsIds.BEGINTYPINGTOLOOKUP
                            ].beginTypingToLookUp
                          }
                          value={selectedItem}
                          options={
                            parseQuestionOptionsForDropdownWithLookup(
                              field.selectInputProps?.selectValues
                                ? field.selectInputProps?.selectValues
                                : [],
                            ) as unknown as readonly (
                              | string
                              | GroupBase<string>
                            )[]
                          }
                          onChange={(e) => {
                            handleOnSelectChange(e, field.name);
                          }}
                        />
                      </>
                    ) : (
                      <InputComponent
                        type={field.type}
                        name={field.name}
                        selectInputProps={{ ...field.selectInputProps }}
                        value={field.value as string}
                        onChange={field.onChange}
                        maxLengthValue={field.maxLengthValue}
                      />
                    )}
                  </div>
                </div>
              );
            })}

            <div className="flex flex-col desktop:flex-row w-full desktop:w-[690px] items-center desktop:items-center">
              <label
                className="w-full desktop:w-3/12 text-base text-dark-gray font-semibold mb-2"
                htmlFor="zip"
              >
                {locale?.contactInfo.zipLabel}
                <span className="text-base font-bold text-clc-red">
                  {locale.accountDeletion.form.required}
                </span>
              </label>
              <div className="w-full desktop:w-36">
                <InputComponent
                  type="zip"
                  name="zipCode"
                  value={formValues.addressInformation.zipCode || ''}
                  onChange={handleZipCodeChange}
                />
              </div>
            </div>
          </div>
          <hr className="desktop:flex flex-row w-full items-center h-px bg-black-blur" />
        </div>
        <div className="hidden w-full desktop:flex justify-end">
          <ButtonComponent
            iconPosition="right"
            disabled={isSubmiting}
            className="px-10"
            onClick={handleOnSubmit}
          >
            {genericAction?.[genericActionsIds.UPDATE].update}
          </ButtonComponent>
        </div>
      </div>
    </div>
  );
};
