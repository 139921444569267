import { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as rotateLeftIcon } from 'assets/icons/rotateLeft.svg';
import { ReactComponent as rotateRightIcon } from 'assets/icons/rotateRight.svg';
import { ModalButtonProps } from 'components/modal/modal.interfaces';
import { useGetPage } from 'hooks/useGetPage';
import { genericActionsIds, pageIds } from 'utilities/constants';
import { ReactComponent as FaceGuideIcon } from 'assets/icons/faceGuide.svg';
import { useImageCrop } from 'hooks/useImageCrop';
import { useImageRotate } from 'hooks/useImageRotate';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import {
  DASHBOARD,
  DASHBOARD_ANALIZE_PHOTO,
  DASHBOARD_NEW_SCAN,
  MY_DERMSCORE_NEW_SCAN,
  MY_SKIN_ANALIZE_PHOTO,
} from 'utilities/routes';
import { getImageDimensionsFromBase64 } from 'utilities/functions';
import { useIsMobile } from 'hooks/useIsMobile';
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop';
import Modal from 'components/modal/modalComponent';
import ButtonComponent from 'components/button/buttonComponent';
import 'react-image-crop/dist/ReactCrop.css';
import Loader from 'components/loaderComponent';

const MAX_IMAGE_HEIGHT = 600;
const MAX_IMAGE_HEIGHT_MOBILE = 500;
const MIN_CROP_HEIGHT = 500;
const MIN_CROP_WIDTH = 500;
const MARGIN_CROP_CONTAINER = 100;
const MARGIN_CROP_CONTAINER_MOBILE = 150;

export const ResizePhoto = () => {
  const navigate = useNavigate();
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.RESIZE_PHOTO,
  });

  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.SUBMIT, genericActionsIds.CANCEL],
    });

  const location = useLocation();
  const initialImageSrc = location.state?.image || '';
  const initialMimeType = location.state?.mimeType || 'image/png';
  const filename = location.state?.filename || '';
  const [rotation, setRotation] = useState(0);
  const [cropFactorWidth, setCropFactorWidth] = useState(1);
  const [cropFactorHeight, setCropFactorHeight] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const isMobile = useIsMobile();
  const firstRendered = useRef(false);

  const {
    src: cropSrc,
    setSrc: setCropSrc,
    crop,
    setCrop,
    imgRef: cropImgRef,
    setCompletedCrop,
    onImageLoad,
    onCropComplete,
  } = useImageCrop(initialImageSrc, 1, initialMimeType);

  const { rotateImage } = useImageRotate();
  const isDashboard = location.pathname === DASHBOARD;
  const newScanUrl = isDashboard ? DASHBOARD_NEW_SCAN : MY_DERMSCORE_NEW_SCAN;
  const analizePhotoUrl = isDashboard
    ? DASHBOARD_ANALIZE_PHOTO
    : MY_SKIN_ANALIZE_PHOTO;

  useEffect(() => {
    if (!cropSrc) {
      navigate(newScanUrl, {
        state: { error: true },
      });
    }
  }, [cropSrc, navigate, newScanUrl]);

  const handleSubmitPhoto = async () => {
    try {
      setIsSubmitting(true);
      const capturedPhoto = await onCropComplete(filename);
      setIsSubmitting(false);
      navigate(analizePhotoUrl, {
        state: {
          image: capturedPhoto,
          filename,
        },
      });
    } catch {
      setIsSubmitting(false);
      navigate(newScanUrl, {
        state: { fileError: true },
      });
    }
  };
  const rotate = (angle: number): void => {
    try {
      const rotatedSrc = rotateImage(cropSrc || '', angle);
      setCropSrc(rotatedSrc);
      setRotation(rotation + angle);
    } catch {
      navigate(newScanUrl, {
        state: { fileError: true },
      });
    }
  };

  const handleCancel = () => {
    navigate(DASHBOARD);
  };

  const Buttons: ModalButtonProps[] = [
    {
      label: genericAction?.[genericActionsIds.SUBMIT].submit,
      onClick: handleSubmitPhoto,
    },
    {
      label: genericAction?.[genericActionsIds.CANCEL].cancel,
      type: 'underline',
      onClick: handleCancel,
    },
  ];

  useEffect(() => {
    if (initialImageSrc && !firstRendered.current) {
      firstRendered.current = true;
      (async () => {
        const { width, height } = await getImageDimensionsFromBase64(
          initialImageSrc,
        );
        const maxImageHeight = isMobile
          ? MAX_IMAGE_HEIGHT_MOBILE
          : MAX_IMAGE_HEIGHT;
        const maxImageWidth = (width / height) * maxImageHeight;

        setCropFactorWidth(maxImageWidth / width);
        setCropFactorHeight(maxImageHeight / height);
      })();
    }
  }, [initialImageSrc, isMobile]);

  const calculateParentHeight = () => {
    if (cropImgRef.current) {
      const reactCropHeight = cropImgRef.current.offsetHeight;
      return isMobile
        ? reactCropHeight + MARGIN_CROP_CONTAINER_MOBILE
        : reactCropHeight + MARGIN_CROP_CONTAINER;
    }
  };

  if ((loading && !locale) || (genericActionLoading && !genericAction))
    return null;

  return (
    <Modal
      isOpen={true}
      title={locale?.resizeTitle}
      buttons={isSubmitting ? [] : Buttons}
      onClose={handleCancel}
    >
      {isSubmitting ? (
        <Loader />
      ) : (
        <>
          <div className="flex flex-col">
            <h3 className="text-h4 font-semibold text-dark-gray pb-2.5 desktop:text-h3">
              {locale?.resizeHeader}
            </h3>
            <p className="text-base font-medium text-med-gray">
              {locale?.resizeSubHeader}
            </p>
          </div>
          <div
            style={{ height: `${calculateParentHeight()}px` }}
            className="relative flex flex-col bg-black-box rounded items-center desktop:flex-row desktop:w-[870px] desktop:mb-[30px] justify-center mr-[-38px] ml-[-38px] dektop:mr-0 desktop:ml-0"
          >
            <ReactCrop
              className="w-fit h-auto"
              crop={crop}
              minWidth={MIN_CROP_HEIGHT * cropFactorWidth}
              minHeight={MIN_CROP_WIDTH * cropFactorHeight}
              keepSelection={true}
              onChange={(_: PixelCrop, percentCrop: Crop) =>
                setCrop(percentCrop)
              }
              onComplete={(_: PixelCrop, percentCrop: Crop) =>
                setCompletedCrop(percentCrop)
              }
            >
              <img
                className="w-full max-h-[500px] desktop:max-h-[600px]"
                src={cropSrc || ''}
                alt={locale.imageAltText}
                ref={cropImgRef}
                onLoad={onImageLoad}
                style={{
                  maxHeight: isMobile
                    ? MAX_IMAGE_HEIGHT_MOBILE
                    : MAX_IMAGE_HEIGHT,
                }}
              />
              <div
                className="absolute p-2.5"
                style={{
                  top: `${crop?.y || 0}%`,
                  left: `${crop?.x || 0}%`,
                  width: `${crop?.width || 100}%`,
                  height: `${crop?.height || 100}%`,
                  transform: `rotate(${rotation}deg)`,
                }}
              >
                <FaceGuideIcon className="w-full h-full" />
              </div>
            </ReactCrop>

            <div className="flex flex-row absolute items-center justify-center space-x-4 bottom-[15px] desktop:space-x-0 desktop:bottom-auto desktop:flex-col desktop:space-y-4 desktop:right-[42px]">
              <ButtonComponent
                Icon={rotateLeftIcon}
                type="icon"
                paddingX="px-2.5"
                paddingY="py-2.5"
                onClick={() => rotate(-90)}
              />
              <ButtonComponent
                Icon={rotateRightIcon}
                type="icon"
                paddingX="px-2.5"
                paddingY="py-2.5"
                onClick={() => rotate(90)}
              />
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};
