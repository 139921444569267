import {
  Dnavisit_Test_Statuses_Enum,
  Test_Types_Enum,
} from 'graphql/generated/hasura';
import {
  Hasura_Dnavisit_Test_Statuses_Enum,
  Hasura_Test_Types_Enum,
  Hasura_Zrt_Test_States_Enum,
} from 'graphql/generated/remote-schema-hasura';

export const MapFhirToHasuraDnaVisitTestState: Map<
  Hasura_Dnavisit_Test_Statuses_Enum,
  Dnavisit_Test_Statuses_Enum
> = new Map([
  [
    Hasura_Dnavisit_Test_Statuses_Enum.KitNotReceived,
    Dnavisit_Test_Statuses_Enum.KitNotReceived,
  ],
  [
    Hasura_Dnavisit_Test_Statuses_Enum.ReceivedByLab,
    Dnavisit_Test_Statuses_Enum.ReceivedByLab,
  ],
  [
    Hasura_Dnavisit_Test_Statuses_Enum.ProviderPending,
    Dnavisit_Test_Statuses_Enum.ProviderPending,
  ],
  [
    Hasura_Dnavisit_Test_Statuses_Enum.ResultsWithProvider,
    Dnavisit_Test_Statuses_Enum.ResultsWithProvider,
  ],
  [
    Hasura_Dnavisit_Test_Statuses_Enum.AppointmentScheduled,
    Dnavisit_Test_Statuses_Enum.AppointmentScheduled,
  ],
  [
    Hasura_Dnavisit_Test_Statuses_Enum.Complete,
    Dnavisit_Test_Statuses_Enum.Complete,
  ],
]);

export const MapFhirZrtToDnaVisitTestState: Map<
  Hasura_Zrt_Test_States_Enum,
  Dnavisit_Test_Statuses_Enum
> = new Map([
  [
    Hasura_Zrt_Test_States_Enum.KitNotReceived,
    Dnavisit_Test_Statuses_Enum.KitNotReceived,
  ],
  [
    Hasura_Zrt_Test_States_Enum.ReceivedByLab,
    Dnavisit_Test_Statuses_Enum.ReceivedByLab,
  ],
  [
    Hasura_Zrt_Test_States_Enum.ProviderPending,
    Dnavisit_Test_Statuses_Enum.ProviderPending,
  ],
  [
    Hasura_Zrt_Test_States_Enum.ResultsWithProvider,
    Dnavisit_Test_Statuses_Enum.ResultsWithProvider,
  ],
  [
    Hasura_Zrt_Test_States_Enum.AppointmentScheduled,
    Dnavisit_Test_Statuses_Enum.AppointmentScheduled,
  ],
  [Hasura_Zrt_Test_States_Enum.Complete, Dnavisit_Test_Statuses_Enum.Complete],
]);

export const MapHasuraToFhirDnaVisitTestState: Map<
  Dnavisit_Test_Statuses_Enum,
  Hasura_Dnavisit_Test_Statuses_Enum
> = new Map([
  [
    Dnavisit_Test_Statuses_Enum.KitNotReceived,
    Hasura_Dnavisit_Test_Statuses_Enum.KitNotReceived,
  ],
  [
    Dnavisit_Test_Statuses_Enum.ReceivedByLab,
    Hasura_Dnavisit_Test_Statuses_Enum.ReceivedByLab,
  ],
  [
    Dnavisit_Test_Statuses_Enum.ProviderPending,
    Hasura_Dnavisit_Test_Statuses_Enum.ProviderPending,
  ],
  [
    Dnavisit_Test_Statuses_Enum.ResultsWithProvider,
    Hasura_Dnavisit_Test_Statuses_Enum.ResultsWithProvider,
  ],
  [
    Dnavisit_Test_Statuses_Enum.AppointmentScheduled,
    Hasura_Dnavisit_Test_Statuses_Enum.AppointmentScheduled,
  ],
  [
    Dnavisit_Test_Statuses_Enum.Complete,
    Hasura_Dnavisit_Test_Statuses_Enum.Complete,
  ],
]);

export const MapFhirToHasuraDnaVisitTestType: Map<
  Hasura_Test_Types_Enum,
  Test_Types_Enum
> = new Map([
  [Hasura_Test_Types_Enum.DnaSkin, Test_Types_Enum.DnaSkin],
  [Hasura_Test_Types_Enum.DnaVitamin, Test_Types_Enum.DnaVitamin],
  [Hasura_Test_Types_Enum.ZrtHeavyMetals, Test_Types_Enum.ZrtHeavyMetals],
  [Hasura_Test_Types_Enum.ZrtHormone, Test_Types_Enum.ZrtHormone],
  [Hasura_Test_Types_Enum.ZrtInflammatory, Test_Types_Enum.ZrtInflammatory],
  [
    Hasura_Test_Types_Enum.ZrtNeurotransmitters,
    Test_Types_Enum.ZrtNeurotransmitters,
  ],
]);

export const MapHasuraToFhirDnaVisitTestType: Map<
  Test_Types_Enum,
  Hasura_Test_Types_Enum
> = new Map([
  [Test_Types_Enum.DnaSkin, Hasura_Test_Types_Enum.DnaSkin],
  [Test_Types_Enum.DnaVitamin, Hasura_Test_Types_Enum.DnaVitamin],
  [Test_Types_Enum.ZrtHeavyMetals, Hasura_Test_Types_Enum.ZrtHeavyMetals],
  [Test_Types_Enum.ZrtHormone, Hasura_Test_Types_Enum.ZrtHormone],
  [Test_Types_Enum.ZrtInflammatory, Hasura_Test_Types_Enum.ZrtInflammatory],
  [
    Test_Types_Enum.ZrtNeurotransmitters,
    Hasura_Test_Types_Enum.ZrtNeurotransmitters,
  ],
]);
