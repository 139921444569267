import { Link, useNavigate } from 'react-router-dom';
import { ModalButtonProps } from 'components/modal/modal.interfaces';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import { genericActionsIds } from 'utilities/constants';
import { UVIndexIconLabels } from 'assets/icons/weatherIcons/UVIndexIconLabels';
import { UVInfoProps } from './interfaces';
import Modal from 'components/modal/modalComponent';
import IconComponent from 'components/iconComponent';
import InfoCategory from './InfoCategory';
import InfoCategoryWrapper from './InfoCategoryWrapper';

const UVInfo: React.FC<UVInfoProps> = ({ locale }) => {
  const navigate = useNavigate();

  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.CLOSE],
    });

  const handleClose = () => navigate(-1);

  if (genericActionLoading || !genericAction) {
    return null;
  }

  const Buttons: ModalButtonProps[] = [
    {
      label: genericAction?.[genericActionsIds.CLOSE].close,
      onClick: handleClose,
    },
  ];

  return (
    <Modal
      isOpen={true}
      title={locale.uvIndex.uvInfoTitle}
      disableMobileGrow={true}
      buttons={Buttons}
      onClose={handleClose}
      modalContainerCustomClass="desktop:!min-h-[830px] desktop:max-h-[830px] overflow-y-auto"
    >
      <div className="flex flex-col items-center desktop:hidden">
        <div className="max-w-[320px]">
          <IconComponent
            width="w-full"
            height="h-auto"
            icon={() => <UVIndexIconLabels id="uvinfo" />}
            fill="none"
          />
        </div>
      </div>

      <InfoCategoryWrapper>
        <InfoCategory
          borderTop
          color="bg-gradient-green"
          category={`${locale.uvIndex.low} ${locale.uvIndex.lowLevels}`}
          categoryLetter={locale.categoryA}
          categoryWarning={locale.uvIndex.lowWarning}
        />

        <InfoCategory
          borderTop
          color="bg-gradient-tangerine"
          category={`${locale.uvIndex.moderate} ${locale.uvIndex.moderateLevels}`}
          categoryLetter={locale.categoryB}
          categoryWarning={locale.uvIndex.moderateWarning}
        />

        <InfoCategory
          borderTop
          color="bg-gradient-blazing"
          category={`${locale.uvIndex.high} ${locale.uvIndex.highLevels}`}
          categoryLetter={locale.categoryC}
          categoryWarning={locale.uvIndex.highWarning}
        />

        <InfoCategory
          borderTop
          color="bg-gradient-magenta"
          category={`${locale.uvIndex.veryHigh} ${locale.uvIndex.veryHighLevels}`}
          categoryLetter={locale.categoryD}
          categoryWarning={locale.uvIndex.veryHighWarning}
        />

        <InfoCategory
          borderTop
          color="bg-gradient-azure"
          category={`${locale.uvIndex.extreme} ${locale.uvIndex.extremeLevels}`}
          categoryLetter={locale.categoryE}
          categoryWarning={locale.uvIndex.veryHighWarning}
        />
      </InfoCategoryWrapper>

      <p className="text-med-gray">{locale.uvIndex.indexProvides}</p>
      <p className="text-med-gray">{locale.uvIndex.important}</p>
      <p className="text-med-gray">
        {locale.uvIndex.forMoreInformation}{' '}
        <Link
          className="text-clc-blue underline font-bold"
          to={locale.uvIndex.nationalWeatherServiceLink}
          target="_blank"
        >
          {locale.uvIndex.nationalWeatherService}
        </Link>{' '}
        {locale.uvIndex.webpage}
      </p>
    </Modal>
  );
};

export default UVInfo;
