import React from 'react';
import { PARAM_MODALS_IDENTIFIERS } from 'utilities/constants';
import { useParams } from 'react-router-dom';
import CancelAppointmentModalProvider from 'app/appointments/components/CancelAppointmentModalProvider';
import { useModalParams } from 'components/modal/useModalManager';
import { AppointmentHistory } from 'app/my-appointments/components/AppointmentHistory';
import { UpcomingAppointments } from 'app/my-appointments/components/UpcomingAppointments';

export const MyAppointmentsTab: React.FC = () => {
  const { patientId } = useParams();
  const { isOpen: isCancelAppointmentModalOpen } = useModalParams(
    PARAM_MODALS_IDENTIFIERS.CANCEL_APPOINTMENT_MODAL_PROVIDER_ID,
  );

  return (
    <>
      {isCancelAppointmentModalOpen && <CancelAppointmentModalProvider />}
      <div className="px-7 pt-[30px] desktop:pt-0">
        <div className="flex flex-col gap-[30px]">
          <UpcomingAppointments patientCodexId={patientId} />
          <AppointmentHistory patientCodexId={patientId} />
        </div>
      </div>
    </>
  );
};
