import { useMemo } from 'react';
import {
  useGetGenericActionsQuery,
  GetGenericActionsQueryVariables,
} from '../graphql/generated/strapi';
import { useStrapi } from 'apollo/LocalesProvider';

export interface GenericActionData {
  id: number;
  [key: string]: string | number;
}

export type GenericActionsMap = {
  [key: string]: GenericActionData;
};

export const useGetGenericActions = (
  variables: GetGenericActionsQueryVariables,
) => {
  const { client } = useStrapi();
  const { data, loading, error } = useGetGenericActionsQuery({
    variables,
    client: client,
    fetchPolicy: 'cache-first',
  });

  const genericActionsData = data?.genericActions?.data.reduce(
    (result: GenericActionsMap, dataItem) => {
      const id = dataItem?.attributes?.genericActionId;
      if (id) {
        result[id] = { id, ...dataItem?.attributes?.content };
      }
      return result;
    },
    {},
  );

  const genericActionsMemoized = useMemo(
    () => ({ data: genericActionsData, loading, error }),
    [genericActionsData, loading, error],
  );

  return genericActionsMemoized;
};
