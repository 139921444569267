import {
  AppointmentDetailsLocale,
  AppointmentInfoProviderProps,
} from '../interfaces';
import DefaultAvatar from 'assets/icons/default_patient.png';
import { useGetSignedUrlFromStorage } from 'hooks/useGetSignedUrlFromStorage';
import {
  extractUUIDFromResURL,
  fromFhirAppointmentTypeToLocal,
} from 'utilities/functions';
import { appointmentRenderInfo } from 'app/my-appointments/interfaces';
import Avatar from 'components/avatarComponent';

const AppointmentInfoProvider: React.FC<AppointmentInfoProviderProps> = ({
  datetime,
  name,
  pictureOwnerCodexId,
  profilePictureUrl,
  appointmentType,
  locale,
  appointmentPrice,
  mainSpecialty,
}) => {
  const photoUrl =
    extractUUIDFromResURL(profilePictureUrl || '') || DefaultAvatar;
  const signedProfileUrl = useGetSignedUrlFromStorage(
    photoUrl,
    pictureOwnerCodexId,
  );
  const { icon: TypeIcon, text: typeLocale } =
    appointmentRenderInfo[
      fromFhirAppointmentTypeToLocal(appointmentType || undefined)
    ];

  return (
    <div className="flex flex-col md:flex-row items-start p-0 md:gap-x-48">
      <div className="flex flex-col mb-4 md:mb-0">
        <h4 className="font-exo text-lg font-semibold leading-5 text-left text-gray-800">
          {locale.aboutYourAppointment}
        </h4>
      </div>

      <div className="flex flex-col">
        <div className="flex items-center mb-2">
          <TypeIcon className="h-6 w-4" />
          <h5 className="font-proxima-nova text-sm font-bold leading-6 text-left text-gray-800 ml-2">
            {locale[typeLocale as keyof AppointmentDetailsLocale]}{' '}
            {appointmentPrice && `($${appointmentPrice})`}
          </h5>
        </div>
        <p className="font-proxima-nova text-lg font-semibold leading-6 text-left text-gray-800">
          {datetime}
        </p>
        <div className="flex items-center mt-2">
          <Avatar
            size="sm"
            imageUrl={signedProfileUrl}
            customClasses="h-11 w-11 rounded-full mr-2"
          />
          <div className="flex flex-col">
            <p className="font-proxima-nova text-base/[21px] font-semibold leading-6 text-left text-gray-800">
              {name}
            </p>
            {mainSpecialty && (
              <p className="text-med-gray font-medium text-base">
                {mainSpecialty}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentInfoProvider;
