interface WidgetContainerProps {
  title: string;
  children: React.ReactNode;
  desktopHiddden?: boolean;
  titlesInBold?: boolean;
  titlesCapitalization?: 'uppercase' | 'none';
  titlesSize?: 'text-sm' | 'text-base' | 'text-lg';
  backgroundColor?: string;
}

export const WidgetContainer: React.FC<WidgetContainerProps> = ({
  title,
  children,
  desktopHiddden = false,
  titlesInBold = false,
  titlesCapitalization = 'uppercase',
  titlesSize = 'text-sm',
  backgroundColor = 'bg-white',
}) => {
  return (
    <div
      className={`flex flex-col ${backgroundColor} rounded-2.5 p-5 gap-4 rounded-10 ${
        desktopHiddden ? 'desktop:hidden' : ''
      }`}
    >
      <h1
        className={`${titlesCapitalization} ${titlesSize} ${
          titlesInBold ? 'font-bold' : 'font-semibold'
        }`}
      >
        {title}
      </h1>
      {children}
    </div>
  );
};
