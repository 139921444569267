import { PreworkItemContainer } from './PreworkItemContainer';

interface PreworkItemProps {
  question: string;
  answer: string;
}

export const PreworkItem: React.FC<PreworkItemProps> = ({
  question,
  answer,
}) => {
  return (
    <PreworkItemContainer question={question}>
      <div className="flex flex-col">
        <span className="text-med-gray">{answer}</span>
      </div>
    </PreworkItemContainer>
  );
};
