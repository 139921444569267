import { useMyAppointmentsLocale } from 'app/my-appointments/pages/MyAppointments';
import {
  AppointmentHistoryRowProps,
  appointmentRenderInfo,
} from '../../interfaces';
import ButtonComponent from 'components/button/buttonComponent';
import { Roles } from '../../../../firebase/interfaces';
import { useContext } from 'react';
import { AuthContext } from 'auth/context/AuthContext';
import { useModal } from 'layout/useModal';
import CompleteMyVisitSummaryModal from '../CompleteMyVisitSummaryModal';
import {
  MY_APPOINTMENTS_PATIENT_MYVISIT_SUMMARY_MODAL,
  MY_APPOINTMENTS_PROVIDER_MYVISIT_SUMMARY_MODAL,
  MY_APPOINTMENTS_PROVIDER_OVERVIEW,
  MY_PATIENTS,
  MY_PATIENTS_BIO_INFO,
} from 'utilities/routes';
import { PARAM_MODALS_IDENTIFIERS } from 'utilities/constants';
import { useNavigate } from 'react-router-dom';

export const AppointmentHistoryRowMobile: React.FC<
  AppointmentHistoryRowProps
> = ({
  date,
  name: provider,
  type,
  logs: logsRow,
  summaryCompleted = true,
  locale: localeProp,
  hideProvider = false,
  patientCodexId,
  id,
}) => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { locale: localeHook } = useMyAppointmentsLocale();
  const locale = { ...localeProp, ...localeHook };
  const ProviderRenderIcon = appointmentRenderInfo[type].icon;
  const [openMyVisitSummaryModal] = useModal(CompleteMyVisitSummaryModal);
  const handleOnPatientClick = () => {
    if (user?.role !== Roles.PROVIDER) return;
    const path = `${MY_PATIENTS}/${patientCodexId}/${MY_PATIENTS_BIO_INFO}`;
    navigate(path);
  };

  const handleNavigateCompleteSummaryModal = () => {
    openMyVisitSummaryModal({ appointmentId: id });
  };

  const handleNavigateMySummaryModal = () => {
    if (user?.role === Roles.PROVIDER) {
      navigate(MY_APPOINTMENTS_PROVIDER_MYVISIT_SUMMARY_MODAL, {
        state: { appointmentId: id },
      });
    } else {
      navigate(MY_APPOINTMENTS_PATIENT_MYVISIT_SUMMARY_MODAL, {
        state: { appointmentId: id },
      });
    }
  };

  const handleNavigateAppoinmetnOverview = () => {
    if (user?.role === Roles.PROVIDER) {
      navigate(MY_APPOINTMENTS_PROVIDER_OVERVIEW, {
        state: { appointmentId: id },
      });
    } else {
      navigate(
        `.?${PARAM_MODALS_IDENTIFIERS.VIEW_APPOINTMENT_DETAILS_MODAL_PATIENT}=true`,
        {
          state: { appointmentId: id },
        },
      );
    }
  };

  return (
    <div className="flex desktop:hidden flex-col gap-5 w-full">
      <hr className="flex flex-row w-full items-center h-px bg-black-blur" />
      <div className="flex flex-col">
        <p className="text-sm font-semibold">{locale.dateAndTime}</p>
        <p className="text-sm font-semibold text-med-gray">{date}</p>
      </div>
      {!hideProvider && (
        <div className="flex flex-col">
          <p className="text-sm font-semibold">{locale.provider}</p>
          <p
            className={`text-sm font-semibold underline ${
              user?.role === Roles.PROVIDER ? 'cursor-pointer' : ''
            }`}
            onClick={handleOnPatientClick}
          >
            {provider}
          </p>
        </div>
      )}
      <div className="flex flex-col">
        <p className="text-sm font-semibold">{locale.type}</p>
        <p className="text-sm font-semibold text-med-gray">
          <ProviderRenderIcon className="inline" />
          {locale[appointmentRenderInfo[type].text]}
        </p>
      </div>
      <div className="flex flex-col items-start">
        <p className="text-sm font-semibold">{locale.log}</p>
        <ul className="list-disc list-inside px-2">
          {logsRow.map((log) => (
            <li key={log} className="text-sm text-med-gray">
              {log}
            </li>
          ))}
        </ul>
      </div>
      <div className="flex flex-col">
        <p className="text-sm font-semibold">{locale.infoAndDiagnoses}</p>
        {!summaryCompleted && user?.role === Roles.PROVIDER && (
          <ButtonComponent
            type="outlined"
            className="pt-2"
            onClick={handleNavigateCompleteSummaryModal}
          >
            {locale.completeYourSummary}
          </ButtonComponent>
        )}
        <ul className="list-disc list-inside px-2 w-fit">
          <li
            key={locale.appointmentOverview}
            onClick={handleNavigateAppoinmetnOverview}
            className="text-base cursor-pointer text-clc-blue font-bold underline"
          >
            {locale.appointmentOverview}
          </li>
          {summaryCompleted && user?.role === Roles.PROVIDER && (
            <li
              key={locale.getFHIRProviderbyCodexIdArgument}
              onClick={handleNavigateMySummaryModal}
              className="text-base cursor-pointer text-clc-blue font-bold underline"
            >
              {locale.getFHIRProviderbyCodexIdArgument}
            </li>
          )}
          {summaryCompleted && user?.role === Roles.PATIENT && (
            <li
              key={locale.appointmentMyVisitSummary}
              onClick={handleNavigateMySummaryModal}
              className="text-base cursor-pointer text-clc-blue font-bold underline"
            >
              {locale.appointmentMyVisitSummary}
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};
