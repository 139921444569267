import React, { ReactNode } from 'react';
import { useNetworkStatus } from 'hooks/useNetworkStatus';
import Modal from './modal/modalComponent';
import { pageIds } from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';

interface NetworkRequiredRouteProps {
  children: ReactNode;
}

const NetworkRequiredRoute: React.FC<NetworkRequiredRouteProps> = ({
  children,
}) => {
  const isOnline = useNetworkStatus();
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.ERRORS,
  });

  if (loading && !locale) return null;

  return isOnline ? (
    <>{children}</>
  ) : (
    <>
      <Modal
        type="warning-with-header"
        isOpen={true}
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
        titleContainerMinHeight="desktop:min-h-[25px]"
      >
        <p>{locale?.noConnection} </p>
      </Modal>
      {children}
    </>
  );
};

export default NetworkRequiredRoute;
