import { ReactComponent as ChevronRightIcon } from 'assets/icons/chevron-right.svg';
import { User_Notification_Status_Enum } from 'graphql/generated/hasura';
import { useContext, useEffect, useState } from 'react';
import {
  Notification,
  NotificationsWidgetProps,
} from '../interfaces/notifications.interfaces';
import { relativeDate } from 'utilities/functions';
import { useGetPage } from 'hooks/useGetPage';
import { pageIds } from 'utilities/constants';
import { NOTIFICATIONS } from 'utilities/routes';
import { useNavigate } from 'react-router-dom';
import ButtonComponent from 'components/button/buttonComponent';
import Numball from 'components/numballComponent';
import NoNotifications from './NoNotifications';
import NotificationsList from './NotificationsList';
import { AuthContext } from 'auth/context/AuthContext';
import { Roles } from '../../../firebase/interfaces';
import { useNotificationCount } from 'notifications/contexts/NotificationsContext';
import { DoseSpotNotificationsModal } from 'app/notifications/pages/DoseSpotNotificationsModal';
import { useModal } from 'layout/useModal';
import { NotificationResult, useNotifications } from 'hooks/useNotifications';

export const NotificationsWidget: React.FC<NotificationsWidgetProps> = () => {
  const { user: loggedUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.NOTIFICATIONS,
  });

  const { loading: loadingNotifications, notifications: notificationsData } =
    useNotifications({ limit: 10 });

  const [openDoseSpotNotificationsModal] = useModal(DoseSpotNotificationsModal);

  const [notifications, setNotifications] = useState<Notification[]>([]);
  const handleOnDosespotClick = () => {
    openDoseSpotNotificationsModal(undefined);
  };

  useEffect(() => {
    const populateNotificationsData = (notifications: NotificationResult) => {
      const populateNotifications: Notification[] =
        notifications.notifications.map((notification) => {
          return {
            id: notification.id,
            body: notification.data.message,
            date: relativeDate(notification.data.date),
            isRead: notification.status === User_Notification_Status_Enum.Read,
            action: notification.data.action,
          };
        }) ?? [];
      setNotifications(populateNotifications);
    };

    if (notificationsData?.notifications?.length) {
      populateNotificationsData(notificationsData);
    } else {
      setNotifications([]);
    }
  }, [notificationsData, navigate]);

  const { notificationCount, externalNotificationCount } =
    useNotificationCount();

  if ((loading && !locale) || loadingNotifications) return null;

  return (
    <div className="flex flex-col items-center px-5 pt-5 pb-[30px] desktop:p-[30px] gap-5 bg-white rounded-10">
      <div className="flex flex-col w-full items-start p-0 gap-2.5">
        <div className="hidden desktop:flex flex-row items-center p-0 gap-2.5">
          <div className="flex text-h3 text-dark-gray font-semibold">
            {locale?.title}
          </div>
          <Numball count={notificationCount} />
        </div>
        <hr className="flex flex-row w-full items-center h-px bg-black-blur" />
      </div>

      <div className="flex flex-col w-full items-start p-0 gap-5 max-h-[400px] overflow-y-auto">
        {loggedUser?.role === Roles.PROVIDER &&
          externalNotificationCount !== 0 && (
            <>
              <p
                className="text-base text-dark-gray font-medium cursor-pointer"
                onClick={handleOnDosespotClick}
              >
                {externalNotificationCount} - {locale?.doseSpotNotifications}
              </p>
              <hr className="w-full" />
            </>
          )}
        {notifications.length ? (
          <NotificationsList notifications={notifications} />
        ) : (
          <NoNotifications />
        )}
      </div>

      <div className="flex flex-col desktop:flex-row w-full justify-between items-center p-0 gap-[30px]">
        <ButtonComponent
          type="underline"
          Icon={ChevronRightIcon}
          iconPosition="right"
          iconWidth="w-[10px]"
          iconHeight="h-[18px]"
          onClick={() => navigate(NOTIFICATIONS)}
        >
          {locale.viewAll}
        </ButtonComponent>
      </div>
    </div>
  );
};
