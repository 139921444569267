import { deepLinkableModals } from 'layout/deepLinkableModals';
import { ModalContext } from 'layout/ModalContext';
import { ModalSpecification } from 'layout/ModalSpecification';
import { UrlModalSearchManager } from 'layout/UrlModalSearchManager';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { VoidCallback } from 'utilities/functions';

type modalState = {
  resolver: VoidCallback<unknown>;
  canBeDismissed?: boolean;
  state: unknown;
};

export const ModalProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [allState, setAllState] = useState<{
    modals: ModalSpecification[];
    state: Record<string, modalState>;
  }>({ modals: [], state: {} });

  useEffect(() => {
    setAllState((prevState) => {
      const { modals, state } = prevState;
      const searchModals = UrlModalSearchManager.getModals(location.search);
      const searchKeys = searchModals.map((m) => m.modal);

      const existingModalKeys = modals.map((m) => m.key);

      const newSearchModals = searchModals.filter(
        (sm) => !existingModalKeys.includes(sm.modal),
      );
      const removedSearchModalKeys = modals
        .filter((em) => em.deepLinkable && !searchKeys.includes(em.key))
        .map((m) => m.key);

      if (newSearchModals.length === 0 && removedSearchModalKeys.length === 0)
        return prevState ?? { modals: [], state: {} };

      const remainingModals = modals.filter(
        (m) => !removedSearchModalKeys.includes(m.key),
      );

      const newState: Record<string, modalState> = {};
      remainingModals.forEach((rm) => (newState[rm.key] = state[rm.key]));
      newSearchModals.forEach(
        (nm) =>
          (newState[nm.modal] = {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            resolver: () => {},
            canBeDismissed: true,
            state: nm.state,
          }),
      );

      return {
        modals: [
          ...remainingModals,
          ...newSearchModals
            .reverse()
            .map((nm) => deepLinkableModals[nm.modal]),
        ],
        state: newState,
      };
    });
  }, [location.search]);

  const closeModal = (
    key: string,
    result: unknown,
  ): Promise<unknown> | undefined => {
    if (deepLinkableModals[key]) {
      navigate(`.?${UrlModalSearchManager.withoutModal(location.search, key)}`);
    } else {
      return new Promise<void>((resolve) => {
        setAllState((prevState) => {
          const { modals, state } = prevState;
          const newState = { ...state };
          const currentState = state[key];
          if (currentState) {
            const resolver = currentState.resolver;
            resolver(result);
          }

          delete newState[key];

          const idx = modals.findIndex((m) => m.key === key);
          const newModals = modals;

          if (idx >= 0) {
            newModals.splice(idx, 1);
          }
          resolve();
          return { modals: newModals, state: newState };
        });
      });
    }
  };

  const openModal: (
    spec: ModalSpecification,
    canBeDismissed: boolean,
    state: unknown,
  ) => Promise<unknown> = (spec, canBeDismissed, modalState) => {
    return new Promise((resolve) => {
      setAllState((prevState) => {
        const { modals, state } = prevState;
        const newModals = [...modals];
        if (!modals.find((m) => m === spec)) {
          newModals.push(spec);
        }
        const newState = { ...state };
        const existingState = state[spec.key];
        if (existingState) {
          throw new Error('Cannot open modal that is already open!');
        }

        newState[spec.key] = {
          resolver: resolve,
          canBeDismissed,
          state: modalState,
        };
        return { modals: newModals, state: newState };
      });
    });
  };

  const { modals, state } = allState;
  return (
    <ModalContext.Provider
      value={{
        openModal,
        closeModal,
        async closeAllModals(): Promise<boolean> {
          if (
            modals.find((spec) => {
              const currentState = state[spec.key];
              return currentState && !currentState.canBeDismissed;
            })
          )
            return false;

          await Promise.all(
            modals.map((spec) => {
              if (!state[spec.key]) return;
              closeModal(spec.key, undefined);
            }),
          );

          return true;
        },
      }}
    >
      {children}
      {modals.map((modalProvider) => {
        const currentState = state[modalProvider.key];
        return (
          currentState && (
            <modalProvider.Modal
              key={modalProvider.key}
              state={currentState ? currentState.state : undefined}
              isOpen={!!currentState}
              close={(result) => closeModal(modalProvider.key, result)}
            />
          )
        );
      })}
    </ModalContext.Provider>
  );
};
