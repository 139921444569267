import React, { useContext, useMemo, useState } from 'react';
import { Card } from '../Card';
import { UpcomingAppointmentsTable } from './UpcomingAppointmentsTable';
import { UpcomingAppointmentDesktop } from './UpcomingAppointmentDesktop';
import { UpcomingAppointmentMobile } from './UpcomingAppointmentMobile';
import { UpcomingAppointmentsCardRightComponent } from './UpcomingAppointmentsCardRightComponent';
import { interpolateVariables } from 'utilities/functions';
import {
  UpcomingAppoinment,
  UpcomingAppointmentsDayFilter,
} from '../../interfaces';
import {
  AppointmentTypeEnum,
  UpcomingAppointmentCounts,
  useGetAppointmentsByPatientCodexIdQuery,
} from 'graphql/generated/remote-schema-hasura';
import {
  FHIR_APPOINTMENT_STATUS,
  ORDER_BY_DIRECTION_NUMERIC,
  SORT_FHIR_APPOINTMENT_BY,
} from 'utilities/interfaces';
import { AuthContext, AuthContextType } from 'auth/context/AuthContext';
import Loader from 'components/loaderComponent';
import { upcomingStatuses } from 'app/my-appointments/components/UpcomingAppointments';

const INITIAL_USER_DATE = new Date(Date.now() - 30 * 60 * 1000).toISOString();
const MAX_LOAD_MORE_APPOINTMENTS = 5;

interface UpcomingAppointmentsCardProps {
  disableLoadMore?: boolean;
  isDashboardView?: boolean;
  locale?: Record<string, string>;
}

export const UpcomingAppointmentsCard: React.FC<
  UpcomingAppointmentsCardProps
> = ({ disableLoadMore = false, isDashboardView = false, locale }) => {
  const { user: loggedUser } = useContext<AuthContextType>(AuthContext);

  const [appointmentsQueryLimit, setAppointmentsQueryLimit] = useState(3);
  const [upcomingAppointments, setUpcommingAppointments] = useState<
    UpcomingAppoinment[]
  >([]);
  const [upcomingAppointmentsDayFilter, setUpcomingAppointmentsDayFilter] =
    useState<UpcomingAppointmentsDayFilter>(
      UpcomingAppointmentsDayFilter.NOFILTER,
    );
  const [upcomingAppointmentCounts, setUpcomingAppointmentCounts] =
    useState<UpcomingAppointmentCounts>({
      today: 0,
      tomorrow: 0,
      week: 0,
      month: 0,
    });

  const {
    data: allUpcommingAppointments,
    loading: getPatientAppointmentLoading,
    fetchMore,
  } = useGetAppointmentsByPatientCodexIdQuery({
    variables: {
      appointmentStatus: upcomingStatuses,
      limit: appointmentsQueryLimit,
      from: INITIAL_USER_DATE,
      sort: SORT_FHIR_APPOINTMENT_BY.SUMMARY_DATE,
      sortDirection: ORDER_BY_DIRECTION_NUMERIC.ASC,
      tzOffset: new Date().getTimezoneOffset(),
      dayFilter:
        upcomingAppointmentsDayFilter === UpcomingAppointmentsDayFilter.NOFILTER
          ? undefined
          : upcomingAppointmentsDayFilter,
    },
    onCompleted: (data) => {
      if (data.getFHIRAppointmentByRequestPatientCodexId.total > 0) {
        const allUpcommingAppointments: UpcomingAppoinment[] =
          data.getFHIRAppointmentByRequestPatientCodexId.appointment.map(
            (appointment) => ({
              name: `${appointment.providerFirstName} ${appointment.providerLastName}`,
              appointmentId: appointment.appointmentCodexId,
              date: appointment.start || '',
              end: appointment.end || '',
              patientId: loggedUser?.uuid || '',
              providerId: appointment.codexProviderId || '',
              appointmentType:
                appointment.appointmentType || AppointmentTypeEnum.Chat,
              provider: appointment.providerFirstName || '',
              status: appointment.status as FHIR_APPOINTMENT_STATUS,
            }),
          );
        setUpcommingAppointments(allUpcommingAppointments);
        setUpcomingAppointmentCounts(
          data.getFHIRAppointmentByRequestPatientCodexId.counts,
        );
      } else {
        setUpcommingAppointments([]);
        setUpcomingAppointmentCounts(
          data.getFHIRAppointmentByRequestPatientCodexId.counts,
        );
      }
    },
  });

  const handleLoadMore = () => {
    setAppointmentsQueryLimit((prev) => prev + MAX_LOAD_MORE_APPOINTMENTS);
    fetchMore({
      variables: {
        offset: appointmentsQueryLimit + MAX_LOAD_MORE_APPOINTMENTS,
        appointmentStatus: FHIR_APPOINTMENT_STATUS.BOOKED,
        from: new Date().toISOString(),
        sort: SORT_FHIR_APPOINTMENT_BY.SUMMARY_DATE,
        sortDirection: 0,
      },
    });
  };

  const upcomingAppointmentsDayFilterText = useMemo<
    Record<UpcomingAppointmentsDayFilter, string>
  >(() => {
    return {
      [UpcomingAppointmentsDayFilter.NOFILTER]: `${
        locale?.[UpcomingAppointmentsDayFilter.NOFILTER] ||
        UpcomingAppointmentsDayFilter.NOFILTER
      }`,
      [UpcomingAppointmentsDayFilter.TODAY]: `${
        locale?.[UpcomingAppointmentsDayFilter.TODAY] ||
        UpcomingAppointmentsDayFilter.TODAY
      } (${upcomingAppointmentCounts.today})`,
      [UpcomingAppointmentsDayFilter.TOMORROW]: `${
        locale?.[UpcomingAppointmentsDayFilter.TOMORROW] ||
        UpcomingAppointmentsDayFilter.TOMORROW
      } (${upcomingAppointmentCounts.tomorrow})`,
      [UpcomingAppointmentsDayFilter.MONTH]: `${
        locale?.[UpcomingAppointmentsDayFilter.MONTH] ||
        UpcomingAppointmentsDayFilter.MONTH
      } (${upcomingAppointmentCounts.month})`,
      [UpcomingAppointmentsDayFilter.WEEK]: `${
        locale?.[UpcomingAppointmentsDayFilter.WEEK] ||
        UpcomingAppointmentsDayFilter.WEEK
      } (${upcomingAppointmentCounts.week})`,
    };
  }, [
    locale,
    upcomingAppointmentCounts.month,
    upcomingAppointmentCounts.week,
    upcomingAppointmentCounts.tomorrow,
    upcomingAppointmentCounts.today,
  ]);

  const onAppointmentsForSelectChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const selectedValue = Object.entries(
      upcomingAppointmentsDayFilterText,
    ).find((entry) => e.target.value === entry[1]);
    if (selectedValue) {
      setUpcomingAppointmentsDayFilter(
        selectedValue[0] as UpcomingAppointmentsDayFilter,
      );
    }
  };

  if (getPatientAppointmentLoading) {
    return <Loader />;
  }

  if (!locale) {
    return null;
  }

  return (
    <Card
      title={locale.upcomingAppointmentsCardTitle}
      subtitle={interpolateVariables(locale.upcomingAppointmentsSubtitle, {
        current: upcomingAppointments.length.toString(),
        total:
          allUpcommingAppointments?.getFHIRAppointmentByRequestPatientCodexId.total.toString() ||
          '0',
      })}
      alwaysExpanded
      hideMobileSubtitle
      borderedSubtitleDesktop
      rightComponent={
        <UpcomingAppointmentsCardRightComponent
          appointmentsForEntries={Object.values(
            upcomingAppointmentsDayFilterText,
          )}
          appointmentsForValue={
            upcomingAppointmentsDayFilterText[upcomingAppointmentsDayFilter]
          }
          locale={locale}
          onAppointmentsForSelectChange={onAppointmentsForSelectChange}
        />
      }
    >
      {upcomingAppointments.length ? (
        <UpcomingAppointmentsTable
          upcomingAppointments={upcomingAppointments}
          UpcomingAppointmentDesktopRow={UpcomingAppointmentDesktop}
          UpcomingAppointmentMobileRow={UpcomingAppointmentMobile}
          totalAppointments={
            allUpcommingAppointments?.getFHIRAppointmentByRequestPatientCodexId
              .total || 0
          }
          disableLoadMore={disableLoadMore}
          isDashboardView={isDashboardView}
          HandleOnLoadMore={handleLoadMore}
          locale={locale}
          isPatient
        />
      ) : (
        <>
          <hr className="flex flex-row w-full items-center h-px bg-black-blur" />
          <div className="w-full text-med-gray font-semibold">
            {locale.noUpcomingAppointments}
          </div>
        </>
      )}
    </Card>
  );
};
