import React, { useState } from 'react';
import {
  PatientDetails,
  PatientDetailsLocale,
} from '../interfaces/patientDetails.interface';
import { ReactComponent as Down } from 'assets/icons/arrowDown.svg';
import { ReactComponent as Up } from 'assets/icons/arrowUp.svg';
import { ReactComponent as Pharmacy } from 'assets/icons/patientIcons/pharmacy.svg';
import ButtonComponent from 'components/button/buttonComponent';

interface IPatientPharmacy {
  patientDetails: PatientDetails;
  locale: PatientDetailsLocale;
}

const PatientPharmacy: React.FC<IPatientPharmacy> = ({
  locale,
  patientDetails,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const ArrowIcon = isExpanded ? Up : Down;
  const handleExpandIconClick = () =>
    setIsExpanded((isExpanded) => !isExpanded);

  const { primaryPharmacy } = patientDetails;

  return (
    <div>
      <ButtonComponent
        type="underline"
        iconPosition="right"
        Icon={ArrowIcon}
        className="uppercase font-exo font-bold text-sm mb-8"
        onClick={handleExpandIconClick}
      >
        {isExpanded ? locale.seeLess : locale.seeMore}
      </ButtonComponent>
      {isExpanded && (
        <>
          <h3 className="flex text-base text-charcoal-gray -mt-2 mb-3 font-semibold">
            <Pharmacy />
            <span className="ml-4">{locale.pharmacy || 'Pharmacy'}</span>
          </h3>

          <div className="ml-[31px] grid grid-cols-3 gap-4">
            <div className="flex flex-col">
              <span className="text-charcoal-gray font-semibold uppercase text-sm">
                {locale.primaryPharmacy}
              </span>
              <span className="text-med-gray text-base font-normal leading-snug">
                {primaryPharmacy?.name || 'Al’s Pharmacy '}
                {primaryPharmacy?.id || '321654987 '}
                {primaryPharmacy?.address || 'Some Street '}
                {primaryPharmacy?.city || 'Los Angeles '}
                {primaryPharmacy?.state || 'CA'}{' '}
                {primaryPharmacy?.zip || '12345'},
                {primaryPharmacy?.phone || ' (NNN) NNN-NNNN'}
              </span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PatientPharmacy;
