import { useNavigate } from 'react-router-dom';
import { useGetPage } from 'hooks/useGetPage';
import { pageIds } from 'utilities/constants';
import { TEST_KITS_MANAGEMENT_REGISTER_TEST_KIT } from 'utilities/routes';
import ButtonComponent from 'components/button/buttonComponent';

export const RegisterTestKitBanner = () => {
  const navigate = useNavigate();
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.DASHBOARD,
  });

  const handleNavigate = async () => {
    navigate(TEST_KITS_MANAGEMENT_REGISTER_TEST_KIT);
  };

  if (loading || !locale) return null;

  return (
    <div
      className="flex p-1 h-[160px] bg-white cursor-pointer registerTestKitBanner"
      onClick={handleNavigate}
    >
      <div className="flex w-full h-full bg-cover bg-center bg-register-test-kit">
        <div className="flex flex-col justify-center py-2.5 px-[30px] gap-[15px]">
          <p className="text-h5 font-semibold text-dark-gray">
            {locale?.registerTestKitBanner?.title}
          </p>

          <ButtonComponent
            paddingX="px-5"
            paddingY="py-[9.5px]"
            onClick={handleNavigate}
            className="registerTestKitButton"
          >
            {locale?.registerTestKitBanner?.buttonAction}
          </ButtonComponent>
        </div>
      </div>
    </div>
  );
};
