import { useGetPage } from 'hooks/useGetPage';
import { PreWorkSectionProps } from '../interfaces';
import DotPlacer from 'app/my-skin/components/BodySymtomsLocation/DotPlacer';
import { pageIds } from 'utilities/constants';
import Loader from 'components/loaderComponent';
import { useGetFrontAndBackDots } from 'app/my-skin/components/BodySymtomsLocation/hooks';
import { useMemo } from 'react';
import {
  Dot,
  SvgImageTag,
} from 'app/my-skin/components/BodySymtomsLocation/interfaces';

const PreWorkSection: React.FC<PreWorkSectionProps> = ({
  locale,
  appointmentData,
  onMediaLocationClick,
}) => {
  const { data: preworkLocales, loading: preworkLocalesLoading } = useGetPage({
    locale: 'en',
    pageId: pageIds.APPOINTMENT_PREWORK,
  });
  const { backDots: allBackDots, frontDots: allFrontDots } =
    useGetFrontAndBackDots();

  const [backDots, backDotsLocations] = useMemo<[Dot[], string[]]>(() => {
    if (!appointmentData?.symptomsLocation?.length || !allBackDots?.length)
      return [[], []];
    const backDotsLocationSet = new Set(appointmentData?.symptomsLocation);
    const backDots = allBackDots
      .filter((dot) => dot.location && backDotsLocationSet.has(dot.location))
      .map((dot) => ({ ...dot, selected: true }));
    return [backDots, backDots.map((dot) => dot.location as string)];
  }, [appointmentData, allBackDots]);
  const [frontDots, frontDotsLocations] = useMemo<[Dot[], string[]]>(() => {
    if (!appointmentData?.symptomsLocation?.length || !allFrontDots?.length)
      return [[], []];
    const frontDotsLocationSet = new Set(appointmentData?.symptomsLocation);
    const frontDots = allFrontDots
      .filter((dot) => dot.location && frontDotsLocationSet.has(dot.location))
      .map((dot) => ({ ...dot, selected: true }));
    return [frontDots, frontDots.map((dot) => dot.location as string)];
  }, [appointmentData, allFrontDots]);

  if (preworkLocalesLoading) return <Loader />;

  const onDotSelected = (dotId: number) => {
    const foundDot = [...frontDots, ...backDots].find(
      (dot) => dot.id === dotId && dot.selected && dot.location,
    );
    if (foundDot && foundDot.location) onMediaLocationClick(foundDot.location);
  };

  return (
    <div>
      <div className="mb-5">
        <div>
          <div className="font-semibold text-left">
            {locale.subjectOfAppointment}
          </div>
          <div className="text-left text-gray-900">
            <span className="text-gray-500">
              {appointmentData?.subjectOfAppointment}
            </span>
          </div>
        </div>
      </div>

      <div className="flex flex-col desktop:flex-row">
        {/* Questionnaire Section */}
        <div className="w-full desktop:w-1/2">
          <div className="rounded mb-5">
            <div className="font-semibold text-left text-gray-800">
              {preworkLocales.symptomTypes}
            </div>
            <ul className="text-left text-gray-500 list-disc pl-5">
              {appointmentData?.appointmentNotes?.symptomsTypes?.map(
                (symptom) => (
                  <li key={symptom}>{symptom}</li>
                ),
              )}
            </ul>
          </div>

          <div className="rounded mb-5">
            <div className="font-semibold text-left text-gray-800">
              {preworkLocales.howLongTitle}
            </div>
            <ul className="text-left text-gray-500 list-disc pl-5">
              <li>
                {appointmentData?.appointmentNotes?.howLongQuestion || ''}
              </li>
            </ul>
          </div>

          <div className="rounded mb-5">
            <div className="font-semibold text-left text-gray-800">
              {preworkLocales.severityQuestion}
            </div>
            <ul className="text-left text-gray-500 list-disc pl-5">
              <li>
                {appointmentData?.appointmentNotes?.severityQuestion || ''}
              </li>
            </ul>
          </div>

          <div className="font-semibold mb-1 text-left text-gray-800">
            {preworkLocales.whereQuestion}
          </div>
          <div className="rounded mb-5">
            <div className="font-semibold text-left text-gray-800">
              {preworkLocales.front}
            </div>
            <ul className="text-left text-gray-500 list-disc pl-5">
              {frontDotsLocations?.map((symptom) => (
                <li
                  key={symptom}
                  className="cursor-pointer"
                  onClick={() => onMediaLocationClick(symptom)}
                >
                  {symptom}
                </li>
              ))}
            </ul>
            <div className="font-semibold text-left text-gray-800">
              {preworkLocales.back}
            </div>
            <ul className="text-left text-gray-500 list-disc pl-5">
              {backDotsLocations?.map((symptom) => (
                <li
                  key={symptom}
                  className="cursor-pointer"
                  onClick={() => onMediaLocationClick(symptom)}
                >
                  {symptom}
                </li>
              ))}
            </ul>
          </div>

          <div className="rounded mb-5">
            <div className="font-semibold text-left text-gray-800">
              {preworkLocales.anythingElseQuestion}
            </div>
            <ul className="text-left text-gray-500 list-disc pl-5">
              <li>
                {appointmentData?.appointmentNotes?.anythingElseQuestion || ''}
              </li>
            </ul>
          </div>
        </div>

        {/* Human body must go after "where are you experiencing these symptoms?"
            this will be updated once the data is returned from the backend */}
        <div className="w-full desktop:w-1/2 flex flex-col desktop:flex-row justify-center desktop:justify-end desktop:items-start">
          <DotPlacer
            type={SvgImageTag.BODY_FRONT_SVG}
            dots={frontDots}
            onDotSelected={onDotSelected}
            hideNotSelectedDots
          />
          <DotPlacer
            type={SvgImageTag.BODY_BACK_SVG}
            dots={backDots}
            onDotSelected={onDotSelected}
            hideNotSelectedDots
          />
        </div>
      </div>
      <div>
        <div className="rounded mb-5">
          <div className="font-semibold text-left text-gray-800">
            {
              preworkLocales.additionalInformationShared
                .additionalInformationShared
            }
            :
          </div>
          <ul className="text-left text-gray-500 list-disc pl-5">
            <li>
              {preworkLocales.additionalInformationShared.patientProfileInfo}
            </li>
            <li>
              {preworkLocales.additionalInformationShared.healthQuestionnaire}
            </li>
            <li>{preworkLocales.additionalInformationShared.other}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PreWorkSection;
