import { ReactComponent as VideoIcon } from 'assets/icons/video-icon.svg';
import { ReactComponent as ChatIcon } from 'assets/icons/chat.svg';
import { ReactComponent as MessageIcon } from 'assets/icons/message.svg';
import {
  ConsultationType,
  FromProviderData,
  FromProviderDataWidgetLocale,
} from './interfaces';
import { CalendarIcon } from 'assets/icons/CalendarIcon';
import { WidgetContainer } from './WidgetContainer';
import IconComponent from 'components/iconComponent';
import AvatarComponent from 'components/avatarComponent';
import { useGetSignedUrlFromStorage } from 'hooks/useGetSignedUrlFromStorage';

interface FromProviderDataWidgetProps {
  altImage: string;
  locale: FromProviderDataWidgetLocale;
  fromProviderData: FromProviderData;
  backgroundColor?: string;
}

const getConsultationTypeIcon = (consultationType: ConsultationType) => {
  switch (consultationType) {
    case ConsultationType.MESSAGING:
      return MessageIcon;
    case ConsultationType.CHAT:
      return ChatIcon;
    default:
      return VideoIcon;
  }
};

const getConsultationTypeLocale = (
  consultationType: ConsultationType,
  locale: FromProviderDataWidgetLocale,
) => {
  switch (consultationType) {
    case ConsultationType.MESSAGING:
    case ConsultationType.CHAT:
      return locale.chatAppointment;
    default:
      return locale.videoAppointment;
  }
};

export const FromProviderDataWidget: React.FC<FromProviderDataWidgetProps> = ({
  locale,
  fromProviderData,
  backgroundColor = 'bg-white',
}) => {
  const signedProfileUrl = useGetSignedUrlFromStorage(
    fromProviderData.providerPicture ?? '',
    fromProviderData.providerId ?? '',
  );

  return (
    <WidgetContainer
      title={locale.fromProviderData}
      titlesInBold={true}
      backgroundColor={backgroundColor}
    >
      <div className="flex flex-col gap-2">
        <div className="flex flex-row gap-2 items-center">
          <div className="w-6">
            <AvatarComponent imageUrl={signedProfileUrl} size="xs" />
          </div>
          <p className="font-bold underline text-base">
            {fromProviderData.providerName}
          </p>
        </div>
        <div className="flex flex-row gap-2 items-center">
          <div className="flex justify-center w-6">
            <IconComponent
              height="24"
              fill="fill-med-gray"
              icon={getConsultationTypeIcon(fromProviderData.consultationType)}
              width="24"
            />
          </div>
          <p className="text-charcoal-gray text-base">
            {getConsultationTypeLocale(
              fromProviderData.consultationType,
              locale,
            )}
          </p>
        </div>
        <div className="flex flex-row gap-2 items-center">
          <div className="flex justify-center w-6">
            <IconComponent
              height="24"
              fill="fill-med-gray"
              icon={CalendarIcon}
              width="24"
            />
          </div>
          <p className="text-charcoal-gray text-base">
            {fromProviderData.providerDate}
          </p>
        </div>
      </div>
    </WidgetContainer>
  );
};
