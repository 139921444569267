import { AuthContext } from 'auth/context/AuthContext';
import { useGetDnaVisitIframeSecretMutation } from 'graphql/generated/hasura';
import { useContext, useEffect, useRef } from 'react';

const DNAVisitIFrame = () => {
  const isFirstRender = useRef(false);
  const { user: loggedUser } = useContext(AuthContext);

  const [getDnaVisitIframeSecret, { data, loading }] =
    useGetDnaVisitIframeSecretMutation({
      variables: { userEmail: loggedUser?.email || '' },
    });

  useEffect(() => {
    if (!isFirstRender.current) {
      isFirstRender.current = true;
      getDnaVisitIframeSecret();
    }
  }, [isFirstRender, getDnaVisitIframeSecret]);

  if (!data || loading) return null;

  return (
    <iframe
      src={`https://www.dnavisit.com/hosting/hostedchatmain?t=${data.getDNAVisitIframeSecret.dnaVisitIframeSecret}`}
      className="w-full h-full"
      referrerPolicy="no-referrer"
    />
  );
};

export default DNAVisitIFrame;
