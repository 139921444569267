import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ModalButtonProps } from 'components/modal/modal.interfaces';
import { useGetLegalDocument } from 'hooks/legalDocuments/useGetLegalDocument';
import { useIsMobile } from 'hooks/useIsMobile';
import { useGetPage } from 'hooks/useGetPage';
import {
  genericActionsIds,
  legalDocumentsIds,
  pageIds,
} from 'utilities/constants';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import { TEST_KITS_MANAGEMENT_QUESTIONNAIRE_FULL } from 'utilities/routes';
import { useAcceptLegalDocument } from 'hooks/legalDocuments/useAcceptLegalDocument';
import { LegalDocument } from 'graphql/generated/strapi';
import { TestKitData } from 'components/dynamicQuestionnaire/interfaces/dynamicQuestionnaire.interface';
import { QuestionnaireResponseInterface } from 'components/dynamicQuestionnaire/interfaces/dynamicQuestionnaireResponse.interface';
import Modal from 'components/modal/modalComponent';
import InputComponent from 'components/inputComponent';
import { Test_Types_Enum } from 'graphql/generated/hasura';
import { sanitize } from '../../../utilities/sanitizer';

interface DnaVisitTermsOfUseProps {
  onSubmit: (
    testKitData: TestKitData,
    questionnaireId: number,
    persistentData: QuestionnaireResponseInterface[],
    locale: Record<string, string>,
    state: object,
  ) => Promise<void>;
  handleModalCloseRedirection?: string;
}

export const DnaVisitTermsOfUse: React.FC<DnaVisitTermsOfUseProps> = ({
  onSubmit,
  handleModalCloseRedirection = TEST_KITS_MANAGEMENT_QUESTIONNAIRE_FULL,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useIsMobile();
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.REGISTER_TEST_KIT,
  });
  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.CANCEL, genericActionsIds.SUBMIT],
    });
  const {
    legalDocument: labSampleResultsPrivacyStatement,
    loading: loadingLabSampleResultsPrivacyStatement,
  } = useGetLegalDocument({
    legalDocumentId: legalDocumentsIds.LAB_SAMPLE_RESULTS_PRIVACY_STATEMENT,
  });
  const {
    legalDocument: dnaVisitRecordConsent,
    loading: loadingDnaVisitRecordConsent,
  } = useGetLegalDocument({
    legalDocumentId: legalDocumentsIds.DNA_VISIT_RECORD_CONSENT,
  });

  const {
    legalDocument: generalTestRecordConsent,
    loading: loadingGeneralTestRecordConsent,
  } = useGetLegalDocument({
    legalDocumentId: legalDocumentsIds.GENERAL_TEST_RECORD_CONSENT,
  });

  const [
    isLabSampleResultsPrivacyStatementChecked,
    setLabSampleResultsPrivacyStatementChecked,
  ] = useState(false);
  const [isTestRecordConsentChecked, setTestRecordConsentChecked] =
    useState(false);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { acceptLegalDocument } = useAcceptLegalDocument();

  const handleModalClose = () => {
    navigate(handleModalCloseRedirection, {
      state: location.state,
    });
  };

  const testType =
    location.state?.testKitData?.testType || Test_Types_Enum.DnaSkin;
  const isDNATestType = [
    Test_Types_Enum.DnaSkin,
    Test_Types_Enum.DnaVitamin,
  ].includes(testType);

  const handleLabSampleResultsPrivacyStatementCheckbox = (checked: boolean) => {
    setLabSampleResultsPrivacyStatementChecked(checked);
  };

  const handleTestRecordConsentCheckbox = (checked: boolean) => {
    setTestRecordConsentChecked(checked);
  };

  const handleOnSubmit = async () => {
    setIsSubmitting(true);
    await acceptLegalDocument(
      labSampleResultsPrivacyStatement as LegalDocument,
    );
    const consentDocument = isDNATestType
      ? dnaVisitRecordConsent
      : generalTestRecordConsent;
    await acceptLegalDocument(consentDocument as LegalDocument);
    await onSubmit(
      location.state.testKitData,
      location.state.id,
      location.state.persistentData,
      location.state.locale,
      location.state,
    );
    setIsSubmitting(false);
  };

  const Buttons: ModalButtonProps[] = [
    {
      label: genericAction?.[genericActionsIds.SUBMIT]?.submit,
      disabled:
        !isLabSampleResultsPrivacyStatementChecked ||
        !isTestRecordConsentChecked ||
        isSubmitting,
      onClick: handleOnSubmit,
    },
    {
      label: genericAction?.[genericActionsIds.CANCEL]?.cancel,
      disabled: isSubmitting,
      type: 'underline',
      onClick: handleModalClose,
    },
  ];

  if (
    loading ||
    !locale ||
    genericActionLoading ||
    !genericAction ||
    !dnaVisitRecordConsent?.legalDocumentId ||
    !labSampleResultsPrivacyStatement?.legalDocumentId ||
    !generalTestRecordConsent?.legalDocumentId ||
    loadingLabSampleResultsPrivacyStatement ||
    loadingDnaVisitRecordConsent ||
    loadingGeneralTestRecordConsent
  )
    return null;

  const consentDocument = isDNATestType
    ? dnaVisitRecordConsent
    : generalTestRecordConsent;

  return (
    <Modal
      isOpen={true}
      title={
        isDNATestType
          ? locale?.dnaVisitTermsOfUse
          : locale?.generalVisitTermsOfUse
      }
      buttons={Buttons}
      onClose={handleModalClose}
      disableWebClose
      disableMobileClose
    >
      <div className="flex flex-wrap gap-5">
        <p className="text-h4 font-semibold text-dark-gray">
          {labSampleResultsPrivacyStatement.legalDocumentName}
        </p>
        <div
          dangerouslySetInnerHTML={{
            __html: sanitize(
              isMobile
                ? labSampleResultsPrivacyStatement.contentMobile
                : labSampleResultsPrivacyStatement.contentWeb,
            ),
          }}
        />

        <p className="text-h4 font-semibold text-dark-gray">
          {consentDocument.legalDocumentName}
        </p>
        <div
          dangerouslySetInnerHTML={{
            __html: sanitize(
              isMobile
                ? consentDocument.contentMobile
                : consentDocument.contentWeb,
            ),
          }}
        />

        <div className="flex">
          <InputComponent
            type="checkbox"
            name="labSampleResultsPrivacyStatement"
            checkboxProps={{
              checked: isLabSampleResultsPrivacyStatementChecked,
              onCheckboxChange: handleLabSampleResultsPrivacyStatementCheckbox,
            }}
          />
          <label
            className="ml-3 group"
            htmlFor="labSampleResultsPrivacyStatement"
          >
            <span className="group-hover:cursor-pointer">
              {locale?.checkboxSubmitLabel}{' '}
              {labSampleResultsPrivacyStatement.legalDocumentName}
            </span>
          </label>
        </div>

        <div className="flex">
          <InputComponent
            type="checkbox"
            name="testRecordConsent"
            checkboxProps={{
              checked: isTestRecordConsentChecked,
              onCheckboxChange: handleTestRecordConsentCheckbox,
            }}
          />
          <label className="ml-3 group" htmlFor="testRecordConsent">
            <span className="group-hover:cursor-pointer">
              {locale?.checkboxSubmitLabel} {consentDocument.legalDocumentName}
            </span>
          </label>
        </div>
      </div>
    </Modal>
  );
};
