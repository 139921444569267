import ButtonComponent from 'components/button/buttonComponent';
import { ReactComponent as ChevronDownIcon } from 'assets/icons/chevron-down.svg';
import { ReactComponent as ChevronUpIcon } from 'assets/icons/chevron-up-fix.svg';
import { useMemo, useState } from 'react';
import Input from 'components/inputComponent';
import { ReactComponent as MagnifierIcon } from 'assets/icons/magnifier.svg';
import DatePicker from 'components/datePicker/DatePicker';
import { HandleAppointmentFilterChangeEvent } from 'app/my-appointments/interfaces';
import { useGetComponent } from 'hooks/useGetComponent';
import { componentIds } from 'utilities/constants';
import {
  AppointmentsHistoryPatientFilterProps,
  SelectWithIdInfo,
} from 'app/appointments/components/interfaces';
import {
  AppointmentTypeEnum,
  useGetFhirAvailableProvidersNamesQuery,
} from 'graphql/generated/remote-schema-hasura';

import { useIsMobile } from 'hooks/useIsMobile';

export const DNAVisitTypeValue = 'DNAVisit';

export const MyVisitSummariesHistoryFilter: React.FC<
  AppointmentsHistoryPatientFilterProps
> = ({ filter, onFilter, setFilter }) => {
  const [availableProviders, setAvailableProviders] = useState<
    SelectWithIdInfo[]
  >([]);

  useGetFhirAvailableProvidersNamesQuery({
    fetchPolicy: 'cache-first',
    onCompleted: (data) => {
      const repeatedProvidesMap: Record<string, number> = {};
      const providers = data.getFHIRProviders.providers.map<SelectWithIdInfo>(
        (provider) => {
          const initialName = `${provider.SENSITIVE_firstname} ${provider.SENSITIVE_lastname}`;
          let name = initialName;
          if (repeatedProvidesMap[initialName]) {
            name = `${name} ${repeatedProvidesMap[name]}`;
          }
          repeatedProvidesMap[initialName] =
            (repeatedProvidesMap[initialName] || 0) + 1;
          return {
            id: provider.id,
            name,
          };
        },
      );
      setAvailableProviders(providers);
    },
  });

  const isMobile = useIsMobile();

  const [isExpanded, setIsExpanded] = useState(false);
  const ArrowIcon = isExpanded ? ChevronUpIcon : ChevronDownIcon;
  const handleExpandIconClick = () => {
    setIsExpanded((isExpanded) => !isExpanded);
  };
  const { data: locale, loading: localeLoading } = useGetComponent({
    locale: 'en',
    componentId: componentIds.APPOINTMENTS_PATIENT_FILTER,
  });

  const [byTypeEntries, localeToValueMapByType] = useMemo(() => {
    if (!locale) return [];
    const localeToValueMap: Record<string, string> = {
      [locale[DNAVisitTypeValue]]: DNAVisitTypeValue,
    };
    const byTypeEntries = Object.values(AppointmentTypeEnum).map((value) => {
      const text = locale[value];
      localeToValueMap[text] = value;
      return text;
    });
    byTypeEntries.unshift(locale[DNAVisitTypeValue]);
    byTypeEntries.unshift(locale.all);
    return [byTypeEntries, localeToValueMap];
  }, [locale]);

  const [providerEntries, providersNameIdMap, providersIdNameMap] =
    useMemo(() => {
      const providersNameIdMap = availableProviders.reduce<
        Record<string, string>
      >((acc, patient) => {
        acc[patient.name] = patient.id;
        return acc;
      }, {});
      const providersIdNameMap = availableProviders.reduce<
        Record<string, string>
      >((acc, patient) => {
        acc[patient.id] = patient.name;
        return acc;
      }, {});
      const providerEntries = availableProviders.map((patient) => patient.name);
      locale?.all && providerEntries.unshift(locale.all);
      return [providerEntries, providersNameIdMap, providersIdNameMap];
    }, [locale?.all, availableProviders]);

  const getValueFromLocale = (
    locale: string,
    name: string,
  ): string | number => {
    if (!localeToValueMapByType) {
      return locale;
    }
    const localeToValueMap: Record<string, Record<string, string | number>> = {
      type: localeToValueMapByType,
      provider: providersNameIdMap,
    };
    return localeToValueMap[name] ? localeToValueMap[name][locale] : locale;
  };

  const handleFilterChange = (
    e: HandleAppointmentFilterChangeEvent,
    name?: string,
  ) => {
    let currentName: string = name || '';
    if (!currentName) {
      if (!e.target.name) {
        throw new Error('No name provided for the filter');
      }
      currentName = e.target.name;
    }
    const value = getValueFromLocale(e.target.value, currentName);
    setFilter &&
      setFilter((oldFilter) => {
        return {
          ...oldFilter,
          [currentName]: value,
        };
      });
  };

  if (!locale || localeLoading || isMobile) {
    return null;
  }

  return (
    <div className="hidden desktop:flex flex-col items-center px-3 pt-2 pb-[5px] desktop:p-3 gap-3 bg-base-content rounded-10 w-full">
      <div className="flex flex-col w-full">
        <div className="flex flex-row w-full items-center justify-between p-0 gap-2.5">
          <div className="flex flex-row items-end gap-[7px]">
            <p className="flex text-base text-dark-gray font-semibold">
              {locale.findAndFilter}
            </p>
          </div>
          <div>
            <ButtonComponent
              type="underline"
              iconPosition="right"
              Icon={ArrowIcon}
              fill="black"
              stroke="black"
              onClick={handleExpandIconClick}
            />
          </div>
        </div>
      </div>
      {isExpanded && (
        <>
          <hr className="flex flex-row w-full items-center h-px bg-black-blur" />
          <div className="flex flex-row w-full gap-1 items-stretch justify-between">
            <div className="flex flex-col items-start gap-3 justify-around">
              <p className="text-sm font-medium">{locale.find}</p>
              <p className="text-sm font-medium">{locale.byType}</p>
            </div>
            <div className="flex flex-col items-start gap-3 flex-1">
              <div className="flex flex-row w-full gap-8">
                <div className="flex-1 h-full">
                  <Input
                    type="text"
                    decorator={<MagnifierIcon />}
                    decoratorLeft
                    noMarginBottom
                    value={filter?.find}
                    name="find"
                    onChange={handleFilterChange}
                    placeholder={locale.findPlaceholder}
                  />
                </div>
                <div className="flex flex-row gap-2 items-center">
                  <p className="text-sm font-medium text-right pl-3 pr-1">
                    {locale.from}
                  </p>
                  <div>
                    <DatePicker
                      name="from"
                      onChange={handleFilterChange}
                      fullWidthDesktop
                      value={filter?.from}
                    />
                  </div>
                  <p className="text-sm font-medium text-right pl-3 pr-1">
                    {locale.to}
                  </p>
                  <div className="flex-1">
                    <DatePicker
                      name="to"
                      onChange={handleFilterChange}
                      fullWidthDesktop
                      value={filter.to}
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-row w-full items-center justify-between">
                <div className="flex-[2]">
                  <Input
                    type="select"
                    name="type"
                    value={filter?.type && locale[filter?.type]}
                    selectInputProps={{
                      selectValues: byTypeEntries,
                      fullWidth: true,
                      onSelectChange: handleFilterChange,
                    }}
                  />
                </div>
                <p className="text-sm font-medium text-right pl-3 pr-1">
                  {locale.byProvider}
                </p>
                <div className="flex-[2]">
                  <Input
                    type="select"
                    value={
                      (filter.provider &&
                        providersIdNameMap[filter.provider]) ||
                      locale.all
                    }
                    name="provider"
                    selectInputProps={{
                      selectValues: providerEntries,
                      fullWidth: true,
                      onSelectChange: handleFilterChange,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <hr className="flex flex-row w-full items-center h-px bg-black-blur" />
          <ButtonComponent
            onClick={() => onFilter && onFilter()}
            className="self-end bg-base-content my-2"
            type="outlined"
          >
            {locale.updateResults}
          </ButtonComponent>
        </>
      )}
    </div>
  );
};
