import React, { ReactElement, useState } from 'react';
import { ReactComponent as ChevronLeftIcon } from 'assets/icons/chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from 'assets/icons/chevron-right.svg';
import CarouselDots from 'components/carouselDotsComponent';
import CarouselArrow from 'components/carouselArrow';

interface WeatherCarouselProps {
  children: React.ReactNode;
}

const WeatherCarousel: React.FC<WeatherCarouselProps> = ({ children }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const incrementScanIndex = () => {
    setCurrentIndex((index) => (index + 1) % React.Children.count(children));
  };

  const decrementScanIndex = () => {
    setCurrentIndex(
      (index) =>
        (index - 1 + React.Children.count(children)) %
        React.Children.count(children),
    );
  };

  return (
    <>
      <div className="flex flex-row w-full items-center justify-between p-0 gap-[15px]">
        {React.Children.count(children) > 1 && (
          <CarouselArrow onClick={decrementScanIndex} icon={ChevronLeftIcon} />
        )}

        {React.Children.map(
          children,
          (child, index) =>
            index === currentIndex &&
            React.cloneElement(child as ReactElement, { key: index }),
        )}

        {React.Children.count(children) > 1 && (
          <CarouselArrow onClick={incrementScanIndex} icon={ChevronRightIcon} />
        )}
      </div>
      {React.Children.count(children) > 1 && (
        <div className="flex flex-row items-center justify-center w-full">
          <CarouselDots
            carouselLength={React.Children.count(children)}
            selectedDotIndex={currentIndex}
          />
        </div>
      )}
    </>
  );
};

export default WeatherCarousel;
