import { useGetDoseSpotPractitionerUrlMutation } from 'graphql/generated/hasura';

import React, { useContext, useEffect, useState } from 'react';
import { AuthContext, AuthContextType } from 'auth/context/AuthContext';

const DosespotIFrame: React.FC<{
  showNotifications?: boolean;
  encounterId?: string;
  patientId?: string;
}> = ({ showNotifications, encounterId, patientId }) => {
  const { user: loggedUser } = useContext<AuthContextType>(AuthContext);
  const [urlError, setError] = useState<string | null>(null);
  const [url, setUrl] = useState<string | null>(null);
  const [getDoseSpotPractitionerUrlMutation, { loading }] =
    useGetDoseSpotPractitionerUrlMutation({
      variables: {
        providerId: loggedUser?.uuid as string,
        encounterId,
        patientId,
        showNotifications: showNotifications ?? false,
      },
      fetchPolicy: 'network-only',
      onError: (e) => setError(e.message),
      onCompleted: (data) => setUrl(data.GetDoseSpotPractitionerURL?.url || ''),
    });

  useEffect(() => {
    getDoseSpotPractitionerUrlMutation();
  }, [getDoseSpotPractitionerUrlMutation]);

  if (loading || url === null) return null;

  return (
    <>
      {urlError !== null ? (
        <p>{urlError}</p>
      ) : (
        <iframe
          src={url as string}
          className="w-full h-full"
          referrerPolicy="no-referrer"
        />
      )}
    </>
  );
};

export default DosespotIFrame;
