export enum TextCapitalization {
  UPPERCASE = 'uppercase',
  CAPITALIZE = 'capitalize',
  NORMALCASE = 'normal-case',
}

export interface Tab {
  name: string;
  url: string;
  onClick?: () => void;
  referringPage?: string;
  customState?: Record<string, unknown>;
}

export interface TabsProps {
  tabs: Tab[];
  isNotification?: boolean;
  textCapitalization?: TextCapitalization;
}

export interface MobileTabsProps {
  tabs: Tab[];
  selectedTab: string;
  textCapitalization: TextCapitalization;
}
