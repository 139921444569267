import { useLocation, useNavigate } from 'react-router-dom';

export const usePdfDownload = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const downloadPdf = (reportId: string, reportName?: string) => {
    navigate(`${location.pathname}?download-warning=true`, {
      state: { reportId: reportId, reportName },
    });
  };

  const downloadPdfWithURI = (uri: string, reportName?: string) => {
    navigate(`${location.pathname}?download-warning=true`, {
      state: { reportURI: uri, reportName },
    });
  };

  return {
    downloadPdf,
    downloadPdfWithURI,
  };
};
