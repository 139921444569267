import { useGetChannelSignalsSubscription } from 'graphql/generated/hasura';
import { useRef } from 'react';

export enum SIGNAL_CHANNELS {
  APPOINTMENTS = 'APPOINTMENTS',
  MEDICATIONS = 'MEDICATIONS',
  PATIENTS_OF_PROVIDERS = 'PATIENTS_OF_PROVIDERS',
  SHARED_MEDIA = 'SHARED_MEDIA',
  DERMSCORE = 'DERMSCORE',
  TESTKIT = 'TESTKIT',
}

export const useChannelSignal = (
  onSignal: (first: boolean) => void,
  channel: SIGNAL_CHANNELS,
  key?: string,
) => {
  const firstRef = useRef(true);

  useGetChannelSignalsSubscription({
    shouldResubscribe: true,
    variables: { channel, key: key ?? 'GLOBAL' },
    onData: () => {
      onSignal(firstRef.current);
      firstRef.current = false;
    },
    onError: (error) =>
      console.log('error:', error, error.message, error.name, error.extraInfo),
  });
};
