import { useGetPage } from 'hooks/useGetPage';
import { componentIds, genericActionsIds, pageIds } from 'utilities/constants';
import { ReactComponent as SearchIcon } from 'assets/icons/searchIconBlue.svg';
import ButtonComponent from 'components/button/buttonComponent';
import InputComponent from 'components/inputComponent';
import { ProviderSideBarFilter } from 'app/providers-view-selection/components/ProviderSideBarFilter';
import ProviderStack from 'app/my-appointments/components/select-a-provider/ProviderStack';
import React, { useRef, useState } from 'react';
import { useGetFhirProvidersQuery } from 'graphql/generated/remote-schema-hasura';

import { ProviderOutput, parseServiceType } from './ProviderCardProps';
import Loader from 'components/loaderComponent';
import { ReactComponent as ArrowLoadMoreIcon } from 'assets/icons/arrowLoadMore.svg';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import { useGetComponent } from 'hooks/useGetComponent';
import { SelectAProviderTabProp } from 'app/my-appointments/pages/SelectAProvider';
import { FindAProviderSelectionFilterType } from 'app/providers-view-selection/components/ProviderSideBarFilterInterfaces';
import { extractUUIDFromResURL } from 'utilities/functions';

type FindAProviderFilterType = Record<
  string,
  string | number | string[] | undefined
>;

const findAProviderBatchSize = 10;

const initialFilter = {
  limit: findAProviderBatchSize,
  offset: 0,
};

const FindAProvider: React.FC<SelectAProviderTabProp> = ({
  onProviderSelect,
}) => {
  const { data: locale, loading: localeLoading } = useGetPage({
    locale: 'en',
    pageId: pageIds.PROVIDERS_SELECTION,
  });
  const { data: genericActions, loading: actionsLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.SEE_MORE, genericActionsIds.VIEW_ALL],
    });
  const { loading: selectAProviderLocaleLoading, data: selectAProviderLocale } =
    useGetPage({
      locale: 'en',
      pageId: pageIds.SELECT_A_PROVIDER,
    });
  const { loading: componentLocaleLoading } = useGetComponent({
    locale: 'en',
    componentId: componentIds.AVAILABLE_SPECIALTIES_AND_LANGUAGES,
  });
  const [total, setTotal] = useState<number>(0);
  const isFirstLoad = useRef(true);
  const [currentFilter, setCurrentFilter] = useState<FindAProviderFilterType>(
    {},
  );
  const [filter, setFilter] = useState<FindAProviderFilterType>(initialFilter);
  const [providers, setProviders] = useState<ProviderOutput[]>([]);
  const { loading: providersLoading } = useGetFhirProvidersQuery({
    variables: {
      ...filter,
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const providers = data.getFHIRProviders.providers.map<ProviderOutput>(
        (provider) => {
          const pictureId = extractUUIDFromResURL(
            provider?.SENSITIVE_profile_picture_id ?? '',
          );
          return {
            ...provider,
            isFavorite: false,
            accepted_appointment_types: provider.serviceOfferings.map(
              (serviceOffering) => ({
                price: serviceOffering.price,
                serviceType: parseServiceType(serviceOffering.serviceType),
              }),
            ),
            SENSITIVE_profile_picture_id: pictureId,
          } as ProviderOutput;
        },
      );
      setProviders(providers);
      data.getFHIRProviders.total && setTotal(data.getFHIRProviders.total);
    },
  });

  const onSearchClick = () => {
    setFilter((prev) => ({
      ...currentFilter,
      ...initialFilter,
      limit: prev.limit,
    }));
  };

  const onSeeMoreClick = () => {
    setFilter((prev) => ({
      ...prev,
      limit: ((prev.limit as number) || 0) + findAProviderBatchSize,
    }));
  };

  if (
    localeLoading ||
    selectAProviderLocaleLoading ||
    actionsLoading ||
    componentLocaleLoading ||
    (providersLoading && isFirstLoad.current)
  )
    return <Loader />;
  const canSeeMore = total > providers.length;
  const noProviderFound = providers.length === 0 && !providersLoading;
  isFirstLoad.current = false;
  return (
    <div
      className={`flex flex-col w-full px-5 pt-5 pb-[30px] desktop:p-[30px] gap-8 bg-white rounded-10 relative ${
        providersLoading ? 'pointer-events-none' : ''
      }}`}
    >
      {providersLoading && (
        <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center">
          <Loader />
        </div>
      )}
      <div
        className={`flex flex-col desktop:flex-row w-full gap-5 items-center justify-between ${
          providersLoading ? 'opacity-50' : ''
        }`}
      >
        <div className="flex flex-col desktop:flex-row gap-4 desktop:items-center w-full">
          <p className="text-dark-gray font-semibold text-h6">
            {locale?.findAProvider?.filters?.search}
          </p>
          <div className="w-full">
            <InputComponent
              noMarginBottom
              type="text"
              value={(currentFilter?.search || '') as string}
              onChange={(e) =>
                setCurrentFilter((prev) => ({
                  ...prev,
                  search: e.target.value,
                }))
              }
              name="search"
              maxLengthValue={35}
            />
          </div>
        </div>
        <div className="flex flex-row w-full desktop:w-[15%] items-center justify-center">
          <ButtonComponent
            type="outlined"
            Icon={SearchIcon}
            iconPosition="left"
            iconWidth="w-[24px]"
            iconHeight="h-[25px]"
            onClick={onSearchClick}
          >
            {locale?.findAProvider?.filters?.search}
          </ButtonComponent>
        </div>
      </div>
      <hr
        className={`flex flex-row w-full items-center h-px bg-black-blur ${
          providersLoading ? 'opacity-50' : ''
        }`}
      />
      <div
        className={`flex flex-col desktop:flex-row w-full gap-[30px] ${
          providersLoading ? 'opacity-50' : ''
        }`}
      >
        <div className="flex w-full desktop:w-1/4">
          <ProviderSideBarFilter
            selectedFilters={currentFilter as FindAProviderSelectionFilterType}
            setSelectedFilters={
              setCurrentFilter as React.Dispatch<
                React.SetStateAction<FindAProviderSelectionFilterType>
              >
            }
          />
        </div>
        {noProviderFound ? (
          <div>
            <p className="text-med-gray font-semibold text-h6">
              {selectAProviderLocale?.noProvidersFound}
            </p>
          </div>
        ) : (
          <div className="flex flex-col w-full desktop:w-3/4 gap-4">
            <div className="flex flex-col w-full border-t border-gray-opacity-15">
              {providers.map((provider) => (
                <ProviderStack
                  provider={provider}
                  key={provider.id}
                  isFirstItem
                  onSelect={onProviderSelect}
                />
              ))}
            </div>
            {canSeeMore && (
              <div className="flex items-center">
                <ButtonComponent
                  type="underline"
                  iconPosition="right"
                  Icon={ArrowLoadMoreIcon}
                  onClick={onSeeMoreClick}
                >
                  {genericActions?.[genericActionsIds.SEE_MORE].seeMore}
                </ButtonComponent>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default FindAProvider;
