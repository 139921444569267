import { AppointmentTypeEnum } from 'graphql/generated/remote-schema-hasura';

export interface SharedMedia {
  id: string;
  img: string;
  provider?: string;
  date: Date;
  extraInformation?: string;
  appointmentId?: string;
  dermscoreId?: string;
  bodySite?: string;
  description?: string;
}

export enum MediaType {
  APPOINTMENT_MEDIA = 'appointmentMedia',
  DERMSCORE_MEDIA = 'dermscoreMedia',
}

export interface SharedMediaLocale {
  of: string;
  showing: string;
  viewAllButton: string;
  sharedMediaTitle: string;
  sharedMediaAltImage: string;
}

export interface SharedMediaProps {
  locale: Record<string, string>;
  sharedMedia: SharedMedia[];
  total: number;
}

export interface SharedMediaModalLocationState {
  mediaSrc: string;
  mediaDate: Date;
  providerName?: string;
  appointmentType?: AppointmentTypeEnum;
  bodySite?: string;
  appointmentCodexId?: string;
  mediaTitle?: string;
  description?: string;
}

export interface SharedMediaModalProps {
  locale?: Record<string, string>;
}

export enum MediaIdentifiersTypes {
  IMAGE = 'image',
  DERMSCORE_ID = 'dermscore_id',
  APPOINTMENT_ID = 'appointment_id',
}
