import { Link } from 'react-router-dom';
import { ListRecord } from './interfaces';
import { WidgetContainer } from './WidgetContainer';

interface ListWidgetProps {
  title: string;
  list: ListRecord[];
  backgroundColor?: string;
  target?: string;
}

export const ListWidget: React.FC<ListWidgetProps> = ({
  title,
  list,
  backgroundColor = 'bg-white',
  target = undefined,
}) => {
  return (
    <WidgetContainer
      title={title}
      titlesInBold={true}
      backgroundColor={backgroundColor}
    >
      <ul className="list-disc flex flex-col ml-6 text-clc-blue">
        {list.map((record: ListRecord) => (
          <li key={record.id}>
            {record.link ? (
              <Link
                target={target}
                className="underline font-bold"
                to={record.link}
              >
                {record.text}
              </Link>
            ) : (
              <p
                className="underline font-bold text-clc-blue cursor-pointer"
                onClick={record.onClick}
              >
                {record.text}
              </p>
            )}
          </li>
        ))}
      </ul>
    </WidgetContainer>
  );
};
