import React from 'react';
import { LegalDocument } from 'graphql/generated/strapi';
import { Link } from 'react-router-dom';

interface LegalTermsProps {
  linkClasses?: string;
  openingLabel?: string;
  legalDocuments: LegalDocument[];
  closingLabel?: string;
  separatorLabel: string;
}

const LegalTerms: React.FC<LegalTermsProps> = ({
  linkClasses,
  openingLabel,
  legalDocuments,
  closingLabel,
  separatorLabel,
}) => {
  const className = linkClasses ? ['underline', linkClasses] : ['underline'];

  return (
    <>
      {openingLabel && `${openingLabel} `}
      {legalDocuments?.map((document, index) => (
        <React.Fragment key={document.legalDocumentId}>
          {index > 0
            ? index === legalDocuments.length - 1
              ? ` ${separatorLabel} `
              : ', '
            : null}
          <Link
            target="_blank"
            to={`/legal-documents/${document.legalDocumentId}`}
            className={className.join(' ')}
          >
            {document.legalDocumentName}
          </Link>
        </React.Fragment>
      ))}
      {closingLabel && ` ${closingLabel}`}
    </>
  );
};

export default LegalTerms;
