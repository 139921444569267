import ButtonComponent from 'components/button/buttonComponent';
import { ReactComponent as ChevronDownIcon } from 'assets/icons/chevron-down.svg';
import { ReactComponent as ChevronUpIcon } from 'assets/icons/chevron-up-fix.svg';
import { useMemo, useState } from 'react';
import Input from 'components/inputComponent';
import { ReactComponent as MagnifierIcon } from 'assets/icons/magnifier.svg';
import DatePicker from 'components/datePicker/DatePicker';
import {
  HistoricAppointmentsSortFilter,
  HandleAppointmentFilterChangeEvent,
} from 'app/my-appointments/interfaces';
import { componentIds } from 'utilities/constants';
import { useGetComponent } from 'hooks/useGetComponent';
import {
  AppointmentsHistoryPatientFilterMobileProps,
  SelectWithIdInfo,
} from 'app/appointments/components/interfaces';
import {
  AppointmentTypeEnum,
  useGetFhirAvailableProvidersNamesQuery,
} from 'graphql/generated/remote-schema-hasura';

import { DNAVisitTypeValue } from './MyVisitSummariesHistoryFilter';

export const MyVisitSummaryHistoryFilterMobile: React.FC<
  AppointmentsHistoryPatientFilterMobileProps
> = ({ filter, setFilter, onFilter, sortColumns }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const ArrowIcon = isExpanded ? ChevronUpIcon : ChevronDownIcon;
  const handleExpandIconClick = () => {
    setIsExpanded((isExpanded) => !isExpanded);
  };
  const { data: locale, loading: localeLoading } = useGetComponent({
    locale: 'en',
    componentId: componentIds.APPOINTMENTS_PATIENT_FILTER,
  });

  const [availableProviders, setAvailableProviders] = useState<
    SelectWithIdInfo[]
  >([]);

  useGetFhirAvailableProvidersNamesQuery({
    fetchPolicy: 'cache-first',
    onCompleted: (data) => {
      const repeatedProvidesMap: Record<string, number> = {};
      const providers = data.getFHIRProviders.providers.map<SelectWithIdInfo>(
        (provider) => {
          let name = `${provider.SENSITIVE_firstname} ${provider.SENSITIVE_lastname}`;
          if (repeatedProvidesMap[name]) {
            name = `${name} ${repeatedProvidesMap[name]}`;
          }
          repeatedProvidesMap[name] = (repeatedProvidesMap[name] || 0) + 1;
          return {
            id: provider.id,
            name,
          };
        },
      );
      setAvailableProviders(providers);
    },
  });

  const [byTypeEntries, localeToValueMapByType] = useMemo(() => {
    if (!locale) return [];
    const localeToValueMap: Record<string, string> = {
      [locale[DNAVisitTypeValue]]: DNAVisitTypeValue,
    };
    const byTypeEntries = Object.values(AppointmentTypeEnum).map((value) => {
      const text = locale[value];
      localeToValueMap[text] = value;
      return text;
    });
    byTypeEntries.unshift(locale[DNAVisitTypeValue]);
    byTypeEntries.unshift(locale.all);
    return [byTypeEntries, localeToValueMap];
  }, [locale]);

  const [providersEntries, providersNameIdMap, providersIdNameMap] =
    useMemo(() => {
      const providersNameIdMap = availableProviders.reduce<
        Record<string, string>
      >((acc, patient) => {
        acc[patient.name] = patient.id;
        return acc;
      }, {});
      const providersIdNameMap = availableProviders.reduce<
        Record<string, string>
      >((acc, patient) => {
        acc[patient.id] = patient.name;
        return acc;
      }, {});
      const providersEntries = availableProviders.map(
        (patient) => patient.name,
      );
      locale?.all && providersEntries.unshift(locale.all);
      return [providersEntries, providersNameIdMap, providersIdNameMap];
    }, [locale?.all, availableProviders]);

  const { localizedColumns, sortColumnsMap } = useMemo(() => {
    if (!locale) return {};
    const sortColumnsMap: Record<string, HistoricAppointmentsSortFilter> = {};
    const localizedColumns = sortColumns.flatMap((column) => {
      const text = locale[column];
      const newest = text + ` - ${locale.newest}`;
      const oldest = text + ` - ${locale.oldest}`;
      sortColumnsMap[newest] = { column, direction: 1 };
      sortColumnsMap[oldest] = { column, direction: 0 };
      return [newest, oldest];
    });
    const noneText = locale.none;
    return {
      localizedColumns: [noneText, ...localizedColumns],
      sortColumnsMap,
    };
  }, [locale, sortColumns]);

  let sortColumnText = filter.sort ? locale[filter.sort] : undefined;
  if (sortColumnText) {
    sortColumnText += filter.sortDirection
      ? ` - ${locale.newest}`
      : ` - ${locale.oldest}`;
  }

  const getValueFromLocale = (
    locale: string,
    name: string,
  ): string | number | undefined => {
    if (!localeToValueMapByType) return locale;
    const localeToValueMap: Record<string, Record<string, string | number>> = {
      type: localeToValueMapByType,
      provider: providersNameIdMap,
    };
    return localeToValueMap[name] ? localeToValueMap[name][locale] : locale;
  };

  const handleFilterChange = (
    e: HandleAppointmentFilterChangeEvent,
    name?: string,
  ) => {
    let currentName: string = name || '';
    if (!currentName) {
      if (!e.target.name) {
        throw new Error('No name provided for the filter');
      }
      currentName = e.target.name;
    }
    const value = getValueFromLocale(e.target.value, currentName);
    setFilter &&
      setFilter((oldFilter) => {
        return {
          ...oldFilter,
          [currentName]: value,
        };
      });
  };

  const handleSortChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const sortResult = sortColumnsMap?.[e.target.value];
    if (!sortResult) {
      setFilter((filter) => ({
        ...filter,
        sort: undefined,
        sortDirection: undefined,
      }));
      return;
    }
    const { column, direction } = sortResult;
    setFilter((filter) => ({
      ...filter,
      sort: column,
      sortDirection: direction,
    }));
  };

  if (!locale || localeLoading) return null;

  return (
    <div className="flex desktop:hidden flex-col items-center px-3 py-2 gap-3 bg-base-content rounded-10 w-full">
      <div className="flex flex-col w-full">
        <div className="flex flex-row w-full items-center justify-between p-0 gap-2.5">
          <div className="flex flex-row items-end gap-[7px]">
            <p className="flex text-base text-dark-gray font-semibold">
              {locale.findAndFilter}
            </p>
          </div>
          <div>
            <ButtonComponent
              type="underline"
              iconPosition="right"
              Icon={ArrowIcon}
              fill="black"
              stroke="black"
              onClick={handleExpandIconClick}
            />
          </div>
        </div>
      </div>
      {isExpanded && (
        <>
          <hr className="flex flex-row w-full items-center h-px bg-black-blur" />
          <div className="flex flex-col w-full gap-1 items-start">
            <p className="text-sm font-medium">{locale.find}</p>
            <div className="w-full">
              <Input
                type="text"
                decorator={<MagnifierIcon />}
                decoratorLeft
                noMarginBottom
                customYPadding="py-1"
                value={filter.find}
                name="find"
                onChange={handleFilterChange}
                placeholder={locale.findPlaceholder}
              />
            </div>
          </div>
          <div className="flex flex-row w-full gap-2 items-center justify-between">
            <p className="text-sm font-medium flex-1">{locale.from}</p>
            <div className="flex-[5]">
              <DatePicker
                fullWidthDesktop
                value={filter.from}
                name="from"
                onChange={handleFilterChange}
              />
            </div>
          </div>
          <div className="flex flex-row w-full gap-2 items-center justify-between">
            <p className="text-sm font-medium flex-1">{locale.to}</p>
            <div className="flex-[5]">
              <DatePicker
                fullWidthDesktop
                value={filter.to}
                name="to"
                onChange={handleFilterChange}
              />
            </div>
          </div>
          <div className="flex flex-col w-full gap-1 items-start">
            <p className="text-sm font-medium">{locale.byType}</p>
            <div className="w-full">
              <Input
                type="select"
                value={filter?.type && locale[filter?.type]}
                name="type"
                selectInputProps={{
                  selectValues: byTypeEntries,
                  fullWidth: true,
                  onSelectChange: handleFilterChange,
                }}
              />
            </div>
          </div>
          <div className="flex flex-col w-full gap-1 items-start">
            <p className="text-sm font-medium">{locale.byProvider}</p>
            <div className="w-full">
              <Input
                type="select"
                value={
                  (filter.provider && providersIdNameMap[filter.provider]) ||
                  locale.all
                }
                name="provider"
                selectInputProps={{
                  selectValues: providersEntries,
                  fullWidth: true,
                  onSelectChange: handleFilterChange,
                }}
              />
            </div>
          </div>

          <hr className="flex flex-row w-full items-center h-px bg-black-blur" />
          <div className="flex flex-col w-full gap-1 items-start">
            <p className="text-sm font-medium">{locale.sortBy}</p>
            <div className="w-full">
              <Input
                type="select"
                value={sortColumnText}
                selectInputProps={{
                  selectValues: localizedColumns,
                  fullWidth: true,
                  onSelectChange: handleSortChange,
                }}
              />
            </div>
          </div>
          <hr className="flex flex-row w-full items-center h-px bg-black-blur" />
          <ButtonComponent
            onClick={() => onFilter && onFilter()}
            className="self-end bg-base-content"
            type="outlined"
          >
            {locale.updateResults}
          </ButtonComponent>
        </>
      )}
    </div>
  );
};
