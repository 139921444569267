import { useIsMobile } from 'hooks/useIsMobile';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as MessagingIcon } from 'assets/icons/message.svg';
import { ReactComponent as VideoIcon } from 'assets/icons/videocamera.svg';
import { ReactComponent as ChatIcon } from 'assets/icons/chat.svg';
import { AppointmentTypeEnum } from 'graphql/generated/hasura';
import { ServiceOfferingType } from '../../my-appointments/components/select-a-provider/ProviderCardProps';

import Avatar from 'components/avatarComponent';
import ButtonComponent from 'components/button/buttonComponent';
import { useGetPage } from 'hooks/useGetPage';
import { useGetSignedUrlFromStorage } from 'hooks/useGetSignedUrlFromStorage';

import { pageIds } from 'utilities/constants';
import {
  MY_APPOINTMENTS_SCHEDULE_AN_APPOINTMENT,
  PROVIDERS_PROVIDER_BIO,
} from 'utilities/routes';

enum type {
  video = 'video',
  chat = 'chat',
  messaging = 'messaging',
}

const Icon = {
  [AppointmentTypeEnum.Video]: VideoIcon,
  [AppointmentTypeEnum.Chat]: ChatIcon,
  [AppointmentTypeEnum.Email]: MessagingIcon,
  [AppointmentTypeEnum.Dnavisit]: ChatIcon,
};

interface ContactPriceProps {
  serviceOfferingType: ServiceOfferingType;
}

export interface ProviderCardProps {
  providerId: string;
  name: string;
  title?: string;
  specialties?: string[];
  location?: string;
  rateAndServices?: ServiceOfferingType[];
  upcomingAppointment?: {
    type: type;
    date: Date;
  };
  profileImage?: string;
  favorite?: boolean;
  isFirstItem?: boolean;
  isStack?: boolean;
  calendarId?: string | null;
  ownerId?: string | null;
  signUrl?: boolean;
}

const PriceIcon: React.FC<{ serviceType: AppointmentTypeEnum }> = ({
  serviceType,
}) => {
  const IconComponent = Icon[serviceType];
  return <IconComponent />;
};

const ContactPrice: React.FC<ContactPriceProps> = ({ serviceOfferingType }) => {
  const { serviceType, price, serviceLabel } = serviceOfferingType;
  return (
    <p className="text-med-gray text-h7 font-semibold flex flex-row items-center gap-2">
      <span>
        <PriceIcon serviceType={serviceType} />
      </span>
      {serviceLabel} (${price})
    </p>
  );
};

export const ProviderCard: React.FC<ProviderCardProps> = ({
  providerId,
  name,
  title = null,
  specialties = [],
  location = null,
  rateAndServices = null,
  profileImage = null,
  isFirstItem = false,
  isStack = false,
  calendarId = null,
  ownerId = null,
  signUrl = false,
}) => {
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.PROVIDERS_SELECTION,
  });
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const signedProfileUrl = useGetSignedUrlFromStorage(
    profileImage ?? '',
    providerId ?? '',
  );

  const handleScheduleAnAppointment = () => {
    navigate(MY_APPOINTMENTS_SCHEDULE_AN_APPOINTMENT, {
      state: { codexProviderId: providerId, calendarId, ownerId },
    });
  };

  const onProviderNameClick = () => {
    navigate(PROVIDERS_PROVIDER_BIO, { state: { providerId } });
  };

  if (loading || !locale) return null;

  return (
    <div
      className={`flex flex-col w-full py-4 px-8 gap-4 ${
        !isFirstItem && 'desktop:border-l'
      } border-b border-gray-opacity-15`}
    >
      <div className="flex flex-row w-full items-center justify-center my-6">
        <div
          className={`flex ${
            isStack ? 'desktop:flex-row flex-col' : 'flex-col'
          } w-full items-center gap-4`}
        >
          <div className="flex w-52 justify-center">
            <Avatar
              size="s"
              imageUrl={signUrl ? signedProfileUrl : profileImage}
            />
          </div>
          <div
            className={`flex flex-col w-full ${
              isStack ? 'desktop:items-start items-center' : 'items-center'
            } gap-2`}
          >
            <p
              className="font-semibold text-dark-gray text-h5 underline text-center cursor-pointer"
              onClick={onProviderNameClick}
            >
              {name}
            </p>
            <p className="text-med-gray text-h6 font-normal text-center">
              {title}
            </p>
            <p className="w-full text-med-gray text-h6 font-normal text-left whitespace-pre-line">
              {location}
            </p>
            <div
              className={`w-full ${
                isStack && !isMobile
                  ? 'desktop:flex flex-row'
                  : 'grid grid-cols-2 items-center justify-center'
              } gap-2`}
            >
              {specialties.map((specialty, key) => (
                <div
                  key={key}
                  className={`${
                    isStack ? 'bg-light-blue' : 'bg-base-content'
                  } rounded-[30px] py-1 px-1`}
                >
                  <p className="text-dark-gray text-h7 font-semibold text-center">
                    {specialty}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-col w-full items-center gap-4">
            <p className="w-full text-dark-gray text-h5 font-semibold text-left">
              {isStack ? locale?.card?.acepted : locale?.card?.rates}
            </p>
            {!isStack && (
              <hr className="flex flex-row w-full items-center h-px bg-black-blur" />
            )}
            <div className="flex flex-col w-full justify-center gap-2">
              {!!rateAndServices &&
                rateAndServices.map((service) => (
                  <ContactPrice serviceOfferingType={service} />
                ))}
            </div>
          </div>
          {!isStack && (
            <div className="flex flex-col w-full items-center">
              <ButtonComponent
                type="outlined"
                onClick={handleScheduleAnAppointment}
              >
                {locale?.card?.button}
              </ButtonComponent>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
