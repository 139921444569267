interface InfoCategoryWrapperProps {
  children: React.ReactNode;
}

const InfoCategoryWrapper: React.FC<InfoCategoryWrapperProps> = ({
  children,
}) => {
  return (
    <div className="flex flex-col relative mt-2 desktop:mt-0 desktop:before:absolute desktop:before:top-0 desktop:before:left-0 desktop:before:w-10 desktop:before:bottom-0 desktop:before:z-0 desktop:before:bg-aq-gradient gap-9 desktop:gap-0">
      {children}
    </div>
  );
};

export default InfoCategoryWrapper;
