import ScanImage from 'components/ScanImage';
import { MyPatientsDermSCOREScansProps } from 'app/my-patients/interfaces/DermSCOREScans.interface';

export const MyPatientsScanImage: React.FC<MyPatientsDermSCOREScansProps> = ({
  scan,
  patientId = null,
}) => {
  return (
    <div className="flex flex-col self-stretch items-center">
      {scan && (
        <ScanImage
          className="overflow-hidden rounded-10 w-[120px] h-[150px] bg-cover bg-center"
          imageId={scan.imageId}
          objectFit="object-cover"
          patientId={patientId}
        />
      )}
    </div>
  );
};
