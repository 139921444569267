import { useNavigate } from 'react-router-dom';
import { ModalButtonProps } from 'components/modal/modal.interfaces';
import { useGetLegalDocument } from 'hooks/legalDocuments/useGetLegalDocument';
import { useIsMobile } from 'hooks/useIsMobile';
import { useGetPage } from 'hooks/useGetPage';
import {
  genericActionsIds,
  legalDocumentsIds,
  pageIds,
} from 'utilities/constants';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import { TEST_KITS_MANAGEMENT_REGISTER_TEST_KIT } from 'utilities/routes';
import Modal from 'components/modal/modalComponent';
import { sanitize } from '../../../utilities/sanitizer';

interface ResultsPrivacyStatementProps {
  closeTo?: string;
  state?: object;
}

export const ResultsPrivacyStatement: React.FC<
  ResultsPrivacyStatementProps
> = ({ closeTo = TEST_KITS_MANAGEMENT_REGISTER_TEST_KIT, state = null }) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.RESULTS_PRIVACY_STATEMENT,
  });
  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.CLOSE],
    });
  const { legalDocument } = useGetLegalDocument({
    legalDocumentId: legalDocumentsIds.LAB_SAMPLE_RESULTS_PRIVACY_STATEMENT,
  });

  const handleModalClose = () => {
    if (state !== null) {
      navigate(closeTo, { state: state });
    } else {
      navigate(closeTo);
    }
  };

  const Buttons: ModalButtonProps[] = [
    {
      label: genericAction?.[genericActionsIds.CLOSE].close,
      onClick: handleModalClose,
    },
  ];

  if ((loading && !locale) || (genericActionLoading && !genericAction))
    return null;

  if (legalDocument?.legalDocumentId) {
    return (
      <Modal
        isOpen={true}
        title={locale?.labSampleResultsPrivacyStatement}
        buttons={Buttons}
        onClose={handleModalClose}
      >
        <div className="flex flex-wrap">
          <div
            dangerouslySetInnerHTML={{
              __html: sanitize(
                isMobile
                  ? legalDocument.contentMobile
                  : legalDocument.contentWeb,
              ),
            }}
          />
        </div>
      </Modal>
    );
  }
  return null;
};
