import { AppointmentTypeEnum } from 'graphql/generated/hasura';
import { FHIR_APPOINTMENT_STATUS } from 'utilities/interfaces';

export enum FilterDirection {
  SUBTRACT = 0,
  ADD = 1,
}

export interface AppointmentListProps {
  list: ProviderDashboardUpcomingAppointments[];
  unavailable: boolean;
  locale: {
    unavailableDontHaveAppointments: string;
    youDoNotHaveAppointments: string;
    joinNow: string;
    viewAllAppointments: string;
  };
}

export interface WorkingHoursProps {
  date: string;
  workingHoursList: string[];
  unavailable: boolean;
  locale: {
    workingHoursFor: string;
    unavaibleWorkingHours: string;
    editWorkingHours: string;
    unavailable: string;
  };
}

export interface ProviderWeeklyWorkingHours {
  data: {
    Monday: string[];
    Tuesday: string[];
    Wednesday: string[];
    Thursday: string[];
    Friday: string[];
    Saturday: string[];
    Sunday: string[];
  };
}

export type DaysOfWeek =
  | 'Sunday'
  | 'Monday'
  | 'Tuesday'
  | 'Wednesday'
  | 'Thursday'
  | 'Friday'
  | 'Saturday';

export interface AvailabilityTime {
  time: string;
  slotsAvailable: number;
}

export interface BlockedTime {
  start: string;
  end: string;
}

export interface ProviderDashboardUpcomingAppointments {
  date: string;
  end?: string;
  appointmentType: AppointmentTypeEnum;
  patientName: string;
  appointmentCodexId: string;
  patientCodexId: string;
  status: FHIR_APPOINTMENT_STATUS;
}

export interface JoinableAppointmentParams {
  startDate: string;
  endDate?: string;
  status: FHIR_APPOINTMENT_STATUS;
}
