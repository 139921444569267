import { AuthContext } from 'auth/context/AuthContext';
import { useCallback, useContext, useMemo } from 'react';

import { ObjectValidate, areAllKeysDefined } from 'utilities/functions';
import {
  FHIR_USER_FIELDS_REQUIRED_FOR_PERSONAL_DETAILS_COMPLETE,
  GOOGLE_EHR_AVAILABLE_SYSTEMS_IDENTIFIERS,
  USER_FIELDS_REQUIRED_FOR_PERSONAL_DETAILS_COMPLETE,
} from 'utilities/constants';
import {
  FhirQuestionnaireNames,
  useGetFhirPatientbyCodexIdLazyQuery,
  useGetFhirQuestionnaireResponseLazyQuery,
} from 'graphql/generated/remote-schema-hasura';
import {
  Persona_Inquiry_Status_Enum,
  useGetPersonaVerificationStatusByEmailLazyQuery,
} from 'graphql/generated/hasura';

const useUserValidation = (includePersona = false) => {
  const { user: loggedUser } = useContext(AuthContext);

  const [
    getPersonaVerificationStatusByEmail,
    { loading: userVerificationLoading, error: userVerificationError },
  ] = useGetPersonaVerificationStatusByEmailLazyQuery({
    fetchPolicy: 'network-only',
  });

  const [fetchFhirUser, { error: errorFhirUser, loading: fhirLoading }] =
    useGetFhirPatientbyCodexIdLazyQuery({
      fetchPolicy: 'network-only',
    });

  const [fetchFhirQuestionnaire, { error, loading: loadingFhirQuestionnaire }] =
    useGetFhirQuestionnaireResponseLazyQuery({
      fetchPolicy: 'network-only',
    });

  const userValidation = useCallback(async () => {
    let isPersonalInfoValid = false,
      isContactInfoValid = false;
    const userResponse = await fetchFhirUser();

    const user = userResponse.data?.getFHIRPatientbyCodexID?.users[0];

    isPersonalInfoValid = areAllKeysDefined(
      user as unknown as ObjectValidate,
      USER_FIELDS_REQUIRED_FOR_PERSONAL_DETAILS_COMPLETE,
    );
    isContactInfoValid = areAllKeysDefined(
      user as unknown as ObjectValidate,
      FHIR_USER_FIELDS_REQUIRED_FOR_PERSONAL_DETAILS_COMPLETE,
    );

    let isQuestionnaireValid = false;

    const questionnaire = await fetchFhirQuestionnaire({
      variables: {
        codexPatientId: loggedUser?.uuid || '',
        identifier: FhirQuestionnaireNames.HealthQuestionnaire,
        system: GOOGLE_EHR_AVAILABLE_SYSTEMS_IDENTIFIERS.codex,
      },
    });
    isQuestionnaireValid = Boolean(
      questionnaire.data?.getFHIRQuestionnaireResponse.questionnaire_response
        .responses[0] &&
        questionnaire.data?.getFHIRQuestionnaireResponse.questionnaire_response
          .is_complete,
    );

    let isPersonaVerified = true;
    if (includePersona) {
      const verificationStatusQueryResult =
        await getPersonaVerificationStatusByEmail({
          variables: {
            email: loggedUser?.email || '',
          },
        });
      isPersonaVerified =
        verificationStatusQueryResult.data?.users?.[0]?.kyc_current_status ===
        Persona_Inquiry_Status_Enum.Approved;
    }

    return {
      isPersonalInfoValid,
      isContactInfoValid,
      isQuestionnaireValid,
      isPersonaVerified,
      generalValidation:
        isPersonalInfoValid &&
        isContactInfoValid &&
        isQuestionnaireValid &&
        (isPersonaVerified || !includePersona),
    };
  }, [
    fetchFhirQuestionnaire,
    fetchFhirUser,
    getPersonaVerificationStatusByEmail,
    includePersona,
    loggedUser?.email,
    loggedUser?.uuid,
  ]);

  return useMemo(
    () => ({
      userValidation,
      loading:
        loadingFhirQuestionnaire || fhirLoading || userVerificationLoading,
      error: error || errorFhirUser || userVerificationError,
    }),
    [
      userValidation,
      loadingFhirQuestionnaire,
      fhirLoading,
      userVerificationLoading,
      error,
      errorFhirUser,
      userVerificationError,
    ],
  );
};

export default useUserValidation;
