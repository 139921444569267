import { Scan } from 'app/dashboard/interfaces/scan.interfaces';
import { useGetFhirSkintelligentTestByTestIdAndUserIdLazyQuery } from 'graphql/generated/remote-schema-hasura';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  mapFhirSkintelligentTest,
  populateScanResult,
} from 'utilities/functions';
import {
  SharedMedia,
  SharedMediaModalLocationState,
} from 'app/my-patients/interfaces/interfaces';
import { PARAM_MODALS_IDENTIFIERS } from 'utilities/constants';
export function useHandleMediaClick(): [(media: SharedMedia) => void, boolean] {
  const navigate = useNavigate();

  const [getFhirSkintelligentTestByTestIdAndUserId, { loading: scanLoading }] =
    useGetFhirSkintelligentTestByTestIdAndUserIdLazyQuery({});

  const getScanData = useCallback(
    async (scanId: string): Promise<Scan | undefined> => {
      const { data } = await getFhirSkintelligentTestByTestIdAndUserId({
        variables: { testId: scanId },
      });
      if (!data?.getFHIRSkintelligentTestByTestIdAndUserId.skintelligent_test)
        return;
      const test = mapFhirSkintelligentTest(
        data.getFHIRSkintelligentTestByTestIdAndUserId.skintelligent_test,
      );
      return populateScanResult(test);
    },
    [getFhirSkintelligentTestByTestIdAndUserId],
  );

  const handleMediaClick = useCallback(
    async (media: SharedMedia) => {
      if (media.dermscoreId) {
        const scan = await getScanData(media.dermscoreId);
        if (!scan) return;
        navigate(
          `.?${PARAM_MODALS_IDENTIFIERS.MY_PATIENTS_DERMSCORE_SCANS_MODAL_ID}=true`,
          {
            state: {
              scan: {
                ...scan,
                imageUrl: media.img,
              },
              fileUrl: media.img,
              onlyView: true,
            },
          },
        );
        return;
      }
      const state: SharedMediaModalLocationState = {
        mediaDate: media.date,
        mediaSrc: media.img,
        appointmentCodexId: media.appointmentId,
        bodySite: media.bodySite,
        description: media.description,
      };
      navigate(`.?${PARAM_MODALS_IDENTIFIERS.SHARED_MEDIA_MODAL_ID}=true`, {
        state,
      });
    },
    [getScanData, navigate],
  );

  return [handleMediaClick, scanLoading];
}
