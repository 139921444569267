import { GetAppointmentByCodexAppointmentIdQuery } from 'graphql/generated/remote-schema-hasura';
import { PreworkItem } from './PreWorkItem';
import { PreworkSymptoms } from './PreworkSymptoms';
import { PreworkSymptomsLocation } from './PreworkSymptomsLocation';

interface PreWorkProps {
  prework: GetAppointmentByCodexAppointmentIdQuery | undefined;
  locale: {
    subjectOfAppointment: string;
    typeofSymptoms: string;
    howLongSymptoms: string;
    rateSeveritySymptoms: string;
    whereSymptoms: string;
    front: string;
    back: string;
    anythingElseYouWouldLikeToShareWithYourProvider: string;
  };
  symptomsLocation: string[] | null | undefined;
}

export const PreworkComponent: React.FC<PreWorkProps> = ({
  prework,
  locale,
  symptomsLocation,
}) => {
  return (
    <div className="flex flex-col gap-5 text-base">
      <PreworkItem
        question={locale.subjectOfAppointment}
        answer={
          prework?.getFHIRAppointmentByCodexId.appointment
            ?.subjectOfAppointment as string
        }
      />

      <PreworkSymptoms
        question={locale.typeofSymptoms}
        symptoms={
          prework?.getFHIRAppointmentByCodexId.appointment?.appointmentNotes
            ?.symptomsTypes as string[]
        }
      />

      <PreworkItem
        question={locale.howLongSymptoms}
        answer={
          prework?.getFHIRAppointmentByCodexId.appointment?.appointmentNotes
            ?.howLongQuestion as string
        }
      />

      <PreworkItem
        question={locale.rateSeveritySymptoms}
        answer={
          prework?.getFHIRAppointmentByCodexId.appointment?.appointmentNotes
            ?.severityQuestion as string
        }
      />

      {prework?.getFHIRAppointmentByCodexId.appointment?.appointmentNotes
        ?.anythingElseQuestion && (
        <PreworkItem
          question={locale.anythingElseYouWouldLikeToShareWithYourProvider}
          answer={
            prework?.getFHIRAppointmentByCodexId.appointment?.appointmentNotes
              ?.anythingElseQuestion as string
          }
        />
      )}

      <PreworkSymptomsLocation
        question={locale.whereSymptoms}
        frontLabel={locale.front}
        backLabel={locale.back}
        symptomsLocation={symptomsLocation}
      />
    </div>
  );
};
