import { useCallback, useEffect, useState } from 'react';
import { Record_Order_By } from 'components/codexTable/interfaces/record.interfaces';
import MedicationHistoryFilters from '../components/MedicationHistoryFilters';
import MedicationHistoryTable from '../components/MedicationHistoryTable';
import { useParams } from 'react-router-dom';
import { LIST_BATCH_SIZE, pageIds } from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';
import { useGetMedications } from 'hooks/useGetMedications';
import Loader from 'components/loaderComponent';
import { MedicationHistoryFilter } from 'app/my-skin/pages/MyMedicationHistory';
import { useModalParams } from 'components/modal/useModalManager';
import {
  MedicationHistoryDetailView,
  MedicationHistoryDetailViewModalId,
} from 'components/medicationHistoryDetailView/MedicationHistoryDetailView';
import { Order_By } from 'graphql/generated/hasura';

export const MedicationHistory: React.FC = () => {
  const { patientId } = useParams();
  const { data: testKitLocale, loading: testKitLocaleLoading } = useGetPage({
    locale: 'en',
    pageId: pageIds.MEDICATION_HISTORY,
  });
  const [isContentVisible, setIsContentVisible] = useState(false);
  const [providerName, setProviderName] = useState<string | null>(null);
  const [brandName, setBrandName] = useState<string | null>(null);
  const [find, setFind] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [sortByMobile, setSortByMobile] = useState<Record_Order_By>({
    date: Order_By.Asc,
  });
  const [orderBy, setOrderBy] = useState<Record_Order_By>({});
  const [appliedFilter, setAppliedFilter] = useState<MedicationHistoryFilter>({
    limit: LIST_BATCH_SIZE,
  });
  const { isOpen: isMedicationModalOpen } = useModalParams(
    MedicationHistoryDetailViewModalId,
  );

  useEffect(() => {
    if (testKitLocale && testKitLocale.mobileDateNewest && !sortByMobile)
      setSortByMobile(testKitLocale.mobileDateNewest);
  }, [testKitLocale, sortByMobile]);

  const handleOnUpdateFilters = useCallback(() => {
    setAppliedFilter({
      providerName,
      brandName,
      find,
      fromDate,
      toDate,
      orderBy,
    });
    setOrderBy(sortByMobile);
  }, [brandName, find, fromDate, orderBy, providerName, sortByMobile, toDate]);

  useEffect(() => setAppliedFilter((old) => ({ ...old, orderBy })), [orderBy]);

  const {
    brands,
    brandsLoading,
    medicationHistoryData: data,
    medicationLoading,
    providers,
    providersLoading,
    medicationsDetails,
    total: totalMedications,
  } = useGetMedications(appliedFilter, patientId, !patientId);
  const toggleContentVisibility = () => setIsContentVisible(!isContentVisible);
  const handleOnFromDateChange = (date: string) => {
    if (toDate && date && new Date(date).getTime() > new Date(toDate).getTime())
      setToDate('');
    setFromDate(date);
  };

  const handleOnToDateChange = (date: string) => {
    if (
      fromDate &&
      date &&
      new Date(date).getTime() < new Date(fromDate).getTime()
    )
      setFromDate('');
    setToDate(date);
  };
  const handleLoadMore = () => {
    setAppliedFilter((prev) => ({
      ...prev,
      limit: (prev.limit ?? 0) + LIST_BATCH_SIZE,
    }));
  };

  if (!patientId) return null;

  if (
    !testKitLocale ||
    medicationLoading ||
    testKitLocaleLoading ||
    brandsLoading ||
    providersLoading
  )
    return <Loader />;
  return (
    <>
      {isMedicationModalOpen && medicationsDetails && (
        <MedicationHistoryDetailView
          medicationRequestLists={medicationsDetails}
        />
      )}
      <div className="rounded-10 bg-white p-5 mt-[30px] desktop:p-[30px] m-5">
        <div className="flex flex-col desktop:flex-row desktop:items-center">
          <h4 className="text-h4 text-dark-gray font-semibold">
            {testKitLocale.history}
          </h4>
          <p className="text-med-gray text-h6 desktop:ml-2.5">
            {testKitLocale.showing} {data.length} {testKitLocale.of}{' '}
            {totalMedications}
          </p>
        </div>
        <MedicationHistoryFilters
          isContentVisible={isContentVisible}
          providerName={providerName}
          fromDate={fromDate}
          toDate={toDate}
          brandName={brandName}
          find={find}
          sortByMobile={sortByMobile}
          providers={providers}
          brands={brands}
          onToggleContentVisibility={toggleContentVisibility}
          onProviderSearchChange={(providerName) =>
            setProviderName(providerName)
          }
          onFromDateChange={(newFromDate) =>
            handleOnFromDateChange(newFromDate)
          }
          onToDateChange={(newToDate) => handleOnToDateChange(newToDate)}
          onBrandNameSearchChange={setBrandName}
          onFindSearchChange={(find) => setFind(find)}
          handleOnUpdateFilters={handleOnUpdateFilters}
          onSortByMobileChange={(sortByMobile) =>
            sortByMobile && setSortByMobile(sortByMobile)
          }
          locale={testKitLocale}
        />

        {data.length > 0 && (
          <MedicationHistoryTable
            data={data}
            locale={testKitLocale}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            onSeeMore={handleLoadMore}
            canSeeMore={data.length < totalMedications}
          />
        )}

        {data.length === 0 && (
          <p className="mt-[30px] font-semibold text-dark-gray">
            {testKitLocale.noMatchesFoundForSearchFilters}
          </p>
        )}
      </div>
    </>
  );
};
