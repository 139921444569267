import { useMemo } from 'react';
import {
  GetPageQueryVariables,
  useGetPageQuery,
} from '../graphql/generated/strapi';
import { useStrapi } from 'apollo/LocalesProvider';

export const useGetPage = (variables: GetPageQueryVariables) => {
  const { client } = useStrapi();
  const { data, loading, error } = useGetPageQuery({
    variables,
    client: client,
    fetchPolicy: 'cache-first',
  });

  const pageMemoized = useMemo(
    () => ({ data: data?.pages?.data[0]?.attributes?.content, loading, error }),
    [data, loading, error],
  );

  return pageMemoized;
};
