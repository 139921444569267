import InputComponent from 'components/inputComponent';
import Alert from 'components/alertComponent';
import { useGetPage } from 'hooks/useGetPage';
import { pageIds } from 'utilities/constants';

interface PasswordChangeVerificationProps {
  onCodeChange: (code: string) => void;
  mfaError: string;
}

export const PasswordChangeVerification: React.FC<
  PasswordChangeVerificationProps
> = ({ onCodeChange, mfaError }) => {
  const { data: locale, loading: loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.VERIFICATION,
  });

  if (loading && !locale) return null;

  return (
    <div className="flex flex-col gap-2.5">
      {mfaError && <Alert type="warning" text={mfaError} />}
      <p className="text-med-gray">{locale.instructionTextUpdatePassword}</p>
      <label
        className="font-base font-semibold text-dark-gray"
        htmlFor="Verification code"
      >
        {locale.verificationCodeLabel}
      </label>

      <InputComponent
        testID="verification-code-input"
        type="text"
        name="Verification Code"
        onChange={(e) => onCodeChange(e.target.value)}
        noMarginBottom
      />
    </div>
  );
};
