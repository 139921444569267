import { useIsMobile } from 'hooks/useIsMobile';
import {
  MedicationTableListItem,
  MedicationsTableListLocale,
} from './interfaces';

interface MedicationsSectionProps {
  title: string;
  list: MedicationTableListItem[];
  locale: MedicationsTableListLocale;
}

export const MedicationsTableList: React.FC<MedicationsSectionProps> = ({
  title,
  list,
  locale,
}) => {
  const isMobile = useIsMobile();
  return (
    <div className="flex flex-col gap-2">
      <h1 className="text-charcoal-gray font-semibold text-lg">{title}</h1>
      <hr />
      <div>
        <div className="grid grid-cols-1">
          {list.map((item, index) => (
            <div key={index}>
              <div className="grid desktop:grid-cols-6 grid-cols-1">
                <div className="w-full desktop:max-w-[134px] desktop:py-0 pt-5">
                  {(isMobile || !index) && (
                    <>
                      <p className="desktop:py-2.5 text-base text-charcoal-gray font-bold">
                        {locale.medicationType}
                      </p>
                      {!isMobile && <hr />}
                    </>
                  )}
                  <p className="desktop:py-[10px]">{item.type}</p>
                </div>
                <div className="w-full desktop:max-w-[134px] desktop:py-0 pt-5">
                  {(isMobile || !index) && (
                    <>
                      <p className="desktop:py-2.5 text-base text-charcoal-gray font-bold">
                        {locale.medicationBrand}
                      </p>
                      {!isMobile && <hr />}
                    </>
                  )}
                  <p className="desktop:py-[10px]">{item.brand}</p>
                </div>
                <div className="w-full desktop:max-w-[134px] desktop:py-0 pt-5">
                  {(isMobile || !index) && (
                    <>
                      <p className="desktop:py-2.5 text-base text-charcoal-gray font-bold">
                        {locale.medicationDelivery}
                      </p>
                      {!isMobile && <hr />}
                    </>
                  )}
                  <p className="desktop:py-[10px]">{item.delivery}</p>
                </div>
                <div className="w-full desktop:max-w-[134px] desktop:py-0 pt-5">
                  {(isMobile || !index) && (
                    <>
                      <p className="desktop:py-2.5 text-base text-charcoal-gray font-bold">
                        {locale.medicationDosage}
                      </p>
                      {!isMobile && <hr />}
                    </>
                  )}
                  <p className="desktop:py-[10px]">{item.dosage}</p>
                </div>
                <div className="w-full desktop:max-w-[134px] desktop:py-0 pt-5">
                  {(isMobile || !index) && (
                    <>
                      <p className="desktop:py-2.5 text-base text-charcoal-gray font-bold">
                        {locale.medicationFrecuency}
                      </p>
                      {!isMobile && <hr />}
                    </>
                  )}
                  <p className="desktop:py-[10px]">{item.frecuency}</p>
                </div>
                <div className="w-full desktop:max-w-[134px] desktop:py-0 py-5">
                  {(isMobile || !index) && (
                    <>
                      <p className="desktop:py-2.5 text-base text-charcoal-gray font-bold">
                        {locale.medicationAdditionalNotes}
                      </p>
                      {!isMobile && <hr />}
                    </>
                  )}
                  <span className="desktop:py-[10px] block desktop:w-[134px] w-full">
                    {item.additionalNotes || '\u00A0'}
                  </span>
                </div>
              </div>
              <hr className="col-span-6" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
