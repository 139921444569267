import { AppRouter } from './router/AppRouter';
import './App.css';
import { useInitializeConfig } from 'utilities/initializeConfig';
import { AuthProvider } from './auth/context/AuthProvider';

function App() {
  useInitializeConfig();

  return (
    <AuthProvider>
      <AppRouter />
    </AuthProvider>
  );
}

export default App;
