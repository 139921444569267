import { ReactComponent as LeftArrow } from '../../../assets/icons/small-chevron-left.svg';
import { ReactComponent as RightArrow } from '../../../assets/icons/small-chevron-right.svg';

type buttonType = 'default' | 'arrow-button';
type arrowDirection = 'left' | 'right' | null;

interface datePickerButtonProps {
  label?: string | number;
  buttonType?: buttonType;
  arrowDirection?: arrowDirection;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const DatePickerButton: React.FC<datePickerButtonProps> = ({
  label,
  buttonType = 'default',
  arrowDirection = null,
  onClick,
}) => {
  const baseButtonClasses = 'p-1 py-2 text-base font-bold text-dark-gray';
  const arrowButtonClasses = 'p-2.5 cursor-pointer';

  return (
    <button
      className={
        buttonType === 'arrow-button' ? arrowButtonClasses : baseButtonClasses
      }
      onClick={onClick}
    >
      {arrowDirection && arrowDirection === 'left' && <LeftArrow />}

      {arrowDirection && arrowDirection === 'right' && <RightArrow />}
      {label}
    </button>
  );
};
