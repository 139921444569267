import React, { ChangeEvent, useCallback, useEffect } from 'react';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { ReactComponent as Phone } from 'assets/icons/phone.svg';
import { ReactComponent as Picture } from 'assets/icons/mediaLibraryIcon.svg';
import Modal from 'components/modal/modalComponent';
import { useGetGenericActions } from '../../../hooks/useGetGenericActions';
import { genericActionsIds, pageIds } from '../../../utilities/constants';
import { ModalButtonProps } from '../../../components/modal/modal.interfaces';
import { useGetPage } from '../../../hooks/useGetPage';
import { Dot, ImageObject } from './BodySymtomsLocation/interfaces';
import InputComponent from '../../../components/inputComponent';
import Dropzone from '../../../components/Dropzone';
import { useUploadFileToStorageMutation } from '../../../graphql/generated/hasura';
import { twMerge } from 'tailwind-merge';
import ButtonComponent from '../../../components/button/buttonComponent';

interface IModalSymptomDetails {
  dot: Dot | null;
  setDot: React.Dispatch<React.SetStateAction<Dot | null>>;
  handleDeleteSymptom: () => void;
  mediaPerBodyLocation: Map<string, string | ImageObject>;
}

const initial = {
  image: '',
  mediaId: '',
};

const ModalSymptomDetails: React.FC<IModalSymptomDetails> = ({
  dot,
  mediaPerBodyLocation,
  setDot,
  handleDeleteSymptom,
}) => {
  const [formModal, setForm] = React.useState<ImageObject>(initial);
  const [uploadFile, { loading: isMediaSaving }] =
    useUploadFileToStorageMutation({});

  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.EDIT_SYMPTOM_MODAL,
  });

  const onClose = () => setDot(null);

  const handleOnTextAreaChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      const description = event.target.value;
      setForm((prev) => ({ ...prev, description }));
    },
    [],
  );

  const handleImageUpload = useCallback(
    async (files: File[]) => {
      const file = files[0];
      const buffer = await file.arrayBuffer();
      const result = await uploadFile({
        variables: {
          file: {
            mimetype: file.type,
            originalname: file.name,
            buffer: Array.from(new Uint8Array(buffer)),
          },
        },
      });
      const url = result.data?.UploadFileToStorage?.file?.url;
      const mediaId = result.data?.UploadFileToStorage?.file?.fileId;

      if (!url || !mediaId) return;
      setForm((prev) => ({ ...prev, image: url, mediaId }));
    },
    [uploadFile],
  );

  const handleUpdate = () => {
    mediaPerBodyLocation.set(String(dot?.location), formModal);
    return onClose();
  };

  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.UPDATE, genericActionsIds.CANCEL],
    });

  const Buttons: ModalButtonProps[] = [
    {
      label: genericAction?.[genericActionsIds.UPDATE]?.update,
      onClick: handleUpdate,
    },
    {
      label: genericAction?.[genericActionsIds.CANCEL]?.cancel,
      type: 'underline',
      onClick: onClose,
    },
    {
      label: locale?.deleteDetails,
      type: 'underline',
      Icon: TrashIcon,
      floatLeft: true,
      fill: 'fill-clc-blue',
      iconPosition: 'left',
      className: 'ml-auto',
      onClick: handleDeleteSymptom,
    },
  ];

  useEffect(() => {
    setForm(mediaPerBodyLocation.get(String(dot?.location)) as ImageObject);
  }, [dot, mediaPerBodyLocation]);

  if (
    (loading && !locale) ||
    (genericActionLoading && !genericAction) ||
    !dot
  ) {
    return null;
  }

  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      title={locale?.title}
      buttons={Buttons}
      titleCustomClass="font-exo normal-case desktop:!text-h3"
    >
      <div className="flex flex-col gap-[30px] pt-[60px] desktop:py-2">
        <h2 className="text-h1 font-medium font-exo">{dot.location}</h2>

        <div
          className={twMerge(
            'mt-8 desktop:h-64 desktop:flex gap-6 border border-transparent border-b-gray-opacity-15 pb-4',
            isMediaSaving && 'opacity-50',
          )}
        >
          <div className="desktop:min-w-[300px] w-[300px] rounded-lg overflow-hidden">
            <img
              src={formModal?.image}
              alt="scan"
              className="w-full h-full object-cover"
            />
          </div>

          <div className="w-full mt-4 desktop:mt-0">
            <InputComponent
              value={formModal?.description}
              type="text-area"
              maxLengthValue={500}
              textAreaProps={{
                height: 'h-52',
                textAreaTitle: locale?.descriptionLabel,
                containerHeight: 'h-full',
                onTextAreaChange: handleOnTextAreaChange,
                name: 'appointmentNotes',
              }}
            />
          </div>
        </div>

        <div className="w-full">
          <h4 className="font-semibold text-charcoal-gray font-exo text-h4 mb-4">
            {locale.changePhotoLabel}
          </h4>
          <p className="text-base font-semibold text-charcoal-gray mb-2">
            {locale?.changePhotoDescription}
          </p>
          <div className="w-full desktop:grid grid-cols-8 gap-8">
            <Dropzone
              onDrop={handleImageUpload}
              label={locale.selectFile}
              supportText={locale.supportedFormat}
              btnText={locale.selectFileBtn}
              disabled={isMediaSaving}
              className="col-span-5 "
            />

            <div className="col-span-3">
              <ButtonComponent
                type="outlined-transparent"
                className="desktop:w-full font-exo mt-4 desktop:mt-0"
                containerClassName="w-full"
                iconPosition="right"
                Icon={Phone}
              >
                {locale.takePhotoBtn}
              </ButtonComponent>

              <ButtonComponent
                type="outlined-transparent"
                className="desktop:w-full font-exo mt-4"
                containerClassName="w-full"
                iconPosition="right"
                Icon={Picture}
              >
                {locale.selectLibraryBtn}
              </ButtonComponent>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalSymptomDetails;
