import {
  GeneralStates,
  ProviderProfileDataOptional,
  providerDataResponseEducation,
} from 'app/my-account/interfaces/profile.interfaces';
import { ProviderData } from 'firebase/interfaces';

export interface AccountInfoProps {
  formValues: ProviderData;
}

export interface ProviderPersonalInfo {
  gender: string;
  languagesOfCommunication: string[];
  about?: string;
}

export interface ProviderProfileInfoProps extends ProviderProfileDataOptional {
  handleUpdatedProviderAccountInfo: (state: GeneralStates) => void;
  handleProviderProfileInfoChange: (data: ProviderProfileDataOptional) => void;
}

export type Question = {
  id: number;
  text: string;
  subtitle: string;
  answers: string[];
};

export enum educationFieldType {
  MEDICAL_SCHOOL = 'medicalSchool',
  RESIDENCY = 'residency',
  UNDERGRADUATE = 'undergraduate',
}

export interface educationItem {
  id: number;
  title: string;
  completed: boolean;
  answers: providerDataResponseEducation[];
}

export interface CredentialsErrors {
  titles: string | null;
  states: string | null;
  certifications: string | null;
}
export interface EducationErrors {
  medicalSchool: string | null;
  residency: string | null;
  undergraduate: string | null;
}

export interface CredentialsItem {
  titles: string[];
  states: string[];
  certifications: string[];
}

export interface EducationListItem {
  medicalSchool: string;
  residency: string;
  undergraduate: string;
}
