import {
  AccountSettingsState,
  SectionsLoaded,
} from './providerModuleInterfaces';

export interface PrivacyPreferencesProps {
  locale: Record<string, string>;
  setAccountSettings: React.Dispatch<
    React.SetStateAction<AccountSettingsState | undefined>
  >;
  setIsAllSectionsLoaded: React.Dispatch<React.SetStateAction<SectionsLoaded>>;
}

export enum PrivacyPreferencesKeys {
  PRIVACY__SETTINGS_ENABLED,
  PRIVACY_AFFILIATES_CODEX_LABS_SETTING,
  PRIVACY_NON_AFFILIATES_CODEX_LABS_SETTING,
  PRIVACY_CALIFORNIA_SETTINGS,
}

export interface PrivacyPreferencesState {
  PRIVACY__SETTINGS_ENABLED: boolean;
  PRIVACY_AFFILIATES_CODEX_LABS_SETTING: boolean;
  PRIVACY_NON_AFFILIATES_CODEX_LABS_SETTING: boolean;
  PRIVACY_CALIFORNIA_SETTINGS: boolean;
}
