import { useLocation, useNavigate } from 'react-router-dom';
import SelectAProviderTabs from 'components/SelectAProviderTabs';
import { useRequestZrtTestMutation } from 'graphql/generated/remote-schema-hasura';

import Loader from 'components/loaderComponent';
import { TEST_KITS_MANAGEMENT_REGISTER_TEST_KIT } from 'utilities/routes';

export interface SelectAProviderTabProp {
  onProviderSelect: (
    id: string,
    calendarId?: string | null,
    ownerId?: string | null,
  ) => void;
}

const ZrtSelectAProvider: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [requestZetTest, { loading }] = useRequestZrtTestMutation({});

  const onProviderSelect = async (providerCodexId: string) => {
    const { testKitData } = location.state ?? {};
    const { testKitIdNumber, testKitTrackingNumber, testType } =
      testKitData ?? {};
    if (!testKitIdNumber || !testType) {
      return;
    }
    try {
      const result = await requestZetTest({
        variables: {
          providerCodexId,
          testType,
          zrtSwabCode: testKitIdNumber,
          trackingNumber: testKitTrackingNumber,
        },
      });

      if (result.data) {
        navigate(TEST_KITS_MANAGEMENT_REGISTER_TEST_KIT, {
          state: {
            customAlertMessage: 'zrtTestKitSent',
            customAlertType: 'positive',
          },
        });
        return;
      }
    } catch (error) {
      console.error(error);
    }
    navigate(TEST_KITS_MANAGEMENT_REGISTER_TEST_KIT, {
      state: {
        customAlertMessage: 'zrtTestKitSentError',
        customAlertType: 'warning',
      },
    });
  };

  return (
    <div className="relative">
      {loading && (
        <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center">
          <Loader />
        </div>
      )}
      <div className={loading ? 'opacity-50 pointer-events-none' : ''}>
        <SelectAProviderTabs onProviderSelect={onProviderSelect} />
      </div>
    </div>
  );
};

export default ZrtSelectAProvider;
