import { DynamicQuestionResponseSection } from '../interfaces/dynamicQuestionnaireResponse.interface';

interface generateLinesInbetweenOptionsProps {
  optionsList: DynamicQuestionResponseSection[];
  currentMapIndex: number;
  currentItem: DynamicQuestionResponseSection;
}

export const InBetweenOptionLine = ({
  optionsList,
  currentMapIndex,
  currentItem,
}: generateLinesInbetweenOptionsProps) => {
  return (
    <>
      {currentMapIndex < optionsList.length - 1 ? (
        <span
          id={`line-${currentItem.name}`}
          className={
            'mobile-line bg-gray-opacity-15 w-full h-[1px] flex desktop:hidden'
          }
        />
      ) : (
        ''
      )}
    </>
  );
};
