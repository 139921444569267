import ScanActions from 'app/dashboard/components/ScanActions';
import { Scan } from 'app/dashboard/interfaces/scan.interfaces';
import { useGetFhirSkintelligentTestByTestIdAndUserIdQuery } from 'graphql/generated/remote-schema-hasura';
import { useState } from 'react';
import {
  formatDateToCustomFormat,
  mapFhirSkintelligentTest,
  populateScanResult,
} from 'utilities/functions';
import { ReactComponent as DesktopIcon } from 'assets/icons/desktop.svg';
import { ReactComponent as MobileIcon } from 'assets/icons/mobile.svg';
import { Image_Source_Types_Enum } from 'graphql/generated/hasura';
import { useGetComponent } from 'hooks/useGetComponent';
import { componentIds } from 'utilities/constants';
import SizeableLoader from 'components/SizeableLoader';

interface DermscoreMediaItemProps {
  scanId: string;
  imageSrc: string;
}

const DermscoreMediaItem: React.FC<DermscoreMediaItemProps> = ({
  scanId,
  imageSrc,
}) => {
  const { data: locale } = useGetComponent({
    locale: 'en',
    componentId: componentIds.SHARED_MEDIA_LIBRARY,
  });
  const [scan, setScan] = useState<Scan>();
  const { loading } = useGetFhirSkintelligentTestByTestIdAndUserIdQuery({
    variables: {
      testId: scanId,
    },
    skip: !scanId,
    onCompleted: (data) => {
      if (!data.getFHIRSkintelligentTestByTestIdAndUserId.skintelligent_test)
        return;
      const test = mapFhirSkintelligentTest(
        data.getFHIRSkintelligentTestByTestIdAndUserId.skintelligent_test,
      );
      setScan(populateScanResult(test));
    },
  });
  const SourceIcon =
    scan?.source === Image_Source_Types_Enum.Pc ? DesktopIcon : MobileIcon;
  return (
    <div
      key={scanId}
      className="desktop:w-1/3 flex flex-col desktop:flex-row desktop:gap-4"
    >
      <div className="flex items-center justify-center desktop:block">
        <div className="overflow-hidden rounded-10 desktop:w-44 desktop:h-full h-[350px] bg-cover bg-center">
          <img
            src={imageSrc}
            className="object-cover w-full h-full"
            alt={locale?.sharedMediaAltText}
          />
        </div>
      </div>
      <div className="flex grow flex-col gap-1.5 w-fit px-7 py-5 desktop:px-0 desktop:py-0 justify-center items-center desktop:justify-start desktop:items-stretch">
        {loading || !scan ? (
          <div className="flex items-center justify-center h-full">
            <SizeableLoader sizeClassName="w-[100px] h-[100px]" />
          </div>
        ) : (
          <>
            <div className="text-charcoal-gray text-base font-semibold w-fit font-exo">
              {locale?.inAppointmentShare}
            </div>
            <div className="text-med-gray-3 text-base font-normal leading-snug">
              {formatDateToCustomFormat(scan.date)}
            </div>
            <div className="justify-start items-center gap-2.5 inline-flex">
              <div className="justify-center items-center w-6 h-6 flex">
                <SourceIcon />
              </div>
              <div className="grow shrink basis-0 text-zinc-800 text-sm font-bold leading-snug">
                {locale?.[scan.source]}
              </div>
            </div>
            <div className="flex flex-row grow justify-around items-end" />
            <div className="flex flex-row justify-around desktop:justify-start items-center">
              <ScanActions
                withTitles
                useCurrentPathNavigate
                scan={scan}
                classNames="desktop:block"
                buttonWrapperClassName="desktop:justify-start"
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DermscoreMediaItem;
