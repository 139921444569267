import { UserSettings, VonageContext } from 'context/VonageContext';
import { usePreviewPublisher } from 'hooks/vonage/usePreviewPublisher';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import InputComponent from 'components/inputComponent';
import Select, { SingleValue } from 'react-select';
import * as VideoExpress from '@vonage/video-express';
import {
  DEVICE_ACCESS_STATUS,
  PARAM_MODALS_IDENTIFIERS,
  pageIds,
} from 'utilities/constants';
import { DeviceAcessAlert } from './DeviceAccessAlert';
import { useGetPage } from 'hooks/useGetPage';
import { MY_APPOINTMENTS, VONAGE_VIDEO_ROOM_ROUTES } from 'utilities/routes';
import { useModalParams } from 'components/modal/useModalManager';
import { ReactComponent as VideoIconTitle } from 'assets/icons/appointmentsIcons/videoIconL.svg';
import { ReactComponent as ChatIconTitle } from 'assets/icons/appointmentsIcons/chatIconL.svg';
import { ReactComponent as VideoIcon } from 'assets/icons/appointmentsIcons/videoIcon.svg';
import { ReactComponent as MicrophoneIcon } from 'assets/icons/appointmentsIcons/microphone.svg';
import { ReactComponent as SpeakerIcon } from 'assets/icons/appointmentsIcons/speaker.svg';
import { ReactComponent as AudioDisableIcon } from 'assets/icons/videoIcons/mobile/audioDisable.svg';
import { ReactComponent as AudioEnableIcon } from 'assets/icons/videoIcons/mobile/audioEnable.svg';
import { ReactComponent as VideoEnableIcon } from 'assets/icons/videoIcons/mobile/videoEnable.svg';
import { ReactComponent as VideoDisableIcon } from 'assets/icons/videoIcons/mobile/videoDisable.svg';
import Modal from 'components/modal/modalComponent';
import { ModalButtonProps } from 'components/modal/modal.interfaces';
import { useIsMobile } from 'hooks/useIsMobile';
import { AppointmentTypeEnum } from 'graphql/generated/remote-schema-hasura';
import { AuthContext } from 'auth/context/AuthContext';

interface SelectValue {
  label: string;
  value: string;
}

export const WaitingRoom: React.FC = () => {
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.VONAGE,
  });
  const { data: appoinmentsLocale, loading: appointmentsLoading } = useGetPage({
    locale: 'en',
    pageId: pageIds.MY_APPOINTMENTS,
  });
  const navigate = useNavigate();
  const location = useLocation();
  const { user, setUser } = useContext(VonageContext);
  const { user: loggedUser } = useContext(AuthContext);
  const waitingRoomVideoContainer = useRef<HTMLDivElement | null>(null);
  const roomToJoin = location.state?.roomId || '';
  const appointmentType = location.state?.appointmentType;
  const [roomName] = useState(roomToJoin);
  const [userName, setUserName] = useState(loggedUser?.displayName || '');
  const [isUserNameInvalid, setIsUserNameInvalid] = useState(false);
  const [localAudio, setLocalAudio] = useState(
    user?.defaultSettings.publishAudio,
  );
  const [localVideo, setLocalVideo] = useState(
    user?.defaultSettings.publishVideo,
  );
  const [localVideoSource, setLocalVideoSource] = useState<string | undefined>(
    undefined,
  );
  const [localAudioSource, setLocalAudioSource] = useState<string | undefined>(
    undefined,
  );
  const [localAudioOutput, setLocalAudioOutput] = useState<string | undefined>(
    undefined,
  );
  const [audioDevice, setAudioDevice] = useState('');
  const [videoDevice, setVideoDevice] = useState('');
  const [audioOutputDevice, setAudioOutputDevice] = useState('');

  const { isOpen: isVideoTest } = useModalParams(
    PARAM_MODALS_IDENTIFIERS.IS_VIDEO_TEST,
  );
  const isMobile = useIsMobile();

  const {
    createPreview,
    destroyPreview,
    previewPublisher,
    previewMediaCreated,
    deviceInfo,
    accessAllowed,
  } = usePreviewPublisher();

  const handleVideoSource = useCallback(
    (e: SingleValue<{ label: string; value: string }>) => {
      const videoDeviceId = e?.value ?? '';
      setVideoDevice(videoDeviceId);
      previewPublisher?.setVideoDevice(videoDeviceId);
      setLocalVideoSource(videoDeviceId || undefined);
    },
    [previewPublisher, setVideoDevice, setLocalVideoSource],
  );

  const handleOnClose = () => {
    navigate(MY_APPOINTMENTS);
  };

  const handleAudioSource = useCallback(
    (e: SingleValue<{ label: string; value: string }>) => {
      const audioDeviceId = e?.value ?? '';
      setAudioDevice(audioDeviceId);
      previewPublisher?.setAudioDevice(audioDeviceId);
      setLocalAudioSource(audioDeviceId);
    },
    [previewPublisher, setAudioDevice, setLocalAudioSource],
  );

  const handleAudioOutput = useCallback(
    (e: SingleValue<{ label: string; value: string }>) => {
      const audioOutputId = e?.value ?? '';
      setAudioOutputDevice(audioOutputId);
      setLocalAudioOutput(audioOutputId);
    },
    [setLocalAudioOutput, setAudioOutputDevice],
  );

  const handleAudioChange = () => {
    setLocalAudio((prevAudio) => !prevAudio);
  };

  const handleVideoChange = () => {
    setLocalVideo((prevVideo) => !prevVideo);
  };

  const handleOnSubmit = () => {
    if (validateForm()) {
      navigate(VONAGE_VIDEO_ROOM_ROUTES, {
        state: {
          room: roomName,
          appointmentType,
          patientId: location.state?.patientId || undefined,
          providerId: location.state?.providerId || undefined,
        },
      });
    }
  };

  const validateForm = () => {
    if (userName === '') {
      setIsUserNameInvalid(true);
      return false;
    } else if (roomName === '') {
      return false;
    }
    return true;
  };

  const onChangeParticipantName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const userName = e.target.value;
    if (userName.trim() === '') {
      setUserName('');
      return;
    }
    setIsUserNameInvalid(false);
    setUserName(userName);
  };

  useEffect(() => {
    if (
      localAudio !== user?.defaultSettings.publishAudio ||
      localVideo !== user?.defaultSettings.publishVideo ||
      localAudioSource !== user?.defaultSettings.audioSource ||
      localVideoSource !== user?.defaultSettings.videoSource ||
      localAudioOutput !== user?.defaultSettings.audioOutput
    ) {
      setUser({
        ...user,
        videoEffects: {
          backgroundBlur: false,
          virtualBackground: false,
          videoSourceId: localVideoSource,
        },
        defaultSettings: {
          publishAudio: Boolean(localAudio),
          publishVideo: Boolean(localVideo),
          audioSource: localAudioSource,
          videoSource: localVideoSource,
          audioOutput: localAudioOutput,
        },
      } as UserSettings);
    }
  }, [
    localAudio,
    localVideo,
    user,
    setUser,
    localAudioSource,
    localVideoSource,
    localAudioOutput,
  ]);

  useEffect(() => {
    if (userName !== user?.userName) {
      setUser({ ...user, userName: userName } as UserSettings);
    }
  }, [userName, user, setUser]);

  useEffect(() => {
    if (previewPublisher && previewMediaCreated && deviceInfo) {
      previewPublisher
        .getAudioDevice()
        .then((currentAudioDevice) => {
          setAudioDevice(currentAudioDevice.deviceId);
        })
        .catch((error) => {
          console.log('Error getting audio device', error);
        });

      const currentVideoDevice = previewPublisher.getVideoDevice();
      setVideoDevice(currentVideoDevice.deviceId);

      VideoExpress.getActiveAudioOutputDevice()
        .then((currentAudioOutputDevice) => {
          setAudioOutputDevice(currentAudioOutputDevice.deviceId ?? '');
        })
        .catch((error) => {
          console.log('Error getting audio output device', error);
        });
    }
  }, [
    deviceInfo,
    previewPublisher,
    setAudioDevice,
    setVideoDevice,
    previewMediaCreated,
    setAudioOutputDevice,
  ]);

  useEffect(() => {
    if (previewPublisher) {
      if (localAudio && !previewPublisher.isAudioEnabled()) {
        previewPublisher.enableAudio();
      } else if (!localAudio && previewPublisher.isAudioEnabled()) {
        previewPublisher.disableAudio();
      }
    }
  }, [localAudio, previewPublisher]);

  useEffect(() => {
    if (previewPublisher) {
      if (localVideo && !previewPublisher.isVideoEnabled()) {
        previewPublisher.enableVideo();
      } else if (!localVideo && previewPublisher.isVideoEnabled()) {
        previewPublisher.disableVideo();
      }
    }
  }, [localVideo, previewPublisher]);

  useEffect(() => {
    if (waitingRoomVideoContainer.current) {
      createPreview(waitingRoomVideoContainer.current);
    }

    return () => {
      destroyPreview();
    };
  }, [
    createPreview,
    destroyPreview,
    localVideoSource,
    loading,
    appointmentsLoading,
  ]);

  const audioSourceOptions = useMemo(
    () =>
      deviceInfo?.audioInputDevices.map((device) => ({
        value: device.deviceId,
        label: device.label,
      })) as SelectValue[],
    [deviceInfo?.audioInputDevices],
  );

  const audioOutputOptions = useMemo(
    () =>
      deviceInfo?.audioOutputDevices.map((device) => ({
        value: device.deviceId,
        label: device.label,
      })) as SelectValue[],
    [deviceInfo?.audioOutputDevices],
  );

  const videoSourceOptions = useMemo(
    () =>
      deviceInfo?.videoInputDevices.map((device) => ({
        value: device.deviceId,
        label: device.label,
      })) as SelectValue[],
    [deviceInfo?.videoInputDevices],
  );

  const Buttons: ModalButtonProps[] = [
    {
      label: locale?.join,
      onClick: handleOnSubmit,
      disabled: !roomName || !userName,
      className: 'px-10',
    },
    {
      label: locale?.cancel,
      onClick: handleOnClose,
      type: 'underline',
    },
  ];

  const getIcon = () => {
    switch (appointmentType) {
      case AppointmentTypeEnum.Video:
        return <VideoIconTitle height="20" width="20" />;
      case AppointmentTypeEnum.Chat:
      default:
        return <ChatIconTitle height="20" width="20" />;
    }
  };

  const getTitle = () => {
    switch (appointmentType) {
      case AppointmentTypeEnum.Video:
        return appoinmentsLocale?.video?.deets;
      case AppointmentTypeEnum.Chat:
      default:
        return appoinmentsLocale?.chat?.deets;
    }
  };

  if (loading || !locale || !appoinmentsLocale || appointmentsLoading)
    return null;

  return (
    <Modal
      isOpen={true}
      title={getTitle()}
      icon={getIcon()}
      onClose={handleOnClose}
      buttons={Buttons}
      modalDesktopWidth="desktop:max-w-full"
      modalDesktopHeight="h-full"
      contentPaddingX="0"
      contentPaddingY="0"
    >
      <div className="flex flex-col desktop:flex-row w-full h-full justify-center items-center gap-3 desktop:gap-[30px] overflow-x-hidden">
        {appointmentType && appointmentType === AppointmentTypeEnum.Video && (
          <div
            id="waiting-room-video-container"
            className="relative w-full flex flex-col items-center justify-center h-full desktop:h-[460px]"
            ref={waitingRoomVideoContainer}
          >
            <div className="absolute z-10 bottom-7 flex flex-row gap-[30px]">
              {localVideo ? (
                <VideoEnableIcon
                  className="cursor-pointer"
                  onClick={handleVideoChange}
                />
              ) : (
                <VideoDisableIcon
                  className="cursor-pointer"
                  onClick={handleVideoChange}
                />
              )}
              {localAudio ? (
                <AudioEnableIcon
                  className="cursor-pointer"
                  onClick={handleAudioChange}
                />
              ) : (
                <AudioDisableIcon
                  className="cursor-pointer"
                  onClick={handleAudioChange}
                />
              )}
            </div>
          </div>
        )}
        {isMobile && isVideoTest && (
          <div className="flex flex-col gap-1">
            <InputComponent
              testID="name-input"
              type="text"
              name="name"
              onChange={onChangeParticipantName}
              placeholder={locale?.name}
              errorStatus={isUserNameInvalid}
              value={userName}
            />
          </div>
        )}
        {!isMobile && (
          <div className="flex flex-col gap-[30px]">
            {appointmentType &&
              appointmentType === AppointmentTypeEnum.Video && (
                <p className="text-h7 desktop:text-h7 text-dark-gray font-bold uppercase">
                  {locale?.settings}
                </p>
              )}

            {isVideoTest && deviceInfo && (
              <div className="pt-2">
                <InputComponent
                  testID="name-input"
                  type="text"
                  name="name"
                  onChange={onChangeParticipantName}
                  placeholder={locale?.name}
                  errorStatus={isUserNameInvalid}
                  value={userName}
                />
              </div>
            )}
            {deviceInfo &&
              previewMediaCreated &&
              deviceInfo.videoInputDevices &&
              appointmentType &&
              appointmentType === AppointmentTypeEnum.Video && (
                <div className="flex flex-row gap-2.5 items-center justify-center">
                  <VideoIcon />
                  <Select
                    className="dropdown w-full"
                    classNamePrefix="react-select"
                    placeholder={locale?.videoSource}
                    value={videoSourceOptions.find(
                      (device) => device.value === videoDevice,
                    )}
                    options={videoSourceOptions}
                    onChange={(e) =>
                      handleVideoSource(e as SingleValue<SelectValue>)
                    }
                  />
                </div>
              )}
            {deviceInfo &&
              previewMediaCreated &&
              appointmentType &&
              appointmentType === AppointmentTypeEnum.Video && (
                <>
                  <div className="flex flex-row gap-2.5 items-center justify-center">
                    <MicrophoneIcon />
                    <Select
                      className="dropdown w-full"
                      classNamePrefix="react-select"
                      placeholder={locale?.audioSource}
                      value={audioSourceOptions.find(
                        (device) => device.value === audioDevice,
                      )}
                      options={audioSourceOptions}
                      onChange={(e) =>
                        handleAudioSource(e as SingleValue<SelectValue>)
                      }
                    />
                  </div>
                  <div className="flex flex-row gap-[10px] items-center justify-center">
                    <SpeakerIcon />
                    <Select
                      className="dropdown w-full"
                      classNamePrefix="react-select"
                      placeholder={locale?.audioOutput}
                      value={audioOutputOptions.find(
                        (device) => device.value === audioOutputDevice,
                      )}
                      options={audioOutputOptions}
                      onChange={(e) =>
                        handleAudioOutput(e as SingleValue<SelectValue>)
                      }
                    />
                  </div>
                </>
              )}
          </div>
        )}
      </div>
      {accessAllowed !== DEVICE_ACCESS_STATUS.ACCEPTED &&
        !isMobile &&
        appointmentType &&
        appointmentType === AppointmentTypeEnum.Video && (
          <DeviceAcessAlert accessStatus={accessAllowed} />
        )}
    </Modal>
  );
};
