import { MultiFactorAuthenticationComponentProps } from 'app/my-account/interfaces/profile.interfaces';
import MfaSmsComponent from 'components/multiFactorAuth/MfaSmsComponent';
import Alert from 'components/alertComponent';

export const MultiFactorAuthentication: React.FC<
  MultiFactorAuthenticationComponentProps
> = ({
  locale,
  country,
  phone,
  phoneError,
  countries,
  onCountryChange,
  onPhoneNumberChange,
}) => {
  return (
    <div className="flex flex-col items-start w-full gap-2.5 p-0">
      <div className="flex flex-row w-full items-center justify-between">
        <div className="flex flex-row items-center">
          <h3 className="text-h7 desktop:text-h7 text-dark-gray font-bold desktop:mr-2.5 uppercase">
            {locale.securityAndPrivacy.title}
          </h3>
        </div>
      </div>
      <p>
        <strong>{locale.securityAndPrivacy.pleaseNote}</strong>{' '}
        {locale.securityAndPrivacy.note}
      </p>

      <div className="w-full">
        {phoneError && (
          <div className="mb-[15px]">
            <Alert type="warning" text={phoneError} />
          </div>
        )}
        <MfaSmsComponent
          countries={countries}
          country={country}
          onCountryChange={onCountryChange}
          onPhoneNumberChange={onPhoneNumberChange}
          phoneNumber={phone}
        />
      </div>
    </div>
  );
};
