import { Link } from 'react-router-dom';
import { Enum_Legaldocument_Legaldocumenttype } from 'graphql/generated/strapi';
import { ReactComponent as CodexIcon } from 'assets/icons/codexLogo.svg';
import { FooterTypes } from './footer.types';
import { useGetLegalDocuments } from 'hooks/legalDocuments/useGetLegalDocuments';
import { pageIds } from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';

interface FooterProps {
  type?: FooterTypes;
  closeNavbarMobile?: () => void;
}

const containerSizeClasses = 'w-full h-[375px] desktop:h-20';
const containerPositionClasses =
  'flex flex-col desktop:flex-row desktop:justify-between items-start desktop:items-center';
const textClasses = 'text-sm text-center font-medium';
const linkClasses =
  'text-base desktop:text-link font-medium desktop:font-semibold underline';
const Footer: React.FC<FooterProps> = ({
  type = 'default',
  closeNavbarMobile,
}) => {
  const { legalDocuments } = useGetLegalDocuments({
    locale: 'en',
    legalDocumentTypes: [
      Enum_Legaldocument_Legaldocumenttype.TermsOfService,
      Enum_Legaldocument_Legaldocumenttype.PrivacyPolicy,
    ],
  });

  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.FOOTER_COMPONENT,
  });

  let backgroundColor: string,
    textColor: string,
    borderClass: string,
    iconClass: string;

  switch (type) {
    case 'login':
      backgroundColor = 'bg-transparent';
      textColor = 'text-white';
      borderClass = '';
      iconClass = 'stroke-white';
      break;
    case 'signup':
      backgroundColor = 'bg-base-content';
      textColor = 'text-med-gray';
      borderClass = '';
      iconClass = 'stroke-black';
      break;
    case 'navbar':
      backgroundColor = 'bg-transparent';
      textColor = 'text-med-gray';
      borderClass = '';
      iconClass = 'stroke-clc-black';
      break;
    default:
      backgroundColor = 'bg-footer';
      textColor = 'text-med-gray';
      borderClass = 'desktop:border-t';
      iconClass = 'stroke-black';
  }

  if (loading && !locale) return null;

  return (
    <div
      className={`${containerSizeClasses} ${containerPositionClasses} ${backgroundColor} ${textColor} ${borderClass} desktop:px-[135px] shrink-0`}
    >
      <div className="flex flex-col desktop:flex-row items-center mx-auto desktop:mr-1 desktop:ml-0">
        <CodexIcon
          className={`${iconClass} h-20 w-20 desktop:h-10 desktop:w-10 mt-8 desktop:mt-0 desktop:mr-4`}
        />
        <p className={`${textClasses} ${textColor} mt-10 desktop:mt-0`}>
          {locale?.copyrightText}
          <span className="desktop:hidden">
            <br />
          </span>{' '}
          {locale?.allRightsText}
        </p>
      </div>
      <div className="flex flex-col desktop:flex-row items-center mx-auto desktop:mr-0 desktop:ml-0">
        {legalDocuments?.map((document) => (
          <Link
            target="_blank"
            key={document.legalDocumentId}
            to={`/legal-documents/${document.legalDocumentId}`}
            className={`${linkClasses} ${textColor} mt-10 desktop:mt-0 desktop:mr-10`}
            onClick={closeNavbarMobile}
          >
            {document.legalDocumentName}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Footer;
