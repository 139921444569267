interface DropdownProps {
  options: string[];
  right?: boolean;
  fullWidth?: boolean;
  scrollableList?: boolean;
  onClick: (event: React.MouseEvent<HTMLElement>, option: string) => void;
}

const Dropdown: React.FC<DropdownProps> = ({
  options,
  right = false,
  fullWidth = false,
  scrollableList = false,
  onClick,
}) => {
  const alignment = right ? 'right-0' : 'left-0';
  return (
    <>
      {options.length >= 1 && (
        <div
          className={`${fullWidth ? 'w-full' : 'w-max'} ${
            scrollableList ? 'max-h-48 overflow-y-scroll' : ''
          } z-[1] flex flex-col ${alignment} items-start absolute top-full py-2.5 bg-white rounded-b-large rounded-l-large gap-px shadow-container`}
        >
          {options.map((option, index) => (
            <div
              key={index}
              className="flex flex-row w-full items-start px-5 py-[7px] hover:bg-light-blue gap-2.5 cursor-pointer"
              onClick={(event) => onClick(event, option)}
            >
              <p className="text-base font-semibold text-dark-gray">{option}</p>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default Dropdown;
