import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as MenuHamburgerIcon } from 'assets/icons/menu-hamburger.svg';
import { Section } from './navbar.interfaces';
import { Roles } from '../../firebase/interfaces';
import { useFetchUserByEmail, useFirebaseSignOut } from '../../firebase/hooks';
import { useNavbarOptions } from 'hooks/useNavbarOptions';
import { useGetPage } from 'hooks/useGetPage';
import { pageIds } from 'utilities/constants';
import { AUTH_LOGIN } from 'utilities/routes';

import {
  useClearAllSavedDnaScannedDataForUserIdMutation,
  useGetFileUrlFromStorageMutation,
} from 'graphql/generated/hasura';
import { AuthContext } from 'auth/context/AuthContext';
import Avatar from 'components/avatarComponent';
import Dropdown from 'components/dropdown/dropdownComponent';
import useDropdown from 'components/dropdown/useDropdown';

interface AuthenticatedNavbarProps {
  openNavbarMobile: () => void;
}

export const ProviderAuthenticatedNotApprovedNavbar: React.FC<
  AuthenticatedNavbarProps
> = ({ openNavbarMobile }) => {
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.NAVBAR_COMPONENT,
  });

  const {
    loadingOptions,
    MyAccountOptions,
    MyAccountRoutes,
    ProviderMyAccountOptions,
    ProviderNotApprovedMyAccountOptions,
    ProviderMyAccountRoutes,
    ProviderNotApprovedMyAccountRoutes,
  } = useNavbarOptions();

  const { profilePicture, setProfilePicture, user } = useContext(AuthContext);
  const [getFileUrlFromStorage] = useGetFileUrlFromStorageMutation({});
  const { fetchUserByEmailPromise } = useFetchUserByEmail();

  const userRole = user?.role;
  const [selectedSection, setSelectedSection] = useState<Section>();
  const navigate = useNavigate();
  const location = useLocation();
  const signOutUser = useFirebaseSignOut();
  const firstRendered = useRef(true);

  const getMyAccountOptionsBasedOnRole = () => {
    switch (userRole) {
      case Roles.PATIENT:
        return MyAccountOptions;
      case Roles.PROVIDER:
        return user?.providerEnabled
          ? ProviderMyAccountOptions
          : ProviderNotApprovedMyAccountOptions;
      default:
        return MyAccountOptions;
    }
  };

  const getMyAccountRoutesBasedOnRole = () => {
    switch (userRole) {
      case Roles.PATIENT:
        return MyAccountRoutes;
      case Roles.PROVIDER:
        return user?.providerEnabled
          ? ProviderMyAccountRoutes
          : ProviderNotApprovedMyAccountRoutes;
      default:
        return MyAccountRoutes;
    }
  };

  useEffect(() => {
    const getProfilePicture = async (profilePicture: string) => {
      const image = await getFileUrlFromStorage({
        variables: {
          fileId: profilePicture,
        },
      });
      setProfilePicture(image.data?.GetSignUrlFormStorage?.url || null);
    };
    if (
      (userRole === Roles.PATIENT ||
        userRole === Roles.PROVIDER ||
        userRole === Roles.ADMIN) &&
      user &&
      user.profilePicture &&
      firstRendered.current
    ) {
      getProfilePicture(user.profilePicture);
      firstRendered.current = false;
    }
  }, [
    userRole,
    user,
    getFileUrlFromStorage,
    setProfilePicture,
    fetchUserByEmailPromise,
  ]);

  useEffect(() => {
    const route = location.pathname.split('/')[1];
    setSelectedSection((route as Section) || Section.Dashboard);
  }, [location]);

  const {
    openDropdownId,
    handleDropdownClick,
    registerDropdownRef,
    handleHoverOut,
  } = useDropdown();

  const [clearAllSavedDnaScannedDataForUserIdMutation] =
    useClearAllSavedDnaScannedDataForUserIdMutation({
      variables: {
        userId: user?.uuid || '',
      },
    });

  const handleDropdownOptionSelected = async (
    section: Section,
    subRoute: string,
  ) => {
    if (subRoute === MyAccountRoutes[locale?.myAccountOptions.logOut]) {
      await clearAllSavedDnaScannedDataForUserIdMutation().catch((e) =>
        console.log('Error on clearing DNA saved data', e),
      );
      signOutUser();
      navigate(AUTH_LOGIN);
    } else if (section === Section.Shop) {
      window.open(
        `${locale?.shopOptionsUrl}${subRoute}`,
        '_blank',
        'noreferrer',
      );
    } else if (
      section === Section.MyAccount ||
      section === Section.ManageTestKits ||
      section === Section.Providers ||
      section === Section.Appointments
    ) {
      navigate(subRoute);
    } else if (section === Section.MySkin) {
      navigate(`${subRoute}`);
    } else {
      navigate(`/${section}/${subRoute}`);
    }
  };

  const isSectionSelected = (section: Section): boolean =>
    selectedSection === section;

  if ((loading && !locale) || loadingOptions) return null;

  return (
    <div className="flex flex-row h-full desktop:gap-12">
      <div className="hidden desktop:flex desktop:flex-row">
        <div
          className="hidden desktop:flex flex-col cursor-pointer relative"
          onClick={() => handleDropdownClick('my-account')}
          onMouseEnter={() => handleDropdownClick('my-account')}
          onMouseLeave={() => handleHoverOut()}
        >
          <div ref={(ref) => registerDropdownRef('my-account', ref)}>
            <div className="flex h-[84px] w-16 items-center justify-center pt-1 px-0">
              <Avatar size="sm" imageUrl={profilePicture} />
            </div>
            <div className="absolute bottom-0 w-full h-[30px] hover:bg-pnav-hover" />
            <div
              className={`flex h-1.5 ${
                isSectionSelected(Section.MyAccount) ? 'bg-clc-blue' : ''
              }`}
            />
            {openDropdownId === 'my-account' && (
              <Dropdown
                options={getMyAccountOptionsBasedOnRole()}
                right={true}
                onClick={(event, option) =>
                  handleDropdownOptionSelected(
                    Section.MyAccount,
                    getMyAccountRoutesBasedOnRole()[option].toString(),
                  )
                }
              />
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col desktop:hidden">
        <div
          className="flex h-[70px] w-[45px] items-center justify-center cursor-pointer"
          onClick={openNavbarMobile}
        >
          <Avatar size="xs" imageUrl={profilePicture} />
        </div>
      </div>
      <div className="flex desktop:hidden">
        <div className="flex h-[70px] desktop:h-[84px] w-[45px] desktop:w-16 items-center justify-center desktop:pt-1 desktop:px-0">
          <MenuHamburgerIcon
            className="h-8 w-[45px] cursor-pointer"
            onClick={openNavbarMobile}
          />
        </div>
      </div>
    </div>
  );
};
