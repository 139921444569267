import { AuthContext } from 'auth/context/AuthContext';
import { useAppendEncounterNotesToFhirAppointmentMutation } from 'graphql/generated/remote-schema-hasura';
import { useCallback, useContext, useRef } from 'react';
import { Roles } from '../firebase/interfaces';

export function useInAppointmentActions(
  currentAppointmentCodexId: string,
  notes?: string,
): {
  leftEvent: () => Promise<void>;
} {
  const [appendEncounterNotes] =
    useAppendEncounterNotesToFhirAppointmentMutation({});
  const { user } = useContext(AuthContext);

  const didLeftExecuted = useRef(false);

  const staleAppointmentCodexId = useRef(currentAppointmentCodexId);

  if (currentAppointmentCodexId && !staleAppointmentCodexId.current) {
    staleAppointmentCodexId.current = currentAppointmentCodexId;
  }

  const providerLeft = useCallback(async () => {
    await appendEncounterNotes({
      variables: {
        codexAppointmentId: staleAppointmentCodexId.current,
        followupRecommendations: '',
        providerNotes: notes ?? '',
        providerSummary: '',
      },
    });
  }, [appendEncounterNotes, notes]);

  const leftEvent = useCallback(async () => {
    if (didLeftExecuted.current) return;
    didLeftExecuted.current = true;
    const role = user?.role;
    if (role === Roles.PATIENT) {
      return;
    }
    return await providerLeft();
  }, [providerLeft, user?.role]);

  return { leftEvent };
}
