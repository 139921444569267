import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { StrichSDK } from '@pixelverse/strichjs-sdk';
import { getLaunchDarklyConfig, getStrichConfig } from 'utilities/config';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/cjs/Page/AnnotationLayer.css';
import 'react-pdf/dist/cjs/Page/TextLayer.css';
import { LocalesProvider } from 'apollo/LocalesProvider';
import { DataProvider } from 'apollo/DataProvider';

// Loads the worker from the unpkg CDN (needed for the PDF viewer)
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const { licenseKey } = getStrichConfig();
const { clientId: launchDarklyClientID } = getLaunchDarklyConfig();

StrichSDK.initialize(licenseKey)
  .then(() => console.log('Strich SDK initialized'))
  .catch((e) => console.error(`'Could not initialize Strich SDK': ${e}`));

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const router = createBrowserRouter(
  createRoutesFromElements(<Route path="*" element={<App />} />),
);

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: launchDarklyClientID,
    options: {
      sendEvents: false,
    },
  });
  root.render(
    <React.StrictMode>
      <LocalesProvider>
        <DataProvider>
          <LDProvider>
            <RouterProvider router={router} />
          </LDProvider>
        </DataProvider>
      </LocalesProvider>
    </React.StrictMode>,
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
