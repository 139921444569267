import InputComponent from 'components/inputComponent';
import {
  DynamicQuestionResponseSection,
  DynamicQuestionnaireAnswer,
  QuestionnaireResponseInterface,
  questionnaire_questions_question,
} from '../interfaces/dynamicQuestionnaireResponse.interface';

import { Dispatch, SetStateAction } from 'react';
import { HandleOnInputChangeType } from '../interfaces/dynamicQuestionnaire.interface';

interface DynamicQuestionnaireMixedInputsProps {
  question: questionnaire_questions_question;
  onChange: HandleOnInputChangeType;
  handleCheckboxToggle: (
    answer: string,
    questionID: number | undefined,
    questionParentID?: number,
    childrenOriginalQuestionID?: string,
  ) => void;
  section: DynamicQuestionResponseSection;
  questionParentID?: number;
  childrenOriginalQuestionID?: string | null;
  answerChecked?: string;
  radioCheckedState?: QuestionnaireResponseInterface[];
  setRadioCheckedState?: Dispatch<
    SetStateAction<QuestionnaireResponseInterface[]>
  >;
  checkboxesCheckedState?: QuestionnaireResponseInterface[];
  setCheckboxesCheckedState?: Dispatch<
    SetStateAction<QuestionnaireResponseInterface[]>
  >;
  isRequiredQuestion: boolean;
}

const DynamicQuestionnaireMixedInputs: React.FC<
  DynamicQuestionnaireMixedInputsProps
> = ({
  question,
  onChange,
  handleCheckboxToggle,
  section,
  questionParentID,
  childrenOriginalQuestionID,
  radioCheckedState,
  setRadioCheckedState,
  checkboxesCheckedState,
  setCheckboxesCheckedState,
  isRequiredQuestion,
}) => {
  const handleRadioClick = (value: string | null | undefined) => {
    const questionID = question.questionnaire_questions_question.id;
    const formattedValue: string | DynamicQuestionnaireAnswer[] = value || '';

    const getExistingRadio = (prevState: QuestionnaireResponseInterface[]) => {
      const existingSectionIndex = prevState.findIndex(
        (item: QuestionnaireResponseInterface) =>
          item.section === section.name && item.questionID === questionID,
      );

      if (existingSectionIndex !== -1) {
        const updatedState = [...prevState];
        updatedState[existingSectionIndex] = {
          section: section.name,
          response: value,
          questionID,
        };
        return updatedState;
      } else {
        return [
          ...prevState,
          { section: section.name, response: value, questionID },
        ];
      }
    };

    if (setRadioCheckedState) {
      setRadioCheckedState(
        (radioCheckedState: QuestionnaireResponseInterface[]) =>
          getExistingRadio(radioCheckedState),
      );
    }

    const getUpdatedSelectedCheckboxes = checkboxesCheckedState?.filter(
      (item: QuestionnaireResponseInterface) =>
        !(item.section === section.name && item.questionID === questionID),
    );

    if (getUpdatedSelectedCheckboxes && setCheckboxesCheckedState) {
      setCheckboxesCheckedState(getUpdatedSelectedCheckboxes);
    }

    onChange(
      formattedValue,
      question.questionnaire_questions_question.id,
      undefined,
      questionParentID,
      childrenOriginalQuestionID || '',
    );
  };

  const handleCheckboxClick = (value: string) => {
    const questionID = question.questionnaire_questions_question.id;

    const getClearedRadioFromCurrentSection = (
      prevState: QuestionnaireResponseInterface[],
    ) => {
      const sectionIndex = prevState.findIndex(
        (item) =>
          item.section === section.name && item.questionID === questionID,
      );
      if (sectionIndex !== -1) {
        const updatedRadio = [...prevState];
        updatedRadio[sectionIndex] = {
          ...updatedRadio[sectionIndex],
          response: undefined,
        };
        return updatedRadio;
      } else {
        return prevState;
      }
    };

    let updatedRadioCheckedState = null;

    if (radioCheckedState) {
      updatedRadioCheckedState =
        getClearedRadioFromCurrentSection(radioCheckedState);
    }

    if (setRadioCheckedState && updatedRadioCheckedState) {
      setRadioCheckedState(updatedRadioCheckedState);
    }

    handleCheckboxToggle(
      value,
      question.questionnaire_questions_question.id,
      questionParentID || 0,
      childrenOriginalQuestionID || '',
    );
  };

  const matchSelectedRadio = (stringToMatch: string) =>
    radioCheckedState?.find(
      (item: QuestionnaireResponseInterface) =>
        item.section === section.name &&
        item.response === stringToMatch &&
        item.questionID === question.questionnaire_questions_question.id,
    );

  const matchSelectedCheckboxes = (answerToMatch: string) =>
    checkboxesCheckedState?.some(
      (item: QuestionnaireResponseInterface) =>
        item.response === answerToMatch &&
        item.section === section.name &&
        item.questionID === question.questionnaire_questions_question.id,
    );

  return (
    <div>
      <InputComponent
        type="radio"
        radioInputProps={{
          radioError: null,
          radioInputValue: 'None of the below',
          radioInputLabel: 'None of the below',
          radioInputCheckedValue: matchSelectedRadio('None of the below')
            ? 'None of the below'
            : undefined,
          onRadioClick: () => handleRadioClick('None of the below'),
        }}
        isDisabled={!isRequiredQuestion}
      />
      <InputComponent
        type="radio"
        radioInputProps={{
          radioError: null,
          radioInputValue: 'All of the below',
          radioInputLabel: 'All of the below',
          radioInputCheckedValue: matchSelectedRadio('All of the below')
            ? 'All of the below'
            : undefined,

          onRadioClick: () => handleRadioClick('All of the below'),
        }}
        isDisabled={!isRequiredQuestion}
      />

      {question &&
        question.questionnaire_questions_question.dont_know_answer && (
          <InputComponent
            type="radio"
            radioInputProps={{
              radioError: null,
              radioInputValue:
                question.questionnaire_questions_question.dont_know_answer,
              radioInputLabel:
                question.questionnaire_questions_question.dont_know_answer,
              radioInputCheckedValue: matchSelectedRadio(
                question.questionnaire_questions_question.dont_know_answer,
              )
                ? question.questionnaire_questions_question.dont_know_answer
                : undefined,
              onRadioClick: () =>
                handleRadioClick(
                  question.questionnaire_questions_question.dont_know_answer,
                ),
            }}
            isDisabled={!isRequiredQuestion}
          />
        )}

      {question.questionnaire_questions_question.answers &&
        question.questionnaire_questions_question.answers.map((answer, key) => (
          <InputComponent
            key={key}
            type="checkbox"
            checkboxProps={{
              checkboxLabel: answer,
              checked: matchSelectedCheckboxes(answer),
              onCheckboxChange: () => handleCheckboxClick(answer),
              bottomMargin: true,
              enableVersatileClick: true,
            }}
            isDisabled={!isRequiredQuestion}
          />
        ))}
    </div>
  );
};

export default DynamicQuestionnaireMixedInputs;
