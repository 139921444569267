export enum UI_CONTROL {
  RADIO_BUTTON = 'RADIO_BUTTON',
  TEXT = 'TEXT',
  CHECKBOX = 'CHECKBOX',
  MIX = 'MIX',
  RADIO_WITH_IMAGE = 'RADIO_WITH_IMAGE',
  LOOKUP_FIELD = 'LOOKUP_FIELD',
  DOUBLE_TEXT_COMBO_L_HELPER_TEXT = 'DOUBLE_TEXT_COMBO_L_HELPER_TEXT',
  DOUBLE_DROPDOWN_COMBO = 'DOUBLE_DROPDOWN_COMBO',
  DROPDOWN_50_PERCENT = 'DROPDOWN_50_PERCENT',
  DROPDOWN_35_PERCENT = 'DROPDOWN_35_PERCENT',
  TEXT_AREA = 'TEXT_AREA',
  SMALL_TEXT = 'SMALL_TEXT',
  LOOKUP_WITH_MULTISELECT = 'LOOKUP_WITH_MULTISELECT',
  PHONE = 'PHONE',
  PASSWORD = 'PASSWORD',
  DATE = 'DATE',
  DATE_WITH_HOURS = 'DATE_WITH_HOURS',
  DATE_WITH_HOURS_TEXT = 'DATE_WITH_HOURS_TEXT',
  DATE_WITH_DROPDOWN = 'DATE_WITH_DROPDOWN',
  SLIDER = 'SLIDER',
  COMBO_TEXT_WITH_DROPDOWN = 'COMBO_TEXT_WITH_DROPDOWN',
  DATE_WITH_HOURS_DROPDOWN = 'DATE_WITH_HOURS_DROPDOWN',
}
