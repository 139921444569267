import { PreworkItemContainer } from './PreworkItemContainer';

interface PreworkSymptomsProps {
  question: string;
  symptoms: string[];
}

export const PreworkSymptoms: React.FC<PreworkSymptomsProps> = ({
  question,
  symptoms,
}) => {
  return (
    <PreworkItemContainer question={question}>
      <ul className="text-med-gray list-disc ml-6">
        {symptoms &&
          symptoms.map((symptom, key) => <li key={key}>{symptom}</li>)}
      </ul>
    </PreworkItemContainer>
  );
};
