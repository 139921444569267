import { singleOrDefault } from 'utilities/functions';

export class UrlModalSearchManager {
  public static appendModal(search: string, key: string, state: unknown) {
    const builder = new UrlModalSearchManager(search);
    builder.addModal(key, state);
    return builder.toSearch();
  }

  public static withoutModal(search: string, key: string) {
    const builder = new UrlModalSearchManager(search);
    builder.removeModal(key);
    return builder.toSearch();
  }

  private readonly data: Record<string, string[]>;
  constructor(original: string) {
    const params = new URLSearchParams(original);
    this.data = [...params.keys()].reduce((pv, cv) => {
      pv[cv] = params.getAll(cv);
      return pv;
    }, {} as Record<string, string[]>);
  }

  public removeModal(key: string) {
    const modal = (this.data['modal'] ?? []).flatMap((d) => d.split(','));
    const idx = modal.findIndex((m) => m === key);
    if (idx >= 0) modal.splice(idx, 1);
    this.data['modal'] = modal;
    if (!this.data['modal'].length) delete this.data['modal'];
    const otherKeys = [`${key}:state`];
    otherKeys.forEach((k) => {
      delete this.data[k];
    });
  }

  public addModal(key: string, state: unknown) {
    const modal = (this.data['modal'] ?? []).flatMap((d) => d.split(','));
    modal.push(key);
    this.data['modal'] = modal;
    this.data[`${key}:state`] = [JSON.stringify(state)];
  }

  public toSearch() {
    const search = new URLSearchParams();
    Object.keys(this.data).forEach((k) => {
      search.set(k, this.data[k].join(','));
    });
    return search.toString();
  }

  public getModals(): { modal: string; state: unknown }[] {
    const modal = (this.data['modal'] ?? []).flatMap((d) => d.split(','));
    return modal.map((m) => ({
      modal: m,
      state: JSON.parse(singleOrDefault(this.data[`${m}:state`]) ?? '{}'),
    }));
  }

  public static getModals(search: string): { modal: string; state: unknown }[] {
    const mgr = new UrlModalSearchManager(search);
    return mgr.getModals();
  }
}
