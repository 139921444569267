import InputComponent from 'components/inputComponent';
import { QuestionnaireResponseInterface } from '../interfaces/dynamicQuestionnaireResponse.interface';
import { HandleOnInputChangeType } from '../interfaces/dynamicQuestionnaire.interface';
import { questionnaire_questions_question } from '../interfaces/dynamicQuestionnaireResponse.interface';
import { Dispatch, SetStateAction, useMemo } from 'react';

interface ThirdLevelNestedQuestionProps {
  questionToRender: questionnaire_questions_question | null;
  questionParentID: number;
  childrenOriginalQuestionID: string | null | undefined;
  onChange: HandleOnInputChangeType;
  textFieldResponses: QuestionnaireResponseInterface[] | undefined;
  setTextFieldResponses:
    | Dispatch<SetStateAction<QuestionnaireResponseInterface[]>>
    | undefined;
  isRequiredQuestion: boolean;
  isIncompleteNestedQuestion: boolean;
}

const ThirdLevelNestedQuestion: React.FC<ThirdLevelNestedQuestionProps> = ({
  questionToRender,
  questionParentID,
  childrenOriginalQuestionID,
  onChange,
  textFieldResponses,
  setTextFieldResponses,
  isRequiredQuestion,
  isIncompleteNestedQuestion,
}) => {
  const useMemoizedGetResponseByQuestionID = (
    questionIDToFind: number,
    textFieldResponses: QuestionnaireResponseInterface[] | undefined,
  ) => {
    const getResponseByQuestionID = useMemo(() => {
      const foundResponse = textFieldResponses?.find(
        (response) => response.questionID === questionIDToFind,
      );

      return foundResponse ? (foundResponse.response as string) : '';
    }, [textFieldResponses, questionIDToFind]);

    return getResponseByQuestionID;
  };

  const handleTextFieldResponses = (value: string) => {
    if (Array.isArray(textFieldResponses) && setTextFieldResponses) {
      // Check if the questionID already exists in the array
      const existingIndex = textFieldResponses.findIndex(
        (response) => response.questionID === questionParentID,
      );

      if (existingIndex !== -1) {
        // If the questionID exists, update the existing item
        const updatedResponses = [...textFieldResponses];
        updatedResponses[existingIndex].response = value;
        setTextFieldResponses(updatedResponses);
      } else {
        // If the questionID doesn't exist, create a new response
        const newResponse: QuestionnaireResponseInterface = {
          questionID: questionParentID,
          response: value,
        };

        setTextFieldResponses([...textFieldResponses, newResponse]);
      }
    }
  };

  return (
    <div className="pt-5">
      <div className="mb-2.5">
        <p
          className={`${
            isIncompleteNestedQuestion ? 'text-clc-red' : 'text-dark-gray'
          } font-semibold `}
        >
          {questionToRender?.questionnaire_questions_question.text}
          {isRequiredQuestion && (
            <span className="text-base font-bold text-clc-red"> *</span>
          )}
        </p>
        {questionToRender?.questionnaire_questions_question.subtitle && (
          <p className="italic text-med-gray">
            {questionToRender?.questionnaire_questions_question.subtitle}
          </p>
        )}
      </div>
      <InputComponent
        type="text"
        value={useMemoizedGetResponseByQuestionID(
          questionParentID,
          textFieldResponses,
        )}
        onChange={(e) => {
          onChange(
            questionToRender?.questionnaire_questions_question.text || '',
            questionToRender?.questionnaire_questions_question.id || 0,
            e.target.value,
            questionParentID,
            undefined,
            childrenOriginalQuestionID,
            true,
          );
          textFieldResponses && handleTextFieldResponses(e.target.value);
        }}
        isDisabled={!isRequiredQuestion}
      />
    </div>
  );
};

export default ThirdLevelNestedQuestion;
