import { FullscriptAuthLinkState } from 'app/fullscript/fullscript.interface';
import Loader from 'components/loaderComponent';
import { PrescribeMedicationModal } from 'components/PrescribeMedicationModal';
import { useLinkFullscriptProviderAccountMutation } from 'graphql/generated/remote-schema-hasura';
import { useModal } from 'layout/useModal';
import { useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { VIRTUAL_PHARMACIES } from 'utilities/constants';
import {
  DASHBOARD,
  MY_PATIENTS,
  MY_PATIENTS_BIO_INFO,
  MY_PATIENTS_BIO_INFO_PATIENT_INFO,
} from 'utilities/routes';

const CODE_SEARCH_PARAM = 'code';
const STATE_SEARCH_PARAM = 'state';

const FullscriptLink: React.FC = () => {
  const [searchParams] = useSearchParams();
  const queryRun = useRef(false);
  const navigate = useNavigate();
  const [openPrescriptionModal] = useModal(PrescribeMedicationModal);
  const onSuccess = async (state: FullscriptAuthLinkState) => {
    await openPrescriptionModal({
      platform: VIRTUAL_PHARMACIES.fullscript,
      patientId: state.patientId,
      encounterId: state.appointmentId,
    });
    navigate(
      `${MY_PATIENTS}/${state.patientId}/${MY_PATIENTS_BIO_INFO}/${MY_PATIENTS_BIO_INFO_PATIENT_INFO}`,
    );
  };
  const onFailure = (state: Partial<FullscriptAuthLinkState>) => {
    if (state.patientId) {
      navigate(
        `${MY_PATIENTS}/${state.patientId}/${MY_PATIENTS_BIO_INFO}/${MY_PATIENTS_BIO_INFO_PATIENT_INFO}`,
      );
    } else {
      navigate(DASHBOARD);
    }
  };
  const [linkProvider] = useLinkFullscriptProviderAccountMutation({
    onCompleted: (data) => {
      if (!searchParams.has(STATE_SEARCH_PARAM)) {
        console.log('Missing Fullscript State Search Parameters');
      }

      const state: FullscriptAuthLinkState = JSON.parse(
        decodeURI(searchParams.get(STATE_SEARCH_PARAM) as string),
      ) as FullscriptAuthLinkState;

      if (state.patientId && data.linkProviderAccount?.success) {
        return onSuccess(state);
      }
      return onFailure(state);
    },
  });
  useEffect(() => {
    if (queryRun.current || !searchParams.has(CODE_SEARCH_PARAM)) return;
    const code = searchParams.get(CODE_SEARCH_PARAM) as string;
    queryRun.current = true;
    linkProvider({ variables: { tokenCode: code } });
  }, [linkProvider, searchParams]);

  return (
    <div className="w-full h-full flex justify-center items-center">
      <Loader />
    </div>
  );
};

export default FullscriptLink;
