import { render, renderers } from 'app/superadmin/defaultRenderers';
import React from 'react';

type DataTableKeyColumnProps<T> = {
  title?: string;
  dataKey: keyof T;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderer?: string | ((value: any, item: T) => React.ReactNode);
  width?: string;
};
type DataTableDynamicColumnProps<T> = {
  title: string;
  renderer: (item: T) => React.ReactNode;
  width?: string;
};
type DataTableKeyColumnType<T> = React.FC<DataTableKeyColumnProps<T>>;
type DataTableDynamicColumnType<T> = React.FC<DataTableDynamicColumnProps<T>>;

type DataTableType<T> = React.FC<{
  children:
    | React.ReactElement<DataTableKeyColumnProps<T>>[]
    | React.ReactElement<DataTableDynamicColumnProps<T>>[]
    | React.ReactElement<DataTableKeyColumnProps<T>>
    | React.ReactElement<DataTableDynamicColumnProps<T>>;
  data: T[];
  getKey: (item: T) => string;
}>;

export function makeTableComponent<T>(): DataTableType<T> & {
  KeyColumn: DataTableKeyColumnType<T>;
  DynamicColumn: DataTableDynamicColumnType<T>;
} {
  const table: DataTableType<T> = ({ children, data, getKey }) => {
    const childItems = Array.isArray(children) ? children : [children];
    const getColumnTitle: (column: (typeof childItems)[number]) => string = (
      c,
    ) =>
      (
        c.props.title ??
        (c.props as DataTableKeyColumnProps<T>).dataKey ??
        'undefined'
      ).toString();

    return (
      <table cellPadding="10px">
        <thead>
          <tr>
            {childItems.map((c) => {
              const title = getColumnTitle(c);
              return <th key={`column-${title}`}>{title} </th>;
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((item, idx) => {
            const key = getKey(item);
            return (
              <tr
                key={key}
                className={`${idx % 2 === 0 ? 'bg-white' : 'bg-base-content'}`}
              >
                {childItems.map((c) => {
                  const props = c.props as DataTableKeyColumnProps<T>;
                  const columnKey = getColumnTitle(c);
                  const dataKey = props.dataKey;
                  if (dataKey) {
                    const value = (item as T)[dataKey];
                    const renderer =
                      typeof props.renderer === 'string'
                        ? renderers[props.renderer]
                        : props.renderer ?? renderers[dataKey as string];

                    return (
                      <td
                        key={`column-${columnKey}`}
                        className={`whitespace-nowrap w-[${c.props.width}]`}
                      >
                        <>{renderer?.(value, item) ?? render(value)}</>
                      </td>
                    );
                  } else {
                    const renderer =
                      typeof c.props.renderer === 'string'
                        ? renderers[c.props.renderer]
                        : (props.renderer as  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            | ((item: any) => React.ReactElement)
                            | undefined);
                    return (
                      <td
                        key={`column-${columnKey}`}
                        className={`whitespace-nowrap w-[${c.props.width}]`}
                      >
                        {renderer?.(item)}
                      </td>
                    );
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const keyColumn: DataTableKeyColumnType<T> = () => <></>;

  const dynamicColumn: DataTableDynamicColumnType<T> = () => <></>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (table as any).KeyColumn = keyColumn;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (table as any).DynamicColumn = dynamicColumn;
  return table as DataTableType<T> & {
    KeyColumn: DataTableKeyColumnType<T>;
    DynamicColumn: DataTableDynamicColumnType<T>;
  };
}
