import ButtonComponent from 'components/button/buttonComponent';
import Loader from 'components/loaderComponent';
import {
  useGetDiagnosticReportsQuery,
  useGetSignedUrlFromStorageByAdminMutation,
} from 'graphql/generated/hasura';
import React, { useMemo, useState } from 'react';
import { SAPerson } from '../types/SAPerson';
import { SAReportsType } from '../types/SAReportsType';
import { DateWithTimeTooltip } from 'app/superadmin/components/DateWithTimeTooltip';
import { makeTableComponent } from 'app/superadmin/components/DataTable';
import Tooltip from 'components/Tooltip';
import { renderers } from 'app/superadmin/defaultRenderers';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import { useParams } from 'react-router-dom';

export const Expandable: React.FC<{
  minText: string;
  children: React.ReactNode;
}> = ({ minText, children }) => {
  const [expanded, setExpanded] = useState(false);
  if (!expanded) {
    return (
      <ButtonComponent type="underline" onClick={() => setExpanded(true)}>
        {minText}
      </ButtonComponent>
    );
  } else {
    return (
      <>
        <ButtonComponent
          type="underline"
          className="w-auto"
          onClick={() => setExpanded(false)}
          Icon={Close}
        />
        <hr />
        {children}
      </>
    );
  }
};

export const nameRenderer = (p: SAPerson) => {
  if (!p) return <></>;
  const name = (p ? `${p?.last ?? ''}, ${p?.first ?? ''}` : '-').trim();

  if (name.length > 40) {
    return (
      <>
        <Tooltip text={name}>{name.substring(0, 40)} [..]</Tooltip>
        <hr />
        <Expandable minText="view identifiers">
          {renderers['identifiers'](p.identifiers)}
        </Expandable>
      </>
    );
  } else {
    return (
      <>
        {name}
        <hr />
        <Expandable minText="view identifiers">
          {renderers['identifiers'](p.identifiers)}
        </Expandable>
      </>
    );
  }
};

export const Tests = () => {
  const params = useParams();
  const [getSignedUrlFromStorageByAdmin] =
    useGetSignedUrlFromStorageByAdminMutation({});

  const getFileSignedUrl = async (userId: string, fileId: string) => {
    try {
      const signedUrl = await getSignedUrlFromStorageByAdmin({
        variables: { userId: userId, fileId: fileId },
      });

      if (signedUrl.data?.GetSignUrlFromStorageByAdmin?.url) {
        window.open(
          signedUrl.data?.GetSignUrlFromStorageByAdmin?.url,
          '_blank',
        );
      }
    } catch (error) {
      console.error(
        'There was an error fetching the signed url by an admin.',
        error,
      );
    }
  };

  const { loading, data } = useGetDiagnosticReportsQuery({
    variables: { limit: 500, ...params },
  });

  const Table = useMemo(() => makeTableComponent<SAReportsType>(), []);

  if (loading) return <Loader />;

  return (
    <div>
      Tests
      <hr />
      {data?.getDiagnosticReports.reports.length && (
        <Table
          data={data?.getDiagnosticReports.reports}
          getKey={(item) =>
            item.identifiers.find((id) => id[0] === 'fhir')?.[1] ?? 'never'
          }
        >
          <Table.KeyColumn dataKey="identifiers" />
          <Table.KeyColumn dataKey="type" />
          <Table.DynamicColumn
            title="created"
            renderer={(item) => (
              <DateWithTimeTooltip
                isoDate={item.dates.find((d) => d[0] === 'created')?.[1]}
              />
            )}
          />
          <Table.DynamicColumn
            title="updated"
            renderer={(item) => (
              <DateWithTimeTooltip
                isoDate={item.dates.find((d) => d[0] === 'lastUpdated')?.[1]}
              />
            )}
          />
          <Table.DynamicColumn
            title="artifacts"
            renderer={(item: SAReportsType) => {
              const items = item.artifacts;
              const userId = item.patient?.identifiers.find(
                ([type]) => type === 'codex',
              )?.[1];
              return (
                <table>
                  <tbody>
                    {items.map(([type, _, id]) => (
                      <tr key={type}>
                        <td>
                          <ButtonComponent
                            type="underline"
                            className="w-auto"
                            onClick={() =>
                              userId ? getFileSignedUrl(userId, id) : undefined
                            }
                          >
                            {type}
                          </ButtonComponent>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              );
            }}
          />
          <Table.KeyColumn
            dataKey="provider"
            renderer={nameRenderer}
            width="150px"
          />
          <Table.KeyColumn
            dataKey="patient"
            renderer={nameRenderer}
            width="150px"
          />
          <Table.KeyColumn dataKey="status" />
        </Table>
      )}
      {!data?.getDiagnosticReports.reports.length && <>No Records Found</>}
    </div>
  );
};
