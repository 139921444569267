import { useState } from 'react';
import { ReactComponent as ChevronLeftIcon } from 'assets/icons/chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from 'assets/icons/chevron-right.svg';
import { Steps } from '../interfaces/steps.interfaces';
import CarouselArrow from './carouselArrowComponent';
import CarouselDots from './carouselDotsComponent';

interface ScanCarouselProps {
  steps: Steps[];
}

const ScanCarousel: React.FC<ScanCarouselProps> = ({ steps }) => {
  const [selectedStepIndex, setSelectedStepIndex] = useState(0);

  const incrementStepIndex = () => {
    setSelectedStepIndex((index) => (index + 1) % steps.length);
  };

  const decrementStepIndex = () => {
    setSelectedStepIndex((index) => (index - 1 + steps.length) % steps.length);
  };

  const selectedStep = steps[selectedStepIndex];

  return (
    <>
      <div className="flex flex-row w-full items-center justify-between p-0 gap-[15px] desktop:hidden">
        {steps.length > 1 && (
          <CarouselArrow onClick={decrementStepIndex} icon={ChevronLeftIcon} />
        )}

        <div className="flex flex-col w-full items-center gap-2.5 px-[15px] desktop:grid-cols-4">
          <img
            className="w-[195px] h-[195px] rounded-10 desktop:w-20 desktop:h-[100px]"
            src={selectedStep.imageUrl}
            alt="Avatar"
          />
          <p className="text-base text-med-gray font-medium">
            {selectedStep.description}
          </p>
        </div>

        {steps.length > 1 && (
          <CarouselArrow onClick={incrementStepIndex} icon={ChevronRightIcon} />
        )}
      </div>

      {steps.length > 1 && (
        <div className="flex flex-row self-center pt-[17px] desktop:hidden">
          <CarouselDots
            carouselLength={steps.length}
            selectedDotIndex={selectedStepIndex}
          />
        </div>
      )}
      <div className="hidden desktop:grid desktop:grid-cols-4 desktop:w-full desktop:gap-4">
        {steps.map((step, index) => (
          <div
            key={index}
            className="flex flex-col items-center gap-y-[15px] gap-x-[30px]"
          >
            <img
              className="w-[195px] h-[195px] rounded-10"
              src={step.imageUrl}
              alt="Avatar"
            />
            <p className="text-base text-med-gray font-medium">
              {step.description}
            </p>
          </div>
        ))}
      </div>
    </>
  );
};

export default ScanCarousel;
