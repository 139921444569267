import { useLocation, useNavigate } from 'react-router-dom';

import {
  genericActionsIds,
  pageIds,
  questionnairesIds,
} from 'utilities/constants';
import { ReactComponent as ChevronLeftIcon } from 'assets/icons/chevron-left.svg';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import { useGetPage } from 'hooks/useGetPage';
import Loader from 'components/loaderComponent';
import DynamicQuestionnaire from 'components/dynamicQuestionnaire/DynamicQuestionnaireComponent';
import ButtonComponent from 'components/button/buttonComponent';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  CodexFeatureFlags,
  SUPPORTED_GENDERS_ASSIGNED_AT_BIRTH,
} from 'utilities/interfaces';
import { useState } from 'react';
import {
  useGetFhirPatientbyCodexIdQuery,
  useGetFhirQuestionnaireByIdQuery,
} from 'graphql/generated/remote-schema-hasura';

export const HormoneQuestionnaire = () => {
  const location = useLocation();
  const testKitData = location.state.testKitData;
  const [gender, setGender] =
    useState<SUPPORTED_GENDERS_ASSIGNED_AT_BIRTH | null>(null);

  const { loading: fhirPatientDataLoading } = useGetFhirPatientbyCodexIdQuery({
    onCompleted(data) {
      if (data?.getFHIRPatientbyCodexID.users.length > 0) {
        const user = data?.getFHIRPatientbyCodexID.users[0];
        setGender(
          (user?.SENSITIVE_gender ||
            null) as SUPPORTED_GENDERS_ASSIGNED_AT_BIRTH,
        );
      }
    },
  });

  const assignQuestionnaireBasedOnGender = (): number => {
    let questionnaireID = 0;

    if (gender === 'Female') {
      questionnaireID = questionnairesIds.HORMONE_QUESTIONNAIRE_FEMALE;
    }

    if (gender === 'Male') {
      questionnaireID = questionnairesIds.HORMONE_QUESTIONNAIRE_MALE;
    }

    return questionnaireID;
  };

  const { hormoneQuestionnaireRouteDisabled } = useFlags<CodexFeatureFlags>();
  const navigate = useNavigate();

  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.BACK],
    });

  const { data: locale, loading: localeLoading } = useGetPage({
    locale: 'en',
    pageId: pageIds.REGISTER_TEST_KIT,
  });

  const { data, loading } = useGetFhirQuestionnaireByIdQuery({
    variables: {
      questionnaire_id: assignQuestionnaireBasedOnGender(),
    },
  });

  if (hormoneQuestionnaireRouteDisabled !== false) {
    navigate('/');
  }

  if (
    loading ||
    genericActionLoading ||
    !genericAction ||
    !locale ||
    localeLoading ||
    gender === null ||
    fhirPatientDataLoading
  )
    return <Loader />;

  return (
    <div>
      <div className="flex justify-start w-16">
        <ButtonComponent
          type="underline"
          Icon={ChevronLeftIcon}
          iconPosition="left"
          iconWidth="w-2.5"
          iconHeight="h-[18px]"
          onClick={() => navigate(-1)}
        >
          <p className="uppercase">
            {genericAction?.[genericActionsIds.BACK].back}
          </p>
        </ButtonComponent>
      </div>
      <div className="mt-[30px]">
        {data && (
          <DynamicQuestionnaire
            testKitData={testKitData}
            enableSubmitCheckbox={{
              enable: false,
            }}
            id={assignQuestionnaireBasedOnGender()}
            response={data?.getFHIRQuestionnaireByCodexID}
          />
        )}
      </div>
    </div>
  );
};
