import generateKey from './../sframe/generateKey';
import arrayBufferToHex from './arrayBufferToHex';

// Note: this same algorithm is being use by the native SDKs.  (We have a unit
// test to confirm correctness as well).
const extractSenderIdForStandardSFrame = async (sessionId, connectionId) => {
  const key = await generateKey(sessionId, connectionId);
  const exportedKey = await window.crypto.subtle.exportKey('raw', key);
  const trimmedKeyBuffer = [...new Uint8Array(exportedKey)];

  // We trim to workaround javascript's issues with large integers
  trimmedKeyBuffer.splice(6);
  const keyAsHex = arrayBufferToHex(trimmedKeyBuffer);

  return parseInt(keyAsHex, 16);
};

// This function extracts the senderId, which consists in max 9 numbers.
// Currently, the input is the connection ID.
// For example, "5a12917d-395b-483a-bbd7-a91dfa4c2a53" -> "512917395".
const extractSenderIdForLegacySFrame = (connectionId) => {
  const extractNumbers = text => (text ? text.replace(/\D+/g, '') || '0' : '0');
  const truncate = text => text.substring(0, 9);
  const parseToInt = text => parseInt(text, 10);

  return parseToInt(truncate(extractNumbers(connectionId)));
};

export default async ({ sessionId, connectionId, useDeprecatedMethod = true }) => {
  if (useDeprecatedMethod) {
    return extractSenderIdForLegacySFrame(connectionId);
  }

  return extractSenderIdForStandardSFrame(sessionId, connectionId);
};
