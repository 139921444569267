import _ from 'lodash';
import InputComponent, {
  SelectWithFlagsValues,
} from 'components/inputComponent';
import {
  DropdownItem,
  DropdownOption,
  DropdownOptionsState,
  DynamicQuestionResponseSection,
  QuestionnaireResponseInterface,
  questionnaire_questions_question,
} from '../interfaces/dynamicQuestionnaireResponse.interface';
import {
  generateIdsFromContactInfoToMergeIntoPersistentData,
  getUiControlType,
  parseIso,
  removeParentEntriesFromPersistentData,
  updatePersistentDataWithDynamicData,
  validateQuestionResponse,
} from '../utils/utils';
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  parseMappedCountriesIntoSelectValues,
  parseMultipleValuesIntoSelectFormat,
  parseQuestionOptionsForDropdownWithProperties,
  parseSelectedValue,
  parseValueIntoSelectFormat,
  validateNPI,
} from 'utilities/functions';
import { useGetCountriesQuery } from 'graphql/generated/hasura';
import { UI_CONTROL } from '../interfaces/uiControl.interface';
import {
  SignUpProviderQuestionnaireModuleProps,
  PhoneDataProps,
} from '../interfaces/SignUpProviderModule.interface';
import { useGetComponent } from 'hooks/useGetComponent';
import {
  DEFAULT_COUNTRY_CODE_NUMBER,
  ISO_CODE_TO_EXCLUDE,
  MATCHES_NON_DIGIT_CHARACTER,
  MAX_ZIP_CODE_LENGTH,
  MIN_ZIP_CODE_LENGTH,
  NON_ALPHABETIC_REGEX_PRESERVES_SPACES_AND_CHARACTERS,
  STATES_TO_EXCLUDE,
  componentIds,
  pageIds,
} from 'utilities/constants';
import IconComponent from 'components/iconComponent';
import { ReactComponent as InfoIcon } from 'assets/icons/weatherIcons/question.svg';
import { Link } from 'react-router-dom';
import { AUTH_SIGN_UP_PROVIDER_NPI_MODAL } from 'utilities/routes';
import { useIsMobile } from 'hooks/useIsMobile';
import Tooltip from 'components/Tooltip';
import useFormValidation, {
  Errors,
  ValidationRules,
} from 'hooks/useFormValidation';
import ErrorMessageWithIcon from 'components/errorMessageWithIcon';
import {
  AddressInformation,
  ProviderDataLocalForm,
} from '../interfaces/signUpProviderResponse.interface';
import {
  emailPattern,
  min7OnlyDigitsPattern,
  passwordPattern,
} from 'utilities/variables';
import { ModuleFormErrors } from '../interfaces/dynamicQuestionnaire.interface';
import { CountryItem } from '../interfaces/dynamicQuestionnaire.interface';
import CreatableSelect from 'react-select/creatable';
import Select, {
  MultiValue,
  Options,
  SingleValue,
  components,
} from 'react-select';
import {
  SPECIFIC_UNAUTHENTICATED_QUESTION_IDS,
  SPECIFIC_UNAUTHENTICATED_SECTION,
  initialSections,
  SignUpProviderSkipRenderingIds,
  staticIdsForAddressContactInfo,
  signUpValidationFunctionsRules,
  allowOnlyCharactersInCityField,
} from '../utils/constants';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plusIcon.svg';
import { ReactComponent as MinusIcon } from '../../../assets/icons/minusIcon.svg';
import { State, City } from 'country-state-city';
import { useGetPage } from 'hooks/useGetPage';

const SignUpProviderQuestionnaireModule: React.FC<
  SignUpProviderQuestionnaireModuleProps
> = ({
  questionList,
  onChange,
  textFieldResponses,
  setTextFieldResponses,
  requiredQuestionsIds,
  setModuleFormErrors,
  currentSection,
  radioCheckedState,
  matchSelectedRadio,
  handleRadioClick,
  section,
  nestedQuestionList,
  renderNestedQuestions,
  onDropdownWithMultiSelectChange,
  setPersistentData,
  isSubmitClicked,
  setIsSubmitClicked,
  moduleFormErrors,
}) => {
  const isMobile = useIsMobile();
  const [selectedCountry, setSelectedCountry] = useState<CountryItem>({
    label: null,
    value: null,
    flag: null,
  });

  const [phoneData, setPhoneData] = useState<PhoneDataProps>({
    phone: '',
    country: DEFAULT_COUNTRY_CODE_NUMBER,
    extension: '',
  });

  const [officePhoneData, setOfficePhoneData] = useState<PhoneDataProps>({
    phone: '',
    country: DEFAULT_COUNTRY_CODE_NUMBER,
    extension: '',
  });

  const [phoneQuestionData, setPhoneQuestionData] =
    useState<questionnaire_questions_question[]>();

  const [triggerPhoneChange, setTriggerPhoneChange] = useState(false);
  const [triggerOfficePhoneChange, setTriggerOfficePhoneChange] =
    useState(false);

  const [officeAddressInformation, setOfficeAddressInformation] =
    useState<AddressInformation>({
      city: '',
      country: '',
      state: '',
    });

  const [personalAddressInformation, setPersonalAddressInformation] =
    useState<AddressInformation>({
      city: '',
      country: '',
      state: '',
    });

  const [officeCountryIso, setOfficeCountryIso] = useState<string>(
    parseIso(officeAddressInformation.country) ?? '',
  );

  const [personalCountryIso, setPersonalCountryIso] = useState<string>(
    parseIso(personalAddressInformation.country) ?? '',
  );

  const [officeStateData, setOfficeStateData] = useState<string[]>([
    officeAddressInformation.state || '',
  ]);

  const [personalStateData, setPersonalStateData] = useState<string[]>([
    personalAddressInformation.state || '',
  ]);

  const [officeStateIso, setOfficeStateIso] = useState<string>(
    parseIso(officeAddressInformation.state) || '',
  );

  const [personalStateIso, setPersonalStateIso] = useState<string>(
    parseIso(personalAddressInformation.state) || '',
  );

  const [officeCityData, setOfficeCityData] = useState<string[]>([
    officeAddressInformation.city || '',
  ]);

  const [personalCityData, setPersonalCityData] = useState<string[]>([
    personalAddressInformation.city || '',
  ]);

  const [textLocalFieldResponses, setTextLocalFieldResponses] = useState<
    QuestionnaireResponseInterface[]
  >([]);

  const [isOfficeCountryClicked, setIsOfficeCountryClicked] =
    useState<boolean>(false);

  const [isPersonalCountryClicked, setIsPersonalCountryClicked] =
    useState<boolean>(false);

  const [isOfficeStateClicked, setIsOfficeStateClicked] =
    useState<boolean>(false);

  const [isPersonalStateClicked, setIsPersonalStateClicked] =
    useState<boolean>(false);

  const [educationList, setEducationList] = useState<
    QuestionnaireResponseInterface[]
  >([{ id: 0, title: 'Education List 1', completed: false, answers: [] }]);

  const [
    isSameOfficeAddresCheckboxChecked,
    setIsSameOfficeAddresCheckboxChecked,
  ] = useState(false);

  const setSelectStorageValues = useCallback((): DropdownOptionsState[] => {
    const defaultValue: DropdownOptionsState[] = [
      { value: [] },
      { value: [] },
      { value: [] },
      { value: [] },
      { value: [] },
      { value: [] },
      { value: [] },
    ];
    return defaultValue;
  }, []);

  const [selectedValue, setSelectedValue] = useState<DropdownOptionsState[]>(
    setSelectStorageValues(),
  );

  const [triggerCopyData, setTriggerCopyData] = useState(false);

  const [enableErrorsFeedbackUser, setEnableErrorsFeedbackUser] =
    useState(false);

  const { data: locale, loading } = useGetComponent({
    locale: 'en',
    componentId: componentIds.SIGN_UP_PROVIDER_MODULE,
  });
  const { data: localeProvider, loading: localesLoading } = useGetPage({
    locale: 'en',
    pageId: pageIds.MY_PROFILE,
  });

  const isRequiredQuestion = (questionId: string): boolean => {
    const isRequired = requiredQuestionsIds.find((id) => id === questionId);
    return Boolean(isRequired);
  };

  const handleTextFieldResponses = (
    value: string,
    questionID: number,
    question: questionnaire_questions_question,
    responsePropertyValue: string | null | undefined,
  ) => {
    if (Array.isArray(textFieldResponses) && setTextFieldResponses) {
      // Check if the questionID already exists in the array
      const existingIndex = textFieldResponses.findIndex(
        (response) => response.questionID === questionID,
      );

      if (existingIndex !== -1) {
        // If the questionID exists, update the existing item
        const updatedResponses = [...textFieldResponses];
        updatedResponses[existingIndex].response = value;
        setTextFieldResponses(updatedResponses);

        onChange(
          [
            {
              response: value,
              responsePropertyValue: responsePropertyValue,
              questionID: questionID,
            },
          ],
          question?.questionnaire_questions_question.id || 0,
        );
      } else {
        // If the questionID doesn't exist, create a new response
        const newResponse: QuestionnaireResponseInterface = {
          questionID: questionID,
          response: value,
          responsePropertyValue: responsePropertyValue,
        };

        setTextFieldResponses([...textFieldResponses, newResponse]);

        onChange(
          [
            {
              response: value,
              responsePropertyValue: responsePropertyValue,
              questionID: questionID,
            },
          ],
          question?.questionnaire_questions_question.id || 0,
        );
      }
    }
  };

  const { data, loading: loadingCountries } = useGetCountriesQuery({});

  const mappedCountriesValues = useMemo(() => {
    return data?.countries
      ? [...data.countries]
          .sort((a, b) => {
            const codea = parseInt(a.code);
            const codeb = parseInt(b.code);

            if (codea < codeb) {
              return -1;
            } else if (codea > codeb) {
              return 1;
            } else {
              return 0;
            }
          })
          .map((country) => {
            return { code: country.code, flag: country.flag };
          })
      : [];
  }, [data?.countries]);

  const getMemoizedCurrentCountryValue = useMemo(
    () =>
      phoneData.country &&
      parseSelectedValue(phoneData.country, mappedCountriesValues),
    [phoneData.country, mappedCountriesValues],
  );

  const getMemoizedCurrentOfficeCountryValue = useMemo(
    () =>
      officePhoneData.country &&
      parseSelectedValue(officePhoneData.country, mappedCountriesValues),
    [officePhoneData.country, mappedCountriesValues],
  );

  const getMemoizedDefaultParsedValue = useMemo(
    () => parseMappedCountriesIntoSelectValues(mappedCountriesValues)[0],
    [mappedCountriesValues],
  );

  const getMemoizedParsedCountries = useMemo(
    () => parseMappedCountriesIntoSelectValues(mappedCountriesValues),
    [mappedCountriesValues],
  );

  const handleOnPhoneChange = useCallback(
    (
      value: string,
      questionID: number | undefined,
      question: questionnaire_questions_question,
      responsePropertyValue: string | null | undefined,
    ) => {
      if (selectedCountry) {
        if (Array.isArray(textFieldResponses) && setTextFieldResponses) {
          // Check if the questionID already exists in the array
          const existingIndex = textFieldResponses.findIndex(
            (response) => response.questionID === questionID,
          );

          if (existingIndex !== -1) {
            // If the questionID exists, update the existing item
            const updatedResponses = [...textFieldResponses];
            updatedResponses[existingIndex].response = value;
            setTextFieldResponses(updatedResponses);

            onChange(
              [
                {
                  response: value,
                  responsePropertyValue: responsePropertyValue,
                  questionID: questionID,
                },
              ],
              question?.questionnaire_questions_question.id || 0,
            );
          } else {
            // If the questionID doesn't exist, create a new response
            const newResponse: QuestionnaireResponseInterface = {
              questionID: questionID,
              response: value,
              responsePropertyValue: responsePropertyValue,
            };

            setTextFieldResponses([...textFieldResponses, newResponse]);

            onChange(
              [
                {
                  response: value,
                  responsePropertyValue: responsePropertyValue,
                  questionID: questionID,
                },
              ],
              question?.questionnaire_questions_question.id || 0,
            );
          }
        }
      }
    },
    [textFieldResponses, setTextFieldResponses, onChange, selectedCountry],
  );

  const processPhoneData = useMemo(
    () => (value: PhoneDataProps) => {
      return `+${value.country} ${value.phone}${
        value.extension ? ` ${value.extension}` : ''
      }`.trim();
    },
    [],
  );

  const handlePhoneDataChange = useCallback(
    (phoneDataType: 'phoneData' | 'officePhoneData') => {
      if (phoneQuestionData) {
        const processedPhoneData =
          phoneDataType === 'phoneData'
            ? processPhoneData(phoneData)
            : processPhoneData(officePhoneData);
        handleOnPhoneChange(
          processedPhoneData,
          phoneQuestionData?.[0].questionnaire_questions_question.id,
          phoneQuestionData?.[0],
          phoneQuestionData?.[0].questionnaire_questions_question
            .response_property,
        );
      }
    },
    [
      handleOnPhoneChange,
      officePhoneData,
      phoneData,
      phoneQuestionData,
      processPhoneData,
    ],
  );

  const validationRules: ValidationRules = {
    firstname: [
      {
        validator: (value) => value.length > 0,
        message: locale?.fieldRequired,
      },
    ],
    lastname: [
      {
        validator: (value) => value.length > 0,
        message: locale?.fieldRequired,
      },
    ],
    email: [
      {
        validator: (value) => value.length > 0,
        message: locale?.fieldRequired,
      },
      {
        validator: (value) => new RegExp(emailPattern).test(value),
        message: locale?.errorEnterValidEmail,
      },
    ],
    phone: [
      {
        validator: (value) => value.length > 0,
        message: locale?.fieldRequired,
      },
      {
        validator: (value) => new RegExp(min7OnlyDigitsPattern).test(value),
        message: locale?.fieldRequired,
      },
    ],
    nationalProviderIdentifier: [
      {
        validator: (value) => value.length > 0,
        message: locale?.errorEnterValidNPI,
      },
      {
        validator: (value) => validateNPI(value),
        message: locale?.errorEnterValidNPI,
      },
    ],
    password: [
      {
        validator: (value) => value.length > 0,
        message: locale?.fieldRequired,
      },
      {
        validator: (value) => new RegExp(passwordPattern).test(value),
        message: locale?.errorEnterValidPassword,
      },
    ],
    confirmPassword: [
      {
        validator: (value) => value.length > 0,
        message: locale?.fieldRequired,
      },
      {
        validator: (value) => new RegExp(passwordPattern).test(value),
        message: locale?.errorEnterValidPassword,
      },
      {
        validator: (value, localFormData) => value === localFormData?.password,
        message: locale?.errorPasswordsDontMatch,
      },
    ],
    office_information_office_name: [
      {
        validator: (value) => value.length > 0,
        message: locale?.fieldRequired,
      },
    ],
    office_information_email: [
      {
        validator: (value) => value.length > 0,
        message: locale?.fieldRequired,
      },
      {
        validator: (value) => new RegExp(emailPattern).test(value),
        message: locale?.errorEnterValidEmail,
      },
    ],
    office_information_phone: [
      {
        validator: (value) => value.length > 0,
        message: locale?.fieldRequired,
      },
      {
        validator: (value) => new RegExp(min7OnlyDigitsPattern).test(value),
        message: locale?.fieldRequired,
      },
    ],
    office_information_address_1: [
      {
        validator: (value) => value.length > 0,
        message: locale?.fieldRequired,
      },
      {
        validator: (value) => value.length < 100,
        message: locale?.maxCharactersLength,
      },
    ],
    office_information_address_2: [
      {
        validator: (value) => value.length < 100,
        message: locale?.maxCharactersLength,
      },
    ],
    office_information_zip_code: [
      {
        validator: (value) => value.length > 0,
        message: locale?.fieldRequired,
      },
      {
        validator: (value) => {
          const numericValue = value.replace(MATCHES_NON_DIGIT_CHARACTER, '');
          return (
            numericValue.length === MIN_ZIP_CODE_LENGTH ||
            numericValue.length === MAX_ZIP_CODE_LENGTH
          );
        },
        message: locale?.zipLengthValidation,
      },
    ],
    office_information_country: [
      {
        validator: (value) => value.length > 0,
        message: locale?.fieldRequired,
      },
    ],
    office_information_state: [
      {
        validator: (value) => value.length > 0,
        message: locale?.fieldRequired,
      },
    ],
    office_information_city: [
      {
        validator: (value) => value.length > 0,
        message: locale?.fieldRequired,
      },
    ],
    gender: [
      {
        validator: (value) => value.length > 0,
        message: locale?.fieldRequired,
      },
    ],
    languagesOfCommunication: [
      {
        validator: (value) => value.length > 0,
        message: locale?.fieldRequired,
      },
    ],
    address_information_address_1: [
      {
        validator: (value) => value.length > 0,
        message: locale?.fieldRequired,
      },
      {
        validator: (value) => value.length < 100,
        message: locale?.maxCharactersLength,
      },
    ],
    address_information_address_2: [
      {
        validator: (value) => value.length < 100,
        message: locale?.maxCharactersLength,
      },
    ],
    address_information_country: [
      {
        validator: (value) => value.length > 0,
        message: locale?.fieldRequired,
      },
    ],
    address_information_state: [
      {
        validator: (value) => value.length > 0,
        message: locale?.fieldRequired,
      },
    ],
    address_information_city: [
      {
        validator: (value) => value.length > 0,
        message: locale?.fieldRequired,
      },
    ],
    address_information_zip_code: [
      {
        validator: (value) => value.length > 0,
        message: locale?.fieldRequired,
      },
      {
        validator: (value) => {
          const numericValue = value.replace(MATCHES_NON_DIGIT_CHARACTER, '');
          return (
            numericValue.length === MIN_ZIP_CODE_LENGTH ||
            numericValue.length === MAX_ZIP_CODE_LENGTH
          );
        },
        message: locale?.zipLengthValidation,
      },
    ],
    about: [
      {
        validator: (value) => value.length > 0,
        message: locale?.fieldRequired,
      },
    ],
  };

  const { errors, validateForm } = useFormValidation(validationRules);

  const firstNonNullValueOfErrors = Object.values(errors).find(
    (value) => value !== null,
  );

  const [localFormData, setLocalFormData] = useState<ProviderDataLocalForm>({
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    nationalProviderIdentifier: '',
    password: '',
    confirmPassword: '',
    office_information_office_name: '',
    office_information_email: '',
    office_information_phone: '',
    office_information_address_1: '',
    office_information_address_2: '',
    office_information_zip_code: '',
    office_information_country: '',
    office_information_state: '',
    office_information_city: '',
    gender: '',
    languagesOfCommunication: [],
    about: '',
    address_information_address_1: '',
    address_information_address_2: '',
    address_information_country: '',
    address_information_state: '',
    address_information_city: '',
    address_information_zip_code: '',
  });

  const handleLocalFormData = (
    question: questionnaire_questions_question,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setLocalFormData({
      ...localFormData,
      [question.questionnaire_questions_question.response_property as string]:
        e.target.value,
    });
  };

  const handleLocalProccesedFormData = (property: string, value: string) => {
    setLocalFormData({
      ...localFormData,
      [property]: value,
    });
  };

  const handleLocalSelectProccesedFormData = (
    property: string,
    value: string[],
  ) => {
    setLocalFormData({
      ...localFormData,
      [property]: value,
    });
  };

  const handleValidation = useCallback(
    // change for validation
    (providerData: ProviderDataLocalForm) => {
      const formData = JSON.parse(JSON.stringify(providerData));
      return validateForm(formData);
    },
    [validateForm],
  );

  const prevLocalFormDataRef = useRef<ProviderDataLocalForm | null>(null);
  const validationExecutedRef = useRef(false);

  const handleValidationCallback = useCallback(() => {
    // Check if the validation has already been executed
    if (!validationExecutedRef.current) {
      if (handleValidation) {
        handleValidation(localFormData);
      }
      validationExecutedRef.current = true;
    }
  }, [handleValidation, localFormData]);

  const [previousSectionName, setPreviousSectionName] = useState(
    currentSection.name,
  );

  const updateErrorsInSections = useCallback(
    (
      prevState: ModuleFormErrors | null,
      currentSectionName: string,
    ): ModuleFormErrors => {
      const updatedSections = (prevState?.sectionsHasErrors ?? []).map(
        (section) =>
          section === currentSection.name ? currentSection.name : section,
      );

      // Add the value if it doesn't exist
      if (!updatedSections.includes(currentSectionName)) {
        updatedSections.push(currentSectionName);
      }

      return {
        sectionsHasErrors: updatedSections,
      };
    },
    [currentSection.name],
  );

  const handleErrorsUpdate = useCallback(
    (
      prevState: ModuleFormErrors | null,
      currentSectionName: string,
      checkForNullErrors: boolean,
    ): ModuleFormErrors => {
      if (!checkForNullErrors) {
        return updateErrorsInSections(prevState, currentSectionName);
      } else {
        const updatedSections = (prevState?.sectionsHasErrors ?? []).filter(
          (section) => section !== currentSectionName,
        );

        return {
          sectionsHasErrors: updatedSections,
        };
      }
    },
    [updateErrorsInSections],
  );

  const parseQuestionOptionsForDropdownWithLookup = (options: string[]) => {
    const parsedOptions = options.map((option: string) => ({
      label: option,
      value: option,
    }));
    return parsedOptions;
  };

  const getSelectedValues = (
    parsedOptions: DropdownItem[],
    selectedOptions: string[],
  ): DropdownItem[] => {
    const filteredOptions = parsedOptions.filter((obj: DropdownItem) => {
      return selectedOptions.includes(obj.value);
    });

    const customValues = selectedOptions.filter((value) => {
      return !parsedOptions.some((obj) => obj.value === value);
    });

    const customOptions = customValues.map((option) => {
      return {
        label: option,
        value: option,
      };
    });

    return [...filteredOptions, ...customOptions];
  };

  const handleChange = (
    e: MultiValue<DropdownOption>,
    no: number,
    questionID: number,
    questionResponseProperty: string,
  ) => {
    const newItem: DropdownOptionsState[] = selectedValue.map(
      (item: DropdownOptionsState) => {
        return selectedValue.indexOf(item) === no
          ? { value: Array.isArray(e) ? e.map((x) => x.value) : [] }
          : item;
      },
    );
    // If length of array is > 1 and none option is selected, remove it
    const filteredOptions = newItem.map((item) => {
      if (
        item.value.some((value) => value.toLowerCase() === 'none') &&
        item.value.length > 1
      ) {
        return {
          ...item,
          value: item.value.filter((value) => value.toLowerCase() !== 'none'),
        };
      } else {
        return item;
      }
    });

    onDropdownWithMultiSelectChange(
      newItem[no].value,
      questionID,
      questionResponseProperty,
    );
    setSelectedValue(filteredOptions);
  };

  const handleLocalTextFieldResponses = (
    value: string,
    questionID: number,
    educationID: number,
  ) => {
    // Ensure that textLocalFieldResponses is initialized as an empty array initially
    if (!Array.isArray(textLocalFieldResponses)) {
      setTextLocalFieldResponses([]);
    }

    // Find an existing response with the same questionID and educationID
    const existingResponseIndex = textLocalFieldResponses.findIndex(
      (response) =>
        response.medicationID === educationID &&
        response.questionID === questionID,
    );

    if (existingResponseIndex !== -1) {
      // If the response exists, update it
      const updatedResponses = [...textLocalFieldResponses];
      updatedResponses[existingResponseIndex].response = value;
      setTextLocalFieldResponses(updatedResponses);
    } else {
      // If the response doesn't exist, create a new response
      const newResponse: {
        questionID: number;
        medicationID: number;
        response: string;
      } = {
        questionID: questionID,
        medicationID: educationID,
        response: value,
      };

      setTextLocalFieldResponses([...textLocalFieldResponses, newResponse]);
    }
  };

  const sortedQuestionList = [...questionList].sort(
    (a, b) =>
      (a.questionnaire_questions_question.id ?? 0) -
      (b.questionnaire_questions_question.id ?? 0),
  );

  const sortedEducationList = useMemo(() => {
    return [...educationList].sort((a, b) => (b.id ?? 0) - (a.id ?? 0));
  }, [educationList]);

  const insertAfter = (
    id: number,
    education: QuestionnaireResponseInterface,
  ) => {
    const array = [...educationList];
    const index = educationList.findIndex((item) => item.id === id);
    array.splice(index + 1, 0, education);
    setEducationList(array);
  };

  const insertAfterLastEducation = () => {
    const lastMedication = educationList[educationList.length - 1];
    if (lastMedication && lastMedication.id !== undefined) {
      insertAfter(lastMedication.id, {
        id: educationList.length,
        title: `Education List ${educationList.length + 1}`,
        completed: false,
        answers: [],
      });
    } else if (lastMedication === undefined) {
      insertAfter(0, {
        id: 0,
        title: 'Education List 1',
        completed: false,
        answers: [],
      });
    }
  };

  const removeEducation = (idToRemove: number) => {
    const updatedArray = educationList
      .filter((education) => education.id !== idToRemove)
      .map((education, index) => ({
        ...education,
        id: index, // Resetting the id based on the new index
      }));
    setEducationList(updatedArray);
  };

  const handleDropdownOptionSelected = (
    value: SingleValue<string | DropdownItem>,
    questionID: number,
    educationListID: number,
  ) => {
    const selectedValue = (value?.valueOf() as { value: string }).value;
    const updatedMedicationList = educationList.map((educationItem) => {
      if (educationItem.id === educationListID && educationItem.answers) {
        // Update the answers for the matching education
        const updatedAnswers = [...educationItem.answers];

        // Check if there's an existing answer for the same questionID
        const existingAnswerIndex = updatedAnswers.findIndex(
          (answer) => answer.questionID === questionID,
        );

        if (existingAnswerIndex !== -1) {
          // Update the existing answer with the new response values
          updatedAnswers[existingAnswerIndex] = {
            ...updatedAnswers[existingAnswerIndex], // Create a shallow copy of the answer object
            response: selectedValue,
          };
        } else {
          // If there is no existing answer, create a new one
          updatedAnswers.push({
            questionID: questionID,
            response: selectedValue,
          });
        }

        return {
          ...educationItem,
          answers: updatedAnswers,
        };
      }

      return educationItem;
    });

    // Update the state with the modified educationList
    setEducationList(updatedMedicationList);
  };

  const getEducationListValue = (index: number, questionID: number) => {
    let parsedResponse: DropdownItem = {
      label: '',
      value: '',
    };

    const indexedQuery = educationList.findIndex((item) => {
      return item.id === index;
    });
    const indexedAnswers = educationList[indexedQuery].answers;

    const responseFromStorage = indexedAnswers?.find(
      (item: QuestionnaireResponseInterface) => {
        return item.questionID === questionID;
      },
    );

    if (responseFromStorage) {
      parsedResponse = {
        label: responseFromStorage.response as string,
        value: responseFromStorage.response as string,
      };
    }

    return parsedResponse;
  };

  const handleInputPhoneDataChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    question: questionnaire_questions_question,
    type: 'phoneData' | 'officePhoneData',
    typeOfField: 'phone' | 'ext',
  ) => {
    if (typeOfField === 'phone') {
      handleLocalFormData(question, e);
    }

    if (localFormData !== null && typeOfField === 'phone') {
      handleValidation(localFormData);
    }

    if (type === 'phoneData') {
      // detect phone field / ext field
      if (typeOfField === 'phone') {
        if (
          validateQuestionResponse(
            question,
            e.target.value,
            signUpValidationFunctionsRules,
          )
        ) {
          setPhoneData({
            phone: e.target.value,
            country: phoneData?.country || getMemoizedDefaultParsedValue.label,
            extension: phoneData?.extension,
          });
        }
      }

      if (typeOfField === 'ext') {
        if (
          validateQuestionResponse(
            question,
            e.target.value,
            signUpValidationFunctionsRules,
          )
        ) {
          setPhoneData({
            phone: phoneData?.phone,
            country: phoneData?.country || getMemoizedDefaultParsedValue.label,
            extension: e.target.value,
          });
        }
      }

      setTriggerPhoneChange(true);
    } else if (type === 'officePhoneData') {
      // detect phone field / ext field
      if (typeOfField === 'phone') {
        if (
          validateQuestionResponse(
            question,
            e.target.value,
            signUpValidationFunctionsRules,
          )
        ) {
          setOfficePhoneData({
            phone: e.target.value,
            country:
              officePhoneData?.country || getMemoizedDefaultParsedValue.label,
            extension: officePhoneData?.extension,
          });
        }
      }

      if (typeOfField === 'ext') {
        if (
          validateQuestionResponse(
            question,
            e.target.value,
            signUpValidationFunctionsRules,
          )
        ) {
          setOfficePhoneData({
            phone: officePhoneData?.phone,
            country:
              officePhoneData?.country || getMemoizedDefaultParsedValue.label,
            extension: e.target.value,
          });
        }
      }

      setTriggerOfficePhoneChange(true);
    }
  };

  const handleInputTextChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    question: questionnaire_questions_question,
  ) => {
    const responseProperty = question.questionnaire_questions_question
      .response_property as string;

    if (
      validateQuestionResponse(
        question,
        e.target.value,
        signUpValidationFunctionsRules,
      )
    ) {
      handleLocalFormData(question, e);

      if (localFormData !== null) {
        handleValidation(localFormData);
      }

      textFieldResponses &&
        handleTextFieldResponses(
          e.target.value,
          question.questionnaire_questions_question.id,
          question,
          responseProperty,
        );
    }
  };

  const isEducationListItemLengthValid = (
    array: QuestionnaireResponseInterface[],
  ) => array.every((item) => item && item.answers && item.answers.length === 3);

  const checkSectionForNullErrors = useCallback(
    (
      section: DynamicQuestionResponseSection,
      errors: Errors,
      localFormData: ProviderDataLocalForm,
      selectedValue: DropdownOptionsState[],
    ): boolean => {
      const sections = initialSections;

      const currentSectionProperties = sections.find(
        (s) => s.name === section.name,
      )?.propertiesToCheck;

      if (
        currentSectionProperties &&
        section.name === SPECIFIC_UNAUTHENTICATED_SECTION.ACCOUNT_INFO
      ) {
        const isSectionValid = _.every(currentSectionProperties, (property) => {
          const localFormDataValue =
            localFormData[property as keyof ProviderDataLocalForm];

          if (Array.isArray(localFormDataValue)) {
            return localFormDataValue.length > 0;
          }

          return localFormDataValue !== '';
        });

        const areErrorsNull = _.every(
          currentSectionProperties,
          (property) => errors[property] === null,
        );
        return isSectionValid && areErrorsNull;
      }

      if (
        currentSectionProperties &&
        section.name ===
          SPECIFIC_UNAUTHENTICATED_SECTION.CRENDENTIALS_AND_CERTIFICATIONS
      ) {
        return (
          selectedValue[1].value.length >= 1 &&
          selectedValue[2].value.length >= 1 &&
          selectedValue[3].value.length >= 1 &&
          isEducationListItemLengthValid(educationList)
        );
      }

      if (
        currentSectionProperties &&
        section.name === SPECIFIC_UNAUTHENTICATED_SECTION.PERSONAL_INFO
      ) {
        return (
          selectedValue[0].value.length >= 1 &&
          localFormData['gender'] !== '' &&
          localFormData['about'] !== ''
        );
      }

      if (
        currentSectionProperties &&
        section.name === SPECIFIC_UNAUTHENTICATED_SECTION.CONTACT_INFO
      ) {
        const areErrorsNull = _.every(
          currentSectionProperties,
          (property) => errors[property] === null,
        );
        return (
          officeAddressInformation.country != '' &&
          officeAddressInformation.state != '' &&
          officeAddressInformation.city != '' &&
          areErrorsNull
        );
      }

      if (
        currentSectionProperties &&
        section.name === SPECIFIC_UNAUTHENTICATED_SECTION.CONTACT_INFO
      ) {
        const areErrorsNull = _.every(
          currentSectionProperties,
          (property) => errors[property] === null,
        );
        return (
          personalAddressInformation.country != '' &&
          personalAddressInformation.state != '' &&
          personalAddressInformation.city != '' &&
          areErrorsNull
        );
      }

      if (currentSectionProperties) {
        const isSectionValid = _.every(currentSectionProperties, (property) => {
          const localFormDataValue =
            localFormData[property as keyof ProviderDataLocalForm];

          if (Array.isArray(localFormDataValue)) {
            return localFormDataValue.length > 0;
          }

          return localFormDataValue !== '';
        });

        const isLocalFormDataValid = _.every(
          Object.values(localFormData),
          (value) => value !== '',
        );

        const areErrorsNull = _.every(
          currentSectionProperties,
          (property) => errors[property] === null,
        );

        const overallValidity =
          isSectionValid || (isLocalFormDataValid && areErrorsNull);

        return overallValidity;
      }

      return false;
    },
    [educationList, officeAddressInformation, personalAddressInformation],
  );

  const updatePersistentData = (
    prevState: QuestionnaireResponseInterface[],
    questionID: number,
    section: DynamicQuestionResponseSection,
    value: string,
    responsePropertyName: string,
  ) => {
    let updatedState = [...prevState];
    let foundExistingEntry = false;

    // Check if an entry with the same questionID exists in prevState
    updatedState = updatedState.map((entry) => {
      if (entry.questionID === questionID) {
        const updatedEntry = {
          ...entry,
          currentSection: section.name,
          response: [
            {
              response: value,
              responsePropertyValue: responsePropertyName,
              questionID: questionID,
            },
          ],
        };
        foundExistingEntry = true;
        return updatedEntry;
      }
      return entry;
    }) as QuestionnaireResponseInterface[];

    // If no existing entry was found, create a new one
    if (!foundExistingEntry) {
      updatedState.push({
        currentSection: section.name,
        questionID: questionID,
        response: [
          {
            response: value,
            responsePropertyValue: responsePropertyName,
            questionID: questionID,
          },
        ] as QuestionnaireResponseInterface,
      });
    }

    return updatedState;
  };

  const handleUpdloadOfEducationListIntoPersistentData = (
    prevState: QuestionnaireResponseInterface[],
    section: DynamicQuestionResponseSection,
    response: QuestionnaireResponseInterface[],
  ) => {
    let updatedState = [...prevState];
    let foundExistingEntry = false;
    // Check if an entry with the same questionID exists in prevState
    updatedState = updatedState.map((entry) => {
      if (
        entry.questionID ===
        SPECIFIC_UNAUTHENTICATED_QUESTION_IDS.EDUCATION_DYNAMIC_LIST
      ) {
        const updatedEntry = {
          ...entry,
          currentSection: section.name,
          response: response,
        };
        foundExistingEntry = true;
        return updatedEntry;
      }
      return entry;
    });
    // If no existing entry was found, create a new one
    if (!foundExistingEntry) {
      updatedState.push({
        currentSection: section.name,
        questionID:
          SPECIFIC_UNAUTHENTICATED_QUESTION_IDS.EDUCATION_DYNAMIC_LIST,
        response: response,
      });
    }
    return updatedState;
  };

  const handleOnOfficeAddressSelectChange = (
    value: SingleValue<string | DropdownItem>,
    name: string,
    questionID: number,
    responsePropertyName: string,
  ) => {
    if (name === 'country') {
      let countryCode = '';
      let countryValue = '';

      if (typeof value !== 'string') {
        countryValue = value?.value ?? '';
      }

      if (typeof value === 'string') {
        countryCode = value.split(' - ')[0];
      } else {
        countryCode = value?.value.split(' - ')[0] ?? '';
      }

      setOfficeCountryIso(countryCode);
      const statesIso = State.getStatesOfCountry(countryCode).map(
        (state) => `${state.isoCode}`,
      );
      setOfficeStateIso(statesIso[0]);
      setOfficeAddressInformation({
        ...officeAddressInformation,
        country: countryValue,
      });
      setIsOfficeCountryClicked(true);
      setIsOfficeStateClicked(true);

      // set data to persistent
      setPersistentData((prevState) =>
        updatePersistentData(
          prevState,
          questionID,
          section,
          countryValue,
          responsePropertyName,
        ),
      );
      handleLocalProccesedFormData(responsePropertyName, countryValue);
    }
    if (name === 'state') {
      let stateCode = '';
      let stateValue = '';
      if (typeof value !== 'string') {
        stateValue = value?.value ?? '';
      }

      if (typeof value === 'string') {
        stateCode = value.split(' - ')[0];
      } else {
        stateCode = value?.value.split(' - ')[0] ?? '';
      }
      setOfficeStateIso(stateCode);
      setOfficeAddressInformation({
        ...officeAddressInformation,
        state: stateValue,
      });
      setIsOfficeStateClicked(true);
      // set data to persistent
      setPersistentData((prevState) =>
        updatePersistentData(
          prevState,
          questionID,
          section,
          stateValue,
          responsePropertyName,
        ),
      );
      handleLocalProccesedFormData(responsePropertyName, stateValue);
    }
    if (name === 'city') {
      const cityValue =
        typeof value === 'string'
          ? value.replace(
              NON_ALPHABETIC_REGEX_PRESERVES_SPACES_AND_CHARACTERS,
              '',
            )
          : value?.value?.replace(
              NON_ALPHABETIC_REGEX_PRESERVES_SPACES_AND_CHARACTERS,
              '',
            );

      setOfficeAddressInformation({
        ...officeAddressInformation,
        city: cityValue as string,
      });
      // set data to persistent
      setPersistentData((prevState) =>
        updatePersistentData(
          prevState,
          questionID,
          section,
          cityValue as string,
          responsePropertyName,
        ),
      );
      handleLocalProccesedFormData(responsePropertyName, cityValue as string);
    }
  };

  const handleOnPersonalAddressSelectChange = (
    value: SingleValue<string | DropdownItem>,
    name: string,
    questionID: number,
    responsePropertyName: string,
  ) => {
    if (name === 'country') {
      let countryCode = '';
      let countryValue = '';

      if (typeof value !== 'string') {
        countryValue = value?.value ?? '';
      }

      if (typeof value === 'string') {
        countryCode = value.split(' - ')[0];
      } else {
        countryCode = value?.value.split(' - ')[0] ?? '';
      }

      setPersonalCountryIso(countryCode);
      const statesIso = State.getStatesOfCountry(countryCode).map(
        (state) => `${state.isoCode}`,
      );
      setPersonalStateIso(statesIso[0]);
      setPersonalAddressInformation({
        ...personalAddressInformation,
        country: countryValue,
      });
      setIsPersonalCountryClicked(true);
      setIsPersonalStateClicked(true);
      // set data to persistent
      setPersistentData((prevState) =>
        updatePersistentData(
          prevState,
          questionID,
          section,
          countryValue,
          responsePropertyName,
        ),
      );
      handleLocalProccesedFormData(responsePropertyName, countryValue);
    }
    if (name === 'state') {
      let stateCode = '';
      let stateValue = '';
      if (typeof value !== 'string') {
        stateValue = value?.value ?? '';
      }

      if (typeof value === 'string') {
        stateCode = value.split(' - ')[0];
      } else {
        stateCode = value?.value.split(' - ')[0] ?? '';
      }
      setPersonalStateIso(stateCode);
      setPersonalAddressInformation({
        ...personalAddressInformation,
        state: stateValue,
      });
      setIsPersonalStateClicked(true);
      // set data to persistent
      setPersistentData((prevState) =>
        updatePersistentData(
          prevState,
          questionID,
          section,
          stateValue,
          responsePropertyName,
        ),
      );
      handleLocalProccesedFormData(responsePropertyName, stateValue);
    }
    if (name === 'city') {
      const cityValue =
        typeof value === 'string'
          ? value.replace(
              NON_ALPHABETIC_REGEX_PRESERVES_SPACES_AND_CHARACTERS,
              '',
            )
          : value?.value?.replace(
              NON_ALPHABETIC_REGEX_PRESERVES_SPACES_AND_CHARACTERS,
              '',
            );

      setPersonalAddressInformation({
        ...personalAddressInformation,
        city: cityValue as string,
      });
      // set data to persistent
      setPersistentData((prevState) =>
        updatePersistentData(
          prevState,
          questionID,
          section,
          cityValue as string,
          responsePropertyName,
        ),
      );
      handleLocalProccesedFormData(responsePropertyName, cityValue as string);
    }
  };

  const handleCheckboxCopyData = (checked: boolean) => {
    setIsSameOfficeAddresCheckboxChecked(checked);

    if (checked === true) {
      setTriggerCopyData(true);
      setLocalFormData((prevState) => {
        return {
          ...prevState,
          address_information_address_1:
            localFormData['office_information_address_1'],
          address_information_address_2:
            localFormData['office_information_address_2'],
          address_information_zip_code:
            localFormData['office_information_zip_code'],
        };
      });

      setPersonalAddressInformation({
        country: officeAddressInformation.country,
        state: officeAddressInformation.state,
        city: officeAddressInformation.city,
      });

      setPersonalCountryIso(officeCountryIso);
      setPersonalStateIso(officeStateIso);
      setPersonalStateData(officeStateData);
      setPersonalCityData(officeCityData);
    }

    if (checked === false) {
      setTriggerCopyData(false);
      setLocalFormData((prevState) => {
        return {
          ...prevState,
          address_information_address_1: '',
          address_information_address_2: '',
          address_information_zip_code: '',
          address_information_country: '',
          address_information_state: '',
          address_information_city: '',
        };
      });

      setPersonalAddressInformation({
        country: '',
        state: '',
        city: '',
      });

      setPersonalCountryIso('');
      setPersonalStateIso('');
      setPersonalStateData([]);
      setPersonalCityData([]);

      setPersistentData((prevState) =>
        removeParentEntriesFromPersistentData(
          prevState,
          staticIdsForAddressContactInfo,
        ),
      );
    }
  };

  const isDropdownItemArray = (items: unknown): items is DropdownItem[] => {
    return (
      Array.isArray(items) &&
      items.every((item) => 'label' in item && 'value' in item)
    );
  };

  useEffect(() => {
    if (triggerCopyData) {
      if (isSameOfficeAddresCheckboxChecked) {
        setLocalFormData((prevState) => ({
          ...prevState,
          address_information_address_1:
            localFormData['office_information_address_1'],
          address_information_address_2:
            localFormData['office_information_address_2'],
          address_information_zip_code:
            localFormData['office_information_zip_code'],
          address_information_country:
            localFormData['office_information_country'],
          address_information_state: localFormData['office_information_state'],
          address_information_city: localFormData['office_information_city'],
        }));

        setPersonalAddressInformation({
          country: officeAddressInformation.country,
          state: officeAddressInformation.state,
          city: officeAddressInformation.city,
        });

        setPersonalCountryIso(officeCountryIso);
        setPersonalStateIso(officeStateIso);
        setPersonalStateData(officeStateData);
        setPersonalCityData(officeCityData);

        const idsToMerge =
          generateIdsFromContactInfoToMergeIntoPersistentData(localFormData);

        idsToMerge.forEach(
          ({ questionID, valueToAttach, responsePropertyValueToAttach }) => {
            const updatedData = [
              {
                questionID,
                responsePropertyValue: responsePropertyValueToAttach,
                response: [
                  {
                    response: valueToAttach,
                    questionID,
                    responsePropertyValue: responsePropertyValueToAttach,
                  },
                ],
              },
            ];

            setPersistentData((prevState) =>
              updatePersistentDataWithDynamicData(
                prevState,
                section.name,
                questionID,
                updatedData,
              ),
            );
          },
        );
      }

      setTriggerCopyData(false);
    }
  }, [
    isSameOfficeAddresCheckboxChecked,
    localFormData,
    officeAddressInformation.city,
    officeAddressInformation.country,
    officeAddressInformation.state,
    officeCityData,
    officeCountryIso,
    officeStateData,
    officeStateIso,
    section,
    setPersistentData,
    triggerCopyData,
  ]);

  useEffect(() => {
    const defaultCountry = parseMappedCountriesIntoSelectValues(
      mappedCountriesValues,
    )[0];

    setSelectedCountry(defaultCountry);
  }, [mappedCountriesValues]);

  useEffect(() => {
    if (questionList) {
      const phoneQuestion = questionList.filter((data) => {
        return (
          data.questionnaire_questions_question.ui_control === UI_CONTROL.PHONE
        );
      });
      setPhoneQuestionData(phoneQuestion);
    }
  }, [questionList]);

  useEffect(() => {
    if (triggerPhoneChange) {
      handlePhoneDataChange('phoneData');
      setTriggerPhoneChange(false);
    }
  }, [triggerPhoneChange, handlePhoneDataChange]);

  useEffect(() => {
    if (triggerOfficePhoneChange) {
      handlePhoneDataChange('officePhoneData');
      setTriggerOfficePhoneChange(false);
    }
  }, [triggerOfficePhoneChange, handlePhoneDataChange]);

  useEffect(() => {
    // Check if localFormData is different from the previous data
    if (localFormData !== prevLocalFormDataRef.current) {
      // Update the ref with the latest current data
      prevLocalFormDataRef.current = localFormData;

      // Reset the validation executed ref when localFormData changes
      validationExecutedRef.current = false;

      // Run validation when localFormData changes
      handleValidationCallback();
    }
  }, [handleValidationCallback, localFormData]);

  useEffect(() => {
    if (
      moduleFormErrors?.sectionsHasErrors?.includes(currentSection.name) &&
      isSubmitClicked
    ) {
      setEnableErrorsFeedbackUser(true);
    } else {
      setEnableErrorsFeedbackUser(false);
    }
  }, [
    currentSection.name,
    isSubmitClicked,
    moduleFormErrors?.sectionsHasErrors,
  ]);

  useEffect(() => {
    if (isSubmitClicked && currentSection.name !== previousSectionName) {
      setIsSubmitClicked(false);
    }
    setPreviousSectionName(currentSection.name);
  }, [
    currentSection.name,
    isSubmitClicked,
    previousSectionName,
    setIsSubmitClicked,
  ]);

  useEffect(() => {
    const isSectionValid = checkSectionForNullErrors(
      currentSection,
      errors,
      localFormData,
      selectedValue,
    );
    setModuleFormErrors((prevState: ModuleFormErrors | null) =>
      handleErrorsUpdate(prevState, currentSection.name, isSectionValid),
    );
  }, [
    errors,
    setModuleFormErrors,
    currentSection,
    handleErrorsUpdate,
    checkSectionForNullErrors,
    localFormData,
    selectedValue,
  ]);

  useEffect(() => {
    if (
      section.name ===
      SPECIFIC_UNAUTHENTICATED_SECTION.CRENDENTIALS_AND_CERTIFICATIONS
    ) {
      setPersistentData((prevState) =>
        handleUpdloadOfEducationListIntoPersistentData(
          prevState,
          section,
          educationList,
        ),
      );
    }
  }, [educationList, localFormData, section, setPersistentData]);

  useEffect(() => {
    if (officeCountryIso) {
      const states = State.getStatesOfCountry(officeCountryIso)
        .filter(
          (state) =>
            !state.isoCode.includes(ISO_CODE_TO_EXCLUDE) &&
            !STATES_TO_EXCLUDE.includes(state.name),
        )
        .map((state) => `${state.isoCode} - ${state.name}`);

      setOfficeStateData(states);
      if (isOfficeCountryClicked) {
        const stateCode = states?.[0]?.split(' - ')[0] || '';
        setOfficeStateIso(stateCode);
        setOfficeAddressInformation((prevFormValues) => ({
          ...prevFormValues,
          addressInformation: {
            ...prevFormValues,
            state: states?.[0] || '',
            city: '',
          },
        }));
        setIsOfficeCountryClicked(false);
        setIsOfficeStateClicked(false);
      }
    } else {
      setOfficeStateData([]);
      setOfficeCityData([]);
    }
  }, [officeCountryIso, isOfficeCountryClicked]);

  useEffect(() => {
    if (personalCountryIso) {
      const states = State.getStatesOfCountry(personalCountryIso)
        .filter(
          (state) =>
            !state.isoCode.includes(ISO_CODE_TO_EXCLUDE) &&
            !STATES_TO_EXCLUDE.includes(state.name),
        )
        .map((state) => `${state.isoCode} - ${state.name}`);

      setPersonalStateData(states);
      if (isPersonalCountryClicked) {
        const stateCode = states?.[0]?.split(' - ')[0] || '';
        setPersonalStateIso(stateCode);
        setPersonalAddressInformation((prevFormValues) => ({
          ...prevFormValues,
          addressInformation: {
            ...prevFormValues,
            state: states?.[0] || '',
            city: '',
          },
        }));
        setIsPersonalCountryClicked(false);
        setIsPersonalStateClicked(false);
        setTriggerCopyData(true);
      }
    } else {
      setPersonalStateData([]);
      setPersonalCityData([]);
    }
  }, [personalCountryIso, isPersonalCountryClicked]);

  useEffect(() => {
    if (officeCountryIso && officeStateIso) {
      const cities = City.getCitiesOfState(
        officeCountryIso,
        officeStateIso,
      ).map((city) => `${city.name}`);
      setOfficeCityData(cities);
      if (isOfficeStateClicked) {
        setOfficeAddressInformation({
          ...officeAddressInformation,
          city: '',
        });
        setIsOfficeStateClicked(false);
      }
      setTriggerCopyData(true);
    } else {
      setOfficeCityData([]);
    }
  }, [
    officeStateIso,
    officeCountryIso,
    isOfficeStateClicked,
    officeAddressInformation,
  ]);

  useEffect(() => {
    if (personalCountryIso && personalStateIso) {
      const cities = City.getCitiesOfState(
        personalCountryIso,
        personalStateIso,
      ).map((city) => `${city.name}`);
      setPersonalCityData(cities);
      if (isPersonalStateClicked) {
        setPersonalAddressInformation({
          ...personalAddressInformation,
          city: '',
        });
        setIsPersonalStateClicked(false);
        setTriggerCopyData(true);
      }
    } else {
      setPersonalCityData([]);
    }
  }, [
    personalStateIso,
    personalCountryIso,
    isPersonalStateClicked,
    personalAddressInformation,
  ]);

  if (loadingCountries || !locale || loading || localesLoading) {
    return null;
  }

  return (
    <>
      <div className="mt-5 desktop:mx-[60px]">
        {sortedQuestionList.map((question) => {
          const dynamicProperty =
            question &&
            question.questionnaire_questions_question &&
            question.questionnaire_questions_question.response_property;
          const errorStatus = dynamicProperty ? errors[dynamicProperty] : null;
          const localValue = dynamicProperty
            ? localFormData[dynamicProperty as keyof ProviderDataLocalForm]
            : '';

          let parsedOptions: DropdownItem[] = [];
          let languageParsedOptions: DropdownItem[] = [];

          const parsedOptionsCountry: DropdownItem[] = [
            { label: 'US - United States', value: 'US - United States' },
          ];

          if (
            question.questionnaire_questions_question.id ===
            SPECIFIC_UNAUTHENTICATED_QUESTION_IDS.LANGUAGES
          ) {
            languageParsedOptions =
              parseQuestionOptionsForDropdownWithProperties(
                isDropdownItemArray(
                  question.questionnaire_questions_question.answers,
                )
                  ? question.questionnaire_questions_question.answers
                  : [{ label: '', value: '' }],
              );
          }

          if (
            question.questionnaire_questions_question.ui_control ===
              UI_CONTROL.LOOKUP_WITH_MULTISELECT ||
            UI_CONTROL.LOOKUP_FIELD
          ) {
            parsedOptions = parseQuestionOptionsForDropdownWithLookup(
              question.questionnaire_questions_question.answers
                ? question.questionnaire_questions_question.answers
                : [''],
            );
          }
          return (
            <>
              {question.questionnaire_questions_question.id ===
                SPECIFIC_UNAUTHENTICATED_QUESTION_IDS.CREDENTIALS_TITLE && (
                <p className="text-h7 font-bold uppercase mb-[15px]">
                  {locale?.credentialsTitle}
                </p>
              )}

              {question.questionnaire_questions_question.id ===
                SPECIFIC_UNAUTHENTICATED_QUESTION_IDS.EDUCATION_SCHOOL && (
                <>
                  <hr className="flex flex-row w-full items-center h-px bg-black-blur mb-[25px]" />
                  <p className="text-h7 font-bold uppercase mb-[15px]">
                    {locale?.educationTitle}
                  </p>
                </>
              )}

              {question.questionnaire_questions_question.id ===
                SPECIFIC_UNAUTHENTICATED_QUESTION_IDS.OFFICE_NAME && (
                <>
                  <p className="text-h7 font-bold uppercase mb-[15px]">
                    {localeProvider?.providerLocales?.officeDetails}
                  </p>
                </>
              )}

              {section.name ===
              SPECIFIC_UNAUTHENTICATED_SECTION.CRENDENTIALS_AND_CERTIFICATIONS ? (
                <>
                  {!SignUpProviderSkipRenderingIds.includes(
                    question.questionnaire_questions_question.id,
                  ) && (
                    <div key={question.questionnaire_questions_question.id}>
                      <div className="mb-4">
                        <div
                          className={
                            'w-full flex flex-col justify-between desktop:flex-row'
                          }
                        >
                          <div className="flex flex-col">
                            <p className="flex items-center pr-5 mr-auto font-semibold text-dark-gray">
                              {question.questionnaire_questions_question.text}
                              {isRequiredQuestion(
                                question.questionnaire_questions_question.id.toString(),
                              ) && (
                                <span className="text-base font-bold text-clc-red">
                                  *
                                </span>
                              )}
                            </p>
                            {question.questionnaire_questions_question
                              .subtitle && (
                              <p className="italic text-med-gray">
                                {
                                  question.questionnaire_questions_question
                                    .subtitle
                                }
                              </p>
                            )}
                          </div>
                          <div className="flex flex-col w-full desktop:min-w-[435px] desktop:max-w-[435px] desktop:ml-3">
                            {(question.questionnaire_questions_question
                              .ui_control &&
                              question.questionnaire_questions_question
                                .ui_control ===
                                UI_CONTROL.LOOKUP_WITH_MULTISELECT &&
                              question.questionnaire_questions_question.id ===
                                SPECIFIC_UNAUTHENTICATED_QUESTION_IDS.CREDENTIALS_TITLE && (
                                <div className="flex flex-col w-full">
                                  <Select
                                    className="dropdown"
                                    classNamePrefix={
                                      enableErrorsFeedbackUser &&
                                      firstNonNullValueOfErrors &&
                                      selectedValue[1].value.length < 1
                                        ? 'react-select-with-error'
                                        : 'react-select'
                                    }
                                    placeholder={locale?.lookupPlaceholder}
                                    value={getSelectedValues(
                                      parsedOptions,
                                      selectedValue[1].value,
                                    )}
                                    options={parsedOptions}
                                    onChange={(event) => {
                                      return handleChange(
                                        event,
                                        1,
                                        question
                                          .questionnaire_questions_question.id,
                                        question
                                          .questionnaire_questions_question
                                          .response_property ?? '',
                                      );
                                    }}
                                    isMulti={true}
                                    noOptionsMessage={() =>
                                      locale?.selectLanguageNoOptions
                                    }
                                    isOptionSelected={(
                                      option: DropdownItem,
                                      selectValue: Options<DropdownItem>,
                                    ) => {
                                      let status = false;
                                      selectValue.forEach((data) => {
                                        if (
                                          data.value.toLowerCase() !== 'none'
                                        ) {
                                          status = false;
                                        } else {
                                          status = true;
                                        }
                                      });
                                      return status;
                                    }}
                                    components={{
                                      Input: (props) => (
                                        <components.Input
                                          {...props}
                                          maxLength={100}
                                        />
                                      ),
                                    }}
                                  />
                                  {enableErrorsFeedbackUser &&
                                    firstNonNullValueOfErrors &&
                                    selectedValue[1].value.length < 1 && (
                                      <ErrorMessageWithIcon
                                        message={locale?.fieldRequired}
                                      />
                                    )}
                                </div>
                              )) ||
                              (question.questionnaire_questions_question.id ===
                                SPECIFIC_UNAUTHENTICATED_QUESTION_IDS.CREDENTIALS_STATE && (
                                <div className="flex flex-col w-full">
                                  <Select
                                    className="dropdown"
                                    placeholder={locale?.lookupPlaceholder}
                                    classNamePrefix={
                                      enableErrorsFeedbackUser &&
                                      firstNonNullValueOfErrors &&
                                      selectedValue[2].value.length < 1
                                        ? 'react-select-with-error'
                                        : 'react-select'
                                    }
                                    value={getSelectedValues(
                                      parsedOptions,
                                      selectedValue[2].value,
                                    )}
                                    options={parsedOptions}
                                    onChange={(event) => {
                                      return handleChange(
                                        event,
                                        2,
                                        question
                                          .questionnaire_questions_question.id,
                                        question
                                          .questionnaire_questions_question
                                          .response_property ?? '',
                                      );
                                    }}
                                    isMulti={true}
                                    noOptionsMessage={() =>
                                      locale?.selectLanguageNoOptions
                                    }
                                    isOptionSelected={(
                                      option: DropdownItem,
                                      selectValue: Options<DropdownItem>,
                                    ) => {
                                      let status = false;
                                      selectValue.forEach((data) => {
                                        if (
                                          data.value.toLowerCase() !== 'none'
                                        ) {
                                          status = false;
                                        } else {
                                          status = true;
                                        }
                                      });
                                      return status;
                                    }}
                                    components={{
                                      Input: (props) => (
                                        <components.Input
                                          {...props}
                                          maxLength={100}
                                        />
                                      ),
                                    }}
                                  />
                                  {enableErrorsFeedbackUser &&
                                    firstNonNullValueOfErrors &&
                                    selectedValue[2].value.length < 1 && (
                                      <ErrorMessageWithIcon
                                        message={locale?.fieldRequired}
                                      />
                                    )}
                                </div>
                              )) ||
                              (question.questionnaire_questions_question.id ===
                                SPECIFIC_UNAUTHENTICATED_QUESTION_IDS.CREDENTIALS_CERTIFICATIONS && (
                                <div className="flex flex-col w-full">
                                  <CreatableSelect
                                    className="dropdown"
                                    classNamePrefix={
                                      enableErrorsFeedbackUser &&
                                      firstNonNullValueOfErrors &&
                                      selectedValue[3].value.length < 1
                                        ? 'react-select-with-error'
                                        : 'react-select'
                                    }
                                    placeholder={locale?.lookupPlaceholder}
                                    value={getSelectedValues(
                                      parsedOptions,
                                      selectedValue[3].value,
                                    )}
                                    options={undefined}
                                    onChange={(event) => {
                                      return handleChange(
                                        event,
                                        3,
                                        question
                                          .questionnaire_questions_question.id,
                                        question
                                          .questionnaire_questions_question
                                          .response_property ?? '',
                                      );
                                    }}
                                    isMulti={true}
                                    noOptionsMessage={() =>
                                      locale?.selectCreateEntry
                                    }
                                    isOptionSelected={(
                                      option: DropdownItem,
                                      selectValue: Options<DropdownItem>,
                                    ) => {
                                      let status = false;
                                      selectValue.forEach((data) => {
                                        if (
                                          data.value.toLowerCase() !== 'none'
                                        ) {
                                          status = false;
                                        } else {
                                          status = true;
                                        }
                                      });
                                      return status;
                                    }}
                                    components={{
                                      Input: (props) => (
                                        <components.Input
                                          {...props}
                                          maxLength={100}
                                        />
                                      ),
                                    }}
                                  />
                                  {enableErrorsFeedbackUser &&
                                    firstNonNullValueOfErrors &&
                                    selectedValue[3].value.length < 1 && (
                                      <ErrorMessageWithIcon
                                        message={locale?.fieldRequired}
                                      />
                                    )}
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : section.name ===
                SPECIFIC_UNAUTHENTICATED_SECTION.CONTACT_INFO ? (
                <>
                  <div key={question.questionnaire_questions_question.id}>
                    <div className="mb-4">
                      <div
                        className={`flex flex-col ${
                          currentSection.name ===
                            SPECIFIC_UNAUTHENTICATED_SECTION.ACCOUNT_INFO ||
                          currentSection.name ===
                            SPECIFIC_UNAUTHENTICATED_SECTION.CONTACT_INFO
                            ? 'desktop:flex-row'
                            : 'desktop:flex-col'
                        }`}
                      >
                        <p className="flex items-center pr-5 mr-auto font-semibold text-dark-gray">
                          {question.questionnaire_questions_question.text}
                          {isRequiredQuestion(
                            question.questionnaire_questions_question.id.toString(),
                          ) && (
                            <span className="text-base font-bold text-clc-red">
                              *
                            </span>
                          )}
                        </p>
                        {question.questionnaire_questions_question.subtitle && (
                          <p className="italic text-med-gray">
                            {question.questionnaire_questions_question.subtitle}
                          </p>
                        )}
                        <div className="flex flex-col w-full desktop:min-w-[435px] desktop:max-w-[435px] desktop:ml-3">
                          {(question.questionnaire_questions_question
                            .ui_control &&
                            question.questionnaire_questions_question
                              .ui_control === UI_CONTROL.TEXT && (
                              <InputComponent
                                isDisabled={
                                  !question.questionnaire_questions_question.response_property?.includes(
                                    'office',
                                  ) && isSameOfficeAddresCheckboxChecked
                                }
                                value={localValue as string}
                                noMarginBottom={true}
                                type={getUiControlType(
                                  question.questionnaire_questions_question
                                    .ui_control,
                                )}
                                onChange={(e) => {
                                  setTriggerCopyData(true);
                                  handleInputTextChange(e, question);
                                }}
                                errorStatus={
                                  enableErrorsFeedbackUser && !!errorStatus
                                }
                                errorMsgWithIcon={
                                  (enableErrorsFeedbackUser &&
                                    errorStatus) as string
                                }
                              />
                            )) ||
                            (question.questionnaire_questions_question
                              .ui_control &&
                              question.questionnaire_questions_question
                                .ui_control === UI_CONTROL.PHONE && (
                                <div className="w-full flex flex-col desktop:flex-row">
                                  <div className="desktop:max-w-full flex w-full mr-5">
                                    <InputComponent
                                      testID="phoneNumber-country-select"
                                      type="select-with-country-icons"
                                      name="country"
                                      borderRadiusRight={true}
                                      selectInputWithFlagsProps={{
                                        currentValue:
                                          getMemoizedCurrentOfficeCountryValue as SelectWithFlagsValues,
                                        defaultValue:
                                          getMemoizedDefaultParsedValue as SelectWithFlagsValues,
                                        options:
                                          getMemoizedParsedCountries as SelectWithFlagsValues[],
                                        onSelectChange: (e) => {
                                          setOfficePhoneData({
                                            phone: officePhoneData?.phone,
                                            country: e.label,
                                            extension:
                                              officePhoneData?.extension,
                                          });
                                          setTriggerOfficePhoneChange(true);
                                        },
                                      }}
                                    />
                                    <div className="w-full min-w-[105px]">
                                      <InputComponent
                                        value={officePhoneData.phone}
                                        testID="phoneNumber-input"
                                        type="tel"
                                        name="phoneNumber"
                                        flexGrow={1}
                                        borderRadiusLeft={true}
                                        onChange={(e) =>
                                          handleInputPhoneDataChange(
                                            e,
                                            question,
                                            'officePhoneData',
                                            'phone',
                                          )
                                        }
                                        errorStatus={
                                          enableErrorsFeedbackUser &&
                                          !!errorStatus
                                        }
                                      />
                                      {enableErrorsFeedbackUser &&
                                        errors.office_information_phone && (
                                          <ErrorMessageWithIcon
                                            message={
                                              errors.office_information_phone
                                            }
                                          />
                                        )}
                                    </div>
                                  </div>
                                  <div className="flex">
                                    <label
                                      className="mt-3 mb-2.5 mr-2.5 text-base text-dark-gray font-bold desktop:w-2/12 desktop:mb-2"
                                      htmlFor="phoneNumberExtension"
                                    >
                                      {locale?.extensionLabel}
                                    </label>
                                    <div className="flex w-full desktop:max-w-[130px] max-h-[58px]">
                                      <InputComponent
                                        value={officePhoneData?.extension}
                                        flexGrow={0}
                                        type="ext"
                                        name="phoneNumberExtension"
                                        onChange={(e) => {
                                          handleInputPhoneDataChange(
                                            e,
                                            question,
                                            'officePhoneData',
                                            'ext',
                                          );
                                        }}
                                        maxLengthValue={4}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )) ||
                            (question.questionnaire_questions_question
                              .ui_control &&
                              question.questionnaire_questions_question
                                .ui_control === UI_CONTROL.LOOKUP_FIELD &&
                              question.questionnaire_questions_question.id ===
                                SPECIFIC_UNAUTHENTICATED_QUESTION_IDS.OFFICE_INFORMATION_COUNTRY && (
                                <div className="flex flex-col w-full">
                                  <Select
                                    className="dropdown"
                                    classNamePrefix={
                                      enableErrorsFeedbackUser &&
                                      errors.office_information_country
                                        ? 'react-select-with-error'
                                        : 'react-select'
                                    }
                                    placeholder={
                                      locale?.selectLanguagePlaceholder
                                    }
                                    // refactor this
                                    value={
                                      parseValueIntoSelectFormat(
                                        officeAddressInformation.country as string,
                                      ) as DropdownItem
                                    }
                                    // refactor this
                                    options={parsedOptionsCountry}
                                    onChange={(event) => {
                                      setTriggerCopyData(true);
                                      return handleOnOfficeAddressSelectChange(
                                        event,
                                        'country',
                                        question
                                          .questionnaire_questions_question.id,
                                        question
                                          .questionnaire_questions_question
                                          .response_property ?? '',
                                      );
                                    }}
                                    isMulti={false}
                                    noOptionsMessage={() =>
                                      locale?.selectLanguageNoOptions
                                    }
                                    isOptionSelected={(
                                      option: DropdownItem,
                                      selectValue: Options<DropdownItem>,
                                    ) => {
                                      let status = false;
                                      selectValue.forEach((data) => {
                                        if (
                                          data.value.toLowerCase() !== 'none'
                                        ) {
                                          status = false;
                                        } else {
                                          status = true;
                                        }
                                      });
                                      return status;
                                    }}
                                    components={{
                                      Input: (props) => (
                                        <components.Input
                                          {...props}
                                          maxLength={100}
                                        />
                                      ),
                                    }}
                                  />
                                  {enableErrorsFeedbackUser &&
                                    errors.office_information_country && (
                                      <ErrorMessageWithIcon
                                        message={
                                          errors.office_information_country
                                        }
                                      />
                                    )}
                                </div>
                              )) ||
                            (question.questionnaire_questions_question
                              .ui_control &&
                              question.questionnaire_questions_question
                                .ui_control === UI_CONTROL.LOOKUP_FIELD &&
                              question.questionnaire_questions_question.id ===
                                SPECIFIC_UNAUTHENTICATED_QUESTION_IDS.OFFICE_INFORMATION_STATE && (
                                <div className="flex flex-col w-full">
                                  <Select
                                    className="dropdown"
                                    classNamePrefix={
                                      enableErrorsFeedbackUser &&
                                      errors.office_information_state
                                        ? 'react-select-with-error'
                                        : 'react-select'
                                    }
                                    placeholder={
                                      locale?.selectLanguagePlaceholder
                                    }
                                    value={
                                      parseValueIntoSelectFormat(
                                        officeAddressInformation.state as string,
                                      ) as DropdownItem
                                    }
                                    options={parseMultipleValuesIntoSelectFormat(
                                      officeStateData,
                                    )}
                                    onChange={(event) => {
                                      setTriggerCopyData(true);
                                      return handleOnOfficeAddressSelectChange(
                                        event,
                                        'state',
                                        question
                                          .questionnaire_questions_question.id,
                                        question
                                          .questionnaire_questions_question
                                          .response_property ?? '',
                                      );
                                    }}
                                    isMulti={false}
                                    noOptionsMessage={() =>
                                      locale?.selectLanguageNoOptions
                                    }
                                    isOptionSelected={(
                                      option: DropdownItem,
                                      selectValue: Options<DropdownItem>,
                                    ) => {
                                      let status = false;
                                      selectValue.forEach((data) => {
                                        if (
                                          data.value.toLowerCase() !== 'none'
                                        ) {
                                          status = false;
                                        } else {
                                          status = true;
                                        }
                                      });
                                      return status;
                                    }}
                                    components={{
                                      Input: (props) => (
                                        <components.Input
                                          {...props}
                                          maxLength={100}
                                        />
                                      ),
                                    }}
                                  />
                                  {enableErrorsFeedbackUser &&
                                    errors.office_information_state && (
                                      <ErrorMessageWithIcon
                                        message={
                                          errors.office_information_state
                                        }
                                      />
                                    )}
                                </div>
                              )) ||
                            (question.questionnaire_questions_question
                              .ui_control &&
                              question.questionnaire_questions_question
                                .ui_control === UI_CONTROL.LOOKUP_FIELD &&
                              question.questionnaire_questions_question.id ===
                                SPECIFIC_UNAUTHENTICATED_QUESTION_IDS.OFFICE_INFORMATION_CITY && (
                                <div className="flex flex-col w-full">
                                  <CreatableSelect
                                    className="dropdown"
                                    classNamePrefix={
                                      enableErrorsFeedbackUser &&
                                      errors.office_information_city
                                        ? 'react-select-with-error'
                                        : 'react-select'
                                    }
                                    placeholder={
                                      locale?.selectLanguagePlaceholder
                                    }
                                    value={
                                      parseValueIntoSelectFormat(
                                        officeAddressInformation.city as string,
                                      ) as DropdownItem
                                    }
                                    options={parseMultipleValuesIntoSelectFormat(
                                      officeCityData,
                                    )}
                                    onChange={(event) => {
                                      setTriggerCopyData(true);
                                      return handleOnOfficeAddressSelectChange(
                                        event,
                                        'city',
                                        question
                                          .questionnaire_questions_question.id,
                                        question
                                          .questionnaire_questions_question
                                          .response_property ?? '',
                                      );
                                    }}
                                    isMulti={false}
                                    noOptionsMessage={() =>
                                      locale?.selectLanguageNoOptions
                                    }
                                    isOptionSelected={(
                                      option: DropdownItem,
                                      selectValue: Options<DropdownItem>,
                                    ) => {
                                      let status = false;
                                      selectValue.forEach((data) => {
                                        if (
                                          data.value.toLowerCase() !== 'none'
                                        ) {
                                          status = false;
                                        } else {
                                          status = true;
                                        }
                                      });
                                      return status;
                                    }}
                                    onKeyDown={allowOnlyCharactersInCityField}
                                    components={{
                                      Input: (props) => (
                                        <components.Input
                                          {...props}
                                          maxLength={100}
                                        />
                                      ),
                                    }}
                                  />
                                  {enableErrorsFeedbackUser &&
                                    errors.office_information_city && (
                                      <ErrorMessageWithIcon
                                        message={errors.office_information_city}
                                      />
                                    )}
                                </div>
                              )) ||
                            (question.questionnaire_questions_question
                              .ui_control &&
                              question.questionnaire_questions_question
                                .ui_control === UI_CONTROL.LOOKUP_FIELD &&
                              question.questionnaire_questions_question.id ===
                                SPECIFIC_UNAUTHENTICATED_QUESTION_IDS.PERSONAL_INFORMATION_COUNTRY && (
                                <div className="flex flex-col w-full">
                                  <Select
                                    isDisabled={
                                      isSameOfficeAddresCheckboxChecked
                                    }
                                    className="dropdown"
                                    classNamePrefix={
                                      enableErrorsFeedbackUser &&
                                      errors.address_information_country
                                        ? 'react-select-with-error'
                                        : 'react-select'
                                    }
                                    placeholder={
                                      locale?.selectLanguagePlaceholder
                                    }
                                    // refactor this
                                    value={
                                      parseValueIntoSelectFormat(
                                        personalAddressInformation.country as string,
                                      ) as DropdownItem
                                    }
                                    // refactor this
                                    options={parsedOptionsCountry}
                                    onChange={(event) => {
                                      return handleOnPersonalAddressSelectChange(
                                        event,
                                        'country',
                                        question
                                          .questionnaire_questions_question
                                          .id ?? '',
                                        question
                                          .questionnaire_questions_question
                                          .response_property ?? '',
                                      );
                                    }}
                                    isMulti={false}
                                    noOptionsMessage={() =>
                                      locale?.selectLanguageNoOptions
                                    }
                                    isOptionSelected={(
                                      option: DropdownItem,
                                      selectValue: Options<DropdownItem>,
                                    ) => {
                                      let status = false;
                                      selectValue.forEach((data) => {
                                        if (
                                          data.value.toLowerCase() !== 'none'
                                        ) {
                                          status = false;
                                        } else {
                                          status = true;
                                        }
                                      });
                                      return status;
                                    }}
                                    components={{
                                      Input: (props) => (
                                        <components.Input
                                          {...props}
                                          maxLength={100}
                                        />
                                      ),
                                    }}
                                  />
                                  {enableErrorsFeedbackUser &&
                                    errors.address_information_country && (
                                      <ErrorMessageWithIcon
                                        message={
                                          errors.address_information_country
                                        }
                                      />
                                    )}
                                </div>
                              )) ||
                            (question.questionnaire_questions_question
                              .ui_control &&
                              question.questionnaire_questions_question
                                .ui_control === UI_CONTROL.LOOKUP_FIELD &&
                              question.questionnaire_questions_question.id ===
                                SPECIFIC_UNAUTHENTICATED_QUESTION_IDS.PERSONAL_INFORMATION_STATE && (
                                <div className="flex flex-col w-full">
                                  <Select
                                    isDisabled={
                                      isSameOfficeAddresCheckboxChecked
                                    }
                                    className="dropdown"
                                    classNamePrefix={
                                      enableErrorsFeedbackUser &&
                                      errors.address_information_state
                                        ? 'react-select-with-error'
                                        : 'react-select'
                                    }
                                    placeholder={
                                      locale?.selectLanguagePlaceholder
                                    }
                                    value={
                                      parseValueIntoSelectFormat(
                                        personalAddressInformation.state as string,
                                      ) as DropdownItem
                                    }
                                    options={parseMultipleValuesIntoSelectFormat(
                                      personalStateData,
                                    )}
                                    onChange={(event) => {
                                      return handleOnPersonalAddressSelectChange(
                                        event,
                                        'state',
                                        question
                                          .questionnaire_questions_question
                                          .id ?? '',
                                        question
                                          .questionnaire_questions_question
                                          .response_property ?? '',
                                      );
                                    }}
                                    isMulti={false}
                                    noOptionsMessage={() =>
                                      locale?.selectLanguageNoOptions
                                    }
                                    isOptionSelected={(
                                      option: DropdownItem,
                                      selectValue: Options<DropdownItem>,
                                    ) => {
                                      let status = false;
                                      selectValue.forEach((data) => {
                                        if (
                                          data.value.toLowerCase() !== 'none'
                                        ) {
                                          status = false;
                                        } else {
                                          status = true;
                                        }
                                      });
                                      return status;
                                    }}
                                    components={{
                                      Input: (props) => (
                                        <components.Input
                                          {...props}
                                          maxLength={100}
                                        />
                                      ),
                                    }}
                                  />
                                  {enableErrorsFeedbackUser &&
                                    errors.address_information_state && (
                                      <ErrorMessageWithIcon
                                        message={
                                          errors.address_information_state
                                        }
                                      />
                                    )}
                                </div>
                              )) ||
                            (question.questionnaire_questions_question
                              .ui_control &&
                              question.questionnaire_questions_question
                                .ui_control === UI_CONTROL.LOOKUP_FIELD &&
                              question.questionnaire_questions_question.id ===
                                SPECIFIC_UNAUTHENTICATED_QUESTION_IDS.PERSONAL_INFORMATION_CITY && (
                                <div className="flex flex-col w-full">
                                  <CreatableSelect
                                    isDisabled={
                                      isSameOfficeAddresCheckboxChecked
                                    }
                                    className="dropdown"
                                    classNamePrefix={
                                      enableErrorsFeedbackUser &&
                                      errors.address_information_city
                                        ? 'react-select-with-error'
                                        : 'react-select'
                                    }
                                    placeholder={
                                      locale?.selectLanguagePlaceholder
                                    }
                                    value={
                                      parseValueIntoSelectFormat(
                                        personalAddressInformation.city as string,
                                      ) as DropdownItem
                                    }
                                    options={parseMultipleValuesIntoSelectFormat(
                                      personalCityData,
                                    )}
                                    onChange={(event) => {
                                      return handleOnPersonalAddressSelectChange(
                                        event,
                                        'city',
                                        question
                                          .questionnaire_questions_question
                                          .id ?? '',
                                        question
                                          .questionnaire_questions_question
                                          .response_property ?? '',
                                      );
                                    }}
                                    isMulti={false}
                                    noOptionsMessage={() =>
                                      locale?.selectLanguageNoOptions
                                    }
                                    isOptionSelected={(
                                      option: DropdownItem,
                                      selectValue: Options<DropdownItem>,
                                    ) => {
                                      let status = false;
                                      selectValue.forEach((data) => {
                                        if (
                                          data.value.toLowerCase() !== 'none'
                                        ) {
                                          status = false;
                                        } else {
                                          status = true;
                                        }
                                      });
                                      return status;
                                    }}
                                    onKeyDown={allowOnlyCharactersInCityField}
                                    components={{
                                      Input: (props) => (
                                        <components.Input
                                          {...props}
                                          maxLength={100}
                                        />
                                      ),
                                    }}
                                  />
                                  {enableErrorsFeedbackUser &&
                                    errors.address_information_city && (
                                      <ErrorMessageWithIcon
                                        message={
                                          errors.address_information_city
                                        }
                                      />
                                    )}
                                </div>
                              )) ||
                            (question.questionnaire_questions_question
                              .ui_control === UI_CONTROL.SMALL_TEXT && (
                              <div className="max-w-[160px]">
                                <InputComponent
                                  isDisabled={
                                    !question.questionnaire_questions_question.response_property?.includes(
                                      'office',
                                    ) && isSameOfficeAddresCheckboxChecked
                                  }
                                  value={localValue as string}
                                  noMarginBottom={true}
                                  type={getUiControlType(
                                    question.questionnaire_questions_question
                                      .ui_control,
                                  )}
                                  onChange={(e) => {
                                    setTriggerCopyData(true);
                                    handleInputTextChange(e, question);
                                  }}
                                  errorStatus={
                                    enableErrorsFeedbackUser && !!errorStatus
                                  }
                                  errorMsgWithIcon={
                                    (enableErrorsFeedbackUser &&
                                      errorStatus) as string
                                  }
                                />
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  {currentSection.name ===
                    SPECIFIC_UNAUTHENTICATED_SECTION.CONTACT_INFO &&
                    question.questionnaire_questions_question.id ===
                      SPECIFIC_UNAUTHENTICATED_QUESTION_IDS.CONTACT_INFO_OFFICE_ZIP_CODE && (
                      <>
                        <hr className="flex flex-row w-full items-center h-px bg-black-blur my-5 mb-[25px]" />

                        <div className="mb-[15px]">
                          <p className="text-h7 font-bold uppercase mb-[15px]">
                            {locale?.contactInfoTitle}
                          </p>
                          <p className="text-med-gray text-base">
                            {locale?.contactInfoSubtitle}
                          </p>
                        </div>

                        <div className="flex items-center mb-5">
                          <InputComponent
                            type="checkbox"
                            checkboxProps={{
                              checked: isSameOfficeAddresCheckboxChecked,
                              onCheckboxChange: handleCheckboxCopyData,
                            }}
                          />
                          <p className="font-semibold text-med-gray text-sm ml-[10px]">
                            {locale?.contactInfoCheckboxLabel}
                          </p>
                        </div>
                      </>
                    )}
                  <div
                    className={`flex flex-col ${
                      currentSection.name ===
                        SPECIFIC_UNAUTHENTICATED_SECTION.ACCOUNT_INFO ||
                      currentSection.name ===
                        SPECIFIC_UNAUTHENTICATED_SECTION.CONTACT_INFO
                        ? ''
                        : 'desktop:pl-10 pb-10'
                    }`}
                  >
                    {currentSection.name ===
                      SPECIFIC_UNAUTHENTICATED_SECTION.PERSONAL_INFO &&
                      nestedQuestionList.map((questionStatus, key) => (
                        <div key={key}>
                          {renderNestedQuestions(
                            questionStatus,
                            question,
                            question.questionnaire_questions_question.id,
                            questionStatus.dependency,
                            () => null,
                            () => null,
                            section,
                            radioCheckedState,
                            () => null,
                            undefined,
                            () => null,
                            textFieldResponses,
                            setTextFieldResponses,
                          )}
                        </div>
                      ))}
                  </div>
                </>
              ) : (
                <>
                  <div key={question.questionnaire_questions_question.id}>
                    <div className="mb-4">
                      <div
                        className={`flex flex-col ${
                          currentSection.name ===
                            SPECIFIC_UNAUTHENTICATED_SECTION.ACCOUNT_INFO ||
                          currentSection.name ===
                            SPECIFIC_UNAUTHENTICATED_SECTION.CONTACT_INFO
                            ? 'desktop:flex-row'
                            : 'desktop:flex-col'
                        }`}
                      >
                        <p className="flex items-center pr-5 mr-auto font-semibold text-dark-gray">
                          {question.questionnaire_questions_question.text}
                          {isRequiredQuestion(
                            question.questionnaire_questions_question.id.toString(),
                          ) && (
                            <span className="text-base font-bold text-clc-red">
                              *
                            </span>
                          )}
                          {question.questionnaire_questions_question
                            .has_tooltip && (
                            <span className="ml-1 h-[22px]">
                              {isMobile && (
                                <Link to={`${AUTH_SIGN_UP_PROVIDER_NPI_MODAL}`}>
                                  <IconComponent
                                    width="w-5"
                                    height="h-5"
                                    icon={InfoIcon}
                                    fill="fill-clc-blue"
                                  />
                                </Link>
                              )}
                              {!isMobile && (
                                <Tooltip
                                  text={
                                    question.questionnaire_questions_question
                                      .tooltip_content ?? ''
                                  }
                                >
                                  <span className="cursor-pointer">
                                    <IconComponent
                                      width="w-5"
                                      height="h-5"
                                      icon={InfoIcon}
                                      fill="fill-clc-blue"
                                    />
                                  </span>
                                </Tooltip>
                              )}
                            </span>
                          )}
                        </p>
                        {question.questionnaire_questions_question.subtitle && (
                          <p className="italic text-med-gray">
                            {question.questionnaire_questions_question.subtitle}
                          </p>
                        )}

                        <div
                          className={`flex flex-col ${
                            currentSection.name ===
                            SPECIFIC_UNAUTHENTICATED_SECTION.PERSONAL_INFO
                              ? 'w-full'
                              : 'w-full desktop:min-w-[435px] desktop:max-w-[435px] desktop:ml-3'
                          }`}
                        >
                          {(question.questionnaire_questions_question
                            .ui_control &&
                            question.questionnaire_questions_question
                              .ui_control === UI_CONTROL.TEXT && (
                              <InputComponent
                                value={localValue as string}
                                noMarginBottom={true}
                                type={getUiControlType(
                                  question.questionnaire_questions_question
                                    .ui_control,
                                )}
                                onChange={(e) => {
                                  handleInputTextChange(e, question);
                                }}
                                errorStatus={
                                  enableErrorsFeedbackUser && !!errorStatus
                                }
                                errorMsgWithIcon={
                                  (enableErrorsFeedbackUser &&
                                    errorStatus) as string
                                }
                              />
                            )) ||
                            (question.questionnaire_questions_question
                              .ui_control &&
                              question.questionnaire_questions_question
                                .ui_control === UI_CONTROL.PHONE && (
                                <div className="w-full flex flex-col desktop:flex-row">
                                  <div className="flex w-full mr-5">
                                    <div className="desktop:max-w-full">
                                      <InputComponent
                                        testID="phoneNumber-country-select"
                                        type="select-with-country-icons"
                                        name="country"
                                        borderRadiusRight={true}
                                        selectInputWithFlagsProps={{
                                          currentValue:
                                            getMemoizedCurrentCountryValue as SelectWithFlagsValues,
                                          defaultValue:
                                            getMemoizedDefaultParsedValue as SelectWithFlagsValues,
                                          options:
                                            getMemoizedParsedCountries as SelectWithFlagsValues[],
                                          onSelectChange: (e) => {
                                            setPhoneData({
                                              phone: phoneData?.phone,
                                              country: e.label,
                                              extension: phoneData?.extension,
                                            });
                                            setTriggerPhoneChange(true);
                                          },
                                        }}
                                      />
                                    </div>
                                    <div className="w-full min-w-[105px]">
                                      <InputComponent
                                        value={phoneData.phone}
                                        testID="phoneNumber-input"
                                        type="tel"
                                        name="phoneNumber"
                                        flexGrow={1}
                                        borderRadiusLeft={true}
                                        onChange={(e) =>
                                          handleInputPhoneDataChange(
                                            e,
                                            question,
                                            'phoneData',
                                            'phone',
                                          )
                                        }
                                        errorStatus={
                                          enableErrorsFeedbackUser &&
                                          !!errorStatus
                                        }
                                      />
                                      {enableErrorsFeedbackUser &&
                                        errors.phone && (
                                          <ErrorMessageWithIcon
                                            message={errors.phone}
                                          />
                                        )}
                                    </div>
                                  </div>
                                  <div className="flex">
                                    <label
                                      className="mt-3 mb-2.5 mr-2.5 text-base text-dark-gray font-bold desktop:w-2/12 desktop:mb-2"
                                      htmlFor="phoneNumberExtension"
                                    >
                                      {locale?.extensionLabel}
                                    </label>
                                    <div className="flex w-full desktop:max-w-[130px] max-h-[58px]">
                                      <InputComponent
                                        value={phoneData?.extension}
                                        flexGrow={0}
                                        type="ext"
                                        name="phoneNumberExtension"
                                        onChange={(e) => {
                                          handleInputPhoneDataChange(
                                            e,
                                            question,
                                            'phoneData',
                                            'ext',
                                          );
                                        }}
                                        maxLengthValue={4}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )) ||
                            (question.questionnaire_questions_question
                              .ui_control &&
                              question.questionnaire_questions_question
                                .ui_control === UI_CONTROL.PASSWORD && (
                                <div>
                                  <InputComponent
                                    value={localValue as string}
                                    testID="password-input"
                                    type="password"
                                    name="password"
                                    onChange={(e) => {
                                      handleInputTextChange(e, question);
                                    }}
                                    errorStatus={
                                      enableErrorsFeedbackUser && !!errorStatus
                                    }
                                    errorMsgWithIcon={
                                      (enableErrorsFeedbackUser &&
                                        errorStatus) as string
                                    }
                                    maxLengthValue={64}
                                  />
                                </div>
                              )) ||
                            (question.questionnaire_questions_question
                              .ui_control &&
                              question.questionnaire_questions_question
                                .ui_control === UI_CONTROL.RADIO_BUTTON && (
                                <div>
                                  {question.questionnaire_questions_question
                                    .answers &&
                                    question.questionnaire_questions_question.answers.map(
                                      (answer) => {
                                        return (
                                          <div
                                            key={answer}
                                            className="flex flex-col"
                                          >
                                            <InputComponent
                                              key={answer}
                                              type={getUiControlType(
                                                question
                                                  .questionnaire_questions_question
                                                  .ui_control,
                                              )}
                                              radioInputProps={{
                                                radioError:
                                                  ((enableErrorsFeedbackUser &&
                                                    errors?.gender) as boolean) ===
                                                  false
                                                    ? null
                                                    : errors.gender,
                                                radioInputValue: answer,
                                                radioInputLabel: answer,
                                                radioInputCheckedValue:
                                                  matchSelectedRadio(
                                                    question
                                                      .questionnaire_questions_question
                                                      .id,
                                                    answer,
                                                    radioCheckedState,
                                                  )
                                                    ? answer
                                                    : undefined,
                                                onRadioClick: () => {
                                                  handleLocalProccesedFormData(
                                                    question
                                                      ?.questionnaire_questions_question
                                                      .response_property ?? '',
                                                    answer as string,
                                                  );
                                                  handleRadioClick(
                                                    question
                                                      .questionnaire_questions_question
                                                      .id,
                                                    answer,
                                                  );
                                                },
                                              }}
                                            />
                                          </div>
                                        );
                                      },
                                    )}
                                </div>
                              )) ||
                            (question.questionnaire_questions_question
                              .ui_control &&
                              question.questionnaire_questions_question
                                .ui_control ===
                                UI_CONTROL.LOOKUP_WITH_MULTISELECT &&
                              question.questionnaire_questions_question.id ===
                                SPECIFIC_UNAUTHENTICATED_QUESTION_IDS.LANGUAGES && (
                                <div className="flex flex-col w-full">
                                  <Select
                                    className="dropdown"
                                    classNamePrefix={
                                      enableErrorsFeedbackUser &&
                                      firstNonNullValueOfErrors &&
                                      selectedValue[0].value.length < 1
                                        ? 'react-select-with-error'
                                        : 'react-select'
                                    }
                                    placeholder={
                                      locale?.selectLanguagePlaceholder
                                    }
                                    value={getSelectedValues(
                                      languageParsedOptions,
                                      selectedValue[0].value,
                                    )}
                                    options={languageParsedOptions}
                                    onChange={(event) => {
                                      handleLocalSelectProccesedFormData(
                                        question
                                          .questionnaire_questions_question
                                          .response_property ?? '',
                                        selectedValue[0].value,
                                      );
                                      return handleChange(
                                        event,
                                        0,
                                        question
                                          .questionnaire_questions_question.id,
                                        question
                                          .questionnaire_questions_question
                                          .response_property ?? '',
                                      );
                                    }}
                                    isMulti={true}
                                    noOptionsMessage={() =>
                                      locale?.selectLanguageNoOptions
                                    }
                                    isOptionSelected={(
                                      option: DropdownItem,
                                      selectValue: Options<DropdownItem>,
                                    ) => {
                                      let status = false;
                                      selectValue.forEach((data) => {
                                        if (
                                          data.value.toLowerCase() !== 'none'
                                        ) {
                                          status = false;
                                        } else {
                                          status = true;
                                        }
                                      });
                                      return status;
                                    }}
                                  />
                                  {enableErrorsFeedbackUser &&
                                    firstNonNullValueOfErrors &&
                                    selectedValue[0].value.length < 1 && (
                                      <ErrorMessageWithIcon
                                        message={locale?.fieldRequired}
                                      />
                                    )}
                                </div>
                              )) ||
                            (question.questionnaire_questions_question
                              .ui_control &&
                              question.questionnaire_questions_question
                                .ui_control === UI_CONTROL.TEXT_AREA && (
                                <div className="">
                                  <InputComponent
                                    value={
                                      textLocalFieldResponses &&
                                      textLocalFieldResponses[0] &&
                                      (textLocalFieldResponses[0]
                                        .response as string)
                                    }
                                    key={
                                      question.questionnaire_questions_question
                                        .id
                                    }
                                    maxLengthValue={1500}
                                    type="text-area"
                                    placeholder={
                                      locale?.personalInfoTextAreaPlaceholder
                                    }
                                    textAreaProps={{
                                      height: 'h-[120px]',
                                      errorClasses:
                                        enableErrorsFeedbackUser && errors.about
                                          ? 'border-alert-negative bg-error-yellow'
                                          : '',
                                      onTextAreaChange: (e) => {
                                        handleLocalTextFieldResponses(
                                          e.target.value,
                                          question
                                            .questionnaire_questions_question
                                            .id,
                                          0,
                                        );
                                        handleTextFieldResponses(
                                          e.target.value,
                                          question
                                            .questionnaire_questions_question
                                            .id,
                                          question,
                                          question
                                            .questionnaire_questions_question
                                            .response_property,
                                        );
                                        handleLocalFormData(
                                          question,
                                          e as unknown as ChangeEvent<HTMLInputElement>,
                                        );
                                      },
                                    }}
                                  />
                                  {enableErrorsFeedbackUser && errors.about && (
                                    <ErrorMessageWithIcon
                                      message={errors.about}
                                    />
                                  )}
                                </div>
                              ))}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`flex flex-col ${
                      currentSection.name ===
                        SPECIFIC_UNAUTHENTICATED_SECTION.ACCOUNT_INFO ||
                      currentSection.name ===
                        SPECIFIC_UNAUTHENTICATED_SECTION.CONTACT_INFO
                        ? ''
                        : 'desktop:pl-10 pb-10'
                    }`}
                  >
                    {currentSection.name ===
                      SPECIFIC_UNAUTHENTICATED_SECTION.PERSONAL_INFO &&
                      nestedQuestionList.map((questionStatus, key) => (
                        <div key={key}>
                          {renderNestedQuestions(
                            questionStatus,
                            question,
                            question.questionnaire_questions_question.id,
                            questionStatus.dependency,
                            () => null,
                            () => null,
                            section,
                            radioCheckedState,
                            () => null,
                            undefined,
                            () => null,
                            textFieldResponses,
                            setTextFieldResponses,
                          )}
                        </div>
                      ))}
                  </div>
                </>
              )}
            </>
          );
        })}

        {section.name ===
          SPECIFIC_UNAUTHENTICATED_SECTION.CRENDENTIALS_AND_CERTIFICATIONS && (
          <div>
            {sortedEducationList &&
              sortedEducationList.length >= 0 &&
              sortedEducationList.map((educationItem, index) => (
                <>
                  {index != 0 && (
                    <hr className="flex flex-row w-full items-center h-px bg-black-blur my-5" />
                  )}
                  <div key={educationItem.id}>
                    {questionList.map((question) => {
                      const educationEvaluationForError =
                        (
                          getEducationListValue(
                            index,
                            question.questionnaire_questions_question.id,
                          ) as DropdownItem
                        ).value === '';

                      return (
                        SignUpProviderSkipRenderingIds.includes(
                          question.questionnaire_questions_question.id,
                        ) && (
                          <div
                            className="flex flex-col desktop:flex-row mb-4"
                            key={question.questionnaire_questions_question.id}
                          >
                            <p className="flex items-center pr-5 mr-auto font-semibold text-dark-gray">
                              {question.questionnaire_questions_question.text}
                              {isRequiredQuestion(
                                question.questionnaire_questions_question.id.toString(),
                              ) && (
                                <span className="text-base font-bold text-clc-red">
                                  *
                                </span>
                              )}
                            </p>
                            {question.questionnaire_questions_question
                              .subtitle && (
                              <p className="italic text-med-gray">
                                {
                                  question.questionnaire_questions_question
                                    .subtitle
                                }
                              </p>
                            )}
                            <div className="w-full desktop:min-w-[435px] desktop:max-w-[435px] desktop:ml-3">
                              <CreatableSelect
                                className="dropdown"
                                classNamePrefix={
                                  enableErrorsFeedbackUser &&
                                  firstNonNullValueOfErrors !== undefined
                                    ? educationEvaluationForError
                                      ? 'react-select-with-error'
                                      : 'react-select'
                                    : 'react-select'
                                }
                                placeholder={locale?.lookupPlaceholder}
                                value={
                                  educationEvaluationForError === true
                                    ? undefined
                                    : (getEducationListValue(
                                        index,
                                        question
                                          .questionnaire_questions_question.id,
                                      ) as DropdownItem)
                                }
                                options={undefined}
                                onChange={(e) => {
                                  handleDropdownOptionSelected(
                                    e,
                                    question.questionnaire_questions_question
                                      .id,
                                    index,
                                  );
                                }}
                                isMulti={false}
                                noOptionsMessage={() =>
                                  locale?.selectCreateEntry
                                }
                                isOptionSelected={(
                                  option: DropdownItem,
                                  selectValue: Options<DropdownItem>,
                                ) => {
                                  let status = false;
                                  selectValue.forEach((data) => {
                                    if (data.value.toLowerCase() !== 'none') {
                                      status = false;
                                    } else {
                                      status = true;
                                    }
                                  });
                                  return status;
                                }}
                                components={{
                                  Input: (props) => (
                                    <components.Input
                                      {...props}
                                      maxLength={100}
                                    />
                                  ),
                                }}
                              />
                              {enableErrorsFeedbackUser &&
                                firstNonNullValueOfErrors !== undefined &&
                                educationEvaluationForError && (
                                  <ErrorMessageWithIcon
                                    message={locale?.fieldRequired}
                                  />
                                )}
                            </div>
                          </div>
                        )
                      );
                    })}
                    {educationList.length > 1 && index >= 1 && (
                      <div className="flex pt-2 pb-6">
                        {
                          <button
                            className="flex mr-6"
                            onClick={() => removeEducation(index)}
                          >
                            <MinusIcon className="text-clc-blue mr-2" />
                            <span className="text-clc-blue font-bold underline uppercase">
                              {locale?.removeEducation}
                            </span>
                          </button>
                        }
                      </div>
                    )}
                  </div>
                </>
              ))}
            <hr className="flex flex-row w-full items-center h-px bg-black-blur mt-5" />
            <button
              className="flex mr-3 my-5"
              onClick={insertAfterLastEducation}
            >
              <PlusIcon className="mr-2" />
              <span className="text-clc-blue font-bold underline uppercase">
                {locale?.addMoreEducation}
              </span>
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default SignUpProviderQuestionnaireModule;
