import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { userHasRequiredRole } from '../firebase/hooks';
import { Roles } from '../firebase/interfaces';
import { pageIds } from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';
import Alert from './alertComponent';
import Modal from './modal/modalComponent';
import { DASHBOARD } from 'utilities/routes';

interface RoleRequiredRouteProps {
  allowedRoles: Roles[];
  children: ReactNode;
}

const RoleRequiredRoute: React.FC<RoleRequiredRouteProps> = ({
  allowedRoles,
  children,
}) => {
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.ROLE_REQUIRED,
  });
  const navigate = useNavigate();
  const [isAllowed, setIsAllowed] = useState<boolean>(false);
  const hasBeenProcessed = useRef<boolean>(false);

  const checkRole = useCallback(async () => {
    for (const role of allowedRoles) {
      const isAllowed = await userHasRequiredRole(role);
      hasBeenProcessed.current = true;
      if (isAllowed) {
        setIsAllowed(true);
        break;
      }
    }
  }, [allowedRoles]);

  useEffect(() => {
    checkRole();
  }, [checkRole]);

  const handleButtonClick = () => {
    navigate(DASHBOARD);
  };

  if (loading || !locale || !hasBeenProcessed.current) return null;

  return isAllowed ? (
    <>{children}</>
  ) : (
    <>
      <Modal
        isOpen={true}
        buttons={[
          { label: locale?.returnToDashboard, onClick: handleButtonClick },
        ]}
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
      >
        <Alert type={'warning'} text={locale?.noPermissions} />
      </Modal>
    </>
  );
};

export default RoleRequiredRoute;
