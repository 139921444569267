import { ProvidersFilterType } from '../components/ProviderSideBarFilterInterfaces';

export const toggleButtonGroupMock = {
  buttons: ['$', '$$', '$$$', '$$$$'],
};

export const filterOptions: Record<string, ProvidersFilterType> = {
  speciality: {
    options: [
      {
        value: 'all',
        label: 'All',
        type: 'radio',
      },
      {
        value: 'dentist',
        label: 'Dentist',
        type: 'checkbox',
      },
      {
        value: 'doctor',
        label: 'Doctor',
        type: 'checkbox',
      },
      {
        value: 'nurse',
        label: 'Nurse',
        type: 'checkbox',
      },
      {
        value: 'other',
        label: 'Other',
        type: 'checkbox',
      },
      {
        value: 'viewAll',
        label: 'View all',
        type: 'button',
      },
    ],
  },
  appointmentAccepted: {
    options: [
      {
        value: 'all',
        label: 'All',
        type: 'radio',
      },
      {
        value: 'video',
        label: 'Video',
        type: 'checkbox',
      },
      {
        value: 'chat',
        label: 'Chat',
        type: 'checkbox',
      },
      {
        value: 'messaging',
        label: 'Messaging',
        type: 'checkbox',
      },
    ],
  },
  gender: {
    options: [
      {
        value: 'all',
        label: 'All genders',
        type: 'radio',
      },
      {
        value: 'female',
        label: 'Female',
        type: 'checkbox',
      },
      {
        value: 'male',
        label: 'Male',
        type: 'checkbox',
      },
    ],
  },
  providerCanSpeak: {
    options: [
      {
        value: 'english',
        label: 'English',
        type: 'checkbox',
      },
      {
        value: 'spanish',
        label: 'Spanish',
        type: 'checkbox',
      },
      {
        value: 'portuguese',
        label: 'Portuguese',
        type: 'checkbox',
      },
      {
        value: 'french',
        label: 'French',
        type: 'checkbox',
      },
      {
        value: 'viewAll',
        label: 'View all',
        type: 'button',
      },
    ],
  },
};

export type FilterLabels = {
  availability: string;
  speciality: string;
  distance: string;
  appointmentAccepted: string;
  gender: string;
  providerCanSpeak: string;
};

export const filterLabels: FilterLabels = {
  availability: 'Availability',
  speciality: 'Speciality',
  distance: 'Distance',
  appointmentAccepted: 'Appointment accepted',
  gender: 'Gender',
  providerCanSpeak: 'Provider can speak',
};
