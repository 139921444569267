import { ModalButtonProps } from 'components/modal/modal.interfaces';
import Modal from 'components/modal/modalComponent';
import {
  useAccountDeletion,
  useUserProviders,
} from '../../../../../../firebase/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import IconComponent from 'components/iconComponent';
import { useGetPage } from 'hooks/useGetPage';
import { pageIds } from 'utilities/constants';
import InputComponent from 'components/inputComponent';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { AuthContext } from 'auth/context/AuthContext';
import { AUTH_ACCOUNT_DELETED, MY_ACCOUNT_PROFILE } from 'utilities/routes';
import { avoidSpacesOnKeyDown } from 'utilities/functions';

export const AccountDeletion = () => {
  const location = useLocation();
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.MY_PROFILE,
  });
  const { user: loggedUser, logout } = useContext(AuthContext);
  const showEmailInput = loggedUser?.signInProvider === 'password';
  const navigate = useNavigate();
  const { accountDeletion, errorMsg } = useAccountDeletion();
  const [formValues, setFormValues] = useState({
    email: '',
    validationText: '',
  });
  const [emailValid, setEmailValid] = useState(true);
  const [textValid, setTextValid] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const { userProviders } = useUserProviders();
  const section = location.state?.currentSidebarItem;

  const handleCancel = () => {
    navigate(MY_ACCOUNT_PROFILE, {
      state: {
        section,
      },
    });
  };

  const handleConfirm = async () => {
    setIsButtonDisabled(true);
    const response = await accountDeletion();
    setIsButtonDisabled(false);
    if (response) {
      logout();
      navigate(AUTH_ACCOUNT_DELETED);
    }
  };

  useEffect(() => {
    if (
      (formValues.email.length || !showEmailInput) &&
      formValues.validationText.length
    )
      setIsButtonDisabled(!(emailValid && textValid));
  }, [emailValid, textValid, formValues, showEmailInput]);

  useEffect(() => {
    if (!showEmailInput) {
      setEmailValid(true);
    }
  }, [showEmailInput]);

  const handleOnInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    const trimmedValue = value.replace(/\s/g, ''); // Trim the value

    if (name === 'email') {
      setEmailValid(value === loggedUser?.email);
    }

    if (name === 'validationText') {
      setTextValid(value === locale?.accountDeletion.form.validationText);
    }

    setFormValues({ ...formValues, [name]: trimmedValue });
  };

  if (loading && !locale) return null;

  const Buttons: ModalButtonProps[] = [
    {
      label: locale.accountDeletion.form.confirmButton,
      onClick: handleConfirm,
      disabled: isButtonDisabled,
    },
    {
      label: locale.accountDeletion.form.cancelButton,
      type: 'underline',
      onClick: handleCancel,
    },
  ];

  return (
    <Modal
      isOpen={true}
      title={locale.accountDeletion.title}
      modalDesktopWidth="desktop:max-w-[600px]"
      buttons={Buttons}
      onClose={handleCancel}
    >
      {userProviders?.includes('apple.com') && (
        <div className="flex flex-col desktop:flex-row items-center p-5 desktop:py-3 px-5 gap-[15px] bg-white rounded-b-lg">
          <IconComponent
            width="w-12 desktop:w-6"
            height="h-12 desktop:h-6"
            icon={InfoIcon}
            fill="fill-clc-blue"
          />
          <p className="text-base font-semibold text-med-gray">
            {locale.accountDeletion.appleMessage}
          </p>
        </div>
      )}

      <div className="flex flex-col desktop:flex-row items-center desktop:gap-[15px]">
        <p className="text-base font-medium text-med-gray">
          {`${locale.accountDeletion.securityMessage1} `}
          <a
            href={locale.accountDeletion.HIPAALink}
            target="_blank"
            className="text-clc-blue underline cursor-pointer"
          >
            {locale.accountDeletion.HIPAAtext}
          </a>
          {` ${locale.accountDeletion.securityMessage2} `}
          <span className="text-clc-blue underline cursor-pointer">
            {locale.accountDeletion.policyText}
          </span>
          {locale.accountDeletion.securityMessage3}
        </p>
      </div>
      <div className="flex flex-col desktop:flex-row items-center desktop:gap-[15px]">
        <p className="text-base font-medium text-med-gray">
          {locale.accountDeletion.confirmMessage}
        </p>
      </div>

      {showEmailInput && (
        <div className="flex flex-col desktop:flex-row items-start desktop:items-center">
          <label
            className="w-full desktop:w-3/12 text-base text-dark-gray font-semibold mb-2"
            htmlFor="email"
          >
            {locale.accountDeletion.form.emailLabel}
            <span className="text-base font-bold text-clc-red">
              {locale.accountDeletion.form.required}
            </span>
          </label>
          <div className="w-full desktop:w-9/12">
            <InputComponent
              testID="email-input"
              type="email"
              name="email"
              onChange={handleOnInputChange}
              onKeyDown={avoidSpacesOnKeyDown}
              value={formValues.email}
              errorMsg={
                emailValid ? undefined : locale.accountDeletion.form.emailError
              }
            />
          </div>
        </div>
      )}

      <div className="flex flex-col desktop:flex-row items-start desktop:items-center">
        <label
          className="w-full desktop:w-3/12 text-base text-dark-gray font-semibold mb-2"
          htmlFor="validationText"
        >
          {locale.accountDeletion.form.validationLabel}
          <span className="text-base font-bold text-clc-red">
            {locale.accountDeletion.form.required}
          </span>
        </label>
        <div className="w-full desktop:w-9/12">
          <InputComponent
            testID="validationText-input"
            type="text"
            name="validationText"
            onChange={handleOnInputChange}
            value={formValues.validationText}
            onKeyDown={avoidSpacesOnKeyDown}
            errorMsg={
              textValid
                ? undefined
                : locale.accountDeletion.form.validationError
            }
          />
        </div>
      </div>

      {errorMsg && <p className="text-clc-red">{errorMsg}</p>}
    </Modal>
  );
};
