import { useGetDnaVisitIframeSecretMutation } from 'graphql/generated/hasura';
import { useEffect, useRef } from 'react';

interface Props {
  email: string;
}
const DNAVisitIFrameTest: React.FC<Props> = ({ email }) => {
  const isFirstRender = useRef(false);

  const [getDnaVisitIframeSecret, { data, loading }] =
    useGetDnaVisitIframeSecretMutation({
      variables: { userEmail: email },
    });

  useEffect(() => {
    if (!isFirstRender.current) {
      isFirstRender.current = true;
      getDnaVisitIframeSecret();
    }
  }, [email, isFirstRender, getDnaVisitIframeSecret]);

  if (!data || loading) return null;

  return (
    <iframe
      src={`https://www.dnavisit.com/hosting/hostedchatmain?t=${data.getDNAVisitIframeSecret.dnaVisitIframeSecret}`}
      className="w-full h-full"
      referrerPolicy="no-referrer"
    />
  );
};

export default DNAVisitIFrameTest;
