import ButtonComponent from 'components/button/buttonComponent';
import { ReactComponent as ChevronDownIcon } from 'assets/icons/chevron-down.svg';
import { ReactComponent as ChevronUpIcon } from 'assets/icons/chevron-up-fix.svg';
import React, { useState } from 'react';

export interface CardProps {
  title: string;
  subtitle?: string | React.ReactNode;
  children: React.ReactNode;
  backgroundColor?: string;
  alwaysExpanded?: boolean;
  rightComponent?: React.ReactNode;
  hideMobileSubtitle?: boolean;
  borderedSubtitleDesktop?: boolean;
  showSeparator?: boolean;
}

export const Card: React.FC<CardProps> = ({
  title,
  subtitle,
  children,
  backgroundColor = 'bg-white',
  alwaysExpanded = false,
  rightComponent = null,
  hideMobileSubtitle = false,
  borderedSubtitleDesktop = false,
  showSeparator = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(alwaysExpanded);
  const ArrowIcon = isExpanded ? ChevronUpIcon : ChevronDownIcon;
  const handleExpandIconClick = () => {
    setIsExpanded((isExpanded) => !isExpanded);
  };

  return (
    <div
      className={`flex flex-col items-center px-5 pt-5 pb-[30px] desktop:p-[30px] gap-5 ${backgroundColor} rounded-10`}
    >
      <div className="flex flex-col w-full">
        <div className="flex flex-row w-full items-center justify-between p-0 gap-2.5">
          <div className="flex flex-row items-end gap-[7px]">
            <h3 className="flex text-h4 text-dark-gray font-semibold">
              {title}
            </h3>
            {borderedSubtitleDesktop ? (
              <div className="px-2 self-center bg-black bg-opacity-5 rounded-5 flex-col justify-center items-center inline-flex">
                <div className="text-xs font-semibold">{subtitle}</div>
              </div>
            ) : (
              <span className="text-xs hidden desktop:inline text-med-gray">
                {subtitle}
              </span>
            )}
          </div>
          <div className="flex flex-row items-center gap-2">
            <div className="hidden desktop:flex flex-row items-center gap-2">
              {rightComponent}
            </div>
            {!alwaysExpanded && (
              <ButtonComponent
                type="underline"
                iconPosition="right"
                Icon={ArrowIcon}
                fill="black"
                stroke="black"
                onClick={handleExpandIconClick}
              />
            )}
          </div>
        </div>
        {!hideMobileSubtitle && (
          <span className="text-xs self-start desktop:hidden text-med-gray">
            {subtitle}
          </span>
        )}
        <div className="desktop:hidden">{rightComponent}</div>
      </div>
      {showSeparator && isExpanded && (
        <div className="w-full border-t-2 border-card-border-color" />
      )}
      {(isExpanded || alwaysExpanded) && children}
    </div>
  );
};
