import DosespotIFrame from 'components/DosespotIframe';
import { ModalButtonProps } from 'components/modal/modal.interfaces';
import Modal from 'components/modal/modalComponent';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import { createModal } from 'layout/createModal';
import { genericActionsIds } from 'utilities/constants';

export const DoseSpotNotificationsModal = createModal<undefined, void>(
  'dosespot-notifications-modal',
  ({ isOpen, close }) => {
    const { data: genericAction, loading: genericActionLoading } =
      useGetGenericActions({
        locale: 'en',
        genericActionId: [genericActionsIds.CLOSE],
      });

    const Buttons: ModalButtonProps[] = [
      {
        label: genericAction?.[genericActionsIds.CLOSE].close,
        disabled: false,
        onClick: close,
      },
    ];

    if (genericActionLoading) {
      return null;
    }

    return (
      <Modal
        isOpen={isOpen}
        title={'DoseSpot'}
        modalDesktopWidth="desktop:max-w-[1140px]"
        contentPaddingX="px-2.5"
        onClose={close}
        buttons={Buttons}
      >
        <div className="flex flex-col gap-[30px]">
          <div className="flex flex-col py-2.5 bg-white items-center justify-center w-full min-h-[790px] h-[70vh]">
            <DosespotIFrame showNotifications={true} />
          </div>
        </div>
      </Modal>
    );
  },
);
