import { useCallback } from 'react';
import { useGetUserEmailPreferencesQuery } from 'graphql/generated/hasura';
import {
  EmailPreferencesState,
  EmailPreferencesProps,
  EmailPreferencesKeys,
} from '../interfaces/emailPreferencesInterfaces';
import { ToggleComponent } from './ToggleComponent';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'auth/context/AuthContext';

import InputComponent from 'components/inputComponent';
import { AccountSettingsState } from '../interfaces/providerModuleInterfaces';

export const EmailPreferences: React.FC<EmailPreferencesProps> = ({
  locale,
  setAccountSettings,
  setIsAllSectionsLoaded,
}) => {
  const [emailPreferencesStatus, setEmailPreferencesStatus] =
    useState<EmailPreferencesState | null>(null);

  const { user: loggedUser } = useContext(AuthContext);

  useGetUserEmailPreferencesQuery({
    variables: {
      id: loggedUser?.uuid,
    },
    onCompleted: (data) => {
      const {
        EMAIL_MONTHLY_NEWSLETTER,
        EMAIL_UNSUBSCRIBED,
        EMAIL_SPECIAL_OFFERS_SETTING,
        EMAIL_SPECIAL_OFFER_FRIENDS_SETTING,
        EMAIL_NEW_FEATURES_SERVICES_SETTINGS,
      } = data.users[0];
      setEmailPreferencesStatus({
        EMAIL_PREFERENCES_ENABLED:
          EMAIL_MONTHLY_NEWSLETTER ||
          EMAIL_SPECIAL_OFFERS_SETTING ||
          EMAIL_SPECIAL_OFFER_FRIENDS_SETTING ||
          EMAIL_NEW_FEATURES_SERVICES_SETTINGS ||
          false,
        EMAIL_MONTHLY_NEWSLETTER,
        EMAIL_NEW_FEATURES_SERVICES_SETTINGS,
        EMAIL_SPECIAL_OFFER_FRIENDS_SETTING,
        EMAIL_SPECIAL_OFFERS_SETTING,
        EMAIL_UNSUBSCRIBED,
      });
      setIsAllSectionsLoaded((prevState) => ({
        ...prevState,
        emailPreferences: true,
      }));
    },
  });

  const toggleHandler = useCallback(
    (key: EmailPreferencesKeys, state: boolean) => {
      setEmailPreferencesStatus({
        ...emailPreferencesStatus,
        [EmailPreferencesKeys[key]]: state,
      } as EmailPreferencesState);
      setHasCheckboxEffectRun(false);
    },
    [emailPreferencesStatus],
  );

  const toggleEnabled = (state: boolean) => {
    setEmailPreferencesStatus({
      EMAIL_PREFERENCES_ENABLED: state,
      EMAIL_MONTHLY_NEWSLETTER: state,
      EMAIL_NEW_FEATURES_SERVICES_SETTINGS: state,
      EMAIL_SPECIAL_OFFER_FRIENDS_SETTING: state,
      EMAIL_SPECIAL_OFFERS_SETTING: state,
    } as EmailPreferencesState);
  };

  const handleToggleCheckbox = () => {
    setEmailPreferencesStatus({
      ...emailPreferencesStatus,
      EMAIL_UNSUBSCRIBED:
        emailPreferencesStatus && !emailPreferencesStatus.EMAIL_UNSUBSCRIBED,
    } as EmailPreferencesState);
  };

  useEffect(() => {
    if (emailPreferencesStatus?.EMAIL_UNSUBSCRIBED === true) {
      setEmailPreferencesStatus((prevState) => {
        return {
          EMAIL_PREFERENCES_ENABLED: false,
          EMAIL_MONTHLY_NEWSLETTER: false,
          EMAIL_NEW_FEATURES_SERVICES_SETTINGS: false,
          EMAIL_SPECIAL_OFFER_FRIENDS_SETTING: false,
          EMAIL_SPECIAL_OFFERS_SETTING: false,
          EMAIL_UNSUBSCRIBED:
            (prevState && prevState.EMAIL_UNSUBSCRIBED) ?? false,
        };
      });
    }
  }, [emailPreferencesStatus?.EMAIL_UNSUBSCRIBED]);

  const [hasCheckboxEffectRun, setHasCheckboxEffectRun] = useState(false);

  useEffect(() => {
    const {
      EMAIL_MONTHLY_NEWSLETTER,
      EMAIL_NEW_FEATURES_SERVICES_SETTINGS,
      EMAIL_SPECIAL_OFFERS_SETTING,
      EMAIL_SPECIAL_OFFER_FRIENDS_SETTING,
      EMAIL_PREFERENCES_ENABLED,
    } = emailPreferencesStatus || {};
    if (
      !EMAIL_MONTHLY_NEWSLETTER &&
      !EMAIL_NEW_FEATURES_SERVICES_SETTINGS &&
      !EMAIL_SPECIAL_OFFERS_SETTING &&
      !EMAIL_SPECIAL_OFFER_FRIENDS_SETTING &&
      EMAIL_PREFERENCES_ENABLED
    ) {
      toggleHandler(EmailPreferencesKeys.EMAIL_PREFERENCES_ENABLED, false);
    }
  }, [emailPreferencesStatus, toggleHandler]);

  useEffect(() => {
    if (!hasCheckboxEffectRun) {
      setEmailPreferencesStatus((prevState) => {
        return {
          ...prevState,
          EMAIL_UNSUBSCRIBED: false,
        } as EmailPreferencesState;
      });
      setHasCheckboxEffectRun(true);
    }
  }, [emailPreferencesStatus, hasCheckboxEffectRun]);

  useEffect(() => {
    setAccountSettings((prevState) => {
      return {
        ...prevState,
        ...emailPreferencesStatus,
      } as AccountSettingsState;
    });
  }, [emailPreferencesStatus, setAccountSettings]);

  return (
    <div>
      <div className="mb-[30px]">
        <p className="text-title text-charcoal-gray font-bold uppercase">
          {locale.sectionTitle}
        </p>
        <p className="text-sm font-semibold text-med-gray">
          {locale.sectionSubtitle}
        </p>
        {emailPreferencesStatus !== null && (
          <>
            <ToggleComponent
              title={'Enable email preferences'}
              onChangeHandler={toggleEnabled}
              checkedState={emailPreferencesStatus.EMAIL_PREFERENCES_ENABLED}
              locale={locale}
            />
            <ToggleComponent
              title={locale.toggleTitle1}
              subtitle={locale.toggleSubtitle1}
              onChangeHandler={(state) =>
                toggleHandler(
                  EmailPreferencesKeys.EMAIL_SPECIAL_OFFERS_SETTING,
                  state,
                )
              }
              checkedState={emailPreferencesStatus.EMAIL_SPECIAL_OFFERS_SETTING}
              disabled={!emailPreferencesStatus.EMAIL_PREFERENCES_ENABLED}
              locale={locale}
            />
            <ToggleComponent
              title={locale.toggleTitle2}
              subtitle={locale.toggleSubtitle2}
              onChangeHandler={(state) =>
                toggleHandler(
                  EmailPreferencesKeys.EMAIL_SPECIAL_OFFER_FRIENDS_SETTING,
                  state,
                )
              }
              checkedState={
                emailPreferencesStatus.EMAIL_SPECIAL_OFFER_FRIENDS_SETTING
              }
              disabled={!emailPreferencesStatus.EMAIL_PREFERENCES_ENABLED}
              locale={locale}
            />
            <ToggleComponent
              title={locale.toggleTitle3}
              subtitle={locale.toggleSubtitle3}
              onChangeHandler={(state) =>
                toggleHandler(
                  EmailPreferencesKeys.EMAIL_NEW_FEATURES_SERVICES_SETTINGS,
                  state,
                )
              }
              checkedState={
                emailPreferencesStatus.EMAIL_NEW_FEATURES_SERVICES_SETTINGS
              }
              disabled={!emailPreferencesStatus.EMAIL_PREFERENCES_ENABLED}
              locale={locale}
            />
            <ToggleComponent
              title={locale.toggleTitle4}
              subtitle={locale.toggleSubtitle4}
              onChangeHandler={(state) =>
                toggleHandler(
                  EmailPreferencesKeys.EMAIL_MONTHLY_NEWSLETTER,
                  state,
                )
              }
              checkedState={emailPreferencesStatus.EMAIL_MONTHLY_NEWSLETTER}
              disabled={!emailPreferencesStatus.EMAIL_PREFERENCES_ENABLED}
              locale={locale}
            />
            <div className="w-full flex flex-col justify-between max-w-[690px] mx-auto my-[30px]">
              <p className="text-base font-semibold text-charcoal-gray mb-[5px]">
                {locale.checkboxTitle}
              </p>
              <InputComponent
                type="checkbox"
                name="email-preferences"
                checkboxProps={{
                  isAlternativeCheckboxColor: true,
                  checkboxLabel: locale.checkboxLabel,
                  checkboxLabelSize: 'text-sm',
                  checked: emailPreferencesStatus.EMAIL_UNSUBSCRIBED,
                  onCheckboxChange: handleToggleCheckbox,
                }}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
