import { ModalButtonProps } from 'components/modal/modal.interfaces';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import { componentIds, genericActionsIds, pageIds } from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useMemo, useContext } from 'react';
import { PatientOutlineIcon } from 'assets/icons/PatientOutlineIcon';
import { PreworkOutlineIcon } from 'assets/icons/PreworkOutlineIcon';
import { PreworkSolidIcon } from 'assets/icons/PreworkSolidIcon';
import { ChatSolidIcon } from 'assets/icons/ChatSolidIcon';
import { ReactComponent as ChatFilled } from 'assets/icons/filledChat.svg';
import { ChatOutlineIcon } from 'assets/icons/ChatOutlineIcon';
import { PatientSolidIcon } from 'assets/icons/PatientSolidIcon';
import {
  ChatMessage,
  Tabs,
  Medication,
  FREQUENCY_ENUM,
} from 'app/my-appointments/components/interfaces';
import { ChatComponent } from './ChatComponent';
import { PreworkComponent } from './PreworkComponent';
import { PatientComponent } from './PatientComponent';
import Modal from 'components/modal/modalComponent';
import CollapseTab from './CollapseTab';
import CompleteMyVisitSummaryModalMedicationTable from './CompleteMyVisitSummaryModalMedicationTable';
import {
  GetFhirMedicationRequestListQuery,
  GetProviderPatientInfoQuery,
  useGetAppointmentByCodexAppointmentIdQuery,
  useGetAppointmentMessageListByAppointmentCodexIdQuery,
  useGetFhirMedicationRequestListLazyQuery,
  useGetProviderFullPatientInfoLazyQuery,
} from 'graphql/generated/remote-schema-hasura';

import { formatDateToCustomFormat } from 'utilities/functions';
import { useGetComponent } from 'hooks/useGetComponent';
import useParseChatMessages from 'hooks/useParseChatMessages';
import { AppointmentChatMessage } from 'graphql/generated/hasura';
import Loader from 'components/loaderComponent';
import { AuthContext } from '../../../auth/context/AuthContext';

export type SummaryFormData = {
  appointmentNotes: string;
  summaryDiagnosis: string;
  followUpRecommendations: string;
};

const MyVisitSummaryModal = () => {
  const { data: localeHook, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.COMPLETE_MY_VISIT_SUMMARY_MODAL,
  });
  const { data: summaryLocale, loading: loadingSummary } = useGetComponent({
    locale: 'en',
    componentId: componentIds.SUMMARY_MODAL,
  });

  const { user: loggedUser } = useContext(AuthContext);

  const locale = useMemo(
    () => ({ ...localeHook, ...summaryLocale }),
    [localeHook, summaryLocale],
  );

  const [active, setActive] = useState(-1);
  const [patientId, setPatientId] = useState<string | null>();
  const [patientInfo, setPatientInfo] =
    useState<GetProviderPatientInfoQuery | null>();
  const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);
  const [medications, setMedications] =
    useState<GetFhirMedicationRequestListQuery | null>();
  const location = useLocation();
  const appointmentId = location.state?.appointmentId;
  const navigate = useNavigate();

  const [parseMessages, chatMediaLoading] = useParseChatMessages(appointmentId);

  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.CLOSE],
    });

  const [getProviderPatientData, { loading: providerPatientLoading }] =
    useGetProviderFullPatientInfoLazyQuery({
      onCompleted: (data) => {
        setPatientInfo(data);
      },
    });

  const [getFHIRMedicationsByPatientCodexId] =
    useGetFhirMedicationRequestListLazyQuery({
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setMedications(data);
      },
    });

  const { loading: getAppointmentLoading, data: appointmentData } =
    useGetAppointmentByCodexAppointmentIdQuery({
      variables: {
        appointmentCodexId: appointmentId,
      },
      fetchPolicy: 'network-only',
      async onCompleted(data) {
        if (data.getFHIRAppointmentByCodexId.appointment?.codexPatientId) {
          setPatientId(
            data.getFHIRAppointmentByCodexId.appointment?.codexPatientId,
          );
          getProviderPatientData({
            variables: {
              patientCodexId:
                data.getFHIRAppointmentByCodexId.appointment.codexPatientId,
            },
          });
          const encounterId =
            data.getFHIRAppointmentByCodexId.appointment?.encounterId;
          getFHIRMedicationsByPatientCodexId({
            variables: {
              patientCodexId:
                data.getFHIRAppointmentByCodexId.appointment.codexPatientId,
              encounterId,
            },
          });
        }
      },
    });

  const { loading: chatDataLoading } =
    useGetAppointmentMessageListByAppointmentCodexIdQuery({
      variables: { appointmentCodexId: appointmentId },
      onCompleted: async (data) => {
        if (
          data.getAppointmentMessageListByAppointmentCodexId.chat &&
          data.getAppointmentMessageListByAppointmentCodexId.chat.messages &&
          data.getAppointmentMessageListByAppointmentCodexId.chat.messages
            .length > 0
        ) {
          const messagesParsed = await parseMessages(
            data.getAppointmentMessageListByAppointmentCodexId.chat
              .messages as AppointmentChatMessage[],
          );
          setChatMessages(messagesParsed ?? []);
        }
      },
      onError: (error) => {
        console.log('An error happened', error);
      },
    });

  const handleClose = () => navigate(-1);

  const handleOnToggle = (tab: number) => {
    setActive((prev) => (prev === tab ? -1 : tab));
  };

  if (
    loading ||
    loadingSummary ||
    !locale ||
    genericActionLoading ||
    !genericAction ||
    getAppointmentLoading ||
    !appointmentData ||
    providerPatientLoading
  ) {
    return null;
  }
  const Buttons: ModalButtonProps[] = [
    {
      label: genericAction?.[genericActionsIds.CLOSE].close,
      onClick: handleClose,
    },
  ];

  const chatLoading = chatDataLoading || chatMediaLoading;

  return (
    <Modal
      isOpen={true}
      title={locale.title}
      modalDesktopWidth="desktop:max-w-[1300px]"
      disableMobileGrow={true}
      buttons={Buttons}
      onClose={handleClose}
    >
      <div className="flex flex-col gap-5 desktop:gap-[30px] desktop:flex-row">
        <div className="flex flex-col">
          <div className="flex flex-col w-full desktop:w-[360px] bg-white rounded-10 overflow-hidden">
            {patientInfo && (
              <CollapseTab
                active={active === Tabs.Patient}
                activeIcon={PatientSolidIcon}
                icon={PatientOutlineIcon}
                label={locale.patientTab}
                onClick={() => handleOnToggle(Tabs.Patient)}
              >
                <PatientComponent
                  patient={patientInfo}
                  locale={locale}
                  patientId={patientId}
                />
              </CollapseTab>
            )}

            {appointmentData && (
              <CollapseTab
                active={active === Tabs.Prework}
                activeIcon={PreworkSolidIcon}
                icon={PreworkOutlineIcon}
                label={locale.preworkTab}
                onClick={() => handleOnToggle(Tabs.Prework)}
              >
                <PreworkComponent
                  prework={appointmentData}
                  symptomsLocation={
                    appointmentData?.getFHIRAppointmentByCodexId.appointment
                      ?.symptomsLocation
                  }
                  locale={locale}
                />
              </CollapseTab>
            )}

            {chatMessages && (
              <CollapseTab
                active={active === Tabs.Chat}
                activeIcon={ChatSolidIcon}
                borderBottom={active === Tabs.Chat}
                icon={ChatOutlineIcon}
                label={locale.chatTab}
                onClick={() => handleOnToggle(Tabs.Chat)}
              >
                {chatLoading ? (
                  <Loader />
                ) : (
                  <ChatComponent
                    loggedUser={loggedUser}
                    locale={locale.chatMessage}
                    messages={chatMessages}
                  />
                )}
              </CollapseTab>
            )}
            {appointmentData && (
              <CollapseTab
                active={active === Tabs.MyNotes}
                activeIcon={ChatFilled}
                borderBottom={active === Tabs.MyNotes}
                contentBorderBottom={false}
                icon={ChatFilled}
                label={locale.myNotesTab}
                onClick={() => handleOnToggle(Tabs.MyNotes)}
              >
                <p className="text-base text-med-gray font-normal">
                  {
                    appointmentData.getFHIRAppointmentByCodexId.appointment
                      ?.encounterNotes?.providerNotes
                  }
                </p>
              </CollapseTab>
            )}
          </div>
        </div>
        <div className="flex flex-col py-2.5 gap-[30px] desktop:flex-1 grow bg-white rounded-10">
          <div className="flex flex-row border-b-2 py-2.5 px-5 items-center gap-3">
            <div className="w-6 h-6 flex justify-center items-center">
              <ChatFilled className="fill-med-gray" />
            </div>
            <p className="font-exo font-semibold text-lg text-charcoal-gray grow">
              {locale.summaryAndAnalysis}
            </p>
            <p className="font-bold text-base text-charcoal-gray">
              {locale.summaryDate}{' '}
              <span className="font-normal text-med-gray">
                {formatDateToCustomFormat(
                  appointmentData.getFHIRAppointmentByCodexId.appointment
                    ?.start ?? '',
                )}
              </span>
            </p>
          </div>
          <div className="flex flex-col px-5 items-start gap-1">
            <p className="font-bold text-sm text-charcoal-gray grow">
              {locale.providerSummary}
            </p>
            <p className="font-normal text-base text-med-gray">
              {
                appointmentData.getFHIRAppointmentByCodexId.appointment
                  ?.encounterNotes?.providerSummary
              }
            </p>
          </div>
          <div className="flex flex-col items-start gap-1 px-5">
            <p className="font-bold text-sm border-b-2 text-charcoal-gray grow w-full">
              {locale.medications}
            </p>
            <CompleteMyVisitSummaryModalMedicationTable
              locale={locale}
              medications={
                (medications?.getFHIRMedicationRequestByPatientCodexId.medication_requests.map(
                  (medication, index) => ({
                    id: index,
                    name: medication.displayName,
                    type: medication.type ?? '',
                    brand: medication.brandName || '',
                    delivery: medication.delivery ?? '',
                    dosage: {
                      quantity: medication.dosage ?? '',
                    },
                    frequency: {
                      quantity: medication.daysSupply?.toString() ?? '',
                      unit: FREQUENCY_ENUM.DAILY,
                    },
                  }),
                ) as unknown as Medication[]) ?? []
              }
            />
          </div>
          <div className="flex flex-col px-5 items-start gap-1">
            <p className="font-bold text-sm text-charcoal-gray grow">
              {locale.followupRecommendations}
            </p>
            <p className="font-normal text-base text-med-gray">
              {
                appointmentData.getFHIRAppointmentByCodexId.appointment
                  ?.encounterNotes?.followupRecommendations
              }
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MyVisitSummaryModal;
