import ButtonComponent from 'components/button/buttonComponent';
import {
  PersonaStatus,
  ProviderPersonaSectionWidgetProps,
} from 'app/my-account/pages/my-profile/components/personaComponents/interfaces';
import { useNavigate } from 'react-router-dom';
import IconComponent from 'components/iconComponent';
import { ReactComponent as InfoIcon } from 'assets/icons/weatherIcons/question.svg';
import Tooltip from 'components/Tooltip';
import { MY_PROFILE_VALIDATE_PERSONA } from 'utilities/routes';
import { ReactComponent as UnverifiedBadge } from 'assets/icons/verification-1.svg';
import { ReactComponent as VerifiedBadge } from 'assets/icons/verification-2.svg';
import { ReactComponent as InReviewBadge } from 'assets/icons/verification-3.svg';
import { ReactComponent as DeclinedBadge } from 'assets/icons/verification-4.svg';
import { ReactComponent as LockedBadge } from 'assets/icons/verification-5-filled.svg';
import { ReactComponent as StartedBadge } from 'assets/icons/verification-6.svg';
import { Persona_Inquiry_Status_Enum } from 'graphql/generated/hasura';
import Alert from 'components/alertComponent';
import { useCallback } from 'react';

export const ProviderPersonaSection: React.FC<
  ProviderPersonaSectionWidgetProps
> = ({ locale, userVerificationStatus }) => {
  const navigate = useNavigate();

  const userVerificationStatusFactory = useCallback(
    (status: PersonaStatus) => {
      switch (status) {
        case null:
        case undefined:
        case Persona_Inquiry_Status_Enum.Expired:
          return (
            <>
              <span className="flex gap-1 items-center">
                <UnverifiedBadge />
                <p className="font-semibold text-med-gray">
                  {locale?.notVerified}
                  <a
                    className="ml-1 underline text-clc-blue font-bold"
                    href={MY_PROFILE_VALIDATE_PERSONA}
                  >
                    {locale?.verifyMyIdentity}
                  </a>
                </p>
              </span>
              <div className="flex desktop:self-end mobile:self-center desktop:mt-4">
                <ButtonComponent
                  onClick={() => navigate(MY_PROFILE_VALIDATE_PERSONA)}
                >
                  {locale?.verifyMyIdentity}
                </ButtonComponent>
              </div>
            </>
          );
        case Persona_Inquiry_Status_Enum.Failed:
          return (
            <>
              <span className="flex gap-1 items-center">
                <UnverifiedBadge />
                <p className="font-semibold text-med-gray">
                  {locale?.notVerified}
                  <a
                    className="ml-1 underline text-clc-blue font-bold"
                    href={MY_PROFILE_VALIDATE_PERSONA}
                  >
                    {locale?.verifyMyIdentity}
                  </a>
                </p>
              </span>
              <div className="flex desktop:self-end mobile:self-center desktop:mt-4">
                <ButtonComponent
                  onClick={() => navigate(MY_PROFILE_VALIDATE_PERSONA)}
                >
                  {locale?.verifyMyIdentity}
                </ButtonComponent>
              </div>
            </>
          );
        case Persona_Inquiry_Status_Enum.Started:
        case Persona_Inquiry_Status_Enum.Created:
          return (
            <>
              <span className="flex gap-1 items-center">
                <StartedBadge />
                <p className="font-semibold text-med-gray">
                  {locale?.verificationInProgress}
                  <a
                    className="ml-1 underline text-clc-blue font-bold"
                    href={MY_PROFILE_VALIDATE_PERSONA}
                  >
                    {locale?.completeMyIDVerification}
                  </a>
                </p>
              </span>
              <Alert
                type="info"
                desktopItemsAlign="desktop:items-start"
                text={locale?.completeMyIDVerificationAlert}
              />
              <div className="flex desktop:self-end mobile:self-center desktop:mt-4">
                <ButtonComponent
                  onClick={() => navigate(MY_PROFILE_VALIDATE_PERSONA)}
                >
                  {locale?.completeMyIDVerification}
                </ButtonComponent>
              </div>
            </>
          );
        case Persona_Inquiry_Status_Enum.Approved:
        case Persona_Inquiry_Status_Enum.Completed:
          return (
            <>
              <span className="flex gap-1 items-center">
                <VerifiedBadge />
                <p className="font-semibold text-med-gray">
                  {locale?.verified}
                </p>
              </span>
              <Alert
                type="positive"
                desktopItemsAlign="desktop:items-start"
                text={locale?.verifiedNotYetApprovedAlert}
              />
            </>
          );
        case Persona_Inquiry_Status_Enum.MarkedForReview:
          return (
            <>
              <span className="flex gap-1 items-center">
                <InReviewBadge />
                <p className="font-semibold text-med-gray">
                  {locale?.verificationInReview}
                </p>
              </span>
              <Alert
                type="info"
                desktopItemsAlign="desktop:items-start"
                text={locale?.verificationInReviewAlert}
              />
            </>
          );
        case Persona_Inquiry_Status_Enum.Declined:
          return (
            <>
              <span className="flex gap-1 items-center">
                <DeclinedBadge />
                <p className="font-semibold text-med-gray">
                  {locale?.verificationDeclined}
                  <a
                    className="ml-1 underline text-clc-blue font-bold"
                    href={MY_PROFILE_VALIDATE_PERSONA}
                  >
                    {locale?.tryAgain}
                  </a>
                </p>
              </span>
              <Alert
                type="warning"
                desktopItemsAlign="desktop:items-start"
                text={locale?.verificationDeclinedAlert}
              />
              <div className="flex desktop:self-end mobile:self-center desktop:mt-4">
                <ButtonComponent
                  onClick={() => navigate(MY_PROFILE_VALIDATE_PERSONA)}
                >
                  {locale?.verifyMyIdentity}
                </ButtonComponent>
              </div>
            </>
          );
        case Persona_Inquiry_Status_Enum.Locked:
          return (
            <>
              <>
                <span className="flex gap-1 items-center">
                  <LockedBadge />
                  <p className="font-semibold text-med-gray">
                    {locale?.verificationLocked}
                  </p>
                  <Tooltip
                    containerMaxHeight="h-[80px]"
                    textContainerTop="top-[-87px]"
                    textContainerCustomClasses="min-h-[78px]"
                    text={locale?.verificationLocked}
                  >
                    <span className="cursor-pointer">
                      <IconComponent
                        width="w-5"
                        height="h-5"
                        icon={InfoIcon}
                        fill="fill-clc-blue"
                      />
                    </span>
                  </Tooltip>
                </span>
                <Alert
                  type="warning"
                  desktopItemsAlign="desktop:items-start"
                  customContent={
                    <p className="text-clc-red font-semibold">
                      {locale?.verificationLockedAlert}
                      <a
                        className="ml-1 underline text-clc-blue font-bold"
                        // TODO COD-2379
                        href="mailto:placeholder@gmail.com"
                      >
                        {`${locale?.contactUs}.`}
                      </a>
                    </p>
                  }
                />
                <div className="flex desktop:self-end mobile:self-center desktop:mt-4">
                  <ButtonComponent
                    onClick={() => navigate(MY_PROFILE_VALIDATE_PERSONA)}
                  >
                    {locale?.contactUs}
                  </ButtonComponent>
                </div>
              </>
            </>
          );
        default:
          return null;
      }
    },
    [
      locale?.completeMyIDVerification,
      locale?.completeMyIDVerificationAlert,
      locale?.contactUs,
      locale?.notVerified,
      locale?.tryAgain,
      locale?.verificationDeclined,
      locale?.verificationDeclinedAlert,
      locale?.verificationInProgress,
      locale?.verificationInReview,
      locale?.verificationInReviewAlert,
      locale?.verificationLocked,
      locale?.verificationLockedAlert,
      locale?.verified,
      locale?.verifiedNotYetApprovedAlert,
      locale.verifyMyIdentity,
      navigate,
    ],
  );

  return <>{userVerificationStatusFactory(userVerificationStatus)}</>;
};
