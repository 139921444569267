import React from 'react';

interface SpinnerProps {
  height?: string;
  width?: string;
  spinningInnerColor?: string;
  borderOuterColor?: string;
  borderSize?: string;
  centered?: boolean;
}

const Spinner: React.FC<SpinnerProps> = ({
  height = 'h-6',
  width = 'w-6',
  spinningInnerColor = 'border-t-gray-100',
  borderOuterColor = 'border-gray-400',
  borderSize = 'border-4',
  centered = 'ml-auto mr-auto',
}) => {
  const spinnerClasses = `${borderOuterColor} ${height} ${width} ${centered} animate-spin rounded-full ${borderSize} ${spinningInnerColor}`;

  return <div className={spinnerClasses} />;
};

export default Spinner;
