import { Navigate, Route, Routes } from 'react-router-dom';
import { BASE_ROUTE, BASE_ROUTES, UNLINK } from 'utilities/routes';
import FullscriptLink from 'app/fullscript/FullscriptLink';
import { FullscriptUnlink } from 'app/fullscript/FullscriptUnlink';

export const FullscriptRoutes = () => {
  return (
    <Routes>
      <Route path={UNLINK} element={<FullscriptUnlink />} />
      <Route path={BASE_ROUTE} element={<FullscriptLink />} />
      <Route path={BASE_ROUTES} element={<Navigate to={BASE_ROUTE} />} />
    </Routes>
  );
};
