import { useEffect, useMemo, useState } from 'react';
import {
  useGetLegalDocumentByIdQuery,
  GetLegalDocumentByIdQueryVariables,
  LegalDocument,
} from '../../graphql/generated/strapi';
import { useStrapi } from 'apollo/LocalesProvider';

interface UseGetLegalDocument {
  legalDocument?: LegalDocument | null;
  loading: boolean;
  error?: Error;
}

export const useGetLegalDocument = (
  variables: GetLegalDocumentByIdQueryVariables,
): UseGetLegalDocument => {
  const [legalDocument, setLegalDocument] = useState<LegalDocument | null>();
  const { client } = useStrapi();

  const { data, loading, error } = useGetLegalDocumentByIdQuery({
    variables,
    client: client,
    fetchPolicy: 'cache-first',
  });

  useEffect(() => {
    if (data && data.legalDocuments) {
      const legalDocument = data.legalDocuments.data;

      if (legalDocument.length) {
        setLegalDocument({
          legalDocumentId: legalDocument[0].attributes?.legalDocumentId,
          legalDocumentName: legalDocument[0].attributes?.legalDocumentName,
          legalDocumentType: legalDocument[0].attributes?.legalDocumentType,
          contentWeb: legalDocument[0].attributes?.contentWeb,
          contentMobile: legalDocument[0].attributes?.contentMobile,
        } as LegalDocument);
      } else {
        setLegalDocument(null);
      }
    }
  }, [data]);

  const pagesMemoized = useMemo(
    () => ({ legalDocument, loading, error }),
    [legalDocument, loading, error],
  );

  return pagesMemoized;
};
