interface PreworkSymptomsLocationProps {
  title: string;
  symptoms: string[];
}

export const PreworkSymptomsLocationSection: React.FC<
  PreworkSymptomsLocationProps
> = ({ title, symptoms }) => {
  return (
    <div className="flex flex-col gap-1">
      <p className="text-h7 font-bold uppercase">{title}</p>
      <ul className="text-med-gray list-disc ml-6">
        {symptoms.map((location, index) => (
          <li key={index.toString()}>{location}</li>
        ))}
      </ul>
    </div>
  );
};
