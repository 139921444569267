import { useEffect, useState, useCallback, useRef } from 'react';

type CameraFacingMode = 'environment' | 'user';
type CameraErrorTypes = 'initilization' | 'capturing' | 'fatal';
export enum CameraExactFacingMode {
  EXACT = 'exact',
  NON_EXACT = 'non-exact',
}
interface VideoDimensions {
  w: number;
  h: number;
}

// For testing, please enable on .env.local HTTPS=true,
// otherwise permissions and camera won't work properly.

export const useCamera = (
  useExactFacingMode: CameraExactFacingMode = CameraExactFacingMode.EXACT,
) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const streamRef = useRef<MediaStream | null>(null);
  const [videoDem, setVideoDem] = useState<VideoDimensions>({ w: 0, h: 0 });
  const [cameraFacingMode, setCameraFacingMode] =
    useState<CameraFacingMode>('user');
  const [imageData, setImageData] = useState<string>('');
  const [error, setError] = useState<CameraErrorTypes | null>(null);
  const [flashOn, setFlashOn] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [retriesCounter, setRetries] = useState<number>(0);
  const currentVideoDeviceIndex = useRef<number | null>(null);

  const getUserMedia = useCallback(
    async (deviceIdStr?: string): Promise<MediaStream> => {
      try {
        const deviceId = deviceIdStr ? { deviceId: deviceIdStr } : {};

        const constraint: MediaStreamConstraints = {
          video: {
            width: { min: 500 },
            height: { min: 500 },
            ...(useExactFacingMode === CameraExactFacingMode.EXACT
              ? { facingMode: { exact: cameraFacingMode } }
              : { facingMode: cameraFacingMode }),
            ...deviceId,
          },
          audio: false,
        };
        const stream = await navigator.mediaDevices.getUserMedia(constraint);
        currentVideoDeviceIndex.current = null;
        return stream;
      } catch (error) {
        let videoDevices = (
          await navigator.mediaDevices.enumerateDevices()
        ).filter((device) => device.kind === 'videoinput');
        if (cameraFacingMode === 'environment') {
          // In my testing, the back camera is always the last one in the list (s23 ultra and a52)
          // Reverse the order of the video devices so that the back camera is the first one
          videoDevices = videoDevices.reverse();
        }
        if (
          currentVideoDeviceIndex.current !== null &&
          (currentVideoDeviceIndex.current < 0 ||
            currentVideoDeviceIndex.current >= videoDevices.length)
        ) {
          throw error;
        }
        const nextVideoDeviceIndex =
          currentVideoDeviceIndex.current === null
            ? 0
            : currentVideoDeviceIndex.current + 1;
        currentVideoDeviceIndex.current = nextVideoDeviceIndex;
        return getUserMedia(videoDevices[nextVideoDeviceIndex].deviceId);
      }
    },
    [cameraFacingMode, useExactFacingMode],
  );

  const initializeCamera = useCallback(async () => {
    try {
      setIsLoading(true);

      if (streamRef.current) {
        stopCamera();
      }

      const stream = await getUserMedia();
      streamRef.current = stream;

      if (videoRef.current && canvasRef.current) {
        const video = videoRef.current;
        const canvas = canvasRef.current;
        video.setAttribute('playsinline', 'true');
        video.srcObject = stream;
        video.onloadedmetadata = () => {
          const { clientLeft, clientTop, videoWidth, videoHeight } = video;
          setVideoDem({ w: videoWidth, h: videoHeight });
          video.style.transform =
            cameraFacingMode === 'user' ? 'scaleX(-1)' : 'none';
          canvas.style.position = 'absolute';
          canvas.style.left = clientLeft.toString();
          canvas.style.top = clientTop.toString();
          canvas.setAttribute('width', videoWidth.toString());
          canvas.setAttribute('height', videoHeight.toString());
          video.play();
          setIsLoading(false);
          setError(null);
        };
      }
    } catch (error: unknown) {
      setIsLoading(false);
      setError('initilization');
      console.error(`Error initializing the camera.: ${error}`);
    }
  }, [
    cameraFacingMode,
    setVideoDem,
    setError,
    streamRef,
    videoRef,
    canvasRef,
    getUserMedia,
  ]);

  useEffect(() => {
    initializeCamera();
  }, [initializeCamera]);

  useEffect(() => {
    const retryInitializeCamera = async () => {
      if (error) {
        if (retriesCounter < 3) {
          await initializeCamera();
          setRetries((prev) => prev + 1);
        } else {
          setError('fatal');
        }
      } else {
        setRetries(0);
      }
    };

    retryInitializeCamera();
  }, [initializeCamera, error, retriesCounter]);

  useEffect(() => {
    if (streamRef.current) {
      const [track] = streamRef.current.getVideoTracks();
      track.applyConstraints({ torch: flashOn } as MediaTrackConstraints);
    }
  }, [flashOn]);

  const switchCameraFacingMode = () => {
    setCameraFacingMode((prevMode) =>
      prevMode === 'environment' ? 'user' : 'environment',
    );
  };

  const isTorchSupported = () => {
    return (
      streamRef.current &&
      streamRef.current
        .getVideoTracks()
        .some((track) => 'torch' in track.getCapabilities())
    );
  };

  const supportsFacingMode = () => {
    const track = streamRef.current?.getVideoTracks()[0];

    if (track && 'getCapabilities' in track) {
      const capabilities = track.getCapabilities();
      return 'facingMode' in capabilities;
    }

    return false;
  };

  const toggleFlash = () => {
    setFlashOn((flashOn) => !flashOn);
  };

  const captureImage = (): string => {
    try {
      if (videoRef.current && canvasRef.current) {
        const video = videoRef.current;
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');

        if (context) {
          context.drawImage(video, 0, 0, videoDem.w, videoDem.h);
          const imageData = context.getImageData(0, 0, videoDem.w, videoDem.h);
          const image =
            cameraFacingMode === 'user'
              ? reverseImageDataHorizontal(imageData)
              : imageData;
          context.putImageData(image, 0, 0);
          const data = canvas.toDataURL('image/jpeg', 1);
          setImageData(data);
          return data;
        }
      }
    } catch (error: unknown) {
      setError('capturing');
      console.error(`Error while capturing image: ${error}`);
    }
    return '';
  };

  const captureImageWithAspectRatio = (): string => {
    try {
      if (videoRef.current && canvasRef.current) {
        const video = videoRef.current;
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');

        if (context) {
          const { videoWidth, videoHeight } = video;
          const orientation = window.screen.orientation.type;

          // Adjust canvas dimensions based on orientation
          if (orientation.includes('landscape')) {
            canvas.width = videoWidth;
            canvas.height = videoHeight;
          } else {
            canvas.width = videoWidth;
            canvas.height = videoHeight;
          }

          // Draw the video frame on the canvas
          context.drawImage(video, 0, 0, videoWidth, videoHeight);

          // Reverse image data if the camera is user-facing
          const imageData = context.getImageData(
            0,
            0,
            canvas.width,
            canvas.height,
          );
          const image =
            cameraFacingMode === 'user'
              ? reverseImageDataHorizontal(imageData)
              : imageData;
          context.putImageData(image, 0, 0);

          // Get the image data and convert it to base64
          const data = canvas.toDataURL('image/jpeg', 1);
          setImageData(data);
          return data;
        }
      }
    } catch (error: unknown) {
      setError('capturing');
      console.error(`Error while capturing image: ${error}`);
    }
    return '';
  };

  const reverseImageDataHorizontal = (imageData: ImageData): ImageData => {
    const width = imageData.width;
    const height = imageData.height;
    const reversedData = new Uint8ClampedArray(width * height * 4);

    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
        const srcIndex = (y * width + (width - x - 1)) * 4;
        const destIndex = (y * width + x) * 4;

        reversedData[destIndex] = imageData.data[srcIndex];
        reversedData[destIndex + 1] = imageData.data[srcIndex + 1];
        reversedData[destIndex + 2] = imageData.data[srcIndex + 2];
        reversedData[destIndex + 3] = imageData.data[srcIndex + 3];
      }
    }

    return new ImageData(reversedData, width, height);
  };

  const stopCamera = () => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(function (track) {
        track.stop();
      });
      streamRef.current = null;
    }
    setVideoDem({ w: 0, h: 0 });
  };

  return {
    videoRef,
    canvasRef,
    cameraFacingMode,
    switchCameraFacingMode,
    captureImage,
    captureImageWithAspectRatio,
    toggleFlash,
    isTorchSupported,
    supportsFacingMode,
    initializeCamera,
    stopCamera,
    imageData,
    error,
    isLoading,
  };
};
