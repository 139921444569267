import { UserData } from 'auth/context/AuthContext';
import { Roles } from '../firebase/interfaces';
import {
  PharmacyInformationInput,
  useGetFhirPatientbyCodexIdQuery,
} from 'graphql/generated/remote-schema-hasura';
import { parsePhoneNumber } from 'libphonenumber-js';
import { useState } from 'react';
import { ProfileData } from 'app/my-account/interfaces/profile.interfaces';

const useProfileDataLoader = (loggedUser: UserData | null) => {
  const [profileData, setProfileData] = useState<ProfileData | null>(null);

  const getMfaParts = (mfaPhoneNumber?: string) => {
    try {
      const parsedPhoneNumber = parsePhoneNumber(mfaPhoneNumber || '');
      return {
        mfaPhone: parsedPhoneNumber.nationalNumber,
        mfaCountry: parsedPhoneNumber.countryCallingCode,
      };
    } catch (error) {
      console.error('Unable to parse phone number');
      return {
        mfaPhone: '',
        mfaCountry: '',
      };
    }
  };

  const { loading: fhirPatientDataLoading } = useGetFhirPatientbyCodexIdQuery({
    onCompleted(data) {
      if (
        loggedUser?.role === Roles.PATIENT &&
        data?.getFHIRPatientbyCodexID.users.length > 0
      ) {
        const user = data?.getFHIRPatientbyCodexID.users[0];
        setProfileData({
          firstname: user?.SENSITIVE_firstname || '',
          lastname: user?.SENSITIVE_lastname || '',
          email: user?.SENSITIVE_email || '',
          gender: user?.SENSITIVE_gender || '',
          phone: user?.SENSITIVE_phone || '',
          dob: user?.SENSITIVE_dob || '',
          selfIdentifyGender: user?.SENSITIVE_self_identity_gender || '',
          userMeasurements: {
            weight: user.SENSITIVE_user_measurements?.weight
              ? String(user?.SENSITIVE_user_measurements?.weight)
              : '',
            heightFt: user.SENSITIVE_user_measurements?.heightFt
              ? String(user?.SENSITIVE_user_measurements?.heightFt)
              : '',
            heightIn: user.SENSITIVE_user_measurements?.heightIn
              ? String(user?.SENSITIVE_user_measurements?.heightIn)
              : '',
            hips: user.SENSITIVE_user_measurements?.hips
              ? String(user?.SENSITIVE_user_measurements?.hips)
              : '',
            waist: user.SENSITIVE_user_measurements?.waist
              ? String(user?.SENSITIVE_user_measurements?.waist)
              : '',
          },
          etnicity: user?.SENSITIVE_etnicity || [],
          country: user?.SENSITIVE_country || '',
          addressInformation: {
            addressLine1: user?.SENSITIVE_address_information?.address1 || '',
            addressLine2: user?.SENSITIVE_address_information?.address2 || '',
            country: user?.SENSITIVE_address_information?.country || '',
            city: user.SENSITIVE_address_information?.city || '',
            state: user.SENSITIVE_address_information?.state || '',
            zipCode: user.SENSITIVE_address_information?.zipCode || '',
          },
          signInProvider: loggedUser?.signInProvider || null,
          pharmacyList: user.SENSITIVE_pharmacies as PharmacyInformationInput[],
          ...getMfaParts(loggedUser?.mfaPhoneNumber),
        });
      }
    },
  });

  return {
    fhirPatientDataLoading,
    profileData,
    setProfileData,
  };
};

export default useProfileDataLoader;
