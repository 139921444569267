import IconComponent from 'components/iconComponent';
import { ModalButtonProps } from 'components/modal/modal.interfaces';
import Modal from 'components/modal/modalComponent';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import { useNavigate } from 'react-router-dom';
import { genericActionsIds } from 'utilities/constants';
import { ReactComponent as InfoIcon } from 'assets/icons/weatherIcons/question.svg';
import { ValidateInformationModalProps } from './interfaces';

export const ValidateInformationModal: React.FC<
  ValidateInformationModalProps
> = ({ locale }) => {
  const navigate = useNavigate();
  const handleClose = () => navigate(-1);

  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.CLOSE],
    });

  const Buttons: ModalButtonProps[] = [
    {
      label: genericAction?.[genericActionsIds.CLOSE].close,
      onClick: handleClose,
    },
  ];

  if (genericActionLoading || !genericAction) {
    return null;
  }

  return (
    <Modal
      contentPaddingX="0"
      contentPaddingY="0"
      isOpen={true}
      disableMobileGrow={true}
      buttons={Buttons}
      onClose={handleClose}
    >
      <div className="px-10 bg-white flex items-center flex-col gap-[30px]">
        <IconComponent
          width="w-10"
          height="h-10"
          icon={InfoIcon}
          fill="fill-clc-blue"
        />
        <p className="text-base font-semibold text-med-gray">
          {locale.personaLocales.personaWidget.hoverText}
        </p>
      </div>
    </Modal>
  );
};
