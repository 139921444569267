import { ReactNode, useContext } from 'react';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import { AuthContext } from 'auth/context/AuthContext';
import {
  LOCAL_STORAGE_USER_SCANNED_TEST_KIT_ID,
  LOCAL_STORAGE_USER_SCANNED_TRACKING_NUMBER,
} from 'utilities/constants';
import {
  BASE_ROUTE,
  TEST_KITS_MANAGEMENT_REGISTER_TEST_KIT,
} from 'utilities/routes';
import { Buffer } from 'buffer';
import StorageService from 'utilities/storageService';

interface Props {
  children: ReactNode;
}

export const UnauthenticatedRoute = ({ children }: Props) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const encodedRedirect = searchParams.get('redirect');
  const testKitStorage = new StorageService(
    LOCAL_STORAGE_USER_SCANNED_TEST_KIT_ID,
  );
  const testKitReturnStorage = new StorageService(
    LOCAL_STORAGE_USER_SCANNED_TRACKING_NUMBER,
  );
  const redirect =
    encodedRedirect &&
    Buffer.from(decodeURIComponent(encodedRedirect), 'base64').toString(
      'utf-8',
    );

  const testKitNumber = testKitStorage.getData();
  const testKitReturnNumber = testKitReturnStorage.getData();

  const queryParams = new URLSearchParams(location.search);
  const customToken = queryParams.get('token') || '';

  const { isLoggedIn } = useContext(AuthContext);

  if (isLoggedIn && testKitNumber) {
    testKitStorage.clear();
    testKitReturnStorage.clear();
    return (
      <Navigate
        to={TEST_KITS_MANAGEMENT_REGISTER_TEST_KIT}
        state={{
          testKitNumber: testKitNumber,
          testKitReturnNumber: testKitReturnNumber || '',
        }}
      />
    );
  }

  return !isLoggedIn || customToken ? (
    <>{children}</>
  ) : (
    <Navigate to={redirect ? redirect : BASE_ROUTE} />
  );
};
