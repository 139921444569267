export enum ProfileSectionTypes {
  PROFILE = 'Personal info',
  CONTACT = 'Contact info',
  SECURITY_AND_PRIVACY = 'Security & privacy',
  HEALTH_QUESTIONNAIRE = 'Health Questionnaire',
}

export enum ProviderProfileSectionTypes {
  ACCOUNT_INFO = 'Account info',
  PERSONAL_INFO = 'Personal info',
}
