import ButtonComponent from 'components/button/buttonComponent';
import { PersonaSectionWidgetProps, PersonaStatus } from './interfaces';
import { Link, useNavigate } from 'react-router-dom';
import IconComponent from 'components/iconComponent';
import { ReactComponent as InfoIcon } from 'assets/icons/weatherIcons/question.svg';
import { useIsMobile } from 'hooks/useIsMobile';
import Tooltip from 'components/Tooltip';
import {
  MY_PROFILE_PATIENT_LOCKED_VERIFICATION,
  MY_PROFILE_PATIENT_VALIDATE_INFORMATION,
  MY_PROFILE_VALIDATE_PERSONA,
} from 'utilities/routes';
import { ReactComponent as UnverifiedBadge } from 'assets/icons/verification-1.svg';
import { ReactComponent as VerifiedBadge } from 'assets/icons/verification-2.svg';
import { ReactComponent as InReviewBadge } from 'assets/icons/verification-3.svg';
import { ReactComponent as DeclinedBadge } from 'assets/icons/verification-4.svg';
import { ReactComponent as LockedBadge } from 'assets/icons/verification-5.svg';
import { ReactComponent as StartedBadge } from 'assets/icons/verification-6.svg';
import { Persona_Inquiry_Status_Enum } from 'graphql/generated/hasura';

export const PersonaSection: React.FC<PersonaSectionWidgetProps> = ({
  locale,
  userVerificationStatus,
}) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const userVerificationStatusFactory = (status: PersonaStatus) => {
    switch (status) {
      case Persona_Inquiry_Status_Enum.Failed:
      case Persona_Inquiry_Status_Enum.Expired:
      case null:
      case undefined:
        return (
          <>
            <UnverifiedBadge />
            <ButtonComponent
              type="underline"
              onClick={() => navigate(MY_PROFILE_VALIDATE_PERSONA)}
            >
              {locale.personaLocales.personaWidget.link}
            </ButtonComponent>
          </>
        );
      case Persona_Inquiry_Status_Enum.Created:
      case Persona_Inquiry_Status_Enum.Started:
        return (
          <>
            <div className="flex desktop:min-w-[400px]">
              <StartedBadge />
              <h3 className="w-full text-sm text-med-gray font-semibold flex mx-2 flex-col desktop:flex-row">
                {locale.personaLocales.personaAlerts.alertBadge5Text}
                <Link
                  className="ml-1 underline text-clc-blue"
                  to={MY_PROFILE_VALIDATE_PERSONA}
                >
                  {locale.personaLocales.personaAlerts.alertBadge5TextHelper}
                </Link>
              </h3>
            </div>
          </>
        );
      case Persona_Inquiry_Status_Enum.Completed:
      case Persona_Inquiry_Status_Enum.MarkedForReview:
        return (
          <>
            <InReviewBadge />
            <h3 className="w-full text-sm text-med-gray font-semibold flex">
              {locale.personaLocales.personaAlerts.alertBadgeText1}
            </h3>
          </>
        );
      case Persona_Inquiry_Status_Enum.Approved:
        return (
          <>
            <VerifiedBadge />
            <h3 className="w-full text-sm text-med-gray font-semibold flex">
              {locale.personaLocales.personaAlerts.alertBadgeText2}
            </h3>
          </>
        );
      case Persona_Inquiry_Status_Enum.Declined:
        return (
          <>
            <DeclinedBadge />
            <h3 className="w-full text-sm text-med-gray font-semibold flex">
              {locale.personaLocales.personaAlerts.alertBadgeText3}
              <Link
                className="ml-1 underline text-clc-blue"
                to={MY_PROFILE_VALIDATE_PERSONA}
              >
                {locale.personaLocales.personaAlerts.alertBadgeText3Helper}
              </Link>
            </h3>
          </>
        );

      case Persona_Inquiry_Status_Enum.Locked:
        return (
          <>
            <div className="flex">
              <div className="flex mr-2">
                <LockedBadge className="mr-2" />
                <h3 className="w-full text-sm text-med-gray font-semibold flex">
                  {locale.personaLocales.personaAlerts.alertBadge4}
                </h3>
              </div>
              {isMobile && (
                <Link to={MY_PROFILE_PATIENT_LOCKED_VERIFICATION}>
                  <IconComponent
                    width="w-5"
                    height="h-5"
                    icon={InfoIcon}
                    fill="fill-clc-blue"
                  />
                </Link>
              )}
              {!isMobile && (
                <Tooltip
                  containerMaxHeight="h-[80px]"
                  textContainerTop="top-[-87px]"
                  textContainerCustomClasses="min-h-[78px]"
                  text={
                    locale.personaLocales.personaAlerts.alertBadge4PopupText
                  }
                >
                  <span className="cursor-pointer">
                    <IconComponent
                      width="w-5"
                      height="h-5"
                      icon={InfoIcon}
                      fill="fill-clc-blue"
                    />
                  </span>
                </Tooltip>
              )}
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col w-full gap-2.5 p-0 items-center">
      <div className="flex flex-row w-full items-center justify-between pb-[15px]">
        <div className="flex flex-row items-center">
          <h3 className="w-full uppercase text-sm text-dark-gray font-bold mb-2 flex">
            {locale.personaLocales.personaWidget.title}
          </h3>
        </div>
      </div>
      <div className="pb-5 flex flex-col desktop:flex-row w-full desktop:w-[690px] items-start desktop:items-center gap-3">
        <p className="w-full desktop:w-2/4 text-base text-dark-gray font-semibold flex">
          {locale.personaLocales.personaWidget.title}
          <span className="text-base font-bold text-clc-red mr-[7px]"> *</span>
          {isMobile && (
            <Link to={MY_PROFILE_PATIENT_VALIDATE_INFORMATION}>
              <IconComponent
                width="w-5"
                height="h-5"
                icon={InfoIcon}
                fill="fill-clc-blue"
              />
            </Link>
          )}
          {!isMobile && (
            <Tooltip
              containerMaxHeight="h-[80px]"
              textContainerTop="top-[-87px]"
              textContainerCustomClasses="min-h-[78px]"
              text={locale.personaLocales.personaWidget.hoverText}
            >
              <span className="cursor-pointer">
                <IconComponent
                  width="w-5"
                  height="h-5"
                  icon={InfoIcon}
                  fill="fill-clc-blue"
                />
              </span>
            </Tooltip>
          )}
        </p>
        <div className="flex flex-row desktop:w-5/12 gap-[15px]">
          {userVerificationStatusFactory(userVerificationStatus)}
        </div>
      </div>
      <hr className="desktop:flex flex-row w-full items-center h-px bg-black-blur" />
    </div>
  );
};
