import { useNavigate } from 'react-router-dom';
import { ModalButtonProps } from 'components/modal/modal.interfaces';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import { genericActionsIds } from 'utilities/constants';
import { AQIndexIconLabels } from 'assets/icons/weatherIcons/AQIndexIconLabels';
import { AQInfoProps } from './interfaces';
import Modal from 'components/modal/modalComponent';
import IconComponent from 'components/iconComponent';
import InfoCategory from './InfoCategory';
import InfoCategoryWrapper from './InfoCategoryWrapper';

const AQInfo: React.FC<AQInfoProps> = ({ locale }) => {
  const navigate = useNavigate();

  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.CLOSE],
    });

  const handleClose = () => navigate(-1);

  if (genericActionLoading || !genericAction) {
    return null;
  }

  const Buttons: ModalButtonProps[] = [
    {
      label: genericAction?.[genericActionsIds.CLOSE].close,
      onClick: handleClose,
    },
  ];

  return (
    <Modal
      isOpen={true}
      title={locale.aqIndex.aqInfoTitle}
      disableMobileGrow={true}
      buttons={Buttons}
      onClose={handleClose}
      modalContainerCustomClass="desktop:!min-h-[830px] desktop:max-h-[830px] overflow-y-auto"
    >
      <div className="flex flex-col items-center desktop:hidden">
        <div className="max-w-[320px]">
          <IconComponent
            width="w-full"
            height="h-auto"
            icon={() => <AQIndexIconLabels id="aqinfo" />}
            fill="none"
          />
        </div>
      </div>

      <InfoCategoryWrapper>
        <InfoCategory
          borderTop
          color="bg-gradient-lime"
          category={`${locale.aqIndex.good} ${locale.aqIndex.goodLevels}`}
          categoryLetter={locale.categoryA}
          categoryWarning={locale.aqIndex.goodWarning}
        />

        <InfoCategory
          color="bg-gradient-yellow"
          category={`${locale.aqIndex.moderate} ${locale.aqIndex.moderateLevels}`}
          categoryLetter={locale.categoryB}
          categoryWarning={locale.aqIndex.moderateWarning}
        />

        <InfoCategory
          color="bg-gradient-orange"
          category={`${locale.aqIndex.unhealthySensitive} ${locale.aqIndex.unhealthySensitiveLevels}`}
          categoryLetter={locale.categoryC}
          categoryWarning={locale.aqIndex.unhealthySensitiveWarning}
        />

        <InfoCategory
          color="bg-gradient-red"
          category={`${locale.aqIndex.unhealthy} ${locale.aqIndex.unhealthyLevels}`}
          categoryLetter={locale.categoryD}
          categoryWarning={locale.aqIndex.unhealthyWarning}
        />

        <InfoCategory
          color="bg-gradient-wine"
          category={`${locale.aqIndex.veryUnhealthy} ${locale.aqIndex.veryUnhealthyLevels}`}
          categoryLetter={locale.categoryE}
          categoryWarning={locale.aqIndex.veryUnhealthyWarning}
        />

        <InfoCategory
          color="bg-gradient-crimson"
          category={`${locale.aqIndex.hazardous} ${locale.aqIndex.hazardousLevels}`}
          categoryLetter={locale.categoryF}
          categoryWarning={locale.aqIndex.hazardousWarning}
        />
      </InfoCategoryWrapper>
      <p className="text-med-gray">{locale.aqIndex.aqiInfo}</p>
      <p className="text-med-gray">{locale.aqIndex.youCanThink}</p>
      <p className="text-med-gray">{locale.aqIndex.aqiValue}</p>
    </Modal>
  );
};

export default AQInfo;
