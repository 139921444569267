import { AuthContext, useAuth } from 'auth/context/AuthContext';
import {
  Test_States_Enum,
  Test_Types_Enum,
  ZrtTestOutput,
} from 'graphql/generated/hasura';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  TestKitsTableData,
  TestsSortTypes,
} from '../components/DNATestKitTable/interfaces';

import {
  extractUUIDFromResURL,
  formatDateToCustomFormat,
  formatMMDDYYYYtoYYYYMMDD,
} from 'utilities/functions';
import { Dnavisit_Test_Statuses_Enum } from 'graphql/generated/hasura';
import { useGetComponent } from 'hooks/useGetComponent';
import { componentIds } from 'utilities/constants';
import {
  DnaVisitTestOutput,
  Hasura_Test_Types_Enum,
  useGetFhirDnaVisitAndZrtTestsLazyQuery,
  GetFhirDnaVisitAndZrtTestsQuery,
  InputMaybe,
} from 'graphql/generated/remote-schema-hasura';
import {
  MapFhirToHasuraDnaVisitTestState,
  MapFhirToHasuraDnaVisitTestType,
  MapFhirZrtToDnaVisitTestState,
  MapHasuraToFhirDnaVisitTestState,
  MapHasuraToFhirDnaVisitTestType,
} from './constants';
import { SIGNAL_CHANNELS, useChannelSignal } from 'hooks/useChannelSignal';

interface Props {
  limit: number;
  dnaTestStateSelected: Dnavisit_Test_Statuses_Enum[];
  testTypeSelected: string | null;
}

interface DNATestData {
  dnaTests: TestKitsTableData[];
  loading: boolean;
  count: number;
}

interface NewFilters {
  fromDate: string;
  toDate: string;
  newStates: Dnavisit_Test_Statuses_Enum[];
  providerNameToFilter: string;
  sortByMobile: string;
}

export const useGetUserTestKits = ({
  dnaTestStateSelected,
  testTypeSelected,
}: Props) => {
  const { user: loggedUser } = useContext(AuthContext);
  const isFirstRun = useRef(true);
  const [dnaData, setDNAData] = useState<DNATestData>({
    loading: false,
    dnaTests: [],
    count: 0,
  });
  const [filterByProviderName, setFilterByProviderName] = useState('');
  const [sortByMobile, setSortByMobile] = useState('Date - Newest');

  const [getFhirTests, { loading: loadingFhirTests, fetchMore, refetch }] =
    useGetFhirDnaVisitAndZrtTestsLazyQuery({
      fetchPolicy: 'cache-and-network',
      variables: {
        limit: 10,
        patientCodexId: loggedUser?.uuid,
      },
      onCompleted: (data) => {
        if (data) getUserFhirTests(data);
      },
    });

  const {
    data: testKitsAndResultsFiltersLocale,
    loading: testKitsAndResultsFilterLoading,
  } = useGetComponent({
    locale: 'en',
    componentId: componentIds.TEST_KITS_AND_RESULTS_FILTERS,
  });

  const viewTypeToTestTypesEnum = useCallback(
    (type: string | null): Test_Types_Enum | undefined => {
      switch (type) {
        case testKitsAndResultsFiltersLocale?.dnaSkinTestSelectOption:
          return Test_Types_Enum.DnaSkin;
        case testKitsAndResultsFiltersLocale?.dnaVitaminTestSelectOption:
          return Test_Types_Enum.DnaVitamin;
        case testKitsAndResultsFiltersLocale?.zrtHeavyMetalsTestSelectOption:
          return Test_Types_Enum.ZrtHeavyMetals;
        case testKitsAndResultsFiltersLocale?.zrtHormoneTestSelectOption:
          return Test_Types_Enum.ZrtHormone;
        case testKitsAndResultsFiltersLocale?.zrtNeurotransmittersTestSelectOption:
          return Test_Types_Enum.ZrtNeurotransmitters;
        case testKitsAndResultsFiltersLocale?.zrtInflamatoryTestSelectOption:
          return Test_Types_Enum.ZrtInflammatory;

        case testKitsAndResultsFiltersLocale?.all:
          return undefined;

        default:
          console.error('Unexpected type was received');
          return;
      }
    },
    [testKitsAndResultsFiltersLocale],
  );

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      getFhirTests({
        variables: {
          limit: 10,
          ...(dnaTestStateSelected.length === 1
            ? {
                state: MapHasuraToFhirDnaVisitTestState.get(
                  dnaTestStateSelected[0],
                ) as unknown as Test_States_Enum,
              }
            : {}),
        },
      });
    }
  }, [dnaTestStateSelected, loggedUser?.uuid, getFhirTests]);

  const sortData = (
    a: string,
    b: string,
    column: keyof TestKitsTableData,
    orderBy: TestsSortTypes,
  ) => {
    if (column === 'date') {
      const firstDate = new Date(a);
      const secondDate = new Date(b);
      const descOrd =
        firstDate.getTime() > secondDate.getTime()
          ? -1
          : secondDate.getTime() > firstDate.getTime()
          ? 1
          : 0;
      if (orderBy === 'desc') return descOrd;
      return descOrd * -1;
    }
    const descOrd = a > b ? -1 : b > a ? 1 : 0;
    if (orderBy === 'desc') return descOrd;
    return descOrd * -1;
  };

  const getSortedData = useCallback(
    (data: TestKitsTableData[]) => {
      if (testKitsAndResultsFilterLoading || !testKitsAndResultsFiltersLocale) {
        const tempData = [...data];
        return tempData.sort((a, b) =>
          sortData(
            a['date'].toLocaleLowerCase(),
            b['date'].toLocaleLowerCase(),
            'date',
            'desc',
          ),
        );
      }
      const mappedSortByMobile: { [key: string]: string } = {
        [testKitsAndResultsFiltersLocale?.mobileDateNewest]: 'date/desc',
        [testKitsAndResultsFiltersLocale?.mobileDateOldest]: 'date/asc',
        [testKitsAndResultsFiltersLocale?.mobileTestTypeDesc]: 'test type/desc',
        [testKitsAndResultsFiltersLocale?.mobileTestTypeAsc]: 'test type/asc',
        [testKitsAndResultsFiltersLocale?.mobileTestKitIDDesc]: 'swabCode/desc',
        [testKitsAndResultsFiltersLocale?.mobileTestKitIDAsc]: 'swabCode/asc',
        [testKitsAndResultsFiltersLocale?.mobileProviderDesc]: 'provider/desc',
        [testKitsAndResultsFiltersLocale?.mobileProviderAsc]: 'provider/asc',
        [testKitsAndResultsFiltersLocale?.mobileStatusDesc]: 'status/desc',
        [testKitsAndResultsFiltersLocale?.mobileStatusAsc]: 'status/asc',
      };

      const rawData = mappedSortByMobile[sortByMobile].split('/');
      const columnToSort = (rawData[0] as keyof TestKitsTableData) || 'date';
      const sortOrder = (rawData[1] as TestsSortTypes) || 'desc';

      const tempData = [...data];

      return tempData.sort((a, b) =>
        sortData(
          a[columnToSort]?.toLocaleLowerCase() || 'date',
          b[columnToSort]?.toLocaleLowerCase() || 'date',
          columnToSort || 'date',
          sortOrder || 'desc',
        ),
      );
    },
    [
      testKitsAndResultsFiltersLocale,
      sortByMobile,
      testKitsAndResultsFilterLoading,
    ],
  );

  const DnaTestTypes = [
    Hasura_Test_Types_Enum.DnaVitamin,
    Hasura_Test_Types_Enum.DnaSkin,
  ];

  const getUserFhirTests = (testData: GetFhirDnaVisitAndZrtTestsQuery) => {
    const zrt_tests = testData.getFHIRDnaAndZrtTests.zrt_tests;
    const dna_tests = testData.getFHIRDnaAndZrtTests.dna_tests;
    const tests = [...zrt_tests, ...dna_tests].sort((a, b) => a.rank - b.rank);

    if (tests.length === 0) {
      return setDNAData({ dnaTests: [], loading: false, count: 0 });
    }

    const result: TestKitsTableData[] = tests.map(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (test: Record<string, any>) => {
        const common = {
          date: formatDateToCustomFormat(test.created_at),
          'test type': MapFhirToHasuraDnaVisitTestType.get(
            test.test_type,
          ) as Test_Types_Enum,
          provider: test.provider_name || 'DNAvisit',
          providerAcuityCalendarId: test.provider_acuity_calendar_id,
          providerAcuityOwnerId: test.provider_acuity_owner_id,
          trackingNumber: test.tracking_number || '',
          patientPdfId: extractUUIDFromResURL(test.patient_pdf_id || ''),
          providerCodexId: test.provider_codex_id || '',
          // TODO COD-1992: Map these fields into FHIR using appointments resource.
          patientVisitDate: '',
          patientVisitUrl: '',
        };

        if (DnaTestTypes.includes(test.test_type)) {
          const dna_test = test as DnaVisitTestOutput;
          return {
            ...common,
            testID: dna_test.test_id,
            fhirId: dna_test.diagnosticReportId,
            status: MapFhirToHasuraDnaVisitTestState.get(
              dna_test.state,
            ) as Dnavisit_Test_Statuses_Enum,
            swabCode: dna_test.sapphiros_barcode,
            patientVisitSummaryPdfId: dna_test.visit_summary_pdf_id || '',
          };
        } else {
          const zrt_test = test as ZrtTestOutput;
          return {
            ...common,
            fhirId: zrt_test.fhirId,
            testID: zrt_test.reference_code,
            status: MapFhirZrtToDnaVisitTestState.get(
              zrt_test.state,
            ) as Dnavisit_Test_Statuses_Enum,
            swabCode: zrt_test.swab_code,
            patientVisitSummaryPdfId: null,
          };
        }
      },
    );

    const sortedData = getSortedData(result);

    if (filterByProviderName) {
      const filteredDataByProviderName = sortedData.filter((test) =>
        test.provider
          .toLocaleLowerCase()
          .startsWith(filterByProviderName.toLocaleLowerCase()),
      );
      return setDNAData({
        dnaTests: filteredDataByProviderName,
        loading: false,
        count: filteredDataByProviderName.length,
      });
    }

    return setDNAData({
      dnaTests: sortedData,
      loading: false,
      count: testData.getFHIRDnaAndZrtTests.total,
    });
  };

  const handleFetchMore = async () => {
    const tempTestTypeEnum = viewTypeToTestTypesEnum(testTypeSelected);
    const testType:
      | InputMaybe<Hasura_Test_Types_Enum | Hasura_Test_Types_Enum[]>
      | undefined = tempTestTypeEnum
      ? (MapHasuraToFhirDnaVisitTestType.get(
          tempTestTypeEnum,
        ) as Hasura_Test_Types_Enum) ?? null
      : null;
    fetchMore({
      variables: {
        limit: dnaData.dnaTests.length + 10,
        testType,
        ...(dnaTestStateSelected.length === 1
          ? {
              state: MapHasuraToFhirDnaVisitTestState.get(
                dnaTestStateSelected[0],
              ) as unknown as Test_States_Enum,
            }
          : {}),
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        return !fetchMoreResult
          ? prev
          : {
              ...prev,
              getFHIRDnaAndZrtTests: {
                total: fetchMoreResult.getFHIRDnaAndZrtTests.total,
                dna_tests: [...fetchMoreResult.getFHIRDnaAndZrtTests.dna_tests],
                zrt_tests: [...fetchMoreResult.getFHIRDnaAndZrtTests.zrt_tests],
              },
            };
      },
    });
  };

  const handleRefetch = ({
    newStates,
    fromDate,
    toDate,
    providerNameToFilter,
    sortByMobile,
  }: NewFilters) => {
    setFilterByProviderName(providerNameToFilter);
    setSortByMobile(sortByMobile);

    const tempTestTypeEnum = viewTypeToTestTypesEnum(testTypeSelected);
    const testType:
      | InputMaybe<Hasura_Test_Types_Enum | Hasura_Test_Types_Enum[]>
      | undefined = tempTestTypeEnum
      ? (MapHasuraToFhirDnaVisitTestType.get(
          tempTestTypeEnum,
        ) as Hasura_Test_Types_Enum) ?? null
      : null;

    refetch({
      limit: 10,
      ...(newStates.length === 1
        ? {
            state: MapHasuraToFhirDnaVisitTestState.get(
              newStates[0],
            ) as unknown as Test_States_Enum,
          }
        : {}),
      testType: testType,
      from: fromDate ? `${formatMMDDYYYYtoYYYYMMDD(fromDate)}T00:00:00Z` : null,
      to: toDate ? `${formatMMDDYYYYtoYYYYMMDD(toDate)}T23:59:59Z` : null,
    });
  };

  const { user } = useAuth();

  useChannelSignal(
    (first) => {
      if (!first) refetch();
    },
    SIGNAL_CHANNELS.TESTKIT,
    user?.uuid,
  );

  return {
    ...dnaData,
    dnaTestStateSelected,
    handleFetchMore,
    handleRefetch,
    loadingFhirDnaVisitTests: loadingFhirTests,
  };
};
