import { AlertExtraActions } from 'utilities/interfaces';
import { ReactComponent as CloseIcon } from 'assets/icons/iconClose.svg';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import { genericActionsIds, pageIds } from 'utilities/constants';
import ButtonComponent from 'components/button/buttonComponent';

import { useNavigate } from 'react-router-dom';
import { useGetPage } from 'hooks/useGetPage';
import { useHandleExtraActions } from 'hooks/useHandleExtraActions';
import {
  User_Notification_Status_Enum,
  useUpdateInAppNotificationMutation,
} from 'graphql/generated/hasura';
import { NOTIFICATIONS } from 'utilities/routes';

interface NotificationModalProps {
  notification: string;
  notificationId: string;
  action: AlertExtraActions;
  extraActions: AlertExtraActions[];
  onClose?: () => void;
  disableEscapeKeyDown?: boolean;
  disableBackdropClick?: boolean;
  enableOverlapOfModals?: boolean;
  disableOverflow?: boolean;
}

export const NotificationModal: React.FC<NotificationModalProps> = ({
  notification,
  notificationId,
  action,
  extraActions,
  onClose,
  disableEscapeKeyDown = false,
  disableBackdropClick = false,
  enableOverlapOfModals = false,
  disableOverflow = false,
}) => {
  const navigate = useNavigate();
  const { handler: customActionsHandler, isLoading } = useHandleExtraActions();

  const [updateInAppNotificationMutation] = useUpdateInAppNotificationMutation(
    {},
  );

  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.CANCEL],
    });
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.NOTIFICATIONS,
  });

  const handleModalKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (disableEscapeKeyDown && event.key === 'Escape') {
      event.stopPropagation();
    }
  };

  const handleBackdropClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (disableBackdropClick) {
      event.stopPropagation();
    }
  };

  const handleBackdropClickModified = (
    event: React.MouseEvent<HTMLDivElement>,
  ) => {
    const target = event.target as HTMLDivElement;

    if (target.id === 'backdrop' && onClose) {
      event.stopPropagation();
      onClose();
    }
  };

  const handleAction = async (action: AlertExtraActions) => {
    if (action.customAction && customActionsHandler[action.customAction]) {
      customActionsHandler[action.customAction](
        action.customActionParams ?? {},
        false,
      );
      try {
        const response = await updateInAppNotificationMutation({
          variables: {
            id: String(notificationId),
            status: User_Notification_Status_Enum.Read,
          },
        });
        if (!response.data) {
          throw new Error('Failed to update notification');
        }
        navigate(NOTIFICATIONS, {
          state: {
            refetch: true,
          },
        });
      } catch (error: unknown) {
        throw new Error('Failed to update notification');
      }
      return;
    }
    navigate(action.route ?? -1);
  };

  if (genericActionLoading || !locale || loading) return null;

  return (
    <div
      onClick={(e) => handleBackdropClickModified(e)}
      id="backdrop"
      className={`fixed inset-0 ${
        enableOverlapOfModals ? 'z-[99999]' : 'z-50'
      } bg-black-blur backdrop-blur ${
        disableOverflow ? 'overflow-hidden' : 'overflow-auto'
      }`}
    >
      <div
        className={
          'desktop:w-[375px] flex flex-col mx-auto w-screen h-screen desktop:h-[auto] bg-base-content desktop:rounded-b-10'
        }
        onKeyDown={handleModalKeyDown}
        onClick={handleBackdropClick}
      >
        <div className="flex flex-col w-full">
          <div className="flex justify-end items-end pt-3 pr-3">
            <CloseIcon
              className={' stroke-med-gray cursor-pointer'}
              onClick={onClose}
            />
          </div>
          <div className="flex flex-col gap-[30px] px-10 pb-10 items-center">
            <div className="text-h6 text-med-gray font-medium">
              {notification}
            </div>
            <div className="text-h5 text-dark-gray font-semibold">
              {locale?.modalText}
            </div>
            <div className="flex flex-col gap-3">
              {action?.actionName && (
                <ButtonComponent
                  onClick={() => handleAction(action)}
                  disabled={isLoading}
                >
                  {action?.actionName}
                </ButtonComponent>
              )}
              {extraActions?.map((extraAction, index) => (
                <ButtonComponent
                  type={extraAction?.type ?? 'outlined'}
                  key={index}
                  onClick={() => handleAction(extraAction)}
                  disabled={isLoading}
                >
                  {extraAction.actionName}
                </ButtonComponent>
              ))}
              <ButtonComponent onClick={onClose} type="underline">
                {genericAction?.[genericActionsIds.CANCEL].cancel}
              </ButtonComponent>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
