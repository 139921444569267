import { useRegisterAuthProviderMutation } from 'graphql/generated/hasura';

import { REGISTER_AUTH_PROVIDER_ERRORS } from './interfaces/authProvider.interfaces';

export const useRegisterAuthProvider = () => {
  const [registerAuthProviderMutation, { error: registerProviderError }] =
    useRegisterAuthProviderMutation();

  const registerAuthProvider = async (providerType: string): Promise<void> => {
    try {
      const response = await registerAuthProviderMutation({
        variables: {
          provider_type: providerType,
        },
      });

      if (!response.data) {
        throw new Error(
          REGISTER_AUTH_PROVIDER_ERRORS.FAILED_TO_CREATE_PROVIDER,
        );
      }
    } catch (e) {
      if (e instanceof Error) {
        throw new Error(
          e.message ===
          REGISTER_AUTH_PROVIDER_ERRORS.AUTH_PROVIDER_ALREADY_EXISTS
            ? REGISTER_AUTH_PROVIDER_ERRORS.AUTH_PROVIDER_ALREADY_EXISTS
            : REGISTER_AUTH_PROVIDER_ERRORS.FAILED_TO_CREATE_PROVIDER,
        );
      } else {
        throw new Error(
          REGISTER_AUTH_PROVIDER_ERRORS.FAILED_TO_CREATE_PROVIDER,
        );
      }
    }
  };

  return { registerAuthProvider, registerProviderError };
};
