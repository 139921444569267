import { AuthContext } from 'auth/context/AuthContext';
import { useCallback, useContext, useMemo } from 'react';

import {
  Persona_Inquiry_Status_Enum,
  useGetUsersVerificationStatusByEmailSubscription,
} from 'graphql/generated/hasura';

export interface UserVerificationStatus {
  status: Persona_Inquiry_Status_Enum | null | undefined;
}

const useUserVerificationStatus = () => {
  const { user: loggedUser } = useContext(AuthContext);

  const {
    data: userVerficationData,
    loading: userVerificationLoading,
    error: errorUserVerification,
  } = useGetUsersVerificationStatusByEmailSubscription({
    fetchPolicy: 'network-only',
    variables: {
      email: loggedUser?.email || '',
    },
  });

  const userVerificationValidation = useCallback(async () => {
    let userVerificationStatus = null;

    userVerificationStatus = userVerficationData?.users[0].kyc_current_status;

    return {
      userVerificationStatus,
    };
  }, [userVerficationData?.users]);

  return useMemo(
    () => ({
      userVerificationValidation,
      loading: userVerificationLoading,
      error: errorUserVerification,
    }),
    [
      userVerificationValidation,
      userVerificationLoading,
      errorUserVerification,
    ],
  );
};

export default useUserVerificationStatus;
