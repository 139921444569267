import { useGetComponent } from 'hooks/useGetComponent';
import { MyVisitSummariesHistoryMobileProps } from './MyVisitSummariesHistoryTableDesktop';
import { componentIds } from 'utilities/constants';
import { useIsMobile } from 'hooks/useIsMobile';
import { appointmentRenderInfo } from 'app/my-appointments/interfaces';
import MyVisitSummariesTableActions from './MyVisitSummariesTableActions';
import { twMerge } from 'tailwind-merge';
import { AppointmentTypeEnum } from 'graphql/generated/hasura';

const MyVisitSummariesHistoryTableMobile: React.FC<
  MyVisitSummariesHistoryMobileProps
> = ({ myVisitSummaries, loading }) => {
  const { data: locale, loading: localeLoading } = useGetComponent({
    locale: 'en',
    componentId: componentIds.APPOINTMENTS_PATIENT_FILTER,
  });
  const isMobile = useIsMobile();
  if (localeLoading || !locale || !isMobile) {
    return null;
  }
  return (
    <div
      className={`flex flex-col px-2 self-stretch ${
        loading ? 'opacity-50 pointer-events-none' : ''
      }`}
    >
      {myVisitSummaries.map((myVisitSummary) => {
        const typeLocaleKey = appointmentRenderInfo[myVisitSummary.type]?.text;
        const TypeIcon = appointmentRenderInfo[myVisitSummary.type]?.icon;
        let customProviderClasses = '';
        if (myVisitSummary.type !== AppointmentTypeEnum.Dnavisit) {
          customProviderClasses =
            'font-bold text-charcoal-gray underline underline-offset-4';
        }
        return (
          <div
            key={myVisitSummary.id}
            className="flex flex-col gap-3 border-b-2 border-card-border-color py-4"
          >
            <div className="flex flex-col gap-1">
              <p className="font-bold text-base text-charcoal-gray">
                {locale.summaryDate}
              </p>
              <p className="font-medium text-base text-med-gray">
                {myVisitSummary.summaryDate}
              </p>
            </div>
            <div className="flex flex-col gap-1">
              <p className="font-bold text-base text-charcoal-gray">
                {locale.subject}
              </p>
              <p className="font-medium text-base text-med-gray">
                {myVisitSummary.subject}
              </p>
            </div>
            <div className="flex flex-col gap-1">
              <p className="font-bold text-base text-charcoal-gray">
                {locale.type}
              </p>
              <div className="flex flex-row gap-2 justify-start items-center">
                <TypeIcon />
                <p className="font-medium text-base text-med-gray">
                  {locale[typeLocaleKey]}
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <p className="font-bold text-base text-charcoal-gray">
                {locale.providerName}
              </p>
              <p
                className={twMerge(
                  'font-medium text-base text-med-gray',
                  customProviderClasses,
                )}
              >
                {myVisitSummary.providerName}
              </p>
            </div>
            <MyVisitSummariesTableActions appointmentId={myVisitSummary.id} />
          </div>
        );
      })}
    </div>
  );
};

export default MyVisitSummariesHistoryTableMobile;
