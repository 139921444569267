import Loader from 'components/loaderComponent';
import {
  CodexTags,
  useGetProvidersQuery,
  usePatchFhirProviderMutation,
  useUpdateProviderApprovalMutation,
} from 'graphql/generated/hasura';
import { Fragment, useMemo, useState } from 'react';
import { makeTableComponent } from 'app/superadmin/components/DataTable';
import Tooltip from 'components/Tooltip';
import ButtonComponent from 'components/button/buttonComponent';
import toast from 'react-hot-toast';
import { useModal } from 'layout/useModal';
import { Search } from '../components/Search';
import { SUPER_ADMIN, SUPER_ADMIN_APPOINTMENTS } from 'utilities/routes';
import { useNavigate } from 'react-router-dom';
import { easyFormModal, getIdentifier, hasFlag } from '../utils';

const nameRenderer = (p: ProvidersDataType) => {
  const name = (p ? `${p?.last ?? ''}, ${p?.first ?? ''}` : '-').trim();

  if (name.length > 40) {
    return <Tooltip text={name}>{name.substring(0, 40)} [..]</Tooltip>;
  } else {
    return <>{name}</>;
  }
};

type ProvidersDataType = {
  __typename?: 'SAProvider';
  appointmentCount: Array<Array<string>>;
  first: string;
  flags: Array<CodexTags>;
  identifiers: Array<Array<string>>;
  dates: Array<Array<string>>;
  last: string;
  patientCount: number;
};

export const Providers = () => {
  const [search, setSearch] = useState('');
  const navigate = useNavigate();
  const { loading, data, refetch } = useGetProvidersQuery({
    variables: {
      search,
    },
  });
  const [patchApprovalMutation, { loading: patchApprovalLoading }] =
    useUpdateProviderApprovalMutation();
  const [patchProviderMutation, { loading: patchProviderLoading }] =
    usePatchFhirProviderMutation();
  const [openConfigure] = useModal(easyFormModal);

  const configure = async (item: ProvidersDataType) => {
    const codexId = getIdentifier(item, 'codex');
    if (!codexId) {
      toast.error('Codex Id Is Required To Configure A Provider!');
      return;
    }

    const result = await openConfigure({
      title: 'Configure Provider',
      acuityOwnerId: getIdentifier(item, 'acuityOwner'),
      acuityCalendarId: getIdentifier(item, 'acuityCalendar'),
      dosespotId: getIdentifier(item, 'dosespot'),
    });

    if (result) {
      patchProviderMutation({
        variables: {
          codexId,
          input: {
            ...result,
          },
        },
        onCompleted: () => {
          toast.success('Updated Provider Configuration!');
          refetch();
        },
        onError: (error) => {
          toast.error(
            `Error encountered while updating Provider Configuration! -- ${error.message}`,
          );
        },
      });
    }
  };

  const setApproved = (codexId: string | undefined, approved: boolean) => {
    if (!codexId) {
      toast.error('Codex Id Is Required!');
      return;
    }

    patchApprovalMutation({
      variables: {
        codexId,
        approved,
      },
      onCompleted: () => {
        toast.success('Updated Codex Provider Approval!');
        refetch();
      },
      onError: (error) => {
        toast.error(
          `Error encountered while updating Codex Provider Approval! -- ${error.message}`,
        );
      },
    });
  };

  const Table = useMemo(() => makeTableComponent<ProvidersDataType>(), []);

  if (loading || patchApprovalLoading || patchProviderLoading)
    return <Loader />;

  return (
    <div>
      Providers
      <br />
      <Search text={search} onSearch={setSearch} />
      <hr />
      {data?.getProviders.providers.length && (
        <Table
          data={data?.getProviders.providers}
          getKey={(item) =>
            item.identifiers.find((id) => id[0] === 'fhir')?.[1] ?? 'never'
          }
        >
          <Table.KeyColumn dataKey="identifiers" />
          <Table.DynamicColumn title="name" renderer={nameRenderer} />
          <Table.KeyColumn
            dataKey="appointmentCount"
            renderer={(items: [string, string][], item: ProvidersDataType) => (
              <>
                {items.map(([type, count]) => (
                  <Fragment key={type}>
                    {type}:{' '}
                    <ButtonComponent
                      type="underline"
                      className="w-auto"
                      onClick={() =>
                        navigate(
                          `${SUPER_ADMIN}${SUPER_ADMIN_APPOINTMENTS}/provider/${getIdentifier(
                            item,
                            'fhir',
                          )}/status/${type}`,
                        )
                      }
                    >
                      {count}
                    </ButtonComponent>
                    <br />
                  </Fragment>
                ))}
                {items.length && (
                  <ButtonComponent
                    type="underline"
                    className="w-auto"
                    onClick={() =>
                      navigate(
                        `${SUPER_ADMIN}${SUPER_ADMIN_APPOINTMENTS}/provider/${getIdentifier(
                          item,
                          'fhir',
                        )}`,
                      )
                    }
                  >
                    view all
                  </ButtonComponent>
                )}
              </>
            )}
          />
          <Table.KeyColumn dataKey="patientCount" />
          <Table.KeyColumn dataKey="flags" />
          <Table.KeyColumn dataKey="dates" />
          <Table.DynamicColumn
            title="actions"
            renderer={(item) => (
              <>
                {!hasFlag(item, CodexTags.CodexApproved) && (
                  <ButtonComponent
                    type="underline"
                    className="w-auto"
                    onClick={() =>
                      setApproved(getIdentifier(item, 'codex'), true)
                    }
                  >
                    approve
                  </ButtonComponent>
                )}
                {hasFlag(item, CodexTags.CodexApproved) && (
                  <ButtonComponent
                    type="underline"
                    className="w-auto"
                    onClick={() =>
                      confirm('are you sure?') &&
                      setApproved(getIdentifier(item, 'codex'), false)
                    }
                  >
                    rescind approval
                  </ButtonComponent>
                )}
                <br />
                <ButtonComponent
                  type="underline"
                  className="w-auto"
                  onClick={() => configure(item)}
                >
                  configure
                </ButtonComponent>
              </>
            )}
          />
        </Table>
      )}
      {!data?.getProviders.providers.length && <>No Records Found</>}
    </div>
  );
};
