import React, { useEffect, useRef, useState } from 'react';
import { useGetSignedUrlFromStorageMutation } from 'graphql/generated/hasura';
import { ReactComponent as FileDownloadIcon } from 'assets/icons/file-download.svg';
import { ReactComponent as PrintIcon } from 'assets/icons/print.svg';
import { ReactComponent as EyeIcon } from 'assets/icons/pwEyeShow.svg';
import { useGetFileFromStorage } from 'hooks/useGetFileFromStorage';
import { usePdfDownload } from 'hooks/usePdfDownload';
import { printPdf } from 'utilities/functions';
import { useIsMobile } from 'hooks/useIsMobile';
import MySkinSummary from '../../my-skin/components/MySkinSummary';

interface Props {
  patientPdfId: string;
  onLoadComplete: () => void;
}

const DNATestKitActions: React.FC<Props> = ({
  patientPdfId,
  onLoadComplete,
}) => {
  const [pdfReportSignedUri, setPDFReportSignedURI] = useState('');
  const [pdfReportURI, setPDFReportURI] = useState('');
  const fetchedReportFileRef = useRef<boolean>(false);
  const [showMySkinSummaryModal, setShowMySkinSummaryModal] = useState(false);
  const { loading, hasError, fetchFileFromStorage } = useGetFileFromStorage();
  const { downloadPdfWithURI } = usePdfDownload();
  const isMobile = useIsMobile();

  const handleOnView = () => setShowMySkinSummaryModal(true);

  const [getSignedUrlFromStorage] = useGetSignedUrlFromStorageMutation({});

  const handleOnDownload = async () => {
    downloadPdfWithURI(pdfReportURI);
  };

  const handleOnPrint = async () => {
    printPdf(pdfReportURI);
  };

  const handleCloseModal = () => setShowMySkinSummaryModal(false);

  useEffect(() => {
    const getPdfReport = async () => {
      const pdfReportLink = await fetchFileFromStorage(patientPdfId);
      const signedUrl = await getSignedUrlFromStorage({
        variables: { fileId: patientPdfId },
      });
      setPDFReportURI(pdfReportLink);
      if (signedUrl.data?.GetSignUrlFormStorage?.url) {
        setPDFReportSignedURI(signedUrl.data?.GetSignUrlFormStorage.url);
      }
      onLoadComplete();
    };

    if (!fetchedReportFileRef.current) {
      fetchedReportFileRef.current = true;
      getPdfReport().catch((err: unknown) =>
        console.log('Something when trying to get PDF from storage: ', err),
      );
    }
  }, [
    patientPdfId,
    fetchFileFromStorage,
    getSignedUrlFromStorage,
    onLoadComplete,
  ]);

  if (loading || hasError) return null;

  return (
    <div
      className={
        'flex flex-row w-full items-center justify-around desktop:w-full'
      }
    >
      <EyeIcon
        onClick={handleOnView}
        className="w-[18px] h-2.5 fill-current cursor-pointer"
      />
      <FileDownloadIcon
        onClick={handleOnDownload}
        className="w-[15px] h-[18px] cursor-pointer fill-clc-blue"
      />
      {!isMobile && (
        <PrintIcon
          onClick={handleOnPrint}
          className="w-[17px] h-[16px] cursor-pointer fill-clc-blue"
        />
      )}

      {showMySkinSummaryModal && (
        <MySkinSummary
          pdfReportURI={pdfReportSignedUri}
          handleCloseModal={handleCloseModal}
        />
      )}
    </div>
  );
};
export default DNATestKitActions;
