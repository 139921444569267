import React, { ReactNode } from 'react';

interface FormFieldProps {
  label: string;
  isRequired: boolean;
  children: ReactNode;
  zipCode?: boolean;
  htmlFor: string;
}

const FormField: React.FC<FormFieldProps> = ({
  label,
  isRequired,
  children,
  zipCode,
  htmlFor,
}) => {
  return (
    <div className="flex flex-col desktop:flex-row w-full desktop:w-[690px] items-center desktop:items-baseline mx-auto">
      <label
        className={`${
          zipCode
            ? 'desktop:mr-[19px] w-full desktop:w-auto'
            : 'w-full desktop:w-3/12'
        } text-base text-dark-gray font-semibold mb-2`}
        htmlFor={htmlFor}
      >
        {label}
        {isRequired && (
          <span className="text-base font-bold text-clc-red">*</span>
        )}
      </label>
      {children}
    </div>
  );
};

export default FormField;
