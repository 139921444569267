interface ContentBlockProps {
  title: string;
  content: string;
  titlesInBold?: boolean;
}

export const ContentBlock: React.FC<ContentBlockProps> = ({
  title,
  content,
  titlesInBold = false,
}) => {
  return (
    <div className="flex flex-col gap-2">
      <h1
        className={`${
          titlesInBold ? 'font-bold' : 'font-semibold'
        } text-sm uppercase text-charcoal-gray`}
      >
        {title}
      </h1>
      <div>
        <p className="text-med-gray">{content}</p>
      </div>
    </div>
  );
};
