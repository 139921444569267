import { UpcomingAppointmentsCardRightComponentProps } from 'app/my-appointments/interfaces';
import Input from 'components/inputComponent';

export const UpcomingAppointmentsCardRightComponent: React.FC<
  UpcomingAppointmentsCardRightComponentProps
> = ({
  locale,
  appointmentsForEntries,
  appointmentsForValue,
  onAppointmentsForSelectChange,
}) => {
  return (
    <div className="flex-col desktop:flex-row justify-start items-start flex desktop:inline-flex gap-2">
      <div className="self-stretch justify-start items-center gap-[5px] inline-flex">
        <div className="pt-1 justify-start items-start flex">
          <span className="w-max text-base font-semibold leading-snug">
            {locale.viewAppointmentsFor}
          </span>
        </div>
      </div>
      <div className="w-full desktop:w-40">
        <Input
          type="select"
          value={appointmentsForValue}
          selectInputProps={{
            selectValues: appointmentsForEntries,
            fullWidth: true,
            onSelectChange: onAppointmentsForSelectChange,
          }}
        />
      </div>
    </div>
  );
};
