import { AppointmentStatus } from 'app/my-appointments/interfaces';
import {
  AppointmentHistoryEntry,
  AppointmentHistoryModalComponentProps,
} from 'components/appointmentDetailModal/interfaces';
import {
  Appointment_Event,
  GetAppointmentByCodexAppointmentIdQuery,
} from 'graphql/generated/remote-schema-hasura';
import { useGetPage } from 'hooks/useGetPage';
import { useNavigate } from 'react-router-dom';
import { pageIds } from 'utilities/constants';
import {
  MY_APPOINTMENTS_PATIENT_MYVISIT_SUMMARY_MODAL,
  MY_APPOINTMENTS_SCHEDULE_AN_APPOINTMENT,
} from 'utilities/routes';

const youDidNotAttendEntry: AppointmentHistoryEntry = {
  actionLocale: 'scheduleNewAppointment',
  actionPath: MY_APPOINTMENTS_SCHEDULE_AN_APPOINTMENT,
  titleLocale: 'appointmentHistoryYouDidNotShowUpStatus',
};

const providerDidNotAttendEntry: AppointmentHistoryEntry = {
  actionLocale: 'scheduleNewAppointment',
  actionPath: MY_APPOINTMENTS_SCHEDULE_AN_APPOINTMENT,
  titleLocale: 'appointmentHistoryProviderDidNotShowUpStatus',
};

const appointmentCompleted: AppointmentHistoryEntry = {
  actionLocale: 'myVisitSummary',
  actionPath: MY_APPOINTMENTS_PATIENT_MYVISIT_SUMMARY_MODAL,
  titleLocale: 'appointmentHistoryCompletedStatus',
};

function getAppointmentEntryBasedOnEvents(
  appointment: GetAppointmentByCodexAppointmentIdQuery['getFHIRAppointmentByCodexId']['appointment'],
): AppointmentHistoryEntry | null {
  const isAppointmentCompleted =
    appointment?.encounterNotes?.followupRecommendations ||
    appointment?.encounterNotes?.providerSummary;
  if (isAppointmentCompleted) {
    return appointmentCompleted;
  }
  if (appointment?.status === AppointmentStatus.BOOKED) {
    return null;
  }
  const didPatientJoined = appointment?.events?.find(
    (e) => e.event === Appointment_Event.PatientJoined,
  );
  const didProviderJoined = appointment?.events?.find(
    (e) => e.event === Appointment_Event.ProviderJoined,
  );
  if (!didPatientJoined) {
    return youDidNotAttendEntry;
  }
  if (!didProviderJoined) {
    return providerDidNotAttendEntry;
  }
  return null;
}

export const AppointmentHistoryModalComponent: React.FC<
  AppointmentHistoryModalComponentProps
> = ({ appointment }) => {
  const { data: locale } = useGetPage({
    locale: 'en',
    pageId: pageIds.APPOINTMENT_DETAILS,
  });
  const navigate = useNavigate();
  if (!locale) return null;
  const currentEntry = getAppointmentEntryBasedOnEvents(appointment);
  if (!currentEntry) return null;
  const handleAction = () => {
    navigate(currentEntry.actionPath, {
      state: {
        appointmentId: appointment?.appointmentCodexId,
      },
    });
  };
  return (
    <div className="container mx-auto mt-4">
      <div className="bg-white p-4 shadow-desktop">
        <h2 className="text-lg font-semibold  border-b border-gray-300 pb-4">
          {locale.appointmentHistory}
        </h2>
        <div className="flex flex-col mt-5 desktop:flex-row px-5">
          <ul className="flex w-full flex-col desktop:flex-row justify-between">
            <p className="font-bold text-charcoal-gray desktop:hidden">
              {locale.log}
            </p>
            <li className="list-disc text-med-gray text-h6">
              {locale[currentEntry.titleLocale]}
            </li>
            <p className="mt-5 font-bold text-charcoal-gray desktop:hidden">
              {locale.actions}
            </p>
            <li
              className="list-disc text-clc-blue underline hover:cursor-pointer font-bold desktop:mt-0"
              onClick={handleAction}
            >
              {locale[currentEntry.actionLocale]}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
