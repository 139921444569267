import { Card } from './Card';
import { TestKitResultRow } from './TestKitResultRow';
import { TestKitsResultsProps } from '../interfaces/testKits.interfaces';
import { capitalizeFirstLetter } from 'utilities/functions';
import { useNavigate } from 'react-router-dom';
import { MY_PATIENTS_BIO_INFO_TEST_KIT_RESULTS } from 'utilities/routes';
import Placeholder from './Placeholder';
import { useMemo } from 'react';
import { DNA_VISIT_TESTS_LIMIT } from '../pages/PatientInfo';
import { useGetGenericActions } from '../../../hooks/useGetGenericActions';
import { genericActionsIds } from '../../../utilities/constants';

export const TestKitsResults: React.FC<TestKitsResultsProps> = ({
  locale,
  total,
  testKitResult,
}) => {
  const title = capitalizeFirstLetter(locale.testKitsResultsTitle);
  const hasTestKitResult = useMemo(
    () => testKitResult.length > 0,
    [testKitResult],
  );
  const subtitle = capitalizeFirstLetter(
    hasTestKitResult
      ? `${locale.showing} ${testKitResult.length} ${locale.of} ${total}`
      : `${locale.showing} ${testKitResult.length}`,
  );

  const navigate = useNavigate();
  const goToTestKitResults = () => {
    navigate(`../${MY_PATIENTS_BIO_INFO_TEST_KIT_RESULTS}`, {
      replace: true,
    });
  };

  const { data: genericAction } = useGetGenericActions({
    locale: 'en',
    genericActionId: [genericActionsIds.VIEW],
  });

  const btnText = genericAction && genericAction[genericActionsIds.VIEW].view;

  return (
    <Card
      title={title}
      subtitle={subtitle}
      buttonText={
        testKitResult.length > DNA_VISIT_TESTS_LIMIT
          ? locale.viewAllButton
          : undefined
      }
      buttonCTA={goToTestKitResults}
      className="min-h-[416px]"
    >
      {hasTestKitResult ? (
        testKitResult.map((testResult, index) => (
          <TestKitResultRow
            {...testResult}
            buttonText={btnText}
            key={`${testResult.name}${index}`}
          />
        ))
      ) : (
        <Placeholder placeholder={locale.testKitPlaceholder} />
      )}
    </Card>
  );
};
