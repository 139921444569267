import { ReactComponent as FileDownloadIcon } from 'assets/icons/file-download.svg';
import { ReactComponent as PrintIcon } from 'assets/icons/print.svg';
import { ReactComponent as EyeIcon } from 'assets/icons/pwEyeShow.svg';
import { useGetFileFromStorage } from 'hooks/useGetFileFromStorage';
import { printPdf } from 'utilities/functions';
import { Scan } from 'app/dashboard/interfaces/scan.interfaces';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  DASHBOARD,
  DASHBOARD_SCAN_RESULTS,
  MY_DERMSCORE_SCAN_RESULTS,
} from 'utilities/routes';
import { usePdfDownload } from 'hooks/usePdfDownload';
import { useIsMobile } from 'hooks/useIsMobile';
import {
  genericActionsIds,
  PARAM_MODALS_IDENTIFIERS,
} from 'utilities/constants';
import ButtonComponent from 'components/button/buttonComponent';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import { twMerge } from 'tailwind-merge';

interface ScanActionsProps {
  scan: Scan;
  alignIconsRight?: boolean;
  useCurrentPathNavigate?: boolean;
  withTitles?: boolean;
  classNames?: string;
  buttonWrapperClassName?: string;
}

const ScanActions: React.FC<ScanActionsProps> = ({
  alignIconsRight = false,
  scan,
  useCurrentPathNavigate = false,
  withTitles = false,
  classNames,
  buttonWrapperClassName,
}) => {
  const { data: genericAction } = useGetGenericActions({
    locale: 'en',
    genericActionId: [
      genericActionsIds.VIEW,
      genericActionsIds.DOWNLOAD,
      genericActionsIds.PRINT,
    ],
  });

  const alignIconsRightClass = alignIconsRight
    ? 'justify-end'
    : 'justify-center';

  const { fetchFileFromStorage } = useGetFileFromStorage();
  const { downloadPdf } = usePdfDownload();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useIsMobile();
  const isDashboard = location.pathname === DASHBOARD;
  const scanResultUrl = isDashboard
    ? DASHBOARD_SCAN_RESULTS
    : MY_DERMSCORE_SCAN_RESULTS;

  const isTitlesHidden = !withTitles;
  const buttonClassName = twMerge(
    'flex flex-row items-center justify-center px-2.5 gap-2 rounded-10',
    buttonWrapperClassName,
  );

  const handleOnView = async () => {
    const imageUrl = await fetchFileFromStorage(String(scan?.imageId));
    navigate(
      useCurrentPathNavigate
        ? `.?${PARAM_MODALS_IDENTIFIERS.MY_PATIENTS_DERMSCORE_SCANS_MODAL_ID}=true`
        : scanResultUrl,
      {
        state: {
          scan: {
            ...scan,
            imageUrl,
          },
          fileUrl: imageUrl,
          onlyView: true,
        },
      },
    );
  };

  const handleOnDownload = async () => {
    scan?.reportId && downloadPdf(scan.reportId);
  };

  const handleOnPrint = async () => {
    const fileUrl = await fetchFileFromStorage(String(scan?.reportId));
    printPdf(fileUrl);
  };

  return (
    <div
      className={`flex max-mobile:block flex-row w-full desktop:w-auto items-center justify-center max-mobile:justify-items-start desktop:${alignIconsRightClass} ${classNames} p-0 desktop:gap-[2px]`}
    >
      <div onClick={handleOnView} className={buttonClassName}>
        <EyeIcon className="w-[24px] h-2.5 fill-current cursor-pointer" />
        <ButtonComponent
          className={isTitlesHidden ? 'hidden' : 'max-mobile:w-auto'}
          type="underline"
        >
          {genericAction?.[genericActionsIds.VIEW]?.view}
        </ButtonComponent>
      </div>
      <div onClick={handleOnDownload} className={buttonClassName}>
        <FileDownloadIcon className="w-[15px] h-[18px] cursor-pointer fill-clc-blue" />
        <ButtonComponent
          className={isTitlesHidden ? 'hidden' : ''}
          type="underline"
        >
          {genericAction?.[genericActionsIds.DOWNLOAD]?.download}
        </ButtonComponent>
      </div>
      {!isMobile && (
        <div onClick={handleOnPrint} className={buttonClassName}>
          <PrintIcon className="w-[17px] h-[16px] cursor-pointer fill-clc-blue" />
          <ButtonComponent
            className={!withTitles ? 'hidden' : ''}
            type="underline"
          >
            {genericAction?.[genericActionsIds.PRINT]?.print}
          </ButtonComponent>
        </div>
      )}
    </div>
  );
};

export default ScanActions;
