import { userHasRequiredRole } from '../firebase/hooks';
import { Roles } from '../firebase/interfaces';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { DASHBOARD } from 'utilities/routes';

interface Props {
  allowedRoles: Roles[];
  children: React.ReactNode;
}

const ProtectedSubRoute: React.FC<Props> = ({ allowedRoles, children }) => {
  const hasBeenProcessed = useRef<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkRole = async () => {
      for (const role of allowedRoles) {
        const isAllowed = await userHasRequiredRole(role);
        hasBeenProcessed.current = true;
        if (isAllowed) {
          break;
        } else {
          navigate(DASHBOARD, { replace: true });
        }
      }
    };
    checkRole();
  }, [allowedRoles, navigate]);

  return <>{children}</>;
};

export default ProtectedSubRoute;
