import { useGetUserPrivacyPreferencesQuery } from 'graphql/generated/hasura';
import { ToggleComponent } from './ToggleComponent';
import { useCallback, useContext, useEffect, useState } from 'react';
import { AuthContext } from 'auth/context/AuthContext';

import {
  PrivacyPreferencesProps,
  PrivacyPreferencesState,
  PrivacyPreferencesKeys,
} from '../interfaces/privacyPreferences';
import { AccountSettingsState } from '../interfaces/providerModuleInterfaces';

export const PrivacyPreferences: React.FC<PrivacyPreferencesProps> = ({
  locale,
  setAccountSettings,
  setIsAllSectionsLoaded,
}) => {
  const [privacyPreferencesStatus, setPrivacyPreferencesStatus] =
    useState<PrivacyPreferencesState | null>(null);

  const { user: loggedUser } = useContext(AuthContext);

  useGetUserPrivacyPreferencesQuery({
    variables: {
      id: loggedUser?.uuid,
    },
    onCompleted: (data) => {
      setPrivacyPreferencesStatus({
        PRIVACY__SETTINGS_ENABLED:
          data.users[0].PRIVACY_AFFILIATES_CODEX_LABS_SETTING ||
          data.users[0].PRIVACY_CALIFORNIA_SETTINGS ||
          data.users[0].PRIVACY_NON_AFFILIATES_CODEX_LABS_SETTING ||
          false,
        PRIVACY_AFFILIATES_CODEX_LABS_SETTING:
          data.users[0].PRIVACY_AFFILIATES_CODEX_LABS_SETTING,
        PRIVACY_CALIFORNIA_SETTINGS: data.users[0].PRIVACY_CALIFORNIA_SETTINGS,
        PRIVACY_NON_AFFILIATES_CODEX_LABS_SETTING:
          data.users[0].PRIVACY_NON_AFFILIATES_CODEX_LABS_SETTING,
      });
      setIsAllSectionsLoaded((prevState) => ({
        ...prevState,
        privacyPreferences: true,
      }));
    },
  });

  const toggleHandler = useCallback(
    (key: PrivacyPreferencesKeys, state: boolean) => {
      setPrivacyPreferencesStatus(
        (prev) =>
          ({
            ...prev,
            [PrivacyPreferencesKeys[key]]: state,
          } as PrivacyPreferencesState),
      );
    },
    [],
  );

  const toggleEnabled = (state: boolean) => {
    return setPrivacyPreferencesStatus({
      PRIVACY__SETTINGS_ENABLED: state,
      PRIVACY_AFFILIATES_CODEX_LABS_SETTING: state,
      PRIVACY_CALIFORNIA_SETTINGS: state,
      PRIVACY_NON_AFFILIATES_CODEX_LABS_SETTING: state,
    } as PrivacyPreferencesState);
  };

  useEffect(() => {
    const {
      PRIVACY_NON_AFFILIATES_CODEX_LABS_SETTING,
      PRIVACY_CALIFORNIA_SETTINGS,
      PRIVACY_AFFILIATES_CODEX_LABS_SETTING,
      PRIVACY__SETTINGS_ENABLED,
    } = privacyPreferencesStatus || {};
    if (
      !PRIVACY_AFFILIATES_CODEX_LABS_SETTING &&
      !PRIVACY_CALIFORNIA_SETTINGS &&
      !PRIVACY_NON_AFFILIATES_CODEX_LABS_SETTING &&
      PRIVACY__SETTINGS_ENABLED
    ) {
      toggleHandler(PrivacyPreferencesKeys.PRIVACY__SETTINGS_ENABLED, false);
    }
  }, [privacyPreferencesStatus, toggleHandler]);

  useEffect(() => {
    setAccountSettings((prevState) => {
      return {
        ...prevState,
        ...privacyPreferencesStatus,
      } as AccountSettingsState;
    });
  }, [privacyPreferencesStatus, setAccountSettings]);

  return (
    <div>
      <div className="mb-[30px]">
        <p className="text-title text-charcoal-gray font-bold uppercase">
          {locale.privacyTitle}
        </p>
        <p className="text-sm font-semibold text-med-gray">
          {locale.privacySubtitle}
        </p>
        {privacyPreferencesStatus !== null && (
          <>
            <ToggleComponent
              title={locale?.privacyToggleEnabledTitle}
              onChangeHandler={toggleEnabled}
              checkedState={privacyPreferencesStatus.PRIVACY__SETTINGS_ENABLED}
              locale={locale}
            />
            <ToggleComponent
              title={locale.privacyToggleTitle1}
              subtitle={locale.privacyToggleSubtitle1}
              onChangeHandler={(state) =>
                toggleHandler(
                  PrivacyPreferencesKeys.PRIVACY_AFFILIATES_CODEX_LABS_SETTING,
                  state,
                )
              }
              checkedState={
                privacyPreferencesStatus.PRIVACY_AFFILIATES_CODEX_LABS_SETTING
              }
              disabled={!privacyPreferencesStatus.PRIVACY__SETTINGS_ENABLED}
              locale={locale}
            />
            <ToggleComponent
              title={locale.privacyToggleTitle2}
              subtitle={locale.privacyToggleSubtitle2}
              onChangeHandler={(state) =>
                toggleHandler(
                  PrivacyPreferencesKeys.PRIVACY_NON_AFFILIATES_CODEX_LABS_SETTING,
                  state,
                )
              }
              checkedState={
                privacyPreferencesStatus.PRIVACY_NON_AFFILIATES_CODEX_LABS_SETTING
              }
              disabled={!privacyPreferencesStatus.PRIVACY__SETTINGS_ENABLED}
              locale={locale}
            />
            <ToggleComponent
              title={locale.privacyToggleTitle3}
              subtitle={locale.privacyToggleSubtitle3}
              onChangeHandler={(state) =>
                toggleHandler(
                  PrivacyPreferencesKeys.PRIVACY_CALIFORNIA_SETTINGS,
                  state,
                )
              }
              checkedState={
                privacyPreferencesStatus.PRIVACY_CALIFORNIA_SETTINGS
              }
              disabled={!privacyPreferencesStatus.PRIVACY__SETTINGS_ENABLED}
              locale={locale}
            />
          </>
        )}
      </div>
    </div>
  );
};
