interface BottomTabsProps {
  tabs: Tab[];
  onTabSelected: (index: number) => void;
}

interface Tab {
  icon: React.ReactNode;
  iconSelected: React.ReactNode;
  isSelected: boolean;
}

const BottomTabs: React.FC<BottomTabsProps> = ({ tabs, onTabSelected }) => {
  const handleTabSelected = (index: number) => {
    onTabSelected(index);
  };

  return (
    <div className="flex flex-row bg-white w-full h-full justify-between">
      {tabs.map((tab, index) => (
        <div
          key={index}
          className={`${
            tab.isSelected ? 'border-clc-blue' : 'border-white'
          } border-t-[6px] w-full flex items-center justify-center cursor-pointer pb-[6px]`}
          onClick={() => handleTabSelected(index)}
        >
          {tab.isSelected ? tab.iconSelected : tab.icon}
        </div>
      ))}
    </div>
  );
};

export default BottomTabs;
