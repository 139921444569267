import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useFirebaseSignOut } from '../firebase/hooks';
import { componentIds } from 'utilities/constants';
import { useGetComponent } from './useGetComponent';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CodexFeatureFlags } from 'utilities/interfaces';

const REMAINING_TIME_BASE_STATE = '6:00';

export const useIdleTimerModalFunctionality = () => {
  const [inactivityModalOpen, setInactivityModalOpen] =
    useState<boolean>(false);

  const { data: componentLocale, loading: componentLoading } = useGetComponent({
    locale: 'en',
    componentId: componentIds.INACTIVITY_MODAL,
  });
  const { inactivityModalFlag } = useFlags<CodexFeatureFlags>();
  const onIdle = () => {
    setInactivityModalOpen(false);
  };

  const onActive = () => {
    setInactivityModalOpen(false);
  };

  const onPrompt = () => {
    setInactivityModalOpen(true);
  };

  const { getRemainingTime, pause, reset, getLastActiveTime } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout: inactivityModalFlag?.show ? inactivityModalFlag?.timeout : 1,
    promptBeforeIdle: inactivityModalFlag?.show
      ? inactivityModalFlag?.promtBeforeIdle
      : undefined,
    throttle: 500,
    crossTab: true,
    leaderElection: true,
    syncTimers: 200,
  });

  const [remainingTime, setRemainingTime] = useState<string>('');

  const handleStillHere = () => {
    reset();
    setInactivityModalOpen(false);
  };

  const signOutUser = useFirebaseSignOut();

  const handleLogOut = () => {
    setInactivityModalOpen(false);
    pause();
    signOutUser();
  };

  useEffect(() => {
    if (inactivityModalFlag && inactivityModalFlag?.show) {
      if (
        inactivityModalOpen &&
        remainingTime.includes(REMAINING_TIME_BASE_STATE)
      ) {
        setInactivityModalOpen(false);
      }

      const interval = setInterval(() => {
        const remaining = getRemainingTime();
        const minutes = Math.floor(remaining / 60_000);
        const seconds = Math.floor((remaining % 60_000) / 1000);
        setRemainingTime(
          `${minutes}:${seconds < 10 ? '0' : ''}${seconds} ${
            componentLocale?.minutesAbbreviation
          }`,
        );

        if (remaining === 0) {
          setInactivityModalOpen(false);
          signOutUser();
        }
      }, 500);

      return () => {
        clearInterval(interval);
      };
    }
  }, [
    remainingTime,
    getRemainingTime,
    signOutUser,
    inactivityModalOpen,
    componentLocale?.minutesAbbreviation,
    inactivityModalFlag,
  ]);

  return {
    inactivityModalOpen,
    handleStillHere,
    handleLogOut,
    componentLoading,
    componentLocale,
    remainingTime,
    getLastActiveTime,
    signOutUser,
  };
};
