interface PreworkItemContainerProps {
  question: string;
  children: React.ReactNode;
  gap?: string;
}

export const PreworkItemContainer: React.FC<PreworkItemContainerProps> = ({
  question,
  children,
  gap,
}) => {
  return (
    <div className={`flex flex-col ${gap ? gap : ''}`}>
      <span className="font-semibold">{question}</span>
      {children}
    </div>
  );
};
