import { Dispatch, SetStateAction } from 'react';
import { TabType } from '../../DatePicker.types';
import { DatePickerButton } from '../datePickerButton';
import { DaysRowHeader } from '../daysRowHeader';
import { MonthDaysGrid } from '../monthDaysGrid';
import { DatePickerNotice } from '../datePickerNotice';

export interface daysRowHeaderProps {
  weekDays: string[];
}

export interface monthDaysGridProps {
  monthDays: Date[][];
  selectedMonth: number;
  selectedDate: Date | null;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
}

export interface DatePickerNoticeProps {
  label: string;
}

interface dayTabProps {
  selectedYear: number;
  currentMonth: string;
  weekDays: string[];
  daysRowHeaderProps: daysRowHeaderProps;
  monthDaysGridProps: monthDaysGridProps;
  datePickerNoticeProps: DatePickerNoticeProps;
  setSelectionTab: Dispatch<SetStateAction<TabType>>;
  onNavClickPrev: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onNavClickNext: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const DayTab: React.FC<dayTabProps> = ({
  selectedYear,
  currentMonth,
  daysRowHeaderProps,
  monthDaysGridProps,
  datePickerNoticeProps,
  setSelectionTab,
  onNavClickPrev,
  onNavClickNext,
}) => {
  const changeSelectionTab = (
    e: React.MouseEvent<HTMLElement> | null = null,
    tab: TabType,
  ) => {
    if (e) {
      e.preventDefault();
    }

    setSelectionTab(tab);
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <DatePickerButton
          buttonType="arrow-button"
          arrowDirection="left"
          onClick={onNavClickPrev}
        />
        <div>
          <DatePickerButton
            label={currentMonth}
            onClick={(e) => changeSelectionTab(e, 'month')}
          />
          <DatePickerButton
            label={selectedYear}
            onClick={(e) => {
              changeSelectionTab(e, 'year');
            }}
          />
        </div>
        <DatePickerButton
          buttonType="arrow-button"
          arrowDirection="right"
          onClick={onNavClickNext}
        />
      </div>
      <table className="w-full">
        <DaysRowHeader weekDays={daysRowHeaderProps.weekDays} />
        <MonthDaysGrid
          monthDays={monthDaysGridProps.monthDays}
          selectedMonth={monthDaysGridProps.selectedMonth}
          selectedDate={monthDaysGridProps.selectedDate}
          onClick={monthDaysGridProps.onClick}
        />
      </table>
      <hr className="mt-3.5" />
      {/* Available dates should be shown conditionally once we have that feature */}
      <DatePickerNotice label={datePickerNoticeProps.label} />
    </div>
  );
};
