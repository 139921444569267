import { Link } from 'react-router-dom';
import { UVWarningProps } from './interfaces';

const UVWarning: React.FC<UVWarningProps> = ({ locale }) => {
  return (
    <p className="text-med-gray font-normal">
      {locale.uvIndex.additionalInformation}{' '}
      <Link
        className="text-clc-blue underline"
        to={locale.uvIndex.usEpaLink}
        target="_blank"
      >
        {locale.uvIndex.usEpa}
      </Link>{' '}
      {locale.uvIndex.website}
    </p>
  );
};

export default UVWarning;
