import {
  AppointmentTypeEnum,
  useGetPatientAppointmentByCodexAppointmentIdQuery,
} from 'graphql/generated/remote-schema-hasura';
import { useState } from 'react';
import { fromFhirAppointmentTypeToLocal } from 'utilities/functions';

export interface ModalAppointmentInfo {
  appointmentTypeByState?: AppointmentTypeEnum;
  providerNameByState?: string;
  loading: boolean;
}

function useLoadModalAppointmentInfo(
  appointmentCodexId?: string,
  appointmentTypeByParam?: AppointmentTypeEnum,
  providerNameByParam?: string,
): ModalAppointmentInfo {
  const [providerNameByState, setProviderNameByState] = useState<string>();
  const [appointmentTypeByState, setAppointmentTypeByState] =
    useState<AppointmentTypeEnum>();
  const skipAppointmentQuery =
    !appointmentCodexId ||
    (appointmentTypeByParam !== undefined && !!providerNameByParam);
  const { loading } = useGetPatientAppointmentByCodexAppointmentIdQuery({
    skip: skipAppointmentQuery,
    variables: {
      appointmentCodexId: appointmentCodexId || '',
    },
    onCompleted: (data) => {
      const appointment = data.getFHIRAppointmentByCodexId.appointment;
      if (!appointment) return;
      const providerName = `${appointment.providerFirstName} ${appointment.providerLastName}`;
      setProviderNameByState(providerName);
      setAppointmentTypeByState(
        fromFhirAppointmentTypeToLocal(appointment.appointmentType),
      );
    },
  });
  return {
    appointmentTypeByState,
    loading,
    providerNameByState,
  };
}

export default useLoadModalAppointmentInfo;
