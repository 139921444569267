import { ReactComponent as ClearDaySunnyIcon } from 'assets/icons/weatherIcons/clearDaySunny.svg';
import { ReactComponent as ClearNightIcon } from 'assets/icons/weatherIcons/clearNight.svg';
import { ReactComponent as CloudyIcon } from 'assets/icons/weatherIcons/cloudy.svg';
import { ReactComponent as FoggyIcon } from 'assets/icons/weatherIcons/foggy.svg';
import { ReactComponent as RainIcon } from 'assets/icons/weatherIcons/rain.svg';
import { ReactComponent as SnowIcon } from 'assets/icons/weatherIcons/snow.svg';
import { ReactComponent as LightningIcon } from 'assets/icons/weatherIcons/lightning.svg';
import { Conditions } from './interfaces';
import IconComponent from 'components/iconComponent';

interface ConditionIconProps {
  id: number;
  isDay: boolean;
}

const ConditionIcon: React.FC<ConditionIconProps> = ({ id, isDay }) => {
  switch (id) {
    case Conditions.THUNDERSTORM_WITH_LIGHT_RAIN:
    case Conditions.THUNDERSTORM_WITH_RAIN:
    case Conditions.THUNDERSTORM_WITH_HEAVY_RAIN:
    case Conditions.LIGHT_THUNDERSTORM:
    case Conditions.THUNDERSTORM:
    case Conditions.HEAVY_THUNDERSTORM:
    case Conditions.RAGGED_THUNDERSTORM:
    case Conditions.THUNDERSTORM_WITH_LIGHT_DRIZZLE:
    case Conditions.THUNDERSTORM_WITH_DRIZZLE:
    case Conditions.THUNDERSTORM_WITH_HEAVY_DRIZZLE:
      return (
        <IconComponent
          width="w-[120px]"
          height="h-[120px]"
          icon={LightningIcon}
          fill="none"
        />
      );
    case Conditions.LIGHT_INTENSITY_DRIZZLE:
    case Conditions.DRIZZLE:
    case Conditions.HEAVY_INTENSITY_DRIZZLE:
    case Conditions.LIGHT_INTENSITY_DRIZZLE_RAIN:
    case Conditions.DRIZZLE_RAIN:
    case Conditions.HEAVY_INTENSITY_DRIZZLE_RAIN:
    case Conditions.SHOWER_RAIN_AND_DRIZZLE:
    case Conditions.HEAVY_SHOWER_RAIN_AND_DRIZZLE:
    case Conditions.SHOWER_DRIZZLE:
    case Conditions.LIGHT_RAIN:
    case Conditions.MODERATE_RAIN:
    case Conditions.HEAVY_INTENSITY_RAIN:
    case Conditions.VERY_HEAVY_RAIN:
    case Conditions.EXTREME_RAIN:
    case Conditions.LIGHT_INTENSITY_SHOWER_RAIN:
    case Conditions.SHOWER_RAIN:
    case Conditions.HEAVY_INTENSITY_SHOWER_RAIN:
    case Conditions.RAGGED_SHOWER_RAIN:
      return (
        <IconComponent
          width="w-[120px]"
          height="h-[120px]"
          icon={RainIcon}
          fill="none"
        />
      );
    case Conditions.FREEZING_RAIN:
    case Conditions.LIGHT_SNOW:
    case Conditions.SNOW:
    case Conditions.HEAVY_SNOW:
    case Conditions.SLEET:
    case Conditions.LIGHT_SHOWER_SLEET:
    case Conditions.SHOWER_SLEET:
    case Conditions.LIGHT_RAIN_AND_SNOW:
    case Conditions.RAIN_AND_SNOW:
    case Conditions.LIGHT_SHOWER_SNOW:
    case Conditions.SHOWER_SNOW:
    case Conditions.HEAVY_SHOWER_SNOW:
      return (
        <IconComponent
          width="w-[120px]"
          height="h-[120px]"
          icon={SnowIcon}
          fill="none"
        />
      );
    case Conditions.MIST:
    case Conditions.SMOKE:
    case Conditions.HAZE:
    case Conditions.SAND_DUST_WHIRLS:
    case Conditions.FOG:
    case Conditions.SAND:
    case Conditions.DUST:
    case Conditions.VOLCANIC_ASH:
    case Conditions.SQUALLS:
    case Conditions.TORNADO:
      return (
        <IconComponent
          width="w-[120px]"
          height="h-[120px]"
          icon={FoggyIcon}
          fill="none"
        />
      );
    case Conditions.CLEAR_SKY:
      return (
        <IconComponent
          width="w-[120px]"
          height="h-[120px]"
          icon={isDay ? ClearDaySunnyIcon : ClearNightIcon}
          fill="none"
        />
      );
    case Conditions.FEW_CLOUDS_11_25:
    case Conditions.SCATTERED_CLOUDS_25_50:
    case Conditions.BROKEN_CLOUDS_51_84:
    case Conditions.OVERCAST_CLOUDS_85_100:
      return (
        <IconComponent
          width="w-[120px]"
          height="h-[120px]"
          icon={CloudyIcon}
          fill="none"
        />
      );
    default:
      console.log(`Unexpected code: ${id}`);
      return null;
  }
};

export default ConditionIcon;
