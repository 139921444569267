import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRootConfig } from 'utilities/rootConfig';
import { useGenerateCustomToken } from '../../../firebase/hooks';
import { genericActionsIds, pageIds } from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';
import { AuthContext, AuthContextType } from 'auth/context/AuthContext';
import { ModalButtonProps } from 'components/modal/modal.interfaces';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import { useGetSkintellingentTestsByUserIdSubscription } from 'graphql/generated/hasura';

import {
  DASHBOARD,
  DASHBOARD_ANALIZE_PHOTO,
  DASHBOARD_NEW_SCAN,
  MY_DERMSCORE_NEW_SCAN,
  MY_SKIN,
  MY_SKIN_ANALIZE_PHOTO,
  QR_ACTION,
} from 'utilities/routes';
import Modal from 'components/modal/modalComponent';
import QRCode from 'react-qr-code';

export const ScanQr = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user: loggedUser } = useContext<AuthContextType>(AuthContext);
  const [userTestsCount, setUserTestsCount] = useState<number>();
  const { baseUrl } = useRootConfig();
  const { handleGenerateCustomToken, data, error } = useGenerateCustomToken();
  const [link, setLink] = useState<string>('');
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.SCAN_QR,
  });
  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.CLOSE],
    });
  const { data: testData } = useGetSkintellingentTestsByUserIdSubscription({
    variables: {
      userId: loggedUser?.uuid || '',
    },
  });
  const isDashboard = location.pathname === DASHBOARD;
  const goBackUrl = isDashboard ? DASHBOARD : MY_SKIN;
  const newScanUrl = isDashboard ? DASHBOARD_NEW_SCAN : MY_DERMSCORE_NEW_SCAN;
  const analizePhotoUrl = isDashboard
    ? DASHBOARD_ANALIZE_PHOTO
    : MY_SKIN_ANALIZE_PHOTO;

  useEffect(() => {
    const userTests = testData?.skintelligent_tests_aggregate.nodes;

    if (userTestsCount === undefined && userTests) {
      setUserTestsCount(userTests.length);
    }
    if (
      Number(userTestsCount) >= 0 &&
      userTests &&
      userTestsCount !== userTests.length
    ) {
      const submittedSessionId = userTests[0]?.session_id;
      if (submittedSessionId) {
        navigate(analizePhotoUrl, {
          state: {
            submittedSessionId,
          },
        });
      }
    }
  }, [testData, userTestsCount, navigate, analizePhotoUrl]);

  useEffect(() => {
    const token = data?.GenerateCustomToken?.token;
    if (token) {
      const link = `${baseUrl}${QR_ACTION}?action=scanFace&token=${token}`;
      setLink(link);
    }
    if (error) {
      navigate(newScanUrl, {
        state: { qrCodeError: true },
      });
    }
  }, [baseUrl, data, error, navigate, newScanUrl]);

  useEffect(() => {
    handleGenerateCustomToken();
    const interval = setInterval(() => {
      handleGenerateCustomToken();
    }, 55 * 60 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [handleGenerateCustomToken]);

  const handleModalClose = () => {
    navigate(goBackUrl);
  };

  const Buttons: ModalButtonProps[] = [
    {
      label: genericAction?.[genericActionsIds.CLOSE].close,
      onClick: handleModalClose,
    },
  ];

  if ((loading && !locale) || (genericActionLoading && !genericAction) || !link)
    return null;

  return (
    <Modal
      isOpen={true}
      title={locale.takeAPhoto}
      buttons={Buttons}
      onClose={handleModalClose}
    >
      <div className="flex flex-col gap-[30px]">
        <p className="text-base font-semibold text-med-gray">
          {locale.scanTheQrCode}
        </p>
        <QRCode size={300} className="self-center" value={link} />
      </div>
    </Modal>
  );
};
