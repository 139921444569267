interface ProviderToggleButtonGroupProps {
  buttons: string[];
  selectedButton: string;
  onButtonChange: (button: string) => void;
}

export const ProviderToggleButtonGroup: React.FC<
  ProviderToggleButtonGroupProps
> = ({ buttons, selectedButton, onButtonChange }) => {
  return (
    <div className="flex flex-row w-full rounded-5 border border-gray-opacity-15 appearance-none bg-white">
      {buttons.map((button, index) => (
        <button
          key={button}
          className={`flex w-full items-center justify-center font-bold text-h7 p-2 ${
            button === selectedButton ? 'bg-blue text-white' : 'text-med-gray'
          }${
            index === 0
              ? ' rounded-l-5'
              : index === buttons.length - 1
              ? ' rounded-r-5'
              : ''
          }${
            index !== buttons.length - 1
              ? ' border-r border-gray-opacity-15'
              : ''
          }`}
          onClick={() => onButtonChange(button)}
        >
          {button}
        </button>
      ))}
    </div>
  );
};
