import { linearGradientDef } from '@nivo/core';
import {
  COMEDONE_GRAPH_ID,
  INFLAMMATORY_GRAPH_ID,
  PIH_GRAPH_ID,
  TOTAL_GRAPH_ID,
} from './interface';

export const perGraphExtraInfo = {
  [COMEDONE_GRAPH_ID]: {
    mainColor: '#741EB8EE',
    initialOpacity: 0.5,
  },
  [INFLAMMATORY_GRAPH_ID]: {
    mainColor: '#107DA3',
    initialOpacity: 0.35,
  },
  [PIH_GRAPH_ID]: {
    mainColor: '#BF3F3F',
    initialOpacity: 0.5,
  },
  [TOTAL_GRAPH_ID]: {
    mainColor: '#2B43DA',
    initialOpacity: 0.3,
  },
};

export const areaLinearGradients = Object.entries(perGraphExtraInfo).map(
  ([id, info]) =>
    linearGradientDef(`g-${id}`, [
      { offset: 0, color: info.mainColor, opacity: info.initialOpacity },
      { offset: 75, color: info.mainColor, opacity: info.initialOpacity },
      { offset: 100, color: '#000000', opacity: 0 },
    ]),
);

export const areaLinearGradientsMatchers = Object.keys(perGraphExtraInfo).map(
  (id) => ({
    match: { id },
    id: `g-${id}`,
  }),
);
