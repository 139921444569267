import React, { useState, ReactNode } from 'react';

interface TooltipProps {
  text: string | null | undefined;
  children: ReactNode;
  maxWidth?: string;
  containerMaxHeight?: string;
  textContainerTop?: string;
  textContainerCustomClasses?: string;
}

const Tooltip: React.FC<TooltipProps> = ({
  text,
  children,
  maxWidth = 'w-[355px]',
  containerMaxHeight = 'h-[93px]',
  textContainerTop = 'top-[-103px]',
  textContainerCustomClasses = '',
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <span
      className="relative inline-block"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {showTooltip && (
        <>
          <span
            className={`absolute ${containerMaxHeight} p-5 rounded-[5px] left-[-17px] border-[1px] border-gray-opacity-15 bottom-[30px] z-[99999999] after:content-[''] after:border-b-[1px] after:border-r-[1px] after:border-b-gray-opacity-15 after:border-r-gray-opacity-15 after:bg-error-yellow after:absolute after:left-[19px] after:bottom-[-8px] after:w-[15px] after:h-[15px] after:rotate-45 w-[355px]`}
          />
          <span
            className={`shadow-container z-50 absolute ${textContainerTop} left-40 transform -translate-x-1/2 bg-error-yellow text-charcoal-gray text-sm py-2 px-3 rounded whitespace-pre-wrap border-gray-opacity-15 ${maxWidth} ${textContainerCustomClasses}`}
          >
            {text}
          </span>
        </>
      )}
    </span>
  );
};

export default Tooltip;
