import { Tab } from 'app/my-patients/interfaces/tab.interface';
import InputComponent, {
  SelectWithCustomIconsValues,
} from 'components/inputComponent';
import { useEffect, useMemo, useState } from 'react';
import DermscoreGraph from '../components/dermscore-graph/DermscoreGraph';
import {
  INFLAMMATORY_GRAPH_ID,
  COMEDONE_GRAPH_ID,
  PIH_GRAPH_ID,
  TOTAL_GRAPH_ID,
  GraphType,
} from '../components/dermscore-graph/interface';
import { ReactComponent as InflammationsIcon } from 'assets/icons/inflammations.svg';
import { ReactComponent as ComedonesIcon } from 'assets/icons/comedones.svg';
import { ReactComponent as PihIcon } from 'assets/icons/pih.svg';
import { ReactComponent as TotalIcon } from 'assets/icons/total.svg';
import { ReactComponent as AllIcon } from 'assets/icons/dermscore-graph-all.svg';
import { Tabs } from 'app/my-patients/components/Tabs';
import { useIsMobile } from 'hooks/useIsMobile';
import { componentIds } from 'utilities/constants';
import { useGetComponent } from 'hooks/useGetComponent';
import {
  OrderByDirections,
  SkintelligentOrderByCriterias,
  useGetFhirSkintellingentTestsQuery,
} from 'graphql/generated/remote-schema-hasura';

import { Scan } from 'app/dashboard/interfaces/scan.interfaces';
import { Image_Source_Types_Enum } from 'graphql/generated/hasura';
import Loader from 'components/loaderComponent';
import { MAX_GRAPH_POINTS, allTabId, viewValues } from './constants';

const DermscoreGraphCard: React.FC = () => {
  const { data: locale } = useGetComponent({
    locale: 'en',
    componentId: componentIds.DERMSCORE_GRAPH_CARD,
  });

  const [viewPeriod, setViewPeriod] = useState<string>();
  const viewPeriodValues = useMemo(() => {
    if (!locale) return;
    return Object.entries(viewValues).reduce<Record<string, string>>(
      (acc, [key, dateGenerator]) => {
        const localeValue = locale.viewPeriod[key] as string;
        if (!localeValue) return acc;
        acc[localeValue] = dateGenerator();
        return acc;
      },
      {},
    );
  }, [locale]);

  const [scans, setScans] = useState<Scan[]>();

  useGetFhirSkintellingentTestsQuery({
    variables: {
      limit: MAX_GRAPH_POINTS,
      orderBy: {
        field: SkintelligentOrderByCriterias.CreatedAt,
        direction: OrderByDirections.Desc,
      },
      from: viewPeriodValues?.[
        viewPeriod || Object.keys(viewPeriodValues || {})[0]
      ],
    },

    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const scans =
        data.getFHIRSkintelligentTests.skintelligent_tests.map<Scan>(
          (fhirTest) => ({
            id: fhirTest.test_id,
            comedones: fhirTest.comedone || 0,
            date: fhirTest.created_at,
            imageId: fhirTest.result_image_id || '',
            reportId: fhirTest.report_id || '',
            source: fhirTest.image_source as Image_Source_Types_Enum,
            inflammations: fhirTest.inflammatory || 0,
            pih: fhirTest.pih || 0,
            total: fhirTest.total_lesions || 0,
          }),
        );
      setScans(scans);
    },
    onError: (error) => {
      // TODO COD-2320: Handle error in a better way
      console.error(error);
    },
  });
  const [activeTab, setActiveTab] = useState<string>();
  const isMobile = useIsMobile();
  const tabs = useMemo<Tab[] | undefined>(() => {
    if (!locale) return undefined;
    return [
      {
        display: locale?.tabs?.[INFLAMMATORY_GRAPH_ID],
        id: INFLAMMATORY_GRAPH_ID,
        Icon: InflammationsIcon,
      },
      {
        display: locale?.tabs?.[COMEDONE_GRAPH_ID],
        id: COMEDONE_GRAPH_ID,
        Icon: ComedonesIcon,
      },
      {
        display: locale?.tabs?.[PIH_GRAPH_ID],
        id: PIH_GRAPH_ID,
        Icon: PihIcon,
      },
      {
        display: locale?.tabs?.[TOTAL_GRAPH_ID],
        id: TOTAL_GRAPH_ID,
        Icon: TotalIcon,
      },
      { display: locale?.tabs?.[allTabId], id: allTabId, Icon: AllIcon },
    ];
  }, [locale]);

  const customSelectInputValues = useMemo<
    SelectWithCustomIconsValues[] | undefined
  >(() => {
    if (!locale) return undefined;
    return [
      {
        label: locale?.tabs?.[INFLAMMATORY_GRAPH_ID],
        value: INFLAMMATORY_GRAPH_ID,
        icon: InflammationsIcon,
      },
      {
        label: locale?.tabs?.[COMEDONE_GRAPH_ID],
        value: COMEDONE_GRAPH_ID,
        icon: ComedonesIcon,
      },
      {
        label: locale?.tabs?.[PIH_GRAPH_ID],
        value: PIH_GRAPH_ID,
        icon: PihIcon,
      },
      {
        label: locale?.tabs?.[TOTAL_GRAPH_ID],
        value: TOTAL_GRAPH_ID,
        icon: TotalIcon,
      },
      { label: locale?.tabs?.[allTabId], value: allTabId, icon: AllIcon },
    ];
  }, [locale]);

  useEffect(() => {
    if (tabs && !activeTab) {
      setActiveTab(tabs?.[0].id);
    }
  }, [tabs, activeTab]);

  const [lastScan, average] = useMemo(() => {
    if (!scans) return [];
    let dataToProcess: number[];
    switch (activeTab) {
      case COMEDONE_GRAPH_ID:
        dataToProcess = scans
          .sort(
            (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
          )
          .map((test) => test.comedones || 0);
        break;
      case PIH_GRAPH_ID:
        dataToProcess = scans
          .sort(
            (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
          )
          .map((test) => test.pih || 0);
        break;
      case INFLAMMATORY_GRAPH_ID:
        dataToProcess = scans
          .sort(
            (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
          )
          .map((test) => test.inflammations || 0);
        break;
      default:
        dataToProcess = scans
          .sort(
            (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
          )
          .map((test) => test.total || 0);
        break;
    }
    const lastScan = dataToProcess[0];
    const average =
      dataToProcess.reduce((acc, curr) => acc + curr, 0) / dataToProcess.length;
    const averageFixedTo = 3;
    const averageFixed =
      Math.round(average * 10 ** averageFixedTo) / 10 ** averageFixedTo;
    return [lastScan, averageFixed];
  }, [scans, activeTab]);
  const loaded = scans && tabs && viewPeriodValues && locale;
  return (
    <div className="flex flex-col px-5 pt-5 pb-10 my-[30px] desktop:p-[30px] desktop:mb-[30px] gap-5 bg-white rounded-10">
      {loaded ? (
        <>
          <div className="flex flex-col desktop:flex-row desktop:items-center justify-between">
            <h3 className="text-h3 text-dark-gray font-semibold">
              {locale?.text?.skinHealthProgress}
            </h3>
            <div className="flex flex-row items-center gap-4">
              <span className="font-semibold text-black text-base">
                {locale?.text?.view}
              </span>
              <div className="flex grow desktop:grow-0">
                <InputComponent
                  type="select"
                  name="viewPeriod"
                  value={viewPeriod}
                  selectInputProps={{
                    selectValues: Object.keys(viewPeriodValues || {}),
                    onSelectChange: (e) => {
                      setViewPeriod(e.target.value);
                    },
                    fullWidth: isMobile,
                    customClasses: 'pr-7',
                  }}
                />
              </div>
            </div>
          </div>

          {isMobile ? (
            <>
              <hr />
              <InputComponent
                type="select-with-custom-icons"
                name="graphType"
                selectInputWithCustomIconProps={{
                  onSelectChange: (e) => setActiveTab(e.value),
                  options: customSelectInputValues || [],
                  currentValue: customSelectInputValues?.find(
                    (v) => v.value === activeTab,
                  ),
                }}
                value={tabs.find((tab) => tab.id === activeTab)?.display}
              />
            </>
          ) : (
            <Tabs
              activeTab={activeTab || ''}
              setActiveTab={setActiveTab}
              tabs={tabs}
              customContainerProps="desktop:justify-start border-b border-graph-border-color"
              inactiveColor="text-black text-bold"
              customTabsProps="mx-8 min-w-[100px]"
            />
          )}
          <div className="self-center desktop:self-end pb-5 flex flex-col desktop:flex-row items-start desktop:items-center gap-2 desktop:gap-5">
            <div className="flex flex-row items-center gap-2">
              <span className="font-exo font-semibold text-black text-lg">
                {lastScan ?? '-'}
              </span>
              <span className="font-exo font-normal text-med-gray text-base">
                {locale.text.lastScan}
              </span>
            </div>
            <div className="flex flex-row items-center gap-2">
              <span className="font-exo font-semibold text-black text-lg">
                {!average && average !== 0 ? '-' : average}
              </span>
              <span className="font-exo font-normal text-med-gray text-base">
                {locale?.text?.average}
              </span>
            </div>
          </div>
          <DermscoreGraph
            data={scans}
            only={activeTab !== 'all' ? (activeTab as GraphType) : undefined}
          />
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default DermscoreGraphCard;
