import React, { useState } from 'react';
import Input from 'components/inputComponent';
import ButtonComponent from 'components/button/buttonComponent';
import { useGetComponent } from 'hooks/useGetComponent';
import { componentIds } from 'utilities/constants';
import { useNavigate } from 'react-router-dom';
import { MY_PATIENTS } from 'utilities/routes';
import { ReactComponent as SearchIcon } from 'assets/icons/searchIconBlue.svg';

const FindPatientRecordSearchBar: React.FC = () => {
  const [searchBarValue, setSearchBarValue] = useState<string>('');
  const navigate = useNavigate();

  const { data: locale, loading } = useGetComponent({
    locale: 'en',
    componentId: componentIds.FIND_PATIENT_RECORD,
  });

  const handleSearchBarChange = () => {
    navigate(MY_PATIENTS, {
      state: { searchBarValue: searchBarValue },
    });
  };

  if (loading || !locale) return null;

  return (
    <div className="flex flex-col items-center px-5 pt-5 pb-[30px] desktop:p-[30px] gap-5 bg-white rounded-10">
      <div className="flex flex-col w-full items-start p-0 gap-2.5">
        <div className="flex flex-col desktop:flex-row w-full desktop:items-center desktop:justify-between p-0 gap-2.5">
          <h3 className="flex text-h4 text-dark-gray font-semibold">
            {locale?.ctaTitle}
          </h3>
        </div>
        <hr className="flex flex-row w-full items-center h-px bg-black-blur" />
      </div>
      <div className="flex flex-col w-full items-start gap-2.5 text-base text-dark-gray font-medium">
        <div className="flex desktop:flex-row mobile:flex-col gap-6 mobile:items-start desktop:items-center justify-around w-full">
          <p className="text-sm font-semibold desktop:w-1/6 mobile:w-full">
            {locale?.label}
          </p>
          <div className="flex h-full w-full mobile:w-full desktop:flex-row mobile:flex-col gap-4">
            <Input
              type="text"
              noMarginBottom
              value={searchBarValue}
              name="find"
              onChange={(e) => setSearchBarValue(e.target.value as string)}
              placeholder={locale?.placeholder}
            />
            <ButtonComponent
              type={'outlined'}
              onClick={handleSearchBarChange}
              disabled={searchBarValue === ''}
              desktopFullWidth={true}
              fullWidthClassName="desktop:w-1/4"
              Icon={SearchIcon}
              iconPosition="left"
            >
              {locale?.ctaButtonLabel1}
            </ButtonComponent>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FindPatientRecordSearchBar;
