import { Image_Source_Types_Enum } from 'graphql/generated/hasura';
import { ReactComponent as DesktopIcon } from 'assets/icons/desktop.svg';
import { ReactComponent as MobileIcon } from 'assets/icons/mobile.svg';
import { pageIds } from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';

interface ScanSourceProps {
  source: Image_Source_Types_Enum;
  hideText?: boolean;
}

const ScanSource: React.FC<ScanSourceProps> = ({ source, hideText }) => {
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.DASHBOARD,
  });

  if (loading && !locale) return null;

  return (
    <div className="flex flex-row w-full items-center justify-start p-0 gap-2.5">
      {!hideText && (
        <p className="text-base font-semibold text-dark-gray">
          {locale?.dermscore?.source}:
        </p>
      )}
      {source === Image_Source_Types_Enum.Pc ? (
        <DesktopIcon className="w-[20px] h-[16px] fill-current" />
      ) : (
        <MobileIcon className="w-[19px] h-[20px] fill-current" />
      )}
    </div>
  );
};

export default ScanSource;
