import Avatar from 'components/avatarComponent';
import React, { useEffect, useState } from 'react';
import { ReactComponent as WhiteCheckedProfileIcon } from 'assets/icons/white-checked-profile.svg';
import { ReactComponent as VideoIcon } from 'assets/icons/video-icon.svg';
import { ReactComponent as ChatIcon } from 'assets/icons/chat.svg';
import { ReactComponent as EmailGrayIcon } from 'assets/icons/email-gray.svg';
import { useGetPage } from 'hooks/useGetPage';
import { PARAM_MODALS_IDENTIFIERS, pageIds } from 'utilities/constants';
import {
  AppointmentTypeEnum,
  useGetProviderPatientSignedUrlMutation,
} from 'graphql/generated/hasura';
import { PatientsRelatedToProvider } from '../interfaces/patient.interface';
import AppointmentDetailModalProvider from 'components/appointmentDetailModal/AppointmentDetailModalProvider';
import { useNavigate } from 'react-router-dom';
import {
  MY_PATIENTS,
  MY_PATIENTS_BIO_INFO,
  MY_PATIENTS_BIO_INFO_APPOINTMENT_HISTORY,
  MY_PATIENTS_BIO_INFO_DERMSCORE_HISTORY,
  MY_PATIENTS_BIO_INFO_SHARED_MEDIA,
  MY_PATIENTS_BIO_INFO_TEST_KIT_RESULTS,
} from 'utilities/routes';

interface Props {
  className: string;
  patient: PatientsRelatedToProvider;
}

type RelatedInformation =
  | 'appointmentHistory'
  | 'dermscores'
  | 'testKitsResults'
  | 'sharedMediaText';

const MyPatientCard: React.FC<Props> = ({ className, patient }) => {
  const navigate = useNavigate();
  const [showProviderAppointmentModal, setShowProviderAppointmentModal] =
    useState<boolean>(false);

  const [patientsProfilePictureUrl, setPatientsProfilePictureUrl] =
    useState<string>('');

  const { data: locale, loading: localeLoading } = useGetPage({
    locale: 'en',
    pageId: pageIds.MY_PATIENTS,
  });

  const [getProviderPatientFileUrlFromStorage] =
    useGetProviderPatientSignedUrlMutation({});

  const renderAppointmentIconBasedOnType = (type: AppointmentTypeEnum) => {
    switch (type) {
      case AppointmentTypeEnum.Video:
        return <VideoIcon />;

      case AppointmentTypeEnum.Chat:
        return <ChatIcon />;

      case AppointmentTypeEnum.Email:
        return <EmailGrayIcon />;

      default:
        return <VideoIcon />;
    }
  };

  const onPatientNameClick = () =>
    navigate(`?${PARAM_MODALS_IDENTIFIERS.NEW_PATIENTS_REQUEST}=true`, {
      state: {
        id: patient.id,
        patientData: {
          age: patient.age.toString(),
          gender: patient.SENSITIVE_gender,
          genderIdentity: patient.SENSITIVE_self_identity_gender,
          height: `${patient.heightFt} ${locale.feet} ${patient.heightIn} ${locale.inches}`,
          hips: `${patient.waist} ${locale.inches}`,
          name: `${patient.SENSITIVE_firstname} ${patient.SENSITIVE_lastname}`,
          nationality: patient.SENSITIVE_etnicity,
          profilePicture: patientsProfilePictureUrl,
          waist: `${patient.waist} ${locale.inches}`,
        },
      },
    });
  const onAppointmentClick = (appoinmentCodexId: string) => {
    navigate(
      `?${PARAM_MODALS_IDENTIFIERS.APPOINTMENT_ID}=${appoinmentCodexId}`,
    );
    setShowProviderAppointmentModal(true);
  };
  const onRelatedInformationClick = (item: RelatedInformation) => {
    let patientDetailTab = '';
    switch (item) {
      case 'appointmentHistory':
        patientDetailTab = MY_PATIENTS_BIO_INFO_APPOINTMENT_HISTORY;
        break;
      case 'dermscores':
        patientDetailTab = MY_PATIENTS_BIO_INFO_DERMSCORE_HISTORY;
        break;
      case 'testKitsResults':
        patientDetailTab = MY_PATIENTS_BIO_INFO_TEST_KIT_RESULTS;
        break;
      case 'sharedMediaText':
        patientDetailTab = MY_PATIENTS_BIO_INFO_SHARED_MEDIA;
        break;
      default:
        patientDetailTab = MY_PATIENTS_BIO_INFO;
        break;
    }
    navigate(
      `${MY_PATIENTS}/${patient.id}/${MY_PATIENTS_BIO_INFO}/${patientDetailTab}`,
      { state: { patientId: patient.id } },
    );
  };

  useEffect(() => {
    const getPatientProfilePictureLink = async (id: string) => {
      const image = await getProviderPatientFileUrlFromStorage({
        variables: {
          patientCodexId: patient.id,
          fileId: id,
        },
      });
      setPatientsProfilePictureUrl(
        image.data?.GetProviderPatientSignedUrl?.url || '',
      );
    };
    if (patient.SENSITIVE_profile_picture_id) {
      getPatientProfilePictureLink(patient.SENSITIVE_profile_picture_id).catch(
        (error) =>
          console.error(
            `Something went wrong while trying to get the patient's profile picture: ${error}`,
          ),
      );
    }
  }, [
    patient.id,
    patient.SENSITIVE_profile_picture_id,
    getProviderPatientFileUrlFromStorage,
  ]);

  if (localeLoading || !locale) return null;

  return (
    <div className={`flex flex-col items-center bg-white p-10 ${className}`}>
      {showProviderAppointmentModal && <AppointmentDetailModalProvider />}
      <Avatar size="md" imageUrl={patientsProfilePictureUrl} />
      <div className="flex flex-col items-center mt-5 text-center">
        <div className="flex items-center">
          <p
            className="font-semibold text-h5 text-charcoal-gray underline hover:cursor-pointer"
            onClick={onPatientNameClick}
          >
            {patient.SENSITIVE_firstname} {patient.SENSITIVE_lastname}
          </p>
          {patient.kyc_approved && (
            <WhiteCheckedProfileIcon className="w-[14px] h-[14px] ml-1" />
          )}
        </div>
        <p className="font-medium text-med-gray text-h6">
          {patient.SENSITIVE_gender}, {patient.age} {locale.yearsOld}{' '}
          {patient.city}, {patient.state}
        </p>
      </div>
      {patient.appointments && patient.appointments.length > 0 && (
        <div className="flex mt-[30px] flex-col self-start desktop:mt-10">
          <p className="font-bold text-charcoal-gray text-sm">
            {locale.upcomingAppointment}
          </p>
          <ul className="flex flex-col mt-[6px] [&>li]:mt-2.5 desktop:[&>li]:mt-0">
            {patient.appointments.map((appointment, index) => (
              <li key={index} className="flex items-center">
                {renderAppointmentIconBasedOnType(appointment.appointmentType)}
                <span
                  className="ml-[5px] text-clc-blue underline font-bold text-base hover:cursor-pointer"
                  onClick={() =>
                    onAppointmentClick(appointment.appointmentCodexId)
                  }
                >
                  {appointment.start}
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}

      <div className="font-bold mt-[30px] self-start text-charcoal-gray text-sm desktop:mt-10">
        <p className="font-bold text-sm text-charcoal-gray">
          {locale.relatedInformation}
        </p>
        <ul className="mt-[5px] text-clc-blue font-bold text-h6 flex flex-col list-disc px-6 [&>*]:mt-2.5 desktop:[&>*]:mt-0">
          <li
            className="hover:cursor-pointer"
            onClick={() => onRelatedInformationClick('appointmentHistory')}
          >
            {locale.appointmentHistory}
          </li>
          <li
            className="hover:cursor-pointer"
            onClick={() => onRelatedInformationClick('dermscores')}
          >
            {locale.dermscores}
          </li>
          <li
            className="hover:cursor-pointer"
            onClick={() => onRelatedInformationClick('testKitsResults')}
          >
            {locale.testKitsResults}
          </li>
          <li
            className="hover:cursor-pointer"
            onClick={() => onRelatedInformationClick('sharedMediaText')}
          >
            {locale.sharedMediaText}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MyPatientCard;
