import Loader from 'components/loaderComponent';
import { useGetPatientsQuery } from 'graphql/generated/hasura';
import { Fragment, useMemo, useState } from 'react';
import { makeTableComponent } from 'app/superadmin/components/DataTable';
import Tooltip from 'components/Tooltip';
import { renderers } from 'app/superadmin/defaultRenderers';
import { Search } from '../components/Search';
import ButtonComponent from 'components/button/buttonComponent';
import { useNavigate } from 'react-router-dom';
import {
  SUPER_ADMIN,
  SUPER_ADMIN_APPOINTMENTS,
  SUPER_ADMIN_TESTS,
} from 'utilities/routes';
import { getIdentifier } from '../utils';

const nameRenderer = (p: PatientsDataType) => {
  const name = (p ? `${p?.last ?? ''}, ${p?.first ?? ''}` : '-').trim();

  if (name.length > 40) {
    return <Tooltip text={name}>{name.substring(0, 40)} [..]</Tooltip>;
  } else {
    return <>{name}</>;
  }
};

type PatientsDataType = {
  __typename?: 'SAPatient';
  appointmentCount: Array<Array<string>>;
  testKitCount: number;
  identifiers: Array<Array<string>>;
  first: string;
  last: string;
  dates: Array<Array<string>>;
  nextAppointment?: {
    __typename?: 'SAAppointmentBrief';
    identifiers: Array<Array<string>>;
    dates: Array<Array<string>>;
  } | null;
};

export const Patients = () => {
  const [search, setSearch] = useState('');
  const { loading, data } = useGetPatientsQuery({
    variables: {
      search,
    },
  });
  const navigate = useNavigate();

  const Table = useMemo(() => makeTableComponent<PatientsDataType>(), []);

  if (loading) return <Loader />;

  return (
    <div>
      Patients ({data?.getPatients.count} of {data?.getPatients.total})
      <br />
      <Search text={search} onSearch={setSearch} />
      <hr />
      {data?.getPatients.patients.length && (
        <Table
          data={data?.getPatients.patients}
          getKey={(item) =>
            item.identifiers.find((id) => id[0] === 'fhir')?.[1] ?? 'never'
          }
        >
          <Table.KeyColumn dataKey="identifiers" />
          <Table.DynamicColumn title="name" renderer={nameRenderer} />
          <Table.KeyColumn
            dataKey="appointmentCount"
            renderer={(items: [string, string][], item: PatientsDataType) => (
              <>
                {items.map(([type, count]) => (
                  <Fragment key={type}>
                    {type}:{' '}
                    <ButtonComponent
                      type="underline"
                      className="w-auto"
                      onClick={() =>
                        navigate(
                          `${SUPER_ADMIN}${SUPER_ADMIN_APPOINTMENTS}/patient/${getIdentifier(
                            item,
                            'fhir',
                          )}/status/${type}`,
                        )
                      }
                    >
                      {count}
                    </ButtonComponent>
                    <br />
                  </Fragment>
                ))}
                {items.length && (
                  <ButtonComponent
                    type="underline"
                    className="w-auto"
                    onClick={() =>
                      navigate(
                        `${SUPER_ADMIN}${SUPER_ADMIN_APPOINTMENTS}/patient/${getIdentifier(
                          item,
                          'fhir',
                        )}`,
                      )
                    }
                  >
                    view all
                  </ButtonComponent>
                )}
              </>
            )}
          />
          <Table.KeyColumn
            dataKey="nextAppointment"
            renderer={(brief) =>
              !!brief && (
                <>
                  {renderers['identifiers'](brief.identifiers)}
                  <hr />
                  {renderers['dates'](brief.dates)}
                </>
              )
            }
          />
          <Table.KeyColumn
            dataKey="testKitCount"
            renderer={(value, item) => (
              <ButtonComponent
                type="underline"
                onClick={() =>
                  navigate(
                    `${SUPER_ADMIN}${SUPER_ADMIN_TESTS}/patient/${getIdentifier(
                      item,
                      'fhir',
                    )}`,
                  )
                }
              >
                {value}
              </ButtonComponent>
            )}
          />
          <Table.KeyColumn dataKey="dates" />
        </Table>
      )}
      {!data?.getPatients.patients.length && <>No Records Found</>}
    </div>
  );
};
