import { useGetFrontAndBackDots } from 'app/my-skin/components/BodySymtomsLocation/hooks';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useGetPage } from './useGetPage';
import { pageIds } from 'utilities/constants';
import { useUploadFileToStorageMutation } from 'graphql/generated/hasura';

import { AlertState } from 'app/my-appointments/interfaces';
import {
  Dot,
  ImageObject,
} from 'app/my-skin/components/BodySymtomsLocation/interfaces';

export const AllowedFormats: string[] = [
  'image/jpeg',
  'image/png',
  'image/bmp',
  'image/tiff',
  'image/gif',
];

export function useGetFrontAndBackDotsWithMediaSync(
  setAlert: React.Dispatch<React.SetStateAction<AlertState | undefined>>,
) {
  const { data: locale } = useGetPage({
    pageId: pageIds.APPOINTMENT_PREWORK,
    locale: 'en',
  });
  const [mediaPerBodyLocation, setMediaPerBodyLocation] = useState<
    Map<string, ImageObject>
  >(new Map());
  const { backDots, frontDots, setBackDots, setFrontDots } =
    useGetFrontAndBackDots();
  const [uploadFile, { loading: isMediaSaving }] =
    useUploadFileToStorageMutation({});
  const mediaSyncOn = useRef<boolean>(true);
  const locationToUploadMedia = useRef<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleImageUpload = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files && event.target.files[0];
      event.target.value = '';
      if (!file) return;
      if (
        !file ||
        !AllowedFormats.includes(file.type) ||
        !locationToUploadMedia.current
      ) {
        setAlert({
          message: locale?.errorUploadingMedia,
          type: 'negative',
        });
        return;
      }
      const buffer = await file.arrayBuffer();
      const result = await uploadFile({
        variables: {
          file: {
            mimetype: file.type,
            originalname: file.name,
            buffer: Array.from(new Uint8Array(buffer)),
          },
        },
      });
      const url = result.data?.UploadFileToStorage?.file?.url;
      const mediaId = result.data?.UploadFileToStorage?.file?.fileId;
      if ((result.errors && result.errors.length) || !url || !mediaId) {
        setAlert({
          message: locale?.errorUploadingMedia,
          type: 'negative',
        });
        return;
      }

      setMediaPerBodyLocation((prev) => {
        const newMap = new Map(prev);
        newMap.set(locationToUploadMedia.current as string, {
          image: url,
          mediaId,
        });
        return newMap;
      });
    },
    [uploadFile, setAlert, locale?.errorUploadingMedia],
  );

  const UploadInputComponent = useMemo<React.FC>(
    () => () =>
      (
        <input
          type="file"
          id="myImage"
          onChange={handleImageUpload}
          ref={fileInputRef}
          className="hidden"
        />
      ),
    [handleImageUpload, fileInputRef],
  );

  const {
    backDots: backDotsAfterMedia,
    frontDots: frontDotsAfterMedia,
    setBackDots: setBackDotsAfterMedia,
    setFrontDots: setFrontDotsAfterMedia,
  } = useGetFrontAndBackDots();
  useEffect(() => {
    const currentDots = new Set(
      [...frontDotsAfterMedia, ...backDotsAfterMedia]
        .filter((dot) => !!dot.location && dot.selected)
        .map((dot) => dot.location),
    );
    const toAddDots = [...mediaPerBodyLocation.keys()].filter(
      (mediaLocation) => !currentDots.has(mediaLocation),
    );
    const toRemoveDots = [...currentDots].filter(
      (dot) => dot && !mediaPerBodyLocation.has(dot),
    );
    if (!(toAddDots.length + toRemoveDots.length)) {
      return;
    }
    const newFrontDots = frontDots.map((dot) => {
      const selected =
        dot.selected && mediaPerBodyLocation.has(dot.location as string);
      return {
        ...dot,
        selected,
      };
    });
    const newBackDots = backDots.map((dot) => {
      const selected =
        dot.selected && mediaPerBodyLocation.has(dot.location as string);
      return {
        ...dot,
        selected,
      };
    });
    setFrontDotsAfterMedia(newFrontDots);
    setBackDotsAfterMedia(newBackDots);
  }, [
    mediaPerBodyLocation,
    frontDots,
    backDots,
    setFrontDotsAfterMedia,
    setBackDotsAfterMedia,
    backDotsAfterMedia,
    frontDotsAfterMedia,
  ]);

  useEffect(() => {
    if (!mediaSyncOn.current) return;
    const selectedDots = [...frontDots, ...backDots].filter(
      (dot) => dot.selected,
    );
    const selectedAfterMediaDots = [
      ...frontDotsAfterMedia,
      ...backDotsAfterMedia,
    ].filter((dot) => dot.selected);
    const afterMediaSet = new Set(
      selectedAfterMediaDots.map((dot) => dot.location),
    );
    const beforeMedia = new Set(selectedDots.map((dot) => dot.location));
    const addedDots = selectedDots.filter(
      (dot) => !afterMediaSet.has(dot.location),
    );
    const removedDots = selectedAfterMediaDots.filter(
      (dot) => !beforeMedia.has(dot.location),
    );
    if (removedDots.length) {
      setMediaPerBodyLocation((prev) => {
        const newMap = new Map(prev);
        removedDots.forEach((dot) => newMap.delete(dot.location as string));
        return newMap;
      });
    }
    if (addedDots.length) {
      locationToUploadMedia.current = addedDots[0].location as string;
      fileInputRef.current?.click();
    }
  }, [backDots, frontDots, backDotsAfterMedia, frontDotsAfterMedia]);

  const updateMediaAndDotsWithoutSync = useCallback(
    (
      frontDots: Parameters<React.Dispatch<React.SetStateAction<Dot[]>>>[0],
      backDots: Parameters<React.Dispatch<React.SetStateAction<Dot[]>>>[0],
      media: Parameters<
        React.Dispatch<React.SetStateAction<Map<string, ImageObject>>>
      >[0],
    ) => {
      mediaSyncOn.current = false;
      setFrontDots(frontDots);
      setBackDots(backDots);
      setMediaPerBodyLocation(media);
    },
    [setFrontDots, setBackDots, setMediaPerBodyLocation],
  );

  useEffect(() => {
    if (mediaPerBodyLocation.size && (frontDots.length || backDots.length))
      mediaSyncOn.current = true;
  }, [mediaPerBodyLocation, frontDots, backDots]);

  return {
    backDots: backDotsAfterMedia,
    frontDots: frontDotsAfterMedia,
    UploadInputComponent,
    isMediaSaving,
    mediaPerBodyLocation,
    setFrontDots,
    setBackDots,
    updateMediaAndDotsWithoutSync,
  };
}
