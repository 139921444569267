import { AuthContext } from 'auth/context/AuthContext';
import { Roles } from '../../../../firebase/interfaces';
import { useContext, useEffect } from 'react';
import { ProviderModule } from './modules/ProviderModule';
import { useNavigate } from 'react-router-dom';
import { DASHBOARD } from 'utilities/routes';

export const AccountSettings: React.FC = () => {
  const navigate = useNavigate();
  const { user: loggedUser } = useContext(AuthContext);

  useEffect(() => {
    if (loggedUser?.role === Roles.PATIENT) {
      navigate(DASHBOARD);
    }
  }, [navigate, loggedUser]);
  return <div>{loggedUser?.role === Roles.PROVIDER && <ProviderModule />}</div>;
};
