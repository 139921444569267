import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CodexFeatureFlags } from 'utilities/interfaces';
import {
  BASE_ROUTE,
  BASE_ROUTES,
  DASHBOARD,
  MY_PATIENTS_BIO_INFO_ROUTES,
} from 'utilities/routes';
import { useContext, useEffect } from 'react';
import { PatientBioInfo } from '../pages/PatientBioInfo';
import MyPatients from '../pages/MyPatients';
import { AuthContext } from 'auth/context/AuthContext';
import { Roles } from '../../../firebase/interfaces';

export const MyPatientsRoutes = () => {
  const { user } = useContext(AuthContext);
  const { myPatientsRoutesDisabled } = useFlags<CodexFeatureFlags>();
  const navigate = useNavigate();

  useEffect(() => {
    if (myPatientsRoutesDisabled || user?.role === Roles.PATIENT) {
      navigate(DASHBOARD);
    }
  }, [myPatientsRoutesDisabled, navigate, user]);

  return (
    <Routes>
      <Route path={BASE_ROUTE} element={<MyPatients />} />
      <Route path={MY_PATIENTS_BIO_INFO_ROUTES} element={<PatientBioInfo />} />
      <Route path={BASE_ROUTES} element={<Navigate to={BASE_ROUTE} />} />
    </Routes>
  );
};
