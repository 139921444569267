import { AppointmentTypeEnum } from 'graphql/generated/remote-schema-hasura';
import { ReactComponent as VideoIconTab } from 'assets/icons/appointmentsIcons/videoIcon.svg';
import { ReactComponent as ChatIconTab } from 'assets/icons/appointmentsIcons/chatIcon.svg';
import { ReactComponent as VideoIconTabSelected } from 'assets/icons/appointmentsIcons/videoIconSelected.svg';
import { ReactComponent as ChatIconTabSelected } from 'assets/icons/appointmentsIcons/chatIconSelected.svg';
import { PreworkSolidIcon } from 'assets/icons/PreworkSolidIcon';
import { PreworkOutlineIcon } from 'assets/icons/PreworkOutlineIcon';
import { MyNotesOutlineIcon } from 'assets/icons/MyNotesOutlineIcon';
import { MyNotesSolidIcon } from 'assets/icons/MyNotesSolidIcon';
import { Roles } from '../../../../firebase/interfaces';
import IconComponent from 'components/iconComponent';
import { UserData } from 'auth/context/AuthContext';

export const initializeTabsState = (
  appointmentType: string,
  loggedUser: UserData | null,
) => {
  const tabs =
    appointmentType === AppointmentTypeEnum.Chat
      ? [
          {
            icon: <ChatIconTab />,
            iconSelected: <ChatIconTabSelected />,
            isSelected: true,
          },
          {
            icon: (
              <IconComponent
                width="w-[17px]"
                height="h-5"
                icon={PreworkOutlineIcon}
                fill="fill-charcoal-gray"
              />
            ),
            iconSelected: (
              <IconComponent
                width="w-[17px]"
                height="h-5"
                icon={PreworkSolidIcon}
                fill="fill-clc-blue"
              />
            ),
            isSelected: false,
          },
          {
            icon: (
              <IconComponent
                width="w-[17px]"
                height="h-5"
                icon={MyNotesOutlineIcon}
                fill="fill-charcoal-gray"
              />
            ),
            iconSelected: (
              <IconComponent
                width="w-[17px]"
                height="h-5"
                icon={MyNotesSolidIcon}
                fill="fill-clc-blue"
              />
            ),
            isSelected: false,
          },
        ]
      : [
          {
            icon: <VideoIconTab />,
            iconSelected: <VideoIconTabSelected />,
            isSelected: true,
          },
          {
            icon: (
              <IconComponent
                width="w-[17px]"
                height="h-5"
                icon={PreworkOutlineIcon}
                fill="fill-charcoal-gray"
              />
            ),
            iconSelected: (
              <IconComponent
                width="w-[17px]"
                height="h-5"
                icon={PreworkSolidIcon}
                fill="fill-clc-blue"
              />
            ),
            isSelected: false,
          },
          {
            icon: <ChatIconTab />,
            iconSelected: <ChatIconTabSelected />,
            isSelected: false,
          },
          {
            icon: (
              <IconComponent
                width="w-[17px]"
                height="h-5"
                icon={MyNotesOutlineIcon}
                fill="fill-charcoal-gray"
              />
            ),
            iconSelected: (
              <IconComponent
                width="w-[17px]"
                height="h-5"
                icon={MyNotesSolidIcon}
                fill="fill-clc-blue"
              />
            ),
            isSelected: false,
          },
        ];
  if (loggedUser?.role === Roles.PATIENT) {
    tabs.pop();
  }

  return tabs;
};
