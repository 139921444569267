import { ModalButtonProps } from 'components/modal/modal.interfaces';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import { genericActionsIds, pageIds } from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from 'components/modal/modalComponent';
import ButtonComponent from 'components/button/buttonComponent';
import AvatarComponent from 'components/avatarComponent';
import { interpolateVariables } from 'utilities/functions';
import {
  MY_PATIENTS,
  MY_PATIENTS_BIO_INFO,
  MY_PATIENTS_BIO_INFO_PATIENT_INFO,
} from 'utilities/routes';

export const NewPatientRequestModal: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const id = location.state?.id;
  const patientData = location.state?.patientData;

  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.DASHBOARD,
  });

  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.CLOSE],
    });

  const handleClose = () => {
    navigate(-1);
  };

  const handleViewFullPatientProfile = () => {
    navigate(
      `${MY_PATIENTS}/${id}/${MY_PATIENTS_BIO_INFO}/${MY_PATIENTS_BIO_INFO_PATIENT_INFO}`,
      { state: { patientId: id } },
    );
  };

  const Buttons: ModalButtonProps[] = [
    {
      label: genericAction?.[genericActionsIds.CLOSE].close,
      onClick: handleClose,
      paddingX: 'px-[3.3rem]',
    },
  ];

  if (
    loading ||
    !locale ||
    genericActionLoading ||
    !genericAction ||
    !patientData ||
    !id
  ) {
    return null;
  }

  return (
    <Modal
      isOpen={true}
      title={locale?.meetYourNewPatient}
      modalDesktopWidth="desktop:max-w-[600px]"
      disableMobileGrow={true}
      buttons={Buttons}
      onClose={handleClose}
      modalContainerCustomClass="w-full"
      contentPaddingX="px-2.5"
    >
      <div className="flex flex-col gap-5 desktop:gap-[60px] desktop:flex-row">
        <div className="px-[40px] desktop:pb-[20px] flex flex-col py-2.5 desktop:min-h-[450px] desktop:flex-1 justify-center items-center">
          <div>
            <AvatarComponent
              imageUrl={patientData.profilePicture ?? ''}
              size="lg"
            />
          </div>
          <div>
            <p className="font-semibold text-charcoal-gray text-h5 text-center pt-[30px] pb-5">
              {interpolateVariables(locale.congratulationsNewPatient, {
                name: patientData.name ?? '',
              })}
            </p>
            <p className="text-center font-medium text-med-gray pb-[30px]">
              {locale?.congratulationsNewPatientParagraph}
            </p>
          </div>
          <div className="w-full pb-[30px]">
            <div className="w-full desktop:px-[64px] flex flex-row">
              <div className="w-full desktop:w-2/4">
                <div className="flex flex-col desktop:order-0">
                  <p className="text-base font-semibold text-med-gray">
                    {patientData.age}
                  </p>
                </div>
                <div className="flex flex-col desktop:order-2">
                  <p className="text-base font-semibold text-med-gray">
                    {patientData.nationality}
                  </p>
                </div>
                <div className="flex flex-col desktop:order-4">
                  <p className="text-base font-semibold text-med-gray">
                    {interpolateVariables(locale.gender, {
                      gender: patientData.gender ?? '',
                      identityGender: patientData.genderIdentity ?? '',
                    })}
                  </p>
                </div>
              </div>
              <div className="w-full desktop:w-2/4">
                <div className="flex flex-col desktop:order-1">
                  <p className="text-base font-semibold text-med-gray">
                    {locale.height}
                    {patientData.height}
                  </p>
                </div>
                <div className="flex flex-col desktop:order-3">
                  <p className="text-base font-semibold text-med-gray">
                    {locale.waist}
                    {patientData.waist}
                  </p>
                </div>
                <div className="flex flex-col desktop:order-5">
                  <p className="text-base font-semibold text-med-gray">
                    {locale.hips}
                    {patientData.hips}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <ButtonComponent
            type="outlined-transparent"
            className="max-w-[240px]"
            onClick={handleViewFullPatientProfile}
          >
            {locale.viewFullProfile}
          </ButtonComponent>
        </div>
      </div>
    </Modal>
  );
};
