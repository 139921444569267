// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable one-var, no-underscore-dangle, no-use-before-define, no-param-reassign */
/* eslint-disable max-len, no-var, vars-on-top, global-require */
import promiseTimeout from 'p-timeout';
import assign from 'lodash/assign';
import cloneDeep from 'lodash/cloneDeep';
import intersection from 'lodash/intersection';
import debounceDefault from 'lodash/debounce';
import omit from 'lodash/omit';
import uuid from 'uuid';
import eventing from '../../helpers/eventing';
import createLogger from '../../helpers/log';
import envDefault from '../../helpers/env';
import DefaultExceptionCodes from '../exception_codes';
import OTErrorClassDefault from '../ot_error_class';
import OTHelpersError from '../../common-js-helpers/error';
import createCleanupJobs from '../../helpers/createCleanupJobs';
import SDPHelpers from './sdp_helpers';
import shouldUsePlanBSDP from '../../helpers/shouldUsePlanBSDP';
import extractSenderId from '../../helpers/extract-sender-id';
import hasValidPeerConnectionDefault from '../../helpers/hasValidPeerConnection';
import getApplySdpTransform from './applySdpTransform';
import getCreatePeerConnection from '../../helpers/createPeerConnection';
import getSDPTransformDefaults from './sdpTransformDefaults';
import getGetStatsAdapter from './getStatsAdapter';
import getGetRtcStatsReportAdapter from './get_rtc_stats_report_adapter';
import getGetSynchronizationSources from './get_synchronization_sources';
import getIceCandidateProcessor from './ice_candidate_processor';
import getOfferProcessor from './offer_processor';
import getPeerConnectionChannels from './peer_connection_channels';
import getSubscribeProcessor from './subscribe_processor';
import getOfferAnswerProcessor from './offerAnswerProcessor';
import getChangeMediaDirection from './change-media-direction';
import qosDefault from './qos/Qos';
import getNeedsToSwapH264Profiles from '../../helpers/needsToSwapH264Profiles';
import getErrors from '../Errors';
import getHasPictureInPictureBug from '../../helpers/hasPictureInPictureBug';
import now from '../../helpers/now';

export default function PeerConnectionFactory(deps = {}) {
  const hasValidPeerConnection =
    deps.hasValidPeerConnection || hasValidPeerConnectionDefault;
  const applySdpTransform = deps.applySdpTransform || getApplySdpTransform;
  const createPeerConnection = deps.createPeerConnection || getCreatePeerConnection;
  const env = deps.env || envDefault;
  const sdpTransformDefaults = deps.sdpTransformDefaults || getSDPTransformDefaults;
  const getStatsAdapter = deps.getStatsAdapter || getGetStatsAdapter;
  const getRtcStatsReportAdapter = deps.getRtcStatsReportAdapter || getGetRtcStatsReportAdapter;
  const getSynchronizationSources = deps.getSynchronizationSources || getGetSynchronizationSources;
  const IceCandidateProcessor = deps.IceCandidateProcessor || getIceCandidateProcessor;
  const createLog = deps.logging || createLogger;
  const offerProcessor = deps.offerProcessor || getOfferProcessor;
  const PeerConnectionChannels = deps.PeerConnectionChannels || getPeerConnectionChannels;
  const subscribeProcessor = deps.subscribeProcessor || getSubscribeProcessor;
  const OfferAnswerProcessor = getOfferAnswerProcessor;
  const {
    changeMediaDirectionToInactive: deactivateMedia,
    changeMediaDirectionToRecvOnly: reactivateMedia,
  } = deps.changeMediaDirection || getChangeMediaDirection;
  const Qos = deps.Qos || qosDefault;
  const windowMock = deps.global || global;
  const debounce = deps.debounce || debounceDefault;
  const needsToSwapH264Profiles = deps.needsToSwapH264Profiles || getNeedsToSwapH264Profiles.once;

  const futureIsPeerConnectionValid = () => hasValidPeerConnection(windowMock.RTCPeerConnection);

  const NativeRTCIceCandidate = deps.NativeRTCIceCandidate || windowMock.RTCIceCandidate;
  const NativeRTCSessionDescription = deps.NativeRTCSessionDescription || windowMock.RTCSessionDescription;
  const Errors = deps.Errors || getErrors;
  const OTErrorClass = deps.OTErrorClass || OTErrorClassDefault;
  const ExceptionCodes = deps.ExceptionCodes || DefaultExceptionCodes;
  const hasPictureInPictureBug = deps.hasPictureInPictureBug || getHasPictureInPictureBug;
  // Unified-plan does not transition to failed. Plan-b used to transition after 10 secs (empirically measured)
  const DISCONNECT_TO_FAILED_TIMEOUT = 10000;

  // Helper function to forward Ice Candidates via +sendMessage+
  const iceCandidateForwarder = ({ sendMessage, logging }) => {
    const sdpMids = {};
    return (event) => {
      if (event.candidate) {
        // It would be better to read the mids from the SDP
        sdpMids[event.candidate.sdpMid] = event.candidate.sdpMLineIndex;
        sendMessage('candidate', {
          candidate: event.candidate.candidate,
          sdpMid: event.candidate.sdpMid || '',
          sdpMLineIndex: event.candidate.sdpMLineIndex || 0,
        });
      } else {
        logging.debug('IceCandidateForwarder: No more ICE candidates.');
      }
    };
  };

  const noop = () => {};

  /*
  * Negotiates a WebRTC PeerConnection.
  *
  * Responsible for:
  * * offer-answer exchange
  * * iceCandidates
  * * notification of remote streams being added/removed
  *
  */
  return function PeerConnection(options = {}) {
    let hasRelayCandidates = false;
    const {
      iceConfig = { servers: [] },
      sendMessage: originalSendMessage,
      isPublisher,
      offerOverrides,
      answerOverrides,
      p2p,
      codecFlags,
      sourceStreamId,
      keyStore,
      sFrameClientStore,
      isE2ee,
      removeUnusedCodecs,
      sessionId,
    } = options;
    // Following block can be modified by subsequent actors when SPC. Please see: addOptions
    // eslint-disable-next-line prefer-const
    let {
      logAnalyticsEvent = noop,
      remoteConnectionId,
    } = options;
    let processingOffer = false; // Whether we are currently processing an offer
    let pendingOfferMessage; // An offer we received that is pending waiting on a previous offer
    const replaceBaselineProfile = needsToSwapH264Profiles();
    let offerMessagesReceived = 0; // number of offer or generateoffer messages received
    let renegotiationInProgress = false;
    let sFrameReceiverClient;
    let sFrameSenderClient;
    let sFrameSenderId;
    let keyStoreChangeHandler;
    const offerAnswerProcessor = new OfferAnswerProcessor(logAnalyticsEvent);

    const cleanupJobs = createCleanupJobs();

    function sendMessage(type, payload) {
      if (!hasRelayCandidates) {
        const shouldCheckForRelayCandidates = [
          'candidate',
          'offer',
          'answer',
          'pranswer',
        ].indexOf(type) > -1;

        if (shouldCheckForRelayCandidates) {
          const message = type === 'candidate' ? payload.candidate : payload.sdp;
          hasRelayCandidates = message.indexOf('typ relay') !== -1;
        }
      }

      originalSendMessage(type, payload);
    }

    const startConnectingTime = now();

    logAnalyticsEvent('createPeerConnection', 'Attempt');

    const api = {};
    api.id = uuid();
    const logging = createLog(`PeerConnection:${api.id}`);

    logging.debug('construct', { id: api.id, options });

    const sdpTransforms = sdpTransformDefaults;

    const shouldFilterCandidate = candidate => (
      iceConfig.transportPolicy === 'relay' &&
      candidate != null &&
      candidate.candidate.indexOf('typ relay') === -1
    );

    const config = omit(options, [
      'isPublisher',
      'logAnalyticsEvent',
      'offerOverrides',
      'answerOverrides',
      'sendMessage',
    ]);

    let _peerConnection,
      _channels,
      _offer,
      _answer,
      _transitionToFailedTimeOut;

    let _peerConnectionCompletionHandlers = [];

    const _simulcastEnabled = (() => {
      let value = config.overrideSimulcastEnabled || false;

      return {
        get() { return value; },
        set(newValueParam) {
          const newValue = Boolean(newValueParam) && config.capableSimulcastStreams > 1;

          if (newValue !== value && config.overrideSimulcastEnabled === undefined) {
            value = newValue;
            api.generateOfferAndSend();
          }
        },
      };
    })();

    const _createOfferWithIceRestart = () => {
      if (!api.iceConnectionStateIsConnected()) {
        api.generateOfferAndSend();
      } else {
        logging.debug('iceRestart is going to wait until we disconnect or negotiationNeeded' +
         ' and then restart ice');
      }
    };

    const _iceRestartNeeded = (() => {
      let value = false;

      return {
        get() { return value; },
        set(newValueParam) {
          const newValue = Boolean(newValueParam);

          if (newValue !== value) {
            value = newValue;

            if (value) {
              _createOfferWithIceRestart();
            }
          }
        },
      };
    })();

    const _readyToCompleteOffer = {
      clean() {
        delete this.promise;
        delete this.resolve;
        delete this.reject;
      },
    };

    _readyToCompleteOffer.promise = new Promise((resolve, reject) => {
      _readyToCompleteOffer.resolve = resolve;
      _readyToCompleteOffer.reject = reject;
    });

    let _iceProcessor = new IceCandidateProcessor();

    let _state = 'new';

    Object.defineProperty(
      api,
      'signalingState',
      {
        get() {
          return _peerConnection.signalingState;
        },
        set(val) {
          // obviously they should not be doing this, but we'll mimic what the browser does.
          _peerConnection.signalingState = val;
          return val;
        },
      }
    );

    eventing(api);

    api.addOptions = (opt) => {
      ({ remoteConnectionId, logAnalyticsEvent } = opt);
    };

    api.startEncryption = async (connectionId) => {
      if (!isE2ee) {
        return;
      }

      sFrameSenderId = await extractSenderId({
        sessionId,
        connectionId,
        useDeprecatedMethod: !sFrameClientStore?.isStandard,
      });

      // Create an sframe sender client, which is used for encrypting the video and audio of
      // a publisher before sending it to the other side.
      sFrameSenderClient = await sFrameClientStore.createSender(sFrameSenderId);

      // Generate an encryption key based on the session ID. This key will be also needed
      // for decrypting on the subscriber side.
      const sharedKey = await keyStore.get();
      await sFrameSenderClient.setSenderEncryptionKey(sharedKey);

      keyStoreChangeHandler = (key) => { sFrameSenderClient.setSenderEncryptionKey(key); };
      keyStore.on('keyChanged', keyStoreChangeHandler);

      // SFrame uses the RTCRtpSender objects (transceiver.sender) to extract the video
      // and audio streams from the publisher and then encrypt them.
      _peerConnection.getTransceivers().forEach(async (transceiver) => {
        await sFrameSenderClient.encrypt(sFrameSenderId, transceiver.sender);
      });
    };

    api.once('iceConnected', async () => {
      const proxyInfo = '';
      const payload = {
        pcc: parseInt(now() - startConnectingTime, 10),
        hasRelayCandidates,
        proxyInfo,
      };
      if (_peerConnection && _peerConnection.proxyInfo) payload.proxyInfo = _peerConnection.proxyInfo;

      logAnalyticsEvent('createPeerConnection', 'Success', payload);
    });

    // Standard SFrame requires the peer connection to be in "connected" or
    // "connecting" state.  The `decrypt` method will throw an "invalid state"
    // exception otherwise.
    if (sFrameClientStore?.isStandard) {
      api.on('iceConnected', () => {
        _peerConnection.getReceivers().forEach((receiver) => {
          sFrameReceiverClient?.decrypt(sFrameSenderId, receiver);
        });
      });
    }

    _readyToCompleteOffer.resolve();

    // List of SFrame clients pending or created
    const sFrameSenderIds = [];

    const createSFrameReceiverClient = async () => {
      const sharedKey = await keyStore.get();

      sFrameSenderId = await extractSenderId({
        sessionId,
        connectionId: remoteConnectionId,
        useDeprecatedMethod: !sFrameClientStore?.isStandard,
      });

      // Standard SFrame may send multiple requests.  If there's a request
      // already inflight, then we bail
      if (sFrameSenderIds.includes(sFrameSenderId)) {
        return;
      }

      // This is the first request for this connection.  Add it to the
      // list, to prevent trying similar requests for the same connection.
      sFrameSenderIds.push(sFrameSenderId);

      sFrameReceiverClient = sFrameClientStore.getReceiver(sFrameSenderId);
      if (!sFrameReceiverClient) {
        sFrameReceiverClient = await sFrameClientStore.createReceiver(sFrameSenderId);
        sFrameReceiverClient.addReceiver(sFrameSenderId);
      }

      sFrameReceiverClient.setReceiverEncryptionKey(sFrameSenderId, sharedKey);
      keyStore.on('keyChanged', (key) => {
        sFrameReceiverClient.setReceiverEncryptionKey(sFrameSenderId, key);
      });
      sFrameReceiverClient.addEventListener('decryptFailed', () => {
        api.trigger('decryptFailed');
      });
      sFrameReceiverClient.addEventListener('decryptionRestored', () => {
        api.trigger('decryptRestored');
      });
    };

    // Create and initialise the PeerConnection object. This deals with
    // any differences between the various browser implementations and
    // our own OTPlugin version.
    //
    // +completion+ is the function is call once we've either successfully
    // created the PeerConnection or on failure.
    //
    const internalCreatePeerConnection = (completion) => {
      logging.debug('internalCreatePeerConnection: called');

      if (_peerConnection) {
        logging.debug('internalCreatePeerConnection: resolving synchronously');
        completion.call(null, null, _peerConnection);
        return;
      }

      _peerConnectionCompletionHandlers.push(completion);

      if (_peerConnectionCompletionHandlers.length > 1) {
        // The PeerConnection is already being setup, just wait for
        // it to be ready.
        return;
      }

      logging.debug(`Creating peer connection config "${JSON.stringify(config)}".`);

      if (iceConfig.servers.length === 0) {
        // This should never happen unless something is misconfigured
        logging.error('No ice servers present');
        logAnalyticsEvent('Error', 'noIceServers');
      }

      if (iceConfig.transportPolicy === 'relay') {
        const isTurnUrl = url => (url && url.indexOf('turn') === 0);

        iceConfig.servers = iceConfig.servers
          .map((providedServer) => {
            const server = cloneDeep(providedServer);
            if (!Array.isArray(server.urls)) {
              server.urls = [server.urls];
            }
            server.urls = server.urls.filter(isTurnUrl);
            return server.urls.length === 0 ? undefined : server;
          })
          .filter(server => server !== undefined);
      }

      config.iceTransportPolicy = iceConfig.transportPolicy;

      config.iceServers = iceConfig.servers;
      if (isE2ee) {
        // Let's activate the required properties to enable Insertable Streams.
        config.forceEncodedVideoInsertableStreams = true;
        config.forceEncodedAudioInsertableStreams = true;
        config.encodedInsertableStreams = true;
      }

      futureIsPeerConnectionValid()
        .then((isValid) => {
          if (!isValid) {
            logging.error('createPeerConnection: Invalid RTCPeerConnection object');
            throw new Error('Failed to create valid RTCPeerConnection object');
          }

          return createPeerConnection({
            window: windowMock,
            config,
          });
        })
        .then(
          async (pc) => {
            if (!isPublisher && isE2ee && !sFrameClientStore?.isStandard) {
              // Note: we can remove this once legacy SFrame is no longer
              // supported.  Reason being, Vonage SFrame requires peer connections
              // be in state "connecting" or "connected".  Legacy SFrame doesn't.
              await createSFrameReceiverClient();
            }

            attachEventsToPeerConnection(null, pc);
          },
          err => attachEventsToPeerConnection(err)
        );
    };

    // An auxiliary function to internalCreatePeerConnection. This binds the various event
    // callbacks once the peer connection is created.
    //
    // +err+ will be non-null if an err occured while creating the PeerConnection
    // +pc+ will be the PeerConnection object itself.
    //
    // @todo if anything called in attachEventsToPeerConnection throws it will be
    // silent
    const attachEventsToPeerConnection = (err, pc) => {
      if (err) {
        triggerError({
          reason: `Failed to create PeerConnection, exception: ${err}`,
          prefix: 'NewPeerConnection',
        });

        _peerConnectionCompletionHandlers = [];
        return;
      }

      logging.debug('OT attachEventsToPeerConnection');
      _peerConnection = pc;
      if (_iceProcessor) {
        _iceProcessor.setPeerConnection(pc);
      }
      _channels = new PeerConnectionChannels(_peerConnection);
      if (config.channels) { _channels.addMany(config.channels); }

      const forwarder = iceCandidateForwarder({ sendMessage, logging });

      const onIceCandidate = (event) => {
        _readyToCompleteOffer.resolve();
        if (shouldFilterCandidate(event.candidate)) {
          logging.debug('Ignore candidate', event.candidate.candidate);
          return;
        }
        forwarder(event);
      };

      const onTrackAdded = async (event) => {
        onRemoteTrackAdded(event);

        if (!isPublisher && isE2ee) {
          if (sFrameReceiverClient) {
            // Standard SFrame's `decrypt` method can only be invoked when
            // the peer connection is in either "connected" or "connecting"
            // state.  It can't be invoked when a new peer connection is
            // instantiated, in other words.
            // If `sFrameReceiverClient` exists, then those requirements are
            // met.
            // Note: Legacy SFrame doesn't have this constraint so decrypt
            // requests even if the peer connection isn't in "connected" or
            // "connecting" state (e.g., it was just instantiated).
            await sFrameReceiverClient.decrypt(sFrameSenderId, event.receiver);
          } else {
            // Unlike Legacy SFrame, Standard SFrame can't decrypt frames
            // unless the peer connection is in "connected" or "connecting"
            // state.  So, we create a client instead.
            // For Standard SFrame, decyption occurs when the peer connection
            // is in "connected" state.  See `iceConnected` event handler in
            // this module.
            await createSFrameReceiverClient();
          }
        }
      };

      let _previousIceState = _peerConnection.iceConnectionState;
      const onIceConnectionStateChanged = (event) => {
        if (_peerConnection) {
          logging.debug('iceconnectionstatechange', _peerConnection.iceConnectionState);
          if (_peerConnection.iceConnectionState === 'connected') {
            // Now that the iceConnectionState is connected, let's make sure the transitionToFailedTimeOut
            // is cleared ASAP to avoid triggering an iceConnectionStateChange event to failed.
            if (_previousIceState === 'disconnected') {
              api.clearFailedTimeout();
            }
            api.emit('iceConnected');
            _iceRestartNeeded.set(false);
          } else if (_peerConnection.iceConnectionState === 'completed' && env.isLegacyEdge) {
            // Start collecting stats later in Edge because it fails if you call it sooner
            // This can probably be fixed better in Adapter.js
            setTimeout(() => qos.startCollecting(_peerConnection), 1000);
          }
        } else {
          logging.debug('iceconnectionstatechange on peerConnection that no longer exists', api);
        }

        const newIceState = event.target.iceConnectionState;
        api.trigger('iceConnectionStateChange', newIceState);

        logAnalyticsEvent('attachEventsToPeerConnection', 'iceconnectionstatechange',
          newIceState);

        if (newIceState === 'disconnected') {
          if (_iceRestartNeeded.get()) {
            logging.debug('Restarting ice!');
            api.generateOfferAndSend();
          } else if (!shouldUsePlanBSDP()) {
            // We only transition to failed in unified-plan
            // Plan-b will transition natively
            _transitionToFailedTimeOut = setTimeout(() => {
              const iceState = 'failed';
              logAnalyticsEvent('attachEventsToPeerConnection', 'iceconnectionstatechange',
                iceState);
              _previousIceState = iceState;
              api.trigger('iceConnectionStateChange', iceState);
            }, DISCONNECT_TO_FAILED_TIMEOUT);
          }
        }

        if (_previousIceState !== 'disconnected' && newIceState === 'failed') {
          // the sequence disconnected => failure would indicate an abrupt disconnection (e.g. remote
          // peer closed the browser) or a network problem. We don't want to log that has a connection
          // establishment failure. This behavior is seen only in Chrome 47+

          triggerError({
            reason: 'The stream was unable to connect due to a network error.' +
              ' Make sure your connection isn\'t blocked by a firewall.',
            prefix: 'ICEWorkflow',
          });
        }

        _previousIceState = newIceState;
      };

      const onNegotiationNeeded = () => {
        logAnalyticsEvent('peerConnection:negotiationNeeded', 'Event');
        if (isPublisher) {
          api.generateOfferAndSend();
        }
        api.trigger('negotiationNeeded');
      };

      _peerConnection.addEventListener('track', onTrackAdded);
      _peerConnection.addEventListener('icecandidate', onIceCandidate);
      _peerConnection.addEventListener('signalingstatechange', routeStateChanged);
      _peerConnection.addEventListener('negotiationneeded', onNegotiationNeeded);
      _peerConnection.addEventListener('iceconnectionstatechange', onIceConnectionStateChanged);

      cleanupJobs.add(() => {
        if (!_peerConnection) {
          return;
        }

        _peerConnection.removeEventListener('track', onTrackAdded);
        _peerConnection.removeEventListener('icecandidate', onIceCandidate);
        _peerConnection.removeEventListener('signalingstatechange', routeStateChanged);
        _peerConnection.removeEventListener('negotiationneeded', onNegotiationNeeded);
        _peerConnection.removeEventListener('iceconnectionstatechange', onIceConnectionStateChanged);
      });

      triggerPeerConnectionCompletion(null);
    };

    const triggerPeerConnectionCompletion = () => {
      while (_peerConnectionCompletionHandlers.length) {
        _peerConnectionCompletionHandlers.shift().call(null);
      }
    };

    // Clean up the Peer Connection and trigger the close event.
    // This function can be called safely multiple times, it will
    // only trigger the close event once (per PeerConnection object)
    const tearDownPeerConnection = () => {
      // Our connection is dead, stop processing ICE candidates
      if (_iceProcessor) {
        _iceProcessor.destroy();
        _iceProcessor = null;
      }

      _peerConnectionCompletionHandlers = [];

      qos.stopCollecting();
      cleanupJobs.releaseAll();
      _readyToCompleteOffer.clean();

      if (_peerConnection !== null) {
        if (_peerConnection.destroy) {
          // OTPlugin defines a destroy method on PCs. This allows
          // the plugin to release any resources that it's holding.
          _peerConnection.destroy();
        }

        _peerConnection = null;
        api.trigger('close');
      }

      api.off();
    };

    const routeStateChanged = (event) => {
      const newState = event.target.signalingState;

      api.emit('signalingStateChange', newState);

      if (newState === 'stable') {
        api.emit('signalingStateStable');
      }

      if (newState && newState !== _state) {
        _state = newState;
        logging.debug(`stateChange: ${_state}`);

        switch (_state) {
          case 'closed':
            tearDownPeerConnection();
            break;
          default:
        }
      }
    };

    const qosCallback = (parsedStats) => {
      parsedStats.dataChannels = _channels.sampleQos();
      api.trigger('qos', { parsedStats, simulcastEnabled: _simulcastEnabled.get() });
    };

    const getRemoteStreams = () => {
      let streams;

      if (_peerConnection.getRemoteStreams) {
        streams = _peerConnection.getRemoteStreams();
      } else if (_peerConnection.remoteStreams) {
        streams = _peerConnection.remoteStreams;
      } else {
        throw new Error('Invalid Peer Connection object implements no ' +
          'method for retrieving remote streams');
      }

      // Force streams to be an Array, rather than a 'Sequence' object,
      // which is browser dependent and does not behaviour like an Array
      // in every case.
      return Array.prototype.slice.call(streams);
    };

    // PeerConnection signaling
    const onRemoteTrackAdded = (event) => {
      const { track, transceiver } = event;
      // To be safe we consider this event can be either RTCTrackEvent or MediaStreamEvent type.
      // - RTCTrackEvent.streams => array with all the streams associated with the added track.
      //   Only one stream should be associated to the added track.
      // - MediaStreamEvent.stream => stream added
      const stream = event.stream || event.streams?.[0];
      logAnalyticsEvent('createPeerConnection', 'TrackAdded');
      api.trigger('trackAdded', { stream, track, transceiver });
    };

    // ICE Negotiation messages

    const reportError = (message, reason, prefix) => {
      processingOffer = false;
      triggerError({ reason: `PeerConnection.offerProcessor ${message}: ${reason}`, prefix });
    };

    // Process an offer that
    const processOffer = (message) => {
      if (processingOffer) {
        // If we get multiple pending offer messages we just handle the most recent one
        pendingOfferMessage = message;
        return;
      }
      processingOffer = true;
      logAnalyticsEvent('peerConnection:processOffer', 'Event');
      const offer = new NativeRTCSessionDescription({ type: 'offer', sdp: message.content.sdp });
      // Relays +answer+ Answer
      const relayAnswer = (answer) => {
        processingOffer = false;
        if (_iceProcessor) {
          _iceProcessor.process();
        }

        sendMessage('answer', answer);

        if (!env.isLegacyEdge) {
          qos.startCollecting(_peerConnection, isPublisher);
        }

        if (pendingOfferMessage) {
          processOffer(pendingOfferMessage);
          pendingOfferMessage = null;
        }
      };

      const onRemoteVideoSupported = supported => api.trigger('remoteVideoSupported', supported);

      internalCreatePeerConnection(() => {
        offerProcessor(
          _peerConnection,
          windowMock.RTCPeerConnection,
          windowMock.RTCSessionDescription,
          NativeRTCSessionDescription,
          sdpTransforms,
          offer,
          codecFlags,
          p2p,
          relayAnswer,
          reportError,
          onRemoteVideoSupported,
          replaceBaselineProfile,
          sourceStreamId
        );
      });
    };

    const processAnswer = (message) => {
      logAnalyticsEvent('peerConnection:processAnswer', 'Event');
      const errorMessageFixedInChrome71 = 'Failed to parse SessionDescription. a=extmap-allow-mixed Expects at least 2 fields.';
      const failure = (errorReason) => {
        if (errorReason.includes(errorMessageFixedInChrome71)) {
          errorReason = 'SessionDescription issue has been fixed in Chrome 71 and above. Please update your browser.';
        }
        triggerError({
          reason: `Error while setting RemoteDescription ${errorReason}`,
          prefix: 'SetRemoteDescription',
        });
      };

      if (!message.content.sdp) {
        failure('Weird answer message, no SDP.');
        return;
      }

      _answer = new NativeRTCSessionDescription({
        type: 'answer',
        sdp: applySdpTransform(
          sdpTransforms,
          'remote',
          'answer',
          assign({ replaceBaselineProfile }, answerOverrides),
          message.content.sdp
        ).local,
      });

      (() => {
        const success = () => {
          logging.debug('processAnswer: setRemoteDescription Success');
          if (_iceProcessor) {
            _iceProcessor.process();
          }
        };

        _peerConnection.setRemoteDescription(_answer)
          .then(success)
          .catch(failure);

        // Once answer is received, the offerAnswer process is resolved.
        offerAnswerProcessor.setResolved();
      })();

      if (!env.isLegacyEdge) {
        qos.startCollecting(_peerConnection, isPublisher);
      }
    };

    const processSubscribe = (onOfferCreated) => {
      if (_peerConnection?.signalingState?.toLowerCase() === 'closed') {
        return;
      }
      logAnalyticsEvent('peerConnection:processSubscribe', 'Event');
      logging.debug('processSubscribe: Sending offer to subscriber.');

      let numSimulcastStreams = (_simulcastEnabled.get() ?
        config.capableSimulcastStreams :
        1
      );

      // When transitioning from Mantis to P2P, in order to ensure the video quality we must
      // send only one video layer, regardless that simulcast is enabled.
      const isAdaptiveP2pSourceStreamId = sourceStreamId === 'P2P' && !p2p;
      if (isAdaptiveP2pSourceStreamId) {
        numSimulcastStreams = 1;
      }

      internalCreatePeerConnection(() => {
        subscribeProcessor({
          peerConnection: _peerConnection,
          NativeRTCSessionDescription,
          sdpTransforms,
          numSimulcastStreams,
          removeVideoOrientation: hasPictureInPictureBug(),
          offerOverrides,
          offerConstraints: { iceRestart: _iceRestartNeeded.get() },
          replaceBaselineProfile,
          removeUnusedCodecs,
        }).then(
          (offer) => {
            logging.debug('processSubscribe: got offer, waiting for ' +
              '_readyToCompleteOffer');

            _offer = offer;
            _readyToCompleteOffer.promise.then(() => onOfferCreated(_offer));
          },
          (error) => {
            triggerError({
              reason: `subscribeProcessor ${error.message}: ${error.reason}`,
              prefix: error.prefix,
            });
          }
        );

        _iceRestartNeeded.set(false);
      });
    };

    const cleanupSFrameClients = () => {
      if (typeof keyStoreChangeHandler === 'function') {
        keyStore.off('keyChanged', keyStoreChangeHandler);
      }
      sFrameSenderClient = null;
      sFrameSenderId = null;
    };

    api.generateOfferAndSend = () => {
      const offerSender = (offer) => {
        logging.debug('processSubscribe: sending offer');
        sendMessage('offer', offer);
      };
      api.generateOffer(offerSender);
    };

    api.generateOfferAndAnswer = (createAnswer) => {
      const setAnswer = (offer) => {
        createAnswer(offer);
      };
      api.generateOffer(setAnswer);
    };

    api.generateOffer = debounce((onOfferCreated = () => {}) => {
      // processSubscribe will handle offer creation, thus it is enqueued.
      offerAnswerProcessor.enqueueOfferAnswer(() =>
        processSubscribe(onOfferCreated));
    }, 100);

    const triggerError = ({ reason, prefix }) => {
      logging.error(
        reason,
        'in state',
        !_peerConnection ? '(none)' : {
          connectionState: _peerConnection.connectionState,
          iceConnectionState: _peerConnection.iceConnectionState,
          iceGatheringState: _peerConnection.iceGatheringState,
          signalingState: _peerConnection.signalingState,
        }
      );

      api.trigger('error', { reason, prefix });
    };

    /*
    * Add a track to the underlying PeerConnection
    *
    * @param {object} track - the track to add
    * @param {object} stream - the stream to add it to
    * @return {RTCRtpSender}
    */
    api.addTrack = (track, stream) => {
      const promise = new Promise((resolve, reject) => {
        internalCreatePeerConnection((err) => {
          if (err) { return reject(err); }
          resolve();
          return undefined;
        });
      })
        .then(() => {
          if (_peerConnection.addTrack) {
            return _peerConnection.addTrack(track, stream);
          }

          const pcStream = _peerConnection.getLocalStreams()[0];
          if (pcStream === undefined) {
            throw new Error('PeerConnection has no existing streams, cannot addTrack');
          }
          pcStream.addTrack(track);
          api.generateOfferAndSend();
          return undefined;
        })
        .then(() => new Promise((resolve) => {
          api.once('signalingStateStable', () => {
            resolve();
          });
        }));
      return promiseTimeout(promise, 15000, 'Renegotiation timed out');
    };

    function FakeRTCRtpSender(track) {
      this.track = track;
    }

    api.setIceConfig = (newIceConfig) => {
      try {
        // Firefox does not follow the spec laid out in MDN for attempts to change the certs
        // after they've already been set. The documentation states the following about certificates:
        // once the certificates have been set, this property is ignored in future calls to
        // RTCPeerConnection.setConfiguration().
        if (env.isFirefox) {
          newIceConfig.certificates = _peerConnection.getConfiguration().certificates;
        }
        _peerConnection.setConfiguration(newIceConfig);
      } catch (err) {
        throw (new OTHelpersError(`A peer connection failed to set configuration with ${err.message}`,
          Errors.FAILED_SET_CONFIGURATION));
      }

      _iceRestartNeeded.set(true);
      return _peerConnection;
    };

    /**
     * Remove a track from the underlying PeerConnection
     *
     * @param {RTCRtpSender} RTCRtpSender - the RTCRtpSender to remove
     */
    api.removeTrack = (RTCRtpSender) => {
      const promise = Promise.resolve()
        .then(() => {
          if (RTCRtpSender instanceof FakeRTCRtpSender) {
            _peerConnection.getLocalStreams()[0].removeTrack(RTCRtpSender.track);
            api.generateOfferAndSend();
            return undefined;
          }
          return _peerConnection.removeTrack(RTCRtpSender);
        })
        .then(() => new Promise((resolve) => {
          api.once('signalingStateStable', () => {
            resolve();
          });
        }));
      return promiseTimeout(promise, 15000, 'Renegotiation timed out');
    };

    api.addLocalStream = webRTCStream => new Promise((resolve, reject) => {
      internalCreatePeerConnection((err) => {
        if (err) {
          reject(err);
          return;
        }
        try {
          // only node does not support getTracks
          if (webRTCStream.getTracks) {
            webRTCStream.getTracks().forEach(track => _peerConnection.addTrack(track, webRTCStream));
          }
        } catch (addStreamErr) {
          reject(addStreamErr);
          return;
        }
        resolve();
      }, webRTCStream);
    });

    api.getLocalStreams = () => _peerConnection.getLocalStreams();

    api.getSenders = () => {
      if (_peerConnection.getSenders) {
        return _peerConnection.getSenders();
      }

      return _peerConnection.getLocalStreams()[0].getTracks().map(track => new FakeRTCRtpSender(track));
    };

    api.disconnect = () => {
      if (_iceProcessor) {
        _iceProcessor.destroy();
        _iceProcessor = null;
      }

      if (_peerConnection?.signalingState &&
        _peerConnection.signalingState.toLowerCase() !== 'closed') {
        _peerConnection.close();
        cleanupSFrameClients();
        setTimeout(tearDownPeerConnection);
      }
    };

    api.iceRestart = () => _iceRestartNeeded.set(true);

    api.clearFailedTimeout = () => clearTimeout(_transitionToFailedTimeOut);

    const disableMedia = (shouldDisable) => {
      const mediaHandler = shouldDisable ? deactivateMedia : reactivateMedia;

      const mediaHandlerPromise = new Promise((resolve) => {
        offerAnswerProcessor.enqueueOfferAnswer(async () => {
          await mediaHandler(_peerConnection);
          // No answer is expected, thus the offerAnswer process is resolved.
          offerAnswerProcessor.setResolved();
          resolve();
        });
      });
      return mediaHandlerPromise;
    };

    // Change media direction to RECVONLY enables to send media.
    api.changeMediaDirectionToRecvOnly = () => disableMedia(false);

    // Change media direction to INACTIVE stops sending media.
    api.changeMediaDirectionToInactive = () => disableMedia(true);

    api.processMessage = (type, message) => {
      logging.debug(`processMessage: Received ${
        type} from ${message.fromAddress}`, message);

      logging.debug(message);

      switch (type) {
        case 'generateoffer':
          if (
            message.content &&
            message.content.simulcastEnabled !== undefined
          ) {
            _simulcastEnabled.set(message.content.simulcastEnabled);
          }

          api.generateOfferAndSend();
          trackRenegotiationAttempts();
          break;

        case 'offer':
          processOffer(message);
          trackRenegotiationAttempts();
          break;

        case 'answer':
        case 'pranswer':
          processAnswer(message);
          break;

        case 'candidate':
          var iceCandidate = new NativeRTCIceCandidate(message.content);

          if (_iceProcessor) {
            _iceProcessor.addIceCandidate(iceCandidate).catch((err) => {
              // Sometimes you might get an error adding an iceCandidate
              // this does not mean we should fail, if we get a working candidate
              // later then we should let it work
              logging.warn(`Error while adding ICE candidate: ${JSON.stringify(iceCandidate)}: ${err.toString()}`);
            });
          }

          break;

        default:
          logging.debug(`processMessage: Received an unexpected message of type ${
            type} from ${message.fromAddress}: ${JSON.stringify(message)}`);
      }

      function trackRenegotiationAttempts() {
        offerMessagesReceived += 1;
        qos.handleOfferMessageReceived();

        if (offerMessagesReceived > 1) {
          logAnalyticsEvent('Renegotiation', 'Attempt');
          renegotiationInProgress = true;
        }
      }

      return api;
    };

    const _sortTransceivers = (transceivers) => {
      // Tranceivers are sorted in reverse order, so we only stop the first transceiver in the
      // PC once.
      const sorter = (t1, t2) => {
        // This will swap the positions between t1 and t2.
        if (t1.mid < t2.mid) {
          return 1;
        }
        // This will keep the same positions.
        if (t1.mid > t2.mid) {
          return -1;
        }
        // This should not happen, but just to be safe.
        return 0;
      };
      return transceivers.sort(sorter);
    };

    api.stopTransceivers = (transceivers) => {
      // transceivers provides an array of transceiver.mid. This will stop every transceiver
      // provided in the PC.
      const transceiversToStop = _peerConnection.getTransceivers()
        .filter(transceiver => transceivers.includes(transceiver.mid));

      _sortTransceivers(transceiversToStop).forEach(transceiver => transceiver.stop());
    };

    api.remoteStreams = () => (_peerConnection ? getRemoteStreams() : []);

    api.remoteTracks = () => {
      if (!_peerConnection) {
        return [];
      }

      if (_peerConnection.getReceivers) {
        return Array.prototype.slice.apply(_peerConnection.getReceivers())
          .map(receiver => receiver.track);
      }

      return Array.prototype.concat.apply([],
        getRemoteStreams()
          .map(stream => stream.getTracks())
      );
    };

    api.remoteDescription = () => _peerConnection.remoteDescription;

    /**
     * @param {function(DOMError, Array<RTCStats>)} callback
     */
    api.getStats = (track, callback) => {
      if (!_peerConnection) {
        const errorCode = ExceptionCodes.PEER_CONNECTION_NOT_CONNECTED;
        callback(new OTHelpersError(OTErrorClass.getTitleByCode(errorCode),
          Errors.PEER_CONNECTION_NOT_CONNECTED, {
            code: errorCode,
          }));
        return;
      }
      getStatsAdapter(_peerConnection, track, callback);
    };

    /**
     * @param {function(DOMError, RTCStatsReport)} callback
     */
    api.getRtcStatsReport = (track, callback) => {
      if (!_peerConnection) {
        const errorCode = ExceptionCodes.PEER_CONNECTION_NOT_CONNECTED;
        callback(new OTHelpersError(OTErrorClass.getTitleByCode(errorCode),
          Errors.PEER_CONNECTION_NOT_CONNECTED, {
            code: errorCode,
          }));
        return;
      }
      getRtcStatsReportAdapter(_peerConnection, track, callback);
    };

    api.getSynchronizationSources = (callback) => {
      if (!isPublisher) {
        if (!_peerConnection) {
          const errorCode = ExceptionCodes.PEER_CONNECTION_NOT_CONNECTED;
          callback(new OTHelpersError(OTErrorClass.getTitleByCode(errorCode),
            Errors.PEER_CONNECTION_NOT_CONNECTED, {
              code: errorCode,
            }));
          return;
        }
        getSynchronizationSources(_peerConnection, callback);
      }
    };

    const waitForChannel = function waitForChannel(timesToWait, label, channelOptions, completion) {
      let err;
      const channel = _channels.get(label, channelOptions);

      if (!channel) {
        if (timesToWait > 0) {
          setTimeout(
            waitForChannel.bind(null, timesToWait - 1, label, channelOptions, completion),
            200
          );

          return;
        }

        err = new OTHelpersError(`${'A channel with that label and options could not be found. ' +
                              'Label:'}${label}. Options: ${JSON.stringify(channelOptions)}`);
      }

      completion(err, channel);
    };

    api.getDataChannel = (label, channelOptions, completion) => {
      if (!_peerConnection) {
        completion(new OTHelpersError('Cannot create a DataChannel before there is a connection.'));
        return;
      }
      // Wait up to 20 sec for the channel to appear, then fail
      waitForChannel(100, label, channelOptions, completion);
    };

    api.getSourceStreamId = () => sourceStreamId;

    api.iceConnectionStateIsConnected = () => _peerConnection && ['connected', 'completed'].indexOf(_peerConnection.iceConnectionState) > -1;

    api.hasTrack = track => _peerConnection.getSenders().some(sender => sender.track === track);

    api.findAndReplaceTrack = (oldTrack, newTrack) => Promise.resolve()
      .then(() => {
        const sender = _peerConnection.getSenders()
          .filter(s => s.track === oldTrack)[0];

        if (!sender) {
          // There is no video track to replace but this is OK
          // they probably called cycleVideo on an audio only Publisher
          // or on a Publisher that does not support the videoCodec
          return Promise.resolve();
        }

        if (typeof sender.replaceTrack !== 'function') {
          throw new Error('Sender does not support replaceTrack');
        }

        return sender.replaceTrack(newTrack);
      });

    api.hasRelayCandidates = () => hasRelayCandidates;

    api.getNegotiatedCodecs = () => {
      if (!_peerConnection.localDescription || !_peerConnection.remoteDescription) {
        return null;
      }

      const getForMediaType = mediaType => intersection(
        SDPHelpers.getCodecs(_peerConnection.localDescription.sdp, mediaType),
        SDPHelpers.getCodecs(_peerConnection.remoteDescription.sdp, mediaType)
      );

      return {
        audio: getForMediaType('audio'),
        video: getForMediaType('video'),
      };
    };

    api.on('signalingStateStable', () => {
      if (renegotiationInProgress) {
        renegotiationInProgress = false;
        logAnalyticsEvent('Renegotiation', 'Success', api.getNegotiatedCodecs());
      }
    });

    api.on('error', ({ prefix }) => {
      if (renegotiationInProgress && ['CreateOffer', 'SetRemoteDescription'].indexOf(prefix) !== -1) {
        renegotiationInProgress = false;
        logAnalyticsEvent('Renegotiation', 'Failure');
      }
    });

    api.on('close', () => {
      if (renegotiationInProgress) {
        renegotiationInProgress = false;
        logAnalyticsEvent('Renegotiation', 'Cancel');
      }
    });

    const qos = new Qos();
    qos.on('stats', qosCallback);

    return api;
  };
}
