/* eslint-disable no-underscore-dangle */
import { Client as SFrameClientDefault } from '@vonage/js-sframe';
import normalizeKeyDefault from './normalizeKey';

const create = async (senderId, deps = {}) => {
  const SFrameClient = deps.SFrameClient || SFrameClientDefault;
  const normalizeKey = deps.normalizeKey || normalizeKeyDefault;

  const client = await SFrameClient.create(senderId);

  // Cache original method, since we need to ensure it's compatible
  // with it's legacy SFrame counterpart
  client._setReceiverEncryptionKey = client.setReceiverEncryptionKey;
  client.setReceiverEncryptionKey = async (_senderId, key) => {
    const normalizedKey = await normalizeKey(key);

    return client._setReceiverEncryptionKey(_senderId, normalizedKey);
  };

  // Cache original method, since we need to ensure it's compatible
  // with it's legacy SFrame counterpart
  client._setSenderEncryptionKey = client.setSenderEncryptionKey;
  client.setSenderEncryptionKey = async (key) => {
    const normalizedKey = await normalizeKey(key);

    return client._setSenderEncryptionKey(normalizedKey);
  };

  return client;
};

const StandardSFrameClient = {
  create,
};

export default StandardSFrameClient;
