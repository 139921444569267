import { useGetUserAlertPreferencesQuery } from 'graphql/generated/hasura';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'auth/context/AuthContext';

import { AccountSettingsState } from '../interfaces/providerModuleInterfaces';
import {
  AlertIdentifiers,
  AlertPreferencesKeys,
  AlertPreferencesProps,
  AlertPreferencesState,
} from '../interfaces/alertPreferencesInterfaces';
import { CheckboxComponent } from './CheckboxComponent';
import { ReactComponent as AlertInfoIcon } from 'assets/icons/alertsInfo.svg';
import ButtonComponent from 'components/button/buttonComponent';

export const AlertPreferences: React.FC<AlertPreferencesProps> = ({
  locale,
  setAccountSettings,
  setIsAllSectionsLoaded,
}) => {
  const [alertsPreferencesStatus, setAlertsPreferencesStatus] =
    useState<AlertPreferencesState | null>(null);

  const { user: loggedUser } = useContext(AuthContext);

  useGetUserAlertPreferencesQuery({
    variables: {
      id: loggedUser?.uuid,
    },
    onCompleted: (data) => {
      const userAlertSettings = data.users[0].ALERT_SETTINGS;

      setAlertsPreferencesStatus({
        ALERT_SETTINGS: {
          ...userAlertSettings,
        },
      });

      setIsAllSectionsLoaded((prevState) => ({
        ...prevState,
        alertPreferences: true,
      }));
    },
  });

  const checkboxHandler = (
    option: AlertPreferencesKeys,
    identifier: AlertIdentifiers,
  ) => {
    setAlertsPreferencesStatus((prevState) => {
      if (!prevState) {
        return null;
      }
      return {
        ALERT_SETTINGS: {
          ...prevState.ALERT_SETTINGS,
          [identifier]: {
            ...prevState.ALERT_SETTINGS[identifier],
            [option]: !prevState.ALERT_SETTINGS[identifier][option],
          },
        },
      };
    });
  };

  const handleUncheckAlerts = () => {
    setAlertsPreferencesStatus({
      ALERT_SETTINGS: {
        [AlertIdentifiers.SYSTEM_OUTAGES]: {
          primaryEmail: false,
          mobileMessage: false,
        },
        [AlertIdentifiers.APPOINTMENT_CHANGES]: {
          primaryEmail: false,
          mobileMessage: false,
        },
        [AlertIdentifiers.APPOINTMENT_REMINDERS]: {
          primaryEmail: false,
          mobileMessage: false,
        },
        [AlertIdentifiers.PASSWORD_OR_EMAIL_CHANGES]: {
          primaryEmail: false,
          mobileMessage: false,
        },
      },
    });
  };

  useEffect(() => {
    setAccountSettings((prevState) => {
      return {
        ...prevState,
        ...alertsPreferencesStatus,
      } as AccountSettingsState;
    });
  }, [alertsPreferencesStatus, setAccountSettings]);

  return (
    <div>
      <div className="mb-[30px]">
        <p className="text-title text-charcoal-gray font-bold uppercase">
          {locale.alertsTitle}
        </p>
        <p className="text-sm font-semibold text-med-gray">
          {locale.alertsSubtitle}
        </p>
        {alertsPreferencesStatus !== null && (
          <>
            <CheckboxComponent
              locale={locale}
              title={locale?.alertCheckboxTitle1}
              subtitle={locale?.alertCheckboxSubtitle1}
              onChangeHandler={(option, identifier) =>
                checkboxHandler(option, identifier)
              }
              option1CheckedState={
                alertsPreferencesStatus?.ALERT_SETTINGS
                  .PASSWORD_OR_EMAIL_CHANGES.primaryEmail ?? false
              }
              option2CheckedState={
                alertsPreferencesStatus?.ALERT_SETTINGS
                  .PASSWORD_OR_EMAIL_CHANGES.mobileMessage ?? false
              }
              identifier={AlertIdentifiers.PASSWORD_OR_EMAIL_CHANGES}
            />
            <CheckboxComponent
              locale={locale}
              title={locale?.alertCheckboxTitle2}
              onChangeHandler={(option, identifier) =>
                checkboxHandler(option, identifier)
              }
              option1CheckedState={
                alertsPreferencesStatus?.ALERT_SETTINGS.SYSTEM_OUTAGES
                  .primaryEmail ?? false
              }
              option2CheckedState={
                alertsPreferencesStatus?.ALERT_SETTINGS.SYSTEM_OUTAGES
                  .mobileMessage ?? false
              }
              identifier={AlertIdentifiers.SYSTEM_OUTAGES}
            />
            <CheckboxComponent
              locale={locale}
              title={locale?.alertCheckboxTitle3}
              onChangeHandler={(option, identifier) =>
                checkboxHandler(option, identifier)
              }
              option1CheckedState={
                alertsPreferencesStatus?.ALERT_SETTINGS.APPOINTMENT_CHANGES
                  .primaryEmail ?? false
              }
              option2CheckedState={
                alertsPreferencesStatus?.ALERT_SETTINGS.APPOINTMENT_CHANGES
                  .mobileMessage ?? false
              }
              identifier={AlertIdentifiers.APPOINTMENT_CHANGES}
            />
            <CheckboxComponent
              locale={locale}
              title={locale?.alertCheckboxTitle4}
              subtitle={locale?.alertCheckboxSubtitle4}
              onChangeHandler={(option, identifier) =>
                checkboxHandler(option, identifier)
              }
              option1CheckedState={
                alertsPreferencesStatus?.ALERT_SETTINGS.APPOINTMENT_REMINDERS
                  .primaryEmail ?? false
              }
              option2CheckedState={
                alertsPreferencesStatus?.ALERT_SETTINGS.APPOINTMENT_REMINDERS
                  .mobileMessage ?? false
              }
              identifier={AlertIdentifiers.APPOINTMENT_REMINDERS}
            />
            <div className="desktop:pl-32">
              <ButtonComponent
                type="underline"
                onClick={() => handleUncheckAlerts()}
              >
                {locale?.stopUsingAlerts}
                <AlertInfoIcon className="h-5 w-5 mr-[14px] shrink-0" />
              </ButtonComponent>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
