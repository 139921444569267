import Modal from 'components/modal/modalComponent';
import { MedicationHistoryDetailViewProps } from './interfaces';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import { componentIds, genericActionsIds } from 'utilities/constants';
import { useGetComponent } from 'hooks/useGetComponent';
import { ModalButtonProps } from 'components/modal/modal.interfaces';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  MedicationDetails,
  MedicationDetailsMedication,
} from './components/MedicationDetail';
import { useEffect, useMemo, useState } from 'react';

export const MedicationHistoryDetailViewModalId =
  'medication-history-detail-view-modal';

export const MedicationHistoryDetailView: React.FC<
  MedicationHistoryDetailViewProps
> = ({ medicationRequestLists }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [
        genericActionsIds.CANCEL,
        genericActionsIds.SUBMIT,
        genericActionsIds.CLOSE,
      ],
    });

  const medicationIds = medicationRequestLists
    .map((m) => m.medicationId)
    .join(',');

  const [currentMedicationId, setCurrentMedicationId] = useState<string>();
  const [currentMedication, next, prev] = useMemo<
    [
      current: MedicationDetailsMedication | undefined,
      next: string | undefined,
      prev: string | undefined,
    ]
  >(() => {
    if (!currentMedicationId || !medicationRequestLists)
      return [undefined, undefined, undefined];
    const currentIndex = medicationRequestLists.findIndex(
      (medication) => medication.medicationId === currentMedicationId,
    );
    if (currentIndex === -1) return [undefined, undefined, undefined];
    const current = medicationRequestLists[currentIndex];
    const next = medicationRequestLists[currentIndex + 1]?.medicationId;
    const prev = medicationRequestLists[currentIndex - 1]?.medicationId;
    return [current, next, prev];
    // We need to consider when the reference of medicationRequestLists does not changes but we add or remove some medications form the array (doing a push for example)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMedicationId, medicationRequestLists, medicationIds]);

  const { data: locale, loading: localeLoading } = useGetComponent({
    locale: 'en',
    componentId: componentIds.MEDICATION_HISTORY_DETAIL_VIEW,
  });

  const medicationByState = location?.state?.medicationId as string | undefined;

  useEffect(() => {
    if (medicationByState) setCurrentMedicationId(medicationByState);
  }, [medicationByState]);

  const handleClose = () => navigate(-1);

  const onNextClick = () => {
    if (next) setCurrentMedicationId(next);
  };

  const onPreviousClick = () => {
    if (prev) setCurrentMedicationId(prev);
  };

  const Buttons: ModalButtonProps[] = [
    {
      label: genericAction?.[genericActionsIds.CLOSE].close,
      disabled: false,
      onClick: handleClose,
    },
    {
      label: `${locale?.nextCta} >`,
      type: 'underline',
      floatLeft: true,
      disabled: !next,
      onClick: onNextClick,
    },
    {
      label: `< ${locale?.previousCta}`,
      type: 'underline',
      floatLeft: true,
      disabled: !prev,
      onClick: onPreviousClick,
    },
  ];

  if (
    localeLoading ||
    genericActionLoading ||
    !locale ||
    !genericAction ||
    !currentMedication
  ) {
    return null;
  }

  return (
    <Modal
      isOpen={true}
      onClose={handleClose}
      buttons={Buttons}
      title={locale.modalTitle}
      mobileRowButtons={true}
      modalDesktopWidth="desktop:w-[600px]"
    >
      <div className="container mx-auto">
        <MedicationDetails locale={locale} medication={currentMedication} />
      </div>
    </Modal>
  );
};
