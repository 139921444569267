import { Card } from './Card';
import { PatientDetailsProps } from '../interfaces/patientDetails.interface';
import { PatientDetailsMobile } from './PatientDetailsMobile';
import { PatientDetailsDesktop } from './PatientDetailsDesktop';

export const PatientDetails: React.FC<PatientDetailsProps> = ({
  locale,
  patientDetails,
}) => {
  return (
    <Card title="Patient details" customYPadding="py-0">
      <PatientDetailsMobile locale={locale} patientDetails={patientDetails} />
      <PatientDetailsDesktop locale={locale} patientDetails={patientDetails} />
    </Card>
  );
};
