import { AppointmentTypeEnum } from 'graphql/generated/remote-schema-hasura';
import { MyAppointmentDeets } from '../MyAppointmentDeets';
import { interpolateVariables } from 'utilities/functions';

interface AppointmentWillStartProps {
  locale: Record<string, Record<string, string>>;
  appointmentType: AppointmentTypeEnum;
  room: string;
  providerName?: string | null;
  isPatient?: boolean;
  patientName?: string | null;
  title?: string | null;
}

export const AppointmentWillStart: React.FC<AppointmentWillStartProps> = ({
  locale,
  appointmentType,
  providerName = null,
  patientName = null,
  title = null,
  isPatient = false,
}) => {
  return (
    <>
      <div className="w-full h-full flex flex-col items-center justify-center gap-[60px]">
        <div className="flex flex-col items-center gap-2">
          <div className="text-h1 font-medium text-dark-gray mb-9 text-center">
            {interpolateVariables(locale?.consultation?.welcomeStarted, {
              participant: isPatient
                ? locale?.consultation?.provider
                : locale?.consultation?.patient,
            })}
          </div>
          <div className="text-h6 font-normal text-med-gray">
            {interpolateVariables(
              locale?.consultation?.welcomeStartedSubtitle,
              {
                participant: isPatient
                  ? locale?.consultation?.provider
                  : locale?.consultation?.patient,
              },
            )}
          </div>
        </div>
        <MyAppointmentDeets
          appointmentType={appointmentType}
          date={new Date().toISOString()}
          fullName={(providerName || patientName) as string}
          title={title}
          isProvider={!isPatient}
        />
      </div>
    </>
  );
};
