import {
  DropdownItem,
  DynamicQuestionResponseSection,
  QuestionnaireResponseInterface,
  questionnaire_questions_question,
} from '../interfaces/dynamicQuestionnaireResponse.interface';
import {
  ProviderDataLocalForm,
  ProviderDataResponse,
} from '../interfaces/signUpProviderResponse.interface';
import { DynamicQuestionnaireDependencie } from '../interfaces/dynamicQuestionnaireResponse.interface';
import {
  SPECIFIC_UNAUTHENTICATED_QUESTION_IDS,
  SPECIFIC_UNAUTHENTICATED_QUESTION_RESPONSE,
} from './constants';
import { HasuraAnswerItem } from 'graphql/generated/remote-schema-hasura';

enum UIControlType {
  MIX = 'MIX',
  RADIO_BUTTON = 'RADIO_BUTTON',
  TEXT = 'TEXT',
  CHECKBOX = 'CHECKBOX',
}

export interface MedicationItemResponse {
  questionID: number;
  medicationID: number;
  response: string;
}

export interface MedicationItemResponseWithLabel
  extends MedicationItemResponse {
  inputLabel: string;
}

export interface MedicationItemResponseForFiltering {
  questionID: number;
  response: string;
  inputLabel?: string;
}

export const getUiControlType = (uiControl: string): string => {
  switch (uiControl) {
    case UIControlType.MIX:
      return 'mix';
    case UIControlType.RADIO_BUTTON:
      return 'radio';
    case UIControlType.TEXT:
      return 'text';
    case UIControlType.CHECKBOX:
      return 'checkbox';
    default:
      return '';
  }
};

interface ResponseItem {
  response: string;
  responsePropertyValue?: string;
  questionID?: number;
}

interface ResponseItemWithAnswers extends ResponseItem {
  answers?: ResponseItem[];
}

type InputArray = Array<
  { response: string } | { response: Array<ResponseItem> }
>;

export interface FhirResponseMappedToHasura {
  response: HasuraAnswerItem[] | string[];
  currentIndexOfSection: number;
  currentSection: string;
  questionID: number;
}

export const extractResponseValue = (
  inputArray: InputArray,
): string | string[] => {
  let extractedValue: string | string[] = '';

  if (Array.isArray(inputArray) && inputArray.length > 0) {
    const firstItem = inputArray[0];
    if (
      'response' in firstItem &&
      Array.isArray(firstItem.response) &&
      firstItem.response.length > 0
    ) {
      const nestedResponse = firstItem.response[0];

      if (firstItem.response.every((item) => typeof item === 'string')) {
        extractedValue = firstItem.response as unknown as string[];
      } else {
        if (
          'response' in nestedResponse &&
          typeof nestedResponse.response === 'string'
        ) {
          extractedValue = nestedResponse.response;
        } else if (
          'responsePropertyValue' in nestedResponse &&
          typeof nestedResponse.responsePropertyValue === 'string'
        ) {
          extractedValue = nestedResponse.responsePropertyValue;
        }
      }
    } else if (typeof firstItem.response === 'string') {
      extractedValue = firstItem.response;
    }
  }

  return extractedValue;
};

export const extractGenderValue = (inputArray: InputArray): string => {
  let extractedValue = '';

  if (typeof inputArray[0].response === 'string') {
    extractedValue = inputArray[0].response as string;
  }

  if (typeof inputArray[0].response !== 'string') {
    const foundItem = inputArray[0].response.find((item) => {
      return (
        item.questionID ===
          SPECIFIC_UNAUTHENTICATED_QUESTION_IDS.GENDER_QUESTION &&
        item.response === SPECIFIC_UNAUTHENTICATED_QUESTION_RESPONSE.OTHER
      );
    });

    if (foundItem) {
      const foundInputValue = inputArray[0].response.find((item) => {
        return (
          item.questionID ===
          SPECIFIC_UNAUTHENTICATED_QUESTION_IDS.GENDER_OTHER_QUESTION
        );
      });
      extractedValue = foundInputValue?.response as string;
    } else {
      const foundRadioItem = inputArray[0].response.find((item) => {
        return (
          item.questionID ===
          SPECIFIC_UNAUTHENTICATED_QUESTION_IDS.GENDER_QUESTION
        );
      });
      extractedValue = foundRadioItem?.response as string;
    }
  }

  return extractedValue.toLowerCase();
};

export const getPropertyForEducationDynamicList = (
  questionID: number,
): string | null => {
  switch (questionID) {
    case SPECIFIC_UNAUTHENTICATED_QUESTION_IDS.EDUCATION_SCHOOL:
      return 'medicalSchool';
    case SPECIFIC_UNAUTHENTICATED_QUESTION_IDS.EDUCATION_RESIDENCY:
      return 'residency';
    case SPECIFIC_UNAUTHENTICATED_QUESTION_IDS.EDUCATION_UNDERGRADUATE:
      return 'undergraduate';
    default:
      return null;
  }
};

interface providerDataResponseEducation {
  medicalSchool: string;
  residency: string;
  undergraduate: string;
}

type DynamicObject = { [key: string]: string } | providerDataResponseEducation;

export const extractEducationValues = (inputArray: InputArray) => {
  let processedData: DynamicObject[][] = [];
  if (Array.isArray(inputArray[0]?.response)) {
    processedData = inputArray[0].response.map((educationItem) => {
      return (
        (educationItem as ResponseItemWithAnswers).answers?.map(
          (educationData) => {
            const propertyName = getPropertyForEducationDynamicList(
              educationData.questionID as number,
            ) as string;

            const result = {
              [propertyName]: educationData.response,
            };
            return result;
          },
        ) ?? []
      );
    });
  }

  const processedDataMapped = processedData.map((subArray) => {
    return subArray.reduce((result, item) => {
      return { ...result, ...item };
    }, {});
  });

  return processedDataMapped;
};

interface DynamicSignUpProviderData {
  [key: string]: Array<{ response: string }>;
}

export const processSignUpProviderForm = (
  responses: QuestionnaireResponseInterface[],
): ProviderDataResponse => {
  const dynamicObject: DynamicSignUpProviderData = {};

  responses.forEach((response) => {
    // gender specific property assignation
    if (
      response.questionID ===
      SPECIFIC_UNAUTHENTICATED_QUESTION_IDS.GENDER_QUESTION
    ) {
      if (!dynamicObject['gender']) {
        dynamicObject['gender'] = [];
      }
      dynamicObject['gender'].push({ response: response.response as string });
    }

    if (
      response.questionID ===
      SPECIFIC_UNAUTHENTICATED_QUESTION_IDS.EDUCATION_DYNAMIC_LIST
    ) {
      if (!dynamicObject['education']) {
        dynamicObject['education'] = [];
      }
      dynamicObject['education'].push({
        response: response.response as string,
      });
    }

    if (Array.isArray(response.response)) {
      const propertyName = response.response[0].responsePropertyValue;

      const propertyValue =
        response.response[0]?.response !== undefined
          ? response.response[0].response
          : response.response;

      if (propertyName && propertyValue) {
        if (!dynamicObject[propertyName]) {
          dynamicObject[propertyName] = [];
        }
        dynamicObject[propertyName].push({ response: propertyValue as string });
      }
    }
  });

  const providerData: ProviderDataResponse = {
    firstname: extractResponseValue(dynamicObject['firstname']) as string,
    lastname: extractResponseValue(dynamicObject['lastname']) as string,
    email: extractResponseValue(dynamicObject['email']) as string,
    phone: extractResponseValue(dynamicObject['phone']) as string,
    nationalProviderIdentifier: extractResponseValue(
      dynamicObject['nationalProviderIdentifier'],
    ) as string,
    password: extractResponseValue(dynamicObject['password']) as string,
    confirmPassword: extractResponseValue(
      dynamicObject['confirmPassword'],
    ) as string,
    gender: extractGenderValue(dynamicObject['gender']),
    languagesOfCommunication: extractResponseValue(
      dynamicObject['languagesOfCommunication'],
    ) as string[],
    about: extractResponseValue(dynamicObject['about']) as string,
    credentials: {
      titles: extractResponseValue(
        dynamicObject['credentials_title'],
      ) as string[],
      states: extractResponseValue(
        dynamicObject['credentials_states'],
      ) as string[],
      certifications: extractResponseValue(
        dynamicObject['credentials_certifications'],
      ) as string[],
    },
    education: extractEducationValues(
      dynamicObject['education'],
    ) as providerDataResponseEducation[],
    office_information: {
      officeName: extractResponseValue(
        dynamicObject['office_information_office_name'],
      ) as string,
      email: extractResponseValue(
        dynamicObject['office_information_email'],
      ) as string,
      phone: extractResponseValue(
        dynamicObject['office_information_phone'],
      ) as string,
      address1: extractResponseValue(
        dynamicObject['office_information_address_1'],
      ) as string,
      address2: extractResponseValue(
        dynamicObject['office_information_address_2'],
      ) as string,
      country: extractResponseValue(
        dynamicObject['office_information_country'],
      ) as string,
      state: extractResponseValue(
        dynamicObject['office_information_state'],
      ) as string,
      city: extractResponseValue(
        dynamicObject['office_information_city'],
      ) as string,
      zipCode: extractResponseValue(
        dynamicObject['office_information_zip_code'],
      ) as string,
    },
    address_information: {
      address1: extractResponseValue(
        dynamicObject['address_information_address_1'],
      ) as string,
      address2: extractResponseValue(
        dynamicObject['address_information_address_2'],
      ) as string,
      country: extractResponseValue(
        dynamicObject['address_information_country'],
      ) as string,
      state: extractResponseValue(
        dynamicObject['address_information_state'],
      ) as string,
      city: extractResponseValue(
        dynamicObject['address_information_city'],
      ) as string,
      zipCode: extractResponseValue(
        dynamicObject['address_information_zip_code'],
      ) as string,
    },
  };
  return providerData;
};

export const accumulateRequiredValues = (
  dependencies: DynamicQuestionnaireDependencie[],
): string[] => {
  let accumulatedValues: string[] = [];

  dependencies.forEach((dependency) => {
    const { then } = dependency;
    if (then && then.required && Array.isArray(then.required)) {
      accumulatedValues = accumulatedValues.concat(then.required);
    }
  });

  return accumulatedValues;
};

export const parseIso = (value: string | DropdownItem) => {
  let stringToReturn = '';
  if (typeof value === 'string') {
    stringToReturn = value.split(' - ')[0];
  } else {
    if (value && value.value) {
      stringToReturn = value.value.split(' - ')[0];
    }
  }
  return stringToReturn;
};

export const updatePersistentDataWithDynamicData = (
  prevState: QuestionnaireResponseInterface[],
  sectionName: string,
  questionID: number,
  response: QuestionnaireResponseInterface[],
) => {
  let updatedState = [...prevState];
  let foundExistingEntry = false;

  // Check if an entry with the same questionID exists in prevState
  updatedState = updatedState.map((entry) => {
    if (entry.questionID === questionID) {
      const updatedEntry = {
        ...entry,
        currentSection: sectionName,
        response: response,
      };
      foundExistingEntry = true;
      return updatedEntry;
    }
    return entry;
  });

  // If no existing entry was found, create a new one
  if (!foundExistingEntry) {
    updatedState.push({
      currentSection: sectionName,
      questionID: questionID,
      response: response,
    });
  }

  return updatedState;
};

export const constructPersonalInformationDataAsPersistentDataObject = (
  questionID: number,
  responseValue: QuestionnaireResponseInterface[],
  responsePropertyValue: string,
) => {
  return {
    currentIndexOfSection: '',
    currentSection: '',
    questionID: questionID,
    response: [
      {
        questionID: questionID,
        response: [
          {
            response: responseValue[0],
            responsePropertyValue: responsePropertyValue,
            questionID: questionID,
          },
        ],
        responsePropertyValue: responsePropertyValue,
      },
    ],
  };
};

export const generateIdsFromContactInfoToMergeIntoPersistentData = (
  providerLocalForm: ProviderDataLocalForm,
) => [
  {
    questionID:
      SPECIFIC_UNAUTHENTICATED_QUESTION_IDS.PERSONAL_INFORMATION_ADDRESS_1,
    valueToAttach: providerLocalForm['office_information_address_1'],
    responsePropertyValueToAttach: 'address_information_address_1',
  },
  {
    questionID:
      SPECIFIC_UNAUTHENTICATED_QUESTION_IDS.PERSONAL_INFORMATION_ADDRESS_2,
    valueToAttach:
      providerLocalForm['office_information_address_2'] &&
      providerLocalForm['office_information_address_2'].length > 0
        ? providerLocalForm['office_information_address_2']
        : '',
    responsePropertyValueToAttach: 'address_information_address_2',
  },
  {
    questionID:
      SPECIFIC_UNAUTHENTICATED_QUESTION_IDS.PERSONAL_INFORMATION_COUNTRY,
    valueToAttach: providerLocalForm['office_information_country'],
    responsePropertyValueToAttach: 'address_information_country',
  },
  {
    questionID:
      SPECIFIC_UNAUTHENTICATED_QUESTION_IDS.PERSONAL_INFORMATION_STATE,
    valueToAttach: providerLocalForm['office_information_state'],
    responsePropertyValueToAttach: 'address_information_state',
  },
  {
    questionID: SPECIFIC_UNAUTHENTICATED_QUESTION_IDS.PERSONAL_INFORMATION_CITY,
    valueToAttach: providerLocalForm['office_information_city'],
    responsePropertyValueToAttach: 'address_information_city',
  },
  {
    questionID:
      SPECIFIC_UNAUTHENTICATED_QUESTION_IDS.PERSONAL_INFORMATION_ZIP_CODE,
    valueToAttach: providerLocalForm['office_information_zip_code'],
    responsePropertyValueToAttach: 'address_information_zip_code',
  },
];

export const removeParentEntriesFromPersistentData = (
  prevState: QuestionnaireResponseInterface[],
  idsToRemove: number[],
) => {
  return prevState.filter(
    (response) => !idsToRemove.includes(response.questionID as number),
  );
};

export const validateQuestionResponse = (
  question: questionnaire_questions_question,
  value: string,
  validationRules: {
    callback: (value: string) => boolean;
    properties: string[];
    maxLength?: number | undefined;
  }[],
  customProperty?: string,
): boolean => {
  const definePropertyUsage = customProperty
    ? customProperty
    : (question.questionnaire_questions_question.response_property as string);

  const responseProperty = definePropertyUsage;

  for (const rule of validationRules) {
    if (
      rule &&
      rule.properties.includes(responseProperty) &&
      rule.callback(value)
    ) {
      if (rule.maxLength === undefined || value.length <= rule.maxLength) {
        return true;
      }
    }
  }

  return false;
};

export const parseQuestionOptionsForDropdownWithLookup = (
  options: string[],
) => {
  const parsedOptions = options.map((option: string) => ({
    label: option,
    value: option,
  }));
  return parsedOptions;
};

export const hasMonthElapsed = (initialDateString: string) => {
  if (initialDateString !== '') {
    const initialDate = new Date(initialDateString);
    const currentDate = new Date();

    const monthsDifference =
      (currentDate.getFullYear() - initialDate.getFullYear()) * 12 +
      (currentDate.getMonth() - initialDate.getMonth());

    return monthsDifference >= 1;
  } else {
    return null;
  }
};

export const updateStateWithMedicationValues = (
  prevState: QuestionnaireResponseInterface[],
  section: DynamicQuestionResponseSection,
  medicationList: QuestionnaireResponseInterface[],
  medicationID: number,
) => {
  let updatedState = [...prevState];
  let foundExistingEntry = false;

  updatedState = updatedState.map((entry) => {
    if (entry.questionID === medicationID) {
      foundExistingEntry = true;
      return {
        ...entry,
        currentSection: section.name,
        response: medicationList,
      };
    }
    return entry;
  });

  if (!foundExistingEntry) {
    updatedState.push({
      currentSection: section.name,
      questionID: medicationID,
      response: medicationList,
    });
  }

  return updatedState;
};
