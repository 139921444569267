import { useGetGenericActions } from 'hooks/useGetGenericActions';
import {
  PARAM_MODALS_IDENTIFIERS,
  genericActionsIds,
  pageIds,
} from 'utilities/constants';
import { ModalButtonProps } from '../modal/modal.interfaces';
import Modal from '../modal/modalComponent';
import { useGetPage } from 'hooks/useGetPage';
import PreWorkSection from './components/PreworkSection';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  useGetAppointmentByCodexAppointmentIdQuery,
  useGetProviderInfoByProviderCodexIdLazyQuery,
} from 'graphql/generated/remote-schema-hasura';

import Loader from 'components/loaderComponent';
import AppointmentInfoProvider from './components/AppointmentInfoProvider';
import ButtonComponent from 'components/button/buttonComponent';
import { ReactComponent as ChevronRightIcon } from 'assets/icons/chevron-right.svg';
import {
  appointmentTypeFromServiceType,
  formatDateForAppointment,
  fromFhirAppointmentTypeToLocal,
} from 'utilities/functions';
import { useRef } from 'react';
import { SharedMediaModalLocationState } from 'app/my-patients/interfaces/interfaces';
import { useGetAndStoreMediaSignedUrl } from 'hooks/useGetAndStoreMediaSignedUrl';
import { AppointmentHistoryModalComponent } from 'components/appointmentDetailModal/AppointmentHistoryModalComponent';
import { PROVIDERS_PROVIDER_BIO } from 'utilities/routes';

const appointmentIdQueryParam = 'appointmentId';

const AppointmentDetailModalPatient = () => {
  const navigate = useNavigate();

  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [
        genericActionsIds.CANCEL,
        genericActionsIds.SUBMIT,
        genericActionsIds.CLOSE,
      ],
    });
  const { data: locale, loading: localeLoading } = useGetPage({
    locale: 'en',
    pageId: pageIds.APPOINTMENT_DETAILS,
  });

  const location = useLocation();
  const appointmentIdByState = location.state?.appointmentId as string;

  // For testing purposes appointmentId can be sent by query param
  // This way the modal is fully testable without having provider appointments screen done
  const searchParams = new URLSearchParams(location.search);
  const appointmentIdByQueryParam = searchParams.get(appointmentIdQueryParam);
  const appointmentId = appointmentIdByQueryParam || appointmentIdByState;
  const mediaMap = useRef<Map<string, string>>(new Map());
  const getMedia = useGetAndStoreMediaSignedUrl();

  const [getProviderInfo, { loading: patientLoading, data: providerData }] =
    useGetProviderInfoByProviderCodexIdLazyQuery();

  const { data: appointmentData, loading: appointmentLoading } =
    useGetAppointmentByCodexAppointmentIdQuery({
      variables: { appointmentCodexId: appointmentId },
      skip: !appointmentId,
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        if (data.getFHIRAppointmentByCodexId.appointment?.codexProviderId) {
          getProviderInfo({
            variables: {
              providerCodexId:
                data.getFHIRAppointmentByCodexId.appointment.codexProviderId,
            },
          });
        }
        if (data.getFHIRAppointmentByCodexId.appointment?.media?.length) {
          for (const { bodySite, fileId } of data.getFHIRAppointmentByCodexId
            .appointment.media) {
            if (bodySite && fileId) {
              mediaMap.current.set(bodySite, fileId);
            }
          }
        }
      },
    });

  const handleClose = () => navigate(-1);

  if (
    localeLoading ||
    genericActionLoading ||
    !locale ||
    !genericAction ||
    !appointmentId
  )
    return null;

  const Buttons: ModalButtonProps[] = [
    {
      label: genericAction?.[genericActionsIds.CLOSE].close,
      disabled: false,
      onClick: handleClose,
    },
  ];

  const dataLoading = appointmentLoading || patientLoading;
  const provider = providerData?.getFHIRProviderbyId.providers[0];
  const appointment = appointmentData?.getFHIRAppointmentByCodexId.appointment;
  const providerFullName = `${provider?.SENSITIVE_firstname || ''} ${
    provider?.SENSITIVE_lastname || ''
  }`;
  const goToProviderBioInfo = () =>
    navigate(PROVIDERS_PROVIDER_BIO, {
      state: { providerId: appointment?.codexProviderId },
    });

  const onMediaLocationClick = async (location: string) => {
    if (!appointment) return;
    const currentLocationMediaId = mediaMap.current.get(location);
    if (currentLocationMediaId) {
      const mediaUrl = await getMedia(currentLocationMediaId);
      const media = appointment.media?.find(
        ({ fileId }) => fileId === currentLocationMediaId,
      );
      if (!mediaUrl) return;
      const modalState: SharedMediaModalLocationState = {
        mediaDate: new Date(appointment.start || 0),
        mediaSrc: mediaUrl,
        providerName: providerFullName,
        appointmentType: fromFhirAppointmentTypeToLocal(
          appointment.appointmentType || undefined,
        ),
        bodySite: location,
        description: media?.description || undefined,
      };
      navigate(`.?${PARAM_MODALS_IDENTIFIERS.SHARED_MEDIA_MODAL_ID}=true`, {
        state: modalState,
      });
    }
  };
  const serviceOffering = provider?.serviceOfferings?.find(
    (service) =>
      appointmentTypeFromServiceType(service.serviceType) ===
      appointment?.appointmentType,
  );
  const specialty = provider?.SENSITIVE_credentials?.titles[0];
  return (
    <Modal
      isOpen={true}
      onClose={handleClose}
      buttons={Buttons}
      title={locale.appointmentDetails}
    >
      {dataLoading || !appointment ? (
        <Loader />
      ) : (
        <div className="container mx-auto">
          {/* Alert component must come here when the backend ticket is taken */}

          {provider && (
            <AppointmentInfoProvider
              datetime={formatDateForAppointment(
                new Date(appointment.start || ''),
              )}
              appointmentType={appointment.appointmentType}
              pictureOwnerCodexId={appointment.codexProviderId as string}
              profilePictureUrl={provider.SENSITIVE_profile_picture_id}
              name={providerFullName}
              locale={locale}
              appointmentPrice={serviceOffering?.price}
              mainSpecialty={specialty}
            />
          )}
          <div className="row mt-5 desktop:mt-8">
            <div className="flex justify-between items-center border-b border-gray-300 mb-4 pb-4">
              <span className="transition duration-600 ease-in-out font-exo text-lg font-semibold">
                {locale.appointmentPrework}
              </span>
            </div>
            <div className="overflow-hidden transition-all ease-in-out duration-600">
              <div className="content mb-5">
                <PreWorkSection
                  onMediaLocationClick={onMediaLocationClick}
                  appointmentData={appointment}
                  locale={locale}
                />
                <ButtonComponent
                  onClick={goToProviderBioInfo}
                  type="underline"
                  Icon={ChevronRightIcon}
                >
                  {locale.viewProviderProfile}
                </ButtonComponent>
              </div>
            </div>
          </div>
          <AppointmentHistoryModalComponent
            appointment={
              appointmentData.getFHIRAppointmentByCodexId.appointment
            }
          />
        </div>
      )}
    </Modal>
  );
};

export default AppointmentDetailModalPatient;
