import { useEffect, useRef } from 'react';

type UnloadCallback = () => void;

const useUnload = (
  fn: UnloadCallback,
): React.MutableRefObject<UnloadCallback> => {
  const cb = useRef<UnloadCallback>(fn);

  useEffect(() => {
    const onUnload = cb.current;
    window.addEventListener('beforeunload', onUnload);
    return () => {
      window.removeEventListener('beforeunload', onUnload);
    };
  }, []);

  return cb;
};

export default useUnload;
