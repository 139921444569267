interface CarouselArrowProps {
  onClick: () => void;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

const CarouselArrow: React.FC<CarouselArrowProps> = ({
  onClick,
  icon: Icon,
}) => {
  return (
    <div
      className="flex flex-row items-center p-0 cursor-pointer"
      onClick={onClick}
    >
      <Icon className="w-[15px] h-[25px] fill-current" />
    </div>
  );
};

export default CarouselArrow;
