import { useContext, useEffect, useState, useRef, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from 'auth/context/AuthContext';
import { componentIds, pageIds } from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';
import {
  useGetUsersByIdQuery,
  Dnavisit_Test_Statuses_Enum,
} from 'graphql/generated/hasura';

import { Tab } from 'components/tabs/tabs.interface';
import {
  TEST_KITS_MANAGEMENT_TEST_KITS,
  MY_ACCOUNT_PROFILE,
  TEST_KITS_MANAGEMENT_REGISTER_TEST_KIT,
  MY_ACCOUNT_HEALTH_QUESTIONNAIRE,
} from 'utilities/routes';
import { ReactComponent as DownArrow } from 'assets/icons/arrowDown.svg';
import { useGetUserTestKits } from '../hooks/hooksBoth';
import { twMerge } from 'tailwind-merge';
import { useGetComponent } from 'hooks/useGetComponent';
import { ReactComponent as CloseIcon } from 'assets/icons/iconClose.svg';
import { interpolateVariables } from 'utilities/functions';
import { ProfileSectionTypes } from 'app/my-account/pages/my-profile/enums';
import TabsComponent from 'components/tabs/tabsComponent';
import ButtonComponent from 'components/button/buttonComponent';
import DNATestKitTable from '../components/DNATestKitTable/DNATestKitDesktopTable/DNATestKitTable';
import DnaTestKitMobileTable from '../components/DNATestKitTable/DNATestKitMobileTable/DNATestKitMobileTable';
import DNATestKitFilters from '../components/DNATestKitTable/DNATestKitFilters/DNATestKitFilters';
import Alert from 'components/alertComponent';
import useUserValidation from 'hooks/useUserValidation';
import Loader from 'components/loaderComponent';
import { useModalParams } from 'components/modal/useModalManager';
import { ScheduleDnaVisit } from '../components/ScheduleDnaVisit';
import { VisitSummary } from '../components/VisitSummary';
import { useIsMobile } from 'hooks/useIsMobile';
import EmptyStateComponent from 'components/EmptyStateComponent';

const ALL_DNA_TEST_STATES: Dnavisit_Test_Statuses_Enum[] = [
  Dnavisit_Test_Statuses_Enum.Complete,
  Dnavisit_Test_Statuses_Enum.KitNotReceived,
  Dnavisit_Test_Statuses_Enum.ReceivedByLab,
  Dnavisit_Test_Statuses_Enum.ResultsWithProvider,
  Dnavisit_Test_Statuses_Enum.AppointmentScheduled,
];

export const TestKitsBoth = () => {
  const [isContentVisible, setIsContentVisible] = useState(false);
  const [providerName, setProviderName] = useState('');
  const [viewByType, setViewByType] = useState('All');
  const [viewByStatus, setViewByStatus] = useState('All');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [sortByMobile, setSortByMobile] = useState('Date - Newest');
  const [testKitAlertText, setTestKitAlertText] = useState<string>('');
  const [alertVisible, setAlertVisible] = useState<boolean>(true);
  const [documentsReady, setDocumentsReady] = useState<boolean>(false);
  const [missingSectionType, setMissingSectionType] =
    useState<ProfileSectionTypes | null>(null);
  const [showProfileComplete, setShowProfileComplete] = useState(false);
  const isMobile = useIsMobile();
  const { userValidation, loading: loadingUserValidation } =
    useUserValidation();
  const { user: loggedUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [showTestKitRegisteredAlert, setShowTestKitRegisteredAlert] =
    useState<boolean>(false);
  const [dnaTestStateSelected, setDNATestStateSelected] =
    useState<Dnavisit_Test_Statuses_Enum[]>(ALL_DNA_TEST_STATES);
  const hasAlreadyRenderedTable = useRef(false);
  const { isOpen: isScheduleVisitModalOpen } =
    useModalParams('schedule-dna-visit');
  const { isOpen: isVisitSummaryModalOpen } = useModalParams('visit-summary');

  const {
    dnaTests,
    loading: dnaTestsLoading,
    count,
    handleFetchMore,
    handleRefetch,
    loadingFhirDnaVisitTests,
  } = useGetUserTestKits({
    limit: 10,
    dnaTestStateSelected,
    testTypeSelected: viewByType,
  });

  const location = useLocation();
  const testKit = location.state;
  const referringPage = location.pathname;
  const dnaFlowCompleted = location.state?.dnaFlowCompleted || false;
  const userCanScheduleAppointment = useMemo(
    () =>
      dnaTests.some(
        (test) =>
          test.status !== Dnavisit_Test_Statuses_Enum.KitNotReceived &&
          test.status !== Dnavisit_Test_Statuses_Enum.ReceivedByLab &&
          test.status !== Dnavisit_Test_Statuses_Enum.ProviderPending,
      ),
    [dnaTests],
  );

  const { data: testKitLocale, loading: testKitLoading } = useGetPage({
    locale: 'en',
    pageId: pageIds.TEST_KITS_AND_RESULTS,
  });

  const {
    data: testKitsAndResultsFiltersLocale,
    loading: testKitsAndResultsFiltersLoading,
  } = useGetComponent({
    locale: 'en',
    componentId: componentIds.TEST_KITS_AND_RESULTS_FILTERS,
  });

  const { data: userData } = useGetUsersByIdQuery({
    variables: { id: loggedUser?.uuid },
  });

  const toggleContentVisibility = () => setIsContentVisible(!isContentVisible);
  const handleLoadMore = () => {
    handleFetchMore();
  };

  useEffect(() => {
    const handleBeforeUnload = () => {
      navigate(location.pathname, {
        replace: true,
        state: { ...location.state, dnaFlowCompleted: false },
      });
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [location, navigate]);

  useEffect(() => {
    if (testKit?.alert && testKitLocale?.testKitsRegistered) {
      setShowTestKitRegisteredAlert(true);
      setTestKitAlertText(
        interpolateVariables(testKitLocale?.testKitsRegistered, {
          testKit: testKit?.testName,
        }),
      );
    }
  }, [testKit, testKitLocale]);

  useEffect(() => {
    const validateUser = async () => {
      const result = await userValidation();
      if (!result.isPersonalInfoValid) {
        setMissingSectionType(ProfileSectionTypes.PROFILE);
      } else if (!result.isContactInfoValid) {
        setMissingSectionType(ProfileSectionTypes.CONTACT);
      } else if (!result.isQuestionnaireValid) {
        setMissingSectionType(ProfileSectionTypes.HEALTH_QUESTIONNAIRE);
      } else {
        setMissingSectionType(null);
      }
    };

    validateUser().catch((error) => {
      console.log(error);
      throw new Error('Failed to validate user');
    });
  }, [userValidation]);

  const handleNavigateIncompleteSection = async () => {
    switch (missingSectionType) {
      case ProfileSectionTypes.PROFILE:
        return navigate(MY_ACCOUNT_PROFILE, {
          state: {
            section: ProfileSectionTypes.PROFILE,
            isDnaTestKitsFlow: true,
            referringPage,
          },
        });
      case ProfileSectionTypes.CONTACT:
        return navigate(MY_ACCOUNT_PROFILE, {
          state: {
            section: ProfileSectionTypes.CONTACT,
            isDnaTestKitsFlow: true,
            referringPage,
          },
        });
      default:
        return navigate(MY_ACCOUNT_HEALTH_QUESTIONNAIRE, {
          state: { referringPage },
        });
    }
  };

  const handleOnUpdateFilters = () => {
    let tempDNATestStatus;

    switch (viewByStatus) {
      case testKitsAndResultsFiltersLocale.dnaTestKitStateNotReceivedSelectOption:
        setDNATestStateSelected([Dnavisit_Test_Statuses_Enum.KitNotReceived]);
        tempDNATestStatus = [Dnavisit_Test_Statuses_Enum.KitNotReceived];
        break;

      case testKitsAndResultsFiltersLocale.dnaTestKitStateReceivedByLabSelectOption:
        setDNATestStateSelected([Dnavisit_Test_Statuses_Enum.ReceivedByLab]);
        tempDNATestStatus = [Dnavisit_Test_Statuses_Enum.ReceivedByLab];
        break;

      case testKitsAndResultsFiltersLocale.dnaTestKitStateProviderPendingSelectOption:
        setDNATestStateSelected([Dnavisit_Test_Statuses_Enum.ProviderPending]);
        tempDNATestStatus = [Dnavisit_Test_Statuses_Enum.ProviderPending];
        break;

      case testKitsAndResultsFiltersLocale.dnaTestKitStateResultsWithProviderSelectOption:
        setDNATestStateSelected([
          Dnavisit_Test_Statuses_Enum.ResultsWithProvider,
        ]);
        tempDNATestStatus = [Dnavisit_Test_Statuses_Enum.ResultsWithProvider];
        break;

      case testKitsAndResultsFiltersLocale.dnaTestKitStateScheduleAppointmentSelectOption:
        setDNATestStateSelected([
          Dnavisit_Test_Statuses_Enum.AppointmentScheduled,
        ]);
        tempDNATestStatus = [Dnavisit_Test_Statuses_Enum.AppointmentScheduled];
        break;

      case testKitsAndResultsFiltersLocale.dnaTestKitStateCompleteSelectOption:
        setDNATestStateSelected([Dnavisit_Test_Statuses_Enum.Complete]);
        tempDNATestStatus = [Dnavisit_Test_Statuses_Enum.Complete];
        break;

      case testKitsAndResultsFiltersLocale.all:
      default:
        setDNATestStateSelected(ALL_DNA_TEST_STATES);
        tempDNATestStatus = ALL_DNA_TEST_STATES;
        break;
    }

    handleRefetch({
      fromDate,
      toDate,
      newStates: tempDNATestStatus,
      providerNameToFilter: providerName,
      sortByMobile,
    });
  };

  useEffect(() => {
    if (dnaTests.length > 0) {
      if (!hasAlreadyRenderedTable.current)
        hasAlreadyRenderedTable.current = true;
    }
  }, [hasAlreadyRenderedTable, dnaTests]);

  useEffect(() => {
    if (dnaFlowCompleted) setShowProfileComplete(true);
  }, [dnaFlowCompleted]);

  if (isVisitSummaryModalOpen)
    return <VisitSummary onClose={() => setDocumentsReady(false)} />;

  if (
    !testKitLocale ||
    testKitLoading ||
    !userData ||
    dnaTestsLoading ||
    testKitsAndResultsFiltersLoading ||
    !testKitsAndResultsFiltersLocale ||
    loadingUserValidation ||
    loadingFhirDnaVisitTests
  )
    return (
      <div className="flex justify-center items-center mt-8">
        <Loader />
      </div>
    );

  const tabs: Tab[] = [
    {
      name: testKitLocale?.testKitsAndResultsTab,
      url: TEST_KITS_MANAGEMENT_TEST_KITS,
    },
    {
      name: testKitLocale?.registerTestKitTab,
      url: TEST_KITS_MANAGEMENT_REGISTER_TEST_KIT,
    },
  ];

  const getAlertInfoBasedOnMissingData = (
    missingSectionType: ProfileSectionTypes,
  ): { text: string; action: string } => {
    switch (missingSectionType) {
      case ProfileSectionTypes.PROFILE:
        return {
          text: testKitLocale?.personalInformationIncomplete,
          action: testKitLocale?.completePersonalInformation,
        };
      case ProfileSectionTypes.CONTACT:
        return {
          text: testKitLocale?.contactInformationIncomplete,
          action: testKitLocale?.completeContactInformation,
        };
      default:
        return {
          text: testKitLocale?.healthQuestionnaireIncomplete,
          action: testKitLocale?.completeHealthQuestionnaire,
        };
    }
  };

  const handleOnFromDateChange = (date: string) => {
    if (toDate && date && new Date(date).getTime() > new Date(toDate).getTime())
      setToDate('');
    setFromDate(date);
  };

  const handleOnToDateChange = (date: string) => {
    if (
      fromDate &&
      date &&
      new Date(date).getTime() < new Date(fromDate).getTime()
    )
      setFromDate('');
    setToDate(date);
  };

  if (isScheduleVisitModalOpen && userCanScheduleAppointment) {
    return <ScheduleDnaVisit />;
  }

  // If dnaTests loads but is empty, hide loading indicator
  // If dnaTests loads and is not empty, show loading indicator until table is ready
  const hideLoadingIndicator =
    documentsReady || (dnaTests && dnaTests.length === 0);

  return (
    <>
      {!hideLoadingIndicator && (
        <div className={'flex justify-center items-center mt-8'}>
          <Loader />
        </div>
      )}
      <div
        className={`${hideLoadingIndicator ? 'flex' : 'hidden'} flex-col grow`}
      >
        <TabsComponent tabs={tabs} />
        <div className="flex flex-col grow px-7">
          {showProfileComplete && (
            <Alert
              type="positive"
              customContent={
                <div className="flex flex-col w-full desktop:flex-row justify-between items-center desktop:relative">
                  <div className="flex flex-col desktop:flex-row w-full gap-5 desktop:gap-[30px]">
                    <p className="text-base font-semibold text-alert-positive desktop:mr-[35px]">
                      {testKitLocale?.profileCompleted}
                    </p>
                  </div>

                  <CloseIcon
                    className="absolute top-5 right-5 desktop:top-0 desktop:right-0 w-6 h-6 stroke-med-gray cursor-pointer"
                    onClick={() => setShowProfileComplete(false)}
                  />
                </div>
              }
            />
          )}
          {showTestKitRegisteredAlert && (
            <Alert
              type="positive"
              customContent={
                <div className="flex flex-col w-full desktop:flex-row justify-between items-center desktop:relative">
                  <div className="flex flex-col desktop:flex-row w-full gap-5 desktop:gap-[30px]">
                    <p className="text-base font-semibold text-alert-positive desktop:mr-[35px]">
                      {testKitAlertText}
                    </p>
                  </div>

                  <CloseIcon
                    className="absolute top-5 right-5 desktop:top-0 desktop:right-0 w-6 h-6 stroke-med-gray cursor-pointer"
                    onClick={() => setShowTestKitRegisteredAlert(false)}
                  />
                </div>
              }
            />
          )}
          {missingSectionType && alertVisible && (
            <div className="flex flex-row mb-[30px]">
              <Alert
                type="info"
                text={getAlertInfoBasedOnMissingData(missingSectionType).text}
                actionLabel={
                  getAlertInfoBasedOnMissingData(missingSectionType).action
                }
                onActionClick={handleNavigateIncompleteSection}
                onClose={() => setAlertVisible(false)}
                isNotification
              />
            </div>
          )}

          {dnaTests.length === 0 && !hasAlreadyRenderedTable.current && (
            <div className="flex grow items-center justify-center desktop:min-h-[300px]">
              <EmptyStateComponent
                title={testKitLocale?.noResultsTitle}
                text={testKitLocale?.noResults}
                ctaButton={
                  !missingSectionType ? (
                    <ButtonComponent
                      className="font-exo text-sm rounded mt-4 desktop:mt-8"
                      onClick={() =>
                        navigate(TEST_KITS_MANAGEMENT_REGISTER_TEST_KIT)
                      }
                    >
                      {testKitLocale.registerYourTestKit}
                    </ButtonComponent>
                  ) : (
                    <ButtonComponent
                      className="font-exo text-sm rounded mt-4 desktop:mt-8"
                      onClick={handleNavigateIncompleteSection}
                    >
                      {
                        getAlertInfoBasedOnMissingData(missingSectionType)
                          .action
                      }
                    </ButtonComponent>
                  )
                }
              />
            </div>
          )}

          {(dnaTests.length > 0 || hasAlreadyRenderedTable.current) && (
            <>
              <div
                className={`flex flex-col desktop:flex-row desktop:justify-between ${
                  showProfileComplete || showTestKitRegisteredAlert
                    ? 'mt-4'
                    : ''
                }`}
              >
                <div className="flex flex-col desktop:items-start desktop:max-w-[60%]">
                  <p className="text-h2 text-dark-gray font-medium desktop:text-h1">
                    {testKitLocale.testKitsAndResults}
                  </p>
                </div>
                {!missingSectionType && (
                  <ButtonComponent
                    className="max-h-[45px] mt-[15px] desktop:mt-0"
                    onClick={() =>
                      navigate(TEST_KITS_MANAGEMENT_REGISTER_TEST_KIT)
                    }
                  >
                    {testKitLocale.registerYourTestKit}
                  </ButtonComponent>
                )}
                {missingSectionType && (
                  <ButtonComponent
                    className="max-h-[45px] mt-[15px] desktop:mt-0"
                    onClick={handleNavigateIncompleteSection}
                  >
                    {getAlertInfoBasedOnMissingData(missingSectionType).action}
                  </ButtonComponent>
                )}
              </div>
              <div className="rounded-10 bg-white p-5 mt-[30px] desktop:p-[30px]">
                <div className="flex flex-col desktop:flex-row desktop:items-center">
                  <h4 className="text-h4 text-dark-gray font-semibold">
                    {testKitLocale.history}
                  </h4>
                  <p className="text-med-gray text-h6 desktop:ml-2.5">
                    {testKitLocale.showing} {dnaTests.length} {testKitLocale.of}{' '}
                    {count}
                  </p>
                </div>
                <DNATestKitFilters
                  isContentVisible={isContentVisible}
                  providerName={providerName}
                  fromDate={fromDate}
                  toDate={toDate}
                  viewByType={viewByType}
                  viewByStatus={viewByStatus}
                  sortByMobile={sortByMobile}
                  onToggleContentVisibility={toggleContentVisibility}
                  onProviderSearchChange={(providerName) =>
                    setProviderName(providerName)
                  }
                  onFromDateChange={(newFromDate) =>
                    handleOnFromDateChange(newFromDate)
                  }
                  onToDateChange={(newToDate) =>
                    handleOnToDateChange(newToDate)
                  }
                  onViewTypeChange={(viewType) => setViewByType(viewType)}
                  onViewStatusChange={(viewStatus) =>
                    setViewByStatus(viewStatus)
                  }
                  handleOnUpdateFilters={handleOnUpdateFilters}
                  onSortByMobileChange={(sortByMobile) =>
                    setSortByMobile(sortByMobile)
                  }
                />

                {dnaTests.length > 0 && !isMobile && (
                  <DNATestKitTable
                    data={dnaTests}
                    onLoadComplete={() => setDocumentsReady(true)}
                  />
                )}

                {dnaTests.length > 0 && isMobile && (
                  <DnaTestKitMobileTable
                    data={dnaTests}
                    onLoadComplete={() => setDocumentsReady(true)}
                  />
                )}

                <div
                  className={twMerge(
                    `flex flex-col desktop:flex-row justify-between items-center desktop:mt-5 ${
                      dnaTests.length === count ? 'justify-end' : ''
                    }`,
                  )}
                >
                  {dnaTests.length < count && (
                    <div
                      className="flex justify-center items-center mt-5 desktop:justify-start hover:cursor-pointer"
                      onClick={handleLoadMore}
                    >
                      <ButtonComponent type="underline" className="w-auto">
                        {testKitLocale.seeMore}
                      </ButtonComponent>
                      <DownArrow className="w-3.5 h-3.5 fill-clc-blue ml-2.5" />
                    </div>
                  )}
                </div>

                {dnaTests.length === 0 && (
                  <p className="mt-[30px] font-semibold text-dark-gray">
                    {
                      testKitsAndResultsFiltersLocale.noMatchesFoundForSearchFilters
                    }
                  </p>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
