import InputComponent from 'components/inputComponent';
import { WidgetContainer } from './WidgetContainer';

interface TreatmentSurveyLocale {
  issueResolved: string;
  itsBetter: string;
  noChange: string;
  conditionWorsened: string;
}

interface TreatmentSurveyProps {
  locale: TreatmentSurveyLocale;
  title: string;
  optionSelected: string;
  onOptionSelected: (option: string) => void;
  desktopHidden?: boolean;
  backgroundColor?: string;
}

export const TreatmentSurvey: React.FC<TreatmentSurveyProps> = ({
  title,
  locale,
  optionSelected,
  onOptionSelected,
  desktopHidden = true,
  backgroundColor = 'bg-white',
}) => {
  return (
    <WidgetContainer
      titlesCapitalization="none"
      desktopHiddden={desktopHidden}
      title={title}
      titlesSize="text-lg"
      backgroundColor={backgroundColor}
    >
      <ul className="text-sm text-med-gray">
        <li className="flex flex-row gap-2.5">
          <InputComponent
            type="radio"
            radioInputProps={{
              radioLabelTextWeight: 'font-semibold',
              radioError: null,
              radioPosition: 'items-center desktop:items-start',
              radioInputValue: locale.issueResolved,
              radioInputLabel: locale.issueResolved,
              radioInputCheckedValue:
                optionSelected === locale.issueResolved
                  ? locale.issueResolved
                  : undefined,
              onRadioClick: () => onOptionSelected(locale.issueResolved),
            }}
          />
        </li>
        <li className="flex flex-row gap-2.5">
          <InputComponent
            type="radio"
            radioInputProps={{
              radioLabelTextWeight: 'font-semibold',
              radioError: null,
              radioPosition: 'items-center desktop:items-start',
              radioInputValue: locale.itsBetter,
              radioInputLabel: locale.itsBetter,
              radioInputCheckedValue:
                optionSelected === locale.itsBetter
                  ? locale.itsBetter
                  : undefined,
              onRadioClick: () => onOptionSelected(locale.itsBetter),
            }}
          />
        </li>
        <li className="flex flex-row gap-2.5">
          <InputComponent
            type="radio"
            radioInputProps={{
              radioLabelTextWeight: 'font-semibold',
              radioError: null,
              radioPosition: 'items-center desktop:items-start',
              radioInputValue: locale.noChange,
              radioInputLabel: locale.noChange,
              radioInputCheckedValue:
                optionSelected === locale.noChange
                  ? locale.noChange
                  : undefined,
              onRadioClick: () => onOptionSelected(locale.noChange),
            }}
          />
        </li>
        <li className="flex flex-row gap-2.5">
          <InputComponent
            type="radio"
            radioInputProps={{
              radioLabelTextWeight: 'font-semibold',
              radioError: null,
              radioInputValue: locale.conditionWorsened,
              radioInputLabel: locale.conditionWorsened,
              radioInputCheckedValue:
                optionSelected === locale.conditionWorsened
                  ? locale.conditionWorsened
                  : undefined,
              onRadioClick: () => onOptionSelected(locale.conditionWorsened),
            }}
          />
        </li>
      </ul>
    </WidgetContainer>
  );
};
