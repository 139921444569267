import { useState } from 'react';
import { componentIds, genericActionsIds } from 'utilities/constants';
import { ModalButtonProps } from 'components/modal/modal.interfaces';
import Modal from 'components/modal/modalComponent';
import { useGetComponent } from 'hooks/useGetComponent';
import InputComponent from 'components/inputComponent';
import { PrescribeMedicationModal } from 'components/PrescribeMedicationModal';
import { VIRTUAL_PHARMACIES } from 'utilities/constants';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import { createModal } from 'layout/createModal';
import { useModal } from 'layout/useModal';
import { useGetFhirProviderByRequestIdQuery } from 'graphql/generated/remote-schema-hasura';
import Loader from 'components/loaderComponent';

export const SharedMediaModalId = 'shared-media-modal';

export const VirtualPharmaciesModal = createModal<
  { appointmentId?: string; patientId: string; encounterId?: string },
  void
>('virtual-pharmacies-modal', ({ isOpen, state, close }) => {
  const [virtualPlatformSelect, setVirtualPlatformSelect] = useState<string>();
  const [dosestopAvailable, setDosestopAvailable] = useState<boolean>(false);
  const [openPrescriptionModal] = useModal(PrescribeMedicationModal);
  const { loading: providerDataLoading } = useGetFhirProviderByRequestIdQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const dosespotAvailable =
        !!data?.getFHIRProviderByRequestId?.providers?.[0]?.dosespotId;
      setDosestopAvailable(dosespotAvailable);
      if (!dosespotAvailable) {
        handleContinue(VIRTUAL_PHARMACIES.fullscript);
      } else {
        setVirtualPlatformSelect(VIRTUAL_PHARMACIES.dosespot);
      }
    },
  });

  const { data: locale, loading: loading } = useGetComponent({
    locale: 'en',
    componentId: componentIds.VIRTUAL_PHARMACY_MODAL,
  });

  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.CANCEL, genericActionsIds.CONTINUE],
    });

  const handleContinue = async (pharmacy?: string) => {
    await close();
    if (!virtualPlatformSelect && !pharmacy) return;
    openPrescriptionModal(
      {
        platform: pharmacy ?? (virtualPlatformSelect as string),
        patientId: state.patientId,
        appointmentCodexId: state.appointmentId,
        encounterId: state.encounterId,
      },
      true,
    );
  };

  const handleVirtualPlatformSelect = (value: string) => {
    setVirtualPlatformSelect(value);
  };

  const Buttons: ModalButtonProps[] = [
    {
      label: genericAction?.[genericActionsIds.CONTINUE].continue,
      disabled: false,
      onClick: () => handleContinue(),
    },
    {
      label: genericAction?.[genericActionsIds.CANCEL].cancel,
      disabled: false,
      onClick: close,
      type: 'underline',
    },
  ];

  if (loading && !locale && genericActionLoading) return null;

  return (
    <Modal
      isOpen={isOpen}
      title={locale?.modalTitle}
      buttons={Buttons}
      onClose={close}
      contentPaddingX="grow px-0 desktop:px-[30px]"
      contentPaddingY="py-0 desktop:py-[30px]"
      disableMobileGrow
      contentContainerDefaultBackground="bg-white"
      contentContainerDefaultBorder="border-b-2 border-base-content"
    >
      {providerDataLoading ? (
        <Loader />
      ) : (
        <div className="flex flex-col gap-5 desktop:gap-[60px] desktop:flex-row">
          <div className="flex flex-col py-2.5 desktop:max-h-[450px] desktop:flex-1">
            <div className="flex bg-base-content p-5 desktop:p-8 rounded-[10px] w-full">
              <div className="flex flex-col w-full">
                <p className="text-lg font-semibold text-charcoal-gray mb-3">
                  {locale?.chooseVirtualPlatform}
                  <span className="text-clc-red">*</span>
                </p>
                <div className="flex justify-between items-center w-full">
                  <InputComponent
                    type="radio"
                    isDisabled={!dosestopAvailable}
                    radioInputProps={{
                      radioLabelTextWeight: 'font-semibold',
                      radioError: null,
                      radioPosition: 'items-center desktop:items-start',
                      radioInputValue: VIRTUAL_PHARMACIES.dosespot,
                      radioInputLabel: 'DoseSpot for prescription medications',
                      radioInputCheckedValue:
                        virtualPlatformSelect === VIRTUAL_PHARMACIES.dosespot
                          ? VIRTUAL_PHARMACIES.dosespot
                          : undefined,
                      onRadioClick: () =>
                        handleVirtualPlatformSelect(
                          VIRTUAL_PHARMACIES.dosespot,
                        ),
                    }}
                  />
                  <InputComponent
                    type="radio"
                    radioInputProps={{
                      radioLabelTextWeight: 'font-semibold',
                      radioError: null,
                      radioPosition: 'items-center desktop:items-start',
                      radioInputValue: VIRTUAL_PHARMACIES.fullscript,
                      radioInputLabel: 'Fullscript for vitamins & supplements',
                      radioInputCheckedValue:
                        virtualPlatformSelect === VIRTUAL_PHARMACIES.fullscript
                          ? VIRTUAL_PHARMACIES.fullscript
                          : undefined,
                      onRadioClick: () =>
                        handleVirtualPlatformSelect(
                          VIRTUAL_PHARMACIES.fullscript,
                        ),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
});
