import { ModalButtonProps } from 'components/modal/modal.interfaces';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import {
  genericActionsIds,
  MODAL_CONTENT_TO_DOWNLOAD_ID,
  pageIds,
} from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { ListRecord } from './interfaces';
import { ReactComponent as PrintIcon } from 'assets/icons/print.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/file-download.svg';
import { ListWidget } from './ListWidget';
import { ContentBlock } from './ContentBlock';
import Modal from 'components/modal/modalComponent';
import { FromProviderDataWidget } from './FromProviderDataWidget';
import { MedicationsTableList } from './MedicationsTableList';
import { TreatmentSurvey } from './TreatmentSurvey';
import { uniqueId } from 'lodash';
import { useGetMyVisitSummary } from 'hooks/useGetMyVisitSummary';
import Loader from 'components/loaderComponent';
import html2toCanvas from 'html2canvas';

const MyVisitSummaryModalPatient = () => {
  const navigate = useNavigate();
  // TODO: implement connection to back-end to retrieve survey details from summary data and add it as default state
  // COD-2553
  // potentially add a mechanism to when user closes the modal with a option selected and re-opens it, not allow him to
  // change the option in order to avoid multiple answers to the survey
  const [surveyOption, setSelectedSurveyOption] = useState('');
  const location = useLocation();

  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.MY_VISIT_SUMMARY_MODAL,
  });

  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.CLOSE],
    });

  const appointmentId = location?.state?.appointmentId;

  const [myVisitSummary, myVisitSummaryLoading] =
    useGetMyVisitSummary(appointmentId);

  const filesAndMedia = useMemo<ListRecord[]>(
    () =>
      myVisitSummary?.filesAndMedia.map((media, i) => {
        return {
          id: media.id || uniqueId(),
          onClick: media.onClick,
          text: media.text || locale?.mediaGenericTitle + ` ${i + 1}`,
        };
      }) || [],
    [myVisitSummary, locale],
  );

  useEffect(() => {
    const style = document.createElement('style');
    style.innerHTML = '@page wide {size: landscape; margin: 0; }';
    style.id = 'page-orientation';
    document.head.appendChild(style);
    return () => {
      const child = document.getElementById('page-orientation');
      child?.parentNode?.removeChild(child);
    };
  }, []);

  const handleClose = () => navigate(-1);

  const isLoading =
    loading ||
    !locale ||
    genericActionLoading ||
    !genericAction ||
    !myVisitSummary ||
    myVisitSummaryLoading;

  if (!appointmentId) {
    return null;
  }

  const Buttons: ModalButtonProps[] = [
    {
      label: genericAction?.[genericActionsIds.CLOSE].close,
      onClick: handleClose,
    },
    {
      type: 'icon',
      Icon: DownloadIcon,
      onClick: () => {
        const visitSummary = document.getElementById(
          MODAL_CONTENT_TO_DOWNLOAD_ID,
        );
        html2toCanvas(visitSummary as HTMLElement)
          .then((canvas) => {
            const dataURL = canvas
              .toDataURL('image/jpeg')
              .replace('image/jpeg', 'image/octet-stream');
            const a = document.createElement('a');
            a.href = dataURL;
            a.download = 'summary.png';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          })
          .catch((e) => {
            console.log(e);
          });
      },
    },
    {
      type: 'icon',
      Icon: PrintIcon,
      onClick: () => {
        window.print();
      },
    },
  ];

  const surveyOptionHandler = (value: string) => {
    setSelectedSurveyOption(value);

    // TODO: implement connection to back-end to send survey details with summary data
    // COD-2553
  };

  return (
    <Modal
      isOpen={true}
      title={locale?.title}
      modalDesktopWidth="desktop:max-w-[1140px]"
      disableMobileGrow={true}
      buttons={Buttons}
      onClose={handleClose}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <div className="flex flex-col gap-5 desktop:gap-[60px] desktop:flex-row">
          <div className="flex flex-col py-2.5 gap-8 min-h-[790px] desktop:flex-1">
            <div className="flex flex-col">
              <h1 className="font-semibold text-base text-charcoal-gray mb-2.5">
                {locale.date}
                <span className="ml-1 font-normal text-base text-med-gray">
                  {myVisitSummary?.summaryDate}
                </span>
              </h1>
              <h1 className="font-semibold text-lg text-charcoal-gray">
                {myVisitSummary?.subject}
              </h1>
            </div>

            {myVisitSummary?.summary && (
              <ContentBlock
                content={myVisitSummary.summary}
                title={locale.providerSummary}
                titlesInBold={true}
              />
            )}
            {myVisitSummary?.recommendations && (
              <ContentBlock
                content={myVisitSummary.recommendations}
                title={locale.followUpRecommendations}
                titlesInBold={true}
              />
            )}
            {!!(
              myVisitSummary?.medications && myVisitSummary?.medications.length
            ) && (
              <MedicationsTableList
                locale={locale}
                title={locale?.medications}
                list={myVisitSummary.medications}
              />
            )}
          </div>
          <div className="flex flex-col w-full gap-[30px] desktop:w-[280px]">
            <div className="flex flex-col order-1 desktop:order-2 gap-[30px]">
              {myVisitSummary?.fromProviderData && (
                <FromProviderDataWidget
                  altImage={locale.altImage}
                  locale={locale}
                  fromProviderData={myVisitSummary.fromProviderData}
                />
              )}

              {myVisitSummary && myVisitSummary.filesAndMedia.length > 0 && (
                <ListWidget
                  title={locale.additionalFilesProviderMedia}
                  list={filesAndMedia}
                />
              )}

              {myVisitSummary && myVisitSummary.related.length > 0 && (
                <ListWidget
                  title={locale.related}
                  list={myVisitSummary.related}
                />
              )}
              <TreatmentSurvey
                locale={locale.treatmentSurvey}
                onOptionSelected={surveyOptionHandler}
                optionSelected={surveyOption}
                desktopHidden={false}
                title={locale.howTreatmentWorked}
              />
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default MyVisitSummaryModalPatient;
