import { ProfileSectionTypes } from 'app/my-account/pages/my-profile/enums';
import ButtonComponent from 'components/button/buttonComponent';
import Loader from 'components/loaderComponent';
import { ModalButtonProps } from 'components/modal/modal.interfaces';
import Modal from 'components/modal/modalComponent';
import { useGetComponent } from 'hooks/useGetComponent';
import useUserValidation from 'hooks/useUserValidation';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { componentIds } from 'utilities/constants';
import {
  MY_ACCOUNT_HEALTH_QUESTIONNAIRE,
  MY_ACCOUNT_PROFILE,
  MY_PROFILE_VALIDATE_PERSONA,
} from 'utilities/routes';

export interface ProtectedRoutesModalProps {
  children: React.ReactNode;
}

export interface Validations {
  isPersonalInfoValid: boolean;
  isContactInfoValid: boolean;
  isQuestionnaireValid: boolean;
  isPersonaVerified: boolean;
  generalValidation: boolean;
}

interface MissingType {
  title: string;
  description: string;
  buttonText: string;
  buttonAction: () => void;
}

const ProtectedRoutesModal: React.FC<ProtectedRoutesModalProps> = ({
  children,
}) => {
  const [validations, setValidations] = useState<Validations>();
  const { data: locale } = useGetComponent({
    locale: 'en',
    componentId: componentIds.PROTECTED_ROUTE_MODAL,
  });
  const navigate = useNavigate();
  const { userValidation } = useUserValidation(true);
  const runValidation = useCallback(async () => {
    setValidations(await userValidation());
  }, [userValidation]);
  useEffect(() => {
    runValidation();
  }, [runValidation]);

  const handleClose = () => navigate(-1);

  const missing = useMemo(() => {
    if (!locale) return [];
    const missing: MissingType[] = [];
    if (!validations?.isPersonalInfoValid) {
      missing.push({
        title: locale.noPersonalInfoTitle,
        description: locale.noPersonalInfoDescription,
        buttonText: locale.noPersonalInfoButton,
        buttonAction: () =>
          navigate(MY_ACCOUNT_PROFILE, {
            state: {
              section: ProfileSectionTypes.PROFILE,
            },
          }),
      });
    }

    if (!validations?.isContactInfoValid) {
      missing.push({
        title: locale.noContactInfoTitle,
        description: locale.noContactInfoDescription,
        buttonText: locale.noContactInfoButton,
        buttonAction: () =>
          navigate(MY_ACCOUNT_PROFILE, {
            state: {
              section: ProfileSectionTypes.CONTACT,
            },
          }),
      });
    }

    if (!validations?.isQuestionnaireValid) {
      missing.push({
        title: locale.noQuestionnaireTitle,
        description: locale.noQuestionnaireDescription,
        buttonText: locale.noQuestionnaireButton,
        buttonAction: () => navigate(MY_ACCOUNT_HEALTH_QUESTIONNAIRE),
      });
    }

    if (!validations?.isPersonaVerified) {
      missing.push({
        title: locale.noPersonaTitle,
        description: locale.noPersonaDescription,
        buttonText: locale.noPersonaButton,
        buttonAction: () => navigate(MY_PROFILE_VALIDATE_PERSONA),
      });
    }

    return missing;
  }, [
    navigate,
    validations?.isContactInfoValid,
    validations?.isPersonaVerified,
    validations?.isPersonalInfoValid,
    validations?.isQuestionnaireValid,
    locale,
  ]);

  if (!validations || !locale) return <Loader />;
  const Buttons: ModalButtonProps[] = [
    {
      label: locale?.goBack,
      disabled: false,
      onClick: handleClose,
    },
  ];
  const isOpen = !validations.generalValidation;
  return (
    <>
      {isOpen && (
        <Modal
          isOpen={isOpen}
          disableBackdropClick
          onClose={handleClose}
          title={locale?.title}
          buttons={Buttons}
        >
          <div className="flex flex-col gap-5 px-4">
            {missing.map(({ buttonAction, buttonText, description, title }) => {
              return (
                <div className="flex flex-row justify-between items-center">
                  <div className="flex flex-col justify-start items-start">
                    <p className="text-lg font-bold">{title}</p>
                    <p className="text-base text-med-gray font-medium">
                      {description}
                    </p>
                  </div>
                  <ButtonComponent onClick={buttonAction}>
                    {buttonText}
                  </ButtonComponent>
                </div>
              );
            })}
          </div>
        </Modal>
      )}
      {children}
    </>
  );
};

export default ProtectedRoutesModal;
