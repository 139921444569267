import ButtonComponent from 'components/button/buttonComponent';
import { useGetPage } from 'hooks/useGetPage';
import { pageIds } from 'utilities/constants';
import shopImage from 'assets/images/shopImage.png';

export const ShopsWidget: React.FC = () => {
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.DASHBOARD,
  });

  if (loading && !locale) return null;

  return (
    <div className="flex flex-col items-center px-5 pt-5 pb-[30px] desktop:p-[30px] gap-5 bg-white rounded-10">
      <div className="flex flex-col w-full items-start p-0 gap-2.5">
        <div className="flex flex-row items-center p-0 gap-2.5">
          <div className="flex text-h3 text-dark-gray font-semibold">
            {locale.shop.title}
          </div>
        </div>
        <hr className="flex flex-row w-full items-center h-px bg-black-blur" />
      </div>

      <div className="flex flex-col w-full items-start p-0 gap-5">
        <div className="flex flex-col items-start gap-2.5 text-base text-dark-gray font-medium">
          {locale.shop.content}
        </div>
      </div>

      <div className="w-[248px] h-[200px] rounded-10 px-[45px]">
        <img src={shopImage} alt={locale.shop.altImage} />
      </div>

      <div className="flex flex-row w-full justify-center items-center p-0 gap-[30px]">
        <ButtonComponent
          type="outlined"
          iconPosition="right"
          iconWidth="w-2.5"
          iconHeight="h-[18px]"
          onClick={() => window.open(process.env.REACT_APP_SHOP, '_blank')}
        >
          {locale.shop.button}
        </ButtonComponent>
      </div>
    </div>
  );
};
