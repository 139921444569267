import React from 'react';

interface EmptyStateComponentProps {
  title?: string;
  text?: string;
  ctaButton?: React.ReactElement;
}

const EmptyStateComponent: React.FC<EmptyStateComponentProps> = ({
  title,
  text,
  ctaButton,
}) => {
  return (
    <div className="flex flex-col desktop:items-center">
      <h1 className="font-exo text-left text-mobileH2 desktop:text-h2 text-charcoal-gray font-medium mb-3">
        {title}
      </h1>
      <p className="text-h6 text-med-gray-3 font-medium max-w-[752px] desktop:text-center">
        {text}
      </p>
      {!!ctaButton && (
        <div className="flex flex-row w-full desktop:w-auto">{ctaButton}</div>
      )}
    </div>
  );
};

export default EmptyStateComponent;
