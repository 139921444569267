import React, { useEffect, useState } from 'react';
import DNATestKitCard from './DNATestKitCard';
import { Dnavisit_Test_Statuses_Enum } from 'graphql/generated/hasura';
import { TestKitsTableData } from '../interfaces';

interface Props {
  data: TestKitsTableData[];
  onLoadComplete: () => void;
}

const DnaTestKitMobileTable: React.FC<Props> = ({ data, onLoadComplete }) => {
  const [cardsCompleted, setCardsCompleted] = useState<number>(0);

  useEffect(() => {
    if (data.length) {
      const completedTests = data.filter(
        (test) => test.status === Dnavisit_Test_Statuses_Enum.Complete,
      );
      if (!completedTests.length) {
        onLoadComplete();
      }

      if (completedTests.length === cardsCompleted) {
        onLoadComplete();
      }
    }
  }, [data, cardsCompleted, onLoadComplete]);

  return (
    <>
      {data.map((testKit) => (
        <DNATestKitCard
          key={testKit.testID}
          data={testKit}
          onLoadComplete={() => setCardsCompleted((c) => c + 1)}
        />
      ))}
    </>
  );
};

export default DnaTestKitMobileTable;
