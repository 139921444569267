interface AQInfoLocale {
  categoryA: string;
  categoryB: string;
  categoryC: string;
  categoryD: string;
  categoryE: string;
  categoryF: string;
  aqIndex: {
    good: string;
    goodLevels: string;
    goodWarning: string;
    moderate: string;
    moderateLevels: string;
    moderateWarning: string;
    unhealthySensitive: string;
    unhealthySensitiveLevels: string;
    unhealthySensitiveWarning: string;
    unhealthy: string;
    unhealthyLevels: string;
    unhealthyWarning: string;
    veryUnhealthy: string;
    veryUnhealthyLevels: string;
    veryUnhealthyWarning: string;
    hazardous: string;
    hazardousLevels: string;
    hazardousWarning: string;
    aqiInfo: string;
    youCanThink: string;
    aqiValue: string;
    aqInfoTitle: string;
  };
}

interface UVInfoLocale {
  categoryA: string;
  categoryB: string;
  categoryC: string;
  categoryD: string;
  categoryE: string;
  uvIndex: {
    low: string;
    lowLevels: string;
    lowWarning: string;
    moderate: string;
    moderateLevels: string;
    moderateWarning: string;
    high: string;
    highLevels: string;
    highWarning: string;
    veryHigh: string;
    veryHighLevels: string;
    veryHighWarning: string;
    extreme: string;
    extremeLevels: string;
    indexProvides: string;
    important: string;
    forMoreInformation: string;
    nationalWeatherServiceLink: string;
    nationalWeatherService: string;
    webpage: string;
    uvInfoTitle: string;
  };
}

interface UVWarningLocale {
  uvIndex: {
    additionalInformation: string;
    lowWarning: string;
    moderateWarning: string;
    highWarning: string;
    veryHighWarning: string;
    usEpa: string;
    website: string;
    usEpaLink: string;
  };
}

export interface AQInfoProps {
  locale: AQInfoLocale;
}

export interface CurrentConditionsProps {
  condition: {
    id: number;
  };
  tempF: number;
  tempC: number;
  isDay: boolean;
  locale: {
    currentConditions: string;
    farenheit: string;
    celsius: string;
  };
}

export interface UVInfoProps {
  locale: UVInfoLocale;
}

export interface UVWarningProps {
  locale: UVWarningLocale;
}

export interface WeatherData {
  tempC: number;
  tempF: number;
  isDay: boolean;
  aqIndex: number;
  uvIndex: number;
  location: {
    name: string;
    country: string;
  };
  condition: {
    id: number;
  };
}

export enum Conditions {
  THUNDERSTORM_WITH_LIGHT_RAIN = 200,
  THUNDERSTORM_WITH_RAIN = 201,
  THUNDERSTORM_WITH_HEAVY_RAIN = 202,
  LIGHT_THUNDERSTORM = 210,
  THUNDERSTORM = 211,
  HEAVY_THUNDERSTORM = 212,
  RAGGED_THUNDERSTORM = 221,
  THUNDERSTORM_WITH_LIGHT_DRIZZLE = 230,
  THUNDERSTORM_WITH_DRIZZLE = 231,
  THUNDERSTORM_WITH_HEAVY_DRIZZLE = 232,

  LIGHT_INTENSITY_DRIZZLE = 300,
  DRIZZLE = 301,
  HEAVY_INTENSITY_DRIZZLE = 302,
  LIGHT_INTENSITY_DRIZZLE_RAIN = 310,
  DRIZZLE_RAIN = 311,
  HEAVY_INTENSITY_DRIZZLE_RAIN = 312,
  SHOWER_RAIN_AND_DRIZZLE = 313,
  HEAVY_SHOWER_RAIN_AND_DRIZZLE = 314,
  SHOWER_DRIZZLE = 321,

  LIGHT_RAIN = 500,
  MODERATE_RAIN = 501,
  HEAVY_INTENSITY_RAIN = 502,
  VERY_HEAVY_RAIN = 503,
  EXTREME_RAIN = 504,
  FREEZING_RAIN = 511,
  LIGHT_INTENSITY_SHOWER_RAIN = 520,
  SHOWER_RAIN = 521,
  HEAVY_INTENSITY_SHOWER_RAIN = 522,
  RAGGED_SHOWER_RAIN = 531,

  LIGHT_SNOW = 600,
  SNOW = 601,
  HEAVY_SNOW = 602,
  SLEET = 611,
  LIGHT_SHOWER_SLEET = 612,
  SHOWER_SLEET = 613,
  LIGHT_RAIN_AND_SNOW = 615,
  RAIN_AND_SNOW = 616,
  LIGHT_SHOWER_SNOW = 620,
  SHOWER_SNOW = 621,
  HEAVY_SHOWER_SNOW = 622,

  MIST = 701,
  SMOKE = 711,
  HAZE = 721,
  SAND_DUST_WHIRLS = 731,
  FOG = 741,
  SAND = 751,
  DUST = 761,
  VOLCANIC_ASH = 762,
  SQUALLS = 771,
  TORNADO = 781,

  CLEAR_SKY = 800,

  FEW_CLOUDS_11_25 = 801,
  SCATTERED_CLOUDS_25_50 = 802,
  BROKEN_CLOUDS_51_84 = 803,
  OVERCAST_CLOUDS_85_100 = 804,
}
