import React from 'react';
import { pageIds } from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';
import { ReactComponent as Inflammations } from 'assets/icons/inflammations.svg';
import { ReactComponent as Comedones } from 'assets/icons/comedones.svg';
import { ReactComponent as Pih } from 'assets/icons/pih.svg';
import { ReactComponent as Total } from 'assets/icons/total.svg';

interface ScanIndicatorsProps {
  inflammations: number;
  comedones: number;
  pih: number;
  total: number;
  date: string;
  orientation?: 'row' | 'col';
  flagSize?: 'default' | 'large';
  hideDate?: boolean;
}

const ScanIndicators: React.FC<ScanIndicatorsProps> = ({
  inflammations,
  comedones,
  pih,
  total,
  date,
  orientation = 'row',
  flagSize = 'default',
  hideDate = false,
}) => {
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.DASHBOARD,
  });
  const orientationClassName =
    orientation === 'row'
      ? 'desktop:flex-row desktop:gap-[30px]'
      : 'desktop:flex-col gap-3 desktop:mb-5';

  const sizeFactor = flagSize === 'large' ? 1.6 : 1;
  const fontSizeClass = orientation === 'row' ? 'text-base' : 'text-sm';

  if (loading && !locale) return null;

  return (
    <div className="flex flex-col w-full items-start p-0 gap-[15px] desktop:gap-2.5">
      <div
        className={`flex flex-col ${orientationClassName} w-full items-start p-0`}
      >
        <div className="flex flex-row items-center p-0 gap-2.5">
          <Inflammations width={10 * sizeFactor} height={10 * sizeFactor} />
          <p className={`${fontSizeClass} font-semibold text-med-gray`}>
            {inflammations} {locale?.dermscore?.inflammations}
          </p>
        </div>
        <div className="flex flex-row items-center p-0 gap-2.5">
          <Comedones width={12 * sizeFactor} height={10 * sizeFactor} />
          <p className={`${fontSizeClass} font-semibold text-med-gray`}>
            {comedones} {locale?.dermscore?.comedones}
          </p>
        </div>
        <div className="flex flex-row items-center p-0 gap-2.5">
          <Pih width={11 * sizeFactor} height={10 * sizeFactor} />
          <p className={`${fontSizeClass} font-semibold text-med-gray`}>
            {pih} {locale?.dermscore?.pih}
          </p>
        </div>
        <div className="flex flex-row items-center p-0 gap-2.5">
          <Total width={10 * sizeFactor} height={10 * sizeFactor} />
          <p className={`${fontSizeClass} font-semibold text-med-gray`}>
            {total} {locale?.dermscore?.total}
          </p>
        </div>
      </div>
      {!hideDate && (
        <div className="w-full text-base desktop:text-sm font-semibold text-med-gray">
          {date}
        </div>
      )}
    </div>
  );
};

export default ScanIndicators;
