import ButtonComponent from 'components/button/buttonComponent';
import { ReactComponent as ArrowLoadMoreIcon } from 'assets/icons/arrowLoadMore.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/chevron-right.svg';
import { ReactComponent as AddIcon } from 'assets/icons/addIcon.svg';
import React from 'react';
import IconComponent from 'components/iconComponent';
import { UpcomingAppoinment } from 'app/my-appointments/interfaces';
import { useNavigate } from 'react-router-dom';
import {
  MY_APPOINTMENTS,
  MY_APPOINTMENTS_SCHEDULE_AN_APPOINTMENT,
} from 'utilities/routes';

export interface AppointmentsTableProps {
  upcomingAppointments: UpcomingAppoinment[];
  UpcomingAppointmentMobileRow: React.FC<
    UpcomingAppoinment & { isDashboardView: boolean; hideProvider?: boolean }
  >;
  UpcomingAppointmentDesktopRow: React.FC<
    UpcomingAppoinment & { isDashboardView: boolean; hideProvider?: boolean }
  >;
  HandleOnLoadMore?: () => void;
  CanLoadMore?: boolean;
  TableHeader?: React.FC;
  isDashboardView?: boolean;
  totalAppointments: number;
  disableLoadMore?: boolean;
  locale?: Record<string, string>;
  isPatient?: boolean;
}

export const UpcomingAppointmentsTable = ({
  upcomingAppointments,
  UpcomingAppointmentDesktopRow,
  UpcomingAppointmentMobileRow,
  HandleOnLoadMore,
  CanLoadMore = false,
  TableHeader,
  isDashboardView = false,
  totalAppointments,
  disableLoadMore = false,
  locale,
  isPatient = false,
}: AppointmentsTableProps) => {
  const navigate = useNavigate();
  const handleNavigateToAllAppointments = () => navigate(MY_APPOINTMENTS);
  const handleNavigateToScheduleAppointment = () =>
    navigate(MY_APPOINTMENTS_SCHEDULE_AN_APPOINTMENT);

  return (
    <div className="table flex-col w-full gap-5">
      {TableHeader && <TableHeader />}
      {upcomingAppointments.map((appointment) => (
        <React.Fragment key={appointment.appointmentId}>
          <UpcomingAppointmentMobileRow
            {...appointment}
            isDashboardView
            hideProvider={!isPatient}
          />
          <UpcomingAppointmentDesktopRow
            {...appointment}
            isDashboardView
            hideProvider={!isPatient}
          />
        </React.Fragment>
      ))}
      <hr className="flex flex-row w-full items-center h-px bg-black-blur" />
      <div className="w-full flex flex-col items-center desktop:items-start pt-3">
        {isDashboardView ? (
          <div className="flex text-clc-blue font-bold text-h7 w-full flex-col items-center desktop:flex-row desktop:justify-between">
            <div
              className="flex items-center hover:cursor-pointer"
              onClick={handleNavigateToAllAppointments}
            >
              <a>{locale?.viewAllAppointments}</a>
              <div className="flex justify-center w-2.5 ml-[7px]">
                <IconComponent
                  height="h-[18px]"
                  fill="fill-clc-blue"
                  icon={ArrowRightIcon}
                  width="w-[10px]"
                />
              </div>
            </div>
            <div className="mt-5 desktop:mt-0">
              <ButtonComponent
                iconPosition="left"
                Icon={AddIcon}
                type="outlined"
                hoverFill="text-white"
                hoverStroke="text-white"
                onClick={handleNavigateToScheduleAppointment}
              >
                {locale?.scheduleAppointment}
              </ButtonComponent>
            </div>
          </div>
        ) : (
          <>
            {disableLoadMore === false &&
              totalAppointments !== upcomingAppointments.length && (
                <ButtonComponent
                  disabled={!CanLoadMore}
                  type="underline"
                  iconPosition="right"
                  Icon={ArrowLoadMoreIcon}
                  onClick={HandleOnLoadMore}
                >
                  {locale?.seeMore}
                </ButtonComponent>
              )}
          </>
        )}
      </div>
    </div>
  );
};
