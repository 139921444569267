import Modal from 'components/modal/modalComponent';
import { useGetPage } from 'hooks/useGetPage';
import React from 'react';
import { pageIds } from 'utilities/constants';

interface Props {
  pdfReportURI: string;
  handleCloseModal: () => void;
}
const MySkinSummary: React.FC<Props> = ({ pdfReportURI, handleCloseModal }) => {
  const { data: locale, loading: loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.MY_SKIN_SUMMARY,
  });

  if (loading || !locale) return null;

  return (
    <Modal
      isOpen={true}
      title={locale.mySkinSummary}
      modalDesktopWidth="desktop:max-w-[1410px]"
      onClose={handleCloseModal}
    >
      <div className="flex h-[450px] grow desktop:min-h-[825px] p-[30px] bg-white items-center justify-center w-full">
        {pdfReportURI && (
          <embed
            title="PDF Viewer"
            type="application/pdf"
            src={`${pdfReportURI}&embedded=true`}
            width="100%"
            height="100%"
          />
        )}
      </div>
    </Modal>
  );
};

export default MySkinSummary;
