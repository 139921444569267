import { ReactComponent as FileDownloadIcon } from 'assets/icons/file-download.svg';
import { ReactComponent as PrintIcon } from 'assets/icons/print.svg';
import { ReactComponent as EyeIcon } from 'assets/icons/pwEyeShow.svg';
import { printPdf } from 'utilities/functions';
import { usePdfDownload } from 'hooks/usePdfDownload';
import {
  SharedMedia,
  SharedMediaModalLocationState,
} from '../interfaces/interfaces';
import { useNavigate } from 'react-router-dom';
import {
  genericActionsIds,
  PARAM_MODALS_IDENTIFIERS,
} from 'utilities/constants';
import ButtonComponent from '../../../components/button/buttonComponent';
import React from 'react';
import { useGetGenericActions } from '../../../hooks/useGetGenericActions';

interface SharedMediaActionsProps {
  media: SharedMedia;
  alignIconsRight?: boolean;
  hidePrint?: boolean;
  hideShow?: boolean;
}

const SharedMediaActions: React.FC<SharedMediaActionsProps> = ({
  alignIconsRight = false,
  media,
  hideShow = false,
}) => {
  const alignIconsRightClass = alignIconsRight
    ? 'justify-end'
    : 'justify-center';

  const { downloadPdfWithURI } = usePdfDownload();
  const navigate = useNavigate();

  const { data: genericAction } = useGetGenericActions({
    locale: 'en',
    genericActionId: [
      genericActionsIds.VIEW,
      genericActionsIds.DOWNLOAD,
      genericActionsIds.PRINT,
    ],
  });

  const handleOnView = async () => {
    const state: SharedMediaModalLocationState = {
      mediaDate: media.date,
      mediaSrc: media.img,
      appointmentCodexId: media.appointmentId,
      bodySite: media.bodySite,
      description: media.description,
    };
    navigate(`.?${PARAM_MODALS_IDENTIFIERS.SHARED_MEDIA_MODAL_ID}=true`, {
      state,
    });
  };

  const handleOnDownload = async () => {
    downloadPdfWithURI(media.img);
  };

  const handleOnPrint = async () => {
    printPdf(media.img);
  };

  return (
    <div
      className={`flex flex-row max-mobile:flex-col desktop:flex-col w-full desktop:w-auto items-start justify-center desktop:${alignIconsRightClass} p-0 gap-3`}
    >
      {!hideShow && (
        <>
          <ButtonComponent
            className="text-sm font-exo"
            type="underline"
            Icon={EyeIcon}
            iconPosition="left"
            stroke="stroke-transparent"
            containerClassName="!gap-1.5 max-mobile:justify-start"
            onClick={handleOnView}
          >
            {genericAction?.[genericActionsIds.VIEW]?.view}
          </ButtonComponent>
          <ButtonComponent
            className="text-sm font-exo"
            type="underline"
            containerClassName="!gap-1.5 max-mobile:justify-start"
            Icon={FileDownloadIcon}
            iconPosition="left"
            stroke="stroke-transparent"
            onClick={handleOnDownload}
          >
            {genericAction?.[genericActionsIds.DOWNLOAD]?.download}
          </ButtonComponent>
          <ButtonComponent
            className="text-sm font-exo"
            containerClassName="!gap-1.5 max-mobile:justify-start"
            type="underline"
            Icon={PrintIcon}
            iconPosition="left"
            stroke="stroke-transparent"
            onClick={handleOnPrint}
          >
            {genericAction?.[genericActionsIds.PRINT]?.print}
          </ButtonComponent>
        </>
      )}
    </div>
  );
};

export default SharedMediaActions;
