import { useGetUserAutoApprovalPreferencesQuery } from 'graphql/generated/hasura';
import { ToggleComponent } from './ToggleComponent';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'auth/context/AuthContext';

import { AccountSettingsState } from '../interfaces/providerModuleInterfaces';
import {
  AutomaticApprovalPreferencesKeys,
  AutomaticApprovalPreferencesProps,
  AutomaticApprovalPreferencesState,
} from '../interfaces/automaticApprovalPreferencesInterfaces';
import { ReactComponent as AlertInfoIcon } from 'assets/icons/alertInfo.svg';

export const AutomaticApprovalPreferences: React.FC<
  AutomaticApprovalPreferencesProps
> = ({ locale, setAccountSettings, setIsAllSectionsLoaded }) => {
  const [
    automaticApprovalPreferencesStatus,
    setAutomaticApprovalPreferenceStatus,
  ] = useState<AutomaticApprovalPreferencesState | null>(null);

  const { user: loggedUser } = useContext(AuthContext);

  useGetUserAutoApprovalPreferencesQuery({
    variables: {
      id: loggedUser?.uuid,
    },
    onCompleted: (data) => {
      setAutomaticApprovalPreferenceStatus({
        AUTO_APPROVAL_PATIENTS_CODEX_LABS_SETTING:
          data.users[0].AUTO_APPROVAL_PATIENTS_CODEX_LABS_SETTING,
        AUTO_APPROVAL_TESTKIT_SCRIPTS_CODEX_LABS_SETTING:
          data.users[0].AUTO_APPROVAL_TESTKIT_SCRIPTS_CODEX_LABS_SETTING,
      });
      setIsAllSectionsLoaded((prevState) => ({
        ...prevState,
        automaticApprovalPreferences: true,
      }));
    },
  });

  const toggleHandler = (
    key: AutomaticApprovalPreferencesKeys,
    state: boolean,
  ) => {
    setAutomaticApprovalPreferenceStatus({
      ...automaticApprovalPreferencesStatus,
      [AutomaticApprovalPreferencesKeys[key]]: state,
    } as AutomaticApprovalPreferencesState);
  };

  useEffect(() => {
    setAccountSettings((prevState) => {
      return {
        ...prevState,
        ...automaticApprovalPreferencesStatus,
      } as AccountSettingsState;
    });
  }, [automaticApprovalPreferencesStatus, setAccountSettings]);

  return (
    <div>
      <div className="mb-[30px]">
        <p className="text-title text-charcoal-gray font-bold uppercase">
          {locale.autoApprovalTitle}
        </p>
        <p className="text-sm font-semibold text-med-gray">
          {locale.autoApprovalSubtitle}
        </p>
        {automaticApprovalPreferencesStatus !== null && (
          <>
            <div>
              <ToggleComponent
                title={locale.autoApprovalToggleTitle1}
                onChangeHandler={(state) =>
                  toggleHandler(
                    AutomaticApprovalPreferencesKeys.AUTO_APPROVAL_PATIENTS_CODEX_LABS_SETTING,
                    state,
                  )
                }
                checkedState={
                  automaticApprovalPreferencesStatus.AUTO_APPROVAL_PATIENTS_CODEX_LABS_SETTING
                }
                locale={locale}
                disableMarginBottom={true}
              />
              <div className="flex mt-2 desktop:mt-0 desktop:pl-1 w-full flex-row justify-between max-w-[690px] mx-auto">
                <AlertInfoIcon className="h-5 w-5 mr-[14px] shrink-0" />
                <p className="text-base font-semibold text-med-gray">
                  {locale?.autoApprovalToggleNotice}
                </p>
              </div>
            </div>
            <ToggleComponent
              title={locale.autoApprovalToggleTitle2}
              onChangeHandler={(state) =>
                toggleHandler(
                  AutomaticApprovalPreferencesKeys.AUTO_APPROVAL_TESTKIT_SCRIPTS_CODEX_LABS_SETTING,
                  state,
                )
              }
              checkedState={
                automaticApprovalPreferencesStatus.AUTO_APPROVAL_TESTKIT_SCRIPTS_CODEX_LABS_SETTING
              }
              locale={locale}
            />
          </>
        )}
      </div>
    </div>
  );
};
