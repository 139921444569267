import { useContext, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { ReactComponent as CloseIcon } from 'assets/icons/iconClose.svg';
import { ReactComponent as ChevronUpIcon } from 'assets/icons/chevron-up.svg';
import { ReactComponent as ChevronDownIcon } from 'assets/icons/chevron-down.svg';
import { Section } from './navbar.interfaces';
import { useFirebaseSignOut } from '../../firebase/hooks';
import { pageIds } from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';
import { Roles } from '../../firebase/interfaces';
import { useNavbarOptions } from 'hooks/useNavbarOptions';
import {
  DASHBOARD,
  MY_APPOINTMENTS,
  MY_PATIENTS,
  NOTIFICATIONS,
} from 'utilities/routes';
import { AuthContext } from 'auth/context/AuthContext';
import Footer from 'components/footer/footerComponent';
// import AdminRoleToggle from 'components/AdminRoleToggle';
import Numball from 'components/numballComponent';
import { useClearAllSavedDnaScannedDataForUserIdMutation } from 'graphql/generated/hasura';
import { useNotificationCount } from 'notifications/contexts/NotificationsContext';

interface NavbarMobileProps {
  closeNavbarMobile: () => void;
}

const ProviderNavbarMobile: React.FC<NavbarMobileProps> = ({
  closeNavbarMobile,
}) => {
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.NAVBAR_COMPONENT,
  });
  const {
    loadingOptions,
    MyAccountOptions,
    MyAccountRoutes,
    ProviderMyAccountOptions,
    ProviderMyAccountRoutes,
  } = useNavbarOptions();

  const getMyAccountOptionsBasedOnRole: () => string[] = () => {
    switch (userRole) {
      case Roles.PATIENT:
        return MyAccountOptions;
      case Roles.PROVIDER:
        return ProviderMyAccountOptions;
      default:
        return MyAccountOptions;
    }
  };

  const getMyAccountRoutesBasedOnRole: () => Record<string, string> = () => {
    switch (userRole) {
      case Roles.PATIENT:
        return MyAccountRoutes;
      case Roles.PROVIDER:
        return ProviderMyAccountRoutes;
      default:
        return MyAccountRoutes;
    }
  };

  const { user } = useContext(AuthContext);
  const userRole = user?.role;
  // const isAdminRole =
  //   userRole === Roles.ADMIN || userRole === Roles.ADMIN_OBFUSCATED;
  const location = useLocation();
  const [selectedSection, setSelectedSection] = useState<Section>();
  const [isMyAccountOptionsOpen, setMyAccountOptionsOpen] =
    useState<boolean>(false);
  const signOutUser = useFirebaseSignOut();

  const [clearAllSavedDnaScannedDataForUserIdMutation] =
    useClearAllSavedDnaScannedDataForUserIdMutation({
      variables: {
        userId: user?.uuid || '',
      },
    });

  useEffect(() => {
    const route = location.pathname.split('/')[1];
    setSelectedSection((route as Section) || Section.Dashboard);
  }, [location]);

  const isSectionSelected = (section: Section): boolean =>
    selectedSection === section;

  const handleMyAccountOptions = async (routeSelected: string) => {
    if (routeSelected === MyAccountRoutes[locale?.myAccountOptions.logOut]) {
      await clearAllSavedDnaScannedDataForUserIdMutation().catch((e) =>
        console.log('Error on cleaning DNA saved data', e),
      );
      signOutUser();
    } else {
      closeNavbarMobile();
    }
  };

  const { notificationCount: notificationsAndExternalNotificationsCount } =
    useNotificationCount();

  if ((loading && !locale) || loadingOptions) return null;

  return (
    <div className="flex flex-col items-center p-0 bg-white">
      <div className="flex flex-row h-[100px] items-center justify-center p-2.5 gap-2.5">
        <div className="flex flex-col items-center justify-center p-px w-12 h-12">
          <CloseIcon
            className="w-12 h-12 stroke-med-gray"
            onClick={closeNavbarMobile}
          />
        </div>
      </div>

      <div className="flex flex-col w-[300px] items-center p-b[60px]">
        <hr className="flex flex-row w-full items-center h-px bg-black-blur" />
        <NavLink
          to={DASHBOARD}
          className="flex flex-row w-full h-[50px] items-center p-0 gap-2.5"
          onClick={closeNavbarMobile}
        >
          <div
            className={`flex flex-row w-1.5 h-full items-start p-0 ${
              isSectionSelected(Section.Dashboard) ? 'bg-clc-blue' : ''
            }`}
          />
          <div
            className={`flex items-center text-base font-bold ${
              isSectionSelected(Section.Dashboard)
                ? 'text-clc-blue'
                : 'text-dark-gray'
            }`}
          >
            {locale?.dashboard}
          </div>
        </NavLink>

        <hr className="flex flex-row w-full items-center h-px bg-black-blur" />
        <NavLink
          to={MY_PATIENTS}
          className="flex flex-row w-full h-[50px] items-center p-0 gap-2.5"
          onClick={closeNavbarMobile}
        >
          <div
            className={`flex flex-row w-1.5 h-full items-start p-0 ${
              isSectionSelected(Section.MyPatients) ? 'bg-clc-blue' : ''
            }`}
          />
          <div
            className={`flex items-center text-base font-bold ${
              isSectionSelected(Section.MyPatients)
                ? 'text-clc-blue'
                : 'text-dark-gray'
            }`}
          >
            {locale.myPatients}
          </div>
        </NavLink>

        <hr className="flex flex-row w-full items-center h-px bg-black-blur" />
        <NavLink
          to={MY_APPOINTMENTS}
          className="flex flex-row w-full h-[50px] items-center p-0 gap-2.5"
          onClick={closeNavbarMobile}
        >
          <div
            className={`flex flex-row w-1.5 h-full items-start p-0 ${
              isSectionSelected(Section.Appointments) ? 'bg-clc-blue' : ''
            }`}
          />
          <div
            className={`flex items-center text-base font-bold ${
              isSectionSelected(Section.Appointments)
                ? 'text-clc-blue'
                : 'text-dark-gray'
            }`}
          >
            {locale.appointments}
          </div>
        </NavLink>

        <hr className="flex flex-row w-full items-center h-px bg-black-blur" />
        <NavLink
          to={NOTIFICATIONS}
          className="flex flex-row w-full h-[50px] items-center p-0 gap-2.5"
          onClick={closeNavbarMobile}
        >
          <div
            className={`flex flex-row w-1.5 h-full items-start p-0 ${
              isSectionSelected(Section.Notifications) ? 'bg-clc-blue' : ''
            }`}
          />
          <div
            className={`flex items-center text-base font-bold gap-2.5 ${
              isSectionSelected(Section.Notifications)
                ? 'text-clc-blue'
                : 'text-dark-gray'
            }`}
          >
            {locale?.notifications}
            <Numball count={notificationsAndExternalNotificationsCount ?? 0} />
          </div>
        </NavLink>

        <hr className="flex flex-row w-full items-center h-px bg-black-blur" />
        <div
          className="flex flex-row w-full h-[50px] items-center p-0 gap-2.5"
          onClick={() => setMyAccountOptionsOpen(!isMyAccountOptionsOpen)}
        >
          <div
            className={`flex flex-row w-1.5 h-full items-start p-0 ${
              isSectionSelected(Section.MyAccount) ? 'bg-clc-blue' : ''
            }`}
          />
          <div
            className={`flex items-center text-base font-bold w-[250px] ${
              isSectionSelected(Section.MyAccount)
                ? 'text-clc-blue'
                : 'text-dark-gray'
            }`}
          >
            {locale?.myAccount}
          </div>
          {isMyAccountOptionsOpen ? (
            <ChevronUpIcon className="w-[18px] h-2.5 fill-current" />
          ) : (
            <ChevronDownIcon className="w-[18px] h-2.5 fill-current" />
          )}
        </div>
        {isMyAccountOptionsOpen && (
          <div className="flex flex-col w-full items-start pb-2.5">
            {getMyAccountOptionsBasedOnRole().map((option, index) => (
              <NavLink
                to={`${getMyAccountRoutesBasedOnRole()[option]}`}
                key={index}
                className="flex flex-row h-10 text-base text-med-gray items-center pl-[37px]"
                onClick={() =>
                  handleMyAccountOptions(
                    `${getMyAccountRoutesBasedOnRole()[option]}`,
                  )
                }
              >
                {option}
              </NavLink>
            ))}
          </div>
        )}

        <hr className="flex flex-row w-full items-center h-px bg-black-blur" />

        {/* {isAdminRole && (
          <div className="flex flex-row items-center mt-8 cursor-pointer">
            <AdminRoleToggle />
          </div>
        )} */}
      </div>
      <Footer type={'navbar'} closeNavbarMobile={closeNavbarMobile} />
    </div>
  );
};

export default ProviderNavbarMobile;
