import Loader from 'components/loaderComponent';
import { useUnlinkFullscriptMutation } from 'graphql/generated/remote-schema-hasura';
import { useEffect, useState } from 'react';

export const FullscriptUnlink: React.FC = () => {
  const [result, setResult] = useState<string>();
  const [unlinkProvider, { loading }] = useUnlinkFullscriptMutation({
    onCompleted: (data) => {
      if (data.unlinkFullscript.update_providers_one?.id) {
        setResult('success');
      } else {
        setResult('error');
      }
    },
    onError: (err) => {
      console.error(err);
      setResult(`Error: ${JSON.stringify(err)}`);
    },
  });
  useEffect(() => {
    unlinkProvider();
  }, [unlinkProvider]);
  if (!result || loading) {
    return <Loader />;
  }
  return <div>{result}</div>;
};
