import { useCallback, useRef } from 'react';
import { useGetSignedUrlFromStorageFunction } from './useGetSignedUrlFromStorage';

export function useGetAndStoreMediaSignedUrl() {
  const mediaUrlMap = useRef<Map<string, string>>(new Map());
  const getMedia = useGetSignedUrlFromStorageFunction();
  const getMediaUrl = useCallback(
    async (mediaId: string, userId?: string) => {
      if (!mediaUrlMap.current.has(mediaId)) {
        const url = await getMedia(mediaId, userId);
        mediaUrlMap.current.set(mediaId, url || '');
      }
      return mediaUrlMap.current.get(mediaId);
    },
    [getMedia],
  );
  return getMediaUrl;
}
