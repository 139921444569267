export interface MedicationDetailsMedication {
  readonly title: string;
  readonly isOtc: boolean;
  readonly type: string;
  readonly brand?: string;
  readonly delivery: string;
  readonly dosage: string;
  readonly lastUsed: string;
  readonly frequency: string;
  readonly prescribedBy?: string | null;
  readonly usageTime: string;
  readonly notes?: string | null;
  readonly medicationId: string;
  readonly providerId: string;
}

interface MedicationDetailsProps {
  locale: Record<string, string>;
  medication: MedicationDetailsMedication;
}
export const MedicationDetails: React.FC<MedicationDetailsProps> = ({
  locale,
  medication,
}) => {
  return (
    <div className="flex flex-col gap-[10px]">
      <div className="w-full flex flex-row">
        <p className="w-2/4 desktop:w-2/4 text-base font-bold">
          {locale?.title1}:
        </p>
        <p className="w-2/4 desktop:w-3/4 text-base font-semibold text-med-gray">
          {medication.isOtc ? locale.OTC : locale.prescription}
        </p>
      </div>
      <div className="w-full flex flex-row">
        <p className="w-2/4 desktop:w-2/4 text-base font-bold">
          {locale?.title2}:
        </p>
        <p className="w-2/4 desktop:w-3/4 text-base font-semibold text-med-gray">
          {medication.type}
        </p>
      </div>
      <div className="w-full flex flex-row">
        <p className="w-2/4 desktop:w-2/4 text-base font-bold">
          {locale?.title3}:
        </p>
        <p className="w-2/4 desktop:w-3/4 text-base font-semibold text-med-gray">
          {medication.brand ?? locale.noBrand}
        </p>
      </div>
      <div className="w-full flex flex-row">
        <p className="w-2/4 desktop:w-2/4 text-base font-bold">
          {locale?.title4}:
        </p>
        <p className="w-2/4 desktop:w-3/4 text-base font-semibold text-med-gray">
          {medication.delivery}
        </p>
      </div>
      <div className="w-full flex flex-row">
        <p className="w-2/4 desktop:w-2/4 text-base font-bold">
          {locale?.title5}:
        </p>
        <p className="w-2/4 desktop:w-3/4 text-base font-semibold text-med-gray">
          {medication.dosage}
        </p>
      </div>
      <div className="w-full flex flex-row">
        <p className="w-2/4 desktop:w-2/4 text-base font-bold">
          {locale?.title6}:
        </p>
        <p className="w-2/4 desktop:w-3/4 text-base font-semibold text-med-gray">
          {medication.lastUsed ?? locale?.notFound}
        </p>
      </div>
      <div className="w-full flex flex-row">
        <p className="w-2/4 desktop:w-2/4 text-base font-bold">
          {locale?.title7}:
        </p>
        <p className="w-2/4 desktop:w-3/4 text-base font-semibold text-med-gray">
          {medication.frequency ?? locale?.notFound}
        </p>
      </div>
      <div className="w-full flex flex-row">
        <p className="w-2/4 desktop:w-2/4 text-base font-bold">
          {locale?.title8}:
        </p>
        <p className="w-2/4 desktop:w-3/4 text-base font-semibold text-med-gray">
          {medication.prescribedBy ?? locale?.notFound}
        </p>
      </div>
      <div className="w-full flex flex-row">
        <p className="w-2/4 desktop:w-2/4 text-base font-bold">
          {locale?.title9}:
        </p>
        <p className="w-2/4 desktop:w-3/4 text-base font-semibold text-med-gray">
          {medication.usageTime ?? locale?.notFound}
        </p>
      </div>
      <div className="w-full flex flex-col">
        <p className="w-2/4 text-base font-bold">{locale?.title10}: </p>
        <p className="w-full text-base font-semibold text-med-gray">
          {medication.notes ?? locale?.notFound}
        </p>
      </div>
    </div>
  );
};
