import React from 'react';

export const MyNotesOutlineIcon: React.ComponentType<
  React.SVGProps<SVGSVGElement>
> = ({ fill, className }) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.91971 15H1V13.0806L9.83713 4.24815L11.7563 6.16736L2.91971 15ZM14.1131 3.80651L12.1935 1.88693L13.0351 1.04527L14.9547 2.96486L14.1131 3.80651Z"
        stroke="#707070"
        stroke-width="2"
      />
    </svg>
  );
};
