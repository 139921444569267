import Loader from 'components/loaderComponent';
import { ModalButtonProps } from 'components/modal/modal.interfaces';
import Modal from 'components/modal/modalComponent';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import { createModal } from 'layout/createModal';
import { useModal } from 'layout/useModal';
import { genericActionsIds } from 'utilities/constants';

const confirmationModalSpec = createModal<
  { text: string; title: string },
  boolean
>('confirmation-modal', ({ close, isOpen, state }) => {
  const handleClose = () => close(false);
  const handleOnContinue = () => close(true);
  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.CANCEL, genericActionsIds.CONTINUE],
    });
  const Buttons: ModalButtonProps[] = [
    {
      label: genericAction?.[genericActionsIds.CONTINUE].continue,
      disabled: false,
      onClick: handleOnContinue,
    },
    {
      label: genericAction?.[genericActionsIds.CANCEL].cancel,
      disabled: false,
      onClick: handleClose,
      type: 'underline',
    },
  ];
  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      buttons={Buttons}
      title={state.title}
    >
      {genericActionLoading ? (
        <Loader />
      ) : (
        <div className="flex px-2">
          <p className="text-med-gray font-medium text-lg">{state.text}</p>
        </div>
      )}
    </Modal>
  );
});

export const useConfirmationModal = () => {
  const [openModal] = useModal(confirmationModalSpec);
  return openModal;
};
