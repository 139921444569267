import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useMemo, useState } from 'react';
import { genericActionsIds } from 'utilities/constants';
import { ModalButtonProps } from 'components/modal/modal.interfaces';
import { formatDateToCustomFormat, printPdf } from 'utilities/functions';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import Modal from 'components/modal/modalComponent';
import { ReactComponent as FileDownloadIcon } from 'assets/icons/file-download.svg';
import { ReactComponent as PrintIcon } from 'assets/icons/print.svg';
import { usePdfDownload } from 'hooks/usePdfDownload';
import Avatar from 'components/avatarComponent';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/cjs/Page/AnnotationLayer.css';
import 'react-pdf/dist/cjs/Page/TextLayer.css';
import { ReactComponent as ChevronLeftIcon } from 'assets/icons/chevron-left-current.svg';
import { ReactComponent as ChevronRightIcon } from 'assets/icons/chevron-right-current.svg';
import InputComponent from 'components/inputComponent';
import { OnDocumentLoadSuccess } from 'react-pdf/dist/cjs/shared/types';
import Loader from 'components/loaderComponent';
import { TestKitResultModalProps } from '../interfaces/testKits.interfaces';

export const TestKitResultModal: React.FC<TestKitResultModalProps> = ({
  locale,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [totalPages, setTotalPages] = useState<number>(-1);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const testPdfSrc: string = location.state?.testPdfSrc;
  const testDate: Date = location.state?.testDate;
  const testName: string = location.state?.testName;
  const patientName: string = location.state?.patientName;
  const patientProfileImageSrc: string = location.state?.patientProfileImageSrc;

  const handlePdfLoad = useCallback<OnDocumentLoadSuccess>(({ numPages }) => {
    setIsLoading(false);
    setTotalPages(numPages);
  }, []);

  const pagesArray = useMemo<string[]>(() => {
    const PAGES_OFFSET = 1;
    return totalPages > 0
      ? [...Array(totalPages).keys()].map((i) => (i + PAGES_OFFSET).toString())
      : [];
  }, [totalPages]);

  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.CLOSE, genericActionsIds.DOWNLOAD],
    });

  const handleClose = () => {
    navigate(-1);
  };

  const { downloadPdfWithURI } = usePdfDownload();

  const handleOnDownload = async () => {
    if (!testPdfSrc) return;
    downloadPdfWithURI(testPdfSrc);
  };

  const handleOnPrint = async () => {
    if (!testPdfSrc) return;
    printPdf(testPdfSrc);
  };

  const Buttons: ModalButtonProps[] = [
    {
      label: genericAction?.[genericActionsIds.CLOSE].close,
      onClick: handleClose,
    },
    {
      type: 'icon',
      Icon: FileDownloadIcon,
      iconHeight: 'h-[14px]',
      onClick: handleOnDownload,
    },
    {
      type: 'icon',
      Icon: PrintIcon,
      iconHeight: 'h-[14px]',
      onClick: handleOnPrint,
    },
  ];
  const handlePageChange = (offset: number) => {
    const newPage = currentPage + offset;
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  if (genericActionLoading || !genericAction || !testPdfSrc) return null;

  return (
    <Modal
      isOpen={true}
      title={locale.viewTestKitResults}
      buttons={Buttons}
      onClose={handleClose}
      contentPaddingY="py-5"
    >
      <>
        <div
          className={`w-full h-full justify-center items-center ${
            !isLoading ? 'hidden' : 'flex'
          }`}
        >
          <Loader />
        </div>
        <div
          className={`flex-col-reverse desktop:flex-row grow gap-4 ${
            isLoading ? 'hidden' : 'flex'
          }`}
        >
          <div className="grow flex flex-col gap-5">
            <div className="grow rounded-10 overflow-x-scroll self-center max-w-full">
              <Document
                file={testPdfSrc}
                onLoadSuccess={handlePdfLoad}
                onError={handleClose}
                className="w-max"
              >
                <Page pageNumber={currentPage} />
              </Document>
            </div>
            <div className="flex flex-row justify-center items-stretch gap-5">
              <button
                disabled={currentPage === 1}
                onClick={() => handlePageChange(-1)}
                className="
                  rounded-5 border border-clc-blue fill-clc-blue w-12 flex justify-center items-center
                active:border-clc-blue-medium active:fill-clc-blue-medium
                disabled:border-med-gray disabled:fill-med-gray
                "
              >
                <ChevronLeftIcon width={10} height={18} fill="current" />
              </button>
              <div className="flex flex-row justify-center items-center gap-1">
                <div className="min-w-[60px]">
                  <InputComponent
                    type="select"
                    name="currentPageSelector"
                    value={currentPage.toString()}
                    selectInputProps={{
                      onSelectChange: (e) =>
                        setCurrentPage(parseInt(e.target.value)),
                      selectValues: pagesArray,
                      fullWidth: true,
                    }}
                  />
                </div>
                <span className="font-semibold text-sm text-med-gray">
                  /{totalPages}
                </span>
              </div>
              <button
                disabled={currentPage === totalPages}
                onClick={() => handlePageChange(1)}
                className="
                  rounded-5 border border-clc-blue fill-clc-blue w-12 flex justify-center items-center
                active:border-clc-blue-medium active:fill-clc-blue-medium
                disabled:border-med-gray disabled:fill-med-gray
                "
              >
                <ChevronRightIcon width={10} height={18} fill="current" />
              </button>
            </div>
          </div>
          <div className="flex gap-4 desktop:gap-2 flex-col w-fit items-start justify-start">
            <span className="font-bold text-charcoal-gray text-base pb-2">
              {locale.date}
              <span className="font-normal text-med-gray">
                {formatDateToCustomFormat(testDate.toISOString(), true)}
              </span>
            </span>
            <span className="font-exo font-semibold text-2xl leading-7 text-charcoal-gray uppercase">
              {testName}
            </span>
            <div className="flex flex-row justify-start items-center w-fit gap-2">
              <Avatar size="xs" imageUrl={patientProfileImageSrc} />
              <span className="font-bold text-charcoal-gray text-base underline w-fit">
                {patientName}
              </span>
            </div>
          </div>
        </div>
      </>
    </Modal>
  );
};
