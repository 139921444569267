import {
  VIRTUAL_PHARMACIES,
  componentIds,
  genericActionsIds,
} from 'utilities/constants';
import Modal from 'components/modal/modalComponent';
import DosespotIFrame from './DosespotIframe';
import { useGetComponent } from 'hooks/useGetComponent';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import { ModalButtonProps } from './modal/modal.interfaces';
import { FullScriptIframe } from './FullScriptIframe';
import { createModal } from 'layout/createModal';

interface PrescribeMedicationModalProps {
  platform: string;
  appointmentCodexId?: string;
  encounterId?: string;
  patientId: string;
}

export const PrescribeMedicationModal = createModal<
  PrescribeMedicationModalProps,
  void
>('prescribe-medications', ({ isOpen, state, close }) => {
  const { platform, appointmentCodexId, encounterId, patientId } = state;
  const { data: locale, loading } = useGetComponent({
    locale: 'en',
    componentId: componentIds.VIRTUAL_PHARMACY_MODAL,
  });

  const handleClose = () => {
    close();
  };

  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [
        genericActionsIds.SKIP_THIS_STEP,
        genericActionsIds.CLOSE,
      ],
    });

  const Buttons: ModalButtonProps[] = [
    {
      label: genericAction?.[genericActionsIds.CLOSE].close,
      disabled: false,
      onClick: handleClose,
    },
    // ,
    // {
    //   label: genericAction?.[genericActionsIds.SKIP_THIS_STEP].skipThisStep,
    //   disabled: false,
    //   onClick: () => console.log('skip this step'),
    //   type: 'outlined',
    // },
  ];

  if (loading && !locale && genericActionLoading) return null;

  return (
    <Modal
      isOpen={isOpen}
      title={locale?.modalTitle}
      modalDesktopWidth="desktop:max-w-[1140px]"
      contentPaddingX="px-2.5"
      onClose={handleClose}
      buttons={Buttons}
    >
      <div className="flex flex-col gap-[30px]">
        <div className="flex flex-col py-2.5 bg-white items-center justify-center w-full min-h-[790px] h-[70vh]">
          {platform === VIRTUAL_PHARMACIES.dosespot && (
            <DosespotIFrame encounterId={encounterId} patientId={patientId} />
          )}
          {platform === VIRTUAL_PHARMACIES.fullscript && (
            <FullScriptIframe
              patientId={patientId}
              appointmentCodexId={appointmentCodexId}
            />
          )}
        </div>
      </div>
    </Modal>
  );
});
