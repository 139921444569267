import { ReactComponent as FileDownloadIcon } from 'assets/icons/file-download.svg';
import { ReactComponent as PrintIcon } from 'assets/icons/print.svg';
import { ReactComponent as EyeIcon } from 'assets/icons/pwEyeShow.svg';
import { useNavigate } from 'react-router-dom';
import { PARAM_MODALS_IDENTIFIERS } from 'utilities/constants';

export interface MyVisitSummariesTableActionsProps {
  appointmentId: string;
}

const MyVisitSummariesTableActions: React.FC<
  MyVisitSummariesTableActionsProps
> = ({ appointmentId }) => {
  const navigate = useNavigate();
  const onEyeClick = () => {
    const state = { appointmentId };
    navigate(
      `.?${PARAM_MODALS_IDENTIFIERS.MY_VISIT_SUMMARY_MODAL_PATIENT_MODAL_ID}=true`,
      { state },
    );
  };
  return (
    <div className="flex py-4 desktop:py-0 flex-row w-full desktop:w-auto items-center justify-around desktop:justify-end p-0 gap-2.5 desktop:gap-[2px]">
      <div
        className="flex flex-row items-center justify-center px-2.5 gap-2.5 rounded-10"
        onClick={onEyeClick}
      >
        <EyeIcon
          onClick={onEyeClick}
          className="w-[18px] h-2.5 fill-current cursor-pointer"
        />
      </div>
      <div className="flex flex-row items-center justify-center px-2.5 gap-2.5 rounded-10">
        <FileDownloadIcon className="w-[15px] h-[18px] cursor-pointer fill-clc-blue" />
      </div>
      <div className="flex flex-row items-center justify-center px-2.5 gap-2.5 rounded-10">
        <PrintIcon className="w-[17px] h-[16px] cursor-pointer fill-clc-blue" />
      </div>
    </div>
  );
};

export default MyVisitSummariesTableActions;
