import {
  GeneralStates,
  PharmacyList,
  PharmacyProps,
} from 'app/my-account/interfaces/profile.interfaces';
import { DropdownItem } from 'components/dynamicQuestionnaire/interfaces/dynamicQuestionnaireResponse.interface';
import {
  PharmacyInformationInput,
  useGetCountriesQuery,
} from 'graphql/generated/hasura';
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  allowCharactersWithMaxLength,
  allowOnlyNumbersAndMaxLengthForZipCodes,
  parseMappedCountriesIntoSelectValues,
  parsePhoneNumber,
  parseSelectedValue,
  parseStringArrayIntoSelectOptions,
  parseValueIntoSelectFormat,
  scrollToTop,
} from 'utilities/functions';
import {
  PerPharmacyCity,
  PerPharmacyCountry,
  PerPharmacyState,
  PerPharmacyStateIso,
  PharmacyInformationInputWithTypename,
} from './pharmaciesInterfaces';
import { City, State } from 'country-state-city';
import InputComponent, {
  SelectWithFlagsValues,
} from 'components/inputComponent';
import { useGetPage } from 'hooks/useGetPage';
import {
  ISO_CODE_TO_EXCLUDE,
  NON_ALPHABETIC_REGEX_PRESERVES_SPACES_AND_CHARACTERS,
  STATES_TO_EXCLUDE,
  genericActionsIds,
  pageIds,
} from 'utilities/constants';
import Select, { SingleValue, components } from 'react-select';
import FormField from './components/FormField';
import { ReactComponent as MinusIcon } from 'assets/icons/minusIcon.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plusIcon.svg';
import { ReactComponent as ChevronUpIcon } from 'assets/icons/chevron-up.svg';
import { ReactComponent as ChevronDownIcon } from 'assets/icons/chevron-down.svg';
import ButtonComponent from 'components/button/buttonComponent';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import ErrorMessageWithIcon from 'components/errorMessageWithIcon';
import { allowOnlyCharactersInCityField } from 'components/dynamicQuestionnaire/utils/constants';
import { usePatchFhirPatientPersonalDataMutation } from 'graphql/generated/remote-schema-hasura';
import { AuthContext } from 'auth/context/AuthContext';
import { ParseIsoValue, PharmacyItem } from './interfaces';

export const Pharmacies: React.FC<PharmacyProps> = ({
  pharmacyList,
  handlePharmacyInfoChange,
  handleUpdatedPharmacyInfo,
}) => {
  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [
        genericActionsIds.UPDATE,
        genericActionsIds.BEGINTYPINGTOLOOKUP,
      ],
    });

  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.MY_PROFILE,
  });

  const [isFormDisplayed, setIsFormDisplayed] = useState<boolean>(true);
  const [pharmacyDataList, setPharmacyDataList] = useState<
    PharmacyInformationInput[]
  >(pharmacyList ?? []);

  const findDefaultPharmaccy = () => {
    const index = pharmacyDataList.findIndex(
      (pharmacy) => pharmacy.isDefault === true,
    );
    return index !== -1 ? index.toString() : '0';
  };

  const [defaultSelectedPharmacyIndex, setDefaultSelectedPharmacyIndex] =
    useState(findDefaultPharmaccy());

  const handleSetDefaultPharmacy = (index: number) => {
    const newPharmacyDefaultSelected = pharmacyDataList.map((pharmacy, idx) => {
      if (idx === index) {
        return { ...pharmacy, isDefault: true };
      } else {
        return { ...pharmacy, isDefault: false };
      }
    });

    setDefaultSelectedPharmacyIndex(index.toString());
    setPharmacyDataList(newPharmacyDefaultSelected);
  };

  const parseIso = (value: ParseIsoValue) => {
    let stringToReturn = '';
    if (typeof value === 'string') {
      stringToReturn = value.split(' - ')[0];
    } else {
      if (value && value.value) {
        stringToReturn = value.value.split(' - ')[0];
      }
    }
    return stringToReturn;
  };

  const [countryIso, setCountryIso] = useState<PerPharmacyCountry[]>([
    {
      pharmacyID: 0,
      country: parseIso(pharmacyDataList[0]?.country as string) ?? '',
    },
  ]);

  const [stateData, setStateData] = useState<PerPharmacyState[]>([
    { pharmacyID: 0, state: [''] },
  ]);

  const [stateIso, setStateIso] = useState<PerPharmacyStateIso[]>([
    {
      pharmacyID: 0,
      stateIso: parseIso(pharmacyDataList[0]?.state ?? '') || '',
    },
  ]);

  const [cityData, setCityData] = useState<PerPharmacyCity[]>([
    {
      pharmacyID: 0,
      city: [''],
    },
  ]);

  const [isIndexedCountryClicked, setIsIndexedCountryClicked] = useState([
    { id: 0, clicked: false },
  ]);

  const [isIndexedStateClicked, setIsIndexedStateClicked] = useState([
    { id: 0, clicked: false },
  ]);

  const [errors, setErrors] = useState<
    Partial<Record<keyof PharmacyList, string | null>>[]
  >([]);

  const validateFields = useCallback(
    (list: PharmacyInformationInput[]) => {
      const newErrors = list.map((pharmacy, index) => {
        const errorObject: Partial<Record<keyof PharmacyList, string | null>> =
          {};
        for (const key in pharmacy) {
          if (key === 'phone') {
            const phoneNumber = parsePhoneNumber(
              pharmacyDataList[index].phone,
            )?.number;

            if (phoneNumber === undefined || phoneNumber === '') {
              errorObject.phone = locale?.pharmacies.fieldRequired;
            } else {
              errorObject.phone = null;
            }
          } else {
            if (
              pharmacy[key as keyof PharmacyList] === '' &&
              key !== 'address2'
            ) {
              errorObject[key as keyof PharmacyList] =
                locale?.pharmacies.fieldRequired;
            } else {
              errorObject[key as keyof PharmacyList] = null;
            }
          }
        }
        return errorObject;
      });
      setErrors(newErrors);
    },
    [locale?.pharmacies.fieldRequired, pharmacyDataList],
  );

  useEffect(() => {
    if (pharmacyDataList) {
      validateFields(pharmacyDataList);
    } else {
      setErrors([]);
    }
  }, [pharmacyDataList, validateFields]);

  const handleOnSelectChange = (
    value: SingleValue<string | DropdownItem>,
    name: string,
    index: number,
  ) => {
    if (name === 'country') {
      let countryCode = '';
      let countryValue = '';

      if (typeof value !== 'string') {
        countryValue = value?.value ?? '';
      }

      if (typeof value === 'string') {
        countryCode = value.split(' - ')[0];
      } else {
        countryCode = value?.value.split(' - ')[0] ?? '';
      }
      setCountryIso((prevState) => {
        const newState = [...prevState];
        newState[index] = {
          ...newState[index],
          country: countryCode,
        };
        return newState;
      });

      const statesIso = State.getStatesOfCountry(countryCode).map(
        (state) => `${state.isoCode}`,
      );

      setStateIso((prevState) => {
        const newState = [...prevState];
        newState[index] = {
          ...newState[index],
          stateIso: statesIso[0],
        };
        return newState;
      });

      setIsIndexedCountryClicked((prevState) => {
        const newState = [...prevState];
        newState[index] = {
          ...newState[index],
          clicked: true,
        };

        return newState;
      });

      setIsIndexedStateClicked((prevState) => {
        const newState = [...prevState];
        newState[index] = {
          ...newState[index],
          clicked: true,
        };
        return newState;
      });

      setPharmacyDataPerInput(index, countryValue, 'country');
    }

    if (name === 'state') {
      let stateCode = '';
      let stateValue = '';

      if (typeof value !== 'string') {
        stateValue = value?.value ?? '';
      }

      if (typeof value === 'string') {
        stateCode = value.split(' - ')[0];
      } else {
        stateCode = value?.value.split(' - ')[0] ?? '';
      }

      setIsIndexedStateClicked((prevState) => {
        const newState = [...prevState];
        newState[index] = {
          ...newState[index],
          clicked: true,
        };
        return newState;
      });

      setStateIso((prevState) => {
        const newState = [...prevState];
        newState[index] = {
          ...newState[index],
          stateIso: stateCode,
        };
        return newState;
      });

      setPharmacyDataPerInput(index, stateValue, 'state');
    }

    if (name === 'city') {
      const cityValue =
        typeof value === 'string'
          ? value.replace(
              NON_ALPHABETIC_REGEX_PRESERVES_SPACES_AND_CHARACTERS,
              '',
            )
          : value?.value?.replace(
              NON_ALPHABETIC_REGEX_PRESERVES_SPACES_AND_CHARACTERS,
              '',
            );

      setPharmacyDataPerInput(index, cityValue as string, 'city');
    }
  };

  const { data: countryNumberData } = useGetCountriesQuery({});

  const mappedCountriesValues = useMemo(
    () =>
      countryNumberData?.countries
        ? [...countryNumberData.countries]
            .sort((a, b) => {
              const codea = parseInt(a.code);
              const codeb = parseInt(b.code);

              if (codea < codeb) {
                return -1;
              } else if (codea > codeb) {
                return 1;
              } else {
                return 0;
              }
            })
            .map((country) => {
              return { code: country.code, flag: country.flag };
            })
        : [],
    [countryNumberData?.countries],
  );

  const handleShowForm = () => {
    setIsFormDisplayed(!isFormDisplayed);
  };

  const getPhonesFromPharmacyList = () => {
    return pharmacyDataList
      .filter((pharmacy) => pharmacy.phone)
      .map((pharmacy) => {
        const parsedPhone = parsePhoneNumber(pharmacy.phone);
        return {
          phone: parsedPhone?.number,
          country: parsedPhone?.country,
          extension: parsedPhone?.extension,
        };
      });
  };

  const [phoneIndexedData, setPhoneIndexedData] = useState<
    { phone: string; country: string; extension: string; pharmacyID: number }[]
  >(getPhonesFromPharmacyList() as PharmacyItem[]);

  const getMemoizedCurrentCountryValue = useCallback(
    (index: number) => {
      return (
        parseSelectedValue(
          phoneIndexedData[index]?.country as string,
          mappedCountriesValues,
        ) ?? undefined
      );
    },
    [phoneIndexedData, mappedCountriesValues],
  );

  const getMemoizedDefaultParsedValue = useMemo(
    () => parseMappedCountriesIntoSelectValues(mappedCountriesValues)[0],
    [mappedCountriesValues],
  );

  const getMemoizedParsedCountries = useMemo(
    () => parseMappedCountriesIntoSelectValues(mappedCountriesValues),
    [mappedCountriesValues],
  );

  const setIndexedPharmacyDefaultListValues = (
    pharmacyDataListLength: number,
  ) => {
    const id = pharmacyDataListLength;

    setCountryIso((prevState) => [
      ...prevState,
      { country: '1', pharmacyID: id },
    ]);

    setStateIso((prevState) => [
      ...prevState,
      { stateIso: '', pharmacyID: id },
    ]);

    setStateData((prevState) => [
      ...prevState,
      { pharmacyID: id, state: [''] },
    ]);

    setCityData((prevState) => [...prevState, { city: [''], pharmacyID: id }]);

    setPhoneIndexedData((prevState) => [
      ...prevState,
      { country: '1', extension: '', phone: '', pharmacyID: id },
    ]);

    setIsIndexedCountryClicked((prevState) => [
      ...prevState,
      { id, clicked: false },
    ]);

    setIsIndexedStateClicked((prevState) => [
      ...prevState,
      { id, clicked: false },
    ]);
  };

  const removeIndexedPharmacyListValues = (idToRemove: number) => {
    const updatedCountryIsoArray = countryIso
      .filter((country) => country.pharmacyID !== idToRemove)
      .map((country, index) => ({
        ...country,
        id: index,
        pharmacyID: index,
      }));

    setCountryIso(updatedCountryIsoArray);

    const updatedStateIsoArray = stateIso
      .filter((state) => state.pharmacyID !== idToRemove)
      .map((country, index) => ({
        ...country,
        id: index,
        pharmacyID: index,
      }));

    setStateIso(updatedStateIsoArray);

    const updatedStateDataArray = stateData
      .filter((state) => state.pharmacyID !== idToRemove)
      .map((state, index) => ({
        ...state,
        id: index,
        pharmacyID: index,
      }));

    setStateData(updatedStateDataArray);

    const updatedCityDataArray = cityData
      .filter((city) => city.pharmacyID !== idToRemove)
      .map((state, index) => ({
        ...state,
        id: index,
        pharmacyID: index,
      }));

    setCityData(updatedCityDataArray);

    const updatedPhoneIndexedArray = phoneIndexedData
      .filter((phoneIndexed) => phoneIndexed.pharmacyID !== idToRemove)
      .map((state, index) => ({
        ...state,
        id: index,
        pharmacyID: index,
      }));

    setPhoneIndexedData(updatedPhoneIndexedArray);

    const updatedCountryClickedIndexedArray = isIndexedCountryClicked
      .filter((country) => country.id !== idToRemove)
      .map((state, index) => ({
        ...state,
        id: index,
        pharmacyID: index,
      }));

    setIsIndexedCountryClicked(updatedCountryClickedIndexedArray);

    const updatedStateClickedIndexedArray = isIndexedStateClicked
      .filter((state) => state.id !== idToRemove)
      .map((state, index) => ({
        ...state,
        id: index,
        pharmacyID: index,
      }));

    setIsIndexedStateClicked(updatedStateClickedIndexedArray);
  };
  const insertAfter = (index: number, pharmacy: PharmacyList) => {
    const array = [...pharmacyDataList];

    if (index >= 0) {
      array.splice(index + 1, 0, pharmacy);
      setPharmacyDataList(array);
    } else {
      console.error('Invalid index');
    }
  };
  const insertAfterLastPharmacy = () => {
    const lastIndex = pharmacyDataList.length - 1;
    const newPharmacy = {
      address1: '',
      city: '',
      address2: '',
      country: '',
      isDefault: false,
      name: '',
      phone: '+1',
      state: '',
      zipCode: '',
    } as PharmacyList;

    if (lastIndex >= 0) {
      insertAfter(lastIndex, newPharmacy);
      setIndexedPharmacyDefaultListValues(lastIndex + 1);
    } else {
      newPharmacy.isDefault = true;
      insertAfter(0, newPharmacy);
      setIndexedPharmacyDefaultListValues(0);
    }
  };

  const removeLastPharmacy = (index: number) => {
    // Ensure the index is valid
    if (index < 0 || index >= pharmacyDataList.length) {
      console.error('Invalid index');
      return;
    }

    const updatedArray = pharmacyDataList
      .filter((_, i) => i !== index)
      .map((pharmacy, i) => ({
        ...pharmacy,
        id: i.toString(),
      }));

    removeIndexedPharmacyListValues(index);
    setPharmacyDataList(updatedArray);
  };

  // modify to utilize index on pharmacyDataList

  const setPharmacyDataPerInput = (
    index: number,
    value: string,
    name: string,
  ) => {
    setPharmacyDataList((prevState) => {
      const newState = [...prevState];
      newState[index] = {
        ...newState[index],
        [name]: value,
      };
      return newState;
    });
  };

  const handleOnInputChange = (
    e: ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const { value, name } = e.target;
    switch (name) {
      case 'name':
        if (allowCharactersWithMaxLength(value)) {
          setPharmacyDataPerInput(index, value, name);
        }
        break;
      case 'address1':
        if (allowCharactersWithMaxLength(value)) {
          setPharmacyDataPerInput(index, value, name);
        }
        break;

      case 'address2':
        if (allowCharactersWithMaxLength(value)) {
          setPharmacyDataPerInput(index, value, name);
        }
        break;
      case 'zipCode':
        if (allowOnlyNumbersAndMaxLengthForZipCodes(value)) {
          setPharmacyDataPerInput(index, value, name);
        }
        break;
      default:
        return null;
    }
  };

  const setDefaultPhoneCountry = (index: number) => {
    setPhoneIndexedData((prevState) => {
      const newState = [...prevState];
      newState[index] = {
        ...newState[index],
        country: '1',
      };
      return newState;
    });
  };

  const setPhoneNumberPerInput = (
    index: number,
    value: string,
    name: string,
  ) => {
    let valueParsed = '';

    setPhoneIndexedData((prevState) => {
      const newState = [...prevState];
      newState[index] = {
        ...newState[index],
        [name]: value,
      };
      return newState;
    });

    if (name === 'country') {
      valueParsed = `+${value} ${phoneIndexedData[index]?.phone} ${
        phoneIndexedData[index]?.extension ?? ''
      }`;
    }

    if (name === 'phone') {
      if (phoneIndexedData[index]?.country === undefined) {
        setDefaultPhoneCountry(index);
      }
      valueParsed = `+${phoneIndexedData[index]?.country} ${value} ${
        phoneIndexedData[index]?.extension ?? ''
      }`;
    }

    if (name === 'extension') {
      if (phoneIndexedData[index]?.country === undefined) {
        setDefaultPhoneCountry(index);
      }
      valueParsed = `+${phoneIndexedData[index]?.country} ${
        phoneIndexedData[index]?.phone
      } ${value ?? ''}`;
    }

    setPharmacyDataPerInput(index, valueParsed, 'phone');
  };

  const handlePhoneChange = (value: string, index: number, name: string) => {
    setPhoneNumberPerInput(index, value, name);
  };

  const [patchFhirPatientPersonalData] =
    usePatchFhirPatientPersonalDataMutation({});

  const { user: loggedUser } = useContext(AuthContext);
  const [isSubmiting, setIsSubmiting] = useState(false);

  const removePropertiesFromPharmacyDataList = (
    pharmacyData: PharmacyInformationInputWithTypename[],
  ) => {
    return pharmacyData.map((pharmacy) => {
      // This is necessary since javascript doesnt allow to perform a delete on __typename
      // and whenever submitted with this data, the back-end doesnt allow it.
      // So either we remove it or we make it optional on the backend. For now, im removing it.
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { __typename, ...rest } = pharmacy;
      return rest;
    });
  };
  const handleOnSubmit = async () => {
    const isFormValid = errors.every((error) =>
      Object.values(error).every((value) => value === null),
    );

    if (!isFormValid)
      return handleUpdatedPharmacyInfo(GeneralStates.MISSING_INFO);

    try {
      setIsSubmiting(true);

      const response = await patchFhirPatientPersonalData({
        variables: {
          patientPersonalInfo: {
            codexID: loggedUser?.uuid || '',
            SENSITIVE_pharmacies:
              removePropertiesFromPharmacyDataList(pharmacyDataList),
          },
        },
      });

      if (!response.data) {
        handleUpdatedPharmacyInfo(GeneralStates.ERROR);
        throw new Error('Failed to update user');
      }

      handleUpdatedPharmacyInfo(GeneralStates.SUCCESS);
    } catch (error: unknown) {
      console.log(error);
      handleUpdatedPharmacyInfo(GeneralStates.ERROR);
      throw new Error('Failed to update user');
    } finally {
      setIsSubmiting(false);
      scrollToTop();
    }
  };

  useEffect(() => {
    countryIso.forEach((country, index) => {
      const states = State.getStatesOfCountry(country.country)
        .filter((state) => !state.isoCode.includes(ISO_CODE_TO_EXCLUDE))
        .filter((state) => !STATES_TO_EXCLUDE.includes(state.name))
        .map((state) => `${state.isoCode} - ${state.name}`);

      setStateData((prevStateData) => {
        const newStateData = [...prevStateData];
        newStateData[index] = {
          pharmacyID: index,
          state: states,
        };
        return newStateData;
      });

      if (isIndexedCountryClicked[index].clicked) {
        const stateCode = states?.[0]?.split(' - ')[0] || '';

        setStateIso((prevState) => {
          const newState = [...prevState];
          newState[index] = {
            ...newState[index],
            stateIso: stateCode,
          };
          return newState;
        });

        setPharmacyDataPerInput(index, states?.[0] || '', 'state');
        setPharmacyDataPerInput(index, '', 'city');

        setIsIndexedCountryClicked((prevState) => {
          const newState = [...prevState];
          newState[index] = {
            ...newState[index],
            clicked: false,
          };

          return newState;
        });

        setIsIndexedStateClicked((prevState) => {
          const newState = [...prevState];
          newState[index] = {
            ...newState[index],
            clicked: false,
          };
          return newState;
        });
      }
    });
  }, [countryIso, isIndexedCountryClicked]);

  useEffect(() => {
    stateIso.forEach((state, index) => {
      if (countryIso[index]?.country && state.stateIso) {
        const cities = City.getCitiesOfState(
          countryIso[index].country,
          state.stateIso,
        ).map((city) => `${city.name}`);

        setCityData((prevCityData) => {
          const newCityData = [...prevCityData];
          newCityData[index] = {
            pharmacyID: index,
            city: cities,
          };
          return newCityData;
        });

        if (isIndexedStateClicked[index]?.clicked) {
          setPharmacyDataPerInput(index, '', 'city');

          setIsIndexedStateClicked((prevState) => {
            const newState = [...prevState];
            newState[index] = {
              ...newState[index],
              clicked: false,
            };
            return newState;
          });
        }
      } else {
        setCityData((prevState) => {
          const newState = [...prevState];
          newState[index] = {
            ...newState[index],
            city: [],
          };
          return newState;
        });
      }
    });
  }, [stateIso, countryIso, isIndexedCountryClicked, isIndexedStateClicked]);

  useEffect(() => {
    handlePharmacyInfoChange({
      pharmacyList: removePropertiesFromPharmacyDataList(pharmacyDataList),
    });
  }, [pharmacyDataList, handlePharmacyInfoChange]);

  if (loading || genericActionLoading) return null;

  return (
    <div className="flex flex-col items-center bg-white w-full p-5 desktop:p-[30px] gap-5 rounded-10">
      <div
        className="flex flex-col items-start w-full gap-2.5 p-0 cursor-pointer desktop:cursor-default"
        onClick={handleShowForm}
      >
        <div className="flex flex-row w-full items-center justify-between">
          <div className="flex flex-row items-center">
            <h3 className="text-h5 desktop:text-h4 text-dark-gray font-semibold desktop:mr-2.5">
              {locale?.pharmacies.title}
            </h3>
          </div>
          {isFormDisplayed ? (
            <ChevronUpIcon
              className="desktop:hidden w-[18px] h-2.5 fill-current cursor-pointer"
              onClick={handleShowForm}
            />
          ) : (
            <ChevronDownIcon
              className="desktop:hidden w-[18px] h-2.5 fill-current cursor-pointer"
              onClick={handleShowForm}
            />
          )}
        </div>
        <hr
          className={`desktop:flex flex-row w-full items-center h-px bg-black-blur ${
            isFormDisplayed ? 'flex' : 'hidden'
          }`}
        />
      </div>

      <div
        className={`w-full desktop:flex flex-col gap-[15px] items-center ${
          isFormDisplayed ? 'flex' : 'hidden'
        }`}
      >
        {pharmacyDataList.length === 0 && (
          <div className="pb-20">
            <p className="font-medium text-dark-gray flex items-center justify-center">
              {locale?.pharmacies.noPharmacy}
            </p>
          </div>
        )}

        {pharmacyDataList.length > 0 &&
          pharmacyDataList.map((pharmacyData, index) => {
            return (
              <div
                key={index}
                className="flex flex-col w-full desktop:min-w-[435px] desktop:ml-3"
              >
                <>
                  <div className="flex flex-col desktop:flex-row w-full desktop:w-[690px] items-center desktop:items-center mx-auto mb-3">
                    <div className="w-full">
                      <InputComponent
                        type="radio"
                        name="pharmacyDefault"
                        radioInputProps={{
                          radioLabelTextWeight: 'font-semibold',
                          radioError: null,
                          radioPosition: 'items-center desktop:items-start',
                          radioInputValue: index.toString(),
                          radioInputLabel: locale.pharmacies.setAsDefault,
                          radioInputCheckedValue:
                            defaultSelectedPharmacyIndex === index.toString()
                              ? index.toString()
                              : undefined,
                          onRadioClick: () => handleSetDefaultPharmacy(index),
                        }}
                      />
                    </div>
                  </div>
                  {/* pharmacy name */}
                  <FormField
                    label={locale?.pharmacies.pharmacyName}
                    isRequired={true}
                    htmlFor="name"
                  >
                    <div className="w-full mb-1">
                      <InputComponent
                        value={pharmacyData.name}
                        type="text"
                        name="name"
                        onChange={(e) => handleOnInputChange(e, index)}
                        errorStatus={!!errors[index]?.name}
                        errorMsgWithIcon={errors[index]?.name}
                        maxLengthValue={400}
                      />
                    </div>
                  </FormField>
                  {/* phone */}
                  <FormField
                    label={locale?.contactInfo.phoneLabel}
                    isRequired={true}
                    htmlFor="country"
                  >
                    <div className="relative desktop:flex space-x-2.5 w-full">
                      <div className="flex w-full mr-5">
                        <div className="desktop:max-w-full">
                          <InputComponent
                            testID="phoneNumber-country-select"
                            type="select-with-country-icons"
                            name="country"
                            borderRadiusRight={true}
                            selectInputWithFlagsProps={{
                              currentValue: getMemoizedCurrentCountryValue(
                                index,
                              ) as SelectWithFlagsValues,
                              defaultValue:
                                getMemoizedDefaultParsedValue as SelectWithFlagsValues,
                              options:
                                getMemoizedParsedCountries as SelectWithFlagsValues[],
                              onSelectChange: (e) =>
                                handlePhoneChange(e.value, index, 'country'),
                            }}
                          />
                        </div>
                        <div className="w-full min-w-[105px] desktop:max-w-[195px] mb-1">
                          <InputComponent
                            value={phoneIndexedData[index]?.phone}
                            testID="phoneNumber-input"
                            type="tel"
                            name="phoneNumber"
                            flexGrow={1}
                            borderRadiusLeft={true}
                            onChange={(e) => {
                              handlePhoneChange(e.target.value, index, 'phone');
                            }}
                            errorStatus={!!errors[index]?.phone}
                            errorMsgWithIcon={errors[index]?.phone}
                          />
                        </div>
                      </div>
                      <div className="flex">
                        <label
                          className="mt-3 mb-2.5 mr-2.5 text-base text-dark-gray font-bold desktop:w-2/12 desktop:mb-2"
                          htmlFor="phoneNumberExtension"
                        >
                          {locale?.providerLocales?.phoneExtension}
                        </label>
                        <div className="flex w-full desktop:max-w-[130px] max-h-[58px]">
                          <InputComponent
                            value={phoneIndexedData[index]?.extension ?? ''}
                            flexGrow={1}
                            type="ext"
                            name="phoneNumberExtension"
                            maxLengthValue={4}
                            onChange={(e) => {
                              handlePhoneChange(
                                e.target.value,
                                index,
                                'extension',
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </FormField>
                  {/* address 1 */}
                  <FormField
                    label={locale?.contactInfo.address1Label}
                    isRequired={true}
                    htmlFor="address1"
                  >
                    <div className="w-full mb-1">
                      <InputComponent
                        type="text"
                        value={pharmacyData.address1}
                        name="address1"
                        onChange={(e) => handleOnInputChange(e, index)}
                        errorStatus={!!errors[index]?.address1}
                        errorMsgWithIcon={errors[index]?.address1}
                        maxLengthValue={200}
                      />
                    </div>
                  </FormField>
                  {/* address 2 */}
                  <FormField
                    label={locale?.contactInfo.address2Label}
                    isRequired={false}
                    htmlFor="address2"
                  >
                    <div className="w-full mb-1">
                      <InputComponent
                        type="text"
                        value={pharmacyData.address2 ?? ''}
                        name="address2"
                        onChange={(e) => handleOnInputChange(e, index)}
                        errorStatus={!!errors[index]?.address2}
                        errorMsgWithIcon={errors[index]?.address2}
                        maxLengthValue={200}
                      />
                    </div>
                  </FormField>
                  {/* country */}
                  <FormField
                    label={locale?.contactInfo.countryLabel}
                    isRequired={true}
                    htmlFor="pharmacyCountry"
                  >
                    <div className="w-full mb-2.5">
                      <Select
                        name="pharmacyCountry"
                        className="dropdown"
                        classNamePrefix={
                          errors[index]?.country
                            ? 'react-select-with-error'
                            : 'react-select'
                        }
                        placeholder={locale?.selectLanguagePlaceholder}
                        value={parseValueIntoSelectFormat(
                          pharmacyData.country as string,
                        )}
                        options={[
                          {
                            label: 'US - United States',
                            value: 'US - United States',
                          },
                        ]}
                        onChange={(e) => {
                          const dropdownItem = e as DropdownItem;

                          handleOnSelectChange(
                            {
                              label: dropdownItem?.label ?? '',
                              value: dropdownItem?.value ?? '',
                            },
                            'country',
                            index,
                          );
                        }}
                        isMulti={false}
                        noOptionsMessage={() => locale?.selectLanguageNoOptions}
                        components={{
                          Input: (props) => (
                            <components.Input {...props} maxLength={200} />
                          ),
                        }}
                        onKeyDown={allowOnlyCharactersInCityField}
                      />
                      {errors[index]?.country && (
                        <div className="mt-1">
                          <ErrorMessageWithIcon
                            message={(errors[index]?.country as string) ?? ''}
                          />
                        </div>
                      )}
                    </div>
                  </FormField>
                  {/* state */}
                  <FormField
                    label={locale?.contactInfo.stateLabel}
                    isRequired={true}
                    htmlFor="pharmacyState"
                  >
                    <div className="w-full mb-2.5">
                      <Select
                        name="pharmacyState"
                        className="dropdown"
                        classNamePrefix={
                          errors[index]?.state
                            ? 'react-select-with-error'
                            : 'react-select'
                        }
                        placeholder={locale?.selectLanguagePlaceholder}
                        value={parseValueIntoSelectFormat(
                          pharmacyData.state as string,
                        )}
                        options={
                          (stateData[index]?.state &&
                            parseStringArrayIntoSelectOptions(
                              stateData[index]?.state,
                            )) ??
                          undefined
                        }
                        onChange={(e) => {
                          const dropdownItem = e as DropdownItem;

                          handleOnSelectChange(
                            {
                              label: dropdownItem?.label ?? '',
                              value: dropdownItem?.value ?? '',
                            },
                            'state',
                            index,
                          );
                        }}
                        isMulti={false}
                        noOptionsMessage={() => locale?.selectLanguageNoOptions}
                        components={{
                          Input: (props) => (
                            <components.Input {...props} maxLength={200} />
                          ),
                        }}
                        onKeyDown={allowOnlyCharactersInCityField}
                      />
                      {errors[index]?.state && (
                        <div className="mt-1">
                          <ErrorMessageWithIcon
                            message={(errors[index]?.state as string) ?? ''}
                          />
                        </div>
                      )}
                    </div>
                  </FormField>
                  {/* city */}
                  <FormField
                    label={locale?.contactInfo.cityLabel}
                    isRequired={true}
                    htmlFor="pharmacyCity"
                  >
                    <div className="w-full mb-2.5">
                      <Select
                        name="pharmacyCity"
                        className="dropdown"
                        classNamePrefix={
                          errors[index]?.city
                            ? 'react-select-with-error'
                            : 'react-select'
                        }
                        placeholder={locale?.selectLanguagePlaceholder}
                        value={parseValueIntoSelectFormat(
                          pharmacyData.city as string,
                        )}
                        options={
                          (cityData[index]?.city &&
                            parseStringArrayIntoSelectOptions(
                              cityData[index]?.city,
                            )) ??
                          undefined
                        }
                        onChange={(e) => {
                          const dropdownItem = e as DropdownItem;

                          handleOnSelectChange(
                            {
                              label: dropdownItem?.label ?? '',
                              value: dropdownItem?.value ?? '',
                            },
                            'city',
                            index,
                          );
                        }}
                        isMulti={false}
                        noOptionsMessage={() => locale?.selectLanguageNoOptions}
                        components={{
                          Input: (props) => (
                            <components.Input {...props} maxLength={200} />
                          ),
                        }}
                        onKeyDown={allowOnlyCharactersInCityField}
                      />
                      {errors[index]?.city && (
                        <div className="mt-1">
                          <ErrorMessageWithIcon
                            message={(errors[index]?.city as string) ?? ''}
                          />
                        </div>
                      )}
                    </div>
                  </FormField>
                  {/* zipcode */}
                  <FormField
                    label={locale?.contactInfo.zipLabel}
                    isRequired={true}
                    zipCode={true}
                    htmlFor="pharmacyZipCode"
                  >
                    <div className="w-[160px] mr-auto mb-1">
                      <InputComponent
                        type="text"
                        name="zipCode"
                        value={pharmacyData.zipCode}
                        onChange={(e) => handleOnInputChange(e, index)}
                        errorStatus={!!errors[index]?.zipCode}
                        errorMsgWithIcon={errors[index]?.zipCode}
                      />
                    </div>
                  </FormField>

                  <div className="flex flex-col desktop:flex-row w-full desktop:w-[690px] items-center desktop:items-center mx-auto">
                    <button
                      className="flex mr-6"
                      onClick={() => removeLastPharmacy(index)}
                    >
                      <MinusIcon className="text-clc-blue mr-2" />
                      <span className="text-clc-blue font-bold underline uppercase">
                        {locale?.pharmacies.remove}
                      </span>
                    </button>
                  </div>
                </>
                <hr className="my-[30px]" />
              </div>
            );
          })}
        <div className="flex flex-row desktop:flex-row w-full items-center desktop:items-center">
          <button
            className="flex mr-3"
            onClick={() => insertAfterLastPharmacy()}
          >
            <PlusIcon className="mr-2" />
            <span className="text-clc-blue font-bold underline uppercase">
              {locale?.pharmacies.addMore}
            </span>
          </button>
        </div>
      </div>

      <div className="hidden w-full desktop:flex justify-end">
        <ButtonComponent
          iconPosition="right"
          disabled={isSubmiting}
          className="px-10"
          onClick={handleOnSubmit}
          showSpinner={true}
        >
          {genericAction?.[genericActionsIds.UPDATE].update}
        </ButtonComponent>
      </div>
    </div>
  );
};
