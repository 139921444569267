import React from 'react';
import { twMerge } from 'tailwind-merge';

const Placeholder: React.FC<{ placeholder?: string; className?: string }> = ({
  placeholder,
  className,
}) => {
  return (
    <p
      className={twMerge(
        'flex my-auto text-sm text-med-gray font-medium',
        className,
      )}
    >
      {placeholder}
    </p>
  );
};

export default Placeholder;
