import { CurrentConditionsProps } from './interfaces';
import ConditionIcon from './ConditionIcon';

const CurrentConditions: React.FC<CurrentConditionsProps> = ({
  condition,
  tempF,
  tempC,
  isDay,
  locale,
}) => {
  return (
    <div className="flex flex-col flex-1 items-center">
      <p className="font-semibold">{locale.currentConditions}</p>
      <div className="flex flex-row items-center min-h-[160px]">
        <ConditionIcon id={condition.id} isDay={isDay} />
        <div className="flex flex-col gap-1">
          <span className="text-h1">
            {tempF}
            {locale.farenheit}
          </span>
          <span className="text-base">
            {tempC}
            {locale.celsius}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CurrentConditions;
