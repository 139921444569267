import React from 'react';
import CameraURL from 'assets/icons/camera.svg';
import { MessageType, ChatMessageLocale, ChatMessage } from './interfaces';
import Avatar from 'components/avatarComponent';
import { Roles } from '../../../firebase/interfaces';

interface ChatMessageProps extends ChatMessage {
  locale: ChatMessageLocale;
}

export const ChatMessageComponent: React.FC<ChatMessageProps> = ({
  locale,
  message,
  messageGroup,
  senderPicture,
  senderType,
  senderName,
  mediaURL,
  type = MessageType.MESSAGE,
}) => {
  return (
    <div className="flex flex-col">
      <div
        className={`flex flex-row items-end gap-3 ${
          senderType === Roles.PROVIDER
            ? 'ml-[85px] justify-end'
            : 'mr-[85px] justify-start'
        }`}
      >
        {type === MessageType.MEDIA ? (
          mediaURL ? (
            <img
              alt={locale.mediaAlt}
              src={mediaURL}
              className={`bg-frost-blue text-med-gray rounded-[7px] w-[120px] h-20 border object-cover ${
                senderType === Roles.PROVIDER
                  ? 'rounded-br-none order-1'
                  : 'rounded-bl-none order-2'
              }`}
            />
          ) : (
            <div
              className={`bg-silver-gray flex items-center justify-center rounded-[7px] w-[120px] h-20 border ${
                senderType === Roles.PROVIDER
                  ? 'rounded-br-none order-1'
                  : 'rounded-bl-none order-2'
              }`}
            >
              <img alt={locale.cameraAlt} src={CameraURL} />
            </div>
          )
        ) : messageGroup && messageGroup?.length > 0 ? (
          <div
            className={`flex flex-col overflow-hidden gap-[2px] ${
              senderType === Roles.PROVIDER ? 'order-1' : 'order-2'
            }`}
          >
            {messageGroup.map((message, index) => (
              <span
                key={message.id}
                className={`text-base py-2.5 px-[15px] rounded-[7px] break-words ${
                  index === 0
                    ? 'rounded-b-none'
                    : index === messageGroup.length - 1
                    ? 'rounded-t-none'
                    : 'rounded-b-none rounded-t-none'
                } ${
                  senderType === Roles.PROVIDER
                    ? 'bg-azure-blue text-white'
                    : 'bg-frost-blue text-med-gray'
                }`}
              >
                {message.message}
              </span>
            ))}
          </div>
        ) : (
          <span
            className={`text-base py-2.5 px-[15px] overflow-hidden rounded-[7px] break-words ${
              senderType === Roles.PROVIDER
                ? 'bg-azure-blue text-white rounded-br-none order-1'
                : 'bg-frost-blue text-med-gray rounded-bl-none order-2'
            }`}
          >
            {message}
          </span>
        )}
        <span>{senderName}</span>
        <Avatar imageUrl={senderPicture} size="xs" />
      </div>
    </div>
  );
};
