import { ReactComponent as CodexLogo } from 'assets/icons/logo-codex.svg';

const Loader: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="w-[240px] h-[240px] relative">
        <CodexLogo />
      </div>
    </div>
  );
};

export default Loader;
