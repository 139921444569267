import { useGetComponent } from 'hooks/useGetComponent';
import { componentIds } from 'utilities/constants';

const useDate = () => {
  const { data: locale } = useGetComponent({
    locale: 'en',
    componentId: componentIds.DATEPICKER,
  });

  const formatDate = (date: Date) => {
    if (!locale) {
      return date.toString();
    }

    const fullWeekDays = locale.fullWeekDays;
    const monthNames = locale.monthNames;
    const day = fullWeekDays[date.getDay()];
    const month = monthNames[date.getMonth()];
    const dayOfMonth = date.getDate();

    const getDaySuffix = (day: number) => {
      if (day >= 11 && day <= 13) {
        return locale.daySuffix.th;
      }
      switch (day % 10) {
        case 1:
          return locale.daySuffix.st;
        case 2:
          return locale.daySuffix.nd;
        case 3:
          return locale.daySuffix.rd;
        default:
          return locale.daySuffix.th;
      }
    };

    const daySuffix = getDaySuffix(dayOfMonth);
    const shortDay = day.substr(0, 3);
    const formattedDate = `${shortDay}, ${month} ${dayOfMonth}${daySuffix}, ${date.getFullYear()}`;

    return formattedDate;
  };

  return {
    formatDate,
  };
};

export default useDate;
