import ButtonComponent from 'components/button/buttonComponent';
import Thumbnail from 'assets/images/thumbnail.svg';
import AvatarComponent from 'components/avatarComponent';
import { ReactComponent as FilledHeartIcon } from 'assets/icons/filledHeart.svg';
import { appointmentRenderInfo } from 'app/my-appointments/interfaces';
import { useGetPage } from 'hooks/useGetPage';
import { pageIds } from 'utilities/constants';
import { ProviderCardProps } from 'app/my-appointments/components/select-a-provider/ProviderCardProps';

const ProviderCard: React.FC<ProviderCardProps> = ({
  providerInfo,
  onSelect,
}) => {
  const { loading: localeLoading, data: locale } = useGetPage({
    locale: 'en',
    pageId: pageIds.SELECT_A_PROVIDER,
  });
  if (localeLoading) return null;
  return (
    <div className="bg-white rounded-10 flex flex-col desktop:flex-row py-2.5 px-5 gap-5 justify-between">
      <div className="flex flex-col items-center justify-start gap-2.5 relative">
        <AvatarComponent
          imageUrl={providerInfo.SENSITIVE_profile_picture_id || Thumbnail}
          size="s"
        />
        <ButtonComponent
          className="hidden desktop:flex"
          onClick={() =>
            onSelect(
              providerInfo.id,
              providerInfo.acuityCalendarId,
              providerInfo.acuityOwnerId,
            )
          }
        >
          {locale.select}
        </ButtonComponent>
        {providerInfo.isFavorite && (
          <div className="absolute top-0 right-0 w-6 h-6 flex justify-center items-center">
            <FilledHeartIcon className="w-3 h-4" />
          </div>
        )}
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col">
          <p className="text-charcoal-gray font-semibold text-lg">
            {providerInfo.SENSITIVE_firstname} {providerInfo.SENSITIVE_lastname}
          </p>
          <p className="text-med-gray font-normal text-base">
            {providerInfo.SENSITIVE_credentials.titles.join(', ')}
          </p>
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-charcoal-gray font-bold text-base">
            {locale.acceptedAppointments}
          </p>
          {providerInfo.accepted_appointment_types.map((serviceOffering) => {
            const Icon =
              appointmentRenderInfo[serviceOffering.serviceType].icon;
            return (
              <div className="flex flex-row gap-2.5 items-center px-2">
                <Icon />
                <p className="text-med-gray font-normal text-base">
                  {
                    locale[
                      appointmentRenderInfo[serviceOffering.serviceType].text
                    ]
                  }{' '}
                  (${serviceOffering.price})
                </p>
              </div>
            );
          })}
        </div>
      </div>
      <ButtonComponent
        className="desktop:hidden"
        onClick={() =>
          onSelect(
            providerInfo.id,
            providerInfo.acuityCalendarId,
            providerInfo.acuityOwnerId,
          )
        }
      >
        {locale.select}
      </ButtonComponent>
    </div>
  );
};

export default ProviderCard;
