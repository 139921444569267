import { DatePickerNoticeProps } from './tabs/dayTab';

export const DatePickerNotice: React.FC<DatePickerNoticeProps> = ({
  label,
}) => (
  <div className="flex justify-center items-center mt-3">
    <span className="rounded-full bg-clc-blue h-3 w-3 mr-2" />
    <p className="font-medium text-sm">{label}</p>
  </div>
);
