import React from 'react';

export const WarningIcon: React.ComponentType<
  React.SVGProps<SVGSVGElement>
> = ({ fill, className }) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9328 5.02555C13.0738 3.53767 10.9262 3.53767 10.0672 5.02555L3.30234 16.7426C2.44331 18.2305 3.5171 20.0903 5.23515 20.0903H18.7648C20.4829 20.0903 21.5567 18.2305 20.6977 16.7426L13.9328 5.02555ZM11.0897 13.8739L10.7147 8.40964H12.9647L12.5897 13.8739H11.0897ZM13.2861 16.1239C13.2861 16.834 12.7105 17.4096 12.0004 17.4096C11.2903 17.4096 10.7147 16.834 10.7147 16.1239C10.7147 15.4138 11.2903 14.8382 12.0004 14.8382C12.7105 14.8382 13.2861 15.4138 13.2861 16.1239Z"
        fill={fill}
      />
    </svg>
  );
};
