import { PatientDetails } from 'app/my-patients/components/PatientDetails';
import { UpcomingAppointments } from 'app/my-patients/components/UpcomingAppointments';
import { MyPatientsDermSCOREScans } from 'app/my-patients/components/DermSCOREScans/MyPatientsDermSCOREScans';
import {
  Image_Source_Types_Enum,
  AppointmentTypeEnum,
} from 'graphql/generated/hasura';

import {
  calculateAge,
  formatDateToCustomFormat,
  parseMediaToSharedMedia,
} from 'utilities/functions';
import { useMemo, useState } from 'react';
import Loader from 'components/loaderComponent';
import { TestKitsResults } from 'app/my-patients/components/TestKitsResults';
import { SharedMedia } from 'app/my-patients/components/SharedMedia';
import { Scan } from 'app/dashboard/interfaces/scan.interfaces';
import { SharedMediaModal } from '../components/SharedMediaModal';
import { useModalParams } from 'components/modal/useModalManager';
import { TestKitResultModal } from '../components/TestKitResultModal';
import { DermScoreResults } from 'app/my-skin/components/dermScoreResults';
import { PARAM_MODALS_IDENTIFIERS } from 'utilities/constants';
import { useParams } from 'react-router-dom';
import {
  useGetAppointmentsByProviderQuery,
  useGetFhirDnaVisitTestsByUserIdQuery,
  useGetFhirSkintellingentTestsByUserIdQuery,
  useGetProviderPatientDetailsQuery,
} from 'graphql/generated/remote-schema-hasura';
import { PatientDetails as PatientDetailsType } from '../interfaces/patientDetails.interface';
import { pageIds } from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';
import { TestKitResult } from '../interfaces/testKits.interfaces';
import {
  AppointmentType,
  UpcomingAnointmentType,
} from '../interfaces/upcomingAppointments.interface';
import { SharedMedia as SharedMediaType } from '../interfaces/interfaces';
import { useGetFhirPatientbyMediaCodexIdParameterQuery } from 'graphql/generated/remote-schema-hasura';

export const SKINTELLIGENT_LIMIT = 1;
export const DNA_VISIT_TESTS_LIMIT = 4;
export const AppointmentLimit = 2;

export const PatientInfo: React.FC = () => {
  const { data: localeDermscore, loading: loadingLocaleDermscore } = useGetPage(
    {
      locale: 'en',
      pageId: pageIds.DERMSCORE_SCANS_PROVIDER_TAB,
    },
  );
  const { data: localeMyPatients, loading: loadingLocaleMyPatients } =
    useGetPage({
      locale: 'en',
      pageId: pageIds.MY_PATIENTS,
    });
  const { data: localeTestKits, loading: loadingLocaleTestKits } = useGetPage({
    locale: 'en',
    pageId: pageIds.TEST_KIT_RESULTS_PROVIDER_TAB,
  });
  const {
    data: localePatientsDetailsInfo,
    loading: loadingLocalePatientsDetailsInfo,
  } = useGetPage({
    locale: 'en',
    pageId: pageIds.PATIENT_DETAILS_INFO_PROVIDER_TAB,
  });
  const {
    data: localeUpcomingAppointments,
    loading: loadingLocaleUpcomingAppointments,
  } = useGetPage({
    locale: 'en',
    pageId: pageIds.APPOINTMENTS_HISTORY_MY_PATIENT_PROVIDER_TAB,
  });

  const locales = useMemo(
    () =>
      !localeDermscore ||
      loadingLocaleDermscore ||
      !localeTestKits ||
      loadingLocaleTestKits ||
      !localePatientsDetailsInfo ||
      loadingLocalePatientsDetailsInfo ||
      !localeMyPatients ||
      loadingLocaleMyPatients ||
      !localeUpcomingAppointments ||
      loadingLocaleUpcomingAppointments,
    [
      localeDermscore,
      loadingLocaleDermscore,
      localeTestKits,
      loadingLocaleTestKits,
      localePatientsDetailsInfo,
      loadingLocalePatientsDetailsInfo,
      localeMyPatients,
      loadingLocaleMyPatients,
      localeUpcomingAppointments,
      loadingLocaleUpcomingAppointments,
    ],
  );

  const [recentSharedMedia, setRecentSharedMedia] = useState<SharedMediaType[]>(
    [],
  );
  const [sharedMediaTotal, setSharedMediaTotal] = useState<number>(0);
  const [scan, setScan] = useState<Scan | null>(null);
  const [upcomingAppointmentsCount, setUpcomingAppointmentsCount] = useState<
    Record<string, number>
  >({
    total: 0,
    current: 0,
  });
  const [dermScoreCount, setDermScoreCount] = useState<number>(0);
  const [patientDetailsInfo, setPatientDetailsInfo] =
    useState<PatientDetailsType | null>(null);
  const [dnaDataTest, setDnaDataTest] = useState<TestKitResult[]>([]);
  const [dnaDataTestTotal, setDnaDataTestTotal] = useState<number>(0);
  const [upcomingAppointmentsData, setUpcomingAppointmentsData] = useState<
    UpcomingAnointmentType[]
  >([]);

  const { isOpen: isSharedMediaModalOpen } = useModalParams(
    PARAM_MODALS_IDENTIFIERS.SHARED_MEDIA_MODAL_ID,
  );
  const { isOpen: isTestKitResultsModalOpen } = useModalParams(
    PARAM_MODALS_IDENTIFIERS.TEST_KIT_RESULT_MODAL_ID,
  );
  const { isOpen: isDermSCOREModalOpen } = useModalParams(
    PARAM_MODALS_IDENTIFIERS.MY_PATIENTS_DERMSCORE_SCANS_MODAL_ID,
  );
  const { patientId } = useParams();

  const { data: fhirSkintelligentTestsByUserData } =
    useGetFhirSkintellingentTestsByUserIdQuery({
      variables: {
        codexPatientId: patientId || '',
        limit: SKINTELLIGENT_LIMIT,
      },

      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        if (data.getFHIRSkintelligentTestsByUserId.skintelligent_tests.length) {
          setDermScoreCount(data.getFHIRSkintelligentTestsByUserId.total);
          const scan: Scan =
            data.getFHIRSkintelligentTestsByUserId.skintelligent_tests.map(
              (test) => {
                return {
                  id: test?.test_id,
                  reportId: test?.report_id,
                  date: formatDateToCustomFormat(test?.created_at),
                  imageId: test?.result_image_id || '',
                  inflammations: Number(test?.inflammatory),
                  comedones: Number(test?.comedone),
                  pih: Number(test?.pih),
                  total: Number(test?.total_lesions),
                  source: test?.image_source as Image_Source_Types_Enum,
                } as Scan;
              },
            )[0];
          setScan(scan);
        }
      },
    });
  const { data: patientDetailsData } = useGetProviderPatientDetailsQuery({
    variables: {
      patientCodexId: patientId || '',
    },
    fetchPolicy: 'network-only',
    skip: !localePatientsDetailsInfo,
    onCompleted: (data) => {
      setPatientDetailsInfo({
        dateOfBirth:
          data.getFHIRPatientbyCodexIDParameter.users[0].SENSITIVE_dob || '',
        age: data.getFHIRPatientbyCodexIDParameter.users[0].SENSITIVE_dob
          ? String(
              calculateAge(
                data.getFHIRPatientbyCodexIDParameter.users[0].SENSITIVE_dob,
              ),
            )
          : '',
        height: [
          {
            value:
              data.getFHIRPatientbyCodexIDParameter.users[0]
                .SENSITIVE_user_measurements?.heightFt || 0,
            unit: localePatientsDetailsInfo.ft,
          },
          {
            value:
              data.getFHIRPatientbyCodexIDParameter.users[0]
                .SENSITIVE_user_measurements?.heightIn || 0,
            unit: localePatientsDetailsInfo.in,
          },
        ],
        weight: [
          {
            value:
              data.getFHIRPatientbyCodexIDParameter.users[0]
                .SENSITIVE_user_measurements?.weight || 0,
            unit: localePatientsDetailsInfo.lbs,
          },
        ],
        waistSize: [
          {
            value:
              data.getFHIRPatientbyCodexIDParameter.users[0]
                .SENSITIVE_user_measurements?.waist || 0,
            unit: localePatientsDetailsInfo.in,
          },
        ],
        hipSize: [
          {
            value:
              data.getFHIRPatientbyCodexIDParameter.users[0]
                .SENSITIVE_user_measurements?.waist || 0,
            unit: localePatientsDetailsInfo.in,
          },
        ],
        birthGender:
          data.getFHIRPatientbyCodexIDParameter.users[0].SENSITIVE_gender?.toLocaleLowerCase() ||
          '',
        genderSelfIdentification:
          data.getFHIRPatientbyCodexIDParameter.users[0].SENSITIVE_self_identity_gender?.toLocaleLowerCase() ||
          '',
        //TO DO COD-2525: Add Primary Pharmacy Information to the Patient Info Tab
        primaryPharmacy: undefined,
      });
    },
  });

  const { data: fhirDNAVisitTestsData } = useGetFhirDnaVisitTestsByUserIdQuery({
    variables: {
      codexPatientId: patientId || '',
      limit: DNA_VISIT_TESTS_LIMIT,
    },
    onCompleted: (data) => {
      setDnaDataTest(
        data.getFHIRDnaVisitTestsByUserId.dnavisit_tests.map<TestKitResult>(
          (test) => ({
            date: formatDateToCustomFormat(test?.created_at, true),
            name: test.test_type,
            resultSrc: test.patient_pdf_id || '',
            patientName: `${patientDetailsData?.getFHIRPatientbyCodexIDParameter.users[0].SENSITIVE_firstname} ${patientDetailsData?.getFHIRPatientbyCodexIDParameter.users[0].SENSITIVE_lastname}`,
          }),
        ),
      );
      setDnaDataTestTotal(data.getFHIRDnaVisitTestsByUserId.total);
    },
    skip: !patientDetailsData,

    fetchPolicy: 'network-only',
  });
  const { data: recentMediaData } =
    useGetFhirPatientbyMediaCodexIdParameterQuery({
      variables: {
        patientCodexId: patientId || '',
        limit: 6,
        sort: 0,
      },
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setRecentSharedMedia(
          parseMediaToSharedMedia(
            data.getFHIRPatientbyMediaCodexIDParameter.media,
          ),
        );
        setSharedMediaTotal(data.getFHIRPatientbyMediaCodexIDParameter.total);
      },
    });

  const now = useMemo(() => new Date().toISOString(), []);
  const oneMonthFromNow = useMemo(() => {
    const currentDate = new Date();
    const futureDate = new Date(currentDate);
    futureDate.setMonth(currentDate.getMonth() + 1);
    return futureDate.toISOString();
  }, []);

  const { data: upcomingAppointments } = useGetAppointmentsByProviderQuery({
    fetchPolicy: 'network-only',
    variables: {
      patientCodexId: patientId || '',
      from: now,
      to: oneMonthFromNow,
      limit: AppointmentLimit,
      sort: 'start',
      sortDirection: 1,
    },
    onCompleted: (data) => {
      setUpcomingAppointmentsData(
        data.getFHIRAppointmentByRequestProviderId.appointment.map<UpcomingAnointmentType>(
          (data) => {
            const patient =
              data.patientFirstName || data.patientLastName
                ? `${data.patientFirstName} ${data.patientLastName}`
                : '';
            const date = new Date(data.start || Date.now());
            const dateString = formatDateToCustomFormat(date.toISOString());
            let type: AppointmentType;
            switch (data.appointmentType) {
              case AppointmentTypeEnum.Chat:
                type = AppointmentType.chat;
                break;
              case AppointmentTypeEnum.Video:
                type = AppointmentType.video;
                break;
              case AppointmentTypeEnum.Email:
                type = AppointmentType.message;
                break;
              default:
                type = AppointmentType.chat;
            }
            return {
              id: data.appointmentCodexId,
              date: dateString,
              provider: patient,
              type: type,
              actions: undefined, // TODO COD-1758
            };
          },
        ),
      );
      setUpcomingAppointmentsCount({
        total: data.getFHIRAppointmentByRequestProviderId.total,
        current: data.getFHIRAppointmentByRequestProviderId.appointment.length,
      });
    },
  });

  if (
    !fhirSkintelligentTestsByUserData ||
    !patientDetailsData ||
    !fhirDNAVisitTestsData ||
    !upcomingAppointments ||
    !recentMediaData ||
    locales
  )
    return <Loader />;

  return (
    <>
      {isSharedMediaModalOpen && localeMyPatients?.sharedMediaModal && (
        <SharedMediaModal locale={localeMyPatients?.sharedMediaModal} />
      )}
      {isTestKitResultsModalOpen && localeTestKits?.testKitResultsModal && (
        <TestKitResultModal locale={localeTestKits?.testKitResultsModal} />
      )}
      {isDermSCOREModalOpen && <DermScoreResults />}
      <div className="grid grid-cols-12 gap-[30px] w-full px-5 desktop:px-0">
        <div className="col-span-12 desktop:col-span-8">
          {patientDetailsInfo && (
            <PatientDetails
              locale={localePatientsDetailsInfo}
              patientDetails={patientDetailsInfo}
            />
          )}
        </div>
        <div className="col-span-12 desktop:col-span-4">
          <UpcomingAppointments
            locale={localeUpcomingAppointments}
            upcomingAppointments={upcomingAppointmentsData}
            count={upcomingAppointmentsCount}
          />
        </div>
        <div className="col-span-12 desktop:col-span-4">
          <MyPatientsDermSCOREScans
            locale={localeDermscore}
            scan={scan}
            count={dermScoreCount}
            patientId={patientId}
          />
        </div>
        <div className="col-span-12 desktop:col-span-4">
          <TestKitsResults
            locale={localeTestKits}
            testKitResult={dnaDataTest}
            total={dnaDataTestTotal}
          />
        </div>
        <div className="col-span-12 desktop:col-span-4">
          {localeMyPatients?.sharedMedia && (
            <SharedMedia
              locale={localeMyPatients?.sharedMedia}
              sharedMedia={recentSharedMedia}
              total={sharedMediaTotal}
            />
          )}
        </div>
      </div>
    </>
  );
};
