import { Hasura_Test_Types_Enum } from 'graphql/generated/hasura';
import { PatientRequestInformation } from '../components/provider/newPatientRequest/interfaces/newPatientRequestModalInterfaces';

export enum PatientRequestEnum {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
}

export interface PatientNewRequestState {
  status: PatientRequestEnum;
  patientData: PatientRequestInformation;
  id: string;
}

export interface PatientNewTestKitRequestState {
  status: PatientRequestEnum;
  patientData: PatientRequestInformation;
  patientId: string;
  testKitId: string;
  testKitType: Hasura_Test_Types_Enum;
  isPatientApproved: boolean;
}
