import { ChatMessage, ChatMessageLocale } from './interfaces';
import { ChatMessageComponent } from './ChatMessageComponent';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  useGetFhirPatientByCodexIdLazyQuery,
  useGetFhirProviderByCodexIdLazyQuery,
} from '../../../graphql/generated/remote-schema-hasura';
import {
  useGetFileUrlFromStorageMutation,
  useGetSignUrlFromStorageForRequesterMutation,
} from '../../../graphql/generated/hasura';
import { useGetPatientImage } from '../../../hooks/useGetPatientImage';
import { Roles } from '../../../firebase/interfaces';
import { UserData } from '../../../auth/context/AuthContext';

interface ChatComponentProps {
  messages: ChatMessage[];
  locale: ChatMessageLocale;
  loggedUser: UserData | null;
}

interface ICollocutor {
  SENSITIVE_firstname: string | null | undefined;
  SENSITIVE_lastname: string | null | undefined;
  senderPicture?: string;
}

export const ChatComponent: React.FC<ChatComponentProps> = ({
  messages,
  locale,
  loggedUser,
}) => {
  const { state } = useLocation();
  const [collocutor, setCollocutor] = useState<ICollocutor | null>(null);
  const [profilePicture, setProfilePicture] = useState<string | null>('');
  const [getFileUrlFromStorage] = useGetFileUrlFromStorageMutation({});

  const [getProvider] = useGetFhirProviderByCodexIdLazyQuery({
    onCompleted: (data) => data,
  });

  const [getPatient] = useGetFhirPatientByCodexIdLazyQuery({
    onCompleted: (data) => data,
  });
  const [getFileUrlFromStorageForRequester] =
    useGetSignUrlFromStorageForRequesterMutation({});
  const getPatientImage = useGetPatientImage();

  const containerRef = useRef<HTMLDivElement>(null);
  const scrollToBottom = useCallback(() => {
    containerRef.current?.scrollTo({
      top:
        containerRef.current.scrollHeight - containerRef.current.clientHeight,
      behavior: 'instant' as unknown as ScrollBehavior, //https://github.com/Microsoft/TypeScript/issues/28755,
    });
  }, []);

  const getProviderData = useCallback(async () => {
    const { data } = await getProvider({
      variables: { codexProviderId: state?.providerId },
    });
    const provider =
      data?.getFHIRProviderbyCodexIdArgument.providers[0]?.providers;
    let profileImage = undefined;
    if (!!provider && provider?.SENSITIVE_profile_picture_id) {
      profileImage = await getFileUrlFromStorageForRequester({
        variables: {
          fileId: provider.SENSITIVE_profile_picture_id,
          requestedUserId: state?.providerId,
        },
      });
    }
    return {
      ...provider,
      senderPicture: profileImage?.data?.GetSignUrlFromStorageForRequester?.url,
    };
  }, [getFileUrlFromStorageForRequester, getProvider, state?.providerId]);

  const getPatientData = useCallback(async () => {
    const users = await getPatient({
      variables: { patientCodexId: state?.patientId },
    });
    const user = users.data?.getFHIRPatientbyCodexIDParameter?.users[0];
    let profileImage = undefined;
    if (!!user && user?.SENSITIVE_profile_picture_id) {
      profileImage = await getPatientImage(
        state?.patientId,
        user?.SENSITIVE_profile_picture_id,
      );
    }
    return {
      ...user,
      senderPicture: profileImage,
    };
  }, [getPatient, getPatientImage, state?.patientId]);

  const getProfilePicture = useCallback(
    async (profilePicture: string | undefined) => {
      if (!profilePicture) {
        setProfilePicture(null);
        return;
      }
      const image = await getFileUrlFromStorage({
        variables: {
          fileId: profilePicture,
        },
      });
      setProfilePicture(image.data?.GetSignUrlFormStorage?.url || null);
    },
    [getFileUrlFromStorage],
  );

  const getCollocutor =
    loggedUser?.role === Roles.PATIENT ? getProviderData : getPatientData;

  useEffect(() => {
    getCollocutor()
      .then((data) => {
        const { senderPicture, SENSITIVE_firstname, SENSITIVE_lastname } = data;
        setCollocutor({
          SENSITIVE_firstname,
          SENSITIVE_lastname,
          senderPicture,
        });
      })
      .catch(console.error);

    getProfilePicture(loggedUser?.profilePicture);
  }, [
    getPatientData,
    getProviderData,
    getCollocutor,
    getProfilePicture,
    loggedUser?.profilePicture,
  ]);

  useEffect(() => {
    messages.length > 0 && scrollToBottom();
  }, [messages, scrollToBottom]);
  return (
    <div
      ref={containerRef}
      className="flex flex-col pr-2 gap-4 max-h-full overflow-y-auto"
    >
      {messages &&
        messages.map((message, key) => {
          const isMyMessage = message.senderType === loggedUser?.role;
          const senderPicture = isMyMessage
            ? profilePicture
            : collocutor?.senderPicture;
          const senderName = isMyMessage
            ? message.senderName
            : collocutor?.SENSITIVE_firstname;
          return (
            <ChatMessageComponent
              id={message.id}
              locale={locale}
              key={key}
              mediaURL={message.mediaURL}
              message={message.message}
              messageGroup={message.messageGroup}
              senderPicture={senderPicture}
              senderType={message.senderType}
              senderName={senderName}
              type={message.type}
            />
          );
        })}
    </div>
  );
};
