import { daysRowHeaderProps } from './tabs/dayTab';

export const DaysRowHeader: React.FC<daysRowHeaderProps> = ({ weekDays }) => {
  return (
    <thead>
      <tr>
        {weekDays.map((day: string) => (
          <th
            className="text-xs font-bold text-med-gray-2 uppercase py-2.5"
            key={day}
          >
            {day}
          </th>
        ))}
      </tr>
    </thead>
  );
};
