import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContextType, AuthContext } from 'auth/context/AuthContext';
import { ReactComponent as MobileIcon } from 'assets/icons/mobile.svg';
import { ModalButtonProps } from 'components/modal/modal.interfaces';
import { genericActionsIds, pageIds } from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import {
  DASHBOARD,
  DASHBOARD_ANALIZE_PHOTO,
  DASHBOARD_FACE_CAMERA,
  MY_SKIN,
  MY_SKIN_ANALIZE_PHOTO,
  MY_SKIN_FACE_CAMERA,
} from 'utilities/routes';
import { Helmet } from 'react-helmet';
import Modal from 'components/modal/modalComponent';

export const PhotoSubmission = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoggedIn } = useContext<AuthContextType>(AuthContext);
  const token = location.state?.token || '';
  const capturedPhoto = location.state?.image || '';
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.PHOTO_SUBMISSION,
  });

  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.SUBMIT, genericActionsIds.CANCEL],
    });
  const isDashboard = location.pathname.includes(DASHBOARD);
  const goBackUrl = isDashboard ? DASHBOARD : MY_SKIN;
  const analizePhotoUrl = isDashboard
    ? DASHBOARD_ANALIZE_PHOTO
    : MY_SKIN_ANALIZE_PHOTO;
  const faceCameraUrl = isDashboard
    ? DASHBOARD_FACE_CAMERA
    : MY_SKIN_FACE_CAMERA;

  const handlePhotoSubmission = async () => {
    navigate(analizePhotoUrl, {
      state: { image: capturedPhoto, token },
      replace: true,
    });
  };

  const handlePhotoRetake = () => {
    navigate(faceCameraUrl, {
      state: { token },
      replace: true,
    });
  };

  const handleCancelSubmission = () => {
    navigate(goBackUrl, { replace: true });
  };

  const Buttons: ModalButtonProps[] = [
    {
      label: genericAction?.[genericActionsIds.SUBMIT].submit,
      onClick: handlePhotoSubmission,
    },
    {
      label: locale?.retakePhoto,
      type: 'outlined',
      Icon: MobileIcon,
      onClick: handlePhotoRetake,
    },
    {
      label: genericAction?.[genericActionsIds.CANCEL].cancel,
      type: 'underline',
      onClick: handleCancelSubmission,
    },
  ];

  if ((loading && !locale) || (genericActionLoading && !genericAction))
    return null;

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <Modal
        isOpen={true}
        title={locale?.confirmPhoto}
        buttons={Buttons}
        disableMobileClose={!isLoggedIn}
        onClose={handleCancelSubmission}
      >
        <div className="flex flex-col gap-[30px]">
          <p className="text-base text-med-gray">{locale?.photoSubmission}</p>
          <img
            className="object-cover w-full h-[469px]"
            src={capturedPhoto}
            alt={locale?.scanSubmission}
          />
        </div>
      </Modal>
    </>
  );
};
