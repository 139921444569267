import { useAuth } from 'auth/context/AuthContext';
import { Roles } from '../firebase/interfaces';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { PARAM_MODALS_IDENTIFIERS } from 'utilities/constants';
import { MY_APPOINTMENTS } from 'utilities/routes';

export const useGoToAppointmentDetails = (): ((
  appointmentCodexId: string,
) => void) => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const role = user?.role;

  const goToAppointmentDetails = useCallback(
    (appointmentCodexId: string) => {
      let urlString =
        PARAM_MODALS_IDENTIFIERS.VIEW_APPOINTMENT_DETAILS_MODAL_PATIENT;
      if (role === Roles.PROVIDER) {
        urlString =
          PARAM_MODALS_IDENTIFIERS.APPOINTMENT_DETAILS_MODAL_PROVIDER_ID;
      }
      navigate(`${MY_APPOINTMENTS}?${urlString}=true`, {
        state: { appointmentId: appointmentCodexId },
      });
    },
    [navigate, role],
  );

  return goToAppointmentDetails;
};
