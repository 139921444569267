import { AlertType } from 'components/alertComponent';

export interface CustomAuthCodeAlertData {
  text: string;
  alertType: AlertType;
  show: boolean;
}

export enum SOURCE_TYPE {
  SHOP = 'shop',
}
