import Input from 'components/inputComponent';
import Modal from 'components/modal/modalComponent';
import { CodexTags } from 'graphql/generated/hasura';
import { createModal } from 'layout/createModal';
import { useState, Fragment } from 'react';

export const hasFlag = (item: { flags: string[] }, flag: CodexTags) => {
  const flags = item.flags;
  return !!flags.find((f) => f === flag);
};

export const getIdentifier = (
  item: { identifiers: Array<Array<string>> },
  identifier: string | undefined,
): string | undefined => {
  return item.identifiers.find((id) => id[0] === identifier)?.[1];
};

export const easyFormModal = createModal<
  { title: string } & Record<string, string | undefined>,
  false | Record<string, string | undefined>
>('easyFormModal', ({ state, close, isOpen }) => {
  const { title, ...fields } = state;

  const [currentState, setState] = useState(fields);

  return (
    <Modal
      title={title}
      isOpen={isOpen}
      onClose={() => close(false)}
      buttons={[
        { label: 'cancel', onClick: () => close(false) },
        { label: 'save', onClick: () => close(currentState) },
      ]}
    >
      <>
        {Object.keys(fields).map((k) => (
          <Fragment key={k}>
            {k.toString()}
            <br />
            <Input
              type="text"
              name={k}
              value={currentState[k] ?? ''}
              onChange={(e) =>
                setState({
                  ...currentState,
                  [k]:
                    e.currentTarget.value === ''
                      ? undefined
                      : e.currentTarget.value,
                })
              }
            />
          </Fragment>
        ))}
      </>
    </Modal>
  );
});
