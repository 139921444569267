import { Link } from 'react-router-dom';
import AlertComponent from 'components/alertComponent';
import { interpolateVariables } from 'utilities/functions';

interface AppointmetInconvenienceProps {
  locale: Record<string, Record<string, string | Record<string, string>>>;
  isPatient?: boolean;
}

export const AppointmetInconvenience: React.FC<
  AppointmetInconvenienceProps
> = ({ locale, isPatient = false }) => {
  return (
    <>
      <div className="w-full h-full flex flex-col items-center justify-center gap-[60px]">
        <div className="flex flex-col items-center gap-2">
          <div className="flex w-auto">
            <AlertComponent
              type="info"
              text={interpolateVariables(
                locale?.consultation?.apologizeAlert as string,
                {
                  participant: isPatient
                    ? (locale?.consultation?.provider as string)
                    : (locale?.consultation?.patient as string),
                },
              )}
            />
          </div>
          <div className="text-h1 font-medium text-dark-gray mb-9 text-center">
            {locale?.consultation?.apologize as string}
          </div>
          <div className="text-h6 font-normal text-med-gray w-[690px] mb-[30px]">
            {interpolateVariables(
              locale?.consultation?.apologizeDescription as string,
              {
                participant: isPatient
                  ? (locale?.consultation?.provider as string)
                  : (locale?.consultation?.patient as string),
              },
            )
              .split('\n')
              .map((line, index) => (
                <p key={index}>
                  {line}
                  <br />
                </p>
              ))}
          </div>
          <div className="flex flex-col rounded-10 p-[40px] bg-white w-[320px] desktop:w-fit gap-2.5">
            <div className="text-h7 font-bold text-dark-gray flex justify-center">
              {
                (
                  locale?.consultation?.apologizeActions as Record<
                    string,
                    string
                  >
                )?.title
              }
            </div>
            <div className="flex justify-center w-[280px]">
              <ul className="text-left list-disc pl-4 text-clc-blue gap-0">
                {Object.keys(locale?.consultation?.apologizeActions)
                  .filter(
                    (action) =>
                      (
                        locale?.consultation?.apologizeActions as Record<
                          string,
                          string
                        >
                      )?.[action] !==
                      (
                        locale?.consultation?.apologizeActions as Record<
                          string,
                          string
                        >
                      )?.title,
                  )
                  .map((action, index) => (
                    <li key={index}>
                      <Link
                        className="text-clc-blue underline text-h6 font-bold"
                        to=""
                        target="_blank"
                      >
                        {interpolateVariables(
                          (
                            locale?.consultation?.apologizeActions as Record<
                              string,
                              string
                            >
                          )?.[action] as string,
                          {
                            participant: isPatient
                              ? (locale?.consultation?.provider as string)
                              : (locale?.consultation?.patient as string),
                          },
                        )}
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
