import { AuthContext } from 'auth/context/AuthContext';
import {
  useProviderNotificationSubscriptions,
  useNotificationSubscriptions,
} from 'hooks/authProvider/useNotificationSubscriptions';
import { NotifiationsContext } from 'notifications/contexts/NotificationsContext';
import React, { useContext, useState } from 'react';
import { Roles } from '../../firebase/interfaces';

const ProviderNotificationsHOC: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { setNotificationCount } = useContext(NotifiationsContext);
  useProviderNotificationSubscriptions(setNotificationCount);

  return <>{children}</>;
};

const UserNotificationsHOC: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { setNotificationCount } = useContext(NotifiationsContext);
  useNotificationSubscriptions(setNotificationCount);

  return <>{children}</>;
};

export const NotificationsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { user } = useContext(AuthContext);

  const userRole = user?.role;

  const NotificationsManager =
    userRole === Roles.PROVIDER
      ? ProviderNotificationsHOC
      : UserNotificationsHOC;

  const [notifications, setNotificationCount] = useState<{
    internal: number;
    external: number;
  }>({ internal: 0, external: 0 });

  const value = {
    notificationCount: notifications.external + notifications.internal,
    internalNotificationCount: notifications.internal,
    externalNotificationCount: notifications.external,
    setNotificationCount,
  };

  return (
    <NotifiationsContext.Provider value={value}>
      <NotificationsManager>{children}</NotificationsManager>
    </NotifiationsContext.Provider>
  );
};
