import { useEffect } from 'react';
import { excludeItemsFromElementsArray } from 'utilities/functions';
import { DynamicQuestionnaireSidebarProps } from '../interfaces/dynamicQuestionnaireSidebar.interface';
import { DynamicQuestionnaireSidebarList } from './dynamicQuestionnaireSidebarList';

const DynamicQuestionnaireSidebar: React.FC<
  DynamicQuestionnaireSidebarProps
> = ({
  items,
  currentItem,
  currentIndexPosition,
  prefaceSubtitle,
  isSidebarBlocked,
  onClick,
}) => {
  const paintLines = (paintTheseLines: Element[]) => {
    paintTheseLines.forEach((lineItem) => {
      const lineToPaint = document.getElementById(`${lineItem.id}`);
      lineToPaint?.classList.remove('bg-gray-opacity-15');
      lineToPaint?.classList.add('bg-blue');
    });
  };

  const clearLinesFromIndex = (
    linesList: Element[],
    excludedLines: Element[],
  ) => {
    const clearLines = excludeItemsFromElementsArray(linesList, excludedLines);
    clearLines.forEach((lineItem) => {
      const lineToClear = document.getElementById(`${lineItem.id}`);
      lineToClear?.classList.remove('bg-blue');
      lineToClear?.classList.add('bg-gray-opacity-15');
    });
  };

  const paintCirclesBehindIndex = (index: number) => {
    for (let i = 0; i <= index; i++) {
      const circleToPaint = document.getElementById(`circle-${i}`);
      circleToPaint?.classList.remove('bg-white');
      circleToPaint?.classList.add('bg-blue');
      circleToPaint?.classList.add('text-white');
    }
  };

  const clearCirclesFromIndex = () => {
    const getAllCircles = document.querySelectorAll('.circle');
    getAllCircles.forEach((circle) => {
      if (
        circle.id === `circle-${currentIndexPosition.index}` &&
        !circle.id.includes('complete')
      ) {
        for (
          let i = currentIndexPosition.index + 1;
          i < getAllCircles.length;
          i++
        ) {
          if (!getAllCircles[i].id.includes('complete')) {
            getAllCircles?.[i].classList.remove('bg-blue');
            getAllCircles?.[i].classList.remove('text-white');
            getAllCircles?.[i].classList.add('text-med-gray');
            getAllCircles?.[i].classList.add('bg-white');
          }
        }
      }
    });
  };

  const paintMobileTimeline = (maxLength: number) => {
    const lines = document.querySelectorAll('.mobile-line');
    const linesList = Array.from(lines);
    const paintTheseLines = linesList.splice(0, maxLength);

    paintLines(paintTheseLines);
    clearLinesFromIndex(linesList, paintTheseLines);
    paintCirclesBehindIndex(maxLength);
    clearCirclesFromIndex();
  };

  useEffect(() => {
    paintMobileTimeline(currentIndexPosition.index);
  });

  return (
    <>
      <div className="w-full max-w-[360px] mx-auto">
        <div className="w-full flex desktop:flex-col flex-row">
          <DynamicQuestionnaireSidebarList
            items={items}
            currentItem={currentItem}
            onClick={onClick}
            isSidebarBlocked={isSidebarBlocked}
          />
        </div>
      </div>
      <div className="flex flex-col items-center pb-[30px]">
        <p className="text-h6 text-clc-blue uppercase font-bold pt-4 pb-[30px] desktop:hidden flex">
          {currentItem}
        </p>
        <p className="text-h6 text-med-gray desktop:hidden flex">
          {prefaceSubtitle}
        </p>
      </div>
    </>
  );
};

export default DynamicQuestionnaireSidebar;
