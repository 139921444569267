import {
  ChatMessage,
  MessageType,
} from 'app/my-appointments/components/interfaces';
import { groupChatMessages } from 'app/my-appointments/components/modalContent/VideoRoom';
import { useGetVonageSignUrlFormStorageMutation } from 'graphql/generated/hasura';
import { AppointmentChatMessage } from 'graphql/generated/remote-schema-hasura';
import { useCallback, useState } from 'react';
import { decodeMediaInChat } from 'utilities/functions';
import { Roles } from '../firebase/interfaces';

const getSenderType = (from: string): Roles | null => {
  switch (from.toLowerCase()) {
    case Roles.PATIENT:
      return Roles.PATIENT;
    case Roles.PROVIDER:
      return Roles.PROVIDER;
    default:
      return null;
  }
};

function useParseChatMessages(
  appointmentId: string,
): [
  (messages: AppointmentChatMessage[]) => Promise<ChatMessage[] | undefined>,
  boolean,
] {
  const [chatMediaLoading, setChatMediaLoading] = useState<boolean>(false);
  const [getVonageSignUrlFormStorage] =
    useGetVonageSignUrlFormStorageMutation();
  const parseMessages = useCallback(
    async (messages: AppointmentChatMessage[]) => {
      setChatMediaLoading(true);
      try {
        const messagesParsed = messages.map<ChatMessage>((message) => {
          const decodedMedia = decodeMediaInChat(message.message ?? '');
          if (decodedMedia) {
            return {
              id: decodedMedia.mediaId,
              senderType: getSenderType(message.from) as Roles,
              mediaURL: decodedMedia.mediaUrl,
              message: message.message as string,
              type: MessageType.MEDIA,
            };
          }
          return {
            senderType: getSenderType(message.from) as Roles,
            message: message.message as string,
            id: '',
          };
        });
        const messagesWithMedia = messagesParsed.map(async (m) => {
          if (m.type !== MessageType.MEDIA) return m;
          const signUrl = await getVonageSignUrlFormStorage({
            variables: {
              appointmentId: appointmentId,
              fileId: m.id,
            },
          });
          return {
            ...m,
            mediaURL: signUrl.data?.GetVonageSignUrlFormStorage?.url,
          };
        });
        return groupChatMessages(await Promise.all(messagesWithMedia));
      } catch (error) {
        console.error(error);
      } finally {
        setChatMediaLoading(false);
      }
    },
    [appointmentId, getVonageSignUrlFormStorage],
  );
  return [parseMessages, chatMediaLoading];
}

export default useParseChatMessages;
