import CompleteMyVisitSummaryModal from 'app/my-appointments/components/CompleteMyVisitSummaryModal';
import { ModalSpecification } from 'layout/ModalSpecification';

export const deepLinkableModals = [CompleteMyVisitSummaryModal].reduce(
  (pv, cv) => {
    pv[cv.key] = cv;
    return pv;
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  {} as Record<string, ModalSpecification<any, any>>,
);
