import * as VideoEffects from '@vonage/video-effects';

import { useCallback, useRef } from 'react';

const { isSupported, BackgroundBlurEffect } = VideoEffects;

export interface BackgroundBlurHook {
  startBackgroundBlur: (deviceId: string) => Promise<MediaStream | undefined>;
  destroyTracks: () => void;
  stopEffect: () => Promise<void>;
  isSupported: boolean;
}

export const useBackgroundBlur = (): BackgroundBlurHook => {
  const backgroundBlur = useRef<VideoEffects.BackgroundBlurEffect | null>(null);
  const localMediaTrack = useRef<MediaStream | null>(null);
  const getUserMedia = useCallback(async (localVideo: string) => {
    try {
      const track = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: localVideo },
      });
      localMediaTrack.current = track;
    } catch (e) {
      console.log('OT get user media error ' + e);
    }
  }, []);

  const startBackgroundBlur = useCallback(
    async (deviceId: string) => {
      try {
        await getUserMedia(deviceId);
        backgroundBlur.current = new BackgroundBlurEffect({
          assetsPath: process.env.REACT_APP_ASSETS_PATH || '',
        });
        await backgroundBlur.current.loadModel();
        const outputStream = backgroundBlur.current.startEffect(
          localMediaTrack.current as MediaStream,
        );
        return outputStream;
      } catch (error) {
        console.error('Error in startBackgroundBlur:', error);
      }
    },
    [getUserMedia],
  );

  const stopEffect = useCallback(async () => {
    if (backgroundBlur.current) {
      backgroundBlur.current.stopEffect();
      destroyTracks();
    }
  }, []);

  const destroyTracks = () => {
    if (localMediaTrack.current) {
      localMediaTrack.current.getTracks().forEach((t) => t.stop());
    }
  };

  return {
    startBackgroundBlur,
    destroyTracks,
    stopEffect,
    isSupported,
  };
};
