import { FirebaseOptions, initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { getAuth, GoogleAuthProvider, OAuthProvider } from 'firebase/auth';
import { getFirebaseConfig } from 'utilities/config';
import { Analytics } from 'utilities/interfaces';

const firebaseEnvConfig = getFirebaseConfig();

const firebaseConfig: FirebaseOptions = {
  apiKey: firebaseEnvConfig.firebaseApiKey,
  authDomain: firebaseEnvConfig.firebaseAuthDomain,
  appId: firebaseEnvConfig.firebaseAppId,
  projectId: firebaseEnvConfig.firebaseProjectId,
  storageBucket: firebaseEnvConfig.firebaseStorageBucket,
  messagingSenderId: firebaseEnvConfig.firebaseMessagingSenderId,
  measurementId: firebaseEnvConfig.firebaseMeasurementId,
};

export const firebaseApp = initializeApp(firebaseConfig);

export const firebaseAnalytics: Analytics = {
  logEvent: (eventName, eventData) => {
    logEvent(getAnalytics(firebaseApp), eventName, eventData);
  },
};

export const firebaseAuth = getAuth(firebaseApp);

export const firebaseGoogleAuthProvider = new GoogleAuthProvider();
export const firebaseAppleAuthProvider = new OAuthProvider('apple.com');
