import { useGetFrontAndBackDots } from 'app/my-skin/components/BodySymtomsLocation/hooks';
import { PreworkItemContainer } from './PreworkItemContainer';
import { PreworkSymptomsLocationSection } from './PreworkSymptomsLocationSection';
import { useMemo } from 'react';

interface PreworkSymptomsLocationProps {
  question: string;
  frontLabel: string;
  backLabel: string;
  symptomsLocation: string[] | null | undefined;
}

export const PreworkSymptomsLocation: React.FC<
  PreworkSymptomsLocationProps
> = ({ question, frontLabel, backLabel, symptomsLocation }) => {
  const { backDots: allBackDots, frontDots: allFrontDots } =
    useGetFrontAndBackDots();

  const frontDotsLocations = useMemo<string[]>(() => {
    if (!symptomsLocation?.length || !allFrontDots?.length) return [];
    const frontDotsLocationSet = new Set(symptomsLocation);
    return allFrontDots
      .filter((dot) => dot.location && frontDotsLocationSet.has(dot.location))
      .map((dot) => dot.location as string);
  }, [symptomsLocation, allFrontDots]);

  const backDotsLocations = useMemo<string[]>(() => {
    if (!symptomsLocation?.length || !allBackDots?.length) return [];
    const backDotsLocationSet = new Set(symptomsLocation);
    return allBackDots
      .filter((dot) => dot.location && backDotsLocationSet.has(dot.location))
      .map((dot) => dot.location as string);
  }, [symptomsLocation, allBackDots]);

  return (
    <PreworkItemContainer question={question} gap="gap-3">
      <PreworkSymptomsLocationSection
        title={frontLabel}
        symptoms={frontDotsLocations}
      />
      <PreworkSymptomsLocationSection
        title={backLabel}
        symptoms={backDotsLocations}
      />
    </PreworkItemContainer>
  );
};
