import { JoinableAppointmentParams } from 'app/dashboard/components/upcomingAppointments/interfaces/upcomingAppointments.interfaces';
import { useCallback, useEffect, useState } from 'react';
import { FHIR_APPOINTMENT_STATUS } from 'utilities/interfaces';
import {
  MILLISECONDS_IN_SEC,
  SECONDS_IN_MIN,
} from '../utilities/constants/time';

const joinableStatuses = [FHIR_APPOINTMENT_STATUS.BOOKED];
const SHOW_LINK_BEFORE_START_TIME = 5 * SECONDS_IN_MIN * MILLISECONDS_IN_SEC;

export function useIsJoinable(appointment: JoinableAppointmentParams) {
  const {
    startDate: appointmentStartDate,
    endDate: appointmentEndDate,
    status,
  } = appointment;
  const [isJoinable, setIsJoinable] = useState(false);

  const checkTime = useCallback(() => {
    if (!joinableStatuses.includes(status)) {
      setIsJoinable(false);
      return;
    }
    const appointmentStartTime = new Date(appointmentStartDate).getTime();
    const appointmentEndTime = new Date(appointmentEndDate || '').getTime();
    const now = Date.now();
    const timeDiff = appointmentStartTime - now;

    setIsJoinable(
      timeDiff <= SHOW_LINK_BEFORE_START_TIME && now < appointmentEndTime,
    );
  }, [appointmentEndDate, appointmentStartDate, status]);

  useEffect(() => {
    checkTime();
    const intervalId = setInterval(checkTime, MILLISECONDS_IN_SEC);

    return () => clearInterval(intervalId);
  }, [checkTime]);

  return isJoinable;
}
