import React from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import ButtonComponent from './button/buttonComponent';
import { twMerge } from 'tailwind-merge';

interface CustomProps {
  label: string;
  supportText?: string;
  btnText?: string;
  className?: string;
}

interface IDropzone extends DropzoneOptions, CustomProps {}

const Dropzone: React.FC<IDropzone> = ({
  onDrop,
  supportText,
  accept,
  label,
  className,
}) => {
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    noClick: true,
    accept,
  });

  return (
    <div
      className={twMerge(
        'w-full h-[240px] p-4 flex flex-col items-center justify-center border border-dashed border-med-gray rounded-lg',
        className,
      )}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Drop the files here ...</p>
      ) : (
        <>
          <p className="font-semibold text-lg text-charcoal-gray">{label}</p>
          <p className="font-semibold text-sm text-med-gray">{supportText}</p>
          <ButtonComponent className="mt-8 w-auto" onClick={open}>
            Choose File
          </ButtonComponent>
        </>
      )}
    </div>
  );
};

export default Dropzone;
