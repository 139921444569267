import { PARAM_MODALS_IDENTIFIERS } from 'utilities/constants';
import { useLocation, useNavigate } from 'react-router-dom';
import SelectAProviderTabs from 'components/SelectAProviderTabs';
import { useEffect } from 'react';

export interface SelectAProviderTabProp {
  onProviderSelect: (
    id: string,
    calendarId?: string | null,
    ownerId?: string | null,
  ) => void;
  onNoProviderFound?: () => void;
}

const SelectAProvider: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const appointmentCodexId = location?.state?.appointmentCodexId;

  useEffect(() => {
    if (!appointmentCodexId) {
      navigate(-1);
    }
  }, [appointmentCodexId, navigate]);

  const onProviderSelect = (
    codexProviderId: string,
    calendarId?: string | null,
    ownerId?: string | null,
  ) => {
    navigate(`.?${PARAM_MODALS_IDENTIFIERS.ACUITY_CALENDAR_MODAL}=true`, {
      state: {
        codexProviderId,
        appointmentCodexId,
        calendarId,
        ownerId,
      },
    });
  };

  return <SelectAProviderTabs onProviderSelect={onProviderSelect} />;
};

export default SelectAProvider;
