import { useNavigate } from 'react-router-dom';
import AlertComponent from 'components/alertComponent';
import ButtonComponent from 'components/button/buttonComponent';
import { MY_APPOINTMENTS } from 'utilities/routes';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import { genericActionsIds } from 'utilities/constants';

interface AppointmentCompleteProps {
  locale: Record<string, Record<string, string | Record<string, string>>>;
}

export const AppointmentComplete: React.FC<AppointmentCompleteProps> = ({
  locale,
}) => {
  const { data: genericAction } = useGetGenericActions({
    locale: 'en',
    genericActionId: [genericActionsIds.CLOSE],
  });
  const navigate = useNavigate();

  const handleClose = () => {
    navigate(MY_APPOINTMENTS);
  };

  return (
    <>
      <div className="w-full h-full flex flex-col items-center justify-center gap-[60px]">
        <div className="flex flex-col items-center gap-2">
          <div className="flex w-auto">
            <AlertComponent
              type="positive"
              text={locale?.complete?.alert as string}
            />
          </div>
          <div className="text-h1 font-medium text-dark-gray mb-9 text-center">
            {locale?.complete?.title as string}
          </div>
          <div className="text-h6 font-normal text-med-gray w-[690px] mb-[30px]">
            {locale?.complete?.description as string}
          </div>
          {/* 
          COD-2847: Steve comment: Please disable display of the white box for now, as we don't have the ability to solicit feedback today.  We need a ticket for that behavior.
          <div className="flex flex-col rounded-10 p-[40px] bg-white w-[320px] desktop:w-fit gap-2.5">
            <div className="text-h7 font-bold text-dark-gray flex justify-center">
              {(locale?.complete?.actions as Record<string, string>)?.title}
            </div>
            <div className="flex justify-center w-[280px]">
              <ul className="text-left list-disc pl-4 text-clc-blue gap-0">
                {Object.keys(locale?.complete?.actions)
                  .filter(
                    (action) =>
                      (locale?.complete?.actions as Record<string, string>)
                        ?.title !==
                      (locale?.complete?.actions as Record<string, string>)?.[
                        action
                      ],
                  )
                  .map((action, index) => (
                    <li key={index}>
                      <Link
                        className="text-clc-blue underline text-h6 font-bold"
                        to=""
                        target="_blank"
                      >
                        {
                          (
                            locale?.complete?.actions as Record<string, string>
                          )?.[action] as string
                        }
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
          </div> */}
          <div className="mt-4">
            <ButtonComponent onClick={handleClose} paddingX="px-10">
              {genericAction?.[genericActionsIds.CLOSE].close}
            </ButtonComponent>
          </div>
        </div>
      </div>
    </>
  );
};
