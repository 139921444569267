import { useEffect, useState } from 'react';
import { AccountDetailProps } from 'app/my-account/interfaces/profile.interfaces';
import DatePicker from 'components/datePicker/DatePicker';
import InputComponent from 'components/inputComponent';

export const PersonalDetail: React.FC<AccountDetailProps> = ({
  locale,
  onChange,
  formValues,
  setFormValues,
  onSelectChange,
  onDateChange,
}) => {
  const [selectedEthnicities, setSelectedEthnicities] = useState<string[]>([]);

  const handleCheckboxChange = (option: string) => {
    const isOptionSelected = selectedEthnicities.includes(option);

    if (isOptionSelected) {
      setSelectedEthnicities(
        selectedEthnicities.filter((item) => item !== option),
      );
      setFormValues({
        ...formValues,
        etnicity: selectedEthnicities.filter((item) => item !== option),
      });
    } else {
      setSelectedEthnicities([...selectedEthnicities, option]);
      setFormValues({
        ...formValues,
        etnicity: [...selectedEthnicities, option],
      });
    }
  };

  useEffect(() => {
    setSelectedEthnicities(formValues.etnicity || []);
  }, [formValues.etnicity]);

  return (
    <div className="flex flex-col items-start w-full gap-2.5 p-0">
      <div className="flex flex-row w-full items-center justify-between pb-[15px]">
        <div className="flex flex-row items-center">
          <h3 className="text-h7 desktop:text-h7 text-dark-gray font-bold desktop:mr-2.5">
            {locale.personalInfo.personalDetails.title}
          </h3>
        </div>
      </div>

      <div className="desktop:flex flex-col w-full items-center space-y-4">
        <div className="flex flex-col desktop:flex-row w-full desktop:w-[690px] items-center desktop:items-center">
          <label
            className="w-full desktop:w-3/12 text-base text-dark-gray font-semibold mb-2"
            htmlFor="date-of-birth"
          >
            {locale.personalInfo.personalDetails.dateOfBirthLabel}
            <span className="text-base font-bold text-clc-red">
              {locale.accountDeletion.form.required}
            </span>
          </label>
          <div className="flex flex-row w-full desktop:w-9/12 gap-[15px]">
            <DatePicker
              name="dob"
              onChange={onDateChange}
              value={formValues.dob || ''}
              disableFutureDates
            />
          </div>
        </div>

        <div className="flex flex-col desktop:flex-row w-full desktop:w-[690px] items-center desktop:items-center">
          <label
            className="w-full desktop:w-3/12 text-base text-dark-gray font-semibold mb-2"
            htmlFor="heightFt"
          >
            {locale.personalInfo.personalDetails.heightLabel}
            <span className="text-base font-bold text-clc-red">
              {locale.accountDeletion.form.required}
            </span>
          </label>
          <div className="flex flex-row w-full desktop:w-1/4 gap-[15px]">
            <InputComponent
              type="text"
              name="heightFt"
              value={formValues?.userMeasurements?.heightFt || ''}
              maxLengthValue={1}
              onChange={onChange}
            />
            <label
              className="w-auto desktop:w-3/12 text-base text-med-gray font-semibold mb-2 flex items-center"
              htmlFor="heightFt"
            >
              {locale.personalInfo.personalDetails.feetLabel}
            </label>
          </div>

          <div className="flex flex-row w-full desktop:w-1/4 gap-[15px]">
            <InputComponent
              type="text"
              name="heightIn"
              value={formValues.userMeasurements.heightIn || ''}
              maxLengthValue={2}
              onChange={onChange}
            />
            <label
              className="w-auto desktop:w-3/12 text-base text-med-gray font-semibold mb-2 flex items-center"
              htmlFor="heightIn"
            >
              {locale.personalInfo.personalDetails.inchesLabel}
            </label>
          </div>
        </div>

        <div className="flex flex-col desktop:flex-row w-full desktop:w-[690px] items-center desktop:items-center">
          <label
            className="w-full desktop:w-3/12 text-base text-dark-gray font-semibold mb-2"
            htmlFor="weight"
          >
            {locale.personalInfo.personalDetails.weightLabel}
            <span className="text-base font-bold text-clc-red">
              {locale.accountDeletion.form.required}
            </span>
          </label>
          <div className="flex flex-row w-full desktop:w-1/4 gap-[15px]">
            <InputComponent
              type="text"
              name="weight"
              value={formValues.userMeasurements.weight || ''}
              maxLengthValue={3}
              onChange={onChange}
            />
            <label
              className="w-auto desktop:w-3/12 text-base text-med-gray font-semibold mb-2 flex items-center"
              htmlFor="weight"
            >
              {locale.personalInfo.personalDetails.lbsLabel}
            </label>
          </div>
        </div>

        <div className="flex flex-col desktop:flex-row w-full desktop:w-[690px] items-center desktop:items-center">
          <label
            className="w-full desktop:w-3/12 text-base text-dark-gray font-semibold mb-2"
            htmlFor="waist"
          >
            {locale.personalInfo.personalDetails.waistLabel}
            <span className="text-base font-bold text-clc-red">
              {locale.accountDeletion.form.required}
            </span>
          </label>
          <div className="flex flex-row w-full desktop:w-1/4 gap-[15px]">
            <InputComponent
              type="text"
              name="waist"
              value={formValues?.userMeasurements?.waist || ''}
              maxLengthValue={3}
              onChange={onChange}
            />
            <label
              className="w-auto desktop:w-3/12 text-base text-med-gray font-semibold mb-2 flex items-center"
              htmlFor="waist"
            >
              {locale.personalInfo.personalDetails.inchesLabel}
            </label>
          </div>
        </div>

        <div className="flex flex-col desktop:flex-row w-full desktop:w-[690px] items-center desktop:items-center">
          <label
            className="w-full desktop:w-3/12 text-base text-dark-gray font-semibold mb-2"
            htmlFor="hips"
          >
            {locale.personalInfo.personalDetails.hipsLabel}
            <span className="text-base font-bold text-clc-red">
              {locale.accountDeletion.form.required}
            </span>
          </label>
          <div className="flex flex-row w-full desktop:w-1/4 gap-[15px]">
            <InputComponent
              type="text"
              name="hips"
              value={formValues?.userMeasurements?.hips || ''}
              maxLengthValue={3}
              onChange={onChange}
            />
            <label
              className="w-auto desktop:w-3/12 text-base text-med-gray font-semibold mb-2 flex items-center"
              htmlFor="hips"
            >
              {locale.personalInfo.personalDetails.inchesLabel}
            </label>
          </div>
        </div>

        <div className="flex flex-col desktop:flex-row w-full desktop:w-[690px] items-center desktop:items-start">
          <label
            className="w-full desktop:w-3/12 text-base text-dark-gray font-semibold mb-2"
            htmlFor="ethnicity"
          >
            {locale.personalInfo.personalDetails.ethnicityLabel}
            <span className="text-base font-bold text-clc-red">
              {locale.accountDeletion.form.required}
            </span>
            <div className="font-normal italic text-med-gray">
              {locale.personalInfo.personalDetails.ethnicityText}
            </div>
          </label>
          <div className="flex flex-col w-full desktop:w-4/12 gap-[15px]">
            {(
              locale.personalInfo.personalDetails.ethnicityOptions as string[]
            ).map((option: string) => (
              <InputComponent
                key={option}
                type="checkbox"
                name="ethnicity"
                checkboxProps={{
                  checkboxLabel: option,
                  bottomMargin: true,
                  checked: selectedEthnicities.includes(option),
                  enableVersatileClick: true,
                  onCheckboxChange: () => handleCheckboxChange(option),
                }}
              />
            ))}
          </div>
        </div>

        <div className="flex flex-col desktop:flex-row w-full desktop:w-[690px] items-center desktop:items-center gap-3">
          <label
            className="w-full desktop:w-2/4 text-base text-dark-gray font-semibold"
            htmlFor="gender"
          >
            {locale.personalInfo.personalDetails.genderLabel}
            <span className="text-base font-bold text-clc-red">
              {locale.accountDeletion.form.required}
            </span>
          </label>
          <div className="flex flex-row w-full desktop:w-5/12 gap-[15px]">
            <InputComponent
              type="select"
              value={formValues.gender}
              name="gender"
              selectInputProps={{
                selectValues: locale.personalInfo.personalDetails
                  .genderOptions as string[],
                fullWidth: true,
                defaultValue: locale.personalInfo.personalDetails
                  .selectYourOption as string,
                onSelectChange: onSelectChange,
              }}
            />
          </div>
        </div>

        <div className="flex flex-col desktop:flex-row w-full desktop:w-[690px] items-center desktop:items-center gap-3">
          <label
            className="w-full desktop:w-2/4 text-base text-dark-gray font-semibold"
            htmlFor="selfIdentifyGender"
          >
            {locale.personalInfo.personalDetails.selfIdentifyLabel}
            <span className="text-base font-bold text-clc-red">
              {locale.accountDeletion.form.required}
            </span>
          </label>
          <div className="flex flex-row w-full desktop:w-5/12 gap-[15px]">
            <InputComponent
              type="select"
              value={formValues.selfIdentifyGender}
              name="selfIdentifyGender"
              selectInputProps={{
                selectValues: locale.personalInfo.personalDetails
                  .selfIdentifyOptions as string[],
                fullWidth: true,
                defaultValue: locale.personalInfo.personalDetails
                  .selectYourOption as string,
                onSelectChange: onSelectChange,
              }}
            />
          </div>
        </div>
      </div>

      <hr className="desktop:flex flex-row w-full items-center h-px bg-black-blur" />
    </div>
  );
};
