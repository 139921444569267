import { ResponsiveLine, Serie } from '@nivo/line';
import {
  COMEDONE_GRAPH_ID,
  DermscoreGraphProps,
  GraphType,
  INFLAMMATORY_GRAPH_ID,
  PIH_GRAPH_ID,
  TOTAL_GRAPH_ID,
} from './interface';
import {
  areaLinearGradients,
  areaLinearGradientsMatchers,
  perGraphExtraInfo,
} from './constants';
import { CustomLineLayer } from './CustomLineLayer';
import { ActivePoint } from './ActivePoint';
import { PointTooltip } from './PointTooltip';
import { useMemo } from 'react';
import {
  DERMSCORE_GRAPH_POINT_SIZE,
  DERMSCORE_GRAPH_POINT_COLOR,
  DERMSCORE_GRAPH_POINT_BORDER_WIDTH,
  DERMSCORE_GRAPH_LINE_WIDTH,
} from 'utilities/constants';

function isIncluded(id: GraphType, only: GraphType | undefined) {
  if (!only) return true;
  return id === only;
}
function makeSeriesFromData(id: GraphType, data: Serie['data']) {
  return {
    id,
    data,
    ...perGraphExtraInfo[id],
  };
}

const maxXPadding = 1000 * 3600 * 12;
const maxYPadding = 0.1;

const DermscoreGraph: React.FC<DermscoreGraphProps> = ({ data, only }) => {
  const seriesData = useMemo<Serie[]>(() => {
    const comedoneData = data.map((test) => ({
      x: new Date(test.date).toISOString(),
      y: test.comedones || 0,
    }));
    const pihData = data.map((test) => ({
      x: new Date(test.date).toISOString(),
      y: test.pih || 0,
    }));
    const inflammatoryData = data.map((test) => ({
      x: new Date(test.date).toISOString(),
      y: test.inflammations || 0,
    }));
    const totalData = data.map((test) => ({
      x: new Date(test.date).toISOString(),
      y: test.total || 0,
    }));
    const comedoneSeries = makeSeriesFromData(COMEDONE_GRAPH_ID, comedoneData);
    const pihSeries = makeSeriesFromData(PIH_GRAPH_ID, pihData);
    const inflammatorySeries = makeSeriesFromData(
      INFLAMMATORY_GRAPH_ID,
      inflammatoryData,
    );
    const totalSeries = makeSeriesFromData(TOTAL_GRAPH_ID, totalData);
    const series: Serie[] = [];
    if (isIncluded(COMEDONE_GRAPH_ID, only)) series.push(comedoneSeries);
    if (isIncluded(PIH_GRAPH_ID, only)) series.push(pihSeries);
    if (isIncluded(INFLAMMATORY_GRAPH_ID, only))
      series.push(inflammatorySeries);
    if (isIncluded(TOTAL_GRAPH_ID, only)) series.push(totalSeries);
    return series;
  }, [data, only]);
  const [maxX, maxY] = useMemo<[number, number]>(
    () =>
      seriesData.reduce<[number, number]>(
        (acc, curr) => {
          const [maxX, maxY] = acc;
          const [x, y] = curr.data.reduce<[number, number]>(
            (acc, curr) => {
              const [maxX, maxY] = acc;
              return [
                Math.max(maxX, new Date(curr.x || 0).getTime()),
                Math.max(maxY, Number(curr.y)),
              ];
            },
            [-Infinity, -Infinity],
          );
          return [Math.max(maxX, x), Math.max(maxY, y)];
        },
        [-Infinity, -Infinity],
      ),
    [seriesData],
  );
  const lineLayer =
    seriesData.length > 1 ? CustomLineLayer : ('lines' as const);
  return (
    <div className="h-[500px]">
      <ResponsiveLine
        data={seriesData}
        margin={{ bottom: 50, left: 60 }}
        tooltip={PointTooltip}
        xFormat="time:%Y-%m-%dT%H:%M:%S.%L%Z"
        xScale={{
          format: '%Y-%m-%dT%H:%M:%S.%L%Z',
          precision: 'second',
          type: 'time',
          min: 'auto',
          max: new Date(maxX + maxXPadding),
          useUTC: false,
        }}
        yScale={{
          type: 'linear',
          min: 'auto',
          max: maxY + maxYPadding,
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          format: '%b',
          tickValues: 'every 1 month',
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          truncateTickAt: 0,
        }}
        enablePoints
        pointSize={DERMSCORE_GRAPH_POINT_SIZE}
        pointColor={DERMSCORE_GRAPH_POINT_COLOR}
        pointBorderWidth={DERMSCORE_GRAPH_POINT_BORDER_WIDTH}
        pointBorderColor={{ from: 'serieColor', modifiers: [] }}
        lineWidth={DERMSCORE_GRAPH_LINE_WIDTH}
        enableTouchCrosshair
        useMesh
        enableArea={seriesData.length === 1}
        colors={(d) => d.mainColor}
        defs={areaLinearGradients}
        enableGridY={false}
        fill={areaLinearGradientsMatchers}
        curve="monotoneX"
        layers={[
          'grid',
          'markers',
          'areas',
          lineLayer,
          ActivePoint,
          'slices',
          'axes',
          'legends',
          'mesh',
        ]}
      />
    </div>
  );
};
export default DermscoreGraph;
