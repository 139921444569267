import Modal from 'components/modal/modalComponent';
import { ModalButtonProps } from 'components/modal/modal.interfaces';
import { componentIds, genericActionsIds, pageIds } from 'utilities/constants';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import { useGetComponent } from 'hooks/useGetComponent';
import { ReactComponent as PlusIcon } from 'assets/icons/circledPlusIcon.svg';
// import { ReactComponent as MediaLibraryIcon } from 'assets/icons/mediaLibraryIcon.svg';
// import { ReactComponent as UploadButtonIcon } from 'assets/icons/uploadButton.svg';
import { ReactComponent as RetakePhotoIcon } from 'assets/icons/retakePhoto.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import ButtonComponent from 'components/button/buttonComponent';
import step1Image from 'assets/images/step1.png';
import step2Image from 'assets/images/step2.png';
import { useCallback, useEffect, useRef, useState } from 'react';
import InputComponent from 'components/inputComponent';
import { ReactComponent as ChevronLeftIcon } from 'assets/icons/chevron-left.svg';
import { AlertState } from 'app/my-appointments/interfaces';
import { createModal } from 'layout/createModal';
import { useModal } from 'layout/useModal';
import { AppointmentPreWorkCamera } from 'app/my-appointments/components/appointment-prework/AppointmentPreworkCamera';
import { useUploadFileToStorageMutation } from 'graphql/generated/hasura';
import { useGetPage } from 'hooks/useGetPage';
import { toByteArray } from 'base64-js';
import Alert from 'components/alertComponent';
import { ImageObject } from '../../../my-skin/components/BodySymtomsLocation/interfaces';

export const AppointmentPreworkPreCaptureModal = createModal<
  { bodyPartLocation: string },
  ImageObject | undefined
>('AppointmentPreworkPreCaptureModal', ({ close, isOpen, state }) => {
  const [alert, setAlert] = useState<AlertState | undefined>(undefined);
  const [description, setDescription] = useState<string>();
  const [uploadFile, { loading: loadingUploadFile }] =
    useUploadFileToStorageMutation();
  const { data: preworkLocale } = useGetPage({
    pageId: pageIds.APPOINTMENT_PREWORK,
    locale: 'en',
  });

  const base64ToUint8Array = (base64: string) => {
    const bytes = toByteArray(base64);
    return new Uint8Array(bytes);
  };

  const handleBlobImageUpload = useCallback(
    async (blobString: string, locationToUploadMedia: { current: string }) => {
      if (!blobString || !locationToUploadMedia.current) {
        setAlert({
          message: preworkLocale?.errorUploadingMedia,
          type: 'negative',
        });
        return;
      }
      const mimeString = blobString.split(',')[0].split(':')[1].split(';')[0];
      const base64 = blobString.split(',')[1];
      const bytes = base64ToUint8Array(base64);

      const blob = new Blob([bytes], { type: mimeString });

      try {
        const buffer = await blob.arrayBuffer();
        const result = await uploadFile({
          variables: {
            file: {
              mimetype: mimeString,
              originalname: 'uploaded_image.jpg', // Provide a name for the file
              buffer: Array.from(new Uint8Array(buffer)),
            },
          },
        });

        const { fileId, url } = result.data?.UploadFileToStorage?.file || {};

        if ((result.errors && result.errors.length) || !fileId || !url) {
          setAlert({
            message: preworkLocale?.errorUploadingMedia,
            type: 'negative',
          });
          return;
        }

        return { image: url, mediaId: fileId };
      } catch (error) {
        setAlert({
          message: preworkLocale?.errorUploadingMedia,
          type: 'negative',
        });
      }
    },
    [preworkLocale?.errorUploadingMedia, uploadFile],
  );

  const bodyPartSelected = state.bodyPartLocation;

  const [showRetakePhoto, setShowRetakePhoto] = useState(false);
  const [showContinueScreen, setShowContinueScreen] = useState(false);
  const [imageData, setImageData] = useState<string>();
  const imageUploaded = useRef<ImageObject>();

  const [openCameraModal] = useModal(AppointmentPreWorkCamera);

  const { data: locale, loading: localeLoading } = useGetComponent({
    componentId: componentIds.APPOINTMENT_PRE_WORK_PRE_CAPTURE_MODAL,
    locale: 'en',
  });

  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [
        genericActionsIds.CANCEL,
        genericActionsIds.CONTINUE,
        genericActionsIds.BACK,
        genericActionsIds.SUBMIT,
      ],
    });

  const handleClose = () => {
    close(
      imageUploaded.current
        ? { ...imageUploaded.current, description }
        : undefined,
    );
  };

  const handleNavigateTapPhoto = async () => {
    const imageData = await openCameraModal({
      bodyPartLocation: bodyPartSelected,
    });
    setImageData(imageData);
  };

  const retakePhoto = () => {
    handleNavigateTapPhoto();
  };

  const clearImageData = () => {
    setImageData(undefined);
  };

  const handleContinueScreen = () => setShowContinueScreen(true);

  const handleBackContinueScreen = () => setShowContinueScreen(false);

  const handleSubmit = async () => {
    if (!imageData) return;
    const upload = await handleBlobImageUpload(imageData, {
      current: bodyPartSelected,
    });
    imageUploaded.current = upload;

    if (upload) {
      handleClose();
    }
  };

  const Buttons: ModalButtonProps[] = [
    {
      label: genericAction?.[genericActionsIds.CANCEL].cancel,
      disabled: false,
      onClick: handleClose,
    },
  ];

  const ButtonsWithContinue: ModalButtonProps[] = [
    {
      label: genericAction?.[genericActionsIds.CONTINUE].continue,
      disabled: false,
      onClick: handleContinueScreen,
    },
    {
      label: genericAction?.[genericActionsIds.CANCEL].cancel,
      disabled: false,
      onClick: handleClose,
      type: 'outlined',
    },
  ];

  const ButtonsWithSubmit: ModalButtonProps[] = [
    {
      label: genericAction?.[genericActionsIds.SUBMIT].submit,
      disabled: loadingUploadFile,
      onClick: handleSubmit,
      showSpinner: true,
    },
    {
      label: genericAction?.[genericActionsIds.CANCEL].cancel,
      disabled: false,
      onClick: handleClose,
      type: 'outlined',
    },
  ];

  const handleButtons = () => {
    if (showContinueScreen) {
      return ButtonsWithSubmit;
    } else {
      return showRetakePhoto && imageData ? ButtonsWithContinue : Buttons;
    }
  };

  useEffect(() => {
    if (imageData && imageData.length > 0) {
      setShowRetakePhoto(true);
    } else {
      setShowRetakePhoto(false);
    }
  }, [imageData]);

  if (genericActionLoading || localeLoading) {
    return null;
  }
  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      buttons={handleButtons()}
      title={locale?.modalTitle}
    >
      {alert && <Alert type="warning" text={alert.message} />}
      <div className="flex flex-col ">
        {!showContinueScreen && (
          <p className="font-medium text-med-gray">{locale?.modalSubtitle}</p>
        )}
        {showContinueScreen && (
          <div className="flex justify-start items-start">
            <ButtonComponent
              type="underline"
              onClick={handleBackContinueScreen}
              fullWidthClassName="w-auto"
              iconPosition="left"
              Icon={ChevronLeftIcon}
              iconWidth="w-2.5"
              iconHeight="h-[18px]"
            >
              {genericAction?.[genericActionsIds.BACK].back}
            </ButtonComponent>
          </div>
        )}
        <p className="font-medium text-charcoal-gray text-[28px] mt-[10px]">
          {bodyPartSelected}
        </p>

        {showContinueScreen && (
          <div className="pt-[15px]">
            <p className="font-medium text-med-gray">{locale?.describeIssue}</p>
            <div className="h-[120px]">
              <InputComponent
                textAreaProps={{
                  height: 'h-full',
                  containerHeight: 'h-full',
                  onTextAreaChange: (e) => setDescription(e.target.value),
                }}
                type="text-area"
                onChange={(e) => setDescription(e.target.value)}
                value={description}
              />
            </div>
          </div>
        )}
      </div>
      {showRetakePhoto && !showContinueScreen && (
        <div
          className={`relative flex ${
            showRetakePhoto ? 'justify-end' : 'justify-center'
          } flex-col items-center rounded-[10px] min-w-[300px] min-h-[240px] bg-light-blue border-dashed border border-med-gray`}
        >
          <img
            className="absolute h-full w-full object-cover rounded-[10px]"
            src={`${imageData}`}
            alt="Captured Image"
          />
          <div className="w-full relative bottom-0 flex flex-row bg-camera-blur px-4 pt-2 pb-2 justify-between items-center min-h-[65px]">
            <ButtonComponent
              fullWidthClassName="w-[114px]"
              type="outlined-transparent"
              Icon={RetakePhotoIcon}
              iconPosition="left"
              className="text-white border-white"
              onClick={retakePhoto}
            >
              {locale?.retake}
            </ButtonComponent>
            <ButtonComponent
              fullWidthClassName="w-[45px]"
              type="outlined-transparent"
              Icon={TrashIcon}
              iconPosition="left"
              className="text-white border-white"
              onClick={clearImageData}
            />
          </div>
        </div>
      )}

      {!showRetakePhoto && !showContinueScreen && (
        <div
          onClick={handleNavigateTapPhoto}
          className="flex justify-center flex-col items-center rounded-[10px] min-w-[300px] min-h-[240px] bg-light-blue border-dashed border border-med-gray"
        >
          <p className="font-semibold text-charcoal-gray text-lg mb-[10px]">
            {locale?.tapToTakePhoto}
          </p>
          <PlusIcon />
        </div>
      )}

      {!showContinueScreen && (
        <>
          {/* 
          <div className="flex flex-col gap-3">
            <ButtonComponent
              type="outlined-transparent"
              Icon={MediaLibraryIcon}
              iconPosition="right"
            >
              {locale?.selectFromMediaLibrary}
            </ButtonComponent>
            <ButtonComponent
              type="outlined-transparent"
              Icon={UploadButtonIcon}
              iconPosition="right"
            >
              {locale?.uploadPhoto}
            </ButtonComponent>
          </div>
           */}
          <div className="">
            <p className="font-medium text-charcoal-gray text-base">
              {locale?.bestResultsTitle}
            </p>
            <div className="flex flex-row py-[15px]">
              <img
                className="w-[80px] h-full rounded-10 mr-[10px]"
                src={step1Image}
                alt="Captured Image"
              />
              <p className="font-medium text-med-gray text-base my-auto">
                {locale?.bestResultsText1}
              </p>
            </div>
            <div className="flex flex-row">
              <img
                className="w-[80px] h-full rounded-10 mr-[10px]"
                src={step2Image}
                alt="Captured Image"
              />
              <p className="font-medium text-med-gray text-base my-auto">
                {locale?.bestResultsText2}
              </p>
            </div>
          </div>
        </>
      )}
    </Modal>
  );
});
