import { useGetGenericActions } from 'hooks/useGetGenericActions';
import { genericActionsIds } from 'utilities/constants';

interface DynamicQuestionnairePrefaceProps {
  title: string;
  subtitle: string | null | undefined;
}

const DynamicQuestionnairePreface: React.FC<
  DynamicQuestionnairePrefaceProps
> = ({ title, subtitle }) => {
  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.REQUIRED],
    });

  if (!genericAction || genericActionLoading) return null;

  return (
    <div className="w-full px-7">
      <div className="flex flex-col gap-[30px] desktop:flex-row desktop:items-center desktop:justify-between">
        <h1 className="text-h2 desktop:text-h1 text-dark-gray font-medium">
          {title}
        </h1>
        <p className="text-sm text-clc-red font-bold">
          {genericAction?.[genericActionsIds.REQUIRED].required}
        </p>
      </div>

      <p className="mt-[30px] text-h6 text-med-gray desktop:flex mobile:hidden">
        {subtitle}
      </p>
    </div>
  );
};

export default DynamicQuestionnairePreface;
