import { Point } from '@nivo/line';
import { Scan } from 'app/dashboard/interfaces/scan.interfaces';

export type CurrentPoint = {
  currentPoint?: Point;
};
export const COMEDONE_GRAPH_ID = 'comedone';
export const PIH_GRAPH_ID = 'pih';
export const INFLAMMATORY_GRAPH_ID = 'inflammatory';
export const TOTAL_GRAPH_ID = 'total';

export type GraphType =
  | typeof COMEDONE_GRAPH_ID
  | typeof PIH_GRAPH_ID
  | typeof INFLAMMATORY_GRAPH_ID
  | typeof TOTAL_GRAPH_ID;

export interface DermscoreGraphProps {
  data: Scan[];
  only?: GraphType;
}
