import { AppointmentHistoryRowMobile } from '../appointmentHistory/AppointmentHistoryRowMobile';
import TableContent from 'components/codexTable/TableContent';
import {
  HistoricAppointmentsActionElement,
  customClassesDesktop,
  keyMap,
} from '../appointmentHistory/appointmentHistory';
import { AppointmentsHistoryFilter } from '../appointmentHistory/AppointmentsHistoryFilter';
import { AppointmentsHistoryFilterMobile } from '../appointmentHistory/AppointmentsHistoryFilterMobile';
import { useCallback, useMemo, useState } from 'react';
import { useMyAppointmentsLocale } from '../../pages/MyAppointments';
import {
  AppointmentHistoryRowProps,
  HistoricAppointmentsFilter,
} from '../../interfaces';
import { Record_Order_By } from 'components/codexTable/interfaces/record.interfaces';
import { Order_By } from 'graphql/generated/hasura';
import { ReactComponent as ArrowLoadMoreIcon } from 'assets/icons/arrowLoadMore.svg';
import ButtonComponent from 'components/button/buttonComponent';

export interface AppointmentsHistoryTableProps {
  appointmentHistory: AppointmentHistoryRowProps[];
  onFilter: (filter: HistoricAppointmentsFilter) => void;
  locale?: Record<string, string>;
  totalAppointments: number;
  filterVars: HistoricAppointmentsFilter;
  loadMore?: () => void;
  hideProvider?: boolean;
  isDataLoading?: boolean;
}

export const AppointmentsHistoryTable: React.FC<
  AppointmentsHistoryTableProps
> = ({
  appointmentHistory,
  onFilter,
  locale: localeProp,
  totalAppointments,
  loadMore,
  hideProvider = false,
  filterVars,
  isDataLoading,
}) => {
  const { locale: localeHook } = useMyAppointmentsLocale();
  const locale = { ...localeProp, ...localeHook };

  const [historicAppointmentFilter, setHistoricAppointmentFilter] =
    useState<HistoricAppointmentsFilter>(filterVars);

  const historicAppointmentsOrderBy = useMemo<Record_Order_By>(() => {
    if (
      !historicAppointmentFilter.sort ||
      historicAppointmentFilter.sortDirection === undefined
    )
      return {};
    return {
      [historicAppointmentFilter.sort]: historicAppointmentFilter.sortDirection
        ? Order_By.Asc
        : Order_By.Desc,
    };
  }, [historicAppointmentFilter]);

  const handleSortChange = useCallback(
    (nextSort: Record_Order_By) => {
      setHistoricAppointmentFilter((prevFilter) => ({
        ...prevFilter,
        sort: Object.keys(nextSort)[0],
        sortDirection:
          nextSort[Object.keys(nextSort)[0]] === Order_By.Asc ? 0 : 1,
      }));
      onFilter({
        ...historicAppointmentFilter,
        sort: Object.keys(nextSort)[0],
        sortDirection:
          nextSort[Object.keys(nextSort)[0]] === Order_By.Asc ? 0 : 1,
      });
    },
    [historicAppointmentFilter, onFilter],
  );

  const columnKeys = useMemo(() => {
    if (appointmentHistory.length > 0) {
      const allKeys = appointmentHistory.reduce((keys, appointment) => {
        return [...keys, ...Object.keys(appointment)];
      }, [] as string[]);
      const uniqueKeys = Array.from(new Set(allKeys));
      return uniqueKeys.filter(
        (key) =>
          key !== 'id' &&
          key !== 'actions' &&
          key !== 'summaryCompleted' &&
          key !== 'patientCodexId' &&
          key !== 'providerCodexId' &&
          (key !== 'provider' || !hideProvider),
      );
    }

    return [];
  }, [appointmentHistory, hideProvider]);

  const [sortableColumns, excludeFromSort] = useMemo(() => {
    const excludeFromSort = ['logs', 'name', 'type'];
    const sortableColumns = columnKeys.filter(
      (column) => !excludeFromSort.includes(column),
    );
    return [sortableColumns, excludeFromSort];
  }, [columnKeys]);

  const handleOnFilter = useCallback(() => {
    onFilter(historicAppointmentFilter);
  }, [historicAppointmentFilter, onFilter]);

  const handleResetFilters = useCallback(
    (historicAppointmentFilter: HistoricAppointmentsFilter) => {
      setHistoricAppointmentFilter(historicAppointmentFilter);
      onFilter(historicAppointmentFilter);
    },
    [setHistoricAppointmentFilter, onFilter],
  );

  const filteredNames = useMemo(() => {
    const nameSet = new Set();
    const providers = appointmentHistory
      .map((appointment, index) => ({
        id: String(index),
        name: appointment.name,
      }))
      .filter((provider) => {
        if (nameSet.has(provider.name)) {
          return false;
        } else {
          nameSet.add(provider.name);
          return true;
        }
      });
    return providers;
  }, [appointmentHistory]);

  return (
    <>
      <AppointmentsHistoryFilter
        filter={historicAppointmentFilter}
        setFilter={setHistoricAppointmentFilter}
        onFilter={handleOnFilter}
        patientsList={filteredNames}
        providersList={filteredNames}
        locale={localeProp}
        onResetFilters={handleResetFilters}
      />
      <AppointmentsHistoryFilterMobile
        filter={historicAppointmentFilter}
        setFilter={setHistoricAppointmentFilter}
        sortColumns={sortableColumns}
        onFilter={handleOnFilter}
        patientsList={filteredNames}
        providersList={filteredNames}
        locale={localeProp}
        onResetFilters={handleResetFilters}
      />
      {!!isDataLoading && <>Loading...</>}
      {!isDataLoading && (
        <>
          {appointmentHistory.length ? (
            appointmentHistory.map((appointment) => (
              <AppointmentHistoryRowMobile
                key={appointment.id}
                {...appointment}
                locale={localeProp}
                hideProvider={hideProvider}
              />
            ))
          ) : (
            <>
              <hr className="flex flex-row w-full items-center h-px bg-black-blur desktop:hidden" />
              <p className="w-full text-med-gray font-semibold desktop:hidden">
                {locale.noHistoricAppointment}
              </p>
            </>
          )}

          <TableContent
            columnKeys={columnKeys}
            data={appointmentHistory}
            keyMap={keyMap}
            onColumnSortChange={handleSortChange}
            orderBy={historicAppointmentsOrderBy}
            actions={HistoricAppointmentsActionElement}
            customClasses={customClassesDesktop}
            excludeSort={excludeFromSort}
            locale={locale}
            noDataMessage={locale.noHistoricAppointment}
          />

          <>
            {totalAppointments !== appointmentHistory.length && (
              <ButtonComponent
                type="underline"
                iconPosition="right"
                Icon={ArrowLoadMoreIcon}
                onClick={loadMore && loadMore}
              >
                {locale.seeMore}
              </ButtonComponent>
            )}
          </>
        </>
      )}
    </>
  );
};
