import React from 'react';

export const UVIndexIcon: React.ComponentType<
  React.SVGProps<SVGSVGElement>
> = ({ id, fill, className }) => {
  return (
    <svg
      className={className}
      width="200"
      height="31"
      viewBox="0 0 200 31"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#clip0_${id})`}>
        <rect
          y="0.5"
          width="200"
          height="30"
          fill={`url(#paint0_linear_${id})`}
        />
        <rect
          width="30"
          height="1"
          transform="matrix(0 -1 1 0 39.2002 30.5)"
          fill="white"
        />
        <rect
          width="30"
          height="1"
          transform="matrix(0 -1 1 0 79.4004 30.5)"
          fill="white"
        />
        <rect
          width="30"
          height="1"
          transform="matrix(0 -1 1 0 119.6 30.5)"
          fill="white"
        />
        <rect
          width="30"
          height="1"
          transform="matrix(0 -1 1 0 159.8 30.5)"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id={`paint0_linear_${id}`}
          x1="3.72529e-07"
          y1="15.5"
          x2="200"
          y2="15.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.099" stopColor="#56B31F" />
          <stop offset="0.3" stopColor="#F6A628" />
          <stop offset="0.497" stopColor="#E42D20" />
          <stop offset="0.699" stopColor="#B84687" />
          <stop offset="0.904" stopColor="#70BBFC" />
        </linearGradient>
        <clipPath id={`clip0_${id}`}>
          <rect y="0.5" width="200" height="30" rx="5" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
