import { useState, useRef } from 'react';
import { Crop, centerCrop, makeAspectCrop } from 'react-image-crop';
import { convertFileToBase64, blobToFile } from 'utilities/functions';

const centerAspectCrop = (
  mediaWidth: number,
  mediaHeight: number,
  aspect: number,
) => {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 100,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );
};

export const useImageCrop = (
  initialSrc: string,
  initialAspect: number,
  mimeType: string,
) => {
  const [src, setSrc] = useState<string | null>(initialSrc);
  const [aspect, setAspect] = useState<number | undefined>(initialAspect);
  const [crop, setCrop] = useState<Crop>();
  const imgRef = useRef<HTMLImageElement | null>(null);
  const [completedCrop, setCompletedCrop] = useState<Crop>();

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    if (aspect) {
      const { naturalWidth: width, naturalHeight: height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  };

  const createCroppedImage = async (
    imageSrc: string,
    crop: Crop,
    fileName: string,
  ): Promise<string> => {
    const image = new Image();
    image.src = imageSrc;
    await new Promise((resolve) => {
      image.onload = resolve;
    });

    const canvas = document.createElement('canvas');

    const cropX = (crop.x / 100) * image.width;
    const cropY = (crop.y / 100) * image.height;
    const cropWidth = (crop.width / 100) * image.width;
    const cropHeight = (crop.height / 100) * image.height;

    canvas.width = cropWidth;
    canvas.height = cropHeight;

    const ctx = canvas.getContext('2d');

    if (!ctx) {
      throw new Error('Could not create canvas context.');
    }

    ctx.drawImage(
      image,
      cropX,
      cropY,
      cropWidth,
      cropHeight,
      0,
      0,
      cropWidth,
      cropHeight,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error('Canvas is empty'));
          return;
        }
        window.URL.revokeObjectURL(imageSrc);
        const file = blobToFile(blob, fileName);
        const base64 = convertFileToBase64(file);
        resolve(base64);
      }, mimeType);
    });
  };

  const onCropComplete = async (filename: string) => {
    if (completedCrop?.width && completedCrop?.height && imgRef.current?.src) {
      const parts = filename.split('.');
      const extension = mimeType.split('/')[1];
      const name = `${
        parts.length > 1 ? parts.pop() && parts.join('.') : filename
      }.${extension}`;

      return createCroppedImage(imgRef.current.src, completedCrop, name);
    }
  };

  return {
    src,
    setSrc,
    aspect,
    setAspect,
    crop,
    setCrop,
    imgRef,
    completedCrop,
    setCompletedCrop,
    onImageLoad,
    onCropComplete,
  };
};
