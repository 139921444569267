import { useGetPage } from 'hooks/useGetPage';
import { pageIds } from 'utilities/constants';
import { useMemo } from 'react';
import { parseSelectedValue } from 'utilities/functions';
import { PhoneDataProps } from 'components/dynamicQuestionnaire/interfaces/SignUpProviderModule.interface';

import { useGetCountriesQuery } from 'graphql/generated/hasura';
import { SelectWithFlagsValues } from 'components/inputComponent';
import SvgStringToComponent from '../../../../../../components/SvgStringToComponent/SvgStringToComponent';

export const PhoneDataItem: React.FC<PhoneDataProps> = ({
  country,
  extension,
  phone,
}) => {
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.MY_PROFILE,
  });

  const { data: localePendingReview, loading: loadingPendingReview } =
    useGetPage({
      locale: 'en',
      pageId: pageIds.PROVIDER_USER_PENDING_REVIEW,
    });

  const { data, loading: loadingCountries } = useGetCountriesQuery({});

  const mappedCountriesValues = useMemo(
    () =>
      data?.countries
        ? [...data.countries]
            .sort((a, b) => {
              const codea = parseInt(a.code);
              const codeb = parseInt(b.code);

              if (codea < codeb) {
                return -1;
              } else if (codea > codeb) {
                return 1;
              } else {
                return 0;
              }
            })
            .map((country) => {
              return { code: country.code, flag: country.flag };
            })
        : [],
    [data?.countries],
  );

  const memoizedCountryFlag = useMemo(
    () =>
      country && parseSelectedValue(country as string, mappedCountriesValues),
    [mappedCountriesValues, country],
  );

  if (
    loading ||
    !locale ||
    loadingPendingReview ||
    !localePendingReview ||
    !memoizedCountryFlag ||
    loadingCountries
  )
    return null;

  return (
    <div>
      <div className="text-med-gray font-semibold flex gap-2">
        <div className="w-[30px] h-[30px]">
          <SvgStringToComponent
            svgString={
              (memoizedCountryFlag as SelectWithFlagsValues).flag as string
            }
          />
        </div>
        <div className="flex gap-2">
          <p>{`+${country}`}</p>
          <p>{phone}</p>
        </div>
        <p>
          <span className="text-charcoal-gray mr-2">
            {localePendingReview?.ext}
          </span>
          <span>{extension}</span>
        </p>
      </div>
    </div>
  );
};
