import { useState, useCallback } from 'react';
import * as VideoExpress from '@vonage/video-express';
import ScreenPublisher from '@vonage/video-express/dist/mp/screen-publisher';

interface ScreenSharingHook {
  screen: ScreenPublisher | null;
  isScreenSharing: boolean | null;
  startScreenSharing: () => Promise<void>;
  stopScreenSharing: () => Promise<void>;
}

export const useScreenSharing = ({
  room,
}: {
  room: VideoExpress.Room | null;
}): ScreenSharingHook => {
  const [screen, setScreen] = useState<ScreenPublisher | null>(null);
  const [isScreenSharing, setIsScreenSharing] = useState<boolean | null>(null);

  const startScreenSharing = useCallback(async () => {
    if (room) {
      try {
        const { screen } = room;
        screen.on('started', () => {
          setScreen(screen);
          setIsScreenSharing(true);
        });
        screen.on('stopped', () => {
          console.warn(
            '[useScreensharing] - The screen sharing stopped because: ',
          );
          setScreen(null);
          setIsScreenSharing(false);
        });
        await room.startScreensharing();
      } catch (e) {
        console.error('[useScreensharing] - startScreenSharing error:', e);
      }
    }
  }, [room]);

  const stopScreenSharing = useCallback(async () => {
    if (room) {
      room.stopScreensharing();
      setIsScreenSharing(false);
    }
  }, [room]);

  return {
    screen,
    isScreenSharing,
    startScreenSharing,
    stopScreenSharing,
  };
};
