import { Card } from 'app/my-appointments/components/Card';
import MedicationHistoryFilters from 'app/my-patients/components/MedicationHistoryFilters';
import MedicationHistoryTable from 'app/my-patients/components/MedicationHistoryTable';
import { Record_Order_By } from 'components/codexTable/interfaces/record.interfaces';
import EmptyStateComponent from 'components/EmptyStateComponent';
import Loader from 'components/loaderComponent';
import {
  MedicationHistoryDetailView,
  MedicationHistoryDetailViewModalId,
} from 'components/medicationHistoryDetailView/MedicationHistoryDetailView';
import { useModalParams } from 'components/modal/useModalManager';
import { Order_By } from 'graphql/generated/hasura';
import { useGetMedications } from 'hooks/useGetMedications';
import { useGetPage } from 'hooks/useGetPage';
import { useEffect, useMemo, useRef, useState } from 'react';
import { pageIds } from 'utilities/constants';

export interface MedicationHistoryFilter {
  providerName?: string | null;
  brandName?: string | null;
  find?: string | null;
  fromDate?: string | null;
  toDate?: string | null;
  sortByMobile?: string | null;
  limit?: number;
  orderBy?: Record_Order_By;
}

const medicationHistoryBatchSize = 10;

const MyMedicationHistory: React.FC = () => {
  const { data: locale } = useGetPage({
    locale: 'en',
    pageId: pageIds.MY_MEDICATION_HISTORY,
  });
  const [orderBy, setOrderBy] = useState<Record_Order_By>({});
  const [isContentVisible, setIsContentVisible] = useState(false);
  const [filter, setFilter] = useState<MedicationHistoryFilter>({
    orderBy: {
      date: Order_By.Asc,
    },
  });
  const [appliedFilter, setAppliedFilter] = useState<MedicationHistoryFilter>({
    limit: medicationHistoryBatchSize,
  });
  const toggleContentVisibility = () => setIsContentVisible(!isContentVisible);
  const { isOpen: isMedicationModalOpen } = useModalParams(
    MedicationHistoryDetailViewModalId,
  );
  const firsRender = useRef(true);
  const {
    brands,
    brandsLoading,
    medicationHistoryData,
    medicationLoading,
    medicationsDetails,
    providers,
    providersLoading,
    total,
    allMedicationsLoading,
    allMedicationsAmount,
  } = useGetMedications(appliedFilter);

  const handleFilterUpdate =
    (key: keyof MedicationHistoryFilter) =>
    (value: string | Record_Order_By | null | undefined) => {
      if (key === 'fromDate' && value && filter.toDate) {
        if (new Date(value as string) > new Date(filter.toDate)) {
          setFilter((prev) => ({ ...prev, toDate: null }));
        }
      } else if (key === 'toDate' && value && filter.fromDate) {
        if (new Date(value as string) < new Date(filter.fromDate)) {
          setFilter((prev) => ({ ...prev, fromDate: null }));
        }
      }
      setFilter((prev) => ({ ...prev, [key]: value }));
    };
  const onSeeMore = () => {
    setAppliedFilter((prev) => ({
      ...prev,
      limit: (prev.limit ?? 0) + medicationHistoryBatchSize,
    }));
  };

  useEffect(() => setAppliedFilter((old) => ({ ...old, orderBy })), [orderBy]);

  const current = medicationHistoryData.length;
  const cardSubtitle = `${locale?.showing} ${current} ${locale?.of} ${total}`;
  const sortByList = useMemo<string[]>(() => {
    if (!medicationHistoryData.length || !locale) return [];
    const excludeKeys = ['id'];
    const keys = Object.keys(medicationHistoryData[0]).filter(
      (key) => !excludeKeys.includes(key),
    );
    return keys.flatMap((key) => {
      const firstWord = locale[key as keyof typeof locale] || key;
      return [`${firstWord} - ${locale.asc}`, `${firstWord} - ${locale.desc}`];
    });
  }, [locale, medicationHistoryData]);
  if (
    providersLoading ||
    brandsLoading ||
    !locale ||
    (medicationLoading && firsRender.current) ||
    allMedicationsLoading
  )
    return <Loader />;
  firsRender.current = false;

  return (
    <>
      {isMedicationModalOpen && medicationsDetails && (
        <MedicationHistoryDetailView
          medicationRequestLists={medicationsDetails}
        />
      )}
      {!allMedicationsAmount ? (
        <div className="flex grow justify-center items-center px-5 desktop:min-h-[300px]">
          <EmptyStateComponent
            title={locale?.noResultsTitle}
            text={locale?.noResults}
          />
        </div>
      ) : (
        <div className="flex flex-col gap-6 items-stretch px-4 desktop:px-0">
          <h1 className="text-charcoal-gray font-exo font-medium text-h1">
            {locale.pageTitle}
          </h1>
          <Card
            title={locale.history}
            alwaysExpanded
            subtitle={cardSubtitle}
            showSeparator
          >
            <div
              className={`grow-0 self-stretch flex flex-col ${
                medicationLoading ? 'opacity-50 pointer-events-none' : ''
              }}`}
            >
              <MedicationHistoryFilters
                isContentVisible={isContentVisible}
                providerName={filter.providerName || null}
                fromDate={filter.fromDate || ''}
                toDate={filter.toDate || ''}
                brandName={filter.brandName || null}
                find={filter.find || ''}
                sortByMobile={filter.orderBy as Record_Order_By}
                providers={providers}
                brands={brands}
                onToggleContentVisibility={toggleContentVisibility}
                onProviderSearchChange={handleFilterUpdate('providerName')}
                onFromDateChange={handleFilterUpdate('fromDate')}
                onToDateChange={handleFilterUpdate('toDate')}
                onBrandNameSearchChange={handleFilterUpdate('brandName')}
                onFindSearchChange={handleFilterUpdate('find')}
                handleOnUpdateFilters={() =>
                  setAppliedFilter((prev) => ({ ...prev, ...filter }))
                }
                onSortByMobileChange={handleFilterUpdate('orderBy')}
                locale={locale}
                sortByList={sortByList}
              />
              {medicationHistoryData.length ? (
                <MedicationHistoryTable
                  data={medicationHistoryData}
                  locale={locale}
                  orderBy={orderBy}
                  setOrderBy={setOrderBy}
                  canSeeMore={medicationHistoryData.length < total}
                  onSeeMore={onSeeMore}
                />
              ) : (
                <p className="mt-10 text-charcoal-gray font-medium">
                  {locale.noMedicationHistory}
                </p>
              )}
            </div>
          </Card>
        </div>
      )}
    </>
  );
};

export default MyMedicationHistory;
