import { useEffect, useRef, useState } from 'react';
import { useGetFileFromStorage } from './useGetFileFromStorage';

export const useScanImage = (imageId: string, patientId?: string | null) => {
  const [imageUrl, setImageUrl] = useState('');
  const [fileUrl, setFileUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const { fetchFileFromStorage, fetchImage, fetchPatientImage } =
    useGetFileFromStorage();

  const firstRendered = useRef(true);
  const [changeImage, setChangeImage] = useState<boolean>(false);

  useEffect(() => {
    if (firstRendered.current || changeImage) {
      firstRendered.current = false;
      setChangeImage(false);
      (async () => {
        setLoading(true);
        const url = await fetchFileFromStorage(imageId);
        let file = '';
        if (patientId) {
          file = await fetchPatientImage(imageId, patientId);
        } else {
          file = await fetchImage(imageId);
        }
        setImageUrl(url);
        setFileUrl(file);
        setLoading(false);
      })();
    }
  }, [
    imageId,
    fileUrl,
    fetchFileFromStorage,
    fetchImage,
    changeImage,
    fetchPatientImage,
    patientId,
  ]);

  return { imageUrl, fileUrl, loading, setChangeImage };
};
