import { ReactComponent as InfoIcon } from 'assets/icons/weatherIcons/question.svg';
import { ReactComponent as Indicator } from 'assets/icons/weatherIcons/indicator.svg';
import { AQIndexIcon } from 'assets/icons/weatherIcons/AqIndexIcon';
import { Link } from 'react-router-dom';
import { DASHBOARD } from 'utilities/routes';
import IconComponent from 'components/iconComponent';

interface AQIndexProps {
  id: string;
  aqIndex: number;
  locale: {
    aqIndexTitle: string;
    aqIndex: {
      good: string;
      moderate: string;
      unhealthySensitive: string;
      unhealthy: string;
      veryUnhealthy: string;
      hazardous: string;
    };
  };
}

const AQIndex: React.FC<AQIndexProps> = ({ id, aqIndex, locale }) => {
  const getAQIndexCategory = (aqIndex: number) => {
    if (aqIndex >= 0 && aqIndex < 2) {
      return locale.aqIndex.good;
    } else if (aqIndex < 3) {
      return locale.aqIndex.moderate;
    } else if (aqIndex < 4) {
      return locale.aqIndex.unhealthySensitive;
    } else if (aqIndex < 5) {
      return locale.aqIndex.unhealthy;
    } else if (aqIndex < 6) {
      return locale.aqIndex.veryUnhealthy;
    } else if (aqIndex >= 6) {
      return locale.aqIndex.hazardous;
    }

    return null;
  };

  return (
    <div className="flex flex-col flex-1 items-center">
      <div className="flex flex-row gap-1">
        <span className="font-semibold">{locale.aqIndexTitle}</span>
        <Link to={`${DASHBOARD}?aqi-info=true`}>
          <IconComponent
            width="w-5"
            height="h-5"
            icon={InfoIcon}
            fill="fill-clc-blue"
          />
        </Link>
      </div>
      <div className="flex flex-col items-center justify-center gap-7 min-h-[160px]">
        <div className="text-[48px]">{aqIndex}</div>
        <div className="flex flex-col items-center gap-2 relative">
          <IconComponent
            width="w-[200px]"
            height="h-[30px]"
            icon={() => <AQIndexIcon id={id} />}
            fill="none"
          />
          <div
            className="absolute top-[-10px] ml-[-7px]"
            style={{
              left: aqIndex > 6 ? '100%' : `${(aqIndex / 6) * 100}%`,
            }}
          >
            <IconComponent
              width="w-[14px]"
              height="h-[6px]"
              icon={Indicator}
              fill="none"
            />
          </div>
          <p className="text-sm text-med-gray">{getAQIndexCategory(aqIndex)}</p>
        </div>
      </div>
    </div>
  );
};

export default AQIndex;
