import TableContent, { localeType } from 'components/codexTable/TableContent';
import {
  CustomClassNamesProps,
  KeyMapProps,
} from 'components/codexTable/interfaces/keymap.interfaces';
import { Record_Order_By } from 'components/codexTable/interfaces/record.interfaces';
import { Order_By } from 'graphql/generated/hasura';
import React from 'react';
import { ReactComponent as EyeIcon } from 'assets/icons/pwEyeShow.svg';
import TableContentMobile from 'components/codexTable/TableContentMobile';
import {
  MedicationHistoryData,
  MedicationHistoryTableProps,
} from '../interfaces/MadicationHistory.interface';
import ButtonComponent from 'components/button/buttonComponent';
import { useIsMobile } from 'hooks/useIsMobile';
import { useNavigate } from 'react-router-dom';
import { MedicationHistoryDetailViewModalId } from 'components/medicationHistoryDetailView/MedicationHistoryDetailView';
import { ReactComponent as ArrowLoadMoreIcon } from 'assets/icons/arrowLoadMore.svg';
import { formatDateToCustomFormat } from 'utilities/functions';

const keyMap: KeyMapProps = {
  date: {
    column: 'date',
    isActive: (orderBy: Record_Order_By) => (orderBy.date ? true : false),
    direction: (orderBy: Record_Order_By) =>
      (orderBy.date as Order_By) || Order_By.Desc,
    FormatValue: ({ value }) => (
      <>{formatDateToCustomFormat(value as string, true)}</>
    ),
  },
  brand: {
    column: 'brand',
    isActive: (orderBy: Record_Order_By) => (orderBy.brand ? true : false),
    direction: (orderBy: Record_Order_By) =>
      (orderBy.brand as Order_By) || Order_By.Desc,
  },
  provider: {
    column: 'provider',
    isActive: (orderBy: Record_Order_By) => (orderBy.provider ? true : false),
    direction: (orderBy: Record_Order_By) =>
      (orderBy.provider as Order_By) || Order_By.Desc,
  },
};

const customClasses: CustomClassNamesProps = {
  date: {
    customRowClassName: 'text-left pl-2.5',
  },
  brand: {
    customRowClassName: 'text-left pl-2.5',
  },
  provider: {
    customRowClassName: 'text-left pl-2.5',
  },
};

type actionsComponentProps = MedicationHistoryData & { locale?: localeType };

const ActionsComponent: React.FC<actionsComponentProps> = ({ locale, id }) => {
  const navigate = useNavigate();
  const handleViewDetails = () => {
    navigate(`.?${MedicationHistoryDetailViewModalId}=true`, {
      state: { medicationId: id },
    });
  };
  return (
    <div className="flex items-start desktop:items-center justify-start desktop:justify-end">
      <ButtonComponent
        noUpperCase
        type="underline"
        iconPosition="left"
        Icon={EyeIcon}
        onClick={handleViewDetails}
        fullWidthClassName="w-max"
      >
        {locale?.viewDetails}
      </ButtonComponent>
    </div>
  );
};

const columnKeys: (keyof MedicationHistoryData)[] = [
  'date',
  'brand',
  'provider',
];

export const MedicationHistoryTable: React.FC<MedicationHistoryTableProps> = ({
  data,
  locale,
  orderBy,
  setOrderBy,
  canSeeMore,
  onSeeMore,
}) => {
  const wrapper = (nextSort: Record_Order_By) => {
    const sortColumn = Object.keys(nextSort)[0];
    const oldSortDirection = nextSort[sortColumn];
    const sortDirection =
      oldSortDirection === Order_By.Asc ? Order_By.Desc : Order_By.Asc;
    setOrderBy({ [sortColumn]: sortDirection });
  };
  const isMobile = useIsMobile();
  if (isMobile) {
    return (
      <div className="desktop:hidden">
        <TableContentMobile
          keyMap={keyMap}
          columnKeys={columnKeys}
          data={data}
          actions={ActionsComponent}
          locale={locale}
          vertical
          onSeeMore={onSeeMore}
          canSeeMore={canSeeMore}
        />
      </div>
    );
  }
  return (
    <div className="mt-[30px] hidden desktop:flex desktop:flex-col gap-3">
      <TableContent
        columnKeys={columnKeys}
        data={data}
        keyMap={keyMap}
        orderBy={orderBy}
        onColumnSortChange={wrapper}
        customClasses={customClasses}
        actions={ActionsComponent}
        locale={locale}
        bottomBorder
      />
      <div className="self-start">
        <ButtonComponent
          disabled={!canSeeMore}
          type="underline"
          iconPosition="right"
          Icon={ArrowLoadMoreIcon}
          onClick={onSeeMore}
        >
          {locale.seeMore}
        </ButtonComponent>
      </div>
    </div>
  );
};

export default MedicationHistoryTable;
