import { FooterTypes } from 'components/footer/footer.types';
import Footer from 'components/footer/footerComponent';
import { ReactComponent as SGBNETLogo } from 'assets/images/sgbnetLogo.svg';
import { useNavigate } from 'react-router-dom';
import { AUTH_LOGIN } from 'utilities/routes';

interface AuthLayoutProps {
  children: React.ReactNode;
  background?: string;
  footerType: FooterTypes;
  contentSize: string;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({
  children,
  background,
  footerType,
  contentSize,
}) => {
  const navigate = useNavigate();

  return (
    <div
      className={`flex flex-col bg-base-content bg-no-repeat ${background} desktop:bg-top desktop:min-h-screen`}
    >
      <div className="absolute desktop:min-w-[1300px] top-0 left-0 right-0 flex items-center justify-center pt-[60px] desktop:pt-20">
        <SGBNETLogo
          onClick={() => navigate(AUTH_LOGIN)}
          className="cursor-pointer w-[210px] desktop:w-[320px]"
        />
      </div>
      <div
        className={`h-full mx-7 mb-[35px] pt-40 ${contentSize} desktop:mx-auto desktop:my-auto desktop:justify-center`}
      >
        {children}
      </div>
      <Footer type={footerType} />
    </div>
  );
};

export default AuthLayout;
