import React, { useEffect, useState } from 'react';
import {
  Dnavisit_Test_Statuses_Enum,
  GetLatestDnaTestsForSuperAdminQuery,
  useGetLatestDnaVisitTestsMutation,
  useGetSignedUrlFromStorageByAdminMutation,
} from 'graphql/generated/hasura';
import { formatDateToCustomFormat } from 'utilities/functions';

import DNAVisitIFrameTest from './TestIframe';

export const DnaVisitLatestTests = () => {
  const [email, setEmail] = useState('');
  const [getLatestDnaVisitTests, { data }] = useGetLatestDnaVisitTestsMutation(
    {},
  );
  const [getSignedUrlFromStorageByAdmin] =
    useGetSignedUrlFromStorageByAdminMutation({});

  useEffect(() => {
    getLatestDnaVisitTests();
  }, [getLatestDnaVisitTests]);

  const getFileSignedUrl = async (userId: string, fileId: string) => {
    try {
      const signedUrl = await getSignedUrlFromStorageByAdmin({
        variables: { userId: userId, fileId: fileId },
      });

      if (signedUrl.data?.GetSignUrlFromStorageByAdmin?.url) {
        window.open(
          signedUrl.data?.GetSignUrlFromStorageByAdmin?.url,
          '_blank',
        );
      }
    } catch (error) {
      console.error(
        'There was an error fetching the signed url by an admin.',
        error,
      );
    }
  };

  if (!data || !data.GetLatestDnaVisitTests.dnavisit_tests.length) return null;

  const query: GetLatestDnaTestsForSuperAdminQuery =
    data.GetLatestDnaVisitTests;

  return (
    <div className="container mx-auto my-8">
      <h2 className="text-2xl font-bold mb-4">Tests registrations</h2>
      <table className="min-w-full bg-white border border-gray-300">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b text-left">Date</th>
            <th className="py-2 px-4 border-b text-left">Name</th>
            <th className="py-2 px-4 border-b text-left">Lastname</th>
            <th className="py-2 px-4 border-b text-left">Email</th>
            <th className="py-2 px-4 border-b text-left">Swabcode</th>
            <th className="py-2 px-4 border-b text-left">Test type</th>
            <th className="py-2 px-4 border-b text-left">Status</th>
            <th className="py-2 px-4 border-b text-left">Tracking number</th>
            <th className="py-2 px-4 border-b text-left">Errors</th>
          </tr>
        </thead>
        <tbody>
          {query.dnavisit_tests.map((test, index) => (
            <React.Fragment key={index}>
              <tr className={`${test.errors ? 'bg-negative' : ''}`}>
                <td className="py-2 px-4 border-b text-left">
                  {formatDateToCustomFormat(test.created_at)}
                </td>
                <td className="py-2 px-4 border-b text-left">
                  {test.dnavisit_tests_test.tests_user.SENSITIVE_firstname}
                </td>
                <td className="py-2 px-4 border-b text-left">
                  {test.dnavisit_tests_test.tests_user.SENSITIVE_lastname}
                </td>
                <td className="py-2 px-4 border-b text-left">
                  {test.dnavisit_tests_test.tests_user.SENSITIVE_email}
                </td>
                <td className="py-2 px-4 border-b text-left">
                  {test.sapphiros_barcode}
                </td>
                <td className="py-2 px-4 border-b text-left">
                  {test.dnavisit_tests_test.tests_test_definition.test_type}
                </td>
                <td className="py-2 px-4 border-b text-left">{test.state}</td>
                <td className="py-2 px-4 border-b text-left">
                  {test.tracking_number}
                </td>
                <td className="py-2 px-4 border-b text-left">
                  {JSON.stringify(test.errors)}
                </td>
                {test.state ===
                  Dnavisit_Test_Statuses_Enum.ResultsWithProvider && (
                  <td className="py-2 px-4 border-b text-left">
                    <button
                      onClick={() =>
                        setEmail(
                          test.dnavisit_tests_test.tests_user.SENSITIVE_email,
                        )
                      }
                    >
                      DNA Appointment
                    </button>
                  </td>
                )}
              </tr>

              <tr>
                <td className="py-2 px-4 border-b text-left">
                  {test.dna_test_questionnaire_pdf_id && (
                    <a
                      className="text-clc-blue font-bold cursor-pointer"
                      onClick={() =>
                        getFileSignedUrl(
                          test.dnavisit_tests_test.tests_user.id,
                          test.dna_test_questionnaire_pdf_id,
                        )
                      }
                    >
                      Dna test questionnaire
                    </a>
                  )}
                </td>

                <td className="py-2 px-4 border-b text-left">
                  {test.health_questionnaire_pdf_id && (
                    <a
                      className="text-clc-blue font-bold cursor-pointer"
                      onClick={() =>
                        getFileSignedUrl(
                          test.dnavisit_tests_test.tests_user.id,
                          test.health_questionnaire_pdf_id,
                        )
                      }
                    >
                      Health questionnaire
                    </a>
                  )}
                </td>

                <td className="py-2 px-4 border-b text-left">
                  {test.sapphiros_report_pdf_id && (
                    <a
                      className="text-clc-blue font-bold cursor-pointer"
                      onClick={() =>
                        getFileSignedUrl(
                          test.dnavisit_tests_test.tests_user.id,
                          test.sapphiros_report_pdf_id,
                        )
                      }
                    >
                      Sapphiros report
                    </a>
                  )}
                </td>

                <td className="py-2 px-4 border-b text-left">
                  {test.provider_report_pdf_id && (
                    <a
                      className="text-clc-blue font-bold cursor-pointer"
                      onClick={() =>
                        getFileSignedUrl(
                          test.dnavisit_tests_test.tests_user.id,
                          test.provider_report_pdf_id,
                        )
                      }
                    >
                      Provider report
                    </a>
                  )}
                </td>

                <td className="py-2 px-4 border-b text-left">
                  {test.patient_pdf_id && (
                    <a
                      className="text-clc-blue font-bold cursor-pointer"
                      onClick={() =>
                        getFileSignedUrl(
                          test.dnavisit_tests_test.tests_user.id,
                          test.patient_pdf_id,
                        )
                      }
                    >
                      Patient report
                    </a>
                  )}
                </td>

                <td className="py-2 px-4 border-b text-left">
                  {test.visit_summary_pdf_id && (
                    <a
                      className="text-clc-blue font-bold cursor-pointer"
                      onClick={() =>
                        getFileSignedUrl(
                          test.dnavisit_tests_test.tests_user.id,
                          test.visit_summary_pdf_id,
                        )
                      }
                    >
                      Visit summary report
                    </a>
                  )}
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
      {email && <DNAVisitIFrameTest email={email} />}
    </div>
  );
};
