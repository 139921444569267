import { Dispatch, SetStateAction } from 'react';
import {
  DynamicQuestionResponseSection,
  QuestionnaireResponseInterface,
  childrenInputResponseTypes,
  questionStatusI,
  questionnaire_questions_question,
  RequiredNestedIdsList,
} from '../interfaces/dynamicQuestionnaireResponse.interface';
import ThirdLevelNestedQuestion from './ThirdLevelNestedQuestion';
import RenderNestedQuestionOptions from './RenderNestedQuestionOptions';
import { HandleOnInputChangeType } from '../interfaces/dynamicQuestionnaire.interface';

type OmitNull<T> = Exclude<T, null>;

interface NestedQuestionsComponentProps {
  questionStatus: questionStatusI;
  questionToRender: questionnaire_questions_question | null;
  answerChecked: OmitNull<childrenInputResponseTypes>;
  nestedAnswerChecked?: OmitNull<childrenInputResponseTypes>;
  thirdLevelQuestionToRender: questionnaire_questions_question | null;
  thirdLevelParentId: string | null | undefined;
  questionParentID: number;
  childrenOriginalQuestionID: string | null | undefined;
  onChange: HandleOnInputChangeType;
  handleCheckboxToggle: (
    answer: string,
    questionID: number | undefined,
    questionParentID?: number,
    childrenOriginalQuestionID?: string,
  ) => void;
  clearCheckboxState: (questionID: number) => void;
  currentSection: DynamicQuestionResponseSection;
  radioCheckedState?: QuestionnaireResponseInterface[];
  setRadioCheckedState?: Dispatch<
    SetStateAction<QuestionnaireResponseInterface[]>
  >;
  checkboxesCheckedState?: QuestionnaireResponseInterface[];
  setCheckboxesCheckedState?:
    | Dispatch<SetStateAction<QuestionnaireResponseInterface[]>>
    | undefined;
  textFieldResponses?: QuestionnaireResponseInterface[];
  setTextFieldResponses?:
    | Dispatch<SetStateAction<QuestionnaireResponseInterface[]>>
    | undefined;
  requiredNestedQuestionsIds: RequiredNestedIdsList;
  getIncompleteNestedQuestions: () => RequiredNestedIdsList;
  submitWasTriggered: boolean;
}

const NestedQuestionsComponent: React.FC<NestedQuestionsComponentProps> = ({
  questionStatus,
  questionToRender,
  answerChecked,
  nestedAnswerChecked,
  onChange,
  thirdLevelQuestionToRender,
  thirdLevelParentId,
  questionParentID,
  childrenOriginalQuestionID,
  handleCheckboxToggle,
  clearCheckboxState,
  currentSection,
  radioCheckedState,
  setRadioCheckedState,
  checkboxesCheckedState,
  setCheckboxesCheckedState,
  textFieldResponses,
  setTextFieldResponses,
  requiredNestedQuestionsIds,
  getIncompleteNestedQuestions,
  submitWasTriggered,
}) => {
  const isRequiredQuestion = (
    questionId: string,
    mainQuestionId: string,
  ): boolean => {
    if (questionId) {
      for (let i = 0; i < requiredNestedQuestionsIds.length; i++) {
        const object = requiredNestedQuestionsIds[i];
        if (mainQuestionId in object && object[mainQuestionId] === questionId) {
          return true;
        }
      }
    }

    return false;
  };

  const missingNestedIds = getIncompleteNestedQuestions();

  const isIncompleteNestedQuestion = (parentId: number, childId: string) => {
    return missingNestedIds.some((rel) => rel[parentId] === childId);
  };

  return (
    <div key={questionStatus.dependency}>
      <div className="mb-2.5">
        <p
          className={`${
            isIncompleteNestedQuestion(
              questionParentID,
              questionToRender?.questionnaire_questions_question.id.toString() ||
                '',
            ) && submitWasTriggered
              ? 'text-clc-red'
              : 'text-dark-gray'
          } font-semibold `}
        >
          {questionToRender?.questionnaire_questions_question.text}
          {isRequiredQuestion(
            questionToRender?.questionnaire_questions_question.id.toString() ||
              '',
            questionParentID.toString(),
          ) && <span className="text-base font-bold text-clc-red"> *</span>}
        </p>
        {questionToRender?.questionnaire_questions_question.subtitle && (
          <p className="italic text-med-gray">
            {questionToRender?.questionnaire_questions_question.subtitle}
          </p>
        )}
      </div>

      <RenderNestedQuestionOptions
        questionToRender={questionToRender}
        answerChecked={answerChecked}
        nestedAnswerChecked={nestedAnswerChecked}
        questionParentID={questionParentID}
        childrenOriginalQuestionID={childrenOriginalQuestionID}
        onChange={onChange}
        handleCheckboxToggle={handleCheckboxToggle}
        clearCheckboxState={clearCheckboxState}
        currentSection={currentSection}
        radioCheckedState={radioCheckedState}
        setRadioCheckedState={setRadioCheckedState}
        checkboxesCheckedState={checkboxesCheckedState}
        setCheckboxesCheckedState={setCheckboxesCheckedState}
        textFieldResponses={textFieldResponses}
        setTextFieldResponses={setTextFieldResponses}
        isRequiredQuestion={isRequiredQuestion(
          questionToRender?.questionnaire_questions_question.id.toString() ||
            '',
          questionParentID.toString(),
        )}
      />

      {/* Handle third-level nesting */}
      <div className="desktop:pl-10">
        {questionToRender?.questionnaire_questions_question.id.toString() ===
          thirdLevelParentId && (
          <ThirdLevelNestedQuestion
            questionToRender={thirdLevelQuestionToRender}
            onChange={onChange}
            questionParentID={questionParentID}
            childrenOriginalQuestionID={childrenOriginalQuestionID}
            textFieldResponses={textFieldResponses}
            setTextFieldResponses={setTextFieldResponses}
            isRequiredQuestion={isRequiredQuestion(
              thirdLevelQuestionToRender?.questionnaire_questions_question.id.toString() ||
                '',
              questionParentID.toString(),
            )}
            isIncompleteNestedQuestion={
              submitWasTriggered &&
              isIncompleteNestedQuestion(
                questionParentID,
                thirdLevelQuestionToRender?.questionnaire_questions_question.id.toString() ||
                  '',
              )
            }
          />
        )}
      </div>
    </div>
  );
};

export default NestedQuestionsComponent;
