import {
  AccountSettingsState,
  SectionsLoaded,
} from './providerModuleInterfaces';

export interface AutomaticApprovalPreferencesProps {
  locale: Record<string, string>;
  setAccountSettings: React.Dispatch<
    React.SetStateAction<AccountSettingsState | undefined>
  >;
  setIsAllSectionsLoaded: React.Dispatch<React.SetStateAction<SectionsLoaded>>;
}

export enum AutomaticApprovalPreferencesKeys {
  AUTO_APPROVAL_PATIENTS_CODEX_LABS_SETTING,
  AUTO_APPROVAL_TESTKIT_SCRIPTS_CODEX_LABS_SETTING,
}

export interface AutomaticApprovalPreferencesState {
  AUTO_APPROVAL_PATIENTS_CODEX_LABS_SETTING: boolean;
  AUTO_APPROVAL_TESTKIT_SCRIPTS_CODEX_LABS_SETTING: boolean;
}
