import { useMemo } from 'react';
import {
  GetTemplateMessagesQueryVariables,
  useGetTemplateMessagesQuery,
} from 'graphql/generated/strapi';
import { useStrapi } from 'apollo/LocalesProvider';

export const useGetMessageTemplates = (
  variables: GetTemplateMessagesQueryVariables,
) => {
  const { client } = useStrapi();
  const { data, loading, error } = useGetTemplateMessagesQuery({
    variables,
    client: client,
    fetchPolicy: 'cache-first',
  });

  const messageTemplatesData = data?.messageTemplates?.data.map(
    (dataItem) => dataItem?.attributes?.body,
  );

  const messageTemplatesMemoized = useMemo(
    () => ({ data: messageTemplatesData, loading, error }),
    [messageTemplatesData, loading, error],
  );

  return messageTemplatesMemoized;
};
