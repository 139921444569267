import IconComponent from './iconComponent';
import { ModalButtonProps } from './modal/modal.interfaces';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import Modal from './modal/modalComponent';

interface InactivityModalProps {
  inactivityModalOpen: boolean;
  title: string;
  message: string;
  timerMessage: string;
  remainingTime: string;
  continueMessage: string;
  buttons: ModalButtonProps[];
  onClose: () => void;
}

const InactivityModal: React.FC<InactivityModalProps> = ({
  inactivityModalOpen,
  title,
  message,
  timerMessage,
  remainingTime,
  continueMessage,
  buttons,
  onClose,
}) => {
  return (
    <Modal
      isOpen={inactivityModalOpen}
      title={title}
      modalDesktopWidth="desktop:max-w-[600px]"
      buttons={buttons}
      onClose={onClose}
      enableOverlapOfModals={true}
    >
      <div className="flex flex-col desktop:flex-row p-5 desktop:py-3 px-5 gap-[15px] bg-white rounded-lg">
        <IconComponent
          width="w-12 desktop:w-6"
          height="h-12 desktop:h-6"
          icon={InfoIcon}
          fill="fill-clc-blue"
        />
        <div className="flex flex-col justify-center">
          <p className="text-base font-normal text-med-gray">{message}</p>
          <div className="ml-auto mr-auto mt-[15px]">
            <p className="text-base font-normal text-med-gray">
              {timerMessage}
            </p>
            <p className="text-h1 font-semibold text-med-gray">
              {remainingTime}
            </p>
          </div>
        </div>
      </div>
      <p className="text-h5 ml-auto mr-auto font-normal text-med-dark">
        {continueMessage}
      </p>
    </Modal>
  );
};

export default InactivityModal;
