import React from 'react';

export const PatientOutlineIcon: React.ComponentType<
  React.SVGProps<SVGSVGElement>
> = ({ fill, className }) => {
  return (
    <svg
      className={className}
      width="18"
      height="21"
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99424 9.47517C9.03768 9.47517 9.32004 9.44477 9.6806 9.22322C9.72404 9.18412 9.78486 9.14503 9.85871 9.10593C10.4191 8.6889 11.201 7.72018 11.4703 5.40046V5.39177L11.5094 4.72278C11.5094 3.95389 11.2966 3.37179 10.936 2.95041C10.2149 2.09464 9.47208 1.973 8.99424 1.973C8.51639 1.973 7.77356 2.09464 7.05245 2.95041C6.69624 3.37613 6.48338 3.95389 6.47904 4.72278L6.51813 5.39177V5.40046C6.78746 7.72018 7.56939 8.69324 8.12977 9.10593C8.20362 9.14068 8.2601 9.18412 8.30788 9.22322C8.66844 9.44477 8.9508 9.47083 8.99424 9.47517ZM8.99424 10.8696C9.15063 10.8696 9.76314 10.8305 10.4799 10.3657L10.4929 10.3614L10.5972 10.2875C11.5746 9.6055 12.5477 8.22409 12.8518 5.5134V5.47431L12.8561 5.42652L12.8952 4.75754V4.74016C12.8952 3.69759 12.6041 2.77231 11.996 2.0512C10.9491 0.808797 9.77617 0.574219 8.98555 0.574219C8.19493 0.574219 7.02204 0.808797 5.97513 2.0512C5.36696 2.77665 5.07591 3.69759 5.07591 4.74016V4.75754L5.115 5.42652V5.5134C5.42343 8.22409 6.3965 9.60115 7.37391 10.2875L7.47817 10.3614H7.4912V10.3657C8.20797 10.8262 8.82048 10.8696 8.97686 10.8696H8.99424Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.99598 10.5004C7.11761 10.8653 6.92213 11.2606 6.55288 11.3822C5.18451 11.8383 3.28182 12.7245 2.32178 14.1885C1.75271 15.0616 1.56157 16.0825 1.80484 17.2554C1.83959 17.3075 1.91344 17.4031 2.04811 17.4899C2.18277 17.5768 2.41301 17.6811 2.78659 17.6941L2.87348 17.0425V17.0338C2.96036 16.5038 3.45992 16.0825 4.04637 16.1737C4.572 16.2562 4.99337 16.7558 4.90649 17.3336L4.79355 18.2111C5.32786 18.5586 6.59198 19.1581 8.99858 19.1711C11.3965 19.1581 12.6693 18.5586 13.2036 18.2067L13.0907 17.3292C13.0081 16.7384 13.4295 16.2562 13.9508 16.1737H13.9551C14.5503 16.0825 15.0368 16.5082 15.1193 17.0338V17.0425L15.2062 17.6941C15.5755 17.6811 15.8014 17.5768 15.936 17.4899C16.0707 17.4031 16.1489 17.3075 16.188 17.2467C16.4269 16.0738 16.2445 15.0573 15.671 14.1885C14.711 12.7202 12.8083 11.834 11.4399 11.3779C11.075 11.2562 10.8752 10.8609 10.9968 10.496C11.1185 10.1311 11.5138 9.9313 11.8787 10.0529C13.2992 10.5264 15.6015 11.5343 16.8352 13.4196C17.6606 14.6663 17.8735 16.1129 17.5303 17.6463L17.5129 17.7289L17.4738 17.8071C17.4087 17.9374 17.1915 18.337 16.7006 18.6585C16.1967 18.9886 15.4712 19.1928 14.4851 19.0408L14.42 19.0321L14.4113 19.0408C13.9247 19.4795 12.3609 20.5482 9.00293 20.5655H8.99424C5.6276 20.5482 4.07243 19.4752 3.5859 19.0408L3.57721 19.0321L3.51205 19.0408C2.52161 19.1885 1.79615 18.9886 1.2879 18.6585C0.797021 18.337 0.579818 17.9374 0.50597 17.7766L0.479905 17.7158L0.466873 17.6507C0.123693 16.1085 0.349584 14.6663 1.15758 13.4239C2.39129 11.5386 4.69363 10.5264 6.11414 10.0529C6.47904 9.9313 6.87434 10.1268 6.99598 10.496V10.5004Z"
        fill={fill}
      />
    </svg>
  );
};
