import React, { useState } from 'react';
import { ReactComponent as ChevronLeftIcon } from 'assets/icons/chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from 'assets/icons/chevron-right.svg';
import { Scan } from '../interfaces/scan.interfaces';
import CarouselDots from 'components/carouselDotsComponent';
import CarouselArrow from 'components/carouselArrow';
import ScanIndicators from './ScanIndicators';
import ScanActions from './ScanActions';
import ScanSource from './ScanSource';
import ScanImage from 'components/ScanImage';

interface ScanCarouselProps {
  scans: Scan[];
  showHr?: boolean;
  useCurrentPathNavigate?: boolean;
}

const ScanCarousel: React.FC<ScanCarouselProps> = ({
  scans,
  showHr = true,
  useCurrentPathNavigate = false,
}) => {
  const [selectedScanIndex, setSelectedScanIndex] = useState(0);

  const incrementScanIndex = () => {
    setSelectedScanIndex((index) => (index + 1) % scans.length);
  };

  const decrementScanIndex = () => {
    setSelectedScanIndex((index) => (index - 1 + scans.length) % scans.length);
  };

  return (
    <>
      <div className="flex flex-row w-full items-center justify-between p-0 gap-[15px]">
        <div className="flex flex-row w-full items-start justify-center">
          <div className="flex flex-col w-full items-center gap-5 mb-4">
            <div className="flex w-full flex-row justify-between items-center p-0 gap-2.5">
              <ScanImage
                className="w-20 h-[100px] rounded-10 overflow-hidden bg-cover bg-center"
                imageId={scans[selectedScanIndex].imageId}
                isCarousel
              />
              <ScanIndicators
                inflammations={scans[selectedScanIndex].inflammations}
                comedones={scans[selectedScanIndex].comedones}
                pih={scans[selectedScanIndex].pih}
                total={scans[selectedScanIndex].total}
                date={scans[selectedScanIndex].date}
                hideDate
              />
            </div>
            <div className="my-4 w-full">
              <div className="w-full text-base desktop:text-sm font-semibold text-med-gray">
                {scans[selectedScanIndex].date}
              </div>
              <ScanSource source={scans[selectedScanIndex].source} />
            </div>
            <ScanActions
              scan={scans[selectedScanIndex]}
              useCurrentPathNavigate={useCurrentPathNavigate}
              withTitles
            />
          </div>
        </div>
      </div>
      {scans.length > 1 && (
        <div className="flex flex-row items-center justify-around w-full">
          <CarouselArrow onClick={decrementScanIndex} icon={ChevronLeftIcon} />
          <CarouselDots
            carouselLength={scans.length}
            selectedDotIndex={selectedScanIndex}
          />
          <CarouselArrow onClick={incrementScanIndex} icon={ChevronRightIcon} />
        </div>
      )}
      {showHr && (
        <hr className="flex flex-row w-full items-center h-px bg-black-blur" />
      )}
    </>
  );
};

export default ScanCarousel;
