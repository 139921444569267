import React from 'react';
import { SharedMedia } from '../interfaces/interfaces';
import { SharedMediaRecentResult } from './SharedMediaRecentResult';
import SharedMediaCarousel from './SharedMediaCarousel';
import DermscoreMediaItem from 'app/my-account/pages/my-media-library/components/DermscoreMediaItem';

interface RecentSharedMediasProps {
  sharedMedia: SharedMedia[];
  locale: Record<string, string>;
}

const RecentSharedMedias: React.FC<RecentSharedMediasProps> = ({
  sharedMedia,
  locale,
}) => {
  return (
    <>
      <div className="flex flex-col w-full items-center gap-5 desktop:hidden">
        <SharedMediaCarousel
          locale={locale}
          medias={sharedMedia}
          showHr={false}
        />
      </div>
      <div
        className={`hidden desktop:flex desktop:${
          sharedMedia.length < 3 ? 'justify-start' : 'justify-between'
        } desktop:space-x-8 desktop:flex-row desktop:align-middle`}
      >
        {sharedMedia.map((media) => {
          if (media.dermscoreId) {
            return (
              <DermscoreMediaItem
                key={media.id}
                scanId={media.dermscoreId}
                imageSrc={media.img}
              />
            );
          }
          return (
            <SharedMediaRecentResult
              locale={locale}
              media={media}
              key={media.id}
            />
          );
        })}
      </div>
    </>
  );
};

export default RecentSharedMedias;
