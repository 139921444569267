import { PARAM_MODALS_IDENTIFIERS, pageIds } from 'utilities/constants';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { generateContextAndProvider } from 'components/LocaleProvider';
import { DASHBOARD } from 'utilities/routes';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CodexFeatureFlags } from 'utilities/interfaces';
import { AuthContext } from 'auth/context/AuthContext';
import { Roles } from '../../../firebase/interfaces';
import { PatientAppointmentsMainPage } from '../components/PatientAppointmentsMainPage';
import { ProviderAppointmentsMainPage } from '../components/ProviderAppointmentsMainPage';
import { useModalParams } from 'components/modal/useModalManager';
import { SharedMediaModal } from 'app/my-patients/components/SharedMediaModal';

const [useLocale, LocaleProvider] = generateContextAndProvider();
export { LocaleProvider as MyAppointmentsLocaleProvider };
export { useLocale as useMyAppointmentsLocale };

export const MyAppointments = () => {
  const navigate = useNavigate();
  const { appointmentRoutesDisabledFlag } = useFlags<CodexFeatureFlags>();
  const { user } = useContext(AuthContext);
  const { isOpen: isMediaModalOpen } = useModalParams(
    PARAM_MODALS_IDENTIFIERS.SHARED_MEDIA_MODAL_ID,
  );

  const getMyAppointmentsScreen = (userRole: string | undefined) => {
    if (userRole) {
      switch (userRole) {
        case Roles.PROVIDER:
          return <ProviderAppointmentsMainPage />;
        case Roles.PATIENT:
          return <PatientAppointmentsMainPage />;
        default:
          return <PatientAppointmentsMainPage />;
      }
    }
  };

  useEffect(() => {
    if (appointmentRoutesDisabledFlag) {
      navigate(DASHBOARD);
    }
  }, [appointmentRoutesDisabledFlag, navigate]);

  return (
    <>
      {isMediaModalOpen && <SharedMediaModal />}
      <LocaleProvider locale="en" pageId={pageIds.MY_APPOINTMENTS}>
        {getMyAppointmentsScreen(user?.role)}
      </LocaleProvider>
    </>
  );
};
