import AppointmentPreworkDesktop from 'app/my-appointments/pages/AppointmentPreworkDesktop';
import AppointmentPreworkMobile from 'app/my-appointments/pages/AppointmentPreworkMobile';
import { useIsMobile } from 'hooks/useIsMobile';

const AppointmentPrework: React.FC = () => {
  const isMobile = useIsMobile();
  if (isMobile) return <AppointmentPreworkMobile />;
  return <AppointmentPreworkDesktop />;
};

export default AppointmentPrework;
