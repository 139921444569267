export const accountInfoRequiredFields = [
  'firstname',
  'lastname',
  'email',
  'phone',
  'nationalProviderIdentifier',
];

export const personalInfoRequiredFields = [
  'gender',
  'languagesOfCommunication',
  'about',
];

export const contactInfoOfficeAddressRequiredFields = [
  'officeName',
  'email',
  'phone',
  'address1',
  'country',
  'state',
  'city',
  'zipCode',
];

export const contactInfoPersonalAddressRequiredFields = [
  'address1',
  'country',
  'state',
  'city',
];

export const questionsIds = {
  GENDER_QUESTION: 9,
  LANGUAGES_QUESTION: 11,
  ABOUT_QUESTION: 12,
  CREDENTIALS_TITLE_QUESTION: 13,
  CREDENTIALS_STATE_QUESTION: 14,
  CREDENTIALS_BOARD_QUESTION: 15,
  EDUCATION_SCHOOL_QUESTION: 16,
  EDUCATION_RESIDENCY_QUESTION: 17,
  EDUCATION_UNDERGRADUATE_QUESTION: 18,
};

export const ALLOWED_SELECT_CHARACTERS = /^[a-zA-Z\s.'-]+$/;
