import { useGetPage } from 'hooks/useGetPage';
import React, { useMemo } from 'react';
import {
  LocaleContextValue,
  LocaleGenericProvider,
} from 'utilities/interfaces';

export const generateContextAndProvider = () => {
  const Context = React.createContext<LocaleContextValue>({
    loading: true,
    locale: {},
  });
  const Provider: React.FC<LocaleGenericProvider> = ({
    children,
    locale,
    pageId,
    localeMock,
  }) => {
    const { data: localeResult, loading } = useGetPage({
      locale: locale,
      pageId: pageId,
    });
    const value = useMemo<LocaleContextValue>(
      () => ({
        locale: {
          ...localeMock,
          ...localeResult,
        },
        loading,
      }),
      [localeResult, localeMock, loading],
    );
    return <Context.Provider value={value}>{children}</Context.Provider>;
  };

  const useLocale = () => {
    const { loading, locale } = React.useContext(Context);
    return { loading, locale: locale as Record<string, string> };
  };

  return [useLocale, Provider, Context] as const;
};
