export const useImageRotate = () => {
  const rotateImage = (src: string, angle: number) => {
    const img = new Image();
    img.src = src;

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) throw new Error('Could not create canvas context.');
    canvas.width = img.height;
    canvas.height = img.width;
    ctx.translate(canvas.width / 2, canvas.height / 2);
    ctx.rotate((angle * Math.PI) / 180);
    ctx.drawImage(img, -img.width / 2, -img.height / 2);

    return canvas.toDataURL();
  };

  return {
    rotateImage,
  };
};
