import { Route, Routes } from 'react-router-dom';
import { BackOfficeRoutes } from 'backoffice/routes/BackOfficeRoutes';
import { LegalDocumentRoutes } from 'legalDocument/routes/LegalDocumentRoutes';
import { AppRoutes } from 'app/routes/AppRoutes';
import { AuthenticatedRoute } from './AuthenticatedRoute';
import { Action } from 'auth/pages/Action/Action';
import {
  AUTH_ROUTES,
  BACK_OFFICE_ROUTES,
  LEGAL_DOCUMENTS_ROUTES,
  MFA_ACTION,
  NOT_FOUND_ROUTE,
} from 'utilities/routes';
import { UnauthenticatedRoute } from './UnauthenticatedRoute';
import { AuthRoutes } from 'auth/routes/AuthRoutes';
import NetworkRequiredRoutes from 'components/networkRequiredRoutes';

export const AppRouter = () => {
  return (
    <Routes>
      <Route
        path={MFA_ACTION}
        element={
          <NetworkRequiredRoutes>
            <Action />
          </NetworkRequiredRoutes>
        }
      />

      <Route
        path={AUTH_ROUTES}
        element={
          <UnauthenticatedRoute>
            <AuthRoutes />
          </UnauthenticatedRoute>
        }
      />

      <Route
        path={BACK_OFFICE_ROUTES}
        element={
          <AuthenticatedRoute>
            <BackOfficeRoutes />
          </AuthenticatedRoute>
        }
      />

      <Route path={LEGAL_DOCUMENTS_ROUTES} element={<LegalDocumentRoutes />} />
      <Route path={NOT_FOUND_ROUTE} element={<AppRoutes />} />
    </Routes>
  );
};
