import { Medication } from 'app/my-appointments/components/interfaces';

export interface CompleteMyVisitSummaryModalMedicationTableProps {
  locale: Record<string, string>;
  medications: Medication[];
}

const CompleteMyVisitSummaryModalMedicationTable: React.FC<
  CompleteMyVisitSummaryModalMedicationTableProps
> = ({ locale, medications }) => {
  return (
    <table className="w-full">
      <thead>
        <tr className="border-b">
          <th className="text-left py-2 font-bold text-base">{locale.type}</th>
          <th className="text-left py-2 font-bold text-base">{locale.brand}</th>
          <th className="text-left py-2 font-bold text-base">
            {locale.delivery}
          </th>
          <th className="text-left py-2 font-bold text-base">
            {locale.dosage}
          </th>
          <th className="text-left py-2 font-bold text-base">
            {locale.frequency}
          </th>
        </tr>
      </thead>
      <tbody>
        {medications.map((medication) => (
          <tr key={medication.id} className="border-b">
            <td className="py-2 text-base text-med-gray">{medication.type}</td>
            <td className="py-2 text-base text-med-gray">{medication.brand}</td>
            <td className="py-2 text-base text-med-gray">
              {medication.delivery}
            </td>
            <td className="py-2 text-base text-med-gray">
              {medication.dosage.quantity}
              <span> {locale[medication.dosage.unit]}</span>
            </td>
            <td className="py-2 text-base text-med-gray">
              {medication.frequency.quantity}
              <span> {locale[medication.frequency.unit]}</span>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default CompleteMyVisitSummaryModalMedicationTable;
