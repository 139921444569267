import React from 'react';

export const AQIndexIcon: React.ComponentType<
  React.SVGProps<SVGSVGElement>
> = ({ id, fill, className }) => {
  return (
    <svg
      className={className}
      width="200"
      height="31"
      viewBox="0 0 200 31"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#clip0_${id})`}>
        <rect
          y="0.5"
          width="200"
          height="30"
          fill={`url(#paint0_linear_${id})`}
        />
        <rect
          width="30"
          height="1"
          transform="matrix(0 -1 1 0 32.5 30.5)"
          fill="white"
        />
        <rect
          width="30"
          height="1"
          transform="matrix(0 -1 1 0 66 30.5)"
          fill="white"
        />
        <rect
          width="30"
          height="1"
          transform="matrix(0 -1 1 0 99.5 30.5)"
          fill="white"
        />
        <rect
          width="30"
          height="1"
          transform="matrix(0 -1 1 0 133 30.5)"
          fill="white"
        />
        <rect
          width="30"
          height="1"
          transform="matrix(0 -1 1 0 166.5 30.5)"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id={`paint0_linear_${id}`}
          x1="3.72529e-07"
          y1="15.5"
          x2="200"
          y2="15.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0808333" stopColor="#AAD330" />
          <stop offset="0.246667" stopColor="#F7F211" />
          <stop offset="0.415833" stopColor="#EDA626" />
          <stop offset="0.586667" stopColor="#DB032C" />
          <stop offset="0.745" stopColor="#A6004E" />
          <stop offset="0.920833" stopColor="#8F1523" />
        </linearGradient>
        <clipPath id={`clip0_${id}`}>
          <rect y="0.5" width="200" height="30" rx="5" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
