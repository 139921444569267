import { Card } from './Card';
import { SharedMediaProps } from '../interfaces/interfaces';
import { useHandleMediaClick } from 'hooks/useHandleMediaClick';
import { MY_PATIENTS_BIO_INFO_SHARED_MEDIA } from 'utilities/routes';
import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import { capitalizeFirstLetter } from 'utilities/functions';
import Placeholder from './Placeholder';

export const SharedMedia: React.FC<SharedMediaProps> = ({
  locale,
  sharedMedia,
  total,
}) => {
  const title = locale.sharedMediaTitle;
  const hasSharedMedia = useMemo(() => sharedMedia.length > 0, [sharedMedia]);
  const subtitle = capitalizeFirstLetter(
    hasSharedMedia
      ? `${locale.showing} ${sharedMedia.length} ${locale.of} ${total}`
      : `${locale.showing} ${sharedMedia.length}`,
  );
  const [handleMediaClick] = useHandleMediaClick();
  const navigate = useNavigate();
  const goToSharedMedia = () => {
    navigate(`../${MY_PATIENTS_BIO_INFO_SHARED_MEDIA}`, {
      replace: true,
    });
  };

  return (
    <Card
      title={title}
      subtitle={subtitle}
      buttonText={total > sharedMedia.length ? locale.viewAllButton : undefined}
      customYPadding="py-0"
      buttonCTA={goToSharedMedia}
      className="min-h-[416px]"
    >
      <div className="grid flex-grow grid-cols-3 grid-rows-auto auto-rows-fr gap-y-5 desktop:gap-y-[34px] gap-x-5 desktop:gap-x-[51px]">
        {hasSharedMedia ? (
          sharedMedia.map((media) => (
            <div className="flex min-w-[70px]" key={media.id}>
              <img
                src={media.img}
                onClick={() => handleMediaClick(media)}
                alt={locale.sharedMediaAltImage}
                className="object-cover w-full min-w-[70px] aspect-square cursor-pointer"
              />
            </div>
          ))
        ) : (
          <Placeholder
            className="col-span-3"
            placeholder={locale.placeholder}
          />
        )}
      </div>
    </Card>
  );
};
