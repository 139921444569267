import { ReactComponent as UpArrowIcon } from 'assets/icons/upArrow.svg';
import InputComponent from 'components/inputComponent';
import ButtonComponent from 'components/button/buttonComponent';
import DatePicker from 'components/datePicker/DatePicker';
import { MedicationHistoryFiltersProps } from '../interfaces/MadicationHistory.interface';
import { useMemo } from 'react';
import { Record_Order_By } from 'components/codexTable/interfaces/record.interfaces';
import { Order_By } from 'graphql/generated/hasura';

const MedicationHistoryFilters: React.FC<MedicationHistoryFiltersProps> = ({
  isContentVisible,
  providerName,
  brandName,
  fromDate,
  toDate,
  find,
  sortByMobile,
  providers,
  brands,
  onToggleContentVisibility,
  onProviderSearchChange,
  onBrandNameSearchChange,
  onFromDateChange,
  onToDateChange,
  onFindSearchChange,
  handleOnUpdateFilters,
  onSortByMobileChange,
  locale,
}) => {
  const [sortByList, orderByMap, orderByStrings] = useMemo(() => {
    if (!locale.sortByMobileDirections || !locale.sortByMobile) return [[]];
    const directions = locale.sortByMobileDirections as unknown as Record<
      string,
      string
    >;
    const orderByMap = new Map<string, Record_Order_By>();
    const orderByStrings: Record<string, Record<string, string>> = {};
    const sortByList = Object.entries(locale.sortByMobile).flatMap<string>(
      ([key, value]) => {
        const ascString = `${value} - ${directions.asc}`;
        const descString = `${value} - ${directions.desc}`;
        orderByMap.set(ascString, { [key]: Order_By.Asc });
        orderByMap.set(descString, { [key]: Order_By.Desc });
        orderByStrings[key] = {
          [Order_By.Asc]: ascString,
          [Order_By.Desc]: descString,
        };
        return [ascString, descString];
      },
    );
    return [sortByList, orderByMap, orderByStrings];
  }, [locale]);

  const onProviderUpdate = (providerName: string) => {
    onProviderSearchChange(providerName === locale.all ? null : providerName);
  };

  const onBrandUpdate = (brandName: string) => {
    onBrandNameSearchChange(brandName === locale.all ? null : brandName);
  };

  const [sortKey, sortDirection] = Object.entries(sortByMobile || {})[0];

  return (
    <div className="bg-white rounded-10">
      <div className="flex flex-col bg-base-content rounded-10 mt-[30px]">
        <div
          className={`flex items-center flex-row justify-between px-5 pt-5 cursor-pointer ${
            !isContentVisible ? 'pb-5' : 'pb-3'
          }`}
          onClick={onToggleContentVisibility}
        >
          <h4 className="text-h5 font-semibold text-dark-gray">
            {locale.findAndFilter}
          </h4>
          <UpArrowIcon
            className={`self-center transition-transform duration-300 ${
              !isContentVisible && 'rotate-180'
            }`}
            width={12}
            height={12}
          />
        </div>
        {isContentVisible && (
          <div className="p-5">
            <div className="border-y py-4 mb-3">
              <div className="flex flex-col desktop:flex-row justify-between gap-6 desktop:gap-[30px] mt-5">
                <div className="flex grow flex-col desktop:flex-row gap-2 desktop:items-center">
                  <label className="text-dark-gray font-semibold desktop:shrink-0">
                    {locale.findLabel}
                  </label>
                  <div className="desktop:w-full mt-[5px]">
                    <InputComponent
                      type="text"
                      name="find"
                      value={find}
                      onChange={(e) => onFindSearchChange(e.target.value)}
                    />
                  </div>
                </div>
                <div className="flex flex-col desktop:flex-row gap-[30px]">
                  <div className="flex flex-row items-center gap-2">
                    <span className="w-10 desktop:w-auto font-semibold">
                      {locale.from}
                    </span>
                    <DatePicker
                      onChange={(e) => onFromDateChange(e.target.value)}
                      value={fromDate}
                    />
                  </div>
                  <div className="flex flex-row items-center gap-2">
                    <span className="w-10 desktop:w-auto font-semibold">
                      {locale.to}
                    </span>
                    <DatePicker
                      onChange={(e) => onToDateChange(e.target.value)}
                      value={toDate}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col desktop:flex-row justify-between gap-6 desktop:gap-[30px] mt-5">
                <div className="flex flex-col desktop:flex-row desktop:items-center gap-2 desktop:gap-2 flex-1">
                  <div className="flex-wrap font-semibold">
                    {locale.byBrandSelect}
                  </div>
                  <div className="flex-1">
                    <InputComponent
                      type="select"
                      name="viewByBrand"
                      value={brandName || locale.all}
                      selectInputProps={{
                        onSelectChange: (e) => onBrandUpdate(e.target.value),
                        selectValues: [locale.all, ...brands],
                        fullWidth: true,
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-col desktop:flex-row desktop:items-center gap-2 desktop:gap-2 flex-1">
                  <div className="flex-wrap font-semibold">
                    {locale.byProviderSelect}
                  </div>
                  <div className="flex-1">
                    <InputComponent
                      type="select"
                      name="viewByProvider"
                      value={providerName || locale.all}
                      selectInputProps={{
                        onSelectChange: (e) => onProviderUpdate(e.target.value),
                        selectValues: [locale.all, ...providers],
                        fullWidth: true,
                      }}
                    />
                  </div>
                </div>

                <div className="flex flex-col desktop:flex-row desktop:items-center gap-2 desktop:gap-2 flex-1 desktop:hidden">
                  <div className="flex-wrap font-semibold">
                    {locale.mobileSortBy}
                  </div>
                  <div className="flex-1">
                    <InputComponent
                      type="select"
                      name="orderBy"
                      value={
                        sortKey && orderByStrings
                          ? orderByStrings[sortKey][
                              (sortDirection as string) || Order_By.Asc
                            ]
                          : ''
                      }
                      selectInputProps={{
                        selectValues: sortByList,
                        onSelectChange: (e) =>
                          onSortByMobileChange(orderByMap?.get(e.target.value)),
                        fullWidth: true,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-end mt-3">
              <ButtonComponent type="outlined" onClick={handleOnUpdateFilters}>
                {locale.updateResults}
              </ButtonComponent>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MedicationHistoryFilters;
