import Spinner from './Spinner';
import { useLayoutEffect, useRef, useState } from 'react';

export const MiniLoaderComponent: React.FC = () => {
  const divContainerRef = useRef<HTMLDivElement>(null);
  const [spinnerBorderSizeClass, setSpinnerBorderSizeClass] =
    useState('border-2');
  useLayoutEffect(() => {
    if (divContainerRef.current) {
      const width = divContainerRef.current.clientWidth;
      const widthBreakpoint = 250;
      if (width > widthBreakpoint) {
        setSpinnerBorderSizeClass('border-4');
      }
    }
  }, []);
  return (
    <div
      ref={divContainerRef}
      className="flex relative flex-row items-stretch justify-center h-full w-full px-[25%]"
    >
      <div className="grow flex flex-col justify-center items-center">
        <Spinner
          width="w-full"
          height="aspect-square"
          spinningInnerColor="border-t-transparent"
          borderSize={spinnerBorderSizeClass}
        />
      </div>
    </div>
  );
};
