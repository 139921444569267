import React from 'react';

export const UVIndexIconLabels: React.ComponentType<
  React.SVGProps<SVGSVGElement>
> = ({ id, fill, className }) => {
  return (
    <svg
      className={className}
      width="321"
      height="64"
      viewBox="0 0 321 64"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#clip0_${id})`}>
        <rect
          x="0.5"
          width="320"
          height="40"
          fill={`url(#paint0_linear_${id})`}
        />
        <rect
          width="40"
          height="1"
          transform="matrix(0 -1 1 0 63.7 40)"
          fill="white"
        />
        <rect
          width="40"
          height="1"
          transform="matrix(0 -1 1 0 127.9 40)"
          fill="white"
        />
        <rect
          width="40"
          height="1"
          transform="matrix(0 -1 1 0 192.1 40)"
          fill="white"
        />
        <rect
          width="40"
          height="1"
          transform="matrix(0 -1 1 0 256.3 40)"
          fill="white"
        />
      </g>
      <path
        d="M35.5593 58L34.8873 56.16H30.1193L29.4473 58H26.9512L31.0793 47.328H33.9273L38.0393 58H35.5593ZM32.5033 49.296L30.7273 54.192H34.2633L32.5033 49.296Z"
        fill="#333333"
      />
      <path
        d="M92.2513 58V47.328H97.8673C99.8993 47.328 100.939 48.624 100.939 50.048C100.939 51.392 100.107 52.288 99.0993 52.496C100.235 52.672 101.147 53.776 101.147 55.12C101.147 56.72 100.075 58 98.0433 58H92.2513ZM94.5233 51.616H97.3713C98.1393 51.616 98.6193 51.12 98.6193 50.432C98.6193 49.776 98.1393 49.264 97.3713 49.264H94.5233V51.616ZM94.5233 56.064H97.4513C98.3153 56.064 98.8273 55.568 98.8273 54.8C98.8273 54.128 98.3473 53.552 97.4513 53.552H94.5233V56.064Z"
        fill="#333333"
      />
      <path
        d="M161.152 58.192C158.032 58.192 155.552 55.952 155.552 52.672C155.552 49.392 158.032 47.152 161.152 47.152C163.584 47.152 164.944 48.432 165.696 49.76L163.744 50.72C163.296 49.856 162.336 49.168 161.152 49.168C159.296 49.168 157.888 50.656 157.888 52.672C157.888 54.688 159.296 56.176 161.152 56.176C162.336 56.176 163.296 55.504 163.744 54.624L165.696 55.568C164.928 56.896 163.584 58.192 161.152 58.192Z"
        fill="#333333"
      />
      <path
        d="M219.814 58V47.328H224.022C227.366 47.328 229.686 49.456 229.686 52.672C229.686 55.888 227.366 58 224.022 58H219.814ZM222.086 56H224.022C226.134 56 227.366 54.48 227.366 52.672C227.366 50.784 226.214 49.328 224.022 49.328H222.086V56Z"
        fill="#333333"
      />
      <path
        d="M284.892 58V47.328H292.444V49.28H287.164V51.6H292.332V53.552H287.164V56.032H292.444V58H284.892Z"
        fill="#333333"
      />
      <defs>
        <linearGradient
          id={`paint0_linear_${id}`}
          x1="0.500001"
          y1="20"
          x2="320.5"
          y2="20"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.099" stopColor="#56B31F" />
          <stop offset="0.3" stopColor="#F6A628" />
          <stop offset="0.497" stopColor="#E42D20" />
          <stop offset="0.699" stopColor="#B84687" />
          <stop offset="0.904" stopColor="#70BBFC" />
        </linearGradient>
        <clipPath id={`clip0_${id}`}>
          <rect x="0.5" width="320" height="40" rx="5" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
