import { useGetPage } from 'hooks/useGetPage';
import { genericActionsIds, pageIds } from 'utilities/constants';
import { MultiFactorAuthentication } from './SecurityAndPrivacy/MultiFactorAuthentication';
import { SecurityAndPrivacyProps } from 'app/my-account/interfaces/profile.interfaces';
import { useMemo, useRef } from 'react';
import { useGetCountriesQuery } from 'graphql/generated/hasura';

import { ReactComponent as ChevronDownIcon } from 'assets/icons/chevron-down.svg';
import { ReactComponent as ChevronUpIcon } from 'assets/icons/chevron-up.svg';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import { MY_ACCOUNT_PASSWORD_CHANGE } from 'utilities/routes';
import { useNavigate } from 'react-router-dom';
import ButtonComponent from 'components/button/buttonComponent';
import Alert from 'components/alertComponent';
import InputComponent from 'components/inputComponent';

export type ChangePasswordForm = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};

export const SecurityAndPrivacy: React.FC<SecurityAndPrivacyProps> = ({
  disableButton,
  displayMFAForm,
  displaySecurityAndPrivacy,
  mfaPhone,
  mfaCountry,
  phoneError,
  signInProvider,
  mfaError,
  securityAndPrivacySubmitting,
  onCodeChange,
  onDisplayMFAForm,
  onDisplaySecurityAndPrivacy,
  onResendCode,
  onSavePhoneNumber,
  onVerifyMFACode,
  onCountryChange,
  onPhoneNumberChange,
}) => {
  const navigate = useNavigate();
  const recaptchaContainerWrapper = useRef<HTMLDivElement>(null);

  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [
        genericActionsIds.CANCEL,
        genericActionsIds.CLOSE,
        genericActionsIds.SUBMIT,
        genericActionsIds.VERIFY,
        genericActionsIds.UPDATE,
      ],
    });

  const { data: verificationLocale, loading: verificationLocaleLoading } =
    useGetPage({
      locale: 'en',
      pageId: pageIds.VERIFICATION,
    });

  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.MY_PROFILE,
  });

  const { data } = useGetCountriesQuery({});

  const mappedCountriesValues = useMemo(
    () =>
      data?.countries
        ? [...data.countries]
            .sort((a, b) => {
              const codea = parseInt(a.code);
              const codeb = parseInt(b.code);

              if (codea < codeb) {
                return -1;
              } else if (codea > codeb) {
                return 1;
              } else {
                return 0;
              }
            })
            .map((country) => {
              return { code: country.code, flag: country.flag };
            })
        : [],
    [data?.countries],
  );

  const handleShowForm = () =>
    onDisplaySecurityAndPrivacy(!displaySecurityAndPrivacy);

  if (
    (loading && !locale) ||
    (verificationLocaleLoading && !verificationLocale) ||
    (genericActionLoading && !genericAction)
  )
    return null;

  return (
    <>
      <div className="flex flex-col items-center bg-white w-full p-5 desktop:p-[30px] gap-5 rounded-10">
        <div
          className="flex flex-col items-start w-full gap-2.5 p-0 cursor-pointer desktop:cursor-default"
          onClick={handleShowForm}
        >
          <div className="flex flex-row w-full items-center justify-between">
            <div className="flex flex-row items-center">
              <h3 className="text-h5 desktop:text-h4 text-dark-gray font-semibold desktop:mr-2.5">
                {locale.securityAndPrivacy.title}
              </h3>
            </div>
            {displaySecurityAndPrivacy ? (
              <ChevronUpIcon
                className="desktop:hidden w-[18px] h-2.5 fill-current cursor-pointer"
                onClick={handleShowForm}
              />
            ) : (
              <ChevronDownIcon
                className="desktop:hidden w-[18px] h-2.5 fill-current cursor-pointer"
                onClick={handleShowForm}
              />
            )}
          </div>
          <hr
            className={`desktop:flex flex-row w-full items-center h-px bg-black-blur ${
              displaySecurityAndPrivacy ? 'flex' : 'hidden'
            }`}
          />
        </div>

        <div
          className={`w-full desktop:flex flex-col gap-[15px] ${
            displaySecurityAndPrivacy ? 'flex' : 'hidden'
          }`}
        >
          {signInProvider === 'password' && (
            <div
              className="text-clc-blue hover:underline font-bold uppercase cursor-pointer"
              onClick={() => navigate(MY_ACCOUNT_PASSWORD_CHANGE)}
            >
              {locale.securityAndPrivacy.changePassword}
            </div>
          )}
          <hr className="desktop:flex flex-row w-full items-center h-px bg-black-blur" />

          {displayMFAForm ? (
            <>
              {mfaError && <Alert type="warning" text={mfaError} />}
              <p>{verificationLocale.instructionTextUpdateMFA}</p>
              <label
                className="font-base font-semibold text-dark-gray"
                htmlFor="Verification Code"
              >
                {verificationLocale.verificationCodeLabel}
              </label>

              <InputComponent
                testID="verification-code-input"
                type="text"
                name="Verification Code"
                onChange={(e) => onCodeChange(e.target.value)}
              />

              <ButtonComponent onClick={onResendCode} type="underline">
                {verificationLocale.resendCodeLink}
              </ButtonComponent>
            </>
          ) : (
            <MultiFactorAuthentication
              country={mfaCountry}
              countries={mappedCountriesValues}
              locale={locale}
              phone={mfaPhone}
              onCountryChange={onCountryChange}
              onPhoneNumberChange={onPhoneNumberChange}
              phoneError={phoneError}
            />
          )}

          <hr className="desktop:flex flex-row w-full items-center h-px bg-black-blur" />

          <div ref={recaptchaContainerWrapper}>
            <div id="recaptcha-container" />
          </div>

          <div className="hidden w-full desktop:flex justify-end gap-4">
            {displayMFAForm && (
              <ButtonComponent
                iconPosition="right"
                className="px-10"
                onClick={() => onDisplayMFAForm(false)}
                disabled={securityAndPrivacySubmitting}
              >
                {genericAction?.[genericActionsIds.CANCEL].cancel}
              </ButtonComponent>
            )}

            <ButtonComponent
              iconPosition="right"
              className="px-10"
              onClick={() =>
                displayMFAForm ? onVerifyMFACode() : onSavePhoneNumber()
              }
              disabled={disableButton || securityAndPrivacySubmitting}
            >
              {displayMFAForm
                ? genericAction?.[genericActionsIds.VERIFY].verify
                : genericAction?.[genericActionsIds.UPDATE].update}
            </ButtonComponent>
          </div>
        </div>
      </div>
    </>
  );
};
