import {
  useAcceptLegalDocumentMutation,
  useGetAcceptedLegalDocumentsLazyQuery,
} from 'graphql/generated/hasura';
import { LegalDocument } from 'graphql/generated/strapi';
import { useIsMobile } from 'hooks/useIsMobile';
import { calculateSHA256Hash } from 'utilities/functions';

export const useAcceptLegalDocument = () => {
  const [acceptLegalDocumentMutation, { error: acceptLegalDocumentError }] =
    useAcceptLegalDocumentMutation();
  const [fetchAcceptedLegalDocuments] = useGetAcceptedLegalDocumentsLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const isMobile = useIsMobile();

  const acceptLegalDocument = async (
    document: LegalDocument,
  ): Promise<void> => {
    const hashedContent = calculateSHA256Hash(
      isMobile ? document.contentMobile : document.contentWeb,
    );
    acceptLegalDocumentMutation({
      variables: {
        hash_content: hashedContent,
        legal_document_type: document.legalDocumentType,
      },
    });
  };

  const acceptUnacceptedDocuments = async (
    documents: LegalDocument[],
    userId: string,
  ): Promise<void> => {
    try {
      const acceptedLegalDocuments = await fetchAcceptedLegalDocuments({
        variables: { user_id: userId },
      });
      const acceptedHashes =
        acceptedLegalDocuments.data?.accepted_legal_documents.map(
          (acceptedLegalDocument) =>
            acceptedLegalDocument.accepted_hash_content,
        ) || [];
      const registerPromises = documents.map((document) => {
        const hashedContent = calculateSHA256Hash(
          isMobile ? document.contentMobile : document.contentWeb,
        );
        if (!acceptedHashes.includes(hashedContent)) {
          return acceptLegalDocumentMutation({
            variables: {
              hash_content: hashedContent,
              legal_document_type: document.legalDocumentType,
            },
          });
        }
      });

      if (registerPromises.length) {
        const responses = await Promise.all(registerPromises);
        responses.forEach((response) => {
          if (!response || !response.data) {
            console.error('Failed to accept legal document');
            throw new Error('Failed to accept legal document');
          }
        });
      }
    } catch (error: unknown) {
      console.error('Failed to accept legal document');
      throw new Error('Failed to accept legal document');
    }
  };

  return {
    acceptUnacceptedDocuments,
    acceptLegalDocument,
    acceptLegalDocumentError,
  };
};
