import React from 'react';
import { PatientDetailsProps } from '../interfaces/patientDetails.interface';
import { ReactComponent as Gender } from 'assets/icons/patientIcons/gender.svg';
import { ReactComponent as SelfIdentification } from 'assets/icons/patientIcons/self-identification.svg';
import { ReactComponent as Waist } from 'assets/icons/patientIcons/waist.svg';
import { ReactComponent as Height } from 'assets/icons/patientIcons/height.svg';
import { ReactComponent as Hip } from 'assets/icons/patientIcons/hip.svg';
import { ReactComponent as Age } from 'assets/icons/patientIcons/age.svg';
import { ReactComponent as Weight } from 'assets/icons/patientIcons/weight.svg';
import { ReactComponent as Calendar } from 'assets/icons/patientIcons/calendar.svg';
import PatientPharmacy from './PatientPharmacy';

interface IPatentDetailItem {
  label: string;
  data: string;
  icon: React.ReactNode;
}

const PatentDetailItem: React.FC<IPatentDetailItem> = ({
  label,
  icon,
  data,
}) => {
  return (
    <div className="flex">
      {icon}
      <div className="flex flex-col ml-2">
        <span className="text-base font-semibold text-charcoal-gray">
          {label}
        </span>
        <span className="text-base font-semibold text-med-gray">{data}</span>
      </div>
    </div>
  );
};

export const PatientDetailsDesktop: React.FC<PatientDetailsProps> = ({
  locale,
  patientDetails,
}) => {
  const {
    dateOfBirth,
    age,
    height,
    weight,
    waistSize,
    hipSize,
    birthGender,
    genderSelfIdentification,
  } = patientDetails;
  return (
    <div className="hidden desktop:flex flex-col gap-8">
      <div className="grid grid-cols-3 gap-8">
        <PatentDetailItem
          label={locale.birthGender}
          data={locale[birthGender]}
          icon={<Gender />}
        />
        <PatentDetailItem
          label={locale.dateOfBirth}
          data={dateOfBirth}
          icon={<Calendar />}
        />
        <PatentDetailItem
          label={locale.age}
          data={`${age} ${locale.yearsOld}`}
          icon={<Age />}
        />
        <PatentDetailItem
          label={locale.genderSelfIdentification}
          data={locale[genderSelfIdentification]}
          icon={<SelfIdentification />}
        />
        <PatentDetailItem
          label={locale.height}
          data={height
            .map((measure) => `${measure.value} ${measure.unit} `)
            .join('')}
          icon={<Height />}
        />
        <PatentDetailItem
          label={locale.weight}
          data={weight
            .map((measure) => `${measure.value} ${measure.unit} `)
            .join('')}
          icon={<Weight />}
        />
        <PatentDetailItem
          label={locale.waistSize}
          data={waistSize
            .map((measure) => `${measure.value} ${measure.unit} `)
            .join('')}
          icon={<Waist />}
        />
        <PatentDetailItem
          label={locale.hipSize}
          data={hipSize
            .map((measure) => `${measure.value} ${measure.unit} `)
            .join('')}
          icon={<Hip />}
        />
      </div>
      <PatientPharmacy patientDetails={patientDetails} locale={locale} />
    </div>
  );
};
