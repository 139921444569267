import ButtonComponent from 'components/button/buttonComponent';
import { ReactComponent as ChevronDownIcon } from 'assets/icons/chevron-down.svg';
import { ReactComponent as ChevronUpIcon } from 'assets/icons/chevron-up-fix.svg';
import { useContext, useMemo, useState } from 'react';
import Input from 'components/inputComponent';
import { ReactComponent as MagnifierIcon } from 'assets/icons/magnifier.svg';
import DatePicker from 'components/datePicker/DatePicker';
import { useMyAppointmentsLocale } from 'app/my-appointments/pages/MyAppointments';
import {
  appointmentRenderInfo,
  AppointmentsHistoryFilterMobileProps,
  HistoricAppointmentsSortFilter,
  HandleAppointmentFilterChangeEvent,
} from 'app/my-appointments/interfaces';
import { AuthContext } from 'auth/context/AuthContext';
import { Roles } from '../../../../firebase/interfaces';
import { AppointmentTypeEnum } from 'graphql/generated/hasura';

export const AppointmentsHistoryFilterMobile: React.FC<
  AppointmentsHistoryFilterMobileProps
> = ({
  filter,
  setFilter,
  onFilter,
  patientsList,
  providersList,
  sortColumns,
  locale: localeProp,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const ArrowIcon = isExpanded ? ChevronUpIcon : ChevronDownIcon;
  const handleExpandIconClick = () => {
    setIsExpanded((isExpanded) => !isExpanded);
  };
  const { user } = useContext(AuthContext);
  const { locale: localeHook } = useMyAppointmentsLocale();
  const locale = useMemo(
    () => ({ ...localeProp, ...localeHook }),
    [localeProp, localeHook],
  );

  const [byTypeEntries, localeToValueMapByType] = useMemo(() => {
    const localeToValueMap: Record<string, AppointmentTypeEnum> = {};
    const noDnaVisitAppointmentType = {
      [AppointmentTypeEnum.Chat]: appointmentRenderInfo.CHAT,
      [AppointmentTypeEnum.Video]: appointmentRenderInfo.VIDEO,
      [AppointmentTypeEnum.Email]: appointmentRenderInfo.EMAIL,
    };
    const byTypeEntries = Object.entries(noDnaVisitAppointmentType).map(
      ([key, value]) => {
        const text = locale[value.text];
        localeToValueMap[text] = key as AppointmentTypeEnum;
        return text;
      },
    );
    byTypeEntries.unshift(locale.all);
    return [byTypeEntries, localeToValueMap];
  }, [locale]);

  const [patientsEntries, patientsNameIdMap, patientIdNameMap] = useMemo(() => {
    const patients = patientsList ? patientsList : [];
    const patientsNameIdMap = patients.reduce<Record<string, string>>(
      (acc, patient) => {
        acc[patient.name] = patient.id;
        return acc;
      },
      {},
    );
    const patientIdNameMap = patients.reduce<Record<string, string>>(
      (acc, patient) => {
        acc[patient.id] = patient.name;
        return acc;
      },
      {},
    );
    const patientsEntries = patients.map((patient) => patient.name);
    patientsEntries.unshift(locale.all);
    return [patientsEntries, patientsNameIdMap, patientIdNameMap];
  }, [patientsList, locale.all]);

  const [providersEntries, providersNameIdMap] = useMemo(() => {
    const providers = providersList ? providersList : [];
    const providersNameIdMap = providers.reduce<Record<string, string>>(
      (acc, provider) => {
        acc[provider.name] = provider.id;
        return acc;
      },
      {},
    );
    const patientIdNameMap = providers.reduce<Record<string, string>>(
      (acc, provider) => {
        acc[provider.id] = provider.name;
        return acc;
      },
      {},
    );
    const providersEntries = providers.map((provider) => provider.name);
    providersEntries.unshift(locale.all);
    return [providersEntries, providersNameIdMap, patientIdNameMap];
  }, [providersList, locale.all]);

  const filterTypeText =
    filter !== undefined && filter.type !== undefined
      ? appointmentRenderInfo[filter.type].text
      : undefined;

  const { localizedColumns, sortColumnsMap } = useMemo(() => {
    const sortColumnsMap: Record<string, HistoricAppointmentsSortFilter> = {};
    const localizedColumns = sortColumns.flatMap((column) => {
      const text = locale[column];
      const newest = text + ` - ${locale.newest}`;
      const oldest = text + ` - ${locale.oldest}`;
      sortColumnsMap[newest] = { column, direction: 0 };
      sortColumnsMap[oldest] = { column, direction: 1 };
      return [newest, oldest];
    });
    return {
      localizedColumns: localizedColumns,
      sortColumnsMap,
    };
  }, [locale, sortColumns]);

  let sortColumnText = filter.sort ? locale[filter.sort] : undefined;
  if (sortColumnText) {
    sortColumnText += filter.sortDirection
      ? ` - ${locale.oldest}`
      : ` - ${locale.newest}`;
  }

  const getValueFromLocale = (value: string, name: string): string | number => {
    if (name === 'provider') {
      if (value === locale?.all) return '';
      return value;
    }
    const localeToValueMap: Record<string, Record<string, string | number>> = {
      type: localeToValueMapByType,
      patient: patientsNameIdMap,
      provider: providersNameIdMap,
    };
    return localeToValueMap[name] ? localeToValueMap[name][value] : value;
  };

  const getTypeFilterName = (userRole: string | undefined) => {
    if (userRole) {
      switch (userRole) {
        case Roles.PROVIDER:
          return locale.byType;
        case Roles.PATIENT:
          return locale.type;
        default:
          return locale.type;
      }
    }
  };

  const handleFilterChange = (
    e: HandleAppointmentFilterChangeEvent,
    name?: string,
  ) => {
    let currentName: string = name || '';
    if (!currentName) {
      if (!e.target.name) {
        throw new Error('No name provided for the filter');
      }
      currentName = e.target.name;
    }
    const value = getValueFromLocale(e.target.value, currentName);
    if (currentName === 'from') {
      const newFromDate = new Date(value);
      if (filter.to && newFromDate > new Date(filter.to)) {
        setFilter &&
          setFilter((oldFilter) => ({
            ...oldFilter,
            to: undefined,
            from: value as string,
          }));
      }
    } else if (currentName === 'to') {
      const newToDate = new Date(value);
      if (filter.from && newToDate < new Date(filter.from)) {
        setFilter &&
          setFilter((oldFilter) => ({
            ...oldFilter,
            from: undefined,
            to: value as string,
          }));
      }
    }
    setFilter &&
      setFilter((oldFilter) => {
        return {
          ...oldFilter,
          [currentName]: value,
        };
      });
  };

  const handleSortChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const sortResult = sortColumnsMap[e.target.value];
    if (!sortResult) {
      setFilter((filter) => ({
        ...filter,
        sort: undefined,
        sortDirection: undefined,
      }));
      return;
    }
    const { column, direction } = sortResult;
    setFilter((filter) => ({
      ...filter,
      sort: column,
      sortDirection: direction,
    }));
  };

  return (
    <div className="flex desktop:hidden flex-col items-center px-3 py-2 gap-3 bg-base-content rounded-10 w-full">
      <div className="flex flex-col w-full">
        <div className="flex flex-row w-full items-center justify-between p-0 gap-2.5">
          <div className="flex flex-row items-end gap-[7px]">
            <p className="flex text-base text-dark-gray font-semibold">
              {locale.findAndFilter}
            </p>
          </div>
          <div>
            <ButtonComponent
              type="underline"
              iconPosition="right"
              Icon={ArrowIcon}
              fill="black"
              stroke="black"
              onClick={handleExpandIconClick}
            />
          </div>
        </div>
      </div>
      {isExpanded && (
        <>
          <hr className="flex flex-row w-full items-center h-px bg-black-blur" />
          <div className="flex flex-col w-full gap-1 items-start">
            <p className="text-sm font-medium">{locale.find}</p>
            <div className="w-full">
              <Input
                type="text"
                decorator={<MagnifierIcon />}
                decoratorLeft
                noMarginBottom
                customYPadding="py-1"
                value={filter.find || ''}
                name="find"
                onChange={handleFilterChange}
                placeholder={locale.findPlaceholder}
              />
            </div>
          </div>
          <div className="flex flex-row w-full gap-2 items-center justify-between">
            <p className="text-sm font-medium flex-1">{locale.from}</p>
            <div className="flex-[5]">
              <DatePicker
                fullWidthDesktop
                value={filter.from}
                name="from"
                onChange={handleFilterChange}
              />
            </div>
          </div>
          <div className="flex flex-row w-full gap-2 items-center justify-between">
            <p className="text-sm font-medium flex-1">{locale.to}</p>
            <div className="flex-[5]">
              <DatePicker
                fullWidthDesktop
                value={filter.to}
                name="to"
                onChange={handleFilterChange}
              />
            </div>
          </div>
          <div className="flex flex-col w-full gap-1 items-start">
            <p className="text-sm font-medium">
              {getTypeFilterName(user?.role)}
            </p>
            <div className="w-full">
              <Input
                type="select"
                value={filterTypeText && locale[filterTypeText]}
                name="type"
                selectInputProps={{
                  selectValues: byTypeEntries,
                  fullWidth: true,
                  onSelectChange: handleFilterChange,
                }}
              />
            </div>
          </div>
          {user?.role === Roles.PROVIDER ? (
            <>
              <div className="flex flex-col w-full gap-1 items-start">
                <label className="text-sm font-medium" htmlFor="patient">
                  {locale.byPatient}
                </label>
                <div className="w-full">
                  <Input
                    type="select"
                    value={
                      (filter.patient && patientIdNameMap[filter.patient]) ||
                      locale.all
                    }
                    name="patient"
                    selectInputProps={{
                      selectValues: patientsEntries,
                      fullWidth: true,
                      onSelectChange: handleFilterChange,
                    }}
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="flex flex-col w-full gap-1 items-start">
              <label className="text-sm font-medium" htmlFor="provider">
                {locale.provider}
              </label>
              <div className="w-full">
                <Input
                  type="select"
                  value={filter.provider || locale.all}
                  name="provider"
                  selectInputProps={{
                    selectValues: providersEntries,
                    fullWidth: true,
                    onSelectChange: handleFilterChange,
                  }}
                />
              </div>
            </div>
          )}

          <hr className="flex flex-row w-full items-center h-px bg-black-blur" />
          <div className="flex flex-col w-full gap-1 items-start">
            <p className="text-sm font-medium">{locale.sortBy}</p>
            <div className="w-full">
              <Input
                type="select"
                name="sort"
                value={sortColumnText}
                selectInputProps={{
                  selectValues: localizedColumns,
                  fullWidth: true,
                  onSelectChange: handleSortChange,
                }}
              />
            </div>
          </div>
          <hr className="flex flex-row w-full items-center h-px bg-black-blur" />
          <ButtonComponent
            onClick={() => onFilter && onFilter()}
            className="self-end bg-base-content"
            type="outlined"
          >
            {locale.updateResults}
          </ButtonComponent>
        </>
      )}
    </div>
  );
};
