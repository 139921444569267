import { useState, useRef, useCallback } from 'react';
import * as VideoExpress from '@vonage/video-express';
import { DEVICE_ACCESS_STATUS } from 'utilities/constants';
import { useDevices, DevicesHook } from './useDevices';

interface PreviewPublisherHook extends DevicesHook {
  previewPublisher: VideoExpress.PreviewPublisher | null;
  logLevel: number;
  previewMediaCreated: boolean;
  accessAllowed: string;
  createPreview: (
    targetEl: HTMLElement,
    publisherOptions?: never,
  ) => Promise<void>;
  destroyPreview: () => void;
}

export const usePreviewPublisher = (): PreviewPublisherHook => {
  const previewPublisher = useRef<VideoExpress.PreviewPublisher | null>(null);
  const [logLevel, setLogLevel] = useState<number>(0);
  const [previewMediaCreated, setPreviewMediaCreated] =
    useState<boolean>(false);
  const [accessAllowed, setAccessAllowed] = useState<string>(
    DEVICE_ACCESS_STATUS.PENDING,
  );
  const { deviceInfo, getDevices } = useDevices();

  const calculateAudioLevel = useCallback((audioLevel: number) => {
    let movingAvg = null;
    if (movingAvg === null || movingAvg <= audioLevel) {
      movingAvg = audioLevel;
    } else {
      movingAvg = 0.8 * movingAvg + 0.2 * audioLevel;
    }
    const currentLogLevel = (Math.log(movingAvg) / Math.LN10 / 1.5 + 1) * 100;
    setLogLevel(Math.min(Math.max(currentLogLevel, 0), 100));
  }, []);

  const createPreview = useCallback(
    async (targetEl: HTMLElement, publisherOptions: never | undefined) => {
      try {
        const publisherProperties = Object.assign({}, publisherOptions);
        previewPublisher.current = new VideoExpress.PreviewPublisher(targetEl);
        previewPublisher.current.on('audioLevelUpdated', (audioLevel) => {
          calculateAudioLevel(audioLevel);
        });
        previewPublisher.current.on('accessAllowed', () => {
          setAccessAllowed(DEVICE_ACCESS_STATUS.ACCEPTED);
          getDevices();
        });
        previewPublisher.current.on('accessDenied', () => {
          setAccessAllowed(DEVICE_ACCESS_STATUS.REJECTED);
        });
        previewPublisher.current.on('accessDialogClosed', () => {
          setAccessAllowed(DEVICE_ACCESS_STATUS.REJECTED);
        });
        await previewPublisher.current.previewMedia({
          targetElement: targetEl,
          publisherProperties,
        });

        setPreviewMediaCreated(true);
      } catch (err) {
        console.error('[createPreview]', err);
      }
    },
    [calculateAudioLevel, getDevices],
  );

  const destroyPreview = useCallback(() => {
    if (previewPublisher.current) {
      previewPublisher.current.destroy();
      console.log('[destroyPreview] - ', previewPublisher.current);
    }
  }, []);

  return {
    previewPublisher: previewPublisher.current,
    createPreview,
    destroyPreview,
    logLevel,
    previewMediaCreated,
    accessAllowed,
    deviceInfo,
    getDevices,
  };
};
