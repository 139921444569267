import { useState, FormEvent, ChangeEvent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { usePasswordResetEmail } from '../../../firebase/hooks';
import { ValidationRules } from 'hooks/useFormValidation';
import { pageIds } from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';
import { AUTH_SEND_RESET_PASSWORD_EMAIL } from 'utilities/routes';
import ButtonComponent from 'components/button/buttonComponent';
import InputComponent from 'components/inputComponent';
import useFormValidation from 'hooks/useFormValidation';
import Alert from 'components/alertComponent';

export const ForgotPassword = () => {
  const navigate = useNavigate();
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.FORGOT_PASSWORD,
  });

  const validationRules: ValidationRules = {
    email: [
      {
        validator: (value) => value.length > 0,
        message: locale?.emailRequiredError,
      },
    ],
  };

  const [formData, setFormData] = useState({ email: '' });
  const [errorMsg, handlePasswordResetEmail] = usePasswordResetEmail();
  const { errors, validateField, validateForm } =
    useFormValidation(validationRules);

  const handleOnInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    validateField(name, value);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateForm(formData)) {
      const result = await handlePasswordResetEmail(formData.email);
      if (result) {
        navigate(`${AUTH_SEND_RESET_PASSWORD_EMAIL}?email=${formData.email}`, {
          replace: true,
        });
      }
    }
  };

  if (loading && !locale) return null;

  return (
    <>
      {errorMsg && <Alert type="warning" text={errorMsg} />}
      <div
        className={`flex flex-col items-center desktop:justify-center px-[20px] py-[30px] desktop:px-[60px] desktop:py-[40px] gap-[30px] rounded-10 bg-white ${
          errorMsg ? 'mt-4' : ''
        }`}
      >
        <div className="flex flex-col items-start desktop:items-center p-0 gap-2.5 desktop:gap-[5px]">
          <p className="text-center font-exo text-h2 desktop:text-h1 text-dark-gray font-medium">
            {locale?.forgotPasswordTitle}
          </p>
          <p className="text-base font-medium text-center text-med-gray">
            {locale?.instructionText}
          </p>
        </div>

        <form
          className="flex flex-col w-full gap-[30px]"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col w-full self-center p-0 gap-2.5">
            <label
              className="font-base font-semibold text-charcoal-gray"
              htmlFor="emailAddress"
            >
              {locale?.emailAddressLabel}
            </label>
            <InputComponent
              testID="email-address-input"
              type="text"
              name="email"
              onChange={handleOnInputChange}
              errorMsg={errors.email}
            />
          </div>

          <div className="flex flex-row items-center justify-center">
            <ButtonComponent
              testID="reset-button"
              type="submit"
              paddingX="px-10"
              paddingY="py-[9.5px] desktop:py-[17px]"
              disabled={false}
              className="uppercase font-exo"
            >
              {locale?.resetPasswordButton}
            </ButtonComponent>
          </div>

          <div className="flex flex-row mx-auto" data-testid="link-container">
            <Link
              className="text-clc-blue text-base font-bold underline text-center uppercase"
              to={'/auth/login'}
            >
              {locale?.returnToLoginLink}
            </Link>
          </div>
        </form>
      </div>
    </>
  );
};
