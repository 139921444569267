import React from 'react';

export const ChatOutlineIcon: React.ComponentType<
  React.SVGProps<SVGSVGElement>
> = ({ fill, className }) => {
  return (
    <svg
      className={className}
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.77157 12.9703H16V2.57031H2V13.7419L2.77157 12.9703ZM0 18.5703V2.37031C0 1.37581 0.8055 0.570312 1.8 0.570312H16.2C17.1945 0.570312 18 1.37581 18 2.37031V13.1703C18 14.1648 17.1945 14.9703 16.2 14.9703H3.6L0 18.5703Z"
        fill={fill}
      />
      <path d="M5.78572 7.03906H3.85714V8.96763H5.78572V7.03906Z" fill={fill} />
      <path d="M8.6786 7.03906H6.75003V8.96763H8.6786V7.03906Z" fill={fill} />
      <path d="M11.5715 7.03906H9.64288V8.96763H11.5715V7.03906Z" fill={fill} />
      <path d="M14.4643 7.03906H12.5357V8.96763H14.4643V7.03906Z" fill={fill} />
    </svg>
  );
};
