import { MY_PATIENTS_BIO_INFO_DERMSCORE_HISTORY } from 'utilities/routes';
import { ReactComponent as FileDownloadIcon } from 'assets/icons/file-download.svg';
import { ReactComponent as EyeIcon } from 'assets/icons/pwEyeShow.svg';
import { Card } from '../Card';
import { MyPatientsScanImage } from './MyPatientsScanImage';
import { MyPatientsScanIndicators } from './MyPatientsScanIndicators';
import { MyPatientsDermSCOREScansProps } from 'app/my-patients/interfaces/DermSCOREScans.interface';
import { useNavigate } from 'react-router-dom';
import Placeholder from '../Placeholder';
import {
  GenericActionsMap,
  useGetGenericActions,
} from 'hooks/useGetGenericActions';
import {
  genericActionsIds,
  PARAM_MODALS_IDENTIFIERS,
} from 'utilities/constants';
import { usePdfDownload } from 'hooks/usePdfDownload';
import { useCallback } from 'react';
import ButtonComponent from 'components/button/buttonComponent';
import { SKINTELLIGENT_LIMIT } from '../../pages/PatientInfo';

export const MyPatientsDermSCOREScans: React.FC<
  MyPatientsDermSCOREScansProps
> = ({ scan, locale, count, patientId = null }) => {
  const navigate = useNavigate();
  const { downloadPdf } = usePdfDownload();
  const goToDermScores = () => {
    navigate(`../${MY_PATIENTS_BIO_INFO_DERMSCORE_HISTORY}`, {
      replace: true,
    });
  };

  const { data: genericAction, loading } = useGetGenericActions({
    locale: 'en',
    genericActionId: [genericActionsIds.DOWNLOAD, genericActionsIds.VIEW],
  });

  const handleOnDownload = async () => {
    scan?.reportId && downloadPdf(scan.reportId);
  };
  const openDermSCOREModal = useCallback(() => {
    navigate(
      `.?${PARAM_MODALS_IDENTIFIERS.MY_PATIENTS_DERMSCORE_SCANS_MODAL_ID}=true`,
      {
        state: { onlyView: true, scan },
      },
    );
  }, [scan, navigate]);

  if (!genericAction || loading) {
    return null;
  }

  const {
    [genericActionsIds.DOWNLOAD]: download,
    [genericActionsIds.VIEW]: view,
  } = genericAction as GenericActionsMap;

  return (
    <Card
      title={locale.dermSCOREScans}
      subtitle={String(count ?? locale.dermSCOREScansSubtitle)}
      buttonText={
        count && count > SKINTELLIGENT_LIMIT ? locale.viewAllButton : undefined
      }
      borderSubtitle
      customYPadding="py-0"
      buttonCTA={goToDermScores}
      className="min-h-[416px]"
    >
      {scan ? (
        <div className="flex flex-col gap-2.5">
          <span className="text-charcoal-gray text-sm font-bold uppercase leading-tight">
            {locale.mostRecent}
          </span>
          <div className="flex flex-col items-center gap-2.5 desktop:items-stretch desktop:flex-row desktop:gap-7">
            <MyPatientsScanImage
              scan={scan}
              locale={locale}
              patientId={patientId}
            />
            {scan && <MyPatientsScanIndicators scan={scan} locale={locale} />}
          </div>
          <div className="flex flex-row py-2.5 self-stretch">
            <ButtonComponent
              type="underline"
              className="uppercase font-bold text-sm font-exo"
              onClick={openDermSCOREModal}
              containerClassName="!gap-1.5"
            >
              <EyeIcon className="w-[15px] h-[18px] fill-clc-blue" />
              <span>{view.view}</span>
            </ButtonComponent>

            <ButtonComponent
              type="underline"
              className="uppercase ml-4 font-bold text-sm font-exo"
              onClick={handleOnDownload}
              containerClassName="!gap-1.5"
            >
              <FileDownloadIcon className="w-[15px] h-[18px] fill-clc-blue" />
              <span>{download.download}</span>
            </ButtonComponent>
          </div>
        </div>
      ) : (
        <Placeholder placeholder={locale.placeholder} />
      )}
    </Card>
  );
};
