import { ReactComponent as CodexLogo } from 'assets/icons/logo-codex-no-size.svg';

export interface SizeableLoaderProps {
  sizeClassName?: string;
}

const SizeableLoader: React.FC<SizeableLoaderProps> = ({
  sizeClassName = 'w-[240px] h-[240px]',
}) => {
  return (
    <div className="flex flex-col items-center justify-center">
      <div className={`${sizeClassName} relative`}>
        <CodexLogo />
      </div>
    </div>
  );
};

export default SizeableLoader;
