import React from 'react';
import { Dot, ImageObject } from '../interfaces';
import CheckboxList from './CheckboxList';

interface ICheckboxGroup {
  label: string;
  dots: Dot[];
  medias: Map<string, string | ImageObject>;
  handleBodyParsing: (id: number) => void;
  className?: string;
}

const CheckboxGroup: React.FC<ICheckboxGroup> = ({
  dots,
  handleBodyParsing,
  className,
  medias,
  label,
}) => {
  return (
    <section className={`flex flex-col gap-1.5 ${className}`}>
      <h3 className="uppercase font-bold text-h7">{label}</h3>
      <CheckboxList
        dots={dots}
        medias={medias}
        onCheckboxChange={handleBodyParsing}
      />
    </section>
  );
};

export default CheckboxGroup;
