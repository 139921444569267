import { useLocation, useNavigate } from 'react-router-dom';

import {
  GetLatestQuestionnaireByIdQuery,
  useGetUsersByIdQuery,
} from 'graphql/generated/hasura';
import {
  GOOGLE_EHR_AVAILABLE_SYSTEMS_IDENTIFIERS,
  genericActionsIds,
  pageIds,
  questionnairesIds,
} from 'utilities/constants';
import { ReactComponent as ChevronLeftIcon } from 'assets/icons/chevron-left.svg';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import { useGetPage } from 'hooks/useGetPage';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'auth/context/AuthContext';
import Loader from 'components/loaderComponent';
import DynamicQuestionnaire from 'components/dynamicQuestionnaire/DynamicQuestionnaireComponent';
import ButtonComponent from 'components/button/buttonComponent';
import { useGetFhirQuestionnaireByIdQuery } from 'graphql/generated/remote-schema-hasura';
import {
  FhirQuestionnaireNames,
  QuestionnaireResponseStatus,
  useGetFhirQuestionnaireResponseQuery,
  useGetLatestFhirQuestionnaireByIdQuery,
} from 'graphql/generated/remote-schema-hasura';
import { convertDNAQuestionnaireResponsesFromFhirToHasura } from 'utilities/functions';

export const Questionnaire = () => {
  const [storedQuestionnaireResponse, setStoredQuestionnaireResponse] =
    useState<GetLatestQuestionnaireByIdQuery>();
  const [
    storedDraftQuestionnaireResponse,
    setStoredDraftQuestionnaireResponse,
  ] = useState<GetLatestQuestionnaireByIdQuery>();
  const [
    storedSavedQuestionnaireResponse,
    setStoredSavedQuestionnaireResponse,
  ] = useState<GetLatestQuestionnaireByIdQuery>();
  const navigate = useNavigate();
  const location = useLocation();

  const { user: loggedUser } = useContext(AuthContext);
  const { data: userData } = useGetUsersByIdQuery({
    variables: { id: loggedUser?.uuid },
  });

  const user = userData?.users[0];

  const testKitData = location.state?.testKitData;

  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.BACK],
    });

  const { data: locale, loading: localeLoading } = useGetPage({
    locale: 'en',
    pageId: pageIds.REGISTER_TEST_KIT,
  });

  const { data, loading } = useGetFhirQuestionnaireByIdQuery({
    variables: {
      questionnaire_id: questionnairesIds.REGISTER_MY_DNA_SKIN_TEST_KIT,
    },
  });
  const {
    data: latestIncompleteFHIRQuestionnaire,
    refetch: questionnaireFHIRStoredDataRefetch,
    loading: latestIncompleteFHIRQuestionnaireLoading,
  } = useGetLatestFhirQuestionnaireByIdQuery({
    variables: {
      questionnaire_id: questionnairesIds.REGISTER_MY_DNA_SKIN_TEST_KIT,
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    async function fetchData() {
      if (user && latestIncompleteFHIRQuestionnaire) {
        try {
          const data = await questionnaireFHIRStoredDataRefetch({
            questionnaire_id: questionnairesIds.REGISTER_MY_DNA_SKIN_TEST_KIT,
          });
          const response =
            data?.data?.getLatestFHIRQuestionnaireById
              ?.questionnaire_responses[0];
          if (!response) return;
          const hasuraFormatData =
            convertDNAQuestionnaireResponsesFromFhirToHasura({
              getFHIRQuestionnaireResponse: {
                questionnaire_response: {
                  id: response.id,
                  responses: response.responses,
                  is_complete: QuestionnaireResponseStatus.InProgress,
                },
              },
            });
          setStoredDraftQuestionnaireResponse(
            hasuraFormatData as GetLatestQuestionnaireByIdQuery,
          );
        } catch (err) {
          console.log('Error when trying to refetch stored data:', err);
        }
      }
    }

    fetchData().catch((e) =>
      console.log('Error when trying to fetch stored data:', e),
    );
  }, [
    latestIncompleteFHIRQuestionnaire,
    questionnaireFHIRStoredDataRefetch,
    setStoredDraftQuestionnaireResponse,
    user,
  ]);

  const { loading: isFhirQuestionnaireResponseLoading } =
    useGetFhirQuestionnaireResponseQuery({
      variables: {
        codexPatientId: user?.id,
        identifier: FhirQuestionnaireNames.DnaQuestionnaire,
        system: GOOGLE_EHR_AVAILABLE_SYSTEMS_IDENTIFIERS.codex,
      },
      fetchPolicy: 'no-cache',
      onCompleted(data) {
        if (
          data &&
          data.getFHIRQuestionnaireResponse.questionnaire_response.responses
            .length
        ) {
          setStoredSavedQuestionnaireResponse(
            convertDNAQuestionnaireResponsesFromFhirToHasura(
              data,
            ) as GetLatestQuestionnaireByIdQuery,
          );
        }
      },
      skip: !user,
    });

  useEffect(() => {
    const newData =
      storedDraftQuestionnaireResponse?.questionnaire_responses &&
      storedDraftQuestionnaireResponse?.questionnaire_responses.length
        ? storedDraftQuestionnaireResponse
        : storedSavedQuestionnaireResponse;
    setStoredQuestionnaireResponse(newData);
  }, [storedDraftQuestionnaireResponse, storedSavedQuestionnaireResponse]);

  if (
    loading ||
    genericActionLoading ||
    !genericAction ||
    !locale ||
    localeLoading ||
    isFhirQuestionnaireResponseLoading ||
    latestIncompleteFHIRQuestionnaireLoading
  )
    return <Loader />;

  return (
    <div>
      <div className="flex justify-start w-16">
        <ButtonComponent
          type="underline"
          Icon={ChevronLeftIcon}
          iconPosition="left"
          iconWidth="w-2.5"
          iconHeight="h-[18px]"
          onClick={() => navigate(-1)}
        >
          <p className="uppercase">
            {genericAction?.[genericActionsIds.BACK].back}
          </p>
        </ButtonComponent>
      </div>
      <div className="mt-[30px]">
        {data && (
          <DynamicQuestionnaire
            questionnaireStoredData={storedQuestionnaireResponse}
            enableSubmitCheckbox={{
              enable: false,
            }}
            id={questionnairesIds.REGISTER_MY_DNA_SKIN_TEST_KIT}
            response={{
              ...data?.getFHIRQuestionnaireByCodexID,
              title: `${locale?.questionnaireTitle} ${testKitData?.testName}`,
            }}
            testKitData={testKitData}
          />
        )}
      </div>
    </div>
  );
};
