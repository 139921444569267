import React, { useEffect, useState } from 'react';
import {
  Dnavisit_Test_Statuses_Enum,
  Test_Types_Enum,
} from 'graphql/generated/hasura';
import {
  FEDEX_TRACKING_NO_TRACKING_NUMBER,
  FEDEX_TRACKING_URL,
  USPS_TRACKING_URL,
  pageIds,
} from 'utilities/constants';
import { TestKitsTableData } from '../interfaces';
import {
  EDIT_APPOINTMENT,
  MY_APPOINTMENTS_SCHEDULE_AN_APPOINTMENT,
  SCHEDULE_DNA_VISIT,
  VISIT_SUMMARY,
} from 'utilities/routes';
import { useGetPage } from 'hooks/useGetPage';
import { useNavigate } from 'react-router-dom';
import ButtonComponent from 'components/button/buttonComponent';
import DNATestKitActions from '../../DNATestKitActions';
import { extractUUIDFromResURL } from 'utilities/functions';

interface Props {
  data: TestKitsTableData[];
  onLoadComplete: () => void;
}

const TableBody: React.FC<Props> = ({ data = [], onLoadComplete }) => {
  const navigate = useNavigate();
  const [rowsCompleted, setRowsCompleted] = useState<number>(0);

  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.TEST_KITS_AND_RESULTS,
  });

  useEffect(() => {
    if (data.length) {
      const completedTests = data.filter(
        (test) => test.status === Dnavisit_Test_Statuses_Enum.Complete,
      );
      if (!completedTests.length) {
        onLoadComplete();
      }
      if (completedTests.length === rowsCompleted) {
        onLoadComplete();
      }
    }
  }, [data, rowsCompleted, onLoadComplete]);

  if (loading && !locale) return null;
  const handleScheduleAppointmentClick = () => {
    navigate(SCHEDULE_DNA_VISIT);
  };
  const handleScheduleZrtAppointmentClick = () => {
    navigate(EDIT_APPOINTMENT);
  };

  const handleSchedulZrtAppointmentClick = (
    providerAcuityCalendar: string,
    providerAcuityOwnderId: string,
    providerCodexId: string,
    diagnosticReportId: string,
  ) => {
    navigate(MY_APPOINTMENTS_SCHEDULE_AN_APPOINTMENT, {
      state: {
        codexProviderId: providerCodexId,
        calendarId: providerAcuityCalendar,
        ownerId: providerAcuityOwnderId,
        diagnosticReportId,
      },
    });
  };

  const handleTrackPackageClick = (trackingNumber?: string) => {
    window.open(
      `${USPS_TRACKING_URL}${trackingNumber}`,
      '_blank',
      'noreferrer',
    );
  };
  const handleZrtTrackPackageClick = (trackingNumber?: string) => {
    window.open(
      `${
        trackingNumber ? FEDEX_TRACKING_URL : FEDEX_TRACKING_NO_TRACKING_NUMBER
      }${trackingNumber}`,
      '_blank',
      'noreferrer',
    );
  };

  const mapTestType = (testType: Test_Types_Enum) => {
    switch (testType) {
      case Test_Types_Enum.DnaSkin:
        return locale.dnaTestTestType;

      case Test_Types_Enum.DnaVitamin:
        return locale.dnaVitaminTestType;

      case Test_Types_Enum.Skintelligent:
        return locale.skintelligentTestType;

      case Test_Types_Enum.ZrtHeavyMetals:
        return locale.zrtHeavyMetalsTestType;

      case Test_Types_Enum.ZrtHormone:
        return locale.zrtHormoneTestType;

      case Test_Types_Enum.ZrtInflammatory:
        return locale.zrtInflammatoryTestType;

      case Test_Types_Enum.ZrtNeurotransmitters:
        return locale.zrtNeurotransmittersTestType;

      default:
        return locale.dnaTestTestType;
    }
  };

  const renderActionsByTestKitStatus = (
    status: Dnavisit_Test_Statuses_Enum,
    trackingNumber: string | undefined,
    patientPdfId: string | null | undefined,
    testType: Test_Types_Enum,
    providerAcuitycalendarId: string,
    providerAcuityOwnerId: string,
    providerCodexId: string,
    diagnosticReportId: string,
  ) => {
    switch (status) {
      case Dnavisit_Test_Statuses_Enum.KitNotReceived:
        switch (testType) {
          case Test_Types_Enum.DnaSkin:
          case Test_Types_Enum.DnaVitamin:
            return (
              <ButtonComponent
                className="bg-transparent text-clc-blue desktop:w-full overflow-ellipsis overflow-hidden"
                onClick={() => handleTrackPackageClick(trackingNumber)}
              >
                {locale.trackPackage}
              </ButtonComponent>
            );

          case Test_Types_Enum.ZrtHeavyMetals:
          case Test_Types_Enum.ZrtHormone:
          case Test_Types_Enum.ZrtInflammatory:
          case Test_Types_Enum.ZrtNeurotransmitters:
            return (
              <ButtonComponent
                className="bg-transparent text-clc-blue desktop:w-full overflow-ellipsis overflow-hidden"
                onClick={() => handleZrtTrackPackageClick(trackingNumber)}
              >
                {locale.trackPackage}
              </ButtonComponent>
            );

          default:
            return '';
        }

      case Dnavisit_Test_Statuses_Enum.ResultsWithProvider:
        switch (testType) {
          case Test_Types_Enum.DnaSkin:
          case Test_Types_Enum.DnaVitamin:
            return (
              <ButtonComponent
                className="bg-transparent text-clc-blue desktop:w-full overflow-ellipsis overflow-hidden"
                onClick={handleScheduleAppointmentClick}
              >
                {locale.scheduleAppointment}
              </ButtonComponent>
            );

          case Test_Types_Enum.ZrtHeavyMetals:
          case Test_Types_Enum.ZrtHormone:
          case Test_Types_Enum.ZrtInflammatory:
          case Test_Types_Enum.ZrtNeurotransmitters:
            return (
              <ButtonComponent
                className="bg-transparent text-clc-blue desktop:w-full overflow-ellipsis overflow-hidden"
                onClick={() =>
                  handleSchedulZrtAppointmentClick(
                    providerAcuitycalendarId,
                    providerAcuityOwnerId,
                    providerCodexId,
                    diagnosticReportId,
                  )
                }
              >
                {locale.scheduleAppointment}
              </ButtonComponent>
            );

          default:
            return '';
        }

      case Dnavisit_Test_Statuses_Enum.AppointmentScheduled:
        switch (testType) {
          case Test_Types_Enum.DnaSkin:
          case Test_Types_Enum.DnaVitamin:
            return (
              <ButtonComponent
                className="bg-transparent text-clc-blue desktop:w-full overflow-ellipsis overflow-hidden"
                onClick={handleScheduleAppointmentClick}
              >
                {locale.reScheduleAppointment}
              </ButtonComponent>
            );

          case Test_Types_Enum.ZrtHeavyMetals:
          case Test_Types_Enum.ZrtHormone:
          case Test_Types_Enum.ZrtInflammatory:
          case Test_Types_Enum.ZrtNeurotransmitters:
            return (
              <ButtonComponent
                className="bg-transparent text-clc-blue desktop:w-full overflow-ellipsis overflow-hidden"
                onClick={handleScheduleZrtAppointmentClick}
              >
                {locale.reScheduleAppointment}
              </ButtonComponent>
            );

          default:
            return '';
        }

      case Dnavisit_Test_Statuses_Enum.Complete:
        if (patientPdfId)
          return (
            <DNATestKitActions
              patientPdfId={patientPdfId}
              onLoadComplete={() => setRowsCompleted((c) => c + 1)}
            />
          );
        return null;

      default:
        return null;
    }
  };

  const renderValue = (value: Dnavisit_Test_Statuses_Enum) => {
    switch (value) {
      case Dnavisit_Test_Statuses_Enum.KitNotReceived:
      case Dnavisit_Test_Statuses_Enum.ProviderPending:
        return (
          <span className="text-clc-red border rounded-[25px] border-clc-red px-2.5 font-bold text-h7">
            {value === Dnavisit_Test_Statuses_Enum.KitNotReceived
              ? locale.kitNotReceived
              : locale.kitProviderPending}
          </span>
        );

      case Dnavisit_Test_Statuses_Enum.ReceivedByLab:
      case Dnavisit_Test_Statuses_Enum.ResultsWithProvider:
        return (
          <span className="bg-yellow border rounded-[25px] px-2.5 font-bold text-h7">
            {value === Dnavisit_Test_Statuses_Enum.ReceivedByLab
              ? locale.kitReceivedByLab
              : locale.kitResultsWithProvider}
          </span>
        );

      case Dnavisit_Test_Statuses_Enum.AppointmentScheduled:
        return (
          <span className="bg-yellow border rounded-[25px] px-2.5 font-bold text-h7">
            {locale.kitAppointmentScheduled}
          </span>
        );

      case Dnavisit_Test_Statuses_Enum.Complete:
        return (
          <span className="text-alert-positive border rounded-[25px] border-alert-positive px-2.5 font-bold text-h7">
            {locale.kitComplete}
          </span>
        );

      default:
        return <span className="text-med-gray">{value}</span>;
    }
  };

  const handleViewMyVisitSummaryClicked = (fileId: string) => {
    navigate(VISIT_SUMMARY, {
      state: {
        fileId: extractUUIDFromResURL(fileId),
      },
    });
  };

  if (loading || !locale) return null;

  return (
    <>
      {data.map((testKit) => (
        <div
          className="auto-rows-auto grid grid-cols-[minmax(130px,_1fr)_minmax(150px,_1fr)_minmax(140px,_1fr)_minmax(100px,_1fr)_minmax(185px,_1fr)_minmax(126px,_1fr)_minmax(82px,_1fr)]  border-b-[1px] border-gray-opacity-15 text-med-gray"
          key={testKit.testID}
        >
          <div className="pt-2 pb-2 flex flex-col items-start justify-center pr-[30px]">
            {testKit.date}
          </div>
          <div className="pt-2 pb-2 flex items-center">
            {mapTestType(testKit['test type'])}
          </div>
          <div className="pt-2 pb-2 flex items-center">{testKit.swabCode}</div>
          <div className="pt-2 pb-2 flex items-center">{testKit.provider}</div>
          <div className="pt-2 pb-2 flex items-center">
            {renderValue(testKit.status)}
          </div>

          <div className="pt-2 pb-2 flex items-center w-full text-clc-blue font-bold">
            {testKit.patientVisitSummaryPdfId && (
              <span
                className="hover:cursor-pointer"
                onClick={() =>
                  handleViewMyVisitSummaryClicked(
                    testKit.patientVisitSummaryPdfId || '',
                  )
                }
              >
                {locale.myVisitSummaryTableBody}
              </span>
            )}
          </div>

          <div className="pt-2 pb-2 flex items-center w-full max-w-[15.625rem]">
            {renderActionsByTestKitStatus(
              testKit.status,
              testKit.trackingNumber,
              testKit.patientPdfId,
              testKit['test type'],
              testKit.providerAcuityCalendarId || '',
              testKit.providerAcuityOwnerId || '',
              testKit.providerCodexId || '',
              testKit.fhirId,
            )}
          </div>
        </div>
      ))}
    </>
  );
};

export default TableBody;
