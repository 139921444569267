import { createContext } from 'react';

export interface UserSettings {
  userName: string;
  videoEffects: {
    backgroundBlur: boolean;
    virtualBackground: boolean;
    videoSourceId: string | undefined;
  };
  defaultSettings: {
    publishAudio: boolean;
    publishVideo: boolean;
    audioSource?: string | undefined;
    videoSource?: string | undefined;
    audioOutput?: string | undefined;
  };
}

export interface VonageContextType {
  user: UserSettings | null;
  setUser: (user: UserSettings | null) => void;
}

export const VonageContext = createContext<VonageContextType>({
  user: null,
  setUser: () => true,
});
