import {
  AppointmentTypeEnum,
  useGetFhirMedicationRequestListLazyQuery,
  useGetFhirProviderByCodexIdLazyQuery,
  useGetFulfilledAppointmentsByPatientQuery,
} from 'graphql/generated/remote-schema-hasura';

import {
  ConsultationType,
  MyVisitSummaryPatientProps,
  ResourceLink,
} from 'app/appointments/components/interfaces';
import { useEffect, useState } from 'react';
import {
  formatDateToCustomFormat,
  fromFhirAppointmentTypeToLocal,
} from 'utilities/functions';
import { SharedMediaModalLocationState } from 'app/my-patients/interfaces/interfaces';
import { PARAM_MODALS_IDENTIFIERS, pageIds } from 'utilities/constants';
import { useNavigate } from 'react-router-dom';
import { useGetSignedUrlFromStorageFunction } from 'hooks/useGetSignedUrlFromStorage';
import { useGetPage } from 'hooks/useGetPage';
import { MY_APPOINTMENTS } from 'utilities/routes';
const defaultProfilePicture = 'https://placehold.co/30x30';

export const useGetMyVisitSummary = (
  appointmentId?: string,
): [MyVisitSummaryPatientProps | null | undefined, boolean, Error | null] => {
  const [myVisitSummary, setMyVisitSummary] =
    useState<MyVisitSummaryPatientProps | null>();
  const [error, setError] = useState<Error | null>(null);
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [getFhirProviderByCodexId] = useGetFhirProviderByCodexIdLazyQuery({
    fetchPolicy: 'network-only',
  });
  const { data: appointmentLocale } = useGetPage({
    locale: 'en',
    pageId: pageIds.MY_APPOINTMENTS,
  });
  const [getFHIRMedicationsByPatientCodexId] =
    useGetFhirMedicationRequestListLazyQuery({
      fetchPolicy: 'network-only',
    });
  const navigate = useNavigate();
  const getMedia = useGetSignedUrlFromStorageFunction();
  const { loading: lastVisitSummaryLoading } =
    useGetFulfilledAppointmentsByPatientQuery({
      fetchPolicy: 'network-only',
      variables: {
        type: Object.values(AppointmentTypeEnum).join(','), // All appointment types except DNA
        limit: 1,
        sort: 'summaryDate',
        sortDirection: 1,
        appointmentCodexId: appointmentId || undefined,
      },
      onCompleted: async (data) => {
        try {
          const lastSummary =
            data.getFHIRAppointmentByRequestPatientCodexId.appointment[0];
          if (!lastSummary) {
            setMyVisitSummary(null);
          }
          if (!lastSummary.codexProviderId) {
            const errorMessage = new Error(
              'Fulfilled appointment does not have a provider id',
            );
            setError(errorMessage);
            return;
          }
          const provider = await getFhirProviderByCodexId({
            variables: { codexProviderId: lastSummary.codexProviderId },
          });
          if (provider.error) {
            const errorMessage = new Error('Error fetching provider data');
            setError(errorMessage);
            return;
          }
          if (!provider.data) {
            const errorMessage = new Error(
              'No data returned from provider query',
            );
            setError(errorMessage);
            return;
          }

          const patientMedications = await getFHIRMedicationsByPatientCodexId({
            variables: {
              patientCodexId: lastSummary.codexPatientId,
              encounterId: lastSummary.encounterId,
            },
          });
          if (patientMedications.error) {
            const errorMessage = new Error(
              'Error fetching patient medications data',
            );
            setError(errorMessage);
            return;
          }
          if (!patientMedications.data) {
            const errorMessage = new Error(
              'No data returned from patient medications query',
            );
            setError(errorMessage);
            return;
          }
          let consultationType: ConsultationType;
          switch (lastSummary.appointmentType) {
            case AppointmentTypeEnum.Chat:
              consultationType = ConsultationType.CHAT;
              break;
            case AppointmentTypeEnum.Video:
              consultationType = ConsultationType.VIDEO;
              break;
            case AppointmentTypeEnum.Email:
              consultationType = ConsultationType.MESSAGING;
              break;
            default:
              setError(new Error('Invalid consultation type for last summary'));
              return;
          }
          const providerName = `${provider.data.getFHIRProviderbyCodexIdArgument.providers[0].providers.SENSITIVE_firstname} ${provider.data.getFHIRProviderbyCodexIdArgument.providers[0].providers.SENSITIVE_lastname}`;
          const pictureParts =
            provider.data.getFHIRProviderbyCodexIdArgument.providers[0].providers.SENSITIVE_profile_picture_id?.split(
              '/',
            ) || [];
          const providerPicture = pictureParts[pictureParts.length - 1];
          const startDate = new Date(lastSummary.start || Date.now());
          const dateString = formatDateToCustomFormat(startDate.toISOString());
          const mediaList =
            lastSummary.media?.map<Partial<ResourceLink>>((media) => ({
              id: media.fileId || undefined,
              text: media.bodySite || '',
              onClick: async () => {
                if (!media.fileId) return;
                setLoadingState(true);
                const mediaUrl = await getMedia(media.fileId);
                setLoadingState(false);
                const modalState: SharedMediaModalLocationState = {
                  mediaDate: new Date(lastSummary.start || 0),
                  mediaSrc: mediaUrl as string,
                  providerName: `${lastSummary.patientFirstName} ${lastSummary.patientLastName}`,
                  appointmentType: fromFhirAppointmentTypeToLocal(
                    lastSummary.appointmentType || undefined,
                  ),
                  description: media.description || undefined,
                  bodySite: media.bodySite || '',
                };
                navigate(
                  `.?${PARAM_MODALS_IDENTIFIERS.SHARED_MEDIA_MODAL_ID}=true`,
                  {
                    state: modalState,
                  },
                );
              },
            })) || [];
          setMyVisitSummary({
            filesAndMedia: mediaList,
            medications:
              patientMedications.data.getFHIRMedicationRequestByPatientCodexId.medication_requests.map(
                (medication) => ({
                  brand: medication.brandName || '',
                  delivery: medication.delivery ?? '',
                  dosage: medication.dosage ?? '',
                  frecuency: medication.daysSupply?.toString() ?? '',
                  type: medication.type ?? '',
                  additionalNotes: medication.note || '',
                }),
              ),
            related: [],
            id: lastSummary.appointmentCodexId,
            fromProviderData: {
              consultationType,
              providerDate: dateString,
              providerName,
              providerPicture: providerPicture || defaultProfilePicture,
              providerId:
                provider.data.getFHIRProviderbyCodexIdArgument.providers[0]
                  .providers.id,
            },
            subject: lastSummary.subjectOfAppointment || '',
            recommendations:
              lastSummary.encounterNotes?.followupRecommendations || '',
            summary: lastSummary.encounterNotes?.providerSummary || '',
            summaryDate: dateString,
          });
        } catch (error) {
          console.error('Error fetching last summary:', error);
          setMyVisitSummary(null);
        }
      },
    });
  useEffect(() => {
    if (myVisitSummary && appointmentLocale?.appointmentOverview) {
      const isAlreadyRelated = myVisitSummary?.related?.some(
        (r) => r.id === appointmentLocale.appointmentOverview,
      );
      if (!isAlreadyRelated) {
        const newRelated = (myVisitSummary.related ?? []).filter(
          (r) => r.id !== appointmentLocale.appointmentOverview,
        );
        newRelated.unshift({
          id: appointmentLocale.appointmentOverview,
          text: appointmentLocale.appointmentOverview,
          onClick: () =>
            navigate(
              `${MY_APPOINTMENTS}?${PARAM_MODALS_IDENTIFIERS.VIEW_APPOINTMENT_DETAILS_MODAL_PATIENT}=true`,
              {
                state: { appointmentId: myVisitSummary.id },
              },
            ),
        });
        setMyVisitSummary({
          ...myVisitSummary,
          related: newRelated,
        });
      }
    }
  }, [appointmentLocale?.appointmentOverview, myVisitSummary, navigate]);

  const loading = lastVisitSummaryLoading || loadingState;
  return [myVisitSummary, loading, error];
};
