import { useEffect } from 'react';
import { useRef } from 'react';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import {
  useFirebaseSignOut,
  useVerifyActionCode,
} from '../../../firebase/hooks';
import {
  AUTH_LOGIN,
  AUTH_MFA_SETUP,
  AUTH_RESET_PASSWORD,
  AUTH_SEND_VERIFICATION_EMAIL,
} from 'utilities/routes';

type ModeActionType = {
  oobCode: string;
  navigate: NavigateFunction;
  handleVerifyActionCode: (oobCode: string) => Promise<boolean>;
  callback: () => Promise<unknown>;
};

enum MODE {
  RESET_PASSWORD = 'resetPassword',
  VERIFY_EMAIL = 'verifyEmail',
  REVERT_SECONDARY_FACTORY = 'revertSecondFactorAddition',
}

const modeActions = {
  [MODE.RESET_PASSWORD]: async ({ oobCode, navigate }: ModeActionType) =>
    navigate(`${AUTH_RESET_PASSWORD}?oobCode=${oobCode}`, {
      replace: true,
    }),
  [MODE.VERIFY_EMAIL]: async ({
    oobCode,
    navigate,
    handleVerifyActionCode,
  }: ModeActionType) => {
    const result = await handleVerifyActionCode(oobCode);
    return navigate(
      result
        ? `${AUTH_MFA_SETUP}`
        : `${AUTH_SEND_VERIFICATION_EMAIL}?linkExpired=true`,
      { replace: true },
    );
  },
  [MODE.REVERT_SECONDARY_FACTORY]: async ({
    oobCode,
    navigate,
    handleVerifyActionCode,
    callback,
  }: ModeActionType) => {
    const result = await handleVerifyActionCode(oobCode);
    await callback();
    navigate(
      result
        ? `${AUTH_LOGIN}?mfaRemoved=true`
        : `${AUTH_LOGIN}?mfaLinkExpired=true`,
      { replace: true },
    );
  },
};

const getAction = (mode: MODE | null | undefined) => {
  if (!mode) return null;
  return modeActions[mode];
};

export const Action = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const mode = queryParams.get('mode') as MODE;
  const oobCode = queryParams.get('oobCode') || '';
  const actionChecked = useRef<boolean>(false);
  const [, handleVerifyActionCode] = useVerifyActionCode();
  const signOutUser = useFirebaseSignOut();

  useEffect(() => {
    const checkActions = getAction(mode);

    if (!actionChecked.current && !!checkActions) {
      checkActions({
        oobCode,
        navigate,
        handleVerifyActionCode,
        callback: signOutUser,
      });
      actionChecked.current = true;
    }
  }, [mode, oobCode, navigate, handleVerifyActionCode, signOutUser]);

  return null;
};
