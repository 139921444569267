import DynamicQuestionnaire from 'components/dynamicQuestionnaire/DynamicQuestionnaireComponent';
import { Tab } from 'components/tabs/tabs.interface';
import TabsComponent from 'components/tabs/tabsComponent';
import { GetQuestionnaireResponsesByUserAndQuestionnaireIdQuery } from 'graphql/generated/hasura';
import { useGetPage } from 'hooks/useGetPage';
import {
  GOOGLE_EHR_AVAILABLE_SYSTEMS_IDENTIFIERS,
  pageIds,
  questionnairesIds,
} from 'utilities/constants';
import {
  MY_ACCOUNT_HEALTH_QUESTIONNAIRE,
  MY_ACCOUNT_MY_MEDIA_LIBRARY,
  MY_ACCOUNT_PROFILE,
} from 'utilities/routes';
import { ReactComponent as CloseIcon } from 'assets/icons/iconClose.svg';

import { AuthContext } from 'auth/context/AuthContext';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AlertComponent from 'components/alertComponent';
import Loader from 'components/loaderComponent';
import { useGetFhirQuestionnaireByIdQuery } from 'graphql/generated/remote-schema-hasura';
import {
  FhirQuestionnaireNames,
  useGetFhirQuestionnaireResponseQuery,
} from 'graphql/generated/remote-schema-hasura';
import { convertHealthQuestionnaireResponsesFromFhirToHasura } from 'utilities/functions';

export const HealthQuestionnaire: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [
    showContactInfoCompletedQuestionnaireMissing,
    setShowContactInfoCompletedQuestionnaireMissing,
  ] = useState(false);

  const { user: loggedUser } = useContext(AuthContext);

  const [fhirQuestionnaireStoredData, setFhirQuestionnaireStoredData] =
    useState<GetQuestionnaireResponsesByUserAndQuestionnaireIdQuery>({
      questionnaire_responses: [],
    });

  const {
    loading: isfhirQuestionnaireDataLoading,
    refetch: refetchFhirQuestionnaireData,
  } = useGetFhirQuestionnaireResponseQuery({
    variables: {
      codexPatientId: loggedUser?.uuid || '',
      identifier: FhirQuestionnaireNames.HealthQuestionnaire,
      system: GOOGLE_EHR_AVAILABLE_SYSTEMS_IDENTIFIERS.codex,
    },
    onCompleted(data) {
      if (data) {
        setFhirQuestionnaireStoredData(
          convertHealthQuestionnaireResponsesFromFhirToHasura(data),
        );
      }
    },
  });

  const handleRefetch =
    async (): Promise<GetQuestionnaireResponsesByUserAndQuestionnaireIdQuery> => {
      const { data } = await refetchFhirQuestionnaireData({
        codexPatientId: loggedUser?.uuid || '',
        identifier: FhirQuestionnaireNames.HealthQuestionnaire,
        system: GOOGLE_EHR_AVAILABLE_SYSTEMS_IDENTIFIERS.codex,
      });

      const parsedData =
        convertHealthQuestionnaireResponsesFromFhirToHasura(data);

      return parsedData;
    };

  const { data: locale, loading: localeLoading } = useGetPage({
    locale: 'en',
    pageId: pageIds.MY_PROFILE,
  });

  const { data, loading } = useGetFhirQuestionnaireByIdQuery({
    variables: {
      questionnaire_id: questionnairesIds.HEALTH_QUESTIONNAIRE,
    },
  });

  const tabs: Tab[] = [
    {
      name: locale?.title,
      url: MY_ACCOUNT_PROFILE,
    },
    {
      name: locale?.healthQuestionnaire,
      url: MY_ACCOUNT_HEALTH_QUESTIONNAIRE,
    },
    {
      name: locale?.myMediaLibrary,
      url: MY_ACCOUNT_MY_MEDIA_LIBRARY,
    },
  ];

  useEffect(() => {
    const showAlert = location.state?.isDnaTestKitsFlow || false;
    const referringPage = location.state?.referringPage || null;
    if (!referringPage) {
      setShowContactInfoCompletedQuestionnaireMissing(showAlert);
    }
  }, [location]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      navigate(location.pathname, {
        replace: true,
        state: { ...location.state, isDnaTestKitsFlow: false },
      });
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [location, navigate]);

  if (loading || localeLoading || !locale || isfhirQuestionnaireDataLoading) {
    return <Loader />;
  }

  return (
    <div>
      <TabsComponent tabs={tabs} />
      {showContactInfoCompletedQuestionnaireMissing && (
        <div className="flex flex-row mb-[30px]">
          <AlertComponent
            type="positive"
            customContent={
              <div className="flex flex-col w-full desktop:flex-row justify-between items-center desktop:relative">
                <div className="flex flex-col desktop:flex-row w-full gap-[20px] desktop:gap-[30px]">
                  <p className="text-base font-semibold text-alert-positive text-center desktop:mr-[35px]">
                    {locale?.myProfileUpdate}
                  </p>
                </div>

                <CloseIcon
                  className="absolute top-5 right-5 desktop:top-0 desktop:right-0 w-6 h-6 stroke-med-gray cursor-pointer"
                  onClick={() =>
                    setShowContactInfoCompletedQuestionnaireMissing(false)
                  }
                />
              </div>
            }
          />
        </div>
      )}
      {data && (
        <DynamicQuestionnaire
          questionnaireStoredData={fhirQuestionnaireStoredData}
          id={questionnairesIds.HEALTH_QUESTIONNAIRE}
          response={data?.getFHIRQuestionnaireByCodexID}
          enableSubmitCheckbox={{ enable: false }}
          refetchData={handleRefetch}
        />
      )}
    </div>
  );
};
