import Loader from 'components/loaderComponent';
import {
  AppointmentTypeEnum,
  useGetAppointmentsQuery,
} from 'graphql/generated/hasura';
import { Fragment, useMemo, useState } from 'react';
import { DateWithTimeTooltip } from 'app/superadmin/components/DateWithTimeTooltip';
import { makeTableComponent } from 'app/superadmin/components/DataTable';
import { Expandable, nameRenderer } from './Tests';
import { useParams } from 'react-router-dom';
import { AppointmentStatus } from 'app/my-appointments/interfaces';
import Input from 'components/inputComponent';

type AppointmentsDataType = {
  __typename?: 'SAAppointment';
  identifiers: Array<Array<string>>;
  type?: AppointmentTypeEnum | null;
  dates: Array<Array<string>>;
  status: string;
  logs: Array<Array<string>>;
  patient?: {
    __typename?: 'SAPerson';
    identifiers: Array<Array<string>>;
    first: string;
    last: string;
  } | null;
  provider?: {
    __typename?: 'SAPerson';
    identifiers: Array<Array<string>>;
    first: string;
    last: string;
  } | null;
};

const availableStatuses = [
  AppointmentStatus.PENDING,
  AppointmentStatus.PROPOSED,
  AppointmentStatus.BOOKED,
  AppointmentStatus.CANCELLED,
  AppointmentStatus.NOSHOW,
  AppointmentStatus.FULFILLED,
];

export const Appointments = () => {
  const { statuses: statusesParam, ...params } = useParams();
  const [statuses, setStatuses] = useState(
    statusesParam?.split(',') ?? availableStatuses,
  );
  const { loading, data } = useGetAppointmentsQuery({
    variables: { ...params, statuses },
  });

  const toggle = (value: string) => {
    const currently = statuses.includes(value);
    if (currently) {
      setStatuses(statuses.filter((s) => s !== value));
    } else {
      setStatuses([...statuses, value]);
    }
  };

  const Table = useMemo(() => makeTableComponent<AppointmentsDataType>(), []);

  if (loading) return <Loader />;

  return (
    <div>
      Appointments
      <br />
      <Expandable minText={statuses.join(',')}>
        {availableStatuses.map((s) => (
          <Input
            key={s}
            type="checkbox"
            value={s}
            name={s}
            checkboxProps={{
              checked: statuses.includes(s),
              checkboxLabel: s,
              onCheckboxChange: () => toggle(s),
            }}
          />
        ))}
      </Expandable>
      <hr />
      {data?.getAppointments.appointments.length && (
        <Table
          data={data?.getAppointments.appointments}
          getKey={(item) =>
            item.identifiers.find((id) => id[0] === 'fhir')?.[1] ?? 'never'
          }
        >
          <Table.KeyColumn dataKey="identifiers" />
          <Table.KeyColumn dataKey="type" />
          <Table.DynamicColumn
            title="created"
            renderer={(item) => (
              <DateWithTimeTooltip
                isoDate={item.dates.find((d) => d[0] === 'created')?.[1]}
              />
            )}
          />
          <Table.DynamicColumn
            title="updated"
            renderer={(item) => (
              <DateWithTimeTooltip
                isoDate={item.dates.find((d) => d[0] === 'lastUpdated')?.[1]}
              />
            )}
          />
          <Table.KeyColumn
            dataKey="logs"
            renderer={(items: [string, string, string][]) => (
              <Expandable minText={`view logs (${items.length})`}>
                <>
                  {items.map(([event, source, datetime]) => (
                    <Fragment key={`${event}-${source}-${datetime}`}>
                      {`${event} (${source}) @ ${new Date(
                        datetime,
                      ).toTimeString()}`}
                      <br />
                    </Fragment>
                  ))}
                </>
              </Expandable>
            )}
          />
          <Table.KeyColumn
            dataKey="provider"
            renderer={nameRenderer}
            width="150px"
          />
          <Table.KeyColumn
            dataKey="patient"
            renderer={nameRenderer}
            width="150px"
          />
          <Table.KeyColumn dataKey="status" />
        </Table>
      )}
      {!data?.getAppointments.appointments.length && <>No Records Found</>}
    </div>
  );
};
