interface InfoSectionProps {
  borderTop?: boolean;
  color: string;
  categoryLetter: string;
  category: string;
  categoryWarning: string;
}

const InfoCategory: React.FC<InfoSectionProps> = ({
  borderTop,
  color,
  categoryLetter,
  category,
  categoryWarning,
}) => {
  const sectionClass = `flex flex-row gap-5 desktop:gap-0 desktop:min-h-[137px] desktop:border-b ${
    borderTop ? 'desktop:border-t' : ''
  } border-gray-opacity-15 z-10`;
  const tileClass = `w-10 h-10 ${color} rounded-5`;

  return (
    <div className={sectionClass}>
      <div className="desktop:hidden">
        <div className={tileClass} />
      </div>
      <div className="flex flex-col justify-center desktop:pl-16 pr-5 gap-1 desktop:gap-2">
        <div className="uppercase text-sm font-bold text-charcoal-gray">
          <span className="desktop:hidden uppercase">{categoryLetter} </span>
          {category}
        </div>
        <div className="text-base text-med-gray">{categoryWarning}</div>
      </div>
    </div>
  );
};

export default InfoCategory;
