import {
  useGetHistoricAppointmentCountByProviderQuery,
  useGetHistoricAppointmentCountQuery,
} from 'graphql/generated/remote-schema-hasura';

import React, { useContext, useState } from 'react';
import { AuthContext, AuthContextType } from 'auth/context/AuthContext';
import { SIGNAL_CHANNELS, useChannelSignal } from 'hooks/useChannelSignal';
import { Roles } from '../../../firebase/interfaces';

export const NoAppointmentHistory: React.FC<{ noAppointmentsText: string }> = ({
  noAppointmentsText,
}) => {
  const { user: loggedUser } = useContext<AuthContextType>(AuthContext);
  const [appointmentCount, setAppointmentCount] = useState(0);

  const {
    loading: patientCountLoading,
    refetch: refetchGetPatientHistoricAppointmentCount,
  } = useGetHistoricAppointmentCountQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setAppointmentCount(
        data.getFHIRAppointmentByRequestPatientCodexId.total ?? 0,
      );
    },
    skip: loggedUser?.role === Roles.PROVIDER,
  });

  const {
    loading: providerCountLoading,
    refetch: refetchProviderHistoricAppointmentCount,
  } = useGetHistoricAppointmentCountByProviderQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setAppointmentCount(
        data.getFHIRAppointmentByRequestProviderId.total ?? 0,
      );
    },
    skip: loggedUser?.role === Roles.PATIENT,
  });

  useChannelSignal(
    (first: boolean) => {
      if (!first) {
        if (loggedUser?.role === Roles.PROVIDER) {
          refetchProviderHistoricAppointmentCount();
        } else {
          refetchGetPatientHistoricAppointmentCount();
        }
      }
    },
    SIGNAL_CHANNELS.APPOINTMENTS,
    loggedUser?.uuid,
  );

  if (patientCountLoading || providerCountLoading) return null;

  return (
    <>
      {appointmentCount === 0 && (
        <p className="mt-[15px] text-med-gray font-semibold text-h6 desktop:mt-[30px]">
          {noAppointmentsText}
        </p>
      )}
    </>
  );
};
