import { AuthContext } from 'auth/context/AuthContext';
import { useGetUsersByIdLazyQuery } from 'graphql/generated/hasura';
import { useGetPage } from 'hooks/useGetPage';
import { useContext, useEffect, useState } from 'react';
import { pageIds } from 'utilities/constants';
import {
  getFirstNameFromDisplayName,
  interpolateVariables,
} from 'utilities/functions';
import { useGetFhirPatientbyCodexIdLazyQuery } from 'graphql/generated/remote-schema-hasura';
import { Roles } from '../../../firebase/interfaces';

const Greetings: React.FC = () => {
  const [userFirstName, setUserFirstname] = useState('');
  const { user: loggedUser } = useContext(AuthContext);
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.DASHBOARD,
  });
  const [hasuraGetUsersById, { loading: hasuraLoadingUserData }] =
    useGetUsersByIdLazyQuery({
      variables: { id: loggedUser?.uuid },
      onCompleted: (data) => {
        if (data.users.length > 0) {
          setUserFirstname(data.users[0].SENSITIVE_firstname || '');
        }
      },
    });

  const [fhirGetUsersbyId, { loading: fhirLoadingUserData }] =
    useGetFhirPatientbyCodexIdLazyQuery({
      onCompleted: (data) => {
        if (data.getFHIRPatientbyCodexID.users.length > 0) {
          setUserFirstname(
            data.getFHIRPatientbyCodexID.users[0].SENSITIVE_firstname || '',
          );
        }
      },
    });

  useEffect(() => {
    // TODO: this check should be removed in https://linear.app/codex-labs-sgbnet/issue/COD-1939/migrate-existing-components-to-allow-provider-functionalities
    if (loggedUser?.role === Roles.PATIENT) {
      fhirGetUsersbyId();
    }
  }, [hasuraGetUsersById, fhirGetUsersbyId, loggedUser]);

  if (loading || !locale || hasuraLoadingUserData || fhirLoadingUserData)
    return null;

  return (
    <>
      <div className="col-span-12 flex flex-col justify-center items-center desktop:col-span-9 desktop:flex-row desktop:justify-start">
        <div className="flex flex-col mt-[15px] desktop:mt-0 desktop:ml-[15px]">
          <h2 className="text-[28px] desktop:text-h1 text-dark-gray break-word">
            {interpolateVariables(locale?.user.greetings, {
              firstname: userFirstName
                ? userFirstName
                : getFirstNameFromDisplayName(loggedUser?.displayName) || '',
            })}
          </h2>
          <p className="mt-[15px] text-h6 text-med-gray">
            {locale?.user.fromTestingToTreatment}
          </p>
        </div>
      </div>
    </>
  );
};

export default Greetings;
