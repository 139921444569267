import { createContext } from 'react';
import { ModalSpecification } from 'layout/ModalSpecification';

export type ModalContextType<TReturn = unknown, TState = unknown> = {
  openModal: (
    spec: ModalSpecification<TReturn, TState>,
    canBeDismissed: boolean,
    state: TState,
  ) => Promise<TReturn>;
  closeAllModals: () => Promise<boolean>;
  closeModal: (key: string, result: TReturn) => Promise<unknown> | undefined;
};

export const ModalContext = createContext<ModalContextType>(
  {} as unknown as ModalContextType,
);
