import { Card } from './Card';
import { useMyAppointmentsLocale } from '../pages/MyAppointments';
import { AppointmentsHistoryTable } from './appointmentHistory/AppointmentsHistoryTable';
import {
  AppointmentHistoryRowProps,
  HistoricAppointmentsFilter,
} from '../interfaces';
import {
  formatDateForAppointment,
  formatDateToCustomFormat,
  generateAppointmentLogs,
} from 'utilities/functions';
import {
  AppointmentTypeEnum,
  useGetAppointmentsByPatientCodexIdQuery,
  useGetAppointmentsByProviderQuery,
} from 'graphql/generated/remote-schema-hasura';

import {
  FHIR_APPOINTMENT_STATUS,
  ORDER_BY_DIRECTION_NUMERIC,
  SORT_FHIR_APPOINTMENT_BY,
} from 'utilities/interfaces';
import { AuthContext, AuthContextType } from 'auth/context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { LIST_BATCH_SIZE, PARAM_MODALS_IDENTIFIERS } from 'utilities/constants';
import { AppointmentEvent } from 'graphql/generated/hasura';
import { SIGNAL_CHANNELS, useChannelSignal } from 'hooks/useChannelSignal';
import { QueryContextType } from 'app/my-appointments/components/QueryContextType';
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Roles } from '../../../firebase/interfaces';

const MAX_LOAD_MORE_HISTORY = 10;

const historyStatuses = [
  FHIR_APPOINTMENT_STATUS.FULFILLED,
  FHIR_APPOINTMENT_STATUS.NOSHOW,
];

type varsType = { limit: number; filter: HistoricAppointmentsFilter };
type dataType = { appointments: AppointmentHistoryRowProps[]; total: number };
type AppointmentHistoryContextType = QueryContextType<
  AppointmentHistoryRowProps,
  varsType
>;

const AppointmentHistoryContext = createContext<AppointmentHistoryContextType>(
  {} as unknown as AppointmentHistoryContextType,
);

const PatientAppointmentHistoryProvider: React.FC<{
  children: React.ReactNode;
  patientCodexId?: string | undefined;
  handleMyVisitSummaryClick: (appointmentId: string) => void;
}> = ({ children, handleMyVisitSummaryClick }) => {
  const { loading, locale } = useMyAppointmentsLocale();
  const { user: loggedUser } = useContext<AuthContextType>(AuthContext);
  const [data, setData] = useState<dataType>({
    appointments: [],
    total: 0,
  });
  const [vars, setVars] = useState<varsType>({
    limit: 10,
    filter: {
      sort: SORT_FHIR_APPOINTMENT_BY.DATE,
      sortDirection: ORDER_BY_DIRECTION_NUMERIC.DESC,
    },
  });
  const [allAmount, setAllAmount] = useState<number | undefined>(undefined);

  const getFormatedToDate = () => {
    if (vars.filter.to) {
      const toDate = new Date(vars.filter.to);
      toDate.setHours(23, 59, 59, 999);
      return toDate.toISOString();
    } else {
      return undefined;
    }
  };

  const getFormatedFromDate = () => {
    if (vars.filter.from) {
      const fromDate = new Date(vars.filter.from);
      fromDate.setHours(0, 0, 0, 0);
      return fromDate.toISOString();
    } else {
      return undefined;
    }
  };

  const { loading: dataLoading, refetch } =
    useGetAppointmentsByPatientCodexIdQuery({
      variables: {
        ...vars.filter,
        to: getFormatedToDate(),
        from: getFormatedFromDate(),
        appointmentStatus: historyStatuses,
        providerName: vars.filter.provider,
        limit: vars.limit,
        offset: 0,
      },
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        if (data.getFHIRAppointmentByRequestPatientCodexId.total > 0) {
          const allAppointmentHistory: AppointmentHistoryRowProps[] =
            data.getFHIRAppointmentByRequestPatientCodexId.appointment.map(
              (appointment): AppointmentHistoryRowProps => ({
                id: appointment.appointmentCodexId,
                date:
                  formatDateForAppointment(
                    new Date(appointment.start || ''),
                    true,
                  ) || '',
                name: `${appointment.providerFirstName} ${appointment.providerLastName}`,
                type: appointment.appointmentType || AppointmentTypeEnum.Chat,
                patientCodexId: appointment.codexPatientId,
                providerCodexId: appointment.codexProviderId as string,
                logs: generateAppointmentLogs(
                  (appointment?.events ?? []) as AppointmentEvent[],
                  (locale?.events ?? {}) as unknown as Record<string, string>,
                ),
                actions: [
                  {
                    text: 'yourMyVisitSummary',
                    onClick: () =>
                      handleMyVisitSummaryClick(appointment.appointmentCodexId),
                  },
                ],
                summaryCompleted:
                  !!appointment.encounterNotes?.followupRecommendations ||
                  !!appointment.encounterNotes?.providerSummary,
              }),
            );
          setData({
            appointments: allAppointmentHistory,
            total: data.getFHIRAppointmentByRequestPatientCodexId.total,
          });
        } else {
          setData({
            appointments: [],
            total: 0,
          });
        }
      },
    });

  useGetAppointmentsByPatientCodexIdQuery({
    variables: {
      appointmentStatus: historyStatuses,
      limit: LIST_BATCH_SIZE,
      offset: 0,
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setAllAmount(data.getFHIRAppointmentByRequestPatientCodexId.total);
    },
    onError: () => {
      setAllAmount(0);
    },
  });

  useChannelSignal(
    (first: boolean) => {
      if (!first) {
        refetch();
      }
    },
    SIGNAL_CHANNELS.APPOINTMENTS,
    loggedUser?.uuid,
  );

  const value: AppointmentHistoryContextType = {
    data: data.appointments,
    total: data.total,
    variables: vars,
    loading: loading || dataLoading,
    setVars,
    allAmount,
  };

  return (
    <AppointmentHistoryContext.Provider value={value}>
      {children}
    </AppointmentHistoryContext.Provider>
  );
};

const ProviderAppointmentHistoryProvider: React.FC<{
  children: React.ReactNode;
  patientCodexId?: string | undefined;
  handleMyVisitSummaryClick: (appointmentId: string) => void;
}> = ({ children, patientCodexId, handleMyVisitSummaryClick }) => {
  const { loading, locale } = useMyAppointmentsLocale();
  const { user: loggedUser } = useContext<AuthContextType>(AuthContext);
  const [data, setData] = useState<dataType>({
    appointments: [],
    total: 0,
  });
  const [vars, setVars] = useState<varsType>({
    limit: 10,
    filter: {
      sort: SORT_FHIR_APPOINTMENT_BY.DATE,
      sortDirection: ORDER_BY_DIRECTION_NUMERIC.DESC,
    },
  });

  const getFormatedToDate = () => {
    if (vars.filter.to) {
      const toDate = new Date(vars.filter.to);
      toDate.setHours(23, 59, 59, 999);
      return toDate.toISOString();
    } else {
      return undefined;
    }
  };

  const getFormatedFromDate = () => {
    if (vars.filter.from) {
      const fromDate = new Date(vars.filter.from);
      fromDate.setHours(0, 0, 0, 0);
      return fromDate.toISOString();
    } else {
      return undefined;
    }
  };

  const { loading: dataLoading, refetch } = useGetAppointmentsByProviderQuery({
    variables: {
      patientCodexId,
      ...vars.filter,
      to: getFormatedToDate(),
      from: getFormatedFromDate(),
      appointmentStatus: historyStatuses,
      patientName: vars.filter.patient,
      limit: vars.limit,
      offset: 0,
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.getFHIRAppointmentByRequestProviderId.total > 0) {
        const allAppointmentHistory: AppointmentHistoryRowProps[] =
          data.getFHIRAppointmentByRequestProviderId.appointment.map(
            (appointment): AppointmentHistoryRowProps => ({
              id: appointment.appointmentCodexId,
              date: formatDateToCustomFormat(appointment.start || ''),
              end: formatDateToCustomFormat(appointment.end || ''),
              name: `${appointment.patientFirstName} ${appointment.patientLastName}`,
              type: appointment.appointmentType || AppointmentTypeEnum.Chat,
              patientCodexId: appointment.codexPatientId,
              providerCodexId: appointment.codexProviderId as string,
              logs: generateAppointmentLogs(
                (appointment?.events ?? []) as AppointmentEvent[],
                (locale?.events ?? {}) as unknown as Record<string, string>,
              ),
              actions: [
                {
                  text: 'yourMyVisitSummary',
                  onClick: () =>
                    handleMyVisitSummaryClick(appointment.appointmentCodexId),
                },
              ],
              summaryCompleted:
                !!appointment.encounterNotes?.followupRecommendations ||
                !!appointment.encounterNotes?.providerSummary,
            }),
          );
        setData({
          appointments: allAppointmentHistory,
          total: data.getFHIRAppointmentByRequestProviderId.total,
        });
      } else {
        setData({
          appointments: [],
          total: 0,
        });
      }
    },
  });

  useChannelSignal(
    (first: boolean) => {
      if (!first) {
        refetch();
      }
    },
    SIGNAL_CHANNELS.APPOINTMENTS,
    loggedUser?.uuid,
  );

  const value: AppointmentHistoryContextType = {
    data: data.appointments,
    total: data.total,
    variables: vars,
    loading: loading || dataLoading,
    setVars,
  };

  return (
    <AppointmentHistoryContext.Provider value={value}>
      {children}
    </AppointmentHistoryContext.Provider>
  );
};

export const AppointmentHistoryProvider: React.FC<{
  children: React.ReactNode;
  patientCodexId?: string | undefined;
  handleMyVisitSummaryClick: (appointmentId: string) => void;
}> = ({ children, patientCodexId, handleMyVisitSummaryClick }) => {
  const { user } = useContext(AuthContext);

  const userRole = user?.role;

  const Provider =
    userRole === Roles.PROVIDER
      ? ProviderAppointmentHistoryProvider
      : PatientAppointmentHistoryProvider;

  return (
    <Provider
      patientCodexId={patientCodexId}
      handleMyVisitSummaryClick={handleMyVisitSummaryClick}
    >
      {children}
    </Provider>
  );
};

export const AppointmentHistory: React.FC<{
  patientCodexId?: string | undefined;
  setHasHistoryAppointments?: Dispatch<SetStateAction<boolean>>;
}> = ({ patientCodexId, setHasHistoryAppointments }) => {
  const navigate = useNavigate();

  const handleMyVisitSummaryClick = (appointmentId: string) => {
    navigate(
      `.?${PARAM_MODALS_IDENTIFIERS.MY_VISIT_SUMMARY_MODAL_PATIENT_MODAL_ID}=true`,
      { state: { appointmentId } },
    );
  };

  return (
    <AppointmentHistoryProvider
      patientCodexId={patientCodexId}
      handleMyVisitSummaryClick={handleMyVisitSummaryClick}
    >
      <AppointmentHistoryRenderer
        setHasHistoryAppointments={setHasHistoryAppointments}
      />
    </AppointmentHistoryProvider>
  );
};

const AppointmentHistoryRenderer: React.FC<{
  setHasHistoryAppointments?: Dispatch<SetStateAction<boolean>>;
}> = ({ setHasHistoryAppointments }) => {
  const { loading, locale } = useMyAppointmentsLocale();

  const {
    data,
    loading: dataLoading,
    setVars,
    total,
    variables,
    allAmount,
  } = useContext(AppointmentHistoryContext);

  const handleLoadMoreHistory = () => {
    setVars({
      ...variables,
      limit: variables.limit + MAX_LOAD_MORE_HISTORY,
    });
  };

  const historicAppointmentsSubtitle = `${locale.showing} ${data.length} ${locale.of} ${total}`;

  useEffect(() => {
    if (allAmount === undefined) return;
    setHasHistoryAppointments?.(!!allAmount);
  }, [setHasHistoryAppointments, allAmount]);

  if (!locale || loading) {
    return null;
  }

  return (
    <Card
      title={locale.historicAppointmentsCardTitle}
      subtitle={historicAppointmentsSubtitle}
    >
      <AppointmentsHistoryTable
        appointmentHistory={data}
        filterVars={variables.filter}
        onFilter={(filter) => setVars({ ...variables, filter })}
        totalAppointments={total}
        loadMore={handleLoadMoreHistory}
        isDataLoading={!!dataLoading}
      />
    </Card>
  );
};
