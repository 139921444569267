import { createContext, useContext } from 'react';

export interface NotificationsContextType {
  readonly notificationCount: number;
  readonly internalNotificationCount: number;
  readonly externalNotificationCount: number;
  setNotificationCount: (value: { internal: number; external: number }) => void;
}

export const NotifiationsContext = createContext<NotificationsContextType>({
  notificationCount: 0,
  internalNotificationCount: 0,
  externalNotificationCount: 0,
} as NotificationsContextType);

export const useNotifications = () => {
  const context = useContext(NotifiationsContext);

  if (!context?.setNotificationCount && process.env.NODE_ENV !== 'production')
    throw new Error(
      'useNotifications must be used within a NotificationsProvider.',
    );
  return context;
};

export const useNotificationCount = () => {
  const {
    notificationCount,
    internalNotificationCount,
    externalNotificationCount,
  } = useNotifications();
  return {
    notificationCount,
    internalNotificationCount,
    externalNotificationCount,
  };
};
