import { SHA256 } from 'crypto-js';
import { Scan } from 'app/dashboard/interfaces/scan.interfaces';
import {
  Skintelligent_Tests,
  Image_Source_Types_Enum,
  OrderByDirections,
  SkintelligentOrderByCriterias,
  GetUnauthenticatedQuestionsByIdsQuery,
  GetQuestionnaireResponsesByUserAndQuestionnaireIdQuery,
  GetLatestQuestionnaireByIdQuery,
  Hasura_Test_Types_Enum,
} from 'graphql/generated/hasura';
import { ContactData } from 'app/my-account/interfaces/profile.interfaces';
import Compressor from 'compressorjs';

import { FhirSkintelligentTest } from 'app/my-skin/interfaces/scan.interfaces';
import {
  ALLOWED_ONLY_ALPHABETICAL_AND_NUMBERS_CHARACTERS_WITH_HYPEN,
  ALLOWED_ONLY_ALPHABETICAL_CHARACTERS_AND_SPACES,
  ALLOWED_ONLY_ALPHABETICAL_CHARACTERS_REGEX,
  ALLOWED_ONLY_ALPHABETICAL_CHARACTERS_WITH_HYPEN,
  ALL_ALLOWED_CHARACTERS,
  FULL_PHONE_NUMBER_SEPARATED_BY_GROUPS_REGEX,
  ALLOWED_ONLY_NUMBERS,
  MAX_ZIP_CODE_LENGTH,
  SYSTOLIC_AND_DIASTOLIC_QUESTION_ID,
  capitalizedLabels,
} from './constants';
import { SkintelligentOrderByFhirArg } from './interfaces';
import { DropdownItem } from '../components/dynamicQuestionnaire/interfaces/dynamicQuestionnaireResponse.interface';
import {
  AppointmentEvent,
  AppointmentTypeEnum,
  Appointment_Event,
  GetFhirPatientMediaQuery,
  GetFhirQuestionnaireResponseQuery,
  HasuraAnswerItem,
  ServiceType,
} from 'graphql/generated/remote-schema-hasura';
import { MY_PATIENTS, MY_PATIENTS_BIO_INFO } from './routes';
import {
  MediaIdentifiersTypes,
  SharedMedia,
} from 'app/my-patients/interfaces/interfaces';
import { Roles } from '../firebase/interfaces';

export const calculateSHA256Hash = (content: string): string => {
  return SHA256(content).toString();
};

export function capitalizeFirstLetter(value: string | null) {
  if (!value) {
    return '';
  }
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export const sortScansByDate = (
  scans: Scan[],
  direction: string | undefined,
): Scan[] => {
  return [...scans].sort((a, b) => {
    if (!a.date || !b.date) {
      return 0;
    }
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
      return 0;
    }
    return direction === 'ascending'
      ? dateA.getTime() - dateB.getTime()
      : dateB.getTime() - dateA.getTime();
  });
};

export const populateScanResult = (
  scan: Partial<Skintelligent_Tests>,
): Scan => {
  return {
    id: scan?.test_id,
    reportId: scan?.report_id,
    date: formatDateToCustomFormat(scan?.skintelligent_tests_test?.created_at),
    imageId: scan?.result_image_id || '',
    inflammations: Number(scan?.inflammatory),
    comedones: Number(scan?.comedone),
    pih: Number(scan?.pih),
    total: Number(scan?.total_lesions),
    source: scan?.skintelligent_tests_image_source_type
      ?.value as Image_Source_Types_Enum,
  };
};

export const mapFhirSkintelligentTest = (
  test: FhirSkintelligentTest,
): Skintelligent_Tests => {
  return {
    ...test,
    skintelligent_tests_image_source_type: {
      value: test.image_source,
    },
    skintelligent_tests_test: {
      created_at: test.created_at,
    },
  } as unknown as Skintelligent_Tests;
};

export const formatMMDDYYYYtoYYYYMMDD = (date: string) => {
  const parts = date.split('/');
  const month = parts[0];
  const day = parts[1];
  const year = parts[2];

  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
};

export const formatCurrentDateToYYYYMMDD = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
};

export const getOrderMethodForScanResults = (
  key: string,
): SkintelligentOrderByFhirArg | undefined => {
  const mappedCriteria = new Map<string, SkintelligentOrderByFhirArg>([
    [
      JSON.stringify({ skintelligent_tests_test: { created_at: 'asc' } }),
      {
        field: SkintelligentOrderByCriterias.CreatedAt,
        direction: OrderByDirections.Asc,
      },
    ],
    [
      JSON.stringify({ skintelligent_tests_test: { created_at: 'desc' } }),
      {
        field: SkintelligentOrderByCriterias.CreatedAt,
        direction: OrderByDirections.Desc,
      },
    ],
    [
      JSON.stringify({ inflammatory: 'asc' }),
      {
        field: SkintelligentOrderByCriterias.Inflammatory,
        direction: OrderByDirections.Asc,
      },
    ],
    [
      JSON.stringify({ inflammatory: 'desc' }),
      {
        field: SkintelligentOrderByCriterias.Inflammatory,
        direction: OrderByDirections.Desc,
      },
    ],
    [
      JSON.stringify({ comedone: 'asc' }),
      {
        field: SkintelligentOrderByCriterias.Comedone,
        direction: OrderByDirections.Asc,
      },
    ],
    [
      JSON.stringify({ comedone: 'desc' }),
      {
        field: SkintelligentOrderByCriterias.Comedone,
        direction: OrderByDirections.Desc,
      },
    ],
    [
      JSON.stringify({ pih: 'asc' }),
      {
        field: SkintelligentOrderByCriterias.Pih,
        direction: OrderByDirections.Asc,
      },
    ],
    [
      JSON.stringify({ pih: 'desc' }),
      {
        field: SkintelligentOrderByCriterias.Pih,
        direction: OrderByDirections.Desc,
      },
    ],
    [
      JSON.stringify({ total_lesions: 'asc' }),
      {
        field: SkintelligentOrderByCriterias.TotalLesions,
        direction: OrderByDirections.Asc,
      },
    ],
    [
      JSON.stringify({ total_lesions: 'desc' }),
      {
        field: SkintelligentOrderByCriterias.TotalLesions,
        direction: OrderByDirections.Desc,
      },
    ],
    [
      JSON.stringify({
        skintelligent_tests_image_source_type: { value: 'asc' },
      }),
      {
        field: SkintelligentOrderByCriterias.ImageSource,
        direction: OrderByDirections.Asc,
      },
    ],
    [
      JSON.stringify({
        skintelligent_tests_image_source_type: { value: 'desc' },
      }),
      {
        field: SkintelligentOrderByCriterias.ImageSource,
        direction: OrderByDirections.Desc,
      },
    ],
  ]);

  return mappedCriteria.get(key);
};

export const formatDateToCustomFormat = (
  dateTimeString: string,
  onlyDate = false,
): string => {
  const dateObj = new Date(dateTimeString);
  const month = String(dateObj.getMonth() + 1).padStart(2, '0');
  const day = String(dateObj.getDate()).padStart(2, '0');
  const year = dateObj.getFullYear();
  const hours = String(dateObj.getHours() % 12 || 12).padStart(2, '0');
  const minutes = String(dateObj.getMinutes()).padStart(2, '0');
  const timeZoneName = dateObj
    .toLocaleString(['en-US'], { timeZoneName: 'short' })
    .split(' ')
    .at(-1);
  const ampm = dateObj.getHours() >= 12 ? 'PM' : 'AM';

  const dateString = `${month}/${day}/${year}`;

  if (onlyDate) {
    return dateString;
  }
  return `${dateString} ${hours}:${minutes} ${ampm} ${timeZoneName}`;
};

export const getUserTimeZoneOffset = (): string => {
  const userTimezoneOffset = new Date().getTimezoneOffset();
  // Convert the timezone offset to hours and minutes
  const timezoneOffsetHours = Math.abs(Math.floor(userTimezoneOffset / 60))
    .toString()
    .padStart(2, '0');
  const timezoneOffsetMinutes = Math.abs(userTimezoneOffset % 60)
    .toString()
    .padStart(2, '0');
  const timezoneOffsetSign = userTimezoneOffset < 0 ? '+' : '-';
  const timezoneOffsetString = `${timezoneOffsetSign}${timezoneOffsetHours}:${timezoneOffsetMinutes}`;
  return timezoneOffsetString;
};

export const convertFileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        resolve(reader.result);
      } else {
        reject(new Error('Failed to convert image to Base64.'));
      }
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsDataURL(file);
  });
};

export const getImageDimensionsFromBase64 = async (
  base64Image: string,
): Promise<{ width: number; height: number }> => {
  try {
    const img = new Image();
    img.src = base64Image;

    await img.decode();

    return { width: img.width, height: img.height };
  } catch (error) {
    console.error('Failed to load image or get dimensions:', error);
    return { width: 0, height: 0 };
  }
};

export const blobToFile = (blob: Blob, fileName: string): File => {
  return new File([blob], fileName, {
    type: blob.type,
    lastModified: Date.now(),
  });
};

export const base64ToBlob = (
  base64Data: string,
  contentType = 'application/pdf',
): Blob => {
  const binaryString = window.atob(base64Data);
  const byteArrays: Uint8Array[] = [];

  for (let offset = 0; offset < binaryString.length; offset += 512) {
    const slice = binaryString.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const downloadPdf = (
  fileUrl: string,
  fileName: string,
  blank?: boolean,
) => {
  const anchor = document.createElement('a');
  anchor.href = fileUrl;
  anchor.download = fileName;
  if (blank) {
    anchor.target = '_blank';
  }
  anchor.click();
  window.URL.revokeObjectURL(fileUrl);
};

export const printPdf = (fileUrl: string) => {
  const iframe = document.createElement('iframe');
  iframe.style.display = 'none';
  document.body.appendChild(iframe);

  iframe.src = fileUrl;
  iframe.onload = () => {
    if (iframe.contentWindow) {
      iframe.focus();
      iframe.contentWindow.print();
    }
  };
};

export const excludeItemsFromElementsArray = (
  arr: Element[],
  itemsToExclude: Element[],
) => {
  const remainingItems = arr.filter(
    (currentItem) => !itemsToExclude.includes(currentItem),
  );
  return remainingItems;
};

export const compressImage = (imageFile: File): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    new Compressor(imageFile, {
      quality: 0.6,
      success(result) {
        resolve(result);
      },
      error(error) {
        reject(error);
      },
    });
  });
};

export const blobToBase64 = (blob: Blob): Promise<string> => {
  const reader = new FileReader();

  return new Promise<string>((resolve, reject) => {
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

export const relativeDate = (dateString: string): string => {
  const date = new Date(dateString);
  const now = new Date();
  const timeDifference = now.getTime() - date.getTime();
  const minute = 60 * 1000;
  const hour = minute * 60;
  const day = hour * 24;
  const week = day * 7;

  if (timeDifference < minute) {
    return 'a minute ago';
  } else if (timeDifference < hour) {
    const minutesAgo = Math.floor(timeDifference / minute);
    return `${minutesAgo} minute${minutesAgo === 1 ? '' : 's'} ago`;
  } else if (timeDifference < day) {
    const hoursAgo = Math.floor(timeDifference / hour);
    return `${hoursAgo} hour${hoursAgo === 1 ? '' : 's'} ago`;
  } else if (timeDifference < week) {
    const daysAgo = Math.floor(timeDifference / day);
    return `${daysAgo} day${daysAgo === 1 ? '' : 's'} ago`;
  } else if (timeDifference < week * 2) {
    const weeksAgo = Math.floor(timeDifference / week);
    return `${weeksAgo} week${weeksAgo === 1 ? '' : 's'} ago`;
  } else {
    const formattedDate = `${
      date.getMonth() + 1
    }/${date.getDate()}/${date.getFullYear()}`;
    return formattedDate;
  }
};

export const interpolateVariables = (
  message: string,
  variables: { [key: string]: string },
): string => {
  if (message && message?.trim() === '') {
    return message;
  }

  const areAllVariablesEmpty = Object.values(variables).every(
    (value) => value?.trim() === '',
  );

  if (areAllVariablesEmpty) {
    return message;
  }

  Object.keys(variables).forEach((key) => {
    const regex = new RegExp(`{{${key}}}`, 'g');
    message = message && message.replace(regex, variables[key]);
  });
  return message;
};

export type ObjectValidate = Record<string, string>;

export const areAllKeysDefined = (
  objToValidate: ObjectValidate,
  requiredKeys: string[] = [],
): boolean => {
  for (const key of requiredKeys) {
    const keys = key.split('.');
    let currentObj = objToValidate;

    for (const nestedKey of keys) {
      const value = currentObj[nestedKey];
      if (!value || (Array.isArray(value) && value.length === 0)) {
        return false;
      }
      if (typeof value === 'object' && !Array.isArray(value)) {
        currentObj = value;
      }
    }
  }
  return true;
};

export const scrollToTop = () => {
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
};

// The input date should be in mm/dd/yyyy format.
export const validateDateFormat = (date: string): boolean => {
  const dateFormatRegex = /^\d{2}\/\d{2}\/\d{4}$/;
  if (dateFormatRegex.test(date)) {
    const [month, day, year] = date.split('/');
    const parsedDate = new Date(`${year}-${month}-${day}`);

    if (!isNaN(parsedDate.getTime())) {
      return true;
    }
  }
  return false;
};

export const addMaskToDate = (value: string): string => {
  value = value.replace(/\D/g, '');

  if (value.length >= 2 && value.length <= 4) {
    value = value.slice(0, 2) + '/' + value.slice(2, 4);
  } else if (value.length >= 4) {
    value =
      value.slice(0, 2) + '/' + value.slice(2, 4) + '/' + value.slice(4, 8);
  }
  return value;
};

export const normalizePhoneNumber = (number: string) =>
  number.match(/\d+/g)?.join('') || '';

export const normalizePhoneNumberByUserRole = (number: string, role?: string) =>
  role === Roles.PROVIDER
    ? number.match(/\d+/g)?.slice(1)?.join('') || ''
    : number.match(/\d+/g)?.join('') || '';

interface CountriesValues {
  code: string | null | undefined;
  flag: string | null | undefined;
}

export interface CountriesSelectValue {
  label: string;
  value: string;
  flag: string;
}

interface FullContactData extends ContactData {
  extension: string;
}

export const parseMappedCountriesIntoSelectValues = (
  countriesValues: CountriesValues[],
) =>
  countriesValues.map((value) => ({
    label: value.code,
    value: value.code,
    flag: value.flag,
  }));

export const parseStringArrayIntoSelectOptions = (values: string[]) => {
  return values.map((value) => {
    return { label: value, value: value };
  });
};

export const parseSelectedValue = (
  currentCountry: string,
  mappedCountriesValues: CountriesValues[],
) => {
  const values = parseMappedCountriesIntoSelectValues(mappedCountriesValues);
  const matchingValue = values.find((item) => item.value === currentCountry);
  return matchingValue;
};

export const getCurrentCountryValue = (
  formValues: FullContactData,
  mappedCountriesValues: CountriesValues[],
) => parseSelectedValue(formValues.country, mappedCountriesValues);

export const getDefaultParsedValue = (
  mappedCountriesValues: CountriesValues[],
) => parseMappedCountriesIntoSelectValues(mappedCountriesValues)[0];

export const getParsedCountries = (mappedCountriesValues: CountriesValues[]) =>
  parseMappedCountriesIntoSelectValues(mappedCountriesValues);
export const isArrayOfStrings = (arr: unknown[]) => {
  for (let i = 0; i < arr.length; i++) {
    if (typeof arr[i] !== 'string') {
      return false;
    }
  }
  return true;
};

export const formatDateToAppointment = (date: string) => {
  const options = {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZoneName: 'short',
  };

  const formattedDate = new Intl.DateTimeFormat(
    'en-US',
    options as never,
  ).format(new Date(date));
  return formattedDate;
};

export const avoidSpacesOnKeyDown = (
  e: React.KeyboardEvent<HTMLInputElement>,
) => {
  if (e.key === ' ') {
    e.preventDefault(); // Prevent entering the space
  }
};

export const allowOnlyNumbers = (value: string) => {
  const numericRegex = /^[0-9]*$/;
  return numericRegex.test(value) || value === '';
};

export const allowOnlyLettersWithAllowedCharacters = (value: string) => {
  const nameRegex = ALLOWED_ONLY_ALPHABETICAL_CHARACTERS_WITH_HYPEN;
  return nameRegex.test(value) || value === '';
};

export const allowAllCharactersWithAllowedCharacters = (value: string) => {
  const nameRegex = ALL_ALLOWED_CHARACTERS;
  return nameRegex.test(value) || value === '';
};

export const allowOnlyStringWithNoSpaces = (value: string) => {
  const regex = /^[^\s]*$/;
  return regex.test(value);
};

export const allowCharactersWithMaxLength = (
  value: string,
  maxLength = 100,
) => {
  const regex = ALLOWED_ONLY_ALPHABETICAL_AND_NUMBERS_CHARACTERS_WITH_HYPEN;
  return regex.test(value) && value.length <= maxLength;
};

export const allowOnlyNumbersAndMaxLengthForZipCodes = (value: string) => {
  return (
    ALLOWED_ONLY_NUMBERS.test(value) && value.length <= MAX_ZIP_CODE_LENGTH
  );
};

export const onSelectKeyDownAllowOnlyLetters: React.KeyboardEventHandler<
  HTMLDivElement
> = (event) => {
  const target = event.target as HTMLInputElement;
  const proposedValue = target.value + event.key;

  if (!ALLOWED_ONLY_ALPHABETICAL_CHARACTERS_REGEX.test(proposedValue)) {
    event.preventDefault();
  }
};

export const onSelectKeyDownAllowOnlyLettersAndSpaces: React.KeyboardEventHandler<
  HTMLDivElement
> = (event) => {
  const target = event.target as HTMLInputElement;
  const proposedValue = target.value + event.key;

  if (!ALLOWED_ONLY_ALPHABETICAL_CHARACTERS_AND_SPACES.test(proposedValue)) {
    event.preventDefault();
  }
};

/* Validation of NPI number based on
  https://www.cms.gov/Regulations-and-Guidance/Administrative-Simplification/NationalProvIdentStand/Downloads/NPIcheckdigit.pdf
  Allows for numbers without prefix
  Without prefix: "1234567893" => Valid
*/

function validateNPIWithoutPrefix(npi: string): boolean {
  if (npi.length === 10) {
    let doubleSum = 0;
    let singleSum = 0;

    // Ensure that NPI number starts with 1 or 2 to be valid
    if (['1', '2'].includes(npi[0])) {
      for (let i = 0; i < 9; i += 2) {
        const doubledDigit = parseInt(npi[i]) * 2;
        doubleSum += doubledDigit < 10 ? doubledDigit : (doubledDigit % 10) + 1;
      }

      for (let i = 1; i < 9; i += 2) {
        singleSum += parseInt(npi[i]);
      }

      if (
        10 - ((doubleSum + singleSum + 24) % 10) === parseInt(npi[9]) ||
        (doubleSum + singleSum + 24) % 10 === parseInt(npi[9])
      ) {
        return true;
      }
    }
  }

  return false;
}

/* Validation of NPI number based on
  https://www.cms.gov/Regulations-and-Guidance/Administrative-Simplification/NationalProvIdentStand/Downloads/NPIcheckdigit.pdf
  Allows for numbers with prefix
  With prefix: "808401234567893" => Valid
*/
function validateNPIWithPrefix(npi: string): boolean {
  if (npi.length === 15 && npi.startsWith('80840')) {
    const npiDigits: number[] = npi.slice(5).split('').map(Number);
    let doubleSum = 0;
    let singleSum = 0;

    for (let i = 0; i < 9; i += 2) {
      const doubledDigit = npiDigits[i] * 2;
      doubleSum += doubledDigit < 10 ? doubledDigit : (doubledDigit % 10) + 1;
    }

    for (let i = 1; i < 9; i += 2) {
      singleSum += npiDigits[i];
    }

    if (
      10 - ((doubleSum + singleSum) % 10) === npiDigits[9] ||
      (doubleSum + singleSum) % 10 === npiDigits[9]
    ) {
      return true;
    }
  }

  return false;
}

export function validateNPI(value: string): boolean {
  if (value.startsWith('80840')) {
    // If the value starts with the prefix "80840", use validateNPIWithPrefix
    return validateNPIWithPrefix(value);
  } else {
    // Otherwise, use validateNPIWithoutPrefix
    return validateNPIWithoutPrefix(value);
  }
}
export const getFirstNameFromDisplayName = (displayName?: string): string => {
  if (!displayName) return '';
  const splittedDisplayName = displayName.split(' ');
  if (splittedDisplayName.length === 1) return splittedDisplayName[0];
  return splittedDisplayName.slice(0, -1).join(' ');
};

export const getLastNameFromDisplayName = (displayName?: string): string => {
  if (!displayName) return '';
  const splittedDisplayName = displayName.split(' ');
  if (splittedDisplayName.length === 1) return '';
  return splittedDisplayName.splice(-1, 1).join(' ');
};

export const replacePlusSignFromDisplayedName = (
  displayName?: string | null,
): string => {
  if (!displayName) return '';
  return displayName.replaceAll('+', ' ');
};

export const parsePhoneNumber = (
  input: string,
): { country: string; number: string; extension: string | null } | null => {
  const match = input.match(FULL_PHONE_NUMBER_SEPARATED_BY_GROUPS_REGEX);

  if (match) {
    const [, country, number, extension] = match;
    return {
      country,
      number,
      extension: extension || null,
    };
  }

  return null;
};

export const parseQuestionOptionsForDropdownWithLookup = (
  options: string[],
) => {
  const parsedOptions = options.map((option: string) => ({
    label: option,
    value: option,
  }));
  return parsedOptions;
};

export const parseMultipleValuesIntoSelectFormat = (values: string[]) => {
  return values.map((value: string) => {
    return { label: value, value: value };
  });
};

export const parseValueIntoSelectFormat = (
  value: string,
): string | DropdownItem => {
  return { label: value, value: value };
};

export const getSelectedValues = (
  parsedOptions: DropdownItem[],
  selectedOptions: string[],
): DropdownItem[] => {
  const filteredOptions = parsedOptions.filter((obj: DropdownItem) => {
    return selectedOptions.includes(obj.value);
  });

  const customValues = selectedOptions.filter((value) => {
    return !parsedOptions.some((obj) => obj.value === value);
  });

  const customOptions = customValues.map((option) => {
    return {
      label: option,
      value: option,
    };
  });

  return [...filteredOptions, ...customOptions];
};

interface QuestionItem {
  id: number;
  text: string;
  subtitle: string;
  answers: string[];
}

export const findQuestionById = (
  questions: GetUnauthenticatedQuestionsByIdsQuery,
  id: number,
): QuestionItem | undefined => {
  const foundQuestion = questions.unauthenticated_questions.find(
    (question) => question.id === id,
  );

  if (foundQuestion) {
    return {
      id: foundQuestion.id,
      text: foundQuestion.text,
      answers: foundQuestion.answers ?? [],
      subtitle: foundQuestion.subtitle ?? '',
    };
  }
};

export const convertHealthQuestionnaireResponsesFromFhirToHasura = (
  newData: GetFhirQuestionnaireResponseQuery,
):
  | GetQuestionnaireResponsesByUserAndQuestionnaireIdQuery
  | GetLatestQuestionnaireByIdQuery => {
  if (
    !newData.getFHIRQuestionnaireResponse.questionnaire_response.responses
      .length
  ) {
    return {
      questionnaire_responses: [],
    };
  }
  const questionnaireResponseId =
    newData.getFHIRQuestionnaireResponse.questionnaire_response.id;
  const newResponses =
    newData.getFHIRQuestionnaireResponse.questionnaire_response.responses;
  return {
    questionnaire_responses: [
      {
        ...(questionnaireResponseId && { id: questionnaireResponseId }),
        responses: newResponses.map((response) => {
          const castedResponse = response as HasuraAnswerItem;
          switch (castedResponse.responseType) {
            case 'string':
              return {
                questionID: castedResponse.questionID,
                response: castedResponse.stringResponse,
                currentSection: castedResponse.currentSection,
                currentIndexOfSection: castedResponse.currentIndexOfSection,
              };

            case 'string[]':
              if (
                castedResponse.questionID === SYSTOLIC_AND_DIASTOLIC_QUESTION_ID
              ) {
                return {
                  questionID: castedResponse.questionID,
                  currentSection: castedResponse.currentSection,
                  response: castedResponse.stringArrayResponse?.map(
                    (sugarResp: string, idx: number) => ({
                      questionID: castedResponse.questionID,
                      response: sugarResp,
                      inputId: `input-${idx + 1}`,
                      inputLabel: idx === 0 ? 'Systolic' : 'Diastolic',
                    }),
                  ),
                };
              }
              return {
                questionID: castedResponse.questionID,
                response: castedResponse.stringArrayResponse,
                currentSection: castedResponse.currentSection,
                currentIndexOfSection: castedResponse.currentIndexOfSection,
              };

            // question ID 99 response structure
            case 'HasuraItem[]':
              return {
                questionID: castedResponse.questionID,
                currentSection: castedResponse.currentSection,
                response: castedResponse.hasuraItemArrayResponse?.map(
                  (resp, idx) => {
                    switch (resp.responseType) {
                      case 'string[]':
                        return resp.stringArrayResponse;

                      case 'HasuraItem[]':
                        // each medication response structure
                        return {
                          id: idx,
                          title: `Medication list ${idx + 1}`,
                          completed: false,
                          answers: resp.hasuraItemArrayResponse?.map(
                            (innerResp) => {
                              switch (innerResp.responseType) {
                                case 'string':
                                  return {
                                    questionID: innerResp.questionID,
                                    response: innerResp.stringResponse,
                                  };

                                case 'HasuraMedicationItem':
                                  return {
                                    questionID: innerResp.questionID,
                                    response: {
                                      id: innerResp.hasuraMedicationItemResponse
                                        ?.id,
                                      value:
                                        innerResp.hasuraMedicationItemResponse
                                          ?.value,
                                    },
                                  };

                                default:
                                  throw new Error(
                                    `Unexpected response type from FHIR inside of HasuraItem[] - HasuraMedicationItem: ${innerResp.responseType}`,
                                  );
                              }
                            },
                          ),
                        };
                      default:
                        throw new Error(
                          `Unexpected response type from FHIR inside of HasuraItem[]: ${resp.responseType}`,
                        );
                    }
                  },
                ),
              };

            default:
              throw new Error(
                `Unexpected response type from FHIR: ${response.responseType}`,
              );
          }
        }),
      },
    ],
  };
};

export const convertDNAQuestionnaireResponsesFromFhirToHasura = (
  newData: GetFhirQuestionnaireResponseQuery,
):
  | GetQuestionnaireResponsesByUserAndQuestionnaireIdQuery
  | GetLatestQuestionnaireByIdQuery => {
  if (
    !newData.getFHIRQuestionnaireResponse.questionnaire_response.responses
      .length
  ) {
    return {
      questionnaire_responses: [],
    };
  }
  const questionnaireResponseId =
    newData.getFHIRQuestionnaireResponse.questionnaire_response.id;
  const newResponses =
    newData.getFHIRQuestionnaireResponse.questionnaire_response.responses;
  return {
    questionnaire_responses: [
      {
        ...(questionnaireResponseId && { id: questionnaireResponseId }),
        responses: newResponses.map((response) => {
          const castedResponse = response as HasuraAnswerItem;
          switch (castedResponse.responseType) {
            case 'string':
              return {
                questionID: castedResponse.questionID,
                response: castedResponse.stringResponse,
                currentSection: castedResponse.currentSection,
                currentIndexOfSection: castedResponse.currentIndexOfSection,
              };

            case 'string[]':
              return {
                questionID: castedResponse.questionID,
                response: castedResponse.stringArrayResponse,
                currentSection: castedResponse.currentSection,
                currentIndexOfSection: castedResponse.currentIndexOfSection,
              };

            case 'HasuraItem[]':
              return {
                questionID: castedResponse.questionID,
                currentSection: castedResponse.currentSection,
                currentIndexOfSection: castedResponse.currentIndexOfSection,
                response: castedResponse.hasuraItemArrayResponse?.map(
                  (resp) => {
                    switch (resp.responseType) {
                      case 'string':
                        return {
                          questionID: resp.questionID,
                          response: resp.stringResponse,
                        };

                      case 'HasuraItem[]':
                        return {
                          questionID: resp.questionID,
                          response: resp.hasuraItemArrayResponse?.map(
                            (data) => ({
                              questionID: data.questionID,
                              response: data.stringResponse,
                            }),
                          ),
                        };
                      default:
                        throw new Error(
                          `Unexpected response type from FHIR: ${response.responseType}`,
                        );
                    }
                  },
                ),
              };

            default:
              throw new Error(
                `Unexpected response type from FHIR: ${response.responseType}`,
              );
          }
        }),
      },
    ],
  };
};

// Clears all local storage keys except for the ones inside the parameters
// If it doesn't have anything passed to it, it clears everything
export const clearLocalStorageExceptKeys = (keysToKeep?: string[]) => {
  if (keysToKeep && keysToKeep.length > 0) {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key && !keysToKeep.includes(key)) {
        localStorage.removeItem(key);
      }
    }
  } else {
    localStorage.clear();
  }
};

export const extractUUIDFromResURL = (url: string): string => {
  if (url.includes('/')) {
    const match = url.match(/\/([^/]+)\/?$/);
    return match ? match[1] : '';
  }
  return url;
};

export function fromFhirAppointmentTypeToLocal(
  appointmentType?: AppointmentTypeEnum | null,
): AppointmentTypeEnum {
  switch (appointmentType) {
    case AppointmentTypeEnum.Chat:
      return AppointmentTypeEnum.Chat;
    case AppointmentTypeEnum.Email:
      return AppointmentTypeEnum.Email;
    case AppointmentTypeEnum.Video:
      return AppointmentTypeEnum.Video;
    default: // Dnavisit as default
      return AppointmentTypeEnum.Dnavisit;
  }
}

export function patientBioRouteByPatientId(patientId: string): string {
  return `${MY_PATIENTS}/${patientId}/${MY_PATIENTS_BIO_INFO}`;
}

export const formatDateForAppointment = (date: Date, simplified = false) => {
  const options: Intl.DateTimeFormatOptions = simplified
    ? {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
        timeZone: 'America/Los_Angeles',
      }
    : {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        timeZoneName: 'short',
        hour12: true,
        timeZone: 'America/Los_Angeles',
      };

  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
  return formattedDate;
};

export function asArray<T>(value: Promise<T | T[] | undefined>): Promise<T[]>;
export function asArray<T>(value: T | T[] | undefined): T[];
export function asArray<T>(
  value: (T | T[] | undefined) | Promise<T | T[] | undefined>,
): T[] | Promise<T[]> {
  const logic = (items: T | T[] | undefined): T[] => {
    if (!items) return [];
    return Array.isArray(items) ? items : [items];
  };
  if (value instanceof Promise) {
    return value.then(logic);
  }
  return logic(value);
}

export function singleOrDefault<T, D = null>(
  arr: Promise<T[]>,
  def: D,
): Promise<T | D>;
export function singleOrDefault<T, D = null>(arr: Promise<T[]>): Promise<T | D>;
export function singleOrDefault<T, D = null>(arr: T[], def?: D): T | D;
export function singleOrDefault<T, D = null>(arr: T[]): T | D;
export function singleOrDefault<T, D = null>(
  arr: T[] | Promise<T[]>,
  def: D = null as unknown as D,
): T | D | Promise<T | D> {
  if (!arr) return def;

  const logic = (items: T[]): T | D => {
    if (items.length === 0) return def;
    if (items.length !== 1)
      throw new Error(
        'More than one item found in array that expects zero or one entry',
      );
    return items[0];
  };

  return Array.isArray(arr) ? logic(arr) : arr.then(logic);
}

export function single<T>(arr: Promise<T[]>): Promise<T>;
export function single<T>(arr: T[]): T;
export function single<T>(arr: T[] | Promise<T[]>): T | Promise<T> {
  const logic = (items: T[]): T => {
    if (items.length !== 1)
      throw new Error(
        `${items.length} items found in array that expects exactly one entry`,
      );
    return items[0];
  };

  return Array.isArray(arr) ? logic(arr) : arr.then(logic);
}
export const parseQuestionOptionsForDropdownWithProperties = (
  options: DropdownItem[],
) => {
  const parsedOptions = options.map((option: DropdownItem) => ({
    label: option.label,
    value: option.value,
  }));
  return parsedOptions;
};

export const calculateAge = (birthDateString: string): number => {
  const birthDate = new Date(birthDateString);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();
  if (
    monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }
  return age;
};

export const calculateUserAge = (date: string) => {
  const [year, month, day] = date.split('-').map(Number);

  const birthDate = new Date(year, month - 1, day);
  const today = new Date();

  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();

  // Adjust the age if the birth date has not occurred yet this year
  if (
    monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }
  return age;
};

export const formatTestKitNameForLocaleUsage = (
  testKitId: Hasura_Test_Types_Enum | undefined,
) => {
  switch (testKitId) {
    case Hasura_Test_Types_Enum.DnaSkin:
      return 'testDnaSkin';

    case Hasura_Test_Types_Enum.Skintelligent:
      return 'testSkintelligent';

    case Hasura_Test_Types_Enum.DnaVitamin:
      return 'testDnaVitamin';
    case Hasura_Test_Types_Enum.ZrtHeavyMetals:
    case Hasura_Test_Types_Enum.ZrtHormone:
    case Hasura_Test_Types_Enum.ZrtInflammatory:
    case Hasura_Test_Types_Enum.ZrtNeurotransmitters:
      return 'testHormone';
    default:
      return null;
  }
};

export function parseMediaToSharedMedia(
  medias: GetFhirPatientMediaQuery['getFHIRMediaByRequestPatientCodexID']['media'],
): SharedMedia[] {
  return medias
    .filter((media) => !!media.content.url)
    .map<SharedMedia>((media) => {
      const appointmentId =
        media.identifier?.find(
          ({ type }) => type?.text === MediaIdentifiersTypes.APPOINTMENT_ID,
        )?.value || undefined;
      const dermscoreId =
        media.identifier?.find(
          ({ type }) => type?.text === MediaIdentifiersTypes.DERMSCORE_ID,
        )?.value || undefined;
      const bodySite = media.bodySite?.coding?.[0].code || undefined;
      return {
        date: new Date(media.meta.lastUpdated),
        id: media.id,
        img: media.content.url as string,
        appointmentId,
        dermscoreId,
        bodySite,
        description: media.description || undefined,
      };
    });
}

export function getAge(dateOfBirth: Date): number {
  const today = new Date();
  const birthDate = new Date(dateOfBirth);
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();
  if (
    monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }
  return age;
}

export interface DateLocale {
  monthNames: string;
}

export const formatDateWithLocales = (locales: DateLocale, date: number) => {
  const monthNames = Object.values(locales.monthNames);
  if (monthNames.length !== 12) {
    console.error('Locales object must have exactly 12 month names.');
    return '';
  }

  const parsedDate = new Date(date);

  if (isNaN(parsedDate.getTime())) {
    console.error('Invalid date provided.');
    return '';
  }

  const month = monthNames[parsedDate.getMonth()];
  const day = String(parsedDate.getDate()).padStart(2, '0'); // Ensures day is two digits
  const year = parsedDate.getFullYear();

  const formattedDate = `${month} ${day}, ${year}`;

  return formattedDate;
};

export const formatDateToMonthAndDay = (dateTimeString: number): string => {
  const dateObj = new Date(dateTimeString);
  const month = String(dateObj.getMonth() + 1).padStart(2, '0');
  const day = String(dateObj.getDate()).padStart(2, '0');

  const dateString = `${month}/${day}`;

  return dateString;
};

export const generateAppointmentLogs = (
  events: AppointmentEvent[],
  logs: Record<string, string>,
) => {
  const logsToReturn: string[] = [];

  const eventMap = new Map<Appointment_Event, AppointmentEvent>();

  events.forEach((event) => {
    eventMap.set(event.event, event);
  });

  if (eventMap.has(Appointment_Event.AppointmentEnd)) {
    logsToReturn.push(logs.appointmentComplete);
  } else {
    if (!eventMap.has(Appointment_Event.ProviderJoined)) {
      logsToReturn.push(logs.providerNotAttend);
    }

    if (!eventMap.has(Appointment_Event.PatientJoined)) {
      logsToReturn.push(logs.patientNotAttend);
    }

    const appointmentStartEvent = eventMap.get(
      Appointment_Event.AppointmentStart,
    );
    if (appointmentStartEvent) {
      const providerJoinedEvent = eventMap.get(
        Appointment_Event.ProviderJoined,
      );
      const patientJoinedEvent = eventMap.get(Appointment_Event.PatientJoined);

      if (providerJoinedEvent && appointmentStartEvent) {
        const providerLateMinutes = Math.round(
          (new Date(appointmentStartEvent.date).getTime() -
            new Date(providerJoinedEvent.date).getTime()) /
            (1000 * 60),
        );
        if (providerLateMinutes > 0) {
          logsToReturn.push(
            logs.providerLate.replace(
              '{{minutes}}',
              providerLateMinutes.toString(),
            ),
          );
        }
      }

      if (patientJoinedEvent && appointmentStartEvent) {
        const patientLateMinutes = Math.round(
          (new Date(appointmentStartEvent.date).getTime() -
            new Date(patientJoinedEvent.date).getTime()) /
            (1000 * 60),
        );
        if (patientLateMinutes > 0) {
          logsToReturn.push(
            logs.patientLate.replace(
              '{{minutes}}',
              patientLateMinutes.toString(),
            ),
          );
        }
      }
    }
  }

  return logsToReturn;
};

export function appointmentTypeFromServiceType(
  serviceType: ServiceType,
): AppointmentTypeEnum {
  switch (serviceType) {
    case ServiceType.Chat:
      return AppointmentTypeEnum.Chat;
    case ServiceType.Video:
      return AppointmentTypeEnum.Video;
    default:
      return AppointmentTypeEnum.Video;
  }
}

export type VoidCallback<TArg = undefined> = (arg: TArg) => void;
export type CloseModalCallback<TArg = undefined> = (
  arg: TArg,
) => Promise<unknown> | undefined;

export type Callback<
  TReturns = unknown,
  TArg1 = undefined,
  TArg2 = undefined,
  TArg3 = undefined,
  TArg4 = undefined,
  TArg5 = undefined,
> = TArg1 extends undefined
  ? () => TReturns
  : TArg2 extends undefined
  ? (arg1: TArg1) => TReturns
  : TArg3 extends undefined
  ? (arg1: TArg1, arg2: TArg2) => TReturns
  : TArg4 extends undefined
  ? (arg1: TArg1, arg2: TArg2, arg3: TArg3) => TReturns
  : TArg5 extends undefined
  ? (arg1: TArg1, arg2: TArg2, arg3: TArg3, arg4: TArg4) => TReturns
  : (
      arg1: TArg1,
      arg2: TArg2,
      arg3: TArg3,
      arg4: TArg4,
      arg5: TArg5,
    ) => TReturns;

export const mediaEncodeStringStartsWith = 'media';
export const encodeMediaInChat = (mediaId: string, mediaUrl: string) => {
  return `${mediaEncodeStringStartsWith}:${mediaId}:${mediaUrl}`;
};
export const decodeMediaInChat = (
  message: string,
): { mediaId: string; mediaUrl: string } | null => {
  const [start, mediaId, ...mediaUrl] = message.split(':');
  if (start === mediaEncodeStringStartsWith && mediaId && mediaUrl) {
    return { mediaId, mediaUrl: mediaUrl.join(':') };
  }
  return null;
};

export const transformNavBarOptionsToLowercase = (
  navBarOption: string,
): string => {
  if (!navBarOption) {
    return navBarOption;
  }

  const navBarOptionElements = navBarOption.split(' ');
  const navBarTransformedList = navBarOptionElements.map(
    (navBarOptionElement) => {
      let navBarOptionElementTransformed = navBarOptionElement;
      if (!capitalizedLabels.includes(navBarOptionElement)) {
        navBarOptionElementTransformed = navBarOptionElement.toLowerCase();
      }

      return navBarOptionElementTransformed;
    },
  );
  const navBarTransformed = navBarTransformedList.join(' ');
  return (
    navBarTransformed.substring(0, 1).toUpperCase() +
    navBarTransformed.substring(1)
  );
};
