import { useGetProviderPatientSignedUrlMutation } from 'graphql/generated/hasura';
import { useCallback } from 'react';

export function useGetPatientImage(): (
  patientId: string,
  SENSITIVE_profile_picture_id: string,
) => Promise<string> {
  const [getProviderPatientFileUrlFromStorage] =
    useGetProviderPatientSignedUrlMutation({});

  return useCallback(
    async (patientId: string, SENSITIVE_profile_picture_id: string) => {
      const image = await getProviderPatientFileUrlFromStorage({
        variables: {
          patientCodexId: patientId,
          fileId: SENSITIVE_profile_picture_id,
        },
      });
      return image.data?.GetProviderPatientSignedUrl?.url || '';
    },
    [getProviderPatientFileUrlFromStorage],
  );
}
