import ButtonComponent from 'components/button/buttonComponent';
import Input from 'components/inputComponent';
import { useState } from 'react';

export const Search: React.FC<{
  text: string;
  onSearch: (text: string) => void;
}> = ({ text, onSearch }) => {
  const [search, setSearch] = useState(text);

  return (
    <div>
      <Input
        type="text"
        name="search"
        value={search}
        onChange={(e) => setSearch(e.currentTarget.value)}
        placeholder="Enter name to search"
      />
      <ButtonComponent onClick={() => onSearch(search)}>Search</ButtonComponent>
    </div>
  );
};
