import { GetProviderFullPatientInfoQuery } from 'graphql/generated/remote-schema-hasura';
import {
  extractUUIDFromResURL,
  formatDateToCustomFormat,
} from 'utilities/functions';
import { useGetSignedUrlFromStorage } from 'hooks/useGetSignedUrlFromStorage';
import Avatar from 'components/avatarComponent';

interface PatientComponentProps {
  patient: GetProviderFullPatientInfoQuery | null;
  locale: {
    patientName: string;
    patientPictureAlt: string;
    patientDob: string;
    patientHeight: string;
    patientWeight: string;
    patientWaist: string;
    patientHips: string;
    patientEthnicity: string;
    patientGender: string;
    patientSelfIdentification: string;
  };
  patientId?: string | null;
}

export interface PatientInfoKeys {
  key: string;
  label: string;
}

export const PatientComponent: React.FC<PatientComponentProps> = ({
  patient,
  locale,
  patientId = null,
}) => {
  const patientData = patient?.getFHIRPatientbyCodexIDParameter.users[0];
  const signedProfileUrl = useGetSignedUrlFromStorage(
    patientData?.SENSITIVE_profile_picture_id
      ? extractUUIDFromResURL(patientData?.SENSITIVE_profile_picture_id)
      : '',
    patientId ?? '',
  );

  const patientInfo = [
    {
      label: locale.patientName,
      value: `${patientData?.SENSITIVE_firstname} ${patientData?.SENSITIVE_lastname}`,
    },
    {
      label: locale.patientDob,
      value: formatDateToCustomFormat(patientData?.SENSITIVE_dob ?? '', true),
    },
    {
      label: locale.patientHeight,
      value: `${patientData?.SENSITIVE_user_measurements?.heightFt} ft. ${patientData?.SENSITIVE_user_measurements?.heightIn} in.`,
    },
    {
      label: locale.patientWeight,
      value: `${patientData?.SENSITIVE_user_measurements?.weight} lbs.`,
    },
    {
      label: locale.patientWaist,
      value: `${patientData?.SENSITIVE_user_measurements?.waist} in.`,
    },
    {
      label: locale.patientHips,
      value: `${patientData?.SENSITIVE_user_measurements?.hips} in.`,
    },
    { label: locale.patientEthnicity, value: patientData?.SENSITIVE_etnicity },
    { label: locale.patientGender, value: patientData?.SENSITIVE_gender },
    {
      label: locale.patientSelfIdentification,
      value: patientData?.SENSITIVE_self_identity_gender,
    },
  ];

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-row justify-center">
        <Avatar imageUrl={patientId ? signedProfileUrl : null} size="s" />
      </div>
      <div className="flex flex-col gap-3 text-base">
        {patientInfo.map((info, index) => (
          <div key={index} className="flex flex-row">
            <span className="w-full font-semibold">{info.label}</span>
            <span className="w-full text-med-gray">{info.value ?? '-'}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
