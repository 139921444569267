import { useNavigate } from 'react-router-dom';
import { UpcomingAppointmentsProps } from '../interfaces/upcomingAppointments.interface';
import { Card } from './Card';
import { UpcomingAppointmentsRow } from './UpcomingAppointmentsRow';
import { MY_PATIENTS_BIO_INFO_APPOINTMENT_HISTORY } from 'utilities/routes';
import Placeholder from './Placeholder';
import { AppointmentLimit } from '../pages/PatientInfo';

export const UpcomingAppointments: React.FC<UpcomingAppointmentsProps> = ({
  locale,
  upcomingAppointments,
  count,
}) => {
  const navigate = useNavigate();

  const goToAppointmentHistory = () => {
    navigate(`../${MY_PATIENTS_BIO_INFO_APPOINTMENT_HISTORY}`, {
      replace: true,
    });
  };

  return (
    <Card
      title={locale.upcomingAppointmentsTitle}
      subtitle={String(count?.total ?? 0)}
      buttonText={
        count && count?.total > AppointmentLimit
          ? locale.viewAppointmentsHistory
          : undefined
      }
      borderSubtitle
      buttonCTA={goToAppointmentHistory}
      className="!h-auto min-h-[420px]"
    >
      {upcomingAppointments.length !== 0 ? (
        upcomingAppointments.map((appointment) => (
          <UpcomingAppointmentsRow
            key={appointment.id}
            appointment={appointment}
            onActionClicked={() => {
              return;
            }}
            locale={locale}
          />
        ))
      ) : (
        <Placeholder placeholder={locale.noAppointmentsPlaceholder} />
      )}
    </Card>
  );
};
