import { WarningIcon } from 'assets/icons/WarningIcon';
import IconComponent from './iconComponent';

interface ErrorMessageProps {
  message: string | undefined;
}

const ErrorMessageWithIcon: React.FC<ErrorMessageProps> = ({
  message = '',
}) => {
  return (
    <div className="flex items-center pb-1">
      <div className="self-start">
        <IconComponent icon={WarningIcon} fill={'fill-alert-negative'} />
      </div>
      <p
        data-testid="pw-error-icon-msg"
        className="ml-1 text-sm text-clc-red font-medium"
      >
        {message}
      </p>
    </div>
  );
};

export default ErrorMessageWithIcon;
