import { MedicationHistoryData } from 'app/my-patients/interfaces/MadicationHistory.interface';
import { MedicationHistoryFilter } from 'app/my-skin/pages/MyMedicationHistory';
import { MedicationDetailsMedication } from 'components/medicationHistoryDetailView/components/MedicationDetail';
import {
  GetFhirMedicationRequestListQuery,
  useGetFhirMedicationRequestBranAndNamesByPatientCodexIdQuery,
  useGetFhirMedicationRequestListQuery,
} from 'graphql/generated/remote-schema-hasura';
import {
  ProviderInfo,
  useGetProvidersForCurrentPatient,
} from 'hooks/useGetProvidersForCurrentPatient';
import { useCallback, useEffect, useState } from 'react';
import { SIGNAL_CHANNELS, useChannelSignal } from './useChannelSignal';
import { useAuth } from 'auth/context/AuthContext';
import { Order_By } from 'graphql/generated/hasura';

const BATCH_SIZE = 10;

export function parseMedicationList(
  data: GetFhirMedicationRequestListQuery,
  providerNamesMap: ProviderInfo,
): MedicationHistoryData[] {
  return data.getFHIRMedicationRequestByPatientCodexId.medication_requests.map<MedicationHistoryData>(
    (medication) => ({
      id: medication.prescriptionFhirId,
      brand: medication.brandName
        ? `${medication.brandName} - ${medication.displayName}`
        : medication.displayName,
      date: medication.requestedAt || '',
      provider: medication.providerCodexId
        ? (providerNamesMap.byId.get(medication.providerCodexId) as string)
        : '',
      providerId: medication.providerCodexId || '',
    }),
  );
}

export function parseMedicationDetails(
  data: GetFhirMedicationRequestListQuery,
  providerNamesMap: ProviderInfo,
): MedicationDetailsMedication[] {
  return data.getFHIRMedicationRequestByPatientCodexId.medication_requests.map<MedicationDetailsMedication>(
    (medication) => ({
      brand: medication.brandName ?? undefined,
      isOtc: medication.isOTC,
      type: medication.type ?? ' -- ',
      delivery: medication.delivery ?? ' -- ',
      dosage: medication.dosage ?? ' -- ',
      lastUsed: ' -- ',
      frequency:
        medication.daysSupply?.toString() ?? medication.frequency ?? ' -- ',
      prescribedBy: medication.providerCodexId
        ? (providerNamesMap.byId.get(medication.providerCodexId) as string)
        : '',
      usageTime: '--',
      notes: medication.note ?? '',
      title: medication.displayName,
      medicationId: medication.prescriptionFhirId,
      providerId: medication.providerCodexId || '',
    }),
  );
}

export interface useGetMedicationOutput {
  total: number;
  allMedicationsAmount: number;
  medicationHistoryData: MedicationHistoryData[];
  medicationsDetails: MedicationDetailsMedication[] | undefined;
  providersLoading: boolean;
  medicationLoading: boolean;
  providers: string[];
  providerNamesMap: ProviderInfo;
  brands: string[];
  brandsLoading: boolean;
  allMedicationsLoading: boolean;
}

export function useGetMedications(
  filters: MedicationHistoryFilter,
  patientCodexId?: string,
  skip?: boolean,
): useGetMedicationOutput {
  const [total, setTotal] = useState<number>(0);
  const [allMedicationsAmount, setAllMedicationsAmount] = useState<number>(0);
  const [medicationHistoryData, setMedicationHistoryData] = useState<
    MedicationHistoryData[]
  >([]);
  const [medicationsDetails, setMedicationsDetails] =
    useState<MedicationDetailsMedication[]>();
  const [providers, providersLoading, providerNamesMap] =
    useGetProvidersForCurrentPatient(patientCodexId, skip);
  const { loading: medicationLoading, refetch } =
    useGetFhirMedicationRequestListQuery({
      skip,
      variables: {
        limit: filters.limit,
        sort: Object.keys(filters.orderBy ?? {})[0],
        sortDirection:
          Object.values(filters.orderBy ?? {})[0] === Order_By.Asc ? 1 : -1,
        find: filters.find,
        brandAndName: filters.brandName,
        from: filters.fromDate,
        providerId: filters.providerName
          ? providerNamesMap.byName.get(filters.providerName)
          : undefined,
        to: filters.toDate,
        patientCodexId,
      },
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setMedicationHistoryData(parseMedicationList(data, providerNamesMap));
        setMedicationsDetails(parseMedicationDetails(data, providerNamesMap));
        setTotal(data.getFHIRMedicationRequestByPatientCodexId.total);
      },
    });

  const { loading: allMedicationsLoading } =
    useGetFhirMedicationRequestListQuery({
      skip,
      variables: {
        limit: BATCH_SIZE,
      },
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setAllMedicationsAmount(
          data.getFHIRMedicationRequestByPatientCodexId.total,
        );
      },
    });

  const handleProviderUpdate = useCallback(
    (
      prev: MedicationHistoryData[] | MedicationDetailsMedication[] | undefined,
    ): MedicationHistoryData[] | MedicationDetailsMedication[] | undefined => {
      if (!prev) return prev;
      return prev.map((medication) => {
        const provider = providerNamesMap.byId.get(medication.providerId);
        if (!provider) return medication;
        return {
          ...medication,
          prescribedBy: provider,
          provider: provider,
        };
      }) as unknown as MedicationHistoryData[] | MedicationDetailsMedication[];
    },
    [providerNamesMap],
  );

  useEffect(() => {
    if (!providerNamesMap || providersLoading || !providers.length) return;
    setMedicationsDetails((prev) => {
      return handleProviderUpdate(prev) as MedicationDetailsMedication[];
    });
    setMedicationHistoryData(
      (prev) => handleProviderUpdate(prev) as MedicationHistoryData[],
    );
  }, [handleProviderUpdate, providerNamesMap, providers, providersLoading]);

  const {
    loading: brandsLoading,
    data: brands,
    refetch: refetchBrands,
  } = useGetFhirMedicationRequestBranAndNamesByPatientCodexIdQuery({
    skip,
    variables: {
      patientCodexId,
    },
  });

  const { user } = useAuth();
  useChannelSignal(
    (first) => {
      if (!first) {
        refetch();
        refetchBrands();
      }
    },
    SIGNAL_CHANNELS.MEDICATIONS,
    user?.uuid,
  );

  return {
    medicationHistoryData,
    medicationLoading,
    medicationsDetails,
    providerNamesMap,
    providers,
    providersLoading,
    total,
    brands:
      brands?.getFHIRMedicationRequestBranAndNamesByPatientCodexId
        ?.medication_request_bran_and_names ?? [],
    brandsLoading,
    allMedicationsAmount,
    allMedicationsLoading,
  };
}
