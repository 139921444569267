import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  useGetFileByIdQuery,
  useGetSignedUrlFromStorageMutation,
} from 'graphql/generated/hasura';
import { useGetPage } from 'hooks/useGetPage';
import { genericActionsIds, pageIds } from 'utilities/constants';
import { ReactComponent as CompanyIcon } from 'assets/icons/company.svg';
import { ReactComponent as AppointmentIcon } from 'assets/icons/appointments.svg';
import { ReactComponent as FileDownloadIcon } from 'assets/icons/file-download.svg';
import { ReactComponent as PrintIcon } from 'assets/icons/print.svg';
import { ModalButtonProps } from 'components/modal/modal.interfaces';
import { CalendarIcon } from 'assets/icons/CalendarIcon';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import { formatDateToCustomFormat, printPdf } from 'utilities/functions';
import { useGetFileFromStorage } from 'hooks/useGetFileFromStorage';
import { usePdfDownload } from 'hooks/usePdfDownload';
import { useIsMobile } from 'hooks/useIsMobile';
import Modal from 'components/modal/modalComponent';
import IconComponent from 'components/iconComponent';

interface VisitSummaryProps {
  onClose?: () => void;
}

export const VisitSummary: React.FC<VisitSummaryProps> = ({ onClose }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [iFrameReportUri, setiFrameReportURI] = useState('');
  const [pdfReportLink, setPdfReportLink] = useState<string>('');
  const fetchReportFileRef = useRef<boolean>(false);
  const fileId = location.state?.fileId;
  const { data: locale, loading: loadingPage } = useGetPage({
    locale: 'en',
    pageId: pageIds.VISIT_SUMMARY,
  });
  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.CLOSE],
    });
  const { fetchFileFromStorage } = useGetFileFromStorage();
  const [getSignedUrlFromStorage] = useGetSignedUrlFromStorageMutation({});

  const { downloadPdfWithURI } = usePdfDownload();
  const { data, loading: loadingFile } = useGetFileByIdQuery({
    variables: { fileId: fileId },
  });
  const isMobile = useIsMobile();

  const handleOnDownload = async () => {
    downloadPdfWithURI(pdfReportLink, 'visit_summary.pdf');
  };

  const handleOnPrint = async () => {
    printPdf(pdfReportLink);
  };

  const handleClose = () => {
    location.state.fileId = '';
    onClose && onClose();
    navigate(-1);
  };

  useEffect(() => {
    const getPdfReport = async () => {
      const pdfReportLink = await fetchFileFromStorage(fileId);
      setPdfReportLink(pdfReportLink);

      const signedUrl = await getSignedUrlFromStorage({
        variables: { fileId },
      });
      if (signedUrl.data?.GetSignUrlFormStorage?.url) {
        setiFrameReportURI(signedUrl.data?.GetSignUrlFormStorage.url);
      }
    };

    if (!fetchReportFileRef.current) {
      getPdfReport();
      fetchReportFileRef.current = true;
    }
  }, [fileId, fetchFileFromStorage, getSignedUrlFromStorage]);

  let Buttons: ModalButtonProps[] = [
    {
      label: genericAction?.[genericActionsIds.CLOSE].close,
      paddingX: 'px-[37.5px]',
      onClick: handleClose,
    },
    {
      type: 'icon',
      Icon: FileDownloadIcon,
      onClick: handleOnDownload,
    },
  ];

  const PrintButton: ModalButtonProps = {
    type: 'icon',
    Icon: PrintIcon,
    onClick: handleOnPrint,
  };

  if (!isMobile) {
    Buttons = [...Buttons, PrintButton];
  }

  if (
    loadingPage ||
    !locale ||
    genericActionLoading ||
    !genericAction ||
    loadingFile
  )
    return null;

  return (
    <Modal
      isOpen={true}
      title={locale.visitSummary}
      buttons={pdfReportLink ? Buttons : []}
      modalDesktopWidth="desktop:max-w-[1410px]"
      onClose={handleClose}
    >
      <div className="flex flex-col desktop:flex-row gap-[30px]">
        <div className="flex flex-row p-[30px] bg-white items-center justify-center w-full h-[450px] desktop:min-h-[825px]">
          {iFrameReportUri && (
            <embed
              title="PDF Viewer"
              type="application/pdf"
              src={`${iFrameReportUri}&embedded=true`}
              width="100%"
              height="100%"
            />
          )}
        </div>

        <div className="flex flex-col w-full desktop:w-auto desktop:min-w-[280px]">
          <div className="flex flex-col p-5 gap-5">
            <p className="text-h7 text-dark-gray font-bold uppercase">
              {locale.from}
            </p>
            <div className="flex flex-col gap-2.5">
              <div className="flex flex-row gap-2.5 items-center">
                <CompanyIcon className="w-6 h-6 fill-med-gray" />
                <p className="text-h6 text-med-gray">{locale.dnaVisit}</p>
              </div>

              <div className="flex flex-row gap-2.5 items-center">
                <AppointmentIcon className="w-6 h-6 fill-med-gray" />
                <p className="text-h6 text-med-gray">{locale.dnaVisit}</p>
              </div>

              <div className="flex flex-row gap-2.5 items-center">
                <IconComponent icon={CalendarIcon} fill="fill-med-gray" />
                <p className="text-h6 text-med-gray">
                  {formatDateToCustomFormat(data?.files_by_pk?.created_at)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
