import { useState, useRef, useEffect } from 'react';

type Refs = {
  [key: string]: HTMLElement | null;
};

const useOutsideClick = (refs: Refs, callback: () => void) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const isClickOutsideRefs = Object.keys(refs).every((refKey) => {
        const ref = refs[refKey as keyof typeof refs];
        return ref && !ref.contains(event.target as Node);
      });

      if (isClickOutsideRefs) {
        callback();
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [refs, callback]);
};

const useDropdown = () => {
  const [openDropdownId, setOpenDropdownId] = useState<string | null>(null);
  const dropdownRefs = useRef<Refs>({});

  const handleDropdownClick = (dropdownId: string) => {
    setOpenDropdownId((prevDropdownId) =>
      prevDropdownId === dropdownId ? null : dropdownId,
    );
  };

  const handleHoverOut = () => {
    setOpenDropdownId(null);
  };

  const registerDropdownRef = (dropdownId: string, ref: HTMLElement | null) => {
    dropdownRefs.current[dropdownId] = ref;
  };

  useOutsideClick(dropdownRefs.current, () => setOpenDropdownId(null));

  return {
    dropdownRefs,
    openDropdownId,
    handleDropdownClick,
    registerDropdownRef,
    handleHoverOut,
  };
};

export default useDropdown;
