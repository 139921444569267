import Loader from 'components/loaderComponent';
import { AppointmentTypeEnum } from 'graphql/generated/hasura';
import { useGetFhirAppointmentByAcuityIdQuery } from 'graphql/generated/remote-schema-hasura';
import { useCallback, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  APPOINTMENT_DEEP_LINK_PARAM,
  MY_APPOINTMENTS,
  MY_APPOINTMENTS_CHAT_CONSULTATION,
  MY_APPOINTMENTS_VIDEO_CONSULTATION,
} from 'utilities/routes';

const AppointmentDeepLink: React.FC = () => {
  const [searchParams] = useSearchParams();
  const acuityId = searchParams.get(APPOINTMENT_DEEP_LINK_PARAM) as string;
  const navigate = useNavigate();
  const handleError = useCallback(() => {
    navigate(MY_APPOINTMENTS);
  }, [navigate]);
  const handleJoinAppointment = useCallback(
    (appointmentType: AppointmentTypeEnum, appointmentId: string) => {
      const base =
        appointmentType === AppointmentTypeEnum.Chat
          ? MY_APPOINTMENTS_CHAT_CONSULTATION
          : MY_APPOINTMENTS_VIDEO_CONSULTATION;
      navigate(`${base}&appointment-id=${appointmentId}`);
    },
    [navigate],
  );
  useGetFhirAppointmentByAcuityIdQuery({
    variables: { appointmentAcuityId: acuityId },
    onCompleted: (data) => {
      const { appointmentCodexId, appointmentType } =
        data.getFHIRAppointmentByAcuityId.appointment ?? {};
      if (!appointmentCodexId || !appointmentType) {
        return handleError();
      }

      handleJoinAppointment(appointmentType, appointmentCodexId);
    },
  });
  useEffect(() => {
    if (!acuityId) {
      return handleError();
    }
  }, [acuityId, handleError]);
  return <Loader />;
};

export default AppointmentDeepLink;
