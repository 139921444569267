import {
  FhirQuestionnaireNames,
  useGetFhirQuestionnaireResponseQuery,
} from 'graphql/generated/remote-schema-hasura';
import { useIsMobile } from 'hooks/useIsMobile';

import { useCallback, useContext, useEffect, useState } from 'react';
import { GOOGLE_EHR_AVAILABLE_SYSTEMS_IDENTIFIERS } from 'utilities/constants';
import { convertHealthQuestionnaireResponsesFromFhirToHasura } from 'utilities/functions';
import { GetQuestionnaireResponsesByUserAndQuestionnaireIdQuery } from 'graphql/generated/hasura';
import { AuthContext } from 'auth/context/AuthContext';
import { QuestionnaireResponseInterface } from '../interfaces/dynamicQuestionnaireResponse.interface';
import { MEDICATIONS_IDS } from '../utils/constants';
import InputComponent from 'components/inputComponent';
import {
  HormoneMedicationTableProps,
  MedicationAnswer,
} from '../interfaces/hormoneMedicationTable.interface';
import { hasMonthElapsed } from '../utils/utils';

export const HormoneMedicationTable: React.FC<HormoneMedicationTableProps> = ({
  locale,
  setPersistentData,
  setShowTimeElapsedAlert,
  hormoneTableMedicationsSelected,
  setHormoneTableMedicationsSelected,
  hormoneTableCheckboxesCheckedState,
  setHormoneTableCheckboxesCheckedState,
  hormoneTableCheckboxCheckAll,
  setHormoneTableCheckboxAll,
}) => {
  const isMobile = useIsMobile();

  const generateArray = (length: number): QuestionnaireResponseInterface[] => {
    const resultArray: { id: number; checked: boolean }[] = [];
    for (let i = 0; i < length; i++) {
      resultArray.push({ id: i, checked: false });
    }
    return resultArray;
  };

  const { user: loggedUser } = useContext(AuthContext);

  const [fhirQuestionnaireStoredData, setFhirQuestionnaireStoredData] =
    useState<GetQuestionnaireResponsesByUserAndQuestionnaireIdQuery>({
      questionnaire_responses: [],
    });

  const { loading: isfhirQuestionnaireDataLoading } =
    useGetFhirQuestionnaireResponseQuery({
      variables: {
        codexPatientId: loggedUser?.uuid || '',
        identifier: FhirQuestionnaireNames.HealthQuestionnaire,
        system: GOOGLE_EHR_AVAILABLE_SYSTEMS_IDENTIFIERS.codex,
      },
      onCompleted(data) {
        setShowTimeElapsedAlert(
          hasMonthElapsed(
            data.getFHIRQuestionnaireResponse.questionnaire_response
              .updated_at as string,
          ) as boolean,
        );
        if (data) {
          setFhirQuestionnaireStoredData(
            convertHealthQuestionnaireResponsesFromFhirToHasura(data),
          );
        }
      },
    });

  const getMedicationsFromFhirStoredData =
    useCallback((): QuestionnaireResponseInterface[] => {
      if (
        !isfhirQuestionnaireDataLoading &&
        fhirQuestionnaireStoredData.questionnaire_responses?.length > 0
      ) {
        const questionnaireResponses =
          fhirQuestionnaireStoredData.questionnaire_responses[0];
        if (questionnaireResponses.responses) {
          const medications = questionnaireResponses.responses.filter(
            (response: QuestionnaireResponseInterface) =>
              response.questionID === 99,
          )[0]?.response;
          return medications || [];
        }
      }
      return [];
    }, [
      fhirQuestionnaireStoredData.questionnaire_responses,
      isfhirQuestionnaireDataLoading,
    ]);

  const medicationsFromFhir = getMedicationsFromFhirStoredData();

  const getMedicationSingleAnswer = (
    questionID: number,
    medicationID: number,
  ) => {
    const medications = medicationsFromFhir.filter((medication) => {
      return (
        medication.id === medicationID &&
        medication.answers !== undefined &&
        medication.answers.some((answer) => answer.questionID === questionID)
      );
    });

    if (medications.length > 0) {
      const medication = medications[0];
      const answer =
        medication.answers &&
        medication.answers.find((answer) => answer.questionID === questionID);
      if (answer !== undefined) {
        return answer.response;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const getMedicationDoubleAnswer = (
    questionID: number,
    medicationID: number,
  ) => {
    const medications = medicationsFromFhir.filter((medication) => {
      return (
        medication.id === medicationID &&
        medication.answers !== undefined &&
        medication.answers.some((answer) => answer.questionID === questionID)
      );
    });

    if (medications.length > 0) {
      let answer1: string | null = null;
      let answer2: string | null = null;

      medications.forEach((medication) => {
        const answers =
          medication &&
          medication.answers &&
          (medication.answers.filter(
            (answer) => answer.questionID === questionID,
          ) as MedicationAnswer[]);

        answers &&
          answers.forEach((answer) => {
            if (typeof answer.response === 'object') {
              if (answer.response.id === 'answer-1') {
                answer1 = answer.response.value;
              } else if (answer.response.id === 'answer-2') {
                answer2 = answer.response.value;
              }
            }
          });
      });

      return { answer1, answer2 };
    } else {
      return null;
    }
  };

  const handleCheckbox = (
    item: boolean,
    medicationItem: QuestionnaireResponseInterface,
  ) => {
    setHormoneTableCheckboxesCheckedState((prevState) => {
      // Check if the id already exists in the state
      const index = prevState.findIndex(
        (checkbox) => checkbox.id === medicationItem.id,
      );

      if (index !== -1) {
        // If id exists, toggle the checked property
        const newState = [...prevState];
        newState[index] = {
          ...prevState[index],
          checked: !prevState[index].checked,
        }; // Toggle the checked property
        return newState;
      } else {
        // If id doesn't exist, add a new object to the state
        return [...prevState, { id: medicationItem.id, checked: item }];
      }
    });

    // Update medicationsSelected state based on checkbox state
    setHormoneTableMedicationsSelected((prevState) => {
      if (item) {
        // If the checkbox is checked and medicationItem is not already in medicationsSelected, add it
        if (!prevState.some((med) => med.id === medicationItem.id)) {
          return [...prevState, medicationItem];
        }
      } else {
        // If the checkbox is unchecked, remove the medicationItem from medicationsSelected
        return prevState.filter((med) => med.id !== medicationItem.id);
      }
      return prevState; // If no changes needed, return the previous state
    });
  };

  const getCheckboxState = (id: number) => {
    const filteredCheckbox = hormoneTableCheckboxesCheckedState.filter(
      (checkbox) => checkbox.id === id,
    );

    if (filteredCheckbox.length > 0) {
      return filteredCheckbox[0].checked ?? undefined;
    } else {
      return undefined;
    }
  };

  const checkAllCheckboxes = (checkboxCheckAllState: boolean) => {
    const modifiedCheckboxes = hormoneTableCheckboxesCheckedState.map(
      (checkbox) => ({
        ...checkbox,
        checked: checkboxCheckAllState,
      }),
    );
    setHormoneTableCheckboxesCheckedState(modifiedCheckboxes);
    setHormoneTableCheckboxAll(checkboxCheckAllState);

    // Get IDs of selected medications
    const selectedMedicationIds = modifiedCheckboxes
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.id);

    // Find medication items in medicationsFromFhir based on selected IDs
    const selectedMedications = medicationsFromFhir.filter((medication) =>
      selectedMedicationIds.includes(medication.id),
    );

    // Update medicationsSelected state
    setHormoneTableMedicationsSelected((prevState) => {
      if (checkboxCheckAllState) {
        // If checkboxCheckAllState is true, add selected medications to medicationsSelected
        return [...prevState, ...selectedMedications];
      } else {
        // If checkboxCheckAllState is false, remove selected medications from medicationsSelected
        return [];
      }
    });
  };

  useEffect(() => {
    if (
      medicationsFromFhir.length > 0 &&
      hormoneTableCheckboxesCheckedState.length === 0
    ) {
      setHormoneTableCheckboxesCheckedState(
        generateArray(medicationsFromFhir.length as number),
      );
    }
  }, [
    hormoneTableCheckboxesCheckedState.length,
    medicationsFromFhir.length,
    setHormoneTableCheckboxesCheckedState,
  ]);

  useEffect(() => {
    // Check if checkboxAll is checked
    const isCheckboxAllChecked = hormoneTableCheckboxCheckAll;

    // Check if all individual checkboxes are unchecked
    const allUnchecked = hormoneTableCheckboxesCheckedState.every(
      (checkbox) => !checkbox.checked,
    );

    // If checkboxAll is checked and all individual checkboxes are unchecked, uncheck checkboxAll
    if (isCheckboxAllChecked && allUnchecked) {
      setHormoneTableCheckboxAll(false);
    }
  }, [
    hormoneTableCheckboxCheckAll,
    hormoneTableCheckboxesCheckedState,
    setHormoneTableCheckboxAll,
  ]);

  const updatePersistentData = useCallback(() => {
    const tableQuestionID = MEDICATIONS_IDS.TABLE_MEDICATIONS;

    const updatedMedicationsSelected = hormoneTableMedicationsSelected.map(
      (item) => ({
        ...item,
      }),
    );

    setPersistentData((prevPersistentData) => {
      const tableQuestionIndex = prevPersistentData.findIndex(
        (item) => item.questionID === tableQuestionID,
      );

      const isEmpty = updatedMedicationsSelected.length === 0;

      // If updatedMedicationsSelected is empty and table questionID exists, remove it
      if (isEmpty && tableQuestionIndex !== -1) {
        const updatedPersistentData = [...prevPersistentData];
        updatedPersistentData.splice(tableQuestionIndex, 1); // Remove the entry
        return updatedPersistentData;
      }

      // If the table questionID exists, update its response field
      if (tableQuestionIndex !== -1) {
        const updatedPersistentData = [...prevPersistentData];
        updatedPersistentData[tableQuestionIndex].response =
          updatedMedicationsSelected;
        return updatedPersistentData;
      }

      // If updatedMedicationsSelected is not empty and table questionID doesn't exist, create it
      if (!isEmpty && tableQuestionIndex === -1) {
        const newPersistentDataItem = {
          currentSection: 'Hormone/medication use - Medications table',
          questionID: tableQuestionID,
          response: updatedMedicationsSelected,
        };
        return [...prevPersistentData, newPersistentDataItem];
      }

      // If updatedMedicationsSelected is empty and table questionID doesn't exist, return prevPersistentData
      return prevPersistentData;
    });
  }, [hormoneTableMedicationsSelected, setPersistentData]);

  useEffect(() => {
    updatePersistentData();
  }, [hormoneTableMedicationsSelected, updatePersistentData]);

  if (!locale || isfhirQuestionnaireDataLoading) return null;

  return (
    <div className="flex flex-col gap-2">
      <hr />
      <div>
        <div className="grid grid-cols-1">
          {medicationsFromFhir.map((item, index) => (
            <div key={index}>
              <div className="grid desktop:grid-cols-3 grid-cols-1">
                <div className="w-full desktop:py-0 pt-5">
                  {(isMobile || !index) && (
                    <>
                      <div className="flex pb-5 desktop:pb-0 items-center">
                        <div className="mr-2">
                          <InputComponent
                            type="checkbox"
                            checkboxProps={{
                              checked: !isMobile
                                ? hormoneTableCheckboxCheckAll
                                : getCheckboxState(item.id as number),
                              onCheckboxChange: (e) =>
                                !isMobile
                                  ? checkAllCheckboxes(
                                      !hormoneTableCheckboxCheckAll,
                                    )
                                  : handleCheckbox(e, item),
                            }}
                          />
                        </div>
                        <p
                          className={`${
                            !isMobile
                              ? 'desktop:py-2.5 text-base text-charcoal-gray font-bold'
                              : 'desktop:py-2.5 text-base text-med-gray '
                          }`}
                        >
                          {!isMobile ? locale.medicationName : locale?.include}
                        </p>
                      </div>
                      {!isMobile && <hr />}
                    </>
                  )}
                  {isMobile && (
                    <p className="text-base text-charcoal-gray font-bold">
                      {locale.medicationName}
                    </p>
                  )}
                  <p className="desktop:py-2.5 text-med-gray flex items-center">
                    {!isMobile && (
                      <div className="mr-2">
                        <InputComponent
                          type="checkbox"
                          checkboxProps={{
                            checked: getCheckboxState(item.id as number),
                            onCheckboxChange: (e) => handleCheckbox(e, item),
                          }}
                        />
                      </div>
                    )}
                    {
                      getMedicationSingleAnswer(
                        MEDICATIONS_IDS.MEDICATION,
                        item.id as number,
                      ) as string
                    }
                  </p>
                </div>
                <div className="w-full desktop:py-0 pt-5">
                  {(isMobile || !index) && (
                    <>
                      <p className="desktop:py-2.5 text-base text-charcoal-gray font-bold">
                        {locale.dosage}
                      </p>
                      {!isMobile && <hr />}
                    </>
                  )}
                  <p className="desktop:py-2.5 text-med-gray">
                    {
                      getMedicationDoubleAnswer(
                        MEDICATIONS_IDS.DOSAGE,
                        item.id as number,
                      )?.answer1
                    }{' '}
                    {
                      getMedicationDoubleAnswer(
                        MEDICATIONS_IDS.DOSAGE,
                        item.id as number,
                      )?.answer2
                    }
                  </p>
                </div>
                <div className="w-full desktop:py-0 pt-5">
                  {(isMobile || !index) && (
                    <>
                      <p className="desktop:py-2.5 text-base text-charcoal-gray font-bold">
                        {locale.frecuency}
                      </p>
                      {!isMobile && <hr />}
                    </>
                  )}
                  <p className="desktop:py-2.5 text-med-gray">
                    {
                      getMedicationDoubleAnswer(
                        MEDICATIONS_IDS.FRECUENCY,
                        item.id as number,
                      )?.answer1
                    }
                    {` ${locale.medicationName} `}
                    {
                      getMedicationDoubleAnswer(
                        MEDICATIONS_IDS.FRECUENCY,
                        item.id as number,
                      )?.answer2
                    }
                  </p>
                </div>
              </div>
              <hr className="col-span-6 mt-5" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
