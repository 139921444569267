import InputComponent from 'components/inputComponent';
import { useGetPage } from 'hooks/useGetPage';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { pageIds } from 'utilities/constants';

export interface AppointmentPreworkSymptomsProps {
  symptoms: string[];
  setSymptoms: (symptoms: string[]) => void;
  isContinueButtonTriggered?: boolean;
  setError?: Dispatch<SetStateAction<boolean>>;
}

const AppointmentPreworkSymptoms: React.FC<AppointmentPreworkSymptomsProps> = ({
  setSymptoms,
  symptoms,
  isContinueButtonTriggered,
  setError,
}) => {
  const { data: locale, loading: localeLoading } = useGetPage({
    pageId: pageIds.APPOINTMENT_PREWORK,
    locale: 'en',
  });
  const [isOtherSelected, setIsOtherSelected] = useState(false);
  const symptomsList: string[] = useMemo(
    () => [
      locale?.symptoms.acne,
      locale?.symptoms.ageSpots,
      locale?.symptoms.darkEyeCircles,
      locale?.symptoms.dryness,
      locale?.symptoms.eyePuffiness,
      locale?.symptoms.oilliness,
      locale?.symptoms.redness,
      locale?.symptoms.sensitiveSkin,
      locale?.symptoms.wrinkles,
    ],
    [locale],
  );
  const batchSize = 6;
  const isAllSelected = useMemo<boolean>(
    () => locale && symptomsList.every((symptom) => symptoms.includes(symptom)),
    [symptoms, locale, symptomsList],
  );
  const isNoneSelected = useMemo<boolean>(
    () =>
      locale && symptomsList.every((symptom) => !symptoms.includes(symptom)),
    [symptoms, locale, symptomsList],
  );
  const otherIndex = useMemo<number>(
    () => symptoms.findIndex((symptom) => !symptomsList.includes(symptom)),
    [symptoms, symptomsList],
  );

  const handleSymptomChange = (
    symptom: string,
  ): ((selected: boolean) => void) => {
    return (selected) => {
      const index = symptoms.indexOf(symptom);
      if (selected && index === -1) {
        setSymptoms([...symptoms, symptom]);
      } else if (!selected && index !== -1) {
        const newSymptoms = [...symptoms];
        newSymptoms.splice(index, 1);
        setSymptoms(newSymptoms);
      }
    };
  };

  const onOtherChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (otherIndex !== -1) {
      const newSymptoms = [...symptoms];
      newSymptoms[otherIndex] = newValue;
      setSymptoms(newSymptoms);
    } else {
      setSymptoms([...symptoms, newValue]);
    }
  };
  const onOtherChecked = (checked: boolean) => {
    if (!checked && otherIndex !== -1) {
      const newSymptoms = [...symptoms];
      newSymptoms.splice(otherIndex, 1);
      setSymptoms(newSymptoms);
    }
    setIsOtherSelected(checked);
  };

  const onAllClicked = () => {
    const newSymptoms = [...symptomsList];
    if (otherIndex !== -1) {
      newSymptoms.push(symptoms[otherIndex]);
    }
    setSymptoms(newSymptoms);
  };
  const onNoneClicked = () => {
    const newSymptoms = [];
    if (otherIndex !== -1) {
      newSymptoms.push(symptoms[otherIndex]);
    }
    setSymptoms(newSymptoms);
  };

  useEffect(() => {
    if (otherIndex !== -1 && !isOtherSelected) {
      setIsOtherSelected(true);
    }
  }, [otherIndex, isOtherSelected, setIsOtherSelected]);

  useEffect(() => {
    isOtherSelected && !symptoms[otherIndex]
      ? setError?.(true)
      : setError?.(false);
  }, [isOtherSelected, symptoms, otherIndex, setError]);

  if (!locale || localeLoading) {
    return null;
  }
  return (
    <div className="flex flex-col">
      <InputComponent
        type="radio"
        radioInputProps={{
          radioLabelTextWeight: 'font-semibold text-med-gray',
          radioError: null,
          radioInputValue: locale.symptoms.all,
          radioInputLabel: locale.symptoms.all,
          radioInputCheckedValue: isAllSelected
            ? locale.symptoms.all
            : undefined,
          onRadioClick: onAllClicked,
        }}
      />
      <InputComponent
        type="radio"
        radioInputProps={{
          radioLabelTextWeight: 'font-semibold text-med-gray',
          radioError: null,
          radioInputValue: locale.symptoms.none,
          radioInputLabel: locale.symptoms.none,
          radioInputCheckedValue: isNoneSelected
            ? locale.symptoms.none
            : undefined,
          onRadioClick: onNoneClicked,
        }}
      />
      <div className="flex flex-col desktop:flex-row items-stretch desktop:items-start gap-2 desktop:gap-36">
        <div className="flex flex-col gap-2">
          {symptomsList.slice(0, batchSize).map((symptom) => (
            <div key={symptom} className="flex flex-row items-center gap-2">
              <InputComponent
                type="checkbox"
                checkboxProps={{
                  checked: symptoms.includes(symptom),
                  onCheckboxChange: handleSymptomChange(symptom),
                }}
              />
              <span className="font-semibold text-med-gray text-base">
                {symptom}
              </span>
            </div>
          ))}
        </div>
        <div className="flex flex-col gap-2 grow">
          {symptomsList.slice(batchSize).map((symptom) => (
            <div key={symptom} className="flex flex-row items-center gap-2">
              <InputComponent
                type="checkbox"
                checkboxProps={{
                  checked: symptoms.includes(symptom),
                  onCheckboxChange: handleSymptomChange(symptom),
                }}
              />
              <span className="font-semibold text-med-gray text-base">
                {symptom}
              </span>
            </div>
          ))}
          <div className="flex flex-row items-center gap-2">
            <InputComponent
              type="checkbox"
              checkboxProps={{
                checked: isOtherSelected,
                onCheckboxChange: onOtherChecked,
              }}
            />
            <span className="font-semibold text-med-gray text-base">
              {locale.symptoms.other}
            </span>
          </div>
          <div className="flex flex-row items-center gap-2">
            <div className="opacity-0">
              <InputComponent
                type="checkbox"
                checkboxProps={{
                  checked: true,
                }}
              />
            </div>
            <div className="flex flex-col gap-1 grow">
              <span className="text-dark-gray font-semibold text-base">
                {locale.pleaseDescribe}
                <span className="text-clc-red">*</span>
              </span>
              <InputComponent
                type="text"
                value={otherIndex !== -1 ? symptoms[otherIndex] : ''}
                onChange={onOtherChange}
                isDisabled={!isOtherSelected}
                errorMsgWithIcon={
                  isOtherSelected &&
                  !symptoms[otherIndex] &&
                  isContinueButtonTriggered
                    ? locale?.fillRequredError
                    : null
                }
                errorStatus={
                  isOtherSelected &&
                  !symptoms[otherIndex] &&
                  isContinueButtonTriggered
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentPreworkSymptoms;
