import React, { useEffect, useState } from 'react';

const parseSvgStringToTsx = (svgString: string) => {
  const parser = new DOMParser();
  const svgDoc = parser.parseFromString(svgString, 'image/svg+xml');
  const svgElement = svgDoc.documentElement;

  // Helper function to convert DOM nodes to JSX
  const convertNodeToJsx = (node: Node): React.ReactNode => {
    if (node.nodeType === Node.TEXT_NODE) {
      // Text node
      return node.textContent;
    }

    if (node.nodeType !== Node.ELEMENT_NODE) {
      return null;
    }

    const element = node as Element;
    const attributes = Array.from(element.attributes).reduce(
      (acc, attr) => ({ ...acc, [attr.name]: attr.value }),
      {},
    );

    return React.createElement(
      element.tagName,
      attributes,
      ...Array.from(element.childNodes).map(convertNodeToJsx),
    );
  };

  return convertNodeToJsx(svgElement);
};

const SvgStringToComponent: React.FC<{ svgString: string }> = ({
  svgString,
}) => {
  const [svgJsx, setSvgJsx] = useState<React.ReactNode>(null);

  useEffect(() => {
    const jsx = parseSvgStringToTsx(svgString);
    setSvgJsx(jsx);
  }, [svgString]);

  return <>{svgJsx}</>;
};

export default SvgStringToComponent;
