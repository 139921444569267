import React from 'react';

export const AQIndexIconLabels: React.ComponentType<
  React.SVGProps<SVGSVGElement>
> = ({ id, fill, className }) => {
  return (
    <svg
      className={className}
      width="321"
      height="64"
      viewBox="0 0 321 64"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#clip0_${id})`}>
        <rect
          x="0.5"
          width="320"
          height="40"
          fill={`url(#paint0_linear_${id})`}
        />
        <rect
          width="40"
          height="1"
          transform="matrix(0 -1 1 0 53 40)"
          fill="white"
        />
        <rect
          width="40"
          height="1"
          transform="matrix(0 -1 1 0 106.5 40)"
          fill="white"
        />
        <rect
          width="40"
          height="1"
          transform="matrix(0 -1 1 0 160 40)"
          fill="white"
        />
        <rect
          width="40"
          height="1"
          transform="matrix(0 -1 1 0 213.5 40)"
          fill="white"
        />
        <rect
          width="40"
          height="1"
          transform="matrix(0 -1 1 0 267 40)"
          fill="white"
        />
      </g>
      <path
        d="M30.2259 58L29.5539 56.16H24.7859L24.1139 58H21.6179L25.7459 47.328H28.5939L32.7059 58H30.2259ZM27.1699 49.296L25.3939 54.192H28.9299L27.1699 49.296Z"
        fill="#333333"
      />
      <path
        d="M76.2512 58V47.328H81.8672C83.8992 47.328 84.9392 48.624 84.9392 50.048C84.9392 51.392 84.1072 52.288 83.0992 52.496C84.2352 52.672 85.1472 53.776 85.1472 55.12C85.1472 56.72 84.0752 58 82.0432 58H76.2512ZM78.5232 51.616H81.3712C82.1392 51.616 82.6192 51.12 82.6192 50.432C82.6192 49.776 82.1392 49.264 81.3712 49.264H78.5232V51.616ZM78.5232 56.064H81.4512C82.3152 56.064 82.8272 55.568 82.8272 54.8C82.8272 54.128 82.3472 53.552 81.4512 53.552H78.5232V56.064Z"
        fill="#333333"
      />
      <path
        d="M134.485 58.192C131.365 58.192 128.885 55.952 128.885 52.672C128.885 49.392 131.365 47.152 134.485 47.152C136.917 47.152 138.277 48.432 139.029 49.76L137.077 50.72C136.629 49.856 135.669 49.168 134.485 49.168C132.629 49.168 131.221 50.656 131.221 52.672C131.221 54.688 132.629 56.176 134.485 56.176C135.669 56.176 136.629 55.504 137.077 54.624L139.029 55.568C138.261 56.896 136.917 58.192 134.485 58.192Z"
        fill="#333333"
      />
      <path
        d="M182.48 58V47.328H186.688C190.032 47.328 192.352 49.456 192.352 52.672C192.352 55.888 190.032 58 186.688 58H182.48ZM184.752 56H186.688C188.8 56 190.032 54.48 190.032 52.672C190.032 50.784 188.88 49.328 186.688 49.328H184.752V56Z"
        fill="#333333"
      />
      <path
        d="M236.892 58V47.328H244.444V49.28H239.164V51.6H244.332V53.552H239.164V56.032H244.444V58H236.892Z"
        fill="#333333"
      />
      <path
        d="M290.319 58V47.328H297.871V49.28H292.591V51.6H297.759V53.552H292.591V58H290.319Z"
        fill="#333333"
      />
      <defs>
        <linearGradient
          id={`paint0_linear_${id}`}
          x1="0.500001"
          y1="20"
          x2="320.5"
          y2="20"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0808333" stopColor="#AAD330" />
          <stop offset="0.246667" stopColor="#F7F211" />
          <stop offset="0.415833" stopColor="#EDA626" />
          <stop offset="0.586667" stopColor="#DB032C" />
          <stop offset="0.745" stopColor="#A6004E" />
          <stop offset="0.920833" stopColor="#8F1523" />
        </linearGradient>
        <clipPath id={`clip0_${id}`}>
          <rect x="0.5" width="320" height="40" rx="5" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
