import { useGetProvidersByPatientIdQuery } from 'graphql/generated/remote-schema-hasura';
import { useState, useRef } from 'react';

export interface ProviderInfo {
  byName: Map<string, string>;
  byId: Map<string, string>;
}

export const useGetProvidersForCurrentPatient = (
  patientCodexId?: string,
  skip?: boolean,
) => {
  const [providers, setProviders] = useState<string[]>([]);
  const providerNamesMap = useRef<ProviderInfo>({
    byId: new Map(),
    byName: new Map(),
  });
  const { loading: providersLoading } = useGetProvidersByPatientIdQuery({
    fetchPolicy: 'network-only',
    variables: { patientCodexId },
    skip,
    onCompleted: (data) => {
      providerNamesMap.current.byName =
        data.getProvidersByPatientId.providers.reduce<Map<string, string>>(
          (acc, provider) => {
            acc.set(
              `${provider.SENSITIVE_firstname} ${provider.SENSITIVE_lastname}`,
              provider.id,
            );
            return acc;
          },
          new Map(),
        );
      providerNamesMap.current.byId =
        data.getProvidersByPatientId.providers.reduce<Map<string, string>>(
          (acc, provider) => {
            acc.set(
              provider.id,
              `${provider.SENSITIVE_firstname} ${provider.SENSITIVE_lastname}`,
            );
            return acc;
          },
          new Map(),
        );
      setProviders([...providerNamesMap.current.byName.keys()]);
    },
  });
  return [providers, providersLoading, providerNamesMap.current] as const;
};
