import { pageIds } from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';
import { NotificationsWidget } from '../components/NotificationsWidget';
import { useModalParams } from 'components/modal/useModalManager';
import { AddMedicationModal } from 'app/my-appointments/components/AddMedicationModal';
import Greetings from '../components/Greetings';
import UVInfo from '../components/WeatherWidget/UVInfo';
import AQInfo from '../components/WeatherWidget/AQInfo';
import Loader from 'components/loaderComponent';
import MyVisitSummaryModal from 'app/my-appointments/components/MyVisitSummaryModal';
import MyVisitSummaryModalPatient from '../../appointments/components/MyVisitSummaryModalPatient';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CodexFeatureFlags } from 'utilities/interfaces';
import { useLocation, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import Alert from 'components/alertComponent';
import { MedicationHistoryDetailView } from 'components/medicationHistoryDetailView/MedicationHistoryDetailView';
import {
  PatientNewRequestState,
  PatientNewTestKitRequestState,
  PatientRequestEnum,
} from '../interfaces/providerDashboardInterfaces';
import ButtonComponent from 'components/button/buttonComponent';
import {
  formatTestKitNameForLocaleUsage,
  interpolateVariables,
} from 'utilities/functions';
import FindPatientRecordSearchBar from '../components/FindPatientRecordSearchBar';
import { NewPatientRequestModal } from '../components/provider/newPatientRequest/NewPatientRequestModal';
import { PROVIDER_DASHBOARD_NEW_PATIENT_REQUEST } from 'utilities/routes';
import { Persona_Inquiry_Status_Enum } from 'graphql/generated/hasura';
import { PatientRequestInformation } from '../components/provider/newPatientRequest/interfaces/newPatientRequestModalInterfaces';
import useUserVerificationStatus from 'hooks/useUserVerificationStatus';
import { Roles } from '../../../firebase/interfaces';
import { AuthContext } from 'auth/context/AuthContext';
import { UpcomingAppointments } from '../components/upcomingAppointments/UpcomingAppointments';

export const ProviderDashboardPage = () => {
  const navigate = useNavigate();
  const [showHormoneTestKitAlertText, setShowHormoneTestKitAlertText] =
    useState(false);
  const [hormoneTestKitAlertText, setHormoneTestKitAlertText] =
    useState<string>('');
  const [patientNewRequest, setPatientNewRequest] =
    useState<PatientNewRequestState | null>(null);

  const [patientTestKitNewRequest, setPatientTestKitNewRequest] =
    useState<PatientNewTestKitRequestState | null>(null);

  const { enablePersonaVerification } = useFlags<CodexFeatureFlags>();
  const { userVerificationValidation, loading: loadingUserVerificationStatus } =
    useUserVerificationStatus();
  const [userVerificationStatus, setUserVerificationStatus] = useState<
    Persona_Inquiry_Status_Enum | null | undefined
  >(null);
  const { user: loggedUser } = useContext(AuthContext);
  const [alertVisible, setAlertVisible] = useState<boolean>(true);

  useEffect(() => {
    if (enablePersonaVerification && loggedUser?.role === Roles.PROVIDER) {
      const validateUserStatus = async () => {
        const { userVerificationStatus } = await userVerificationValidation();
        setUserVerificationStatus(userVerificationStatus);
      };
      validateUserStatus().catch((e) =>
        console.log(
          'An error happened while validating the user verification status',
          e,
        ),
      );
    }
  }, [enablePersonaVerification, loggedUser?.role, userVerificationValidation]);

  const location = useLocation();
  const { isOpen: isUVIOpen } = useModalParams('uvi-info');
  const { isOpen: isAQIOpen } = useModalParams('aqi-info');

  const { isOpen: isMyVisitSummaryOpen } = useModalParams(
    'my-visit-summary-testing',
  );
  const { isOpen: isAddMoreMedicationOpen } = useModalParams(
    'add-more-medications-testing',
  );

  const { isOpen: isMyVisitSummaryPatientOpen } = useModalParams(
    'my-visit-patient-summary-testing',
  );

  const { isOpen: isMedicationHistoryDetailViewOpen } = useModalParams(
    'medication-history-detail-view-testing',
  );

  const { isOpen: isNewPatientRequestOpen } = useModalParams(
    'new-patient-request',
  );

  const {
    myvisitSummaryModalDisabledFlag,
    medicationHistoryDetailsModalDisabled,
  } = useFlags<CodexFeatureFlags>();

  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.DASHBOARD,
  });

  const { data: localePendingReview, loading: loadingPendingReview } =
    useGetPage({
      locale: 'en',
      pageId: pageIds.PROVIDER_USER_PENDING_REVIEW,
    });

  const declinePatientRequest = () => {
    // TODO-Implement here logic to write to hasura - COD-2526
    setPatientNewRequest(null);
  };

  const acceptPatientRequest = () => {
    // TODO-Implement here logic to write to hasura - COD-2526
    setPatientNewRequest((prevState) => {
      return {
        ...prevState,
        status: PatientRequestEnum.ACCEPTED,
      } as PatientNewRequestState;
    });
  };

  const declinePatientTestKitRequest = () => {
    // TODO-Implement here logic to write to hasura - COD-2527
    setPatientTestKitNewRequest(null);
  };

  const acceptPatientTestKitRequest = () => {
    // TODO-Implement here logic to write to hasura - COD-2527
    setPatientTestKitNewRequest((prevState) => {
      return {
        ...prevState,
        status: PatientRequestEnum.ACCEPTED,
      } as PatientNewTestKitRequestState;
    });
  };

  const viewPatientRequestDetails = (
    patientData: PatientRequestInformation,
    id: string,
  ) => {
    navigate(PROVIDER_DASHBOARD_NEW_PATIENT_REQUEST, {
      state: { patientData, id },
    });
  };

  const newPatientRequestAlertFactory = (status: PatientRequestEnum) => {
    switch (status) {
      case PatientRequestEnum.PENDING:
        return (
          <>
            <Alert
              isNotification={true}
              type="positive"
              text={interpolateVariables(locale.newPatientRequest, {
                name: patientNewRequest?.patientData.name ?? '',
              })}
              actionLabel={locale?.viewProfile}
              onActionClick={() =>
                viewPatientRequestDetails(
                  patientNewRequest?.patientData as PatientRequestInformation,
                  patientNewRequest?.id as string,
                )
              }
              onClose={declinePatientRequest}
              extraActions={
                <div className="flex gap-5 desktop:ml-5 desktop:mr-[45px] mt-3 desktop:mt-0 desktop:flex-row flex-col desktop:max-w-full max-w-[200px] mx-auto">
                  <ButtonComponent
                    onClick={declinePatientRequest}
                    type="outlined-transparent"
                  >
                    {locale.declineRequest}
                  </ButtonComponent>
                  <ButtonComponent onClick={acceptPatientRequest}>
                    {locale.acceptRequest}
                  </ButtonComponent>
                </div>
              }
            />
          </>
        );
      case PatientRequestEnum.ACCEPTED:
        return (
          <>
            <Alert
              customContent={
                <div className="flex flex-col w-full desktop:flex-row justify-between items-center desktop:relative">
                  <div className="flex flex-col desktop:flex-row w-full gap-5 desktop:gap-[30px]">
                    <p className="text-base font-semibold text-alert-positive desktop:mr-[35px]">
                      {interpolateVariables(locale.acceptedPatientRequest, {
                        name: patientNewRequest?.patientData.name ?? '',
                      })}
                    </p>
                  </div>

                  <ButtonComponent
                    type="underline"
                    className="shrink-0 desktop:mr-[25px] mr-0 desktop:mt-0 mt-5"
                    onClick={() =>
                      viewPatientRequestDetails(
                        patientNewRequest?.patientData as PatientRequestInformation,
                        patientNewRequest?.id as string,
                      )
                    }
                  >
                    {locale.viewProfile}
                  </ButtonComponent>
                </div>
              }
              isNotification={true}
              type="positive"
              onClose={() => setPatientNewRequest(null)}
            />
          </>
        );
      default:
        return null;
    }
  };

  const newPatientTestKitRequestAlertFactory = (status: PatientRequestEnum) => {
    switch (status) {
      case PatientRequestEnum.PENDING:
        return (
          <>
            {patientTestKitNewRequest?.isPatientApproved === false && (
              <Alert
                isNotification={true}
                type="positive"
                text={interpolateVariables(locale.testKitRequest1, {
                  name1: patientTestKitNewRequest?.patientData.name ?? '',
                  name2: patientTestKitNewRequest?.patientData.name ?? '',
                  testKitType:
                    locale?.[
                      formatTestKitNameForLocaleUsage(
                        patientTestKitNewRequest?.testKitType,
                      ) as string
                    ] ?? '',
                })}
                actionLabel={locale?.testKitRequestAction1}
                onActionClick={() =>
                  viewPatientRequestDetails(
                    patientTestKitNewRequest?.patientData as PatientRequestInformation,
                    patientTestKitNewRequest?.patientId as string,
                  )
                }
                onClose={declinePatientTestKitRequest}
                extraActions={
                  <div className="flex gap-5 desktop:ml-5 desktop:mr-[23px] mt-3 desktop:mt-0 desktop:flex-row flex-col desktop:max-w-full max-w-[200px] mx-auto">
                    <ButtonComponent
                      onClick={declinePatientTestKitRequest}
                      type="outlined-transparent"
                    >
                      {locale.testKitRequestAction2}
                    </ButtonComponent>
                    <ButtonComponent onClick={acceptPatientTestKitRequest}>
                      {locale.testKitRequestAction3}
                    </ButtonComponent>
                  </div>
                }
                enableAutoFittingOfExtraActions={true}
              />
            )}
            {patientTestKitNewRequest?.isPatientApproved === true && (
              <Alert
                isNotification={true}
                type="positive"
                text={interpolateVariables(locale.testKitRequest2, {
                  name: patientTestKitNewRequest?.patientData.name ?? '',
                  testKitType:
                    locale?.[
                      formatTestKitNameForLocaleUsage(
                        patientTestKitNewRequest?.testKitType,
                      ) as string
                    ] ?? '',
                })}
                actionLabel={locale?.testKitRequestAction1}
                onActionClick={() =>
                  viewPatientRequestDetails(
                    patientTestKitNewRequest?.patientData as PatientRequestInformation,
                    patientTestKitNewRequest?.patientId as string,
                  )
                }
                onClose={declinePatientTestKitRequest}
                extraActions={
                  <div className="flex gap-5 desktop:ml-5 desktop:mr-[23px] mt-3 desktop:mt-0 desktop:flex-row flex-col desktop:max-w-full max-w-[200px] mx-auto">
                    <ButtonComponent
                      onClick={declinePatientTestKitRequest}
                      type="outlined-transparent"
                    >
                      {locale.declineRequest}
                    </ButtonComponent>
                    <ButtonComponent onClick={acceptPatientTestKitRequest}>
                      {locale.acceptRequest}
                    </ButtonComponent>
                  </div>
                }
                enableAutoFittingOfExtraActions={true}
              />
            )}
          </>
        );
      case PatientRequestEnum.ACCEPTED:
        return (
          <>
            {patientTestKitNewRequest?.isPatientApproved === false && (
              <Alert
                customContent={
                  <div className="flex flex-col w-full desktop:flex-row justify-between items-center desktop:relative">
                    <div className="flex flex-col desktop:flex-row w-full gap-5 desktop:gap-[30px]">
                      <p className="text-base font-semibold text-alert-positive desktop:mr-[35px]">
                        {interpolateVariables(
                          locale.acceptedPatientRequestAndTestKitRequest,
                          {
                            name:
                              patientTestKitNewRequest?.patientData.name ?? '',
                            testKitType:
                              locale?.[
                                formatTestKitNameForLocaleUsage(
                                  patientTestKitNewRequest?.testKitType,
                                ) as string
                              ] ?? '',
                          },
                        )}
                      </p>
                    </div>

                    <ButtonComponent
                      type="underline"
                      className="shrink-0 desktop:mr-[25px] mr-0 desktop:mt-0 mt-5"
                      onClick={() =>
                        viewPatientRequestDetails(
                          patientTestKitNewRequest?.patientData as PatientRequestInformation,
                          patientTestKitNewRequest?.patientId as string,
                        )
                      }
                    >
                      {locale.viewProfile}
                    </ButtonComponent>
                  </div>
                }
                isNotification={true}
                type="positive"
                onClose={() => setPatientTestKitNewRequest(null)}
              />
            )}
          </>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (location.state?.hormoneAlert) {
      setShowHormoneTestKitAlertText(true);
      setHormoneTestKitAlertText(locale?.hormoneTestKitRegistrationAlert);
    }
  }, [locale?.hormoneTestKitRegistrationAlert, location.state]);

  if (
    loading ||
    !locale ||
    loadingPendingReview ||
    !localePendingReview ||
    loadingUserVerificationStatus
  )
    return <Loader />;

  if (isMyVisitSummaryOpen && !myvisitSummaryModalDisabledFlag) {
    return <MyVisitSummaryModal />;
  }

  if (isMyVisitSummaryPatientOpen && !myvisitSummaryModalDisabledFlag) {
    return <MyVisitSummaryModalPatient />;
  }

  if (isAddMoreMedicationOpen) {
    return <AddMedicationModal />;
  }

  if (
    !medicationHistoryDetailsModalDisabled &&
    isMedicationHistoryDetailViewOpen
  ) {
    return (
      <MedicationHistoryDetailView
        // TODO add proper handling - COD-2435
        medicationRequestLists={[]}
      />
    );
  }

  if (isNewPatientRequestOpen) {
    return <NewPatientRequestModal />;
  }

  return (
    <>
      {isAQIOpen && <AQInfo locale={locale.weather} />}
      {isUVIOpen && <UVInfo locale={locale.weather} />}
      {showHormoneTestKitAlertText && (
        <Alert
          isNotification={true}
          type="positive"
          text={hormoneTestKitAlertText}
          actionLabel={locale?.scheduleAppointment}
          // TODO COD-2338
          onActionClick={() => console.log('Schedule an appointment')}
        />
      )}

      <div className="flex flex-col gap-2">
        {patientNewRequest !== null &&
          newPatientRequestAlertFactory(patientNewRequest.status)}

        {patientTestKitNewRequest !== null &&
          newPatientTestKitRequestAlertFactory(patientTestKitNewRequest.status)}
      </div>

      <div className="flex flex-col" data-testid="dashboard-page">
        <div
          className="flex flex-row w-full mb-6"
          data-testid="verified-alert-container"
        />
        {userVerificationStatus === Persona_Inquiry_Status_Enum.Approved &&
          alertVisible && (
            <Alert
              isNotification={true}
              type="positive"
              text={localePendingReview?.accountApproved}
              onClose={() => setAlertVisible(false)}
            />
          )}
        <div className="grid grid-cols-12 gap-[30px] px-7">
          <Greetings />
          <div className="col-span-12 desktop:col-span-9">
            <div className="flex flex-col gap-[30px]">
              <FindPatientRecordSearchBar />
              <UpcomingAppointments />
            </div>
          </div>
          <div className="col-span-12 desktop:grid desktop:col-span-3 desktop:gap-[30px]">
            <div className="hidden desktop:grid">
              <NotificationsWidget />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
