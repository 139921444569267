import { useEffect, useState, useCallback, useRef } from 'react';

export enum ScreenOrientation {
  Landscape = 'landscape',
  Portrait = 'portrait',
}

export const useScreenOrientation = (
  onChange?: (orientation: ScreenOrientation) => void,
) => {
  const isFirstRender = useRef<boolean>(true);
  const [screenOrientation, setScreenOrientation] =
    useState<ScreenOrientation>();

  const onOrientationChange = useCallback(
    (orientation: ScreenOrientation) => {
      setScreenOrientation(orientation);
      if (onChange) onChange(orientation);
    },
    [onChange],
  );

  useEffect(() => {
    const handleOrientationChange = () => {
      if (!screen.orientation) {
        return;
      }

      if (
        screen.orientation.type &&
        screen.orientation.type.includes(ScreenOrientation.Portrait)
      ) {
        onOrientationChange(ScreenOrientation.Portrait);
      } else {
        onOrientationChange(ScreenOrientation.Landscape);
      }
    };

    const handleResize = () => {
      if (window.innerWidth < window.innerHeight) {
        onOrientationChange(ScreenOrientation.Portrait);
      } else {
        onOrientationChange(ScreenOrientation.Landscape);
      }
    };

    if (isFirstRender.current) {
      if (screen.orientation) {
        handleOrientationChange();
      } else {
        handleResize();
      }
      isFirstRender.current = false;
    }

    window.addEventListener('resize', handleResize);
    window.addEventListener('resize', handleOrientationChange);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('resize', handleOrientationChange);
    };
  }, [onOrientationChange]);

  return { screenOrientation };
};
