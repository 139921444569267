import { ReactNode, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from 'auth/context/AuthContext';
import { LOCAL_STORAGE_USER_FROM_QR } from 'utilities/constants';
import StorageService from '../utilities/storageService';

interface Props {
  children: ReactNode;
}

export const QrProtectedRoute = ({ children }: Props) => {
  const { isLoggedIn } = useContext(AuthContext);
  const qrStorage = new StorageService(LOCAL_STORAGE_USER_FROM_QR);
  const userFromQrCode = qrStorage.getData();

  return isLoggedIn || userFromQrCode === 'true' ? (
    <>{children}</>
  ) : (
    <Navigate to="/auth/login" />
  );
};
