import { DateWithTimeTooltip } from 'app/superadmin/components/DateWithTimeTooltip';
import { success } from 'app/superadmin/toasts';
import ButtonComponent from 'components/button/buttonComponent';
import { ReactComponent as Copy } from 'assets/icons/copy-svgrepo-com.svg';
import { Fragment } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const renderers: Record<string, (item: any) => React.ReactNode> = {
  string: (item: string) => <>{item}</>,
  number: (item: number) => <>{item}</>,
  object: (item: object) => <>{JSON.stringify(item)}</>,
  undefined: () => <></>,
  null: () => <></>,
  SAPerson: (item: {
    __typename?: 'SAPerson';
    identifiers: Array<Array<string>>;
    first: string;
    last: string;
  }) => (
    <>
      {`${item.last ?? ''}, ${item.first ?? ''}`}
      <hr />
      {renderers['identifiers'](item.identifiers)}
    </>
  ),
  identifiers: (items: [string, string][]) => {
    return (
      <table>
        <tbody>
          {items.map(([type, id]) => (
            <tr key={type}>
              <td>{type}</td>
              <td>
                {id}
                <ButtonComponent
                  type="underline"
                  className="w-auto"
                  Icon={Copy}
                  onClick={success(
                    `${type} identifier copied to clipboard`,
                    () => navigator.clipboard.writeText(id),
                  )}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  },
  dates: (items: [string, string][]) => {
    return (
      <table>
        <tbody>
          {items.map(([type, date]) => (
            <tr key={type}>
              <td>{type}</td>
              <td>
                <DateWithTimeTooltip isoDate={date} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  },
  flags: (items: string[]) => {
    return items.map((i, idx) => (
      <Fragment key={`${i}-${idx}`}>
        {<DateWithTimeTooltip isoDate={i} />}
        <br />
      </Fragment>
    ));
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function render(item: any): React.ReactNode {
  const type = item?.__typename ?? typeof item;
  const renderer = renderers[type];
  return renderer?.(item) ?? renderers['object'](item);
}
