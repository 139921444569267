import InputComponent from 'components/inputComponent';
import {
  DropdownOptionsState,
  DynamicQuestionResponseSection,
  questionnaire_questions_question,
  QuestionnaireResponseInterface,
} from '../interfaces/dynamicQuestionnaireResponse.interface';
import DynamicQuestionnaireMixedInputs from './dynamicQuestionnaireMixedInputs';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { HandleOnInputChangeType } from '../interfaces/dynamicQuestionnaire.interface';
import { getUiControlType } from '../utils/utils';

// below we should use childrenInputResponseTypes but with omit values
interface RenderNestedQuestionOptionsProps {
  questionToRender: questionnaire_questions_question | null;
  answerChecked:
    | string
    | number
    | QuestionnaireResponseInterface
    | QuestionnaireResponseInterface[]
    | undefined
    | DropdownOptionsState
    | { id: string; value: string }
    | { label: string; value: string };
  nestedAnswerChecked?:
    | string
    | number
    | QuestionnaireResponseInterface
    | QuestionnaireResponseInterface[]
    | undefined
    | DropdownOptionsState
    | { id: string; value: string }
    | { label: string; value: string };
  questionParentID: number;
  childrenOriginalQuestionID: string | null | undefined;
  onChange: HandleOnInputChangeType;
  handleCheckboxToggle: (
    answer: string,
    questionID: number | undefined,
    questionParentID?: number,
    childrenOriginalQuestionID?: string,
  ) => void;
  clearCheckboxState: (questionID: number) => void;
  currentSection: DynamicQuestionResponseSection;
  radioCheckedState?: QuestionnaireResponseInterface[];
  setRadioCheckedState?:
    | Dispatch<SetStateAction<QuestionnaireResponseInterface[]>>
    | undefined;
  checkboxesCheckedState?: QuestionnaireResponseInterface[];
  setCheckboxesCheckedState?:
    | Dispatch<SetStateAction<QuestionnaireResponseInterface[]>>
    | undefined;
  textFieldResponses: QuestionnaireResponseInterface[] | undefined;
  setTextFieldResponses:
    | Dispatch<SetStateAction<QuestionnaireResponseInterface[]>>
    | undefined;
  isRequiredQuestion: boolean;
}

const RenderNestedQuestionOptions: React.FC<
  RenderNestedQuestionOptionsProps
> = ({
  questionToRender,
  answerChecked,
  nestedAnswerChecked,
  questionParentID,
  childrenOriginalQuestionID,
  handleCheckboxToggle,
  currentSection,
  onChange,
  radioCheckedState,
  setRadioCheckedState,
  checkboxesCheckedState,
  setCheckboxesCheckedState,
  textFieldResponses,
  setTextFieldResponses,
  isRequiredQuestion,
}) => {
  const useMemoizedGetResponseByQuestionID = (
    questionIDToFind: number,
    textFieldResponses: QuestionnaireResponseInterface[] | undefined,
  ) => {
    const getResponseByQuestionID = useMemo(() => {
      const foundResponse = textFieldResponses?.find(
        (response) => response.questionID === questionIDToFind,
      );

      return foundResponse ? (foundResponse.response as string) : '';
    }, [textFieldResponses, questionIDToFind]);

    return getResponseByQuestionID;
  };

  const memoizedResponseByQuestionId = useMemoizedGetResponseByQuestionID(
    questionParentID,
    textFieldResponses,
  );

  const handleTextFieldResponses = (value: string) => {
    if (Array.isArray(textFieldResponses) && setTextFieldResponses) {
      // Check if the questionID already exists in the array
      const existingIndex = textFieldResponses.findIndex(
        (response) => response.questionID === questionParentID,
      );

      if (existingIndex !== -1) {
        // If the questionID exists, update the existing item
        const updatedResponses = [...textFieldResponses];
        updatedResponses[existingIndex].response = value;
        setTextFieldResponses(updatedResponses);
      } else {
        // If the questionID doesn't exist, create a new response
        const newResponse: QuestionnaireResponseInterface = {
          questionID: questionParentID,
          response: value,
        };

        setTextFieldResponses([...textFieldResponses, newResponse]);
      }
    }
  };

  if (questionToRender?.questionnaire_questions_question.answers === null) {
    return (
      <>
        <InputComponent
          type="text"
          value={memoizedResponseByQuestionId}
          onChange={(e) => {
            onChange(
              questionToRender?.questionnaire_questions_question.text || '',
              questionParentID || 0,
              e.target.value,
              questionParentID,
              childrenOriginalQuestionID || '',
              questionToRender?.questionnaire_questions_question.ui_control,
            );
            textFieldResponses && handleTextFieldResponses(e.target.value);
          }}
          isDisabled={!isRequiredQuestion}
        />
      </>
    );
  } else {
    return (
      <>
        {questionToRender?.questionnaire_questions_question.ui_control &&
        questionToRender.questionnaire_questions_question.ui_control ===
          'MIX' ? (
          <div className="flex flex-col">
            <DynamicQuestionnaireMixedInputs
              question={questionToRender}
              section={currentSection}
              onChange={onChange}
              handleCheckboxToggle={handleCheckboxToggle}
              questionParentID={questionParentID}
              childrenOriginalQuestionID={childrenOriginalQuestionID}
              radioCheckedState={radioCheckedState}
              setRadioCheckedState={setRadioCheckedState}
              checkboxesCheckedState={checkboxesCheckedState}
              setCheckboxesCheckedState={setCheckboxesCheckedState}
              isRequiredQuestion={isRequiredQuestion}
            />
          </div>
        ) : (
          questionToRender?.questionnaire_questions_question.answers?.map(
            (answer: string) => (
              <div key={answer} className="flex flex-col">
                <InputComponent
                  type={getUiControlType(
                    questionToRender.questionnaire_questions_question
                      .ui_control,
                  )}
                  radioInputProps={{
                    radioError: null,
                    radioInputValue: answer,
                    radioInputLabel: answer,
                    radioInputCheckedValue: nestedAnswerChecked as string,
                    onRadioClick: () =>
                      onChange(
                        answer,
                        questionToRender.questionnaire_questions_question.id,
                        undefined,
                        questionParentID,
                      ),
                  }}
                  checkboxProps={{
                    checkboxLabel: answer,
                    checked: answerChecked === answer,
                    bottomMargin: true,
                    onCheckboxChange: () => onChange(answer, questionParentID),
                    onCheckboxChildrenInputChange: (e) => {
                      onChange(
                        answer,
                        questionToRender.questionnaire_questions_question.id,
                        e.target.value,
                      );
                    },
                  }}
                  isDisabled={!isRequiredQuestion}
                />
              </div>
            ),
          )
        )}
      </>
    );
  }
};

export default RenderNestedQuestionOptions;
