import { useGetPage } from 'hooks/useGetPage';
import { genericActionsIds, pageIds } from 'utilities/constants';
import { ChangeEvent, useCallback, useMemo, useRef, useState } from 'react';
import { firebaseAuth } from '../../../../../../firebase/config';
import { passwordPattern } from 'utilities/variables';
import {
  EmailAuthProvider,
  getMultiFactorResolver,
  reauthenticateWithCredential,
  updatePassword,
  MultiFactorError,
  MultiFactorResolver,
} from 'firebase/auth';
import { FirebaseError } from 'firebase/app';
import { useFirebaseSmsMfa } from '../../../../../../firebase/hooks';
import { FIREBASE_ERROR_CODES } from '../../../../../../firebase/interfaces';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import { useNavigate } from 'react-router-dom';
import { PasswordChangeVerification } from './PasswordChangeVerification';
import { PasswordChangeForm } from './PasswordChangeForm';
import { PasswordChangeSuccess } from './PasswordChangeSuccess';
import useFormValidation, { ValidationRules } from 'hooks/useFormValidation';
import Modal from 'components/modal/modalComponent';

export type ChangePasswordForm = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};

export const PasswordChange = () => {
  const { data: firebaseLocale, loading: firebaseLocaleLoading } = useGetPage({
    locale: 'en',
    pageId: pageIds.FIREBASE_ERRORS,
  });

  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [
        genericActionsIds.CANCEL,
        genericActionsIds.CLOSE,
        genericActionsIds.SUBMIT,
        genericActionsIds.VERIFY,
      ],
    });

  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.MY_PROFILE,
  });

  const [displayMFAForm, setDisplayMFAForm] = useState<boolean>(false);
  const [passwordChangeSuccess, setPasswordChangeSuccess] =
    useState<boolean>(false);
  const [passwordChangeError, setPasswordChangeError] = useState<string>('');
  const [verificationId, setVerificationId] = useState<string>('');
  const [mfaError, setMfaError] = useState<string>('');
  const [code, setCode] = useState<string>('');

  const recaptchaContainerWrapper = useRef<HTMLDivElement>(null);
  const resolver = useRef<MultiFactorResolver>();

  const { handleVerifyPhoneNumberResolver, handleResolveSignIn } =
    useFirebaseSmsMfa(firebaseLocale);

  const [formData, setFormData] = useState<ChangePasswordForm>({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const navigate = useNavigate();

  const handleCancel = useCallback(() => navigate(-1), [navigate]);

  const validationRules: ValidationRules = {
    currentPassword: [
      {
        validator: (value) => value.length > 0,
        message: locale.securityAndPrivacy.passwordRequired,
      },
    ],
    newPassword: [
      {
        validator: (value) => new RegExp(passwordPattern).test(value),
        message: locale.securityAndPrivacy.passwordNotValid,
      },
    ],
    confirmPassword: [
      {
        validator: (value, formData) => value === formData?.newPassword,
        message: locale.securityAndPrivacy.confirmPasswordMismatch,
      },
    ],
  };

  const { errors, validateField, validateForm } = useFormValidation(
    validationRules,
    formData,
  );

  const handleOnCodeChange = (code: string) => {
    setMfaError('');
    setCode(code);
  };

  const handleOnInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    validateField(name, value);
    setPasswordChangeError('');
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const resolveMFA = useCallback(
    async (resolver: MultiFactorResolver) => {
      if (recaptchaContainerWrapper.current) {
        try {
          const verificationId = await handleVerifyPhoneNumberResolver(
            resolver,
            'recaptcha-container',
          );
          setVerificationId(verificationId);
          setDisplayMFAForm(true);
        } catch (error) {
          setPasswordChangeError(locale.securityAndPrivacy.verifyPhoneError);
        }
      }
    },
    [handleVerifyPhoneNumberResolver, locale?.securityAndPrivacy],
  );

  const handleUpdatePassword = useCallback(
    async (
      currentPassword: string,
      newPassword: string,
      recentlyAuthenticated = false,
    ) => {
      const currentUser = firebaseAuth.currentUser;
      if (currentUser?.email) {
        try {
          if (!recentlyAuthenticated) {
            const credential = EmailAuthProvider.credential(
              currentUser.email,
              currentPassword,
            );

            await reauthenticateWithCredential(currentUser, credential);
          }

          await updatePassword(currentUser, newPassword);
          setPasswordChangeSuccess(true);
        } catch (error) {
          if (error instanceof FirebaseError) {
            switch (error.code) {
              case FIREBASE_ERROR_CODES.AUTH_MULTI_FACTOR_AUTH_REQUIRED:
                resolver.current = getMultiFactorResolver(
                  firebaseAuth,
                  error as MultiFactorError,
                );
                resolveMFA(resolver.current);
                break;
              case FIREBASE_ERROR_CODES.AUTH_WRONG_PASSWORD:
                setPasswordChangeError(locale.securityAndPrivacy.wrongPassword);
                break;
              case FIREBASE_ERROR_CODES.AUTH_TOO_MANY_REQUESTS:
                setPasswordChangeError(
                  locale.securityAndPrivacy.tooManyRequests,
                );
                break;
              default:
                console.error(error);
                break;
            }
          } else {
            console.error(error);
          }
        }
      }
    },
    [resolveMFA, locale?.securityAndPrivacy],
  );

  const handleVerifyCode = useCallback(async () => {
    if (verificationId && resolver.current) {
      try {
        await handleResolveSignIn(resolver.current, code, verificationId);
        await handleUpdatePassword(
          formData.currentPassword,
          formData.newPassword,
          true,
        );
      } catch (error) {
        setMfaError(locale.securityAndPrivacy.invalidVerificationCode);
      }
    }
  }, [
    code,
    formData.currentPassword,
    formData.newPassword,
    handleResolveSignIn,
    handleUpdatePassword,
    verificationId,
    locale?.securityAndPrivacy,
  ]);

  const handleSubmit = useCallback(async () => {
    if (validateForm(formData)) {
      await handleUpdatePassword(
        formData.currentPassword,
        formData.newPassword,
      );
    }
  }, [formData, handleUpdatePassword, validateForm]);

  const buttons = useMemo(
    () =>
      passwordChangeSuccess
        ? [
            {
              label: genericAction?.[genericActionsIds.CLOSE].close,
              onClick: handleCancel,
            },
          ]
        : [
            {
              label: displayMFAForm
                ? genericAction?.[genericActionsIds.VERIFY].verify
                : genericAction?.[genericActionsIds.SUBMIT].submit,
              onClick: displayMFAForm ? handleVerifyCode : handleSubmit,
            },
            {
              label: genericAction?.[genericActionsIds.CANCEL].cancel,
              type: 'underline',
              onClick: handleCancel,
            },
          ],
    [
      displayMFAForm,
      passwordChangeSuccess,
      handleCancel,
      handleSubmit,
      handleVerifyCode,
      genericAction,
    ],
  );

  if (
    (loading && !locale) ||
    (firebaseLocaleLoading && !firebaseLocale) ||
    (genericActionLoading && !genericAction)
  )
    return null;

  return (
    <Modal
      buttons={buttons}
      contentPaddingY="py-[37px]"
      modalDesktopWidth="desktop:max-w-[600px]"
      isOpen={true}
      title={locale.securityAndPrivacy.changePassword}
      contentContainerGap="gap-0"
      onClose={handleCancel}
      disableMobileGrow
    >
      {displayMFAForm ? (
        passwordChangeSuccess ? (
          <PasswordChangeSuccess />
        ) : (
          <PasswordChangeVerification
            mfaError={mfaError}
            onCodeChange={handleOnCodeChange}
          />
        )
      ) : (
        <PasswordChangeForm
          errors={errors}
          passwordChangeError={passwordChangeError}
          onChange={handleOnInputChange}
        />
      )}
      <div ref={recaptchaContainerWrapper}>
        <div id="recaptcha-container" />
      </div>
    </Modal>
  );
};
