import {
  Legal_Document_Types_Enum,
  useGetLegalDocumentAcceptanceByLegalDocumentTypeLazyQuery,
} from 'graphql/generated/hasura';
import { LegalDocument } from 'graphql/generated/strapi';
import { useIsMobile } from 'hooks/useIsMobile';
import { calculateSHA256Hash } from 'utilities/functions';

export const useAcceptedLegalDocument = () => {
  const [fetchLegalDocumentAcceptance] =
    useGetLegalDocumentAcceptanceByLegalDocumentTypeLazyQuery({
      fetchPolicy: 'no-cache',
    });

  const isMobile = useIsMobile();

  const hasAcceptedLegalDocument = async (
    document: LegalDocument,
    userId: string,
  ): Promise<boolean> => {
    try {
      const acceptedLegalDocument = await fetchLegalDocumentAcceptance({
        variables: {
          user_id: userId,
          legal_document_type:
            document.legalDocumentType as unknown as Legal_Document_Types_Enum,
        },
      });

      const latestHashContent = isMobile
        ? calculateSHA256Hash(document.contentMobile)
        : calculateSHA256Hash(document.contentWeb);

      const filteredAcceptances =
        acceptedLegalDocument.data?.accepted_legal_documents.find(
          (acceptance) => {
            return (
              acceptance.accepted_hash_content === latestHashContent &&
              (acceptance.expiration_date
                ? new Date(acceptance.expiration_date) > new Date()
                : true) &&
              acceptance.termination_date === null
            );
          },
        );

      return Boolean(filteredAcceptances);
    } catch (error: unknown) {
      throw new Error('Failed to check acceptance of legal document');
    }
  };

  return { hasAcceptedLegalDocument };
};
