import React from 'react';

export const MyNotesSolidIcon: React.ComponentType<
  React.SVGProps<SVGSVGElement>
> = ({ fill, className }) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 12.6662V16H3.3338L13.1707 6.16752L9.83732 2.83412L0 12.6662ZM10.7793 1.88693L14.1131 5.22073L15.74 3.59383C16.0867 3.24712 16.0867 2.6826 15.74 2.33588L13.6641 0.260036C13.3174 -0.0866787 12.7529 -0.0866787 12.4062 0.260036L10.7793 1.88693Z"
        fill="#2B43DA"
      />
    </svg>
  );
};
