import { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContextType, AuthContext } from 'auth/context/AuthContext';
import { ReactComponent as CloseIcon } from 'assets/icons/iconClose.svg';
import { WarningIcon } from 'assets/icons/WarningIcon';
import { pageIds } from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';
import { BarScanner } from '../components/BarScanner/BarScanner';
import {
  TEST_KITS_MANAGEMENT_BARCODE_SCANNED,
  TEST_KITS_MANAGEMENT_BARCODE_SCAN_COMPLETED,
  TEST_KITS_MANAGEMENT_REGISTER_TEST_KIT,
} from 'utilities/routes';
import { useSaveDnaScannedDataMutation } from 'graphql/generated/hasura';
import {
  TEST_KIT_MAX_LENGTH,
  TRACKING_NUMBER_MAX_LENGTH,
} from '../../my-skin/pages/constants';
import IconComponent from 'components/iconComponent';
import ButtonComponent from 'components/button/buttonComponent';
import { useScreenOrientation } from 'hooks/useScreenOrientation';

const uspsTrackingNumberRegexp = new RegExp(
  // https://linear.app/codex-labs-sgbnet/issue/COD-1465/handle-slint-disable-for-regex-control-special-char
  // eslint-disable-next-line no-control-regex
  /(.*\x1D)?(9400\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{1,4}|9205\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{1,4}|9407\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{1,4}|9303\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{1,4}|82\s?\d{3}\s?\d{3}\s?\d{1,3}|EC\s?\d{3}\s?\d{3}\s?\d{3}(\s?US)?|9270\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{1,4}|EA\s?\d{3}\s?\d{3}\s?\d{3}(\s?US)?|CP\s?\d{3}\s?\d{3}\s?\d{3}(\s?US)?|9208\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{1,4}|9202\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{1,4}|9218\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{1,4}|)$/,
);
const fedexStripTrackingNumberToRemove = '1002303102410009700800';

export const BarcodeCamera = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoggedIn } = useContext<AuthContextType>(AuthContext);
  const token = location.state?.token || '';
  const testKitNumber = (location.state?.testKitNumber as string) || '';
  const [hasBarcodeError, setBarcodeError] = useState<boolean>(false);
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.BARCODE_CAMERA,
  });
  const [saveScannedDataMutation] = useSaveDnaScannedDataMutation({});

  const { screenOrientation } = useScreenOrientation();

  const closeBarcodeScanner = () => {
    navigate(TEST_KITS_MANAGEMENT_REGISTER_TEST_KIT);
  };

  const memoizedBarcodeDetected = useCallback(
    async (barcode: string) => {
      if (testKitNumber) {
        const testKitNumberWithMaxLength =
          testKitNumber.length > TEST_KIT_MAX_LENGTH
            ? testKitNumber.slice(0, TEST_KIT_MAX_LENGTH)
            : testKitNumber;

        let regexMatched: RegExpExecArray | string[] | null =
          uspsTrackingNumberRegexp.exec(barcode);

        if (!regexMatched || (regexMatched.length && !regexMatched[2])) {
          regexMatched = ['', '', barcode];
        }
        regexMatched[2] = regexMatched[2].replace(
          fedexStripTrackingNumberToRemove,
          '',
        );

        const trackingNumberWithMaxLength =
          regexMatched[2].length > TRACKING_NUMBER_MAX_LENGTH
            ? regexMatched[2].slice(0, TRACKING_NUMBER_MAX_LENGTH)
            : regexMatched[2];

        const saveScannedDataPromise = saveScannedDataMutation({
          variables: {
            swabBarcode: testKitNumberWithMaxLength,
            trackingNumber: trackingNumberWithMaxLength,
            skipTrackingNumber: false,
            token,
          },
        });

        saveScannedDataPromise
          .then(() => {
            if (!isLoggedIn) {
              navigate(TEST_KITS_MANAGEMENT_BARCODE_SCAN_COMPLETED, {
                state: {
                  testKitNumber: testKitNumberWithMaxLength,
                  testKitReturnNumber: trackingNumberWithMaxLength,
                },
                replace: true,
              });
            }
          })
          .catch((error) => {
            if (!isLoggedIn) {
              if (error.message === 'Unauthorized') {
                navigate(TEST_KITS_MANAGEMENT_BARCODE_SCAN_COMPLETED, {
                  state: { invalidToken: true },
                  replace: true,
                });
              } else {
                navigate(TEST_KITS_MANAGEMENT_BARCODE_SCAN_COMPLETED, {
                  state: { unexpectedError: true },
                  replace: true,
                });
              }
            }
            console.error(
              'There was an error saving the scanned barcode',
              error,
            );
          });

        if (isLoggedIn) {
          navigate(TEST_KITS_MANAGEMENT_REGISTER_TEST_KIT, {
            state: {
              testKitNumber: testKitNumberWithMaxLength,
              testKitReturnNumber: trackingNumberWithMaxLength,
            },
          });
        }
      } else {
        navigate(TEST_KITS_MANAGEMENT_BARCODE_SCANNED, {
          state: { testKitNumber: barcode, token },
          replace: true,
        });
      }
    },
    [navigate, testKitNumber, isLoggedIn, token, saveScannedDataMutation],
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setBarcodeError(true);
    }, 30000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const skipTrackingNumber = () => {
    const testKitNumberWithMaxLength =
      testKitNumber.length > TEST_KIT_MAX_LENGTH
        ? testKitNumber.slice(0, TEST_KIT_MAX_LENGTH)
        : testKitNumber;
    const saveScannedDataPromise = saveScannedDataMutation({
      variables: {
        swabBarcode: testKitNumberWithMaxLength,
        skipTrackingNumber: true,
        token,
      },
    });

    saveScannedDataPromise
      .then(() => {
        if (!isLoggedIn) {
          navigate(TEST_KITS_MANAGEMENT_BARCODE_SCAN_COMPLETED, {
            state: {
              testKitNumber: testKitNumberWithMaxLength,
            },
            replace: true,
          });
        }
      })
      .catch((error) => {
        if (!isLoggedIn) {
          if (error.message === 'Unauthorized') {
            navigate(TEST_KITS_MANAGEMENT_BARCODE_SCAN_COMPLETED, {
              state: { invalidToken: true },
              replace: true,
            });
          } else {
            navigate(TEST_KITS_MANAGEMENT_BARCODE_SCAN_COMPLETED, {
              state: { unexpectedError: true },
              replace: true,
            });
          }
        }
        console.error('There was an error saving the scanned barcode', error);
      });

    if (isLoggedIn) {
      navigate(TEST_KITS_MANAGEMENT_REGISTER_TEST_KIT, {
        state: {
          testKitNumber: testKitNumberWithMaxLength,
          trackingNumber: null,
        },
      });
    }
  };

  if (loading && !locale) return null;

  return (
    <div className="flex flex-col fixed top-0 left-0 right-0 bottom-0 items-center justify-between bg-clc-black">
      <div className="header flex flex-row justify-between shrink-0 py-2.5 px-[15px] bg-clc-blue w-full h-auto">
        <p className="text-h3 font-semibold text-white">
          {!testKitNumber && locale?.scanTestKitBarcode}
          {testKitNumber && locale?.scanTestKitReturnBarcode}
        </p>

        {isLoggedIn && (
          <div className="w-8 h-8">
            <CloseIcon className="stroke-white" onClick={closeBarcodeScanner} />
          </div>
        )}
      </div>

      <div className="flex relative w-full h-full">
        <BarScanner onDetected={memoizedBarcodeDetected} />
      </div>
      {!hasBarcodeError && screenOrientation === 'portrait' && (
        <div
          className={`footer flex flex-row justify-center items-center shrink-0 py-2.5 px-[15px] bg-clc-blue w-full z-50 gap-2.5 ${
            testKitNumber ? 'min-h-[96px]' : 'min-h-[42px]'
          }`}
        >
          <div className="flex flex-col">
            <p
              className={`flex items-center text-h7 font-semibold text-white ${
                testKitNumber && 'min-h-[38px]'
              }`}
            >
              {locale?.placeBarcodeWithoutFlash}
            </p>

            {testKitNumber && (
              <ButtonComponent
                type="underline"
                className="text-white min-h-[38px]"
                onClick={skipTrackingNumber}
              >
                {locale?.dontHaveTestKitReturnBarcode}
              </ButtonComponent>
            )}
          </div>
        </div>
      )}
      {!hasBarcodeError && screenOrientation === 'landscape' && (
        <div
          className={`footer flex flex-row items-center shrink-0 py-2.5 px-[15px] bg-clc-blue w-full min-h-[42px] z-50 ${
            testKitNumber ? 'justify-between' : 'justify-center'
          }`}
        >
          {testKitNumber && (
            <ButtonComponent
              type="underline"
              className="w-auto text-white"
              onClick={skipTrackingNumber}
            >
              {locale?.dontHaveTestKitReturnBarcode}
            </ButtonComponent>
          )}

          <p className="text-h7 font-semibold text-white">
            {locale?.placeBarcodeWithoutFlash}
          </p>
        </div>
      )}
      {hasBarcodeError && (
        <div className="footer flex flex-row justify-start items-center shrink-0 py-2.5 px-[15px] bg-alert-negative w-full gap-2.5 min-h-[44px] z-50">
          <IconComponent
            width="w-[18px] desktop:w-6"
            height="h-[18px] desktop:h-6"
            icon={WarningIcon}
            fill={'fill-white'}
          />
          <p className="text-h7 font-medium text-white">
            {locale?.unableToDetectBarcodeWithoutFlash}
          </p>
        </div>
      )}
    </div>
  );
};
