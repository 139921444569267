import { getWeatherConfig } from 'utilities/config';

export const roundToNearest = (num: number, decimals: number) => {
  decimals = decimals || 0;
  const p = Math.pow(10, decimals);
  return Math.round((num + Number.EPSILON) * p) / p;
};

export const kelvinToCelsius = (kelvin: number) =>
  roundToNearest(kelvin - 273.15, 0);

export const kelvinToFarenheiht = (kelvin: number) =>
  roundToNearest((9 / 5) * (kelvin - 273.15) + 32, 0);

export const fetchApiData = async (url: string) => {
  const response = await fetch(url);
  if (!response.ok) {
    console.error(response);
    throw new Error(`Failed to fetch from URL: ${url}`);
  }
  return await response.json();
};

export const fetchCurrentPosition = () =>
  new Promise<{ lat: number; lon: number }>((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) =>
        resolve({
          lat: position.coords.latitude,
          lon: position.coords.longitude,
        }),
      reject,
    );
  });

export const fetchGeoData = async (zipCode: string) => {
  const { apiKey, geoApi } = getWeatherConfig();
  const countryCode = 'US';
  const geoApiUrl = `${geoApi}?zip=${zipCode},${countryCode}&appid=${apiKey}`;
  return fetchApiData(geoApiUrl);
};

export const fetchWeatherData = async (lat: number, lon: number) => {
  const { apiKey, api } = getWeatherConfig();
  const apiUrl = `${api}?lat=${lat}&lon=${lon}&appid=${apiKey}&exclude=daily,hourly,minutely`;
  return fetchApiData(apiUrl);
};

export const fetchAirPollutionData = async (lat: number, lon: number) => {
  const { apiKey, airApi } = getWeatherConfig();
  const apiUrl = `${airApi}?lat=${lat}&lon=${lon}&appid=${apiKey}`;
  return fetchApiData(apiUrl);
};
