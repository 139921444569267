import ProviderCard from 'app/my-appointments/components/select-a-provider/ProviderCard';
import { ProviderOutput, parseServiceType } from './ProviderCardProps';
import { SelectAProviderTabProp } from 'app/my-appointments/pages/SelectAProvider';
import Loader from 'components/loaderComponent';
import { useGetProvidersByPatientIdQuery } from 'graphql/generated/remote-schema-hasura';
import { useGetPage } from 'hooks/useGetPage';
import { useState } from 'react';
import { pageIds } from 'utilities/constants';
import { useAuth } from 'auth/context/AuthContext';
import { useChannelSignal, SIGNAL_CHANNELS } from 'hooks/useChannelSignal';

const MyProviders: React.FC<SelectAProviderTabProp> = ({
  onProviderSelect,
  onNoProviderFound,
}) => {
  const [providers, setProviders] = useState<ProviderOutput[]>([]);
  const { loading, refetch } = useGetProvidersByPatientIdQuery({
    fetchPolicy: 'network-only',
    onError: (error) => {
      console.error('Error getting providers', error);
      return onNoProviderFound?.();
    },
    onCompleted: (data) => {
      if (!data?.getProvidersByPatientId?.providers?.length) {
        return onNoProviderFound?.();
      }
      const providers =
        data.getProvidersByPatientId.providers.map<ProviderOutput>(
          (provider) => ({
            ...provider,
            isFavorite: false,
            accepted_appointment_types: provider.serviceOfferings.map(
              (serviceOffering) => ({
                price: serviceOffering.price,
                serviceType: parseServiceType(serviceOffering.serviceType),
              }),
            ),
          }),
        );
      setProviders(providers);
    },
  });

  const { user } = useAuth();
  useChannelSignal(
    () => refetch(),
    SIGNAL_CHANNELS.PATIENTS_OF_PROVIDERS,
    user?.uuid,
  );

  const { loading: localeLoading, data: locale } = useGetPage({
    locale: 'en',
    pageId: pageIds.SELECT_A_PROVIDER,
  });
  if (loading || localeLoading) return <Loader />;
  if (!providers.length)
    return (
      <div>
        <p className="text-med-gray font-semibold text-h6">
          {locale?.noProvidersFound}
        </p>
      </div>
    );
  return (
    <div className="grid grid-cols-1 desktop:grid-cols-3 gap-x-3 gap-y-3">
      {providers.map((provider) => (
        <ProviderCard
          onSelect={onProviderSelect}
          key={provider.id}
          providerInfo={provider}
        />
      ))}
    </div>
  );
};

export default MyProviders;
