import { appointmentRenderInfo } from 'app/my-appointments/interfaces';
import { AppointmentProps } from '../interfaces/appointment.interfaces';
import AvatarComponent from 'components/avatarComponent';
import IconComponent from 'components/iconComponent';
import { AppointmentTypeEnum } from 'graphql/generated/remote-schema-hasura';

export const Appointment: React.FC<AppointmentProps> = ({
  date,
  provider,
  type,
  price,
  locales,
}) => {
  const getAppointmentTypeIcon = (appointmentType: AppointmentTypeEnum) => {
    return appointmentRenderInfo[appointmentType].icon;
  };

  const getAppointmentTypeLocale = (appointmentType: AppointmentTypeEnum) => {
    switch (appointmentType) {
      case AppointmentTypeEnum.Chat:
        return locales.messageAppointment;
      case AppointmentTypeEnum.Email:
        return locales.messageAppointment;
      default:
        return locales.videoAppointment;
    }
  };
  return (
    <div className="max-w-[450px]">
      <div className="flex flex-row gap-2 items-center">
        <div className="flex justify-center w-6">
          <IconComponent
            height="24"
            fill="fill-med-gray"
            icon={getAppointmentTypeIcon(type)}
            width="24"
          />
        </div>
        <p className="text-charcoal-gray text-sm font-bold uppercase">
          {getAppointmentTypeLocale(type)} (${price})
        </p>
      </div>
      <div className="flex flex-row gap-2 items-center mt-1 mb-[15px]">
        <p className="text-charcoal-gray text-lg font-semibold">{date}</p>
      </div>
      <div className="flex flex-col gap-2 desktop:mt-1">
        <div className="flex">
          <AvatarComponent imageUrl={provider.profileImg} size="sm" />
          <div className="flex flex-col ml-5">
            <p className="text-charcoal-gray text-base font-semibold">
              {provider.name}, {provider.credentials}
            </p>
            <p className="text-charcoal-gray text-base font-base">
              {provider.title}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
