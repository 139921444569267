import { componentIds } from 'utilities/constants';
import { ReactComponent as UpArrowIcon } from 'assets/icons/upArrow.svg';
import InputComponent from 'components/inputComponent';
import ButtonComponent from 'components/button/buttonComponent';
import DatePicker from 'components/datePicker/DatePicker';
import { useGetComponent } from 'hooks/useGetComponent';
import { useState } from 'react';

interface TestKitsFilterProps {
  isContentVisible: boolean;
  providerName: string;
  fromDate: string;
  toDate: string;
  viewByType: string;
  viewByStatus: string;
  sortByMobile: string;
  onToggleContentVisibility: () => void;
  onProviderSearchChange: (providerSearch: string) => void;
  onFromDateChange: (fromDate: string) => void;
  onToDateChange: (toDate: string) => void;
  onViewTypeChange: (viewType: string) => void;
  onViewStatusChange: (value: string) => void;
  handleOnUpdateFilters: () => void;
  onSortByMobileChange: (sortBy: string) => void;
}

const DNATestKitFilters: React.FC<TestKitsFilterProps> = ({
  isContentVisible,
  providerName,
  fromDate,
  toDate,
  viewByType,
  viewByStatus,
  sortByMobile,
  onToggleContentVisibility,
  onProviderSearchChange,
  onFromDateChange,
  onToDateChange,
  onViewTypeChange,
  onViewStatusChange,
  handleOnUpdateFilters,
  onSortByMobileChange,
}) => {
  const {
    data: testKitsAndResultsFiltersLocale,
    loading: testKitsAndResultsFiltersLoading,
  } = useGetComponent({
    locale: 'en',
    componentId: componentIds.TEST_KITS_AND_RESULTS_FILTERS,
  });

  const [isDatepickerOpenFrom, setIsDatepickerOpenFrom] = useState(false);
  const [isDatepickerOpenTo, setIsDatepickerOpenTo] = useState(false);

  const handleOpenFromDatePicker = () => {
    setIsDatepickerOpenFrom(true);
    setIsDatepickerOpenTo(false);
  };

  const handleOpenToDatePicker = () => {
    setIsDatepickerOpenTo(true);
    setIsDatepickerOpenFrom(false);
  };

  const handleCloseDatepicker = () => {
    setIsDatepickerOpenFrom(false);
    setIsDatepickerOpenTo(false);
  };

  if (testKitsAndResultsFiltersLoading || !testKitsAndResultsFiltersLocale)
    return null;

  const viewTypeList = [
    testKitsAndResultsFiltersLocale.all,
    testKitsAndResultsFiltersLocale.dnaSkinTestSelectOption,
    testKitsAndResultsFiltersLocale.dnaVitaminTestSelectOption,
    testKitsAndResultsFiltersLocale.zrtHeavyMetalsTestSelectOption,
    testKitsAndResultsFiltersLocale.zrtHormoneTestSelectOption,
    testKitsAndResultsFiltersLocale.zrtNeurotransmittersTestSelectOption,
    testKitsAndResultsFiltersLocale.zrtInflamatoryTestSelectOption,
  ];
  const viewStatusList = [
    testKitsAndResultsFiltersLocale.all,
    testKitsAndResultsFiltersLocale.dnaTestKitStateNotReceivedSelectOption,
    testKitsAndResultsFiltersLocale.dnaTestKitStateReceivedByLabSelectOption,
    testKitsAndResultsFiltersLocale.dnaTestKitStateProviderPendingSelectOption,
    testKitsAndResultsFiltersLocale.dnaTestKitStateResultsWithProviderSelectOption,
    testKitsAndResultsFiltersLocale.dnaTestKitStateScheduleAppointmentSelectOption,
    testKitsAndResultsFiltersLocale.dnaTestKitStateCompleteSelectOption,
  ];
  const sortByList = [
    testKitsAndResultsFiltersLocale.mobileDateNewest,
    testKitsAndResultsFiltersLocale.mobileDateOldest,
    testKitsAndResultsFiltersLocale.mobileTestTypeAsc,
    testKitsAndResultsFiltersLocale.mobileTestTypeDesc,
    testKitsAndResultsFiltersLocale.mobileTestKitIDAsc,
    testKitsAndResultsFiltersLocale.mobileTestKitIDDesc,
    testKitsAndResultsFiltersLocale.mobileProviderAsc,
    testKitsAndResultsFiltersLocale.mobileProviderDesc,
    testKitsAndResultsFiltersLocale.mobileStatusAsc,
    testKitsAndResultsFiltersLocale.mobileStatusDesc,
  ];

  return (
    <div className="bg-white rounded-10">
      <div className="flex flex-col bg-base-content rounded-10 mt-[30px]">
        <div
          className={`flex items-center flex-row justify-between px-5 pt-5 cursor-pointer ${
            !isContentVisible ? 'pb-5' : 'pb-3'
          }`}
          onClick={onToggleContentVisibility}
        >
          <h4 className="text-h5 font-semibold text-dark-gray">
            {testKitsAndResultsFiltersLocale.findAndFilter}
          </h4>
          <UpArrowIcon
            className={`self-center transition-transform duration-300 ${
              !isContentVisible && 'rotate-180'
            }`}
            width={12}
            height={12}
          />
        </div>
        {isContentVisible && (
          <div className="p-5">
            <div className="border-y py-4 mb-3">
              <div className="flex flex-col desktop:flex-row desktop:gap-[30px] desktop:items-center">
                <label className="text-dark-gray font-semibold desktop:shrink-0">
                  {testKitsAndResultsFiltersLocale.searchByProviderLabel}
                </label>
                <div className="desktop:w-full mt-[5px]">
                  <InputComponent
                    type="text"
                    name="providerSearch"
                    value={providerName}
                    onChange={(e) => onProviderSearchChange(e.target.value)}
                    onKeyDown={() => handleCloseDatepicker()}
                  />
                </div>
              </div>
              <div className="flex flex-col desktop:flex-row justify-between gap-6 desktop:gap-[30px] mt-5">
                <div className="flex flex-col desktop:flex-row gap-[30px]">
                  <div className="flex flex-row items-center gap-2">
                    <span className="w-10 desktop:w-auto font-semibold">
                      {testKitsAndResultsFiltersLocale.from}
                    </span>
                    <DatePicker
                      onChange={(e) => onFromDateChange(e.target.value)}
                      value={fromDate}
                      isOpen={isDatepickerOpenFrom}
                      onOpen={() => {
                        handleOpenFromDatePicker();
                      }}
                      disableFutureDates
                    />
                  </div>
                  <div className="flex flex-row items-center gap-2">
                    <span className="w-10 desktop:w-auto font-semibold">
                      {testKitsAndResultsFiltersLocale.to}
                    </span>
                    <DatePicker
                      onChange={(e) => onToDateChange(e.target.value)}
                      value={toDate}
                      isOpen={isDatepickerOpenTo}
                      onOpen={() => {
                        handleOpenToDatePicker();
                      }}
                      disableFutureDates
                    />
                  </div>
                </div>
                <div className="flex flex-col desktop:flex-row desktop:items-center gap-2 desktop:gap-2 flex-1">
                  <div className="flex-wrap font-semibold">
                    {testKitsAndResultsFiltersLocale.byTypeSelect}
                  </div>
                  <div className="flex-1">
                    <InputComponent
                      type="select"
                      name="viewByTestType"
                      value={viewByType}
                      selectInputProps={{
                        onSelectChange: (e) => {
                          onViewTypeChange(e.target.value);
                          handleCloseDatepicker();
                        },
                        selectValues: viewTypeList,
                        fullWidth: true,
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-col desktop:flex-row desktop:items-center gap-2 desktop:gap-2 flex-1">
                  <div className="flex-wrap font-semibold">
                    {testKitsAndResultsFiltersLocale.byStatusSelect}
                  </div>
                  <div className="flex-1">
                    <InputComponent
                      type="select"
                      name="viewByStatus"
                      value={viewByStatus}
                      selectInputProps={{
                        selectValues: viewStatusList,
                        onSelectChange: (e) => {
                          onViewStatusChange(e.currentTarget.value);
                          handleCloseDatepicker();
                        },
                        fullWidth: true,
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-col desktop:flex-row desktop:items-center gap-2 desktop:gap-2 flex-1 desktop:hidden">
                  <div className="flex-wrap font-semibold">
                    {testKitsAndResultsFiltersLocale.mobileSortBy}
                  </div>
                  <div className="flex-1">
                    <InputComponent
                      type="select"
                      name="orderBy"
                      value={sortByMobile}
                      selectInputProps={{
                        selectValues: sortByList,
                        onSelectChange: (e) => {
                          onSortByMobileChange(e.currentTarget.value);
                          handleCloseDatepicker();
                        },
                        fullWidth: true,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-end mt-3">
              <ButtonComponent type="outlined" onClick={handleOnUpdateFilters}>
                {testKitsAndResultsFiltersLocale.updateResults}
              </ButtonComponent>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DNATestKitFilters;
