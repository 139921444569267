import { useEffect } from 'react';

type VisibilityChangeCallback = (isVisible: boolean) => void;

const usePageVisibility = (fn: VisibilityChangeCallback) => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        fn(false); // Tab is not visible
      } else {
        fn(true); // Tab is visible
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [fn]);
};

export default usePageVisibility;
