import { useCallback, useContext, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ApolloError } from '@apollo/client';
import {
  useGetSkintellingentTestBySessionIdSubscription,
  useUploadCapturedImageMutation,
  useGetFileUrlFromStorageMutation,
  Image_Source_Types_Enum,
} from 'graphql/generated/hasura';
import { useGetPage } from 'hooks/useGetPage';
import { SKINTELLIGENT_ERRORS, pageIds } from 'utilities/constants';

import {
  DASHBOARD,
  DASHBOARD_NEW_SCAN,
  DASHBOARD_SCAN_RESULTS,
  DASHBOARD_SCAN_COMPLETED,
  MY_DERMSCORE_NEW_SCAN,
  MY_DERMSCORE_SCAN_RESULTS,
  MY_SKIN_SCAN_COMPLETED,
} from 'utilities/routes';
import { useIsMobile } from 'hooks/useIsMobile';
import { AuthContext, AuthContextType } from 'auth/context/AuthContext';
import { mapFhirSkintelligentTest } from 'utilities/functions';
import { useGetFhirSkintellingentTestBySessionIdLazyQuery } from 'graphql/generated/remote-schema-hasura';
import Loader from 'components/loaderComponent';
import Modal from 'components/modal/modalComponent';

export const AnalyzingPhoto = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useIsMobile();
  const { isLoggedIn } = useContext<AuthContextType>(AuthContext);
  const capturedPhoto = location.state?.image || '';
  const filename = location.state?.filename || '';
  const submittedSessionId = location.state?.submittedSessionId || '';
  const token = location.state?.token || '';
  const submitPhotoRef = useRef(false);
  const [getFileUrlFromStorage, { data: fileUrl }] =
    useGetFileUrlFromStorageMutation();
  const [uploadCapturedImageMutation, { data: uploadedImageData }] =
    useUploadCapturedImageMutation();
  const sessionId = uploadedImageData?.UploadCapturedImage?.sessionId;
  const { data: testData } = useGetSkintellingentTestBySessionIdSubscription({
    skip: !(sessionId || submittedSessionId || isLoggedIn),
    variables: {
      sessionId: sessionId || submittedSessionId || '',
    },
  });
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.ANALYZE_PHOTO,
  });
  const isDashboard = location.pathname.includes(DASHBOARD);
  const newScanUrl = isDashboard ? DASHBOARD_NEW_SCAN : MY_DERMSCORE_NEW_SCAN;
  const scanResultsUrl = isDashboard
    ? DASHBOARD_SCAN_RESULTS
    : MY_DERMSCORE_SCAN_RESULTS;
  const scanCompletedUrl = isDashboard
    ? DASHBOARD_SCAN_COMPLETED
    : MY_SKIN_SCAN_COMPLETED;

  const [fhirGetSkintelligentTestBySessionId, { data: fhirSkintelligentTest }] =
    useGetFhirSkintellingentTestBySessionIdLazyQuery({});

  useEffect(() => {
    if (
      testData?.skintelligent_tests.length &&
      testData?.skintelligent_tests[0].result_processed
    ) {
      fhirGetSkintelligentTestBySessionId({
        variables: {
          sessionId: testData?.skintelligent_tests[0].session_id,
        },
        onCompleted: (data) => {
          const test =
            data.getFHIRSkintelligentTestBySessionIdAndUserId
              .skintelligent_test;
          if (test.report_id && test.result_image_id) {
            getFileUrlFromStorage({
              variables: {
                fileId: test.result_image_id,
              },
            });
          }
        },
        fetchPolicy: 'network-only',
      });

      if (testData?.skintelligent_tests[0].errors) {
        const responseErrorType: string =
          testData?.skintelligent_tests[0].errors?.type || '';

        switch (responseErrorType) {
          case SKINTELLIGENT_ERRORS.SKINTELLIGENT_NOT_FACE_DETECTED:
            navigate(newScanUrl, {
              state: {
                noFaceFound: true,
              },
              replace: true,
            });
            break;
          case SKINTELLIGENT_ERRORS.SKINTELLIGENT_FACE_SEGMENTATION_ERROR:
            navigate(newScanUrl, {
              state: {
                faceSegmentationError: true,
              },
              replace: true,
            });
            break;
          default:
            navigate(newScanUrl, {
              state: {
                fileError: true,
              },
              replace: true,
            });
        }
      }
    }
  }, [
    testData,
    navigate,
    getFileUrlFromStorage,
    fhirGetSkintelligentTestBySessionId,
    newScanUrl,
  ]);

  useEffect(() => {
    if (fileUrl?.GetSignUrlFormStorage?.url) {
      if (
        fhirSkintelligentTest &&
        fhirSkintelligentTest.getFHIRSkintelligentTestBySessionIdAndUserId &&
        fhirSkintelligentTest.getFHIRSkintelligentTestBySessionIdAndUserId
          .skintelligent_test
      ) {
        navigate(scanResultsUrl, {
          state: {
            scan: mapFhirSkintelligentTest(
              fhirSkintelligentTest
                ?.getFHIRSkintelligentTestBySessionIdAndUserId
                .skintelligent_test,
            ),
            fileUrl: fileUrl?.GetSignUrlFormStorage?.url,
          },
          replace: true,
        });
      }
    }
  }, [
    fileUrl,
    navigate,
    testData?.skintelligent_tests,
    fhirSkintelligentTest,
    scanResultsUrl,
  ]);

  const submitPhoto = useCallback(async () => {
    try {
      await uploadCapturedImageMutation({
        variables: {
          image: capturedPhoto,
          filename,
          source: isMobile
            ? Image_Source_Types_Enum.Mobile
            : Image_Source_Types_Enum.Pc,
          token,
        },
      });

      if (!isLoggedIn) {
        navigate(scanCompletedUrl, {
          state: { token },
          replace: true,
        });
      }
    } catch (error) {
      if (error instanceof ApolloError) {
        if (isLoggedIn) {
          navigate(newScanUrl, {
            state: {
              fileError: true,
            },
            replace: true,
          });
        } else {
          if (error.message === 'Unauthorized') {
            navigate(scanCompletedUrl, {
              state: { invalidToken: true },
              replace: true,
            });
          } else {
            navigate(scanCompletedUrl, {
              state: { unexpectedError: true },
              replace: true,
            });
          }
        }
      }
    }
  }, [
    capturedPhoto,
    filename,
    isMobile,
    isLoggedIn,
    token,
    navigate,
    uploadCapturedImageMutation,
    scanCompletedUrl,
    newScanUrl,
  ]);

  useEffect(() => {
    if (!submitPhotoRef.current && !submittedSessionId) {
      submitPhoto();
      submitPhotoRef.current = true;
    }
  }, [submitPhoto, submittedSessionId]);

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate(newScanUrl, {
        state: { fileError: true },
        replace: true,
      });
    }, 120000);

    return () => {
      clearTimeout(timer);
    };
  }, [navigate, newScanUrl]);

  const handleCancel = () => {
    navigate(DASHBOARD, { replace: true });
  };

  if (loading && !locale) return null;

  return (
    <Modal
      isOpen={true}
      title={locale.analyzeYourPhoto}
      disableMobileClose={!isLoggedIn}
      onClose={handleCancel}
    >
      <div className="flex flex-col gap-[30px] pt-[60px] desktop:py-5">
        <Loader />
        <h3 className="text-h3 text-dark-gray font-semibold text-center">
          {locale?.analyzing}
        </h3>
      </div>
    </Modal>
  );
};
