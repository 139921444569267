import { ModalButtonProps } from 'components/modal/modal.interfaces';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import { genericActionsIds, pageIds } from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';
import { Link } from 'react-router-dom';
import { useEffect, useState, ChangeEvent } from 'react';
import { PatientOutlineIcon } from 'assets/icons/PatientOutlineIcon';
import { PreworkOutlineIcon } from 'assets/icons/PreworkOutlineIcon';
import { PreworkSolidIcon } from 'assets/icons/PreworkSolidIcon';
import { ChatSolidIcon } from 'assets/icons/ChatSolidIcon';
import { ChatOutlineIcon } from 'assets/icons/ChatOutlineIcon';
import { PatientSolidIcon } from 'assets/icons/PatientSolidIcon';
import { ChatMessage, Tabs } from 'app/my-appointments/components/interfaces';
import { ChatComponent } from './ChatComponent';
import { PreworkComponent } from './PreworkComponent';
import { PatientComponent } from './PatientComponent';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import useFormValidation, { ValidationRules } from 'hooks/useFormValidation';
import Modal from 'components/modal/modalComponent';
import CollapseTab from './CollapseTab';
import InputComponent from 'components/inputComponent';
import IconComponent from 'components/iconComponent';
import {
  GetProviderPatientInfoQuery,
  useAppendEncounterNotesToFhirAppointmentMutation,
  useGetAppointmentByCodexAppointmentIdQuery,
  useGetAppointmentMessageListByAppointmentCodexIdQuery,
  useGetProviderFullPatientInfoLazyQuery,
} from 'graphql/generated/remote-schema-hasura';

import Alert from 'components/alertComponent';
import { createModal } from 'layout/createModal';
import { useModal } from 'layout/useModal';
import { VirtualPharmaciesModal } from 'app/my-patients/components/VirtualPharmaciesModal';
import { AppointmentChatMessage } from 'graphql/generated/hasura';
import Loader from 'components/loaderComponent';
import useParseChatMessages from 'hooks/useParseChatMessages';

export type SummaryFormData = {
  appointmentNotes: string;
  summaryDiagnosis: string;
  followUpRecommendations: string;
};

const CompleteMyVisitSummaryModal = createModal<
  { appointmentId: string },
  void
>(
  'complete-my-visit-summary',
  ({ isOpen, state, close }) => {
    const { data: locale, loading } = useGetPage({
      locale: 'en',
      pageId: pageIds.COMPLETE_MY_VISIT_SUMMARY_MODAL,
    });

    const appointmentId = state.appointmentId;

    const [patientData, setPatientData] =
      useState<GetProviderPatientInfoQuery | null>(null);
    const [patientId, setPatientId] = useState<string | null>();

    const [getProviderPatientData, { loading: providerPatientLoading }] =
      useGetProviderFullPatientInfoLazyQuery({
        onCompleted: (data) => {
          setPatientData(data);
        },
      });

    const [parseMessages, chatMediaLoading] =
      useParseChatMessages(appointmentId);

    const { data: appointmentData, loading: appointmentDataLoading } =
      useGetAppointmentByCodexAppointmentIdQuery({
        fetchPolicy: 'network-only',
        variables: { appointmentCodexId: appointmentId },
        skip: !appointmentId,
        onCompleted: (data) => {
          if (data.getFHIRAppointmentByCodexId.appointment?.codexPatientId) {
            setPatientId(
              data.getFHIRAppointmentByCodexId.appointment.codexPatientId,
            );
            getProviderPatientData({
              variables: {
                patientCodexId:
                  data.getFHIRAppointmentByCodexId.appointment.codexPatientId,
              },
            });
            const providerNotes =
              data?.getFHIRAppointmentByCodexId?.appointment?.encounterNotes
                ?.providerNotes;
            if (providerNotes) {
              setFormData((prev) => ({
                ...prev,
                appointmentNotes: providerNotes,
              }));
            }
          }
        },
      });

    // enable error reporting on any of the fetchs
    const validationRules: ValidationRules = {
      summaryDiagnosis: [
        {
          validator: (value) => value.length > 0,
          message: locale?.fieldRequired,
        },
      ],
      followUpRecommendations: [
        {
          validator: (value) => value.length > 0,
          message: locale?.fieldRequired,
        },
      ],
    };

    const [active, setActive] = useState(Tabs.Patient);
    const [formData, setFormData] = useState<SummaryFormData>({
      appointmentNotes: '',
      summaryDiagnosis: '',
      followUpRecommendations: '',
    });
    const [formDataSubmitted, setFormDataSubmitted] = useState(false);
    const [showAppendEncounterAlert, setShowAppendEncounterAlert] =
      useState(false);
    const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);

    const [
      appendEncounterNotesToFhirAppointmentMutation,
      { loading: encounterAppointmentLoading },
    ] = useAppendEncounterNotesToFhirAppointmentMutation({
      variables: {
        codexAppointmentId: appointmentId,
        providerNotes: formData.appointmentNotes,
        followupRecommendations: formData.followUpRecommendations,
        providerSummary: formData.summaryDiagnosis,
      },
      onCompleted: () => {
        if (showAppendEncounterAlert) {
          setShowAppendEncounterAlert(false);
        }
      },
      onError: () => {
        setShowAppendEncounterAlert(true);
      },
    });

    const { loading: chatDataLoading } =
      useGetAppointmentMessageListByAppointmentCodexIdQuery({
        variables: { appointmentCodexId: appointmentId },
        onCompleted: async (data) => {
          if (
            data.getAppointmentMessageListByAppointmentCodexId.chat &&
            data.getAppointmentMessageListByAppointmentCodexId.chat.messages &&
            data.getAppointmentMessageListByAppointmentCodexId.chat.messages
              .length > 0
          ) {
            const messagesParsed = await parseMessages(
              data.getAppointmentMessageListByAppointmentCodexId.chat
                .messages as AppointmentChatMessage[],
            );
            setChatMessages(messagesParsed ?? []);
          }
        },
        onError: (error) => {
          console.log('An error happened', error);
        },
      });

    const { errors, validateForm } = useFormValidation(validationRules);

    const { data: genericAction, loading: genericActionLoading } =
      useGetGenericActions({
        locale: 'en',
        genericActionId: [
          genericActionsIds.SUBMIT,
          genericActionsIds.SAVE_FOR_LATER,
          genericActionsIds.ADD_MORE_MEDICATIONS,
        ],
      });

    const handleSubmit = () => {
      if (!handleValidation(formData)) {
        return;
      }

      appendEncounterNotesToFhirAppointmentMutation();
      setFormDataSubmitted(true);
    };

    const [openPrescriptionModal] = useModal(VirtualPharmaciesModal);

    const handlePrescribeMedication = () => {
      const patientCodexId =
        appointmentData?.getFHIRAppointmentByCodexId.appointment
          ?.codexPatientId;
      const appointmentCodexId =
        appointmentData?.getFHIRAppointmentByCodexId.appointment
          ?.appointmentCodexId;
      const encounterId =
        appointmentData?.getFHIRAppointmentByCodexId.appointment?.encounterId;
      if (!patientCodexId || !(appointmentCodexId || encounterId)) return;
      openPrescriptionModal({
        patientId: patientCodexId,
        appointmentId: appointmentCodexId,
        encounterId: encounterId ?? undefined,
      });
    };

    const handleOnToggle = (tab: number) => {
      setActive((prev) => (prev === tab ? -1 : tab));
    };

    const handleValidation = (userData: SummaryFormData) => {
      const formData = JSON.parse(JSON.stringify(userData));
      return validateForm(formData);
    };

    const handleOnTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
      const { name, value } = e.target;
      const nextData = { ...formData, [name]: value };
      setFormData(nextData);
      handleValidation(nextData);
    };

    useEffect(() => {
      if (formDataSubmitted && !encounterAppointmentLoading) {
        setFormDataSubmitted(false);
        close();
      }
    }, [encounterAppointmentLoading, formDataSubmitted, close]);

    if (
      loading ||
      !locale ||
      genericActionLoading ||
      !genericAction ||
      providerPatientLoading ||
      appointmentDataLoading
    ) {
      return null;
    }
    const Buttons: ModalButtonProps[] = [
      {
        label: genericAction?.[genericActionsIds.SUBMIT].submit,
        onClick: handleSubmit,
        disabled: encounterAppointmentLoading,
        showSpinner: true,
      },
      {
        label: locale?.prescribeMedication,
        onClick: handlePrescribeMedication,
        disabled: encounterAppointmentLoading,
        type: 'outlined-transparent',
      },
    ];

    const chatLoading = chatMediaLoading || chatDataLoading;

    return (
      <Modal
        isOpen={isOpen}
        title={locale.title}
        modalDesktopWidth="desktop:max-w-[960px]"
        disableMobileGrow={true}
        buttons={Buttons}
        onClose={close}
      >
        {showAppendEncounterAlert && (
          <Alert type="warning" text={locale.errorAppendEncounter} />
        )}
        <div className="flex flex-col gap-5 desktop:gap-[60px] desktop:flex-row">
          <div className="flex flex-col">
            <div className="flex flex-col w-full desktop:w-[360px] bg-white rounded-10 overflow-hidden">
              <CollapseTab
                active={active === Tabs.Patient}
                activeIcon={PatientSolidIcon}
                icon={PatientOutlineIcon}
                label={locale.patientTab}
                onClick={() => handleOnToggle(Tabs.Patient)}
              >
                <PatientComponent
                  patient={patientData}
                  locale={locale}
                  patientId={patientId}
                />
              </CollapseTab>

              <CollapseTab
                active={active === Tabs.Prework}
                activeIcon={PreworkSolidIcon}
                icon={PreworkOutlineIcon}
                label={locale.preworkTab}
                onClick={() => handleOnToggle(Tabs.Prework)}
              >
                <PreworkComponent
                  prework={appointmentData}
                  symptomsLocation={
                    appointmentData?.getFHIRAppointmentByCodexId.appointment
                      ?.symptomsLocation
                  }
                  locale={locale}
                />
              </CollapseTab>

              {chatMessages.length > 0 && (
                <CollapseTab
                  active={active === Tabs.Chat}
                  activeIcon={ChatSolidIcon}
                  borderBottom={active === Tabs.Chat}
                  contentBorderBottom={false}
                  icon={ChatOutlineIcon}
                  label={locale.chatTab}
                  onClick={() => handleOnToggle(Tabs.Chat)}
                >
                  {chatLoading ? (
                    <Loader />
                  ) : (
                    <ChatComponent
                      loggedUser={null}
                      locale={locale.chatMessage}
                      messages={chatMessages}
                    />
                  )}
                </CollapseTab>
              )}
            </div>
          </div>
          <div className="flex flex-col py-2.5 gap-[30px] desktop:flex-1">
            <div className="flex flex-col gap-[7px]">
              <p className="font-semibold">{locale.inAppointmentNotes}</p>
              <div className="flex flex-row gap-1">
                <Link to="">
                  <IconComponent
                    width="w-5"
                    height="h-5"
                    icon={InfoIcon}
                    fill="fill-clc-blue"
                  />
                </Link>
                <span className="text-sm text-med-gray">
                  {locale.theseNotes}
                </span>
              </div>
              <InputComponent
                errorStatus={!!errors.appointmentNotes}
                errorMsgWithIcon={errors.appointmentNotes}
                value={formData.appointmentNotes}
                type="text-area"
                textAreaProps={{
                  onTextAreaChange: handleOnTextAreaChange,
                  name: 'appointmentNotes',
                }}
              />
            </div>

            <div className="flex flex-col">
              <p className="font-semibold">{locale.pleaseFillOut}</p>
              <p className="text-sm text-clc-red font-bold">
                {locale.required}
              </p>
            </div>

            <div className="flex flex-col">
              <p className="font-semibold">
                {locale.yourSummaryDiagnosis}
                <span className="text-clc-red">*</span>
              </p>
              <InputComponent
                errorStatus={!!errors.summaryDiagnosis}
                errorMsgWithIcon={errors.summaryDiagnosis}
                textAreaProps={{
                  onTextAreaChange: handleOnTextAreaChange,
                  name: 'summaryDiagnosis',
                }}
                type="text-area"
                value={formData.summaryDiagnosis}
              />
            </div>

            <div className="flex flex-col">
              <p className="font-semibold">
                {locale.followUpRecommendations}
                <span className="text-clc-red">*</span>
              </p>
              <InputComponent
                errorStatus={!!errors.followUpRecommendations}
                errorMsgWithIcon={errors.followUpRecommendations}
                textAreaProps={{
                  onTextAreaChange: handleOnTextAreaChange,
                  name: 'followUpRecommendations',
                }}
                type="text-area"
                value={formData.followUpRecommendations}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  },
  true,
);

export default CompleteMyVisitSummaryModal;
