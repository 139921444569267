import { useState, useEffect } from 'react';
import { DesktopBreakpoint } from 'utilities/constants';

export const useIsMobile = (): boolean => {
  const [isMobile, setIsMobile] = useState(screen.width < DesktopBreakpoint);

  const handleResize = (): void => {
    setIsMobile(screen.availWidth < DesktopBreakpoint);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobile;
};
