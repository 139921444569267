import { ReactComponent as InfoNewIcon } from 'assets/icons/info-new.svg';
import IconComponent from 'components/iconComponent';
import InputComponent from 'components/inputComponent';

interface MyNotesProps {
  locale: {
    subtitle: string;
    description: string;
  };
  value: string;
  onChange: (value: string) => void;
}

export const MyNotesComponent: React.FC<MyNotesProps> = ({
  locale,
  value,
  onChange,
}) => {
  return (
    <div className="flex flex-col gap-5 text-base h-full">
      <p className="text-h6 text-dark-gray font-semibold">{locale.subtitle}</p>
      <div className="flex flex-row gap-1">
        <IconComponent
          width="w-5"
          height="h-5"
          icon={InfoNewIcon}
          fill="med-gray"
        />
        <p className="text-h7 text-med-gray font-semibold">
          {locale.description}
        </p>
      </div>
      <div className="h-full pb-4">
        <InputComponent
          testID="myNotes"
          type="text-area"
          name="myNotes"
          value={value}
          textAreaProps={{
            height: 'h-full',
            containerHeight: 'h-full',
            onTextAreaChange: (e) => {
              onChange(e.target.value);
            },
          }}
        />
      </div>
    </div>
  );
};
