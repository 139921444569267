import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useGetComponent } from 'hooks/useGetComponent';
import { componentIds } from 'utilities/constants';
import { getFullScriptConfig } from 'utilities/config';
import { Fullscript } from '@fullscript/fullscript-js';
import {
  useGetFhirProviderByRequestIdQuery,
  useGetFullscriptSessionGrantLazyQuery,
  useOnTreatmentPlanActivatedMutation,
} from 'graphql/generated/remote-schema-hasura';
import { FullscriptAuthLinkState } from 'app/fullscript/fullscript.interface';

type FullScriptEnvironments = 'us' | 'ca' | 'us-snd' | 'ca-snd';

interface SessionGrantData {
  sessionGrant: string;
  fullscriptId: string;
}

export const FullScriptIframe: React.FC<{
  appointmentCodexId?: string;
  patientId: string;
}> = ({ appointmentCodexId, patientId }) => {
  const fullscriptClient = useMemo(
    () =>
      Fullscript({
        publicKey: getFullScriptConfig().fullScriptClientId,
        env: getFullScriptConfig().fullScriptEnv as FullScriptEnvironments,
      }),
    [],
  );

  const authorizationUrl = useMemo(
    () => getFullScriptConfig().authorizationUrl,
    [],
  );

  const [sessionGrantData, setSessionData] = useState<SessionGrantData | null>(
    null,
  );

  const [getSessionGrant] = useGetFullscriptSessionGrantLazyQuery({
    fetchPolicy: 'network-only',
  });

  const [onTreatmentPlanActivated] = useOnTreatmentPlanActivatedMutation({});

  const { data: providerData } = useGetFhirProviderByRequestIdQuery({
    fetchPolicy: 'network-only',
  });

  const { data: locale, loading } = useGetComponent({
    locale: 'en',
    componentId: componentIds.VIRTUAL_PHARMACY_MODAL,
  });

  useEffect(() => {
    if (!providerData || !patientId) return;
    const fullscriptProviderId =
      providerData?.getFHIRProviderByRequestId.providers[0]?.fullscriptId;
    if (!fullscriptProviderId) {
      const state: FullscriptAuthLinkState = {
        patientId: patientId,
      };
      window.location.href = `${authorizationUrl}&state=${encodeURI(
        JSON.stringify(state),
      )}`;
      return;
    }
    (async () => {
      const result = await getSessionGrant({
        variables: {
          patientCodexId: patientId as string,
        },
        fetchPolicy: 'network-only',
      });
      if (result?.data?.getSessionGrant) {
        setSessionData({
          sessionGrant: result?.data?.getSessionGrant.sessionGrant,
          fullscriptId: result?.data?.getSessionGrant.patientFullscriptId,
        });
      }
    })();
  }, [getSessionGrant, patientId, providerData, authorizationUrl]);

  const handleTreatmentPlanActivated = useCallback(
    (treatmentPlanId: string) => {
      onTreatmentPlanActivated({
        variables: {
          patientCodexId: patientId as string,
          treatmentPlanId,
          appointmentCodexId,
        },
      });
    },
    [appointmentCodexId, onTreatmentPlanActivated, patientId],
  );

  useEffect(() => {
    if (sessionGrantData) {
      const treatmentPlanFeature = fullscriptClient.create('treatmentPlan', {
        secretToken: sessionGrantData.sessionGrant,
        patient: {
          id: sessionGrantData.fullscriptId,
        },
      });
      treatmentPlanFeature.on('treatmentPlan.activated', (event) => {
        handleTreatmentPlanActivated(event.data.treatmentPlan.id);
      });
      treatmentPlanFeature.mount('treatment-plan-container');
    }
  }, [handleTreatmentPlanActivated, sessionGrantData, fullscriptClient]);

  if (loading && !locale) return null;

  return (
    <div>
      <div
        className={`w-full h-full min-h-[830px] ${
          sessionGrantData ? 'flex' : 'hidden'
        }`}
      >
        <div
          style={{ width: '1050px', height: '100%' }}
          id="treatment-plan-container"
        />
      </div>
    </div>
  );
};
