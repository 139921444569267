import { SFrame as SFrameClientDefault } from 'sframe';

const create = async (senderId, deps = {}) => {
  const SFrameClient = deps.SFrameClient || SFrameClientDefault;

  return SFrameClient.createClient(senderId, { skipVp8PayloadHeader: true });
};

const LegacySFrameClient = {
  create,
};

export default LegacySFrameClient;
