import { ModalButtonProps } from 'components/modal/modal.interfaces';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import {
  CURRENT_LEGAL_DOCUMENTS_URL,
  componentIds,
  genericActionsIds,
} from 'utilities/constants';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from 'components/modal/modalComponent';
import { useGetComponent } from 'hooks/useGetComponent';
import InputComponent from 'components/inputComponent';
import { EDIT_APPOINTMENT } from 'utilities/routes';
import { useState } from 'react';

export const TermsOfUseModal = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isTermsOfUseAppointmentChecked, setisTermsOfUseAppointmentChecked] =
    useState(false);
  const [isDontShowThisAgainChecked, setIsDontShowThisAgainChecked] =
    useState(false);

  const { data: termsOfUseAppointment, loading: termsOfUseAppointmentLoading } =
    useGetComponent({
      locale: 'en',
      componentId: componentIds.TERMS_OF_USE_APPOINTMENTS,
    });

  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.CANCEL],
    });

  const handleClose = () => navigate(-1);

  const acceptTermsOfUseAppointmentHandler = () => {
    setisTermsOfUseAppointmentChecked(true);
    //TODO: COD-2049 add logic for terms of use accepted
  };

  const dontShowThisAgainHandler = () => {
    setIsDontShowThisAgainChecked(true);
    // TODO: COD-2429 add not show this again logic
  };

  const submitEditAppointmentHandler = () => {
    navigate(EDIT_APPOINTMENT, { state: location?.state });
  };

  const Buttons: ModalButtonProps[] = [
    {
      label: 'Submit',
      floatLeft: false,
      fontWeightUnderline: 'text-clc-blue underline uppercase',
      className: 'bg-clc-blue text-white',
      onClick: submitEditAppointmentHandler,
    },
    {
      label: genericAction?.[genericActionsIds.CANCEL].cancel,
      onClick: handleClose,
      paddingX: 'px-[3.3rem]',
      type: 'underline',
    },
  ];

  const message = {
    parts: termsOfUseAppointment?.acceptTermsOfUseMessage.split(
      '{{privacyPolicyLink}}',
    ),
    link: (
      <a
        className="text-clc-blue"
        href={CURRENT_LEGAL_DOCUMENTS_URL}
        target="_blank"
      >
        {termsOfUseAppointment?.anchorTagText}
      </a>
    ),
  };

  if (
    termsOfUseAppointmentLoading ||
    !termsOfUseAppointment ||
    genericActionLoading ||
    !genericAction
  ) {
    return null;
  }

  return (
    <Modal
      isOpen={true}
      title={termsOfUseAppointment?.title}
      modalDesktopWidth="desktop:max-w-[960px]"
      disableMobileGrow={true}
      buttons={Buttons}
      onClose={handleClose}
      modalContainerCustomClass="w-full"
      contentPaddingX="px-10"
    >
      <div className="flex flex-col gap-5 desktop:gap-[60px] desktop:flex-row">
        <div className="flex flex-col py-1 desktop:max-h-[450px] desktop:flex-1 gap-[20px]">
          <div>
            <p className="text-base font-bold text-gray-700">
              {termsOfUseAppointment?.effectiveDate}
            </p>
            <p className="text-base font-bold text-gray-700">
              {termsOfUseAppointment?.lastRevised}
            </p>
          </div>
          <div>
            <p className="text-base text-med-gray">
              {termsOfUseAppointment?.message}
            </p>
          </div>
          <div>
            <div className="flex items-center mb-1">
              <InputComponent
                type="checkbox"
                name="appointmentTermsOfUse"
                checkboxProps={{
                  checked: isTermsOfUseAppointmentChecked,
                  isAlternativeCheckboxColor: true,
                  onCheckboxChange: acceptTermsOfUseAppointmentHandler,
                }}
              />
              <div />
              <p className="font-semibold text-med-gray text-sm ml-[10px]">
                {message.parts[0]}
                <span>{message.link}</span>
                {message.parts[1]}
                <span className="text-clc-red">*</span>
              </p>
            </div>
            <div className="flex items-center mb-1">
              <InputComponent
                type="checkbox"
                name="dontShowThisAgain"
                checkboxProps={{
                  checked: isDontShowThisAgainChecked,
                  isAlternativeCheckboxColor: true,
                  onCheckboxChange: dontShowThisAgainHandler,
                }}
              />
              <p className="font-semibold text-med-gray text-sm ml-[10px]">
                {termsOfUseAppointment?.doNotShowThisAgain}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
