import { ReactComponent as InfoIcon } from 'assets/icons/weatherIcons/question.svg';
import { ReactComponent as Indicator } from 'assets/icons/weatherIcons/indicator.svg';
import { UVIndexIcon } from 'assets/icons/weatherIcons/UVIndexIcon';
import IconComponent from 'components/iconComponent';
import { Link } from 'react-router-dom';
import { DASHBOARD } from 'utilities/routes';

interface UVIndexProps {
  id: string;
  uvIndex: number;
  borderX?: boolean;
  locale: {
    uvIndexTitle: string;
    uvIndex: {
      low: string;
      moderate: string;
      high: string;
      veryHigh: string;
      extreme: string;
    };
  };
}

const UVIndex: React.FC<UVIndexProps> = ({ id, uvIndex, borderX, locale }) => {
  const getUVIndexCategory = (uvIndex: number) => {
    if (uvIndex >= 0 && uvIndex <= 2) {
      return locale.uvIndex.low;
    } else if (uvIndex > 2 && uvIndex <= 5) {
      return locale.uvIndex.moderate;
    } else if (uvIndex > 5 && uvIndex <= 7) {
      return locale.uvIndex.high;
    } else if (uvIndex > 7 && uvIndex <= 10) {
      return locale.uvIndex.veryHigh;
    } else if (uvIndex > 10) {
      return locale.uvIndex.extreme;
    }

    return null;
  };

  return (
    <div
      className={`flex flex-col flex-1 items-center ${
        borderX ? 'border-x' : ''
      }`}
    >
      <div className="flex flex-row gap-1">
        <span className="font-semibold">{locale.uvIndexTitle}</span>
        <Link to={`${DASHBOARD}?uvi-info=true`}>
          <IconComponent
            width="w-5"
            height="h-5"
            icon={InfoIcon}
            fill="fill-clc-blue"
          />
        </Link>
      </div>
      <div className="flex flex-col items-center justify-center gap-7 min-h-[160px]">
        <div className="text-[48px]">{uvIndex}</div>
        <div className="flex flex-col items-center gap-2 relative">
          <IconComponent
            width="w-[200px]"
            height="h-[30px]"
            icon={() => <UVIndexIcon id={id} />}
            fill="none"
          />
          <div
            className="absolute top-[-10px] ml-[-7px]"
            style={{
              left: uvIndex > 10 ? '100%' : `${(uvIndex / 10) * 100}%`,
            }}
          >
            <IconComponent
              width="w-[14px]"
              height="h-[6px]"
              icon={Indicator}
              fill="none"
            />
          </div>
          <p className="text-sm text-med-gray">
            {uvIndex} = {getUVIndexCategory(uvIndex)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default UVIndex;
