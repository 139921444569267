import { useEffect, useMemo, useState } from 'react';
import {
  useGetLegalDocumentsQuery,
  GetLegalDocumentsQueryVariables,
  LegalDocument,
} from '../../graphql/generated/strapi';
import { useStrapi } from 'apollo/LocalesProvider';

interface UseGetLegalDocuments {
  legalDocuments?: LegalDocument[];
  loading: boolean;
  error?: Error;
}

export const useGetLegalDocuments = (
  variables: GetLegalDocumentsQueryVariables,
): UseGetLegalDocuments => {
  const { client } = useStrapi();
  const [legalDocuments, setLegalDocuments] = useState<LegalDocument[]>();

  const { data, loading, error } = useGetLegalDocumentsQuery({
    variables,
    client: client,
    fetchPolicy: 'cache-first',
  });

  useEffect(() => {
    if (data && data.legalDocuments) {
      const legalDocuments = data.legalDocuments.data;
      const formattedLegalDocuments = legalDocuments.map(
        (document) =>
          ({
            legalDocumentId: document.attributes?.legalDocumentId,
            legalDocumentName: document.attributes?.legalDocumentName,
            legalDocumentType: document.attributes?.legalDocumentType,
            contentWeb: document.attributes?.contentWeb,
            contentMobile: document.attributes?.contentMobile,
          } as LegalDocument),
      );
      setLegalDocuments(formattedLegalDocuments);
    }
  }, [data]);

  const pagesMemoized = useMemo(
    () => ({ legalDocuments, loading, error }),
    [legalDocuments, loading, error],
  );

  return pagesMemoized;
};
