import { useMemo, useState } from 'react';
import Loader from 'components/loaderComponent';
import RecentSharedMedias from '../components/RecentSharedMedias';
import SharedMediaTable from '../components/SharedMediaTable';
import { useGetFhirPatientbyMediaCodexIdParameterQuery } from 'graphql/generated/remote-schema-hasura';

import {
  LIST_BATCH_SIZE,
  PARAM_MODALS_IDENTIFIERS,
  componentIds,
  pageIds,
} from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';
import { MediaType, SharedMedia } from '../interfaces/interfaces';
import { useGetComponent } from 'hooks/useGetComponent';
import { useParams } from 'react-router-dom';
import { parseMediaToSharedMedia } from 'utilities/functions';
import { useModalParams } from 'components/modal/useModalManager';
import { SharedMediaModal } from 'app/my-patients/components/SharedMediaModal';
import { SIGNAL_CHANNELS, useChannelSignal } from 'hooks/useChannelSignal';

const LIMIT_RECENT_MEDIA = 3;
const ORDER_RECENT_MEDIA = 0;

export const SharedMediaPage: React.FC = () => {
  const { patientId } = useParams();
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.MY_PATIENTS,
  });
  const { data: mediaLocale } = useGetComponent({
    locale: 'en',
    componentId: componentIds.SHARED_MEDIA_LIBRARY,
  });

  const { isOpen: isMediaModalOpen } = useModalParams(
    PARAM_MODALS_IDENTIFIERS.SHARED_MEDIA_MODAL_ID,
  );

  const [fromDate, setFromDate] = useState<string | undefined>();
  const [toDate, setToDate] = useState<string | undefined>();
  const [type, setType] = useState<MediaType | null>(null);
  const [sharedMedia, setSharedMedia] = useState<SharedMedia[]>([]);
  const [recentMedia, setRecentMedia] = useState<SharedMedia[]>([]);
  const [limit, setLimit] = useState<number>(LIST_BATCH_SIZE);
  const [total, setTotal] = useState<number>(0);
  const [orderBy, setOrderBy] = useState<string>();

  const orderByNumber: number | undefined =
    orderBy === mediaLocale?.mostRecentSortFiler
      ? 0
      : orderBy === mediaLocale?.oldestSortFilter
      ? 1
      : undefined;

  const [filterFromDate, filterToDate] = useMemo<
    [Date | undefined, Date | undefined]
  >(() => {
    const beginningOfTheDay = (!!fromDate && new Date(fromDate)) || undefined;
    beginningOfTheDay?.setHours(0, 0, 0, 0);

    const endOfTheDay = (!!toDate && new Date(toDate)) || undefined;
    endOfTheDay?.setHours(23, 59, 59, 999);
    return [beginningOfTheDay, endOfTheDay];
  }, [fromDate, toDate]);

  const { loading: sharedMediaLoading, refetch: refetchShared } =
    useGetFhirPatientbyMediaCodexIdParameterQuery({
      variables: {
        patientCodexId: patientId ?? '',
        limit,
        from: filterFromDate?.toISOString(),
        to: filterToDate?.toISOString(),
        sort: orderByNumber,
        type: type,
      },
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setTotal(data.getFHIRPatientbyMediaCodexIDParameter.total);
        setSharedMedia(
          parseMediaToSharedMedia(
            data.getFHIRPatientbyMediaCodexIDParameter.media,
          ),
        );
      },
    });

  const { loading: recentMediaLoading, refetch: refetchRecent } =
    useGetFhirPatientbyMediaCodexIdParameterQuery({
      variables: {
        patientCodexId: patientId ?? '',
        limit: LIMIT_RECENT_MEDIA,
        sort: ORDER_RECENT_MEDIA,
      },
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setRecentMedia(
          parseMediaToSharedMedia(
            data.getFHIRPatientbyMediaCodexIDParameter.media,
          ),
        );
      },
    });

  useChannelSignal(
    (first) => {
      if (!first) {
        refetchRecent();
        refetchShared();
      }
    },
    SIGNAL_CHANNELS.SHARED_MEDIA,
    patientId,
  );

  const handleLoadMore = () => {
    setLimit((prev) => prev + LIST_BATCH_SIZE);
  };

  const handleOnFromDateChange = (date: string | undefined) => {
    if (toDate && date && new Date(date).getTime() > new Date(toDate).getTime())
      setToDate(undefined);
    setFromDate(date);
  };

  const handleOnToDateChange = (date: string | undefined) => {
    if (
      fromDate &&
      date &&
      new Date(date).getTime() < new Date(fromDate).getTime()
    )
      setFromDate(undefined);

    setToDate(date);
  };

  if (!locale || loading || recentMediaLoading) return <Loader />;

  return (
    <>
      {isMediaModalOpen && <SharedMediaModal />}
      <div className="px-7 pt-[30px] desktop:pt-0">
        {sharedMedia.length === 0 ? (
          <div className="flex justify-center desktop:justify-start mt-[30px]">
            <p className="text-base text-dark-gray font-medium">
              {locale?.sharedMedia?.noResults}
            </p>
          </div>
        ) : (
          <>
            <div className="flex flex-col px-5 pt-5 pb-10 my-[30px] desktop:p-[30px] desktop:mb-[30px] gap-5 bg-white rounded-10">
              <h3 className="text-h3 text-dark-gray font-semibold">
                {locale?.sharedMedia?.recentResults}
              </h3>
              <hr />
              <RecentSharedMedias
                sharedMedia={recentMedia}
                locale={locale?.sharedMedia}
              />
            </div>

            <SharedMediaTable
              onLoadMore={handleLoadMore}
              onFromDateChange={(date) =>
                handleOnFromDateChange(date || undefined)
              }
              onToDateChange={(date) => handleOnToDateChange(date || undefined)}
              loading={sharedMediaLoading}
              total={total}
              fromDate={fromDate || ''}
              toDate={toDate || ''}
              localeByProp={locale?.sharedMedia}
              onTypeChange={setType}
              type={type}
              sharedMedia={sharedMedia}
              orderBy={orderBy}
              setOrderBy={setOrderBy}
            />
          </>
        )}
      </div>
    </>
  );
};
