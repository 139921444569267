import { ComputedDatum, CustomLayerProps } from '@nivo/line';
import { useAnimatedPath } from '@nivo/core';
import { Line as D3Line } from 'd3-shape';
import { animated } from '@react-spring/web';
import { CurrentPoint } from './interface';
import { useMemo } from 'react';

const CustomLinePath: React.FC<{
  lineGenerator: D3Line<ComputedDatum['position']>;
  color: string;
  strokeWidth: number;
  points: ComputedDatum['position'][];
  selected: boolean;
}> = ({ lineGenerator, points, color, strokeWidth, selected }) => {
  const path = useMemo(() => lineGenerator(points), [lineGenerator, points]);
  const animatedPath = useAnimatedPath(path || '');
  return (
    <animated.path
      d={animatedPath}
      fill="none"
      strokeWidth={strokeWidth}
      stroke={color}
      style={{
        ...(!selected
          ? {
              strokeDasharray: '1 1',
            }
          : {}),
      }}
    />
  );
};

export const CustomLineLayer: (props: CustomLayerProps) => React.ReactNode = ({
  series,
  lineGenerator,
  lineWidth,
  ...props
}) => {
  const { currentPoint } = props as CurrentPoint;
  const selectedId = currentPoint?.id.split('.')[0];
  return (
    <>
      {series
        .slice(0)
        .reverse()
        .map(({ id, data, color }) => (
          <CustomLinePath
            key={id}
            lineGenerator={lineGenerator}
            points={data.map((d) => d.position)}
            color={color || ''}
            strokeWidth={lineWidth || 1}
            selected={id === selectedId}
          />
        ))}
    </>
  );
};
