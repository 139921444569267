import { usePasswordResetEmail } from '../../../firebase/hooks';
import Alert from 'components/alertComponent';
import ButtonComponent from 'components/button/buttonComponent';
import { pageIds } from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';
import { useLocation, useNavigate } from 'react-router-dom';
import { AUTH_LOGIN } from '../../../utilities/routes';

export const SendResetPasswordEmail = () => {
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.RESET_PASSWORD_EMAIL,
  });
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email') || '';
  const [errorMsg, handlePasswordResetEmail, isLoading] =
    usePasswordResetEmail();
  const handleResendResetPasswordEmail = async () => {
    await handlePasswordResetEmail(email);
  };

  const handleLogin = () => navigate(AUTH_LOGIN);

  if (loading && !locale) return null;

  return (
    <>
      <div className="flex flex-row w-full mb-[10px]">
        <Alert
          type="positive"
          testID="success-alert"
          text={locale?.resetEmailSent}
        />
      </div>
      <div className="flex flex-col items-center desktop:justify-center px-[20px] py-[30px] desktop:px-[60px] desktop:py-[40px] gap-[30px] rounded-10 bg-white">
        <div className="flex flex-col items-start desktop:items-center p-0 gap-[20px] desktop:gap-[30px]">
          <p className="text-base font-medium text-center text-med-gray">
            {locale?.checkSpamFolder}
          </p>
        </div>
        <div className="flex flex-col items-center gap-5">
          <ButtonComponent
            testID="login-button"
            type="submit"
            paddingX="px-5"
            paddingY="py-[9.5px]"
            className="uppercase text-sm desktop:w-full"
            containerClassName="font-bold font-exo"
            onClick={handleLogin}
          >
            {locale?.loginButton}
          </ButtonComponent>
          <div className="flex justify-center w-full desktop:w-auto">
            <p className="text-center text-base font-medium text-med-gray mr-2">
              {locale?.emailNotReceived}
            </p>
            <ButtonComponent
              testID="resend-button"
              onClick={handleResendResetPasswordEmail}
              type="underline"
              disabled={isLoading}
              noUpperCase
              className="text-clc-blue text-base font-bold uppercase w-auto"
            >
              {locale.resendButton}
            </ButtonComponent>
          </div>
          {errorMsg && <p className="text-red">{errorMsg}</p>}
        </div>
      </div>
    </>
  );
};
