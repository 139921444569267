import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  LazyQueryExecFunction,
  NormalizedCacheObject,
} from '@apollo/client';
import { FCWChildren } from 'apollo/FCWithChildren';
import {
  Exact,
  GetComponentQuery,
  GetGenericActionsQuery,
  GetLegalDocumentByIdQuery,
  GetLegalDocumentsQuery,
  GetPageQuery,
  GetPagesQuery,
  GetTemplateMessagesQuery,
  InputMaybe,
  Scalars,
  useGetComponentLazyQuery,
  useGetGenericActionsLazyQuery,
  useGetLegalDocumentByIdLazyQuery,
  useGetLegalDocumentsLazyQuery,
  useGetPageLazyQuery,
  useGetPagesLazyQuery,
  useGetTemplateMessagesLazyQuery,
} from 'graphql/generated/strapi';
import React, { createContext, useContext } from 'react';
import { getStrapiConfig } from 'utilities/config';

type StrapiContextType = {
  client: ApolloClient<NormalizedCacheObject>;
  getLegalDocuments: LazyQueryExecFunction<
    GetLegalDocumentsQuery,
    Exact<{
      locale?: InputMaybe<Scalars['I18NLocaleCode']>;
      legalDocumentTypes?: InputMaybe<
        Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
      >;
    }>
  >;
  getComponent: LazyQueryExecFunction<
    GetComponentQuery,
    Exact<{
      locale?: InputMaybe<Scalars['I18NLocaleCode']>;
      componentId?: InputMaybe<Scalars['Long']>;
    }>
  >;
  getLegalDocumentById: LazyQueryExecFunction<
    GetLegalDocumentByIdQuery,
    Exact<{
      locale?: InputMaybe<Scalars['I18NLocaleCode']>;
      legalDocumentId: Scalars['Long'];
    }>
  >;
  getGenericActions: LazyQueryExecFunction<
    GetGenericActionsQuery,
    Exact<{
      locale?: InputMaybe<Scalars['I18NLocaleCode']>;
      genericActionId?: InputMaybe<
        Array<InputMaybe<Scalars['Long']>> | InputMaybe<Scalars['Long']>
      >;
    }>
  >;
  getTemplateMessages: LazyQueryExecFunction<
    GetTemplateMessagesQuery,
    Exact<{
      locale?: InputMaybe<Scalars['I18NLocaleCode']>;
      templateId?: InputMaybe<
        Array<InputMaybe<Scalars['Long']>> | InputMaybe<Scalars['Long']>
      >;
    }>
  >;
  getPage: LazyQueryExecFunction<
    GetPageQuery,
    Exact<{
      locale?: InputMaybe<Scalars['I18NLocaleCode']>;
      pageId?: InputMaybe<Scalars['Long']>;
    }>
  >;
  getPages: LazyQueryExecFunction<
    GetPagesQuery,
    Exact<{
      locale?: InputMaybe<Scalars['I18NLocaleCode']>;
    }>
  >;
};

const strapiContext = createContext<StrapiContextType>({} as StrapiContextType);

const { strapiGraphQlApi } = getStrapiConfig();

const client = new ApolloClient({
  uri: strapiGraphQlApi,
  cache: new InMemoryCache(),
});

export const useStrapi = () => {
  const ctx = useContext(strapiContext);
  if (!ctx.client && process.env.REACT_APP_IS_DEBUG_MODE)
    throw new Error('useStrapi must be called from within a LocalesProvider');
  return ctx;
};

const StrapiProvider: FCWChildren = ({ children }) => {
  const [getLegalDocuments] = useGetLegalDocumentsLazyQuery({ client });
  const [getLegalDocumentById] = useGetLegalDocumentByIdLazyQuery({
    client,
  });
  const [getComponent] = useGetComponentLazyQuery({ client });
  const [getGenericActions] = useGetGenericActionsLazyQuery({ client });
  const [getTemplateMessages] = useGetTemplateMessagesLazyQuery({ client });
  const [getPage] = useGetPageLazyQuery({ client });
  const [getPages] = useGetPagesLazyQuery({ client });
  return (
    <strapiContext.Provider
      value={{
        client,
        getLegalDocuments,
        getComponent,
        getLegalDocumentById,
        getGenericActions,
        getTemplateMessages,
        getPage,
        getPages,
      }}
    >
      {children}
    </strapiContext.Provider>
  );
};

export const LocalesProvider: FCWChildren = ({ children }) => {
  return (
    <ApolloProvider client={client}>
      <StrapiProvider>{children}</StrapiProvider>
    </ApolloProvider>
  );
};
