import { useState, useEffect, useCallback } from 'react';
import * as VideoExpress from '@vonage/video-express';

interface AudioOutputDevices {
  deviceId: string | null | undefined;
  label: string | null | undefined;
}

interface DeviceInfo {
  audioInputDevices: VideoExpress.Device[];
  videoInputDevices: VideoExpress.Device[];
  audioOutputDevices: AudioOutputDevices[];
}

export interface DevicesHook {
  deviceInfo: DeviceInfo;
  getDevices: () => Promise<void>;
}

export const useDevices = (): DevicesHook => {
  const [deviceInfo, setDeviceInfo] = useState<DeviceInfo>({
    audioInputDevices: [],
    videoInputDevices: [],
    audioOutputDevices: [],
  });

  const getDevices = useCallback(async () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      console.warn('enumerateDevices() not supported.');
      return;
    }

    try {
      const devices = await VideoExpress.getDevices();
      let audioOutputDevices = await VideoExpress.getAudioOutputDevices();
      audioOutputDevices = audioOutputDevices.map((audiooutput) =>
        audiooutput.deviceId === 'default'
          ? { ...audiooutput, label: 'System Default' }
          : audiooutput,
      );
      const audioInputDevices = devices?.filter(
        (d) => d.kind.toLowerCase() === 'audioinput',
      ) as VideoExpress.Device[];
      const videoInputDevices = devices?.filter(
        (d) => d.kind.toLowerCase() === 'videoinput',
      ) as VideoExpress.Device[];

      setDeviceInfo({
        audioInputDevices,
        videoInputDevices,
        audioOutputDevices,
      });
    } catch (err) {
      console.error(
        '[loadDevices] - An error occurred while loading devices:',
        err,
      );
    }
  }, []);

  useEffect(() => {
    navigator.mediaDevices.addEventListener('devicechange', getDevices);
    getDevices();

    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', getDevices);
    };
  }, [getDevices]);

  return { deviceInfo, getDevices };
};
