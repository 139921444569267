import { ReactComponent as Close } from 'assets/icons/close.svg';
import { ReactComponent as Arrow } from 'assets/icons/upArrow.svg';
import {
  ClearIndicatorProps,
  components,
  DropdownIndicatorProps,
  MultiValueGenericProps,
  MultiValueRemoveProps,
  NoticeProps,
  SingleValueProps,
} from 'react-select';
import { twMerge } from 'tailwind-merge';

interface IMultiValueLabel {
  readonly value: string;
  readonly label: string;
}

export const SingleValueComponent = (
  props: SingleValueProps<IMultiValueLabel>,
) => {
  return (
    <components.SingleValue
      {...props}
      innerProps={{
        className: 'text-sm !text-med-gray-3 font-semibold',
      }}
    >
      {props.children}
    </components.SingleValue>
  );
};

export const MultiValueLabel = (
  props: MultiValueGenericProps<IMultiValueLabel>,
) => {
  return (
    <components.MultiValueLabel
      {...props}
      innerProps={{
        className: 'text-sm px-2 py-1 text-med-gray-3 font-semibold',
      }}
    >
      {props.children}
    </components.MultiValueLabel>
  );
};

export const MultiValueRemove = (props: MultiValueRemoveProps) => {
  return (
    <components.MultiValueRemove {...props}>
      <Close className="fill-med-gray-3 w-4 h-4" />
    </components.MultiValueRemove>
  );
};

export const DropdownIndicator = (
  props: DropdownIndicatorProps<IMultiValueLabel, true>,
) => {
  return (
    <components.DropdownIndicator {...props}>
      <Arrow
        className={twMerge(
          'h-1.5',
          props.isFocused ? 'rotate-0' : 'rotate-180',
        )}
      />
    </components.DropdownIndicator>
  );
};

export const ClearIndicator = (
  props: ClearIndicatorProps<IMultiValueLabel, true>,
) => {
  return (
    <components.ClearIndicator {...props}>
      <Close className="stroke-med-gray-3 h-5" />
    </components.ClearIndicator>
  );
};

export const NoOptionsMessage = (
  props: NoticeProps<IMultiValueLabel, true>,
) => {
  return (
    <components.NoOptionsMessage
      {...props}
      className="font-semibold text-sm !text-med-gray"
    >
      {props.children}
    </components.NoOptionsMessage>
  );
};
