import { useGetPage } from 'hooks/useGetPage';
import {
  AllowedFormats,
  DEFAULT_COUNTRY_CODE_NUMBER,
  ImageFormat,
  genericActionsIds,
  pageIds,
} from 'utilities/constants';
import { ReactComponent as ChevronDownIcon } from 'assets/icons/chevron-down.svg';
import { ReactComponent as ChevronUpIcon } from 'assets/icons/chevron-up.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as EditIconDisable } from 'assets/icons/editDisable.svg';
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { AuthContext } from 'auth/context/AuthContext';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import {
  useGetCountriesQuery,
  useGetFileUrlFromStorageMutation,
  useUpdateProviderProfilePictureMutation,
} from 'graphql/generated/hasura';

import {
  GeneralStates,
  ProviderProfileDataOptional,
} from 'app/my-account/interfaces/profile.interfaces';
import {
  allowOnlyLettersWithAllowedCharacters,
  convertFileToBase64,
  getFirstNameFromDisplayName,
  getLastNameFromDisplayName,
  normalizePhoneNumber,
  parseMappedCountriesIntoSelectValues,
  parsePhoneNumber,
  parseSelectedValue,
  scrollToTop,
  validateNPI,
} from 'utilities/functions';
import ButtonComponent from 'components/button/buttonComponent';
import { usePatchFhirProviderAccountInfoByCodexIdMutation } from 'graphql/generated/remote-schema-hasura';
import { ProviderAccountInfoProps, ProviderAccountInfo } from './interfaces';
import InputComponent, {
  SelectWithFlagsValues,
} from 'components/inputComponent';
import { useIsMobile } from 'hooks/useIsMobile';
import { Link } from 'react-router-dom';
import IconComponent from 'components/iconComponent';
import Tooltip from 'components/Tooltip';
import { ReactComponent as InfoIcon } from 'assets/icons/weatherIcons/question.svg';
import { MY_PROFILE_PROVIDER_NPI_MODAL } from 'utilities/routes';
import { PhoneDataProps } from 'components/dynamicQuestionnaire/interfaces/SignUpProviderModule.interface';
import { accountInfoRequiredFields } from '../constants';
import useFormValidation, { ValidationRules } from 'hooks/useFormValidation';
import { emailPattern } from 'utilities/variables';
import ErrorMessageWithIcon from 'components/errorMessageWithIcon';
import Avatar from 'components/avatarComponent';

export const AccountInfo: React.FC<ProviderAccountInfoProps> = ({
  firstname,
  lastname,
  email,
  nationalProviderIdentifier,
  phone,
  handleProviderProfileInfoChange,
  handleUpdatedProviderAccountInfo,
}) => {
  const isMobile = useIsMobile();
  const [isSubmiting, setIsSubmiting] = useState(false);
  const { user: loggedUser } = useContext(AuthContext);
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.MY_PROFILE,
  });
  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.UPDATE],
    });
  const [isFormDisplayed, setIsFormDisplayed] = useState<boolean>(true);
  const [formValues, setFormValues] = useState<ProviderProfileDataOptional>({
    firstname:
      firstname || getFirstNameFromDisplayName(loggedUser?.displayName),
    lastname: lastname || getLastNameFromDisplayName(loggedUser?.displayName),
    email,
    phone,
    nationalProviderIdentifier,
  } as ProviderProfileDataOptional);
  const [patchFhirProviderAccountInfo] =
    usePatchFhirProviderAccountInfoByCodexIdMutation({});
  const [updateProfilePictureMutation] =
    useUpdateProviderProfilePictureMutation({});
  const [getFileUrlFromStorage] = useGetFileUrlFromStorageMutation({});
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { profilePicture, setProfilePicture } = useContext(AuthContext);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const handleUploadPhoto = () => {
    if (fileInputRef.current && !isEditing) {
      fileInputRef.current.click();
    }
  };

  const validateImage = (image: File): Promise<void> => {
    if (!AllowedFormats.includes(image.type as ImageFormat)) {
      return Promise.reject();
    }

    const img = new Image();
    img.src = URL.createObjectURL(image);

    return new Promise((resolve) => {
      img.onload = () => {
        setIsEditing(false);
        resolve();
      };
    });
  };

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (isEditing) return;
    const file = event.target.files && event.target.files[0];

    if (file) {
      try {
        await validateImage(file);
        setIsEditing(true);

        const base64Image = await convertFileToBase64(file);
        const profileResponse = await updateProfilePictureMutation({
          variables: {
            image: base64Image,
            filename: file.name,
          },
        });
        const image = await getFileUrlFromStorage({
          variables: {
            fileId:
              profileResponse.data?.UpdateProviderProfilePicture?.fileId || '',
          },
        });

        setProfilePicture(image.data?.GetSignUrlFormStorage?.url || null);
        setIsEditing(false);
      } catch (error) {
        setIsEditing(false);
        error &&
          console.error(`Error on image upload ${JSON.stringify(error)}`);
      }
    }
  };

  const handleShowForm = () => {
    setIsFormDisplayed(!isFormDisplayed);
  };

  const parsedPhoneNumber = parsePhoneNumber(formValues.phone ?? '');

  const [phoneData, setPhoneData] = useState<PhoneDataProps>({
    phone: parsedPhoneNumber?.number,
    country: parsedPhoneNumber?.country ?? DEFAULT_COUNTRY_CODE_NUMBER,
    extension: parsedPhoneNumber?.extension ?? '',
  });

  const handleOnInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;

    if (name === 'firstname' || name === 'lastname') {
      if (allowOnlyLettersWithAllowedCharacters(value)) {
        setFormValues({ ...formValues, [name]: value });
      }
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const handlePhoneChange = (
    value: string,
    type: 'country' | 'phone' | 'extension',
  ) => {
    if (type === 'country') {
      setPhoneData({
        phone: phoneData.phone,
        country: value,
        extension: phoneData.extension,
      });
      const extensionPart = phoneData.extension
        ? ` ${phoneData.extension}`
        : '';
      const valueParsed = `+${value} ${phoneData.phone}${extensionPart}`;
      setFormValues({ ...formValues, phone: valueParsed });
    }

    if (type === 'phone') {
      setPhoneData({
        phone: normalizePhoneNumber(value),
        country: phoneData.country,
        extension: phoneData.extension,
      });
      const extensionPart = phoneData.extension
        ? ` ${phoneData.extension}`
        : '';
      const valueParsed = `+${phoneData.country} ${normalizePhoneNumber(
        value,
      )}${extensionPart}`;
      setFormValues({ ...formValues, phone: valueParsed });
    }

    if (type === 'extension') {
      setPhoneData({
        phone: phoneData.phone,
        country: phoneData.country,
        extension: normalizePhoneNumber(value),
      });
      const extensionPart =
        value.length > 0 ? ` ${normalizePhoneNumber(value)}` : '';
      const valueParsed = `+${phoneData.country} ${phoneData.phone}${extensionPart}`;
      setFormValues({ ...formValues, phone: valueParsed });
    }
  };

  const validationRules: ValidationRules = {
    firstname: [
      {
        validator: (value) => value.length > 0,
        message: locale?.providerLocales.fieldRequired,
      },
    ],
    lastname: [
      {
        validator: (value) => value.length > 0,
        message: locale?.providerLocales?.fieldRequired,
      },
    ],
    email: [
      {
        validator: (value) => value.length > 0,
        message: locale?.providerLocales?.fieldRequired,
      },
      {
        validator: (value) => new RegExp(emailPattern).test(value),
        message: locale?.providerLocales?.errorEnterValidEmail,
      },
    ],
    phone: [
      {
        validator: () =>
          ((phoneData && phoneData.phone && phoneData.phone.length >= 7) ??
            false) as boolean,

        message: locale?.providerLocales?.fieldRequired,
      },
    ],
    nationalProviderIdentifier: [
      {
        validator: (value) => value.length > 0,
        message: locale?.providerLocales?.errorEnterValidNPI,
      },
      {
        validator: (value) => validateNPI(value),
        message: locale?.providerLocales?.errorEnterValidNPI,
      },
    ],
  };

  const { errors, validateForm } = useFormValidation(validationRules);

  useEffect(() => {
    handleProviderProfileInfoChange(formValues);
  }, [formValues, handleProviderProfileInfoChange]);

  const handleValidation = useCallback(
    (providerData: ProviderProfileDataOptional) => {
      const formData = JSON.parse(JSON.stringify(providerData));
      return validateForm(formData);
    },
    [validateForm],
  );

  const prevLocalFormDataRef = useRef<ProviderProfileDataOptional | null>(null);
  const validationExecutedRef = useRef(false);

  const handleValidationCallback = useCallback(() => {
    // Check if the validation has already been executed
    if (!validationExecutedRef.current) {
      if (handleValidation) {
        handleValidation(formValues);
      }
      validationExecutedRef.current = true;
    }
  }, [formValues, handleValidation]);

  useEffect(() => {
    // Check if localFormData is different from the previous data
    if (formValues !== prevLocalFormDataRef.current) {
      // Update the ref with the latest current data
      prevLocalFormDataRef.current = formValues;

      // Reset the validation executed ref when localFormData changes
      validationExecutedRef.current = false;

      // Run validation when localFormData changes
      handleValidationCallback();
    }
  }, [formValues, handleValidationCallback]);

  const { data } = useGetCountriesQuery({});

  const mappedCountriesValues = useMemo(
    () =>
      data?.countries
        ? [...data.countries]
            .sort((a, b) => parseInt(a.code) - parseInt(b.code))
            .map((country) => {
              return { code: country.code, flag: country.flag };
            })
        : [],
    [data?.countries],
  );

  const getMemoizedCurrentCountryValue = useMemo(
    () =>
      phoneData?.country &&
      parseSelectedValue(phoneData.country as string, mappedCountriesValues),
    [mappedCountriesValues, phoneData],
  );

  const getMemoizedDefaultParsedValue = useMemo(
    () => parseMappedCountriesIntoSelectValues(mappedCountriesValues)[0],
    [mappedCountriesValues],
  );

  const getMemoizedParsedCountries = useMemo(
    () => parseMappedCountriesIntoSelectValues(mappedCountriesValues),
    [mappedCountriesValues],
  );

  const accountInfoFields = [
    {
      label: locale?.personalInfo.accountDetails.firstNameLabel,
      type: 'text',
      placeholder: locale?.personalInfo.accountDetails.firstNamePlaceholder,
      name: 'firstname',
      value: formValues.firstname,
      onChange: handleOnInputChange,
      maxLengthValue: 100,
    },
    {
      label: locale?.personalInfo.accountDetails.lastNameLabel,
      type: 'text',
      placeholder: locale?.personalInfo.accountDetails.lastNamePlaceholder,
      name: 'lastname',
      value: formValues.lastname,
      onChange: handleOnInputChange,
      maxLengthValue: 100,
    },
    {
      label: locale?.personalInfo.accountDetails.emailLabel,
      type: 'text',
      placeholder: locale?.personalInfo.accountDetails.emailPlaceholder,
      name: 'email',
      value: formValues.email,
      onChange: handleOnInputChange,
      isDisabled: true,
    },
    {
      label: 'Phone',
      type: 'text',
      name: 'accountInfoPhone',
      value: phoneData?.phone,
      onChange: handleOnInputChange,
    },
    {
      label: 'National Provider Identifier',
      type: 'text',
      name: 'nationalProviderIdentifier',
      value: formValues.nationalProviderIdentifier,
      onChange: handleOnInputChange,
    },
  ];

  const validateRequiredFields = () => {
    for (const key in formValues) {
      if (
        accountInfoRequiredFields.find((requiredField) => requiredField === key)
      ) {
        if (!formValues[key as keyof ProviderAccountInfo]) {
          return false;
        }
      }
    }
    return true;
  };

  const handleOnSubmit = async () => {
    const isFormValid = validateRequiredFields();
    if (!isFormValid)
      return handleUpdatedProviderAccountInfo(GeneralStates.MISSING_INFO);

    try {
      setIsSubmiting(true);

      const response = await patchFhirProviderAccountInfo({
        variables: {
          providerAccountInfo: {
            firstname: formValues.firstname || null,
            lastname: formValues.lastname || null,
            email: formValues.email,
            phone: formValues.phone || null,
            nationalProviderIdentifier:
              formValues.nationalProviderIdentifier || null,
          },
        },
      });
      if (!response.data) {
        setIsSubmiting(false);
        handleUpdatedProviderAccountInfo(GeneralStates.ERROR);
        scrollToTop();
        throw new Error('Failed to update provider');
      }

      handleUpdatedProviderAccountInfo(GeneralStates.SUCCESS);

      setIsSubmiting(false);
      scrollToTop();
    } catch (error: unknown) {
      setIsSubmiting(false);
      console.log('There has been an error while updating the provider', error);
      handleUpdatedProviderAccountInfo(GeneralStates.ERROR);
      scrollToTop();
      throw new Error('Failed to update provider');
    }
  };

  if (loading || !locale || genericActionLoading || !genericAction) return null;

  return (
    <div className="flex flex-col items-center bg-white w-full p-5 desktop:py-[30px] desktop:px-[60px] gap-5 rounded-10 desktop:rounded-tl-none">
      <div
        className="flex flex-col items-start w-full gap-2.5 p-0 cursor-pointer desktop:cursor-default"
        onClick={handleShowForm}
      >
        <div className="flex flex-row w-full items-center justify-between">
          <div className="flex flex-row items-center">
            <h3 className="text-h5 desktop:text-h4 text-charcoal-gray font-semibold desktop:mr-2.5">
              {locale?.providerLocales?.accountInfoSection}
            </h3>
          </div>
          {isFormDisplayed ? (
            <ChevronUpIcon
              className="desktop:hidden w-[18px] h-2.5 fill-current cursor-pointer"
              onClick={handleShowForm}
            />
          ) : (
            <ChevronDownIcon
              className="desktop:hidden w-[18px] h-2.5 fill-current cursor-pointer"
              onClick={handleShowForm}
            />
          )}
        </div>
        <hr
          className={`desktop:flex flex-row w-full items-center h-px bg-black-blur ${
            isFormDisplayed ? 'flex' : 'hidden'
          }`}
        />
      </div>

      <div
        className={`w-full desktop:flex flex-col gap-[15px] ${
          isFormDisplayed ? 'flex' : 'hidden'
        }`}
      >
        <h2 className="uppercase font-bold text-charcoal-gray text-h5">
          {locale?.personalInfo.accountDetails.title}
        </h2>
        <div className="flex flex-col items-center w-full px-[90px]">
          <div className="flex flex-col desktop:flex-row w-full desktop:w-[750px] items-center desktop:items-center">
            <label
              className="desktop:w-4/12 mr-4 text-base text-charcoal-gray text-right font-semibold mb-2"
              htmlFor="profile-picture"
            >
              {locale?.personalInfo.accountDetails.profilePictureLabel}
            </label>
            <div className="flex flex-row w-full desktop:w-8/12 gap-[15px] mb-2.5">
              <Avatar size="md" imageUrl={profilePicture} />
              <div className="flex flex-row items-center cursor-pointer">
                {isEditing ? (
                  <EditIconDisable className="w-4 h-4 fill-current mr-[7px] text-med-gray" />
                ) : (
                  <EditIcon className="w-4 h-4 fill-current mr-[7px] text-med-gray" />
                )}

                <p
                  className={`text-bas font-bold underline uppercase ${
                    isEditing ? 'text-med-gray' : 'text-clc-blue'
                  }`}
                  onClick={handleUploadPhoto}
                >
                  {locale?.personalInfo.accountDetails.changePhotoButton}
                </p>
                <input
                  type="file"
                  id="myImage"
                  onChange={handleImageUpload}
                  ref={fileInputRef}
                  className="hidden"
                />
              </div>
            </div>
          </div>
          {accountInfoFields.map((field) => (
            <div
              key={field.name}
              className="flex flex-col desktop:flex-row w-full desktop:w-[750px] items-center desktop:items-center"
            >
              <label
                className="w-full mr-4 desktop:w-4/12 desktop:text-right text-base text-charcoal-gray font-semibold mb-2"
                htmlFor={field.name}
              >
                {field.label}
                <span className="text-base font-bold text-clc-red">
                  {locale?.accountDeletion.form.required}
                </span>
                {field.name === 'nationalProviderIdentifier' && (
                  <span className="ml-1 h-[22px]">
                    {isMobile && (
                      <Link to={MY_PROFILE_PROVIDER_NPI_MODAL}>
                        <IconComponent
                          width="w-5"
                          height="h-5"
                          icon={InfoIcon}
                          fill="fill-clc-blue"
                        />
                      </Link>
                    )}
                    {!isMobile && (
                      <Tooltip
                        textContainerCustomClasses="text-left"
                        text={
                          locale?.providerLocales
                            ?.nationalProviderIdentifierContent
                        }
                      >
                        <span className="cursor-pointer">
                          <IconComponent
                            width="w-5"
                            height="h-5"
                            icon={InfoIcon}
                            fill="fill-clc-blue"
                          />
                        </span>
                      </Tooltip>
                    )}
                  </span>
                )}
              </label>
              <div className="w-full desktop:w-8/12">
                {field.name === 'accountInfoPhone' ? (
                  <div className="flex desktop:flex-row flex-col">
                    <div className="flex w-full mr-5">
                      <div className="desktop:max-w-full">
                        <InputComponent
                          testID="phoneNumber-country-select"
                          type="select-with-country-icons"
                          name="country"
                          selectInputWithFlagsProps={{
                            currentValue:
                              getMemoizedCurrentCountryValue as SelectWithFlagsValues,
                            defaultValue:
                              getMemoizedDefaultParsedValue as SelectWithFlagsValues,
                            options:
                              getMemoizedParsedCountries as SelectWithFlagsValues[],
                            onSelectChange: (e) => {
                              handlePhoneChange(e.label, 'country');
                            },
                          }}
                        />
                      </div>
                      <div className="w-full min-w-[105px] desktop:max-w-[195px]">
                        <InputComponent
                          value={phoneData?.phone}
                          testID="phoneNumber-input"
                          type="tel"
                          name="phoneNumber"
                          flexGrow={1}
                          borderRadiusLeft={true}
                          onChange={(e) => {
                            handlePhoneChange(e.target.value, 'phone');
                          }}
                          errorStatus={!!errors.phone}
                        />
                        {errors.phone && (
                          <ErrorMessageWithIcon message={errors.phone} />
                        )}
                      </div>
                    </div>
                    <div className="flex">
                      <label
                        className="mt-3 mb-2.5 mr-2.5 text-base text-charcoal-gray font-bold desktop:w-2/12 desktop:mb-2"
                        htmlFor="phoneNumberExtension"
                      >
                        {locale?.providerLocales?.phoneExtension}
                      </label>
                      <div className="flex w-full desktop:max-w-[130px] max-h-[58px]">
                        <InputComponent
                          value={phoneData?.extension as string}
                          flexGrow={1}
                          type="ext"
                          name="phoneNumberExtension"
                          maxLengthValue={4}
                          onChange={(e) => {
                            handlePhoneChange(e.target.value, 'extension');
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <InputComponent
                    type={field.type}
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    isDisabled={field.isDisabled}
                    maxLengthValue={field.maxLengthValue}
                    errorStatus={!!errors[field.name]}
                    errorMsgWithIcon={errors[field.name]}
                  />
                )}
              </div>
            </div>
          ))}
        </div>

        <div className="hidden w-full desktop:flex justify-end border border-transparent border-t-gray-opacity-15 pt-6">
          <ButtonComponent
            disabled={isSubmiting}
            iconPosition="right"
            className="px-10 font-exo text-sm font-bold"
            onClick={handleOnSubmit}
          >
            {genericAction?.[genericActionsIds.UPDATE].update}
          </ButtonComponent>
        </div>
      </div>
    </div>
  );
};
